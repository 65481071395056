import React from "react";
import tokens from '../../../tokens2.json';

export const COUNTRIES = tokens.countries.map(c => c.code);
export const COUNTRIES_MAP = {};
tokens.countries.forEach(c => COUNTRIES_MAP[c.code] = c);

export const COUNTRIES_SOLD = ['RUS', 'SAU', 'SDN', 'SLB', 'USA'];

export const COUNTRIES_NAME = {};
tokens.countries.forEach(c => COUNTRIES[c.code] = c.name);

export const COUNTRIES_SVG = {
    ZMB: <>
        <path className="st0" d="M2090.55,1409.46l0.49-0.09l0.52-0.16l1.64-1.63l0.02-0.32l-0.3-0.55l-0.61-0.82l-0.14-0.34l-0.07-0.99
  		l-0.24-0.3l-0.9-0.67l-0.69-0.7l0.22-0.71l0.12-0.78l-0.34-0.43l-0.51-0.44l-0.31-0.65l-0.14-0.48l-0.4-0.19l-0.37-0.01l-0.27,0.3
  		l-0.29-0.03l-0.35-0.1l-0.12-0.42l-0.02-0.45l-0.24-0.3l-0.23-0.42l-0.03-0.22l-0.48-0.22l-0.9-0.49l-1.25-0.44l-1.02-0.49
  		l-0.45-0.48l-0.96-0.18l-0.83-0.08l-0.77-0.44l-0.8-0.05l-0.78-0.11l-0.17-0.29l-0.03-0.65l-0.27-0.17l-0.57-0.17l-0.62,0.01
  		l-0.38,0.09l-0.22-0.05l-0.51-0.38l-0.48-0.49l-0.18-0.78l-0.72-0.5l-0.83-0.4l-1.39,0.02l0.1,0.35l-0.08,0.66l-0.09,0.1
  		l-0.11-0.03l-0.13-0.16l-0.15,0.04l-0.17,0.25l-0.25,0.19l-0.33,0.13l-0.19-0.04l-0.43-0.35l-1.39-1.41l-0.06-0.1l-0.74-0.27
  		l-0.25-0.17l-0.08-0.1l-0.15,0.03l-0.29,0.31l-0.55,0.3l-0.17-0.01l0.22-0.19l0.08-0.16l-0.01-0.2l-0.21-0.08l-0.41,0.03
  		l-0.25-0.07l-0.14-0.41l0.11-0.56l0.15-0.23l0.25-0.2l0.35-0.16l0.21-0.42l0.03-0.36l-2.1,0.32l-2.33,0.35l-2.4,0.37l-2.38,0.36
  		l-2.26,0.34l-2.05,0.31l-0.62,0.17l0.31,0.88l-0.14,0.93l-0.41,0.72l-0.64,0.89l-0.29,0.35l-0.65,0.69l-0.55,0.49l-1.11,0.82
  		l-0.7,0.47l0,0.42l1.18,1.98l0.54,1.42l0.22,1.29l-0.01,0.74l-0.05,1.52l-0.05,1.81l-0.08,0.71l0.32,1.29l-0.06,1.01l-0.8,1.13
  		l-0.22,1.11l-0.4,1.49l-0.56,2.07l-0.4,1.09l0.22,0.71l0.2,0.48l0.21,0.64l0.43,0.97l0.5,0.57l0.28,0.25l1.64,1.21l0.68,0.59
  		l1.04,1.17l0.77,0.77l1.07,0.18l1.28,0.29l0.71,0.22l0.49-0.11l0.16-0.27l-0.02-0.58l-0.09-0.43l0.14-0.33l0.43-0.22l1.11-0.03
  		l0.49-0.29l0.38-0.07l0,1.28l0,1.23l0,1.49l0,1.71l0,1.4l0,1.49l0,1.72l-0.01,0.2l-0.17,0.39l-0.44,0.13l-0.6-0.34l-0.03-0.35
  		l-0.15-0.63l-0.28-0.32l-0.36-0.1l-0.61,0.16l-0.84,0.47l-1.08,0.41l-0.44,0.23l-0.76-0.03l-0.82-0.22l-0.61-0.53l-0.17-0.79
  		l-0.53-0.81l-0.72-1.17l-0.36-0.48l-0.48-0.55l-0.48-0.06l-0.54-0.15l-0.33-0.8l-0.31-1.01l-0.19-0.39l-0.32-0.5l-0.46-0.31
  		l-1.01-0.4l-1.42-0.56l-1.78-0.71l-0.85-0.03l-0.95-0.12l-0.59-0.34l-0.34-0.27l-0.39-0.98l-0.53-1.15l-1.56-1.37l-0.35-1.51
  		l-0.31-0.22l-0.54,0.12l-0.42,0.19l-0.16,0.41l-0.42,1.36l-0.23,0.63l-0.16,0.17l-0.34,0.21l-0.56,0.18l-0.79,0.09l-1.12-0.03
  		l-1.4-0.21l-0.76-0.15l-2.05-0.23l-0.59-0.11l-0.84-0.3l-0.6-0.3l-1.99-0.64l-0.9,0.08l-0.44-0.45l-0.39-0.22l-0.54-0.43
  		l-0.24-0.59l-0.32-1.26l0.08-0.67l0.23-0.75l-0.26-0.21h-0.36l-0.51,0.26l-0.92,0.14l-1.68,0.33l-0.59,0.19l-0.66,0.14l-0.5,0.13
  		l-1.26,0.73l-0.44,0.08l-0.75-0.26l-0.36-0.39l0.36-0.44l0.15-0.54l-0.26-1.06l-0.39-0.49l-1.12-0.35l-0.43-0.03l-0.18-0.6
  		l-0.31-0.54l-0.64-0.11l-0.3-0.06l0.18,1.11l0.18,1.54l0.13,1.11l0.13,0.5l0.04,0.26l-0.15,0.29l-0.12,0.67l-0.23,0.59l-0.13,0.41
  		l0.11,0.76l-0.09,1.09l-0.09,1.15l-0.03,1.1l0.32,1.98l-0.04,0.61l-0.39,1.03l-0.3,0.79l-0.19,0.91l-0.03,0.47l0.72,1.34
  		l-0.04,0.27l-0.55,0.08l-0.46,0.02h-1.75h-2.5h-2.5h-2.5h-2.3h-2.2h-1.94l0,1.33l0,2.73v2.74l0,2.74l0,2.74l0,2.74l0,2.75l0,2.75
  		l0,2.75v1.99l0.51,2.64l0.93,2.87l0.36,0.27l0.94,0.53l1.3,1.08l0.73,0.82l1.48,1.42l1.97,1.81l1.9,1.63l1.68,1.43l1.8-0.36
  		l1.72-0.34l2-0.34l1.6-0.27l0.4-0.07l3.86,0.32l1.68,0.22l0.58,0.22l0.76,0.57l1.4,1.37l1.63,0.45l0.89,0.04l0.69-0.22l0.86,0.29
  		l1.02,0.81l1.12,0.15l1.21-0.5l1.63,0.15l2.06,0.81l1.7,0.16l2.03-0.72l-0.02-0.27l0.18-0.2l0.21-0.26l0.25-0.23l0.01-0.35
  		l0.09-0.31l0.55-0.71l0.71-0.69l0.31-0.25l0.22-0.15l0.16-0.11l0.14-0.19l0.14-0.21l0.01-0.22l-0.24-0.17l0.23-0.61l0.5-0.77
  		l0.35-0.46l0.66-0.81l0.5-0.68l0.27-0.04l0.23,0.06l0.16-0.08l-0.02-0.21l0.15-0.38l0.11-0.34l-0.1-0.16l0.11-0.1l0.3-0.04
  		l0.39-0.05l0.26-0.14l-0.12-0.28l-0.24-0.28l0.04-0.15l0.27-0.07l0.27-0.15l0.16-0.17l0.19-0.03l0.15,0.11l0.21,0.13l0.28,0.19
  		l0.29,0.05l0.28-0.06l0.26-0.24l0.28-0.3l0.25-0.17l0.27-0.25l0.1-0.25l-0.04-0.2l0.26-0.07l0.34-0.15l0.32-0.25l0.12-0.28
  		l-0.07-0.21l0.2,0.05l0.3,0.09l0.19,0.12l0.17,0.11l0.41-0.24l0.47-0.15l0.28,0.05l0.18-0.08l0.07-0.13l0.16-0.14l0.38-0.09
  		l0.49,0.12l0.66,0.2l0,0l0,0l0.61-0.93l0.2-1.01v-1.41l0.16-0.91l0.32-0.42l0.51-0.32l0.65-0.42l2-1.07l1.68-0.68l2.04-0.45l2.23,0
  		l2.15-0.01l1.22,0l-0.14-1.18l-0.25-1.34l-0.38-0.52l-0.45-0.91l-0.23-1l-0.03-0.48l0.08-0.17l1.8-0.71l0.77-0.35l1.14-0.41
  		l2.04-0.56l1.82-0.5l1.66-0.49l1.76-0.52l0.72-0.35l0.89-0.35l2.13-0.7l0.61-0.24l1.22-0.39l0.61-0.15l2.36-0.8l2.71-0.91
  		l0.95-0.32l1.81-0.61l-0.45-0.62l-0.37,0.16l-0.52,0.43l-0.28,0.12l-0.14-0.01l-0.09-0.11l-0.12-0.28l-0.4-0.79l-0.45-0.57
  		l-0.47-0.22l-0.39-0.26l0.17-0.25l0.18-0.18l-0.08-0.19l-0.22-0.27l-0.84-0.39l-0.01-0.17l0.74-0.34l0.47-0.41l0.32-0.39l0.4-0.85
  		l0.33-0.85l0.24-0.28l0.08-0.56l-0.06-0.64l0.16-0.81l0.08-0.76l-0.25-0.3l-0.21-0.51l0.25-0.88l0.39-0.6l1.87-0.63l1.3-0.57
  		l0.28-0.25l0.44-0.48l0.25-0.47l-0.18-0.14l-1.02-0.01l-0.25-0.18l-0.74-1.66l0.41-1.9l0.03-0.75l-0.01-0.93l-0.13-0.68l-0.32-0.28
  		l-0.2-0.36l0.05-0.99l0.3-0.12l0.65-1.31l0.29-0.77l-0.35-0.62l-0.38-0.88l-0.17-0.56l-0.1-0.18l0.27-0.35L2090.55,1409.46z
  		 M2039.94,1467.59l-0.42,0.48l-0.03,0.04l0.6-0.65l-0.01,0L2039.94,1467.59z"/>
    </>,
    ZAF: <>
        <path className="st0" d="M2128.69,1743.16l-0.7-0.51l-0.88-0.14l-0.3,0.26l-0.5,0.63l0.18,0.41l1.7,0.17l0.36-0.2l0.26-0.45
  		L2128.69,1743.16z M2084.18,1548.93l-0.94,0.02l-1.05,0.03l-1.3-0.25l-0.73,0.05l-0.26-0.13l-0.48-0.12l-0.25,0.06l-0.23,1.29
  		l-0.18,1.92l0.1,1.19l-1.82,0.04l-2.3-0.13l-1.65-0.52l-1.77-1.14l-1.05-1.77l-0.46-1.11l-0.64-0.06l-0.1-0.19l-0.06-1.36
  		l0.01-1.42l0.12-0.38l1.2-1.75l0.74-1.09l0.46-1.05l1-1.23l1.08-0.79l0.4-0.11l0.27,0.03l1.9,1.08l1.95,1.02l0.42-0.12l0.22-0.1
  		l-0.17-0.65l-0.07-1l0.54-1.27l-0.04-2.44l0.06-0.86l-0.01-1.69l-0.01-2.05l-0.01-1.83l0.02-1.58l-0.16-0.75l-0.13-0.41l-0.36-0.84
  		l-0.42-1.74l-0.49-1.31l-0.64-0.87l-0.2-0.46l-0.21-0.61l-0.6-1.07l-0.49-0.62l-0.13-0.5l0.02-1.29l-0.55-2.32l-0.4-1.69l-0.6-1.83
  		l-0.4-1.22l-0.06-0.21l-0.04-0.46l-0.76-0.5l-1.04-0.33l-1.33-0.15l-1.72,0.06l-2.12,0.27l-2.27-0.33l-2.43-0.94l-2.02-0.33
  		l-2.41,0.41l-0.11,0.01l-1.98,0.17l-0.98,0.57l-0.57,1.03l-0.89,0.76l-1.22,0.48l-1.29,0.33l-1.36,0.18l-1.45,0.88l-1.53,1.59
  		l-0.78,1l-0.03,0.41l-0.34,0.36l-0.66,0.3l-0.37,0.36l-0.09,0.42l-0.35,0.2l-0.61-0.02l-0.43,0.31l-0.25,0.64l-0.54,0.39
  		l-0.84,0.13l-0.72,0.36l-0.61,0.58l-0.47,0.3l-0.33,0.01l-0.51,0.47l-0.83,1.12l-0.14,0.52l-1.14,4.24l-0.62,0.5l-1.21,0.87
  		l-0.98,1.05l-0.42,0.62l-0.46,0.27l-2.24,0.52l-0.83,0.28l-1.01,0.4l-0.26,0.36l-0.25,1.31l-0.7,1.88l-0.57,1.39l-0.37,1.21
  		l-0.64,1.51l-0.55,0.51l-0.63,0.46l-0.82,0.23l-1.12,0.15l-1.02-0.04l-0.79,0.02l-1.09,0.53l-1.02,0.04l-1.62-0.31l-1.31-0.3
  		l-0.59-0.06l-1.16-0.98l-0.75,0.02l-1.14-0.08l-0.64-0.23l-0.59-0.5l-1.29-0.99l-1.26-0.8l-1.11-0.47l-1.04-0.22l-0.99,0.2
  		l-0.77,0.21l-0.3,0.11l-0.6,0.41l-0.61,0.78l-0.5,1.23l-0.19,0.75l-0.56,1.6l-0.75,1.92l-0.36,0.55l-0.41,0.41l-0.65,0.36
  		l-2.13,1.52l-1.07,1.72l-0.67,0.5l-0.81,0.23l-0.69,0.15l-0.38,0.29l-0.42,0.87l-0.37,0.31l-0.41,0.12l-1.22-0.1l-0.39-0.09
  		l-3.23,0.17l-0.99-0.28l-0.7-0.11l-1.1,0.36l-0.46-0.24l-0.37-0.72l-0.18-1.45l0.06-1.23l0.6-0.93l0.5-0.68l0.49-0.89l0.06-0.4
  		l-0.1-0.36l-0.1-0.73l-0.05-0.75l-0.69-1.63l-0.85-2.16l-1.15-2.41l-0.36-0.66l-0.72-1.05l-2.67-1.99l-0.41-0.27v3.75v3.98v3.98
  		v3.99v4v4.01v4.02v4.04v1.28l-0.86-0.02l-1.74,0.5l-1.11,0.64l-0.48,0.79l-0.64,0.48l-0.8,0.17l-0.35,0.4l0.09,0.64l-0.31,0.49
  		l-0.71,0.34l-1.14-0.1l-1.58-0.54l-2.01-0.13l-2.44,0.28l-1.75-0.13l-1.07-0.55l-1.13-0.31l-1.2-0.08l-0.7-0.23l-1.42-0.41
  		l-0.27-0.7l-0.17-0.53l-0.4-0.56l-0.04-0.45l0.32-0.34l0.05-0.55l-0.23-0.76l-0.39-0.37l-0.56,0.02l-0.35-0.29l-0.14-0.6
  		l-0.33-0.46l-0.78-0.46l-1.04,0.35l-0.49,0.53l-0.29,0.83l-0.26,0.41l-0.13,0.69l-0.06,0.49l-0.27,0.52l-0.28,0.21l-0.28-0.1
  		l-0.53,0.21l-1.17,0.77l-0.33,0.41l0.28,0.22l2.18,3.35l1.77,3.6l2.01,6.38l1.33,3.17l2.78,5.3l2.2,3.37l1.9,2.52l0.4,0.81
  		l0.84,3.53l0.16,1.37l-0.04,2.2l-0.63,1.37l-1.06,0.91l-0.75,0.24l-0.6-0.62l-0.59,0.39l-0.37,0.72l0.23,1.25l0.68,0.79l0.29,0.99
  		l0.69,0.52l0.69,1.42l0.88,0.59l0.41,0.87l1.04,1.91l0.2,0.75l0.07,0.86l-0.93,0.48l-0.18,1.28l0.16,1.08l0.49,1.01l0.43,0.48
  		l0-1.69l0.32-0.56l0.28-0.22l0.6-0.08l0.87-0.05l0.37,0.1l0.48,0.24l0.15,0.76l0.04,0.62l-0.05,0.4l0.05,0.64l0.59-0.03l0.43-0.16
  		l1.23,0.06l0.43,0.63l0.8-0.04l0.29,0.23l0.43,0.52l-0.06,0.74l-0.21,0.42l0.79-0.09l2.05,1.4l1.81,0.03l0.64,0.17l0.79,0.1
  		l3.49-2.63l0.8-0.43l2.06-0.22l0.91-0.51l0.9-0.18l0.59-0.03l1.59,0.4l0.85,0.01l1.71-0.33l1.99,0l3.85-2.87l1.42-0.14l1.17-0.41
  		l1.53,0l1.6,0.5l2.89,0.17l0.69-0.12l1.98-0.79l0.95,0.07l4.09,0.65l3.47,1.07l1.95-0.05l0.66-1.03l0.82-0.81l1.4-0.12l2.59,0.64
  		l0.82,0.07l0.54-0.23l0.12-1.52l1.29-1.06l1.55-0.24l3.71,0.45l1.55-0.49l3.91-1.75l2.41-1.51l3.35-2.48l0.83-0.39l2.98-2.66
  		l1.98-1.35l3.42-3.08l2.29-2.71l2.99-3.05l2.12-1.89l0.8-0.43l1.19-0.94l2.67-3.25l1.55-2.36l1.61-2.58l1.8-3.34l1.23-1.56
  		l1.23-2.84l1.39-1.95l3.73-4.03l0.95-0.23l0.54-0.26l0.61-0.4l2.18-1.99l0.75-1.12l1.34-2.71l1.03-5.37l0.41-1.5l1.21-3.26
  		l0.31-2.08l-0.92,0.02L2084.18,1548.93z M2057.21,1571.29l-0.32,1.12l-0.47,1.14l-0.37,0.48l-0.46,0.3l-0.45,0.45l-0.17,0.92
  		l-0.2,1.08l-0.58,0.45l-0.45,0.29l-0.62,0.36l-1.38,0.58l-0.76,0.23l-0.11,0.02l-0.49-0.05l-0.65,0.05l-0.51,0.12l-0.4,0.05
  		l-0.65,0.65l-1.17,1.76l-0.31,0.37l-0.09,0.69l-0.27,0.55l-0.33,0.43l-0.33,0.1l-0.98-0.17l-1.25-0.22l-0.73-0.53l-0.65-0.7
  		l-0.34-0.51l-0.36-0.28l-0.12-0.16l-0.51-0.23l-0.19-0.12l-0.17-0.09l-0.21-0.34l-0.12-0.29l0.05-0.82l-0.36-0.49l-0.61-0.83
  		l-0.39-0.68l-0.53-0.93l-0.33-0.79l-0.34-0.82l0.04-0.35l0.32-0.24l0.95-0.41l0.73-0.32l0.52-0.59l0.57-0.87l0.28-0.52l0.28-0.24
  		l0.3-0.37l0.53-0.82l0.59-0.91l0.63-0.98l0.8-0.28l1.09-0.33l1.05-0.85l1.25-0.72l2.01-0.78l0.94-0.2l0.36-0.11l0.23,0.15
  		l0.24,0.45l0.34,0.37l0.8,0.65l0.34,0.16l0.82,0.96l0.88,0.66l1.01,0.76l0.69,0.38l0.35,0.11l0.29,0.67l0.29,0.5l0.17,0.47
  		L2057.21,1571.29z"/>
    </>,
    MYT: <>
        <polygon className="st0" points="2190.05,1425.58 2189.85,1425.55 2189.49,1425.07 2189.07,1425.48 2189.5,1426.2 2189.46,1426.62
  		2189.3,1427.14 2189.45,1427.67 2189.7,1427.9 2190.23,1427.83 2190.22,1427.35 2190.47,1426.73 2190.43,1426.53 2190.59,1425.92
  		"/>
    </>,
    YEM: <>
        <path className="st0" d="M2169.64,1202.85l-0.38,0.68l0.56-0.26l0.32-0.53l-0.11-0.03L2169.64,1202.85z M2168.24,1188.71l-0.17,0.75
  		l0.08,0.33l0.27-0.19l0.17-0.25l0.12-0.31l-0.19-0.55L2168.24,1188.71z M2169.29,1200.68l0.52,0.45l0.15,0.04l0.11-0.18l0.09-0.35
  		l-0.31-0.47L2169.29,1200.68z M2255.79,1175.79l-0.51-1.14l-0.51-1.14l-0.36-0.8l-0.6-0.28l-0.36-0.84l-0.37-0.86l-0.37-0.86
  		l-0.37-0.86l-0.37-0.86l-0.37-0.86l-0.37-0.86l-0.37-0.86l-0.37-0.86l-0.37-0.86l-0.37-0.86l-0.37-0.86l-0.37-0.87l-0.37-0.87
  		l-0.37-0.87l-0.37-0.87l-0.37-0.87l-1.98,0.27l-1.92,0.27l-2.16,0.3l-2.6,0.36l-2.04,0.28l-2.97,0.41l-2.66,0.37l-2.5,0.35
  		l-2.5,0.35l-2.13,0.29l-1.27,0.34l-1.49,0.75l-2.29,1.15l-2.33,1.17l-1.21,0.61l-1.27,1.56l-0.64,0.79l-1.16,1.42l-0.88,1.07
  		l-1.05,1.28l-0.46,1.14l-0.7,1.76l-0.61,0.44l-1,0.57l-0.91,0.41l-1.41-0.06l-0.81-1.08l-0.85-1.15l-0.43-0.46l-0.38-0.03
  		l-1.42,0.15l-1.71,0.18l-2.02-0.19l-2.33-0.22l-2.18-0.2l-1.08-0.15l-1.43-0.75l-0.37-0.15l-0.38-0.03l-1.7-0.02l-1.68-0.02
  		l-1.69,0.24l-1.62-0.09l-1.67,0.14l-0.59,0.28l-0.65-0.02l-0.41,0.25l-0.36,0.12l-0.43-0.21l-0.52,0.05l-0.77-0.18l-0.5-0.49
  		l-0.47-0.43l-0.49-0.24l-0.55-0.15l-0.47,0l-0.61,0.26l-0.37,0.25l-0.94,0.84l-0.04,0.3l0.43,0.5l-0.13,0.23l-0.55,0.3l-0.17,0.79
  		l-0.08,0.44l-0.08,1.04l0.25,0.82l0.33,0.3l0.02,0.35l-0.18,0.7l-0.51,0.22l-0.37,0.67l-0.23,0.31l-0.4,0.36l-1.57,1.18l0.34,2.92
  		l-1.03,3.24l0.7,2.43l-0.01,0.39l-0.09,0.52l-0.44-0.24l-0.32-0.28l-0.34,0.8l1.67,0.86l0.35,1.09l0.33,0.57l0.01,0.35l-0.2,0.3
  		l0.08,0.39l0.21,0.38l0.62,1.87l-0.02,0.29l-0.11,0.32l0.32,1.21l0.41,1.18l-0.04,1.64l1.22,1.3l0.41,1.42l0,0.45l-0.43,3.18
  		l2.05,3.64l0.1,1.19l1.24-0.39l1.69,0.6l0.8,0.49l0.64,0.08l0.89-0.26l1.25-0.05l0.83-0.21l2.18-1.26l1.16,0.45l1.13-0.17
  		l1.25-0.27l0.6-1.04l0.46-0.51l1.93-0.58l1.18-1.42l1.04-0.9l2.21-0.47l2.39-0.25l2.52,0.07l1.36-0.15l1.06-0.28l1.57-0.7
  		l2.25-0.53l1.39-0.45l1.9-1.68l1.87-0.84l0.51-0.48l0.62-0.3l2.42,0.43l1.44-0.07l1.22-0.34l0.63-0.03l0.94-0.63l1.25-1.23
  		l0.64-0.75l0.37-0.87l0.46-0.37l2.08-1.18l1.67-0.72l3.01-0.9l2.19-0.2l1.45-0.65l1.59-0.95l4.11-0.88l2.59-0.73l2.37-0.95
  		l0.66-0.36l0.56-0.52l0.69-0.16l1.14-0.66l1.02-0.43l1.1-0.6l0.04-0.9l-0.41-1.68l0.53-1.84l0.76-1.05l1.02-0.84l1.12-0.68
  		l4.25-1.53l-0.51-1.13L2255.79,1175.79z M2266.09,1212.12l-2.26,0.04l-0.79,0.29l-0.52-0.1l-1.05-0.6l-0.87-0.07l-0.88,0.44
  		l-0.23,0.26l-0.13,0.27l-0.61,0.58l1.55,0.92l0.83,0.71l1.02,0.2l3.46-0.35l1.19-0.73l1.2-0.31l0.31-0.34l0.51-0.25
  		L2266.09,1212.12z"/>
    </>,
    XKX: <>
        <polygon className="st1" points="1992.67,937.72 1992.15,937.72 1989.86,937.01 1989.97,936.2 1989.3,935.76 1988.57,935.37
  		1988.45,934.93 1987.64,934.05 1987.05,933.55 1986.3,933.3 1985.65,932.94 1985.26,932.72 1985.09,932.3 1985.09,932.06
  		1984.89,931.82 1984.58,931.85 1984.05,932.18 1983.4,932.46 1983.29,932.67 1983.52,933.16 1983.69,933.47 1983.61,933.76
  		1983.41,934.14 1982.15,934.96 1982.01,935.25 1982.25,935.72 1982.1,935.93 1981.05,936.24 1979.96,936.53 1979.77,936.98
  		1979.25,936.93 1978.61,936.93 1978.4,937.21 1978.7,937.68 1978.9,938.23 1978.74,938.99 1978.69,939.08 1979.02,939.31
  		1979.72,940.32 1980.18,941.19 1981.08,941.5 1981.59,941.84 1982.24,942.36 1982.55,942.89 1983,944.49 1983.05,945.45
  		1982.92,945.89 1983.02,945.97 1984,946.09 1984.26,945.89 1984.42,945.58 1984.47,944.43 1984.7,943.9 1987.08,942.89
  		1987.77,942.85 1988.31,943.32 1988.73,943.62 1988.99,943.61 1989.08,943.31 1989.37,942.73 1989.85,942.4 1991.3,942.12
  		1991.31,942.12 1991.14,941.78 1990.94,941.3 1991.04,941.08 1991.71,940.7 1991.79,940.34 1992.73,938.6 1992.91,938.03
  		1992.92,937.84 	"/>
    </>,
    WSM: <>
        <path className="st0" d="M3390.32,1431.99l-1.49,0.15l-1.34,0.35l-0.92-0.06l0.29,0.53l0.72,0.56l1.04,1.25l0.43,0.07l1.29-0.22
  		l0.89,0.25l0.41-1.02l-0.38-1.07L3390.32,1431.99z M3397.67,1436.35l-0.38-0.24l-0.49-0.06l-0.32-0.54l-2.14-0.61l-1.07,0.15
  		l-0.51,0.28l0.15,0.42l0.98,0.81l0.41,0l1.14,0.39l2.31-0.01l0.04-0.2L3397.67,1436.35z"/>
    </>,
    WLF: <>
        <path className="st0" d="M3341.4,1438.62l-0.3-0.09l-0.14,0.2l0.3,0.48l0.47,0.11l0.48-0.06l0.03-0.13l-0.52-0.16L3341.4,1438.62z
  		 M3358.19,1429.91l-0.2,0.18l-0.2,0.5l0.16,0.33l0.14-0.07l0.27-0.55L3358.19,1429.91z"/>
    </>,
    VUT: <>
        <path className="st0" d="M3217.95,1448.89l-0.16-0.46l-0.42-2.18l-0.21-0.08l-0.32-0.46l-0.12-0.83l0.18-0.33l-0.41-0.11l-0.33,0.15
  		l-0.17,1.04l-0.37,0.66l-0.32,0.15l-0.63,0.01l-0.54-2.83l-0.7-0.79l-0.46-0.84l-0.34,0.04l-0.35,1.01l0.01,0.77l1.02,3.09
  		l-0.14,1.67l0.57,0.94l0.51,0.44l-0.01,0.55l0.57,0.03l0.93-0.49l1.32,0.02l0.89-0.82L3217.95,1448.89z M3219.95,1437.98
  		l-0.24,0.25l-0.06,0.72l0.23,0.11l0.95,0.14l0.35-0.43l0.12-0.66l-0.78-0.35L3219.95,1437.98z M3218.1,1451.3l0.13-0.68l-0.97-0.19
  		l-0.21,0.54l0.89,0.56L3218.1,1451.3z M3226.13,1448.44l0.15-0.53l-0.02-1.13l-0.43-1.8l-0.18,0.02l-0.09,0.24l0.22,2.59
  		L3226.13,1448.44z M3220.25,1435.84l0.12-0.08l0.08-0.19l0.37-0.1l0.09-0.23l0-0.27l-0.05-0.32l-0.56-0.57l-0.62,0.33l-0.13,0.34
  		l0.5,1.03L3220.25,1435.84z M3226.03,1449.05l-0.31,1.88l0.48,2.1l0.14,0.39l0.14-0.01l0.37-0.13l0.1-0.54l-0.71-3.29
  		L3226.03,1449.05z M3222.79,1456.59l-0.52-0.23l-0.28-0.46l-0.33,0.02l-1.21-0.83l-0.13-0.42h-0.21l-0.39-0.19l-0.65-1.54
  		l-0.69-0.34l-0.46,0.07l-0.14,0.37l-0.27,1.3l0.56,0.64l0.24-0.05l0.59-0.29l0.28,0.33l0.26,0.78l0.17,1.33l0.39,0.87l-0.09,0.11
  		l0.11,0.34l0.65,0.17l0.72-0.65l1.25,0.15l0.65-0.57l-0.37-0.47L3222.79,1456.59z M3235.19,1479.41l0.34,0.05l0.39-0.42l-0.32-0.64
  		l-0.8-0.62l0.46-0.28l-0.65-0.33l-0.29-0.82l-0.47-0.12l-0.61,0.23l-0.24,0.55l0.09,1.02l-0.09,0.4L3235.19,1479.41z
  		 M3223.22,1447.76l-1.28,1.2l0.39,0.22l1.04,0.04l0.57-0.39l0.77-1.31L3223.22,1447.76z M3240.28,1489.54l-0.19-0.03l-0.66,0.07
  		l-0.11,0.43l0.59,0.34l0.46,0.01l0.3-0.48L3240.28,1489.54z M3235.94,1482.41l-0.38-0.07l-0.37,0.2l-0.25,1.14l0.37,0.6l0.72,0.68
  		l0.77,0.22l0.44-0.95l-1.11-0.72L3235.94,1482.41z M3227.18,1456.55l0.01-0.32l-0.19-0.29l-0.34-0.29l-0.31-0.96l-0.29-0.33
  		l-0.84,0.86l-0.67,0.13l-0.47,0.28l0.24,0.37l0.54,0.37l1.35,0.27L3227.18,1456.55z M3226.47,1460.59l0.93-0.16l1.16,0.41
  		l0.14-0.36l-0.25-0.13l-0.68-0.17l-0.59-0.64l-0.26-0.12l-0.27-0.27l-0.29-0.39l-0.15,0.05l-0.39,0.32l-0.09,0.46l0.2,0.65
  		l0.28,0.33L3226.47,1460.59z M3228.43,1466.94l-0.88,0.09l-0.18-0.07l-0.19,0.01l-0.21,0.06l-0.69,0.8l-0.28,0.57l0.2,0.06
  		l0.43-0.16l0.38,0.07l0.24,0.34l-0.46,0.3l1.24,0.23l1.05-0.08l0.51-0.88l-0.33-0.1L3228.43,1466.94z"/>
    </>,
    VNM: <>
        <path className="st0" d="M2731.29,1211.66l0.03-2.02l-0.4-0.59l-0.57-1.65l-0.32-0.51l0.14-1.46l-0.3-1.19l-0.04-2.25l0.35,0.76
  		l0.13-0.78l-0.49-1.68l-0.17-0.37l-0.14-0.49l-0.13-0.99l-0.46-0.97l-0.42-1.44l-0.02-1.4l-0.53-0.74l-0.7-1.7l-0.35-1.53
  		l-0.65-1.69l-0.66-0.42l-0.58-0.49l-0.81-0.87l-1.1-1.53l-0.44-0.94l-0.92-1l-0.1-0.34l-0.06-0.52l-0.22-0.09l-0.27,0.08
  		l-0.33-0.62l-0.69-0.68l-0.49-0.76l-0.33-0.05l-0.46,0.07l-0.46,0.17l-0.41-0.11l-0.26-0.69l-0.67-0.73l-1.1-0.69l-0.44-0.35
  		l0.07-0.29l-1.64-1.3l-1.47-0.9l-0.51-1.36l-1.63-1.43l-1.6-1.26l-1.84-2.55l-1.23-0.73l-0.12-0.16l1.04,0.39l-0.16-1.33l0.33-0.63
  		l-0.73-0.92l-1.45-1.45l-0.8-0.34l-0.66-0.49l-1.49-1.61l-0.41-0.62l-0.26-0.62l-0.54-0.87l-0.1-0.29l-0.93-1.62l0.15-0.79
  		l0.65-0.61l0.63-1.45l-0.05-0.74l0.23-0.77l0.02-1.05l1.43-3.06l0.66-0.42l0.87-0.04l1.94-1.86l1.03-0.72l0.46-0.9l-0.19-1.17
  		l1.71-1.82l-0.14-0.62l-0.52-1.35l0.07-0.35l0.35,0l0.29,0.08l0.51,0.29l0.55,0.07l0.42-0.21l0.38,0.02l0.32-0.17l0.47-0.07
  		l0.31,0.35l0.45,0.09l1.6-0.93l0.16-0.64l0.02-0.58l0.28-0.79l0.99-0.45l0.92-0.28l0.59-0.33l0.86-0.8l0.98-0.02l0.39-0.08
  		l-0.54-0.46l-0.9-0.74l-0.36-0.09l-1,0.36l-1.43,0.14l-0.32-0.39l-0.69,0.29l-0.67-0.89l-0.79-0.06l-0.98-0.68l-0.35-0.36
  		l-0.11-0.51l-0.3-0.27l-0.39,0.03l-0.43-0.27l-0.68-0.27l-0.54-0.16l-0.27,0.12l-0.29,0.06l-0.05-0.35l0.02-1.04l-0.05-0.92
  		l-0.15-0.42l-0.37-0.32l-0.33-0.15l-0.15-0.47l0.12-0.93l0.27-0.63l0.43-0.11l0.58-0.46l0.29-0.65l0.37-0.6l-1.32-0.84l-0.69-0.3
  		l-0.77,0.13l-0.95,0.27l-0.5,0.05l-0.25-0.11l-0.55-0.76l-0.3-0.13l-0.67-0.05l-0.57,0.01l-0.33,0.33l-0.5,0.11l-0.5,0.02
  		l-0.51-0.41l-0.77-0.53l-1.21-0.38l-0.15-0.44l-0.31-0.52l-0.46-0.48l-0.75-0.64l-0.63-0.33l-0.31,0.2l-0.42,0.36l-1.63,0.77
  		l-0.72,0.3l-0.38,0.21l-0.32,0.32l-0.1,0.79l-0.16,0.88l-0.47,0.45l-0.44,0.34l-0.47,0.04l-0.46-0.02l-0.43,0.14l-1.31,0.88
  		l-0.62-0.07l-0.51-0.5l-0.22-0.36l-0.58,0.08l-0.75,0.42l-0.35,0.76l-0.18,0.71l-0.16,0.31l-0.25,0.09l-0.22,0.02l-2.34-2.04
  		l-0.14-0.11l-0.42,0.42l-0.38,1.08l-0.27,0.21l-0.19-0.08l-0.97-1.39l-0.25-0.13l-0.22,0.05l-0.29,0.45l-0.61,0.66l-0.47,0.4
  		l-0.01,0.45l-0.51,0.39l-0.59,0.39l-0.2,0.04l-0.39-0.16l-0.51-0.52l-0.37-0.54l-0.92-0.54l-1.03-0.46l-0.69-0.36l-0.39-0.09
  		l-0.36,0.16l-0.18,0.22l-0.26,0.54l-0.62,0.89l-0.55,0.7l-0.51,0.45l-0.41,0.31l0.47,0.84l1,0.93l1.19,1.33l0.38,0.61l0.8,0.47
  		l0.23,0.46l0.18,0.71l0.08,0.53l0.18,0.31l0.28,0.12l0.36-0.14l0.27-0.28l0.23-0.77l0.15-0.08l0.25,0.64l0.26,0.11l0.35,0.08
  		l0.27,0.28l0.08,0.48l-0.09,0.49l-0.33,0.56l-0.19,0.58l-0.13,0.9l-0.18,0.63l0.27,0.55l1.86,2.72l0.9,0.45l2.13,0.53l0.77,0.37
  		l0.68,0.36l0.67-0.17l0.64-0.81l0.77-0.45l1.43-0.7l0.42-0.04l0.79,0.28l1.3,0.81l0.94,0.76l0.58,0.4l0.45,0.36l-0.07,0.41
  		l-0.36,0.4l-0.46,0.22l-0.6,0.38l-0.34,0.39l0.21,0.15l0.88,0.1l1.03,0.34l0.32,0.4l0.05,0.34l0.13,0.56l0.18,0.17l0.96-0.1
  		l0.3,0.13l0.34,0.29l0.34,0.75l-0.01,0.56l-0.69,0.62l-0.26,0.38l-0.11,0.59l-0.49,0.71l-1.31,1.18l-0.35,0.07l-2.41-0.65
  		l-1.12,0.04l-0.54,0.02l-0.26,0.03l-0.16,0.25l0.32,0.72l0.09,0.71l-0.3,0.54l-0.8,0.47l-0.3,0.23l-0.04,0.3l0.23,0.32l0.74,0.33
  		l0.86,0.31l2.84,1.84l0.61,0.43l0.8,0.64l0.87,0.5l2.33,0.65l1.03,0.43l0.26,0.24l-0.01,0.3l-0.27,0.38l-0.22,0.67l-0.01,0.4
  		l0.24,0.39l0.41,0.58l0.93,0.89l0.51,0.4l0.56,0.09l0.49,0.22l0.51,0.66l0.59,0.81l0.08,0.57l0.25,0.72l0.54,0.83l0.74,0.81l1.04,1
  		l0.6,0.7l0.28,0.27l2.22,1.71l0.54,0.63l0.78,1.22l0.33,0.18l0.31,0.24l0.2,0.67l0.06,0.48l0.1,1.47l0.4,0.44l0.37,0.53l0.16,0.39
  		l0.33,0.29l0.37,0.06l0.44-0.33l0.34-0.31l0.16,0.09l0.35,1.02l0.32,0.37l0.6,0.36l0.57,0.27l1.24,1.23l0.67,0.45l0.45,0.14
  		l0.39,0.21l0.12,0.39l-0.15,0.4l-0.26,0.26l-1.45,0.71l-0.19,0.31l0.2,0.47l0.36,0.59l0.39,0.51l0.5,0.5l1.02,0.81l0.88,0.64
  		l0.48,0.7l0.26,0.47l-0.16,0.56l-0.37,0.61l-0.29,0.53l-0.5,0.3l-0.13,0.36l0.21,0.55l0.17,0.38l-0.09,0.47l0.05,0.96l0.13,0.47
  		l-0.35,0.89l-0.38,0.81l-0.71,0.71l-0.03,0.52l-0.24,1.55l0.09,0.49l0.16,0.42l0.23,0.23l0.61,1.51l0.56,1.38l0.55,1.13l0.1,0.71
  		l-0.51,1.81l-0.59,1.66l0.05,0.83l0.25,0.83l0.27,1.1l0.1,1.41l-0.15,0.92l-0.27,0.57l-0.51,0.59l-0.44,0.3l-0.53-0.5l-0.42-0.02
  		l-0.57,0.15l-0.45,0.23l-0.91,0.86l-1.01,0.84l-1.4,0.21l-0.54,0.62l-0.58,0.09l-1.11,0.03l-0.72,0.15l0.03,0.31l-0.06,1.47
  		l0.01,0.34l-0.11,0.09l-0.5,0.04l-0.85-0.22l-1.15-0.36l-0.82-0.06l-0.42,0.64l-0.25,0.25l-0.31,0.04l-0.32,0.11l-0.11,0.29
  		l-0.03,0.36l0.16,0.61l0.06,0.97l-0.04,0.66l0.3,0.42l1.75,1.41l0.52,0.35l0.06,0.21l-0.31,0.76l0.27,1.08l-0.55-0.02l-0.91-0.46
  		l-0.44-0.28l-0.53,0.23l-0.18-0.04l-0.36-0.53l-0.47-0.54l-0.48-0.03l-1.02,0.21l-1.04,0.15l-0.4,0l-0.16,0.1l-0.61,0.8l-0.26-0.14
  		l-1.05-0.31l-0.96-0.12l-0.2,0.21l0.12,0.66l0.21,0.64l-0.12,0.27l-0.53,0.34l-0.7,0.61l-0.43,0.47l-0.3,0.12l-1.06-0.02
  		l-1.06,0.06l-0.42,0.45l-0.4,0.35l-0.34,0.09l0.76,0.6l0.66,0.62l0.16,0.5l0.43,0.32l0.71-0.25l0.46-0.03l0.6,0.74l0.78,0.12
  		l0.52,0.28l0.48,0.61l0.09,0.43l-0.02,0.37l-0.89,0.27l-0.71,0.44l-0.49,1.77l-0.26,3.55l0.03,3.24l0.65,0.47l-1.06,1.26l1.02,0.12
  		l1.87-0.39l0.65-0.69l1.1-0.76l0.67-1.36l0.84-1.1l5.62-2.56l0.2-0.43l0.11-0.46l-0.4-0.78l-0.39-0.67l-2.37-2.76l0.8,0.33
  		l2.34,2.42l1.47,1.01l0.89-0.03l0.46-0.37l0.28-0.32l-0.07-0.63l-0.48-0.89l-2.73-2.71l-0.4-0.67l2.63,2.38l0.91,0.6l0.32,0.07
  		l0.52-0.35l0.01-0.4l0.01-0.36l0.46-0.58l0.6-0.48v-0.29l-0.47-0.36l-1.07-0.32l-1.17-0.56l0.23-0.05l1.28,0.13l0.96-0.06
  		l0.16-0.36l0.01-0.31l-0.31-0.58l-0.36-0.15l-0.47,0.05l-0.31-0.07l1.02-0.6l0.72,0.87l0.75,0.43l0.38-0.15l0.16-0.34l0.15-1.5
  		l0.19-0.36l0.12,0.25l0.13,0.63l0.44,0.49l0.9,0.23l0.34,0.44l0.22,0.18l1.04-0.51l0.72-0.23l0.79-0.59l2.36-1.22l1.32-0.17
  		l0.79-1.5l0.68-0.19l0.8-0.12l1.24-0.9l1.12-0.98l1.26-0.37l1.01-0.98l1.4-0.18l0.27-1.12l0.18-1.05l0.78-0.07l0.34-0.54l0.21-0.51
  		l-0.05-0.41l-0.3-0.54l0.08-0.64l0.27-0.51l-0.01-0.23l0.13-0.1l0.05,0.44l0.23,0.43l0.1-0.39l-0.02-0.33l-0.34-0.68l-0.07-2.91
  		l0.82,0.21l-0.72-2.16l0.46-0.54l0.52-0.36l0.39,0.69l0.53,0.6L2731.29,1211.66z M2707.27,1245.67l0.18,0.17l0.24-0.02l0.3-0.15
  		l-0.03-0.18l0.08-0.37L2707.27,1245.67z M2712.18,1231.15l-0.61,0.28l0.05,0.43l0.71-0.51l0.23-0.41l-0.15,0L2712.18,1231.15z
  		 M2685.87,1231.08l-0.35,0.01l-0.74,0.49l-0.41-0.02l0.16,0.3l0.71,0.78l0.56,1.81l0.25-0.27l-0.1-0.42l0.33-0.96l0.06-0.98
  		l-0.16-0.42L2685.87,1231.08z M2714.27,1141.16l0.56,0.03l0.47-0.23l0.25-0.48l0-0.46l-0.27,0.19l-0.34,0.53L2714.27,1141.16z
  		 M2710.16,1141.85l0.67,0.71l0.35-0.03l0.09-0.12l0.18-0.34l0-0.15l-0.17-0.17l-0.76-0.26L2710.16,1141.85z M2715.65,1138.38
  		l-0.73-0.43l-0.2,0.29l-0.41,1.24l0.47,0.02l1.21-1.1L2715.65,1138.38z M2708.97,1141.5l0.28,0.18l0.43,0.41l0.1-0.17l-0.09-0.37
  		l-0.5-0.61L2708.97,1141.5z"/>
    </>,
    VIR: <>
        <path className="st0" d="M1264.55,1163.56l0.56-0.23l-0.78-0.15l-0.3,0.27l0.14,0.08L1264.55,1163.56z M1264.92,1168.56l-0.71-0.38
  		l-1,0.19l-0.04,0.61l1.71-0.04l0.89-0.38H1264.92z M1263.17,1163.16l-0.45-0.09l-0.69,0.15l0.87,0.4l0.63-0.08L1263.17,1163.16z"/>
    </>,
    VGB: <>
        <path className="st0" d="M1265.18,1162.57l-0.37,0.27l0.2,0.11l0.66-0.03l0.41-0.31l-0.2-0.07L1265.18,1162.57z M1268.24,1159.99
  		l-0.3-0.11l-0.74,0.01l0.08,0.11l0.15,0.05l0.37,0.02l0.48,0.2l0.07,0L1268.24,1159.99z M1266.92,1162.3l0.05,0.12l0.14,0.01
  		l0.22-0.06l0.59-0.46l-0.85,0.04L1266.92,1162.3z"/>
    </>,
    VEN: <>
        <path className="st0" d="M1268.82,1226.74l0.49-0.15l0.5,0.49l0.39,0.14l0.52,0.03l0.64-0.05l0.76-0.75l0.08-0.21l-0.27-1.11
  		l-0.37-0.31l-0.96,0.84l-0.18,0.56l-0.71-0.03l-0.61-0.32l-0.24-0.37l-0.3,0.05l-0.83,0.24l-0.45,0.6l0.34,0.17L1268.82,1226.74z
  		 M1296.64,1242.55l-0.24,0.13l-0.89,0.68l-0.16,0.23l0.08,0.85l0.52-0.17l0.69-0.27l0.46-0.42l0.1-0.39l-0.43-0.31l0.05-0.18
  		L1296.64,1242.55z M1297.24,1241.53l-0.53,0.11l-0.27,0.39l-0.02,0.23l1.01-0.28l0.26-0.33l0.46-0.22l-0.19-0.11l-0.19-0.02
  		l-0.34,0.09L1297.24,1241.53z M1260,1227.23l0.45-0.19l-0.12-0.2l-0.64-0.37h-0.68l-0.26,0.3l0.42,0.27L1260,1227.23z
  		 M1305.77,1249l-1.57-2.05l-1.26-0.57l-1.45-0.1l-0.54,0.16l-0.65,0.53l-2.69-0.38l-0.54,0.11l-1.44,0.72l-1.33,0.05l-0.93,0.65
  		l-1.17-0.83l-0.71-0.32l-0.77-0.43l3.13-0.02l0.6-1.05l0.45-0.99l0.2-0.83l0.05-0.21l0.33-0.59l0-0.51l0.25-0.5l0.44-0.51l1.1-0.41
  		l0.41-0.82l-0.69-0.78l-1.17-0.87l-1.86-0.35l-0.63-0.3l-1.71-1.81l-0.64-0.4l-0.31,0.66l-1.18,0.02l0.06,0.5l0.23,0.44l-0.04,0.6
  		l-0.19-0.38l-0.39-0.24l-0.22-0.23l-0.05-0.41l-0.6-0.74l-0.91-0.72l-0.51-0.17l-0.36-0.08l-0.3,0.05l0.07,0.22l-0.19,0.62
  		l0.14,0.49l-0.16-0.04l-0.16-0.13l-0.26-0.34l-0.3,0.54l-0.2,0.22l-0.16,0.04l-0.17,0.04l-0.68-1.14l-0.96-2.18l-0.3-0.21
  		l-0.42-0.14l-0.06,0.46l0.06,0.39l-0.43,0.39l-0.75,0.12l0.39-0.37l0.28-0.83l-0.2-0.77l-0.17-0.37l-0.63-0.56l-0.27-0.01
  		l-0.25-0.15l0-0.76l-0.59-0.21l1.85-0.27l2.64,0.14l1.24-0.74l1.62-0.1l1-0.31l0.35-0.5l-3.06,0.35l-3.87-0.43l-2.06,0.36
  		l-0.75-0.11l-1.3,0.09l-2.58,0.56l-3.17-0.17l-2.77,0.26l-0.81-0.02l0.43,0.78l0.75-0.31l2.49,0.18l1.1,0.46l-0.4,0.27l-0.46,0.2
  		l-2.99-0.08l-5.58,3.04l-0.79,0.03l-0.67,0.16l-0.89,0.06l-1.59-0.44l-1.45-0.31l-1.4-0.58l-1.65-0.25l-2.01-1.82l-0.01-0.37
  		l-0.12-0.49l-1.19-0.49l-6.25,0.18l-1.22,0.34l-3.77,0.4l-2.44,0.44l-2.26-0.17l-0.79-0.65l-0.52-1.02l-0.24-1.01l0.44-0.6
  		l-0.44-0.59l-0.15-0.88l-0.47-0.92l-1.83-1.26l-1.78-1.04l-1.91-0.25l-1.5-0.49l-2.47,0.16l-0.37,0.12l-0.52,0.05l-0.68-0.71
  		l-0.43-0.95l-0.41-1.36l-0.17-1.35l-0.25-0.5l-0.46-0.51l-0.75-0.54l-0.99,0.35l-0.68,0.32l-0.36,0.81l-0.35,1l0.56,1.32l0,0.42
  		l0.23,0.48l2.37-0.4l0.79,0l0.37,1.11l-0.27,0.57l-0.68,0.25l-1.37-0.73l-0.41,0.09l-0.53,0.78l-0.61,0.47l-2.64,0.95l-2.32,0.45
  		l-3.74,1.77l-1.73,0.3l0.07,1.09l-0.7,0.48l0.23,1.33l0.2,0.75l0.26,0.54l0.64,1.74l1.05,1.02l0.46,1.09l1.06,1.53l0.24,1.08
  		l-0.22,1.65l-0.06,1.37l-1.01,1.06l-0.3,0.52l-0.48,0.29l-2.01,0.65l-0.7,0l-0.57-0.21l-0.45-0.52l-0.35-0.98l0.17-0.72l-0.38-0.43
  		l-0.57-0.35l-0.87-1.06l-0.14-0.75l-1.01-1.47l1.32-2.47l0.6-0.5l0.77-1.26l1.08-1.08l0.59-1.81l-0.03-0.58l-0.78-0.92l-0.34-1.35
  		l0.47-0.02l0.29-0.14l-1.26-1.03l-0.37-0.47l-0.94-1.9l-0.09-0.58l0-0.74l0.42-0.32l0.33-0.16l3.2-0.78l0.62-0.31l0.55-0.51
  		l0.25-0.4l-0.3,0.1l-0.38,0.22l-1.14,0.42l-1.54,0.4l-2.01,0.51l-0.46,0.55l-1.99,3.44l-1.66,0.7l-0.61,0.51l-0.46,0.65l-0.99,1.2
  		l-0.41,0.92l-1.1,2l-0.6,2.5l-0.23,1.4l-0.33,2.03l-0.48,1.02l-0.65,0.96l-0.7,0.94l-0.6,1.03l-0.51,0.8l-0.08,0.28l0.25,0.22
  		l1.21-0.22l0.48-0.24l0.66-0.31l0.41,0.17l0.41,0.88l0.47,0.11l0.44-0.11l0.47,0.22l0.6,2.2l0.51,1.86l1.18,1.16l0.92,0.91
  		l0.22,0.8l0.22,1.13l0.05,0.55l-0.29,0.33l-0.46,0.69l-0.11,1.32l-0.08,0.44l-0.08,1.22l0.06,0.75l0.24,0.58l0.41,0.33l0.83,0.17
  		l0.75,0.2l0.43,1.01l0.61,1.29l0.65,0.54l0.96,0.36l0.69-0.13l1.6-0.23l1.38,0.05l2.02,0.35l0.75-0.01l0.97-0.06l1.71-0.7l0.62-0.1
  		l0.69,0.06l1.01,0.36l0.55,0.28l0.7,0.29l1.02,0.21l0.66-0.03l0.5-0.01l0.29,0.13l1.61,2l1.39,1.73l1.21,1.45l1.31,1.57l0.1,0.09
  		l0.59-0.2l0.39,0.09l0.36,0.32l0.62-0.13l0.88-0.58l1.29-0.12l1.69,0.35l2.23,0l2.77-0.34l1.72-0.37l0.67-0.4l1.11,0.04l1.34,0.36
  		l0.72,0.52l0.09,0.51l0.27,0.79l-0.29,0.8l-0.85,0.81l-0.47,1.04l-0.09,1.26l-0.44,0.93l-0.79,0.6l-0.31,0.88l0.17,1.16l-0.09,1.69
  		l-0.34,2.22v1.33l0.34,0.43l0.16,0.62l-0.03,0.81l0.13,0.72l0.43,0.94l0.6,1.86l0.5,0.8l0.43,0.29l0.44,0.36l1.27,1.9l0.31,0.4
  		l-0.09,0.35l-0.12,0.26l-0.15,0.17l-1.36,1.13l-2.69,2.47l-0.22,0.31l0.02,0.52l0.78-0.33l0.84,0.28l0.41,0.06l0.19,0.2l0.24,0.66
  		l0.28,0.11l0.41,0.28l0.8,0.7l0.67,0.74l0.5,0.35l0.35,0.33l0.11,0.48l-0.15,0.48l0.42,1.11l0.29,0.35l0.15,0.42l-0.15,0.42
  		l0.35,0.5l0.38,0.98l0.47,1.2l0.06,0.66l0.2,0.31l0.23,0.89l0.39,0.83l-0.09,0.57l0.16,0.56l2.16,1.93l1.6,1.43l0.69,0.46
  		l0.38,0.13l0.93-0.1l1.1-0.18l0.54-0.2l0.59-0.45l0.96-0.62l0.79-0.34l0.31-0.05l0.31,0.11l0.66,0.37l0.36,0.69l-0.33,0.8l0.06,0.5
  		l0.7-0.03l0.56-0.83l0.39-0.65l0.82-0.53l0.79-0.76l0.55-0.72l0.65-0.42l0.98-0.51l0.78-0.31l0.73,0.03l0.54-0.34l0.7-0.64
  		l0.49-0.51l0.34-0.18l0.68,0.05l0.85-0.07l0.84-0.62l0.76-0.75l0.4-1.27l0.27-1.12l0.23-0.23l0.28-0.18l0.33-0.12l0.78-0.08
  		l1.37-0.6l0.94-0.61l0.9-0.13l0.26-0.16l0.32-0.56l0.16-0.99l-0.12-0.6l-1.65-0.18l-1.08,0l-1.78-0.15l-0.85-0.25l-0.18-0.17
  		l-0.02-0.19l0.17-0.43l0.17-0.8l-0.24-1.09l-0.89-1.7l-0.63-1.67l-0.08-1.17l0.01-1.23l0.05-0.81l-0.46-0.63l-2.46-1.99l-0.85-0.94
  		l-0.28-0.65l-0.97-1.2l0.25-0.37l0.56,0.01l0.48,0.31l0.44,0.67l0.31,0.15l0.43,0l2.27,0l0.53,0.11l0.32,0.22l0.27,0.28l0.41,0.78
  		l0.44,0.38l0.9-0.01l1.41-0.02l0.79-0.07l0.47,0.22l0.59,0.18l1.24-0.41l0.35-0.01l0.37,0.18l1.33,1.39l0.77,0.59l0.65,0.78l0.94,0
  		l0.78-0.67l0.21-2.24l0.23-0.65l0.39-0.18l0.47-0.02l0.55-0.35l0.6-0.45l0.52-0.15l2.17,0.49l0.6-0.23l2.2-0.59l2.25-0.76
  		l0.63-0.96l0.94-0.26l0.74-0.7l0.6,0.07l0.9,0.03l0.56-0.12l0.28-0.13l0.31-0.33l0.51-0.94l0.61-0.36l0.77-0.38l0.53-0.44
  		l0.43-0.55l0.2-0.48l-0.01-0.38l-0.26-0.73l-0.31-0.69l-0.34-0.23l-0.25-0.09l-1.78-1.97l-1.8-1.99l-1.77-1.95l-0.12-0.27
  		l0.74-0.93l0.66-0.67l0.55-0.38l0.26-0.34l-0.2-1.43l0.01-0.52l-0.26-0.56l-0.19-0.63l0.22-0.53l0.27-0.37l0.34-0.14l0.82-0.13
  		l0.58-0.05l0.21-0.21l0.34-0.24l0.44-0.01l0.87,0.17l0.39-0.32l0.72-0.43l1.61-0.74l0.36-0.48l0.25-0.75l-0.03-0.35l-0.16-0.13
  		l-0.4-0.12l-0.61-0.02l-0.49,0.19l-0.51-0.11l-0.42-0.46l-0.02-0.38l0.25-0.54l-0.14-0.36l-0.8-1.14l0.01-0.31l0.58-0.51l0.33-0.43
  		l0.45-1.04l0.36-0.35l1.12-0.12l0.29-0.22l0.57-0.55l0.85-0.63l1.23-0.5l0.35-0.91l0.22-0.25l0.97-0.48l0.17-0.26L1305.77,1249z"/>
    </>,
    VCT: <>
        <path className="st0" d="M1294.25,1208.88l0-0.03l-0.01-0.03l-0.1,0.08l-0.23,0.15l-0.07,0.07l0.03,0.04l0.06,0.06l0,0.07l-0.19,0.04
  		l-0.13-0.04v0.06l0.1,0l0.09,0.02l0.07,0.04l0.04,0.06l0.06-0.06h-0.06l0.14-0.09l0.15-0.26l0.12-0.12l-0.04-0.03L1294.25,1208.88z
  		 M1293.24,1211.52l-0.06,0.01l-0.08,0.05l0,0.08l0.02,0.08l-0.05,0.06l-0.1,0.02l-0.02,0.03l0.08,0.03l0.09,0l0.05-0.05l0.02-0.07
  		l0.05-0.05l0.04-0.06l-0.02-0.08L1293.24,1211.52z M1294.76,1206.21l-0.36,0.02l-0.35,0.22l-0.37,0.37l-0.07,0.67l0.62,0.57
  		l0.25-0.13l0.34-0.38l0.09-0.78L1294.76,1206.21z"/>
    </>,
    VAT: <>
        <polygon className="st1"
                 points="1914.46,945.57 1914.4,945.57 1914.37,945.62 1914.4,945.65 1914.47,945.64 	"/>
    </>,
    UZB: <>
        <path className="st0" d="M2425.66,956.37l-1.2-0.32l-0.54,0.18l-0.5,0l-0.3-0.2l-1.45-0.08l-0.32-0.14l-0.96-0.98l-0.66-0.37
  		l-0.53-0.24l-0.58,0.04l-0.69,0.26l-0.22-0.12l-0.06-0.41l0-0.52l-0.14-0.55l-0.41-0.13l-0.53,0.22l-0.79-0.22l-0.67-0.08
  		l-0.17-1.17l-0.27-0.5l-0.28-0.52l-0.29-0.15l-0.48-0.26l-0.03-0.62l-0.1-0.17l-0.17-0.08l-0.23,0.07l-0.3,0.31l0.15,0.68
  		l-0.11,0.68l-0.17,0.34l-0.34,0.25l-0.38,0.01l-0.67-0.35l-0.11,2.06l-0.13,0.13l-0.79-0.29l-0.64,0.13l-0.95-0.13l-0.71-0.34
  		l-0.53-0.09l-0.86-0.29l-0.66-0.38l-0.4-1.38l-0.38-0.49l-0.36-0.11l-1.47,0.48l-0.54-0.37l-0.99-0.47l-0.75-0.18l-0.2-0.26
  		l0.03-0.32l2.31-1.54l0.91-1.06l0.57-0.45l0.82-0.3l0.64-0.18l0.32-0.98l0.12-0.11l0.45-0.07l1.03-0.4l1.65-0.86l0.03-0.24
  		l-0.17-0.2l-0.7-0.44l-0.79-0.35l-0.48,0.18l-0.27,0.18l-0.73,0.42l-0.81,0.13l-0.42,0.26l-0.44,0.62l-0.41,0.53l-0.24,0.19
  		l-0.37-0.03l-0.43-0.41l-0.62,0.02l-0.73,0.51l-0.87,0.83l-1.1,1.26l-1.14,0.67l-1.45,0.57l-1.05,0.25l-0.83,0.43l-1.38,0.88
  		l-0.27,0.52l-1,0.3l-0.81,0.35l-0.75,0.59l-0.18,1.03l-0.48,0.59l-1.14,0.81l-0.96,0.82l-0.63,0.8l-0.66,0.85l-0.21,0.47
  		l-0.02,0.64l0.31,0.54l0.06,0.51l-0.24,0.37l-0.65,0.14l-0.68-0.11l-1.04-0.37l-1.11-0.65l-0.4-0.32l-0.54-0.55l0.08-0.51l0.28-1
  		l0.19-0.68l-0.45-0.33l-0.33-0.35l-0.24-0.34l-0.47-0.67l-0.59,0.16l-0.51,0.16l-0.59-0.23l-1.74,0.1l-1.32,0.08l-1.24,0.07
  		l-1.57,0.09l-1.89,0.11l-0.54-0.15l-0.34-0.22l-0.35-0.92l-0.2-0.78l-0.37-1.46l-0.25-1.13l-0.29-1.35l-0.19-1.49l-0.14-1.06
  		l-1.43-0.03l-1.14-0.03l-1.55-0.04l0.01-0.85l0.02-1.07l0.02-1.22l0.29-1.6l0.11-1.34l0.13-1.64l0.09-1.09l0.1-1.19l-0.8,0.37
  		l-0.88,0.41l-0.83,0.38l-0.57-0.97l-0.55-0.94l-0.84-1.43l-0.63-1.08l-1.09-0.63l-0.81-0.47l-0.84-0.78l-0.72-0.81l-0.69-0.77
  		l-0.82-0.67l-0.79,0.21l-0.89,0.42l-0.86,0.4l-0.91,0.43l-0.45,0.21l-1.05-0.08l-0.92-0.07l-1.65-0.12l-1.39-0.11l-1.42-0.11
  		l-1.98-0.15l-2-0.15l-1.34,0.2l-1.7,0.25l-1.78,0.26l-1.47,0.22l-1.87,0.28l-1.4,0.21l-0.69-0.03l-0.86-0.87l-1.38-1.4l-0.84-0.85
  		l-0.82-0.83l-1.19-1.2l-0.96-0.91l-0.93-0.89l-0.54-0.82l-0.27-1.03l-0.48-0.47l-1.08-0.63l-1.16-0.68l-1.16-0.68l-1.16-0.68
  		l-1.16-0.68l-1.16-0.68l-1.12-0.66l0,0.06l-0.16,1.3l-0.32,0.48l-0.02,0.8l0.28,1.12l0.05,0.8l-0.18,0.47l-0.32,0.27l-0.47,0.07
  		l-0.25,0.13l-0.04,0.2l0.11,0.16l0.25,0.13l0.06,0.16l-0.13,0.19l-0.93,0.54l-0.78,0.2l-1.49,0.15l-0.09-0.21l0.08-0.3l0.23-0.46
  		l0.68-0.8l0-0.21l-2.85-2.53l-0.59-0.72l-0.3-0.55l-0.02-0.39l0.5-1.15l1.01-1.92l0.45-1.06l-0.63-0.37l-1.16-0.68l-1.16-0.69
  		l-1.16-0.69l-1.16-0.69l-0.49-0.29l-0.22,0.45l-0.09,0.75l0.14,0.83l0.22,0.54l0.3,0.25l-0.11,0.36l-0.53,0.48l-0.36,0.5
  		l-0.18,0.52l-0.03,0.3l0.13,0.08l1.18-0.66l0.09,0.05l-0.17,0.76l-0.17,0.26l-0.2,0.04l-0.08,0.38l0.03,0.72l0.1,0.49l0.17,0.26
  		l-0.06,0.17l-0.29,0.09l-0.2-0.06l-0.1-0.22l-0.17-0.1l-0.23,0.03l-0.18,0.19l-0.13,0.35l0.11,0.18l0.54,0.11l0.04,0.16l-0.98,1.35
  		l-0.54,0.58l-0.41,0.31l-0.82,0.33l-0.44,0.03l-0.32-0.24l-0.2-0.33l-0.09-0.44l0.12-0.54l0.33-0.64l-0.02-0.72l-0.37-0.81
  		l-0.16-0.67l0.05-0.54l0.29-0.82l0.89-2.04l0.22-0.79l0.38-0.72l0.54-0.66l0.39-0.75l0.24-0.83l0.01-0.04l-0.3-0.15l-0.89,0.13
  		l-1.33,0.35l-1.4,0.37l-1.38,0.36l-2.48,0.65l-1.59,0.42l-1.25,0.35l-1.33,0.37l-1.74,0.49l-1.46,0.41l-1.71,0.48l-1.51,0.43
  		l-1.27,0.36l-1.33,0.37l-1.05,0.3l0,2.38l0,2.38l0,2.37l0,2.37l0,2.36l0,2.36l0,2.35l0,2.34l0,2.34l0,2.33l0,2.33l0,2.32l0,2.32
  		l0,2.31l0,2.31l0,2.3l2.23,0.11l2,0.1l2.47,0.13l0.73,0.12l0.88,0.11l0.44,0.02l0.39-0.44l0.26-0.24l0.2-0.19l-0.04-0.22
  		l-0.31-0.18l-0.49-0.61l-0.28-2.2l-0.18-1.88l0.59-0.59l0.46-0.29l0.04-0.25l0.75-2.19l0.76-1.19l1.01-0.32l1.33,0.75l0.57,0.99
  		l0.57-0.02l1.08-0.25l0.35-0.42l0.57-1.05l0.19-0.86l0.32-0.39l0.38-0.29l0.39,0.01l0.76,0.25l0.58,0.14l0.41,0.19l0.37,0.3
  		l0.36,0.53l0.06,0.35l0.17,0.2l0.29,0l0.22,0l0.15-0.08l0.1-0.18l-0.07-0.24l-0.5-0.67l-1.09-1.22l-0.71-0.5l-0.35-0.27l-0.09-0.25
  		l0.46-0.39l0.45-0.22l0.79,0.17l1.04,0.09l0.46-0.19l0.48-0.98l1.19,1.04l1.24,1.16l0.45,0.22l0.88,0.12l0.74,0.04l0.31,0.13
  		l0.33,0.3l0.65,1.27l0.65,0.33l0.82,0.24l2.62-0.02l0.81,0.06l0.66,0.6l0.41,0.25l0.18,0.21l-0.04,0.26l-0.16,0.33l-0.02,0.64
  		l-0.04,0.5l-0.21,0.24l-0.06,0.22l0.18,0.19l1.22,0.47l0.41,0.51l0.31,0.23l0.07,0.32l-0.21,0.21l-0.57-0.1l-0.28,0.33v0.59
  		l0.41,0.56l0.12,0.51l-0.27,0.49l-0.31,0.69l-0.01,0.49l0.19,0.28l0.93,0.51l2.15,1.28l0.49,0.05l2.03-0.3l0.95-0.03l0.56,0.2
  		l1.57,0.18l0.5,0.2l0.53,0.01l0.73-0.06l0.49-0.57l0.25-0.13l0.22-0.1l0.45-0.01l1.24,0.36l1.31,0.76l0.87,0.7l0.43,0.63l0.54,1.37
  		l0.65,2.1l0.79,1.41l0.92,0.73l0.65,1.35l0.56,2.93l0.35,0.6l0.36,0.31l1.05,0.85l2.16,1.4l1.27,0.83l1.97,1.32l1.8,1.21l1.81,1.86
  		l0.36,0.27l1.59,1l1.77,1.03l1.24-0.23l1.87,1.58l0.76,0.59l0.32,0.2l1.35,0.62l2.16,1.3l2.72,1.86l1.8,1.08l0.49,0.13l0.49-0.01
  		l0.52-0.23l0.56-0.18l0.96,0.24l1.04,0.43l0.66,0.33l0.76,0.47l0.6,0.45l0.46,0.21l1.56,0.39l0.27,0.23l0.17,0.26l0.02,0.27
  		l-0.87,1.42l-0.12,1.81l-0.01,1.36l0.1,1.07l2.57-0.22l2.03,0.35l1.07,0.96l1.05,0.25l1.03-0.47l0.64-0.08l0.25,0.3l0.51,0.13
  		l0.78-0.05l0.45,0.27l0.05,0.27l0.33-0.7l0.14-2.34l0.41-0.81l1.24-1.46l0.65-1.11l0.73-0.9l0.53-0.3l0.48-0.71l0.4-0.82l0.11-0.51
  		l-0.04-0.4l-0.14-0.26l-0.69-0.55l-0.9-0.86l-0.48-0.88l-0.26-1.13l-0.07-0.79l0.85-2.17l-0.14-0.36l-0.24-0.34l-0.5-0.21
  		l-0.71-0.09l-0.71,0.1l-0.9,0.01l-0.62-0.12l-0.15-0.14l-0.08-0.99l-0.16-0.21l-0.27-0.19l-1.82-0.46l-0.36-0.2l-0.07-0.25
  		l0.64-2.2l0.28-0.17l0.27-0.36l0.43-0.38l1.49-0.63l1.6,0.27l1.42,0.29l1.41,0.16l0.49,0.1l0.81,0.09l0.54-0.08l0.37-0.26
  		l0.67-0.71l0.2-1.07l0.24-0.95l0.4-0.07l0.41,0.1l0.19-0.19l0.08-0.26l0.08-0.22l0.17-0.05l0.29,0.25l0.17-0.07l0.14-0.16
  		l-0.04-0.2l-0.33-0.33l-0.29-0.52l0.03-0.18l0.1-0.19l0.87-0.18l0.4-0.03l0.14-0.18l-0.05-0.29l-0.33-0.17l-1.2,0.09l-1.22-0.02
  		l-0.15-0.18l0.07-0.2l0.18-0.15l2.52-0.4l1.34,0.14l0.99,0.21l0.39-0.1l-0.47-0.89l0.63-0.08l0.08-0.31l-0.82-2.38l0.45-0.21
  		l0.45-0.47l-0.04-0.89l0.4-0.43l0.48-0.3l0.71,0.3l1.1,0.88l0.36,0.17l0.35,0.05l0.51-0.27l1.96-0.87l1.1-0.49l1.31-0.71l0.23-0.28
  		l0.45-1.08l0.25-0.07l0.33,0.12l1.15,1.12l0.67,0.72v0.25l-0.19,0.18l0.04,0.18l0.94,0.39l0,0.18l-0.22,0.34l-0.11,0.19l-0.12,0.08
  		l-1.26,0.98l-1.4,1.09l-0.05,0.14l-0.06,0.27l0.02,0.28l0.22,0.23l0.6,0.16l0.54,0.21l0.27,0.57l0.31,0.53l0.42,0.13l2.07-0.33
  		l0.49-0.04l0.28-0.16l0.87-0.16l1.77-0.16l0.6,0.12l0.68,0.33l0.53,0.33l0.51-0.01l0.42-0.07l0.18,0.09l0.13,0.29l0.22,0.26
  		l0.68-0.35l0.61-0.46l0.48-0.07l0.45-0.18l0.13-0.31l0.35-0.51l1-0.97l0.52-0.16l0.34,0l0.02,0.16l0.16,0.14l0.87,0.22l0.27-0.26
  		l0.14-0.35l-0.31-0.56l-0.01-0.24l0.11-0.22l0.16-0.13l1.39,0.53l0.31-0.01l0.64-0.3l0.58-0.53l0.21-0.42l2.86-1.35l0.2-0.25
  		L2425.66,956.37z M2404.52,955.31l-0.45-0.47l-0.16-0.33l0.19-0.1l0.39,0.23l0.26,0.41l0.03,0.24L2404.52,955.31z M2408.79,964.02
  		l-0.42-0.2l-0.47-0.69l-0.16-0.03l-0.25,0.19l-0.13,0.45l0.12,0.49l0.34,0.33l0.22,0.11l0.03,0.02l-0.01,0.16l-0.27,0.8l0.44,0.1
  		l0.77,0.01l0.42-0.09l0.08-0.14l-0.31-0.72l0.42-0.68L2408.79,964.02z M2413.9,964.8l-0.47,0.12l-0.1,0.22l0.31,0.28l0.4,0.1
  		l0.23-0.43l0.09-0.45l-0.21,0.02L2413.9,964.8z"/>
    </>,
    URY: <>
        <polygon className="st1" points="1361.36,1601.83 1360.25,1601.41 1359.19,1600.68 1358.24,1599.75 1357.8,1598.78 1357.4,1597.73
  		1357.02,1596.9 1356.89,1596.52 1356.52,1596.37 1355.93,1595.95 1355.56,1595.55 1355.01,1595.33 1354.04,1595.08
  		1353.03,1594.65 1351.77,1593.63 1350.86,1592.49 1350.42,1591.74 1349.94,1591.22 1347.34,1590.35 1346.16,1589.31
  		1345.7,1589.63 1345,1589.31 1344.32,1588.81 1344.11,1588.43 1343.83,1588.04 1343.55,1587.59 1343.38,1587.15 1342.68,1586.4
  		1341.77,1585.58 1341.39,1585.35 1341.18,1585.42 1340.99,1585.73 1340.86,1586.03 1340.52,1586.23 1340.09,1586.61
  		1339.66,1587.07 1339.11,1587.37 1338.45,1587.47 1338,1587.46 1337.91,1587.28 1337.89,1586.65 1338.05,1585.22 1337.66,1584.67
  		1337.15,1584.08 1336.56,1583.3 1334.61,1581.63 1331.96,1579.23 1331.03,1578.5 1330.15,1578.45 1329.34,1578.53 1328.6,1578.84
  		1328.04,1579.95 1327.81,1580.12 1326.39,1580.1 1324.97,1579.92 1324.49,1579.24 1324.18,1579.6 1324.14,1580.23 1323.62,1581.05
  		1322.62,1582.07 1322.27,1582.95 1322.72,1584.07 1322.79,1585.42 1322.59,1585.96 1322.15,1586.15 1322.05,1586.5
  		1322.29,1587.01 1322.31,1587.69 1322.09,1588.53 1321.63,1589.5 1320.92,1590.58 1320.74,1591.31 1321.12,1591.67 1321.3,1592.06
  		1321.29,1592.47 1321.14,1593.07 1320.84,1593.85 1320.39,1594.43 1319.79,1594.81 1319.6,1595.29 1319.85,1595.87
  		1319.88,1596.48 1319.71,1597.1 1319.81,1597.72 1320.19,1598.31 1320.16,1598.99 1319.5,1600.39 1319.83,1601.28 1320.11,1603.06
  		1320.5,1604.33 1320.42,1605.02 1319.9,1605.93 1319.33,1606.54 1318.74,1606.62 1318.14,1607.04 1318.22,1607.77 1317.73,1610.1
  		1317.51,1612.08 1317.83,1613.9 1319.45,1615.76 1321.52,1617.63 1322.02,1618.41 1322.26,1618.96 1322.64,1619.24
  		1325.04,1618.96 1328.18,1619 1330.84,1621.13 1334.14,1622.06 1334.78,1622.88 1335.94,1623.26 1336.4,1623.31 1337.05,1623.32
  		1339.2,1622.4 1340.79,1622.07 1343.34,1622.37 1344.46,1623.21 1345.66,1623.2 1347.29,1623.56 1351.81,1621.66 1352.59,1621.04
  		1353.47,1621.07 1354.8,1619.62 1356.7,1618.32 1357.05,1617.08 1358.81,1614.89 1359.33,1613.31 1359.78,1612.65 1360.19,1612.3
  		1359.96,1612.25 1359.4,1611.99 1358.94,1611.69 1358.83,1611.48 1358.78,1611.18 1358.82,1610.47 1358.73,1610.39
  		1358.62,1610.16 1358.53,1609.86 1358.48,1609.39 1358.45,1608.78 1358.37,1608.13 1358.22,1607.6 1358.09,1607.12 1358.05,1606.6
  		1358.26,1606.07 1358.71,1605.56 1359.33,1604.94 1359.89,1604.9 1360.32,1604.64 1360.59,1604.23 1360.67,1603.78
  		1360.67,1603.39 1360.77,1603.09 1361.08,1602.83 1361.48,1602.59 1361.81,1602.32 1361.89,1602.26 	"/>
    </>,
    USA: <>
        <path className="st0" d="M593.12,743.81l-0.81,0.91l-0.61,0.82l0.4-0.07l1.37-1.29l1.3-0.77l0.11-0.42l-1,0.24L593.12,743.81z
  		 M675.75,782.98l-0.36-0.96l-0.77-2.55l-0.46-1.23l-0.52-1.03l-0.72-1.33l-0.8-0.79l-0.55-0.18l-1.82-1.23l-0.86-0.02l-0.33,0.22
  		l-0.69,1.11l-0.09,0.42l-0.37,0.43l-0.47,0.26l-0.89,0.18l-0.3,0.45l0.01,0.6l0.45,1.57l-0.12,0.52l-0.26,0.58l1.27-0.29l0.45-0.45
  		l0.9-2.08l0.4-0.07l0.67,0.74l0.29,1.29l0.62,0.39l0.47,0.05l0.1,0.21l-0.97,1.15l-0.2,0.45l-0.02,0.52l0.08,0.35l0.39,0.26
  		l1.02-0.29l0.14,0.26l-0.1,0.92l0.52,0.27l0.67,0.51l0.76-0.08l0.37-0.14l0.07,0.1l-0.49,0.65l-0.28,0.24l-0.13,0.39l0.02,0.53
  		l0.26,0.74l0.86,1.59l0.35,0.5l0.47,0.48l0.58,0.29l0.23-0.14l0.2-0.89l-0.01-1.59l-0.21-1.05l0.05-0.39l0.23-0.27l0.12-0.3
  		l-0.09-1.38L675.75,782.98z M680.24,776.5l1.47-0.44l0.12-0.19l0.02-0.2l-0.55-1l0.45-0.49l-0.14-1.09l-0.2-0.4l-1.03-1.19
  		l-1.54-2.1l-0.28-0.96l-0.05-0.87l0.12-0.13l0.36-0.05l0.31,0.14l0.27,0.33l0.02,0.35l0.61,1.26l0.32,0.48l0.3,0.23l0.3,0.51
  		l0.59,1.31l0.83,0.65l0.03-0.13l-0.39-0.96l-0.29-0.99l-0.52-1.03l-0.88-1.47l-1.43-1.83l-1.57,0.06l-2.14-0.28l-0.45-0.78
  		l-0.87-1.17l-0.73-0.42l-0.08,0.32l0.21,0.81l0.32,0.74l0.42,0.68l0.33,0.85l0.23,1.01l0.5,3.16l0.3,1.19l0.55,0.85l0.91,1.04
  		l-0.02,0.75l-0.73,2.28l-0.37,0.44l0.05,0.69l0.18,0.55l0.31,0.41l0.33,0.18l0.68-0.17l1.48-1.08L680.24,776.5z M667.46,774.32
  		l-0.02-1.41l0.1-0.65l0.37-0.19l0.56,0.05l2.34,1.08l1.14,0.76l1.29,0.36l0.49-1.31l-0.2-0.71l-0.69-0.93l-2.28-0.1l-0.75-0.44
  		l0.9-0.57l0.56,0.24l0.52,0.03l1.11-0.29l0.36-1.65l-0.23-0.76l-0.4-0.44l-1.35-0.55l-1.55-0.35l-0.24,0.02l-0.39,0.24l-1.27,1.15
  		l-0.35,0.2l-0.49-0.25l-0.18-0.33l0.65-0.23l0.25-0.82l-0.01-0.27l-1.2-1.19l-0.48-0.3l-0.8,0.26l-0.55,0.51l-0.4,0.11l-0.84-0.02
  		l-0.4,0.54l-0.01,0.68l-0.86-0.72l-0.64-0.75l-0.4,0.93l-0.71,0.43l-0.49,0.15l-0.11,0.56l-0.37,0.96l0.92,1.21l0.69,0.4l2.54,2.01
  		l0.67,1.7l0.72,1.07l1.84,0.32L667.46,774.32z M689.9,781.56l-0.35-0.6l-0.63-0.53l-1.05-0.35l-0.57-0.02l-0.3,0.4l-0.56-0.38
  		l-2.88-0.71l-1.33-0.07l-0.81,0.3l-0.14,0.41l0.52,0.51l0.79,0.51l0.55,0.57l0.64,0.95l-0.06,1.05l0.25,1.36l0.08,0.95l0.15,0.39
  		l0.24,0.24l1,0.15l0.85-0.26l1.43,0.11l0.27-0.11l0.19-0.25l0.11-0.4l0-0.46l-0.1-0.53l-0.18-0.4l-0.41-0.53l-0.1-0.44l-0.58-0.73
  		l-0.2-0.39l0.03-0.14l0.72,0.41l0.94,1.35l0.82,0.75l0.26-0.04l0.24-0.28l0.14-0.36l0.04-0.43l-0.08-0.83l0.11-0.82L689.9,781.56z
  		 M692.41,783.91l-1.57-1.32l-0.24,1.18l-0.3,0.71l-0.25,0.37l-0.1,0.47l0.15,0.51l0.39,0.14l1.07,0.02l0.09-0.18l0.27-0.2
  		l0.68-0.13l0.56-0.27l-0.26-0.72L692.41,783.91z M678.39,765.5l0.06-0.3l-0.73-0.72l-1.02-0.56l-1.08,0.25l-0.11,0.26l0.57,0.59
  		l1.16,0.45L678.39,765.5z M694.51,792.57l0.32,0.22l0.68,0.12l0.65-0.28l0.32,0l0.33,0.11l0.17-1.97l0.38-1.6l-1.17-1.71
  		l-0.93-1.19l-0.53-0.13l-1.06,1.95l-0.28,0.13l-0.5,0.98l-0.64,0.21l-0.2,0.3l0.06,0.81l0.26,0.62l0.48,0.14l1.28,0.12l0.38,0.33
  		l-0.12,0.59L694.51,792.57z M689.19,787.74l0.02,0.29l1.21,0.97l0.51,0.18l0.43-0.04l0.93-0.48l0.19-0.31l-0.09-0.31l0.04-0.3
  		l0.17-0.29l0.03-0.28l-0.12-0.28l-0.53-0.16l-1.65-0.06l-0.28,0.14l-0.69,0.59L689.19,787.74z M529.59,738.46l0.84-1.25l-0.1-0.22
  		l-0.51-0.17l-0.38,1.22l-0.54,0.63l0.41-0.05L529.59,738.46z M561.23,741.75l-0.35,0.76l1.67,0.23l0.44-0.39l0.91-0.34l-1.39-0.75
  		L561.23,741.75z M562.2,732.65l0.49,0.16l1.07-0.11l-0.1-0.63l-0.18-0.32l-0.62-0.31l-0.54,0.1l0.02,0.27l-0.2,0.64L562.2,732.65z
  		 M586.05,738.93l0.39,0.2l0.73,0.11l0.27-0.31l0.14-0.65l-0.22-0.16L586.05,738.93z M525.14,762.13l0.25-0.08l0.79-0.69l0.26-0.37
  		l0.05-0.3l-0.09-0.2l-0.23-0.1l-0.57,0.01l-1.19,0.64l-0.27,0.3l0.4,0.4L525.14,762.13z M578.51,737.77l-1.62,0.08l-0.19-0.35
  		l-1.4-0.39l-0.38,0.16l-0.54,0.45l-0.27,0.31l0,0.16l0.17,0.44l0.27,0.32l0.27,0.78l0.19,0.07l1.48-0.72l1.83-0.54l0.62-0.31
  		l0.22-0.24l-0.19-0.16L578.51,737.77z M566.05,737.49l-0.26-0.52l-0.12,0.06l-0.29,0.5l-0.19,0.11l-0.12-0.08l-0.04-0.27
  		l-0.13,0.08l-0.36,1.12l-0.03,0.25l-0.11,0.38l-0.31,0.28l0.17,0.88l0.56-0.01l-0.14,0.35l0.05,0.22l0.24,0.09l0.24-0.16l0.23-0.4
  		l0.17-0.64l0.44-1.01l-0.25-0.59l0.26-0.32L566.05,737.49z M570.58,738.6l-0.51,0.06l-0.21,0.59l-1.11,1.59l-1.09,1.12l-1.19,0.76
  		l-1.07,1.05l-0.29,0.12l-0.39,0.53l-0.49,0.93l0.22,0.37l0.93-0.18l0.57-0.23l0.2-0.28l0.36-0.15l0.52-0.02l0.34-0.28l0.17-0.55
  		l0.27-0.33l0.37-0.12l0.23-0.27l0.25-0.77l0.23-0.29l2.79-2.26l0.24-0.44l-0.52-0.55L570.58,738.6z M703.49,802.93l-0.55-1.57
  		l-0.91-0.58l-0.44-0.02l-0.11,0.18l0.01,0.56l0.18,0.74l-0.33,0.55l0.02,0.76l0.44-0.27l0.49,0.16l0.42,0.45l-0.21,0.77l0.09,0.25
  		l0.33,0.18l0.65,0.08l0.82-0.19l-0.05-0.54L703.49,802.93z M811.57,1032.34l-0.42-0.68l-0.28,0.2l0.52,0.71l0.47,0.9l0.54,0.19
  		l0.49-0.08l-0.28-0.2L811.57,1032.34z M997.33,1074.93l-0.56,0.06l-1.37,0.94l-0.62,0.25l-0.38,0.49l0.31-0.04l1.86-1.12l1.1-0.57
  		l0.08-0.4l-0.13,0.05L997.33,1074.93z M682.96,783.38l-0.44-0.63l-0.79,0.07l-0.55-0.83l-0.09-0.29l-0.43-0.35l-0.77-0.41
  		l-1.1,0.17l-0.84,0.96l-0.14,1.07l0.05,0.31l0.89,1.28l-0.1,0.44l1.18,0.8l0.56,0.68l-0.94,0.51l-0.42,2.51l0.47,1.5l0.57-0.01
  		l0.46-0.66l0.37,0.17l0.25,0.21l0.2-0.08l0.18-0.24l0.09-0.78l0.45-1.17l0.12-1.15l-0.11-1.16l0.24-1.16l0.39-0.35l0.35,0.13
  		l0.24-0.6L682.96,783.38z M812.55,1028.07l-1.37-0.58l-0.12,0.12l0.05,0.25l0.03,0.05l0.44,0.05l0.13,0.13l0.19,0.52l0.19,0.38
  		l0.64-0.04l0.61,0.09l-0.42-0.69L812.55,1028.07z M991.62,1080.02l-1.39,2.32l-0.68,1.76l-0.07,0.77l0.26-0.52l0.87-2.18l1.6-2.54
  		L991.62,1080.02z M993.57,1076.95l-0.67,0.94l-0.08,0.3l-0.41,0.74l0.18-0.02l0.78-1.04l0.69-0.66l-0.15-0.4L993.57,1076.95z
  		 M989.47,1085.29l-0.18,0.87l0.04,2.52l1.13,4.41l0.7,1.95l0.12-0.42l-0.27-1.26l-1.25-4.51L989.47,1085.29z M795.91,1021.87
  		l-0.38,0.16l-0.24,0.22l0.69,0.1l0.45-0.02l-0.39-0.34L795.91,1021.87z M798.4,1022.31l-1.52,0.12l0.71,0.9l0.45,0.12l0.59-0.13
  		l0.5-0.51l-0.09-0.11L798.4,1022.31z M697.89,801.16l-1.35,0.15l-0.34-0.08l-0.07-0.14l0.21-0.21l0.27-0.53l-0.02-0.27l-0.46-0.71
  		l-0.48-0.18l-1.23-0.99l-1.46,0.22l-0.33-0.1l0.42-0.35l0.28-0.48l0.16-0.55l0.15-0.04l1.81,1.02l0.84,0.33l0.2-0.02l-0.35-0.45
  		l-0.62-0.46l-1.19-1.52l-0.56-1.31l-0.31-0.53l-0.54-0.62l-1.35-1.19l-2.86-1.13l-0.04-0.12l0.18-0.34l0.04-0.32l-0.05-0.46
  		l-0.19-0.49l-0.33-0.52l-0.5-0.49l-1.46,0.02l-1.6-0.26l-0.27,0.27l0.04,1.19l0.42,0.47l0.11,0.37l-0.58,0.62l-1.31,1.12l0.11,0.41
  		l0.49,0.26l1.23-0.67l1.41-0.43l0.69,0.2l0.31,0.73l0.09,0.43l-0.56,0.41l-0.11,0.5l-0.75,0.55l-0.3,0.02l-0.76-0.41l-0.4-0.05
  		l-0.68,0.39l-0.13,0.22l0.33,0.43l0.73,0.68l0.43-0.06l1.13,0.08l0.22,0.45l0.38,0.52l0.46,0.14l1.3-0.2l0.47,0.27l-0.38,0.65
  		l-0.03,0.36l1.04,1.28l-0.1,0.23l-0.5-0.02l-0.61,0.2l-0.13,0.38l0.49,0.31l1.25,1.32l1.1,1.64l0.66,0.22l0.18-0.51l-0.16-0.66
  		l0.31-0.19l0.36,0.13l-0.04,0.3l0.32,0.68l-0.05,0.66l0.37,0.51l0.53,0.03l0.15,0.15l0.83,0.18l0.24,0.18l0.63,1.23l0.65,0.79
  		l0.46,0.26l0.59-0.01l0.36-0.15l0.34-0.92l-0.02-0.35l-0.12-0.39l0.01-0.38l0.16-0.45l0-0.34l-0.19-0.75l0.2-2.05L697.89,801.16z
  		 M529.17,764.24l-0.35-0.07l-0.39-0.76l-0.61,0.14l-1-0.62l-0.23,0.02l-0.32,0.73l-0.3-0.1l-0.52-0.49l-0.55-0.35l-0.58-0.22
  		l-0.52-0.06l-1.4,0.42l-0.02,0.26l0.63,0.61l-0.03,0.82l-0.36,0.04l-0.68-0.22l-0.68-0.04l-1.18,0.72l-2.24,1.85l0.64,0.29
  		l2.72,0.81l0.46,0.04l0.74-0.25l0.5-0.03l0.15-0.19l0.68-0.39l0.37-0.47l0.34-0.74l0.23-0.07l0.11,0.61l0.17,0.22l0.72-0.35
  		l0.59,0.06l0.61-0.12l0.03-0.25l-0.23-0.4l-0.01-0.26l0.56-0.54l0.14,0.01l0.24,0.45l0.31,0.36l0.18,0.08l0.47,0.21l0.34-0.21
  		l0.73-0.81l0.07-0.8l-0.2-0.05L529.17,764.24z M692.03,804.81l-0.6-0.08l-0.34,0.42l-0.21,0.11l-0.21-0.16l-0.71-1.27l-0.3-0.36
  		l-0.11-0.91l-0.6-0.65l-0.26-0.56l-1.68-1.32l-1.12,0.26l-0.21,0.51l0.3,0.58l0.78,0.3l0.62,0.12l0.39,1.06l0.69,1.34l0.35,0.24
  		l0.04,0.33l0.56,0.78l1.01,1.08l0.69,0.62l0.85,0.29l0.25-0.49l0.25-0.35l0.11-0.98l0.14-0.61l-0.27-0.17L692.03,804.81z
  		 M685.92,798.8l1.22-0.78l-0.01-0.21l-0.18-0.33l-0.34-0.18l-0.92,0.43l-0.94-0.06l-0.57-0.14l-0.87,0.5l0.86,0.98l-0.13,1.69
  		l0.87-0.56l0.45-0.52l0.26-0.18l0.29-0.48L685.92,798.8z M714.31,791.3l-0.35-0.52l-0.98,0.31l-1.67-0.47l-0.54-1.29l-1.45-0.39
  		l-0.78-0.92l-1.59-0.45l-1.29-0.31l-0.98-0.53l-1.15-0.62l-1.15-0.66l-0.87-0.5l-0.64,0.03l-1.45,0.07l-0.07-1.14l-0.45-0.69
  		l0.17-0.61l-0.81-0.31l-1.19-0.46l0.35-1.16l0.26-0.88l-1.06-0.26l-1.52-0.38l0.49-0.79l0.4-0.64l-0.59-0.94l-1.19-1.58l-0.91-1.13
  		l-1.19-1.76l-1.04-1.55l-0.85-1.27l-0.71-0.88l-1-1.57l-1.3-1.78l-1.24-1.4l0.18-0.91l-1.22-1.14l-1.07-1.15l-1.24-1.33l-1.22-0.65
  		l-0.87-0.47l-1.26-0.67l-0.66-0.6l-0.27-0.51l-0.29-0.36l-0.25-0.5l-0.14-0.58l-0.26-0.36l-1.53-0.87l-0.47-0.55l-1.05-0.63
  		l-0.88-0.26l-0.31-0.21l-1.07-1.91l0.17-0.68l0.12-0.68l-0.12-0.35l-1.77-1.46l-0.9-0.61l-0.91-0.63l-1.91,0.81l-1.95,0.83
  		l-1.37,0.3l-1.89,0.42l0.63,0.9l-0.26,0.66l-0.59,0.3l-1-0.04l0,2.23l-0.94,1.59l-1.98,0.03l-1.06,0.55l-1.57,0.81l-1.27,0.65
  		l-1.36,1.05l-0.69-0.15l0.31-0.94l-0.5-1.59l-0.42-1.33l-0.87-0.68l-1.46-1.15l-1.1-0.87l-1.57-1.24l-1.1-0.87l-1.15-0.91
  		l-1.5-1.19l-0.62-1.55l-1.38-0.56l-1.47-0.6l-1.19-1.14l0.41-1.12l0.49-1.35v-0.81l-1.31,0.05l-1.96,0.08l-1.75,0.07l-1.3,0.95
  		l-1.2,0.88l-1.88-0.69l-2.16-0.79l-0.61,1.03l-2-0.52l-2.02-0.52v-3.71v-3.72v-3.74v-3.76v-3.78v-3.8v-3.81v-3.83v-3.85v-3.87
  		v-3.89v-3.91v-3.93v-3.95v-3.97v-3.99v-4.01v-4.03v-4.06v-4.08v-4.1v-4.12v-4.15v-4.17v-4.19v-4.22v-4.24v-4.27v-4.29v-4.32v-4.34
  		v-4.37l-0.66-0.13l-1.76-0.08l-0.41,0.27l-0.58-0.1l-1-0.92l-1.46-0.84l-5.03-1.5l-1.05-1.05l-2.41-1.42l-4.3-1.25l-0.49,0.32
  		l-0.68,0.09l-1.76-0.18l-1.52-0.01l-2.68,0.72l-2.97,0.23l-1.7,0.86l-4.87-0.4l-0.33-0.38l-1.71-0.26l-3.23-1.65l-1.97,0.06
  		l-1.88-0.45l-3.9-0.09l-2.68,0.32l-5.41-0.71l-0.72-0.35l-0.66-0.96l-1.43-0.19l-0.87-0.61l-0.89-0.02l-1.03,0.63l-0.91-0.04
  		l-1.76-1.5l-1.32-0.14l-3.58-1.15l-1.19,0.14l-1.12-0.33l-2.75,0.05l-2.38,1.01l-1.02,0.14l-1.09-0.15l-1.18-0.7l-1-0.3l-2.66,0.69
  		l-1.25,0.2l-0.82,0.5l-6.06-0.51l1.05-0.9l0.17-0.41l0.26-0.34l-3.4,0.05l-0.68-0.18l-0.14-0.71l-1.09-0.09l-0.6-0.51l0.28-1.22
  		l1.72-1.18l-0.57-0.56l-1.61-0.53l-1.51-0.15l-0.96,0.23l-3.77-0.87l-2.23,0.64l-1.72-0.04l-1.83,0.25l-2.33,1.17l-1.66-0.57
  		l-1.74-0.15l-1.57-0.71l0.5-0.52l0.44-0.91l-1.21-0.95l-1.06-0.53l-1.88-0.25l-0.53,0.26l-0.7,1.04l-2.24,1.86l-1.08,0.57
  		l-1.38,0.35l-0.85-0.11l-0.58-0.93l-0.88-0.39l2.88-1.04l1.43-1.02l0.46-0.92l-0.56-0.95l-1.39-0.09l-5.55-1.6l-0.82-0.78
  		l0.59-0.58l0.85-0.27l-0.63-0.17l-2.64,1.38l-1.6,1.38l-1.87,2.12l-1.09,0.83l-2.37,1.52l-2.56,1.25l-0.75,0.23l-4.09,0.07
  		l-0.22,0.32l-0.93,0.32l-3.16-0.04l-0.66,0.45l-1.48,0.36l-0.45-0.06l-0.3-0.44l0.65-0.5l0.67-0.28l-0.07-0.43l-0.34-0.26
  		l-0.7-0.03l-3.09,1.41l-3.37,2.33l1.01,0.01l1.82,1.58l3.03,0.09l-2.5,0.72l-1.11-0.3l-0.23,0.67l-0.13,0.97l0.02,0.99l-0.09,0.69
  		l-0.35-0.8l-1.58-0.03l0.75-1.74l-0.85-0.38l1.21-1.46l-0.69-0.27l-0.6-0.09l-4.35,2.21l-0.11,0.39l-2.94,1.76l-5.41,1.06
  		l-1.09,0.58l-1.93,0.47l-0.64,0.05l0.27-0.22l1.1-0.44l0.79-0.65l0.48-0.14l-0.16-0.3l-0.28-0.19l-0.58-0.64l-0.82,0.67l-0.78,0.92
  		l-2.35,2.02l-5.07,5.07l-1.19,2.21l-0.32,2.34l-0.26,0.99l-0.22,0.11l-0.15-0.18l-0.38,0.7l-2.4,2.61l-2.8,1.98l-2.38,1.12
  		l-1.28,0.37l-4.95,0.5l-1.3,0.29l-3.92,0.22l-5.89-0.26l0.23,1.3l-0.85,3.29l-0.82,2.17l-0.56,0.51l-1.04,0.43l-0.65-0.19
  		l0.86-0.74l-0.83,0.24l-1.2,0.71l1.78,0.57l1.39,0.18l1.46,0.44l2.33,1.78l4.83,1.6l10.62,6.34l1.54,1.86l1.2,2.96l0.67,1.08
  		l1.59,1.33l4.46,1.07l2.02-0.13l1.5,0.26l1.46-1.22l-0.02,0.62l0.17,0.58l3.59-0.42l2.07,0.41l0.82,0.59l-0.92,0.8l-1.24,1.69
  		l0.19,1.47l1.48,0.77l0.92,0.76l1.46,0.56l2.92-1.42l1.58-0.26l1.85,0.93l2.38-0.11l0.83,0.56l0.3,0.9l-0.04,1.24l-3.53,0.66
  		l-1.13-0.16l-2.22-1.26l-0.91-0.3l-1.52-0.01l-2.15,0.52l-2.68-1.41l-1.19-1.52l0.28-1.65l-0.96-0.24l-1.03-1.66l-0.91-0.41
  		l-0.98,0.23l-1.09,0.52l0.54,1.27l0.64,0.98l1.26,0.03l1.07,0.57l2.56,2.82l-0.25,1.14l0.75,0.58l2.39-0.51l3.57,1.02l0.43,0.56
  		l0.3,1.48l-0.63-0.71l-0.77,0.28l-1.21-0.39l-0.93-0.48l-0.85,0.43l-2.19,2.76l-0.99,0.15l-2.36-0.39l-3.14,0.28l-1.14-0.13
  		l-1.39-0.55l-2.4,0.33l-4.41-0.12l-2.85-1.84l0.58-0.59l0.6-0.42l-0.08-1.28l0.92-1.61l0.15-0.54l-0.53-0.43l-0.47-0.2l0.66-0.11
  		l1.49,0.12l-0.75-0.59l-2.78,0.08l-3.39,0.31l-1.8,0.47l-3.28,1.65l-1.13-0.03l-2.11,0.42l-2.75,1.28l-0.3,0.43l-0.24,0.61
  		l2.36,1.09l-0.25,0.31l-0.34,0.19l-0.79,0.26l-2.4-0.12l-0.41-0.08l-1.32-0.6l-1.55,0.36l-1.19,0.61l-1.75,0.68l-1.24,1.29
  		l-0.87,0.76l-0.65,0.39l-2.79,0.25l-1.47,1.41l-2.82,1.07l-0.11-0.46l-0.03-0.47l-0.67,0.4l-0.66,0.85l0.45,0.86l0.4,0.39
  		l4.91,2.01l6.22,1.16l0.47-0.2l3.47,0.65l0.34,0.27l0.3,0.35l-1.33-0.18l-1.45,0.36l-0.67,1.27l-1.95,0.27l-0.79-0.17l-0.42-0.23
  		l-0.19,0.09l0.85,0.84l2.33,1.96l1.14,0.36l0.06,1.36l-0.58,0.4l-0.03,0.95l1.32,1.39l1.54,0.58l5.87,0.95l2.59,0.65l1.34,0.16
  		l0.67-0.09l0.36-0.27l0.23-0.42l0.54-0.25l0.32,0.09l0.3,0.22l3.27-1.04l4.98-0.06l1.91,0.52l1.85,1.01l1.03,0.7l0.34-0.74
  		l0.44-0.56l-1.03-0.18l-0.62-0.41l-0.46-0.58l0.83-0.63l2.76,1.84l0.58,1.93l0.81-0.04l0.63-0.99l2.54-2.2l1.37-0.89l2.56-0.88
  		l0.92-1l1.06,0.32l1.41-0.03l2.35-1.77l0.48-0.02l0.56,0.29l0.81,0.88l0.68,0.6l0.26,0.55l0.17,1l-0.49,0.94l-0.32,0.46l-0.98,0.61
  		l-1.22,0.25l-1.87-0.14l-0.64,1.26l0.88-0.08l1.39,0.59l1.96,1.97l0.7,2.97l0.53,1.3l0.52,1.56l-0.4,1.2l-0.84,0.92l-1.46,1.37
  		l-1.4,0.81l-2.02,0.38l-3.95,0.2l-0.69-0.25l-0.47-0.84l-0.68-0.09l-0.75,0.16l-0.65,1.02l-2.2,2.49l-1.57,0.79l-1.18,1.22
  		l-0.96,0.47l-1.9,0.44l-0.6,0.01l-0.54-0.51l-0.68-0.28l-1.22,0.65l-0.75,0.53l-0.09-0.18l0.2-0.23l0.52-0.3l0.25-0.27l0.15-0.46
  		l-1.01-0.9l-1.74-0.72l-1.39-0.19l-2.54,0.62l-0.46,0.4l-0.52,0.76l1.26,0.98l-0.08,0.31l-0.37-0.13l-2.1,0.26l-0.73,0.66
  		l-0.3,0.69l-0.39,1.55l0.23,1.63l0.22,0.71l0.87-0.7l0.84-0.43l-0.06,0.3l-0.77,1.03l-1.32,0.36l0.41,0.91l0.32,0.2l-0.18,0.2
  		l-0.95-0.48l-0.91-0.21l-0.98,0.28l-0.66,0.51l-2.13,2.22l-2.19,2.66l-0.31,1.16l0.16,0.68l0.16,0.42l0.78,0.76l-1.86,0.42
  		l-1.33,0.03l-0.74,0.4l0.5,0.71l1.78,0.68l-0.22,0.22l-2.24,0.44l-0.26-0.16l-0.31,0.08l0.04,0.8l0.09,0.56l0.49,0.51l1.12-0.57
  		l0.98,0.19l0.41,0.58l-0.92,1.13l0.36,0.88l1.45,0.46l0.54,1.73l0.52,0.81l0.72,0.1l0.84-0.15l0.01-0.8l-0.11-0.56l0.49-0.69
  		l0.51-0.11l0.25,0.08l-0.56,0.53l-0.29,0.38l0.55,0.36l0.64,0.22l0.45-0.44l0.19-0.07l-0.08,0.8l0.51,0.46l1.14-0.27l0.61,0.04
  		l-0.06,0.32l-1.05,0.55l-1.48,1l0.51,0.42l0.41,0.16l2.63-0.14l2.63,0.78l3.76,0.07l2.07-1.34l0.76,0.4l0.61,0.45l-0.58,0.33
  		l-1.53-0.04l-0.58,0.34l2.39,0.4l0.94,0.31l0.77,0.52l-0.91,1.18l-1.7,0.95l-0.77-1l-0.62-0.98l-0.35-0.17l-0.53-0.1l-1.11,0.95
  		l-1.5,1.08l-0.53,0.19l0.43-0.7l0.47-0.99l-0.45-0.59l-0.43-0.31l-1.2-0.3l-1.43-0.67l-1.03-0.26l-0.8,0.24l-0.98,1.7l-2.85,2.53
  		l1.09,0.22l0.94-0.03l0.73,0.32l-0.19,0.46l-0.1,0.66l1.19,0.81l1.01,0.52l1.16,0.04l1.62,1.95l2.85,1.96l-0.08,0.57l-0.01,0.66
  		l1.99,1.13l1.91,0.07l3.88-0.55l2.88-0.97l1.22-0.89l1.36,0.05l0.3-0.61l0.3-1.11l-0.22-0.92l-0.17-0.4l-1.16-0.48l0.72-0.35
  		l1.1-1.24l1.72-2.55l0.55-0.5l0.52-0.65l0.59-0.12l0.89-0.01l0.13,0.13l-1.62,0.9l-1.25,2l-1.12,2.19l1.51,1.34l0.88,2.5l1.93,3.34
  		l0.67,1.57l-0.02,0.93l-0.34,1.08l-0.4,0.78l-0.87,1.02l0.36,1.72l0.76,0.87l0.81-0.41l1.26,0l-1.22,1.16l-0.01,0.82l0.08,0.65
  		l0.47,1.28l-1.11,0.95l-1.28,0.4l-1.15,0.5l3.28,0.4l3.32-0.71l0.28-0.92l0.34-0.21l0.35-0.48l0.26-0.02l2.46-0.88l0.9,0.01
  		l1.35-1.03l1.15-0.65l1.32-0.54l0.86,0.99l0.91,0.81l1.96,1.06l0.74-0.2l0.76-0.72l0.6-0.21l1.82,1.46l0.81,0.89l2.32,3.1l1.11,0.8
  		l1.36-0.45l0.14-0.98l-0.75-2.45l0.2-0.93l0.52-1.34l-0.29-0.88l0.41,0.29l0.69,0.26l0.79-0.72l0.59-1.06l0.77-0.21l1.7,0.65
  		l1.18,0.75l-0.92-0.03l-1.06-0.36l-0.94,0.12l-0.43,0.76l-0.23,1.09l0.54,0.84l0.42,0.46l0.73,1.28l0.94,0.34l1.42-0.32l3-1.34
  		l4.41-1.6l1.5-1.38l1.3-1.81l-0.96,2.12l-0.99,2.37l0.26,0.35l0.29,0.1l-2.14,1.18l-1.95,1.7l-0.53,1l-0.01,0.87l0.3,1.19l0.8,0.23
  		l0.46,0l1.23,0.49l-2.1,0.27l-0.95,0.39l-0.47,1.09l-0.09,1.9l-0.53,1.84l-0.11,0.78l0.14,0.5l0.62,0.45l0.3,0.74l0.32,0.18
  		l0.5,0.09l0.1,0.15l-0.62,0.28l-0.93-0.17l-0.24-0.19l-0.2-0.31l-0.34-0.11l-0.91,0.24l-0.41,0.2l-1.27,1.24l-0.74,0.52l-0.77,0.29
  		l-0.81,0.54l-1.29,1.19l-0.94,1.03l-0.63,0.9l-0.14,0.51l-0.06,0.64l0.03,0.68l0.13,0.72l-0.08,0.4l-0.28,0.08l-0.62-0.09
  		l-0.95-0.25l-0.19-0.37v-0.42l-0.61,0.27l-1.42,1.08l-1.05,0.98l-4.23,1.52l-2.2,1.49l-0.87,0.49l-1.29,0.98l-0.64,0.87l-0.7,1.24
  		l-0.45,0.93l-0.21,0.63l0.1,0.49l0.4,0.35l1.44,0.84l0.32,0.38l-0.18,0.32l-0.47,0.06l-0.75-0.2l-0.51-0.25l-0.27-0.3l-0.59-0.13
  		l-0.9,0.05l-0.44,0.19l0.11,0.98l-0.14,0.18l-0.34,0.03l-0.41-0.21l-1.3-1.37l-0.03-0.29l0.89-0.35l0.21-0.35l-0.18-0.27
  		l-0.59,0.29l-1.49,0.22l-0.4-0.04l-0.25-0.27l0.37-0.44l-0.31-0.08l-4.06,1.35l-2.02,0.98l-1.86,1.24l-1.62,1.47l-1.38,1.71
  		l-1.23,1.17l-1.07,0.63l-0.6,0.52l-0.12,0.4l-0.29,0.27l-0.47,0.14l-0.39-0.04l-0.31-0.21l-0.58,0.13l-1.38,0.85l-0.21,0.3
  		l-0.02,0.44l0.17,0.57l-0.09,0.71l-0.35,0.85l0.02,0.43l0.39,0.02l0.57-0.3l0.75-0.62l0.26-0.67l-0.23-0.72l0.07-0.35l1.04,0.21
  		l1.1,1.08l0.38,0.05l1.22-0.54l0.47-0.49l0.04-0.39l-0.25-1.72l0.12-0.34l0.75,0.05l0.39,0.4l0.58,0.74l0.12,0.45l-0.34,0.16
  		l-0.04,0.18l0.25,0.19l0.35,0.11l0.45,0.02l0.49-0.27l0.53-0.56l0.38-0.26l0.78,0.05l0.79-0.7l2.01-2.26l0.18-0.35l0.31-1.29
  		l0.25-0.39l0.47-0.35l0.69-0.3l0.49-0.13l0.85,0.2l0.86,0.39l0.34,0.23l0.11,0.19l-0.94-0.18l-0.5,0.03l-0.34,0.24l-0.26,0.27
  		l-0.28,0.57l-0.03,0.79l0.14,0.18l0.69,0.13l1.72-0.21l0.66-0.2l0.63-0.41l0.61-0.62l0.47-0.24l1.06,0.35l0.37-0.19l0.37-0.48
  		l0.49-0.14l0.6,0.2l0.46-0.02l0.31-0.24l0.47-0.66l0.29-0.25l1.09-0.3l1.67-1.21l0.7-0.38l0.41,0.03l0.33-0.1l0.54-0.38l0.33-0.1
  		l0.24-0.03l0.54,0.23l0.11,0.35l-0.04,1.77l0.09,0.23l0.42-0.32l0.36-0.5l0.22-0.63l0.15-0.73l0.79-0.39l5.39-1.71l0.72-0.67
  		l0.66-1.33l0.3-0.36l0.31,0.02l0.1,0.19l0.16,1.12l0.16,0.12l0.24-0.16l0.37-0.43l1.32-1.01l-0.13-0.09l-0.22-0.6l-0.23-0.31
  		l-0.36-0.25l-0.68-0.2l-0.58,0.13l-0.13-0.06l0.12-0.27l1.04-1.2l0.52-0.21l1.37-0.29l0.55-0.27l0.45-0.11l0.75,0.01l0.44-0.13
  		l0.01-0.13l-0.42-0.14l-0.84-0.11l0.43-0.48l1.34-0.64l0.83-0.08l0.82,0.22l0.54,0.07l0.26-0.08l0.42-0.46l0.33-1.04l0.41-0.37
  		l0.42-0.23l0.48-0.07l0.53,0.09l0.55-0.04l0.56-0.17l0.61-0.4l0.66-0.63l0.55-0.35l0.44-0.07l0.4-0.26l0.37-0.44l0.57-0.13
  		l0.7,0.07l0.31-0.66l0.76-0.3l0.22-0.19l0.63-1.2l0.02-0.43l-0.39-0.64l-0.25-0.21l-0.04-0.21l0.36-0.39l1.63-1.08l0.73-0.17
  		l0.56,0.22l0.29-0.03l0.15-0.96l0.31-0.22l1.57-0.17l0.3-0.11l0.41-0.71l0.84-0.56l0.28-0.34l0.05-0.4l0.51-0.31l0.97-0.22
  		l0.85-0.37l1.39-0.91l0.41-0.38l0.78-1.25l3.55-0.48l0.03-0.66l0.1-0.1l1.36-0.36l1.07,0.01l0.3-0.15l0.1-0.92l0.23-0.66
  		l-0.62-0.06l-0.06-0.13l1.71-0.75l0.25-0.39l-0.06-0.55l0.36-0.63l1.33-1.17l0.34-0.2l1.03-0.27l0.68-0.35l1.51-1.24l0.63-0.83
  		l0.24-0.44l0.06-0.33l-0.1-0.3l-0.67-0.64l-2.01-0.98l-1.11-0.36l-0.94-0.13l-1.94-0.52l-0.41-0.44l0.33-1.05l0.6-1.2l-0.18-0.34
  		l2.31-1.38l0.52-0.45l0.32-0.44l0.78-0.67l0.11-0.21l-0.52-0.25l0.15-0.15l1.43-0.93l0.57-0.24l0.4,0.13l0.06,0.15l-0.2,0.63
  		l0.16,0.09l1.08-0.14l1.2-0.48l0.38-0.26l0.2-0.8l-0.13-0.21l-1.44-0.4l0.21-0.18l0.68-0.23l2.1-0.29l0.82-0.29l0.84-0.96
  		l0.27-0.55l-0.01-0.54l-0.29-0.52l-0.74-0.66l-1.18-0.8l-1.16-0.62l0.05-0.08l0.86,0.04l1.06,0.58l0.59,0.13l0.62-0.02l0.95-0.34
  		l1.45-0.9l0.65-0.57l0.26-0.36l-0.38-0.55l-0.01-0.24l0.3-0.71l2.31-1.96l1.1-0.66l0.68-0.08l0.29-0.19l-0.29-1l0.02-0.32l0.4-0.67
  		l1.18-0.88l1.12-0.44l1.5-0.36l1.11-0.56l0.72-0.77l1.01-0.67l2.81-1.32l0.38-0.07l0.29,0.08l0.52,0.52l3.06-0.1l1.12-0.15
  		l0.26-0.19l0.6-1.01l0.42-0.52l1.08-0.74l0.59-0.2l1.62-0.19l0.88,0.04l-2.25,1.03l-1.19,0.7l-0.78,0.72l-0.44,0.56l-0.11,0.41
  		l-0.26,0.24l-0.77,0.24l-0.29,0.3l0.72,0.63l3.16,1.64l1.12,0.37l2.67,0.37l0.6,0.64l-0.03,0.12l-0.82-0.05l-3.87-0.91l-1.88-0.13
  		l-1.19,0.35l-0.97,0.03l-0.76-0.29l-0.66-0.38l-0.57-0.48l-0.78-0.01l-2.85,1.39l-1.47,0.94l-3.1,1.25l-0.29,0.25l0.01,0.8
  		l0.32,1.35l0.04,1.11l-0.7,2.43l-0.47,0.91l-1.35,1.4l-1.03,1.31l-0.41,0.85l-0.59,1.75l0.31,0.77l0.45,0.26l2.12,0.61l0.53,0.01
  		l0.39-0.15l2.65-1.59l0.27,0.09l0.09,0.13l-1.2,1.68l-0.61,0.65l-1.16,0.87l-0.95,0.42l-1.52,0.26l-1.33,0.7l-0.29,0.25l-0.4,0.54
  		l-0.27,0.72l0.12,0.25l0.38,0.07l1.4,0.89l1,0.02l1.2-0.54l0.93-0.19l0.66,0.17l0.55,0.04l0.43-0.09l0.06-0.25l-0.3-0.41l0.14-0.14
  		l1,0.28l0.87,0.43l0.22-0.06l0.3-0.67l0.39-0.49l1.48-1.06l0.46-0.66l0.01-0.69l0.12-0.35l0.22-0.02l0.47,0.34l0.34,0.02l1.24-0.58
  		l0.35-0.02l0.32,0.15l0.51,0.06l1.95-1.55l0.05-0.29l-0.47-0.8l0.09-0.09l0.34,0.03l1.38,0.56l0.16-0.16l-0.18-1.03l0.07-0.29
  		l0.68-0.8l0.25,0.31l0.46,1.39l0.14,0.22l0.12-0.05l0.41-1.56l0.75-0.91l0.23-0.44l0.15-1.25l0.16-0.07l0.76,1.16l0.32,0.19
  		l1.22-0.44l0.43,0.42l0.55,0.26l1.36,0.36l0.77,0.04l0.9-0.11l0.59-0.17l0.27-0.22l0.31-0.02l0.35,0.18l0.29-0.18l0.82-1.68
  		l0.35-0.3l0.39-0.02l0.26-0.09l0.14-0.17l-0.18-1.26l0.02-0.7l0.1-0.53l0.18-0.36l0.44-0.27l0.7-0.18l0.46-0.3l0.23-0.42
  		l-0.17-0.49l-0.56-0.56l-0.58-0.1l-0.59,0.36l-0.9,0.19l-1.21,0.03l-0.92,0.19l-0.63,0.36l-0.13-0.04l0.37-0.44l0.73-0.53
  		l0.35-0.09l1.09,0.03l0.28-0.17l0.17-0.33l0.23-0.84l-0.09-0.31l-0.62-0.31l-0.48-0.12l-1.34-0.88l0.01-0.31l0.72-0.11l0.65,0.05
  		l0.41-0.28l0.43-1.1l0.71-1.15l-0.14-0.18l-0.52,0.1l-0.91,0.37l-0.13-0.06l0.18-0.33l0.39-0.3l0.61-0.27l0.52-0.08l0.43,0.11
  		l0.91-0.04l0.53-0.31l1.19-1.02l0.6-0.4l0.18-0.01l-1.85,2.56l-0.29,0.65l0.12,0.26l0.84,0.75l0.7,0.06l1.28-0.31l0.27-0.3
  		l0.26-0.59l0.21-0.2l0.37,0.31l0.76,0.26l0.36,0.41l0.25,0.04l0.34-0.14l0.3-0.27l0.26-0.4l0.5-0.24l0.75-0.07l0.6,0.08l0.46,0.24
  		l0.89-0.35l1.33-0.93l1.12-0.64l1.67-0.1l0.84,0.3l-2.65,0.76l-0.31,0.78l-0.02,1.22l0.29,0.34l0.6,0.41l1.18,0.36l-0.87,0.69
  		l-0.43,0.15l-0.2,0.2l0.57,0.36l1.31-0.48l0.81-0.14l0.58,0.18l0.13,0.25l0.01,0.3l0.15,0.4l3.99,0.12l-0.13,0.37l-0.59,0.76
  		l-0.43,0.47l-0.74,0.59l0.43,0.11l1.09,0.02l1.31,0.34l1.53,0.66l1.12,0.11l0.72-0.45l0.56-0.49l0.94-1.06l1.02-0.81l1.17-0.79
  		l0.28-0.08l-0.8,1.07l-0.32,0.65l-0.32,0.94l-0.33,1.57l0.41,0.51l0.94,0.28l0.59,0.3l0.24,0.31l0.95,0.25l1.66,0.18l1.24,0.51
  		l0.82,0.84l0.04,0.27l-0.65,0.22l0.12,0.37l1.41,0.1l1.47-0.05l2.52-0.53l4.72-0.53l3.34,0.14l3.74,0.66l3.65,0.8l1.18-0.31
  		l0.7-0.31l0.31-0.29l-0.1-0.54l0-0.29l0.11-0.11l0.39,0.16l0.27,0.28l0.33,0.99l-0.04,0.3l-0.96,0.97l0.64,0.36l4.12,1.57
  		l1.64,0.32l1.92,0.16l1.71-0.2l2.53-0.99l0.56-0.31l2.01-1.8l0.78-0.55l0.74,0.06l0.24,0.14l1.35,1.36l2.14,0.73l-0.51,0.09
  		l-1.1-0.15l-0.35,0.25l-0.21,0.47l-0.11,0.49l-0.06,1.01l-0.09,0.53l-0.09,0.12l-0.28-1.6l0.05-1.37l-0.14-0.37l-0.98-1l-0.3-0.09
  		l-0.25,0.13l-0.35,0.25l-0.13,0.26l-0.1,0.4l-0.01,0.41l0.2,0.73l0.45,0.8l-0.06,0.35l-0.83,1.1l-0.53,0.29l-0.77,0.26l-0.28,0.25
  		l0.22,0.24l0.5,0.29l1.16,0.52l1.99,1.08l3.85,1.73l1.52,0.61l1.59,0.27l-0.05,0.17l-0.33,0.29l0.19,0.17l0.72,0.06l0.83,0.28
  		l0.94,0.5l1.8,1.31l0.56,0.62l0.23,0.55l0.59,0.76l0.96,0.97l0.75,0.58l0.54,0.19l0.27,0.23l0.06,0.44l0.11,0.11l3.98,2.29
  		l1.74,0.77l1.4,0.81l0.77,0.28l0.21-0.02l1.01-1.02l2.8-0.28l0.41-0.17l0.21-0.25l-0.05-0.84l-0.35-0.66l-1.03-1.18l-0.8-0.27
  		l-1.38,0.08l0.67-0.77l-0.05-0.26l-1.04-0.49l-0.13-0.15l-0.16-0.42l-0.38-0.28l-1.06-0.51l-1.17-0.39l-0.71-0.02l-0.63,0.21
  		l-0.17-0.09l0.6-0.64l0.34-0.12l-0.04-0.4l-0.42-0.67l0.11-0.17l1.33,0.63l2.23,0.53l0.46,0.42l0.28,0.55l0.22,0.2l0.6,0.24
  		l0.68,0.5l0.62,0.26l0.33-0.06l0.33-0.23l0.19-0.38l0.05-0.53L663.2,757l-0.3-0.66l0.08-1.26l0.14,0.11l0.28,0.49l0.43,1.32
  		l0.28,0.24l0.72-0.37l0.89,0.07l-1.83,0.94l-0.02,0.4l1.31,2.05l0.23,0.56l-0.29,1.39l-0.01,0.78l0.2,0.07l2.54-0.22l1.03,0.44
  		l0.73,0.96l0.51,0.52l1.36,0.28l0.43-0.15l0.25-0.41l0.09-0.34l-0.1-0.42l-0.76-2.11l-0.28-0.96l-0.19-1l-0.42-1.32l-0.65-1.63
  		l-0.44-2.21l-0.98-1.43l0.58-0.1l0.28,0.03l-0.14-0.42l-0.57-0.84l0.6-0.12l0.09-0.43l0.33-0.83l0.12,0.12l-0.08,1.06l0.24,1.07
  		l0.95,2.02l0.72,2.9l0.47,0.57l0.76,0.39l0.18,0.29l0.19,1.18l1.4,2.37l0.95,0.85l1.5,0.22l1.3,0.83l0.62,0.68l0.41,0.14l0.65-0.57
  		l0.8-1.67l0.78-1.02l0.28-0.21l0.29-0.04l-0.1,0.24l-0.38,0.38l-0.94,2.19l-0.15,0.96l0.06,1.01l0.22,0.69l1.15,0.96l0.62,0.69
  		l0.65,1l0.18,0.13l0.55,0.04l0.27-0.19l0.56-0.82l0.52,0l2.55,0.57l-0.15,0.15l-2.52-0.18l-0.21,0.2l0.01,0.38l0.17,0.7l0.67,0.59
  		l2.68,1.95l-0.22,0.13l-1.68-0.92l-1.78-0.78l-0.41,0l-0.2,0.22l-0.18,0.42l0.92,1.07l0.86,2.63l-0.25,1.99l4.65,1.5l0.75-0.1
  		l0.09,0.18l-0.21,0.97l0.08,0.36l0.23,0.43l0.84,0.88l0.53,0.31l1.28,0.36l0.09,1.4l0.35,0.19l0.65,0.1l0.18,0.27l0.04,0.54
  		l0.23,0.45l0.42,0.37l0.62,0.82l0.41,0.25l0.94,0.24l0.5,0.67l0.29,0.6l0.33,0.37l0.37,0.14l2.47,0.27l-1.57,0.54l-0.89,0.01
  		l-1.36,2.73l-0.72,1.07l-0.57,0.7l-0.42,0.32l-0.13,0.38l0.57,1.43l0.31,0.35l1.14,0.41l0.32-0.23l0.64-1.1l0.3-1.04l0.26-0.37
  		l0.04-0.2l-0.23-0.62l0.09-0.22l0.26-0.26l1.25-0.66l2.93-0.95l2.15-0.9l-0.35,0.52l-0.56,0.56l0.11,0.44l1.27,1.76l0.49,0.9
  		l0.33,1.47l0.05,0.72l-0.05,1.08l0.2,1.23l0.18,0.27l0.73,0.17l-0.02,0.25l-1.97,0.62l-0.52,0.76l-0.02,0.26l0.57,1.13l0.38,1.3
  		l0.72,1.67l1.97,0.2l0.34,0.25l0.69-0.75l1.52-1.31l0.83-0.94l-0.04-0.4l0.39-0.9l1.14-1.89l-0.02-0.54l-0.17-0.81l-0.51-1.32
  		l-0.17-0.71l-0.05-0.82l0.08-0.77l0.21-0.71l0.31-0.67l0.42-0.62l0.09-0.74l-0.07-0.76L714.31,791.3z M454.36,760.91l1.72-2.69
  		l-0.45,0.07l-1.83,0.65l-0.83,0.8l-0.4,0.04l0.52,1.22l0.66,0.1L454.36,760.91z M706.53,798.12l0.12-0.93l-0.01-1.2l-0.27-0.69
  		l-1.03-1.53l-0.97-1.09l-0.28-0.08l-3,1.47l-0.19,2.91l-0.94,0.97l-0.73,1.02l0.03,0.68l0.26,1.59l0.41,0.67l0.33-0.62l0.69-0.95
  		l1.01-0.5l0.4-0.38l0.23-0.42l0.21,0.48l-0.24,0.61l0.25,0.48l0.45,0.12l0.43-0.03l0.46,0.57l0.62,0.16l0.89-0.71l0.58-1.32
  		L706.53,798.12z M413,659.27l0.06-0.15l-1.77,0.71l-0.96,0.57l-0.02,0.21l0.33-0.08l1.41-0.71L413,659.27z M802.8,1022.3
  		l-0.17-0.23l-0.92,0.23l-1.71-0.48l-0.3,0.11l0.22,0.34l0.06,0.35l0.64,0.26l2.08-0.37L802.8,1022.3z M414.13,739.63l-0.29-0.41
  		l-0.46-0.27l-0.48-0.13l-1.3,0.19l-0.41-0.04l-0.77-0.63l-0.42-0.17l-0.52,0.07l-0.98,0.33l-0.48-0.21l-0.47-0.01l-1.04,0.56
  		l-1.11,0.29l-0.45,0.25l-0.44,1l-3.5-0.21l-0.78,0.12l-0.78,0.23l1.19,1.4l1.32,1.1l1.3,0.31l3.01,1.5l2.4,0.38l0.69,0.25
  		l0.62,0.52l0.33,0.12l0.35-0.14l-0.21-0.56l0.26-0.37l1.29-0.51l1.49-0.04l1.49-0.25l-0.11-0.75l-0.22-0.7l-0.69-0.52l0.05-0.39
  		l-0.06-0.91l0.21-0.65L414.13,739.63z M408.33,818.71l-1.39,0.22l0.03-0.29l0.48-0.68l1.43-1.02l0.74-0.68l-0.88-0.44l-0.31-0.32
  		l-0.23,0.24l-0.36,0.62l-0.46,0.48l-0.63,0.35l-0.36-0.55l-0.1-1.09l-0.39-0.12l-0.51,0.04l-0.97,0.28l-1.09,0.17l-0.51,0.24
  		l-0.44,0.42l-0.23,0.38l-0.03,0.34l0.13,0.34l0.29,0.35l0.37,0.16l0.46-0.02l0.7,0.14l0.95,0.29l0.3,0.23l-0.53,0.32l-0.04,0.45
  		l-0.08,0.06l-0.16-0.08l-0.47-0.56l-1.02-0.01l-0.22,0.5l-0.42,0.21l-0.37,1.26l-0.57,0.36l-1.85,0.66l-0.9,0.5l-0.91,0.08
  		l-0.6,0.18l-0.83,0.54l0.24,0.27l0.94,0.46l0.34,0.01l0.31-0.15l0.59-0.04l0.36-0.18l0.43-0.39l0.4-0.19l0.37,0.01l0.31-0.11
  		l0.25-0.23l0.99-0.43l1.62-0.45l0.92-0.06l0.68-0.27l0.57-0.7l1.52-0.84l0.66-0.48l0.76-0.25l0.38-0.28l-0.25-0.23L408.33,818.71z
  		 M410.27,817.38l-0.28,0.16l-0.39,0.44l-0.14,0.19l0.01,0.26l0.22,0.29l0.12-0.03l0.22-0.39l0.38-0.35l0.3-0.52l-0.09-0.12
  		L410.27,817.38z M396.17,821.16l-1.33-0.58l-0.74,0.01l-1.01,0.27l-0.8,0.38l0.14,3l2.58-1.23l0.84-0.48l0.19-0.54l0.32-0.58
  		L396.17,821.16z M435.7,807.1l-0.44-0.29l-0.74-0.21L434,806.4l-0.35-0.38l-0.82-1.93l-0.29-0.39l-0.35-0.28l-0.46-0.15l-1.68,0.02
  		l-0.51,0.12l-2.33,0.98l-1.08,0.64l-1.27-0.15l-0.46,0.07l-0.43,0.3l-1.49,2.2l-0.38,0.34l-1.15,0.64l-0.14,0.46l0,0.27l0.32,0.97
  		l0.36,0.49l0.67,0.14l1.29,0.04l1.07-0.27l0.5-0.24l0.48-0.4l0.94-1.03l0.53-0.37l0.83-0.21l4.13-0.05l0.44-0.15l1.45-1.13
  		l2.31,0.78l-0.05-0.2L435.7,807.1z M413.55,813.73l-1.08-0.64l-0.62-0.05l-0.63,0.23l-0.39,0.26l-0.15,0.28l0.03,0.36l0.39,0.69
  		l0.17,0.08l0.87-0.14l0.19,0.12l0.26-0.04l0.32-0.2l0.87-0.12l0.38-0.22l-0.1-0.23L413.55,813.73z M772.56,876.77l0.38,0.2
  		l0.91,0.23l-0.22-0.35l-0.09-0.41l-0.76-0.81l-0.4,0.07l-0.06,0.21l0.14,0.65L772.56,876.77z M439.82,810.14l-1.47-0.38l0.07,0.58
  		l0.66,0.49l0.78,0.26l0.73-0.25l-0.45-0.53L439.82,810.14z M415.88,812.93l-0.39-0.6l-0.14-0.12l-0.33,0.07l-0.54,0.29l0.28,0.81
  		l0.04,0.51l0.09,0.12l0.37-0.09l0.79-0.51l0.5-0.18l-0.29-0.13L415.88,812.93z M1103.7,903.93l0.78,0.13l1.2,0.02l0.59-0.18
  		l0.2-0.35l-0.08-0.23l-0.22-0.11l-0.43-0.59l-0.24-0.17l-0.64-0.1l-0.13,0.11l0.16,0.26l-0.03,0.14l-0.27,0.21l-0.51,0.28
  		l-0.38,0.06l-0.26-0.15l-0.17,0.02l-0.03,0.27l0.08,0.13L1103.7,903.93z M1100.79,900.06l-0.16,0.01l-0.06,0.21l0.09,0.25
  		l0.42,0.41l0.12-0.06l0.04-0.16l-0.03-0.26l-0.15-0.22L1100.79,900.06z M443.03,804.06l-0.24,0.01l-1.18,0.6l0.15,0.42l0.21,0.27
  		l0.58,0.36l0.2-0.05l0.22-0.24l0.33-0.76l-0.04-0.27L443.03,804.06z M1100.45,899.42l0.17,0.26l0.22,0.15l0.26,0.05l0-0.23
  		l-0.26-0.5l-0.01-0.49l0.23-0.48l-0.04-0.3l-0.32-0.12l-0.3,0.06l-0.49,0.51l0.33,0.45L1100.45,899.42z M1168.66,921.39l0.26-0.18
  		l0.04-0.11l-0.37,0l-0.22,0.14l-0.04,0.11l0.06,0.12L1168.66,921.39z M774.64,875.84l0.05-0.19l-0.14-0.57l0.17,0.04l0.38,0.37
  		l0.24-0.04l0.34-0.23l-0.11-0.23l-0.97-0.41l-0.17,0.04l-0.49,0.3l-0.22,0.29l0.14,0.28L774.64,875.84z M518.11,769.68l0.47,0.4
  		l0.22,0.09l0.24-0.34l-0.42-0.58l-0.48-0.43l-1.13-0.47l-0.33,0.19l0.02,0.17l0.44,0.4L518.11,769.68z M1063.39,890.6l-0.06,0.46
  		l0.35,0.33l1.18,0.22l0.06-0.15l-0.05-0.07l-0.17-0.09l0.31-0.42l0.1,0.17l-0.03,0.47l-0.25,0.34l-0.25,0.24l0,0.31l0.25,0.39
  		l0.31-0.09l0.37-0.57l1.02-0.91l-0.03-0.21l0.16-0.34l1.74-1.17l0.34-0.36l-0.07-0.14l-0.37-0.2l0.05-0.06l1.94-0.2l0.42-0.24
  		l-0.44-0.36l-0.71-0.18l-0.98,0l-0.98,0.13l-1.77,0.55l-0.62,0.35l-1.41,1.24L1063.39,890.6z M1058.68,883.29l-0.26,0.32l0.03,0.22
  		l0.32,0.12l0.63-0.11l0.94-0.33l0.33-0.16l0.06-0.09l-0.31-0.05l0.04-0.18l0.37-0.11l2.41-1.14l0.27-0.32l-0.03-0.17l0.13-0.25
  		l0.81-0.61l-0.12-0.09l-0.75,0.19l-1.41,0.59l-3.29,1.62l-0.39,0.25l-0.06,0.13h0.26L1058.68,883.29z M774.84,876.33l-0.14-0.03
  		l-0.22,0.15l-0.17,0.57l0.09,0.3l0.27,0.24l0.45,0.14l0.13-0.11l0.05-0.23l-0.4-0.53L774.84,876.33z M488.07,1138.76l-0.3-0.2
  		l-0.42,0.1l-1.78-0.24l-0.31-0.13l-0.03,0.43l-0.22,0.25l-0.09,0.35l2.27,0.13l1.36,0.36l0.94-0.41l0.3-0.45l-0.25-0.07
  		L488.07,1138.76z M508.28,784.92l-0.89,0.47l-0.89,0.71l-0.27,0.37l-0.14,0.37l0.03,0.23l0.19,0.1l0.58-0.28l1.45-1.02l0.56-0.62
  		l-0.16-0.3L508.28,784.92z M499.12,794.57l0.33-0.37l0.02-0.78l-0.08-0.41l-0.17-0.04l-0.22,0.13l-0.98,0.99l0.12,0.33l0.36,0.12
  		L499.12,794.57z M495.88,1142.44l-0.24-0.31l-0.71-0.42l-0.38-0.4l-1.09-0.58l-0.65,0.09l-0.9,0.23l-0.22-0.16l-0.38-0.53
  		l-0.45-0.36l-0.6,0.09l-0.34,0.66l0.07,0.42l0.63,0.7l0.6,0.28l0.54-0.1l0.26,0.83l0.09,0.77l0.25,0.11l0.83,0.06l0.63-0.26
  		l1.07-0.14l0.79-0.61L495.88,1142.44z M504.61,1152.14l-0.31-0.76l-0.65-0.14l-0.17-1.11l-0.95-1.03l-3.57-1.47l-1.77-0.98
  		l-0.36,0.14l-0.16,0.81l0.61,1.34l-0.75,1.04l-0.67,0.55l-0.51,0.71l0.7,1.38l0.63,1.81l-0.13,2.23l0.2,0.48l1.69,0.89l0.46,0.03
  		l0.37-0.42l0.39-0.84l1.9-1.31l2.16-0.51l1.71-1.17l0.39-0.61l-0.31-0.38L504.61,1152.14z M526.02,772.31l-0.6-0.7l-0.02-0.38
  		l0.56-0.58l0.06-0.29l-0.14-0.24l-0.7-0.31l-0.88,0.03l-0.68,0.2l-1.3,0.69l-0.34,0.08l-0.06-0.67l0.4-0.89l-0.78-0.48l-1.83-0.44
  		l-0.55,0.18l0.48,0.57l-0.05,0.39l-0.22,0.19l0.01,0.53l-0.14,0.3l-0.29,0.06l-0.88-0.17l-0.28,0.08l-0.27,0.23l-0.26,0.39
  		l-0.29,0.2l-0.31,0l-0.37-0.37l-0.79-1.18l-0.28-0.16l-0.62-0.11l-0.31,0.06l-0.3,0.15l-0.53,0.52l-0.01,0.35l0.22,0.41l0.6,0.52
  		l0.97,0.62l0.03,0.28l-0.92-0.07l-0.33,0.13l0.17,0.49l0.17,1.85l0.34,0.93l0.58,0.75l-0.56-0.24l-0.43-0.4l-0.64-0.98l-0.55-1.1
  		l-0.51-0.32l-0.07-0.13l0.18-0.25l-0.29-0.53l-0.73-0.25l-0.53-0.01l-0.86,0.17l-0.89,0.58l-1.25,0.38l-1.16,1.37l-0.33,0.97
  		l0.05,0.37l1.15,1.56l0.59,2.04l1.35,1.39l0.66,0.11l0.45-0.63l0.2-0.5l-0.05-0.37l-0.15-0.33l-0.25-0.28l-0.26-0.15l-0.94,0.04
  		l0.04-0.13l0.44-0.3l0.68-0.14l0.92,0.03l0.59,0.09l0.25,0.14l0.08,0.16l-0.46,0.57l-0.19,0.48l0.16,0.01l0.71-0.36l1.01,0.56
  		l0.64,0.07l0.09,0.1l-2.34,2.03l0.01,0.19l0.16,0.19l0.2,0.13l0.46,0.05l1.81-1.01l0.96-1.23l0.1-0.28l-0.01-0.32l-0.11-0.23
  		l-0.72-0.27l1.22-0.31l0.54-0.31l0.18-0.42l0.5-0.35l1.43-0.71l1.88-0.14l0.52-0.54l0.94-0.47l0.76,0l0.63-0.12l0.29-0.17
  		l-0.11-0.42l-0.23-0.38l-0.52-0.52l-1.48-0.08l-0.34-0.1l0.48-0.35l0.24-0.12l0.68,0.06l1.69,0.38l1.84,0.21l0.26-0.06l0.37-0.27
  		l1.01-1.15l0.01-0.25l-0.18-0.21L526.02,772.31z M457.19,800.26l0.26-0.66l0-0.2l-0.18,0.03l-0.55,0.66l-0.26,0.09l-0.13-0.01
  		l0.16-0.52l-0.05-0.23l-0.2-0.22l-0.24-0.07l-0.55,0.25l-0.43,0.56l-0.06,0.28l0.18,1.61l0.26,0.34l0.37-0.3l0.41-0.08l0.78,0.22
  		l1.02-0.3l0.04-0.15l-0.41-0.42L457.19,800.26z M521.6,778.1l-2.95-0.21l-0.58,0.64l-0.17,0.96l0.67,0.62l0.51-0.34l0.66-0.79
  		l0.19,0.02l1.07-0.39l0.62-0.05l0.22-0.13l-0.01-0.15L521.6,778.1z M510.81,784.82l-0.89,0.46l-0.08,0.38l0.63,0.31l0.41-0.03
  		l0.5-0.18l0.29-0.17l0.07-0.17l0.04-0.29l-0.07-0.26L510.81,784.82z M463.19,800.77l-0.14-0.11l-0.28,0.06l-0.52,0.82l-0.48,0.06
  		l-0.53,0.69l-0.27,0.16l-0.32-0.03l0.17,0.78l-0.01,0.22l-0.08,0.33l-0.53,1.03l0.48-0.22l1.1-1.2l0.33-0.27l0.39-0.14l0.16-0.33
  		l0.51-0.26l0.16-0.19l0.12-0.44l-0.5-0.46L463.19,800.77z M460.72,1132.04l-0.48,0.41l-0.19,0.48l0.07,0.35l0.29,0.06l0.17-0.39
  		l0.22-0.33l0.62-0.25l0.03-0.45l0.24-0.41l-0.44-0.09L460.72,1132.04z M457.95,799.73l-0.21,0.22l0.31,0.29l1.16,0.59l0.12-0.93
  		l-0.28-0.23L457.95,799.73z M467.56,1129.61l-1.91-0.03l-1.24,0.73l-0.53,0.87l0.35,0.46l0.86,0.33l0.32,0.37l0.82,0.08l0.43,0.21
  		l0.74-0.49l0.24-0.36l0.11-0.68l0.25-0.48l-0.03-0.43L467.56,1129.61z M467.2,804.25l0.27-0.06l-0.01-0.32l-0.23-0.48l-0.6-0.21
  		l0.03,0.28l0.31,0.67L467.2,804.25z M465.32,803.19l0.47-0.28l0.22,0.25l0.12-0.15l0.04-0.93l-0.25-0.87l-0.25,0.1l-0.17,0.42
  		l0.06,0.2l-0.08,0.47l-0.35,0.02l0.04,0.25l-0.11,0.33L465.32,803.19z M481.43,1137l-0.13-0.7l-0.37,0.06l-0.29-0.05l-0.25-0.13
  		l-0.21-0.35l0.02-0.36l-0.93-1.3l-0.49,0.08l-0.87,0.8l-1.26,0.13l0.29,0.46l0,0.38l0.85,0.98l0.23,0.51l0.26,0.06l0.83-0.03
  		l-0.31-0.45l0.33-0.09l0.09,0.1l0.08,0.35l0.48-0.12l0.44,0.44l0.43,0.19l0.91-0.1l0.47-0.24l-0.16-0.23L481.43,1137z
  		 M488.38,1141.14l-0.52-0.22l-0.92,0.15l0.52,0.76l0.13,0.6l0.54,0.11l0.5-0.25l0.34-0.51l-0.33-0.41L488.38,1141.14z
  		 M803.39,1029.39l-0.39-0.07l-0.42,0.04l0.27,0.5l0.51,0.09l0.37-0.02l-0.03-0.14L803.39,1029.39z M1167.65,923.96l-0.25,0.06
  		l-0.13,0.14l-0.01,0.22l0.12-0.03l0.26-0.28L1167.65,923.96z M1076.86,911.54l0.16-0.13l0.05-0.36l-0.16-0.21l-0.37-0.07
  		l-0.33,0.25l-0.29,0.57l-0.37,0.36l-0.46,0.16l-0.32,0.37l-0.17,0.59l-0.3,0.52l-0.68,0.8l-0.06,0.22l0.04,0.31l0.14,0.4l0.23,0.26
  		l0.33,0.13l0.3-0.09l0.78-0.9l0.07-0.2l-0.1-0.1l0.11-0.34l0.31-0.6l0.23-0.29l0.15,0.02l0.15-0.12l0.16-0.25l-0.02-0.23l-0.2-0.21
  		l0.01-0.09l0.23,0.04l0.09-0.22l-0.06-0.48l0.1-0.2L1076.86,911.54z M1144.66,934.6l0.26-0.35l0.14-0.2l-0.02-0.11l-0.6-0.11
  		l-0.22,0.12l-0.04,0.28l0.06,0.22l0.18,0.11L1144.66,934.6z M1077.57,909.59l-0.1-0.11l-0.24,0.6l0.17,0.24l0.46,0.04l0.25-0.1
  		l0.04-0.44l-0.04-0.15l-0.14-0.06L1077.57,909.59z M3268.91,827.62l-1.26-0.6l-1,0.17l-1.45-0.37l-1.13,0.06l-1.54,0.79l0.85,0.35
  		l1.07,0.25l0.45,1.01l1.34,0.51l1.22-0.71l0.66,0.04l1.21-0.17l0.39,0.01l0.39-0.11l0.35-0.2L3268.91,827.62z M3313.19,838.35
  		l-0.29,0.3l0.02,0.28l0.29,0.49l0.54-0.19l0.27-0.41l-0.31-0.28L3313.19,838.35z M3305.23,837.05l-0.36,1.04l-0.35,0.3l-0.82,0.18
  		l-1.1,0.82l0.09,0.22l0.57,0.48l0.73-0.47l1.51-0.73l0.02-0.52l0.48-0.74l0.14-0.49l-0.28-0.12L3305.23,837.05z M3319.81,844.79
  		l-0.95-0.55l-0.82-0.65l-1.48-0.98l-1.82-0.45l-0.37,0.14l0.83,0.64l1.51,0.58l2.97,1.82l1.46,0l-0.3-0.32L3319.81,844.79z
  		 M1084.93,912.29l-0.06,0.1l0.18,0.46l0.19,0.14l0.23,0.01l0.1-0.15l-0.03-0.31l-0.16-0.19L1084.93,912.29z M3271.14,833.2
  		l-0.78,0.16l-0.19,0.37l1.8,0.15l0.35,0.39l0.55-0.03l0.18-0.98l0.26-0.55l-1-0.1L3271.14,833.2z M774.51,890.51l0.26,0.43
  		l0.12,0.01l0.08-0.21l0.03-0.12l-0.3-0.62l-0.23,0.22L774.51,890.51z M1047.77,892.4l-0.07,0.33l0.1,0.08l0.28-0.17l0.14-0.19
  		l0-0.21l-0.1-0.08l-0.21,0.05L1047.77,892.4z M777.97,886.58l-0.09-0.94l-0.08-0.08l-0.27-0.14l-0.09,0.07l-0.11,0.33l-0.02,0.51
  		l0.15,0.23l0.46,0.24L777.97,886.58z M1089.25,905.82l-0.29,0.39l-0.29,0.86l-0.04,0.49l0.22,0.12l0.29-0.05l0.36-0.23l0.14-0.4
  		l-0.08-0.91l0.08-0.1L1089.25,905.82z M778.3,888.8l0.01,0.29l0.17,0.05l0.32-0.19l0.04-0.24l-0.4-0.54l-0.22-0.47l-0.15,0.01
  		l-0.17,0.72l-0.03,0.68l0.35-0.34L778.3,888.8z M1098.6,905.5l-1.08-0.15l-0.05,0.17l0.64,0.53l0.39,0.2l0.24-0.02l0.19-0.11
  		l0.13-0.2l-0.05-0.23l-0.31-0.28L1098.6,905.5z M1047.07,893.77l0.1-0.37l-0.07-0.09l-0.28,0.06l-0.63,0.21l-0.01,0.15l0.6,0.09
  		L1047.07,893.77z M1010.65,1066.27l-1.84,1.4l0.42-0.09l1.47-0.98l0.82-0.79l-0.49-0.02L1010.65,1066.27z M1045.01,895.33l0.2,0.03
  		l0.48-0.14l0.25-0.15l0.02-0.16l0.2-0.15l0.54-0.29l-0.06-0.14l-0.19-0.01l-1.12,0.6l-0.25,0.24L1045.01,895.33z M3406.72,777.85
  		l0.23,0.41l0.79,0.21l0.87-0.57l0.37-0.7l-2.28,0.47L3406.72,777.85z M3404.23,830.41l-0.92,0.75l-0.3,0.35l0.09,0.44l0.16,0.13
  		l0.54-0.36l0.35-0.13l0.36,0l0.3-0.19l0.23-0.37l0.02-0.29l-0.2-0.2L3404.23,830.41z M3412.04,828.29l-0.55,0l-1.36,0.37
  		l-0.08,0.24l0.1,0.27l0.87-0.09l1.3,0.24l0.12-0.17l0.14-0.46l-0.16-0.22L3412.04,828.29z M3389.87,738.15l-1.17,0.09l-1.82-0.83
  		l-0.99-0.61l-0.54-1.29l-1.04,0.49l-0.22,0.95l0.97,0.39l2.72,1.7l2.01-0.03l1.39,0.4l-0.38-0.56L3389.87,738.15z M3423.75,823.52
  		l-0.13,0.21l-0.47,0.3l-1.14-0.1l-0.56,0.11l-0.43,0.33l-0.59,0.6l-0.15,0.3l-0.04,0.79l-0.11,0.39l-0.34,0.29l-0.62,0.78
  		l-0.54,0.47l-0.84,0.52l-0.13,0.37l0.19-0.02l2.74-1.4l0.36-0.07l0.85-0.6l0.41-0.23l0.37-0.08l0.5-0.47l0.64-0.86L3423.75,823.52
  		L3423.75,823.52z M3389.17,833.92l-0.62,0.39l-0.32,0.32l-0.32,0.6l0.68,0.17l0.63-0.17l1.27-0.65l-0.58-0.49L3389.17,833.92z
  		 M3413.76,784.59l-0.62,0.16l-1.11-0.24l-0.09,0.32l1.12,0.75l0.39,0.04l0.95-0.62l-0.1-0.28L3413.76,784.59z M3420.41,701.63
  		l0.38-1.29l-2.36-0.49l-1.9-0.02l-0.62-0.12l-1.12,0.12l-1.07-0.33l-0.27-0.44l-0.31-0.32l-1.29-0.23l-2.02-0.58l-0.33-0.47
  		l-0.22-0.66l-0.33-0.55l-0.42-0.31l-1.08-0.53l-1.1-0.24l-1.02,0.14l-1.02,0.26l-1.7,1l-1.35,0.11l-1.36-0.32l-1.23-0.14
  		l-0.88,0.05l-0.13-0.33l-1.55-1.16l-0.84,0.32l-0.48,1.64l-0.12,0.68l-0.01,0.7l0.24,0.7l0.45,0.41l0.89,0.57l0.95,0.26l0.99-0.1
  		l0.96-0.44l0.94-0.59l0.97-0.4l0.9-0.09l0.89,0.11l2.71,0.87l0.87,0.4l0.85,0.51l0.43,0.36l0.25,0.69l0.45,0.48l0.62,0.03
  		l1.07,0.27l1.05,0.43l0.38,0.24l0.34,0.38l0.49,1.37l0.37,0.45l0.45-0.16l0.43-0.37l0.1-0.81l0.74-0.84l0.89-0.66l2.15-0.18
  		l2.16,0.18L3420.41,701.63z M3374.86,833.55l-1.16,0.48l-0.49,0.41l-0.59,0.28l0.24,0.24l1.24,0.3l-0.4,0.6l-1.42,0.36l-1.63,0.55
  		l-2.09,0.46l-1.7,0.53l-0.81,0.1l-0.69,0.18l0.69,0.32l4.52-0.46l2.81-0.48l1.88-0.65l-0.49-0.73l0.14-0.26l0.91-0.26l0.21-0.49
  		l0.1-0.47l-0.23-0.4L3374.86,833.55z M3349.91,839.08l-0.29,0.23l-0.37,0.77l-0.4,0.41l-0.65,0.32l-2.8,0.62l-0.02,0.23l0.13,0.27
  		l0.65-0.2l0.87-0.08l0.78-0.04l1.28,0.13l0.45-0.11l0.24-0.15l0.11-1.01l0.12-0.32l0.35-0.35l0.14-0.4l-0.4-0.3L3349.91,839.08z
  		 M3355.45,840.12l-0.96,0.05l-0.05-0.38l0.35-0.59l0.01-0.28l-0.4-0.42l-0.86-0.05l-0.39,1.03l0.07,0.61l-0.31,0.24l-0.85,0.32
  		l-0.73,2.09l1.05-0.81l0.56,0.51l1.8-0.92l0.39-0.38l0.35,0.16l0.15-0.05l0.13-0.21l0-0.74L3355.45,840.12z M3344.28,840.09
  		l-0.56-0.23l-0.74-0.65l-1.37,0.03l-0.44,0.14l-0.22,0.23l0.35,0.38l0.91,0.53l0.49,0.41l0.08,0.3l-0.19,0.23l-0.66,0.29l0.16,0.21
  		l1.12,0.62l0.2,0.01l0.19-0.37l0.44-0.41l0.47-1.03l0.39-0.27l0.68-0.28L3344.28,840.09z M3355.95,840.09l0.15,0.91l0.39,0.3
  		l0.53-0.66l0.05-0.12l-0.83-0.66L3355.95,840.09z M3321.57,838.52l-0.05,0.53l0.44,0.44l0.81,0.16l0.69-0.28l0.44-0.69l-1.29-0.72
  		L3321.57,838.52z M3383.75,837.16l-2.05,0.05l-1.65-0.46l-0.87-0.08l-1.03,0.29l-0.13,0.16l-1.22-0.3l-0.45,0.09l0.02,0.22
  		l0.5,0.35l0.43,0.21l0.36,0.06l1.38-0.16l1.78,0.24l0.78-0.05l1.15-0.25l0.45,0.06l1.31-0.19l-0.01-0.13L3383.75,837.16z
  		 M3357.81,839.57l-0.09,0.58l0.94,0.5l0.71-0.25l-0.53-0.35L3357.81,839.57z M3359.18,837.37l-0.39-0.2l-0.66,0.01l-0.24,0.49
  		l0.06,0.3l0.29,0.29l0.82,0.35l0.2-0.33l0.39-0.3l-0.11-0.23L3359.18,837.37z M1045.71,893.68l-0.04-0.15l-0.21-0.22l-0.15-0.03
  		l-0.09,0.15l0.04,0.15l0.32,0.18L1045.71,893.68z M1128.84,1106.61l-0.08,0.13h0.16l0.36-0.15l0.01-0.13l-0.12-0.1L1128.84,1106.61
  		z M1126.32,1107.57l0.4,0.15l0.39-0.21l0.47-0.1l0.49-0.28l-1.3,0.23L1126.32,1107.57z M1123.94,1107.22l-0.01,0.15l0.36,0.59v0.27
  		l0.12,0.06l0.25-0.18l0.13-0.15l-0.02-0.16L1123.94,1107.22z M1165.91,1015.41l-0.77,1.18l0.21-0.11l1.23-1.52l1.27-1.28
  		l-0.41,0.23L1165.91,1015.41z M1130.3,1105.9l0.23-0.04l0.2-0.31l-0.09-0.03L1130.3,1105.9z M1163.92,1015.94l0.1,0.15l0.46,0.2
  		l0.33,0.1l0.19-0.02l-0.64-0.38L1163.92,1015.94z M1120.65,1108.93l0.02,0.14l0.21-0.02l0.37-0.09l0.01-0.19l-0.24-0.01
  		L1120.65,1108.93z M1132.94,1102.34l-0.03,0.57l-0.41,0.48l-0.44,0.27l-0.21,0.42l-0.65,0.9l-0.19,0.42l1.67-1.68l1.05-1.83
  		l-0.2,0.06L1132.94,1102.34z M1122.6,1108.29l-0.44,0.35l0.55-0.09l0.29-0.13l0-0.25l-0.26-0.42L1122.6,1108.29z M1118.34,1091.62
  		l0.01-0.53l-0.31-1.02l-0.4-0.32l0.28,0.98L1118.34,1091.62z M1061.22,1059.25l0.33,1.18l-0.23,1.4l0.24-0.29l0.12-1.15l-0.11-0.62
  		L1061.22,1059.25z M1060.6,1062.71l-0.02,0.18l0.46-0.48l0.14-0.37l-0.24,0.19L1060.6,1062.71z M1036.55,1063.47l-0.66-0.4
  		l-0.42-0.03l-0.69,0.31l0.09,0.34l1.39,0.79l0.31-0.13l0.27-0.35l0.06-0.26l-0.11-0.16L1036.55,1063.47z M1062.43,1057.35
  		l0.08,0.18l0.45,0.18l0.75,0.19l0.11-0.1l-0.13-0.12L1062.43,1057.35z M1066.3,1057.43l-0.44,0.13l0.22,0.07l1.1,0.02l0.74-0.2
  		l-0.32-0.2L1066.3,1057.43z M1117.89,1091.77l-0.53-0.64l0.14,0.6l0.33,0.31l0.61,0.17l0.3-0.23l-0.66-0.07L1117.89,1091.77z
  		 M1094.83,1062.72l-1.04,0.47l-0.41-0.1l-0.57,0.05l0.91,0.24l0.85-0.33l1.44-0.82l-0.63,0.14L1094.83,1062.72z M1231.27,921.61
  		l-0.12,0.08l-0.1,0.17l0.01,0.51l0.34,0.07l0.32-0.2l-0.28-0.48L1231.27,921.61z M1245.28,915.29l-0.78-0.18l-0.06-0.62l0.28-0.47
  		l-0.18-1.02l-0.24-0.53l0.05-0.32l-0.39-0.13l-0.36-0.11l-0.31-0.09l-0.18,0.15l-0.17,0.2l-0.21,0.15l-0.44-0.21l-0.28-0.38
  		l-0.44-0.39l-0.17-0.29l0.09-0.34l0.2-0.33l0.09-0.39l-0.22-0.45l-0.2-0.26l-0.14-0.29l0.05-0.28l0.28-0.14l0.26-0.17l0.09-0.37
  		l-0.16-0.39l-0.14-0.05l0,0.01l-0.53,0.19l-0.95-0.13l-1.29-0.71l-0.71-0.73l0.52-0.17l0.01-0.15l0.07-0.27l0.14-0.23l0.01-0.26
  		l-0.06-0.18l-0.01-0.15l0.04-0.18l0.09-0.37l-0.15-0.27l-0.02-0.94l-0.03-1.76l-0.02-1.35l-0.03-1.7l-0.02-1.24l-0.02-1.74
  		l-0.02-1.65l-0.03-1.56l-1.08-0.9l-1.36-1.14l-1.17-0.76l-0.63-0.09l-0.4,0.11l-0.16,0.3l-0.87,0.32l-1.58,0.34l-1.35,0.53
  		l-0.49,0.01l-0.42-0.09l-0.56-0.27l-0.38-0.4l-0.13-0.69l0.12-0.94l-0.81-0.19l-0.81-0.19l-0.5,0.65l-0.48,0.55l-0.95,1.19
  		l-1.33,1.67l-0.74,0.92l-1.3,1.61l-1.15,1.42l-0.26,1.45l-0.24,1.38l-0.95,1.05l-0.58,0.96l-0.26,1.06l-0.22,0.97l-0.02,0.81
  		l0.16,0.43l-0.08,0.35l-0.31,0.4l-0.63,0.69l-0.11,0.67l-0.38,0.33l-1.09,0.66l-0.89,0.97l-0.04,0.55l0.13,0.45l0.02,0.28
  		l-0.18,0.2l-0.36-0.01l-0.39,0.06l-0.33,0.4l0.01,0.58l-0.24,0.42l-0.28,0.09l-0.24-0.29l-0.29-0.44l-0.33-0.04l-0.51,0.29
  		l-0.63,0.48l-0.56,0.03l-1.06-0.31l-0.77,0.93l-0.83,2.01l-3.5,0.01l-3.5,0l-3.51,0.01l-3.5,0.01l-3.5,0l-3.5,0.01l-3.5,0l-1.96,0
  		l-0.38,0l-0.45,0.05l-0.79-0.05l-1.18,0.35l-1.54,0.73l-1.87,1.32l-3.29,2.85l-0.05,0.07l0.1,0.07l0.09,0.26l-0.13,0.3l-0.35,0.34
  		l-3.42,2.21l-0.97,0.79l0.36,0.45l0.03,0.2l0.21-0.03l0.7-0.38l0.2,0.04l0.1,0.1l0.06,0.25l-0.32,0.43l0.14,0.1l0.62-0.1l0.26,0.05
  		l-0.24,0.32l-0.32,0.21l-0.05,0.2l-0.47,0.54l-0.21,0.11l-0.12-0.07l0.03-0.24l-0.44,0.26l-0.03,0.21l0.25,0.2l0.2,0.31l0.14,0.42
  		l0.07,0.9l0.09,0.18l0.04,0.08l-0.04,0.1l-0.04,0.21l0.09,0.19l-0.08,0.22l-0.25,0.25l-0.49,0.13l-0.73,0.02l-0.81,0.28l-0.9,0.55
  		l-0.62,0.5l-0.34,0.45l-0.25,0.19l-0.16-0.08l-1.21,0.37l-0.43,0.25l-0.11,0.22l-0.11,0.04l-0.11-0.14l-0.86-0.11l-1.62-0.07
  		l-1.26,0.07l-0.91,0.21l-0.8-0.12l-0.7-0.45l-1.1-0.35l-2.84-0.38l-2.38,0.07l-1.95,0.27l-3.06,0.78l-0.07,0.02l0.02,0.05
  		l-0.06,1.75l1.33,0.36l0.11,0.13l-0.05,0.25l-0.2,0.37l0.04,0.5l0.28,0.64l0.12,0.51l-0.05,0.37l-0.34,0.36l-0.63,0.34l-0.58,0.52
  		l-0.53,0.7l-0.71,0.57l-0.89,0.44l-0.85,0.55l-0.81,0.67l-1.62,0.92l-3.13,1.56l-0.41,0.18l-0.37,0.2l-0.71,0.34l-0.7,0.35
  		l-4.5,1.49l-3.03,1.24l-1.84,1l-1.6,1.24l-0.72,0.38l-0.79,0.1l-1.25-0.07l-1.31,0.15l-1.36,0.36l-0.88,0.29l-0.4,0.23l-0.39,0.07
  		l-0.37-0.09l-1-0.6l-0.07,0.04l-0.19,0.09l-0.59-0.1l-0.67-0.01l-0.58,0.22l-0.58,0.04l-0.57-0.15l0.48-0.21l2.28-0.46l-0.03-0.08
  		l-0.82-0.26l-0.25-0.24l-0.21,0.02l-0.16,0.28l-0.3,0.12l-0.44-0.04l-0.44-0.21l-0.45-0.38l-1.46-0.62l-0.41-0.32l-0.52-0.21
  		l-0.76-0.11l-0.11-0.18l0.13-0.24l0.67-1.14l0.39-0.44l0.16-0.12l0.21,0l0.09-0.12l-0.03-0.24l0.08-0.15l0.43-0.42l0.24-0.22
  		l-0.1-0.35l0.11-0.64l0.28-0.82l0.31-0.59l0.35-0.37l0.39-0.25l0.76-0.29l0.2-0.19l0.16-0.48l0.11-0.78l0.25-0.5l0.38-0.21
  		l0.1-0.24l-0.17-0.27l0.14-0.26l0.46-0.25l0.39-0.07l0.31,0.1l0.14,0.26l0.02,0.68l-0.08,0.2l-0.05,0.17l0.84-0.68l0.48-1.17
  		l0.6-2.83l-0.12-0.22l-0.25-0.43l-0.46-2.03l-0.68-3.63l-0.56-2.25l-0.44-0.87l-0.6-0.66l-0.76-0.46l-0.58-0.2l-0.4,0.06
  		l-0.63,0.37l-1.18,0.32l-0.48,0.21l-0.28,0.29l-0.33,0.17l-0.37,0.05l-0.04,0.08l0.29,0.11l0,0.28l-0.8,1.24l-0.18,0.28l-0.48,0.1
  		l-0.4,0.24l-0.41,0.62l-0.39,0.16l-0.57-0.06l-0.95-0.38l-0.57-0.38l-0.19-0.38l-0.01-0.46l0.17-0.53l-0.02-0.43l0.36-0.89
  		l0.48-0.32l0.71-0.07l0.39-0.14l0.07-0.21l0.22-0.15l0.36-0.09l0.31-0.57l0.25-1.05l0.29-0.57l0.21-0.1l0.2,0.03l0.01,0.13
  		l0.81-0.67l0.3-0.47l0.02-0.48l0.08-1.4l0.1-0.9l0.12-0.41l-0.04-1.06l-0.24-0.87l-0.91-0.96l-0.13-0.32l0.03-0.34l0.19-0.36
  		l0.41-0.08l0.71,0.28l0.12-0.07l-0.2-0.16l-0.12-0.32l-0.03-0.48l-0.09-0.26l-0.15-0.05l-0.4-0.55l-0.05-0.28l0.04-0.26l-0.15-0.26
  		l-0.53-0.41l-0.97-0.42l-2.32-0.8l-0.42-0.31l-0.57-0.18l-0.73-0.06l-0.46-0.24l-0.2-0.43l-0.32-0.39l-0.45-0.35l-0.71-0.23
  		l-0.97-0.11l-1.02-0.38l-1.08-0.65l-0.68-0.22l-0.29,0.21l-0.5,0.07l-0.72-0.07l-0.25,0.09l0.22,0.25l-0.19,0.39l-0.59,0.52
  		l-0.34,0.53l-0.09,0.54l0.09,0.48l0.28,0.42l0.41,0.27l0.53,0.12l0.11,0.17l-0.31,0.21l-0.55,0.12l-0.79,0.03l-0.8,0.23l-0.81,0.43
  		l-0.47,0.39l-0.13,0.35l-0.02,0.5l0.1,1.22l-0.06,0.47l-0.35,0.87l-0.46,1.13l-0.54,0.71l-0.1-0.1l0.09-0.35l0.45-1.13l0.07-0.48
  		l-0.04-0.25l-0.16-0.02l-0.18,0.22l-0.2,0.46l-0.04,0.25l0.11,0.04l-0.09,0.31l-0.29,0.59l-0.25,0.12l-0.21-0.35l0.02-0.46
  		l0.34-0.92l-0.12-0.18l0.2-0.57l0.09-0.42l-0.1-0.43l0.08-0.4l0.27-0.36l-0.08-0.11l-0.43,0.14l-0.61,0.68l-0.8,1.21l-0.62,0.53
  		l-0.44-0.15l-0.36,0.1l-0.27,0.35l-0.29,0.17l-0.32-0.01l-0.19,0.42l-0.07,0.85l-0.31,0.48l-0.94,0.29l-0.18,0.22l-0.02,0.31
  		l0.15,0.41l0,0.81l-0.14,1.22l-0.31,1.06l-0.48,0.91l-0.53,0.75l-0.87,0.99l-0.01,0.2l0.43,0.77l0.18,0.57l0.09,1.14l-0.02,0.19
  		l-0.84,1.41l-0.01,0.2l0.9,2.36l0.8,1.69l0.09,0.18l0.24,0.67l0.44,1.32l0.23,1.31l0.01,1.29l-0.11,1.25l-0.23,1.2l-0.34,1.15
  		l-0.45,1.1l-0.46,0.87l-0.95,1.43l-0.46,0.95l-0.48,0.77l-0.5,0.58l-0.9,0.65l-2.31,1.2l-0.72,0.23l-0.8,0.09l-0.87-0.06
  		l-0.47-0.13l-0.11-0.22l-0.19-0.02l-0.25-0.15l-0.32-0.25l-0.35-0.46l-0.38-0.94l-0.41-1.42l-0.37-0.85l-0.32-0.27l-0.29-0.43
  		l-0.26-0.58l-0.13-1.31l0-2.05l0.09-1.39l0.17-0.74l-0.05-0.57l-0.27-0.39l-0.16-0.49l-0.06-0.58l-0.14-0.4l-0.22-0.21l-0.05-0.29
  		l0.12-0.37l0-0.26l-0.12-0.14l-0.03-0.21l0.06-0.28l-0.11-0.82l0.03-0.5l0.15-0.54l0.48-0.98l0.15-0.48l0.04-0.49l0.23-0.62
  		l0.63-1.28l0.01-0.29l-0.21-0.58l-0.05-0.43l0.05-0.58l0.2-0.71l0.35-0.85l0.46-0.68l0.58-0.51l0.19-0.58l-0.2-0.65l0.07-0.96
  		l0.34-1.27l0.38-0.95l0.42-0.63l0.29-0.6l0.16-0.57l-0.07-0.26l-0.32-0.27l-0.31-0.4l-0.51-0.09l-0.63,0.27l-0.09-0.02l-0.32,0.04
  		l-0.61,0.59l-0.54,1.02l-0.39,0.42l-0.54,0.29l-0.4,0.32l-0.26,0.35l-0.34,0.08l-0.42-0.2l-0.08-0.48l0.26-0.77l0.39-0.74l0.8-1.21
  		l0.03-0.29l0.2-0.35l1.24-0.52l0.47-0.41l0.16-0.52l0.05-0.77l0.08-0.22l0.18-0.09l0.54-0.65l0.91-1.21l0.81-1.25l0.72-1.29
  		l0.53-0.73l0.33-0.16l0.25-0.39l0.38-1.2l0.29-0.55l0.19-0.21l0.09,0.13l-0.18,0.66l0,0.55l0.16,0.7l0.31,0.24l0.45-0.22l0.39-0.46
  		l0.33-0.7l0.38-0.29l0.43,0.12l0.35-0.05l0.28-0.21l0.54-0.1l0.1,0.27l-0.07,0.53l-0.17,0.26l-0.28-0.02l-0.31,0.28l-0.35,0.58
  		l0.01,0.46l0.38,0.34l0.19,0.02l0-0.31l0.18-0.31l0.57-0.57l0.05-0.18l1.08-0.5l0.41-0.32l0.11-0.36l0.56-0.74l0.53-0.24l0.72-0.1
  		l0.7,0.07l0.68,0.23l0.38-0.01l0.09-0.24l0.58-0.16l1.06-0.08l0.78-0.35l0.5-0.61l0.64-0.31l0.78-0.01l1.06,0.21l1.34,0.44
  		l1.08,0.6l1.43,1.2l0.38,0.12l0.25,0.01l0.12-0.09l0.01-0.12l-0.11-0.14l0.07-0.51l-0.07-0.15l0.42-0.8l0.36-0.2l0.42,0.07
  		l0.27,0.17l0.11,0.27l0.13,0.1l0.26,0.01l0.15,0.04l0.09-0.02l0.13-0.15l0.9,0.07l3.05,0.4l0.42-0.06l0.24-0.14l0.06-0.21l-0.2-0.2
  		l-0.8-0.43l-0.24-0.28l-0.06-0.27l0.11-0.25l-0.17-0.23l-0.45-0.2l-1-0.06l-0.18-0.2l0.08-0.2l0.29-0.26l0.01-0.38l-0.09-0.22
  		l-0.13-0.65l-0.22-0.44l-0.44-0.65l-0.17-0.24l-0.26-0.14l-0.24,0.03l-0.31,0.12l-0.36,0.28l-0.52,0.3l-0.53-0.07l-0.37-0.41
  		l-0.53-0.09l-0.69,0.24l-0.61-0.01l-0.53-0.26l-1.01-0.12l-0.12-0.19l0.02-0.2l0.16-0.21l0.09-0.51l0.02-0.8l0.14-0.56l0.26-0.33
  		l-0.39-0.14l-1.04,0.06l-1.2,0.27l-1.35,0.48l-1.47,0.17l-1.59-0.14l-1.2,0.02l-1.33,0.24l-0.23-0.08l-0.84,0.32l-1.44,0.72
  		l-1.08,0.67l-0.9,0.75l-0.14,0.01l-0.21-0.17l-0.45-0.23l-0.48-0.01l-0.51,0.22l-0.52-0.14l-0.53-0.49l-0.48-0.14l-0.43,0.22
  		l-0.72,0.1l-1.58-0.12l-0.17-0.16l-0.14-0.53l-0.17-0.23l-0.31-0.16l-0.49-0.59l-1.08-1.51l-0.3-0.18l-0.88-0.47l-0.84-0.21
  		l-2.12-0.29l-0.46-0.21l-0.27,0.04l-1.4,0.82l-0.06,0.18l-0.08,0.17l-0.23,0.29l-0.18,0.13l-0.11-0.13l-0.11-0.24l0.02-0.53
  		l0.15-0.81l-0.09-0.53l-0.32-0.24l-0.14-0.29l0.05-0.34l-0.23-0.28l-0.51-0.21l-0.24-0.27l0.04-0.34l-0.05-0.23l-0.14-0.12
  		l-0.55,0.26l-0.96,0.65l-0.65,0.58l-0.35,0.51l-0.51,0.29l-0.68,0.07l-0.54,0.22l-0.97,0.75l-0.72,0.38l-1.19,0.28l-1.67,0.18
  		l-1.39,0.48l-1.12,0.79l-1.35,0.67l-1.58,0.55l-1.07,0.19l-0.57-0.16l-0.68-0.34l-1.01-0.61l-0.15,0.06l0.08,0.13l-0.02,0.1
  		l-0.19,0.17l-1.29,0.45l-0.17-0.17l0.48-0.62l0.19-0.46l-0.01-0.42l0.21-0.52l0.44-0.62l0.16-0.42l-0.11-0.22l-0.49-0.36
  		l-0.39-0.02l-0.5,0.12l-1.53,0.91l-0.15-0.07l-0.49-0.15l-0.12,0.03l-1.17,0.79l-1.2,0.47l-2.83,0.68l-0.72,0.08l-0.56-0.05
  		l-0.72-0.39l-0.29-0.27l0.02-0.29l0.33-0.31l3.53-2.45l2.18-1.75l3.61-3.29l0.93-0.66l2.1-1.1l3.37-1.06l1.66-0.59l1.44-0.64
  		l0.92-0.49l0.4-0.34l0.32-0.16l0.25,0.02l0.23-0.11l-1.67-0.15l-1.06,0.21l-0.78-0.21l-0.39-0.67l-0.44-0.43l-1.92,0.2l-2.42-0.14
  		l-1.16,0.09l-0.45-0.28l-0.36-0.75l-0.64-0.09l-1.07,0.17l-1.49,0.96l-1.4,0.49l-1.1,0l-1.09-0.5l-1.78-1l-1.24-1.12l-1.4-0.39
  		l-1.07,0.1l-0.42,0.56h-0.56l-0.39-0.96l-0.33-0.75l-0.7-0.32l-1.26-0.72l-0.88-0.39l-1.34-0.48l-0.47-0.09l-0.87-0.06l-0.87-0.04
  		l-1.01,0.13l-0.72,0.6l-0.85,0.26l-1.21,0.12l-0.81-0.25l-0.4-0.63l-1.71-0.56l-3.02-0.49l-1.74-0.42l-0.46-0.34l-0.25-0.37
  		l-0.06-0.59l0,0l-0.76-1.51l-0.33-1.26l-0.15-1.52l0.05-0.5l-0.17-0.16l-0.54-0.33l-1.82-0.22l-0.03,1.81l-0.03,2.3l-1.99-0.02
  		h-3.59h-3.59h-3.59h-3.59h-3.59h-3.59h-3.59h-3.59l-3.59,0h-3.59h-3.59h-3.59h-3.59h-3.59h-3.59h-3.59h-3.59h-3.59h-3.59h-3.59
  		h-3.59h-3.59h-3.59h-3.59h-3.59h-3.59h-3.59h-3.59h-3.59h-3.59h-3.59h-3.59l-3.59,0h-3.59h-3.59h-3.59h-3.59h-3.59h-3.59h-3.59
  		h-3.59h-3.59h-3.59h-3.59h-3.59h-3.59h-3.59h-3.59h-3.59h-3.59h-3.59h-3.59h-3.59h-3.59h-3.59h-3.59l-3.59,0h-3.59h-3.59h-3.59
  		h-3.59h-3.59h-3.59h-3.59h-0.86l0.56,1.52l0.31,0.64l0.28,0.33l0.45-0.04l0.16-0.14l0.15,0.02l0.14,0.17l0.27,1l-0.02,1.23
  		l0.12,0.19l0.04,0.34l-0.39,0.19l-0.72-0.11l-0.25,0.09l-0.1,0.27l0.05,0.2l0.21,0.14l0.47,0.05l0.34,0.19l0.45,0.39l0.67,0.87
  		l0.04,0.27l-0.54,0.12l-0.45,0.32l-0.07,0.32l0.1,0.43l0.19,0.31l0.84,0.5l0.07-0.06l-0.32-0.92l0.07-0.09l0.23,0.19l0.3,0.56
  		l0.3,0.36l0.47,0.41l0.16,0.34l-0.64,0.83l-0.1,0.37l-0.53,0.84l-0.08,0.39l0.17,0.34l-0.01,0.38l-0.2,0.42l-0.03,0.26l0.25,0.26
  		l0.1,0.26l-0.06,0.81l0.2,1.42l-0.02,0.25l-0.56,0.63l-0.38,0.17l-0.39,0.01l-0.26,0.21l-0.52,1.16l-0.19,0.24l-0.63,0.35
  		l-0.24-0.01l-0.69-0.36l-0.86,0.15l-0.96-0.08l0.34-0.36l0.27-0.77l0.31-0.48l0.77-0.5l0.13,0.08l0.25,1.1l0.13,0.08l0.39-0.92
  		l0.11-0.12l0.5,0.37l0.38,0.07l0.22-0.2l0.2-1.19l-0.03-0.62l-0.24-0.7l-0.76-0.9l0.09-0.05l0.3,0.1l0.12-0.08l-0.14-0.83l0.1-0.21
  		l0.8-0.6l0.11-0.5l-0.19-1.11l-0.45-0.09l-0.06,0.12l0.04,0.65l-0.17,0.22l-0.92,0.79l-0.33,0.66l-0.48,0.45l-0.83,0.55l-0.59,0.51
  		l-0.56,0.85l-0.1,0.27l-0.06,0.58l0.41-0.02l0.85-0.18l-0.04,0.11l-0.92,0.51l-0.62,0.13l-0.27-0.08l-0.02-0.32l0.07-0.55
  		l0.68-1.22l1.93-2.58l0.13,0.1l0.03,0.52l0.2-0.03l1.03-1.53l-0.19-0.54l-0.51-0.87l-0.25-0.67l0.01-0.47l-0.09-0.19l-0.69,0.51
  		l-0.4,0.14l-0.55,0.04l-0.42-0.09l-0.84-0.75l-0.32-0.04l-0.74,0.33l-0.38,0.05l-5.74-0.53l-1.04-0.34l-0.65-0.46l-2.14-0.63
  		l-1.71-0.8l-0.65-0.06l0.25,1.02l-0.18,1.44l0.33,1.91l0.35,0.75l1.36,1.28l0.71,1.34l0.56,2.71l0.93,2.09l0.24,1.3l0.06,0.76
  		l0.2,0.64l0.19-0.48l0.04-0.37l0.59,0.06l0.47,0.48l1.21,0.23l-2.27,1.07l0.34,1.25l0.96,0.38l0.58,0.51l-0.2,1.34l-0.28,1.06
  		l-0.59-0.94l-0.23-0.88l-0.05,1.21l0.04,1.24l-0.23,0.98l0.95-0.22l0.54,0.35l1.75-0.34l0.32,0.34l1.56-0.04l0.51,0.53l0.89,0.53
  		l0.4,0.04l0.26,0.14l-0.85,0.1l-0.68-0.12l-0.54-0.57l-0.47-0.14l-1.28,0.42l-2,0l-0.43-0.46l-0.22,0.06l0.12,0.43l0.24,0.4
  		l-0.12,3.12l0.27,2.78l-0.28,1.05l0.12,0.79l-0.93,6.48l0.12,1.34l-0.18,1.33l0.15,0.98l-0.44,0.95l-0.27,2.87l-0.15,3.74
  		l-0.3,0.41l-0.46,1.14l-0.41,1.33l0.46-0.27l0.31,0.13l-0.66,0.57l-0.38-0.01l-0.22,0.27l-0.91,3.36l-0.37,0.77l-0.35,1.26
  		l0.81,1.45l0.32,0.88l-0.12,2.05l0.09,0.78l0.46,1.83l1.21,1.4l0.02,0.97l-0.3,1.01l0.69,0.69l0.38,0.98l0.39,1.63l0.03,0.76
  		l-0.6,2.29l0.06,1.86l-0.56,1.47l-0.16,0.31l-0.03,0.16l0.27,0.03l-0.15,0.26l-0.1,0.49l-0.26-0.07l0.07-0.24l-0.1-0.12l-0.25,0.3
  		l-0.34,1.12l-0.4,1.06l0.13,1.19l0.27,1.18l1.82,1.56l1.89,2.31l0.43,0.84l0.42,1.55l0.05,1.02l-0.36,1.44l0.85,2.53l0.15,2
  		l2.33,2.27l1.14,1.37l1.42,0.85l0.63,1.41l0.5,0.27l0.66,0.79l0.26,0.71l-0.85-1.01l0.08,1.27l-0.28,0.76l0.02,0.3l0.56-0.65
  		l0.5,0.29l0.95,0.78l0.67,0.42l0.81,0.27l0.53,0.46l0.46-0.11l-0.24-1.12l0.09-1.51l0.76-0.35l0.47,0.09l1.09,0.62l0.46,0.07
  		l1.03-0.57l0.32,0.03l0.5,0.32l0.45,0.12l1.11-0.05l0.56,0.06l0.48-0.09l0.85,0.27l-0.4,0.03l-0.55-0.09l-0.66,0.26l-3.12-0.15
  		l-1.1,0.09l-0.82,0.32l-0.6,0.45l0.17,0.38l0.27,0.24l0.32,1.03l0.62,0.57l0.54,1.02l0.29,0.8l0.23,0.25l0.22,0.39l-0.41-0.04
  		l-0.4-0.18l-0.53-0.6l-0.58-0.27l-0.61-0.62l-0.17-0.83l0.05-0.46l-0.52-0.09l-0.58,0.25l0.12,1.16l0.01,1.07l0.76,1.64l0.11,1.6
  		l1.94,2.09l2.39,0.5l0.62,0.85l0.11,0.48l0.04,0.66l-0.38,0.72l-0.7,0.82l0.07,1.34l0.28,0.98l1.79,1.7l1.68,2.17l0.26,0.61
  		l0.76,0.69l0.51,1.11l1.23,0.66l0.97,1.21l1.04,0.53l0.33,0.57l-0.21,0.86l0.23,0.62l1.27,0.49l0.4,0.34l0.21,0.44l-0.25,1.21
  		l0.32,1.3l-0.11,0.59l0.09,0.76l-0.15,0.84l0.71,0.34l0.66,0.68l0.71,0.11l1.91-0.16l0.98,0.07l1.68,0.54l1.18,0.12l0.9-0.17
  		l1.62,0.75l1.23,0.77l0.27,0.88l0.78,0.49l2.63,0.83l1.96-0.1l0.78,0.17l0.95,1.5l-0.15,1.08l0.98,0.3l0.25-0.44l0.86,0.07
  		l0.69,0.27l1.08,0.97l1.38,0.76l2.7,2.28l1.25,1.83l0.47,1.51l0.06,0.61l-0.13,0.63l0.23,1.33l0.5-0.22l0.39,0.36l0.06,1.02
  		l0.02,0.06l2.41-0.2l2.41-0.2l2.41-0.2l2.41-0.2l2.41-0.2l2.41-0.2l2.41-0.2l2.41-0.2l0.96-0.1l-0.53,1.41l-0.4,0.53l3.99,1.38
  		l3.99,1.38l3.99,1.38l3.99,1.38l3.99,1.38l3.99,1.37l3.99,1.37l3.99,1.37l2.98-0.01l2.98-0.01l2.98-0.01l2.98-0.01l2.98-0.01
  		l2.98-0.01l2.98-0.01l2.98-0.01l0.01-1.04l0.01-1.04l0.01-1.04l0.01-1.04l1.85,0.01l1.85,0.01l1.85,0.01l1.85,0.01l1.85,0.01
  		l1.85,0.01l1.85,0.01l1.85,0.01l0.07,0.02l0.08,0.04l0.75,0.79l0.77,1.25l0.91,0.87l1.04,0.49l1.78,1.45l2.52,2.41l2.01,1.6
  		l1.5,0.8l1.01,0.69l0.51,0.58l0.69,1.25l1.3,2.88v1.32l0.5,1.25l1,1.62l0.87,0.95l0.74,0.29l0.81,0.57l0.88,0.86l1.02,0.58
  		l1.15,0.29l1.59,0.77l2.03,1.24l1.39,0.63l0.75,0.03l0.66-0.4l0.57-0.82l0.56-0.53l0.55-0.24l0.22-0.38l-0.1-0.52l0.37-1.18
  		l0.84-1.83l1-0.99l1.17-0.15l0.76-0.34l0.36-0.53l0.62-0.06l0.89,0.42l1.45,0.27l2.01,0.12l1.19-0.04l0.36-0.2l0.19,0.01l0.01,0.22
  		l0.3,0.1l0.58-0.03l0.51,0.31l0.65,0.99l2.23,1.59l0,0l0.19,0.55l0.78,0.79l1.43,1.21l0.81,1.04l0.19,0.87l0.73,1.38l1.27,1.89
  		l0.57,1.02l-0.14,0.15l0.1,0.53l0.34,0.91l0.63,0.77l0.92,0.63l0.93,1.14l0.94,1.64l1.14,1.24l1.34,0.85l0.76,0.79l0.18,0.73
  		l-0.01,0.63l-0.2,0.52l0.09,0.5l0.38,0.47l0.13,0.57l-0.15,0.8l0.01,0.23l0,0l0.11,0.18l1.19,1.37l0.61,1.11l0.48,1.76l0,0.02
  		l0.54,1.05l0.78,0.43l1.2,0.16l0.91,0.37l0.62,0.57l0.78,0.35l0.95,0.12l0.91,0.38l0.87,0.64l1.62,0.42l2.37,0.2l1.8,0.52
  		l1.24,0.84l0.54,0.17l0.15,0.01l0.07-0.13l0.09-0.24l0.48-0.27l1.14-0.18l0.05-0.61l-0.09-0.32l-0.54-0.02l-1.59-2.95l-0.28-0.8
  		l-0.26-1.85l-0.24-0.61l-0.28-1.33l-0.24-0.54l0.32-0.77l0.34-0.58l0.01-0.5l-0.09-0.58l-1.75-0.45l-0.64-1.54l0.73,0.57l1.33,0.73
  		l0.37-0.02l0.34-0.11l0.49-0.82l0.77-2.27l-1.2-1.51l0.23-0.2l0.25-0.1l1.03,0.14l0.68-0.23l0.83-0.97l0.33-0.98l-0.9,0.3
  		l-0.12-0.38l-0.01-0.38l0.51-0.13l0.68-0.05l0.41-0.24l0.28-0.32l0.11,0.35l0.24,0.25l0.44-0.33l0.27-0.23l0.1-0.91l0.18-0.92
  		l0.82,0.73l0.97-0.23l0.61-0.35l0.57-0.47l-0.46-0.2l-0.41-0.09l-0.97-2l0.27-0.13l0.28,0.07l0.13,0.28l0.28,0.33l0.65,0.49
  		l0.63-0.33l0.01-0.24l0.83,0.02l1.35,0.3l0.88-0.09l-1.02,0.65l-0.86,0.65l0.45-0.12l1.35-0.77l1.41-0.49l1.02-0.65l0.64-0.3
  		l2.26-1.4l0.96-0.6l1.02-1.05l0.11-0.81l1.02-0.84l1.1-1.02l-0.4-0.82l-0.48-0.65l-0.25-1.57l0.34-0.09l0.44,0.3l0.34,0.03
  		l0.48-0.69l0.76,0.02l0.15,0.87l-0.45,0.98l0.38,0.11l1.07-0.3l0.67,0.18l-1.89,1.19l-0.08,0.31l1.56-0.92l4-1.7l1.76-0.17
  		l0.03-0.31l-0.5-0.85l0.88-1.51l0.4,0.02l0.21,0.23l-0.03,0.35l-0.3,0.58l-0.33,0.29l-0.3,0.08l0.15,0.5l0.33,0.28l0.51-0.25
  		l0.6-0.16l2.58-0.06l0.89-0.12l0.91,0.1l1.88,0.59l1.36,0.73l1.01,0.34l3.46,0.37l1.49-0.33l0.21-0.22l-0.46-0.46l-0.18-0.29
  		l0.47-0.56l0.53-0.36l1.05-0.33l0.58,0.78l1.28,0.04l0.91,1.29l0.43,0.46l1.54,0.38l0.45-0.45l0.24-0.01l-0.1,0.54l0.14,0.44
  		l0.75,0.98l0.04,0.3l-0.73-0.12l-0.38,0.09l-0.06,0.29l2.42,0.87l2.12,0.57l0.62-0.18l0.77-1.1l0.71-0.26l1.04,0.04l0.65,0.36
  		l0.46,1.14l0.29,0.24l0.65-0.28l0.29-0.42l0.16-0.53l0.08-0.52l0.18-0.37l0-0.86l-0.41-0.29l-0.34-0.15l-0.16-0.23l0.01-0.3
  		l2.37,0.72l0.5,0.38l0.18,0.32l0.04,0.43l0.64,0.19l0.37-0.03l0.44,0.13l0.36,0.32l0.47,0.17l0.66,0.5l0.45,0.81l0.3,0.32
  		l-0.19,0.81l0.38-0.16l0.8-0.75l0.34,0.25l0.33,0.34l0.19-0.27l0.2-0.48l0.74-0.4l0.05-0.55l-0.42-0.14l-0.43-0.27l-0.54-0.8
  		l-0.55,0.02l-2.26-0.79l-0.56-0.6l-0.8-0.48l-0.39-0.74l0.27-0.24l0.05-0.26l0.18-0.08l0.35,0.09l0.52-0.22l-0.27-0.25l0.51-0.43
  		l0.63-0.11l0.85-0.33l-0.07-0.18l0.04-0.74l-0.36-0.52l-0.11-0.3l0.11-0.32l-0.79-0.11l-0.58,0.51l-0.22,0.8l-0.36,0.1l-0.94-0.24
  		l-0.6-0.71l0.32-0.35l0.53-0.3l0.42-0.36l-0.02-0.25l-0.59-0.25l-0.3,0.32l-0.33,0.12l-0.69-0.02l-0.84,0.61l-1.53,0.28l-0.92-0.33
  		l-1.08-0.69l0.68-1.26l0.9-0.94l0.84,0.09l0.68,0.16l0.77,0.76l3.08,0.94l1.22-0.53l1.04-1.12l0.48,0.02l0.34,0.1l1.43-0.33
  		l1.25-0.43l0.27-0.01l0.45,0.09l1.08,0.47l2.88-0.17l0.85,0.09l0.96-0.03l0.16-0.45l0.32-1.39l0.39-1.06l0.18-0.12l0.53,0.62
  		l0.22,0.6l-0.01,1.03l0.22,0.33l0.34,0.06l0.37,0.56l0.19,0.51l-0.96,0.3l-0.68,0.04l-0.18,0.22l3.23-0.31l0.92-0.95l0.55-0.24
  		l0.04,0.28l-0.48,0.5l0.21,0.14l1.64-0.41l0.25-0.53l0.56-0.52l0.12-0.78l0.44,0l0.39,0.35l0.32-0.49l0.31-0.15l0.27,0.63
  		l-0.17,0.65l-1.16,0.31l-0.34,0.21l-0.32,0.32l1.97-0.3l2.43-0.28l0.62-0.2l0.7-0.39l0.64-0.26l0.62,0.12l0.98-0.1l0.77,0.26
  		l0.24,0.21l0.12,0.33l-0.98-0.21l-1.81,0.28l2.35,0.61l2.69,1.09l0.55,0.39l0.29,0.05l0.11-0.31l0.02-0.4l0.55-0.32l0.61-0.07
  		l-0.32,0.46l-0.19,0.44l0.45,0.38l-0.11,0.29l-0.44-0.04l1.44,1.34l1.27,0.92l0.39,0.71l-0.07,0.36l-0.18,0.16l-0.4-0.41
  		l-0.26-0.53v0.69l0.32,0.66l0.48,0.14l1.12-0.25l1.32-0.12l0.51-0.22l0.68-0.3l0.74,0.04l2.11-1.14l0.81-0.11l0.6,0.03l0.2-0.2
  		l-0.14-0.49l0.17-0.43l0.39-0.33l2.24-0.36l2.95,1.63l3.4,4.34l4.39,3.66l0.99,1.5l0.06,0.69l-0.06,0.38l-0.08,2.59l-0.74,2.26
  		l-0.8,3.54l0.3,0.63l0.27,0.38l0.27,0.23l0.23-0.21l0.46,0.13l0.29-0.25l0.13-0.28l0.12-0.87l-0.31-0.22l-0.35-0.6l0.33-0.16
  		l0.47,0.21l0.5,0.73l0.19,0.09l0.44-0.32l0.34,0.36l0.04,0.25l-0.25,0.58l-0.76,0.84l-0.97,1.39l-0.43,0.08l-0.23,0.14l0.5,0.46
  		l0.29,0.44l1.51,3.08l0.74,1.12l0.54,0.58l0.4,0.2l0.52,0.08l0.1-0.31l-0.11-0.56l0.72-0.24l0.69,0.02l-0.45,0.63l-0.09,1.69
  		l0.32,1.37l0.28,0.11l0.31-0.11l0.42-0.71l0.33-0.3l0.45-0.2l-0.56,0.81l-0.53,0.96l0.23,0.2l0.55,0.29l0.46,2.59l0.81,1.46
  		l1.24,0.82l1.71,0.54l0.17,0.89l0.99,1.33l0.96,1.93l0.48,0.26l0.64,0.24l0.34,0.42l-0.21,0.36l-1.11-0.85l-0.32,0.08l-0.19,0.36
  		l-0.07,0.36l0.48,0.81l0.83,0.04l1.26-0.38l1.06,0.18l1.51-0.68l0.61,0.02l0.99-0.91l0.33-0.86l0.23-1.71l0.69-1.1l0.51-1.22
  		l0.13,0.04l0.06,0.28l0.08,0.08l0.13-2.67l0.58-3.93l-0.07-2.15l-0.33-1.68l-0.31-0.8l-0.85-1.12l-3.57-8.81l-0.31-0.83l-0.52-0.99
  		l-0.32-1.63l-0.26-0.68l-0.17-1.11l0.25-0.01l0.32,0.24l-0.07,0.45l0.12,0.46l0.55,0.29l0.1,0.2l-0.34,0.57l-0.02,0.48l0.32,1.07
  		l0.24-0.27l0.1-0.7l0.18-0.6l0.22-0.04l-0.13,1.84l0.11,1.3l0.93,2.21l0.36,0.31l-0.64-1.54l-0.33-1.01l-0.1-0.82l0.03-0.85
  		l0.11-0.56l0.37-0.54l-0.34-0.64l-2.83-4.5l-1.72-3.72l-1.22-3.08l-0.74-3.19l-0.41-1.18l-0.6-3.41l-0.39-0.84l-0.1-0.65
  		l-0.04-0.67l0.17-0.36l0.25-0.88l0.15-0.73l-0.06-0.36l0.16-0.67l0.25,0.19l0.4,0.07l0.64-0.85l-0.34-0.46l-0.41-0.17l-0.03-0.19
  		l0.72-0.17l0.31-0.6l0.33-0.33l0.37-0.55l-0.17-0.07l-0.23,0.1l-0.3-0.1l0.14-0.33l0.61-0.33l0.04-0.33l-0.18-0.19l-0.1-0.35
  		l0.3-0.37l0.54-0.09l0.27-0.32l0.01-0.24l-0.26-0.26l-0.15-0.35l0.26-0.14l0.31,0.02l1.03-0.49l0.43-0.79l-0.08-0.36l0.28-0.42
  		l0.49-0.11l0.28-0.15l0.54-0.68l-0.07-0.28l-0.27-0.18l-0.27-0.31l-0.27-0.61l-0.04-0.79l0.79,1.03l0.22-0.11l0.04-0.3l0.26-0.14
  		l0.18,0.65l0.15,0.31l0.24,0.05l0.55-0.35l0.23-0.26l0.1-0.66l-0.47-0.49l-0.88-0.34l0.52-0.21l0.94,0.12l0.83,0.19l0.8-0.34
  		l0.33-0.37l0.42-0.15l0.49,0.04l0.85-0.29l0.68-0.44l0.4-0.58l-0.33-0.76l1.08,0.21l0.59-0.35l1.01-0.79l0.23-0.86l0.74-0.25
  		l0.66-0.14l1.21-0.87l0.39-0.47l-0.44-1.22l0.46-0.84l-0.01,0.39l-0.09,0.48l0.38,0.64l0.47-1.52l1.84-2.38l0.66-0.62l2.22-1.41
  		l1.45-0.42l3.31,0.05l0.36-0.77l0.15-1.66l0.23,0.91l-0.06,0.79l0.04,0.47l0.34-1.04l0.23-0.93l0.93-1.28l0.45-0.44l0.4-0.24
  		l1.11-0.89l1.16-0.71l-0.19-0.27l-0.09-0.35l0.01-1.31l0.23,0.35l0.22,0.7l0.52,0.16l0.37-0.12l0.99-0.87l0.71,0.1l1.29-0.04
  		l0.84-0.03l0.53-0.03l0.22-0.43l0.75-0.17l0.85-0.07l0.65-0.62l0.65-0.89l-0.8-0.5l-2.43,0.46l-1.29-0.28l-0.64-0.52l-0.8-1.23
  		l1.76,1.42l0.69,0.14l1.27-0.79l0.12-0.29l0.05-0.46l0.35-0.59l0.45-0.53l-0.34-0.34l-0.36-0.23l-3.19-1.23l-0.55-0.66l1.29,0.61
  		l1.23,0.3l0.9-0.21l0.19-0.73l0.28-0.03l0.38,0.23l0.14,0.69l0.22,0.38l0.36-0.1l0.48,0.06l0.97,0.31l0.85,0.14l0.59-0.25
  		l1.16-1.22l1.62-1.32l0.25-1.13l-0.12-0.74l-0.11-0.54l-0.34-0.53l-0.35-0.04l-1.05,0.61l-0.19,1.66l-0.38,0.3l-0.32,0.01
  		l0.07-0.93l0.13-0.87l-0.08-0.88l-1.15-0.2l-0.48,0.23l-0.8,0.14l-1.22-0.03l-0.91,0.12l-0.97-0.13l0.06-0.73l-0.18-0.96l0.05-0.92
  		l0.14,0.78l0.33,0.7l1.01,0.57l0.68-0.12l0.46-0.38l0.34-0.63l0.53-0.04l0.79,0.21l-0.36-0.71l0.41,0.22l0.6,0.2l0.08-0.66
  		l-0.06-0.62l0.78,0.43l0.88,0.24l0.57,0.32l0.53,0.6l-0.39-1.49l-0.49-1.11l-0.45-0.44l-0.12-0.43l0.28-0.94l-0.12-0.28l0.07-0.36
  		l0.16-0.21l0.24,0.25l0.2,0.64l0.71,2.84l0.69,1.61l1.24,2.22l0.39,0.51l-0.21-0.58l-1.68-3.35l-1.12-4.11l-0.43-1.05l-0.21-0.93
  		l-0.28-0.49l-1.22-0.17l-0.84-0.21l-1.31,0.61l-0.74-0.07l-0.14-0.62l-1.09-0.83l-0.32-1.21l-0.79-0.11l-1.35-0.4l-0.65-0.45
  		l-1.64-0.24l-0.26-0.13l-0.2-0.19l2.05,0.11l2.56,0.97l0.61-0.04l0.24,0.76l0.8,0.68l0.38,0.4l0.51,0.38l0.53-0.21l0.46-0.38
  		l-0.15-0.56l-0.22-0.37l-0.62-0.61l-0.81-0.33l-0.96-0.73l-1.07-1.22l-0.15-0.29l-0.02-0.25l1.85,1.9l0.71,0.35l0.51-0.26
  		l-0.1-0.31l0.04-0.52l1.16,0.28l0.08-0.71l-0.12-0.63l-0.83-0.34l0.52-0.4l-1.5-0.56l-0.55-0.39l-0.59-0.83l-0.81-0.53l-1.13-1.26
  		l-0.64-0.9l-1.57-1.29l0.34-0.01l1.1,0.7l0.94,1.3l0.3,0.23l2.53,2.49l0.47,0.11l0.78-0.05l0.32-0.45l0.1-0.71l0.26-0.52
  		l-0.02-0.44l-0.76-0.68l-0.98-0.47l-0.65-0.81l-0.8-0.38l-2.2-0.62l-1.18-1.56l-0.53-0.13l-1.03,0.29l-0.35-0.11l-0.34-0.44
  		l0.25-1.3l0.2-0.69l0.81-0.75l0.61-0.42l0.39-0.55l0.13-1.11l0.1,1.09l-0.3,0.7l-0.68,0.55l-0.72,1.07l-0.17,0.45l0.08,0.85
  		l0.65,0.1l0.67-0.44l0.64-0.03l0.11,0.5l0.32,0.46l0.51,0.54l0.19-0.44l0-0.52l0.83,1.24l1.48,0.34l1.17,0.53l0.44,0.47l0.51,0.37
  		l0.07-0.52l-0.28-0.55l-0.36-0.7l-1.96-1.82l-0.22-0.81l-0.07-0.73l0.15,0.32l0.1,0.4l0.63,1l0.53,0.31l0.6,0.41l0.38-0.07
  		l-0.19-0.5l-0.35-0.53l-0.36-0.56l-0.3-2.06l0.13-0.45l0.05-0.51l-0.03-0.57l0.22-0.46l-0.21-0.55l-0.33-0.63l0.1-0.03l0.62,0.37
  		l0.45-0.42l-0.06-0.51l-0.52-0.32l-0.71-0.94l0.03-0.15l1.26,0.44l0.15-0.27l-0.02-0.5l0.39-0.2l0.12-0.39l-0.02-0.23l0.14-0.16
  		l0.46,0.8l0.16-0.29l0.08-0.85l0.21,0.18l0.06,0.4l0.63-0.23l0.37-0.29l0.14-0.37l-0.04-0.56l0.19-0.34l0.48-0.07l0.4-0.16
  		l-0.1,0.79l0.82-0.06l-0.34,0.42l-0.35,0.09l-0.41,0.48l0.54,0.12l0.53,0.22l-0.84,0.09l-0.83-0.02l-0.66,0.53l-0.7,1.21l0.16,1.26
  		l0.71-0.58l-0.02,0.4l-0.43,0.9l-0.5-0.18l-0.57,0l-0.14,0.56l-0.01,0.43l0.76-0.34l0.42,0.27l0.19,0.62l-0.66,0.29l-0.45,0.04
  		l-0.34,1.06l0.28-0.13l0.25-0.49l0.55,0.14l0.32,0.51l0.99,0.84l0.34-0.04l0.14,0.23l-0.94,0l-0.72-0.17l-0.55,0.18l-0.26,1.03
  		l0.25,0.57l0.45,0.73l0.8,0.43l0.55,0.37l0.26-0.15l0.11-0.27l0.18-0.09l0.15,0.4l0.25-0.18l0.41-0.45l0.25-0.06l-0.15,0.42
  		l0.11,0.56l-0.18,0.46l-0.36,0.44l0.31,0.21l0.3,0.07l0.51,0.52l-0.28,0.53l-0.18,0.6l0.97-0.02l0.64,0.19l-0.51,1.29l-0.62,0.63
  		l-0.81,1.33l-0.73,2.14l-0.19,1.3l0.11,0.5l0.42,0.58l0.68-1.4l0.35-1.24l0.38-0.46l0.57-0.42l0.57-0.18l0.37-0.23l-0.08-0.7
  		l2.05-4.22l0.52-0.62l0.56-1.1l0.55-0.12l0.16-0.42l0.06-0.84l0.15-0.21l0.23-0.19l0.14,0.15l-0.01,0.43l0.19-0.17l0.11-0.42
  		l0.02-0.75l-0.31-0.74l-0.32-0.2l-0.64,0.08l0.49-0.4l0.37-0.89l-0.04-0.54l-0.81-0.41l-1.06-1.44l-0.69-1.24l-0.17-1.85l-0.9-1.2
  		l-0.46-0.73l0.06-0.75l-0.12-0.36l-0.05-0.51l0.72-0.76l0.32-0.63l0.54-0.5l0.67-0.33l1.25-0.3l0.83-0.88l-0.25,0.51l-0.42,0.61
  		l-1.68,0.4l-0.58,0.4l-0.42,0.76l-0.44,1.09l0.04,0.69l-0.04,0.41l1.44,1.48l1.03,0.55l0.8,0.75l0.72-0.03l0.63,0.22l0.66,0.42
  		l-0.2,0.97l-0.29,0.95l0.26,0.09l1.09-0.6l1.25-2.02l0.35-0.39l0.02-0.44l0.2-0.23l0.52-0.3l0.36,0.04l0.38-0.44l0.15-0.66
  		l0.18-0.32l-0.14-0.61l0.64,0.13l0.62-0.77l0.68-1.11l0.49-2.09l0.18-0.37l0.26-0.17l-0.16,1.62l0.03,0.41l0.26-1.33l0.17-1.49
  		l0.2-0.97l0.27-0.79l0.12-0.77l-0.12-0.72l-0.22-0.51l-0.43,0.22l-1.61-0.26l-0.19-0.72l0.32-0.79l0.33-0.4l0.29-0.25l0.31-0.14
  		l0.41-0.32l0.35-0.37l0.83-1.58l0.15-0.82l-0.07-1.4l-0.44-1.14l0.38,0.32l0.36,0.48l0.09,1.15l-0.29,1.43l-0.35,0.74l-0.32,0.87
  		l0.34-0.26l0.31-0.39l0.5-0.15l0.61-0.47l0.91-0.87l0.74-0.56l3.38-1.54l1.34-0.41l0.83-0.69l0.65,0.19l3.1-0.1l0.91-0.36
  		l0.89,0.21l1.61-0.35l1.21-0.15l1.35,0.1l2.08-0.48l0.67-0.75l0.15-1.8l0.53-0.7l-0.22-0.93l0.26,0.09l0.24,0.24l0.17,0.43
  		l0.33,0.39l0.78-0.63l0.25-0.02l-0.47,1.05l0.13,1.25l0.17,0.27l0.75-0.49l0.89-0.1l2.3-1.67l0.29,0.05l0.09,1.03l-0.11,0.5
  		l0.09,0.24l1.48-0.48l0.65-0.45l2.91-0.51l0.61-0.07l0.32,0.07l0.12-0.33l-0.07-0.98l-0.31-1.55l-1.1-1.18l-0.43-0.19l-0.37-0.04
  		l-0.32,0.1l0.04,0.2l0.34,0.36l0.2-0.28l0.52,0.33l0.17,0.51l0.71,1.08l0.04,0.46l-1.13,0.57l-1.35,0.41l-1.11-0.28l-0.74-0.46
  		l-0.29-1.36l-0.9-0.49l0.09-0.35l0.23-0.19l-1.02-1.9l-0.67-0.36l-1.51-0.35l-0.42-0.31l0.97-1.02l0.5-0.66l0.33-0.57l0.67-0.18
  		l0.76-0.47l0.41-0.07l0.07-0.27l-0.17-0.22l-0.26-0.02l-0.35,0.1l-0.33-0.05l-0.38-0.53l-0.16-0.54l-0.24-0.52l0.43-1.17l0.38-1.32
  		l0.35-0.4l0.41-0.26l1.02-2.19l1.36-1.34l1.32-1.49l-0.3-0.31l-0.26-0.16l0.76-0.97l0.98-0.7l0.82-0.21l-0.08,0.38l0,0.31
  		l0.41-0.09l0.45-0.23l0.27,0.3l0.27,0.18l0.14-0.34l-0.1-0.63l0.07-0.45l0.19,0.12l0.09,0.39l0.27,0.09l0.26-1.06l0.39-0.4
  		l0.14,0.46l0.11,0.7l0.29-0.06l0.28-0.99l0.13,0.21l0.17,0.67l0.34-0.08l0.39-0.53l0.76-0.46l1,0.15l0.75-0.53l0.58-0.61l0.04-0.77
  		l0.9-1.81l-0.04-0.89l0.96-0.53l0.4-0.67l0.32-0.22l-0.02,0.63l-0.25,0.57l0.15,0.09l0.35-0.09l0.22,0.12l-0.71,0.63l-0.15,0.44
  		l0.75-0.03l0.94,0.33l0.33,0.41l0.34,0.13l0.15-0.47l-0.06-0.79l0.35-0.68l0.24-0.64l0.28,0.4l0.36,0.25l0.48-0.3l0.33-0.35
  		l0.27-0.08l0.4,0l0.39,0.14l0.29,0.12l0.2,0.54l0.31,0.56l0.36-0.17l0.25-0.2l0.19-0.46l0.47,0.11l0.57-1.27l0.41-0.1l0.54,0.2
  		l0.62,0.04l0.45-0.15l0.36-0.7l0.83-0.12l0.79-0.42l1.46,0.22l1.72-1.58l-0.04-0.23L1245.28,915.29z M1234.97,919.73l-0.52-0.19
  		l-0.4,0.27l-0.53,0.68l-0.02,0.72l0.22,0.18l0.6,0.28l0.06-0.2l-0.01-0.54l0.54,0.09l0.49-0.2l-0.03-0.33L1234.97,919.73z
  		 M777.59,879.08l0.06-0.29l-0.31-0.41l-0.26-0.23l-0.21-0.04l-0.34,0.36l-0.47,0.76l-0.2,0.45l0.06,0.15l0.43,0.55l0.28,0.19
  		l0.29,0.05l0.14,0.25l0.03,0.79l0.1,0.27l0.28,0.4l0.55,0.12l0.26,0.19l0.2,0.35l0.22,0.15l0.24-0.06l0.14-0.17l0-0.5l-1.15-1.02
  		l-0.18,0.59l-0.41-1.41l-0.21-0.47l-0.23-0.14l-0.61-0.16l0.04-0.13L777.59,879.08z M1184.85,958.5l-0.4,0.95l0.4-0.04l0.42-0.19
  		l0.5-0.44l0.1-0.29l-0.01-0.34l-0.27-0.09L1184.85,958.5z M1123.4,1051.51l0.02,0.77l0.16,0.8l0.37-2.25l-0.27,0.22L1123.4,1051.51
  		z M1132.91,1080.93l-0.34-1.05l-0.35-0.51l0.51,1.88l0.96,2.42l0.77,1.54l-0.14-0.66L1132.91,1080.93z M1058.54,1058.22l-0.77,0.53
  		l-0.09,0.15l-0.19,0.14l-0.27,0.15l0.61,0.02l0.41,0.21l-0.03-0.43l0.11-0.39L1058.54,1058.22z M1219.78,950.68l-0.02,0.23
  		l-0.16,0.46l-0.2,0.11l-1.23,0.31l1.5,0.37l0.65-0.16l-0.06-0.33L1219.78,950.68z M1172.66,1004.93l-0.01-0.24l-0.1-0.28
  		l-0.58-0.34l0.56,1.06L1172.66,1004.93z M1176.11,983.54l-1.28,1.95l0.38-0.16l1.09-1.79l0.56-1.05l0.32-1.15l-0.33,0.57
  		L1176.11,983.54z M1169.73,1011.92l0.17,0.04l1.53-0.68l-0.7,0.15L1169.73,1011.92z M1184.33,969.26l0.99-1.49l0.22-0.65
  		l-1.23,1.86L1184.33,969.26z M1173.77,1005.76l-0.01,0.32l0.19,1.56l-0.06,0.88l-0.41,1.91l-1.29,0.54l0.1,0.08l1.13-0.26
  		l0.29-0.38l0.38-1.6l0.07-1.1l-0.19-1.45L1173.77,1005.76z M1202.42,954.51l-0.42-0.36l-0.27,0.05l-0.87,0.23l-1.02,0.52
  		l-0.44,0.38l-0.47,0.19l-0.54-0.07l0.6-0.65l0.69-0.52l1.29-1.15l-0.83,0.27l-1.44,0.99l-0.69,0.35l-1.72,0.2l-1.73,0.06
  		l-0.65,0.09l-0.63,0.27l-0.78,0.06l-0.8-0.2l-0.29,0.03l-0.28,0.14l-0.39,0.07l-0.73,0l-0.3,0.13l-0.28,0.25l-0.08,0.43l-0.36-0.32
  		l-0.52,0.36l-1.03,0.42l-0.72,0.66l-0.33,0.42l-0.24,0.44l0.14,0.57l0.72-0.18l0.45-0.53l0.44-0.04l0.2,0.15l-0.02,0.19l-0.83,0.51
  		l1.12-0.22l1.23-0.07l2.99-0.63l0.31,0.12l0.29-0.03l3.63-1.23l0.73-0.13l1.01-0.35l1.07-0.5l0.75-0.19l3.67-1.66l-0.85,0.16
  		L1202.42,954.51z M1214.94,950.08l-0.49,0.09l-0.73,0.75l-0.58,0.15l0.37,0.32l2.32-0.49l-0.13-0.39L1214.94,950.08z
  		 M1209.34,948.45l-0.23,0.6l-0.09,0.54l-0.24,0.37l0.47,0.05l0.42-0.27l0.08-1.63l-0.27,0.16L1209.34,948.45z M1208.47,948.94
  		l-0.16,0.56l0.09,0.48l0.23-0.19l0.09-0.57l-0.08-0.3L1208.47,948.94z"/>
    </>,
    GBR: <>
        <path className="st0" d="M1756.25,780.67l0.32,0.03l0.25-0.16l0.16-0.25l-0.01-0.29l-0.14-0.26l-0.37-0.23l-0.93,0.39l0.41,0.57
  		L1756.25,780.67z M1782.09,758.38l0.48,0.01l0.48-0.16l0.05-0.09l-0.45-0.15l-0.09-0.15l-0.05-0.39l-0.37-0.6l-0.72-0.17
  		l-0.31,0.12l-0.05,0.34l0.28,0.52L1782.09,758.38z M1756.59,771.99l-0.44,0.06l-0.18,0.77l-2.01,0.49l0.01,0.12l0.27,0.39l0,0.16
  		l-0.19,0.2l-0.32,0.1l-0.51-0.16l-0.41,0.45l-0.07,0.2l0.17,0.36l0.55,0.6l1.96,0.43l0.67,1.09l0.33,0.42l0.48,0.22l0.87,0.03
  		l1.08-0.23l0.17,1.8l0.23,0.09l0.32-0.01l0.3-0.21l1-1.01l0.84-0.63l0.23-0.34l-0.03-0.31l-0.28-0.2l-1.47,0.07l-1.58-0.25
  		l-0.22-0.22l-0.35-0.15l-0.04-0.48l-0.19-0.67l0.15-0.63l0.01-0.54l-0.18-0.97l-0.68-0.8L1756.59,771.99z M1756.6,796.73l0.44,0
  		l1.39-0.59l0.28-0.45l-0.02-0.32l-0.3-0.94l-0.3-1.52l-0.73,0.31l-0.81,0.57l-0.28-0.2l-0.26,0.03l-0.32,0.2l-0.27,0.26l-0.15,0.29
  		l-0.03,0.46l-0.25,0.68l0.04,0.17l0.33-0.08l0.99-0.83l0.27-0.07l0.13,0.1l-0.13,0.52l0.27,0.69l-0.31,0.6L1756.6,796.73z
  		 M1754.37,784.19l-0.83,0.81l0.03,0.17l0.48-0.07l0.65-0.5l0.39-0.47l-0.19-0.08L1754.37,784.19z M1784.64,758.69l0.31,0.23
  		l0.08-0.04l0.14-0.72l0.14-0.35l-0.38-0.1l-0.79,0.16L1784.64,758.69z M1800.92,736.68l0.07-0.21l-0.01-0.8l-0.12-0.69l0.03-0.11
  		l0.38-0.03l0.07-0.36l-0.12-0.39l-0.74-0.05l-0.61,1.48l0.82,1.29L1800.92,736.68z M1802.04,734.48l0.7,0.04l-0.01-0.41l0.44-1.07
  		l0-0.15l-0.23-0.25l-0.18-0.01l-0.35,0.33l-0.22-0.13l-0.21,0.07l-0.1,0.17l-0.09,0.66l0.13,0.62L1802.04,734.48z M1784.74,752.27
  		l0.86,0.34l0.08,0.8l0.31,0.26l0.72-0.31l0.08-0.41l-0.09-0.09l-0.71-0.18l-0.39-0.59l-0.96-0.73l-0.56,0.17l-0.08,0.12l0.32,0.4
  		L1784.74,752.27z M1781.64,756.04l0.23,0.05l0.52-0.4l0.08,0.12l0,0.42l0.08,0.21l0.19,0.17l0.29,0.08l1.45-0.25l1.1,0.6l0.32-0.03
  		l0.28-0.17l0.26-0.61l-0.47-0.32l-0.56-0.03l-0.93-0.26l-0.64,0.01l0.11-0.31l0.32-0.35l0.01-0.23l-0.27-0.29l-0.89-0.46
  		l-0.78-0.09l-0.52,0.16l-0.31,0.82l-0.06,0.57l0.06,0.4L1781.64,756.04z M1789.24,752.31l0.19-0.33l-1.08-0.08l-0.57,0.18
  		l-0.49,0.74l0.95-0.01L1789.24,752.31z M1795.88,740.2l0.24,0.1l0.81-0.02l0.15,0.12l0.02,0.35l0.13,0.26l0.61-0.2l0.73,0.01
  		l0.27,0.44l0.12,0.49l-0.2,0.94l-0.47,1.62l0.47,0.41l0.13-0.1l0.32-1.06l0.39-0.45l0.17-1.35l0.11-0.13l0.11-0.67l0.16-0.37
  		l0.57-2.21l0.11-0.8l-0.55,0.34l-0.34,0l-0.66-0.86l-0.44,0.23l-0.17-0.89l0.05-0.89l-0.52-0.02l-0.42,0.14l-0.71,0.88l-0.46,0.16
  		l-0.16,0.29l0.19,0.17l0.84,0.16l0.63,1.72l-1.01,0.51l-0.69-0.07l-0.73,0.2l0.03,0.26L1795.88,740.2z M1824.38,831.76l-0.23-1.12
  		l-0.5-0.87l-2.31-1.59l-0.93-0.35l-1.82-0.39l-0.9,0.06l-1.03-0.2l-1.03-0.07l-1.23,0.12l-0.36,0.33l-0.71,0.91l-0.42,0.37
  		l-0.44,0.15l-0.42,0.03l-1.97-1.1l0.66-0.75l0.71-0.67l0.76-0.58l0.49-0.9l-0.71-2.01l-1.2-1.52l-3.55-2.57l-1.61-0.02l-1.47-0.34
  		l0.78-0.02l0.9,0.11l1.61-0.24l0.81,0.59l0.84,0.48l0.46,0.07l0.47-0.04l0.34,0.13l0.33,0.23l-0.88-1.54l-1-1.41l-0.51-0.88
  		l-0.31-0.92l0.41-0.68l0.61-0.43l-1.25-0.83l-1.16-1.03l-1.24-1.34l-1.29-1.26l-0.74-0.44l-3.33-1.32l-0.66-0.57l-0.5-0.82
  		l-1.1-2.95l-0.84-2.73l-0.74-2.81l-0.38-0.85l-0.62-0.57l-0.85-0.63l-1.57-1.61l-1.1-1.13l-3.81-1.47l-2,0.01l-1.5,0.8l-0.28,0.08
  		l-4.71-0.76l-0.81-0.32l-0.71-0.62l0.79,0.38l1.81,0.36l1,0.06l0.8-0.21l0.75-0.42l1.67-1.36l1.79-0.1l0.79-0.61l0.18-0.77
  		l-1.96-0.95l-1.7,0.1l-0.93,0.27l-0.94,0.03l0.8-0.24l0.77-0.49l0.64-0.29l2.29-0.4l0.79-0.38l0.74-0.56l0.77-0.9l0.63-1.13
  		l1.4-1.59l1.44-2.88l0.23-0.61l0.14-0.66l0.21-0.61l0.72-1.13l0.84-0.82l0.45-0.66l0.02-0.24l-0.75-1.44l-0.79-0.78l-0.95-0.31
  		l-1.43,0.26l-5.16-0.14l-0.76,0.04l-0.75,0.21l-0.4-0.01l-1.77-0.45l-0.91,0.02l-1.9,0.56l-2.02,0.75l-1.01,0.23l-1.23,0.04
  		l0.47-1.21l1.6-1.34l0.26-0.39l-0.42-0.26l-1.08-0.15l0.13-0.76l0.25-0.55l1.81-1.14l3.07-2.3l1.67-1l0.64-0.7l0.2-0.38l0.1-0.31
  		l-0.07-1.01l0.44-0.9l0.09-0.33l-0.06-0.24l-1.74-0.19l-1.64,0.41l-1.75,0.13l-1.67,0.36l-2.77,0.24l-2.06,0.55l-0.49-0.68
  		l-0.36,0.08l-1.21,0.59l-0.31,0.04l-0.42-0.54l-0.37-0.23l-0.97-0.19l-0.43,0.1l-0.35,0.17l-0.42,0.57l-0.08,0.38l-0.02,0.86
  		l-0.1,0.43l0.08,0.48l0.41,0.58l0.2,0.44l-0.44,0.15l-1.77-0.02l-0.58,0.16l-0.15,0.33l0.08,0.43l-0.04,0.4l-0.52,0.91l0.16,0.32
  		l1.83,1.68l0.17,0.31l-1.12-0.28l-0.25,0.01l-0.25,0.31l-2.18-0.04l-0.48,0.7l-0.25,0.55l-0.42,1.34l0.02,0.3l0.23,0.52l0.3,0.36
  		l0.82,0.3l-0.9,0.28l-0.57,0.29l-0.38,0.38l-0.14,0.39l0.2,0.69l1.17,0.54l0.21,0.49l0.58,0.74l-0.25,1.57l-1.22,1.71l-0.96,0.51
  		l-0.09,0.19l1.1,0.6l-1.24,0.88l-1.32,0.19l-0.83,0.55l-0.01,0.14l0.64,0.17l0.75,0.03l0.27,1.01l0.61,0.52l0.78,0.25l1.01,0.11
  		l0.74-0.4l2.92-2.22l0.25-0.08l-0.46,0.85l-0.59,0.82l-0.14,0.75l-0.53,0.49l-0.35,1.1l-0.46,0.86l-0.4,1.18l-0.17,1.38l-0.46,0.95
  		l-0.11,2.87l0.17,0.19l0.24,0.06l0.56-0.19l0.02,0.06l-0.85,0.97l-0.38,0.55l-0.26,0.59l-0.6,2.13l-0.13,0.57l0,0.38l0.32,0.34
  		l0.71,0.09l0.24-0.05l0.25-0.23l0.27-0.45l1.44-4.49l0.11-0.68l-0.38-1.47l0-0.28l0.07-0.24l0.23-0.28l0.85-0.84l1.67-1.28
  		l0.74-0.43l-1.51,1.38l-0.39,0.61l-0.21,0.78l0.01,0.84l0.15,0.51l0.11-0.03l0.16-0.47l0.52-0.06l0.17-0.13l0.19-0.51l1.03-0.24
  		l0.36-0.24l0.6-1.03l0.31-0.43l0.16-0.09l-0.34,0.92l-0.03,0.35l1.46,0.99l0.73,0.34l-1.88-0.02l-0.16,0.13l-0.38,0.66l-0.15,1.1
  		l-0.02,0.97l1.41,1.19l0.34,0.52l0.06,0.62l-0.37,0.95l-0.54,0.72l-1.52,2.47l-0.76,1.89l-0.51-0.28l-0.47,0.31l0.02,0.8l0.29,0.71
  		l0.87,1.12l1.02,0.84l0.18-0.97l0.32-0.62l0.28-0.24l1.44,0.67l1.1,0.36l0.91-0.34l0.89-0.57l0.42-0.13l0.38,0.11l0.29,0.42
  		l0.35,0.25l0.48-0.09l0.99,0.07l0.5-0.28l0.48-0.44l0.49-0.32l0.54-0.07l0.51-0.2l0.91-0.64l0.98-0.19l2.97,0.02l0.38,0.1
  		l-1.95,0.54l-1.66,1.56l-1.07,2.43l0.19,1.13l1.34,1.88l0.75,0.89l1.31,1.17l0.47,0.02l0.46-0.31l0.52-0.2l1.06-0.08l0.18,0.49
  		l-0.13,1.06l-0.32,0.96l-1.07,0.63l-0.16,0.72l0.11,0.81l0.4,0.31l0.5,0.16l-0.59,0.81l-0.54,0.88l-0.04,0.84l0.8,1.42l0.48,0.45
  		l0.57,0.21l0.44,0.01l0.37,0.23l-0.97,0.2l-0.46-0.15l-1.23-1.39l-0.85,0.37l0.57,1.54l-1.93-0.99l-0.86,0.07l-0.86,0.34
  		l-0.98,0.14l-1-0.11l-0.38,0.06l-2.54,0.96l-1.33,0.85l-0.5,0.45l-0.28,0.56l-0.36,0.48l-1.02,0.63l-0.95,0.76l-0.36,0.53
  		l-0.01,0.43l0.84-0.1l0.94-0.54l0.97-0.4l1.07-0.18l1.08-0.03l-0.11,0.56l-0.02,0.52l0.42,0.67l0.24,0.64l-0.27,0.51l-0.07,0.58
  		l0.26,0.57l0.57,0.18l-0.59,0.75l-0.41,0.93l-0.42,0.76l-0.58,0.55l-1.39,0.9l-1.5,0.52l-2.67,1.23l-1.77,0.52l-0.8,0.52
  		l-0.66,0.78l0.52,0.21l0.28,0.6l-0.01,0.76l0.37,0.39l1.87,0.89l1.56-0.64l0.98-0.6l0.58-0.12l1.22,0.08l0.49,0.46l0.43,0.2
  		l1.56,0.25l-0.7,0.36l-0.51,0.65l1,0.03l0.99-0.18l0.46-0.17l0.45,0.07l1.08,0.58l1.69,1.41l2.27,0.26l0.29-0.09l1.5-1.09
  		l0.86-0.48l1.99-0.47l0.63-0.47l1.08-0.81l0.9-0.51l-1.32,1.48l-0.82,0.8l-0.87,0.7l-0.76,0.77l-1.35,1.75l-0.79,0.49l-1.01,0.12
  		l-1.01-0.03l-1.96-0.35l-1.97-0.03l-2.66,0.33l-0.25,0.14l-0.91,1.8l-1.91,0.55l-0.19,0.85l-0.12,0.88l-0.19,0.49l-2.34,2.15
  		l-0.27,0.53l-0.53,0.12l-0.4,0.31l-0.33,0.48l-0.83,0.86l-1.69,1.41l-1.92,0.54l-0.72,0.72l0.01,0.6l0.28,0.29l0.6-0.36l0.99-0.23
  		l0.94,0.24l0.82,0.68l0.9-0.19l0.59-1.06l0.33-0.29l1.62-1.05l0.75-0.38l1.86-0.56l1.07-0.19l0.69-0.01l0.67-0.21l0.19-0.17
  		l0.19,0.03l0.58,0.47l1.71,0.69l0.9,0.62l0.96-0.12l0.8-0.9l0.49-1.17l0.34-1.32l0.68-0.93l3.41-0.93l0.83-0.06l1.04,0.19l0.99,0.4
  		l0.94,0.59l0.96,0.19l0.33-0.35l0.38-0.07l2.65,0.38l0.32-0.05l0.3-0.22l-0.37-0.5l-0.21-0.58l1.39,0.11l1.5-0.22l0.73,0.03
  		l0.71-0.16l1.54-0.81l-0.69-0.84l1.11,0.44l1.28,0.14l1.11,0.32l1.09,0.47l0.73,0.08l2.82-0.5l2.08-0.05l3.44,0.57l0.8-0.14
  		l0.97-0.48l0.99-0.38l1.28-0.36l0.74-0.54l1.58,0.09l0.16-0.51l0.55-0.84l2.7-1.2l0.27-0.3l0.15-1.44l-0.01-0.59l-0.35-0.13
  		l-0.98,0l-2.04,0.17l-1.06,0l-1.71-0.3l-0.35-0.2l-0.38-0.71l-0.61-0.18l-0.87,0.21l0.7-0.4l0.73-0.21l0.88-0.04l0.86-0.17
  		l0.77-0.38l0.31-0.84l-0.25-0.48l-1.23-0.45l1.71-0.88l1.23,0.25l0.74-0.2l0.72-0.47l0-0.64l-0.39-0.53l0.04-0.25l0.37-0.03
  		l0.34,0.19l0.81-0.43l1.23-1.04l0.27-0.37l0.2-0.47l0.28-1.32l0.45-1.02l0.39-1.13L1824.38,831.76z M1762.89,811.2l-0.62-0.02
  		l-0.2-0.11l0.08-1.12l-0.21-0.84l0.46,0.15l0.4,0.28l0.36,0.61l0.35,0.22l0.11-0.68l-0.17-0.63l-0.32-0.76l-0.46-0.51l-1.31-0.11
  		l-0.55,0.12l-0.63,0.25l0-0.5l0.96-0.47l0.46-0.38l-0.05-0.71l-1.28-1.15l-0.98-1.33l-0.42-1.35l-0.79-0.85l-0.89,0.01l-1.19-0.29
  		l-0.84,0.01l-1.88,0.56l-1.06,0.15l-0.54-0.1l-0.49,0.08l-0.7,1.2l-0.59,0.38l-0.66-0.1l-0.34-0.41l-1.33,0.75l-0.21,0.29
  		l-0.38,1.15l-0.04,0.33l-0.43,0.6l-0.41,0.67l-0.47,0.26l-0.7,0.21l-0.4,0.21l-0.5-0.1l-0.64,0.02l-0.32,0.23l0.02,0.17l0.19,0.2
  		l0.56,0.31l0.62,0.28l-0.07,0.24l-0.33,0.28l-2.11,0.68l-0.63,0.41l-0.22,0.27l0.22,0.46l1.68,1.37l0.29,0.15l0.25,0.79l1.48,0.33
  		l0.61,0.5l0.52,0.12l1.14-0.04l0.46,0.19l0.26-0.14l0.15-0.26l0.96-0.67l0.32-0.29l-0.19-0.41l-0.21-0.31l0.58-0.62l0.71-0.61
  		l0.35,0.02l0.6,0.38l0.5,0.52l0.07,0.41l0.09,0.29l0.47,0.63l0.3,0.22l0.82,0.13l0.19,0.25l-0.15,0.91l0.12,0.3l0.86,0.02
  		l0.91-0.07l0.32,0.03l0.33-0.19l0.51-0.21l0.72,0.07l0.83,0.34l0.85,0.09l0.68-0.44l0.52-0.78l0.18-0.52l0.24-0.4l0.99-0.12
  		l0.85-0.31L1762.89,811.2z M1798.64,851.99l-0.62,0.44l-1.09,0.33l-0.4,0.41l0.41-0.04l1.76,0.9l0.46,0l0.47-0.11l0.17-0.18
  		l0.22-0.45l0.71-0.38l-0.66-0.49L1798.64,851.99z M1759.24,794.4l0.18-0.13l1.46-2.27l0.61-1.34l-0.31-0.02l-0.31,0.14l-1.17,0.75
  		l-0.28,0.48l0.02,0.15l0.49,0.21l-1.09,0.58l-0.26,0.39l0.01,0.54l0.09,0.29l0.16,0.19L1759.24,794.4z M1772.48,824.94l0.39,0.5
  		l0.8-0.44l0.66-0.52l0.98-0.53l0.29-0.47l-0.89,0.03l-0.35-0.21l-1-1.1l-1.23-0.02l-0.89,0.38l0.12,1.44l0.68,0.96L1772.48,824.94z
  		 M1765.26,798.5l0.38,0.1l1.06-0.01l0.09-0.54l-0.09-0.94l-0.47-1.1l-0.21-0.29l-0.56-0.31l-0.56,0.09l-0.23,0.22l-0.21,0.28
  		l-0.18,0.57l0.52,1.62L1765.26,798.5z M1759.62,785.71l-0.7-0.9l-0.62-0.43l-0.3-0.05l-0.37,0.08l-0.88,0.37l-0.17,0.16l-0.11,0.35
  		l0.08,0.21l1.45,0.74l-0.39,1.6l-0.96,0.21l-0.23,0.22l0.1,0.33l1.16,0.06l3.35-0.66l0.14-1.75l-0.63-0.38L1759.62,785.71z
  		 M1750.1,767.03l0.33,0.08l0.18,0.21l0.27,0.05l-0.56,0.4l-0.04,0.18l0.46,0.35l1.17,0.51l0.06,0.11l-0.74,0.36l-0.1,0.35
  		l-1.07,0.62l0.59,0.63l0.25,0.14l0.22,0l0.39-0.28l0.48-0.65l0.48-0.01l0.95-1.02l0.89-0.37l1.29-0.98l0.19-0.25l-0.01-0.42
  		l-0.28-0.2l-0.99-0.01l1.14-0.59l0.37-0.53l0.42-0.05l1.07-2.14l0.04-0.88l-0.21-0.66l-0.15-0.17l-0.5,0.2l-2.08,1.27l-1.67,0.76
  		l-0.29,0.25l-0.1,0.22l0.53,1.06l-0.02,0.1l-0.72-0.08l-0.62,0.17l-0.53-0.43l-0.53-0.14l-0.14,0.08l-0.14,0.25l-0.34,0.24
  		l-0.09,0.54l0.06,0.53L1750.1,767.03z M1747.24,777.81l0.29,0.74l0.29,0.19l0.47,0.06l0.36-0.07l0.02-0.13l-0.16-2.97l-0.25-0.14
  		l-0.96,0.03l0.03,1l-0.13,0.84L1747.24,777.81z M1746.49,780.7l0.74-0.17l0.15-0.22l-0.07-0.2l-0.41-0.22l-0.57,0.15l-0.17,0.41
  		l0.05,0.15L1746.49,780.7z M1748.04,773.67l1.16,0.01l0.76-1.13l-0.95-0.68l-0.55,0.31l-0.45-0.07l-0.56,0.22l-0.41-0.14
  		l-0.25,0.05l-0.25,0.2l-0.14,0.25l0.01,0.17L1748.04,773.67z M1788.54,866.06l-0.3,0.72l-0.77-0.24l-0.06-0.19l0.88-0.42l0.18,0
  		L1788.54,866.06z M1792.7,868.93l0.07,0.55l-0.37,0.12l-0.31-0.19h-0.63l-0.59,0.12l0.13-0.98l1.16,0.12L1792.7,868.93z"/>
    </>,
    UGA: <>
        <path className="st0" d="M2104.3,1303.07l-0.11-1.68l-0.42-1.4l-0.26-1.57l0.19-0.52l-0.33-0.92l-0.16-0.05l-0.27-0.2l-0.35-0.87
  		l-0.26-0.79l-0.16-0.2l-1.13-0.69l-0.56-1.63l-0.63-0.36l-0.34-1.62l-0.07-0.46l0.36-1.62l-0.03-0.37l-0.38-0.34l-1.06-0.35
  		l-0.86-0.67l0.11-0.23l0.06-0.24l-0.45-0.16l-1.31-2.77l-1.98,1.97l-1.46,1.45l-0.24,0.21l-0.42,0.27l-1.39,0.01l-1.43-0.17
  		l-1.32-0.88l-1.34,0.68l-0.85,0.22l-0.51,0.08l-1.2,0.11l-1.68,0.37l-0.76,0.46l-0.41,0.36l-0.34,0.67l-0.17,0.07l-0.31-0.08
  		l-0.43-0.27l-0.9-0.39l-0.45-0.85l-0.42-0.51l-0.34-0.27l-1.42,0.85l-0.69,0.2l-0.57-0.02l-1.03-0.48l-1.14-0.41l-0.59,0
  		l-0.88,0.51l-1,0.76l-0.52,0.75l-0.25,0.45l0.48,0.23l0.09,0.46l-0.33,0.56l-0.33,0.5l-0.41,1l-0.21,1.02l0.27,0.34l0.29,0.28
  		l0.16,0.29l0.09,0.33l-0.03,0.39l-0.65,1.42l-0.33,1.24l-0.01,0.63l0.85,0.46l1.11-0.02l0.35,0.28l0.35,0.45l0.31,0.23l0.47-0.01
  		l0.33,0.16l0.13,0.32l0.38,0.34l0.22,0.27l0.34-0.55l0.35-0.54l0.73-0.81l0.37-0.11l-0.03,0.13l-0.64,0.88l-0.1,0.17l0.03,0.51
  		l0.1,2.55l-0.1,0.32l-0.16,0.17l-0.21,0.02l-0.18,0.26l-0.15,0.51l-0.31,0.46l-0.86,0.69l-0.31,0.15l-0.75,0.2l-0.22,0.14
  		l-0.38,0.16l-0.64,0.66l-0.91,1.16l-0.49,0.72l-0.07,0.27l-1.05,1.25l-0.21,0.11l-0.25,0.06l-0.27-0.16l-0.06-0.08l0.03-0.59
  		l-0.1-0.44l0-0.25l0.09-0.07l-0.07-0.15l-0.14-0.08l-1.32,0.45l-0.68,0.69l-0.48,1.08l-1.14,0.92l-0.88,0.37l-0.09,0.22l-0.07,1
  		l0.09,1.46l-0.41,0.67l-0.6,1.3l-0.31,0.81l-0.24,0.16l-0.27,0.41l-0.17,1.33l-0.11,0.45l-0.01,0.06l0.84,0.01l0.88,0.43
  		l-0.16,1.09l-1.89,1.7l0.04,0.24l-0.33,1.31l-0.01,0.77l-0.14,0.87l-0.24,0.76l0.02,1.21l0.13,1.97l-0.03,0.26l0.28-0.01l1.82-0.43
  		l0.18,0.14l0.29,0.84l0.15,0.12l0.25,0.03l0.51-0.19l0.94-0.66l0.41-0.4l0.48-0.56l0.61-0.63l0.34-0.55l0.33-0.32l0.44-0.1
  		l0.49,0.02l0.34,0.01l0.08-0.04l0.66,0.06l0.62-0.15l0.58-0.37l0.57-0.11l0.12,0.03l0.18,0.03h0.88h1.5h1.5h1.5h1.5h1.01
  		l-0.11-0.55l-0.1-0.4l-0.2-0.42l-0.08-0.39l0.8-1.85l0.41-0.73l1.07-1.34l0.05-0.55l-0.21-0.36l-0.3-0.26l-0.06-0.18l0.14-0.22
  		l0.69-0.35l0.19-0.28l0.88-0.14l0.42-0.41l0.41,0.11l0.24-0.21l0.54,0.05l0.28-0.43l0.25-0.19l0.2-0.03l0.1,0.31l0.31,0.03
  		l0.39-0.14l0.26-0.24l0.2-0.79l0.23-0.35l0.27-0.15l0.28,0.53l0.26,0.08l-0.3,0.35l-0.05,0.17l0.18,0.08l0.36,0l0.38-0.09
  		l0.62-0.42l0.22,0l0.14,0.47l0.17,0.14l0.36-0.1l0.53-0.32l0.41-0.39l0.06-0.38l0.51-0.11l0.54-1.14l-0.12-0.34l0.49-0.19l0.23-0.1
  		l0.33,0.09l0.44-0.17l0.03,0.16l-0.3,0.7l0.18,0.16l0.91,0.13l0.19,0.16l0,0.18l-0.37,0.19l0.05,0.22l0.3,0.22l0.42,0.1l0.4-0.13
  		l0.5-0.66l0.34-0.13l0.27,0.07l0.13,0.65l0.14,0.19l0.98,0.19l0.71-0.27l0.25,0.04l0.53-0.68l0.37-0.74l0.26-1.03l0.41-0.84
  		l0.94-0.68l0.17-0.38l1-1.14l0.6-1.46l0.45-0.5l0.56-0.46l0.4-0.24l0.65-0.24l0.51-0.14l0.09-0.12l0.04-0.24l-0.17-0.91l0.22-0.3
  		l0.35-0.6l0.4-0.56l0.36-0.36l0.2-0.37l0.09-0.64l0.01-0.45L2104.3,1303.07z M2080.85,1321.42l-0.12-0.18l0.02-0.63l-0.29-0.02
  		l-0.52-0.01l-0.18,0.19l0.14,0.3l0.2,0.27l0.22,0.12l0.66,0.22l-0.02,0.29l-0.33,0.37l-0.18,0.38l-0.1,0.32l1.18-0.44l0.22-0.68
  		l0.18-0.45l-0.42-0.39L2080.85,1321.42z M2089.53,1316.33l-0.02,0.36l-0.02,0.36l-0.32,0.12l0.03,0.21l0.67,0.05l0.07,0.19
  		l0.56,0.34l-0.12-0.52l-0.07-0.36l0.47-0.27l0.14-0.47l-0.6-0.21L2089.53,1316.33z"/>
    </>,
    UKR: <>
        <path className="st0" d="M2076.18,900.6l-0.45-0.32l-0.64-0.74l0.17,0.7l0.3,0.51l1.15,0.46l2.6,0.49l1.19,0.14l-1.16-0.52
  		L2076.18,900.6z M2147.66,867.43l-0.58-0.68v-0.72l0.31-0.5l-0.12-0.37l-0.42-0.22l-0.61,0.06l-0.69,0.25l-0.81-0.05l-1.3-0.86
  		l-1.38-0.85l-0.79-0.03l-0.55-0.12l-0.48-0.44l-0.29-0.56l-0.31-0.25l-0.5,0.16l-0.73,0.26l-0.92-0.07l-1.19-0.65l-1.09-0.75
  		l-0.81-0.02l-0.85-0.1l-1.62-0.97l-0.42,0.01l-0.26,0.29l-0.12,0.78l-0.13,0.17l-0.29,0.13l-0.55,0.09l-0.81-0.48l-2.07-1.59
  		l-0.83-1.16l-0.19-0.85l-0.68-0.54l-0.66-0.78l-0.67-0.07l-0.74,0.25l-0.71,0.38l-0.34,0.1l-1.2,0.13l-1.93,0.53l-0.53,0.5
  		l-0.65,0.41l-0.5-0.28l-0.5-0.5l-1.1-0.18l-0.53,0.18l-0.53-0.35l-0.45-0.62l-0.62-0.45l-0.91-0.12l-0.99-0.19l-0.79,0.35
  		l-1.03,0.66l-0.7-0.25l-0.38-0.79l-0.48-0.22l-0.65-0.88l-0.17-0.79l0.17-0.35l0.24-0.44v-0.51l-0.19-0.44l-0.29-0.35l-0.31-1.17
  		l-0.26-0.51l-0.05-0.41l0.22-0.38l-0.19-0.25l-0.36-0.03l-0.6,0.03l-0.34-0.19l-0.36-0.67l-0.19-0.67l-0.24-0.25l-0.62,0.02
  		l-1.02,0.14l-0.91,0.22l-0.4-0.03l-0.8-0.34l-1.06-0.38l-2.16-0.08l-0.17-0.13l0.12-0.24l0.44-0.39l-0.05-0.32l-0.38-0.26
  		l-0.2-0.63l0.02-0.72l-0.07-0.78l-0.46-0.61l-0.26-0.41l0.05-0.38l0.99-0.15l1.18-0.27l0.2-0.28l-0.04-0.44l-2.4-2.24l-0.82-1.99
  		l-0.79-1.08l-0.87-0.72l-0.7-0.33l-1.03,0.14l-1.36-0.01l-1.39-0.22l-1.17,0.15l-2.09,0.95l-0.79,0.04l-1.36-0.3l-1.16-0.33
  		l-0.61,0.01l-0.37,0.14l-0.24,0.26l-0.68,1.78l-0.56,0.35l-0.8,0.36l-0.68,0.06l-0.57-0.02l-0.83-0.27l-0.78-0.32l-0.16-0.02
  		l-1.6-0.08l-1.92,0.03l-1.08,0.62l-0.42-0.14l-0.75-0.16l-0.83,0.35l-1.14,1.05l-0.76,0.65l-0.74,0.91l-0.23,0.49l-0.47,0.91
  		l-0.43,1.04l0.23,0.73l0.35,0.67l0.08,0.73l0.18,0.57l-0.47,0.41l-0.27,0.59l-0.8-0.1l-0.98-0.57l-0.21-0.83l-0.75-0.58l-0.5-0.3
  		l-0.82-0.05l-1.31,0.27l-1.71,0.21l-1.29,0.06l-0.7,0.29l-1.04,0.29l-0.4-0.34l-0.58-0.94l-0.47-0.94l-0.32-0.41l-0.28-0.11
  		l-0.35,0.02l-0.4,0.3l-0.3,0.3l-0.42,0.11l-0.66,0.25l-0.47,0.33l-0.52,0.86l-0.35-0.06l-0.36-0.2l-0.41-0.96l-0.56-0.22l-0.9-0.01
  		l-1.12-0.2l-0.91-0.29l-0.33,0.07l-0.54,0.41l-0.59,0.06l-1.28-0.37l-0.25,0.17l-0.34,0.54l-0.4,0.52l-0.35,0.05l-0.2-0.13
  		l0.11-0.92l-0.74-0.33l-1.26-0.05l-0.88,0.13l-0.43-0.04l-0.22-0.18l-1.08-1.55l-0.57-0.1l-1.02,0.08l-1.51-0.19l-1.74-0.35
  		l-0.96-0.13l-0.5-0.35l-1.07-0.12l-2.88-0.66l-1.18-0.12l-1.73-0.01l-2.64-0.15l-1.69,0.08l-0.78,0.22l-0.9,0.14l-1.53,0.12
  		l-0.62-0.01l-0.98,0.07l-1.12,0.17l-0.32,0.33l-0.37,0.72l-1.29,1.23l-1.25,0.82l-0.23,0.07l-0.73-0.44l-0.61-0.15l-0.71-0.05
  		l-0.51,0.14l-0.32,0.21l0.04,0.95l-0.07,0.08l0.45,0.77l0.18,0.6l-0.19,0.47l0.06,0.47l0.4,0.5l1.27,1.55l0.63,1.49l0.4,0.58
  		l0.93,0.75l0.08,0.31l-0.37,0.28l-0.3,0.04l-0.24,0.08l-0.16,0.26l0.24,0.28l0.33,0.41l0.41,1.17l-0.04,0.96l-0.31,0.24l-0.4,0.57
  		l-0.27,0.52l-2.2,0.36l-0.53,0.55l-1.2,1.07l-0.82,0.62l-1.21,1.11l-1.92,1.91l-0.71,0.8l-0.52,0.66l-1.55,1.75l-0.48,0.73
  		l0.09,0.6l0.5,1.42l0.11,0.64l-0.09,0.59l-0.17,0.53l0.03,0.23l0.46,0.38l0.73,0.6l0.04,0.2l-0.1,0.26l-0.25,0.2l-0.91-0.21
  		l-1.02-0.4l-0.35,0.05l-0.12,0.45l-0.34,0.52l-0.43,0.54l-0.36,0.65l-0.48,1.39l-0.32,0.64l-1.28,1.27l-0.09,1.76l0.8-0.09
  		l0.03,0.01l0.19,0.05l0.13,0.5l0.02,0.03l0.19,0.33l0.18,0.44l0.28,0.33l0.62,0.14l0.81,0.41l0.52,0.77l0.79,0.32l0.06,0.01
  		l0.16-0.04l0.57-0.03l0.11,0.15l0.45,0.37l0.18,0.33l-0.09,0.35l0.08,0.39l0.17,0.14l0.31-0.18l1.2-0.45l0.3-0.46l0.41-0.41
  		l0.53,0.03l1.73,1.01l1.86-0.06l0.34,0.04l0.11,0.02l0.23,0.08l2.47,0.5l0.38-0.06l0.1-0.04l0.99,0.42l0.88-0.05l0.83-0.29
  		l0.87-0.1l0.8,0.17l0.61,0.58l1.57,1.24l0.47,0.46l0.72-0.07l0.8-0.23l0.81-0.83l2.48-0.94l1.89-0.23l1.85-0.38l2.14-0.27
  		l0.62-0.77l0.34-0.52l0.24-0.97l1.15-0.28l1.1-0.2l0.39-0.12l0.18-0.37l1.74-1l0.45,0.16l0.91,0.04l1.85-0.03l0.91-0.66l0.56,0.18
  		l0.46-0.3l0.76-0.37l0.11,0.08l0.1,0.06l1.18,0.17l0.89,0.36l0.59,0.55l0.61,0.34l0.63,0.13l0.35,0.27l0.07,0.42l0.59,0.21
  		l1.11-0.01l0.47,0.27l-0.17,0.56l0.11,0.18l0.4-0.19l0.3,0.16l0.16,0.41l0.18,0.2l0.57-0.64l0.6,0.06l1.45,0.27l0.77,1.33
  		l0.48,0.48l0.42,0.19l0.54-0.21l0.47-0.25l0.27,0.12l0.58,0.88l0.14,1.15l0,0.46l-0.21,0.78l-0.3,0.83l-0.24,0.54l0.1,0.43
  		l0.21,0.36l0.34,0.12l1.12,0.73l0.42,0.51l0.61,0.38l0.46,0.02l0.24,0.21l0.09,0.26l-0.06,0.65l-0.26,0.61l0.03,0.39l0.41,0.46
  		l0.04,0.54l0.03,0.35l0.22,0.27l1.03,0.59l1.33,0.57l0.34,0.49l0.21,0.62l-0.07,1.04l-0.09,0.91l1.74,1.22l-0.2,0.23l-0.27,0.25
  		l-1.66,0.19l-0.34,0.1l-0.72-0.92l-0.38-0.12l-0.36,0.34l-0.42,0.19l-0.5-0.09l-0.54-0.28l-0.27-0.2l-0.22-0.02l-0.34,0.2
  		l-0.45-0.09l-0.29-0.23l-0.42,0.78l-0.26,0.17l-0.16-0.03l-0.03-1.33l-0.12-0.2l-0.34-0.03l-0.81,0.32l-0.77,0.41l-0.26,0.36
  		l0.03,0.65l0.11,0.78l0.53,1.18l-0.29,0.51l-0.2,0.82l-0.83,0.75l-0.93,0.44l-0.08,0.89l-0.52,0.61l-0.89,0.61l-0.6,0.73l0.15,0.5
  		l0.04,0.47l-0.1,0.32l-0.02,0.25l-0.23,0.11l-1.36,0.09l-0.38,0.15l-0.44,0.35l0.89,1.08l1.13,0.57l2.61,0.6l0.23-0.07l0.03-0.11
  		l-0.18-0.16l-0.03-0.2l0.13-0.25l0.36-0.01l0.59,0.22l1.12-0.32l1.65-0.86l1.52-0.18l1.38,0.51l0.71,0.59l0.45,0.56l0.18-0.87
  		l-0.48-2.06l-0.58-0.62l0-0.86l0.23-0.42l0.48-0.34l1.15,0.24l0.68-0.21l0.88-0.48l1.5-0.55l0.29-0.18l2.31-2.34l0.16-0.16
  		l1.22-1.7l0.13-0.39l0.42-0.66l0.43-1.13l0.2-0.83l2.87-0.77l1.55,0.13l0.7-0.17l0.79-1.16l0.56-0.41l-0.26,1.19l1.05,0.24
  		l1.03,0.11l0.79-0.19l0.24-0.76l0.09-2.17l-0.39-0.82l-0.24-0.89l-0.65-1.34l0.67,0.59l0.22,0.66l0.63,1.2l0.21,1.35l-0.16,0.75
  		l0.25,0.8l0.59,0.7l0.7,0.48l1.91,0.34l1.89-0.54l-0.21,0.25l-1.13,0.78l-0.49,0.45l-1.94-0.36l-2.14-0.13l-1.36-0.35l-1.36,0.01
  		l0.58,0.47l0.76,0.41l1.2,0.1l1.29,0.34l0.04,0.45l-0.18,0.24l-0.64,0.17l-0.62,0.03l-0.53,0.23l0.43,0.45l0.79-0.06l0.93,0.28
  		l2.48,1.37l1.24,0.49l2.7-0.5l1.22,0.08l2.19-0.55l0.52,0.53l1.4,0.72l0.58-0.22l0.8-0.18l0.36,0.67l0.24,0.9l-1.67,1.14
  		l-1.57,0.76l-1.16,0.17l-2.65,1.63l-2.7,1.98l0.37,0.56l0.5,0.23l1.36-0.32l1.23,0.11l2.26,1.6l0.63,0.25l1.1-0.18l1.37,0.94
  		l0.39,1.21l0.09,0.77l-0.69,2.36l-0.33,0.64l-0.24,0.23l-0.1,0.45l1.73,1.24l0.84,0.35l1.3,0.12l1.39-0.37l1.75-1.18l1.58-1.9
  		l2.08-0.89l1.44-0.17l1.68,0.22l0.57-0.97l1.71-0.85l0.97-1.25l0.82-0.22l0.91,0.18l0.69,0.32l0.37,0.32l0.56,0.36l1.56-0.27
  		l1.47,0.05l1.38-0.41l0.3-0.91l0.19-0.82l0.53-0.74l0.51-0.94l-1.25-0.41l-1.15-0.25l-1.01,0.04l-0.79,0.3l-0.54,0.55l-1.51-0.31
  		l-0.7,0.13l-1.63,0.82l-0.85-0.06l-0.7-0.39l-0.96-0.97l-2-2.66l-0.98-1.86l-0.42-1.14l-0.11-0.91l0.05-1.22l1.01-0.55l0.8-0.27
  		l1.4-1.83l0.52,0.74l-0.01,0.59l-0.09,0.37l-0.53,0.5l-1.71,1.33l0.34,0.02l0.65-0.23l0.61-0.46l0.44-0.37l1.21-1.87l3.6-2.56
  		l1.67-0.45l1.43,0.23l0.71-0.14l1.28-0.78l1.07-0.32l1.09-0.02l0.89,0.53l1.16-1.17l0.97-0.54l1.44-0.44l1.02,0l1.71-1.67l2.4-0.23
  		l2.94,0.17l0.3-0.12l-0.07-0.47l-0.04-0.42l0.17-0.4l0.37-0.26l0.13-0.23v-0.19l-0.33-0.12l-0.28-0.09l-0.06-0.26l0.09-0.23
  		l0.26-0.33l0.11-0.38l0.02-0.75l0.24-0.85l0.69-0.54l1.2-0.13l1.09-0.47l0.66-0.52l0.87-1.32l0.66-0.19l1.33,0.07l0.85,0.12
  		l1.96,0.04l2.25-0.08l0.65-0.04l0.36-0.46l-0.02-0.82l0.32-0.76l0.6-1.43l0.64-0.75l-0.03-0.33l-0.33-0.14l-0.44-0.07l-0.16-0.15
  		l0.02-0.31l0.34-0.31l-0.06-0.63l-0.21-0.7l-0.18-0.63l-0.59-0.31l-1.02-0.21l0.22-0.77l0.29-0.83l0.43-0.46l0.31-0.28l0.94,0.15
  		l0.68-0.15l0.16-0.16l-0.12-0.32l-1.06-0.29l-0.93-0.4l-0.4-0.49l-0.16-0.52l0.61-0.31l1.1-0.3l0.73-0.71l0.79-0.77l0.33-0.56
  		l0.16-0.61L2147.66,867.43z"/>
    </>,
    TZA: <>
        <path className="st0" d="M2143.71,1385.64l0.46-0.11l0.43-0.55l0.53-0.09l0.62-1.45l0.08-0.66l-0.14,0.12l-0.37,0.56l-1.09,0.85
  		l-0.47,0.58l-0.49,0.3l0.28,0.35L2143.71,1385.64z M2143.85,1363.92l0.29,0.19l0.35,0.12l0.39-0.41l0.48-1.17l0.05-0.84l-0.03-1.27
  		l0.13-0.4l-0.05-0.42l-0.71,0.33l-0.9-0.15l0.23,1.57l-0.46,2.14L2143.85,1363.92z M2139.92,1369.6l-0.2,0.75l0.52,0.86l0.58,0.03
  		l0.59,0.72l0.35-0.14l0.2,0.6l0.28,0.29l0.24-0.02l0.46-0.2l0.08-0.33l-0.64-1.79l-0.07-0.07l-0.46-0.43l-0.55-1.38l-0.09-1.17
  		l-0.41-0.75l-0.35,1.1l-0.63,0.65L2139.92,1369.6z M2077.69,1336.98l-0.23,0.36l-0.07,0.73l0.5,0.51l0.05,0.61l0.43-0.1l0.15-0.59
  		l0.41-0.06l0.6-0.12l0.14-0.19l0.05-0.53l-0.13-0.19l-0.59,0.19l-0.63,0.16l-0.2-0.17l-0.3-0.41L2077.69,1336.98z M2150.41,1406.33
  		l-0.14-0.28l-0.39-0.48l-1.46-0.95l-0.66-0.32l-0.46-0.39l-0.84,0.02l-0.32-0.57l-0.69-0.59l-1.17-0.18l0.49-0.73l-0.08-0.65
  		l-0.39-0.95l-0.26-1.24l-0.6-1.43l0.13-1.83l-1.6-2.1l0.24-0.31l0.07-0.37l-0.94-1.19l-0.62-2.33l0.04-0.78l0.26-0.91l0.85-1.95
  		l-0.11-1.67l-0.82-0.56l-0.37,0.35l0.01-2.27l0.54-1.48l0.68-1.13l0.72-0.7l0.23-0.84l-0.62-1.22l-1.56-0.54l-0.5-1.09l-0.87-1.09
  		l-0.49-0.48l-0.72-0.37l-0.9-1.04l-0.16-1.06l-0.43-1.13l0.12-1.62l0.77-2.11l0.57-0.9l0.67-2.41l0.25-0.55l0.26-0.84l0.04-0.71
  		l0.66-1.71l0.17-0.71l-0.27-0.13l-0.63-0.45l-1.29-0.93l-1.29-0.93l-1.29-0.93l-1.29-0.93l-1.29-0.93l-1.29-0.93l-1.29-0.93
  		l-1.29-0.93l-0.76-0.54l-0.34-0.32l-0.26-0.64l-0.13-0.16l-0.34-0.2l-0.4-0.04l-0.12-0.12l0-0.31l0.14-0.44l0.48-0.85l0.05-0.5
  		l-0.09-0.57l-0.15-0.91l-0.13-0.21l-0.86-0.48l-1.8-1l-1.8-1l-1.8-1l-1.8-1l-1.79-1l-1.8-1l-1.79-1l-1.8-1l-1.8-1l-1.8-1l-1.79-1
  		l-1.8-1l-1.8-1l-1.8-1l-1.8-0.99l-1.79-1l-0.64-0.35l-1.05,1.22l-0.24,0.5l0.04,0.26l0.38,0.36l-0.08,0.16l-0.83,0.02l-0.13,0.18
  		l0,0.22l-0.11,0.13l0.05,0.16l0.79,0.59l0.05,0.16l-0.27-0.01l-0.83-0.23l-0.51-0.04l-0.38,0.07l-0.15,0.15l-0.19,0.54l0.18,0.28
  		l0.07,0.29l-0.62,0.44l-0.07,0.59l-0.39,0.32l-0.49,0.19l-0.73-0.09l-0.3,0.15l-0.29,0.29l-0.28,0.48l1.28-0.06l0.23,0.11
  		l0.11,0.21l0.25,0.22l0.03,0.14l-0.94,0.01l-0.32,0.29l-0.62-0.08l-0.57,0.4l-0.05,0.2l0.42,0.24l2.03,0.32l0.57,0.05l1.42-0.47
  		l0.35,0.1l0.13,0.28l-0.01,0.29l-0.35,0.38l-1.46,1l-0.7,0.61l-0.66,0.25l0.08,0.36l-0.04,0.16l-0.18,0.08l-1.73-0.35l-1.2-0.66
  		l-0.58-0.07l-0.45,0.16l-0.31,0.24l-0.13,0.29l-0.07,1.51l0.53,1.29l0.13,0.25l-0.59,0.11l-0.95,0.92l-0.17,0.11l0.11-0.62
  		l-0.08-0.36l0.7-0.58l0.12-0.34l-0.4-0.92l0.09-0.86l-0.11-0.24l-0.5-0.01l-0.41-0.1l-0.15-0.09l-0.12-0.29l-0.23-0.14l-0.8,0.58
  		l-0.11-0.11v-0.27l-0.12-0.07l-0.69-0.08l-0.41-0.18l-0.29-0.24l-0.31-0.75l-0.31-0.18l-0.36,0l-0.25,0.22l-0.31,0.47l-0.5,0.09
  		l-0.15,0.14l0.37,0.77l0.08,0.33l-0.17,0.1l-0.84-0.17l-0.38,0.26l-0.15,0.52l0.09,0.57l0.21,0.67l-0.28-0.04l-0.45-0.65
  		l-0.17,0.06l-0.11,0.17l-0.31,0.04l-0.25,0.78l-0.15-0.06l-0.15-0.34l-0.02-0.48l0.28-0.6l-0.38-0.2l-0.23-0.26l-0.06-0.31
  		l0.43-0.54l-0.04-0.22l-0.12-0.34l-0.23-0.35l-0.31-0.24l-0.23-0.43l0-1.65l-0.1-0.53l0-0.71l0.83-3.68l0.4-0.61l0.13-0.5
  		l0.33-2.24l-0.04-0.4l-0.1-0.23l-0.17-0.03l-0.21,0.07l-0.18-0.1l-0.01-0.07h-1.01h-1.5h-1.5h-1.5h-1.5h-0.88l-0.18-0.03
  		l-0.12-0.03l-0.57,0.11l-0.58,0.37l-0.62,0.15l-0.66-0.06l-0.08,0.04l-0.28,0.13l-0.06,0.4l0.32,0.64l1.04,1.33l0.66,0.25
  		l0.43,0.52l0.42,0.87l0.13,1.09l-0.18,1.31l0.1,0.98l0.38,0.64l0.1,0.83l-0.18,1.02l-0.22,0.61l-0.26,0.2l-0.3,0.08l-0.4-0.07
  		l-0.49,0.09l-0.53,0.19l-0.33,0.03l-0.17,0.22l-0.77,1.57l-0.15,0.24l0.09,0.14l0.33,0.3l-0.19,0.49l-0.08,0.13l-0.15,0.46
  		l0.08,0.42l0.19,0.16l0.5,0.2l0.75,0.15l0.89,0.35l0.6,0.06l0.14,0.25l-0.03,0.45l0.15,0.39l0,0.71l-0.18,0.62l-0.91,0.29
  		l-0.47,0.32l-0.13,0.16l0.12,0.19l0.06,0.25l-0.86,0.62l-0.88,0.81l-0.21,0.54l-0.18,0.64l-0.26,0.41l-0.67,0.59l-0.69,1.19
  		l-0.34,0.78l-1.68,1.86l-1.5,0.93l-0.44,0.32l-0.6-0.01l-0.05,0.29l-0.29,2.51l0.06,0.77l-0.12,0.13l0.06,0.09l0.25,0.05l0.4,0.21
  		l0.55,0.38l0.36,0.48l0.17,0.57l0.03,0.36l-0.11,0.14l-0.02,0.74l-0.11,0.05l-0.12,0.76l-0.18,0.43l0.05,0.52l0.45,0.51l0.44,0.86
  		l0.49,1.18l0.23,0.75l-0.03,0.32l-0.27,0.33l-0.5,0.35l-0.33,0.14l-0.16-0.06l-0.19,0.09l-0.23,0.25l-0.16,0.55l-0.1,0.85l0.04,0.4
  		l0.22,0.37l0.61,0.69l1.04,0.92l0.05,0.16l0.13,0.02l0.49-0.26l0.6-0.1l0.36,0.07l0.25,0.15l0.13,0.23l0.99,0.89l0.48,0.63
  		l0.35,0.74l0.48,0.65l0.62,0.56l0.3,0.59l-0.02,0.61l-0.15,0.32l-0.19,0.03l-0.07,0.07l0.08,0.42l0.46,0.93l0.19,0.84l-0.09,0.74
  		l0.13,0.66l0.35,0.57l0.67,0.77l0.85,1.76l0.68,1.07l0.06,0.31l0.11,0.2l0.27,0.19l0.04,0.16l-0.12,0.19l0.01,0.24l0.12,0.4
  		l0.43,0.55l0.73,0.69l0.35,0.55l-0.04,0.42l0.11,0.43l0.27,0.43l0.02,0.06l1.39-0.02l0.83,0.4l0.72,0.5l0.18,0.78l0.48,0.49
  		l0.51,0.38l0.22,0.05l0.38-0.09l0.62-0.01l0.57,0.17l0.27,0.17l0.03,0.65l0.17,0.29l0.78,0.11l0.8,0.05l0.77,0.44l0.83,0.08
  		l0.96,0.18l0.45,0.48l1.02,0.49l1.25,0.44l0.9,0.49l0.48,0.22l0.15-0.06l0.31-0.04l1.32,0.85l0.8,0.04l0.89,0.16l0.76,0.75
  		l0.39,0.1l0.5-0.1l1.43-0.08l0.58,0.11l0.74,0.44l0.29,0.06l0.46,0.02l0.08-0.12l0.05-0.26l-0.08-0.52l0.11-0.28l0.28-0.31
  		l0.78,0.36l1.96,1.64l0.06,0.21l1.25,1.62l0.41,0.69v0.36l0.38,1.42l0.08,0.67l-0.09,0.51l0.02,0.41l0.15,0.58l-0.05,0.24
  		l0.45,0.85l0.21,0.72l0.04,0.7l-0.12,0.68l-0.39,0.99l-0.07,0.4l0.09,0.36l0.25,0.4l0.42,0.43l0.32,0.51l0.22,0.6l0.18,0.27
  		l0.23-0.01l0.42,0.09l0.34,0.35l0.39,0.59l0.14,0.72l0.05,0.26l1.88-0.03l1.98,0.07l0.28,0.05l0.45,0.13l0.5-0.02l0.56-0.17
  		l0.62-0.42l0.68-0.67l1.06,0.02l1.44,0.7l0.79,0.61l0.13,0.52l0.96,0.3l1.79,0.08l1.31-0.27l0.82-0.63l0.86-0.33l0.89-0.04
  		l0.68,0.21l0.46,0.47l0.88,0.33l1.3,0.2l1.42-0.3l1.54-0.8l0.88-0.84l0.22-0.87l0.26-0.53l0.29-0.19l0.82-0.11l1.34-0.03l1.17,0.27
  		l1.49,0.87l0.94-0.58l1.61-0.99l1.62-0.52l1.55,0l1.27-0.38l0.99-0.75l1.05-0.47l1.1-0.2l1.03-0.36l1.45-0.78l1.5-1.13l1.53-1.15
  		l0.98-0.74L2150.41,1406.33z M2086.39,1335.89l0.4,0.2l0.76,0.17l0.59,0.25l0.55,0.19l0.42,0.01l0.07-0.36l-0.36-0.39l-0.17-0.48
  		l-0.19-0.38l-0.59,0.07l-0.47,0l-0.19-0.6l-0.54,0.02l-0.46,0.28l0.15,0.35L2086.39,1335.89z"/>
    </>,
    TWN: <>
        <path className="st0" d="M2806.41,1109.72l-0.44-0.07l0.07,0.36l1.16,0.2l0.16-0.37l-0.37-0.59L2806.41,1109.72z M2836.03,1105.92
  		l0.61-0.7l-0.2-0.74l-0.44-0.43l-1.01-0.44l-0.39-0.25l-0.37-0.45l-0.42-0.38l-0.65-0.01l-0.57,0.25l-0.71,0.8l-2.27,0.84
  		l-0.46,0.29l-0.64,0.93l-0.53,1.02l-0.55,0.81l-0.66,0.72l-1.08,1.46l-3.96,6.83l-0.23,0.5l-0.06,1.12l0.15,1.13l-0.18,0.83
  		l-0.3,0.82l-0.11,0.55l0.09,0.5l0.32,0.5l0.24,0.55l0.7,2.27l0.34,0.8l0.44,0.75l-0.08-0.05l0.6,0.56l0.78,0.38l0.85,0.75
  		l0.22,0.39l0.59,1.34l0.1,1.11l0.44,0.68l0.82,0.27l0.21-0.95l0.12-0.96l-0.01-1.06l0.17-1.03l0.42-1.09l0.52-1.03l1.28-1.38
  		l1.13-1.68l0.47-0.89l0.38-0.93l0.67-2.22l0.41-2.16l0.48-1.71l0.25-1.7l0.22-0.69l0.82-1.38l0.77-2.21l-0.01-0.94l-0.11-0.94
  		l0.06-0.7L2836.03,1105.92z"/>
    </>,
    TTO: <>
        <path className="st0" d="M1296.62,1227.6l-1.36,0.07l-0.8,0.24l-1.65,0.06l-0.8,0.27l-1.07,0.14l-0.5,0.25l0.13,0.16l0.8,0.3l0.35,0.22
  		l0.17,0.3l0.11,0.54l-0.29,2.29l-0.25,0.13l-0.87,0.08l-0.24,0.44l-2.06,1.04l1.13-0.13l1.47,0.17l3.56-0.11l1.37-0.48l0.07-0.28
  		l0.04-0.79l0.26-0.52l-0.4-0.53l-0.18-0.81l0.15-0.64l-0.12-0.95l0.31-0.39L1296.62,1227.6z M1299.61,1223.5l-1.23,0.39l-0.8,0.58
  		l-0.05,0.34l0.46-0.08l1.77-0.72l0.18-0.52L1299.61,1223.5z"/>
    </>,
    TUR: <>
        <path className="st0" d="M2030.57,958.47l1.15,0.12l1.83-0.18l1.8-0.02l-0.6,0.82l-2.3,0.99l-0.78,0.54l-0.85,0.75l-0.01,0.66
  		l0.07,0.45l-0.29,0.6l-0.2,0.66l0.58-0.21l0.49-0.27l1.16-1.37l2.56-2.35l1.71-0.66l2.39-1.23l1.45-1.52l0.58-1.33l2.09-0.4
  		l1.5,0.23l1.35-0.71l0.73-0.19l1.03,0.09l4.09,0.97l1.48-0.34l0.33-0.53l0.3-0.79l0.21-0.89l-0.93-0.19l-5.06-2.19l-1.25-0.88
  		l-1.24-1.76l-0.53-1.27l0.23-1.15l-1.14-0.18l-0.4,0.05l-0.25,0.25l-0.53-0.05l-0.65,0l-0.68,0.29l-0.38,0.12l-0.51-0.26
  		l-0.94-0.79l-0.57-0.55l-0.43-0.14l-0.43,0.16l-1.53,0.19l-0.36,0.32l-0.71,0.35l-0.71,0.17l-1.02,0.12l-0.54-0.02l-0.3,0.17
  		l-0.25,0.52l-0.17,0.5l-0.15,0.2l-1.27,0.25l-0.28,0.29l-0.08,0.28l0.02,0.28l0.76,0.2l0.44,0.33l0.27,0.3l0.42,0.26l0.31,0.06
  		l0.24,0.9l0.13,1.11l-0.19,0.48l-0.56,0.11l-1.73,1.05l-0.04,0.96l0.02,0.46l0.04,0.33l0.18,0.28v0.4l-0.19,0.43l-0.76,0.71
  		l-0.52,0.57l-0.59,0.77l-0.33,0.09l-0.26,0.13l0.24,0.43l0.32,0.72L2030.57,958.47z M2027.63,962.29l-1.13,0.37l-0.61,0.6l0.6,0.3
  		l1.94-0.31l0.06-0.41l-0.49-0.6L2027.63,962.29z M2186.98,991.13l-0.67-0.65l-0.94-0.65l-0.25-0.11l-0.01-0.69l0.03-0.52
  		l-0.08-0.47l-0.18-0.27l-0.01-0.21l0.38-0.51l-0.24-0.33l-1.38-0.82l-0.52-0.41l-0.95-0.08l-0.1-0.27l0.15-0.58l0.33-0.7l0.52-0.69
  		l0.16-0.36l0.2-0.62l0.07-0.44l0.58-0.62l0-0.16l-0.16-0.22l-0.46-0.12l-0.47-0.05l-0.18-0.11l-0.06-0.33l0.06-1.35l-0.15-0.81
  		l-0.19-0.59l0.12-1.33l-0.33-0.27l-0.52-0.7l-0.22-0.59l0.12-0.22l0.11-0.39l0.07-0.51l-0.02-0.36l-0.48-0.35l-0.36-0.37
  		l-0.04-0.41l-0.14-0.5l-0.2-0.4l-0.09-0.26l0.17-0.15l0.67-0.12l0.98,0.08l0.8,0.01l0.45-0.26l0.56-2.41l0.51-0.64l0.59-0.37
  		l1.16,0.86l0.48,0.3l0.3,0.01l-0.28-0.34l-0.13-0.19l-0.29-0.42l-1.46-1.39l-1.35-1.07l-0.93-0.44l-0.94,0.05l-1.45,0.21
  		l-0.53-0.09l-1.27-0.47l-1.06-0.56l0.14-0.23l0.22-0.17l-0.27-0.72l-0.59-1.16l0.06-0.36l-0.19-0.5l-0.21-0.38l0.83-0.91l0.38-0.73
  		l0.08-0.72l-0.22-0.74l-0.55-1.35l-0.34-0.39l-0.63-0.37l-0.52-0.6l-0.13-0.42l0.02-0.19l-0.07-0.3l-0.26-0.21l-0.37-0.14
  		l-0.67,0.05l-0.62-0.14l-0.44-0.37l-0.1-0.28l0.26-0.23l-0.19-0.19l-0.77-0.46l-1.27-1.14l-0.72-0.26l-0.28-0.72l-0.28-0.15
  		l-0.6-0.07l-0.64,0.07l-0.14,0.08l-0.19,0.11l-0.5,0.9l-0.35,0.3l-0.86-0.14l-0.71-0.21l-0.58-0.12l-1.12-0.07l-1.28-0.02
  		l-0.86,0.64l-0.37-0.08l-0.65-0.31l-1.05-0.26l-0.56-0.2l-0.81,0.94l-2.79,1.63l-1.05,0.5l-1.18,0.21l-1.11,0.83l-3.56,1.46
  		l-1.15,0.18l-1.08-0.34l-0.75,0.11l-0.87-0.16l-3.21-1.24l-4.84,0.89l-2.49,0.81l-1.48,0.12l-3.97-0.77l-1.22-0.77l-2.82-0.35
  		l-3.07-0.7l-0.62-0.91l-1.8-0.88l-1.1,0.11l-0.51,0.26l-0.65,0.64l-0.88-0.12l-1.07-0.62l-0.84-0.91l-1.07-2.57l-0.62-0.22
  		l-0.49-0.09l-3.05,0.8l-2.19-0.95l-0.75-0.66l-0.73-0.97l-0.07-0.66l0.23-0.33l0.12-0.38l-1.25-0.36l-2.16,1.07l-4.7-0.07
  		l-6.84-0.54l-0.81,0.13l-2.85,1.14l-3.41,0.86l-1.99,0.77l-1.85,1.41l-5.29,2.7l-0.94,1.62l-0.77,0.5l-3.75,0.23l-3.92-1.12
  		l-3.58,0.46l-5.03-0.77l-1.47,0.07l-0.45,0.44l-0.23,0.76l-0.18,0.94l0.31,0.44l0.27,0.26l1.23,0.9l0.88,0.38l3.67,0.49l0.41,0
  		l-0.04,0.22l-2.84,0.3l-3.82,0.59l-0.81,0.19l-1.43,0.96l0.45,0.3l0.45,0.21l0.67,0.15l0.68,0.43l-0.4,0.34l-2.26-0.01l-0.91,0.14
  		l-2.87-0.26l-2.75,0.33l-0.28-0.1l0.3-0.55l0.26-0.31l-0.04-0.23l-0.97-0.23l-0.89,0.03l-0.31,0.28l0.98,0.99l-0.5,0.31l-0.52,0.22
  		l-2.13,0.09l-1.2-0.56l-0.16-0.39l-0.25-0.4l-1.37,0.03l-0.92,0.56l-2.31-0.04l-2.21,2.02l-1.36,1.71l-1.11,0.35l-0.27,1.16
  		l0.04,2.14l-0.45,0.87l-0.05,0.47l0.14,0.53l2-0.16l1.12-0.36l2.89-0.42l0.61,0.13l0.1,0.32l-0.82,0.97l-0.86,0.78l-0.24,0.47
  		l0.32,0.31l1.13,1.43l-0.04,0.58l-0.35,0.42l0.06,0.52l0.43,0.37l0.46-0.11l0.42,0.15l0.37,0.32l-0.88,1.09l-1,0.39l-0.22,0.26
  		l0.2,0.48l0.06,0.33l0.36,0.67l0.58,0.74l0.54,0.33l1.46-0.04l-0.38,0.35l-2,0.42l-0.77-0.15l-0.36-0.3l-0.26,0.13l-0.19,0.68
  		l-0.28-0.16l-0.39-0.65l0.13-0.67l-0.2-0.69l-0.61-0.71l-0.61-0.11l-0.53,0.17l-0.05,0.61l0.48,1.19l-0.11,0.71l-0.61-0.02
  		l-0.45,0.91l0.36,0.34l0.8,0.27l0.82,0.51l0.49,0.13l0.33-0.26l0.52-0.21l1.05,0.58l0.6,0.81l0.55-0.08l1.81,0.74l0.62,0.08
  		l0.19,0.94l-0.26,1.52l-1.23,0.37l-0.08,0.29l0.67,0.53l0.47,1.09l0.13,0.99l0.59,0.39l0.73,0.08l0.2,0.33l1.01,0.56l0.13,0.82
  		l-1.41,0.4l-0.57-0.04l-0.43,0.46l0.11,0.99l0.4-0.05l0.32-0.36l2.69,0.12l3.92-0.21l0.92,0l-0.15,0.32l-1.19,0.73l-0.65,0.85
  		l-0.6,0.22l-2.56,0.22l-0.64,0.27l-0.74,0.12l-0.11,0.33l0.73,0.27l0.97,0.09l1.25-0.6l2.36-0.14l-0.59,0.78l0.04,0.34l0.78-0.11
  		l0.71-0.38l0.91-1.21l1.52,0.08l1.97,0.99l0.84,0.25l0.67,0.02l0.62-0.4l0.58,0.21l0.17,0.53l0.06,0.46l0.43,0.67l0.23,1.18
  		l0.68,0.7l1.05,0.63l2.87,0.98l0.84-0.11l2.48-0.78l1.25-0.56l0.54,0.19l0.77,0.43l0.5-0.26l0.32-0.39l0.19-1.35l0.44-0.72
  		l0.2-2.61l0.52-0.66l2.58,0.16l2.45,0.26l0.94,0.2l3.58,1.81l2.06,0.75l0.91,0.83l1.29,1.74l0.79,0.81l1.31,0.79l2.2,0.62
  		l1.13-0.57l1.43-0.07l2.88-0.48l0.68,0.08l1.45-0.36l2.19-1.09l0.58-0.44l2.33-2.53l2.54-1.74l0.86-0.31l0.91,0.17l1.11,0.71
  		l1.96,0.87l1.83,0.57l1.21-0.21l0.74-0.54l0.3-0.69l0.62-0.38l0.57-0.14l0.87-0.67l1.22-0.61l0.73,0.57l0.38,0.43l0.07,0.62l0,0.81
  		l-1.32,1.31l-1.25,1.12l-0.61,0.93l0.64,1.45l0.59,1.54l-0.54,0.78l0.63,0.06l1.35,0.75l0.22-0.02l0.41-0.99l0.39-0.34l0.83-0.29
  		l0.23-1.61l0.39-0.31l0.47-0.17l0.72-0.03l0.63-0.1l0.04-0.28l-0.88-1.86l0.08-0.47l0.42-1.88l0.27-0.74l0.25-0.24l0.99,0.1
  		l1.39,0.33l0.37,0.54l0.68,0.48l1.02-0.03l1.18,0.09l0.92,0.03l0.73-0.34l1.66-0.63l0.82-0.21l0.75-0.28l2.4-1.03l0.96,0.08
  		l0.66,0.14l0.5,0.16l1.13,0.71l0.93,0.71l0.65,0.21l1.18-0.01l1.7,0.14l2.09-0.01l1.22-0.2l1.56-0.35l2.78-0.84l3.66-1.76
  		l2.15-0.86l0.93-0.1l1.21-0.01l1.21,0.23l1.37,0.16l0.63-0.01l1.48-0.18l1.92-0.36l1.21-0.29l1.46-0.48l0.91-0.8l0.29-0.08
  		l0.38,0.15l0.18,0.05l0.37,0.45l0.39,1.17l0.82-0.19l1.51-1.17l0.89-1.09l0.28-0.1l0.8,0.36l0.57,0.1l1.31-0.41l0.78,0.22
  		l0.66,0.27l0.37,0.02l1.76,0.68l0.44,0.08l0.91,0.08l1.35,0.04l0.87-0.44l0.62-0.43l0.43,0.01l0.42,0.1l0.35,0.18l0.3,0.32
  		l0.14,0.46l-0.06,1.46l0.13,0.39l0.24,0.28l0.3,0.05l0.37-0.32l0.64-0.46l0.79-0.5l0.59-0.46l0.34-0.17l0.53,0.02l0.52,0.08
  		l0.29,0.22l0.01-0.13l-0.07-0.59l0.32-0.51L2186.98,991.13z"/>
    </>,
    TON: <>
        <path className="st0" d="M3368.09,1500.04l0.05,0.28l0.41,0.6l0.05-1.32l-0.09,0.03L3368.09,1500.04z M3366.62,1498.46l-0.12,0
  		l-0.32-0.12l0.35-0.08l-0.57-0.24l-0.63-0.05l-0.19-0.12l0.04-0.27l-0.37,0.34l0.23,0.45l1.12,0.58l0.39,0.35l0.61-0.9l0.05-0.28
  		l-0.45,0.09L3366.62,1498.46z M3376.55,1475.78l-0.29,0.05l-0.56,0.6l0.13,0.2l0.37,0.3l0.15,0.01l0.32-0.51l0.25-0.27l0.02-0.17
  		L3376.55,1475.78z"/>
    </>,
    TUN: <>
        <path className="st0" d="M1904.69,1015.43l0.03-0.46l-0.22-0.17l-0.86,0.72l-0.25,0.6L1904.69,1015.43z M1906.84,1036.4l-0.67-1.1v-1.3
  		l0.04-1.08l0.07-0.64l0.3-1.78l0.02-0.25l-0.88-0.4l-0.53,0.14l-0.87-0.23l-0.27-0.15l0.25-0.21l0.32-0.14l-0.11-0.21l-0.9-0.57
  		l-0.55-1.82l-1.07-0.6l-0.5,0.87l-0.59,0.14l-0.89,0.04l-0.16-0.9l0.07-0.75l-2.18,0.25l-1.26-0.62l-1.23-1.15l-0.93-1.95
  		l-0.08-0.79l0.21-0.67l0.45-0.65l0.69-0.62l2.82-1.88l1.31-1.27l1.48-1.95l0.76-1.42l1.38-1.97l-0.65-0.9l-0.1-1.13l-0.26-0.93
  		l0.03-0.79l-1.86-1.32l-0.8-0.26l-0.83-0.84l-0.72-1.39l-0.25-1.37l0.1-0.77l0.32-0.66l0.98-0.92l1.31-0.71l1.42-2.41l0.75-0.95
  		l0.59-0.31l-0.42-0.89l-0.19-1.02l-0.86,0.13l-1.56,1.25l-1.64,0.49l-0.45,0.85l-0.89,0.57l-1-0.48l0.34-0.81l-1.22-1.63l0.06-1.66
  		l-0.92-0.44l-0.84-0.06l-0.95,0.03l0.03,0.4l0.14,0.3l-0.56,0.45l-0.39-0.74l0.27-0.42l0.19-0.53l-0.67-0.21l-0.6-0.1l-4.6,1.41
  		l-0.7,0.37l-1.98,1.53l-2.08,0.58l0.18,0.51l0.03,0.48l-0.8,0.45l-0.53,0.26l-0.63,1.23l-1.17,0.84l-0.19,0.25l0.01,0.23l0.79,0.38
  		l0.26,0.36l0.12,0.48l-0.35,1.72l-0.22,1.32l-0.29,1.73l0.01,0.66l0.3,0.79l0.3,0.61l0.09,0.69l-0.11,1.71l0.37,0.99l0.29,0.91
  		l-0.69,1.13l-0.3,1l-0.19,1.43l-0.08,0.9l-0.44,0.83l-0.58,0.78l-0.66,0.49l-0.81,0.42l-0.94,0.55l-0.76,1.47l-1.63,1.22
  		l-0.34,0.42l-0.15,0.98l0.04,1.36l0.29,1.08l0.78,1.59l0.69,1.75l0.19,0.88l0.26,0.33l0.96,0.57l1.67,0.78l0.31,0.32l0.83,1.2
  		l0.79,2.16l0.25,1.43l1.53,1.13l1.41,1.04l1.36,0.92l1.47,0.99l0.21,0.31l0.49,2.1l0.49,2.09l0.54,2.3l0.54,2.3l0.64,2.7l0.36,1.53
  		l0.44,1.86l0.51,2.17l1-0.49l1.43-0.55l0.74-0.41l0.32-0.35l1.07-1.42l0.56-0.79l0.76-1.08l0.33-0.75l0.01-0.7l-0.11-0.84
  		l-0.6-2.02l-0.49-1.97l0.38-0.76l0.3-0.36l0.66-0.93l0.26-0.18l1.43-0.29l0.57-0.62l0.44-0.77l0.11-0.41l0.62-0.43l0.75-0.43
  		l0.46-0.55l1.51-0.86l1.37-0.78l1.6-0.82l1.24-0.64l0.26-0.56L1906.84,1036.4z M1901.69,1023.57l-1.49,0.04l-0.1,0.31l-0.1,1.1
  		l0.29,0.2l0.23,0l0.61,0.29l0.22-0.03l0.41-0.26l0.22-0.04l0.3-0.22l0.37-0.37l-0.03-0.19l-0.13-0.17L1901.69,1023.57z"/>
    </>,
    TKM: <>
        <path className="st0" d="M2256.87,973.54l-0.33-0.02l-0.29,0.43l0.23,1.53l0.46,1.38l0.08-0.46l-0.46-2.3L2256.87,973.54z
  		 M2370.01,986.33l0.87-1.42l-0.02-0.27l-0.17-0.26l-0.27-0.23l-1.56-0.39l-0.46-0.21l-0.6-0.45l-0.76-0.47l-0.66-0.33l-1.04-0.43
  		l-0.96-0.24l-0.56,0.18l-0.52,0.23l-0.49,0.01l-0.49-0.13l-1.8-1.08l-2.72-1.86l-2.16-1.3l-1.35-0.62l-0.32-0.2l-0.76-0.59
  		l-1.87-1.58l-1.24,0.23l-1.77-1.03l-1.59-1l-0.36-0.27l-1.81-1.86l-1.8-1.21l-1.97-1.32l-1.27-0.83l-2.16-1.4l-1.05-0.85
  		l-0.36-0.31l-0.35-0.6l-0.56-2.93l-0.65-1.35l-0.92-0.73l-0.79-1.41l-0.65-2.1l-0.54-1.37l-0.43-0.63l-0.87-0.7l-1.31-0.76
  		l-1.24-0.36l-0.45,0.01l-0.22,0.1l-0.25,0.13l-0.49,0.57l-0.73,0.06l-0.53-0.01l-0.5-0.2l-1.57-0.18l-0.56-0.2l-0.95,0.03
  		l-2.03,0.3l-0.49-0.05l-2.15-1.28l-0.93-0.51l-0.19-0.28l0.01-0.49l0.31-0.69l0.27-0.49l-0.12-0.51l-0.41-0.56v-0.59l0.28-0.33
  		l0.57,0.1l0.21-0.21l-0.07-0.32l-0.31-0.23l-0.41-0.51l-1.22-0.47l-0.18-0.19l0.06-0.22l0.21-0.24l0.04-0.5l0.02-0.64l0.16-0.33
  		l0.04-0.26l-0.18-0.21l-0.41-0.25l-0.66-0.6l-0.81-0.06l-2.62,0.02l-0.82-0.24l-0.65-0.33l-0.65-1.27l-0.33-0.3l-0.31-0.13
  		l-0.74-0.04l-0.88-0.12l-0.45-0.22l-1.24-1.16l-1.19-1.04l-0.48,0.98l-0.46,0.19l-1.04-0.09l-0.79-0.17l-0.45,0.22l-0.46,0.39
  		l0.09,0.25l0.35,0.27l0.71,0.5l1.09,1.22l0.5,0.67l0.07,0.24l-0.1,0.18l-0.15,0.08l-0.22,0l-0.29,0l-0.17-0.2l-0.06-0.35
  		l-0.36-0.53l-0.37-0.3l-0.41-0.19l-0.58-0.14l-0.76-0.25l-0.39-0.01l-0.38,0.29l-0.32,0.39l-0.19,0.86l-0.57,1.05l-0.35,0.42
  		l-1.08,0.25l-0.57,0.02l0.08,0.13l-0.12,0.45l0.16,0.4l0.43,0.56l0.15,0.99l1.22,0.75l0.17,0.65l-0.4,0.74l-1.12,0.64l-1.33,0.42
  		l-0.82,0.16l-0.71,0.05l-0.83-0.8l-0.61-0.83l-0.49,0.03l-0.46,0.07l-0.07-0.49l0.3-1.72l-0.46,0.29l-0.59,0.59l0.18,1.88l0.28,2.2
  		l0.49,0.61l0.31,0.18l0.04,0.22l-0.2,0.19l-0.26,0.24l-0.39,0.44l-0.44-0.02l-0.88-0.11l-0.73-0.12l-2.47-0.13l-2-0.1l-2.23-0.11
  		l-0.36-0.02l-0.81,0.13l-1.35,0.32l-1.12,0.16l-0.49-0.1l-0.44-0.24l-0.39-0.51l-0.58-0.62l-0.59-0.5l-0.74-1.03l-0.51-0.79
  		l-1.26-1.72l-0.17-0.54l-0.24-0.56l-0.42-0.46l-1.48-1.14l-1.73-1.03l-1.69-1.01l-0.48-0.24l-0.79-0.31l-0.98-0.01l-0.66,0.06
  		l-2.03,0.33l-1.55,0.39l-2.11,0.53l-1.64,0.59l-0.36,0.17l-1.2,0.71l-1.46,1.17l-1.71,1.65l0.97,2.52l1.16,1.65l0.66,1.35
  		l0.21,0.31l0.1-0.1l-0.27-1.32l0.44-2.73l0.01-0.39l-0.58-0.6l0.76-1.85l0.55-0.81l1.16-0.95l0.47-0.24l1.02,0.12l1.78-0.39
  		l1.09-0.16l1.07,0.07l0.44,0.12l0.35,0.27l0.9,2.25l0.35,0.97l0.38,1.3l0.46,1.25l0.73,0.88l0.87,0.68l2.59,1.71l0.67,0.71
  		l0.27,0.51l0.12,0.58l0.04,0.62l-0.11,0.6l-0.21,0.18l-0.24,0.15l-0.93,0.26l-1.45-0.39l-0.46,0.37l0.14,0.69l0.35,0.71l-0.4,0.04
  		l-0.39-0.05l-0.76-0.27l-0.88,0.13l-1.84,0.58l-0.9-0.17l-0.59-0.8l-0.66-0.72l-0.8-0.12l-0.81,0.38l-0.76,0.1l-1.19-0.27
  		l-0.39-0.15l-0.72-0.65l-0.52-0.7l-0.46-0.78l-0.46,1.74l-0.33,1.77l-0.55,1.38l-0.43,1.47l0.09,1.78l0.51,1.64l0.79,1.4l0.56,0.78
  		l0.6,0.59l-0.7-1.2l0.3-0.91l1.27,0.09l1.26,0.2l0.97-0.02l0.42,0.19l0.28,0.31l-0.24,0.77l-0.07,0.82l0.18,0.79l0.51,0.27
  		l0.41,0.34l0.17,0.6l-0.89,0.13l-0.91-0.03l-0.71-0.2l-0.58-0.51l-0.93,1.74l-0.01,0.84l0.27,0.8l0.39-0.51l0.53-0.25l0.59,0.02
  		l1.17,0.35l0.54,0.31l0.66,0.57l0.73,0.06l0.04,0.55l0.12,0.49l0.76,0.84l0.45,0.67l0.14,0.84l-0.1,1.2l-0.19,1.18l-0.1,1.05
  		l0.1,1.07l0.02,1.17l-0.24,2.33l-0.01,1.16l0.2,2.51l0.42,2.48l0.14,0.68l2.34,0.11l0.91-0.2l1.34-0.52l1.01-0.32l0.51-0.04
  		l0.5-0.25l0.39-0.31l0.87-2.14l0.43-0.54l1.48-1.21l1.26-0.77l1.32-0.68l1.66-0.47l2.21,0.05l1.76,0.17l1.02-0.01l0.49,0.05
  		l0.36-0.07l0.21-0.14l0.23-0.94l0.36-0.31l0.62-0.26l0.87,0l1.06-0.07l0.88,0.06l1.11,0.36l1.45,0.03l0.96-0.06l0.56,0.36l0.4,0.48
  		l0.2,0.4l0.04,0.55l-0.01,0.42l0.15,0.16l0.59,0.25l0.82,0.19l1.6,0.23l1.5,0.43l0.78,0.3l1.08,0.46l1.29,1.14l0.48,0.18l0.58,0.12
  		l0.41-0.03l0.97-0.48l0.84,0.36l0.43-0.05l0.97-0.26l1.03,0.33l2.56,1.25l0.28-0.03l0.23,0.13l0.21,0.29l0.15,0.35l0.19,1.07
  		l0.74,0.78l0.9,0.72l1.05,0.39l2.2,0.94l0.96,0.76l0.97,1.29l1.2,1.69l0.17,0.15l3.09-0.05l3.47,0l0.42,0.68l-0.08,1.34l0.12,1.37
  		l0.31,0.95l0,0.88l-0.25,0.44l-0.19,0.51l-0.06,0.22l0.45,0.32l0.39,0.73l0.05,1.01l-0.19,0.54l0.03,0.44l0.19,0.38l0.7-0.09
  		l0.28,0.35l0.37,0.45l1.02,0.84l0.66,0.24l0.83,0.12l1.02-0.12l0.82-0.16l0.39,0.04l0.89,0.61l1.04,0.86l0.33,0.38l0.15,0.58
  		l0.31,0.17l0.66-0.58l0.64-0.19l0.59,0.11l0.65,0.06l0.65-0.21l0.29-0.15l1.14-0.75l1.03-0.57l0.64-0.35l0.23-1.17l0.3-0.66
  		l0.42-0.39l-0.16-0.48l-0.18-0.37l-0.18-0.5l0.18-0.26l0.41-0.12l1.03,0l1.81-0.52l1.51-0.52l1.4-0.43l0.64-0.07l0.6,0.07
  		l0.28-0.12l0.08-0.41l0.34-0.43l0.78-0.35l1.46-0.74l1.29-1.1l0.46-0.83l0.31-1.22l0.6-1.88l0.66-2.07l0.25-0.91l0.29-0.7
  		l1.14-0.59l1.16-0.43l1.8-0.09l2.12-0.04l0.45-1.13l0.28-0.96l0.35-0.5l0.51-0.4l0.18-0.08l1.14,0.59l1.75,0.9l2.04,0.45l1.02,0.23
  		l0.42-0.04l-0.1-1.07l0.01-1.36L2370.01,986.33z"/>
    </>,
    TL: <>
        <path className="st0" d="M2867.95,1386.9l-0.21,0.08l-0.31,0.24l-0.65,0.81l0.61,0.31L2867.95,1386.9z M2881.52,1389.04l-0.36-0.18
  		l-1.32-0.21l-0.78-0.27l-0.52,0.22l-0.5,0.3l-0.94,0.38l-0.97,0.31l-0.75,0.1l-3.02,0.15l-2.26-0.02l-0.85,0.05l-3.56,0.7
  		l-0.49,0.13l-1.22,0.48l-0.52,0.51l-0.75,1.27l-0.88,0.7l-0.06,0.75l0.18,0.18l0.31,0.09l1.07-0.51l0.2,0.1l0.21,0.23l0,0.68
  		l-0.41,0.56l-1.04,0.04l-0.15,0.16l-0.01,0.35l0.08,0.33l0.24,0.27l0.31,0.47l0.29,1.1l1.2-0.92l1.67-1.08l2.76-0.97l0.89-0.26
  		l0.46,0.02l0.43-0.07l1.07-0.68l0.77-0.4l0.85-0.2l0.99-0.13l0.88-0.38l0.69-0.67l0.82-0.43l1.07-0.23l1.03-0.34l1.68-1.11
  		l1.53-1.34L2881.52,1389.04z M2854.4,1397.03l0.14,0.29l0.32,0.35l0.21,0.06l0.16-0.08l1.24,0.12l0.31-0.12l0.47-0.54l0.31-0.3
  		l0.21-0.64l0.05-0.41l-2.07,0.56L2854.4,1397.03z"/>
    </>,
    TJK: <>
        <path className="st0" d="M2404.52,955.31l0.25-0.02l-0.03-0.24l-0.26-0.41l-0.39-0.23l-0.19,0.1l0.16,0.33L2404.52,955.31z
  		 M2404.05,965.93l-0.58-0.16l-0.13,0l0.05,0.19l0.25,0.34l0.34,0.37l0.44,0.04l0.75-0.38l-0.03-0.2l-0.29-0.1L2404.05,965.93z
  		 M2442.21,989.57l-0.93-0.77l-0.59-0.41l-0.18-0.28l0.15-0.83l0.22-0.83l-0.14-0.32l-0.18-0.27l-0.08-0.9l-0.41-1.09l-0.45-0.64
  		l-0.12-0.86l-0.03-0.81l0.54-1.26l-0.2-0.55l-0.57-0.49l-1.95-0.88l-1.99-0.58l-0.76,0.02l-0.47-0.04l-0.56,0.52l-0.34,0.67
  		l-0.47,0.06l-0.85-0.29l-0.57-0.43l-0.4-0.9l-0.31-1.16l-0.17-0.36l0.09-0.31l0.2-0.28l0.54-0.26l0.09-0.27l-0.08-0.33l-0.44-0.42
  		l-0.45-0.59l-0.7-1.23l0.13-0.68l0.11-0.97l-0.04-0.51l-0.47-0.09l-0.89-0.03l-0.7,0.18l-0.43,0.3l-0.85,0.37l-1.06,0.12
  		l-1.35,0.05l-0.65-0.03l-1.96-0.25l-0.64,0.09l-0.62,0.2l-1.12,0.2l-0.59,0.62l-0.32,0.57l-0.17,0.08l-0.7-0.52l-0.53-0.49
  		l-0.35-0.41l-0.44,0.01l-1.56,0.74l-0.23-0.02l-0.45-0.28l0.08-0.7l-0.03-0.45l-0.5-0.24l-1.06-0.06l-0.36-0.25l0.02-0.38l0.1-0.36
  		l-0.12-0.28l-0.28-0.21l-0.56,0.06l-0.64,0.29l-0.47,0.33l-0.59,0.15l-0.71,0.06l-0.45,0.2l-0.51,0.8l-1.73,0.17l-0.56-0.18
  		l-0.46-0.57l-0.6-0.92l-0.34-0.11l-0.56-0.11l-0.92,0.05l-1.24,0.39l-0.3-0.32l-0.32-0.09l-0.29,0.26l-0.3-0.03l-1.23,0.07
  		l-1.55-0.04l-0.89-0.18l-0.57,0.01l-1.14,0.41l-0.6,0l-0.79,0.08l-0.15-1.39l-0.43-0.94l0.13-0.65l0.29-0.9l0.24-0.5l0.49,0.21
  		l0.56,0.37l0.37-0.1l0.1-0.3l-0.14-0.4l-0.02-0.3l0.21-0.39l0.31-0.37l1.98-0.6l1.7-0.44l0.88,0.29l1.71,0.67l0.88,0.35l0.61,0.2
  		l0.54,0.98l0.35-0.05l0.36-0.2l0.21-0.24l0.17-0.84l0.79-0.47l1.75-0.56l0.12-0.33l-0.02-0.18l-0.49,0.04l-2.07,0.33l-0.42-0.13
  		l-0.31-0.53l-0.27-0.57l-0.54-0.21l-0.6-0.16l-0.22-0.23l-0.02-0.28l0.06-0.27l0.05-0.14l1.4-1.09l1.26-0.98l0.12-0.08l0.11-0.19
  		l0.22-0.34l0-0.18l-0.94-0.39l-0.04-0.18l0.19-0.18v-0.25l-0.67-0.72l-1.15-1.12l-0.33-0.12l-0.25,0.07l-0.45,1.08l-0.23,0.28
  		l-1.31,0.71l-1.1,0.49l-1.96,0.87l-0.51,0.27l-0.35-0.05l-0.36-0.17l-1.1-0.88l-0.71-0.3l-0.48,0.3l-0.4,0.43l0.04,0.89l-0.45,0.47
  		l-0.45,0.21l0.82,2.38l-0.08,0.31l-0.63,0.08l0.47,0.89l-0.39,0.1l-0.99-0.21l-1.34-0.14l-2.52,0.4l-0.18,0.15l-0.07,0.2l0.15,0.18
  		l1.22,0.02l1.2-0.09l0.33,0.17l0.05,0.29l-0.14,0.18l-0.4,0.03l-0.87,0.18l-0.1,0.19l-0.03,0.18l0.29,0.52l0.33,0.33l0.04,0.2
  		l-0.14,0.16l-0.17,0.07l-0.29-0.25l-0.17,0.05l-0.08,0.22l-0.08,0.26l-0.19,0.19l-0.41-0.1l-0.4,0.07l-0.24,0.95l-0.2,1.07
  		l-0.67,0.71l-0.37,0.26l-0.54,0.08l-0.81-0.09l-0.49-0.1l-1.41-0.16l-1.42-0.29l-1.6-0.27l-1.49,0.63l-0.43,0.38l-0.27,0.36
  		l-0.28,0.17l-0.64,2.2l0.07,0.25l0.36,0.2l1.82,0.46l0.27,0.19l0.16,0.21l0.08,0.99l0.15,0.14l0.62,0.12l0.9-0.01l0.71-0.1
  		l0.71,0.09l0.5,0.21l0.24,0.34l0.14,0.36l-0.85,2.17l0.07,0.79l0.26,1.13l0.48,0.88l0.9,0.86l0.69,0.55l0.14,0.26l0.04,0.4
  		l-0.11,0.51l-0.4,0.82l-0.48,0.71l-0.53,0.3l-0.73,0.9l-0.65,1.11l-1.24,1.46l-0.41,0.81l-0.14,2.34l-0.33,0.7l0.06,0.31l0.58,0.73
  		l1.04,0.89l0.92,0.21l1.22-0.69l0.41,0.08l0.2-0.22l0.12-0.5l0.74-0.47l1.34-0.44l0.76-0.4l0.27-0.33l0.46-0.09l0.5,0.1l0.35-0.12
  		l0.12-0.31l0.14-0.14l0.25-0.11l0.22-0.06l0.41,0.09l0.75,0.57l1.1,1.05l0.71,0.48l0.33-0.08l0.42-0.32l0.51-0.56l0.13-0.8
  		l-0.26-1.05l0.18-0.85l0.61-0.64l1.13-0.4l1.64-0.15l1.01,0.09l0.37,0.33l0.5,0.18l0.63,0.04l0.58-0.38l0.53-0.79l0.03-0.98
  		l-0.47-1.17l0.13-0.37l0.2-0.16l0.63-0.42l0.88-0.88l0.85-1.14l0.82-1.39l1.01-0.86l1.2-0.33l1.46,0.38l1.72,1.08l0.65,1.33
  		l-0.42,1.58l-0.04,0.87l0.35,0.16l0.59-0.04l0.83-0.25l0.54-0.01l0.25,0.22l-0.02,0.45l-0.29,0.67l-0.35,1.87l-0.21,1.62
  		l-0.21,1.59l-0.18,1.4l0.33,1.09l0.5,1.64l0.56,1.08l0.57,0.35l0.57,0.12l0.57-0.1l1.18-0.7l1.78-1.29l1.73-0.79l2.52-0.45
  		l0.84-1.39l1.17-0.92l2.66-1.36l1.45-0.52l0.83-0.09l1.04,0.25l0.24,0.09l0.21,0.07l0.53,0.11l0.11,0.42l-0.14,0.45l-0.58,0.37
  		l-0.18,0.29l0.22,0.21l0.81,0.07l1.68-0.5l1.09-0.32l0.75-0.13l0.31-0.42l0.47-0.42l0.75-0.03l0.81,0.22l0.67,0.13l1.14-0.12
  		l0.6,0.36l0.84,0.69l0.38,0.43l0.13,0.1l0.23-0.18l0.76-0.42l0.59-0.49l0.33-0.4L2442.21,989.57z"/>
    </>,
    THA: <>
        <path className="st0" d="M2648.64,1263.38l0.31,0.68l0.16-0.05l0.32-0.41l-0.4-1.21L2648.64,1263.38z M2643.91,1254.63l-0.06,0.09
  		l0,0.65l0.25,0.44l0.31,0.21l-0.22-1.01L2643.91,1254.63z M2638.06,1250.64l-0.24-0.47l-0.17,0.25l-0.33,1.77l0.29,1.26l0.16-0.05
  		l0.35-0.4l0.35,0.01l0.09-0.62l-0.09-0.53l0.3-1.02L2638.06,1250.64z M2639.99,1252.27l0.1-0.14l0.1-1.04l-0.63-0.43
  		L2639.99,1252.27z M2672.23,1217.55l-0.25-0.41l-0.5-0.58l-0.35-0.09l-0.03,0.28l0.24,1.18l0.49,0.05l0.15-0.07l0.44,0.15
  		l-0.03-0.2L2672.23,1217.55z M2652.49,1237.41l0.08-0.14l-0.01-0.44l-0.25-0.36l-0.51-0.02l0.12,0.39l0.23,0.3L2652.49,1237.41z
  		 M2673.4,1220.58l-0.11,0.45l0.48,0.36l0.14-0.89l-0.32-0.13L2673.4,1220.58z M2637.64,1241.97l-0.23,0.08l-0.13,0.29l-0.06,0.46
  		l0.48-0.09l0.04-0.24L2637.64,1241.97z M2651.44,1238.42l-0.07,0.71l0.26,0.46l0.77-0.34l0.18-0.57l-0.04-0.48l-0.23,0.08
  		l-0.75-0.04L2651.44,1238.42z M2699.3,1186.2l-0.5-0.35l-0.85-0.34l-0.53-0.42l-0.22-0.51l0.02-0.45l0.26-0.39l-0.64-0.43
  		l-1.53-0.48l-0.86-0.57l-0.18-0.67l-0.64-0.88l-1.1-1.08l-0.58-1.56l-0.06-2.04l0.13-1.66l0.48-1.92l-0.64-1.39l-0.71-0.73
  		l-0.98-0.54l-0.94-0.77l-0.89-1.01l-1.06-1.49l-1.24-1.97l-0.83-0.89l-0.43,0.2l-0.9-0.18l-1.37-0.57l-1.19-0.31l-1.02-0.04
  		l-0.66,0.13l-0.31,0.3l-0.02,0.3l0.26,0.29l-0.14,0.23l-0.53,0.16l-0.43,0.33l-0.48,0.72l-0.33,0.95l-0.51,0.37l-0.78,0.08
  		l-0.77,0.27l-0.76,0.46l-0.36,0.35l0.04,0.24l-0.16,0.05l-0.37-0.13l-0.17-0.31l0.02-0.49l-0.38-0.33l-0.79-0.17l-0.9-0.53
  		l-1.01-0.89l-0.7-0.47l-0.39-0.06l-0.56,0.35l-0.73,0.76l-0.61,0.3l-0.48-0.16l-0.37,0.27l-0.26,0.7l-0.48,0.55l-1.04,0.59
  		l-0.07,0.07l-1.19,0.81l-0.96,0.81l-1.11,1.09l-0.53,0.17l-0.5-0.26l-0.76-0.27l-0.4-0.37l0.76-1.84l0.96-2.04l0.26-0.94l0.04-0.69
  		l-0.09-0.56l-0.38-0.59l-0.36-0.45l-0.03-0.3l0.11-0.32l0.39-0.47l0.5-0.73l0.46-1.51l0.55-1.6l-0.05-0.97l-0.45-1.06l-0.24-1.01
  		l0.2-1.38l-0.07-0.54l-0.48-0.27l-1.59-0.27l-0.51,0.05l-0.4,0.18l-0.43,0.38l-0.53,0.24l-1,0.13l-0.94-0.47l-0.79-0.79l-0.19-0.97
  		l0.58-1.16l0.41-0.94l0.24-0.8l-0.03-0.38l-0.17-0.4l-0.24-0.05l-0.51-0.49l-0.49-0.87l-0.46-0.4l-0.44,0.07l-0.4,0.33l-0.37,0.58
  		l-0.29,0.24l-0.21-0.11l0.06-0.51l-1-0.55l-0.42-0.31l-0.54-0.08l-0.55,0.35l-0.44,0.38l-0.45,0.14l-0.69,0.04l-0.9-0.19
  		l-0.61-0.18l-0.09,0.1l0.33,0.8l0.12,0.64l-0.13,0.33l-0.29,0.28l-0.44,0.22l-0.52,0.13l-0.46-0.01l-0.73-0.3l-0.56-0.01
  		l-0.48,0.15l-0.29,0.22l-0.16,0.28l-0.28,1.57l-0.25,0.49l-0.35,0.28l-0.34,0.03l-0.47-0.08l-0.5,0.06l-2.25,0.61l-0.33,0.06
  		l-0.71,0.05l-0.65,0.02l-0.46-0.03l-1.08-0.62l-0.52-0.07l-0.29,0.18l-0.2,0.83l-0.63,0.53l-0.84,1.16l-0.2,1.69l0.09,1.18
  		l-0.76,1.16l-0.07,0.56l0.4,2.7l-0.07,0.28l-0.15,0.14l-0.47,0.09l-0.79,0.28l-0.52,0.27l-0.25,0.03l-0.74-0.2l-0.19,0l0.06,0.21
  		l0.59,1.17l0.61,0.55l0.64-0.06l0.28,0.11l-0.08,0.28l0.24,0.73l0.57,1.18l0.17,0.88l-0.35,0.88l0.07,0.31l0.19,0.18l0.54,0.82
  		l1.15,1.28l1.13,1.38l0.94,1.15l0.69,0.8l1.54,1.48l0.27,0.69l0.06,1.4l0.38,0.81l0.35,0.58l0.24,0.48l0.56,1.59l0.24,0.21
  		l1.23-0.96l0.29,0.2l0.16,0.36l0,0.46l-0.19,0.52l-0.4,0.48l-1.9,1.12l-0.16,0.96l-0.13,1.46l-0.03,1.79l0.09,1.34l-0.07,0.31
  		l-0.17,0.15l-0.72-0.06l-1.03,0.68l-0.36,0.06l-0.45,0.26l-0.35,0.32l-0.11,0.49l0.2,1.47l0.37,1.38l0.73,1.01l0.57,0.8l0.8,1.11
  		l0.63,0.97l1.27,1.06l1.79,1.59l0.68,0.88l0.6,1.06l0.43,0.9l0.16,1.2l0.13,0.67l0.04,2.25l-0.33,0.51l-0.25,0.59l0.14,0.62
  		l0.42,0.59v0.68l0.39,1.21l0.65,0.74l0.63,0.5l0.28,0.39l-0.09,0.63l0.19,0.67l0.14,0.73l0.26,1.01l0.51,0.85l0.77,2.62l-0.02,0.27
  		l-0.33,0.53l-0.49,0.48l-0.31,0.15l-0.3,0.49l-0.71,1.4l-1.42,2.41l-1.39,1.57l-1.17,1.11l-0.84,0.68l-0.25,0.4v0.32l0.15,0.56
  		l-0.06,1.07l-0.18,0.68l-0.24,0.72l-0.13,0.64l-1.18,2.98l-0.58,2.33l-0.42,0.58l-0.6,1.71l-0.38,2.71l-0.71,1.7l-0.12,1.89
  		l0.09,1.02l0.57,1.66l0.47,0.33l0.51,0.07l0.45-0.58l0.22-0.6l0.67-0.22l0.48,0.33l0.57,0.41l0.72,1.66l0.71,0.3l0.85,0.51
  		l0.65,0.63l-0.07,1.03l0.29,0.4h0.89l0.68,0.83l0.31,0.49l0.49,1.59l0.64,0.32l0.79,0.04l0.57-0.22l-0.37,1.15l0.42,0.53l0.55,0.04
  		l0.44,0.38l-0.2,1.93l1.45,1.07l2.11,2.59l0.16-0.39l0.2-1.29l0.13-0.25l0.34-0.12l0.38,0.03l0.71,1.12l1.84,0.69l0.55,0.16
  		l0.73-0.27l0.33,0.17l0.33,0.28l0.19,0.79l0.48,0.72l0.97-0.1l0.34,0.1l0.2,0.02l0.19,0.64l0.09,1.11l-0.09,0.65l-0.7,0.93
  		l-0.09,0.63l0.37,0.39l0.48,0.42l0.27,0.32l0.28-0.05l0.36-0.22l0.33-0.55l0.23-0.47l1.24-0.52l1.28-0.47l0.17,0.05l0.21,0.21
  		l0.41,0.68l0.24,0.14l0.35,0.07l0.6-0.07l0.7-0.39l0.37-0.72l0.16-0.57l1-0.99l0.11-0.74l0.28-0.48l-2.54-1.95l-1.56-2.35
  		l-0.98-0.94l-0.82-0.29l-0.83-0.07l-1.24,0.28l-1.15,0.12l-1.9-1.12l-0.77-0.73l-0.97-0.79l-0.81,0.12l-0.56-0.22l-0.44-0.78
  		l-1.4-1.85l-0.37-0.83l-0.02-1.08l0.83-0.39l0.51,0.5l0.06,0.6l-0.37,0.5l0.03,0.28l0.5-0.01l0.31,0.1l0.26,0.65l0.24,1.54
  		l0.89,0.45l-0.35-0.93l-0.42-0.88l-1.47-6.96l-0.43-1.32l-0.55-0.71l-0.04,0.29l-0.04,0.26l-0.21,0.13l-0.61-0.7l-0.56-0.66
  		l-0.24-0.69l-0.47-3.72l-0.23-0.69l-0.35-0.79l-0.94-0.22l-2.78,0.85l-0.49-0.1l-0.69-0.34l0.09-0.74l0.2-0.52l-0.82-1.79
  		l-0.26-0.9l0.07-1.69l0.21-2.04l-0.04-0.76l-0.21-0.46l0.61-0.58l0.4-1.53l1.7-2.71l0.23-1.79l0.4-0.97l0.56-2.1l0.83-1.69
  		l0.62-0.74l0.32-1.59l0.78-0.94l0.5-1.05l0.14-1.56l-0.35-2.85l0.15-0.69l0.91-2.33l-0.33-1.07l-0.51-0.61l0.23-0.93l0.88-0.74
  		l0.95-0.38l2.53-0.26l0.56-0.46l0.45,0.4l2.11,0.5l0.47,0.26l-0.13,0.63l-0.17,0.46l0.17,0.99l-0.36,1.3l-0.06,1.84l-0.28,0.88
  		l0.29,0.52l0.47,0.28l1.15-0.45l2.99,0.46l2.35-0.6l0.94,0.42l0.45,0.4l0.47,0.25l0.76,0.27l0.84,0.76l0.8,0.95l0.16-0.25
  		l0.09-0.28l0.71,1.2l0.77,0.62l0.89,0.59l0.29-0.41l0.16-0.38l0.51,0.46l0.91,1.16l0.24,1.05l0.78,0.98l0.24,0.58l0.18-0.02
  		l-0.13-0.22L2675,1217l-0.26-1.41l0.26-1.09l0.15-0.36l-0.44-0.57l-0.62-0.65l-1.1-0.85l-0.08-1.35l-0.24-1.59l-0.33-0.54
  		l-0.5-0.98l-0.27-0.81l-0.09-2.14l0.14-0.17l0.78-0.06l1-0.15l0.16-0.35l-0.18-0.29l0.64-0.49l0.91-1.07l0.71-1.11l0.51-0.7
  		l0.3-0.7l1.03-0.99l1.42-0.68l0.96-0.16l1-0.23l0.96-0.33l0.46-0.03l1.19,0.4l0.64,0.1l0.68-0.01l0.7,0.04l0.61-0.04l1.46-0.28
  		l1.55,0.22l1.38-0.17l1.71-0.32l0.84,0.2l0.77,0.32l0.11,0.65l0.18,0.3l0.25,0.23l0.34,0l0.44-0.46l0.36-0.47l0.12-0.09l0.51-0.18
  		l0.83-0.42l0.68-0.47l0.45-0.5l0.18-0.52l0.02-0.6l0.19-1.56l0.2-0.76l-0.11-0.93l-0.36-0.74v-1.1l0.13-0.54l0.06-0.35l0.51-0.45
  		l0.35-0.64l0.2-0.84l0.02-0.61L2699.3,1186.2z"/>
    </>,
    TGO: <>
        <polygon className="st1" points="1824.68,1265.91 1824.38,1264.81 1823.51,1263.5 1823.16,1263.26 1822.99,1262.61 1823.2,1262.19
  		1823.1,1261.9 1823.03,1261.02 1822.6,1260.05 1823.39,1260.01 1823.39,1256.88 1823.38,1253.88 1823.38,1251.32 1823.38,1249.29
  		1823.23,1246.86 1823.21,1245.08 1823.18,1242.72 1822.89,1241.99 1821.7,1240.74 1821.37,1240.09 1821.32,1239.24
  		1821.05,1238.36 1821.03,1236.81 1821.01,1235.02 1820.9,1234.73 1819.61,1233.87 1817.77,1232.66 1816.37,1231.73
  		1816.27,1231.66 1816.13,1231.43 1816.33,1228.7 1816.62,1228.34 1817.07,1227.21 1817.29,1226.3 1815.12,1226.39 1814.33,1226.62
  		1813.85,1226.63 1813.83,1226.43 1813.78,1226.31 1811.04,1225.65 1809.12,1225.26 1809.66,1225.77 1809.78,1226.07
  		1809.58,1227.16 1809.19,1227.93 1808.94,1228.65 1808.97,1229.01 1809.22,1229.37 1810.03,1229.94 1810.45,1230.3
  		1810.95,1230.86 1811.52,1231.4 1812.5,1232.11 1812.91,1232.24 1812.89,1232.43 1812.76,1232.7 1812.66,1235.34 1812.59,1236.02
  		1812.52,1236.36 1812.43,1237.34 1812.33,1237.48 1812.14,1237.47 1811.97,1237.51 1811.93,1237.7 1812,1237.91 1812.59,1238.05
  		1812.46,1238.2 1812.02,1238.33 1811.82,1238.63 1811.91,1238.96 1811.67,1239.23 1811.74,1239.42 1811.89,1239.55 1812.14,1239.5
  		1812.83,1239.05 1813.11,1239 1813.47,1239.09 1814.13,1239.78 1814.16,1240.12 1813.89,1241.28 1813.63,1242.17 1813.58,1243.36
  		1813.86,1244.03 1813.82,1244.4 1813.52,1244.72 1812.84,1245.18 1812.89,1245.49 1813.2,1246.07 1813.77,1246.73 1814.89,1247.53
  		1815.48,1248.58 1815.5,1249.01 1815.15,1249.44 1814.75,1249.81 1814.62,1250.35 1814.8,1253.86 1813.91,1255.39 1813.9,1255.82
  		1813.99,1256.33 1814.23,1256.63 1814.68,1256.72 1815.05,1257.01 1814.92,1258.08 1814.72,1259.17 1814.69,1259.7
  		1814.58,1259.95 1814.23,1260.16 1814.11,1260.5 1814.19,1260.93 1814.13,1261.24 1814.32,1261.65 1814.72,1262.15
  		1815.37,1263.41 1815.62,1263.51 1815.73,1263.77 1815.66,1264.03 1815.91,1264.59 1816.63,1265.14 1817.38,1265.63 1818,1265.7
  		1818.14,1266.13 1818.54,1266.69 1818.84,1266.93 1819.3,1267.09 1819.68,1267.17 1819.7,1267.64 1820.74,1267.16 1823.37,1266.57
  		1823.27,1266.28 	"/>
    </>,
    ATF: <>
        <path className="st0" d="M2392.57,1765.75l-0.02,0.47l0.16,0.35l0.52-0.09l0.33-0.27l0.62-0.9l-0.22-0.66l-0.86-0.13l-0.84,0.04
  		l-0.14,0.39l0.16,0.41L2392.57,1765.75z M2245.85,1737.6l-0.36-0.34l-0.7,0.49l0.31,0.57l0.55,0.22l0.61-0.09l-0.16-0.48
  		L2245.85,1737.6z M2403.75,1767.33l-0.39-0.58l-0.66-0.25l-0.72-0.03l-0.3,0.19l-0.65,0.64l-1.24,0.01l-0.65,0.26l-1.09,0.67
  		l-0.71,0.29l-0.88,0.18l-0.47,0.01l-0.58-0.11l-1.16-0.8l0.26-0.63l1.15,0.05l0.6-0.13l-0.48-0.49l0.05-1.1l-0.49,0.04l-1.86,1.43
  		l-0.41,0.1l-0.68-0.06l-1.12-0.3l-0.16-0.7l0.56-0.87l0.04-0.41l0.27-0.42l-0.11-1.03l-0.43-0.14l0.18-0.31l-0.1-0.48l-0.2-0.25
  		l-0.46,0.05l-0.37,0.35l-0.49,0.88l-0.57,0.79l0.04,0.84l-0.34,0.74l-0.23,0.77l0.17,0.41l0.22,0.34l0.31,0.07l0.25,0.26l-0.59,0.3
  		l-0.12,0.43l0.36,0.58l-0.19,0.75l0.37,0.42l0.09,0.57l-0.2,0.6l-0.32,0.55l-0.16,0.54l-0.07,0.57l0.27,0.53l0.49,0.11l1.01-0.05
  		l0.78-0.57l0.57-1.35l1.02,0.14l0.66,0.22l1.05,0.59l1.14,0.37l0.58-0.1l0.54-0.44l0.48,0.13l0.19,0.34l0.78,0.49l1.32,0.21
  		l0.41-0.05l0.7-0.43l0.07-0.4l0.36-0.31l0.41-0.19l-0.5-0.58l-0.69-0.23l-0.78,0.09l-0.09,0.78l-0.64-0.08l-1.1-0.41l-0.64-0.59
  		l-0.26-0.46l0.09-0.19l0.49,0.08l0.36-0.19l0.35-0.34l0.11-0.44l0.65-0.04l1.45-0.02l0.61,0.31l0.5,0.58l0.34,0.11l0.4-0.01
  		l0.21-0.25l-0.18-0.49l0.8-0.41l0.44-0.68l0.16-0.7L2403.75,1767.33z"/>
    </>,
    TCD: <>
        <polygon className="st1" points="2011.7,1184.25 2011.7,1180.41 2011.69,1176.58 2011.69,1172.73 2011.69,1168.88 2011.68,1165.03
  		2011.68,1161.17 2011.68,1157.3 2011.67,1153.42 2007.64,1151.37 2003.61,1149.31 1999.57,1147.24 1995.54,1145.18 1991.5,1143.11
  		1987.46,1141.05 1983.43,1138.98 1979.39,1136.9 1975.36,1134.83 1971.32,1132.75 1967.29,1130.67 1963.25,1128.59
  		1959.22,1126.51 1955.18,1124.42 1951.15,1122.34 1947.11,1120.25 1944.33,1118.8 1941.32,1120.21 1938.96,1121.32
  		1935.86,1122.77 1936.79,1127.86 1937.49,1132.23 1937.54,1135.01 1937.57,1135.73 1937.86,1136.22 1938.51,1136.71
  		1941.15,1140.71 1940.59,1141.41 1940.98,1142.64 1941.67,1143.18 1943.87,1145.56 1944.15,1146.02 1944.03,1146.4 1942.49,1149.2
  		1942.23,1149.88 1941.92,1153.44 1941.71,1155.94 1941.41,1159.38 1941.05,1163.48 1940.77,1166.94 1940.39,1171.51
  		1940.03,1175.82 1937.82,1178.18 1933.9,1182.37 1930.72,1185.77 1929.12,1188.05 1925.99,1192.49 1924.6,1195.36 1923.52,1196.86
  		1922.97,1197.5 1923.45,1199.6 1924.3,1203.27 1925.62,1205.1 1927.05,1207.07 1928.15,1208.6 1929.68,1208.61 1931.5,1209.08
  		1931.96,1209.44 1932.2,1210.8 1932.87,1211.56 1934.03,1212.2 1934.75,1213.5 1935.03,1215.48 1935.67,1216.66 1935.82,1216.85
  		1936.54,1218.55 1936.72,1219.08 1936.78,1220.1 1936.7,1220.8 1937.07,1221.66 1936.51,1223.12 1936.34,1224.02 1936.29,1225.28
  		1936.62,1227.51 1937.15,1229.22 1937.73,1230.61 1938.36,1231.68 1939.41,1232.87 1940.52,1233.96 1941.55,1234.64
  		1940.59,1235.04 1938.73,1235.09 1937.67,1234.86 1937.16,1234.85 1936.64,1234.99 1934.66,1235.2 1932.65,1235.1 1930.79,1234.83
  		1929.66,1234.87 1928.79,1235.53 1928.09,1236.53 1927.42,1237.31 1927.66,1238.18 1928.16,1238.66 1929.11,1239.72
  		1929.98,1240.74 1930.41,1241.43 1932.13,1242.93 1933.79,1244.28 1934.11,1244.5 1934.58,1244.75 1934.87,1244.84
  		1935.77,1245.61 1937.02,1246.88 1938.16,1248.86 1938.98,1250.87 1939.77,1252.82 1940.12,1253.15 1940.67,1253.36
  		1940.74,1253.78 1940.69,1254.4 1940.52,1254.9 1940.08,1255.58 1941,1255.65 1941.94,1255.88 1943.16,1255.99 1944.1,1255.72
  		1944.72,1255.17 1946.07,1254.74 1947.65,1254.24 1947.87,1253.49 1948.33,1253.1 1948.87,1252.75 1949.05,1252.71 1949.1,1252.95
  		1949.42,1253.73 1950.09,1254.5 1951.07,1255.35 1951.35,1255.3 1951.96,1254.66 1953.49,1254.26 1953.88,1254.08 1954.96,1253.15
  		1956.27,1252.54 1956.56,1252.49 1957.03,1252.33 1958.35,1251.71 1959.29,1251.79 1960.8,1251.7 1963.32,1251.4 1965.14,1251.3
  		1966.06,1251.19 1966.29,1251.06 1966.64,1250.16 1966.92,1249.91 1967.6,1249.52 1968.94,1248.16 1969.82,1247.01
  		1970.06,1246.63 1970.09,1246.6 1970.27,1246.53 1970.64,1246.05 1970.27,1245.55 1968.77,1244.53 1968.79,1244.39 1968.7,1244.22
  		1968.79,1244.08 1969.36,1243.66 1970.13,1243.19 1970.95,1243.01 1973.1,1243.05 1974.93,1242.95 1975.36,1242.97
  		1976.78,1242.73 1977.76,1242.51 1978.76,1242.02 1981.03,1242.07 1982.93,1240.83 1983.47,1240.6 1983.71,1240.41
  		1983.78,1240.22 1984.66,1239.72 1985.66,1238.7 1986.44,1237.78 1986.65,1237.13 1988.8,1234.92 1989.54,1234.96 1989.91,1234.69
  		1990.76,1233.22 1991.02,1232.95 1991.42,1232.86 1991.9,1232.69 1992.32,1232.25 1992.69,1231.6 1992.69,1230.8 1992.52,1230.16
  		1992.52,1229.84 1992.73,1229.56 1993.07,1229.27 1994.7,1228.47 1995.11,1228.09 1995.36,1227.75 1995.81,1227.68
  		1996.31,1227.72 1996.63,1227.5 1996.98,1227.14 1998.11,1226.65 1999.16,1226.28 2000.25,1226.44 2001.15,1226.63
  		2001.88,1226.86 2002.24,1226.92 2002.53,1226 2002.89,1224.62 2002.94,1223.98 2002.77,1223.32 2002.15,1222.83 2001.59,1222.77
  		2001.35,1222.52 2000.87,1222.15 2000.39,1221.87 1999.97,1221.33 1999.68,1220.57 1999.89,1217.85 1999.75,1217.49
  		1999.12,1217.39 1998.98,1217.19 1999,1216.67 1998.66,1215.12 1998.28,1213.83 1998.49,1213.12 1997.96,1212.16 1996.96,1211.74
  		1996.02,1211.86 1995,1212.06 1994.39,1212.01 1993.97,1211.82 1993.68,1211.47 1993.52,1211.05 1993.66,1210.42 1994.22,1209.26
  		1994.91,1208.3 1996.33,1207.44 1996.7,1206.97 1996.92,1206.46 1996.96,1205.87 1996.86,1205.25 1996.7,1204.68 1996.28,1203.93
  		1995.9,1203.05 1995.89,1202.45 1996.08,1202.02 1996.45,1201.51 1997.2,1200.93 1997.38,1200.81 1997.85,1200.5 1998.27,1200.27
  		1999.29,1199.66 1999.53,1199.36 1999.44,1199.01 1999.19,1198.72 1998.78,1198.32 1998.7,1197.82 1998.58,1196.98
  		1998.36,1196.44 1998.21,1196.04 1998.5,1195.75 1998.94,1195.34 1999.48,1195.08 2000.32,1194.87 2000.65,1194.57
  		2000.74,1194.01 2000.72,1193.47 2001.02,1193.07 2001.42,1192.21 2001.75,1191.82 2002.3,1191.37 2002.85,1190.81
  		2003.09,1190.16 2003.16,1189.53 2002.86,1187.63 2003.5,1186.84 2004.3,1186.18 2005.47,1186.23 2007.28,1186.08 2008.51,1185.81
  		2009.38,1185.82 2011.39,1186.17 2011.55,1186.09 2011.6,1186.02 2011.7,1185.51 	"/>
    </>,
    TCA: <>
        <path className="st0" d="M1201.44,1133.33l-0.96-0.48l-0.08,0.49l0.06,0.33l0.29,0.02l0.93-0.13l0.08-0.09l0.31-0.11l-0.05-0.1
  		L1201.44,1133.33z M1206.07,1133.01l-1.16-0.16l-0.35,0.08l0.15,0.46l0.92,0l0.47,0.34l0.03-0.12l0.21-0.2L1206.07,1133.01z
  		 M1203.86,1131.97l-0.67,0.01l-0.07,0.28l0.29,0.22l0.17,0.01l0.07,0.24l0.49,0.3l0.15-0.09l-0.17-0.19L1203.86,1131.97z"/>
    </>,
    SWZ: <>
        <polygon className="st1" points="2080.11,1545.91 2079.88,1545.28 2079.63,1544.36 2079.57,1543.77 2079.73,1543.17 2079.8,1542.23
  		2079.73,1541.41 2078.96,1540.99 2078.79,1540.86 2078.56,1540.96 2078.14,1541.08 2076.19,1540.05 2074.29,1538.97
  		2074.02,1538.94 2073.62,1539.05 2072.54,1539.84 2071.54,1541.07 2071.08,1542.12 2070.34,1543.2 2069.14,1544.95
  		2069.02,1545.33 2069.01,1546.75 2069.07,1548.11 2069.17,1548.3 2069.82,1548.36 2070.28,1549.47 2071.33,1551.24
  		2073.11,1552.38 2074.75,1552.9 2077.05,1553.03 2078.87,1552.99 2078.77,1551.8 2078.94,1549.88 2079.18,1548.59 2079.43,1548.53
  		2079.91,1548.65 2080.17,1548.79 	"/>
    </>,
    SYR: <>
        <polygon className="st1" points="2166.47,992.89 2166.08,991.72 2165.71,991.26 2165.53,991.21 2165.15,991.06 2164.86,991.15
  		2163.95,991.95 2162.49,992.43 2161.29,992.72 2159.37,993.08 2157.88,993.25 2157.25,993.27 2155.88,993.11 2154.68,992.88
  		2153.47,992.89 2152.54,992.99 2150.39,993.85 2146.74,995.61 2143.96,996.46 2142.4,996.81 2141.18,997.01 2139.09,997.02
  		2137.39,996.88 2136.21,996.9 2135.56,996.68 2134.62,995.97 2133.49,995.27 2132.99,995.1 2132.33,994.97 2131.37,994.89
  		2128.97,995.92 2128.22,996.2 2127.4,996.41 2125.74,997.04 2125.01,997.38 2124.09,997.34 2122.91,997.25 2121.89,997.29
  		2121.21,996.81 2120.84,996.27 2119.45,995.94 2118.46,995.84 2118.2,996.08 2117.94,996.82 2117.52,998.69 2117.44,999.16
  		2118.31,1001.03 2118.27,1001.31 2117.65,1001.41 2116.93,1001.44 2116.46,1001.6 2116.07,1001.91 2115.84,1003.52
  		2115.01,1003.81 2114.61,1004.15 2114.2,1005.14 2113.98,1005.17 2112.64,1004.41 2112.01,1004.35 2111.56,1005 2110.93,1007.65
  		2112.09,1009.09 2112.2,1009.76 2112.22,1010.24 2112.43,1010.96 2111.98,1012.52 2111.97,1013.58 2112.06,1014.5 2112.71,1016.61
  		2114.18,1016.62 2115.13,1016.58 2115.41,1016.14 2116.14,1016.34 2116.56,1016.76 2116.18,1017.2 2115.66,1017.71
  		2115.69,1017.84 2116.08,1017.88 2116.75,1018.16 2117.16,1018.48 2117.84,1020.47 2117.42,1021.29 2116.76,1022.03
  		2116.47,1022.09 2115.9,1022.46 2115.42,1022.95 2115.25,1023.27 2115.29,1023.56 2115.97,1023.93 2115.99,1024.08
  		2115.85,1024.19 2115.3,1024.11 2114.59,1024.07 2114.17,1024.08 2113.69,1024.15 2113.07,1024.6 2112.79,1024.89 2112.65,1025.08
  		2112.43,1025.69 2112.67,1026.11 2113.14,1026.35 2113.2,1026.47 2113.1,1026.68 2112.64,1026.95 2112.29,1027.27 2112.19,1027.6
  		2111.81,1027.91 2111.66,1028.49 2111.54,1028.86 2111.54,1029.35 2111.72,1029.62 2111.97,1030.16 2112.12,1030.69
  		2111.8,1031.13 2111.83,1031.59 2111.92,1031.98 2112.18,1032.43 2111.71,1033.25 2111.24,1034 2111.12,1034.44 2112.02,1034.64
  		2112.54,1035.08 2113.41,1036.32 2114.76,1036.68 2115.3,1037.03 2116.04,1037.69 2116.95,1037.93 2119.8,1038.34 2122.08,1036.96
  		2124.01,1035.79 2126.2,1034.46 2127.68,1033.55 2130.23,1032.01 2131.9,1031.03 2134.1,1029.74 2136.27,1028.47 2138.66,1027.14
  		2140.44,1026.14 2142.93,1024.75 2145.34,1023.4 2147.63,1022.11 2150.15,1020.7 2152.41,1019.43 2154.48,1018.91 2154.91,1018.51
  		2155.86,1016.77 2156.66,1015.29 2156.7,1014.94 2156.7,1012.83 2156.85,1010.35 2157.11,1009.02 2157.58,1007.85 2158.01,1006.99
  		2158.05,1006.19 2157.99,1005.37 2157.55,1004.14 2157.09,1002.85 2157.14,1001.61 2157.23,1000.94 2157.52,999.88 2158.01,999.1
  		2158.53,998.61 2160.5,998.12 2161.67,997.82 2163.23,996.44 2164.15,995.62 2165.44,994.31 2166.39,993.35 2166.47,993.02 	"/>
    </>,
    SX: <>
        <polygon className="st1"
                 points="1278.05,1165.8 1278.03,1165.84 1278.32,1166.04 1278.89,1166.23 1278.98,1166.01 1278.99,1165.8 	"/>
    </>,
    SLV: <>
        <polygon className="st1" points="1070.36,1204.83 1070.42,1204.32 1070.56,1203.73 1070.92,1202.34 1070.78,1202.1 1070.19,1201.68
  		1069.43,1201.64 1068.6,1201.77 1068.2,1201.56 1067.84,1201.08 1067.25,1200.85 1066.2,1201.23 1065.08,1201.81 1064.74,1202.02
  		1064.46,1201.99 1064.33,1201.56 1064.27,1201.05 1064.21,1200.93 1063.61,1200.74 1062.92,1200.61 1062.56,1200.47
  		1062.23,1200.13 1061.4,1199.68 1061.21,1199.35 1060.1,1198.59 1059.87,1198.21 1059.62,1197.94 1059.08,1197.59 1058.67,1197.67
  		1057.26,1197.23 1057.05,1197.19 1056.87,1197.09 1056.57,1197.06 1055.88,1197.21 1055.55,1197.25 1055.27,1197.42
  		1055.24,1197.78 1055.42,1198.38 1055.49,1198.69 1055.3,1198.83 1054.45,1199.19 1054.11,1199.54 1053.79,1200.09
  		1053.41,1200.32 1053.03,1200.28 1052.75,1200.36 1052.16,1200.77 1051.27,1201.56 1050.79,1202.16 1050.79,1202.6
  		1050.88,1202.99 1051.93,1203.45 1053.33,1204.5 1055.69,1204.93 1057.76,1205.2 1061.22,1206.86 1064.21,1207.7 1064.45,1207.59
  		1063.62,1207.18 1063,1207.06 1062.75,1206.87 1063.54,1206.87 1065.01,1207.45 1067,1207.87 1068.32,1207.83 1069.1,1207.73
  		1069.55,1207.36 1070.03,1206.84 1069.88,1205.98 1070.09,1205.87 1070.74,1205.42 1070.78,1205.15 1070.57,1204.96 	"/>
    </>,
    ST: <>
        <path className="st0" d="M1866.02,1315.26l-0.51,0.04l-0.86,0.5l-0.39,0.5l-0.08,0.44l0.24,0.92l0.19,0.43l0.31,0.16l0.87-0.61
  		l0.76-1.03l0-0.69L1866.02,1315.26z M1872.45,1304.74l-0.11-0.18l-0.19-0.13l-0.23,0.16l-0.48,0.64l0.1,0.33l0.37,0.18l0.31-0.22
  		l0.24-0.53L1872.45,1304.74z"/>
    </>,
    SS: <>
        <polygon className="st1" points="2106.61,1272.49 2105.87,1271.9 2105.18,1271.13 2104.76,1270.28 2104.35,1269.58 2104.14,1268.01
  		2103.63,1267.05 2103.12,1265.87 2102.38,1263.62 2102.05,1262.84 2101.45,1262.32 2100.81,1261.84 2100.15,1260.84
  		2098.42,1259.96 2097.75,1259.28 2096.61,1258.09 2096.32,1257.49 2096.24,1256.89 2095.88,1256.33 2095.24,1255.7
  		2093.25,1254.34 2092.71,1254.18 2091.99,1254.03 2090.95,1253.9 2089.55,1253.59 2088.32,1253.06 2087.77,1252.68
  		2087.63,1252.42 2087.75,1251.98 2088.19,1251.23 2089.03,1249.46 2089.62,1248.23 2090.01,1247.89 2091.09,1247.8
  		2092.24,1247.84 2093.07,1247.93 2094.26,1247.94 2095.67,1247.84 2096.23,1247.43 2096.68,1246.98 2096.86,1246.67
  		2096.92,1245.88 2096.92,1245.24 2096.83,1242.8 2096.78,1241.3 2096.72,1239.59 2096.72,1239.25 2096.71,1239.25 2095.15,1239.25
  		2095.12,1239.23 2095.1,1239.21 2095.07,1239.17 2095.05,1239.11 2094.98,1238.87 2094.95,1238.5 2095,1237.86 2095.18,1237.09
  		2095.72,1236.01 2095.75,1235.92 2095.76,1235.87 2095.76,1235.81 2095.71,1235.62 2095.64,1235.45 2095.61,1235.2
  		2095.71,1234.64 2095.72,1234.47 2095.7,1234.25 2095.66,1234.1 2095.28,1233.17 2095.16,1233.02 2091.51,1230.05 2090.84,1229.24
  		2090.77,1229.18 2090.68,1229.14 2088.83,1228.46 2088.74,1228.4 2088.75,1228.3 2088.81,1228.17 2089.03,1227.78 2089.06,1227.67
  		2089.09,1227.51 2088.26,1221.23 2088.26,1221.11 2088.3,1221.03 2088.33,1220.98 2088.44,1220.84 2088.54,1220.7 2088.65,1220.46
  		2088.67,1220.37 2088.79,1219.25 2088.78,1218.26 2089.27,1216.62 2089.32,1215.92 2085.3,1215.87 2085.28,1215.91
  		2085.27,1215.95 2085.28,1216 2085.29,1216.05 2085.29,1216.16 2085.25,1216.37 2085.25,1216.47 2085.25,1216.58 2085.31,1216.98
  		2085.42,1217.27 2085.43,1217.37 2085.44,1217.48 2085.43,1217.68 2079.83,1217.71 2082.05,1220.18 2082.07,1220.23
  		2082.11,1220.36 2082.13,1220.46 2082.17,1221.33 2082.05,1222.7 2082.07,1223.58 2082.2,1224.15 2082.8,1225.28 2082.76,1225.49
  		2082.62,1225.75 2078.66,1229.1 2078.55,1229.26 2077.99,1230.65 2077.47,1231.46 2077.24,1231.7 2076.31,1232.83 2072.69,1236.4
  		2072.1,1236.64 2070.3,1236.74 2069.34,1236.76 2069.23,1236.79 2069.07,1236.85 2068.97,1236.94 2068.85,1237.01 2068.74,1236.97
  		2068.6,1236.88 2066.37,1234.88 2062.4,1232.36 2062.02,1232.59 2059.77,1233.67 2059.31,1233.95 2059.05,1234.15 2059.04,1235.37
  		2058.65,1235.99 2057.94,1236.66 2055.99,1237.09 2054.98,1237.45 2053.95,1238 2053.78,1238.13 2053.78,1238.13 2053.38,1238.51
  		2052.6,1239.27 2052.52,1239.87 2052.64,1240.4 2045.94,1240.37 2045.5,1239.95 2044.57,1238.09 2044.53,1238.07 2043.84,1238.18
  		2037.73,1237.97 2036.86,1238.16 2035.11,1238.93 2034.23,1239.06 2033.33,1238.71 2030.11,1234.99 2029.42,1234.55
  		2029.17,1234.31 2028.69,1233.66 2028,1233.27 2027.77,1232.99 2027.7,1232.59 2027.72,1231.78 2027.49,1231.27 2026.98,1231.15
  		2022.67,1232.01 2022.05,1231.91 2021.14,1232.07 2020.83,1232.22 2020.46,1232.71 2020.39,1233.22 2020.4,1233.73
  		2020.29,1234.24 2019.96,1234.8 2018.73,1236.06 2018.45,1236.61 2018.51,1238 2018.43,1238.7 2018.25,1239.02 2017.71,1239.55
  		2017.51,1239.86 2017.42,1240.29 2017.39,1241.21 2017.3,1241.64 2016.63,1242.71 2016.47,1243.09 2016.43,1243.86 2016.32,1244.1
  		2014.37,1244.71 2013.64,1245.1 2013.19,1245.71 2013.08,1245.97 2013.48,1246.07 2013.7,1246.45 2013.36,1247.69 2013.6,1248.46
  		2014.3,1249.12 2015,1249.4 2015.68,1249.56 2018.05,1249.96 2019.03,1250.41 2020.32,1251.87 2021.95,1253.19 2022.35,1253.89
  		2022.27,1254.53 2021.79,1255.3 2021.86,1255.62 2022.61,1256.39 2023.47,1257.18 2025.04,1258.06 2027.75,1259.44
  		2028.99,1260.36 2029.42,1261.06 2030.11,1261.82 2031.08,1262.52 2031.73,1263.05 2031.29,1264.56 2031.42,1265.06
  		2031.66,1265.49 2032.23,1266.09 2032.45,1266.85 2033.02,1267.81 2033.69,1268.24 2034.8,1268.4 2035.39,1268.85 2036.62,1269.61
  		2037.81,1270.26 2038.32,1270.71 2038.64,1271.12 2038.91,1271.59 2039.04,1272.06 2039.07,1273.08 2039.27,1274.35
  		2039.91,1275.22 2040.51,1275.86 2040.81,1276.45 2041.24,1277.05 2042.7,1278.07 2043.17,1278.64 2043.52,1279.27
  		2043.75,1279.76 2044.2,1280.15 2044.83,1280.4 2045.37,1280.7 2045.7,1281.15 2046.19,1281.6 2047.15,1282.23 2047.61,1282.24
  		2048.15,1282.33 2048.62,1282.49 2049.13,1282.45 2049.95,1282.04 2050.92,1281.35 2051.65,1280.93 2053.44,1281.08
  		2054.44,1281.43 2055.23,1281.91 2055.85,1281.88 2057.19,1280.99 2057.91,1280.04 2058.6,1279.83 2059.65,1280.24
  		2060.52,1281.13 2061.28,1282.42 2061.82,1282.91 2062.56,1283.67 2064.02,1285.31 2065.92,1286.14 2066.66,1286.54
  		2066.9,1286.95 2067.04,1287.48 2067.09,1288.07 2067.32,1288.31 2067.83,1288.23 2068.27,1288.15 2068.75,1288.31
  		2069.09,1288.74 2069.26,1289.07 2069.44,1289.43 2069.69,1288.98 2070.2,1288.23 2071.2,1287.47 2072.08,1286.96 2072.67,1286.96
  		2073.81,1287.36 2074.84,1287.84 2075.41,1287.86 2076.1,1287.66 2077.52,1286.82 2077.86,1287.09 2078.28,1287.6 2078.73,1288.45
  		2079.63,1288.84 2080.06,1289.11 2080.37,1289.19 2080.54,1289.12 2080.88,1288.45 2081.29,1288.08 2082.05,1287.62
  		2083.72,1287.26 2084.92,1287.15 2085.43,1287.07 2086.28,1286.85 2087.62,1286.17 2088.94,1287.05 2090.38,1287.22
  		2091.76,1287.22 2092.19,1286.95 2092.43,1286.74 2093.89,1285.29 2095.86,1283.32 2097.56,1281.65 2099.27,1279.96
  		2101.45,1277.82 2103.46,1275.86 2105.2,1274.16 2106.75,1272.65 	"/>
    </>,
    SUR: <>
        <polygon className="st1" points="1354.84,1288.34 1354.6,1288.27 1354.2,1287.63 1353.94,1287.1 1353.22,1286.55 1352.73,1285.99
  		1351.93,1284.71 1352.01,1283.99 1351.78,1283.73 1351.53,1283.47 1351.55,1283.14 1351.38,1282.33 1351.18,1281.58 1351.1,1281.1
  		1351.3,1280.28 1351.18,1279.36 1350.92,1278.88 1350.85,1278.15 1350.9,1277.5 1351.12,1277.13 1351.08,1276.67 1352.09,1275.21
  		1352.86,1274.36 1353.57,1273.77 1354.21,1272.57 1354.5,1271.67 1354.57,1270.73 1354.43,1270 1353.69,1269.59 1351.89,1269.15
  		1347.86,1268.49 1345.22,1268.44 1343.27,1268.79 1341,1268.51 1339.49,1268.71 1338.8,1269.29 1338.92,1270.11 1338.9,1270.91
  		1338.55,1270.11 1336.06,1269.35 1334.12,1268.91 1329.87,1268.45 1329.14,1268.9 1328.74,1269.82 1328.47,1270.59
  		1328.43,1271.38 1328.09,1272.34 1327.98,1272.18 1327.53,1272.71 1327.45,1273.05 1327.16,1273.64 1326.94,1273.97
  		1327.26,1274.71 1327.64,1274.74 1327.78,1274.84 1327.87,1274.98 1327.85,1275.14 1327.71,1275.28 1327.35,1275.46
  		1327.01,1275.89 1327.04,1276.36 1326.83,1276.61 1326.15,1276.74 1324.81,1276.74 1324.15,1276.77 1323.63,1276.85
  		1323.29,1277.16 1322.85,1277.38 1322.5,1277.43 1322.2,1277.78 1321.9,1278.29 1322,1278.63 1322.31,1279.09 1322.49,1279.56
  		1322.25,1280.33 1321.99,1280.92 1321.83,1281.37 1321.62,1282.23 1321.11,1283.18 1320.74,1283.72 1320.74,1284.31
  		1320.93,1285.15 1321.97,1286.36 1322.32,1286.95 1322.61,1287.88 1323.55,1288.61 1324.15,1289.21 1324.09,1289.99
  		1324.17,1290.24 1324.54,1290.43 1324.99,1290.58 1325.49,1290.57 1325.93,1290.51 1326.03,1290.4 1327.06,1290.38
  		1327.18,1290.58 1327.24,1291.71 1327.28,1292.16 1327.52,1292.35 1327.67,1292.63 1327.68,1292.88 1327.72,1293.51
  		1327.88,1293.85 1327.85,1294.52 1327.96,1294.77 1328.24,1294.94 1328.6,1295.42 1328.73,1295.48 1328.8,1295.65 1329.11,1296.34
  		1329.27,1296.54 1329.38,1296.57 1329.42,1296.81 1329.64,1297.46 1329.79,1297.62 1330.08,1298.09 1330.2,1298.6 1330.58,1299.18
  		1330.96,1299.59 1331.14,1300.01 1331.63,1300.95 1332.11,1301.61 1332.76,1301.78 1333.3,1301.87 1333.64,1302.12 1333.98,1302.4
  		1334.23,1302.48 1334.79,1302.55 1336.13,1302.87 1337.87,1303.23 1338.35,1303.11 1338.63,1302.85 1338.7,1302.11
  		1338.75,1301.58 1338.36,1301.11 1337.87,1300.59 1337.42,1299.93 1336.88,1299.74 1336.95,1299.4 1337.3,1299.05 1337.66,1298.86
  		1337.87,1298.62 1338.1,1297.75 1338.25,1297.59 1338.4,1297.55 1338.58,1297.59 1338.94,1297.81 1340.31,1298.51 1340.91,1298.41
  		1343.22,1298.22 1343.57,1297.82 1344.06,1297.73 1344.88,1297.33 1345.21,1297.3 1345.5,1297.4 1345.87,1297.32 1346.42,1296.95
  		1346.64,1296.91 1346.73,1297.32 1347.08,1297.75 1347.51,1298.14 1347.71,1298.23 1348.43,1298.1 1348.8,1298.21 1348.97,1298.58
  		1349.01,1298.9 1349.31,1299.17 1349.7,1299.18 1349.79,1299.1 1350.1,1299.04 1350.37,1299.04 1350.8,1298.43 1351.5,1298.05
  		1352.72,1295.94 1353.24,1295.06 1353.3,1294.59 1353.45,1293.59 1353.18,1292.38 1353.3,1292.04 1354.35,1290.58 1354.81,1289.78
  		1354.84,1289.1 1354.97,1288.6 	"/>
    </>,
    SOM: <>
        <polygon className="st1" points="2242.36,1230.69 2241.74,1230.49 2240.87,1230.01 2240.81,1230.22 2240.83,1230.65 2239.78,1231.03
  		2239.52,1230.94 2239.68,1230.71 2240.04,1230.57 2240.13,1230.17 2240.35,1229.66 2240.43,1229.15 2240.28,1225.59
  		2239.96,1223.4 2240.4,1221.96 2241.09,1220.67 2241.2,1219.93 2241.39,1219.2 2240.86,1219.11 2237.5,1217.9 2236.18,1218.24
  		2235.28,1219.27 2234.75,1220.08 2231.75,1221.76 2227.81,1222.42 2225.67,1223.34 2222.93,1223.95 2221.89,1224.05
  		2221.59,1224.09 2219.66,1223.51 2218.8,1223.53 2217.68,1223.79 2214.14,1225.06 2211.56,1225.3 2209.55,1224.76 2208.97,1224.77
  		2207.5,1225.4 2205.33,1226.88 2201.89,1228.39 2201.01,1228.49 2199.27,1228.1 2197.34,1227.99 2195.59,1227.63 2194.57,1227.9
  		2191.56,1229.21 2188.23,1231.01 2183.55,1231.07 2182.64,1230.71 2181.62,1230.05 2179.05,1228.07 2177.18,1225.94
  		2175.58,1223.31 2173.94,1222.01 2173.21,1223.15 2172.28,1224.6 2171.22,1226.25 2171.08,1226.58 2170.71,1227.06
  		2170.27,1227.55 2169.87,1228.05 2169,1229.45 2168.97,1229.63 2169.08,1229.9 2169.55,1230.55 2170.05,1231.58 2170.32,1232.53
  		2170.53,1232.99 2171.13,1233.51 2171.99,1234.6 2172.45,1235.33 2173.4,1235.72 2173.71,1236.65 2174.42,1238.01 2175.19,1239.09
  		2175.93,1239.94 2176.76,1240.27 2177.09,1240.3 2178.83,1241.87 2180.15,1243.07 2180.48,1243.26 2182.87,1244.05
  		2185.61,1244.95 2187.82,1245.67 2190.62,1246.6 2193.39,1247.5 2195.98,1248.37 2199.63,1249.59 2202.57,1250.58 2204.88,1251.35
  		2205.37,1251.6 2208.13,1251.6 2210.93,1251.6 2213.8,1251.6 2211.72,1253.6 2209.37,1255.86 2206.9,1258.24 2205.32,1259.77
  		2202.79,1262.2 2200.7,1264.21 2198.54,1266.42 2196.59,1268.41 2194.05,1271.17 2192.41,1272.96 2189.83,1275.76 2188.21,1277.52
  		2187.97,1277.62 2185.65,1277.49 2183.4,1277.36 2180.53,1277.19 2180.2,1277.19 2179.36,1277.36 2178.85,1277.52 2176.78,1278
  		2176.4,1278.12 2174.68,1278.87 2172.93,1279.76 2172,1280.44 2171.29,1281.43 2170.98,1282.14 2170.66,1282.45 2170.11,1282.72
  		2166.44,1283.38 2165.37,1283.47 2163.65,1284.01 2162.73,1284.9 2162.47,1285.35 2161.43,1286.83 2160.19,1288.59 2157.9,1291.85
  		2156.16,1293.56 2154.84,1294.86 2154.72,1295.09 2154.73,1296.53 2154.74,1300.06 2154.77,1307.11 2154.8,1314.17
  		2154.83,1321.22 2154.84,1324.74 2154.84,1325.93 2156,1327.41 2157.13,1328.86 2158.62,1330.77 2159.42,1331.8 2159.55,1332.14
  		2159.51,1332.83 2160.35,1331.85 2161.19,1330.61 2162.15,1328.71 2162.5,1328.27 2162.82,1327.48 2163.28,1326.79
  		2164.34,1325.81 2165.29,1324.82 2166.81,1322.91 2167.37,1322.46 2168.17,1321.34 2168.79,1320.74 2169.44,1320.12
  		2175.81,1313.44 2177.91,1311.47 2180.57,1309.39 2183.09,1307.01 2188.04,1303.5 2195.67,1299.32 2197.57,1297.93
  		2204.54,1291.15 2209.87,1285.43 2213.77,1281 2215.95,1277.18 2219.45,1272.63 2222.82,1266.93 2223.18,1264.96 2224.38,1261.86
  		2225.34,1260.07 2227.2,1257.49 2228.06,1256.04 2228.81,1254.44 2229.58,1251.89 2231.69,1249.89 2233.23,1247.28
  		2233.53,1246.35 2234.45,1244.45 2236.2,1242.22 2236.6,1241.11 2237.77,1239.53 2237.84,1237.15 2238.18,1235.34 2238.39,1232.56
  		2238.66,1231.87 2239.55,1231.45 2241.01,1231.06 2241.51,1231.3 2242.49,1231.44 2242.53,1231.13 	"/>
    </>,
    SEN: <>
        <polygon className="st1" points="1713.27,1213.25 1713.26,1213.03 1713.31,1212.43 1713.56,1211.18 1713.54,1210.7 1713.77,1209.76
  		1713.4,1209.34 1713.31,1209.03 1712.9,1208.53 1712.43,1207.82 1712.32,1207.25 1712.16,1206.8 1711.71,1206.12 1711.37,1206.01
  		1710.67,1205.91 1710.55,1206.14 1710.29,1206.48 1710.05,1206.58 1709.66,1206.16 1709.51,1205.81 1709.52,1205.48
  		1708.99,1204.92 1708.17,1203.88 1708.26,1203.02 1708.76,1202.55 1708.91,1202.2 1708.96,1201.81 1708.73,1201.34
  		1708.46,1200.96 1708.54,1200.13 1708.47,1198.98 1708.06,1198.4 1707.68,1197.99 1707.16,1197.53 1706.71,1196.83
  		1706.89,1195.87 1707.06,1195.21 1706.27,1193.83 1706.08,1193.77 1705.19,1193.15 1704.76,1192.42 1704.05,1191.87
  		1703.08,1191.5 1702.44,1191.08 1702.14,1190.6 1701.78,1190.29 1701.4,1190.12 1701.37,1189.95 1701.46,1189.72 1701.37,1189.29
  		1700.79,1188.32 1700.26,1187.88 1699.8,1187.95 1699.54,1187.83 1699.38,1187.62 1699.32,1187.3 1699.01,1187.03 1698.47,1186.92
  		1698.04,1186.2 1697.71,1184.88 1697.28,1183.86 1696.76,1183.12 1696.38,1182.85 1696.11,1182.8 1696.01,1182.68 1695.94,1182.47
  		1695.53,1182.39 1694.96,1182.61 1694.44,1182.54 1694.19,1182.18 1693.84,1182.15 1693.39,1182.44 1692.89,1182.36
  		1692.35,1181.89 1692.05,1181.42 1692,1180.96 1691.06,1180.03 1689.26,1178.64 1687.29,1177.99 1685.16,1178.08 1683.96,1178.01
  		1683.7,1177.79 1683.44,1177.81 1683.18,1178.06 1682.9,1178.12 1682.6,1177.98 1682.41,1178.09 1682.34,1178.44 1681.59,1178.62
  		1680.16,1178.63 1679.01,1178.85 1678.14,1179.28 1676.89,1179.46 1675.29,1179.4 1674.32,1179.24 1673.99,1178.99
  		1673.52,1178.93 1672.93,1179.06 1672.4,1179.75 1671.93,1180.99 1671.54,1181.7 1671.23,1181.87 1670.9,1182.79 1670.71,1184.34
  		1670.43,1185.02 1670.13,1185.91 1667.84,1189.68 1665.28,1192.87 1663.05,1193.98 1662.01,1194.3 1662.77,1195.18
  		1662.99,1194.57 1663.61,1194.52 1664.32,1194.76 1665.1,1195.27 1665.85,1196.62 1666.74,1197.3 1667.53,1198.97 1668.22,1199.95
  		1668.27,1200.71 1668.69,1200.7 1669.32,1200.44 1669.74,1200.4 1669.55,1200.68 1668.76,1201.08 1668.48,1201.56 1668.66,1202.11
  		1669.48,1202.7 1669.99,1203.39 1670.21,1204.27 1672.34,1204.18 1674.93,1204.22 1677.74,1204.26 1679.07,1204.27
  		1679.77,1203.07 1681.09,1202.54 1682.45,1202.35 1683.16,1202.4 1683.9,1202.58 1685.33,1203.57 1686.23,1203.79 1686.98,1204.02
  		1687.52,1204.5 1688.37,1204.98 1689.04,1205.11 1689.44,1205.03 1690.11,1204.85 1690.55,1204.7 1691.98,1204.64 1693.03,1205.19
  		1693.25,1205.79 1693.07,1206.41 1691.66,1206.74 1689.71,1207.26 1688.09,1206.98 1686.13,1206.27 1684.98,1205.77
  		1684.5,1205.56 1683.78,1205.24 1683.16,1204.9 1682.55,1204.67 1682.09,1204.53 1681.75,1204.71 1681.58,1205.14 1681.3,1205.61
  		1680.95,1205.9 1679.31,1206.06 1677.83,1206.24 1677.03,1206.39 1676.5,1206.5 1676.34,1207.94 1674.66,1207.92 1673.02,1207.9
  		1671.31,1207.93 1669.48,1207.95 1669.01,1208.25 1668.51,1208.72 1668.56,1209.44 1668.46,1210.26 1668.55,1211.8
  		1668.39,1212.07 1668.47,1212.43 1668.68,1212.79 1669.03,1212.64 1669.28,1212.48 1669.57,1211.95 1669.91,1211.69
  		1670.32,1212.13 1671.11,1212.46 1671.21,1212.59 1671.15,1212.83 1670.83,1212.82 1670.28,1212.63 1669.23,1213.01
  		1668.54,1213.3 1668.33,1213.75 1668.66,1214.37 1668.95,1214.75 1669.41,1214.67 1670.55,1214.81 1671.43,1214.64
  		1672.06,1214.37 1672.91,1214 1673.73,1213.88 1676.29,1214.05 1678.52,1213.6 1680.18,1212.76 1681.71,1211.99 1683.7,1212
  		1685.82,1212.01 1688.85,1212.02 1691.24,1212.03 1694.07,1212.04 1696.79,1212.14 1697.07,1212.21 1698.29,1212.33
  		1699.04,1212.33 1699.51,1212.38 1699.71,1212.54 1699.67,1212.83 1699.54,1213.21 1699.69,1213.6 1700.11,1213.71
  		1700.33,1213.59 1700.54,1213.4 1700.79,1213.24 1701.15,1213.35 1701.92,1213.93 1702.63,1214.09 1703.4,1214.4 1704.13,1214.57
  		1704.78,1214.55 1705.27,1214.88 1706.18,1214.98 1707.35,1214.57 1708.27,1214.39 1709.57,1214.34 1710.25,1214.48
  		1712.22,1214.15 1713.21,1214.22 1713.77,1214.33 1713.83,1213.7 	"/>
    </>,
    SMR: <>
        <polygon className="st1"
                 points="1915.01,924.36 1914.49,924.43 1914.11,924.92 1914.36,925.34 1914.86,925.26 1915.11,924.73 	"/>
    </>,
    SLE: <>
        <polygon className="st1" points="1948.72,897.97 1947.99,897.07 1947.67,896.74 1947.7,896.29 1947.55,896.04 1947.29,895.86
  		1947.14,895.22 1947.06,894.74 1946.85,894.43 1945.24,894.36 1944.77,894.56 1944.27,895.02 1944.3,896.03 1944.23,896.12
  		1944.1,896.33 1942.5,895.97 1942.44,895.98 1941.37,896.11 1940.63,896.57 1939.74,896.83 1937.87,896.69 1936.04,896.87
  		1935.61,897.01 1935.14,897.09 1934.7,897.35 1934.44,897.73 1933.99,898.21 1933.34,898.59 1932.64,898.88 1932.48,899.12
  		1932.25,899.26 1931.86,899.08 1931.54,899.09 1931.15,898.96 1929.87,898.83 1928.45,898.61 1927.78,898.39 1927.02,898.22
  		1926.2,898.09 1925.46,898.05 1925.09,897.99 1924.92,898.6 1924.56,898.75 1923.94,899.1 1923.22,899.58 1922.56,900.13
  		1922.38,900.72 1922.56,901.1 1922.74,901.24 1922.98,901.12 1923.34,901.19 1923.78,901.4 1924.52,901.61 1924.54,901.81
  		1924.39,902.07 1923.81,902.53 1923.29,903.05 1923.24,903.37 1923.3,903.6 1923.48,903.74 1924.25,903.68 1924.36,903.87
  		1923.99,905.21 1924.11,905.44 1924.78,905.65 1925.27,905.97 1926.19,906.82 1926.56,907.5 1926.31,907.73 1925.73,907.85
  		1925.26,907.79 1924.56,908.33 1924.06,908.53 1924.38,908.95 1926.59,909.45 1927.07,909.23 1927.36,908.89 1927.37,908.67
  		1927.56,908.61 1928.34,908.94 1928.97,908.86 1930,908.84 1930.73,908.9 1931.22,908.64 1931.87,907.71 1932.11,907.19
  		1932.41,907.06 1932.6,907.12 1932.74,907.55 1933.09,907.96 1933.79,908.62 1934.3,908.93 1934.75,909.05 1935.2,908.78
  		1935.66,908.71 1936.97,909.22 1938.08,909.32 1938.9,909.05 1938.79,908.68 1938.49,908.27 1938.43,907.87 1938.48,907.53
  		1939.04,907.18 1939.02,907.03 1938.34,906.43 1938.37,906.27 1939.86,905.59 1941.3,905.21 1941.53,904.91 1941.67,904.47
  		1941.73,903.64 1941.65,902.96 1941.07,902.32 1941.03,902 1941.17,901.66 1941.39,901.36 1941.97,901.23 1942.64,901.01
  		1943.18,900.76 1943.9,900.55 1944.47,900.26 1945.02,899.56 1945.36,899.45 1946.38,899.55 1946.59,899.37 1946.45,898.38
  		1946.64,898.12 1947,897.98 1947.17,897.84 1948.06,897.95 1948.81,898.21 	"/>
    </>,
    SVK: <>
        <polygon className="st1" points="1998.98,870.56 1996.7,869.78 1995.17,869.17 1995.01,868.77 1994.72,868.19 1994.07,867.71
  		1992.57,867.29 1991.96,866.96 1989.53,866.78 1988.47,866.77 1987.72,866.91 1987.24,866.9 1986.58,867.75 1986.13,868
  		1985.47,868.02 1984.88,867.87 1984.29,867.42 1983.34,867.19 1982.65,867.3 1982.15,867.2 1981.71,867.18 1981.56,867.27
  		1981.21,867.26 1980.7,867.47 1980.14,867.77 1979.53,868.01 1979.06,868.5 1978.64,869.48 1977.44,869.04 1977.05,869.23
  		1976.49,869.36 1976.1,869.23 1976.19,868.89 1976.37,868.51 1976.36,867.98 1976.25,867.4 1975.88,867.21 1975.32,867.14
  		1975.04,867.02 1975.01,866.83 1974.72,866.58 1974.23,865.95 1973.77,865.17 1973.45,864.94 1972.98,865.31 1972.28,865.73
  		1971.84,865.88 1970.99,867.09 1969.46,867.14 1969.37,866.57 1969.21,866.02 1968.32,865.89 1968.1,865.9 1967.62,866.07
  		1967,866.13 1966.33,866.1 1965.81,866.39 1965.32,866.86 1964.81,867.19 1964.53,867.49 1964.38,867.79 1962.66,868.65
  		1962.42,869.01 1962.23,869.49 1962.15,870.15 1962.03,870.74 1961.73,871.05 1960.81,871.32 1960.58,871.46 1960.4,871.76
  		1959.88,872.22 1959.27,872.66 1958.15,873.16 1956.95,873.31 1955.38,873.15 1954.47,872.95 1954.03,873.17 1953.42,873.82
  		1952.76,874.95 1952.49,875.79 1952.45,875.9 1952.41,876.31 1952.08,876.82 1951.73,877.49 1951.75,878.08 1952.65,880.11
  		1953.46,881.35 1953.61,881.82 1954.12,882.18 1954.35,882.11 1955.22,882.19 1955.42,882.31 1955.56,882.34 1956.93,883.45
  		1958.23,884.28 1959.3,884.7 1960.87,884.74 1962.53,884.78 1965.32,884.63 1967.41,884.52 1967.54,884.31 1967.86,883.82
  		1967.61,883.39 1967.62,882.89 1967.98,882.24 1969.01,881.69 1971.96,881.46 1973.66,881.05 1973.92,880.5 1974.48,879.95
  		1975,879.84 1975.7,880.09 1976.55,880.57 1977.3,880.83 1977.73,880.66 1979.23,879.85 1980.96,879.06 1982.15,876.91
  		1982.28,876.57 1983.57,876.32 1985.45,876.37 1986.41,876.65 1987.14,876.79 1988.23,876.74 1989.79,876.28 1990.38,876.29
  		1990.82,876.62 1991.32,876.9 1991.65,877.25 1991.9,877.74 1992.04,877.92 1992.26,878.17 1992.65,878.51 1993.03,878.6
  		1995.93,878.01 1996.11,877.88 1996.2,876.12 1997.48,874.85 1997.8,874.21 1998.28,872.82 1998.63,872.17 1999.07,871.63
  		1999.41,871.11 1999.53,870.66 	"/>
    </>,
    SI: <>
        <path className="st0" d="M1704.05,1254.88l-0.6-0.25l-2.01,0.13l-0.82,0.43l2.9,0.81l0.56,0.54l0.12-0.22l0.21-0.83l-0.1-0.4
  		L1704.05,1254.88z M1722.44,1247.4l-0.29,0.12l-0.86,1l-0.52,0.39l-0.39-0.03l-0.41-0.09l-0.29,0.07l-0.21-0.11l0.08-0.24
  		l0.21-0.31l0.41-1.09l1.06-1.1l0.02-0.23l-0.43-0.64l-0.45-0.88l0-0.94l-0.09-0.68l-0.93-0.19l-0.17-0.11l-0.02-0.23l0.24-0.61
  		l0.28-0.56l0.04-0.24l-0.07-0.21l-0.57-0.6l-0.89-1.11l-0.83-1.23l-0.71-1.05l-0.57-0.48l-0.55-0.69l-0.21-0.44l-0.57-0.16
  		l-1.67,0.01l-2.01,0.01l-1.69,0.01l-0.1,0.59l-1.85,0.4l-1.14-0.46l-1.27,0.27l-0.62,0.3l-0.19,0.63l-0.28,0.69l-0.27,0.29
  		l-0.12,0.31l-0.16,0.28l-0.25,0.33l-0.28,0.66l-0.6,0.93l-0.63,0.6l-1.08,0.33l-0.34,0.98l-0.25,0.37l-0.42,0.29l-0.44,0.19
  		l-0.41-0.11l-0.47-0.08l-0.49,0.18l0.18,0.52l0.99,0.76l0.79,0.14l-0.1,0.21l-1.14,0.11l-0.16,0.65l-0.02,0.59l0.39,1l0.51-0.1
  		l0.28-0.31l1.14,0.09l0.42-0.35l0.08,0.22l-0.16,0.41l-0.69,0.46l-0.76,0.86l-0.61-0.15l-0.38-0.35l-0.49-0.03l-0.1,0.49l0.6,0.79
  		l0.44,1.02l1.08,0.12l0.54,0.47l0.27,0.76l0.37,1.67l0.25,0.32l0.58,0.23l0.71,0.63l1.07,0.13l0.5-0.44l0.25,0l-0.25,0.74
  		l0.65,1.02l-0.4,0.86l-0.04,0.47l1.17,0.37l3.52,1.33l1.65,0.8l1.57,1.19l0.34,0.34l0.45-0.38l0.66-1.21l0.92-1.16l0.86-0.69
  		l0.68-0.71l0.72-0.54l1.03-0.63l1.57-1.67l0.37-0.19l0.25-1.15l0.39-1.47l0.46-0.46l1.07-0.27l0.25-0.26l0.38-1.04l0.24-1.21
  		l0.02-0.26L1722.44,1247.4z"/>
    </>,
    SHN: <>
        <path className="st0" d="M1688.75,1384.76l-0.2-0.03l-0.13,0.19l-0.14,0.32l0.05,0.2l0.08,0.07l0.29-0.01l0.4-0.15l0.12-0.17
  		l-0.22-0.19L1688.75,1384.76z M1762.01,1452.91l-0.38-0.06l-0.57,0.43l-0.06,0.41l0.76-0.05l0.27-0.23L1762.01,1452.91z"/>
    </>,
    SGP: <>
        <polygon className="st1" points="2685.31,1307 2684.87,1306.8 2684.11,1306.54 2683.16,1306.74 2682.69,1307.56 2684.12,1308.06
  		2685.39,1307.51 2685.61,1307.22 	"/>
    </>,
    SWE: <>
        <path className="st0" d="M1968.89,769.03l-0.5,0.19l-0.3,0.82l-0.7-0.37l-1.55,0.4l-1.11,0.9l-1.03,1.24l-0.66,0.54l-0.58,0.66
  		l-0.06,1.3l0.19,1.33l-0.39,0.81l0.5,0.73l0.38,0.95l0.64,0.6l-0.66,0.88l-0.51,1.08l0.86-0.13l0.77-0.56l0.4-1.32l0.76-0.91
  		l0.51-0.41l1.36-0.55l0.36-0.98l0.35-0.45l0.5-0.31l0.54-0.14l-0.99-1.03l0.19-2.71l0.54-0.29l0.56-0.14l0.41-0.86l0.7-0.29
  		l-1.01-0.78L1968.89,769.03z M1953.64,776.11l-0.54-0.15l-0.25,0.33l-0.3,0.82l-0.5,0.91l-0.31,1.01l-1.15,2.27l-0.82,0.3
  		l-1.84,3.69l-0.15,1.02l0.06,2.06l0.26,0.8l0.38,0.04l0.43-0.6l2.1-6.16l0.51-0.42l0.38-1.74l1.43-2.69l0.04-0.26l-0.07-0.62
  		l0.57-0.48L1953.64,776.11z M1965.4,754.39l-0.17-0.04l-0.57,0.23l-0.17,0.25l-0.23,0.58l0.19,0.04l0.37-0.12L1965.4,754.39z
  		 M2012.25,663.85l-0.46-0.98l-0.74-1.23l-1.31-0.6l-0.26-0.34l-0.17-0.52l-0.06-0.72l-0.17-1.07l0.07-0.88l0.16-0.53l0.57-0.35
  		l0.82-1l0.17-0.73l0.07-1.11l0.38-0.97l0.42-0.49l-0.11-0.39l-0.29-0.56l-0.61-0.8l-0.93-0.97l-0.69-0.93l-0.3-0.87l-0.16-0.78
  		l0.03-0.71l0.26-0.48l0.88-0.61l0.12-0.26l-0.35-1.35l-0.61-0.25l-1.04-0.13l-0.58-0.01l-0.11-0.15l-0.03-0.27l0.12-0.56l0.33-0.64
  		l0.27-0.41l0.04-0.34l-0.35-1.18l-0.1-1.45l0.12-1.14l1.1-0.84l0.05-0.31l-1.39-0.91l-1-1.04l-0.31-0.61l-1.15-0.09l-0.71-1.76
  		l-1.03-0.86l-1.02-0.74l-0.6-0.35l-3.54-1.07l-1.41-0.2l-1.66-0.62l-1.24-0.78l-1.06-0.51l-0.91-0.62l-1.27-0.58l-0.36-0.5
  		l-1.37-0.93l-0.64-0.61l-2.23-1.15l-0.08-0.46l-0.02-0.44l-0.1-0.18l-2.3-0.84l-1.1,0.05l-3.16,0.18l1.4,1.28l0.46,0.51l0.09,0.75
  		l-0.24,1.4l-0.67,1.19l-0.78,0.97l-1.5,0.96l2.28,0.94l-1.55,1.27l-0.73,0.5l-0.84-0.09l-1.51-0.44l-3.64-1.07l-1.74-0.4
  		l-1.55-0.12l-0.83,0.02l-3.3-0.91l-0.64,0.1l-1.18,0.4l-0.13,0.89l0.07,2.21l0.17,1.69l-0.44,0.98l-0.44,0.66l-1.32,1.78
  		l-2.96-1.21l-2.02-0.8l-1.3,1.07l-3.26,1.96l-1.67,3.85l-0.1,0.13l-0.99,0.98l-1.26,0.45l-0.96,0.22l-0.56,1.14l1.3,1.63l0.67,0.86
  		l0.62,1.38l-0.11,0.88l-0.14,0.55l-1.4,1.12l-2.98,2.94l-2.76,3.06l-1.13,0.88l0.51,2.59l-0.92,0.76l-1.87,0.85l-0.95,0.33
  		l-1.03,0.19l-3.16,0.34l0.56,2.75l0.21,1.22l0,0.72l-0.33,0.7l-0.39,1.34l-0.59,4.77l-0.45,0.51l-0.62,1.29l-2,3.08l-1.6,2.04
  		l-2.31,2.93l1.88,0.92l1.72,0.67l0.36,1.03l0.24,1.73l-0.06,1.17l-0.66,1.05l-0.51,0.74l-0.36,0.36l-2.44-0.36l-3.13-0.46l-0.81,0
  		l-1.82,0.33l-1.64,0.68l-0.87,0.57l-0.23,0.23l-1.09,1.3l-1.94,2.31l-1.07,1.01l0.31,1.39l-1.79,2.67l1.17,2.69l0.05,0.09
  		l0.62,1.08l-0.65,0.7l-0.27,0.37l0.09,1.24l0.17,1.38l-0.15,0.81l-0.06,0.9l1.59,4.01l-0.02,0.94l-0.08,0.6l-0.49,2.48l-0.66,3.32
  		l1.15,0.87l1.64,1.04l0.92,0.41l1.36,1.23l1.04,1.2l-0.14,0.8l-0.3,0.88l-0.44,0.61l-0.41,0.84l-0.18,0.62l-0.19,0.17l-1.81,0.07
  		l-0.96,0.23l-0.5,0.26l0.17,1.41l1.1,2.58l0.91,1.83l0.29,1.21l-0.3,1.22l-0.31,0.62l-0.01,0.84l-0.24,1.67l-0.71,0.84l-0.93,0.92
  		l-1.03,0.68l-0.82,0.19l-0.7,0.08l-0.47,0.35l-0.43,1.02l-0.4,1.07l-1.29,1.31l0.03,0.46l0.49,1.55l0.46,1.77l-0.39,1.65
  		l-0.33,1.73l-0.58,1.15l-0.84,0.41l-0.61-0.2l-0.69-1.57l-0.78-0.63l-0.84,0.11l-0.24,0.41l-0.17,0.71l0.18,0.82l0.33,0.7l0.13,2.3
  		l0.4,2.52l-0.16,0.63l-0.03,0.68l0.69-0.14l0.86,0.5l0.15,2.72l0.84,1.43l1.3,0.34l0.22,2.55l0.05,0.57l1.21,0.46l0.05,0.81
  		l0.27,1.1l0.37,1.16l0.77-0.25l0.83,2.66l2.27,3.86l1.27,1l1.22,1.92l0.64,0.16l0.76,0.38l0.3,1.22l-0.52,0.76l-0.71-0.04
  		l-0.98,0.18l0.29,0.67l0.43,0.45l0.5,0.99l-0.41,0.26l-0.39,0.08l-1.68-0.69l-0.3,0.03l0.42,0.54l0.6,1.28l2.04,3.04l0.91,0.9
  		l0.27,0.69l0.03,0.64l-0.12,0.96l-0.21,0.94l0.02,0.61l-0.46,0.83l3.67,0.76l4.08-0.97l2.3,0.43l0.79-0.05l1.41-1.54l-0.55-1.28
  		l-0.62-1.1l0.1-1.22l0.39-0.65l1.18-1.06l0.6-0.45l0.72-0.41l0.82,0.34l0.84-0.16l-0.34-1.2l0.57-0.33l2.27-0.12l2.32,0.25
  		l1.54-0.38l0.98-0.03l0.81,0.25l0.88,0.47l0.79-0.5l0.64-0.66l1.3-3.32l0.55-1.07l1.11-1.43l0.5-1.19l0.42-1.42l0.59-1.7
  		l-0.17-0.89l-0.23-0.56l-0.03-0.94l1.3-2l0.18-0.85l-0.4-0.82l-0.17-0.89l0.02-1.45l-0.26-0.62l0.35-1.23l0.82-0.06l0.04-2.97
  		l0.59-0.66l-0.45-1.09l-0.54-1.61l1.45-0.31l0.84-0.41l-1.14-1.14l-0.89-0.18l-1.73-0.16l-0.73,0.14l-0.61-0.33l-0.87-0.1
  		l0.86-0.33l2.72,0.15l2.85-0.04l1.05-0.7l2.06-0.86l0.92-0.96l1.79-0.72l0.81-0.61l0.54,0.13l1.23-0.6l1.04-0.74l1.58-0.58
  		l0.31-0.28l0.43-0.59l0.35-1.38l0.71-0.02l1-0.44l-0.48-0.84l-0.43-0.17l-0.42,0.14l-0.53,0.35l-0.5-0.08l-0.55,0.1l-0.51,0.45
  		l-0.66,0.19l-1.29-0.16l-0.13-0.38l1.68-0.89l0.45,0.12l0.5-0.21l0.52-0.49l0.54-0.17l1.48-0.94l0.52-0.44l0.66-0.71l1.49-0.95
  		l0.63-0.3l0.17-0.89l-0.48-1.44l-0.41-0.48l-0.27-0.58l-0.55-0.68l-1.57-0.5l-0.55-0.42l0.19-1.27l-1.33-1.06l-1.26-0.31
  		l-0.74-0.59l-1.27-1.31l-0.47-1l-0.71,0.12l-1.09,0.52l-0.68,0.05l-0.26-0.64l-0.32-0.54l-0.32-0.19l-0.83,0.01l-0.82,0.01
  		l-0.92-0.76l0.09-0.8l0.15-0.62l-0.64-1.78l0.08-0.43l-0.23-2.06l-0.05-1.32l-0.13-0.37l0.3-0.43l-0.18-0.59l-0.34-0.31l0.22-0.99
  		l-0.15-0.6l-0.13-0.92l0.71-1.04l-0.16-0.88l1.16,0.43l1.1,0.09l-0.41-0.73l-0.16-0.54l-0.2-1.09l0.32-1.3l0.29-0.74l0.54-1.87
  		l0.44-0.6l0.6-0.27l-0.83-0.4l-0.89-0.93l-0.47-1.2l0.04-0.48l0.27-0.6l0.83,0.34l0.52,0.41l0.64,0l0.6-0.66l0.98-0.02l0.95-0.99
  		l0.76-0.29l-0.26-0.34l-0.64-0.19l-0.23-0.25l0.32-0.27l0.28-0.54l-0.35-0.86l-0.31-0.59l-0.14-0.56l0.23-0.18l0.37,0.7l1.2-0.03
  		l-0.13,0.32l-0.03,0.28l0.81,0.02l0.38-0.3l0.28-0.49l1.81-0.62l0.16-0.43l0.04-0.4l0.13-0.4l-1.59-0.1l0.26-0.47l0.53-0.07
  		l1.04-0.34l0.4-0.83l0.24-0.69l0.51,0.02l0.78-0.29l0.5,0.01l0.33-0.12l-0.07-0.23l-0.49-0.19l0.21-0.26l1.83,0.26l1.7-1.46
  		l0.44-1.08l0.56-0.65l1.18,0.71l-0.03-0.48l0.1-0.65l0.74,0.29l0.55,0.39l0.56-0.07l0.5-1l1.11-0.97l2.45-0.69l1.4-0.81l0.69-0.68
  		l1.89-0.7l0.72-0.56l2.15-4.18l2-1.64l1.76-1.09l0.25-0.5l0.21-0.64l-1.06-1.1l-0.52-1.16l-0.44-1.3l-0.63-0.68l-0.56-0.47
  		l0.49-0.93l0.82-0.88l1.1-0.98l1.26-1.55l0.06-0.48l-0.29-0.64l-0.33-0.53l-0.58-0.52l-0.23-0.48l0.31-0.05l1.01,0.91l0.36-0.09
  		l0.03-0.52l-0.14-0.24l-0.43-0.2l-0.18-0.38l0.08-0.39l0.28-0.3l0.97,0.07l1.68-0.29l0.29-0.18l0.31-0.46l-0.39-0.53l0.14-0.33
  		l1.4,0.03l0.51-0.31l-0.12-0.24l-0.31-0.19l-0.08-0.38l1.41,0.19l0.11-0.33l0.07-1.44l0.11-0.36l0.41-0.56l0.26-0.72l0.29-0.27
  		l0.54,0.13l0.62,0.81l0.69-0.17l1.06-0.9l1.45,1.18l1.54,0.71l0.44-0.2l0.56-0.5l1.66-0.25l1.46-0.01l0.84-0.32l1.68,0.64
  		l2.23-0.32L2012.25,663.85z M1966.54,748.86l-0.41,0.27l-0.14,0.5l-0.08,0.1l0.21,0.51l0.21-0.41l0.24-0.27l0.62-0.41l0-0.13
  		L1966.54,748.86z M1970.86,768.22l-0.81,0.86l0.4,0.56l0.44,0.06l0.15-0.76l1.47-0.49l-0.42-0.18L1970.86,768.22z"/>
    </>,
    SDN: <>
        <polygon className="st1" points="2134.59,1165.77 2132.56,1164.51 2132.14,1163.92 2131.88,1164.11 2131.46,1164.24 2130.84,1163.52
  		2130.38,1162.85 2129.1,1161.59 2127.48,1160.39 2126.38,1160.19 2125.81,1159.88 2125.3,1159.3 2124.38,1156.94 2123.43,1152.68
  		2123.55,1150.86 2122.96,1147.99 2122.92,1145.6 2123.25,1144.19 2122.79,1142.66 2122.65,1141.23 2122.52,1140.47 2122.6,1139.4
  		2123.17,1139.63 2123.5,1139.97 2123.55,1139.68 2123.51,1139.36 2123.12,1138.69 2122.02,1137.46 2120.72,1135.17
  		2120.34,1133.57 2120.25,1131.57 2117.49,1131.57 2114.72,1131.57 2111.96,1131.57 2109.2,1131.57 2106.43,1131.58
  		2103.67,1131.58 2100.91,1131.58 2098.14,1131.58 2095.38,1131.58 2092.62,1131.58 2089.85,1131.58 2087.09,1131.58
  		2084.33,1131.58 2081.56,1131.58 2078.8,1131.58 2076.03,1131.58 2074.46,1131.58 2074.73,1130.8 2074.89,1130.24 2074.71,1129.86
  		2074.17,1129.76 2073.82,1129.89 2072.99,1131.52 2072.56,1131.59 2071.58,1131.59 2068.36,1131.59 2065.14,1131.59
  		2061.93,1131.59 2058.71,1131.59 2055.49,1131.59 2052.27,1131.59 2049.06,1131.58 2045.84,1131.58 2042.62,1131.58
  		2039.4,1131.58 2036.19,1131.58 2032.97,1131.58 2029.75,1131.58 2026.53,1131.58 2023.32,1131.58 2020.1,1131.58 2020.1,1135.95
  		2020.09,1140.32 2020.09,1144.67 2020.09,1149.02 2020.06,1149.04 2020.04,1149.05 2020.01,1149.07 2019.99,1149.08
  		2017.91,1149.08 2015.83,1149.08 2013.75,1149.08 2011.67,1149.08 2011.67,1150.17 2011.67,1151.25 2011.67,1152.34
  		2011.67,1153.42 2011.68,1157.3 2011.68,1161.17 2011.68,1165.03 2011.69,1168.88 2011.69,1172.73 2011.69,1176.58 2011.7,1180.41
  		2011.7,1184.25 2011.7,1185.51 2011.6,1186.02 2011.55,1186.09 2011.39,1186.17 2009.38,1185.82 2008.51,1185.81 2007.28,1186.08
  		2005.47,1186.23 2004.3,1186.18 2003.5,1186.84 2002.86,1187.63 2003.16,1189.53 2003.09,1190.16 2002.85,1190.81 2002.3,1191.37
  		2001.75,1191.82 2001.42,1192.21 2001.02,1193.07 2000.72,1193.47 2000.74,1194.01 2000.65,1194.57 2000.32,1194.87
  		1999.48,1195.08 1998.94,1195.34 1998.5,1195.75 1998.21,1196.04 1998.36,1196.44 1998.58,1196.98 1998.7,1197.82 1998.78,1198.32
  		1999.19,1198.72 1999.44,1199.01 1999.53,1199.36 1999.29,1199.66 1998.27,1200.27 1997.85,1200.5 1997.38,1200.81 1997.2,1200.93
  		1996.45,1201.51 1996.08,1202.02 1995.89,1202.45 1995.9,1203.05 1996.28,1203.93 1996.7,1204.68 1996.86,1205.25 1996.96,1205.87
  		1996.92,1206.46 1996.7,1206.97 1996.33,1207.44 1994.91,1208.3 1994.22,1209.26 1993.66,1210.42 1993.52,1211.05 1993.68,1211.47
  		1993.97,1211.82 1994.39,1212.01 1995,1212.06 1996.02,1211.86 1996.96,1211.74 1997.96,1212.16 1998.49,1213.12 1998.28,1213.83
  		1998.66,1215.12 1999,1216.67 1998.98,1217.19 1999.12,1217.39 1999.75,1217.49 1999.89,1217.85 1999.68,1220.57 1999.97,1221.33
  		2000.39,1221.87 2000.87,1222.15 2001.35,1222.52 2001.59,1222.77 2002.15,1222.83 2002.77,1223.32 2002.94,1223.98
  		2002.89,1224.62 2002.53,1226 2002.24,1226.92 2002.84,1227.98 2003.12,1228.35 2005.57,1230.83 2006.05,1231.42 2007.26,1233.23
  		2008.01,1234.45 2008.86,1236.2 2008.95,1237.15 2008.83,1237.96 2008.66,1240.27 2008.44,1240.94 2007.36,1242.18
  		2007.32,1242.74 2007.54,1243.2 2007.87,1243.39 2008.07,1243.62 2007.94,1244.7 2008.33,1245.12 2009.14,1245.4 2011.18,1245.59
  		2012.25,1245.75 2013.08,1245.97 2013.19,1245.71 2013.64,1245.1 2014.37,1244.71 2016.32,1244.1 2016.43,1243.86 2016.47,1243.09
  		2016.63,1242.71 2017.3,1241.64 2017.39,1241.21 2017.42,1240.29 2017.51,1239.86 2017.71,1239.55 2018.25,1239.02 2018.43,1238.7
  		2018.51,1238 2018.45,1236.61 2018.73,1236.06 2019.96,1234.8 2020.29,1234.24 2020.4,1233.73 2020.39,1233.22 2020.46,1232.71
  		2020.83,1232.22 2021.14,1232.07 2022.05,1231.91 2022.67,1232.01 2026.98,1231.15 2027.49,1231.27 2027.72,1231.78
  		2027.7,1232.59 2027.77,1232.99 2028,1233.27 2028.69,1233.66 2029.17,1234.31 2029.42,1234.55 2030.11,1234.99 2033.33,1238.71
  		2034.23,1239.06 2035.11,1238.93 2036.86,1238.16 2037.73,1237.97 2043.84,1238.18 2044.53,1238.07 2044.57,1238.09
  		2045.5,1239.95 2045.94,1240.37 2052.64,1240.4 2052.52,1239.87 2052.6,1239.27 2053.38,1238.51 2053.78,1238.13 2053.78,1238.13
  		2053.95,1238 2054.98,1237.45 2055.99,1237.09 2057.94,1236.66 2058.65,1235.99 2059.04,1235.37 2059.05,1234.15 2059.31,1233.95
  		2059.77,1233.67 2062.02,1232.59 2062.4,1232.36 2066.37,1234.88 2068.6,1236.88 2068.74,1236.97 2068.85,1237.01 2068.97,1236.94
  		2069.07,1236.85 2069.23,1236.79 2069.34,1236.76 2070.3,1236.74 2072.1,1236.64 2072.69,1236.4 2076.31,1232.83 2077.24,1231.7
  		2077.47,1231.46 2077.99,1230.65 2078.55,1229.26 2078.66,1229.1 2082.62,1225.75 2082.76,1225.49 2082.8,1225.28 2082.2,1224.15
  		2082.07,1223.58 2082.05,1222.7 2082.17,1221.33 2082.13,1220.46 2082.11,1220.36 2082.07,1220.23 2082.05,1220.18
  		2079.83,1217.71 2085.43,1217.68 2085.44,1217.48 2085.43,1217.37 2085.42,1217.27 2085.31,1216.98 2085.25,1216.58
  		2085.25,1216.47 2085.25,1216.37 2085.29,1216.16 2085.29,1216.05 2085.28,1216 2085.27,1215.95 2085.28,1215.91 2085.3,1215.87
  		2089.32,1215.92 2089.27,1216.62 2088.78,1218.26 2088.79,1219.25 2088.67,1220.37 2088.65,1220.46 2088.54,1220.7
  		2088.44,1220.84 2088.33,1220.98 2088.3,1221.03 2088.26,1221.11 2088.26,1221.23 2089.09,1227.51 2089.06,1227.67
  		2089.03,1227.78 2088.81,1228.17 2088.75,1228.3 2088.74,1228.4 2088.83,1228.46 2090.68,1229.14 2090.77,1229.18 2090.84,1229.24
  		2091.51,1230.05 2095.16,1233.02 2095.28,1233.17 2095.66,1234.1 2095.7,1234.25 2095.72,1234.47 2095.71,1234.64 2095.61,1235.2
  		2095.64,1235.45 2095.71,1235.62 2095.76,1235.81 2095.76,1235.87 2095.75,1235.92 2095.72,1236.01 2095.18,1237.09 2095,1237.86
  		2094.95,1238.5 2094.98,1238.87 2095.05,1239.11 2095.07,1239.17 2095.1,1239.21 2095.12,1239.23 2095.15,1239.25 2096.71,1239.25
  		2096.72,1239.25 2096.73,1238.81 2097.08,1236.99 2097.41,1235.94 2097.63,1235.39 2098.52,1233.65 2098.69,1233.09
  		2098.72,1232.58 2098.39,1230.24 2098.96,1229.13 2099.7,1228.04 2100.34,1227.57 2100.88,1227.26 2101.13,1227.39 2101.75,1227.9
  		2102.56,1228.39 2102.94,1228.28 2103.5,1227.85 2103.91,1227.39 2103.86,1226.57 2104.23,1224.87 2104.16,1223.9 2104.55,1222.69
  		2104.99,1220.98 2105.19,1219.9 2105.43,1219.32 2106.61,1218.13 2107.63,1216.45 2108.28,1215.21 2109.51,1213.2 2110.13,1212.47
  		2110.64,1212.15 2111.4,1211.95 2112.81,1211.77 2113.82,1211.59 2113.97,1211.33 2114.05,1210.92 2114.07,1210.02
  		2114.26,1208.47 2114.7,1206.96 2115.21,1205.81 2115.5,1205.29 2115.83,1204.79 2116.2,1203.93 2116.68,1202.09 2116.65,1200.84
  		2117.33,1198.55 2117.06,1196.1 2116.88,1194.45 2116.69,1192.71 2116.51,1191.07 2117.31,1190.06 2117.68,1189.1 2118.63,1185.98
  		2119.01,1185.35 2119.76,1183.68 2119.87,1183.2 2120.61,1181.08 2120.54,1179.68 2120.39,1178.26 2120.79,1177.42
  		2121.15,1176.75 2121.13,1176.18 2121.29,1174.85 2121.41,1174.52 2121.85,1174.5 2122.76,1174.67 2123.43,1174.54 2124.2,1174.54
  		2124.8,1174.5 2125.15,1174.09 2125.63,1172.55 2125.94,1172.24 2126.18,1172.14 2126.86,1171.85 2127.45,1171.4 2127.92,1171.08
  		2128.1,1171.02 2128.6,1170.97 2129.1,1170.78 2129.34,1170.57 2129.97,1170.39 2130.59,1170.49 2131.01,1170.3 2131.28,1170.18
  		2131.6,1170.17 2131.89,1169.99 2132.01,1169.71 2132.2,1169.54 2132.68,1169.13 2132.9,1168.84 2133,1168.55 2133.1,1168.31
  		2133.31,1167.92 2134.16,1166.93 2134.89,1166.36 	"/>
    </>,
    SLB: <>
        <path className="st0" d="M3134.08,1380.27l0.6-0.1l0.02,0.15l0.19,0.28l0.86,0.27l0.65-0.51l-0.27-0.3l-0.3-0.14l-0.34-0.04l-0.64-0.24
  		l-1.2-1.01l-0.38-0.39l-0.39-1.32l-0.61-0.55l-2.23-1.07l-1.36-1.03l-1.05-0.27l-0.23,0.25l0.04,0.65l0.32,0.39l1.69,1.25
  		l1.76,2.27l1.66,1.2L3134.08,1380.27z M3133.26,1384.73l-0.36-0.23l-0.66,0.1l-0.55,0.59l-0.01,0.64l0.7,0.87l0.92,0.05l0.18-0.13
  		l0.17-0.22l0.08-0.56l-0.12-0.63L3133.26,1384.73z M3135.26,1390.39l0.23-0.67l-0.27-0.46l-0.29,0.1l-0.94,0.74l-0.18,0.38
  		l1.02,1.14l0.46,0.24l0-0.12l-0.48-0.53l0.12-0.63L3135.26,1390.39z M3139.6,1389.98l-0.61-1.54l-0.53-0.69l-0.95-0.21l-0.33-0.44
  		l-0.11-1.34l-0.91-0.34l-0.48,0.16l-0.78,0.94l-0.16,0.55l-0.78,0.42l-0.09,0.43l0.12,0.44l0.59,0.16l0.41-0.49l1.29-0.14l0.45,0.1
  		l0.06,0.57l0.2,0.91l0.57,0.46l0.79,0.2l0.59,0.74l0.12-0.33l0.44-0.03L3139.6,1389.98z M3136.91,1391.65l-0.45-0.06l-0.61,0.07
  		l0.04,0.2l1.08,0.45l0.48,0.09l0.02-0.3l-0.19-0.2L3136.91,1391.65z M3141.34,1390.24l-0.9-0.24l-0.26,0.3l-0.24,0.14l-0.24,0.04
  		l-0.09,0.18l-0.16,0.69l0.49,0.57l0.61,0.22l0.83-0.67l-0.04-0.32l-0.12-0.2l-0.18-0.14l0.54-0.42L3141.34,1390.24z
  		 M3158.78,1394.85l0.66,0.33l0.13,0.34l0.07,0.05l0.2-0.07l0.6,0.18l0.3-0.34l-0.74-0.67l-0.55-0.45l-0.23,0.02l-0.48-0.12
  		l-0.61,0.32l0.08,0.39L3158.78,1394.85z M3169.23,1398.48l-0.04,0.73l-0.02,0.69l0.28,0.31l0.3-0.24l0.64,0.66l0.04-0.31l-0.09-0.9
  		l-1.19-2.17l-0.36-0.13l0.32,0.8L3169.23,1398.48z M3129,1382.46l-0.66-0.32l-0.49,0.56l0.07,0.57l0.85,0.82l-0.01,0.51l0.2,0.14
  		l0.28,0.45l0.16-0.12l0.17-0.39l0.69-0.83l0.16-0.46l-0.77-0.23L3129,1382.46z M3121.12,1377.41l-0.23,0.64l0.52,0.27l0.85-0.2
  		l0.21-0.45l-1.06-0.59L3121.12,1377.41z M3128.25,1385.47l-0.08,0.34l-0.02,0.52l0.44,1.04l0.1-0.21l0.07-0.63l-0.35-1.16
  		L3128.25,1385.47z M3176.56,1408.59l-1.1-1.73l-0.43-0.44l-0.69,0.19l-0.91-0.34l-0.62,0.08l-1.06-0.5l-0.15-0.14l-0.37-0.16
  		l-1.5-0.96l-0.79-0.27l-0.65-0.01l-0.16,0.66l0.07,0.37l0.88,0.05l0.75,0.25l0.1,0.79l0.34,0.31l-0.01,0.64l2.1,1.27l1,0.4
  		l1.15,0.17l0.68,0.33l0.65-0.14l0.84,0.21l0.61-0.08l-0.72-0.4L3176.56,1408.59z M3168.82,1399.3l0.09-0.32l-0.08-0.7l-0.92-1.46
  		l-0.02-1.05l-0.4-0.5l-0.03-0.34l-0.39-1.1l-0.97-0.9l-0.57-0.15l-0.26-0.32l0.08-0.84l0.28-0.29l0.08-0.45l-2.09-2.51l-0.4,0.02
  		l-0.15,0.17l-0.3,0.02l-0.44-0.09l-0.05,0.38l1.04,1.4l-0.43,0.69l0.92,2.89l0.85,1.63l1.27,0.97l0.14,0.31l1.26,0.72l1.1,1.66
  		L3168.82,1399.3z M3208.89,1408.34l-0.86-0.16l-0.97,0.11l-0.42,0.24l-0.2,0.49l-0.38-0.04l0,0.24l0.24,0.5l0.31-0.02l0.4,0.09
  		l0.54-0.61l0.5-0.08l0.21,0.04l0.67-0.15l-0.03-0.11l0.27-0.44L3208.89,1408.34z M3215.2,1416.38l-0.17-0.43l-0.54-0.06l-0.37,0.17
  		l0.49,0.73l0.29,0.03l0.74-0.14L3215.2,1416.38z M3163.63,1400.18l-0.59-0.2l-0.47-0.87l-0.84-0.44l-1.44-0.97l-2.44-0.02
  		l-0.8,0.12l-1.85-1.36l-0.54-0.03l-0.51,0.36l-0.15,0.36l0.04,0.99l0.08,0.52l0.49,0.88l0.63,0.75l0.4,0.32l0.43,0.24l1.25,0.18
  		l2.68,0.08l1.35,0.62l1.41,0.29l0.54-0.12l0.75-0.3l0.15-0.13l-0.21-0.81L3163.63,1400.18z M3161.08,1416.95l-3.13-1.72l-0.65-0.18
  		l-0.11,0.19l-0.06,0.37l0.2,0.35l0.7,0.12l0.11,0.14l0.42,0.28l1.02,0.17l0.71,0.41l0.33,0.65l0.41,0.22l0.53,0.15l0.59-0.29
  		l-0.33-0.33L3161.08,1416.95z M3155.16,1389.71l1.13,0.7l-0.02-0.2l-0.26-0.6l-0.46-0.48l0.41-0.67l-3.47-2.51l-0.54-0.29
  		l-0.68-0.15l-0.75-0.56l-0.75-0.05l-0.83-0.56l-0.32-0.41l-0.92-0.56l-1.08-0.99l-2.33-0.5l0.18,0.27l0.73,0.62l0.27,0.91l0.75,0.5
  		l0.77,0.75l0.45,0.17l0.19,0.28l0.75,0.68l1.23,0.53l1.26,0.78l0.44,0.06l0.53,0.48l2.45,0.94L3155.16,1389.71z M3150.36,1394.33
  		l-0.19-0.17l-0.2-0.07l-0.44,0.27l-0.35,0.42l0.29,0.29l0.48,0.03l0.4,0.1l0.11-0.02l0.38-0.25l-0.05-0.54L3150.36,1394.33z
  		 M3154.67,1390l-0.35-0.48l-0.04-0.3l-0.4-0.29l-0.35,0.17l-0.12,0.44l0.26,0.28l0.6,0.31L3154.67,1390z M3142.22,1391.43
  		l-0.46,0.91l0.2,0.34l0.19-0.03l0.44-0.21l-0.14-0.27L3142.22,1391.43z"/>
    </>,
    SAU: <>
        <path className="st0" d="M2119.21,1100l-1.34-0.55l-0.29-0.23l-0.17-0.39l-0.25,0.39l0.22,0.39l1.62,0.6l0.35,0.3l0.35,0.45l0.59,0.6
  		h0.22l0.45-0.28l-0.26-0.1L2119.21,1100z M2117.51,1097.74l-0.02,0.69l0.36,0.31l0.08-0.12l0-0.2l-0.13-0.54l0.02-0.54
  		L2117.51,1097.74z M2277.8,1130.67l-0.72-1.15l-0.75-1.21l-0.7-1.14l-0.51-0.83l-0.62-1l-0.56,0.71l-0.13,0.02l-0.67-0.09
  		l-0.23-0.03l-0.64-0.08l-0.99-0.13l-1.29-0.16l-1.52-0.19l-1.7-0.22l-1.82-0.23l-1.87-0.24l-1.87-0.24l-1.82-0.23l-1.7-0.22
  		l-1.52-0.19l-1.29-0.16l-0.99-0.13l-0.64-0.08l-0.23-0.03l-0.71-0.09l-0.38-0.48l-0.46-0.58l-0.46-0.58l-0.46-0.58l-0.46-0.58
  		l-0.46-0.58l-0.46-0.58l-0.46-0.58l-0.46-0.58l-0.46-0.58l-0.46-0.58l-0.46-0.58l-0.46-0.58l-0.46-0.58l-0.46-0.58l-0.46-0.58
  		l-0.46-0.58l-0.31-0.39l-0.17-0.44l-0.03-1.15v-0.25l-0.28,0l-0.49-0.19l-0.69-0.09l-0.72-0.19l0.51-1.21l0.41-0.48l-0.06-0.35
  		l-0.61,0.06l-0.59-0.38l-0.76,0.18l-0.71,0.2l-0.59-0.01l-0.48-0.08l-0.32-0.19l-0.61-0.75l-0.43-0.98l-0.66-0.71l-0.49-0.84
  		l-0.92-1.1l-0.42-1.96l-0.45-1.06l-1.46-1.26l-0.36-0.51l-0.42-1.19l-0.5-0.81l-0.41-1.03l-0.42-1.34l0.19-0.11l0.35,0.04
  		l0.33,0.16l0.17,0l0.49-1.87l-0.24-0.87l-0.62-0.46l-0.7-0.64l-0.13-0.74l-0.03-0.63l0.15-0.19l0.51,0.21l0.4,0.15l0.13-0.03
  		l-1.38-1.5l-2.27-1.14l-1.51-1.77l-1.11-0.26l-1.04-1.17l-0.9-1.15l0.53-0.5l-0.67-0.32l-0.59-0.18l-1.52-0.73l-0.92-0.86
  		l0.08-0.37l0.22-0.32l-0.2-0.86l-0.3-0.57l-1.24-1.57l-0.87-2.02l-0.21-0.85l-0.47-0.86l-1.46,0.02l-1.85,0.03l-1.5,0.02
  		l-1.69,0.02l-0.74-0.86l-0.25-0.94l-0.27-0.97l-0.74-1.38l-2.48-0.33l-1.32-0.18l-2.17-0.26l-1.63-0.2l-1.47,0.3l-3.43-0.29
  		l-3.8-0.33l-3.78-0.32l-2.81-0.24l-0.22-0.08l-2.78-2.13l-2.2-1.68l-2.74-2.11l-2.8-2.15l-2.84-2.19l-2.07-1.59l-2.51-2.05
  		l-2.28-1.87l-1.8-1.48l-2.31-1.29l-1.81-1.01l-2.63-1.48l-2.11-1.18l-1.8-1.01l-2.77-1.56l-0.92-0.42l-2.88-0.52l-2.73-0.44
  		l-2.83-0.46l-1.88-0.3l-1.25,1.09l-0.29,0.12l-1.62,0.45l-3.32,0.92l-2.22,0.62l-2.84,0.79l-2.36,0.65l-2.34,0.65l-2.16,0.6
  		l1.24,1.26l1.89,1.93l1.26,1.29l1.49,1.65l1.33,1.47l1.41,1.56l-0.99,0.53l-1.63,0.87l-0.17,0.16l-0.14,0.16l-0.67,1.55l-0.53,1.22
  		l-0.18,0.15l-2.27,0.45l-2.29,0.45l-1.45,0.28l-0.43,0.32l-0.94,1.52l-0.97,1.57l-1.63,1.28l-1.81,1.41l-0.45,0.09l-1.31-0.22
  		l-2.23-0.37l-2.16-0.36l-1.48-0.25l-1.79-0.3l-1.28,5.78l-0.16,1.94l-0.81,2.21l-0.57,1.05l0.07,0.76l0.82-0.6l0.89,0.2l2.11,0.2
  		l0.86,0.47l2.05,2.73l1.33,2.73l1.53,1.57l0.75,1.7l1.52,1.71l0.52,1.04l1.31,1.54l2.27,4.4l1.32,0.59l0.23,1.22l0.51,1.36
  		l0.82,0.53l0.51,0.46l1.92,3.13l0.59,1.25l0.21,0.69l0.19,1.01l-0.39,0.77l-0.33,0.47l1.33,1.82l0.78,1.39l0.94,1.49l0.8,0.12
  		l0.63,0.03l0.91,0.77l0.83,0.02l0.49,0.54l1.02,0.59l1.6,1.3l1.48,1.77l0.66,1.36l1.38,2.23l0.43,0.98l0.34,1.29l0.33,0.53
  		l0.89,0.95l-0.49,0l0.47,0.68l0.58,0.31l-0.05,0.63l0.51,0.94l0.29,1.76l-0.22,0.87l-0.3,0.79l-0.11,1.49l-0.28,1.33l0.35,0.93
  		l0.52,0.98l0.47,1.27l0.03,0.76l-0.48,1.07l1.54,2.94l1.81,2.07l1.03,1.92l0.97,1.11l1.31,0.85l1.66,0.24l3.38,2.38l1.13,1.49
  		l1.21,0.58l0.15,0.34l0.12,0.61l0.47,0.79l0.55,0.57l1.71,3.54l0.24,0.81l0.39,1.02l0.25,0.92l0.07,0.75l1.7,1.96l0.64,1.7
  		l1.27,2.15l0.77,1.05l2.55,1.87l2.04,2.02l0.32,1.54l0.43,1.16l0.77,0.63l0.58,0.78l0.07,0.79l0.8,0.58l0.43,0.55l0.23,0.72
  		l0.04,0.72l0.5,1.02l0.08,0.69l1.57-1.18l0.4-0.36l0.23-0.31l0.37-0.67l0.51-0.22l0.18-0.7l-0.02-0.35l-0.33-0.3l-0.25-0.82
  		l0.08-1.04l0.08-0.44l0.17-0.79l0.55-0.3l0.13-0.23l-0.43-0.5l0.04-0.3l0.94-0.84l0.37-0.25l0.61-0.26l0.47,0l0.55,0.15l0.49,0.24
  		l0.47,0.43l0.5,0.49l0.77,0.18l0.52-0.05l0.43,0.21l0.36-0.12l0.41-0.25l0.65,0.02l0.59-0.28l1.67-0.14l1.62,0.09l1.69-0.24
  		l1.68,0.02l1.7,0.02l0.38,0.04l0.37,0.15l1.43,0.75l1.09,0.15l2.18,0.2l2.33,0.22l2.02,0.19l1.71-0.18l1.42-0.15l0.38,0.03
  		l0.43,0.46l0.85,1.15l0.81,1.08l1.41,0.06l0.91-0.41l1-0.57l0.61-0.44l0.7-1.76l0.46-1.14l1.05-1.28l0.88-1.07l1.16-1.42l0.64-0.79
  		l1.27-1.56l1.21-0.61l2.33-1.17l2.29-1.15l1.49-0.75l1.27-0.34l2.13-0.29l2.5-0.35l2.5-0.35l2.66-0.37l2.97-0.41l2.04-0.28
  		l2.6-0.36l2.16-0.3l1.92-0.27l1.98-0.27l1.19-0.41l1.45-0.5l1.45-0.5l1.45-0.5l1.45-0.5l1.45-0.5l1.45-0.5l1.45-0.5l1.45-0.5
  		l1.45-0.5l1.45-0.5l1.45-0.5l1.45-0.5l1.45-0.5l1.45-0.5l1.45-0.5l1.45-0.5l0.9-0.31l0.37-1.16l0.31-0.97l0.31-0.97l0.31-0.97
  		l0.31-0.97l0.31-0.97l0.31-0.97l0.31-0.97l0.31-0.97l0.31-0.97l0.31-0.97l0.31-0.97l0.31-0.97l0.31-0.97l0.31-0.97l0.31-0.97
  		l0.31-0.97l0.28-0.89L2277.8,1130.67z M2163.67,1177.12l0.06,0.14l0.27,0.27l-0.65-0.05l-0.35-0.29l0.13-0.26l-0.07-0.24
  		l-0.05-1.12l-0.26-0.49l-0.48-0.08l0.21,0.48l-0.23,0.46l-0.35,0.28l-0.34,0.11l0.21,0.59l0.81,0.81l0.56,0.27l0.91-0.16l0.26,0.24
  		l0.05,0.22l0.16,0.2l0.25,0.21l0.08-0.22l0.03-0.97l-0.93-0.82L2163.67,1177.12z"/>
    </>,
    RWA: <>
        <polygon className="st1" points="2069.66,1335.75 2069.28,1335.11 2069.17,1334.13 2069.35,1332.82 2069.22,1331.72 2068.8,1330.85
  		2068.36,1330.33 2067.7,1330.09 2066.66,1328.75 2066.34,1328.11 2066.39,1327.71 2066.67,1327.58 2066.33,1327.56
  		2065.85,1327.54 2065.41,1327.64 2065.08,1327.96 2064.73,1328.51 2064.12,1329.14 2063.64,1329.7 2063.23,1330.1 2062.3,1330.75
  		2061.79,1330.94 2061.53,1330.91 2061.38,1330.79 2061.09,1329.95 2060.91,1329.82 2059.09,1330.25 2058.81,1330.26
  		2058.48,1330.44 2057.89,1330.93 2057.34,1331.26 2056.92,1331.34 2056.21,1332.21 2055.82,1332.75 2056.05,1333.17
  		2056.39,1334.21 2056.97,1335.51 2056.8,1335.87 2056.34,1336.17 2056.11,1336.47 2055.98,1336.74 2055.21,1337.7 2054.9,1337.92
  		2054.6,1338.27 2054.35,1338.35 2053.91,1338.39 2053.2,1339 2052.76,1339.11 2052.75,1339.12 2053.04,1340.03 2053.06,1340.7
  		2053.29,1341.09 2054.07,1341.41 2054.19,1340.94 2054.49,1340.42 2054.81,1340.37 2055.62,1340.57 2056.46,1341.01
  		2056.9,1342.01 2057.24,1342.15 2057.86,1342.15 2059.44,1342.02 2059.83,1342.03 2060.55,1341.8 2061.27,1341.38 2061.47,1340.94
  		2061.64,1339.97 2061.79,1338.22 2062.15,1338.2 2063.15,1338.82 2063.36,1338.87 2063.57,1338.84 2063.92,1338.54
  		2064.34,1338.28 2064.66,1338.29 2065.82,1338 2066.44,1338.53 2066.83,1338.69 2067.04,1338.73 2067.37,1338.7 2067.91,1338.51
  		2068.4,1338.42 2068.8,1338.49 2069.09,1338.42 2069.36,1338.21 2069.58,1337.6 2069.76,1336.58 	"/>
    </>,
    RUS: <>
        <g>
            <polygon className="st0" points="2523.17,535.93 2523.58,534.94 2524.54,534 2522.92,534.05 2521.74,534.42 2520.47,535.02
  			2520.8,535.42 		"/>
            <polygon className="st0" points="2539.86,528.42 2541.84,528.71 2542.56,528.19 2543.5,526.87 2542.9,526.15 2542.46,525.99
  			2540.24,526.48 2539.54,526.17 2539.13,525.98 2538.28,526.43 2536.82,526.89 2536.21,527.66 2537.32,528.37 		"/>
            <polygon className="st0" points="2561.83,487.92 2563.63,487.83 2564.91,486.66 2565.03,486.19 2564.49,485.64 2561.67,485.81
  			2560.99,486.34 2560.5,487.15 2560.81,487.45 		"/>
            <polygon className="st0" points="2623.23,504.9 2623.43,504.16 2622.74,503.91 2620.54,503.43 2619.53,504.02 2616.46,503.63
  			2614.84,504.41 2613.04,503.72 2612.55,504.2 2612.46,505.01 2615.55,505.38 2616.95,505.96 2619.18,506.05 2620.79,506.63
  			2621.24,505.66 2621.21,505.06 2622.36,504.68 		"/>
            <polygon className="st0" points="2625.65,502.56 2625.29,502.74 2625.28,503.1 2624.49,504.26 2624.49,505.15 2624.61,505.34
  			2624.98,505.47 2625.45,505.28 2626.47,504.63 2627.13,503.48 2627.06,503.26 2626.6,502.77 		"/>
            <polygon className="st0" points="2504.37,541.31 2505.51,541.63 2506.33,541.06 2506.15,540.72 2505.49,540.1 2504.77,539.89
  			2503.57,540.09 2503.12,540.39 2503.56,540.92 		"/>
            <polygon className="st0" points="2512.23,541.91 2512.56,541.71 2512.94,541.76 2513.39,541.37 2513.98,541.08 2513.09,540.54
  			2510.03,540.05 2507.95,540.43 2507.23,541.05 2510.11,541.32 		"/>
            <polygon className="st0" points="2567.52,413.82 2578.02,414.8 2580.92,413.26 2580.17,412.41 2577.07,412.1 2568.31,411.81
  			2566.89,412.67 2567.05,413.11 		"/>
            <polygon className="st0" points="2598.08,439.18 2599.76,437.49 2597.05,436.79 2589.56,435.72 2586.03,434.78 2582.48,434.64
  			2579.74,434.7 2578.08,435.06 2576.74,436.01 2577.21,437.48 2579.32,438.82 2581.91,439.68 2585.86,441.71 2588.28,441.89
  			2590.33,441.7 2592.37,441.33 2594.3,440.7 2596.21,439.81 		"/>
            <polygon className="st0" points="2615.57,490.82 2616.29,491.36 2617.04,491.64 2619.03,491.15 2620.03,491.33 2620.4,491.07
  			2620.66,490.72 2621.83,489.93 2622.99,488.89 2622.99,488.45 2622.71,487.53 2617.03,489.46 2613.38,490.19 2612.11,490.86
  			2612.9,490.99 		"/>
            <polygon className="st0" points="2581.52,430.42 2583.66,430.82 2585.35,431.33 2586.27,432.18 2593.01,433.68 2598.51,435.47
  			2600.34,435.46 2604.18,434.18 2606.17,433.22 2606.98,433.28 2609.51,432.74 2617.05,432.23 2628.16,430.99 2629.2,430.37
  			2630.19,429.39 2629.1,429.01 2628.79,428.61 2627.65,426 2627.29,425.54 2626.9,425.24 2627.64,423.7 2628.55,422.94
  			2632.29,422.44 2632.98,422.03 2633.9,422.04 2634.01,420.76 2633.69,420.07 2632.6,419.51 2630.17,419.21 2624.62,416.91
  			2624.1,416.19 2623,415.47 2622.7,414.57 2622.23,414.09 2619.83,412.41 2618.89,412.23 2618.12,411.86 2617.43,410.87
  			2616.58,410.47 2611.18,410.66 2610.35,412.32 2608.47,413.29 2606.57,413.79 2604.58,413.93 2602.6,414.29 2600.48,414.91
  			2598.36,415.31 2597.18,415.29 2596.01,415.44 2593.54,416.3 2592.48,417.54 2591.01,418.19 2590.55,418.61 2589.71,419.84
  			2589.56,420.41 2591.08,420.65 2595.2,420.21 2592.83,421.95 2591.53,423.61 2589.44,425.28 2586.65,425.95 2583.7,426.37
  			2581.94,427.53 2580.56,428.7 		"/>
            <polygon className="st0" points="2475.91,419.79 2483.72,419.08 2486.4,418.69 2486.64,418.4 2487.1,417.51 2485.86,417.07
  			2481.87,416.56 2476.91,416.86 2476,417.59 2474.89,419.08 2475.14,419.34 		"/>
            <polygon className="st0" points="2358.82,500.03 2357.78,500.08 2356.74,500.3 2354.7,501.32 2352.64,502.16 2344.75,503.36
  			2340.08,504.63 2338.48,504.48 2335.86,504.74 2333.89,504.55 2330.1,503.68 2328.27,503.55 2325.17,503.84 2324.79,503.99
  			2323.76,504.7 2323.78,505.81 2323.93,506.66 2323.45,507.2 2322.99,507.51 2321.8,507.55 2321.2,506.94 2320.16,506.86
  			2317.4,507.08 2316.05,507.59 2315.35,509.02 2313.21,509.68 2309.55,510.35 2307.56,510.93 2306.58,510.96 2305.63,511.26
  			2303.7,512.6 2301.73,513.58 2298.7,514.56 2298.82,515.32 2298.99,515.77 2296.38,517.24 2295.75,518.13 2295.1,519.81
  			2294.89,520.07 2292.32,519.52 2290.52,519.34 2289.69,519.49 2288.47,519.89 2288.18,520.28 2288.35,521.15 2288.74,521.72
  			2286.16,524.2 2285.45,524.23 2284.64,523.52 2283.79,523.08 2282.73,522.7 2281.66,522.57 2280.69,523.01 2279.76,523.74
  			2279.85,524.33 2280.21,524.86 2281.34,525.8 2284.22,525.63 2284.97,526.32 2285.56,526.58 2283.2,527.59 2280.63,529.3
  			2278.49,531.64 2277.84,532.12 2278.08,532.75 2278.51,533.32 2279.27,533.57 2280.92,533.55 2282.52,534.32 2282.02,534.57
  			2276.45,535.32 2275.81,535.6 2273.13,539.47 2272.27,540.43 2271.52,540.98 2270.69,542 2269.93,543.23 2269.62,543.36
  			2267.77,543.62 2265.98,544.44 2264.21,545.49 2263.26,545.84 2262.52,546.41 2263.16,547.54 2266.24,550.08 2265.62,551.07
  			2267.04,553.19 2269.28,552.09 2270.99,551.59 2273,551.52 2275.3,552.52 2277.56,553.09 2281.65,553.27 2283.28,553.79
  			2284.99,554.06 2286.7,553.95 2289.48,552.94 2290.91,550.69 2293.66,548.96 2294.35,548.18 2293.69,546.74 2292.23,545.61
  			2292.42,545.23 2293.56,545.43 2294.87,546.26 2295.32,546.38 2296.15,546.36 2296.9,545.77 2297.13,545.02 2296.97,544.24
  			2296.34,542.99 2296.25,542.33 2301.92,540.43 2303.41,538.8 2303.81,537.77 2303.64,537.13 2302.94,535.56 2302.44,534.86
  			2302.71,534.28 2306.02,534.88 2306.97,534.5 2307.48,534.13 2307.86,533.39 2307.95,532.41 2308.16,531.49 2308.65,531.03
  			2311.65,532.36 2312.3,532.42 2312.97,531.97 2313.13,531.51 2312.82,530.99 2312.92,530.14 2314.9,530.16 2315.73,529.97
  			2316.92,529.29 2317.58,528.61 2318.75,527.96 2319.27,527.47 2318.88,526.77 2317.08,526.35 2317.38,525.73 2319.06,524.93
  			2319.55,524.86 2320.57,524.93 2321.11,524.7 2322.03,523.98 2322.93,523.09 2324.71,522.09 2325.57,521.09 2326.47,520.52
  			2327.57,520.58 2328.66,520.43 2332.45,518.59 2340.71,516.06 2342.99,515.59 2345.87,514.46 2346.88,514.39 2350.95,513.58
  			2355.01,512.4 2358.86,511.52 2362.38,510.4 2367.96,509.03 2371.12,507.93 2373.11,507.49 2375.08,506.87 2377.08,505.94
  			2380.45,504.62 2383.82,503.79 2384.3,503.29 2387.14,500.91 2390.01,498.74 2389.66,498.08 2389.93,497.22 2390.36,496.37
  			2390.11,495.44 2389.79,494.92 2388.32,493.49 2386.52,492.37 2382.58,491.36 2379.5,490.99 2378.51,491.06 2376.23,491.84
  			2372.57,492.55 2368.49,494.37 2366.12,495.69 2365.24,496.72 2364.43,498.03 2363.52,498.63 2362.53,498.64 2361.61,498.83
  			2359.77,499.71 		"/>
            <polygon className="st0" points="2369.29,593.91 2369.11,594.14 2368.91,594.6 2369.02,596.31 2369.36,597.15 2369.93,597.85
  			2370.37,598.06 2370.31,597.44 2369.44,595.04 2369.48,593.96 		"/>
            <polygon className="st0" points="2474.07,562.87 2478.71,563.52 2479.3,563.34 2479.61,562.58 2479.64,560.18 2478.55,559.14
  			2478.08,558.25 2476.46,557.35 2472.19,560.61 2471.99,561.27 2472.47,562.03 		"/>
            <polygon className="st0" points="2311.99,609.87 2312.73,610.09 2313.47,610.25 2314.37,610.23 2315.27,609.93 2316.87,610.37
  			2317.81,609.96 2318.76,609.8 2319.07,608.78 2319.1,607.4 2318.84,606.65 2318.36,606.24 2316.5,605.32 2314.68,604.03
  			2311.99,602.69 2311.08,601.9 2310.22,600.96 2309.24,600.19 2307.37,599.04 2307.03,598.68 2306.67,598.61 2304.9,599.1
  			2303.92,600.22 2303.39,600.35 2302.58,600.85 2302.19,601.63 2302.99,603.31 2303.31,603.72 2303.55,604.34 2303.93,604.9
  			2306.23,607.29 2306.66,607.43 2307.32,607.01 2307.84,606.85 2308.89,607.2 2309.84,607.32 2310.86,607.69 2311.52,608.82
  			2311.5,609.61 		"/>
            <polygon className="st0" points="2279.04,433.63 2279.77,433.97 2283.05,434.18 2289.67,434.27 2290.46,433.82 2290.4,432.96
  			2290.78,431.9 2290.82,429.51 2290.45,428.85 2289.32,428.55 2287.32,428.61 2286.88,429.25 2281.57,429.03 2281.27,429.45
  			2281.46,431.71 2280.87,432.49 		"/>
            <polygon className="st0" points="2274.58,431.26 2276.98,430.35 2276.6,429.79 2275.91,429.49 2274.96,429.34 2273.71,429.92
  			2272.77,430.69 2273.35,431.23 		"/>
            <polygon className="st0" points="2400.27,557.96 2401.79,558.15 2402.48,558.1 2404.95,557.34 2406.74,556.94 2410.69,556.25
  			2412.97,556.06 2413.01,555.24 2412.66,554.29 2411.45,553.33 2410.66,552.84 2409.65,551.61 2408.89,551.12 2407.89,550.69
  			2407.19,550.53 2402.22,551.13 2400.54,551.66 2399.24,553.05 2399.43,555.25 2399.15,556.14 2398.69,556.83 2398.6,557.51
  			2399.62,558.27 		"/>
            <polygon className="st0" points="2461.42,565.85 2460.43,567.11 2459.49,567.86 2457.42,569.03 2457.15,569.8 2457.44,570.11
  			2459.46,570.36 2463.56,570.21 2464.81,569.93 2466.72,568.6 2467.96,568.21 2469.73,567.17 2469.63,566.82 2469.01,566.04
  			2464.54,564.79 2463.11,564.8 		"/>
            <polygon className="st0" points="2451.1,443.73 2453.66,444.37 2455.17,444.39 2455.28,445.36 2456.63,445.43 2463.19,445.2
  			2462.86,444.77 2461.27,444.15 2453.75,442.5 2452.95,443.04 2451.91,442.35 2451.06,442.1 2450.24,442.47 2450.42,442.84 		"/>
            <polygon className="st0"
                     points="2450.99,549.88 2450.51,550.38 2451.79,551.15 2455.36,551.75 2456.18,551.65 2451.92,549.86 		"/>
            <polygon className="st0" points="2448.35,551.43 2448.98,551.07 2450.24,549.96 2450.14,549.79 2449.22,549.56 2446.19,550.1
  			2444.55,551.13 2444.29,551.87 2445.63,551.47 		"/>
            <polygon className="st0" points="2436.63,560.36 2437.92,560.79 2438.34,561.07 2438.53,560.91 2438.84,560.36 2438.53,560.01
  			2438.42,559.37 2439.22,558.34 2441.06,557.86 2439.07,557.13 2438.02,556.91 2436.41,556.77 2434.64,557.11 2434.16,557.67
  			2433.81,558.52 2434.49,559.27 		"/>
            <polygon className="st0" points="2986.2,551.97 2987.04,553.12 2988.22,553.12 2989.08,553.02 2992.16,551.82 2994.43,551.55
  			2995.2,551.64 2998.81,552.57 3002.3,553.84 3006.76,554.32 3008.58,554.78 3010.64,554.78 3012.78,554.91 3015.74,555.3
  			3017.92,555.13 3018.26,554.89 3018.02,551.43 3017.58,550.42 3017.01,549.64 3011.08,545.8 3009.36,545.01 3007.25,544.28
  			3005.12,543.96 3003.02,544.14 2999.9,544.67 2998.87,544.6 2997.99,544.77 2997.13,545.33 2996.29,546.22 2994.72,548.65
  			2993.85,549.71 2992.06,551.04 2990.16,551.46 2988.18,551.6 		"/>
            <polygon className="st0"
                     points="2629.3,496.69 2629.56,497.76 2630.31,498.43 2631.19,498.54 2631.64,498.28 2629.9,496.39 		"/>
            <polygon className="st0" points="3047.17,524.8 3049.02,525.88 3049.97,526.12 3053.09,526.55 3054.05,527.01 3055.99,528.27
  			3057.01,528.8 3058.73,529.22 3065.07,529.69 3069.68,529.69 3071.71,529.31 3075.86,528.11 3077.96,527.23 3078.52,526.79
  			3080,523.21 3079.45,523.11 3078.89,523.23 3078.24,523.82 3077.54,524.17 3076.59,523.58 3075.44,523.08 3073.95,522.14
  			3070.09,521.71 3065.35,521.41 3063.74,521.99 3061.2,521.85 3060.23,521.24 3060.35,520.61 3060.6,520.15 3060.52,519.28
  			3059.87,518.83 3051.99,518.37 3051.54,518.41 3048.32,519.67 3046.08,519.56 3045.69,518.57 3045.71,517.18 3043.91,515.95
  			3043.08,516.36 3042.27,517.68 3041.55,519.17 3040.95,520.84 3040.64,522.5 3045.31,523.92 		"/>
            <polygon className="st0" points="3066.38,496.91 3066.91,495.67 3068.08,495.06 3062.29,495.69 3060,496.91 3059.59,497.42
  			3065.92,497.21 		"/>
            <polygon className="st0" points="3097.38,506.63 3097.19,505.91 3096.95,505.53 3096.65,505.36 3095.33,505.71 3094.62,506.25
  			3096.54,507.26 		"/>
            <polygon className="st0" points="2299.81,414.19 2299.07,413.8 2298.62,413.71 2295.31,415.44 2293.24,415.14 2291.56,415.73
  			2293.2,417.75 2296.1,418.27 2297.68,420.16 2300.61,420.73 2303.61,420.67 2305.45,420.44 2306.04,419.41 2305.81,418.1
  			2305.07,417.39 2304.62,416.25 2303.45,415.24 2302.48,414.84 		"/>
            <polygon className="st0" points="2992.3,543.85 2993.12,543.9 2997.36,542.57 2997.67,541.73 2998.09,539.78 2997.94,539.1
  			2997.6,538.54 2996.8,538.18 2996,538.03 2992.28,538.15 2991.38,538.3 2990.48,538.64 2989.71,539.51 2990.39,542.48 		"/>
            <polygon className="st0" points="2970.46,581.28 2965.96,581.41 2965,581.76 2964.67,581.78 2964.27,581.99 2964.12,582.19
  			2964.25,582.62 2965.82,583.34 2966.48,583.27 2966.98,583.01 2967.44,582.91 2967.89,583.05 2968.74,583.68 2969.58,583.85
  			2971.67,582.53 2971.45,581.99 2970.81,581.36 		"/>
            <polygon className="st0" points="3162.79,593.4 3163.35,593.14 3162.73,592.2 3162.07,591.58 3161.08,591.42 3161.01,591.6
  			3160.98,592.7 3161.55,593.18 		"/>
            <polygon className="st0" points="3166.78,617.69 3166.87,618.98 3167.26,619.41 3168.79,619.96 3169.61,620.68 3169.56,621.12
  			3169.28,622.1 3169.66,622.08 3169.99,621.68 3170.08,621.06 3169.89,620.38 3169.04,619.04 3168.9,617.73 3168.86,616.27
  			3168.68,615.13 3168.88,614.47 3170.27,614.13 3170.85,613.17 3170.93,611.8 3170.11,611.17 3169.98,611.09 3169.17,611.75
  			3168.45,612.57 3166.65,613.63 3166.42,614.59 3167.11,615.66 		"/>
            <polygon className="st0" points="3224.62,609.05 3225.9,609.98 3227.61,610.72 3232.39,612.11 3234.79,611.98 3235.17,611.67
  			3235.32,611.25 3235.62,609.66 3235.9,609.14 3236.62,609 3236.75,608.2 3236.65,607.84 3236.26,607.44 3227.69,605.43
  			3226.33,605.54 3225.18,606.05 3223.54,607.16 3223.11,607.58 3222.9,608.13 3223.17,608.39 		"/>
            <polygon className="st0" points="2963.63,521.87 2965.42,523.76 2967.35,524.94 2968.37,525.17 2969.33,525.71 2971.29,528.04
  			2972.02,528.78 2972.78,529.27 2979.3,530.9 2980.27,531.35 2981.26,531.64 2982.24,531.59 2983.17,531.13 2984.05,530.08
  			2984.74,528.59 2985.04,527.48 2985.53,526.78 2986.17,526.47 2986.81,526.47 2988.94,527.63 2991.11,528.44 2992.76,528.29
  			2994.41,527.85 2999.89,527.16 3001.74,526.75 3003.58,526.15 3005.59,526.01 3006.54,526.69 3007.25,527.55 3008.12,528.39
  			3008.88,528.75 3009.68,528.89 3010.97,528.6 3012.25,528.09 3015.19,526.36 3013.52,524.8 3011.56,524.11 3010.89,523.6
  			3007.92,519.98 3007.37,519.19 3006.97,518.08 3006.42,514.6 3008.29,514.07 3010.34,513.56 3013.63,513.7 3014.14,514.61
  			3014,515.07 3013,516.13 3011.88,516.59 3011.59,518.23 3011.84,520.13 3012.6,521.32 3013.46,522.17 3015.55,523.88
  			3017.45,524.46 3019.39,524.44 3022.71,525.1 3024.36,524.86 3025.97,524.13 3029.98,521.3 3029.4,520.35 3028.66,519.65
  			3029.3,518.79 3031.16,517.53 3033.99,516.83 3033.57,516.26 3033.11,515.89 3019.89,511.1 3018.83,511.16 3016.74,511.82
  			3015.68,511.97 3013.5,511.74 3011.33,511.11 3009.57,510.41 3005.71,507.99 3003.52,506.87 3001.36,506.36 2999.79,507.64
  			2997.55,508.94 2997.15,509.36 2996.85,510 2996.65,511.05 2996.65,512.22 2996.77,513.07 2996.8,513.92 2996.34,514.75
  			2995.72,515.11 2994.38,515.06 2993.92,514.89 2993.45,514.56 2993.03,514.1 2992.13,512.27 2991.16,511.82 2990.13,512.03
  			2989.26,511.7 2986.69,509.56 2984.88,508.51 2982.21,507.34 2981.35,506.86 2980.58,505.97 2979.75,505.33 2978.86,505.28
  			2975.63,506.49 2973.75,506.75 2972.81,507.35 2972.33,507.93 2971.81,508.26 2970.1,508.47 2968.85,508.95 2968.3,509.52
  			2967.8,510.31 2968.58,511.8 2969.53,512.9 2966.6,512.52 2965.85,513.07 2965.39,516.42 2966.02,519.94 2964.98,519.98
  			2963.43,519.66 2963.14,520.33 2963.26,521.27 		"/>
            <polygon className="st0" points="2715.91,485.3 2715,485 2713.99,485.01 2713.18,486.04 2713.45,486.89 2714.4,486.87 2716.63,486.41
  			2716.5,485.84 2716.21,485.49 		"/>
            <polygon className="st0" points="2704.76,469.41 2706.46,468.7 2706.74,468.39 2708.21,468.33 2708.54,468.33 2708.55,467.79
  			2708.31,467.38 2707.88,467.02 2706.33,466.95 2705.44,468.19 2702.98,468.33 2702.68,469.15 2703.76,469.55 		"/>
            <polygon className="st0" points="2654.16,461.56 2654.12,462.63 2653.76,463.33 2653.32,463.92 2652.99,464.54 2652.43,466.2
  			2652.11,466.97 2649.24,468.91 2648.12,469.92 2647.22,471.65 2645.95,472.49 2646.83,473.17 2647.74,473.62 2650.64,473.97
  			2652.64,473.64 2656.51,472.31 2660.71,470.57 2662.09,469.67 2666.2,469.63 2670.32,469.42 2673.99,469.07 2674.46,469.49
  			2674.98,469.71 2675.51,469.74 2677.24,468.5 2683.28,468.45 2688.15,467.04 2690.01,466.78 2691.89,466.95 2692.65,466.71
  			2696.7,464 2696.95,462.25 2696.68,460.91 2696.22,459.67 2695.79,458.79 2695.29,458.07 2694.19,457.61 2693.06,457.4
  			2690.97,457.76 2689.45,456.93 2689.66,456.12 2689.42,455.36 2689.04,455.11 2686.41,454.43 2685.67,453.5 2685.01,452.36
  			2683.96,451.87 2682.88,451.86 2680.87,452.31 2678.89,453.34 2677.85,453.62 2676.8,453.63 2675.91,454.41 2675.09,455.62
  			2673.74,457.09 2672.27,457.76 2672.57,456.99 2675.08,452.69 2675.43,451.36 2676.25,449.9 2676.71,449.57 2677.66,449.36
  			2678.04,449.04 2677.57,448.43 2675.45,447.28 2672.21,446.5 2671.17,446.56 2670.69,446.89 2670.32,447.64 2670.29,448.79
  			2670.91,449.86 2669.88,449.93 2668.84,449.71 2668.05,448.81 2667.31,447.7 2666.78,447.66 2665.79,447.87 2665.35,448.07
  			2665.05,448.79 2664.95,449.89 2662.99,450.3 2662.02,450.83 2661.63,451.73 2660.81,452.36 2660.95,452.87 2660.63,454.24
  			2660.08,454.56 2659.54,455.05 2659.23,456.07 2659.16,456.59 2659.51,458.19 2660,458.64 2659.32,458.73 2657.17,458.47
  			2656.29,458.65 2655.46,459.29 2654.34,460.67 		"/>
            <polygon className="st0" points="2631.63,432.4 2627.7,432.69 2621.77,433.64 2620.6,433.53 2619.63,433.78 2617.06,433.53
  			2614.03,433.61 2613.13,434.25 2612.68,434.84 2612.21,435.06 2609.73,433.78 2609.39,433.93 2608.28,434.98 2607.47,435.44
  			2604.34,436.77 2603.58,438.93 2601.74,440.35 2600.13,441.39 2596.4,442.72 2593.59,445.32 2595.29,446.02 2597.02,445.94
  			2599.38,446.16 2603.26,447.09 2604.06,448.89 2605.48,450.57 2606.63,451.06 2606.74,452.03 2606.91,452.28 2608.08,453.05
  			2610.01,453.69 2610.96,453.75 2613.52,452.81 2614.31,452.84 2615.76,454.45 2616.55,454.65 2621.19,454.38 2621.88,454.62
  			2625.06,454.96 2625.6,455.36 2626.12,455.92 2628.77,457.15 2631.36,457.92 2632.48,457.91 2634.31,458.23 2635.56,458.03
  			2637.48,458.51 2638.57,458.65 2642.01,458.08 2647.23,457.78 2648.08,457.44 2651.35,455.4 2651.1,454.56 2650.39,452.88
  			2649.85,452.66 2647.88,452.23 2646.2,450.4 2643.88,449.16 2644.41,448.93 2644.93,448.91 2646.79,449.5 2648.05,449.47
  			2649.26,448.59 2649.61,447.42 2649.48,445.93 2649.6,445.39 2649.83,444.94 2650.02,443.94 2650.11,442.79 2650.31,442.31
  			2650.6,442.01 2651.24,440.68 2652.47,439.94 2651.5,438.56 2650.42,437.61 2649.65,437.2 2648.04,436.78 2647.51,436.23
  			2646.65,435.92 2646.01,435.34 2642.4,434.78 2640.13,434.64 2639.58,434.81 2639.08,435.48 2639.31,436.56 2638.08,437.88
  			2637.41,438.08 2635.65,437.56 2634.02,438.49 2632.79,439.86 2632.17,440.26 2631.66,439.98 2631.96,438.53 2633.08,437.66
  			2633.49,436.5 2634.32,435.58 2635.25,435.21 2634.29,433.82 2632.36,432.59 		"/>
            <polygon className="st0" points="2651.46,441.96 2651.24,443.3 2653.09,443.06 2654.48,441.99 2653.14,441.73 2652.53,441.4
  			2651.58,441.6 		"/>
            <polygon className="st0" points="2820.47,558.15 2822.6,557.42 2822.39,557.14 2821.06,556.34 2820.1,556.16 2818.39,556.36
  			2817.37,556.86 2818.65,558.14 		"/>
            <polygon className="st0" points="2858.15,545.07 2858.66,545.03 2859.59,544.41 2859.45,544.2 2858.7,543.65 2857.71,543.5
  			2856.92,543.74 2856.92,544.04 2857.18,544.63 		"/>
            <polygon className="st0" points="2950.52,539.59 2952.07,540.55 2952.76,541.58 2955.59,543.73 2956.18,544.46 2956.82,543.98
  			2957.34,542.81 2955.47,541.07 2952.03,538.92 2950,538.37 2950.13,539.22 		"/>
            <polygon className="st0" points="2705.98,470.63 2710.92,471.43 2713.8,471.38 2714.96,472.13 2716.01,471.67 2716.77,470.79
  			2715.74,469.79 2715.19,469.72 2707.4,470.12 		"/>
            <polygon className="st0" points="2754.32,499.16 2755.52,499.36 2756.35,500.31 2757.5,500.9 2757.86,500.86 2757.96,500.32
  			2758.19,500.04 2758.59,499.86 2758.35,499.1 2757.05,497.9 2756.34,497.5 2755.39,497.95 2753.11,497.69 2752.76,497.81
  			2753.04,498.22 		"/>
            <polygon className="st0" points="2954.73,518.9 2955.33,518.41 2954.87,517.59 2955.02,516.98 2956.58,515.55 2956.23,515.2
  			2954.36,514.02 2953.89,513.42 2953.38,512.23 2952.62,511.42 2951.91,512.78 2951.61,513.75 2951.46,515.01 2951.73,516.04
  			2951.15,517.43 2950.72,517.98 2950.54,519.24 2953.02,519.37 		"/>
            <polygon className="st0" points="2496.8,520.24 2497.44,520.96 2497.8,521.1 2499.17,520.49 2499.55,521.4 2500.16,521.67
  			2500.77,520.07 2501.86,520.1 2502.21,519.91 2502.1,519.29 2501.8,518.73 2501.74,517.09 2500.53,517.12 2499.61,517.39
  			2499.59,518.04 2499.73,518.89 2499.02,518.94 2497.92,518.19 2496.14,519.61 2496.4,520.09 		"/>
            <polygon className="st0" points="3107.83,872.48 3107.54,873.27 3106.7,873.91 3106.77,874.09 3107.13,874.28 3107.45,873.72
  			3108.49,873 3108.69,872.62 3108.44,872.48 		"/>
            <polygon className="st0" points="3126.78,851.01 3125.4,851.87 3125.02,852.45 3125.26,852.78 3126.35,853.48 3126.69,853.54
  			3127.02,853.28 3127.68,852.24 3127.72,851.22 3127.45,851.04 		"/>
            <polygon className="st0"
                     points="3098.76,884.42 3098.21,885.16 3098.38,885.31 3098.8,885.39 3099.19,884.78 3099.01,884.29 		"/>
            <polygon className="st0" points="3119.85,851.58 3120.62,851.46 3120.69,851.2 3120.57,850.47 3119.98,850.21 3119.12,850.44
  			3119.26,850.93 3119.51,851.29 		"/>
            <polygon className="st0" points="3123.62,852.18 3122.64,852.98 3121.7,855.21 3120.92,856.12 3118.84,856.47 3117.94,857.3
  			3117.03,857.25 3116.83,857.62 3117.23,858.19 3117.23,859.49 3117.62,859.86 3118.53,860.07 3118.97,859.67 3119.54,858.93
  			3120.3,858.58 3121.86,858.31 3122.95,857.2 3123.98,855.55 3124.46,854.37 3124.64,853.13 3124.43,852.01 		"/>
            <polygon className="st0" points="3091.32,891.74 3090.26,892.75 3088.78,894.3 3087.52,894.47 3087.59,894.73 3087.85,895.16
  			3088.37,895.17 3089.94,894 3092.35,891.4 3091.9,891.39 		"/>
            <polygon className="st0" points="3111.93,867.31 3111.91,868.25 3112.79,868.54 3113.59,868.05 3113.75,867.66 3113.52,866.35
  			3114.2,865.28 3114.34,864.58 3113.71,864.4 		"/>
            <polygon className="st0" points="3041.13,919.12 3040.33,919.09 3038.88,921.44 3038.46,921.69 3038.14,922.26 3037.48,922.92
  			3037.27,923.51 3036.57,924.26 3034.85,925.58 3034.55,926.2 3034.66,926.95 3035.64,927.69 3035.9,925.84 3037.42,924.86
  			3038.44,923.76 3038.66,923.18 3040.33,921.72 3040.84,921.48 3041.88,921.36 3043.06,920.38 3043.73,920.39 3044.17,919.71
  			3042.38,919.87 		"/>
            <polygon className="st0" points="3046.33,925.68 3044.62,926.17 3044.55,926.34 3044.51,926.92 3045.14,927.16 3045.4,926.88
  			3046.96,926.26 3046.83,926.01 		"/>
            <polygon className="st0" points="3075.68,901.36 3072.76,903.24 3071.36,904.77 3070.26,905.15 3068.42,907.73 3069.19,907.75
  			3070.44,907.22 3072.09,905.74 3072.69,905.33 3073.55,905.05 3074.72,904.17 3075.05,903.34 3077.73,901.28 3076.01,901.22 		"/>
            <polygon className="st0" points="3063.18,909.16 3063.19,908.85 3063.07,908.6 3062.74,908.43 3062.18,908.49 3061.39,908.86
  			3058.96,910.97 3057.33,911.23 3056.7,911.19 3055.93,909.98 3055.59,909.92 3055.16,910.79 3055.27,911.57 3054.29,911.93
  			3053.35,912.95 3052.51,913.26 3051.43,914.48 3049.88,915.41 3049.11,916.34 3048.99,917.4 3047.59,918.41 3047.25,918.96
  			3046.94,920.08 3048.64,918.77 3049.55,918.54 3050.42,917.26 3052.55,915.62 3052.94,915.09 3053.04,914.49 3053.35,914.15
  			3054.41,914.34 3055.5,914.01 3056.27,913.18 3058.44,911.66 3059.72,911.34 3061.28,910.61 3062.89,910.54 3063.28,910.14
  			3062.99,909.61 		"/>
            <polygon className="st0" points="3078.69,753.69 3078.06,754.48 3077.03,755.01 3077.05,755.25 3077.38,755.59 3078.04,755.45
  			3079.2,754.5 3079.08,754.16 		"/>
            <polygon className="st0" points="2972.27,803.24 2971.5,802.78 2971.25,802.58 2968.45,801.56 2968.16,801.95 2967.25,803.68
  			2966.82,803.86 2965.9,805.14 2965.54,806.31 2965.91,806.29 2967.48,805.35 2968.01,805.9 2969.15,807.91 2969.66,807.8
  			2970.24,807.41 2970.91,806.79 2971.66,806.33 2971.93,805.96 2972.14,805.02 2972.82,803.97 2973.74,803.47 2973.45,803.16 		"/>
            <polygon className="st0" points="2376.23,614.04 2376.91,612.73 2376.78,612.1 2375.83,612.05 2374.23,613.43 2374.41,613.67
  			2374.83,613.97 		"/>
            <polygon className="st0" points="3195.81,752.94 3195.35,752.87 3192.69,754.49 3188.98,755.5 3189.02,756.03 3189.17,756.55
  			3188.69,758.18 3187.42,760.13 3186.17,760.89 3186.2,761.29 3186.33,761.56 3186.54,761.75 3187.92,760.59 3190.65,758.86
  			3193.34,757.69 3196.18,757.1 3196.56,756.05 3196.29,754.29 		"/>
            <polygon className="st0" points="2111.63,675.58 2111.36,675.04 2110.63,674.83 2109.62,675.38 2109.42,675.24 2108.94,675.46
  			2109.19,676.25 2109.72,676.73 2110.22,676.75 2111.05,677.86 2111.58,677.52 2111.46,677.04 2111.72,676.47 		"/>
            <polygon className="st0" points="2399.92,653.93 2398.58,653.71 2397.59,653.36 2396.04,653.32 2395.09,653.15 2394.81,653.65
  			2396.34,655.78 2397.97,656.84 2399.45,656.67 2399.77,656.45 2400.2,655.72 2399.76,655.3 2399.76,654.9 		"/>
            <polygon className="st0" points="2753.73,533.44 2753.08,533.81 2752.93,534.32 2752.88,534.99 2752.59,536.11 2752.01,536.53
  			2749.97,536.35 2749.4,536.45 2748.84,536.71 2750.01,538.04 2752.28,538.93 2753.9,539.86 2754.67,540.14 2759.61,540.99
  			2759.85,540.86 2761.25,539.3 2763.04,538.6 2763.62,538.05 2763.96,537.31 2764.42,536.71 2764.7,535.92 2763.85,535.24
  			2761.04,534.6 		"/>
            <polygon className="st0"
                     points="2213.31,908.06 2213.71,909.02 2213.84,908.83 2213.87,908.14 2213.54,907.17 2213.29,907.47 		"/>
            <polygon className="st0" points="3211.87,804.45 3211.24,803.8 3209.92,801.93 3209.77,801.02 3210.16,800.21 3209.62,800.06
  			3207.26,799.74 3206.41,800.26 3205.74,800.41 3207.77,801.63 3208.39,802.27 3208.53,802.97 3208.84,803.51 3209.77,804.61
  			3210.57,805.45 3211.05,805.76 3211.74,805.89 3212.22,806.58 3213.27,807.44 3213.31,805.75 3212.7,804.94 		"/>
            <polygon className="st0" points="3223.69,807.48 3222.24,806.55 3221.25,806.23 3220.57,805.54 3219.98,805.55 3220.37,806.26
  			3221.96,807.4 3225.36,809.55 3225.01,808.94 		"/>
            <polygon className="st0" points="2963.55,802.78 2961.86,803.76 2961.18,804.38 2961.6,804.5 2962.76,804.33 2963.32,804.75
  			2964.05,804.72 2965.09,802.69 2964.9,802.6 2964.24,802.79 		"/>
            <polygon className="st0" points="3411.85,661.71 3410.91,661.42 3407.92,661.34 3408.36,660.89 3408.19,660.4 3407.89,659.9
  			3407.44,659.59 3406.93,659.54 3405.9,659.82 3404.88,660.23 3405.4,659.64 3405.99,659.23 3405.68,658.9 3405.29,658.71
  			3402.17,656.28 3400.29,655.39 3398.52,654.2 3396.75,652.19 3394.85,650.59 3392.86,650 3390.82,650.11 3389.36,649.74
  			3387.9,649.24 3384.64,648.7 3384.69,649.14 3388.22,649.94 3388.75,650.3 3388.5,650.61 3387.73,650.69 3385.02,650.44
  			3384.21,650.25 3383.47,649.63 3383.08,649.71 3382.78,650.07 3382.89,650.88 3383.23,651.54 3382.53,651.89 3381.78,651.73
  			3381.82,650.91 3381.98,650.26 3382.82,649.12 3383.29,648.88 3383.38,648.64 3380.55,648.12 3379.77,647.73 3378.98,647.56
  			3377.26,648.1 3372.51,648.15 3371.65,648.33 3371.61,648.72 3371.91,648.92 3372.66,649.08 3373.41,649.05 3373.9,649.6
  			3374.98,649.88 3375.56,650.43 3375.94,651.39 3376.13,652.07 3376.24,652.76 3375.77,654.02 3375.11,654.54 3374.33,654.84
  			3374.63,655.56 3375.43,656.13 3376.95,656.39 3377.61,656.86 3378.19,657.62 3377.7,658.58 3377.13,659.36 3376.66,659.7
  			3376.33,660.27 3376.07,660.5 3375.74,660.5 3376.13,658.35 3375.57,657.08 3374.55,657.37 3374.12,657.7 3373.2,658.83
  			3372.97,658.89 3372.76,658.5 3372.46,657.68 3372.26,656.81 3372.04,656.16 3371.13,655.49 3370.6,655.24 3369.01,655.1
  			3368.49,654.96 3368.96,653.52 3369.79,652.68 3369.68,650.8 3369.3,650.17 3368.83,649.62 3368.38,648.3 3368.45,646.72
  			3368.96,645.79 3369.13,644.64 3368.55,643.8 3367.84,643.37 3367.31,643.72 3366.83,644.24 3366.56,644.41 3364.71,644.52
  			3365.9,643.24 3365.84,642.17 3365.62,641.52 3365.25,641.01 3364.95,639.91 3351.8,633.53 3349.57,632.72 3348.51,632.03
  			3347.58,631.7 3346.63,631.82 3346.05,632 3345.63,631.75 3346.02,631.17 3346.58,630.98 3345.26,629.98 3340.49,627.37
  			3339.45,627.01 3340.2,627.7 3343.76,629.76 3344.31,630.34 3343.25,631.09 3342.12,631.41 3342.44,630.57 3342.18,629.61
  			3341.77,629.07 3340.53,628.46 3338.6,627.95 3336.76,627.3 3336.39,626.61 3336.26,625.62 3337.43,626.46 3338.06,626.72
  			3336.79,625.4 3335.23,624.24 3331.82,623.2 3331.17,622.79 3330.21,621.91 3329.84,621.84 3329.16,622 3327.45,621.5
  			3325.75,620.86 3325.75,620.86 3324.64,620.43 3319.62,616.76 3316.91,616.23 3316.7,615.78 3316.54,615.24 3316.05,614.86
  			3312.63,613.88 3308.35,613.25 3303.8,611.51 3299.85,610.99 3295.52,609.16 3292.97,607.78 3291.4,607.25 3289.96,607.11
  			3286.13,607.78 3281.83,607.85 3277.91,607.45 3274.78,607.57 3272.97,607.31 3270.49,606.47 3269.77,606.82 3269.13,608.32
  			3267.27,607.71 3265.69,606.88 3263.08,606.15 3258.12,605.66 3252.02,604.52 3248.83,604.22 3245.62,604.04 3245.95,606.61
  			3245.76,607.83 3244.55,609.42 3243.22,610.44 3242.88,611.29 3246.43,611.93 3247.54,614.85 3248.97,616.7 3249.92,618.62
  			3249.91,619.94 3246.05,623.19 3242.08,623.59 3238.24,623.77 3236.59,621.8 3235.72,619.44 3232.65,618.19 3229.63,617.23
  			3228.24,617.06 3227.23,616.59 3226.62,613.97 3225.94,612.02 3225.08,611.3 3224.26,610.2 3223.47,609.76 3221.55,609.58
  			3216.87,612.37 3215.28,613.18 3214.74,613.19 3207.67,612.49 3205.82,611.92 3195.31,611.55 3192.33,609.9 3190.53,609.66
  			3188.51,610.16 3186.76,610.29 3184.26,609.96 3182.1,610.44 3177.31,610.95 3175.54,611.51 3173.68,612.51 3170.24,614.98
  			3169.77,616.16 3169.77,617.62 3170.49,619.67 3170.49,622.02 3169.89,622.83 3168.8,623.23 3167.66,625.72 3166.6,627.06
  			3164.51,627.41 3165.69,626.4 3166.81,625.72 3168.59,622.02 3168.33,620.88 3166.91,620.04 3166.02,619.16 3165.57,615.65
  			3165.12,613.8 3164.97,611.59 3163.53,610.86 3158.3,609.74 3155.89,608.91 3155.02,607.63 3155.94,605.82 3155.88,604.44
  			3156.37,603.27 3157.16,602.32 3157.35,600.98 3156.95,599.24 3156.56,597.99 3155.65,596.48 3155.01,595.79 3151.83,593.63
  			3146.37,591.41 3140.77,589.8 3135.8,589.26 3129.38,588.96 3122.73,588.93 3120.21,589.81 3115.44,589.88 3110.25,590.8
  			3105.03,592.26 3102.22,592.28 3096.64,592.94 3094.2,592.96 3087.92,590.68 3089.92,590.37 3090.7,590.05 3087.9,587.04
  			3086.4,585.97 3082.72,584.62 3081.23,584.52 3080,584.79 3075.12,586.28 3073.9,586.9 3076.32,585.16 3077.5,584.43
  			3078.7,583.35 3078.42,582.67 3078.13,582.34 3073.59,582.48 3073.3,582.32 3072.34,581.39 3071.87,581.07 3068.85,580.09
  			3066.66,579.72 3064.38,579.68 3064.01,579.3 3064.27,578.84 3064.36,578.54 3065.06,578.03 3067.01,577.56 3072.07,577.29
  			3073.22,576.46 3073.06,575.59 3072.76,574.94 3071.11,573.37 3068.88,572.22 3064.36,570.83 3059.62,569.88 3051.46,569.42
  			3050.01,569.63 3048.88,570.2 3046.92,571.69 3042.48,576.04 3040,577.83 3037.74,578.8 3032.55,579.59 3031.6,579.41
  			3030.88,578.69 3031.11,578.06 3031.58,577.48 3031.62,577.1 3031.36,576.37 3031.5,575.98 3032.02,575.96 3033.25,576.47
  			3034.39,576.54 3037.34,575.74 3037.34,574.49 3036.56,573.76 3036.94,572.01 3036.94,571.55 3037.35,571.24 3037.7,571.31
  			3039.82,572.57 3040.54,572.5 3041.32,572.64 3039.43,575.67 3040.34,575.68 3042.77,574.26 3044.43,572.86 3046.18,571.08
  			3046.39,570.14 3044.39,570.03 3031.29,570.71 3026.5,572.05 3025.02,571.77 3023.97,570.72 3025.58,570.62 3027.49,569.98
  			3029.08,568.76 3030.1,568.54 3032.76,568.6 3034.9,569.08 3041.36,569.28 3041.52,567.81 3040.09,567.34 3036.98,566.93
  			3035.05,566.22 3032.64,565.77 3027.33,565.13 3025.1,564.6 3019.85,564.12 3018.46,563.72 3006.21,563.36 3001.64,562.27
  			2999.88,561.16 2995.66,560.63 2994.81,560.64 2994.54,560.94 2994.34,561.4 2997.04,563.42 2997.13,564.81 2997.94,565.5
  			2994.79,566.59 2992.64,567 2985.49,566.95 2984.42,567.42 2981.61,569.6 2981.65,570.63 2981.91,572.23 2984.05,572.23
  			2984.68,571.53 2985.62,571.25 2989.98,571.5 2990.43,571.79 2988.97,572.25 2987.56,572.47 2985.82,574.84 2983.45,575.58
  			2985.08,575.97 2986.52,576.63 2986.28,579.52 2988.33,581.75 2988.72,582.79 2985.75,582.82 2983.12,583.52 2982.19,583.51
  			2980.47,581.78 2978.58,580.64 2977.65,580.55 2976.43,581.68 2974.68,581.05 2973.98,581.15 2973,581.62 2972.41,582.25
  			2971.97,583.22 2971.39,583.75 2971.32,584.46 2972.19,584.78 2972.82,584.86 2974.65,585.37 2972.77,585.65 2972.28,585.99
  			2972.11,586.37 2970.69,586.9 2971.18,587.41 2971.78,587.8 2971.93,588.2 2971.5,588.35 2970.3,587.87 2969.06,587.19
  			2967.1,585.78 2966.24,584.84 2964.56,583.97 2958.58,581.55 2955.92,580.79 2954.19,580.62 2951.45,580.93 2949.76,581.98
  			2946.93,582.42 2945.17,583.27 2944.23,584.42 2939.18,584.54 2935.69,583.68 2933.48,582.79 2930.99,580.99 2928.54,578.66
  			2928.24,578.47 2927.94,577.98 2927.5,576.84 2926.98,575.98 2926.21,576.46 2924.21,579.12 2923.39,580.42 2922.3,582.9
  			2921.5,584.99 2921.39,585.87 2921.77,586.63 2920.68,587.28 2919.52,588.84 2917.78,591.93 2916.69,593.05 2915.31,594.02
  			2914.37,594.37 2913.23,594.31 2912.19,593.43 2911.63,591.4 2911,590.99 2910.25,592.13 2909.15,592.06 2906.99,591.22
  			2904.84,589.4 2902.62,588.56 2899.49,584.14 2898.09,582.52 2897.33,581.2 2895.55,581.07 2894.88,580.1 2895.77,579.41
  			2898.17,578.83 2900.08,578.91 2898.66,577.17 2897.97,576.12 2897.23,575.55 2897.5,576.72 2897.18,577.57 2896.54,578.24
  			2895.46,578.66 2890.8,573.42 2888,570.86 2886.44,569.94 2885.47,568.27 2886.12,567.94 2889.44,569.92 2891.78,570.93
  			2895.65,573.56 2898.59,573.36 2899.66,572.19 2899.67,569.82 2898.57,567.88 2897.18,567.12 2892.41,566.95 2891.3,566.33
  			2892.06,566.13 2893.11,566.09 2894.65,565.52 2897.19,564.06 2898.31,563.61 2898.13,562.48 2896.35,560.93 2893.46,560.71
  			2892.83,560.56 2893.97,559.79 2894.97,559.31 2896.65,558.14 2897.05,557.06 2896.7,557.14 2895.47,557.09 2895.12,556.62
  			2895.26,555.8 2893.93,555.1 2892.72,554.63 2889.95,554.55 2890,554.01 2890.15,553.52 2888.97,553.17 2888,552.54
  			2887.75,551.98 2887.4,551.65 2885.59,551.06 2879.62,549.99 2878.98,550.3 2878,551.84 2875.59,553.45 2873.76,552.58
  			2873.4,552.49 2873.1,552.07 2873.54,551.36 2873.84,550.66 2873.43,550.17 2873.08,549.98 2871.84,550.48 2869.99,550.79
  			2869.2,551.28 2867.55,551.62 2867.71,550.43 2860.8,547.3 2858.65,546.65 2857.36,546.59 2854.25,547.29 2853.53,547.67
  			2852.38,548.69 2849.8,548.04 2849.17,548.53 2848.24,550.23 2848.39,551.89 2848.66,552.35 2848.9,553.25 2849.89,554.64
  			2850.91,555.75 2850.49,556.01 2850.06,556.08 2849.56,556.54 2849.08,557.49 2848.2,558.84 2847.02,559.6 2845.66,559.44
  			2842.43,558.42 2841.68,558.6 2841.47,559.34 2843.4,559.98 2843.58,560.38 2843.07,560.63 2841.77,560.84 2839.44,560.8
  			2837.5,560.53 2836.29,559.5 2835.12,559.36 2831.81,559.34 2828.8,559.89 2825.44,559.18 2819.74,559.33 2818.3,559.21
  			2815.56,557.84 2811.64,556.98 2807.18,554.89 2806.73,552.93 2807.4,551.34 2809.91,551.34 2811.44,551.07 2811.23,550.46
  			2810.89,550.14 2807.35,549.3 2797.73,549.14 2790.88,547.89 2781.13,547.45 2776.74,549.01 2770.37,549.38 2768.66,550.22
  			2765.74,550.68 2765.99,551.84 2767.43,552.74 2768.14,552.92 2768.91,553.27 2768.43,553.59 2767.89,553.73 2766.83,554.45
  			2766.15,555.11 2766.34,556.57 2766.01,558 2764.96,559.96 2764.07,560.83 2763.26,562 2763.95,563.07 2764.74,563.52
  			2766.75,564.06 2767.44,564.43 2767.04,564.74 2764.07,564.37 2763.01,563.21 2762.77,562.58 2762.52,561.6 2763.53,560.53
  			2764.55,559.81 2765.44,558.1 2765.55,556.53 2765.58,553.27 2763.78,552.53 2762.76,551.42 2764.51,549.41 2764.95,548.35
  			2764.2,547.37 2762.97,545.24 2761.72,543.98 2760.89,543.46 2760.06,543.19 2760.93,545.27 2760.23,546.33 2759.72,546.74
  			2756.39,547.31 2754.26,547.35 2751.37,546.75 2747.97,545.4 2747.12,544.46 2746.52,543.08 2748.47,542.48 2749.23,542.09
  			2747.47,541.78 2745.7,541.69 2745.07,543.56 2743.92,543.42 2738.38,542.27 2736.88,542.65 2735.07,543.7 2734.27,544.52
  			2733.36,545.85 2733.71,546.78 2734.09,547.12 2736.94,547.35 2739.45,547.07 2742.26,546.18 2742.91,546.51 2743.49,546.99
  			2742.69,547.67 2739.79,548.66 2734.96,551.21 2733.12,551.51 2730.54,550.97 2729.15,552.4 2728.51,552.74 2724.18,553.71
  			2722.29,553.85 2721.73,554.57 2720.6,554.7 2719.34,555.06 2717.23,556.07 2714.01,556.24 2711.82,556.01 2706.51,556.62
  			2705.14,557.15 2704.24,557.9 2703.83,558.84 2703.05,559.68 2700.03,561.5 2697.45,562.25 2695.27,562.46 2697.37,561.43
  			2699.77,559.53 2704.07,553.89 2708.21,553.52 2709.17,552.77 2712.31,549.3 2713.19,548.79 2717.35,548.72 2721.01,547.59
  			2728.38,542.03 2732.05,541.09 2734.58,539.76 2735.03,539.1 2735.43,538.23 2735.05,537.71 2734.83,537.23 2738.08,536.28
  			2739.32,534.83 2743.69,533.46 2747.12,531.61 2751.91,530.24 2754.64,528.34 2760.81,525.6 2766.61,520.89 2767.56,518.2
  			2766.15,517.32 2764.44,516.77 2763.49,515.45 2762.8,515.29 2761.07,516.12 2759.17,513.28 2756.84,511.68 2756.95,511.45
  			2757.2,511.34 2758.32,511.59 2762.51,513.95 2764.74,514.3 2765.39,514.67 2765.8,515.27 2765.54,516.26 2766.22,516.18
  			2766.66,515.77 2767.75,513.84 2768.78,511.24 2768.66,510.11 2766.19,510.62 2765.04,506.8 2764.52,505.64 2763.74,504.37
  			2762.17,504.26 2761.34,504.58 2762,505.01 2762.71,505.71 2762.23,506.44 2761.84,506.76 2759.92,507.73 2758.55,507.82
  			2759.1,507.41 2759.74,506.49 2759.27,505.5 2758.78,504.94 2758.24,502.07 2756.5,501.63 2755.52,501.17 2754.22,501.36
  			2752.54,502.12 2753.16,501.42 2753.81,500.37 2752.5,499.09 2751.22,498.21 2749.66,497.88 2747.9,496.74 2745.56,496.1
  			2740.15,495.48 2736.02,496.3 2730.86,495.64 2724.71,496.15 2722.3,496.16 2720.86,495.84 2719.56,496.18 2718.91,497.2
  			2718.51,498.8 2716.99,499.63 2716.17,499.85 2712.24,499.6 2709.44,500.38 2708.24,499.77 2705.97,499.81 2705.72,498.45
  			2707.08,498.51 2707.86,498.74 2710.41,495.97 2712.51,494.35 2714.53,492.5 2713.26,491.36 2710.42,490.58 2709.09,490.63
  			2708.42,490.95 2705.34,490.34 2703.71,490.39 2700.99,491.24 2700.06,491.17 2699.5,489.4 2696.76,489.55 2687.34,489.38
  			2688.37,488.83 2693.32,488.08 2697.3,486.95 2700.25,484.91 2702.99,484.22 2702.35,483.19 2701.6,482.45 2700.04,481.79
  			2696.66,481.36 2693.77,480.54 2692.5,479.5 2687.2,478.08 2685.76,478.09 2681.94,479.87 2680.01,479.7 2678.32,479.97
  			2673.93,482.09 2664.73,487.66 2662.84,489.39 2661.94,490.69 2660.29,491.36 2659.58,492.96 2659.7,494.34 2660.44,495.07
  			2661.21,496.44 2660.43,498 2659.77,499.03 2660.39,499.49 2662.17,499.4 2666.13,500.28 2665.41,501.09 2662.99,500.4
  			2660.88,500.43 2659.06,499.58 2654.67,500.39 2651.41,500.2 2648.38,500.53 2642.43,499.86 2641.89,500.37 2643.27,501.24
  			2644.32,502.05 2647.41,503.96 2648.72,504.57 2650.48,506.39 2650.7,509.95 2649.74,510.81 2648.66,512.01 2648.08,512.23
  			2647.25,512.15 2648.6,511.3 2649.33,509.5 2650.02,508.25 2649.11,507.39 2648.71,507.32 2648.27,506.85 2645.1,505.66
  			2643.4,505.14 2641.6,504.85 2640.68,504.53 2637.99,505.61 2636.74,505.86 2635.27,506.42 2634.42,507.21 2632.32,507.39
  			2632.05,508.24 2630.89,509.09 2629.64,508.17 2628.41,508.42 2625.67,509.94 2622.45,510.63 2622.79,510.1 2623.32,508.92
  			2622.54,508.65 2617.71,510.03 2616.1,510.95 2615.34,510.61 2618.14,508.58 2618.9,507.32 2617.59,506.78 2614.71,506.36
  			2612.86,506.32 2610.16,506.77 2606.26,506.11 2605.68,506.87 2604.69,506.96 2602.28,506.6 2600.09,506.99 2598.45,507.81
  			2596.02,507 2595.18,507.03 2593.88,508.3 2592.75,507.44 2591.8,509.1 2592.07,510.3 2592.88,510.43 2593.57,510.25
  			2593.97,509.7 2594.49,509.46 2596.41,510.45 2597,509.91 2597.83,509.5 2598.78,510.1 2602.73,509.45 2602.05,510.25
  			2597.62,511.27 2589.64,512.56 2588,513.07 2583.27,513.52 2580.19,514.79 2576.19,514.79 2569.28,515.79 2564.31,518.07
  			2561.92,517.87 2557.05,519.59 2555.12,520.93 2548.11,523.66 2543.9,522.61 2542.51,522.98 2541.8,523.95 2541.94,524.71
  			2543.64,524.64 2544.88,524.97 2546.4,525.63 2545.99,526.86 2542.81,529.59 2541.3,530.61 2539.52,531.21 2535.73,528.96
  			2535.04,529.95 2534.53,530.44 2533.03,530.24 2532.27,531.83 2535.01,532.11 2537.62,532.83 2539.93,533.88 2541.57,535.1
  			2543.35,535.87 2544.39,536.53 2541.6,537.17 2539.63,535.69 2538.57,535.19 2537.37,535.09 2535.58,535.54 2534.05,537.33
  			2535.53,537.93 2538.85,538.53 2539.91,539.33 2544.22,544.56 2545.3,545.1 2546.7,545.32 2547.27,545.68 2545.57,546.58
  			2544.94,547.42 2543.47,548.88 2537.2,549.64 2535.34,550.2 2534.81,550.45 2534.03,550.99 2533.52,551.47 2533.28,552.55
  			2533.79,553.25 2535.06,553.91 2540.06,556.84 2539.74,557.15 2538.37,556.6 2536.63,555.71 2534.86,554.46 2532.5,553.58
  			2532.37,552.85 2532.29,551.77 2532.35,551.43 2532.58,550.88 2534.83,549.48 2537.12,548.81 2539.91,547.22 2542.71,545.46
  			2542.13,544.85 2541.56,544.42 2539.02,544.3 2533.86,544.92 2530.76,545.5 2529.39,546.93 2527.3,547.14 2526.26,547.17
  			2523.41,546.46 2520.71,547.13 2514.39,547.72 2513.36,548.04 2498.8,548.17 2495.87,548.47 2488.41,549.65 2488.23,550.52
  			2488.52,551.19 2487.36,552.17 2486.85,553.09 2487.03,554.19 2487.08,555.13 2487.34,556.36 2487.79,557.48 2488.88,558.08
  			2489.88,558.46 2490.59,559.69 2490.02,561.11 2489.19,562.74 2489.03,563.5 2489.56,564.53 2490.22,566.57 2490.47,567.08
  			2492.75,568.64 2494.3,569.14 2496.86,569.25 2498.6,569.65 2501.13,570.62 2501.89,571.06 2502.11,571.48 2502.71,573.16
  			2503.03,573.68 2505.78,575.99 2506.7,576.34 2510.45,576.79 2511.64,577.53 2513.27,579.78 2513.43,580.31 2513.58,581.18
  			2513.4,581.97 2513.24,582.43 2512.62,583.16 2511.01,586.14 2510.04,588.81 2511.58,590.58 2513.64,594.01 2514.25,595.45
  			2514.97,597.37 2514.67,598.59 2514.33,599.32 2512.95,600.44 2511.24,600.8 2509.39,601.48 2509.56,602.32 2509.88,603.29
  			2509.69,604.01 2509.45,604.26 2508.44,604.33 2507.56,604.09 2506.81,603.34 2506.09,602.37 2506.6,601.33 2508.09,599.49
  			2509.02,596.85 2509.26,595.1 2509.2,593.25 2508.85,592.02 2507.67,591.1 2506.56,591.3 2505.34,592.1 2504.15,595.17
  			2502.52,597.41 2502.29,598.34 2502.33,599.14 2502.21,599.67 2501.88,597.9 2501.72,596.58 2502.63,594.92 2503.17,593.37
  			2503.01,592.26 2502.36,590.44 2502.49,589.54 2502.68,588.97 2503.06,586.39 2504.5,585.88 2508.08,583.9 2508.57,583.41
  			2509.66,581.68 2510.16,581.06 2510.74,580.03 2509.67,579.21 2508.65,578.77 2506.73,578.52 2504.95,578.61 2501.02,579.42
  			2497.5,579.28 2496.23,578.81 2490.71,575.28 2490.36,573.96 2489.92,573.41 2489.39,573.26 2487.49,572.4 2483.11,571.29
  			2478.63,568.79 2470.72,568.56 2468.55,568.84 2466.39,569.61 2464.41,571.19 2463.5,571.63 2462.2,571.78 2461.94,572.34
  			2461.69,573.11 2462.38,573.68 2464.81,573.01 2466.79,573.36 2467.84,574.11 2468.61,575.57 2468.23,576.28 2464.78,577.39
  			2462.87,577.3 2458.75,574.75 2457.15,574.29 2453.36,574.72 2450.85,575.97 2450.08,576.23 2450.68,577.51 2451.63,579.8
  			2452.44,581.16 2453.46,581.83 2457.15,583.49 2459.19,584.07 2462.28,585.59 2464.18,585.76 2465.88,585.39 2468.45,586.29
  			2469.91,589.06 2470.79,590.02 2471.6,590.5 2472.75,590.78 2473.42,590.82 2474.14,590.45 2475.78,590.38 2475.21,591.18
  			2474.59,591.43 2471.08,591.77 2469.36,591.48 2467.23,590.62 2463.2,587.81 2458.19,587.61 2457.63,588.43 2456.06,587.28
  			2450.74,587.03 2447.57,586.29 2444.18,585.12 2443.9,584.55 2443.75,583.74 2444.9,582.74 2445.33,582.12 2445.63,580.24
  			2443.47,577.75 2443.69,575.46 2444.71,575.08 2446.02,572.12 2446.81,571.14 2447.24,570.81 2447.63,570.13 2447.21,569.28
  			2446.81,568.76 2446.37,567.57 2446.47,566.69 2446.47,565.59 2445.39,563.93 2444.5,562.14 2442.67,561.24 2440.9,561.23
  			2440.25,561.47 2439.59,561.9 2440.52,563.53 2441.46,564.98 2441.9,566.11 2442.21,568.15 2442.15,570.65 2441.84,571.67
  			2441.32,572.53 2439.74,573.59 2437.08,574.86 2435.59,575.48 2432.46,576.16 2430.2,577.25 2425.27,583.51 2425.81,584.44
  			2427.62,585.46 2428.82,586.33 2429.4,587.06 2430.7,589.35 2435.58,595.73 2435.86,596.88 2434.71,598.91 2432.44,601.54
  			2431.53,603.01 2429.41,608.64 2429.26,610.08 2430.13,611.43 2431.56,613.12 2432.05,614.41 2431.08,617.68 2431.58,618.49
  			2432.78,618.92 2436.02,618.47 2439.83,619.27 2441.84,618.89 2444.93,617.08 2446.11,616.88 2449.82,617.13 2455.24,618.88
  			2461,621.23 2462.16,622.02 2463.71,622.05 2464.85,626.06 2465.87,628.23 2466.31,629.77 2466.61,631.49 2465.55,631.85
  			2464.61,632.03 2463.83,632.49 2462.75,635.15 2462.61,636.57 2463.19,638.85 2468.01,639.91 2471.36,640.47 2473.72,640.59
  			2474.13,640.85 2474.42,641.19 2471.62,641.35 2466.53,641.62 2464.73,641.47 2463.92,641.19 2463.11,640.41 2461.56,639.62
  			2460.97,639.08 2459.7,638.46 2460.33,636.12 2460.43,630.67 2460.24,628.42 2459.17,626.56 2456,623.91 2454.91,622.13
  			2453.68,620.94 2452.47,620.74 2450.72,620.97 2446.35,622.08 2442.44,622.66 2437.85,624.29 2437.02,625.64 2436.26,629.14
  			2437.27,630.85 2438.29,632.09 2439.22,634.19 2439.52,635.46 2439.6,636.74 2439.45,638.64 2438.66,639.68 2433.59,643.71
  			2432.85,644.95 2431.98,648.41 2431.21,649.69 2428.87,651.59 2427.42,652.36 2419.64,655.84 2419.35,656.61 2418.83,659.06
  			2416.69,660.16 2415.42,660.26 2412.46,659.04 2410.71,658.68 2408.92,658.57 2402.13,658.91 2399.13,658.09 2396.76,656.92
  			2394.32,656.55 2392.49,655.59 2392.06,654.71 2391.62,653.54 2391.28,652.94 2390.97,652.62 2391.52,652.23 2392.69,652.05
  			2397.09,652.25 2398.24,651.82 2398.84,652.03 2400.06,653.11 2401.66,654.08 2403.01,653.91 2404.15,653.11 2404.59,653.11
  			2405.09,653.23 2404.06,653.86 2403,654.32 2402.72,654.62 2402.5,655.25 2404,656.01 2405.38,656.42 2407.19,656.02
  			2408.25,655.22 2410.58,654.06 2412.24,654.11 2412.34,653.02 2411.48,651.34 2410.77,650.17 2413.33,650.48 2414.84,649.51
  			2421.13,641.23 2424.11,639.65 2425.11,638.63 2425.82,637.21 2426,635.65 2425.63,633.94 2425.72,632.63 2426.79,630.98
  			2428.46,628.99 2429.53,628.24 2429.37,627.5 2429.16,626.88 2426.15,624.94 2422.96,623.34 2421.83,622.46 2420.98,621.08
  			2420.56,619.42 2420.56,618.33 2420.81,614.99 2421.31,613.42 2421.17,608.78 2420.58,603.06 2420.07,601.51 2420.85,600.43
  			2421.62,599.55 2422.02,598.73 2422.28,593.13 2422.05,590.97 2421.02,588.07 2416.79,585.66 2415,583.32 2415.15,582.48
  			2415.38,581.91 2417.21,580.95 2418.47,579.6 2419.28,577.62 2420.96,574.62 2421.38,573.56 2422.46,569.38 2422.75,567.16
  			2422.96,563.83 2421.46,562.98 2419.88,562.24 2416.97,561.62 2415.53,561.77 2412.89,560.45 2411.91,560.26 2404.79,560.64
  			2400.73,560.46 2398.33,560.76 2397.07,560.72 2396.29,560.52 2396.4,559.97 2396.82,559.57 2396.7,559.23 2396.01,559.16
  			2394.15,559.59 2391.18,564.17 2389.42,568.61 2387.55,574.61 2386.38,577.13 2384.7,579.8 2382.09,581.9 2378.57,584.02
  			2376.32,585.02 2373.31,586.04 2372.05,588.25 2370.97,589.15 2371.42,589.76 2372.72,589.42 2371.98,590.99 2371.2,591.94
  			2371.27,592.49 2371.5,593.2 2372.51,593.53 2375.22,592.91 2375.79,593.51 2376.41,594.43 2376.09,598.08 2375.33,601.2
  			2375.24,602.34 2375.67,603.07 2376.02,604.04 2375.22,605.21 2374.59,605.58 2373.39,605.45 2372.6,608.05 2372.35,609.58
  			2372.36,610.8 2372.66,611.55 2373.14,612.38 2373.45,611.73 2373.71,610.85 2374.55,610.28 2379.26,611.92 2380.53,612.73
  			2382.48,613.48 2383.05,614.36 2383.42,617.11 2385.42,619.61 2387,621.1 2387.99,621.69 2388.86,621.84 2390.22,621.26
  			2392.04,621.34 2391.06,623.3 2389.42,626.99 2386.68,630.19 2385.56,630.69 2383.75,629.38 2380.16,627.77 2379.4,626.81
  			2375.26,624.25 2374.03,622.48 2371.96,622.21 2369.09,621.39 2368.67,621.19 2366.3,620.08 2364.01,619.47 2363.36,618.65
  			2361.61,618.04 2359.92,617.63 2357.43,616.61 2356.29,616.94 2356.56,615.78 2353.73,614.14 2350.34,612.66 2343.36,610.56
  			2337.21,609.54 2329.96,609.24 2323.61,607.91 2322.71,607.97 2321.9,608.36 2319.76,610.3 2317.38,610.9 2316.49,611.82
  			2317.89,613.82 2320.65,618.98 2322.28,618.46 2322.91,620.81 2321.92,622.16 2320.42,623.76 2319.17,624.61 2316.4,625.05
  			2314.17,624.95 2313.91,626.44 2314.56,627.82 2314.4,628.39 2314.19,629.12 2313.86,629.49 2313.6,629.72 2312.74,630.14
  			2311.72,630.15 2309.25,629.43 2307.46,628.79 2307.57,626.27 2308.5,625.17 2309.14,624.91 2309.75,624.47 2308.48,622.84
  			2307.56,622.15 2307.13,621.02 2307.11,620.52 2305.95,620.56 2301.19,621.85 2300.2,623.07 2299.66,622.24 2293.53,625.9
  			2290.85,627.18 2289.02,627 2286.58,626.23 2283.68,626.15 2281.73,625.79 2280.73,625.96 2278.63,626.86 2276.46,626.98
  			2274.21,628.27 2272.29,629.82 2271.77,632.33 2270.56,632.58 2269.24,631.29 2268.53,630.98 2267.83,631.26 2266.48,631.39
  			2265.35,631.49 2264.25,631.96 2263.79,631.9 2260.43,631.49 2259.49,631.53 2258.29,631.37 2258.56,630.73 2258.98,630.27
  			2260.87,629.92 2261.91,629.4 2263.08,629.69 2263.93,628.92 2263.82,627.43 2262.48,626.01 2263.12,624.91 2263.6,623.54
  			2263.92,623.39 2264.27,622.91 2263.84,622.52 2262.82,622.32 2262.81,621.98 2263.46,621.7 2267.69,621.14 2268.65,620.73
  			2266.08,620.57 2262.85,620.68 2259.57,621.91 2253.43,624.58 2250.57,626.39 2251.33,626.36 2252.3,626.62 2253.13,627.88
  			2253.76,628.21 2253.31,629.05 2251.67,629.7 2251.01,630.14 2250.39,630.32 2249.85,630.15 2249.22,629.81 2249.59,629.17
  			2250.07,628.57 2248.76,627.5 2248.14,627.37 2247.63,627.41 2244.44,628.32 2242.08,629.4 2239.91,629.97 2237.89,630.17
  			2236.72,630.64 2234.31,632.09 2232.79,632.71 2230.25,634.32 2223.71,637.14 2222.02,637.38 2221.06,637.15 2220.33,636.77
  			2219.84,637.08 2220.4,637.76 2221.38,639.15 2221,639.86 2219.49,639.67 2216.33,640.31 2212.93,641.27 2212.99,642.25
  			2213.21,643.13 2212.63,644.54 2212.03,645.69 2211.53,648.98 2211.08,649.96 2209.74,650.62 2202.95,652.1 2201.79,652.19
  			2201.28,652.46 2200.91,652.19 2199.64,651.85 2197.84,651.84 2197.02,651.71 2196.17,651.17 2195.05,649.77 2193.45,646.98
  			2189.88,645.56 2188.2,644.61 2187.89,643.72 2188.2,642.8 2191.86,639.76 2193.17,638.77 2198.6,637.89 2200.75,637.81
  			2202.95,637.45 2202.89,635.69 2200.75,633.53 2198.47,631.02 2196.23,628.27 2193.09,627.29 2189.37,626.83 2181.77,627.36
  			2180.7,627.26 2175.84,625.34 2175.35,625.31 2174.67,625.43 2174.88,625.98 2175.27,626.38 2181.72,630.5 2182.01,631.57
  			2182.2,633.02 2182.09,633.63 2182.24,634.23 2182.19,635.32 2180.6,640.02 2179.08,643.35 2178.57,644.92 2178.46,645.99
  			2179.07,646.93 2180.92,647.24 2182.75,648.2 2183.69,649.56 2183.91,650.51 2183.97,652.54 2184.41,654.27 2182.96,656.96
  			2182.15,658.01 2181.11,660.42 2181.51,662.13 2181.41,662.81 2181.17,663.59 2180.43,663.01 2179.82,662.33 2178.97,661.72
  			2178.08,661.49 2177.12,661.66 2176.43,661.98 2176.5,661.28 2177.37,660.2 2176.95,659.63 2176.51,659.21 2173.83,657.89
  			2171.92,657.81 2170.24,657.95 2168.52,657.79 2167.24,656.95 2166.09,656.49 2165.22,656.42 2164.15,657.18 2161.6,660.08
  			2159.03,661.98 2155.66,663.41 2153.12,663.88 2152.43,664.22 2150.92,665.89 2149.36,667.17 2145.06,669.3 2144.76,670.17
  			2144.49,671.37 2144.9,672.73 2145.73,674.04 2147.8,676.67 2148.97,677.56 2149.76,678.96 2150.35,680.56 2150.04,680.89
  			2148.32,680.49 2147.09,680.67 2145.33,681.77 2145.19,682.23 2144.56,683.31 2142.96,683.4 2138.63,681.45 2134.92,680.46
  			2134.31,680.39 2133.48,679.9 2133.23,679.49 2131.68,679.57 2130.73,679.53 2129.83,679.2 2125.78,676.05 2122.52,674.87
  			2121.76,674.85 2120.35,675.17 2119.53,677.71 2118.41,678.42 2117.41,678.38 2117.36,679.63 2117.78,680.4 2118.17,680.95
  			2119.39,681.84 2121.67,684.51 2122.88,685.6 2123.77,686.01 2124.95,686.07 2127.57,685.76 2128.44,686.17 2129.37,686.8
  			2129.56,688.33 2130.28,689.89 2130.35,690.77 2129.49,691.8 2126.68,692.55 2125.06,693.63 2124.47,693.58 2121.12,692.34
  			2118.92,691.86 2115.98,691.08 2115.45,690.66 2114.14,688.57 2111.24,686.59 2109.94,686.01 2108.13,686.44 2106.88,686.22
  			2104.79,685.17 2103.39,683.54 2103.29,681.55 2103.69,681.11 2104.09,680.87 2103.08,680.27 2103.03,678.73 2102.83,677.73
  			2101.72,675.23 2100.58,673.72 2099.49,672.1 2100.65,671.25 2101.25,670.52 2102.09,668.38 2102.25,667.66 2102.61,666.94
  			2102.75,666.27 2102.69,665.6 2101.89,664.38 2099.43,661.91 2097.02,660.56 2092.42,659.22 2091.15,659.29 2090.68,659.1
  			2091.66,658.85 2092.64,658.32 2093.17,657.51 2092,657.1 2091.06,656.92 2089.47,656.25 2089.18,655.66 2089.53,655.23
  			2089.16,654.16 2087.04,653.82 2086.48,653.57 2086.44,653.21 2085,652.04 2083.13,650.81 2083.44,649.57 2082.09,648.65
  			2080.92,648.01 2079.08,647.77 2078.34,647.32 2082.59,647.45 2087.06,648.38 2086.68,648.75 2086.36,649.31 2087.66,650.92
  			2088.91,651.84 2091.7,652.96 2092.05,652.96 2092.66,652.68 2094.04,653.15 2095.17,653.78 2097.3,653.83 2099.87,654.56
  			2099.69,654.87 2099.4,655.1 2100.13,655.99 2101.21,655.86 2103.01,655.13 2107.55,657.7 2108.81,658.16 2116.06,659.48
  			2119.4,659.6 2121.2,659.9 2123.82,660.56 2126.62,661.9 2128.92,662.37 2133.1,662.81 2135.26,662.74 2140.61,661.86
  			2147.47,659.51 2150.99,657.45 2156.61,652.09 2157.34,650.84 2158.01,647.89 2158.05,646.63 2157.23,646.51 2156.15,645.81
  			2156.15,644.11 2155.54,643.28 2154.74,639.4 2153.06,638.98 2152.13,638.53 2151.03,638.3 2149.81,637.69 2148.34,636.1
  			2146.9,635.04 2145.72,633.6 2144.99,633.07 2144.46,632.9 2144.83,633.63 2145.11,634.41 2142.97,634.22 2136.76,630.53
  			2135.7,630.24 2135.29,630.58 2133.38,630.09 2132.77,629.22 2127.49,625.15 2118.12,620.56 2111.71,617.77 2106.93,616.53
  			2106.45,616.68 2105.97,617.19 2104.57,617.33 2103.34,617.23 2099.04,616.12 2098,615.97 2093.41,616.01 2092.92,616.32
  			2091.31,618.68 2090.45,619.16 2088.83,619.6 2089.3,618.88 2090.4,618.36 2091.12,616.65 2091.16,615.94 2091.55,615
  			2091.47,614.25 2090.89,614.01 2089.8,614.26 2087.47,615.16 2087.15,614.92 2087.82,613.99 2087.64,613.63 2086.66,613.77
  			2084.58,613.34 2082.4,613.49 2082.01,612.37 2080.58,611.73 2079.99,611.2 2080.71,610.57 2085.57,611.6 2086.93,611.66
  			2087.6,611.29 2087.75,610.63 2087.71,609.85 2087.15,609.41 2083.98,608.59 2082.52,607.65 2079.09,606.37 2078.96,606.97
  			2079.48,608.15 2079.2,608.53 2078.21,608.2 2077.44,608.45 2076.41,609.87 2075.41,610.23 2074.61,610.34 2071.22,609.15
  			2069.7,608.93 2070.16,610.91 2070.15,611.6 2069.93,612.26 2069.63,612.61 2069.02,612.75 2067.56,612.69 2065.57,611.91
  			2064.29,611.18 2063.89,611.15 2063.72,611.24 2064.03,611.98 2063.95,612.54 2063.76,613.16 2063.49,613.71 2063.11,614.18
  			2062.33,614.79 2060.97,615.26 2057.22,616.19 2056.93,616.6 2055.72,619.18 2055.39,619.56 2054.95,619.88 2053.67,620.29
  			2053.1,620.47 2051.36,621.19 2050.3,621.68 2049.02,622.03 2049.35,622.5 2051.48,622.6 2051.8,622.74 2052.04,622.98
  			2052.08,623.36 2051.86,623.99 2049.57,627.42 2049.5,628.14 2050.25,630.15 2051.3,632.5 2054.48,633.55 2056.85,634.36
  			2058.37,636.29 2060.87,638.82 2062.2,639.76 2062.28,640.05 2061.88,641.79 2060.28,643.53 2058.77,644.99 2057.22,646.75
  			2056,648.25 2054.69,650.03 2054.53,650.61 2054.51,651.16 2054.74,651.76 2056.42,653.94 2057.08,655.05 2057.86,656.24
  			2058.54,657.55 2058.93,658.71 2059.6,659.85 2060.02,660.42 2060.72,661.23 2061.56,662.44 2061.84,663.39 2063.11,666.68
  			2063.24,667.52 2063.18,668.14 2062.62,668.29 2061.39,668.39 2060.05,668.79 2059.98,668.93 2060.85,669.7 2060.09,671.02
  			2059.97,672.91 2059.15,673.89 2059.07,674.12 2059.11,674.31 2059.26,674.46 2060.78,674.73 2060.91,674.99 2060.92,675.55
  			2060.78,676.06 2060.02,676.44 2059.2,677 2059.01,677.51 2059.04,677.97 2059.32,678.75 2059.86,679.65 2060.55,680.21
  			2062.99,680.75 2063.3,681.19 2063.44,681.8 2063.39,682.39 2062.25,683.57 2062.26,684.03 2062.73,685.13 2063.29,686.17
  			2065.67,687.31 2066.49,687.93 2066.7,688.42 2066.82,689.22 2066.81,690.08 2066.62,690.84 2065.87,691.83 2064.15,693.76
  			2062.41,694.51 2062.3,694.68 2062.84,695.29 2065.9,697.77 2067.9,698.92 2070.59,700.48 2072.32,701.7 2072.88,702.58
  			2073.63,703.56 2074.48,704.36 2075.09,705.06 2075.32,705.5 2075.3,705.98 2074.48,707.42 2074.02,708.53 2073.2,710.16
  			2072.37,711.3 2070.26,713.36 2067.14,715.92 2066.42,716.7 2064.96,718.04 2062.46,720.73 2061.81,721.33 2059.77,723.47
  			2058.83,724.15 2058.1,724.78 2056.07,726.8 2053.89,728.32 2051.76,729.74 2051.11,730.45 2050.32,731 2049.36,731.52
  			2048.96,731.81 2046.81,733.73 2043.83,736.4 2047.04,735.96 2049.85,734.61 2050.4,734.92 2051.01,735.45 2050.92,736.32
  			2050.77,736.97 2049.67,736.35 2049.93,737.08 2050.95,738.44 2052.38,739 2054.54,740.77 2057.07,740.96 2058.75,740.64
  			2060.03,740.72 2061.3,741.66 2062.18,742.85 2062.88,743.15 2063.83,743.72 2063.7,744.39 2063.41,744.78 2059.59,743.74
  			2055.19,743.19 2054.62,743.68 2054.07,744.42 2053.8,745.01 2053.51,745.34 2052.83,745.55 2051.83,745.62 2050.62,745.47
  			2049.9,745.08 2049.35,745.52 2049.1,746.53 2048.35,747.05 2047.32,746.65 2046.64,745.87 2046.02,745.93 2045.65,746.64
  			2045.92,747.62 2046.07,748.78 2045.64,749.65 2046.09,750.04 2046.65,750.67 2046.8,751.03 2046.61,751.24 2046.05,751.41
  			2045.92,751.61 2045.67,751.93 2045.01,751.99 2044.67,752.23 2044.26,753.29 2043.49,755.04 2042.74,755.91 2042.86,755.94
  			2043.41,756.77 2043.96,760.98 2044.02,762.05 2043.83,762.52 2043.41,762.72 2042.02,764.24 2041.84,764.84 2041.9,765.36
  			2042.09,765.86 2043.13,765.98 2044.4,765.87 2045.3,766.37 2046.35,767.45 2046.79,768.3 2046.96,769.04 2046.39,769.7
  			2045.46,769.6 2044.78,769.27 2044.48,768.97 2044.53,768.76 2043.87,768.11 2042.82,767.77 2042.52,767.8 2042.53,767.82
  			2042.78,768.79 2043.19,769.15 2043.58,769.41 2043.67,769.57 2043.65,769.74 2043.45,769.93 2041.67,770.44 2041.44,770.87
  			2041.25,771.35 2040.48,772.05 2040.24,772.71 2040.09,773.47 2040.07,773.74 2041.06,773.79 2041.41,773.98 2041.65,774.93
  			2042.77,775.68 2043.82,776.3 2044.09,776.58 2044.17,777.13 2044.1,777.78 2043.97,778.11 2043.53,778.49 2043.15,779.46
  			2043.1,780.39 2042.49,781.98 2042.63,782.01 2043.9,781.72 2044.26,781.89 2044.53,782.24 2044.62,783.23 2045.04,783.68
  			2045.46,784.38 2045.59,784.93 2046.4,785.57 2046.46,785.99 2046.96,787.47 2047.15,788.32 2047.23,788.98 2046.99,789.81
  			2046.78,790.38 2047.93,791.41 2048.2,791.45 2048.83,791.05 2048.96,791.02 2050.28,790.98 2050.9,791.34 2051.35,792.05
  			2051.77,792.61 2052.22,792.76 2053.51,792.05 2054.22,791.82 2054.69,791.83 2056.34,792.46 2057.11,792.8 2057.29,793.12
  			2057.31,793.49 2057.1,794.04 2056.93,794.63 2057.43,795.34 2058.02,795.81 2059.26,795.03 2059.72,794.81 2060.22,794.8
  			2060.89,794.51 2061.38,794.06 2061.85,793.91 2062.74,794.02 2064.34,793.91 2066.22,794.6 2066.38,794.82 2067.32,795.63
  			2067.65,796.03 2067.95,796.16 2068.45,796.55 2069.12,796.8 2069.59,796.72 2069.81,796.85 2070.01,797.17 2070.03,797.69
  			2069.96,799.2 2069.63,799.64 2069.29,799.99 2069.2,800.26 2069.24,800.6 2069.77,801.24 2070.45,802.25 2070.61,802.84
  			2070.61,803.27 2069.68,804.56 2069.37,804.86 2069.15,805.49 2069.04,806.13 2069.1,806.4 2070.67,807.42 2071.82,807.97
  			2072.08,808.24 2072.1,808.41 2071.49,809.5 2071.43,809.79 2072.35,810.25 2072.87,810.96 2073.32,812.12 2074.2,813.23
  			2076.09,814.21 2077.47,814.85 2077.76,815.14 2077.85,815.48 2077.75,816.24 2077.39,817.17 2077.15,817.68 2077.71,817.89
  			2079.16,817.84 2080.91,818.02 2083.01,819.03 2083.02,819.49 2082.8,819.9 2082.95,820.34 2083.18,820.71 2085,821.84
  			2085.17,822.17 2085.2,822.72 2085.15,823.12 2084.65,823.21 2084.09,823.4 2083.17,823.88 2082.81,824.56 2081.33,825.5
  			2080.42,825.93 2079.69,825.95 2077.96,825.76 2077.35,825.29 2077.09,824.86 2076.43,824.67 2075.54,824.66 2074.32,824.73
  			2074.07,824.86 2073.87,825.38 2073.35,826.27 2072.98,826.78 2073.28,827.08 2073.77,827.73 2074.53,828.54 2075.31,829.26
  			2075.56,829.71 2075.54,830.02 2075.17,830.39 2075.23,831.14 2075.99,832.13 2075.73,832.28 2075.65,833.49 2075.66,834.77
  			2075.87,835.08 2076.27,835.34 2076.62,835.81 2077.19,836.88 2077.23,837.16 2077.39,837.17 2078.17,837.5 2079,837.77
  			2079.57,837.79 2080.25,837.72 2081.05,837.36 2081.6,837.01 2082.28,835.23 2082.52,834.97 2082.89,834.83 2083.5,834.81
  			2084.66,835.15 2086.01,835.45 2086.8,835.4 2088.89,834.45 2090.06,834.31 2091.45,834.53 2092.81,834.54 2093.84,834.4
  			2094.54,834.73 2095.41,835.46 2096.2,836.54 2097.02,838.52 2099.42,840.76 2099.46,841.2 2099.26,841.48 2098.08,841.75
  			2097.09,841.9 2097.04,842.28 2097.3,842.7 2097.76,843.3 2097.83,844.08 2097.81,844.8 2098,845.43 2098.38,845.69
  			2098.43,846.01 2097.99,846.4 2097.87,846.64 2098.04,846.76 2100.2,846.84 2101.26,847.22 2102.07,847.56 2102.47,847.59
  			2103.38,847.37 2104.41,847.23 2105.03,847.21 2105.27,847.47 2105.46,848.13 2105.82,848.8 2106.16,848.99 2106.76,848.96
  			2107.12,848.99 2107.31,849.24 2107.09,849.62 2107.14,850.04 2107.4,850.54 2107.72,851.71 2108,852.06 2108.2,852.5
  			2108.2,853.01 2107.96,853.45 2107.79,853.79 2107.96,854.58 2108.6,855.46 2109.08,855.68 2109.47,856.47 2110.16,856.72
  			2111.19,856.06 2111.99,855.71 2112.98,855.91 2113.89,856.03 2114.51,856.48 2114.96,857.1 2115.49,857.44 2116.02,857.26
  			2117.12,857.44 2117.62,857.95 2118.13,858.23 2118.78,857.82 2119.3,857.32 2121.24,856.79 2122.44,856.66 2122.77,856.56
  			2123.48,856.18 2124.22,855.93 2124.9,856 2125.56,856.78 2126.24,857.32 2126.43,858.16 2127.26,859.33 2129.34,860.92
  			2130.15,861.41 2130.7,861.32 2130.99,861.19 2131.13,861.03 2131.25,860.25 2131.51,859.96 2131.93,859.95 2133.56,860.92
  			2134.4,861.03 2135.21,861.05 2136.3,861.8 2137.49,862.45 2138.41,862.52 2139.15,862.26 2139.65,862.11 2139.96,862.36
  			2140.25,862.92 2140.73,863.36 2141.28,863.48 2142.08,863.51 2143.46,864.36 2144.75,865.22 2145.56,865.26 2146.25,865.01
  			2146.86,864.95 2147.28,865.16 2147.4,865.54 2147.09,866.04 2147.09,866.75 2147.66,867.43 2147.68,868.11 2147.52,868.71
  			2147.19,869.27 2146.4,870.04 2145.67,870.75 2144.57,871.05 2143.96,871.36 2144.12,871.89 2144.52,872.37 2145.45,872.77
  			2146.51,873.06 2146.63,873.38 2146.47,873.54 2145.79,873.68 2144.86,873.53 2144.54,873.81 2144.11,874.27 2143.82,875.1
  			2143.61,875.87 2144.62,876.08 2145.22,876.4 2145.4,877.03 2145.61,877.73 2145.67,878.36 2145.34,878.67 2145.32,878.98
  			2145.47,879.14 2145.91,879.21 2146.25,879.35 2146.27,879.68 2145.63,880.43 2145.03,881.86 2144.71,882.62 2144.74,883.45
  			2144.38,883.9 2143.72,883.94 2141.47,884.02 2139.51,883.98 2138.67,883.86 2137.34,883.79 2136.68,883.99 2135.81,885.3
  			2135.15,885.82 2134.06,886.28 2132.86,886.42 2132.18,886.96 2131.94,887.81 2131.92,888.56 2131.8,888.94 2131.54,889.27
  			2131.45,889.5 2131.51,889.76 2131.79,889.85 2132.12,889.97 2132.12,890.16 2131.99,890.39 2131.62,890.65 2131.45,891.05
  			2131.49,891.47 2131.56,891.94 2133.84,891.05 2134.62,890.37 2136.17,890.13 2135.96,890.41 2135.18,890.66 2134.41,891.31
  			2135.38,891.38 2137.57,891.05 2138.38,890.02 2139.83,890.05 2140.24,890.79 2140.65,891.79 2140.61,892.16 2140.46,892.44
  			2139.25,892.66 2136.5,893.91 2135.07,894.26 2133.45,894.89 2133.87,895.75 2133.98,896.47 2132.65,896.32 2131.69,896.08
  			2131.1,896.19 2129.49,896.96 2128.64,896.79 2127.79,896.77 2128.15,897.87 2129.03,899.19 2129.57,899.44 2130.41,899.32
  			2132.41,900.92 2133.9,902.52 2133.13,902.63 2132.38,902.47 2131.3,902.47 2130.87,903.44 2130.43,904.15 2130.34,903.78
  			2130.38,903.29 2129.88,902.97 2129.19,903.45 2128.42,905.57 2126.97,907.09 2126.49,908.03 2126.47,908.71 2126.68,908.85
  			2126.99,908.83 2127,909.44 2126.78,909.99 2123.56,910.68 2123.13,911.08 2122.21,910.76 2121.15,909.92 2120.2,909.47
  			2119.6,909.65 2118.98,910.04 2119.33,910.29 2119.74,910.37 2120.84,910.9 2120.26,911.29 2118.13,911.98 2118.2,912.34
  			2118.39,912.6 2120.87,913.19 2123.06,914.2 2123.73,914.9 2124.3,916.11 2124.8,916.66 2125.5,917.07 2126.16,917.33
  			2127.27,917.42 2128.51,917.04 2131.28,919.92 2132.38,920.39 2135.11,920.97 2135.8,921.28 2140.95,925.31 2142.53,927.05
  			2145.54,929.66 2146.42,930.2 2146.8,929.53 2147.31,928.84 2147.87,928.67 2149.48,928.94 2150.97,929.26 2152.06,929.03
  			2153.35,929.59 2154.53,930.22 2155.72,930.66 2158.04,931.08 2158.9,931.67 2159.91,932.25 2163.87,932.55 2164.19,932.45
  			2164.48,932.37 2165.8,932.16 2166.98,932.2 2168.21,932.9 2169.01,932.86 2169.85,932.76 2170.94,933.13 2171.8,933.55
  			2171.87,933.98 2172.62,934.6 2174.8,935.54 2176.57,936.07 2177.12,936.44 2178.46,937.06 2178.6,937.25 2178.57,937.5
  			2178.18,937.97 2178.09,938.38 2178.27,938.61 2178.83,938.84 2179.93,938.89 2180.33,938.59 2181.16,938.38 2181.97,938.01
  			2183.07,937.5 2184.55,937.04 2185.15,937.04 2185.72,937.18 2186.12,937.44 2186.79,938.38 2187.45,937.06 2187.63,936.96
  			2188.24,937.23 2189.32,937.59 2190.06,937.79 2190.47,938.06 2191.61,939.26 2193.45,939.2 2194.23,939.38 2194.65,939.58
  			2194.84,939.82 2194.51,941.01 2194.06,942.25 2194.09,942.55 2194.83,943.02 2195.84,943.51 2196.38,943.91 2196.75,944.26
  			2197.54,944.53 2198.48,944.7 2198.93,944.72 2199.39,945.02 2200.6,945.58 2200.76,945.72 2201.66,945.93 2201.79,946.51
  			2201.95,946.63 2202.32,946.57 2202.95,946.32 2203.45,946.51 2204.09,947.2 2204.98,947.94 2205.46,948.43 2205.64,948.77
  			2206.1,949.1 2206.76,949.49 2207.29,950.1 2207.76,951.51 2208.23,951.83 2209.94,952.37 2210.54,952.48 2212.22,952.67
  			2212.81,952.53 2213.67,951.32 2214.45,950.07 2215.18,949.8 2216.49,949.2 2217.28,948.63 2217.61,948.01 2218.35,946.84
  			2218.81,946.19 2218,945.58 2217.57,945.39 2217.21,945.09 2216.53,943.81 2215.91,942.79 2214.66,941.05 2212.48,938.41
  			2212.04,938.1 2211.69,937.73 2211.53,936.41 2210.91,935.47 2210.01,934.82 2209.68,934.49 2209.46,934.13 2209.88,932.25
  			2209.87,931.72 2209.68,930.59 2209.85,929.28 2210.34,927.49 2210.85,926.24 2211,925.44 2210.3,925.95 2209.46,928.82
  			2209.17,926.51 2208.6,924.32 2208.14,923.19 2207.49,922.27 2206.59,921.56 2205.76,920.71 2204.85,920.26 2203.48,919.91
  			2203.21,919.59 2203.09,919.06 2203.17,918.47 2203.5,917.47 2204.22,916.84 2205.2,916.17 2205.42,915.72 2205.58,915.2
  			2205.89,915.6 2206.26,915.81 2206.52,914.89 2206.92,914.23 2207.42,913.66 2208.05,912.36 2208.52,911.65 2208.85,910.85
  			2209.04,909.53 2209.39,909.4 2209.67,909.17 2209.89,908.8 2210.02,908.39 2209.97,907.64 2209.46,906.83 2209.6,906.74
  			2209.84,906.88 2210.39,907.3 2210.89,907.83 2211.03,907.07 2211.46,906.76 2211.99,906.97 2212.55,907 2214.43,906.39
  			2215.32,906.23 2216.15,905.8 2218.08,904.15 2218.51,904.08 2218.94,904.15 2219.35,904.46 2219.79,904.63 2220.13,904.55
  			2220.29,904.3 2219.91,903.72 2219.77,903.17 2219.74,902.56 2220.24,902.41 2220.8,902.41 2223.07,901.48 2223.34,901.07
  			2223.46,900.51 2224.47,900.4 2224.36,899.92 2223.96,899.8 2222.06,898.81 2220.5,898.12 2219.12,897.5 2218.92,897.39
  			2218.54,897.09 2218.27,896.62 2218.21,896.11 2218.35,895.73 2218.68,895.49 2219.08,895.39 2219.43,895.47 2219.82,895.7
  			2220.52,895.98 2221.42,896.03 2221.98,895.82 2222.06,895.3 2220.99,893.39 2220,891.84 2219.04,890.12 2218.63,889.5
  			2217.46,888.05 2216.3,886.63 2215.39,885.36 2214.91,884.97 2213.43,884.8 2210.61,884.49 2209.62,884.34 2208.82,884.72
  			2208.02,885.02 2207.26,884.46 2206.66,883.56 2206.34,882.8 2206.5,882.02 2206.56,880.88 2206.1,879.74 2205.59,879.18
  			2204.32,878.76 2202.7,877.81 2202.27,876.06 2203.05,873.55 2204.32,871.78 2205.24,871.03 2205.68,870.38 2205.82,869.81
  			2205.71,869.27 2205.17,868.7 2204.32,868.14 2203.89,867.45 2204.07,865.98 2204.63,863.85 2205.49,862.14 2206.65,861.2
  			2207.65,860.52 2208.04,859.89 2208.07,858.99 2208.04,858.14 2208.31,857.52 2208.73,857.03 2209.17,856.59 2209.8,856.09
  			2210.61,855.97 2211.5,856.37 2212.71,857.42 2214.49,859.5 2215.51,860.86 2215.87,861.28 2216.8,862.08 2217.64,862.41
  			2219.03,861.9 2220.37,861.32 2220.81,860.94 2221.08,860.38 2220.87,859.43 2220.59,858.81 2220.29,858.02 2219.88,856.63
  			2219.59,854.46 2219.25,853.77 2219.5,853.69 2220.17,853.84 2220.79,853.9 2221.68,853.42 2222.9,852.52 2225.13,851.13
  			2225.6,850.2 2225.98,849.18 2226.6,848.55 2228.02,848.34 2229.33,848.01 2230.26,847.28 2231.71,846.64 2232.91,846.26
  			2233.43,845.9 2233.8,845.36 2235.17,843.84 2236.25,842.91 2237.19,841.94 2237.51,841.34 2238.26,841.45 2239.4,841.87
  			2240.62,842.25 2241.52,842.85 2241.7,843.45 2241.78,843.93 2242.15,844.18 2242.58,844.23 2243.24,844.1 2244.38,844.08
  			2245.78,843.3 2247.73,841.97 2249.52,841.56 2250.46,841.87 2251.23,842.85 2251.85,843.77 2252.48,844.11 2252.87,844.12
  			2253.02,844.13 2253.8,843.92 2253.86,843.93 2254.58,843.96 2255.27,844.27 2256.42,844.31 2258.01,844.07 2258.18,843.98
  			2258.94,844.1 2259.87,844.52 2260.6,845.03 2261.89,846.68 2262.63,847.1 2264.15,847.69 2264.87,848.2 2265.69,849.03
  			2266.13,849.53 2267.02,850.43 2268.07,851.92 2268.25,853.1 2268.49,854.09 2268.87,854.57 2269.19,854.62 2269.54,854.46
  			2269.87,854.01 2269.99,853.25 2269.89,851.91 2269.62,850.81 2269.28,850.46 2269.11,850.08 2269.34,849.59 2269.92,849.35
  			2270.64,849.5 2271.83,850.13 2273.06,850.93 2274.58,852.31 2275.98,853.19 2277.51,853.89 2278.72,854.1 2279.66,853.89
  			2280.76,853.32 2281.78,852.66 2282.24,851.96 2282.57,851.2 2284.1,850.19 2285.5,849.26 2286.14,849.43 2286.58,849.69
  			2288.02,849.13 2288.52,848.97 2289.88,848.75 2291.29,849.08 2292.42,850.07 2293.51,850.71 2294.48,850.64 2295.29,850.31
  			2295.82,849.69 2296.22,848.96 2296.76,848.49 2296.85,848.46 2298.59,848.71 2299.68,848.68 2299.79,848.57 2301.23,848.77
  			2302.82,849.8 2303.8,850.94 2305.06,852.4 2305.65,852.87 2306.5,853.07 2307.17,853.16 2308.07,853.38 2310.44,853.69
  			2310.8,853.87 2311.04,854.1 2310.82,854.9 2311.03,855.1 2312.95,854.53 2313.47,854.11 2314.11,852.92 2314.67,851.71
  			2315.09,851.26 2315.54,851.14 2315.99,851.32 2316.62,852.03 2317.48,852.76 2318.63,853.04 2319.33,853.15 2320.42,853.21
  			2322.99,852.86 2325.38,851.98 2326.75,851.02 2327.39,849.59 2327.79,847.95 2328.4,846.92 2328.15,845.86 2326.94,844.86
  			2326.53,844.55 2323.6,843.99 2323.42,843.58 2323.25,843.49 2320.36,842.59 2318.97,842.21 2318.57,841.62 2318.31,840.84
  			2317.41,840.15 2315.62,839.52 2315.31,839.04 2315.6,838.56 2317.02,838.02 2318.63,836.88 2319.26,836.65 2320.7,836.59
  			2322.03,835.66 2322.95,835.13 2323.43,834.49 2323.3,833.84 2321.97,831.86 2321.57,830.66 2321.81,829.87 2322.57,829.02
  			2323.01,828.56 2323.53,827.73 2323.87,827.28 2325.22,827.09 2326.85,827.01 2327.97,827.21 2329.53,827.32 2330.96,827.47
  			2331.68,827.61 2332.21,827.35 2332.59,826.91 2332.58,826.31 2332.02,825.74 2329.93,824.98 2329.03,824.36 2328.33,824.43
  			2327.15,824.23 2326.09,823.82 2325.15,823.69 2324.84,823.12 2325.04,822.32 2325.4,821.87 2326.1,821.64 2326.85,821.76
  			2327.67,821.42 2327.91,821.23 2327.98,820.98 2327.85,820.62 2327.47,820.33 2326.93,820.25 2326.3,820.5 2325.56,820.66
  			2324.3,820.29 2323.3,819.85 2323.35,819.44 2324.09,818.83 2324.43,818.34 2324.43,816.85 2324.68,815.91 2325.42,815.27
  			2326.28,814.92 2328.51,815.55 2331.3,816.11 2331.77,816.02 2331.92,815.72 2332.23,815.46 2336.1,815.34 2336.85,814.98
  			2337.22,814.69 2340.94,814.28 2341.38,813.88 2341.93,813.51 2342.78,813.52 2343.8,813.37 2345.22,812.93 2346.23,812.68
  			2346.39,812.66 2347.45,812.76 2348.77,812.4 2349.06,812.26 2349.27,811.99 2350.42,811.47 2352.62,811.04 2353.16,811.3
  			2354.21,811.41 2355.56,811.23 2356.55,810.9 2357.12,811.22 2357.91,811.55 2358.49,811.27 2358.78,810.38 2359.16,809.51
  			2359.82,809.1 2360.35,808.95 2360.82,808.61 2361.18,808.26 2363.12,808.32 2364.86,807.45 2365.2,807.84 2367.46,807.75
  			2370.26,807.19 2371.94,806.93 2374.84,806.34 2376.18,805.87 2378.09,805.57 2379.85,805.36 2381,804.53 2381.92,804.41
  			2383.05,804.34 2383.74,804.14 2384.19,803.83 2384.49,803.25 2384.33,802.51 2384.17,801.98 2384.97,801.68 2386.12,801.59
  			2386.85,801.46 2388.43,800.25 2389.53,799.66 2390.66,799.29 2392.93,799.49 2395.01,799.68 2397.09,800.26 2398.18,800.98
  			2399.12,801.53 2400.01,801.79 2400.81,801.96 2401.75,801.71 2402.4,801.38 2402.79,800.9 2403.37,800.55 2405.49,800.28
  			2405.93,800.8 2406.94,802.37 2407.63,804.44 2408.76,807.19 2409.26,808.54 2409.04,809.25 2409.04,810.22 2408.98,811.28
  			2408.14,812.48 2408.48,813.04 2410.53,813.66 2413.4,813.43 2415.17,812.93 2416.16,813.11 2416.67,812.81 2417.01,811.92
  			2417.69,811.72 2418.39,812.34 2418.9,813.39 2419.38,814.07 2419.35,814.87 2419.53,815.9 2419.88,816.16 2420.59,815.77
  			2421.06,815.54 2421.17,815.23 2420.97,814.84 2420.87,814.45 2421.03,814.09 2421.36,813.94 2422.36,814.05 2423.82,814.25
  			2425.55,815.71 2426.48,815.98 2426.87,816.01 2427.75,815.92 2428.8,815.5 2429.51,814.97 2429.75,814.71 2430.16,814.76
  			2430.54,815 2430.57,815.54 2430.26,816.31 2429.21,817.01 2427.91,817.67 2427.12,818.87 2426.95,820.12 2427.3,820.75
  			2427.59,821.18 2427.67,821.77 2427.97,821.85 2428.5,821.61 2429.96,820.37 2430.7,820.07 2431.78,819.87 2433.54,819.97
  			2434.73,820.37 2435.3,820.93 2435.93,821.39 2436.36,821.2 2436.58,820.67 2436.59,820.06 2436.77,819.56 2438.71,818.32
  			2439.99,817.5 2440.43,817.41 2441.3,817.58 2441.83,817.49 2443.24,816.72 2444.56,815.84 2444.69,815.24 2444.74,814.7
  			2445.07,814.46 2446.92,814.27 2447.22,814.17 2448.8,813.55 2450.99,812.5 2452.06,811.88 2453.99,811.61 2454.35,811.43
  			2454.99,811.01 2456.21,810.43 2456.86,810.37 2456.46,811.77 2455.73,813.38 2455.32,813.81 2453.36,813.74 2453.37,814.18
  			2453.67,814.85 2453.89,815.23 2454.13,815.57 2454.66,816.16 2456.72,817.54 2459.35,819.29 2462.19,821.26 2464.17,822.63
  			2464.97,823.34 2465.48,823.89 2466.94,825.88 2468.32,827.77 2470.66,831.08 2472.73,834.27 2475.01,837.76 2476.33,839.78
  			2479.03,843.98 2479.75,845.27 2481.11,847.7 2482.32,849.97 2483.38,851.98 2484.06,852.16 2484.11,851.62 2484.23,851.26
  			2484.57,851.05 2485.35,850.46 2485.78,850.32 2486.41,850.38 2487.07,850.08 2487.31,849.51 2487.15,848.45 2487.05,847.96
  			2487.27,847.44 2487.64,847.23 2488.14,847.07 2488.6,846.98 2488.98,846.39 2489.69,846.21 2490.35,846.32 2490.89,846.35
  			2491.37,846.78 2491.63,847.37 2492.15,847.41 2492.99,847.35 2493.11,847.85 2492.87,848.67 2492.58,849.31 2492.52,849.83
  			2492.97,850.08 2494.61,849.85 2495.19,849.96 2495.38,850.48 2495.61,850.91 2495.73,851.44 2495.56,852.02 2495.85,852.37
  			2497.26,852.37 2498.26,852.09 2499.79,852.07 2501.17,852.69 2502.13,852.59 2503.09,852.34 2504.51,852.5 2505.5,852.02
  			2506.18,851.41 2506.4,850.93 2506.75,850.66 2508.09,850.67 2508.93,850.62 2509.55,849.92 2510.12,849.6 2511.07,849.54
  			2511.78,849.54 2513.67,850.19 2514.81,850.73 2516.01,851.5 2516.73,851.98 2517.21,853.06 2518.03,853.86 2518.67,854.79
  			2518.83,855.71 2519.36,857.36 2519.91,857.9 2520.57,857.9 2521.39,858.12 2522.31,858.3 2524.26,859.52 2524.97,859.56
  			2525.52,859.85 2525.61,860.42 2525.4,861.07 2525.62,861.69 2526.26,862.49 2526.76,863.26 2527.38,864.2 2527.57,864.74
  			2528.07,864.92 2528.74,864.65 2529.81,864.85 2533.03,865.39 2533.48,865.45 2533.82,866.01 2534.28,865.97 2534.82,865.94
  			2535.56,866.01 2536.07,865.5 2536.5,865.05 2537.56,864.49 2538.43,863.73 2539.17,863.06 2539.72,862.97 2540.17,863.28
  			2540.19,863.87 2539.64,864.29 2539.21,864.8 2539.31,865.32 2540.05,865.36 2540.88,866.14 2542.06,867.94 2542.47,868.32
  			2543.05,868.68 2543.7,868.84 2544.43,869.1 2544.96,869.84 2545.18,870.52 2545.97,870.61 2546.47,870.45 2546.8,870.12
  			2547.31,870.01 2548.09,869.85 2548.88,869.65 2549.32,869.68 2549.35,869.69 2550.33,869.66 2550.78,869.42 2551.12,869.06
  			2551.85,868.66 2552.01,868.2 2552.02,867.3 2552.5,866.53 2553.73,866.3 2554.19,866.19 2554.69,866.3 2555.46,866.19
  			2556.21,866.16 2556.63,866.39 2557.18,866.59 2557.88,866.57 2558.13,866.21 2558.16,865.7 2558.46,865.57 2558.84,865.92
  			2559.05,866.18 2559.37,866.3 2560.22,865.99 2560.82,865.65 2561.01,864.96 2561.36,864.62 2561.82,864.79 2562.63,864.78
  			2563.3,864.25 2564.18,863.82 2564.81,863.63 2564.94,863.26 2564.64,862.47 2564.73,861.59 2565.57,861.1 2566.7,861.04
  			2567.53,860.7 2567.77,859.77 2568.18,859.5 2568.6,859.39 2569.62,859.25 2570.35,858.87 2570.8,858.69 2572.08,858.18
  			2573.24,858.08 2573.74,857.67 2574.13,857.16 2574.78,856.97 2575.45,856.52 2576.33,855.95 2576.68,855.88 2578.12,855.55
  			2578.68,855.42 2579.02,855.35 2579.64,855.37 2579.91,854.78 2580.54,854.33 2581.17,854.19 2581.49,853.75 2582.1,853.19
  			2582.92,852.88 2584.2,852.83 2585.45,852.9 2586.19,852.8 2586.8,851.97 2586.92,851.56 2587.06,851.15 2587.56,850.99
  			2588.16,851.67 2588.67,852.09 2589.45,852.53 2589.85,852.94 2590.31,852.99 2590.79,852.69 2591.13,851.94 2591.78,851.82
  			2592.5,851.94 2592.69,852.31 2592.75,852.67 2593.08,853.31 2593.86,853.87 2594.87,853.84 2595.27,853.74 2596.25,853.82
  			2597.21,853.94 2598.26,854.07 2598.57,854.1 2599.69,854.16 2601.33,854.26 2602.05,854.21 2603.53,854.4 2603.83,854.89
  			2604.1,856.07 2604.34,857.19 2604.4,858.09 2604.79,858.55 2605.28,858.71 2605.6,859.07 2606.17,859.56 2606.59,860.27
  			2607.1,860.22 2607.46,860.05 2608.25,860 2609.25,860.05 2609.95,860.44 2610.21,860.95 2610.78,861.24 2611.24,861.14
  			2612.61,861.14 2613.09,861.17 2613.56,861.46 2614.24,861.5 2614.61,861.14 2615.8,860.9 2616.49,860.39 2617.01,860.39
  			2617.41,860.63 2617.72,860.97 2618.17,860.82 2618.42,860.54 2618.81,860.54 2619.2,860.72 2620.19,861.03 2620.91,861.61
  			2621.47,861.67 2622.19,861.5 2622.52,861.42 2622.84,861.71 2623.3,861.86 2623.65,861.65 2624.25,861.5 2626.56,861.81
  			2627.1,862.39 2627.5,862.67 2627.84,863.14 2628.44,863.48 2629.71,863.36 2630.21,863.02 2631.24,862.25 2631.65,861.5
  			2632.17,861.26 2632.75,861.14 2633.3,861.14 2633.87,861.11 2634.57,860.56 2635.14,860.37 2635.98,859.67 2636.13,859.36
  			2636.54,858.54 2636.79,858.02 2637.21,857.2 2637.44,855.87 2637.57,855.16 2637.46,854.65 2636.96,854.39 2636.33,854.26
  			2635.77,853.87 2635.36,853.42 2635.11,852.78 2634.78,852.04 2634.8,851.5 2634.71,851.09 2634.43,850.73 2633.89,850.11
  			2633.63,849.64 2633.72,848.9 2634.35,847.64 2634.41,847.05 2634.49,846.69 2634.46,846.36 2634.66,845.6 2635.01,845.28
  			2635.42,844.46 2635.97,844.09 2636.66,844.06 2636.96,843.84 2637.11,843.03 2637.44,842.4 2637.66,841.95 2638.08,841.47
  			2640.5,840.53 2641.51,839.36 2641.86,838.77 2642.25,837.5 2642.63,836.97 2643.17,837.15 2643.82,837.9 2644.3,837.9
  			2645.01,838.31 2646.96,839.15 2648.01,839.42 2648.69,839.5 2649.59,839.74 2650.16,840.23 2651.29,841.04 2652.24,841.25
  			2653.89,841.33 2655.9,841.37 2656.47,841.51 2657.94,842.09 2659.56,842.74 2661.09,843.31 2662.25,843.76 2662.94,844.19
  			2663.58,844.43 2664.28,844.22 2665.18,844.27 2667.29,844.79 2668.62,845.22 2669.73,845.54 2670.11,845.98 2670.14,846.57
  			2669.99,847.07 2670.07,848.28 2670.43,848.92 2670.57,849.76 2670.7,850.57 2670.61,851.37 2670.77,851.8 2671.12,852.05
  			2671.46,852.6 2671.35,853.19 2671.2,853.53 2671.22,854.08 2671.63,854.53 2672.22,854.62 2672.75,854.74 2673.4,855.45
  			2674.55,856.27 2675.24,856.49 2676.04,856.86 2677.55,857.22 2678.58,857.33 2679.19,857.62 2679.78,858.33 2680.77,858.47
  			2681.4,858.71 2682.55,859.01 2683.31,858.84 2683.98,858.59 2684.43,858.64 2685.29,858.8 2686.3,858.83 2687.15,858.67
  			2687.83,858.17 2688.62,857.5 2689.57,857.16 2690.66,857.04 2691.41,856.77 2693.87,856.31 2694.86,856.24 2695.63,855.8
  			2696.31,855.46 2697.29,855.31 2698.62,855.67 2699.9,855.97 2701.43,856.07 2702.46,856.48 2703.18,856.87 2704.32,857.18
  			2705.59,857.03 2707.32,856.91 2708.47,857.09 2709.68,857.8 2710.41,858.37 2711.25,859.58 2712.11,860.17 2712.87,860.64
  			2713.83,860.67 2716.22,860.71 2717.53,860.97 2718.63,861.1 2718.89,861.35 2718.78,862.18 2718.81,863.37 2718.8,863.92
  			2719.04,864.33 2719.41,864.4 2719.61,864.98 2720.16,865.32 2721.12,865.73 2722.76,867.13 2723.73,867.73 2724.5,867.94
  			2725.5,867.8 2727.08,867.8 2729.75,867.8 2731.57,868.22 2732.21,868.51 2734.07,868.85 2736.13,869.21 2737.86,869.6
  			2738.88,869.1 2739.78,869.06 2740.64,869.41 2741.49,869.95 2742.15,869.89 2743.15,869.64 2746.32,868.14 2747.43,867.58
  			2748.21,867.72 2748.91,867.52 2749.44,867.35 2750.79,867.12 2751.62,867.06 2752.47,866.92 2753.69,866.83 2756.18,865.84
  			2757.19,865.65 2758.89,865.92 2759.81,865.75 2760.73,865.25 2761.91,864.73 2762.22,863.9 2762.82,862.75 2764.13,861.91
  			2765.19,861.17 2766.28,860.45 2767.61,859.85 2768.86,859.42 2770.46,858.28 2771.73,857.7 2772.37,857.51 2773.12,857.72
  			2774.53,857.87 2775.55,857.83 2776.12,857.96 2777.27,858.52 2778.32,859.01 2779.11,859.88 2780.6,861.09 2781.36,861.5
  			2781.9,861.66 2783.24,861.78 2784.33,861.84 2784.99,861.56 2786.09,861.05 2787.84,860.41 2788.54,860.43 2789.67,860.77
  			2791.35,861.4 2792.03,861.88 2792.46,862.46 2794.2,863.4 2795.32,863.89 2797.2,864.64 2799.15,864.81 2801.02,865.61
  			2801.98,865.86 2802.49,865.86 2805.13,863.9 2807.36,862.23 2809.92,860.94 2811.8,860.76 2813.22,860.38 2814.17,859.8
  			2814.72,858.83 2814.89,857.46 2814.52,856.63 2813.59,856.35 2813.36,856.06 2813.8,855.77 2814.13,855.19 2814.34,854.35
  			2814.88,853.54 2815.73,852.78 2816.2,851.93 2816.29,851 2816.81,850.07 2817.75,849.14 2818.26,848.28 2818.35,847.48
  			2818.83,846.51 2820.12,844.77 2820.96,842.78 2822.4,841.41 2824.7,840 2826.14,838.6 2826.71,837.21 2826.67,835.98
  			2826,834.91 2825.88,833.83 2826.29,832.72 2825.93,831.89 2824.79,831.34 2823.43,831.21 2821.86,831.49 2820.97,831.14
  			2820.77,830.17 2821.2,829.39 2822.24,828.79 2823.95,827.33 2826.33,825.01 2828.7,823.72 2832.24,823.35 2835.09,822.58
  			2837.99,821.81 2840.09,821.42 2840.45,821.68 2841.59,821.74 2843.52,821.61 2845.31,821.27 2846.97,820.74 2848.27,820.61
  			2849.24,820.9 2849.79,820.91 2850.17,820.94 2850.38,820.94 2850.79,820.71 2851.91,821.12 2854.05,822.33 2855.39,822.87
  			2855.94,822.74 2856.54,823.07 2857.2,823.87 2858.01,824.35 2859.48,824.56 2860.93,825.44 2861.52,825.49 2861.73,825
  			2862.27,824.71 2863.14,824.64 2864.41,825.07 2866.07,826.01 2867.11,826.42 2867.53,826.31 2867.98,826.48 2868.34,826.92
  			2868.37,827.47 2868.25,827.76 2868.65,828.21 2869.11,828.21 2869.86,828.43 2870.44,829.24 2870.97,829.61 2871.34,829.93
  			2871.41,830.2 2871.44,830.47 2871.33,830.68 2871.13,831.03 2871.07,831.4 2871.32,831.82 2872.25,832.12 2872.57,832.43
  			2872.64,832.83 2872.94,833.27 2873.57,833.78 2873.81,834.21 2873.67,834.55 2873.85,834.84 2874.17,835.06 2874.23,835.88
  			2874.26,836.34 2874.77,836.87 2874.88,837.94 2875.23,839.13 2876.44,840.75 2876.84,841.63 2876.74,842.67 2876.91,843.16
  			2877.46,843.4 2877.72,843.84 2877.69,844.48 2877.9,844.88 2878.08,845.31 2877.96,845.97 2878.13,846.57 2878.41,846.92
  			2878.61,847.56 2878.72,848.36 2879.53,849.64 2881.03,851.37 2881.94,852.72 2882.28,853.68 2882.28,854.47 2881.95,855.08
  			2881.94,855.53 2882.23,855.81 2882.32,856.19 2882.2,856.67 2882.69,857.24 2884.33,858.23 2883.67,859.74 2883.5,860.8
  			2883.59,861.92 2884,862.7 2884.72,863.16 2885.17,863.63 2885.35,864.13 2886.22,864.67 2887.78,865.25 2889.78,865.36
  			2892.22,864.97 2893.71,864.91 2894.26,865.16 2894.44,865.55 2894.27,866.06 2894.68,866.4 2895.68,866.56 2896.37,866.88
  			2896.75,867.37 2897.21,867.51 2897.76,867.3 2898.29,867.33 2898.81,867.6 2899.15,867.51 2899.44,867.21 2899.91,867.21
  			2900.4,867.22 2900.7,867.93 2901.18,868.33 2901.85,868.42 2902.88,869.29 2904.94,871.75 2906.28,872.62 2907.62,872.9
  			2909.28,872.95 2909.82,873.91 2909.39,874.91 2909.28,875.75 2909.66,876.05 2910.18,877.04 2910.92,877.61 2911.05,878.06
  			2911.4,878.57 2911.26,879.5 2910.62,880.87 2910.8,882.03 2911.77,883 2912.33,883.92 2912.48,884.81 2912.73,885.35
  			2913.07,885.55 2914.07,885.48 2915.74,885.16 2916.96,885.21 2917.74,885.65 2919.66,885.66 2922.73,885.26 2924.67,885.14
  			2925.48,885.29 2926.2,884.72 2926.83,883.42 2927.43,882.81 2927.98,882.88 2928.86,882.46 2930.06,881.55 2931.11,881.11
  			2932.43,881.15 2933.84,881.2 2934.72,880.81 2935.55,880.01 2936.99,879.3 2940.05,878.37 2940.79,878.22 2941.14,878.27
  			2942.16,878.42 2943.06,878.78 2943.92,879.51 2944.05,879.98 2943.95,880.59 2943.77,880.95 2943.41,881.35 2943.08,882
  			2943.3,882.51 2943.79,883.59 2944.2,884.37 2944.65,885.29 2944.45,885.62 2944.18,886.26 2943.34,887.34 2942.88,887.75
  			2942.39,888.15 2941.54,888.25 2941.17,888.34 2940.76,888.51 2940.51,888.89 2940.21,889.16 2939.73,889.7 2939.69,890.16
  			2939.91,890.85 2940.02,891.55 2939.47,892.18 2939.04,893.14 2938.92,893.43 2938.7,894.16 2938.64,894.42 2938.51,895.95
  			2937.96,897 2937.19,898.21 2937.36,898.94 2937.5,899.61 2937.31,899.93 2937.26,900.22 2937.15,900.86 2936.91,901.11
  			2936.21,901.51 2935.79,902 2935.88,902.74 2935.67,903.37 2935.35,903.94 2935.01,904.3 2934.53,904.54 2934.21,904.74
  			2933.97,905.46 2933.9,906.01 2933.68,906.56 2933.81,907.12 2933.57,907.61 2932.89,907.95 2932.5,908.15 2932.14,908.23
  			2931.46,908.76 2930.85,910.57 2930.71,911.62 2930.85,912.55 2929.99,913.14 2929.35,913.6 2928.96,913.43 2928.53,913.28
  			2928.29,913.23 2928.29,913.38 2927.73,914.51 2926.64,916.91 2925.34,917.93 2924.55,918.79 2923.79,918.38 2923.48,918
  			2923.5,917.75 2922.89,917.29 2922.05,916.58 2922.18,915.46 2922.39,914.24 2921.6,913.14 2921.61,912.6 2921.51,911.69
  			2921.67,911.47 2921.28,911.37 2920.71,911.06 2920.22,910.51 2919.74,910.74 2919.3,911.39 2918.56,911.78 2918.22,912.49
  			2917.92,913.04 2917.15,913.78 2916.81,914.08 2915.31,914.58 2914.97,914.74 2913.74,914.85 2913.33,915.07 2912.89,915.53
  			2912.78,915.99 2913.08,916.47 2913.56,917.44 2913.78,918.11 2914.11,919.41 2915.2,923.51 2914.84,924.22 2914.51,927.28
  			2914.59,927.83 2914.56,928.69 2914.58,929.32 2914.81,929.48 2915.1,929.7 2915.25,930.06 2915.21,930.63 2915.06,931.04
  			2914.83,931.86 2914.53,933.04 2914.19,933.49 2913.97,933.85 2913.77,934.1 2913.75,934.93 2913.62,935.48 2913.09,935.67
  			2912.57,935.99 2911.94,935.88 2911.39,935.94 2910.71,936.15 2909.49,936.4 2908.78,936.73 2908.44,936.97 2908.2,937.26
  			2908.16,937.54 2908.32,937.68 2909.01,937.8 2909.48,938.31 2909.55,938.88 2909.06,939.21 2909.29,939.82 2909.83,940.42
  			2910.11,940.86 2910.17,941.31 2910.41,941.56 2910.77,941.33 2911.65,939.33 2910.6,937.98 2910.99,937.81 2912.59,938.21
  			2913.26,938.09 2914.38,938.29 2915.11,937.56 2915.51,936.8 2916.36,936.29 2917.4,934.52 2919.13,932.42 2919.74,931.88
  			2920.96,931.4 2921.27,931.47 2921.58,931.63 2920.61,932.74 2920.35,933.51 2921.03,933.51 2921.71,933.27 2923.43,931.99
  			2924.08,931.29 2924.29,932.05 2924.03,935.67 2925.52,935.4 2926.32,935.79 2927.44,935.75 2928.74,936.58 2929.25,936.47
  			2929.86,936.44 2930.39,937.3 2931.24,937.57 2932.67,936.89 2934.83,936.23 2935.87,936.21 2938.4,935.02 2939.63,934.06
  			2944.14,931.52 2946.04,930.13 2947.84,929.12 2948.93,927.49 2950.81,925.95 2950.86,925.29 2951.23,924.54 2954.1,920.4
  			2955.05,919.72 2956.36,919.21 2956.92,918.45 2957.28,917.37 2959.04,915.76 2960.25,914.14 2961.37,913.08 2961.93,912.13
  			2964.82,909.82 2967.16,907.24 2969.36,905.37 2970.06,904.22 2972.9,900.83 2973.77,898.6 2974.84,897.75 2975.3,895.61
  			2976.22,894.08 2976.47,893.16 2976.95,892.3 2980.44,888.84 2981.83,886.15 2983.57,883.45 2984.81,882.5 2986.12,881.27
  			2986.83,880.3 2988.84,878.76 2989.8,877.69 2990.29,876.6 2990.74,873.91 2992.04,871.84 2991.66,871.5 2991.45,870.86
  			2991.59,870.14 2991.79,869.72 2991.92,869.05 2992.21,868.3 2992.48,867.84 2993.21,864.95 2993.16,863.14 2992.76,862.44
  			2992.75,861.5 2993.77,860.16 2994.02,859.94 2994.11,859.62 2993.6,859.36 2993.36,859.09 2992.86,854.51 2993.24,851.7
  			2994.29,849.63 2994.5,848.91 2994.64,846.89 2995.91,844.86 2996.7,842.56 2998.36,841.35 2999.45,839.86 3000.36,839.19
  			3000.52,837.65 3001.36,836.28 3000.72,835.65 3000.05,835.24 2998.7,834.14 2998.39,833.38 2999.34,832.08 2999.51,830.92
  			2999.43,828.79 2998,828.13 2996.22,826.51 2995.92,825.96 2996.32,825.92 2998.8,826.79 3000.03,825.86 3000.66,824.87
  			3000.42,823.62 2999.11,823.15 2997.4,821.77 2997.32,821.31 2994.64,820.14 2991.77,817.65 2990.89,815.48 2990.35,814.9
  			2987.66,813.12 2987.13,812.53 2986.39,812.29 2983.12,813.26 2981.31,812.97 2977.86,811.79 2977.54,812.04 2978.03,812.92
  			2977.94,813.79 2978.08,814.99 2977.9,817 2977.57,818.44 2976.31,820.45 2975.8,820.82 2974.7,820.99 2974.11,820.97
  			2974.47,820.19 2975.43,819.25 2976.8,817.58 2976.79,816.1 2976.45,815.96 2976.16,815.96 2975.2,816.54 2974.13,818.65
  			2971.59,820.06 2969.8,820.56 2966.35,820.8 2965.72,820.72 2965.45,820.34 2966.22,819.74 2967.94,818.87 2969.01,817.04
  			2970.2,816.61 2970.61,816.11 2968.83,815.83 2967.6,815.17 2966.44,814.33 2966.98,814.06 2967.38,813.98 2967.9,813.68
  			2969.19,812.21 2968,812.12 2966.76,812.23 2964.78,813.39 2964.39,814.01 2965.03,814.84 2965.75,815.2 2964.89,817.55
  			2963.74,817.24 2962.63,817.35 2961.92,818.01 2961.21,817.75 2960.91,816.29 2961.3,814.79 2961.65,811.4 2962.07,810.25
  			2962.1,808.98 2961.87,808.29 2961.18,808.25 2960.05,808.38 2957.16,808.37 2953.91,808.72 2950.42,807.46 2949.48,807.28
  			2948.91,807 2948.52,805.73 2948.71,805 2948.95,804.53 2951.29,802.53 2953.06,801.98 2956.64,799.73 2958.12,797.87
  			2959.04,797.09 2961.84,795.7 2963.68,794.5 2965.18,793.35 2966.82,792.38 2968.41,790.75 2969.41,790.42 2972.63,786.92
  			2973.19,786.14 2973.52,785.06 2973.84,784.58 2977.58,780.54 2980.14,779.06 2981.96,776.97 2984.17,776.14 2984.69,775.8
  			2985.64,774.62 2987.19,773.91 2987.68,773.48 2988.87,771.8 2992.61,770.27 2993.02,769.64 2994.62,765.4 2995.51,764.28
  			2997.17,762.88 3000.2,761.52 3002.35,760.03 3003.63,758.84 3005.91,755.69 3008.48,753.79 3010.58,752.7 3015.74,751.08
  			3018.53,751.41 3021.43,750.56 3023.58,750.6 3026.61,751 3035.63,750.54 3037.33,751.03 3037.95,751.58 3038.81,753.22
  			3039.8,753.57 3041.69,752.93 3043.13,750.33 3043.91,750 3046.15,751.04 3048.14,751.13 3052.14,752.35 3053.6,752.07
  			3055.17,750.86 3058.4,750.53 3060.37,752.42 3062.35,752.48 3063.93,752.17 3064.35,751.09 3063.72,750.71 3062.5,751.04
  			3062.2,750.1 3062.94,749.05 3064.02,749.77 3065.77,749.7 3066.38,749.61 3066.13,749.12 3065.73,748.72 3065.2,747.83
  			3067.1,746.6 3068.23,746.19 3070.06,746.07 3074.78,747.57 3075.82,747.72 3076.93,748.32 3078.7,748.75 3077.62,749.15
  			3077.15,749.53 3078.26,749.38 3079.22,749.84 3080.01,749.95 3080.35,749.77 3080.76,749.17 3081.35,748.57 3081.78,748.39
  			3082.93,748.42 3084.43,748.69 3085.59,749.16 3088.22,751.66 3089.43,752.15 3090.8,752.07 3091.35,752.23 3092.11,752.91
  			3089.84,753.7 3087.67,753.86 3085.75,753.65 3082.52,754.66 3084.25,757.23 3087.43,757.34 3090.66,756.79 3091.31,755.72
  			3092.61,755.3 3093.29,755.36 3094.77,756.25 3096.81,756.6 3097.35,756.44 3099,754.67 3099.99,754.51 3100.64,754.55
  			3101.38,753.02 3104.2,752.89 3105.85,754.27 3106.86,754.75 3108.84,754.34 3109.93,753.35 3110.62,752.99 3112.69,753.93
  			3113.7,753.35 3115.33,753.25 3116.54,753.32 3116.48,752.33 3116.59,751.2 3114.95,750.09 3111.67,748.96 3109.78,749.69
  			3109.06,749.77 3108.56,749.67 3108.03,749.1 3108.53,748.2 3109.03,747.48 3109.01,746.57 3109.23,745.28 3110.05,744.73
  			3110.48,744.65 3111.63,741.99 3114.94,738.43 3118.79,736.23 3121.22,734.54 3122.37,733.33 3124.08,730.54 3126.51,728.5
  			3127.67,727.85 3128.91,726.99 3129.34,724.31 3130.27,723.68 3131.12,723.22 3132.74,721.79 3135.15,720.87 3135.99,720.2
  			3138.76,720.24 3141.05,720.78 3141.73,720.64 3143.27,719.85 3145.06,720.04 3147.4,719.53 3149.52,718.59 3150.47,718.5
  			3151.36,718.7 3152.44,720.08 3153.06,720.42 3153.53,721.22 3154.96,720.72 3158.84,718.85 3159.3,718.83 3159.91,718.96
  			3160.01,719.68 3159.97,720.27 3159.38,722.15 3158.67,723.58 3156.72,726.33 3156.31,726.74 3156.57,727.48 3156.87,728.84
  			3155.91,730.31 3155.53,731.04 3156.31,731.21 3157.33,730.39 3158.85,729.88 3159.67,729.91 3160.49,730.16 3159.2,732.64
  			3158.99,733.94 3158.79,734.44 3158.76,735.1 3159.72,734.74 3160.4,734.21 3161.36,733.81 3163.76,733.64 3165.01,731.86
  			3166.03,730.96 3175.73,723.54 3177.45,721.97 3178.41,721.86 3179.26,722.12 3180.03,722.09 3180.19,721.54 3180.48,721.33
  			3181.35,721.41 3181.91,722.8 3182.52,723.49 3182.97,723.36 3183.28,723.15 3183.74,722.62 3184.23,722.19 3184.74,721.48
  			3184.32,721 3182.65,720.29 3182.72,719 3183.67,716.93 3183.56,715.59 3183.94,714.2 3184.36,713.5 3184.74,713.19
  			3185.11,712.72 3184.62,711.64 3184.99,710.9 3185.36,710.38 3193.14,708.47 3194.51,708.36 3195.76,708.75 3197.66,710.12
  			3199.78,710.83 3202.75,711.13 3202.93,711.75 3201.77,711.54 3201.21,711.73 3201.31,712.29 3201.16,712.7 3200.46,712.21
  			3198.47,711.94 3196.64,711.4 3196.03,711.44 3193.41,713.06 3192.73,713.77 3191.61,717 3191.56,719.22 3191.15,721.34
  			3190,722.25 3189.62,723.31 3189.34,724.56 3190.76,725.09 3190.92,725.5 3191.04,726.07 3190.09,727.4 3187.79,729.06
  			3187.53,729.41 3187.23,730.16 3188.55,731.55 3187.5,732.06 3186.5,732.41 3185.53,733.04 3182.34,733.26 3180.15,734.83
  			3176.39,736.39 3174.72,737.29 3174.18,737.87 3172.85,740.25 3172.07,741.26 3169.51,742.84 3167.57,745.13 3164.5,747.87
  			3163.29,748.19 3161.91,748.87 3160.25,750.78 3156.01,754.11 3153.86,758.11 3152.69,759.45 3151.47,760.5 3150.65,761.62
  			3149.19,762.8 3146.24,764.55 3144.24,766 3143.16,766.97 3142.77,767.88 3142.23,767.68 3140.24,768.17 3137.64,767.75
  			3135.83,770.44 3133.86,770.72 3131.91,770.07 3130.95,770.39 3130.6,770.68 3130.28,771.07 3131.19,771.93 3131.6,772.67
  			3131.73,773.34 3131.84,774.49 3130.76,776.62 3129.74,778.29 3128.07,779.87 3124.18,782.75 3123.82,783.1 3123.46,783.78
  			3121.22,791.22 3120.61,794.52 3119.86,799.78 3119.94,801.53 3120.41,805.45 3121.14,809.45 3122.81,816.32 3123.19,818.44
  			3124.44,826.89 3124.54,828.49 3124.91,829.84 3125.54,831.22 3126.68,832.54 3126.79,834.16 3127.74,839.27 3127.82,844.18
  			3128,845.24 3128.19,846.01 3129.26,846.95 3129.62,848.1 3129.71,848.96 3129.91,849.82 3130.75,849.41 3133.74,847.11
  			3136.16,844.92 3136.5,844.13 3137.33,843.52 3138.97,842.73 3141.33,840.43 3143.25,837.27 3144.36,834.85 3144.61,833.97
  			3144.67,833.1 3144.5,831.21 3144.95,830.51 3145.58,828.41 3145.17,827.85 3144.1,827.45 3144.43,826.6 3145.21,826.4
  			3145.84,826.8 3146.21,827.7 3146.73,828 3148.47,826.42 3150.02,825.63 3153.81,824.25 3155.38,824.34 3156.86,825.54
  			3157.51,825.49 3157.32,823.83 3156.44,822.62 3156.45,821.85 3156.93,820.65 3156.58,819.86 3156.21,819.27 3155.98,817.99
  			3156.64,815.4 3157.39,813.98 3157.93,813.31 3159.73,812.16 3161.66,810.5 3163.81,809.22 3165.18,808.79 3166.82,808.56
  			3168.2,809.46 3170.98,809.51 3171.83,809.31 3173.87,807.5 3175.03,806.76 3174.82,805.2 3174.11,803.89 3172.66,802.24
  			3172.33,801.46 3171.87,799.66 3171.82,798.04 3172.25,796.16 3173.5,793.97 3174.86,791.01 3175.38,790.55 3176.96,789.85
  			3178.03,789.28 3178.59,788.98 3178.26,787.33 3179.11,786.66 3179.8,786.24 3181.54,786.4 3182.1,786.02 3182.89,785.86
  			3182.36,786.73 3181.67,787.32 3180.15,788.14 3179.43,789.32 3181.22,791.3 3182.33,791.68 3182.97,791.55 3184.16,790.45
  			3184.77,790.01 3185.39,789.31 3185.04,786.75 3184.62,785.35 3184.73,783.87 3183.96,783.42 3182.96,783.23 3181.91,783.46
  			3181.3,783.05 3180.9,782.39 3180.81,781.62 3181.01,779.84 3180.95,778.88 3180.56,777.18 3180.56,776.69 3180.71,775.81
  			3182.21,774.35 3183.48,773.29 3184.15,772.41 3184.37,771.81 3184.47,770.55 3183.79,769.98 3180.19,768.65 3179.66,768.63
  			3178.54,769.16 3178.08,770.85 3177.44,771.44 3177.45,771.1 3177.61,770.7 3175.81,770.08 3174.97,769.52 3174.48,768.99
  			3174.16,768.23 3173.81,767.05 3174.56,764.65 3175.34,762.51 3177.96,759.29 3179.56,758.16 3181.28,756.44 3182.02,756.13
  			3182.31,755.85 3182.6,755.43 3182.06,754.26 3182.36,753.98 3182.65,753.84 3183.28,754.05 3184.87,751.92 3184.83,749.21
  			3185.27,746.89 3185.64,745.93 3186.02,745.26 3186.73,744.61 3187.4,744.27 3188.38,743.46 3188.83,742.83 3189.14,742.67
  			3190.26,742.72 3191.14,742.97 3192.13,743.38 3191.94,744.47 3193.11,743.51 3194.16,742.45 3194.7,742.27 3195.41,742.41
  			3196.63,743.22 3197.55,744.77 3198.18,745.19 3199.02,745.15 3199.57,744.94 3200.03,743.87 3200.12,741.94 3201.03,741.61
  			3201.81,741.48 3202.91,740.6 3204.32,740.2 3207.35,738.68 3209.35,737.11 3210.8,737.05 3210.43,737.95 3210.3,738.81
  			3209.77,740.93 3209.25,742.06 3208.98,743.44 3209.09,744.17 3209.41,745.08 3210.13,744.99 3210.63,744.79 3211.65,743.85
  			3215.95,739.31 3218.17,738.05 3221.53,737.26 3222.54,736.74 3225.84,735.92 3228.58,735.69 3230.33,736.06 3231.32,736.05
  			3235.01,735.64 3235.42,736.14 3238.31,737.65 3239.96,739.83 3240.3,740.03 3240.57,740.43 3240.66,741.32 3240.91,741.87
  			3241.38,742.34 3242.82,743.36 3244.48,743.62 3244.86,743.06 3245.09,742.58 3245.84,739.91 3246.48,738.85 3246.49,738.21
  			3246.65,737.69 3248.26,736.91 3249.52,736.57 3254.07,733.99 3256.09,732.49 3256.94,732.57 3257.68,732.22 3258.35,731.76
  			3258.94,731.57 3260.17,730.51 3261.68,729.7 3261.42,728.99 3261.71,728.34 3262.56,728.1 3263.29,728.05 3264.24,727.29
  			3264.18,726.7 3264.52,726.45 3265.02,726.5 3265.68,726.73 3266.02,726.49 3265.43,725.67 3265.16,724.89 3265.58,724.46
  			3267.25,725.27 3267.9,725.27 3270.08,723.33 3272.04,721.27 3273.72,721.74 3276.38,720.24 3277.61,719.95 3279.55,719.87
  			3280.36,719.3 3281.24,718.26 3281.93,718.38 3285.26,717.14 3285.89,716.25 3286.72,716 3287.36,715.92 3288.81,715.18
  			3294.82,713.07 3295.74,712.22 3297.98,710.98 3299.7,710.58 3301.82,710.25 3302.08,709.85 3300.55,709.39 3300.18,708.97
  			3300.17,708.51 3300.4,708.13 3300.68,707.41 3301.25,707.24 3301.94,707.76 3302.66,707.76 3302.99,707.31 3303.34,707.35
  			3303.51,707.94 3303.14,708.62 3302.97,709.15 3302.95,709.75 3303.44,709.9 3306.07,709.96 3309.07,710.43 3317.02,712.95
  			3317.7,713.36 3318.34,713.4 3318.45,712.41 3318.82,711.46 3319.76,710.92 3321.35,709.57 3322.13,708.59 3322.13,707.46
  			3321.63,706.28 3320.54,706 3319.87,705.26 3319.51,704.35 3320.1,703.7 3320.74,703.44 3320.6,702.51 3320.13,701.94
  			3317.56,700.72 3316.67,699.89 3316.64,699.15 3315.58,697.28 3315.44,697.68 3315.53,698.59 3315.17,699.22 3314.62,699.12
  			3314.54,698.62 3314.85,697.53 3314.41,697.06 3312.83,696.83 3312.61,696.41 3314.18,695.81 3314.64,695.81 3315.06,695.59
  			3314.74,693.24 3314.38,691.59 3313.42,691.45 3312.71,690.97 3312.9,689.92 3312.92,689.4 3312.12,687.6 3311.54,686.87
  			3310.84,686.2 3310.29,686.95 3310,687.95 3309.28,688.16 3308.51,688.12 3306.27,687.01 3304.13,685.11 3303.93,683.37
  			3304.42,681.14 3304.08,680.77 3303.75,680.63 3300.9,681.38 3299.16,682.54 3298.22,682.66 3296.33,681.88 3293.25,683.19
  			3293.91,682.43 3294.44,682.13 3295.02,681.57 3294.59,680.49 3294.14,679.69 3293.48,679.12 3292.54,678.84 3291.6,679.38
  			3290.87,679.93 3289.35,680.51 3286.42,681 3284.45,681 3281.1,681.89 3279.84,681.86 3284.46,680.59 3286.98,680.5
  			3290.22,679.67 3292.57,678.07 3295.68,679.52 3296.75,679.73 3299.06,679.6 3301.06,680.46 3301.73,680.21 3302.36,679.43
  			3301.52,678.26 3300.8,677.54 3299.98,677.32 3297.5,677.44 3295.87,677.19 3294.93,676.89 3295.54,676.56 3297.32,677.02
  			3299.48,676.41 3301.99,677.34 3302.6,678.17 3303.32,678.48 3305.38,680.58 3306.79,681.41 3311.31,682.02 3313.28,682.96
  			3314.79,682.58 3318.59,680.52 3321.1,679.97 3322.81,678.62 3324.3,677.07 3325.75,676.62 3328.24,674.97 3328.82,674.18
  			3329.8,672.23 3330.37,671.41 3331.21,670.42 3331.27,669.61 3331.09,668.74 3330.39,668.05 3328.78,667.08 3328.04,666.44
  			3327.52,665.09 3327.57,663.46 3327.93,662.23 3328.42,661.13 3328.98,661.92 3330.61,661.74 3331.42,661.44 3331.51,660.63
  			3331.31,659.69 3331.7,659.44 3332.55,659.33 3332.67,658.76 3332.96,658.46 3333.27,658.86 3333.29,660.46 3333.95,660.86
  			3334.88,661.19 3335.28,661.09 3335.68,660.87 3336.25,660.39 3337.41,658.74 3338.16,658.08 3338.1,659.28 3337.65,660.93
  			3337.4,661.39 3336.75,661.97 3336.44,663.19 3336.3,663.52 3335.37,663.18 3334.68,663.25 3335.19,664.59 3335.93,665.59
  			3336.87,666.56 3337.89,667.13 3338.16,667.12 3338.36,667.32 3338.39,667.93 3338.16,669.37 3338.34,670.14 3339.12,670.72
  			3339.98,670.86 3345.13,670.8 3346.9,670.6 3349.54,669.25 3350.54,669.08 3351.67,669.25 3354.83,670 3358.65,671.06
  			3360.09,672.69 3360.65,674.34 3360.87,676.09 3360.62,676.79 3360.67,677.39 3361.83,678.28 3363.48,679.36 3363.79,679.61
  			3364.14,680.05 3364.53,680.24 3365.71,680.36 3366.63,680.15 3367.56,680.09 3369.29,680.6 3371.48,681.4 3373.61,682.49
  			3374.56,683.3 3376.27,685.05 3377.14,685.58 3378.56,686.2 3379.1,686.29 3379.62,686.18 3380.71,685.33 3381.37,684.64
  			3381.95,683.82 3382.1,684.53 3382.1,685.14 3381.54,685.57 3381.54,686.33 3382.39,687.21 3383.38,687.34 3384.63,687.1
  			3385.04,686.7 3385.42,686.13 3385.14,684.26 3385.52,684 3386.46,684.37 3386.76,684.9 3386.9,685.55 3387.28,685.62
  			3389.75,685.53 3389.94,685.29 3389.81,684.7 3389.45,684.15 3389.02,683.76 3387.94,683.3 3386.84,682.95 3385.94,682.86
  			3385.54,682.61 3385.64,682.13 3385.35,681.57 3385.53,681.24 3386.29,680.8 3386.38,680.4 3385.58,679.92 3384.72,679.77
  			3383.98,680.04 3384.15,679.63 3384.71,679.23 3385.57,679.05 3386.45,679.14 3388.14,678.8 3389.77,678.02 3390.57,677.51
  			3391.33,676.88 3391.25,675.77 3390.72,674.71 3389.94,674.42 3389.07,674.49 3388.3,674.4 3387.55,674.12 3390.52,673.75
  			3390.86,673.38 3391.35,671.69 3391.17,671.27 3390.55,671.38 3389.61,671.35 3389.83,671.01 3390.15,670.71 3388.44,669.1
  			3386.53,668.15 3387.07,667.99 3388.01,668.02 3389.46,668.31 3390.41,669.03 3390.75,669.52 3391.16,669.68 3392.02,669.73
  			3393.49,670.07 3393.57,670.54 3393.91,670.71 3394.9,670.51 3397.62,670.2 3398.49,670.28 3399.73,670.62 3400.12,670.63
  			3400.66,670.5 3401.09,669.96 3400.42,668.88 3399,667.91 3398.17,667.17 3397.75,666.58 3398,666.35 3398.38,666.44
  			3399.6,667.37 3400.55,667.95 3401.54,668.37 3402.42,668.68 3404.36,668.97 3405.25,668.49 3405.41,667.74 3405.23,666.17
  			3405.42,665.58 3406.6,664.71 3408.63,663.6 3409.94,663.24 3410.4,663.28 3410.89,663.62 3411.39,663.72 3412.26,662.9 		"/>
            <polygon className="st0" points="3042.19,927.87 3042,927.82 3041.77,927.96 3041.69,928.05 3041.81,928.1 3042.18,928.15
  			3042.41,928.1 3042.33,927.9 		"/>
            <polygon className="st0" points="2305.95,435.95 2307.47,436.34 2308.05,436.65 2308.33,436.95 2309.41,437.14 2312.43,436.51
  			2314.3,435.76 2313.38,434.06 2311.21,433.36 2306.64,434.61 2306.17,434.84 		"/>
            <polygon className="st0" points="2278.97,412.31 2282.98,411.6 2284.43,412.51 2285.68,412.58 2287,412.12 2288.28,411.32
  			2291.13,412.51 2293.58,413.37 2296.27,412.69 2297.47,412.13 2298.33,410.99 2297.46,410.02 2297.02,408.72 2301.34,408.34
  			2302.95,407.7 2301.88,406.78 2298.35,406.39 2297.05,405.93 2296.61,405.13 2295.82,404.75 2293.63,405.19 2292.86,405.35
  			2290.55,405.23 2289.56,405.85 2287.41,407.6 2284.77,408.33 2282.68,410.02 2279.52,409.49 2276.86,409.86 2277.76,411.52 		"/>
            <polygon className="st0" points="2297.91,402.1 2300.69,401.7 2309.63,400.81 2309.63,400.37 2310.07,399.46 2308.83,398.87
  			2299.34,399.41 2298.08,400.04 2297.74,401.04 2297.44,401.57 2297.54,401.8 		"/>
            <polygon className="st0" points="2309.21,423.62 2309.06,424.51 2309.19,425.51 2309.55,425.84 2312.1,427.28 2314.21,426.99
  			2317.39,426.04 2321.13,427.21 2323.9,427.53 2325.87,425.84 2328.5,425.25 2329.21,424.24 2329.95,423.95 2332.53,423.64
  			2332.86,422.33 2333.51,421.41 2333.81,420.14 2333.31,419.35 2332.76,418.72 2330.64,418.33 2328.5,418.19 2326.11,418.79
  			2321.96,419.51 2319.11,419.95 2317.39,420 2317.03,419.29 2315.85,419.07 2312.66,419.31 2311.62,419.71 2311.26,420.36
  			2310.8,420.69 2309.88,421.76 		"/>
            <polygon className="st0" points="2267.68,420.29 2269,420.37 2269.77,420.72 2270.14,421.24 2273.93,420.98 2277.49,421.94
  			2278.94,423.24 2280.38,423.41 2284.02,423.33 2288.22,422.72 2294.67,420.91 2295.63,420.19 2294.57,419.64 2289.02,417.8
  			2285.34,416.11 2282.79,415.49 2276.9,415.68 2270.57,413.76 2269.86,413.82 2268.03,416.34 2267.61,417.98 2266.54,418.02
  			2264.9,418.61 2265.08,419.76 		"/>
            <polygon className="st0" points="2268.01,426.47 2268.2,425.96 2267.94,425.15 2266.25,424.72 2266,424.48 2263.48,423.87
  			2263.33,424.7 2263.69,425.1 2263.68,425.63 2263.25,425.86 2262.93,426.4 2266.84,427.47 		"/>
            <polygon className="st0" points="2252.31,431.75 2252.78,431.95 2253.03,432.19 2254.88,432.3 2260.48,432.07 2261.59,431.34
  			2262.65,431.23 2263.27,430.45 2260.19,429.38 2259.01,428.55 2258.87,427.84 2257.66,427.64 2254.86,427.84 2253.7,428.91
  			2253.4,429.48 2252.53,429.9 2249.95,430.3 2249.47,430.54 2250.56,431.53 		"/>
            <polygon className="st0" points="2290.42,426.05 2289.88,426.55 2290.49,427.01 2291.35,427.96 2291.57,428.5 2291.59,429.29
  			2292.15,431.9 2292.58,432.59 2293.09,432.96 2296.52,433.64 2297.84,433.27 2299.58,431.85 2300.36,431.75 2300.61,430.85
  			2300.6,429.88 2301.56,429.47 2308.78,429 2307.6,428.11 2306.39,427.58 2302.25,426.62 2294.18,426.41 		"/>
            <polygon className="st0" points="2307.44,410.24 2309.26,409.98 2309.78,409.58 2308.99,408.75 2307.26,408.12 2305.62,408.24
  			2303.55,408.87 2303.34,409.33 2304.26,409.81 		"/>
            <polygon className="st0" points="2244.15,573.69 2243.31,575.13 2242.98,575.85 2242.86,577.56 2242.94,578.32 2243.55,580.34
  			2244.22,581.54 2245.07,582.26 2246.09,582.79 2247.15,583.05 2249.19,582.81 2251.2,582.08 2253.39,582.57 2255.33,582.73
  			2259.16,582 2259.56,582.18 2258.89,583.01 2258.78,584.22 2259.55,585.14 2260.43,585.1 2261.33,585.26 2261.07,585.82
  			2263.56,587.37 2265.83,588.47 2265.31,588.78 2263.86,588.28 2263.12,588.45 2263.31,589.32 2261.74,589.07 2261.04,589.6
  			2261.08,590.4 2261.28,591.17 2261.26,591.72 2260.03,591.94 2259.32,592.36 2262.18,593.26 2266.2,594.03 2267.32,594.34
  			2268.87,595.12 2269.58,595.33 2269.64,594.82 2269.95,594.38 2271.82,595.36 2273.37,595.53 2274.93,595.54 2278.73,595.14
  			2278.89,595.4 2278.89,595.91 2279.65,596.31 2280.58,596.15 2280.87,595.8 2282.09,596.27 2282.33,595.85 2282.56,595.67
  			2285.02,597.12 2285.57,597.06 2286.09,596.65 2284.87,595.56 2284.18,595.38 2283.55,594.79 2284.61,594.5 2285.66,594.58
  			2286.15,595.06 2286.6,595.7 2286.83,595.84 2291.01,596.72 2291.86,596.47 2292,596 2293.55,595.62 2295.05,594.58
  			2294.47,594.01 2293.86,593.6 2290.34,592.32 2288.9,591.53 2285.19,588.75 2281.72,585.06 2279.84,582.54 2278.11,579.69
  			2277.54,578.22 2276.91,576.87 2275.45,575.83 2276.1,574.58 2276.34,573.13 2277.11,571.93 2277.3,571.33 2276.3,569.85
  			2276.23,568.83 2275.97,568.34 2275.94,567.44 2276.45,566.87 2276.34,566.11 2276.66,565.69 2278.13,565.31 2278.84,564.15
  			2278.99,563.35 2279.03,562.63 2279.84,562.26 2282.07,562.26 2282.39,561.98 2282.8,561.32 2282.99,560.4 2282.77,559.14
  			2282.95,558.34 2284.18,557.04 2284.71,556.62 2284.98,555.62 2284.31,555.23 2282.52,554.73 2279.57,554.53 2275.63,553.88
  			2273.96,553.09 2272.44,552.67 2271.29,552.59 2270.21,552.88 2267.28,554.03 2266.22,554.32 2265.28,554.4 2263.38,554.03
  			2262.44,554.13 2261.43,554.66 2260.41,555.02 2259.11,555.24 2258.21,555.92 2257.76,556.49 2257.68,557.19 2258.09,558.69
  			2258.18,559.3 2259.21,560.21 2258.23,560.42 2257.23,560.27 2256.3,560.26 2255.36,560.48 2254.51,560.88 2252.55,562.23
  			2252.31,562.59 2252.12,563.09 2252.77,563.63 2253.42,563.98 2255.39,564.19 2254.74,564.98 2254.6,565.43 2254.95,566.09
  			2253.98,567.16 2253.68,567.89 2253.62,568.62 2253.25,569.48 2252.91,570.05 2252.61,570.32 2251.56,570.83 2251.1,571.71
  			2250.47,572.38 2249.79,572.77 2248.25,572.74 2246.71,572.4 2246.03,572.57 2244.75,573.25 		"/>
            <polygon className="st0" points="2322.01,417.47 2324.66,417.06 2327.45,415.85 2328.25,415.07 2327.33,414 2319.99,414.33
  			2316.29,415.74 2315.71,416.09 2315.54,416.38 2317.75,416.95 		"/>
            <polygon className="st0" points="2341.29,416.73 2338.8,418.18 2336.89,418.98 2336.28,419.6 2338.3,420.77 2340.34,421.75
  			2341.9,422.05 2343.47,422 2353.36,420.91 2357.14,419.66 2360.85,417.45 2360.3,416.71 2360.2,415.91 2360.38,414.94
  			2359.77,414.15 2358.61,413.2 2357.4,412.69 2355.5,412.14 2353.59,412.11 2351.35,412.58 2350.9,413.19 2350.51,413.95
  			2350.14,415.36 2349.55,416.1 2347.53,416.44 2345.5,416.45 		"/>
            <polygon className="st0" points="3039.8,930.06 3039.62,930.19 3039.66,930.33 3039.77,930.31 3039.97,930.18 3040.11,930.1
  			3040.23,929.99 3040.13,929.9 		"/>
            <polygon className="st0" points="2336.37,403.09 2336.72,403.37 2339.35,403.86 2344.77,404.11 2345.8,403.85 2346.91,403.03
  			2346.78,402.74 2346.29,402.27 2338.59,401.63 2334.29,401.88 2332.79,402.43 2336.23,402.84 		"/>
            <polygon className="st0" points="2000.97,808.15 2002,805.75 2001.94,805.37 2001.2,804.7 2000.28,804.21 1999.77,803.17
  			1997.91,803.11 1996.16,803.17 1995.61,803.12 1993.93,802.68 1992.32,801.99 1991.25,801.58 1990.34,801.12 1989.85,800.63
  			1989.08,800.76 1988.56,800.77 1988.45,802.6 1988.16,804.62 1986.54,805 1985.63,804.92 1984.67,804.48 1983.86,804.38
  			1983.16,804.07 1985.39,801.71 1986.22,800.59 1985.73,800.5 1985.27,801.14 1983.87,802.66 1982.53,803.92 1981.49,804.44
  			1979.06,804.37 1977.94,804.79 1977.76,805.85 1977.68,806.79 1976.96,808.14 1976.12,809.18 1974.82,810.17 1975.15,810.31
  			1977.51,810.47 1979.9,810.62 1983.75,810.78 1987.76,810.96 1991.92,811.13 1996.41,811.33 2001.16,811.44 2001.45,811.36
  			2001.1,810.8 2000.72,809.78 2000.76,808.97 		"/>
            <polygon className="st0" points="2169.14,654.04 2169.46,653.85 2169.26,653.38 2168.76,652.71 2168.06,652.52 2167.39,652.66
  			2167.32,652.88 2167.47,653.38 		"/>
            <polygon className="st0" points="2191.99,424.75 2194.11,425.21 2196.88,424.57 2197.33,425.13 2197.64,426.25 2198.33,426.98
  			2200.32,426.78 2201.46,426.41 2202.39,425.14 2202.84,424.73 2205.65,424.72 2206.78,423.8 2207.23,423.68 2208.12,423.85
  			2209.05,422.51 2209.87,422.24 2210.61,421.18 2211.5,420.72 2212.11,420.9 2214.35,422.63 2219.25,423.4 2219.74,423.32
  			2219.76,421.66 2218.59,420.45 2217.73,419.91 2216.89,419.66 2216.03,419.57 2213.13,419.78 2209.28,418.97 2208.53,418.99
  			2205.73,419.75 2203.87,420.92 2199.89,421.31 2189.76,422.95 2187.91,423.75 2189.97,424 		"/>
            <polygon className="st0" points="3038.8,929.82 3038.66,929.84 3038.51,929.84 3038.39,929.79 3038.28,929.81 3038.28,929.89
  			3038.39,929.95 3038.43,930.06 3038.49,930.2 3038.6,930.17 3038.8,930.14 3038.91,930.13 3038.89,929.94 		"/>
            <polygon className="st0" points="3028.31,872.84 3027.65,872.14 3026.18,870.89 3025.41,869.49 3024.83,868.06 3024.56,866.75
  			3024.22,865.46 3023.73,864.24 3022.94,861.67 3020.99,857.42 3020.32,854.95 3018.62,846.74 3018.23,846.39 3018.1,846.15
  			3018.05,845 3017.95,844.22 3017.64,843.67 3016.82,842.97 3016.64,842.42 3016.6,841.17 3016.23,840 3015.72,838.92
  			3015.43,837.35 3015.57,834.35 3015.81,833.29 3016.34,832.89 3016.6,832.32 3016.84,831.36 3016.92,830.38 3016.85,827.39
  			3016.54,825.43 3016.31,824.49 3016,823.59 3014.92,821.38 3013.43,817.87 3013.58,817.67 3013.37,816.9 3013.5,816.01
  			3013.84,815.16 3014,814.5 3013.92,813.86 3012.1,810.93 3011.53,810.67 3011.31,811.35 3010.88,811.98 3010.34,812.26
  			3008.52,812.24 3009.62,813.78 3011.34,815.86 3011.64,816.7 3011.45,817.62 3011.11,818.53 3011.5,818.6 3011.42,819.25
  			3010.35,819.5 3009.98,820.24 3010.61,820.83 3010.13,821.85 3009.27,822.27 3008.82,822.37 3008.38,822.33 3007.21,821.43
  			3006.89,821.3 3005.4,821.75 3004.45,822.52 3004.21,823.09 3004.34,825.39 3004.63,826.52 3004.48,829.32 3004.04,832.02
  			3003.57,833.16 3003.02,834.24 3002.84,835.22 3002.9,839.04 3003.34,840.02 3004.08,840.66 3003.77,841.08 3003.36,841.26
  			3003.77,841.77 3004.62,842.45 3005.74,843.67 3006.46,844.69 3007.44,847 3007.45,849.5 3006.94,850.7 3006.54,851.96
  			3006.25,853.58 3006.29,854.85 3006.9,857.09 3006.98,858.14 3006.89,865.25 3006.61,866.66 3006.25,868.05 3005.86,870.59
  			3005.52,871.75 3004.57,874.16 3004.63,874.68 3004.83,875.19 3005.94,877.11 3006.83,879.12 3007.23,882.1 3006.95,883.29
  			3006.33,884.29 3005.83,885.45 3005.39,886.66 3005.38,887.12 3005.56,889.21 3005.84,890.31 3006.02,891.42 3005.79,892.59
  			3004.57,896.15 3004.27,898.72 3004.99,901.67 3005.1,902.54 3005.37,903.33 3005.83,903.87 3006.34,904.34 3006.96,903.48
  			3007.45,902.53 3008.26,899.71 3008.64,898.64 3009.12,897.63 3009.73,896.68 3010.56,896.08 3011.05,895.92 3011.52,895.98
  			3011.99,896.4 3012.39,896.93 3012.68,897.09 3014.52,897.23 3016.49,897.58 3017.08,898.46 3017.24,899.7 3017.64,901.13
  			3017.75,903.17 3018.02,902.74 3018.18,902.25 3018.25,901.63 3018.4,901.05 3019.01,899.67 3018.99,899.2 3018.67,897.41
  			3018.21,895.54 3017.88,895.12 3017.35,894.97 3016.8,894.95 3015.95,895.09 3015.62,894.57 3014.87,892.9 3014.16,890.55
  			3013.61,889.48 3013.32,889.06 3012.97,888.74 3012.44,888.48 3011.97,888.1 3011.34,887.2 3010.57,885.63 3010.39,885.05
  			3010.29,883.47 3010.53,881.46 3011.18,879.59 3013.88,872.34 3014.34,870.3 3015,869.29 3016.11,868.59 3017.34,868.29
  			3020.29,868.05 3021.02,868.09 3022.27,868.44 3022.95,868.74 3023.6,869.18 3024.93,870.69 3026.01,871.59 3027.06,872.6
  			3027.77,873.45 3028.21,874.93 3028.55,875.34 3028.49,873.4 		"/>
            <polygon className="st0" points="2239.89,436.96 2242.88,437.19 2242.91,436.97 2242.7,436.73 2242,436.19 2241.29,435.82
  			2241.25,435.45 2241.39,434.71 2238.71,433.83 2236.52,434.72 2234.81,434.97 2231.6,436.03 2234.65,437.11 		"/>
            <polygon className="st0" points="2222.01,613.04 2221.1,613.26 2219.3,614.14 2217.47,615.48 2216.68,616.62 2216.48,617.89
  			2216.34,619.18 2216.33,620.02 2216.46,620.85 2216.64,621.47 2217.68,623.5 2219.6,624.55 2221.65,624.41 2223.92,623.89
  			2227.68,622.69 2229.48,621 2230.16,619.83 2231.62,618.76 2232.01,619.16 2232.21,620.06 2232.68,619.89 2233.21,619.3
  			2233.06,617.86 2232.23,616.8 2230.79,616.02 2224.3,613.01 		"/>
            <polygon className="st0" points="2231.26,434.22 2231.43,433.54 2233.51,432.32 2233.44,432.06 2232.94,431.4 2229.85,431.2
  			2227.09,432.58 2227.36,433.02 2230.58,434.48 		"/>
            <polygon className="st0" points="2234.3,414.39 2235.19,415.16 2236.01,415.25 2237.17,415.13 2237.47,414.64 2238.8,413.92
  			2238.23,413.07 2236.86,412.67 2235.81,412.69 2235.22,412.92 2235.09,413.2 2234.74,413.56 2233.93,413.63 2234,414.03 		"/>
            <polygon className="st0" points="2257.82,587.94 2257.72,587.08 2257.28,586.67 2256.72,586.73 2256.04,585.9 2255.28,584.76
  			2254.21,584.21 2253.84,584.15 2253.74,584.37 2253.82,584.91 2252.87,584.47 2251.99,584.44 2250.17,584.88 2249.69,585.38
  			2249.77,586 2250.11,586.23 2251.25,586.71 2252.27,586.53 2253.89,587.62 2254.32,588.63 2254.71,589.08 2255.67,589.58
  			2256.13,590.23 2256.28,590.89 2257.11,590.69 2256.98,590.42 2256.5,589.93 2256.7,589.4 		"/>
            <polygon className="st0" points="2223.14,425.62 2219.25,425.77 2217.9,424.8 2217.37,424.58 2216.56,424.73 2215.73,425.07
  			2213.1,425.36 2211.08,425.92 2208.95,427.01 2202.56,429.83 2203.35,430.66 2204.26,431.06 2205.48,432.12 2207.65,432.17
  			2208.45,432 2209.3,431.2 2210.97,430.9 2213.08,431.02 2213.79,431.54 2211.65,432.73 2210.88,433.49 2211.77,434.07
  			2213.47,433.94 2214.2,433.73 2214.79,433.29 2215.39,433.09 2217.23,433.8 2217.91,433.52 2218.47,432.59 2218.65,432.11
  			2218.88,431.87 2220.7,432.54 2221.49,432.65 2222.21,432.51 2222.49,431.64 2222.33,430.96 2222.06,430.51 2221.75,430.29
  			2219.78,430.03 2219.68,429.84 2220.81,428.79 2221.53,428.49 2227.34,428.35 2229.09,427.39 2228.72,426.48 2229.53,425.98
  			2233.18,425.4 2238.92,425.14 2240.48,423.89 2245.17,422.25 2244.23,421.2 2243.08,421.13 2238.55,418.24 2237.57,417.77
  			2234.46,417.84 2233.17,417.51 2231.87,417.58 2226.68,418.74 2224.46,419.61 2223.65,421.77 2224.03,422.87 2223.96,424.78 		"/>
            <polygon className="st0" points="3346.53,586.34 3346.1,586.05 3344.14,585.14 3342.81,584.36 3342.12,583.63 3341.47,583.19
  			3340.79,582.94 3339.62,582.2 3338.9,582.01 3335.21,581.45 3334.22,581.2 3333.23,581.15 3332.01,581.54 3330.79,581.61
  			3329.57,581.37 3328.35,581.44 3327.06,581.86 3325.75,582.07 3324.79,582.29 3323.36,583.18 3321.94,583.47 3319.31,585.38
  			3316.41,586.83 3315.89,587.66 3314.66,588.78 3314.2,589.68 3314.36,590.4 3315.58,593.15 3316.16,593.08 3318.61,592.25
  			3322.78,591.97 3324.75,590.78 3325.75,590.52 3327.99,590.84 3329.9,591.59 3330.67,591.66 3332.85,591.34 3338.15,590.18
  			3342.07,589.76 3344.1,589.36 3346.61,587.83 3346.82,587.02 		"/>
        </g>
    </>,
    SRB: <>
        <polygon className="st1" points="2002.24,931.91 2001.9,931.42 2001.46,930.87 2000.87,930.49 1999.67,929.85 1999.2,929.19
  		1998.99,928.33 1998.67,927.69 1998.32,927.26 1998.25,926.92 1998.11,926.5 1998.07,925.67 1998.36,924.56 1998.54,924.17
  		1998.95,924.07 2000.03,923.48 2000.08,922.72 2000.27,922.25 2000.62,921.98 2000.93,921.8 2000.79,921.69 2000.75,921.3
  		2000.45,920.99 1999.9,920.76 1999.47,920.36 1999.16,919.76 1999.23,919.2 1999.66,918.68 2000.22,918.45 2000.9,918.52
  		2001.18,918.37 2001.07,918 2000.4,917.53 1999.19,916.96 1997.95,917.27 1996.69,918.47 1995.78,918.66 1995.22,917.85
  		1994.23,917.38 1992.81,917.23 1991.93,916.92 1991.6,916.45 1990.98,916.09 1989.61,915.71 1989.59,915.35 1989.81,915.26
  		1990.3,915.23 1990.95,915.15 1991.06,914.94 1991.06,914.76 1990.55,914.52 1990.03,914.35 1989.75,914.19 1989.58,914.01
  		1989.55,913.82 1989.7,913.69 1989.91,913.68 1990.12,913.57 1990.23,913.13 1990.51,912.77 1990.72,912.64 1990.7,912.37
  		1990.49,912.13 1990.21,911.91 1989.79,911.78 1988.48,911.4 1987.82,910.88 1987.41,910.86 1986.77,910.57 1986.08,910.11
  		1985.49,909.46 1984.84,909.04 1984.67,908.87 1984.66,908.7 1984.78,908.52 1984.77,908.32 1984.6,907.69 1984.71,907.01
  		1984.68,906.38 1984.68,906.09 1984.55,906 1984.44,906.1 1984.28,906.22 1984.12,906.24 1983.65,905.78 1983.05,904.84
  		1982.64,904.52 1981.84,904.09 1981.17,903.73 1980.69,902.94 1980.19,902.33 1979.92,902.14 1979.51,901.98 1977.6,901.77
  		1976.84,901.93 1975.83,901.87 1974.9,901.69 1974.2,901.84 1973.58,902.55 1973.28,902.79 1973.04,902.95 1972.51,903.17
  		1972.07,903.44 1971.48,903.63 1970.96,903.6 1970.46,903.3 1970.29,903.37 1970.13,903.65 1969.86,903.89 1969.12,904.19
  		1968.93,904.18 1968.9,904.44 1968.83,904.88 1968.37,905.19 1968.84,905.91 1969.28,907.08 1969.04,907.65 1969.34,908.1
  		1970.19,908.42 1970.27,908.55 1970.01,908.68 1969.79,909.06 1969.77,909.75 1970.51,910.41 1972.03,911.02 1972.51,911.12
  		1972.7,911.36 1972.95,911.51 1973.1,911.7 1973.11,911.95 1973,912.11 1972.28,912.17 1971.46,912.17 1970.88,911.87
  		1970.83,912.09 1970.82,912.34 1970.26,912.49 1970.57,914.18 1970.45,914.67 1970.24,914.84 1970.05,914.77 1969.81,914.75
  		1969.69,914.91 1969.79,915.27 1970.08,915.25 1970.84,914.96 1971.72,914.8 1972.36,914.98 1972.67,915.15 1972.73,915.38
  		1972.55,916.19 1972.19,917.06 1971.61,917.96 1971,918.81 1970.84,919.26 1970.8,919.98 1970.73,920.54 1970.81,920.85
  		1971.01,921.13 1971.68,921.36 1972.58,921.92 1973.35,922.66 1974.34,923.49 1974.65,923.8 1974.64,924.14 1974.36,924.38
  		1973.51,924.48 1972.64,924.4 1972.3,924.32 1971.99,924.42 1971.79,924.61 1971.89,924.83 1972.79,925.85 1973.84,927.29
  		1973.9,927.92 1973.77,928.41 1973.53,928.74 1973.09,928.69 1972.76,928.42 1972.26,928.44 1971.87,928.52 1971.37,929.04
  		1971.34,929.16 1971.38,929.53 1971.57,929.89 1972.24,930.26 1973.22,930.99 1974.37,932.32 1974.9,932.72 1975.38,932.81
  		1976.31,933.37 1976.96,933.5 1977.68,933.65 1979.56,934.8 1980.41,935.14 1981.01,935.57 1981.08,935.98 1981.05,936.24
  		1982.1,935.93 1982.25,935.72 1982.01,935.25 1982.15,934.96 1983.41,934.14 1983.61,933.76 1983.69,933.47 1983.52,933.16
  		1983.29,932.67 1983.4,932.46 1984.05,932.18 1984.58,931.85 1984.89,931.82 1985.09,932.06 1985.09,932.3 1985.26,932.72
  		1985.65,932.94 1986.3,933.3 1987.05,933.55 1987.64,934.05 1988.45,934.93 1988.57,935.37 1989.3,935.76 1989.97,936.2
  		1989.86,937.01 1992.15,937.72 1992.67,937.72 1992.92,937.84 1992.91,938.03 1992.73,938.6 1991.79,940.34 1991.71,940.7
  		1991.04,941.08 1990.94,941.3 1991.14,941.78 1991.31,942.12 1991.78,942.17 1992.8,941.92 1993.43,941.56 1993.76,941.5
  		1994.19,941.37 1994.81,941.39 1995.43,941.54 1996.23,941.34 1997.02,941 1997.33,941.08 1997.67,941.37 1997.89,941.45
  		1998.55,941.3 1998.75,940.99 1999.41,940.16 1999.48,939.75 1999.41,939.52 1998.97,939.13 1998.67,938.25 1998.9,937.44
  		1998.92,937.02 1998.7,936.6 1998.93,936.09 1999.4,935.8 1999.7,935.72 2000.94,935.67 2001.73,934.63 2002.21,934.3
  		2002.7,933.71 2002.93,933.5 2003.15,933.04 2003.22,932.57 	"/>
    </>,
    ROM: <>
        <polygon className="st1" points="2059.44,910.65 2058.73,910.05 2057.35,909.54 2055.84,909.72 2054.18,910.58 2053.06,910.9
  		2052.47,910.68 2052.12,910.69 2051.99,910.93 2052.01,911.14 2052.2,911.29 2052.17,911.41 2051.94,911.48 2049.33,910.87
  		2048.21,910.3 2047.32,909.22 2046.9,908.56 2046.47,907.98 2046.16,907.67 2046.29,907.53 2046.63,907.36 2046.88,907.17
  		2046.85,906.48 2046.67,905.68 2046.5,905.3 2046.49,904.7 2046.37,903.76 2046.53,902.01 2047.21,899.81 2047.59,898.72
  		2047.41,898.12 2047.55,896.72 2047.25,896.02 2046.79,895.12 2046.14,893.14 2045.31,892.45 2044.3,891.7 2043.87,891.12
  		2043.58,890.49 2042.97,889.87 2042.28,889.29 2041.45,887.85 2041.02,887.2 2040.89,887.02 2039.95,886.1 2039.45,885.26
  		2039.2,884.57 2039.05,883.94 2038.39,882.68 2037.79,881.73 2037.21,881.05 2036.95,880.57 2036.27,879.97 2035.32,879.49
  		2034.7,879.41 2033.9,879.45 2033.51,879.57 2032.41,879.77 2031.26,880.05 2031.02,881.02 2030.68,881.55 2030.06,882.32
  		2027.92,882.59 2026.07,882.96 2024.17,883.2 2021.69,884.14 2020.89,884.96 2020.09,885.2 2019.37,885.26 2018.9,884.8
  		2017.32,883.56 2016.72,882.98 2015.92,882.81 2015.05,882.9 2014.22,883.19 2013.34,883.25 2012.34,882.83 2012.24,882.87
  		2011.86,882.93 2009.39,882.43 2009.16,882.34 2009.05,882.32 2008.71,882.28 2006.86,882.35 2005.13,881.33 2004.59,881.3
  		2004.18,881.71 2003.88,882.17 2002.68,882.62 2002.38,882.81 2002.17,883.07 2000.7,884.39 2000.12,884.74 1999.74,884.82
  		1999.13,884.68 1998.51,884.78 1997.96,885.07 1997.44,885.16 1997.06,885.49 1996.55,886.21 1995.94,886.82 1995.32,887.2
  		1994.99,887.54 1994.96,888.7 1994.61,889.04 1994.15,889.38 1993.9,889.67 1993.19,891.44 1992.65,892.01 1992.14,892.45
  		1992.06,892.84 1992.07,893.3 1991.49,894.2 1990.74,895.14 1990.59,895.53 1990.76,896.05 1990.04,896.64 1989.62,896.93
  		1989.27,897.07 1989.05,897.44 1988.7,898.35 1988.79,898.76 1988.8,899.13 1988.19,899.35 1988.01,899.76 1987.85,900.27
  		1987.6,900.5 1986.91,900.92 1985.2,900.74 1984.55,900.88 1984.36,901.18 1984.32,901.42 1984.11,901.65 1983.72,901.94
  		1983.32,902.07 1982.43,901.71 1980.52,902.07 1980.19,902.33 1980.69,902.94 1981.17,903.73 1981.84,904.09 1982.64,904.52
  		1983.05,904.84 1983.65,905.78 1984.12,906.24 1984.28,906.22 1984.44,906.1 1984.55,906 1984.68,906.09 1984.68,906.38
  		1984.71,907.01 1984.6,907.69 1984.77,908.32 1984.78,908.52 1984.66,908.7 1984.67,908.87 1984.84,909.04 1985.49,909.46
  		1986.08,910.11 1986.77,910.57 1987.41,910.86 1987.82,910.88 1988.48,911.4 1989.79,911.78 1990.21,911.91 1990.49,912.13
  		1990.7,912.37 1990.72,912.64 1990.51,912.77 1990.23,913.13 1990.12,913.57 1989.91,913.68 1989.7,913.69 1989.55,913.82
  		1989.58,914.01 1989.75,914.19 1990.03,914.35 1990.55,914.52 1991.06,914.76 1991.06,914.94 1990.95,915.15 1990.3,915.23
  		1989.81,915.26 1989.59,915.35 1989.61,915.71 1990.98,916.09 1991.6,916.45 1991.93,916.92 1992.81,917.23 1994.23,917.38
  		1995.22,917.85 1995.78,918.66 1996.69,918.47 1997.95,917.27 1999.19,916.96 2000.4,917.53 2001.07,918 2001.18,918.37
  		2000.9,918.52 2000.22,918.45 1999.66,918.68 1999.23,919.2 1999.16,919.76 1999.47,920.36 1999.9,920.76 2000.45,920.99
  		2000.75,921.3 2000.79,921.69 2000.93,921.8 2001.52,922.24 2002.96,922.94 2003.66,923.45 2003.62,923.76 2003.3,924.08
  		2002.67,924.38 2002.31,924.79 2002.21,925.29 2002.3,925.64 2002.74,925.95 2005.31,925.55 2007.92,925.76 2011.43,926.44
  		2013.75,926.68 2015.47,926.36 2018.65,926.94 2021.61,927.47 2024.45,927.63 2026.04,927.21 2027.16,926.65 2028.13,925.58
  		2030.5,924.18 2032.81,923.39 2035.83,922.75 2037.84,922.53 2038.12,922.75 2040.69,924.04 2041.83,924.04 2042.76,924.27
  		2043.09,924.61 2043.33,924.7 2044.56,924.38 2045.1,925.09 2045.95,926.08 2047.4,926.58 2048.69,926.87 2049.1,926.92
  		2050.46,926.9 2050.51,926.33 2051.08,924.42 2050.97,921.2 2051.42,920.39 2052.71,919.03 2053.01,918.32 2052.33,918.42
  		2052.38,918.03 2052.66,917.68 2052.68,916.86 2052.86,916.51 2053.33,915.88 2053.04,914.76 2053.37,914.27 2053.79,913.99
  		2054.76,914.17 2054.36,914.68 2054.54,915.25 2054.64,916 2054.36,916.43 2058.65,915.54 2059.05,914.79 2059.3,914.12
  		2059.67,912.34 2059.76,911.9 2059.9,911.21 	"/>
    </>,
    REU: <>
        <polygon className="st0" points="2279.16,1497.17 2278.51,1496.16 2277.96,1495.93 2276.73,1495.8 2275.56,1496.14 2275.04,1497
  		2274.9,1497.49 2275.55,1498.88 2275.99,1499.37 2277.63,1500.12 2278.46,1500.21 2279.65,1499.95 2279.87,1499.41
  		2280.01,1498.19 	"/>
    </>,
    QAT: <>
        <polygon className="st1" points="2244.32,1103.67 2243.98,1102.45 2243.62,1101.51 2243.55,1100.95 2243.34,1100.3 2243.69,1098.9
  		2244.07,1098.01 2243.82,1096.92 2242.53,1095.95 2241.46,1094.67 2240.16,1095.33 2239.27,1096.22 2238.44,1098.52
  		2238.14,1099.52 2237.59,1100.55 2237.25,1101.02 2237.18,1101.43 2237.37,1103.41 2237.96,1105.98 2237.87,1106.32
  		2237.6,1106.87 2238.03,1107.84 2238.64,1108.6 2238.96,1108.79 2239.44,1108.86 2240.03,1108.87 2240.75,1108.67 2241.51,1108.49
  		2242.59,1108.15 2242.85,1107.95 2243.74,1105.96 2244.19,1105.3 2244.38,1104.52 	"/>
    </>,
    PRY: <>
        <polygon className="st1" points="1352.85,1523.83 1351.76,1523.15 1351.18,1522.54 1350.43,1522.09 1349.62,1521.74 1349.23,1521.89
  		1348.81,1522.09 1348,1522.42 1347.08,1522.97 1346.61,1523.18 1345.77,1523.39 1344.83,1523.56 1344.05,1523.44 1343.38,1523.33
  		1342.96,1522.97 1342.74,1522.21 1342.74,1521.57 1342.6,1520.63 1342.1,1520.1 1341.93,1519.69 1341.9,1519.19 1341.96,1518.64
  		1342.1,1518.23 1342.01,1517.73 1341.79,1517.38 1341.85,1516.77 1341.74,1515.92 1341.4,1515.39 1341.23,1514.78 1341.23,1514.17
  		1340.98,1513.55 1340.96,1512.88 1341.18,1512.27 1341.26,1511.66 1341.01,1511.22 1340.54,1510.96 1340.18,1510.26
  		1340.12,1509.35 1339.73,1508.86 1339.31,1508.45 1338.84,1508.45 1338.11,1508.22 1337.47,1508.25 1336.44,1508.22
  		1335.97,1508.07 1335.72,1507.75 1335.08,1507.32 1334.72,1506.56 1334.27,1506.42 1333.63,1506.65 1333.41,1506.94
  		1333.16,1507.35 1332.71,1507.81 1332.13,1507.78 1331.51,1508.05 1330.93,1508.07 1330.15,1508.13 1329.37,1507.9
  		1328.42,1507.64 1327.61,1507.46 1326.83,1507.64 1326.3,1507.49 1325.61,1507.41 1324.83,1507.35 1324.21,1506.88
  		1323.55,1506.62 1323.18,1506.71 1322.71,1507 1322.21,1506.94 1321.57,1506.71 1321.32,1506.15 1321.37,1505.81 1321.51,1505.46
  		1321.76,1504.96 1321.62,1504.44 1321.68,1503.98 1321.79,1503.57 1321.9,1503.1 1321.82,1502.67 1321.79,1502.2 1321.73,1501.77
  		1321.65,1501.31 1321.99,1500.64 1322.26,1500.09 1322.1,1499.62 1322.15,1499.31 1322.38,1498.78 1322.65,1498.15
  		1322.63,1496.96 1322.38,1496.27 1322.1,1496.08 1322.03,1495.87 1322.17,1495.59 1322.02,1495.31 1321.97,1495.02
  		1322.11,1494.77 1321.91,1494.27 1321.51,1494.13 1321.37,1493.98 1321.23,1493.43 1321.12,1492.8 1321.18,1492.31
  		1320.98,1491.87 1320.7,1491.61 1320.43,1491.15 1320.15,1490.81 1320.04,1490.32 1319.85,1489.68 1320.02,1488.24
  		1319.85,1486.98 1319.68,1486.66 1317.2,1485.17 1314.95,1483.81 1312.01,1482.04 1308.22,1482.09 1304.29,1482.14
  		1300.55,1482.93 1296.87,1483.71 1295.12,1484.08 1291.62,1484.82 1289.55,1485.16 1289.02,1486.59 1288.21,1488.73
  		1287.41,1489.98 1286.48,1491.3 1285.18,1493.15 1285.18,1495.41 1285.18,1497.56 1284.26,1500.58 1283.48,1503.16
  		1282.73,1505.65 1282.21,1507.36 1282.02,1507.8 1282.24,1508.05 1282.23,1508.3 1282.95,1508.82 1284.37,1509.61 1285.7,1511.14
  		1286.95,1513.4 1288.11,1515.08 1289.2,1516.16 1290.21,1516.93 1291.12,1517.38 1291.61,1517.74 1291.67,1518.02 1292.53,1518.6
  		1294.17,1519.48 1295.22,1520.36 1295.65,1521.24 1297.28,1522.15 1300.1,1523.08 1302.14,1523.53 1303.42,1523.49
  		1305.26,1524.24 1307.65,1525.78 1309.11,1526.84 1309.63,1527.44 1311.2,1528.4 1315.1,1530.4 1316.82,1530.9 1317.64,1531.36
  		1318.12,1531.94 1318.6,1532.11 1319.07,1531.89 1320.05,1532.1 1321.54,1532.74 1322.7,1533.52 1324.2,1535.23 1324.67,1535.92
  		1324.88,1536.53 1324.8,1537.07 1324.35,1537.65 1323.51,1538.26 1323.26,1538.53 1323.24,1538.79 1323.03,1539.3 1322.33,1540.41
  		1322.16,1540.92 1322.12,1541.3 1321.68,1541.72 1320.5,1542.48 1320.26,1542.86 1320.2,1543.26 1320.05,1543.5 1319.89,1543.6
  		1319.67,1544 1319.49,1544.67 1319.47,1545.52 1319.61,1546.56 1319.59,1546.9 1319.33,1547.08 1319.18,1547.32 1319.13,1547.81
  		1318.91,1548.17 1318.52,1548.39 1318.38,1548.65 1318.48,1548.95 1318.19,1549.24 1317.52,1549.53 1317.11,1549.95
  		1316.96,1550.5 1316.58,1550.99 1315.99,1551.42 1315.79,1552 1316.1,1553.07 1319.78,1552.7 1322.78,1553.09 1326.32,1554.12
  		1328.68,1554.48 1329.84,1554.17 1330.7,1554.21 1331.26,1554.61 1332.01,1554.69 1332.96,1554.46 1333.74,1554.64
  		1334.36,1555.23 1334.92,1555.08 1335.43,1554.19 1336.01,1553.54 1336.66,1553.13 1337.47,1553.01 1338.45,1553.17
  		1339.23,1553.5 1339.81,1553.99 1340.44,1553.98 1341.13,1553.46 1341.46,1552.83 1341.43,1552.1 1341.71,1551.59 1342.28,1551.27
  		1342.67,1550.85 1342.87,1550.32 1343.55,1549.99 1344.71,1549.87 1345.32,1549.61 1345.37,1549.21 1345.72,1548.83
  		1346.35,1548.49 1346.78,1548.06 1347.02,1547.55 1347.4,1547.23 1347.93,1547.1 1348.53,1546.03 1349.18,1544.01 1349.56,1541.29
  		1349.7,1537.45 1349.74,1536.16 1350.9,1534.27 1351.21,1533.38 1351.06,1532.88 1351.41,1531.12 1352.25,1528.1 1352.52,1526.12
  		1352.21,1525.19 1352.21,1524.54 1352.64,1523.99 	"/>
    </>,
    PLW: <>
        <path className="st0" d="M2914.32,1293.09l-0.12,0.12l-0.01,0.12l0.12,0.03l0.19-0.04l0.12-0.13l0.01-0.11l-0.13-0.04L2914.32,1293.09z
  		 M2943.8,1254l-0.36,0.74l-0.08,0.07l-0.37,0.18l-0.34,0.57l-0.08,0.75l0.24,0.64l0.51-0.18l0.02-0.47l0.29-0.53l0.23-1.36
  		L2943.8,1254z"/>
    </>,
    PRT: <>
        <path className="st0" d="M1580.11,976.4l-0.77,0.13l-0.29,0.23l0.2,0.67l0.5,0.36l1.75,0.26l0.17-0.09l0.28-0.35l0-0.61l-0.72-0.48
  		L1580.11,976.4z M1570.48,978.84l-0.91,0l-0.32,0.34l0.15,0.55l0.65,0.53l1.03-0.04l0.85,0.28l0.35-0.19l1.05-0.08l-0.69-0.39
  		L1570.48,978.84z M1568.35,978.25l-0.36-0.24l-1.22,0.39l0.83,0.74l0.86-0.03l0.3-0.25l-0.16-0.35L1568.35,978.25z M1546.92,969.34
  		l-0.51,0.24l-0.19,1.01l0.21,0.18l0.64,0.17l0.37-0.47l-0.01-0.71L1546.92,969.34z M1571.26,976.98l1.04,0.87l0.8,0.34l2.24,0.75
  		l0.4-0.12l-1.55-0.79L1571.26,976.98z M1667.12,1033.45l-1.06,0.24l-1.15-0.49l-0.42,0.57l0.13,0.38l0.46,0.42l1.29,0.55l1.52,0.14
  		l0.61-0.57l0.61-0.45l-0.68-0.15L1667.12,1033.45z M1757.3,949.04l-0.18-0.42l-0.55-0.41l-0.7-0.23l-0.78,0.01l-0.48-0.08
  		l-0.14-0.32l0.05-0.86l-0.04-0.85l-0.15-0.39l-0.36-0.3l-0.72,0.08l-0.62-0.24l-0.47-0.06l-0.27,0.19l-1.39-0.05l-0.58-0.14
  		l-0.4-0.17l-0.26,0.1l-0.15,0.17l-0.03,0.26l-0.09,0.34l-0.5,0.31l-1.14,0.31l-0.92-0.02l-0.84-0.22l-0.27-0.16l-0.41-0.15
  		l-1.71,0.18l-0.21-0.13l-0.59,0.32l-0.87,0.38l-0.49,0.02l-0.18-0.08l-0.06-0.17l-0.37-0.59l0.1-0.31l0.7-0.92l-0.08-0.22
  		l-0.29-0.3l-0.26-0.43l-0.08-0.22l-0.45-0.04l-0.48,0.23l-1.82,0.46l-0.43,0.17l-0.79,0.45l-0.79,0.68l-0.85,1.1l-0.08,0.68
  		l0.35,0.6l0.77,0.07l-0.47,0.47l0.04,0.92l0.57,2.76l0.54,1.3l0.12,0.68l0.04,0.57l-0.16,1.13l-0.09,1.64l-0.4,1.01l-0.34,0.45
  		l-0.84,3.45l-0.12,0.79l0.3,0.27l0.11,0.36l-1.4,2.92l-1.21,2.75l-0.87,1.15l-0.57,0.34l-0.46,0.52l0.15,0.53l0.04,0.36l-0.52,1.34
  		l-0.14,1.49l-0.36,1.05l-0.04,0.53l0.05,0.67l0.54,0.23l0.45,0.09l0.88-0.15l0.98-0.29l0.38-0.9l1.15-1.78l0.73-0.49l0.64-0.12
  		l-1.23,0.79l-0.53,0.93l0.2,0.98l-0.37,0.55l-0.6,0.49l-0.71,0.09l-0.61,0.3l0.4,1.15l-0.08,0.89l0.22,0.1l0.76-0.17l1.53-0.56
  		l0.45,0.02l0.53-0.08l0.55,0.35l0.55,0.57l-1.79-0.22l0.59,1.43l0.07,1.13l-0.65,2.19l0.47,0.84l0.26,1.35l-0.22,1.36l0.04,1.57
  		l-0.94,2.56l-0.6,1.29l0.53,0.16l0.73-0.58l0.92,0.01l1.19-0.45l0.95,0.21l2.93,0.22l1.66,0.69l0.89,0l2.87-1.57l0.74-0.11
  		l-0.51-2.41l-0.24-0.93l-0.06-0.6l0.5-1.39l0.55-0.56l0.73-1.17l0.9-0.97l0.95-0.23l0.42-0.14l0.35-0.75l0.2-0.64l-0.14-0.06
  		l-1.11,0.13l-1.99-2.7l0.06-0.43l0.25-0.64l0.17-0.81l0.04-0.64l0.52-0.55l0.8-0.55l0.67-0.79l0.34-0.77l0.07-0.7l-0.38-0.5
  		l-1.09-0.28l-1.12-2l-0.25-1.25l-0.23-0.13l-0.69-0.57l-0.67-1.07l-0.1-0.17l0.69-0.19l2.83-0.01l0.59-0.24l0.09-0.08l0.52-0.83
  		l0.54-1.37l0.13-0.84l-0.17-0.35l-0.94-0.85l-0.04-0.25l0.15-0.4l0.56-0.43l0.75-0.49l0.41-0.42l-0.1-0.33l-0.22-0.35l-0.03-0.32
  		l0.14-0.4l0.05-1.35l0.1-0.35l-0.15-1.23l-0.18-1.01l-0.6-1.31l0.11-0.29l0.28-0.24l0.9-0.45l0.72-1.07l1.05-0.89l1.37-0.72
  		l0.96-0.8l0.38-0.61l0.27-0.16L1757.3,949.04z M1598.44,993.7l-0.68,0.05l-0.29,0.21l0.32,0.51l0.6-0.05l0.48,0.07l0.04-0.18
  		l-0.14-0.39L1598.44,993.7z M1597.61,985.82l-0.71-0.1l-2.69,0.14l-0.53-0.07l-1.14-0.68l-0.52,0.17l-0.02,0.21l0.12,0.45
  		l0.84,0.61l2.49,0.46l1.58-0.19l0.51-0.29L1597.61,985.82z"/>
    </>,
    PSE: <>
        <path className="st0" d="M2100.09,1045.15l-0.76,0.94l-1.59,1.49l0.12,0.28l0.28,0.78l0.87-0.78l0.02-0.65l1.48-1.51l-0.01-0.15
  		L2100.09,1045.15z M2108.99,1040.34l0.31-1.26l-0.17-1.47l-0.57-0.06l-0.69-0.46l-0.13-0.4l-0.21-0.13l-0.49-0.05l-0.93-0.2
  		l-1.08,0.69l-0.46,1.14l-0.09,0.53l-0.37,1.12l0.13,0.69l0.06,0.89l0.09,0.73l-0.1,0.44l-0.21,0.23l0.06,0.17l0.18,0.06l0.59-0.2
  		l0.62,0.2l0.6,0.38l0.05,0.24l-0.42,0.14l-1,0.57l-0.71,0.66l-0.18,0.61l-0.48,1.29l0.06,0.27l0.23,0.16l1.63-0.14l1.48-0.52
  		l1.11-0.56l0.35,0.03l0.13-0.77l0.29-1.02l0.5-0.87l-0.23-2.04L2108.99,1040.34z"/>
    </>,
    PRI: <>
        <path className="st0" d="M1238.19,1165.35l-0.05-0.01l-0.49,0.01l-0.1,0.05l-0.05,0.13l0.06,0.12l0.24,0.13l0.05,0.08l0.04,0.02
  		l0.08,0.01l0.08-0.01l0.04-0.03l0.01-0.07l0.03-0.04l0.04-0.04l0.09-0.15l0.05-0.06l0-0.06L1238.19,1165.35z M1255.88,1162.93
  		l-1.04-0.37l-1.61-0.22h-0.19l0.2,0.35l-0.25,0.02l-0.26-0.17l-0.2-0.22l-0.3-0.04l-5.26-0.15l-2.08-0.26l-0.45,0.06l-0.38,0.14
  		l-0.11,0.55l-0.35,0.37l-0.43,0.25l0.21,0.38l0.29,0.32l0.27,0.51l-0.02,0.62l-0.19,1.37l0.46,0.24l1.09-0.01l0.44,0.12l0.52,0.05
  		l0.53-0.06l0.55-0.27l1.47,0.08l0.74-0.08l0.86,0.31l0.7-0.12l0.34,0.12l0.34,0.02l0.92-0.02l1.39-0.22l1.15-0.72l0.44-0.62
  		l0.54-0.5l0.82-0.48l-0.07-1.2L1255.88,1162.93z M1259.69,1165.15l-0.54-0.14l-0.93-0.03l-0.8,0.24l0.14,0.26l0.43,0.07l0.66-0.05
  		l1.1-0.24L1259.69,1165.15z"/>
    </>,
    PCN: <>
        <polygon className="st1" points="729.17,1526.64 729.07,1526.37 728.84,1526.28 728.67,1526.43 728.74,1526.7 728.92,1526.96
  		729.1,1527.07 729.18,1526.94 	"/>
    </>,
    SPM: <>
        <path className="st0" d="M1336.76,894.91l-0.27,0.04l-0.2,0.1l-0.29,0.33l0.6,0.15l0.18-0.1l0.1-0.17l0.02-0.24L1336.76,894.91z
  		 M1335.39,893.4l0.31-0.86l-0.08-0.38l-0.65-0.3l-0.11,0.1l-0.08,0.23l0.45,1.4l0.01,0.21l-0.38,0.72l-0.06,0.3l0.26,0.26
  		l0.73-0.46l0.1-0.24l-0.29-0.41L1335.39,893.4z"/>
    </>,
    POL: <>
        <polygon className="st1" points="2011.9,852.14 2011.66,851.86 2011.82,851.6 2012.06,851.52 2012.36,851.48 2012.73,851.2
  		2012.65,850.89 2011.72,850.14 2011.32,849.56 2010.69,848.07 2009.42,846.53 2009.01,846.03 2008.96,845.55 2009.14,845.08
  		2008.97,844.47 2008.52,843.7 2007.96,842.57 2008.01,841.55 2008.31,840.96 2008.69,840.44 2008.53,839.65 2008.9,838.6
  		2008.91,837.84 2008.75,837.51 2008.45,837.13 2007.64,836.71 2007.28,836.38 2006.17,835.94 2005.08,835.4 2004.89,835.06
  		2004.95,834.83 2005.14,834.48 2005.97,833.46 2006.88,832.46 2007.46,832.06 2010.53,830.79 2011.01,830.34 2011.13,829.58
  		2011.14,829.03 2011.08,828.05 2010.89,826.65 2010.65,825.69 2010.07,823.87 2008.46,820.11 2007.5,816.19 2007.44,815.97
  		2007.49,815.42 2007.47,814.58 2007.24,813.83 2006.54,813.17 2005.8,812.71 2004.85,812.24 2004.16,812.03 2003.77,811.97
  		2003.69,811.7 2003.55,811.46 2003.22,811.25 2002.53,810.97 2001.93,810.91 2001.45,811.36 2001.16,811.44 1996.41,811.33
  		1991.91,811.13 1987.76,810.96 1983.75,810.78 1979.9,810.62 1977.51,810.47 1975.15,810.31 1974.82,810.17 1974.45,810.46
  		1973.16,811.02 1969.53,811.45 1968.35,811.21 1966.95,810.5 1966.25,809.55 1964.98,806.85 1965.53,806.89 1967.02,807.77
  		1968.04,808.15 1967.7,807.55 1965.81,806.56 1964.03,805.76 1962.03,805.78 1959.98,806.01 1955.09,807.03 1952.94,807.93
  		1951.92,808.58 1949.18,809.07 1947.62,810.43 1946.92,811.31 1946.48,811.64 1946.03,812.13 1944.82,812.41 1943.62,812.55
  		1938.47,813.88 1933.64,815.28 1930.85,816.36 1929.72,816.28 1929.4,816.07 1929.29,816.43 1929.42,816.98 1930.57,817.12
  		1932.32,817.53 1932.37,818.33 1932.27,818.87 1932.43,819.23 1932.53,819.65 1931.72,819.27 1929.8,818.61 1929.86,818.87
  		1929.97,819.82 1930.13,820.6 1931.11,823.73 1931.09,824.49 1931.08,824.7 1930.72,825.76 1930.09,826.66 1929.25,827.17
  		1928.79,827.73 1928.7,828.35 1929.75,829.44 1931.95,831 1932.83,832.33 1932.41,833.43 1932.29,834.24 1932.45,834.75
  		1932.8,835.16 1933.34,835.48 1933.56,835.96 1933.45,836.6 1933.55,837.05 1933.95,837.37 1933.92,837.5 1933.72,837.95
  		1933.45,838.77 1933.3,839.36 1932.68,840.18 1932.87,840.87 1933.35,841.68 1933.72,842.09 1933.84,842.48 1933.6,843.4
  		1933.72,843.64 1935.25,844.31 1935.5,844.63 1935.64,845.28 1936.18,846.67 1935.73,848.42 1935.34,849.38 1934.47,850.9
  		1934.43,851.05 1935.16,851.02 1935.89,850.74 1935.95,850.29 1935.91,849.44 1935.99,849.32 1937.1,849.56 1938.22,849.94
  		1938.37,850.78 1938.67,851.19 1939.02,851.57 1939.36,851.74 1939.95,851.77 1941.46,852.27 1942.19,852.37 1942.94,852.71
  		1943.57,853.06 1944.03,853.14 1944.24,853.52 1944.52,853.79 1945.02,853.58 1946.84,853.3 1947.49,853.68 1947.94,854.08
  		1948,854.21 1947.77,854.56 1947.65,854.83 1947.46,855.01 1946.84,855.21 1946.49,855.52 1946.23,855.86 1946.4,856.19
  		1946.91,856.43 1947.28,856.49 1947.41,856.73 1948.57,857.8 1949.49,859.19 1949.84,859.41 1950.18,859.46 1950.57,859.25
  		1951.02,858.8 1951.55,858.48 1952,858.31 1952.8,857.92 1952.83,857.67 1952.17,856.73 1951.78,855.96 1951.87,855.83
  		1952.72,855.95 1954.16,856.36 1956.38,857.73 1956.78,857.73 1957.55,857.62 1958.4,857.4 1958.8,857.15 1958.95,857.24
  		1959.08,857.99 1958.86,858.4 1957.85,858.8 1957.91,858.99 1958.16,859.25 1958.62,859.42 1959.17,859.9 1959.55,860.46
  		1959.88,860.71 1960.25,860.83 1961.17,860.54 1961.43,860.31 1961.54,860.14 1961.72,860.18 1962.04,860.45 1962.14,860.61
  		1963.03,860.92 1963.55,861.29 1963.88,861.47 1964.24,861.3 1965.65,861.6 1966.04,861.85 1966.17,862.27 1966.1,862.53
  		1966.31,863.18 1968.1,864.76 1968.29,865.57 1968.32,865.89 1969.21,866.02 1969.37,866.57 1969.46,867.14 1970.99,867.09
  		1971.84,865.88 1972.28,865.73 1972.98,865.31 1973.45,864.94 1973.77,865.17 1974.23,865.95 1974.72,866.58 1975.01,866.83
  		1975.04,867.02 1975.32,867.14 1975.88,867.21 1976.25,867.4 1976.36,867.98 1976.37,868.51 1976.19,868.89 1976.1,869.23
  		1976.49,869.36 1977.05,869.23 1977.44,869.04 1978.64,869.48 1979.06,868.5 1979.53,868.01 1980.14,867.77 1980.7,867.47
  		1981.21,867.26 1981.56,867.27 1981.71,867.18 1982.15,867.2 1982.65,867.3 1983.34,867.19 1984.29,867.42 1984.88,867.87
  		1985.47,868.02 1986.13,868 1986.58,867.75 1987.24,866.9 1987.72,866.91 1988.47,866.77 1989.53,866.78 1991.96,866.96
  		1992.57,867.29 1994.07,867.71 1994.72,868.19 1995.01,868.77 1995.17,869.17 1996.7,869.78 1998.98,870.56 1999.53,870.66
  		1999.88,870.61 2000.9,871.01 2001.82,871.22 2002.07,871.02 2002.17,870.77 2002.13,870.56 2001.4,869.97 2000.94,869.59
  		2000.91,869.35 2001.08,868.83 2001.16,868.24 2001.06,867.6 2000.56,866.18 2000.47,865.58 2000.94,864.84 2002.5,863.09
  		2003.02,862.43 2003.72,861.64 2005.65,859.73 2006.86,858.62 2007.68,858 2008.88,856.93 2009.41,856.37 2011.61,856.01
  		2011.88,855.5 2012.28,854.93 2012.6,854.68 2012.64,853.73 2012.23,852.56 	"/>
    </>,
    PAK: <>
        <polygon className="st1" points="2458.27,1011.23 2458.06,1010.75 2457.62,1009.79 2457.24,1008.95 2456.66,1007.65 2456.27,1006.79
  		2455.94,1006.63 2455.13,1006.14 2454.56,1005.72 2454.45,1004.63 2454.04,1004.72 2453.06,1005.11 2451.93,1005.36
  		2451.31,1005.37 2451.06,1005.19 2450.68,1004.04 2450.41,1003.72 2449.9,1003.59 2449.35,1003.38 2449.07,1003.08 2449.01,1002.7
  		2449.26,1002.27 2449.55,1001.93 2449.59,999.88 2449.4,999.16 2449.25,998.55 2448.84,997.79 2448.46,997.31 2447.89,996.88
  		2447.01,996.43 2446.22,996.26 2445.26,996.59 2444.96,996.46 2444.56,995.06 2444.31,994.77 2442.61,994.2 2441.84,994.06
  		2440.96,994.24 2440.45,994.4 2440.05,994.14 2439.42,993.81 2438.8,993.59 2438.02,993.58 2437.52,993.72 2436.6,994.09
  		2434.61,994.93 2433.29,995.62 2432.98,995.64 2432.19,995.36 2431.02,995.01 2430.71,995.02 2428.01,995.08 2425.53,995.21
  		2424.49,995.37 2422.57,995.53 2421.37,995.58 2420.6,995.85 2419.75,996.2 2418.87,996.42 2418.22,996.5 2417.44,996.82
  		2416.93,997.47 2415.45,998.43 2414.62,998.89 2414.21,999.41 2413.73,999.46 2412.92,999.37 2412.3,999.93 2411.6,1000.74
  		2410.33,1001.91 2409.66,1002.39 2409.26,1003.15 2409.55,1003.55 2410.59,1004.14 2411.05,1004.7 2411.3,1005.14 2411.77,1006.28
  		2412.07,1007.4 2412.52,1007.89 2412.65,1008.71 2412.76,1009.21 2412.52,1009.57 2412.29,1009.97 2412.29,1010.35
  		2412.56,1010.73 2412.8,1011.08 2412.92,1011.35 2412.77,1011.66 2412.29,1012.13 2412.05,1012.61 2411.53,1013.41
  		2410.71,1013.95 2410.18,1014.35 2409.6,1015.19 2408.65,1016.12 2408.25,1016.89 2407.84,1017.32 2407.41,1017.55
  		2407.52,1017.97 2407.89,1018.49 2408.5,1019.07 2408.48,1019.98 2408.45,1020.64 2408.47,1021.43 2408.13,1022.09
  		2406.42,1022.73 2404.78,1023.01 2402.78,1023.03 2402.02,1022.93 2401.41,1022.79 2399.23,1022.07 2398.35,1022.49
  		2398.16,1023.53 2399.75,1025.2 2400.41,1026.13 2401.13,1027.7 2401.67,1028.5 2401.47,1029.25 2400.04,1030.1 2398.6,1030.91
  		2396.78,1031.07 2395.64,1031.36 2395.08,1031.77 2394.67,1033.53 2394.26,1034.17 2394.27,1034.93 2393.88,1035.79
  		2393.29,1036.35 2392.89,1037.26 2393.01,1038.97 2393.21,1041.88 2392.43,1042.8 2391.55,1043.72 2390.63,1044.39
  		2389.75,1044.69 2389.02,1044.58 2388.44,1044.01 2388.1,1043.53 2387.46,1043.13 2386.82,1043.21 2386.16,1043.58
  		2385.12,1043.45 2384.23,1043.09 2383.79,1043.13 2383.53,1043.49 2382.57,1044.29 2380.24,1045.49 2379.29,1045.58
  		2378.88,1045.88 2379.04,1046.37 2379.46,1046.77 2380.19,1047.06 2380.22,1047.38 2379.58,1047.67 2379.03,1048 2377.82,1048.4
  		2376.43,1048.55 2374.98,1048.32 2374.24,1047.79 2373.37,1047.74 2372.57,1048.13 2371.75,1048.77 2370.84,1050.14
  		2370.6,1050.38 2370.36,1050.6 2369.77,1050.89 2368.93,1051.38 2368.51,1052.39 2368,1054.18 2368.12,1055.15 2368.16,1056.82
  		2367.96,1058 2367.59,1058.77 2367.67,1059.37 2368.22,1060.06 2368,1060.51 2367.53,1061.01 2367.08,1061.28 2365.26,1061.8
  		2362.77,1062.51 2361.13,1062.97 2358.68,1063.64 2357.97,1063.81 2356.48,1063.87 2355.71,1063.77 2354.67,1063.74
  		2353.13,1063.77 2352.06,1063.95 2350.98,1064.29 2350.19,1064.72 2349.73,1065.14 2349.57,1065.34 2348.5,1065 2345.1,1064.37
  		2335.91,1065.19 2335.04,1065.04 2331.9,1064.08 2327.86,1062.84 2325.36,1062.07 2322.15,1061.07 2323.76,1062.86
  		2324.75,1063.96 2326.15,1065.52 2326.33,1065.89 2326.32,1066.5 2327.76,1068.87 2328.26,1070.1 2328.72,1070.82 2329.86,1071.95
  		2330.97,1073.05 2332.17,1073.56 2333,1073.67 2334.87,1074.25 2335.55,1074.71 2336.65,1075.88 2337.94,1075.72 2338.21,1075.72
  		2338.28,1075.81 2338.32,1076.18 2338.13,1078 2338.48,1079.83 2338.73,1082.57 2338.64,1083.05 2338.33,1083.84 2338.32,1084.35
  		2338.24,1084.66 2338.32,1084.8 2338.73,1084.99 2339.61,1085.09 2341.72,1084.78 2341.97,1084.86 2342.48,1085.18
  		2342.86,1085.69 2342.89,1085.94 2342.36,1086.36 2342.27,1087.08 2342.43,1088.15 2342.35,1088.28 2341.89,1088.52
  		2341.73,1090.07 2341.65,1090.21 2341.1,1090.37 2338.52,1090.27 2338.23,1090.31 2337.26,1090.72 2335.59,1091.01
  		2335.14,1091.18 2334.53,1091.64 2334.08,1092.21 2334,1092.74 2333.91,1092.84 2332.96,1092.74 2332.65,1093.19 2330.8,1093.87
  		2330.57,1094.02 2330.29,1094.57 2330.05,1096.09 2329.83,1097.45 2329.69,1097.65 2329.1,1098.12 2329.05,1098.28 2329.13,1098.8
  		2328.86,1099.77 2328.66,1102.44 2328.42,1103.18 2328.25,1103.33 2329.74,1103.76 2331.12,1103.82 2332.65,1103.6
  		2333.18,1103.15 2333.57,1102.98 2333.99,1103.23 2334.55,1103.79 2335.19,1103.63 2335.64,1103.23 2336.72,1102.72
  		2337.49,1102.63 2340.44,1102.99 2341.75,1102.72 2342.72,1102.96 2344.46,1103.11 2344.49,1102.33 2345.01,1101.84
  		2346.39,1101.55 2348.2,1101.93 2348.63,1101.86 2349.24,1101.39 2349.79,1101.65 2350.02,1102.01 2353.32,1102.88
  		2353.74,1103.15 2354.29,1103.35 2355.28,1102.25 2357.68,1102.21 2360.58,1101.65 2362.89,1101.82 2364.6,1101.24
  		2367.56,1100.84 2368.35,1100.83 2368.98,1101 2369.52,1101.01 2369.01,1100.66 2368.59,1100.46 2366.69,1100.59 2366.95,1100.04
  		2367.43,1099.72 2368.32,1099.61 2369.2,1099.85 2370.08,1100.66 2370.39,1101.61 2371.47,1102.97 2371.56,1104 2371.33,1105.62
  		2371.51,1106.23 2374.86,1106.84 2375.45,1107.16 2376.44,1110.62 2376.57,1111.55 2376.61,1112.33 2377.08,1113.07
  		2377.61,1113.31 2377.83,1113.53 2378.02,1113.72 2378.25,1114.42 2378.75,1114.93 2379.45,1114.6 2379.48,1115.06
  		2379.64,1115.56 2380.91,1115.41 2381.25,1114.75 2382.02,1115.4 2382.44,1115.43 2382.74,1115.23 2383,1115.5 2383.4,1116.07
  		2383.68,1115.68 2383.82,1115.15 2384.4,1114.77 2384.81,1114.52 2385.64,1114.32 2386.55,1114.18 2387.37,1114.18 2388.53,1114.2
  		2388.56,1111.53 2388.66,1111.29 2388.82,1111.16 2389.01,1111.1 2389.17,1111.14 2389.41,1111.54 2389.7,1111.52 2390.02,1111.29
  		2390.72,1111.46 2391.29,1111.34 2391.86,1111.5 2392.83,1111.5 2394.59,1111.44 2395.56,1111.46 2396.19,1111.89 2396.89,1112.35
  		2397.63,1112.42 2398.72,1112.36 2399.45,1112.18 2399.82,1111.75 2400.1,1111.33 2401.71,1110.72 2403.4,1110.22 2403.88,1110.17
  		2404.03,1110.46 2403.96,1110.94 2404.15,1111.41 2404.83,1111.7 2405.31,1111.77 2405.74,1111.71 2406.06,1111.56
  		2406.74,1110.83 2407.09,1110.67 2407.55,1110.68 2408.07,1110.33 2408.08,1110.07 2407.75,1109.94 2407.47,1109.56
  		2407.52,1109.24 2407.44,1108.8 2407.5,1108.39 2407.72,1108.07 2408.1,1107.77 2407.87,1107.15 2407.28,1105.96 2406.67,1104.43
  		2406.02,1103.15 2405.19,1102.04 2404.77,1101.21 2404.81,1099.4 2404.74,1099.03 2404.45,1098.81 2404.07,1098.68
  		2403.54,1098.87 2403.05,1098.9 2402.01,1098.86 2401.5,1098.68 2400.12,1096.85 2399.94,1096.14 2399.93,1095.4 2400.39,1094.12
  		2400.53,1092.93 2400.6,1091.82 2400.52,1091.5 2400.24,1091.13 2399.78,1090.85 2398.53,1090.79 2397.05,1090.42 2395.91,1089.77
  		2395.12,1089.38 2394.91,1089.12 2394.81,1088.82 2395.02,1087.47 2395.38,1085.95 2395.64,1085.48 2396.09,1085 2396.42,1084.67
  		2396.96,1084.24 2398.4,1082.78 2399.69,1080.78 2400.49,1079.39 2400.91,1078.97 2401.33,1078.62 2401.96,1078.18
  		2402.68,1077.79 2403.39,1077.81 2404.07,1078.16 2404.57,1078.58 2404.74,1079.51 2405.1,1080.11 2405.49,1080.47 2406,1080.65
  		2406.64,1080.6 2409.25,1079.54 2410.14,1079.33 2412.27,1079.2 2413.73,1078.79 2415.03,1078.36 2415.18,1077.59 2415.68,1076.41
  		2417.2,1074.87 2417.63,1074.19 2418.09,1072.88 2418.58,1071.68 2419,1071.18 2421.39,1069.87 2423.73,1068.66 2424.11,1068.11
  		2425.62,1065.6 2426.49,1063.89 2426.71,1063.34 2427.22,1061.86 2427.76,1060.38 2428.48,1060.03 2430.08,1059.47
  		2431.36,1058.92 2432.01,1058.29 2432.4,1057.73 2432.33,1057.19 2431.98,1056.54 2432.05,1056.15 2432.12,1055.77 2433.04,1055
  		2434.78,1052.7 2435.82,1051.55 2436.17,1051.55 2437.26,1050.94 2438.3,1050.24 2438.24,1049.93 2438.11,1049.52 2437.51,1049.34
  		2437.33,1048.85 2437.47,1048.15 2437.97,1046.26 2437.87,1045.72 2437.26,1043.96 2437.4,1043.48 2437.64,1042.98
  		2438.32,1042.32 2439.19,1041.77 2441.99,1040.46 2442.56,1040.32 2443.53,1039.99 2444.12,1039.29 2444.2,1038.7 2443.94,1038.33
  		2443.36,1037.83 2442.26,1037.38 2441.28,1036.99 2439.61,1037.03 2438.74,1036.69 2438.51,1036.46 2438.38,1035.63
  		2438.55,1034.23 2438.29,1034.11 2437.92,1034.27 2437.04,1034.1 2435.95,1034.13 2435.54,1033.74 2435.74,1033.26
  		2435.68,1032.64 2435.52,1032.04 2435.35,1031.91 2434.84,1031.77 2434.03,1031.25 2433.38,1030.62 2433,1030.19 2432.88,1029.89
  		2432.92,1029.69 2433.39,1029.14 2433.96,1028.36 2434.17,1027.69 2434.23,1027.2 2434.07,1026.85 2433.55,1026.41 2433,1026.02
  		2432.78,1025.69 2432.77,1025.19 2432.97,1024.56 2433.63,1024.08 2434.78,1023.63 2435.08,1023.07 2435.04,1022.65
  		2434.73,1022.53 2433.91,1022.53 2432.54,1022.38 2432.31,1022.16 2432.16,1021.85 2432.16,1021.54 2432.45,1021.2
  		2432.79,1020.76 2432.73,1020.33 2432.33,1019.84 2431.36,1019.49 2431.23,1018.99 2431.38,1018.57 2431.7,1017.98
  		2431.98,1017.56 2432.64,1016.38 2433.44,1016.12 2434.41,1015.74 2435.5,1015.32 2437.16,1015.64 2437.97,1015.79
  		2439.61,1016.15 2440.98,1016.45 2442.39,1016.54 2442.97,1016.52 2443.61,1016.88 2445.2,1017.49 2446.49,1017.81
  		2447.36,1017.81 2448.65,1017.27 2449.29,1016.77 2450.16,1016.23 2451.26,1016.25 2453.66,1015.41 2454.11,1015.55
  		2454.82,1015.6 2455.67,1015.12 2456.12,1014.54 2456.19,1014.25 2456.4,1014.04 2457.32,1013.68 2458.24,1013.17 2458.49,1012.5
  		2458.64,1012.05 	"/>
    </>,
    PHL: <>
        <path className="st0" d="M2852.23,1214.66l-0.03-0.74l-0.56-1.33l-0.73-0.55l-0.29,0.35l0.28,0.53l1.02,1.46L2852.23,1214.66z
  		 M2851.63,1224.14l0.25,0.82l0.66,0.01l-0.49-1.12l-0.13,0.04L2851.63,1224.14z M2847.4,1210.07l-1.37-1.77l-0.22-0.02l-0.5,0.08
  		l-0.07,0.41l0.21,0.2l0.68,0.35l0.94,1l0.91,0.6l0.78,0.89l-0.72-1.3L2847.4,1210.07z M2850.38,1213.99l-0.81-0.48l-1.06-0.35
  		l-0.85-0.35l0.03,0.76l0.23,0.84l-0.18,0.57l-0.29,1.88l-0.46,1.18l0.02,0.36l1.14-0.94l1.06-1.34l0.46-0.19l0.48,0.17l0.68,0.9
  		l0.47,0.18l0.06,0.16l0.52,0.4l-0.09,0.43l0.24,0.14l0.79,0.18l1.14,0.8l0.53,0.56l0.09-0.5l-0.13-1.32l-1.11-1.72l-1.61-1
  		l-1.19-1.02L2850.38,1213.99z M2842.33,1215.34l0.43-0.2l0.24-0.62l-0.08-0.36l-0.59-0.57l-1.11,0l-0.41,0.31l0.13,0.22l0.51,0.39
  		L2842.33,1215.34z M2853.01,1237.44l0.54,0.44l1.57,0.21l2-0.26l0.37-0.2l0.62-0.79l0.24-0.05l0.66,0.03l0.08-0.31l-0.1-0.36
  		l-0.23-0.42l0.19-1.25l-0.77-0.33l-0.68-0.52l-0.27-0.03l-0.18-0.1l-0.13-0.16l-1.37,0.21l-0.67,0.62l-0.29,0.52l-1.27,0.68
  		l-0.38,0.35l-0.39,0.52l0.11,0.48L2853.01,1237.44z M2842.91,1228.46l-0.2,0.14l-0.19,0.23l-0.74,0.74l-0.18,0.97l0.18,0.57
  		l0.5-0.31l0.21,0.02l0.23-0.12l0.27-0.22l0.47-1.32l-0.07-0.44l-0.23-0.29L2842.91,1228.46z M2857.31,1228.95l-0.09-0.1l-0.31-0.13
  		l-0.39,0.89l0.23-0.04l0.55-0.22L2857.31,1228.95z M2858.16,1222.13l1.05-0.05l0.12-0.49l-0.48-0.76l-0.46-0.4l-0.62-0.07
  		l-0.65,0.25l0.58,1.14L2858.16,1222.13z M2841.98,1239.07l0.4,0.33l0.46,0.28l0.91,0.33l0.79,0.43l0.68,1.79l0.39,0.41l0.59,0.05
  		l0.56-0.09l0.52-0.2l0.3-0.28l0.54-0.81l0.23-0.47l0.01-0.38l-0.11-0.33l-1.34-2.1l0-0.45l0.11-0.47l-0.01-1.26l0.21-0.58
  		l0.67-1.06l0.25-0.55l0.4-1.7l0.53-1.13l0.72-1.04l0.29-0.67l0.34-1.01l-0.04-0.3l-0.44-0.91l-2.14-0.6l-0.29,0.04l-1.66,0.65
  		l-0.35,0.21l-0.11,0.43l-0.01,0.6l-0.09,0.57l-0.44,0.81l-0.42,0.42l-0.32,0.42l0.3,0.92l0.12,0.94l-0.01,1.34l-0.09,0.32
  		l-1.2,0.82l-0.55,0.07l-1.05,0.02l-0.44,0.15l-0.39,0.55l-0.22,0.62l0.1,1.09L2841.98,1239.07z M2860.88,1221.52l0.25,0.12
  		l0.16-0.44l-0.28-0.27l-0.28-0.48l-0.48-0.27l0.11,0.48l0.31,0.66L2860.88,1221.52z M2838.12,1212.24l-0.86,0.44l-0.1,1.39
  		l-0.41,0.42l-0.15,0.46l0.1,0.35l0.39,0.38l-0.18,0.46l0.45,0.73l0.67-2.12l0.32-1.55l-0.01-0.64l0.12-0.34L2838.12,1212.24z
  		 M2856.83,1214.34l0.5,1.36l0.51,0.77l0.7,0.62l0.36,0.2l0.88,0.29l0.61,0.74l0.39,0.31l0.21,0.38l0.53,0.65l0.43,0.18l0.5-0.09
  		l0.03,0.53l-0.16,0.54l-0.52,0.68l0.24,0.67l0.75,1.17l0.45,0.46l0.57,0.17l0.65,1.03l0.66,0.02l1.01,0.25l1.65-0.16l0.39,0.1
  		l0.52,0.6l0.11-0.2l-0.38-0.77l-0.65-0.59l-0.45-0.04l0.07-0.35l0.22-0.37l-0.13-0.47l-0.73-1.41l-0.12-0.42l0.07-0.52l-0.03-0.49
  		l-0.27-0.49l-0.06-1.54l0.48-0.87l-0.08-0.69l0.27-0.47l-0.46-0.51l-0.61-0.28l-0.48-0.07l-0.27-0.25l0.06-0.56l-0.14-0.5
  		l-0.6-0.69l-0.75-0.38l-2.61,0.32l-2.31,0.07l-2.28-0.37L2856.83,1214.34z M2867.96,1232.63l0.32-1.24l-0.15-0.41l-0.16,0.03
  		l-0.35,0.48l-0.22,0.13l-0.47,0.46l-0.02,1l-0.23,0.62l0.33,0.24l0.47,0.78l0.49,0.46l0.2,0.49l0.15-0.24l0.02-0.21l-0.07-0.2
  		l0.16-0.92L2867.96,1232.63z M2863.4,1232.86l0.31,0.24l-0.02-0.39l0.07-0.31l0.88,0.07l0.13-0.37l-0.07-0.35l-0.53-0.91
  		l-0.08-1.08l-0.2-0.44l-0.31-0.4l-0.37-0.31l-0.42-0.25l-0.17-0.29l0.22-1.41l0.04-1.55l-0.15-0.65l-0.27-0.37l-0.54-0.99
  		l-0.92-0.24l-0.82,0.67l-0.67,0.12l-0.59-0.3l-0.22-0.44l-0.31-0.24l-0.63-0.28l-0.52-0.49l-0.37-0.17l-0.19,0.42l0.19,0.5
  		l0.3,0.48l0.39,1.87l0.28,1.92l0.48,0.16l0.95-0.49l0.39,0l0.64,0.7l0.4,0.83l0.09,0.42l0.01,0.42l-0.51,2.05l0.44,0.95l0.02,0.45
  		l-0.09,0.9l0.27,0.28l0.98,0.33l0.82,0.53l-0.03-0.69l-0.16-0.69l-0.15-1.44l0.48,0.37L2863.4,1232.86z M2870.69,1238.14l0.19-0.77
  		l-0.21-0.63l-0.16,0.17l-0.22,0.99l0.26,0.45L2870.69,1238.14z M2860.05,1241.1l-0.46,0.15l-0.12,0.43l0.23,0.36l0.94,0.42
  		l0.24-0.5l-0.14-0.4l-0.45-0.45L2860.05,1241.1z M2839.86,1213.27l-0.2-0.51l-0.08-0.03l-0.25,0.31l0.11,0.46l0.16,0.04
  		L2839.86,1213.27z M2871.43,1236.68l0.65-0.01l0.3-0.27l-0.44-0.55l0.07-0.22l0-0.44l-0.47-0.98l-0.63,1.12l-0.06,0.75l0.47,0.66
  		L2871.43,1236.68z M2837.21,1204.61l-0.16,0.08l-0.52-0.02l-0.41-0.22l-0.43,1.21l0.12,0.82l0.39,0.4l1.09,0.65l0.32-0.27l0.1-0.28
  		l0.57-0.82l-0.13-0.47l0.06-0.36L2837.21,1204.61z M2864.94,1235.27l-0.06-0.42l-0.42-1.13l-0.46-0.3l-0.36-0.03l0.22,0.78
  		L2864.94,1235.27z M2836.73,1230.72l0.13,0.22l0.29-0.12l0.53-0.47l0.48-0.52l0.75-0.4l2.73-0.58l1.27-0.92l0.81-0.19l0.18-0.47
  		l-0.01-0.52l0.11-0.41l0.37-0.27l0.78-0.3l0.65-0.49l0.5-0.68l0.37-0.76l0.21-0.65l0.1-0.67l0.02-0.79l-0.47-0.05l-1.75,0.85
  		l0.05-0.39l0.26-0.36l-0.78-0.56l-0.94-0.1l-0.96,0.37l-0.98-0.43l-0.82-0.74l-0.91-0.59l-1.72-0.7l-0.49-0.34l-0.55-0.02
  		l-0.4,0.36l-0.21,0.54l0.42,0.28l1.06,0.29l0.29,0.36l0.02,0.32l-0.37,2.69l-0.07,1.93l-0.25,1l-0.47,0.91l-0.12,0.97l0.19,0.5
  		l0.06,0.51l-0.39,1.23L2836.73,1230.72z M2851.18,1240.79l-0.24,0.09l-0.77,0.46l-0.35-0.02l0,0.2l0.4,0.53l0.62,0.22l0.77-0.25
  		l-0.07-0.88L2851.18,1240.79z M2853.03,1232.53l0.66-0.5l0.44-0.71l0.39-1.57l-0.2-1.54l0.22-1.34l-0.01-0.87l-0.11-0.21
  		l-0.03-0.45l0.18-0.94l-0.16-0.48l-0.6,0.73l-0.03,0.42l-0.12,0.49l-0.21,0.32l0.04,0.65l-0.83,1.97l-0.89,1.43l-0.13,0.75l-1,1.44
  		l-0.66,1.37l-1.08,1.46l0.15,0.66l-0.65,2.63l-0.09,0.75l0.13,0.56l0.32-0.23l1.04-1.18l1.18-2.81l0.08-0.83l0.48-0.91l0.74-0.78
  		L2853.03,1232.53z M2820.24,1230.15l0.23-0.27l-0.49-0.29l-0.83-0.3l-0.74,0.75l0.24,0.82l1.04-0.26L2820.24,1230.15z
  		 M2836.49,1195.05l0.19,0.09l0.48-0.05l0.27-0.83l-0.43-1.14l0.4-0.62l0.12-0.05l0.17,0.02l-0.15-0.3l-0.52-0.35l-1.11,0.07
  		l-0.16,0.64l0.35,0.4l0.23,0.66l0.28,0.34l0.1,0.54l-0.1,0.19L2836.49,1195.05z M2832.82,1209.85l0.13-0.75l0.41-0.59l-0.13-0.36
  		l-0.68-0.49l-0.72-0.66l-0.61-0.93l-0.69-0.5l-0.68,0.44l-0.36-0.25l-0.46-0.15l-0.37-0.49l-0.55-0.13l-1.35,0.26l-0.43-0.07
  		l-0.43-0.15l-1.56-0.21l-0.57,0.05l-0.41,0.38l-0.12,0.52l0.41,0.09l0.43-0.03l0.15,0.1l0.21,0.7l0.23,0.43l0.55,0.44l0.66,0.34
  		l0.24,0.33l0.7,1.37l0.04,1.1l0.06,0.43l0.17,0.36l0.5,0.38l0.38,0.49l0.19,0.55l0.01,0.59l0.34,0.55l0.72,0.74l0.29,0.18l0.2,0.3
  		l0.08,0.43l0.32,0.15l0.68,0.15l0.44-0.49l0.28-0.23l0.29-0.08l0.32,0.11l0.05-0.51l0.15-0.24l-0.05-0.29l0.39-0.72l0.52-0.65
  		l0.18-0.46l-0.51-1.69L2832.82,1209.85z M2838.97,1203.75l1.44,1.09l0.26,0.24l0.77,1.1l0.12,0.42l0.02,0.46l0.71,0.56l0.63-0.5
  		l-0.06-1.21l-0.49-1.04l-0.11-0.38l-0.74-0.81l-0.07-0.4l0.03-0.51l-0.09-0.49l-0.22-0.57l0.15-0.37l0.48,0.04l0.44,0.15l1.57,1.45
  		l0.69,1.02l0.28,0.22l2.25,1.28l0.24,0.33l0.12,0.42l0.75,1.17l-0.04,0.99l0.17,0.47l0.77,0.09l1.89,1.03l0.92,0.13l0.56-0.07
  		l0.52-0.21l0.46-0.08l0.26,0.2l-0.45,0.95l-0.14,0.98l0.71,0.81l0.83,0.24l0.65-1.91l0.05-2.08l-0.32,0.09l-0.3-0.06l-0.96-0.58
  		l-0.69-0.15l-0.74,0.05l0.26-0.69l0.02-0.66l-0.46-0.72l-0.52-0.67l-0.8-0.82l-0.49-1l0.48-0.5l1.68-0.15l0.43-0.66l-0.35-0.32
  		l-0.76-0.4l-0.35-0.11l-0.43-0.01l-1.69-0.58l-0.46-0.53l-0.48-0.28l-0.34,0.31l-0.18,0.42l0.39,0.33l-0.07,0.85l-0.55,0.76
  		l-1.09-0.02l-0.35-0.33l-0.03-0.48l0.12-0.49l0-0.49l-0.48-1.02l-0.68-0.92l-0.66-0.54l-0.8-0.29l-1.13-0.28l-1.15-0.04l-0.9,0.5
  		l-0.85,0.62l-0.38,0.13l-0.32,0.23l0.02,0.31l0.61,0.57l0.11,0.41l-0.5,0.14l-0.14,0.42l-0.57-0.02l-0.55-0.12l-1.41-0.63
  		l-0.49-0.36l-0.45-0.43l-0.29-0.46l-0.12-0.56l-0.87-2.11l-0.17-0.85l-0.01-0.86l0.58-0.48l-0.08-0.24l-0.21-0.21l-0.98-1.79
  		l-0.92-1.86l-0.3-0.43l-0.06-0.5l0.17-0.43l0.34-0.36l0.32-0.8l0.75-0.97l0.23-0.4l0.02-0.48l-0.16-0.45l-0.25-0.42l0.29-0.91
  		l0.76-0.7l0.87-0.54l1.57-0.69l1.35-0.23l0.67-1.43l0.1-0.72l1.68-3.33l0.36-1.44l0.27-0.59l0.16-0.58l-0.43-0.26l-0.51-0.2
  		l-0.12-0.52l-0.05-0.59l-0.21-0.33l-0.79-0.43l-0.27-0.34l-0.52-1.21l-0.19-0.77l-0.01-0.8l0.24-2.66l0.37-0.8l0.6-0.67l0.18-0.74
  		l-0.13-0.71l-0.29-0.49l-0.37-0.36l-0.63,0.12l-0.59,1l-0.32,0.38l-0.77,0.37l-0.86-0.09l-1.08-0.3l-1.04-0.4l-2.85-1.62
  		l-1.29-0.45l-0.42,0.01l-1.06,0.25l-0.48-0.12l-0.46-0.04l-0.88,0.5l-0.92,0.33l-0.13,1.2l-0.28,0.91l-0.39,0.88l-1.24,4.53
  		l0.11,0.89l0.44,0.84l0.02,0.53l-0.13,0.92l0.07,1.55l-0.09,1.16l-0.74,1.67l-0.14,1l0.01,1l0.17,1.11l0.54,1.54l0,0.52l-0.17,0.44
  		l-0.27,0.37l-0.55,0.13l-0.94,0.03l-0.3-0.16l-0.76-1.02l-0.41-0.27l-0.46-0.2l-0.37-0.42l-0.47-0.34l-0.34,0.2l-0.15,0.41
  		l-0.09,1.72l0.06,0.4l0.33,0.48l0.43,0.4l0.18,0.26l0.09,0.32l0.35,3.49l0.22,0.78l0.38,0.95l0.27,0.98l0.07,1.17l0.32,1.09
  		l0.47,0.43l0.64-0.07l0.31,0.13l0.28,0.93l0.69,0.65l0.26,0.98l0.36,0.34l0.48,0.11l0.5-0.01l0.28-0.36l-0.05-0.95l-0.3-1.47
  		l0.31-0.98l0.46,0.56l0.59,0.34l0.81,0.15l0.7,0.37l0.45,0.6l0.09,0.74l-0.25,0.55l-1.97,1.72l-0.38,0.4l-0.22,0.48l0,1.64
  		l0.17,1.63l0.77-0.82l0.94,0.13l0.77,1.05l0.62,0.03l0.75,0.67l0.91,0.33l1.18-0.08l0.86-0.54l0.04-0.67l0.42-0.44l1.2-0.63
  		l0.41-0.16l0.43-0.1l0.3,0.07l2.48,1.27L2838.97,1203.75z M2822.32,1215.9l-0.29,0.45l-0.33,0.12l-0.46-0.13l-0.19-0.26l-0.96-0.62
  		l-0.4-0.41l-0.17,0.05l-0.09,0.12l-0.05,0.17l0.1,0.06l-0.19,0.24l-0.03,0.38l0.25,0.17l0.51,0.93l0.45,0.52l0.75,0.12l0.62-0.22
  		l0.59,0.13l0.6-0.06l0.23-0.55l-0.68-0.55L2822.32,1215.9z M2822.7,1203.45l-0.01-0.58l-0.5-0.6l-0.76-0.32l-0.15,0.13l-0.03,0.22
  		l0.04,0.29L2822.7,1203.45z M2831.2,1268.2l-0.58,0.16l-0.46-0.61l-1.03-0.17l-1.17,0.75l-0.18,0.46l0.46,0.47l0.74-0.22l0.54,0.25
  		l1.14-0.42l0.64,0.61l0.98-0.59l0.03-0.21l-0.19-0.32L2831.2,1268.2z M2868.53,1258.43l-0.26,0.94l0.21,0.28l0.52,1.13l0.13-0.48
  		l-0.01-1.41L2868.53,1258.43z M2875.74,1254.29l-0.22-0.4l-0.42-0.27l-0.5-0.64l-0.08-0.8l0.26-1.86l0.02-0.46l-0.66-1.04
  		l-0.12-1.32l-0.7-0.47l-0.92-0.17l-0.28-0.3l0.02-0.27l0.66-0.58l0.36-0.4l0.48-0.85l-0.13-0.9l-0.8-1.08l-0.14-0.37l0.01-1.28
  		l-0.89,0.13l-0.69-0.51l-0.43-0.89l-0.66-0.73l-1.97-1.19l-1.02-0.88l-0.42,0.02l-0.48,0.74l0.81,3.32l0.2,1.14l-0.29,1.06
  		l-1.04,0.19l-1.08-0.29l-0.32,0l-0.28,0.89l-0.3,0.45l-0.8-0.18l-0.86-0.56l-0.63-0.13l-0.53,0.41l-0.16,0.42l-0.21,1.55
  		l-0.26,1.07l-0.92,0.34l-1.44-0.71l-0.39,0.05l-0.4,0.34l-0.28,0.43l-0.35,1.03l-0.48,0.97l-0.24,0.35l-0.32,0.24l-1.37,0.36
  		l-0.55,0.26l-1.11,0.67l-0.39-0.08l0.84-0.73l0.2-0.37l-0.14-1.58l-0.1-0.48l-0.55-0.97l-0.87-0.61l-0.98-0.23l-0.55-0.29
  		l-0.54-0.18l-0.46,0.74l-0.33,0.38l-0.41,0.24l-1.23,0.21l-0.43,0.3l-0.38,0.39l-0.28,0.3l-0.16,0.36l0.03,0.58l-0.05,0.56
  		l-0.72,0.54l-0.9,0.19l-1.11,0l-0.7,0.34l-1.71,0.4l-0.42,0.15l-0.79,0.7l-0.94,1.13l-0.1,0.38l-0.04,0.89l-0.57,2.49l-0.47,0.71
  		l-0.56,0.67l-0.17,1.05l0.51,0.9l0.53,0.33l0.59,0.12l0.37-0.3l0.28-0.46l0.63-1.39l0.57-1.43l1.09-1.86l0.22-0.65l0.2-0.28l1-0.76
  		l0.82-0.09l0.66,0.43l0.23,1.38l0.2,0.25l0.62-0.01l0.61-0.13l0.5-0.57l0.4-0.72l0.21,0.28l0.15,0.31l0.1,0.46l0.23,0.38l0.87,0.55
  		l0.92,0.48l0.72-2.17l0.04-0.38l-0.04-0.39l0.13-0.43l0.51-0.2l0.47,0l0.48,0.12l0.43,0.27l0.4,0.36l1.72,0.66l0.84,0.73l0.96,1.19
  		l0.2,0.34l0.05,0.54l-0.19,0.54l-0.27,0.41l-0.34,0.37l-0.61,0.51l-0.5,1.01l-0.04,0.54l0.06,0.56l0.51,1.65l0.01,1.13l0.24,1.08
  		l1.13,1.44l1.57,0.95l2,1.02l2.45,1.03l0.4,0.08l0.51-0.04l0.34-0.3l0.82-1.18l0.14-0.13l0.34-0.06l0.31,0.31l-0.03,0.9l-0.27,0.98
  		l0.07,0.43l0.4,1.05l0.49,0.28l0.92-0.55l1.28-1.73l0.51-0.91l0.02-2.07l-0.69-2.02l-0.2-0.28l-0.66-0.62l-0.45-0.28l-0.44-0.7
  		l0.17-0.89l0.54-0.97l0.65-0.89l0.83-0.74l0.16-0.47l0.08-0.52l0.16-0.34l0.71-0.5l0.43-0.09l0.65,1.82l0.51,0.7l0.2,0.75
  		l0.49,0.84l0.31,0.92l0.25,2.05l0.28,0.77l0.39,0.74l0.27-1.46l0.16-2.1l-0.41-1l0.21-0.32l0.65,0.07l1.22-1.09l0.91-1.37l0.29-0.6
  		l0.06-0.65l0.03-1.86L2875.74,1254.29z M2836.88,1199.41l1.82,1.26l0.03-0.35l-1.82-1.54l-0.11,0.2l0,0.2L2836.88,1199.41z
  		 M2844.95,1256.34l-0.37,0.3l-0.41-0.26l-0.22,0.3l0.07,0.65l0.29,0.01l0.88-0.39l0.04-0.21l-0.09-0.2L2844.95,1256.34z
  		 M2854.57,1204.11l1,0.63l0.61-0.47l0.39-0.03l0.27,0.2l0.22-0.55l0.42-0.4l-0.07-0.6l0.18-1.03l-0.68-0.51l-0.24-0.14l-0.7-1.11
  		l-0.33,0.15l-0.27,0.28l-0.26,0.4l0.01,1.61l-0.72,1.09L2854.57,1204.11z M2837.74,1262.17l-1.21,0.54l-0.7,0.1l-0.2,0.42
  		l0.54,0.43l0.06,0.38l0.67,0.85l0.27,0.01l0.85-0.12l0.91-0.46l0.43-0.81l0.6-0.19l-0.3-0.31L2837.74,1262.17z M2832.8,1155.36
  		l0.5,0.02l-0.09-0.79l-1.09-0.32l-0.13,0.17l-0.01,0.2l0.07,0.24L2832.8,1155.36z M2836.22,1142.22l-0.09-0.5l-0.16-0.02
  		l-0.43,0.83l-0.05,0.4l0.33,0.01L2836.22,1142.22z M2836.07,1158.5l-0.3,0.61l0.28,0.17l0.53-0.62l0.56-0.54l-0.37-0.47l-0.46,0.16
  		l-0.25,0.48L2836.07,1158.5z M2836.91,1145.85l0.6-0.9l-0.34-0.09l-0.42,0.23l-0.23,0.82l0.23,0.05L2836.91,1145.85z
  		 M2822.15,1273.93l-0.36,0.06l-0.72,0.58l-0.82,0.3l-1.31,0.8l-0.05,0.53l0.47,0.08l0.68-0.16l0.46-0.6l0.74-0.15l0.15-0.39
  		l0.27,0.26l0.35,0.13l0.27-0.23l0.23-0.51l-0.18-0.23L2822.15,1273.93z M2830.9,1157.61l0.05-0.58l-0.06-0.53l-0.27-0.35l-0.2,0.39
  		l-0.04,0.33l0.09,0.44L2830.9,1157.61z M2820.7,1218.47l-0.32-0.13l-0.35-0.24l-0.34-0.18l-0.46,0.23l0.61,1.52l-0.01,0.29
  		l0.11,0.42l0.16,0.18l0.63-0.29l0.29-0.68l-0.09-0.32l0.07-0.33L2820.7,1218.47z M2795.08,1251.15l-0.15,0.29l-0.05,1.03l0.5,0.74
  		l0.43-0.64l-0.02-1.56L2795.08,1251.15z M2818.97,1223.06l0.2-0.15l0.24-0.67l-0.18-0.45l-0.29,0.08l-0.55,0.35l-0.3-0.01
  		l0.04,0.36l0.58,0.2L2818.97,1223.06z M2797.91,1248.97l-0.41-0.05l-0.07,0.52l0.12,0.53l0.58-0.2l-0.01-0.63L2797.91,1248.97z
  		 M2817.17,1228.72l-0.75-2.08l0.3-0.78l-0.25-0.48l0.02-0.47l0.22-0.93l-0.06-0.4l-0.44-0.28l-0.3,0.45l-1.05,2.21l-0.3,0.5
  		l-0.38,1.09l0.3,0.8l0.14,0.54l-0.22,0.96l-0.53,0.82l-0.32,0.32l-0.36,0.26l-0.53,0.2l-0.47,0.27l-1.51,1.88l-0.21,0.22
  		l-0.78,0.59l-1.64,2.04l-1.6,1.61l-1.93,2.16l-0.77,0.65l-0.34,0.14l-0.43,0.02l-0.39,0.09l-1.18,1.2l-1.28,1.1l-0.53,0.55
  		l-0.95,1.15l-0.57,0.45l-0.79,1.45l-0.23,0.71l-0.08,0.75l0.78-0.61l0.85-0.47l0.48-0.13l0.4-0.23l0.19-0.48l0.27-0.39l0.91-0.3
  		l0.84-0.43l0.92-0.59l0.85-0.66l0.67-0.9l0.54-0.99l0.8-0.56l1.01-0.28l0.72-0.46l0.59-0.65l0.55-0.76l1.72-2.9l-0.16-0.81
  		l0.23-0.46l0.44-0.28l0.96-0.37l2.05-0.57l0.23-0.33l0.11-0.44l0.45-0.84l0.71-0.64l0.45-0.23l0.99-0.21l0.46-0.24l0.77-0.79
  		l-0.02-0.43L2817.17,1228.72z"/>
    </>,
    PNG: <>
        <path className="st0" d="M3044.21,1337.14l0.53-0.31l0.54,0.08l0.41-0.28l1.5,0.34l1.16-0.02l0.66-0.17l0.54,0.13l0.8-0.77l0.3-0.2
  		l0.41,0.04l0.44-0.1l0.05-0.4l-0.17-0.14l-0.05,0.05l0.03,0.19l-0.19,0.01l-2.8-0.54l-1.77-0.1l-0.82,0.24l-0.87-0.03l-0.51,0.36
  		l0.09,0.72l-0.63,0.2l-0.01,0.23l0.13,0.46L3044.21,1337.14z M3054.92,1338.19l0.27-0.44l-0.35-0.3l-0.2,0.13l-0.19,0.36
  		l-0.46,0.08l0.28,0.13L3054.92,1338.19z M3038.98,1358.46l0.56-0.25l0.29-0.72l-0.13-0.56l-0.35-0.28l-0.31,0.13l-0.49,0.42
  		l-0.14,0.53l0.17,0.55L3038.98,1358.46z M3048.91,1362.11l-0.98,0.56l-0.07,0.42l0.19,0.3l0.77,0.8l0.47-0.05l0.38-0.42l-0.13-1.09
  		L3048.91,1362.11z M3017.85,1390.09l-1.27-0.39l-0.73-0.42l0.4,0.56l0.59,0.23l1.17,0.85l1.22,0.51l-0.15-0.37L3017.85,1390.09z
  		 M3054.5,1364.64l-0.1,0.25l-0.01,0.88l0.79,1.02l0.79,0.33l0.15,0.38l0.34-0.06l0.29-0.52l0.13-0.96l-0.18-0.32l-1.75-1.01
  		L3054.5,1364.64z M3089.56,1342.33l0.46-0.18l-0.08-0.6l-0.47-0.24l-0.14,0.35l0.03,0.67L3089.56,1342.33z M3089.54,1342.67
  		l0.14,0.21l0.73,0.83l0.26,0.02l0.09-0.42l-0.21-0.24l-0.84-0.61L3089.54,1342.67z M3095.36,1345.6l0.21-0.74l-0.27-0.76
  		l-0.58,0.17l-0.22,0.28l0.35,0.62L3095.36,1345.6z M3103.89,1352.96l0.03-0.49l-0.2,0.03l-0.41,0.43l0.49,0.23L3103.89,1352.96z
  		 M3073.44,1339.67l0.49,0.75l0.54,0.48l0.6,0.13l1.68-0.11l0.08-0.25l0.05-0.76l-0.01-0.23l-0.17-0.36l-1.7-0.72l-1.05,0.17
  		l-1.19,0.58l0.2,0.15L3073.44,1339.67z M3019.06,1389.78l0.05-0.18l-0.09-0.58l-0.45-0.1l-1.74-0.09l0.38,0.42l1.48,0.57
  		L3019.06,1389.78z M3070.47,1331.79l0.61,0.15l0.02-0.3l-0.34-1.02l-0.78-0.57l-0.44-0.07l-0.28,0.46l-0.02,0.54l1.05,0.88
  		L3070.47,1331.79z M3102.86,1415.08l-1.93-1.02l-0.87-0.27l0.03,0.23l0.23,0.58l0.74,0.43l-0.3,0.39l0.59-0.19l0.19,0.55l1.18,0.3
  		l0.29,0.3l1.23-0.15l0.51-0.22l-0.48-0.49L3102.86,1415.08z M3098.48,1353.01l-5.36-4.39l-0.43-0.51l-1.26-0.93l-0.96-1.09
  		l-0.27-0.24l-1.9-0.66l-0.99-0.84l-0.88-0.58l-0.93-0.51l-1.35-0.56l-0.74-0.04l-0.44-0.68l-1.51-0.84l-1.43-0.97l0.15,0.59
  		l-0.14,0.58l-0.68,0.22l0.86,0.34l1.01,0l0.83,0.41l2.85,1.74l0.5,0.54l0.96,0.44l1.81,1.54l0.73,0.52l0.78,0.45l0.42,0.13
  		l0.95,0.15l0.47,0.16l0.73,0.64l0.64,0.72l2.04,2.74l0.59,1.15l0.24,1.26l-0.03,0.62l-0.13,0.61l0.03,0.58l0.5,1.15l0.39,0.53
  		l0.5,0.52l0.39,0.59l0.62-0.64l0.48-0.75l0.19-0.75l-0.01-0.84l0.57-0.71l0.18-0.33l-0.07-0.84L3098.48,1353.01z M3097.54,1394.52
  		l-0.47-0.17l-0.33-0.48l-1.02,0.06l-0.49-0.13l-0.45,0.09l-0.52,0.34l1.04,0.41l0.59,0.57l0.1,0.34l0.33,0.09l0.91,0.4l0.47-0.18
  		l0.37,0.05l-0.06-0.34l0.22-0.15l0.08-0.17l0-0.19l-0.36-0.32L3097.54,1394.52z M3107.62,1413.69l0.13,0.46l-0.79-0.15l0.06,0.19
  		l0.28,0.41l0.48,0.05l0.91-0.24l0.31,0.16l0.12-0.46l-0.36-0.19L3107.62,1413.69z M3078.44,1405.44l0.46-0.17l1.33-0.69l-1.54,0.18
  		l-1.08-0.43l-1.47-0.14l-0.68-0.23l-0.65-0.32l-0.99-0.32l-1.02-0.23l-0.41-0.16l-0.38-0.24l-0.45-0.67l-0.49-0.55l-0.02-0.53
  		l0.87-0.3l0.54-0.08l0.48-0.2l0.22-0.41l-0.32-0.23l-0.91-0.26l-0.93-0.16l-2.36-0.19l-0.48-0.16l-1.31-0.6l-0.51-0.77l0.11-0.94
  		l0.4-0.97l-0.14-0.93l-0.42-0.34l-0.48-0.14l-0.37,0.02l-2.57,0.61l-0.95,0.02l-0.81-0.34l-0.48-0.95l-0.63-2.06l-0.31-0.26
  		l-1.41-0.92l-0.11-0.38l-0.03-0.42l-0.2-1.02l-0.46-1.5l-0.21-0.48l-1.61-1.08l-0.96-0.32l-0.82-0.52l-1.51-1.39l-0.73-0.8
  		l-0.79-0.69l-0.89-0.59l-0.59-0.72l-0.72-1.78l-1.21-2l-0.1-0.38l0.04-0.41l1.39-0.94l1.99,0.17l2.98-0.16l0.85-0.17l0.3-0.35
  		l0.08-0.93l-0.25-1.5l-0.19-0.49l-0.33-0.2l-0.28-0.26l-0.65-0.89l-0.73-0.82l-0.41-0.3l-0.81-0.46l-0.39-0.13l-1.08,0.03
  		l-1.07-0.08l-0.73-0.22l-5.31-2.54l-1.67-0.6l-1.73-0.4l-1.23-0.22l-0.91-0.58l0.4-1.88l-0.04-2.41l-0.18-0.57l-3.64-3.67
  		l-1.07-0.04l-1.01-0.26l-0.67-0.62l-0.59-0.73l-0.8-0.73l-0.89-0.61l-0.94-0.3l-0.66-0.67l-0.2-0.48l-0.39-0.25l-0.43-0.13
  		l-0.44-0.06l-1.06,0.13l-1.06-0.03l-0.47-0.08l-0.43-0.18l-1.08-0.72l-0.76-0.67l-0.81-0.37l-1.61-1.19l-1.1-0.3l-2.09-0.34
  		l-0.96-0.09l-0.93-0.2l-3-0.97l-2.84-1.01l-1.9-1l-0.4-0.09l-0.42-0.01l-0.43-0.17l-1.26-0.73l-4.22-1.82l-0.68-0.14l-0.85-0.01
  		l-0.25,0v0.03v0.56l0,1.02l0,1.7l0,1.7l0,1.7l0,1.7l0,1.7v1.7l0,1.7v1.7l0,1.7l0,1.71l0,1.7l0,1.71v1.71l0,1.71l0,1.71l0,1.71
  		l0,1.71v0.73l-0.26,0.89l-0.58,1.34l-0.1,1.08l0.48,0.84l0.47,0.55v1.41l0,1.71l0,1.71l0,1.71l0,1.71v1.71l0,1.71l0,1.71l0,1.71
  		l0,1.71v1.71l0,0.11l1.32,0.86l0.71-0.06l0.65-0.39l0.94-0.15l0.95,0.33l0.87,0.18l0.89,0.01l2.12-0.12l2.11-0.24l0.53,0.11
  		l0.87,0.3l0.33,0.15l0.85,0.56l0.94,0.21l1.79-1.06l1.84-0.93l1.25-0.48l1.17-0.63l0.18-0.45l0.04-0.9l-0.13-0.33l-0.59-0.85
  		l-0.71-0.75l-0.94-0.82l-0.4-0.16l-0.43-0.1l-1.82-0.83l-1.87-0.08l-0.85,0.28l-0.71-0.48l-0.26-0.53l-0.3-0.44l-1-0.02l0.58-0.18
  		l0.6-0.05l0.24,0.34l0.19,0.39l0.41,0.52l0.64,0.05l0.77-0.29l0.79-0.13l0.84,0.13l0.82,0.23l1.6-0.03l1.58-0.4l1.41-0.2l1.38-0.33
  		l-0.27-0.74l-0.34-0.7l-0.2-0.24l0.28-0.14l0.96,0.09l0.96,0.28l0.46,0.01l0.46-0.1l-0.4-0.64l0.43,0.08l0.42-0.06l-1.69-3.32
  		l-0.73-0.75l0.58,0.32l0.47,0.44l0.46,0.55l0.54,0.49l0.64,0.27l1.43,0.43l0.7,0.06l0.38-0.43l0.47-0.32l0.22-0.08l0.43,0.14
  		l0.23-0.04l0.16-0.68l0.51-0.26l0.74,0.18l0.73,0.3l0.75,0.15l0.76-0.09l0.18,0.86l0.3,0.36l0.45,0.21l0.91,0.22l0.94,0.11
  		l0.78,0.17l1.52,0.58l0.81,0.12l1.39,0.07l0.36,0.12l0.33,0.22l1.87,0.7l0.38,0.32l0.26,0.46l0.29,0.35l0.35,0.3l0.56,0.82
  		l0.39,0.94l1.34,1.58l0.57,0.89l0.9,1.7l0.55,0.63l1.34,0.53l0.48,0.03l0.43-0.27l-0.28,0.8l-0.04,0.79l0.77,1.19l0.4,0.32
  		l1.97,1.3l0.92,0.8l0.74,0.98l0.48,1.03l0.52,0.4l0.46,0.45l0.84,0.48l1.02,0.15l1.36,0.35l0.42-0.03l0.41-0.15l1,0.18l0.97,0.48
  		l0.4,0.05l1.35-0.11l0.53-0.18l0.49,0.08l1.05,0.57l0.83,0.18l3.5,0.29l1.61,0.41l0.9-0.01l0.87,0.13l0.68,0.39l0.95,0.71l0.28,0.3
  		l0.3,0.5l1.06,0.37l1.5,0.29l0.89-0.05l0.48-0.1l1.14-0.67l0.25-0.34l-0.35-0.29l-0.98-0.49l-0.66-0.73l0.3-0.27L3078.44,1405.44z
  		 M3112.57,1361.71l-0.06-0.74l-0.42-0.34l-0.23,0.18l-0.55,0.64l0.19,0.34l0.11,0.58l0.06,0.79l0.37,1.06l0.17-0.07l0.43-0.42
  		l0.24-1.38L3112.57,1361.71z M3123,1373.65l-0.38-0.8l-0.5-0.75l-0.74-0.71l-0.81-0.63l-0.48-0.21l-0.52-0.12l-0.44-0.31l-0.8-1.43
  		l-0.42-0.36l-0.8-0.56l-0.25-0.31l-0.09-0.43l-0.78-1.32l-0.82-0.67l-1.07-0.16l-0.44-0.23l-0.39-0.33l-0.37-0.08l0.1,0.85
  		l-0.27,1.69l0.1,0.58l0.32,0.96l0.19,0.33l0.74,0.76l0.59,0.38l0.59,0.87l0.29,0.2l1.33,0.62l0.27,0.87l-0.22,0.97l0.44,0.83
  		l0.7,0.8l0.7,0.51l0.79,0.4l0.81,0.22l0.86,0.06l0.37-0.24l0.35-0.33l0.73-0.29l0.19,0.3l0.15-0.14l0.21-0.79L3123,1373.65z
  		 M3092.44,1354.82l-0.86-0.3l-0.67-0.61l-1.26,0.88l-0.44-0.17l-0.43-0.3l-0.38-0.37l-0.97-0.14l-0.93,0.01l-0.27,0.39l-0.14,0.43
  		l0.06,0.39l0.95,2.44l0.12,1.04l-0.06,1.03l-0.83,0.45l-1.12-0.06l-0.5,0.09l-0.46,0.16l-1.59,1.28l-0.58,0.77l-0.39,0.98
  		l-0.58,0.86l-0.44,0.2l-0.49,0.06l-0.49,0.14l-0.42,0.33l-0.92,0.09l-0.9-0.51l-0.97,0.11l-0.89,0.52l-0.97-0.1l-0.65-0.79l-0.28-1
  		l0.08-1.03l0.23-0.42l0.52-0.55l-0.41-0.44l-0.27-0.05l-0.38,0.19l-0.28,0.88l-0.41,2.59l-1.11,0.64l-1.27,0l-0.42-0.06l-1.32,0.48
  		l-0.98,0.09l-0.96-0.09l-1.02-0.42l-1.05-0.32l-1.82,0.23l-0.5-0.15l-0.49-0.06l-0.42,0.17l-0.43,0l-1.12-0.3l-0.74,0.61
  		l-0.06,1.05l0.54,0.8l0.91,0.34l0.97,0.21l0.79,0.31l0.74,0.41l2.46,1.68l0.23,0.09l1.23-0.4l0.92-0.01l0.85,0.39l0.97,1.14
  		l0.46,0.25l0.82,0.09l0.85-0.07l2.86-0.03l2-0.11l0.38-0.11l0.96-0.63l0.99-0.32l0.45-0.29l0.42-0.36l0.93-0.37l1.04-0.1l0.4-0.15
  		l1.17-0.64l0.86-0.68l0.77-0.77l0.28-0.37l0.21-0.41l0.01-0.53l0.28-0.32l1.51-0.07l1.44,0.18l0.87-0.3l0.91-0.59l0.56-0.85
  		l-0.56-0.92l-0.7-0.82l-0.09-0.63l0.25-0.59l1.29-0.09l0.41-0.12l0.35-0.21l0.79-1.11l0.41-0.76l0.03-0.85l-0.23-0.58l-0.11-0.58
  		l0.35-1.26L3092.44,1354.82z M3075.87,1398.52l0.12-0.21l0.19-0.82l-0.09-0.4l-0.31-0.72l-0.94-0.49l-0.62,0.45l-0.21,0.86
  		l1.37,1.17L3075.87,1398.52z M3080.6,1399.78l0.04-0.22l-0.12-0.5l-0.04-0.58l-0.76-0.8l-0.36-0.11l-0.65,0.2l-1.18-0.69
  		l-0.25-0.01l-0.52,0.12l-0.05,0.23l0.03,0.41l0.62,0.85l-0.11,0.22l-0.5,0.53l1.18,0.06l0.86,0.21l1.11-0.01l0.32,0.07l-0.04,0.34
  		L3080.6,1399.78z M3082.26,1389.31l-0.37,0.21l-0.35,0.62l0.66,0.38l0.03,0.22l-0.05,0.39l-0.29,0.73l0.66,0.65l-0.15-0.6
  		l0.27-1.39l-0.19-0.39l0.01-0.87L3082.26,1389.31z M3079.81,1407.27l-0.11,0.42l0.14,0.14l0.69,0.2l0.12-0.66l-0.22-0.11
  		L3079.81,1407.27z M3083.13,1402.13l-0.6,0.63l-0.36,0l-0.31-0.31l0.06-0.37l-1.59-1.15l-0.38-0.56l-0.34-0.23l0.11,0.55l0.6,0.86
  		l0.29,0.78l0.47,0.26l0.06,0.8l1.82,0.56l0.47,0.3l0.55-2.01l-0.34-0.29L3083.13,1402.13z"/>
    </>,
    PYF: <>
        <path className="st0" d="M599.64,1458.65h-0.31l0.82,0.51l0.33-0.05l-0.37-0.14L599.64,1458.65z M601.37,1458.94l-0.43-0.01l-0.3,0.08
  		l0.78,0.12l0.61,0.29l-0.46-0.42L601.37,1458.94z M623.03,1471.93l-0.27-0.32l-0.41-0.21l0.13,0.22l0.53,0.54l0.3,0.44l0.31,0.16
  		l-0.06-0.24L623.03,1471.93z M587.09,1451.58l0.19,0.27l0.52,0.6l0.16,0.37l0.05-0.39l-0.69-0.81L587.09,1451.58z M609.25,1454.58
  		l0.15-0.09l0.26-0.67l-0.22,0.09L609.25,1454.58z M623.97,1473.78l0.07,0.26l0.65,0.31l0.2,0.15l-0.11-0.31L623.97,1473.78z
  		 M533.97,1460.02l-0.08,0.18l-0.08,0.89l0.3,0.13l0.33-0.01l0.38-0.12l-0.39-0.77L533.97,1460.02z M533.98,1459.31l0.24-0.29
  		l-0.16-0.17l-0.41-0.26l-0.06,0.39l0.07,0.24l0.16,0.16L533.98,1459.31z M584.09,1456.05l-0.21-0.46l-0.29-0.56l-0.31-0.69
  		l0.01,0.45l0.03,0.29l0.27,0.31l0.2,0.43l0.11,0.38l0.31,0.44l0.17,0.01l-0.04-0.15L584.09,1456.05z M552.04,1468.21l-0.07-0.87
  		l-0.41-0.57l-1.09-0.22l-0.87,0.3l-0.2,0.28l0.02,0.46l0.45,1.01l0.82,0.15l1.18-0.18l0.43,0.78l0.3,0.24l0.61,0.11l0.26-0.43
  		l-0.23-0.65L552.04,1468.21z M547.15,1466.31l-0.08,0.27l0.06,0.23l0.16,0.22l0.35,0.16l0.26-0.22l0.26-0.49l-0.22-0.12
  		L547.15,1466.31z M641.58,1406.19l0.02,0.9l0.31-0.14l0.16-0.2l0.07-0.25l-0.16-0.14L641.58,1406.19z M642.8,1494.98l-0.19,0.14
  		l0.19,0.07l0.19,0.48l-0.09,0.22l0.24-0.16l-0.08-0.38L642.8,1494.98z M638.77,1400.01l-1.2,0.63l0.27,0.5l0.51,0.14l0.42-0.21
  		l1.26-0.23l0.4-0.43l-0.4,0.05L638.77,1400.01z M655.57,1473.25l-0.32-0.07l0.81,0.83l0-0.16L655.57,1473.25z M661.48,1475.39
  		l-0.44-0.2l-0.39-0.19l-0.27-0.1l-0.17,0.07l0.12,0.12l0.24,0.03l0.44,0.21l0.33,0.18l0.24,0.09l0.02,0.18l0.17-0.19
  		L661.48,1475.39z M629.34,1397.18l0.05,0.21l0.34,0.5l0.18-0.15l0.38-0.69l-0.34-0.14L629.34,1397.18z M638.07,1401.87l-0.23,0.09
  		l0,0.7l0.63-0.66l-0.2-0.13H638.07z M630.16,1393.08l0.02-0.3l-0.12-0.31l-1.4-0.17l-0.13,0.14l-0.11,0.43l0.3,0.69l0.39,0.04
  		l0.83-0.2L630.16,1393.08z M624.77,1468.16l-0.63,0.29l-0.36,0.29l-0.17,0.31l-0.22,0.37l-0.01,0.31l0.09,0.06l0.31,0.02
  		l-0.04-0.16l-0.17-0.06l-0.03-0.09l0.15-0.3l0.33-0.43l1.05-0.61l0.12-0.04l-0.1-0.07L624.77,1468.16z M634.06,1392.96l-0.23,0.1
  		l-0.17,0.22l0.09,0.42l0.55-0.06l0.39-0.21l0.01-0.16l-0.22-0.18L634.06,1392.96z"/>
    </>,
    PER: <>
        <polygon className="st1" points="1231.2,1423.79 1230.08,1421.82 1229.09,1420.09 1228.16,1418.47 1227.09,1416.59 1226.38,1415.36
  		1225.5,1413.82 1224.73,1412.48 1223.68,1410.64 1222.87,1410.66 1221.48,1410.48 1220.46,1410.45 1219.57,1410.9 1218.27,1411.45
  		1217.68,1411.59 1217.25,1411.61 1216.82,1411.54 1216.33,1411.26 1215.64,1410.6 1215.1,1410.85 1214.72,1411.13 1214.72,1409.7
  		1214.73,1407.54 1214.74,1405.64 1214.75,1404.12 1214.76,1402.35 1214.76,1401.1 1215.13,1400.62 1215.35,1400.09
  		1215.08,1399.38 1215.14,1398.73 1215.32,1398.28 1215.57,1397.84 1215.01,1398.06 1214.76,1398.18 1214.46,1398.52
  		1213.74,1398.97 1213.25,1399.42 1212.68,1399.79 1211.95,1400.61 1211.35,1401.06 1210.73,1401.34 1209.7,1402.3 1208.85,1402.49
  		1206.58,1402.64 1204.23,1402.63 1202.08,1402.63 1201.75,1402.62 1201.77,1401.83 1201.83,1401.27 1201.09,1400.68
  		1201.04,1399.96 1200.85,1399.46 1200.6,1398.84 1200.09,1398.45 1199.37,1398.3 1198.18,1397.96 1196.42,1397.61 1194.74,1397.58
  		1193.1,1397.62 1194.1,1396.39 1195.11,1395.16 1195.08,1394.09 1194.27,1393.16 1193.83,1392.58 1193.15,1391.78 1192.31,1391.23
  		1191.85,1390.49 1191.9,1390.05 1191.82,1389.76 1191.51,1389.58 1191.19,1389.31 1190.75,1389.02 1190.23,1388.63
  		1190.23,1388.24 1190.04,1387.82 1189.72,1387.33 1189.72,1386.94 1189.43,1386.33 1189.11,1385.89 1188.79,1385.6
  		1188.33,1385.18 1188.35,1384.84 1188.69,1384.67 1188.84,1384.28 1188.79,1383.89 1188.4,1383.64 1187.94,1383.52
  		1187.32,1382.81 1186.88,1382.45 1186.59,1382.23 1186.42,1381.98 1186.59,1381.81 1186.79,1381.57 1186.84,1381.17
  		1186.74,1380.81 1186.74,1380.49 1187.03,1380.39 1187.35,1380.44 1187.67,1380.25 1188.08,1380.17 1188.55,1380.12
  		1188.79,1379.91 1188.77,1379.51 1188.47,1378.76 1188.18,1378.44 1188.08,1377.98 1188.32,1377.08 1188.47,1376.51
  		1189.01,1375.91 1190.65,1374.61 1192.12,1373.73 1192.84,1373.64 1193.36,1373.31 1193.7,1372.81 1193.8,1372.27 1193.72,1371.79
  		1193.45,1371.09 1193.12,1370.22 1192.88,1369.73 1193.1,1369.14 1193.49,1368.34 1194.29,1367.14 1195.03,1365.84
  		1195.11,1365.46 1195.21,1364.67 1195.55,1363.05 1195.74,1362.17 1195.64,1361.83 1195.81,1361.54 1196.27,1361.3 1197.4,1361.07
  		1198.16,1360.59 1199.33,1359.68 1200.31,1358.72 1201.12,1358.41 1202.59,1357.51 1203.43,1356.94 1203.76,1356.77
  		1204.59,1356.36 1206.07,1356.21 1207.31,1356.06 1208.01,1355.8 1209.04,1355.68 1209.72,1355.38 1210.49,1355.37
  		1211.93,1355.07 1212.41,1354.6 1212.83,1354.05 1213.39,1353.58 1214.05,1353.46 1214.78,1353.54 1215.66,1353.53
  		1216.72,1353.39 1217.23,1353.75 1217.46,1354.2 1218.11,1354.65 1218.58,1354.63 1219.04,1354.53 1219.68,1354.92
  		1220.09,1354.87 1220.36,1354.65 1220.41,1354.11 1219.98,1353.49 1219.33,1352.9 1218.72,1352.55 1218.46,1352.09 1218.1,1351.15
  		1217.61,1350.82 1217.27,1350.57 1216.93,1350.61 1216.58,1350.87 1216.04,1351.03 1215.67,1351.01 1214.18,1350.36
  		1213.94,1350.3 1214.89,1348.81 1216.6,1346.17 1217.68,1344.48 1218.89,1342.61 1219.5,1341.66 1219.58,1341.5 1219.58,1341.25
  		1219.32,1340.89 1218.74,1340.74 1218.07,1340.46 1217.65,1340.01 1217.06,1339.81 1216.6,1339.49 1215.77,1339.17
  		1215.28,1338.88 1214.67,1338.78 1214.19,1338.24 1212.42,1337.21 1211.97,1337.11 1211.47,1337.27 1210.75,1337.43 1210.05,1338
  		1209.18,1338.18 1208.36,1338.18 1207.94,1337.84 1207.53,1337.72 1206.99,1337.26 1206.05,1336.9 1205.37,1336.66
  		1204.94,1336.77 1204.4,1337.29 1203.85,1337.8 1203.41,1338.11 1202.83,1338.1 1202.13,1338.57 1201.44,1338.73 1200.75,1338.81
  		1199.95,1338.97 1199.06,1338.69 1198.34,1338.44 1198.02,1338.32 1197.72,1338.4 1197.27,1338.66 1196.45,1338.77 1195.81,1338.8
  		1195.36,1338.68 1194.95,1338.22 1194.29,1337.99 1193.56,1337.71 1193.41,1337.12 1193.51,1336.69 1193.79,1336.06
  		1193.64,1335.41 1193.33,1334.38 1193.2,1333.96 1192.97,1333.6 1192.62,1333.47 1191.92,1333.57 1191.15,1333.18 1190.68,1332.81
  		1190.44,1332.36 1190.7,1331.5 1190.47,1330.77 1190.01,1330.37 1189.72,1329.66 1189.26,1329.09 1188.66,1328.8 1188.06,1328.84
  		1187.59,1328.66 1187.05,1328.06 1186.55,1327.83 1185.98,1327.25 1184.91,1326.99 1184.36,1326.77 1184.05,1326.41
  		1183.62,1325.76 1183.67,1325.41 1183.46,1325.06 1183.28,1324.43 1182.92,1323.5 1182.52,1322.98 1182.11,1322.58
  		1181.76,1322.24 1181.25,1321.74 1180.61,1321.45 1180.07,1321.14 1179.86,1320.69 1179.68,1320.32 1179.38,1320.35
  		1178.95,1320.31 1178.48,1320.22 1177.97,1319.95 1177.55,1319.62 1176.85,1319.07 1176.46,1318.99 1176.12,1318.99
  		1175.62,1319.54 1175.15,1319.83 1174.66,1319.87 1174.01,1319.96 1173.1,1319.67 1172.74,1319.67 1172.69,1319.96
  		1173.29,1320.32 1173.88,1320.72 1174.09,1321.34 1174.44,1322.07 1175.28,1322.88 1175.8,1323.29 1175.83,1323.58
  		1175.67,1324.12 1175.63,1324.56 1175.91,1326.61 1175.72,1326.73 1175.41,1326.74 1175.08,1326.73 1174.81,1326.51
  		1174.58,1326.38 1174.47,1326.7 1174.23,1327.61 1173.69,1329.66 1173.21,1331.46 1172.61,1332.09 1171.74,1333.1 1170.56,1334.49
  		1168.84,1336.49 1167.56,1337.42 1166.56,1338.15 1165.39,1339 1163.87,1340.09 1162.17,1340.7 1159.81,1341.56 1158.14,1342.16
  		1156.9,1342.58 1155.62,1343.02 1153.92,1343.6 1153.27,1344.14 1152.18,1345.48 1151.67,1346.13 1151.2,1346.68 1151.11,1346.94
  		1151.17,1347.1 1151.39,1347.37 1151.41,1347.65 1151.11,1347.82 1150.84,1347.85 1150.72,1347.71 1150.64,1347.4 1150.35,1347.09
  		1150.03,1347 1149.84,1347.08 1149.82,1347.36 1149.39,1348.73 1149.38,1349.4 1149.2,1349.66 1149.21,1350.26 1148.78,1350.81
  		1148.59,1351.31 1148.46,1351.73 1148.11,1352.02 1147.99,1352.48 1147.67,1353.45 1147.29,1354.21 1147.02,1354.86
  		1146.98,1355.35 1147.18,1355.69 1147.25,1355.97 1147.07,1356.47 1146.97,1356.84 1146.49,1357.1 1145.49,1357.7 1145.1,1358.12
  		1144.95,1358.59 1145.05,1358.99 1145.01,1359.32 1144.53,1359.45 1144.36,1359.74 1144.04,1360.25 1143.68,1360.43
  		1142.75,1360.16 1142.07,1360.16 1141.54,1359.91 1140.96,1359.17 1140.49,1358.55 1140.1,1357.75 1139.98,1356.65
  		1139.46,1356.32 1138.95,1355.94 1138.34,1356.05 1137.61,1356.12 1137.21,1355.86 1136.21,1355.4 1135.37,1354.87
  		1134.73,1354.61 1134.24,1354.73 1133.95,1355.05 1133.43,1355.62 1132.67,1356.01 1132.33,1356 1131.87,1355.73 1131.79,1355.43
  		1132.16,1354.94 1132.93,1353.88 1132.08,1353.85 1131.79,1353.52 1131.75,1353.13 1131.61,1352.71 1131.77,1352.21
  		1132.22,1351.95 1132.89,1352.16 1133.35,1352.17 1133.65,1351.7 1133.96,1351.49 1134.27,1351.34 1134.39,1351.1 1134.07,1350.35
  		1133.97,1349.94 1134.07,1349.7 1134.06,1349.23 1134.04,1348.89 1133.85,1348.58 1133.83,1348.12 1133.67,1347.88
  		1133.61,1347.61 1133.6,1347.31 1133.39,1347.16 1133.17,1347 1131.66,1347.91 1130.4,1349.1 1129.18,1349.87 1128.39,1351.14
  		1125.53,1354.09 1125.09,1354.83 1124.64,1357.74 1125.04,1358.5 1125.84,1359.5 1126.57,1360.74 1126.21,1361.37 1126.07,1361.91
  		1127.96,1364.5 1128.47,1365.84 1128.47,1366.88 1128.06,1367.57 1127.55,1367.73 1126.71,1367.33 1126.1,1367.86 1125.96,1368.42
  		1126.28,1369.38 1126.99,1369.99 1129.07,1371.27 1134.97,1374.36 1135.94,1375.36 1136.7,1376.48 1137.91,1377.86
  		1139.12,1379.79 1139.36,1380.83 1141.15,1384.33 1141.69,1385.07 1142.94,1386.12 1144.22,1387.49 1144.95,1389.13
  		1146.33,1390.92 1146.39,1391.96 1147.15,1393.9 1147.86,1395.47 1148.99,1397.27 1149.74,1399.65 1150.43,1400.99
  		1151.18,1403.34 1151.94,1404.79 1154.97,1409.66 1155.57,1411.23 1155.79,1412.69 1155.84,1413.48 1158.56,1415.56
  		1159.31,1416.67 1159.84,1418.88 1159.88,1420.04 1160.64,1420.43 1160.85,1420.99 1161.22,1421.39 1162.58,1422.49 1163.21,1424
  		1164.23,1425.71 1164.92,1426.52 1165.36,1427.89 1165.99,1428.96 1167.71,1431.18 1168.04,1432.41 1167.41,1434.86
  		1166.9,1435.02 1166.42,1435.38 1167.09,1436.11 1167.16,1437.68 1168.12,1438.48 1168.44,1439.28 1169.54,1440.78
  		1170.15,1441.96 1171.8,1443.25 1173.52,1444.23 1174.67,1445.89 1175.7,1446.62 1176.41,1447.83 1177.14,1448.62 1181.76,1451.08
  		1183.3,1452.24 1185.2,1452.91 1187.91,1454.97 1188.73,1455.39 1191.49,1456.28 1192.64,1457 1195.22,1458.14 1196.59,1458.94
  		1199.34,1459.75 1200.23,1460.32 1201.02,1461.2 1202.34,1462.28 1203.56,1462.81 1204.39,1463.56 1205.18,1463.98 1207.22,1464.8
  		1208.03,1465.42 1208.34,1465.9 1208.63,1467.61 1208.87,1468.15 1211.23,1469.82 1212.2,1470.3 1213.24,1471.35 1215.99,1473.29
  		1216.6,1473.88 1216.95,1473.78 1217.75,1473.71 1218.58,1473.7 1219.63,1473.34 1220.75,1472.67 1221.48,1471.7 1221.79,1470.81
  		1221.79,1470.03 1221.46,1469.03 1221.37,1468.33 1221.76,1468 1222.78,1467.87 1223.61,1467.2 1224.25,1466.63 1224.24,1466.61
  		1224.25,1466.23 1224.15,1465.61 1223.8,1465.13 1223.28,1464.8 1223.11,1464.4 1223.29,1463.99 1224.31,1463.16 1224.86,1463.02
  		1225,1462.61 1225.34,1462.27 1226.3,1461.06 1226.87,1460.27 1227.43,1459.79 1228.09,1459.46 1228.38,1459.18 1228.23,1458.33
  		1228.23,1458.24 1228.13,1458.18 1227.91,1458.14 1227.72,1457.82 1228.11,1457.25 1228.22,1456.79 1228.19,1456.4
  		1228.18,1456.08 1228.51,1455.83 1228.72,1455.72 1228.16,1455.53 1227.43,1455.57 1227.41,1455.66 1227.05,1455.81
  		1226.68,1455.84 1226.29,1455.82 1225.82,1455.75 1225.46,1455.58 1225.18,1455.38 1224.88,1455.27 1224.48,1455.22
  		1224.45,1455.05 1224.76,1454.62 1224.85,1454.17 1224.78,1453.92 1224.43,1453.77 1223.85,1453.58 1223.27,1453.38
  		1222.92,1453.17 1222.73,1452.99 1222.51,1452.86 1222.25,1452.69 1221.96,1452.42 1221.71,1452.19 1221.52,1452.11
  		1221.42,1452.18 1221.36,1452.3 1221.37,1452.47 1221.46,1452.67 1221.53,1452.84 1221.52,1452.94 1221.43,1452.98
  		1221.32,1452.98 1221.17,1452.92 1221.01,1452.8 1220.77,1452.63 1220.45,1452.46 1220.18,1452.31 1220.03,1452.19 1219.99,1452
  		1219.95,1451.7 1219.98,1451.38 1220.11,1451.14 1220.3,1450.92 1220.49,1450.63 1220.8,1450.52 1221.25,1450.74 1221.66,1451.02
  		1221.83,1451.03 1221.66,1450.56 1221.23,1449.66 1220.72,1448.75 1220.65,1448.51 1220.85,1448.3 1221.35,1448.04
  		1221.44,1447.87 1221.53,1447.74 1221.82,1447.65 1222.14,1447.55 1222.3,1447.4 1222.76,1447.35 1223.28,1448.03 1223.99,1448.28
  		1224.77,1449.15 1225.14,1449.3 1225.48,1449.45 1226.01,1448.51 1226.41,1447.94 1227.1,1447.12 1226.97,1446.79 1225.76,1445.42
  		1225.39,1444.77 1225.4,1444.13 1225.52,1443.34 1226.22,1442.92 1226.42,1442.28 1226.57,1441.64 1226.87,1441.44
  		1227.18,1441.08 1227.54,1440.56 1228.1,1440.11 1228.44,1439.77 1228.51,1438.81 1228.79,1438.55 1229.56,1438.24
  		1229.64,1437.99 1229.46,1437.35 1229.08,1436.67 1228.77,1436.34 1228.36,1434.67 1227.93,1433.84 1228.1,1433.51 1228.4,1433.09
  		1228.69,1432.25 1228.78,1431.28 1228.71,1427.71 1228.73,1427 1229.11,1426.51 1229.66,1425.94 1230.13,1425.72 1230.58,1425.36
  		1230.55,1424.69 1230.84,1424.29 	"/>
    </>,
    PAN: <>
        <path className="st0" d="M1117.09,1239.93l-0.22-0.42l-0.14-0.01l-0.39,0.12l0.65,0.71L1117.09,1239.93z M1121.63,1256.41l-0.13-0.51
  		l0.33-0.32l-0.48-0.82l-0.2,0l-0.5,0.25l-0.38,0.71l-0.01,0.23l0.05,0.23l0.69,0.7l0.94,0.36l0.46-0.04l-0.09-0.4L1121.63,1256.41z
  		 M1143.51,1249.09l-0.26,0.37l0.14,0.35l0.38-0.37l-0.17-0.35L1143.51,1249.09z M1159.38,1251.29l-0.59-1.29l-0.53-0.69l-0.34-0.69
  		l-0.18-0.64l-0.6-0.6l0.25-0.57l0.47-0.66l0.16-0.11l-2.72-1.95l-1.12-1.51l-2.12-1.42l-3.55-1.43l-1.62-0.24l-1.98,0.05
  		l-0.37-0.21l-0.35-0.49l-0.8-0.22l-0.84,0.04l-1.21-0.32l-1.87-0.24l-0.63,0.33l-0.6,0.67l-1.11,0.85l-0.51,0.14l-0.53,0.15
  		l-1.26,1.13l-3.54,1.08l-1.09,0.51l-1.37,1.14l-1.89,0.63l-1.18,0.22l-1.27,0.05l-1.61-0.39l-1.4-1.62l-0.76-0.47l-0.34-0.38
  		l-0.44-0.18l-0.05,0.25l0.58,0.55l0.43,0.75l-0.39,0.11l-0.57-0.1l-1.55,0.18l-0.47-0.38l-0.93-0.43l0.07-0.93l0.29-0.22l0.11-0.2
  		l-0.14-0.2l-0.57,0.21l-0.57-0.16l-0.3-1.08l0.1-0.38l-0.06-0.39l-1.09-0.8l-0.53-0.45l-0.05,0.16l-0.15,0.16l-0.21,0.17
  		l-0.28,0.11l-0.67-0.34l-0.65-0.39l-0.36,0.18l-0.14,0.5l-0.24,0.26l-0.3,0.1l-0.12,0.17l-0.03,1.69l0.02,1.59l0.5,0.04l0.83,0.55
  		l0.35,0.33l0.11,0.3l-0.1,0.15l-0.61,0.35l-0.59,0.44l-0.3,0.55l0.52,0.89l0.11,0.6l-0.02,0.63l-0.14,0.3l-1.15,0.72l-0.25,0.25
  		l0.03,0.18l0.63,0.5l0.3,0.48l0.25,0.58l0.03,0.5l0.21-0.24l-0.1-1.24l0.72-0.48l0.86-0.16l1.25,0.29l1.4,0.11l1.09-0.3l0.09,0.68
  		l0.54,0.3l0.53-0.24l1.04,0.06l0.95,0.42l1.12,0.23l0.28,0.56l0.16,0.47l1.44,2.48l1.13,0.39l0.85,0.42l0.42,0.04l0.2-0.4
  		l0.14-1.17l0.18-0.39l0.54-0.18l0.25-0.2l0.24,1.59l1.01,2.3l0.11,1.35l0.47,0.48l1.51-0.05l1.92-0.41l0.56-0.42l0.21-0.51
  		l0.51-0.34l1.49-0.07l0.36-0.17l0.47-0.39l-0.24-0.84l-0.3-0.57l-1.56-1.55l-0.88-1.23l-0.37-0.26l-0.41-0.41l-0.07-0.53l0.06-0.62
  		l0.43-0.41l0.33-0.22l1.42-0.21l0.63-0.3l3.16-2.07l-0.55-0.37l0.48-0.61l0.23-0.54l0.37-0.64l0.97-0.44l0.17-0.18l0.38-0.38
  		l0.55-0.3l1.64-0.12l1.35,0.19l1.11,0.55l0.9,0.76l0.66,0.26l0.5,0.49l0.34,0.09l0.41,0.24l0.9,0.72l0.38,1.53l0.28,0.37l0.22,0.4
  		l0.26-0.03l0.08-0.39l-0.16-0.32l-0.1-0.52l0.21,0.14l0.2,0.25l0.79,0.05l0.04,0.27l0.24,0.21l0.27-0.17l0.24-0.31l0.53-0.36
  		l0.36,0.84l0.38,0.6l1.34,0.92l0.78,0.7l-0.62-0.15l-0.99-0.67l-0.81-0.46l-0.56-0.8l-0.24-0.05l-0.32,0.47l-0.85,0.7l0.22,0.92
  		l-0.27,0.39l-0.24,0.21l-0.44-0.03l-0.45,0.08l0.37,1.36l1.75,3l2.02,2.42l0.24,0.23l0.61-1.8l0.54-0.34l0.18-0.45l-0.21-1.1
  		l0.06-0.26l0.13-0.11l0.13,0.01l0.22,0.17l0.4,0.48l0.34,0.59l0.27,0.18l0.41-0.2l1.58-1.17l-0.1-0.36l0.14-0.74l0.53-0.6
  		l0.56-0.21l0.17-0.34L1159.38,1251.29z M1145.31,1247.7l-0.28,0.02l-0.38,0.19l0.03,0.72l-0.06,0.2l0.39,0.79l0.17-0.36l0.38-0.24
  		l0.12-0.38l-0.14-0.85L1145.31,1247.7z"/>
    </>,
    OMN: <>
        <path className="st0" d="M2283.33,1102.28l-0.15,0.33l-0.05,0.48l0.25,0.04l0.35-0.24l0.05-0.38l-0.09-0.2L2283.33,1102.28z
  		 M2282.77,1095.62l0.04,0.92l-0.16,0.87l-0.02,0.92l-0.06,0.5l0.33,0.41l0.56,0.17l0.24-0.02l0.16-0.21l0.08-0.52l0.19-0.38
  		l0.37-0.47l0.36-2.73l0.01-0.89l0.1-1.07l-0.14-0.22l-0.29-0.05l-0.27,0.38l-0.34,0.7l-0.65,0.14l-0.26-0.08l-0.28,0.2l-0.71,1.3
  		l0.3-0.05L2282.77,1095.62z M2313.56,1127.07l-1.07-0.33l-1.35-0.29l-0.89-0.73l-1-1.17l-0.98-1.58l-1.39-1.4l-0.39-0.92
  		l-0.61-0.88l-0.68-0.56l-0.48-1.06l-1.64-1.12l-0.66-0.02l-0.9,0.24l-0.58-0.05l-1.72-0.82l-2.49-0.38l-1.8-0.39l-3.3-1.06
  		l-0.82-0.51l-1.77-1.5l-1.17-1.64l-1.12-1.21l-1.27-2.19l-0.86-2.34l-0.3,0.05l-0.33,0.37l-0.38,0.58l-0.53,0.3l-0.42,0.34
  		l-0.4,0.42l-0.36,0.09l-0.47-0.53l-0.32-0.54l0.08-0.12l0.22-0.04l0.09-0.28l-0.14-0.41l-0.32-0.15l-0.4-0.01l-0.38,0.18l-0.4,0.36
  		l-0.23,0.38l-0.03,0.77l0.11,0.87l0,0.42l-0.22,0.52l-0.08,0.77l0.16,0.6l0.14,0.36l0.01,0.3l-0.38,0.95l0.33,0.18l1.09,0.07
  		l0.32,0.64l0.22,0.44l-0.06,0.26l-0.77,0.19l-0.97,0.22l-0.7-0.06l-1.25,0.29l-0.67,0.45l0.2,0.28l0.23,0.21l0.1,0.59l-0.2,0.84
  		l-0.35,0.81l-0.44,1.01l-0.51,1.16l-0.7,1.75l-0.59,1.37l-0.07,0.99l0.02,0.64l-0.07,1.29l0.62,1l0.51,0.83l0.7,1.14l0.75,1.21
  		l0.72,1.15l0.53,0.86l-0.28,0.89l-0.31,0.97l-0.31,0.97l-0.31,0.97l-0.31,0.97l-0.31,0.97l-0.31,0.97l-0.31,0.97l-0.31,0.97
  		l-0.31,0.97l-0.31,0.97l-0.31,0.97l-0.31,0.97l-0.31,0.97l-0.31,0.97l-0.31,0.97l-0.31,0.97l-0.37,1.16l-0.9,0.31l-1.45,0.5
  		l-1.45,0.5l-1.45,0.5l-1.45,0.5l-1.45,0.5l-1.45,0.5l-1.45,0.5l-1.45,0.5l-1.45,0.5l-1.45,0.5l-1.45,0.5l-1.45,0.5l-1.45,0.5
  		l-1.45,0.5l-1.45,0.5l-1.45,0.5l-1.19,0.41l0.37,0.87l0.37,0.87l0.37,0.87l0.37,0.87l0.37,0.87l0.37,0.86l0.37,0.86l0.37,0.86
  		l0.37,0.86l0.37,0.86l0.37,0.86l0.37,0.86l0.37,0.86l0.37,0.86l0.37,0.86l0.37,0.86l0.36,0.84l0.6,0.28l0.36,0.8l0.51,1.14
  		l0.51,1.14l0.51,1.13l0.51,1.13l1.79-0.64l2.63-0.32l1.39-0.82l1.51-0.53l0.96-0.76l2.6-0.24l1.6,0.02l0.83,0.19l0.9,0.38
  		l2.46-0.64l0.92-1.02l0.85-1.41l0.17-0.52l-0.12-0.56l-0.36-0.5l0.14-0.7l1.88-2.22l1.14-0.37l3.23-0.42l2.3-0.13l0.95-0.32
  		l1.41-1.54l0.88-3.65l1.44-1.44l1.1-0.64l1.85-0.65l2.12-0.36l2.09-0.12l0.53-0.17l0.62-0.34l-0.18-1.12l-0.22-0.93l-0.03-1.55
  		l-0.39-1.52l-0.01-0.62l0.23-1.1l0.51-1.31l0.35-1.42l0.15-1.1l0.72-0.87l1.31-1.98l0.56-0.17l0.64-0.08l-0.11,0.81l-0.19,0.72
  		l0.48,0.25l0.7,0.07l1.06-0.17l0.51-0.85l1.32-2.65l1.73-2.67l1.46-1.54l1.99-1.28l0.56-0.56l1.23-2.49l1.14-1.48l0.24-0.9l1-1.46
  		l0.21-0.75l0.11-1.02L2313.56,1127.07z M2304.84,1144.71l-1.24,1.39l0,1.1l0.15,0.06l0.53-0.13l0.42-0.42l0.53-1.37l0.97-0.81
  		l-0.56-1.44L2304.84,1144.71z"/>
    </>,
    NZL: <>
        <path className="st0" d="M3209.73,1782.61l-1.04,0.09l0.01,0.38l-0.25,1.17l-1.33,0.93l-0.22,0.49l0.13,0.15l0.57-0.02l0.35-0.46
  		l0.21,0.1l0.3,0.4l0.97,0.26l0.46,0l-0.18-0.93l-0.17-0.12l-0.18-0.4l0.34-0.22l-0.11-0.46l0.02-0.45l0.42-0.38l0.07-0.21
  		l-0.11-0.16L3209.73,1782.61z M3212.82,1730.7l0.43,0.24l0.44,0.06l0.29,0l0.1-0.26l0.04-0.51l-0.12-0.18l-0.39-0.25l-1,0.31
  		l-0.3,0.58l0.23,0.09L3212.82,1730.7z M3225.99,1744.25l-0.26-0.34l-0.69-0.25l-0.02-0.48l0.88,0.15l0-0.42l-1.59-1.78l-1.45,0.06
  		l-0.16,1.04l0.3,1.15l-0.5,0.53l-0.01,0.6l-0.73,0.33l-0.19,0.46l-0.02,0.58l-0.76,0.6l-0.14,0.64l0.28,0.05l1.02-0.22l0.91-0.71
  		l0.22-0.06l0.8,0.1l0.92-0.66l1.42-0.17l0.48-0.34l0.17-0.46l-0.16-0.51L3225.99,1744.25z M3216.45,1726.5l-0.36-1.25l-0.14,0.01
  		l-0.59,0.63l0.32,0.38L3216.45,1726.5z M3234.19,1804.47l-0.42,0.15l-0.48-0.04l0.15,0.37l0.3,0.26l0.43,0.21l0.89-0.25l-0.47-0.58
  		L3234.19,1804.47z M3286.66,1635.95l0.72,0.41l0.25,0.4l0.64,0.19l-0.07-0.52l-0.26-0.98l-0.57-0.6l-0.29-0.42l-0.17,0.07
  		l-0.07,0.16l-0.38,0.38l0.08,0.8L3286.66,1635.95z M3357.12,1710.62l0.52,0.02l0.3-0.27l-3.27-0.23l-0.85,0.49l-0.79-0.07
  		l-0.73,0.68l0.33,0.11l1.46-0.15l0.67,0.33l0.26,0.5l-0.33,0.55l-0.58,0.54l0.02,0.31l0.26,0.73l0.22,0.08l0.47,0.02l0.53-0.41
  		l1-0.29l0-0.24l-0.44-0.76l-0.45,0.03l-0.51-0.97l0.5-0.45l-0.12-0.12l-0.52-0.21l0.15-0.17l0.77-0.07l0.27,0.3l-0.05,0.78
  		L3357.12,1710.62z M3357.51,1715.48l0.12,0.38l-0.05,0.59l0.37-0.09l0.45-0.55l-0.27-0.45L3357.51,1715.48z M3276.38,1685.12
  		l-0.84-0.06l0.54-0.25l0.81-0.57l0.12-0.35l0.51-0.57l0.24-0.49l-0.66,0.05l-0.1-0.39l-0.49,0.05l-0.27,0.14l-0.34,0.01l-0.68-0.13
  		l-0.02,0.35l0.22,0.44l-0.56,0.28l-0.36,0.58l0.16,0.29l-0.31,0.52l-0.84,0.33l0.54-0.8l-0.02-0.68l0.46-0.54l-0.3-0.39l0.08-0.24
  		l0.53-0.22l0.42-0.67l-0.47,0.06l-0.42,0.27l-0.96,0.22l-0.39,0.17l-1.48,1.13l-0.97,0.49l-0.92,0.6l-0.9,0.73l-0.97-0.05
  		l-0.39-0.94l-0.14-1.07l-0.08-1.25l-0.45-1.05l-1.01-0.28l-0.86-0.47l-0.32-0.5l-0.21-0.56l0.06-0.62l0.18-0.61l1.78-0.25
  		l-0.96-0.29l-1,0.07l-0.59,0.21l-1.45,1.03l-1.65,1.36l-1.12,1.88l-0.39,2.54l-0.7,2.44l-0.53,0.94l-0.99,1.17l-0.83,0.65
  		l-0.5,0.25l-1.14,0.13l-0.42,0.37l-0.55,1.8l-0.51,1.08l-0.32,1.1l-0.22,1.15l-0.37,1.01l0.37,0.29l0.15,0.3l-0.47,0.05l-0.3,0.13
  		l-0.27,0.22l-1.36,1.98l0.17,1.07l-0.08,0.61l-0.22,0.23l0.05-0.67l-0.05-0.56l-0.35-0.46l-1.09,1.32l-0.83,0.8l-0.14,0.49
  		l0.08,0.55l-0.59-0.7l-0.42,0.11l-0.77,0.37l0.1,0.32l0.64,0.52l-0.64-0.34l-0.65-0.21l-0.67,0.29l-0.65,0.42l0.6,0.28l0.18,0.49
  		l-0.34-0.29l-0.47-0.09l-0.5,0.2l-0.43,0.59l-0.34,0.26l-0.38,0.18l-0.73,0.86l-1.34,0.78l0.41,0.21l-0.14,0.15l-0.47-0.03
  		l-0.09,0.39l0.08,0.41l-0.54,0.02l-0.91,0.54l-1.23,0.33l-1.62,0.8l-1.29,0.77l-0.28,0.44l0.36,0.96l-0.36-0.14l-0.58-0.37
  		l-0.64,0.27l-1.55,1.05l-0.27,0.05l-1.04-0.25l-1.63,0.6l-0.76,0.53l-1.44,1.46l-1.5,1.39l-1.36,1.47l0.08,0.95l0.3,0.34l0.06,0.41
  		l-0.42-0.41l-0.61-0.31l-0.75,0.48l-1.02,1.03l-0.78,0.32l-0.21,0.32l-0.03,0.37l0.22,0.37l-0.02,0.43l-0.11,0.45l-0.47-1.35
  		l-1.82,1.41l-0.19,0.35l0.28,0.53l0.46,0.35l-0.6,0.12l-0.51-0.45l-0.86,0.76l-0.03,0.55l0.09,0.48l0.8,0.45l0.67,0.15l0.2,0.1
  		l-0.71,0.12l-0.23,0.17l-0.05,0.37l0.37,0.59l-0.87-0.29l-1.21-0.79l-0.33,0.04l0.05,0.59l0.37,0.42l-1.19,0.02l-0.3,0.61
  		l-0.08,0.78l1.14,0.07l1.03-0.19l-1.39,0.74l-0.24,0.45l0.1,0.56l1.63,0.14l-0.43,0.4l-0.98,0.25l-2.72,0.39l-0.21,0.21l-0.09,0.74
  		l0.13,0.64l1.01-0.09l0.89-0.69l0.13,0.41l-0.06,0.37l-0.65,0.82l0.68,0.12l1.06-0.75l0.51-0.25l-1.72,1.85l0.16,0.68l0.84,0.29
  		l2.27,0.25l2.26-0.08l0.38-0.13l0.64-0.78l0.41-0.06l1.2,0.47l0.34,0.36l1.01,1.46l0.49,0.02l1.49-0.16l0.94,0.1l0.35,0.25
  		l0.3,0.35l0.45,0.3l0.2,0.44l-0.15,0.6l0.27,0.45l0.21,0.18l0.71-0.18l0.89,0.24l0.5-0.25l1.14-0.22l0.6,0.13l1.08,0.37l1.12,0.19
  		l2.05-0.11l2.9-0.73l0.36-0.32l0.27-0.44l1.33-1.53l2.26-1.83l0.68-0.82l0.58-0.95l0.7-0.53l2.15-0.48l0.4-0.19l0.46-0.07
  		l0.13-0.28l-0.02-0.54l-0.41-0.38l-0.34-0.44l0.01-0.31l0.97-1.73l0.63-1.52l0.42-1.64l0.43-0.68l1.03-1.17l0.18-0.64l-0.94-0.42
  		l-0.2-0.27l1.24,0.01l0.43-1.5l0.13-1.61l0.15-0.94l0.69-2.27l-0.23-0.24l-0.38-0.15l1.04-0.09l0.45-0.48l0.21-0.75l0.63-0.18
  		l1.19-0.01l0.45-0.2l0.81-0.57l0.69-0.36l0.73-0.23l0.87-0.4l0.83-0.51l-0.36-0.64l-0.72-0.96l-0.14-0.39l0.93,0.63l0.63,0.63
  		l0.64,0.44l0.45-0.09l0.29-0.31l0.09-0.53l0.29-0.32l0.43-0.2l0.4,0.13l0.48,0.35l-0.91,0.61l0.23,0.11l0.44-0.13l1.64-0.19
  		l0.58,0.59l0.87,0.22l0.87-0.06l0.36-0.11l0.24-0.31l0.19-0.48l-0.16-0.97l-0.21-0.28l-1.06-0.18l-1.18-0.39l-0.34-0.61l-0.15-0.46
  		l-0.07-0.51l-0.4-0.24l-1.41,0.21l0.3-0.29l0.59-0.08l0.57-0.29l0.3-0.46l-0.4-0.41l-0.51-0.15l-0.02-0.28l0.8-0.14l0.75-0.62
  		l0.68-0.75l1.55-0.65l0.64-0.38l0.61-0.47l1.06-1.38l1.66-3.28l0.37-0.45l2.11-2.06l0.42-0.6l-0.01-0.82l0.72-0.5l0.62-0.78
  		l1.42-1.54l0.24-0.37l0.33-0.73l-0.56-0.63l-0.4-0.21l-0.73,0.14l0.65-1.1l-0.58-0.57l-0.16-0.34l-0.03-0.42l0.28-0.64l0.57-0.39
  		l0.57-0.15l0.51-0.48l0.59-0.76l0.02-0.85l-0.73,0.68L3276.38,1685.12z M3399.96,1397.07l-0.04-0.07l-0.06-0.05l-0.02,0.01
  		l0.04,0.1l0.06,0.07l0.04,0.05l0.02-0.02L3399.96,1397.07z M3389.03,1390.4l-0.08-0.08l-0.01,0.01l0.04,0.09l0.05,0.1l0.04,0.1
  		l0.04-0.02l-0.02-0.11L3389.03,1390.4z M3311.94,1649.34l-0.75-0.5l-2.21-0.12l-0.43,0.25l-0.41,0.35l-0.82,0.38l-0.72,0.48
  		l-0.66,0.99l-0.76,0.87l-0.88,0.58l-0.99,0.33l-0.52,0.03l-0.95-0.08l-3.3-0.93l-1.31-0.57l-2.72-1.47l-0.41-0.16l-0.44,0.03
  		l-0.7-0.21l-0.59-0.43l-0.07-0.24l0.2-0.14l0.64,0.24l-0.12-0.47l-1.05-0.98l-0.58-2.25l-0.38-2.39l-0.29-0.8l-0.52-0.68
  		l-0.07-0.67l-0.76,0.11l-1.29-1.61l-0.26-0.55l-0.31-0.45l-0.51,0.24l-0.12,0.53l0.34,0.34l0.27,0.41l0.25,0.53l0.12,0.56
  		l-0.07,0.57l0,0.57l0.5,1.74l0.14,1.09l-0.22,0.41l-0.69,0.15l-0.64-0.09l-0.33-0.49l-0.17-1.11l-0.23-0.46l-0.46-0.21l-1.67-0.57
  		l-0.8-0.57l-0.29,0.12l-0.22,0.43l-0.35-0.36l-0.4-0.19l-0.67-0.12l-0.03-0.44l0.26-0.21l0.24-1.2l0.35-0.36l-0.57-1.17l0.21-0.44
  		l-0.04-0.52l0.25-0.78l-1.66-2.2l-0.47-0.71l-0.59-1.17l-0.7-0.83l-0.04-0.23l1.2,0.19l0.4-0.08l-0.61-1.13l0.2-0.39l0.1-0.43
  		l-0.66-1.22l-0.38-0.41l-0.29-0.42l0.07,0.01l-0.17-0.42l-0.45-0.74l-0.32,0.06l-0.67,0.52l-0.51-0.08l-0.21-0.35l-0.08-0.44
  		l0.08-0.42l-0.13-0.28l-1.52-0.82l-0.67-0.29l-0.26,0.14l-0.22,0.26l-0.4-0.13l-0.38-0.47l-1.86-0.56l-0.29-0.18l0.08-0.8
  		l-0.56,0.5l-0.35,0.49l-0.47,0.31l-0.13-0.44l-0.24-0.34l-0.5-0.44l-0.09-0.43l-0.98-1.51l-0.46-0.49l-0.3-0.58l0.68-1l-1.43,0.04
  		l-1.41,0.21l1.3,1.68l1.42,1.59l0.74,0.98l0.62,1.07l-0.02,1.02l-0.61,0.78l0.37,0.4l0.57,0.8l0.39,0.08l0.14,0.25l-0.01,0.4
  		l0.2,0.33l0.53,0.54l0.21-0.18l0.44-0.78l0.35-0.35l0.38-0.31l0.34-0.16l0.38,0.06l-0.13,0.36l-0.21,0.3l-0.89,0.67l-0.57,0.8
  		l4.49,6.06l0.39,0.59l0.54,1.18l0.36,0.3l0.41-0.14l0.17-0.47l-0.2-0.37l-1.17-1.37l-0.72-1.23l-0.07-0.61l0.05-0.44l0.46,1.07
  		l0.57,0.98l1.83,1.22l0.2-0.05l-0.21-0.46l0.13-0.31l0.3,0.07l0.75,0.67l0.02,0.33l-0.36,0.47l0.01,0.51l0.47,0.28l0.31,0.44
  		l0.06,0.58l-0.19,0.52l-0.25,0.36l-1.31-1.13l-0.48,0.07l1.63,2.25l0.2,0.41l0.32,1.1l0.26,0.57l0.51,0.3l0.55,0.12l0.56-0.13
  		l0.55-0.22l0.41-0.05l1.24,1.36l-0.55,0.04l-0.51,0.2l-0.48,0.39l-0.1,0.63l-0.63-1.22l-0.42-0.18l-0.2,0.27l0.73,1.7l0.29,0.5
  		l0.51,0.13l-0.2,0.53l-0.12,0.54l0.17,0.54l0.81,1.75l0.69,1.16l-0.41,0.16l-0.36,0.27l-0.3,0.45l0.32,1.24l-0.26,0.75l-0.79,1.23
  		l-0.53,1.98l-0.29,1.73l-0.18,1.76l-0.26,0.55l-0.91,0.83l-0.51,0.36l-0.36,0.09l-0.37-0.01l-2.02,0.59l-1.91,1.06l-0.32,0.3
  		l-0.21,0.41l-0.13,0.53l-0.02,0.53l0.16,0.57l0.25,0.49l1.03,0.82l1.8,0.58l1.71,0.74l0.86,0.91l0.95,0.77l1.01,0.34l1.07,0.13
  		l1.65,0.91l1.24,1.61l0.46,0.84l0.37,0.89l-0.45,2.15l-0.32,1.16l-1.23,2.25l-1.42,2.11l-1.37,1.59l-0.24,0.34l-0.18,0.38
  		l0.06,0.23l0.22,0.14l0.74-0.01l0.71-0.35l-0.18-0.28l0.1-0.32l0.29-0.08l0.29,0.2l-0.21,0.36l0,1.26l0.05,0.2l0.21,0.09l1.25-0.42
  		l0.94,0.26l0.16,0.32l0.17,0.86l0.15,0.4l0.74,0.36l0.59-0.31l0.56-0.42l2.02-1.27l1.28-0.92l1.21-1.07l0.65-0.84l0.49-1.01
  		l1.12-1.52l0.52-1.08l0.6-1.01l0.77-0.97l1.14-1.27l0.65-1.48l0.69-0.65l0.6-0.7l1.06-2.45l1.2-2.35l-1.21-0.67l-0.23-0.49
  		l-0.03-0.64l0.15-1.21l0.65-0.99l0.38-0.44l0.44-0.35l1.41-0.69l0.93-0.34l0.97-0.07l1.12,0.12l1.1,0.25l0.36,0.33l0.39,0.79
  		l0.28,0.14l0.48-0.95l-0.12-0.47l-0.29-0.32l-0.05-0.4l0.18-1.59l0.37-1.35l0.92-0.28l0.81-0.59l0.73-0.81l0.4-1.05l0.27-2.37
  		l0.39-2.34l0.45-1.03l0.58-0.94l0.17-0.64l-0.51-0.31L3311.94,1649.34z M3274.86,1680.5l0.05-0.74l-0.52,0.33l-0.25,0.03
  		l-0.51,0.44l-0.22,0.88l-0.05,0.4l1.13-0.58L3274.86,1680.5z"/>
    </>,
    NIU: <>
        <polygon className="st1" points="3411.37,1479.26 3411.14,1479.28 3410.74,1479.47 3410.41,1480.19 3410.78,1480.75 3411.63,1480.27
  		3411.71,1479.92 	"/>
    </>,
    NRU: <>
        <polygon className="st1" points="3215.88,1322.8 3215.74,1322.74 3215.53,1322.82 3215.47,1323.03 3215.55,1323.22 3215.74,1323.25
  		3215.91,1322.97 	"/>
    </>,
    NPL: <>
        <polygon className="st1" points="2552.2,1088.79 2551.82,1087.7 2550.82,1086.28 2550.75,1085.85 2551.08,1083.37 2551.45,1081.94
  		2551.77,1081.26 2552.12,1080.29 2552.18,1079.84 2552.15,1079.44 2551.8,1079.19 2551.08,1079.07 2550.32,1079.01
  		2549.71,1079.05 2548.21,1079.64 2547.7,1079.69 2547.13,1079.63 2546.37,1079.61 2544.91,1079.63 2543.65,1079.48
  		2542.63,1078.66 2541.9,1078.3 2541.13,1078.03 2540.36,1077.82 2540.1,1077.38 2539.85,1077.16 2539.21,1077.08 2538.71,1077.25
  		2538.39,1078.35 2538.12,1078.57 2537.48,1078.66 2536.8,1078.38 2535.87,1077.82 2535.5,1077.19 2535.19,1076.98 2534.7,1077.26
  		2534.67,1078.07 2534.58,1078.61 2533.99,1078.83 2533.65,1078.67 2533.38,1078.11 2532.69,1076.79 2532.01,1076.02
  		2531.33,1075.5 2529.07,1075.51 2527.4,1075.36 2526.64,1075.15 2526.36,1074.64 2526.64,1073.62 2526.96,1072.82 2526.95,1072.64
  		2526.68,1072.54 2526.2,1072.48 2524.39,1072.99 2523.9,1072.93 2523.59,1072.76 2523.21,1072.61 2522.89,1072.37 2522.67,1072.03
  		2521.11,1071.18 2520.65,1070.71 2519.82,1070.13 2519.12,1069.73 2518.67,1068.59 2518.27,1067.5 2518.04,1066.92
  		2517.38,1066.61 2516.65,1066.37 2515.43,1066.85 2514.42,1067.21 2513.68,1067.25 2512.61,1066.13 2511.76,1064.91
  		2510.75,1063.86 2510.08,1063.32 2508.89,1063.27 2507.54,1062.68 2505.74,1061.32 2504.44,1060.31 2502.2,1059.19
  		2501.68,1058.72 2501.49,1058.31 2501.18,1057.52 2500.71,1056.77 2499.12,1056.44 2497.33,1056.21 2495.44,1056.67
  		2494.07,1058.92 2493.41,1059.41 2492.85,1059.44 2492.39,1058.86 2492.01,1058.26 2491.64,1058.12 2491.14,1058.19
  		2490.64,1058.49 2490.41,1058.68 2489.26,1059.83 2488.66,1060.18 2488.12,1060.69 2486.89,1062.25 2486.17,1063.7
  		2485.65,1065.06 2485.66,1066.02 2485.46,1067.15 2484.93,1067.79 2484.6,1068.01 2484.21,1068.98 2483.94,1070.11 2484.1,1070.47
  		2484.76,1070.96 2485.41,1071.45 2486.24,1071.96 2487.03,1072.46 2487.54,1072.52 2487.68,1072.24 2487.86,1071.97
  		2488.44,1072.12 2489.15,1072.6 2489.62,1072.99 2489.82,1073.12 2491.05,1073.76 2492.06,1074.3 2493.35,1074.98 2493.66,1075.39
  		2493.94,1075.83 2494.54,1076.42 2496.02,1077.45 2497.28,1078.19 2498.3,1078.8 2499.1,1079.22 2499.48,1079.16 2499.88,1078.93
  		2500.24,1078.8 2500.66,1078.92 2501.29,1079.24 2502.77,1080.22 2504.15,1080.99 2505.65,1080.85 2506.05,1080.98
  		2506.33,1081.67 2506.52,1082.37 2508.2,1082.84 2509.31,1083.05 2510.57,1083.43 2511.21,1083.71 2511.88,1083.36 2512,1083.04
  		2512.53,1082.86 2513.41,1082.94 2515.06,1083.49 2515.75,1083.65 2516.32,1083.13 2517.4,1082.89 2517.96,1082.62 2519.13,1083.2
  		2521.24,1083.92 2522.33,1084.36 2522.59,1084.8 2522.71,1085.22 2522.7,1086.23 2522.96,1086.69 2525.08,1087.72 2525.78,1088.15
  		2526.35,1088.29 2526.67,1088.31 2526.89,1088.44 2527.08,1089.03 2527.23,1089.16 2527.64,1089.31 2528.08,1089.39
  		2529.46,1088.89 2530.4,1088.5 2531.07,1088.6 2531.51,1089.03 2531.57,1089.65 2531.82,1090.3 2532.3,1090.62 2532.82,1090.66
  		2534.1,1090.22 2535.12,1090.56 2536.07,1090.68 2537.12,1090.89 2537.53,1091.05 2538.61,1091.6 2539.94,1092.14 2540.46,1092.08
  		2542.6,1091.06 2542.78,1091.19 2543.21,1092.16 2543.86,1092.51 2544.88,1092.82 2545.94,1092.25 2546.78,1092.41
  		2547.79,1092.47 2548.76,1092.2 2549.61,1092.13 2550.84,1092.62 2551.11,1092.5 2551.34,1092.19 2551.82,1090.78 2552.24,1089.54
  			"/>
    </>,
    NOR: <>
        <path className="st0" d="M1900.28,441.6l0.47,0.94l-0.62,1.13l-0.1,0.5l0.11,0.67l0.81,1.09l0.45,0.9l0.32,1.24l0.42,0.86l1.11,1.36
  		l0.85,1.95l1.1,0.39l1.54-0.79l0.8-1.02l-0.31-1.48l0.84-0.15l2.52-0.03l0.89,0.48l-0.57,1.03l-1.04,1.15l0.08,0.76l0.2,0.65
  		l0.47,0.98l0.54,0.79l0.35,0.15l1.4,0l1.03,0.17l0.24,0.25l-0.67,0.73l-0.41,0.18l-6.13-1.47l-0.77,0.19l-0.76,0.42l2.07,1.26
  		l2.11,0.95l-0.89,0.38l-0.07,0.85l0.23,0.93l0.77,0.78l0.81,0.59l1.49,0.68l1.01,0.2l1.49,2.07l1.94,1.81l1.33,0.61l0.4,0.37
  		l0.36,0.56l2,1.17l4.25-0.14l2.13-0.4l1.71-0.08l1.08-0.72l1.05-0.92l1.15-0.6l1.17-0.41l-0.78-0.87l-0.96-0.56l0.3-0.9l0.4-0.74
  		l-0.09-0.93l-0.32-0.83l0.93-0.55l0.94-0.3h0.46l0.41,0.29l0.39,0.74l0.45,0.49l1.05,0.17l1.02-0.63l0.74-1.27l0.82-0.91l0.52,0.19
  		l0.06,0.93l-0.36,1.08l-0.7,1.02l-0.09,0.36l0.21,0.65l0.68,1.23l0.48,0.26l2.22,0.04l2.22-0.4l1.8-0.83l2.43-1.86l0.71-0.33
  		l2.12-0.14l-0.72,0.94l-2.09,2.03l0.73,1.01l1.61,0.77l0.71,0.18l1.52-0.06l1.52-0.31l-1.42,0.88l-1.9,0.35l-5.28-0.04l-2.32,0.25
  		l-0.77,0.22l-1.07,0.52l0.01,0.63l0.33,0.68l-1.5-0.09l-1.5,0.22l-2.92,1.28l-6.29,1.46l0.5,1.21l-2.19-1.13l-0.93-0.34l-0.95,0.01
  		l-0.45,0.19l-0.44,0.31l-0.31,0.54l0.28,1.98l0.3,0.66l0.35,0.54l1.44,1.05l1.07,0.45l4.33,0.09l2.05-0.22l2.11-0.55l2.09-0.87
  		l2.03-0.22l2.03,0.4l1.97,0l4.04-0.6l2.64-0.57l0.51,0.25l0.46,1.02l0.54,0.8l-3.49-0.02l-3.48,0.29l-10.82,1.7l-1.9,2.96
  		l-0.83-0.23l-0.91-0.59l-0.93-0.16l-2.58,0.28l-0.38,0.34l-0.26,0.67l0.07,1.13l0.39,0.75l0.8,1.21l0.86,0.96l1,0.85l1.02,0.63
  		l2.12,0.66l3.25,1.38l3.56,3.53l3.85,2.22l1.01,0.39l0.96,0.65l0.91,1l0.98,0.62l2.01,0.53l1.98-0.47l1.07-0.93l-0.23-1.08
  		l-0.47-1.04l-0.03-0.57l1.39-1.48l0.9-1.32l-0.51-0.74l-0.3-0.68l1.65-1.92l0.79-1.22l1.52-3.12l1.88-1.75l2.45-0.18l0.76-0.28
  		l0.6-1.01l0.53-1.87l0.35-2.02l0.29-2.69l-0.07-0.89l0.07-0.63l1.14-0.42l1.16,0.14l0.92-0.03l1.46-0.73l0.11-0.93l-0.43-0.92
  		l0.22-0.95l0.61-1.56l0.8-1.11l1.94-1.85l2-1.53l0.31-0.66l0.18-0.22l0.77-0.24l5.21-0.38l2.85-0.54l3.13-0.07l1.24-0.43l1.22-0.76
  		l-0.31-0.59l-0.34-0.44l-1.87-1.06l-3.11-1.01l-1.85-1.4l2.24-1.46l-1.32-0.89l-1.29-0.43l-2.49-0.31l-0.41,0.39l-0.01,0.91
  		l-1.85,0.39l-1.2-1.7l-2.2-0.54l-3.38,0.35l-0.93-0.42l-0.83-1.04l-0.91-0.49l-0.8-0.03l0.36-0.37l0.74-0.41l0.13-0.63l0.08-0.91
  		l-0.14-0.8l-0.25-0.64l-0.31-0.52l-1.41-1.59l-1.55-0.64l-0.54-0.11l-0.52,0.19l-3.46,3.11l-1.62,0.97l0.39-0.85l0.16-0.96
  		l-0.39-0.99l0.38-0.69l1.07-1.25l0.81-1.32l-1.02-1.84l-1.26-1.09l-0.9-0.53l-3.02-1.08l-2.13-0.35l-0.34,0.3l-0.31,0.54l-0.42,0.2
  		l-0.44-0.04l-2.21-2.19l-1.16-0.62l-1.19,0.06l-1.28,0.81l-0.31,1.03l0.37,1.34l-1.22,0.95l-0.93,1.95l-0.16,0.47l-0.08,0.52
  		l0.21,1.81l0.29,1.29l1.28,3.36l1.9,4.35l0.76,2.55l-0.41-0.09l-3.67-3.36l-0.8-0.27l-0.88-1.14l-1.82-3.25l-1.62-2.64l-1.68-2.48
  		l-1.86-1.74l-2.01-0.62l-1.8,1.39l-1.7,2.05l-1.17,0.63l-0.17,0.9l-0.07,1.08l0.13,1l0.25,0.88l-0.22,0.74l-0.61,0.09l-1.04-0.68
  		l-0.99-1.01l-0.97-0.53l-1.43-0.26l-0.4-0.19l-0.42-1.79l-1-0.25l-5.56,0.35l4.08-2.21l6.22-0.58l1.09-0.71l0.12-0.18l0.04-0.61
  		l-0.1-0.45l-1.86-0.85l-4.93,0.56l-2.98,1.07l-1.27,0.05l-2.72-0.82l-0.51,0.35l0.22,0.92l0.36,0.71l-0.7-0.11l-0.87-0.35
  		l-3.36-1.59l-3.02,0.41l-0.78,0.28l-0.55,1.23l-0.29,0.07l-0.85-0.83l-1.55-0.7l-0.52-0.04l-0.49,0.19l-0.54,0.58l0.03,0.47
  		L1900.28,441.6z M1983.67,602.17l-1.37,0.42l-0.68,0.73l-0.06,0.54l0.5,0.64l0.59-0.07l0.53,0.14l0.37,0.22l0.7-0.14l0.45-0.35
  		l0.34-1.75l-0.28-0.21L1983.67,602.17z M2005.99,594.69l0.69-0.49l0.28-0.47l0.17-0.48l-0.38-0.41l-0.97,0.46l-0.64-0.04l-2.03,1.6
  		l-0.89-0.27l-2.42,0.48l-1.26-0.08l-0.6,0.69l-0.99-0.14l-0.53,0.16l-1.48-0.01l0.52,0.67l0.95,0.78l1.6,0.72l0.62,0.09l1.06-0.1
  		l0.4-0.26l0.43-0.4l1.46,0.27l0.47-0.18l0.37-0.31l1.17-0.31L2005.99,594.69z M1970.56,536.72l1.02-0.65l0.47-1.1l-0.11-0.37
  		l-0.66-0.65l-2.71,0.06l-0.54,0.48l1.01,1.25L1970.56,536.72z M2008.82,598.63l-0.06-0.59l-0.16-0.71l-0.31-0.68l-0.27-0.36
  		l-2.52,1.71l-0.76-0.17l-1.14,0.45l-0.69,0.65l-0.2,0.42l0,0.49l0.74,0.49l0.71-0.38l0.15,0.29l-0.07,0.95l0.5,0.2l0.94-0.21
  		l0.63-0.29l-0.1-0.3l1.81-1.11L2008.82,598.63z M1963.61,612.65l1.15-0.26l3.15-0.39l0.13-0.39l0.05-0.52l0.86-1l0.83-0.79
  		l1.04-0.76l0.56,0.16l1.16-0.31l0.91-1.33l1.26-0.93l0.13-0.74l-0.91-0.43l-0.45,0.15l-0.2,0.31l-0.66,0.08l-0.75-0.82l-0.05-1.7
  		l-0.31-1.04l-0.67,0.05l-0.61,1.18l-0.08,0.48l0.2,0.74l-0.2,0.72l-0.69-0.08l-0.72,0.49l-0.5,0.76l-1.16,1.04l-0.11,0.4l0.2,0.59
  		l-0.2,0.65l-0.42-0.47l-0.34,0.1l-0.6,0.57l-0.89-0.19l-0.48,0.21l-0.29,0.79l-0.39,0.21l-0.31,0.38l-0.04,0.62l-1.21,0.14
  		l-0.19,0.36l0.58,0.66L1963.61,612.65z M1961.48,615.42l0.47-0.69l-0.21-0.92l-0.41-0.71l-0.65-0.02l-0.54-0.54l-0.67-0.3l-0.9,0.1
  		l-0.46,0.26l-1.01-0.86l-0.12,0.14l-0.04,0.26l-0.25,0.59l-0.83,0.05l-0.87,0.35l-0.19,0.39l1.21,0.58l0.18,0.33l-0.28,0.52
  		l-2.34-0.26l-0.68,0.55l-0.04,0.47l-0.19,0.68l0.2,1.4l-0.22,0.78l-1.09,0.38l-0.23,0.26l-0.04,0.36l1.26,0.02l0.98,0.34l0.05,0.49
  		l0.66-0.18l1.37-1.54l1.38-0.99l0.68,0.54l1.73-0.17l1.49-0.39l-0.25-1.13l0.18-0.81L1961.48,615.42z M1964.2,434.5l4.31,0.44
  		l-1.09,0.78l-1.11,0.57l-2.86,0.72l0.25,0.81l0.34,0.53l0.87,0.68l2.5,1.22l1.83,0.47l3.86,0.19l2.01-0.04l2.19-0.3l1.89-0.66
  		l2.84,0.07l2.72,0.5l-0.82,0.79l-1.03,0.32l-0.6,1.1l-2.58,0.01l-1.45-0.15l-1.63,0.13l-0.63,0.3l-0.61,0.51l1.9,1.09l1.92,0.84
  		l2.29,0.5l3.04,0.42l0.38,0.61l0.47,0.22l8.85,0.32l8.04-0.58l-1.43,1.57l0.79,1.22l0.96,0.64l7.2,0.47l1.59,0.21l1.56-0.4
  		l1.04-0.91l1.07-0.72l3.09-1.19l0.78-0.05l2.55,0.54l0.79-0.12l3.39-1.1l0.72-0.7l1.48-2.31l0.87-1.06l1.81-1.15l7.23-3.6l1-0.79
  		l-0.42-2.94l-1.1-1.28l-1.32-0.67l-3.57-0.3l-5.06,0.01l-0.72-0.25l-0.71-0.42l-1.65-0.45l-4.75-0.85l-1.02-0.47l-0.42-0.01
  		l-1.03,0.3l-0.56-0.18l-1.21-1.17l-0.89-0.1l-0.15,0.61l-0.39,0.51l-0.77,0.15l-1.6-0.18l-1.52,1.17l-0.72,0.74l-2.82,0.54
  		l-2.01-0.16l0.93-2.54l0.21-1.23l0.55-0.87l-0.54-0.42l-2.05-0.53l-0.94,0.1l-0.88,0.7l-1.01,0.4l-1.04-0.07l-0.83,0.28l-0.28,0.72
  		l0.24,1.11l-0.03,2.3l-0.56,1.95l-0.73,0.78l-0.84-0.2l-2.46-1.41l-0.99-0.12l-0.71-0.4l-0.35-1.15l-0.89-0.48l-4.64,0.08
  		l-0.85-0.22l-1.72-0.95l-1.83-1.42l-0.98-0.57l-2.15-0.57l-2.13-0.81l-0.99-0.13l-1,0.3l0.65,1.18l0.72,0.96l0.28,0.52l-0.07,0.62
  		l-0.43,1.31l-1.54-0.44l-2.03-1.42l-0.54-0.25l-0.72,0.09l-0.18,0.58l0.29,0.75l1.38,1.51l1.54,0.43l-1.63,0.91l-1.69-0.43
  		l-1.52-0.7l-1.54-0.36l-5.81,0.43l-1.45,0.54l1.79,0.96L1964.2,434.5z M1933.72,623.57l-0.29-0.22l-1.15-0.06l-0.48,0.69
  		l-1.04,0.89l0.26,0.71l0.98,0.44l0.77-0.08l1.11-0.57l0.42,0.13l0.09,0.44l0.73,0.4l1.15,0.07l1.64-0.15l1.06-0.82l0.41-1.64
  		l-0.5-0.87l-1.1-1.49l-0.27-0.57l-0.4-0.33l-0.22-0.06l-0.54,0.11l0,1.57l-1.39-0.29l-0.29,0.4l0.09,0.58l-0.4,0.83L1933.72,623.57
  		z M1967.55,429.82l-1.87-0.92l-1.92-0.19l-0.73,0.52l-0.37,0.85l3.06,0.83L1967.55,429.82z M2049.7,456.79l3.26,0.13l3.62,0.52
  		l3.25-0.98l-0.43-0.32l-2.52,0.3l-2.51-0.12l-1.7-1.1l-2.81,0.07l-0.81,0.11l-0.34,0.64l-2.13,0.35l-1.95,1.05l1.25,0.44
  		L2049.7,456.79z M2010.6,594.8l-0.13-0.23l-0.49-0.28l-0.75,0.38l-0.22,0.73l0.06,1.2l0.39,0.54l0.93,0.53l1.6-0.61l0.51-1.27
  		l-1.03-0.75L2010.6,594.8z M2019.44,477.61l-2.78-1.41l-2.81-1.17l-2.99,0.61l-1.68-0.19l-2.98-1.5l-1.8-0.61l0.3-1.76l1.79-0.59
  		l0.74-0.53l-0.84-0.68l-1.96-0.96l-1.1-0.24l-2.14,0.22l-2.4,0.45l-1.26-0.24l-0.78-3.3l-1.38-3.13l-2.51,0.09l-1.16-0.44
  		l-1.29-0.03l-3.44,0.76l-5.76,0.78l-1.14,0.68l1.22,1.22l1.58-0.14l-0.27,1.73l2.17,1.35l0.49,1.58l1.6,1.95l1.47,0.98l3.73,1.5
  		l-0.38,0.14l-1.5,1.89l-0.81,0.73l-0.7,1.1l-0.42,1.65l-2.76,0.98l0.46,1.9l1.03,0.34l6.79-0.96l1.69-0.13l1.67-0.5l1.2-0.74
  		l0.43-0.02l1.45,0.39l0.55-0.07l0.4,0.26l-0.45,0.71l-1.99,1.27l0.37,1.24l-0.15,0.52l-0.35,0.27l1.07,0.88l2.09-0.16l0.82-0.64
  		l0.82-0.89l0.88-0.44l2.35,0.09l1.05-0.38l1.95-1.1l0.88-0.64l0.96-1.08l0.9-1.32l0.57-0.5L2019.44,477.61z M2027.45,588.8
  		l-2.25-0.59l-1.37,0.69l-0.91,0.69l0,0.29l1.41,0.22l0.84,0.91l1.5,0.11l0.26-0.13l1.76-0.19l1.13-0.32l0.11-0.67l-0.58,0.1
  		l-1.12-1.1L2027.45,588.8z M2032.52,458.22l-0.4,0.49l0.44,1.19l2.27,1.37l1.23-0.05l1.11-0.9l-1.84-0.49l-1.71-1.63
  		L2032.52,458.22z M1975.72,603.17l0.3,0.83l0.29,0.5l0.98,0.14l0.92-0.12l0.69-0.39l-0.04-0.4l-0.76-0.31l-0.7-0.8l-0.35-0.16
  		l-0.42,0.11l-0.43-0.18l-0.7-0.86l-0.71,0.11l0.12,0.71L1975.72,603.17z M1851.59,728.59l-0.49-0.66l-0.45,0.07l-0.31,0.2
  		l-0.21,1.22l0.6,0.14l0.75-0.16L1851.59,728.59z M1739.43,588.24l-1.49,1.69l-3.74,1.77l-1.13,0.94l0.45,0.34l0.78-0.1l2.68-1.56
  		l2.8-0.63l2.53-0.93l0.2-1.17l-0.19-0.94L1739.43,588.24z M1852.24,738.27l-0.5-0.8l-0.28,0.06l-0.23,0.45l0.17,0.9l-0.06,0.87
  		l0.1,0.37l0.35,0.58l0.78,0.11l-0.03-1.5L1852.24,738.27z M1876.14,699.79l0.98,0.21l0.83-0.01l0.32-0.38l-0.04-0.87l-0.53-0.53
  		l-1.14,0.28l-1.13,0.48l0.1,0.38L1876.14,699.79z M2067.4,597.72l-1.46-0.36l-1.76,0.06l-0.09-0.29l0.29-0.92l-1.45-1.24
  		l-0.89,0.13l-1.37,0.79l-0.5-0.07l-0.13-0.33l-0.7-0.56l-2.03-0.45l-0.64-0.42l-0.86-1.05l-0.98-0.47l-2.28-0.05l-0.69,0.35
  		l-1.18,1.26l-1.05,2.16l-0.4,1.79l-1.07,0.89l-0.25,0.6l-0.73,2.35l-0.23-0.59v-1.11l0.21-1.22l0.1-2.09l0.58-1.39l-0.47-0.56
  		l-1.83,0.61l-0.85-0.21l0.45-0.61l2.7-1.23l0.46-0.42l0.47-0.68l0.08-1.63l-2.11-1.04l-2.75-0.25l-0.69-0.33l-1.15-0.16l-2.24,1.46
  		l-0.81,0.76l0.28,0.57l2.41,1.27l-0.08,0.36l-2,0.01l-1.06,0.91l-0.31,0.96l-0.64,1.11l-0.69,1.48l-2.72,1.37l-0.68,0.18
  		l-0.01-0.67l0.14-0.76l0.23-0.72l0.14-1.3l-0.73-0.5l0.99-1.1l0.49-1.73l-0.61-1.33l-1.3,0.42l-2.33,2l-2.04,2.41l-4.36,4.34
  		l-0.44,1.6l-1.74,1.5l-1.41,0.42l-0.51-0.53l0.1-1.12l1.28-1.61l0.53-2.52l0.54-0.96l1.64-1.83l1.66-1.61l0.98-0.61l-0.11-0.56
  		l-0.47-0.25l-0.52-0.06l-0.67-0.42l-1.13-0.17l-0.51,0.31l-0.42,0.65l-0.51,0.09l-0.79-0.44l-1.09-0.87l-1.77-0.76l-0.56-0.47
  		l-0.9,0.11l-1.82,1.69l-1.5,1l0.04,0.82l1.14,0.42l0.14,0.66l-0.54,0.11l-0.59,0.49l-2.08,2.71l-1.19,0.1l-1.99,1.2l-2.37,2.32
  		l-0.42,0.61l-0.36,1.55l0.2,0.62l0.76,0.66l-0.39,0.55l-0.81-0.15l-0.68-0.54l-1.1-1.1l-0.54-2.04l-0.35-1.04l-0.75-0.54
  		l-1.41-0.52l-2.22,0.57l-0.31,0.91l-0.53,0.2l-0.86-0.68l-1.39,0.5l-0.5-0.26l-1.81,0.96l-2.03-0.42l-1.54,0.37l-0.08,0.38
  		l0.46,0.51l1.75,1.16l1.64,0.49l0.76,0.93l0.69,2.56l-0.36,0.3l-1.28-1.1l-1.59-0.76l-1.32-1.13l-1.51,0.15l-0.76,1.72l-1.1,0.03
  		l-0.51-0.43l-1.1,0.13l-1.84-0.1l-0.64,0.95l-0.11,2.39l0.25,0.89l0.83,0.72l0.68,0.75l-0.03,0.21l-2.13-0.32l-2.43,2.56
  		l-0.76,0.44l-0.44,0.13l-0.09-0.34l1.97-2.69l0.51-1.21l-0.05-0.9l0.46-2.87l-0.27-0.82l-0.26-0.35l-0.85,0.27l-0.65,0.46
  		l-0.65,0.2l-0.91,0.89l-0.81,1.54l-1.08,3.27l-0.8,1.19l-0.01-1.19l0.47-1.63l0.22-2.53l-0.3-0.35l-4.12,0.85l-1.34,1.31
  		l-0.23,0.55l-0.04,0.54l-0.13,0.4l-0.91,0.57l-0.98,0.09l-0.78-0.05l-0.5,0.45l0.08,0.84l1.06,0.83l1.4,0.64l-0.48,0.31l-1.8-0.11
  		l-1.37-0.64l-0.88-1.12l-0.72-0.53l-0.28,0.07l-0.61,0.56l-0.92,1.61l-0.03,1.38l0.35,0.76l-0.13,0.37l-3.34,0.83l-1.33,1.47
  		l-0.47,1.81l-0.84,1.17l-2.19,1.56l-2.08,0.12l-1.96,0.88l-0.61,0.49l-0.55,0.88l0.09,0.61l0.5,0.35l5.2,0.11l1.89-0.33l0.45,0.11
  		l0.19,0.19l0.58,0.2l-0.15,0.31l-0.63,0l-1.2,0.23l-2.04,0.61l-1.2,0.2l-0.73-0.01l-2.07-0.75l-1.95,0.24l-1.55,1.06l-0.24,0.52
  		l0.72,1.99l0.5,0.62l0.61,0.15l-0.17,0.43l-0.53,0.38l-0.42,0.5l0.45,1.73l-0.43-0.07l-1.18-2.04l-0.47-2.51l-0.22-0.27l-0.25-0.15
  		l-1.32,0.68l-1.64,0.26l-1.43,0.89l-1.44,0.5l-0.19,0.47l0.54,0.48l2.1,0.23l0.13,0.57l-0.97,0.32l-0.89,0.1l-1.07-0.6l-1.18-0.17
  		l-0.72,0.25l-2.1,2.12l0.19,0.86l-0.33,1.08l0.62,0.17l1.57-0.28l2.22-1.19l0.35,0.45l-0.52,0.38l-0.55,0.76l0.25,0.77l0.89,0.84
  		l1.12,0.42l1.47-0.4l0.25,0.31l-0.98,1.11l0.16,1.37l-0.35-0.05l-0.74-1.42l-0.47-0.33l-1.01-0.13l-1.42-1.03l-1.34-0.28
  		l-1.74,1.08l-1.49,1.62l-0.35,0.66l-0.74,0.6l-0.06,0.38l0.32,0.22l0.86-0.16l2.05-0.01l4.01,0.16l1.13,0.15l-0.16,0.63l-5.39,0.11
  		l-1.47,0.29l-1.08,0.45l-1.11-0.23l-1.14,0.68l-0.81-0.11l-0.73,0.66l-1.2,1.54l-0.6,0.06l-0.69,0.32l-0.63,0.44l0.44,0.79
  		l1.79,0.46l0.36,0.35l-1.44,0.17l-1.41-0.17l-0.85,0.75l-0.59,0.37l-1.17,0.19l-0.85,0.86l-0.17,1.46l-0.73-0.03l-0.31,1.53
  		l0.43,2.81l1.96-0.08l0.54-0.22l0.7,0.01l1.54-0.3l2.46-0.65l0.51,0.31l-1,0.7l-1.32,0.37l-0.72,0.58l-2.42-0.04l-5.08,1.15
  		l0.09,0.44l1.53,0.7l0.48,0.88l-0.42,0.2l-1.4-0.16l-1.08,0.71l-0.51,1.34l-2.38,2.44l-0.61,0.86l-0.56,1.13l-0.71,1.71l0.1,1.15
  		l0.55,0.46l0.54-0.15l0.59,0.21l0.25,0.65l0.45,0.13l0.79-0.15l1.71-0.98l0.88-0.7l0.81-0.3l-1.49,1.72l-1.94,1.58l-1.7,0.18
  		l-0.67-0.82l-0.56-0.46l-5.65,2.79l-1.17,0.96l-0.39,1.05l-0.06,1.02l0.81-0.25l1.43-0.62l0.6,0.06l-0.92,0.95l-1.62,0.8
  		l-0.89,0.09l-1.14,0.88l-1.33,0.5l-0.83,1.13l-2.26,1.03l-2.77,3.23l-1.91,1.3l-0.59,1.37l-0.63,0.86l-1.32,0.71l-0.79,0.94
  		l-0.4,1.19l0.23,0.36l0.53-0.25l0.93-0.03l0.55,1.11l0.51,0.54l0.26,0.73l1.1,0.12l2.39-0.78l5.02-2.67l0.95-1.01l-0.68-0.76
  		l-0.44-0.26l1.36-0.9l1.17-0.57l0.78-0.25l1.03,0.33l0.24,0.29l-1.37,0.74l-1,0.66l0.32,0.28l0.79,0.03l0.34,0.51l0.19,0.44
  		l-1.22,0.55l-0.91,0.6l-1.39,0.28l-1.46,0.63l-0.45,0.35l-0.44,0.9l0.28,0.29l0.45,1l-0.47-0.03l-0.96,0.22l-2.11-0.3l-1.28,0.19
  		l-0.91,0.29l-0.5,0.56l-0.35-0.06l-0.36-1.11l-0.38-0.18l-0.5-0.43l-1.14-1.34l-0.8,0.2l-0.69,0.32l-1.66,0.2l-1.41,1.48
  		l-0.61-0.05l-0.07-0.49l0.69-0.88l-0.19-0.37l-2.47-0.7l-1.42,0.31l-0.82,0.29l-1.5,0.88l-0.31,0.48l0.22,0.72l0.79,0.28l0.96-0.03
  		l0.4,0.45l-0.05,0.66l-0.47,0.39l-2.6,0.36l-0.73,0.67l-0.22,1l0.65,1.05l0.87,0.53l2.28,2.14l0.12,0.45l-2.63-1.58l-0.84-0.4
  		l-0.93-1.26l-2.02-0.29l-1.05,0.12l-0.69-0.07l-0.69,0.13l-1.54,1.01l-3.21,0.87l-0.57,0.36l-0.1,0.37l-1.24,1.49l-0.39,0.91
  		l0.38,0.26l2.06-0.37l0.72-0.31l1.11,0l1.4,0.53l5.37-0.78l0.42,0.52l-2.45,0.14l-2.24,0.64l-0.17,0.35l0.08,0.46l1.38,0.32
  		l-0.32,0.28l-0.7,0.21l-0.66,0.07l-1.75-0.78l-2.72-0.32l-1.82-0.15l-1.06,0.22l-1.51,0.15l-0.73-0.02l-0.67,0.36l-0.3,0.84
  		l-0.61,0.49l-0.39,0.46l0.15,0.52l1.06-0.12l1.65-0.42l1.98-0.26l-0.61,0.59l-0.34,0.21l-3.13,0.71l-1.06,0.04l-0.49-0.34
  		l-0.39-0.41l-0.6-0.12l-0.94,0.41l-0.66,0.08l-1.56,0.89l-0.41,0.94l-0.52,0.41l-0.54,0.73l-0.54-0.03l-0.45-0.45l-0.82,0.38
  		l-0.39,1.74l0.53,0.91l0.9,0.28l1.74-0.13l1.61,0.29l3.93,0.91l2.23,0.02l2.41-0.47l0.41,0.22l-2.22,0.81l-3.8,0.26l-1.87-0.51
  		l-2.76-0.91l-2.93,0.15l-1.11-0.2l-0.46,0.29l-0.17,0.89l0.15,1.28l0.58,0.85l0.87,0.32l0.57,0.99l0.84,0.49l0.6,0.25l-0.68,0.39
  		l-0.72-0.02l-1.43,0.3l-0.11,0.72l0.27,1.64l0.72,0.81l1.84,1.02l1.07,0.08l1.65-0.58l3.67-0.25l2.54,0.43l0.92-0.27l0.43-1.15
  		l0.48-0.58l0.22,0.13l-0.13,0.64l0.39,0.29l1.16,0.21l1.25,0.38l1.95-0.07l0.86-0.19l0.19-0.42l-0.18-0.9l0.46-1.13l0.94-0.81
  		l0.08,0.2l-0.89,1.53l0.48,1.01l1.69,0.15l-0.5,0.43l-4.25,1.1l0.06,0.97l0.26,0.63l-0.33,0.17l-0.49-0.53l-0.07-0.79l-0.58-0.59
  		l-1.06-0.52l-1.41,0.07l-1.62,0.68l-1.06,0.04l-2.6-0.47l-4.03,0.78l-1.83,0.11l-0.81-0.08l-0.82-0.23l-0.73,0.42l0.14,1.31
  		l-0.12,0.99l0.32,1.93l0.56,0.91l1.08,0.84l1.71-0.61l1.2-0.98l0.5,0.08l-0.86,0.81l-3.19,1.78l-0.26,0.5l0.39,1.74l0.86,1.3
  		l1.11,0.65l1.32-0.06l0.71,0.06l0.26,0.39l-1.63,0.67l-0.99,0.04l-0.94-0.24l-0.5-0.02l-0.26,0.53l0.38,0.84l-0.27,0.9l-0.57,0.92
  		l-0.12,1.28l0.05,0.8l0.3,0.36l0.34-0.04l0.41-0.62l0.25-0.23l0.75-0.63l1.19-0.82l0.53-1.03l1.19-1.29l0.86-0.2l0.64-0.55
  		l0.23-1.02l0.53-0.69l1.13-1.07l0.43-0.71l1.63-0.92l3.87-1.04l1.59-0.14l-0.39,0.43l-1.37,0.31l-0.57,0.46l-0.5,0.65l-1.13,2.71
  		l0-0.77l0.4-1.86l-0.47-0.6l-1.43,0.69l-1.75,1.51l-0.3,0.86l0.06,0.42l-0.35,0.61l-0.62,0.66l-1.79,1.49l-0.45,0.63l0.28,0.69
  		l0.59,0.17l1.12-0.23l0.78-0.03l1.29-0.2l0.04,0.17l-1.9,0.93l-1.04,0.13l-0.8,0.92l-1.62-0.32l-0.42-0.33h-0.48l-0.58,0.72
  		l-0.83,0.17l-0.53,0.98l-0.48,1.38l-0.45,2.83l0.35,0.8l1.59-0.05l0.89-0.47l0.81-1.09l0.79-0.24l0.51-0.24l1.07-0.29l1.45-0.76
  		l1.19-0.94l1.01-0.56l1.06-0.33l0.1,0.17l-0.92,0.52l-0.9,0.84l-1.25,0.87l-0.83,0.86l-0.12,0.81l0.26,0.6l-0.17,0.63l-0.5,0.47
  		v0.47l1.08,0.9l0.69,0.44l1.87-0.8l0.36,0.19l-1.26,0.7l-0.65,0.87l-0.32,0.05l-2.06-1.1l-2.04-0.66l-0.48,0.47l-0.28,1.89
  		l-0.04,1.19l0.58,1.31l1.02,1.19l2.27,1.12l0.66,0.7l2.82,1.32l0.86,0.1l1.06-0.09l0.36,0.04l0.14,0.36l0.13,0.12l-0.73,0.58
  		l-0.43,0.65l0.3,0.32l1.19,0.35l0.3-0.16l0.04-0.62l0.27-0.27l0.62,0.05l0.3,0.1l-0.14,0.27l-0.04,0.23l0.11,0.39l0.85,0.57
  		l1.59-0.29l2.29,0.33l3.45-0.72l1.36-0.82l1.08,0.02l1.23-0.97l1.76-0.93l3.43-3.31l2.1-1.3l0.13-0.46l0.38-0.34l0.71-0.11
  		l0.61-0.72l-0.72-0.63l2.03-0.94l0.57-0.16l0.32-0.31l0.33-0.48l-0.58-0.72l-0.59-0.56l0.66-0.06l1.39,1.13l0.36,0.85l0.99-0.12
  		l1.04-0.75l0.81,0.24l0.22-0.36l0.32-0.29l1.58-2.71l0.2-1.22l-0.07-0.83l-0.33-0.15l-0.08-0.8l0.81-0.28l0.64-0.57l-0.3-1.36
  		l0.52-0.86l0.08,1.06l0.25,0.97l-0.03,2.18l0.12,0.49l0.82,1.16l0.77,1.4l0.93,0.16l0.45,0.08l0.77,0.28l0.35-0.02l1.97,0.48
  		l0.17,0.48l0.02,0.36l0.69,1.57l0.61,0.2l0.84-0.41l0.58-1.15l0.33-1.73l0.39-1.65l-0.46-1.77l-0.49-1.55l-0.03-0.46l1.29-1.31
  		l0.4-1.07l0.43-1.02l0.47-0.35l0.7-0.08l0.82-0.19l1.03-0.68l0.93-0.92l0.71-0.84l0.24-1.67l0.01-0.84l0.31-0.62l0.3-1.22
  		l-0.29-1.21l-0.91-1.83l-1.1-2.58l-0.17-1.41l0.5-0.26l0.96-0.23l1.81-0.07l0.19-0.17l0.18-0.62l0.41-0.84l0.44-0.61l0.3-0.88
  		l0.14-0.8l-1.04-1.2l-1.36-1.23l-0.92-0.41l-1.64-1.04l-1.15-0.87l0.66-3.32l0.49-2.48l0.08-0.6l0.02-0.94l-1.59-4.01l0.06-0.9
  		l0.15-0.81l-0.17-1.38l-0.09-1.24l0.27-0.37l0.65-0.7l-0.62-1.08l-0.05-0.09l-1.17-2.69l1.79-2.67l-0.31-1.39l1.07-1.01l1.94-2.31
  		l1.09-1.3l0.23-0.23l0.87-0.57l1.64-0.68l1.82-0.33l0.81,0l3.13,0.46l2.44,0.36l0.36-0.36l0.51-0.74l0.66-1.05l0.06-1.17
  		l-0.24-1.73l-0.36-1.03l-1.72-0.67l-1.88-0.92l2.31-2.93l1.6-2.04l2-3.08l0.62-1.29l0.45-0.51l0.59-4.77l0.39-1.34l0.33-0.7l0-0.72
  		l-0.21-1.22l-0.56-2.75l3.16-0.34l1.03-0.19l0.95-0.33l1.87-0.85l0.92-0.76l-0.51-2.59l1.13-0.88l2.76-3.06l2.98-2.94l1.4-1.12
  		l0.14-0.55l0.11-0.88l-0.62-1.38l-0.67-0.86l-1.3-1.63l0.56-1.14l0.96-0.22l1.26-0.45l0.99-0.98l0.1-0.13l1.67-3.85l3.26-1.96
  		l1.3-1.07l2.02,0.8l2.96,1.21l1.32-1.78l0.44-0.66l0.44-0.98l-0.17-1.69l-0.07-2.21l0.13-0.89l1.18-0.4l0.64-0.1l3.3,0.91
  		l0.83-0.02l1.55,0.12l1.74,0.4l3.64,1.07l1.51,0.44l0.84,0.09l0.73-0.5l1.55-1.27l-2.28-0.94l1.5-0.96l0.78-0.97l0.67-1.19
  		l0.24-1.4l-0.09-0.75l-0.46-0.51l-1.4-1.28l3.16-0.18l1.1-0.05l0.45-0.48l1.8-0.03l1.49,0.44l0.33-0.19l0.2-0.39l-0.63-1.57
  		l0.11-0.41l0.65-0.49l1.04-0.39l1.64-0.06l1.12,0.06l0.24,0.04l1.67,1.73l1.43,1.68l0.76,0.72l1.86,2.02l0.69,1.17l0.24,0.84
  		l0.76-0.01l2.61,0.37l2.2,0.31l0.61,0.47l1.51-0.09l1.17-0.42l2.06-0.53l0.55-0.66l0.69-0.7l1.21,0.11l1.32,0.55l1.5,0.73
  		l1.33,0.34l1.79,0.53l0.84,0.68l1.17,0.19l1.23-0.68l0.72-1.85l0.64-0.83l0.91-0.61l1.04-0.27l0.8-0.1l0.6-0.47l0.86-1.05
  		l0.17-1.27l-0.16-2.3l0.16-0.76l0.7-1.25l0.94-3.32l0.42-0.96l0.51-0.58l0.7-0.35l1.28-1.01l1.83-2l0.5-0.17l1.31-0.1l1.63,0.07
  		l1.47,0.35l0.16-0.03l0.66-0.18l1.2-0.62l2.05-1.24l1.32-0.34l1.2,0.05l1.32,1.36l1.87,1.51l1.2,0.73l3.27,1.37l2.87,0.9l1.62,2.97
  		l-0.8,1.18l-0.4,0.41l-1.41,1.17l-1.5,1.65l-0.12,0.86l0.5,0.87l0.62,0.57l1.29-0.41l0.44-0.32l0.33-0.38l1.21-2.58l0.3-0.41
  		l3.74-0.93l1.36-0.48l0.79-0.6l0.38-0.47l0.27-0.56l0.19-0.62l0.08-0.57l-0.31-0.74l0.17-0.09l0.4,0.03l1.28,0.73l1.99,0.78
  		l1.46,0.06l0.61-0.15l0.3-0.34l0.22-0.67l0.01-0.69l-0.46-1.98l-1.31-0.18l-1.01,0.09l-0.93-0.08l-0.47,1.09l-0.26-0.16l-0.41-1.53
  		l-0.94-0.42l-0.48,0.32l-0.21,1.43l-0.56,0.43l-0.83-0.29l-1.67,0.13l-0.82-0.25l-0.49-0.53l-0.13-0.57l0-0.84l0.21-1.05l-0.38-0.5
  		l-6.71-1.75l-0.2-0.8l9.64,0.74l2.84-0.43l1.73-1.11l4-1.16l0.14-1.06l-0.29-0.87L2067.4,597.72z M1881.05,695.59l2.66-0.49
  		l0.24-0.3l-0.05-0.61l-0.38-0.45l-0.26,0.04l-0.21,0.36l-2.17,0.57l-1.38,0.6l0.58,0.3L1881.05,695.59z M2090.88,430.96l-2.4,0.26
  		l-0.67,0.21l-12.95,2.13l0.81,0.51l7.99-0.73l8.68-1.53l0.61-0.37L2090.88,430.96z M1929.79,632.49l0.77,0.18l2.35-0.29l2.51-1.58
  		l0.93,0.35l0.51-0.09l0.76-0.31l0.77-0.93l0.48-0.06l0.04,0.77l0.81,0.18l2.06-0.63l0.68-0.78l0.62,0l0.3,0.22l0.33,0.07l0.53-0.19
  		l0.62-0.89l1.23-1.1l1.21-0.43l1.53-0.96l0.24-1.23l-0.57-1.27l-0.46-0.57l-0.81-0.51l-0.45,0.12l-0.4,0.21l-0.65,0.17l-0.25,0.63
  		l0.06,0.78l-0.49,0.48l-0.08,0.49l-1.27,0.44l-1.15,1.41l-0.1-0.1l0.26-0.82l1.16-1.71l-0.04-1.27l-0.15-1.32l-0.61-0.77
  		l-0.19-0.94l1.52-1.31l1.03-1.54l0.12-0.86l-0.69-0.42h-0.7l-0.61,0.36l-1.27,1.6l-0.78,0.56l-1.4,1.32l-0.37,0.96l0.04,0.88
  		l0.72,0.1l0.3,0.57l-0.63,1.01l-0.65,2.79l-2.67,2.56l-4.29,0.6l-1.25,0.86l-1.52,1.23L1929.79,632.49z M2060.99,607.09l-0.42,1.15
  		l-0.35,0.56l0.19,0.36l1.6-0.44l0.83-0.63l-0.52-0.52l-0.67-0.44L2060.99,607.09z M1919.77,634.22l-0.93,0.81l-0.67,1.42l-0.45,1.4
  		l1.24-0.76l0.87-0.87l0.2-0.32l0-0.67l0.21-0.68L1919.77,634.22z M1916.82,664.21l0.51-0.39l-0.27-0.27l-0.88,0.04l-0.79,0.09
  		l-0.61,0.35l-0.39,0.52l-0.01,0.57l0.67-0.04L1916.82,664.21z M1926.55,631.4l-0.75-0.16l-0.81,0.04l-1.26,0.36l-0.92,1.25
  		l-0.51-0.05l-0.57,0.09l-0.37,0.58l-0.48,0.48l0.25,1.33l1.03-0.21l0.33-0.17l0.11-0.57l0.17-0.32l0.6,0.45l0.75-0.61l0.61-0.16
  		l1.03,0l0.38-0.23l0.54-0.69l1.19-0.28l0.57-0.45l0.19-0.41l-0.26-0.09l-1.31,0.07L1926.55,631.4z M1902.53,677.83l-1.75,0.76
  		l-0.62,0.72l0.78,0.37l1.94-0.24l0.98,0.31l0.44-0.38l0.12-0.57l-0.96-0.93L1902.53,677.83z M1909.09,668.11l-0.3,0.73l0.11,0.37
  		l1.04,0.12l0.56-0.43l0.3-0.73l-0.26-0.31l-0.82-0.06L1909.09,668.11z M1916.02,662l-0.02-0.78l-0.79-0.46l-0.56,0.36l-0.13,0.47
  		l-0.24,0.4l-0.63,0.59l-0.13,0.62l0.78-0.09l1.32-0.4L1916.02,662z M1900.57,460.53l0.44,0.78l2.36,3.36l0.66,0.41l1.46,0.05
  		l1.8,0.93l1.43,1.09l1.76,1.92l1.27-0.14l-0.51-1.34l-1.07-1.28l-0.37-0.64l-0.5-0.49l-1.76-0.72l-1.62-1.36l-1.37,0.13l-0.1-1.28
  		l-0.8-0.55l-0.65-0.84l0.63-0.72l-0.24-0.54l-1.37-1.74l-1.58-0.77l-1.81-0.29l0,1.22l0.6,1.56L1900.57,460.53z"/>
    </>,
    NLD: <>
        <path className="st0" d="M1850.02,826.97l0.83-0.81l0-1.29l-1.23,1.05l-0.26,0.63l0.15,0.19L1850.02,826.97z M1852.73,823.45
  		l-0.69-0.03l-1,0.73l0.13,0.13L1852.73,823.45z M1857.98,821.91l1.66-0.19l-0.01-0.07l-0.44-0.12l-1.42,0.02l-0.45,0.08l0.09,0.13
  		L1857.98,821.91z M1854.56,822.56l2.16-0.6l-0.21-0.06l-1.2,0.14l-1.89,0.45l0.36,0.16L1854.56,822.56z M1866.98,819.81h-0.39
  		l-0.72,0.23l-0.22,0.3l0.78-0.04L1866.98,819.81z M1863.04,821.13l-0.36-0.05l-1.04,0.24l-0.07,0.11l0.29,0.08L1863.04,821.13z
  		 M1870.32,823.74l-1.17-0.21l-0.76-0.31l-0.47-0.55l-0.81-0.75l-2.13,0.08l-1.77,0.22l-2.45,0.09l-1.59,0.37l-2.88,1.22l-0.72,0.62
  		l-0.74,1.34l-2.5,1.55l-1.46,0.6l-0.41-0.23l-0.59-0.15l-0.47,0.79l-0.29,0.71l-0.98,4.17l-0.67,1.51l-0.9,1.27l-1.41,1.55
  		l-0.65,0.53l-0.39,0.2l-0.5,0.75l-0.4,0.9l-0.27,0.42l1.58,1.54l0.2,0.44l0.21,0.26l-1.5,0.16l0.64,0.5l0.8,0.36l0.54,0.17
  		l0.29,0.36l-0.58,0.17l-0.54,0.01l-2.15-1.32l-1.2-0.24l-1.65,0.08l-0.41,0.14l-0.43,0.4l0.6,0.61l0.56,0.36l0.9,0.04l1.08,0.45
  		l1.56-0.38l1.11,0.47l0.74,0.17l0.66,0.28l0.58,0.06l0.25-0.12l-0.16-0.68l0.47-0.36l0.53-0.17l0.24,0.29l0.48,0.3l0.38,0
  		l1.03-0.78l0.24,0.15l0.22,0.28l0.05,0.22l0.04,0.23l0.23,0.1l0.81-0.05l0.41-0.42l0.32-0.27l0.24,0.18l0.12,0.52l0.22,0.67
  		l0.96,0.75l0.81,0.21l1-0.15l0.4-0.13l0.27,0.11l0.27,0.4l0.58,0.46l1.21,0.32l0.37,0.18l0.26,0.31l-0.07,0.44l-0.58,1.09
  		l-0.08,0.32l0.08,0.11l-0.11,0.2l-0.75,0.73l-0.07,0.26l0.25,0.42l0.21,0.35l0,0l0.45,0.17l0.42,0.06l0.8,0.02l0.85,0.02l0.46-1.71
  		l-0.35-0.5l-0.44-0.26l-0.51-0.13l-0.23-0.24l-0.08-0.27l0.09-0.17l0.6,0.05l0.18-0.18l1.42-1.01l0.06-0.19l-0.2-0.11l-0.26-0.06
  		l-0.06-0.21l0.01-0.28l0.76-1.46l0.22-0.62l0.05-0.44l-0.05-0.44l-0.43-0.68l-0.43-0.55l-0.01-0.44l-0.31-0.23l-0.88-1.17l0-0.45
  		l0.5-0.35l0.69-0.22l0.23-0.18l0.42-0.12l1.1,0.34l0.49,0.29l0.14-0.06l0.45-0.32l0.78,0.05l1.89-0.64l0.28-0.31l0.21-0.33
  		l0.02-0.14l-0.73-0.63l-0.02-0.23l0.1-0.26l0.21-0.21l0.43-0.14l0.46-0.28l1.03-0.79l0.36-0.68l0.11-0.74l0.02-0.55l-0.28-0.44
  		l-0.28-0.28l-0.39,0.04l-0.75-0.02l-0.7-0.25l-0.39-0.4l-0.1-0.35l0.18-0.22l0.05-0.27l-0.11-0.27l0.05-0.23l0.32-0.18l2.23,0.01
  		l0.17-0.2l0.15-1.06l0.56-1.62l0.53-0.9l0.09-0.38l-0.01-2.14L1870.32,823.74z M1858.51,833.47l-2.53,1.99l-1.51-0.45l-2.33-0.59
  		l-0.22-0.23l0.19-0.46l0.22-0.3l0.06-0.23l0.01-0.78l-0.08-0.51l0.05-0.66l0.61-0.03l0.87-0.49l0.05-0.69l-0.7-0.63l-0.4-0.57
  		l-0.2-0.57l-0.22-0.42l0.71-0.49l0.98-0.69l0.91-0.43l0.18,0.26l0.12,1.59l0.06,0.32l0.29,0.2l0.78,0.13l0.92-0.07l0.09,0.39
  		l-0.35,0.4l-0.06,0.27l-0.04,0.83l0.05,0.29l0.29,0.33l1.53,0.23l0.27,0.52l0.02,0.88L1858.51,833.47z M1843.48,845.07l-1.08,0.46
  		l-1.41-0.16l-1.07-0.34l-1.38,0.07l-0.64,0.18l0.25,0.97l0.19,0.31l0.25,0.2l0.33,0.04l0.38-0.24l0.53-0.25l0.86,0.12l0.62,0.23
  		l0.22,0.24l0.41,0.23l0.6,0.06l1.16-0.44l1.12-0.67l0.33-0.46l0.12-0.42l-0.97,0.29L1843.48,845.07z M1858.53,831.92l-0.52-0.2
  		l-0.65-0.06l-1,0.34l-1.37,0.76l-1.76,1.26l0.13,0.23l0.49,0.28l1.53,0.41l0.63-0.05l0.41-0.25l0.61-0.58l1.01-0.6l0.5-0.42
  		l0.28-0.41l-0.04-0.38L1858.53,831.92z M1841.13,841.91l0.73-0.18l1.11,0.75l1.05-0.24l-0.06-0.21l-0.18-0.2l-0.82-0.61l-1.35-0.08
  		l-0.76,0.19l0,0.22L1841.13,841.91z"/>
    </>,
    NIC: <>
        <path className="st1" d="M1107.73,1193.04l0.32,0.1l0.04,0.76l0.19-0.09l0.54-1.02l0.26-0.21l0.23-0.31l-2.17-0.13l-1.02,0.27
  		l-0.45,0.59l-0.39,0.27l-0.32-0.06l-0.65,0.23l-0.98,0.53l-0.88,0.2l-0.79-0.13l-0.23,0.13l-0.06,0.17l-0.12,0.17l-0.31,0.09
  		l-0.35-0.05l-0.39-0.19l-0.21,0.08l-0.02,0.34l-0.21,0.09l-0.4-0.16l-0.45,0.12l-0.5,0.41l-0.71,0.09l-0.91-0.24l-0.71-0.45
  		l-0.5-0.66l-0.6-0.17l-1.05,0.49l-0.44,0.57l-0.1,0.35l0.1,0.32l-0.19,0.21l-0.49,0.11l-0.37,0.39l-0.25,0.67l-0.05,0.52l0.15,0.36
  		l-0.24,0.27l-0.64,0.17l-0.75,0.58l-0.87,0.98l-0.87,0.69l-0.86,0.39l-0.42,0.43l0.03,0.48l-0.05,0.15l-0.16,0.06l-0.28,0.06
  		l-1.66-1.03l-0.48-0.72l-0.41,0.11l-0.52,0.36l-0.73,0.81l-0.79,1.1l-0.38,0.12l-1.96-0.16l-1.04,0.1l-0.21,0.15l-0.1,0.4
  		l0.06,0.54l0.29,1.94l0.16,0.8l-0.16,0.25l-0.53,0.04l-0.69,0.11l-0.38,0.37l-0.09,0.38l-0.04,0.53l-0.22,0.54l-0.42,0.39
  		l-0.42,0.14l-2.34,0.1l-0.01,0.25l-0.43,0.25l-0.29,0l-0.62-0.54l-0.38-0.47l-0.35-0.03l-0.72,0.66l0.02,0.53l1.75,1.24l2.29,2.12
  		l0.53,0.63l2.31,1.58l0.8,0.77l0.84,1.49l1.57,2.06l4.28,3.45l1.12,1.12l0.7,0.93l0.01,0.22l0.19-0.04l0.17-0.13l0.1-0.13
  		l0.31-0.47l0.27-0.27l0.31-0.04l0.38,0.2l1.43,0.51l1.6,0.56l2.27,0.8l0.94-0.51l0.81-0.39l0.56,0.06l1.22,0.46l0.74,0.15
  		l0.45-0.05l0.78,0.67l0.43,0.5l0.07,0.34l0.24,0.18l0.61,0.04l1.49,0.34l0.91-0.07l0.83-0.36l0.45-0.43l0.14-0.68l-0.61-0.14
  		l-0.45-0.65l-0.54-1.02l-0.3-1.44l0.07-0.45l0.25-0.63l0.44-0.64l0.27-0.53l0.78-0.64l-0.11-0.69l-0.34-0.85l-0.41,0.03l-0.33-0.13
  		l-0.3-0.21l0.13-0.3l0.37-0.3l-0.03-0.39l0.05-0.69l0.43,0.02l0.15,0.23l0.15,0.05l0.09-1.73l0.15-0.51l-0.14-0.42l-0.41-0.59
  		l-0.32-0.81l0.3-0.43l0.31-0.13l0.47-0.38l0.27-0.85l0.13,0.39l-0.11,0.75l-0.27,0.55l-0.03,0.47l0.26,0.53l0.26,0.03l0.46-0.16
  		l-0.06-0.87l-0.2-0.7l0.23-2.96l-0.45-3.2l0.62-3.57l0.69-2.2l0.55-0.52l0.55-0.82l0.58-0.97l0.2-0.62l-0.94-3.49l-0.35-0.14
  		l-0.29-0.01l-0.33-0.51l0.21-0.39L1107.73,1193.04z M1084.49,1216l-0.59,0.19l-0.72,0.11l-0.45-0.05l0.07-0.24l0.08-0.3l-0.06-0.2
  		l-0.16-0.12l-0.54,0.05l-0.7,0l-0.5-0.25l-0.36-0.34l-0.14-0.31l0-0.22l0.36-0.04l0.65-0.15l0.58-0.3l0.51-0.14l0.37,0.05
  		l0.49,0.77l0.63,0.57l0.36-0.11l0.13,0.14l0.01,0.35L1084.49,1216z M1095.45,1225.69l-0.33,0.14l-0.46,0.12l-0.37-0.14l-0.55-0.05
  		l-0.88-0.2l-1.27-0.36l-1.27-0.43l-0.89-0.35l-0.56-0.29l-0.47-0.28l-0.36-0.29l-0.3-0.3l-0.3-0.45l-0.36-0.65l-0.31-0.68
  		l-0.17-0.54l-0.08-0.32l-0.11-0.16l-0.1-0.07l-0.08-0.1l-0.03-0.15l0.01-0.21v-0.42l-0.06-0.67l-0.05-0.67l0.02-0.42l0.15-0.27
  		l0.19-0.53l0.31-0.09l0.31,0.02l0.39,0.11l0.64,0.25l0.74,0.41l0.63,0.49l0.61,0.48l0.67,0.39l0.86,0.61l1.07,1.02l1.04,1.16
  		l0.78,0.95l0.5,0.72l0.36,0.58l0.19,0.46l0.09,0.42l0.1,0.33L1095.45,1225.69z"/>
    </>,
    NGA: <>
        <path className="st0" d="M1870.57,1280.74l-0.73,1.11l0.53,0.06l0.82-0.26l0.23-0.58l-0.48-0.1L1870.57,1280.74z M1932.84,1216.48
  		l-0.28-0.5l-0.05-0.11l-0.52-0.65l-0.87-0.39l-1.2-0.11l-0.63-0.23l-0.11-0.54l-0.06-0.31l-0.06-0.34l-0.09-0.75l-0.81-3.96
  		l-1.11-1.53l-1.42-1.97l-1.32-1.83l-1.51,0.02l-0.87,0.26l-1.09,0.83l-1.22,0.33l-1.49,0.73l-0.94,0.58l-0.89,0.46l-1.22,1.13
  		l-0.4,0.86l-1.21,0.17l-1.69-0.14l-1.08-0.86l-2.5-0.9l-1.62-0.36l-0.75-0.11l-3.82-0.15l-4.07,0.35l-2.07,0.42l-0.38,0.09
  		l-1.18,0.54l-0.98,0.6l-2.64,2.76l-3.49-0.09l-2.05-0.31l-1.74-0.43l-2.48-1.29l-3.04-1.97l-1.17-0.27l-1.06-0.15l-0.35,0.03
  		l-3.63,1.96l-0.7-0.04l-0.86,0.22l-0.56,0.49l-0.42,0.25l-0.44,0.04l-0.57-0.11l-0.56-0.3l-0.56-0.55l-1.5-2.18l-0.31-0.38
  		l-0.64-0.65l-1.08-1.01l-0.73-0.47l-0.44-0.12l-0.53,0.08l-2.91-0.87l-2.92-0.92l-0.64,0.12l-0.46,0.19l-1.01,0.68l-1.19,0.12
  		l-1.51-0.05l-0.83-0.09l-1.33,0.23l-0.89,0.27l-1.16,0.46l-1.51,1.25l-0.43,0.16l-0.36,0.21l-0.51,3.43l-0.41,1.03l-0.77,1.36
  		l-1.5,1.3l-1.03,0.78l-0.02,1.06l-0.08,1.73l-0.01,1.19l0.07,0.77l-0.17,0.37l-0.07,0.34l0.06,0.51l0.24,0.24l0.15,0.32l-0.1,0.26
  		l-0.49,0.3l-0.35,0.55l-0.53,1.12l-0.02,0.88l1.27,1.85l0.15,0.19l0.33,0.29l0.18,0.34l0.15,0.91l0.09,1.03l0.1,0.69l0.61,0.97
  		l0.04,0.39l-0.43,1.45l-0.1,0.15l-0.11,0.04l-0.66-0.13l-0.28,0.16l-0.36,0.49l-0.22,0.49l-0.01,0.2l0.58,0.91l-0.37,1.32
  		l-0.38,0.82l-0.68,0.47l-0.61,0.11l-0.42,0.22l-0.25,0.29l0.04,0.94l-0.89,0.86l-0.5,0.6l-0.24,0.36l0.1,1.11l-0.32,1.12
  		l-0.55,0.88l-1.24,0.19l-1.04,0.11l-0.35,2.24l0.01,1.42l-0.09,1.45l-0.17,0.59l0.07,0.83l-0.08,1.88l-0.14,1.48l0.18,0.4
  		l0.11,0.87l-0.01,0.9l0.27,0.63l0.29,0.55l-0.01,0.28l-0.15,0.18l-0.13,0.23l0,2.12l0.05,0.63l-0.08,0.4l-0.22,0.33l0.09,1.07
  		l0.18,0.68l0.18,0.5l-0.18,0.42l-0.15,0.56l-0.23,1.41l-0.01,0.49l0.56-0.05l4.74-0.18l0.8-1.08l0.62-0.05l1.8-0.56l0.29,0.12
  		l-1.73,0.89l-0.47,0.17l-0.33,0.25l0.3,0.15l5.38-0.02l2.57,0.53l1.7,1.1l1.92,1.6l1.53,1.92l0.43,0.26l0.11,0.33l0.05,0.73
  		l0.51,0.33l0.87-0.33l0.25-0.44l0.19-0.11l-0.02,0.5l0.21,0.21l0.57-0.01l0.32,0.11l-0.53,0.31l-0.88-0.03l-0.62,0.05l-0.14,0.32
  		l0.28,0.42l1.29,0.69l1.38-0.61l-0.15,0.4l-0.26,0.4l-0.52,0.11l-0.6,0.23l-0.03,0.66l0.05,0.54l0.15,0.18l0.74,0.17l-0.2,0.23
  		l-0.41-0.13l-0.17,0.11l0.55,1.54l0.38,0.9l0.51,0.89l0.29,0.72l1.78,1.6l0.91,0.57l0.54,0.41l0.89,0.4l0.82,0.11l0.27-0.12
  		l0.08-0.78l0.47-0.39l0.04,0.51l-0.16,0.31l0.06,0.21l0.3,0.05l1.37-0.25l0.32,0.01l0.46-0.08l0.21-1.13l0.18,0.17l0.13,0.67
  		l0.13,0.3l0.69-0.02l0.35-0.01l0.87-0.25l-0.57-0.8l-0.01-1.04l-0.05-0.5l-0.15-0.61h0.17l0.31,0.67l0.12,1.02l0.24,0.69l0.47,0.42
  		l0.76-0.06l1.19-0.98l0.08-0.85l-0.65-0.59l-0.09-0.25l0.57,0.27l0.53,0.6l0.65,0.54l1.48-0.06l0.42-0.33l0.07-0.42l0.11-0.08
  		l0.29,0.79l0.66,0.3l1.32,0.03l1.92-0.28l2.23-0.02l0.29-0.83l-0.79-2.11l0.16-0.14l0.75,0.83l0.43,0.09l0.32,0.57l0.7,0.18
  		l0.24-0.28l0.1,0.02l0.25-0.65l0.47-0.79l0.63-1.01l0.72-1.26l0.49-2.23l0.33-1.39l0.31-1.27l0.52-1.15l0.53-0.77l1.5-1.49
  		l1.13-1.12l0.58-0.45l0.4-0.38l0.71-0.43l0.72-0.52l0.55-0.99l0.46-0.93l0.34-0.2l0.45-0.16l1.39-0.99l0.88-0.63l0.2,0.31
  		l0.15,0.39l0.17,0.18l0.74,0.12l1.01-0.01l0.58-0.11l0.31-0.33l0.31-0.9l0.18-0.17l0.24-0.05l1.11,0.63l0.92,0.9l0.91,0.88
  		l0.46,0.31l0.2,0.35l0.4,1.62l0.23,0.4l0.39,0.17l0.71-0.11l0.74-0.29l0.65-0.41l0.64-0.53l0.43-0.48l0.19-0.35l0.1-1.32l0.14-0.29
  		l0.65-0.53l1.09-0.88l0.63-0.51l-0.06-0.18l-0.39-0.54l-0.35-0.59l0.35-0.61l0.36-0.47l1.38-1.59l0-0.52l0.07-0.64l1.1-1.81
  		l0.63-2.4l0.02-0.46l0.66-1.16l0.78-1.48l1.51-0.24l0.58-0.37l0.67-0.66l0.43-0.61l0.2-0.58l0.15-1.12l0.27-1.28l0.17-1.12
  		l0.45-1.04l0.76-0.52l1.31-0.43l0.2-0.2l0.19-0.69l0.15-1.43l0.04-0.86l0.05-0.37l0.17-0.64l1.22-1.14l0.54-1.79l0.48-1.88
  		l1.39-2.27l1.62-2.26l0.75-0.61l0.63-0.28l0.73-0.03l0.5-0.17l1.74-1.13l0.73-0.38l0.54-0.39l0.13-0.34l0.05-0.5l-0.17-1.17
  		l0.3-0.86l0.18-1.33l0.08-1.04L1932.84,1216.48z"/>
    </>,
    NFK: <>
        <polygon className="st1" points="3224.5,1568.68 3224.34,1568.63 3224.17,1568.53 3224.01,1568.49 3223.89,1568.62 3223.99,1568.6
  		3224.04,1568.63 3224.04,1568.69 3223.98,1568.78 3223.94,1568.85 3223.96,1568.93 3223.99,1569.02 3224.01,1569.12
  		3224.06,1569.12 3224.12,1569.03 3224.21,1569.03 3224.3,1569.12 3224.35,1569.25 3224.35,1569.21 3224.36,1569.18
  		3224.38,1569.15 3224.41,1569.12 3224.5,1569.06 3224.58,1568.91 3224.6,1568.75 	"/>
    </>,
    NER: <>
        <polygon className="st1" points="1943.87,1145.56 1941.67,1143.18 1940.98,1142.64 1940.59,1141.41 1941.15,1140.71 1938.51,1136.71
  		1937.86,1136.22 1937.57,1135.73 1937.54,1135.01 1937.49,1132.23 1936.79,1127.86 1935.86,1122.77 1935.86,1122.77
  		1932.3,1124.65 1929.56,1126.1 1929.43,1126.09 1929.31,1126.05 1926.46,1123.6 1924.24,1121.68 1923.25,1121.14 1919.06,1120.16
  		1914.89,1119.19 1910.5,1118.16 1906.14,1120.86 1901.78,1123.55 1897.42,1126.25 1893.05,1128.93 1888.69,1131.62 1884.33,1134.3
  		1879.97,1136.98 1875.61,1139.65 1872.72,1141.42 1870.88,1142.98 1868.57,1144.94 1866.39,1146.88 1864.68,1148.41
  		1862.45,1150.38 1861.34,1151.38 1858.86,1153.58 1858.12,1153.97 1854.83,1154.62 1851.82,1155.21 1849.05,1155.76
  		1847.15,1156.14 1845.31,1156.5 1845.31,1158.02 1845.32,1160.3 1845.33,1162.85 1845.34,1165.19 1845.34,1167.86 1845.35,1170.01
  		1845.36,1172.54 1845.37,1175.06 1845.1,1175.35 1845,1176.77 1844.93,1178.63 1844.41,1180.56 1843.52,1181.97 1843.19,1183.32
  		1842.95,1184.1 1842.61,1184.51 1842.53,1185.02 1842.35,1185.73 1842.07,1186.19 1841.85,1186.43 1840.94,1186.7 1839.35,1188.06
  		1839.22,1189.15 1837.4,1188.85 1835.48,1188.54 1835.22,1188.56 1835.06,1188.7 1834.98,1189.28 1832.35,1189.38 1830.09,1189.46
  		1827.29,1189.55 1825.36,1189.62 1822.92,1189.75 1820.65,1189.87 1819.15,1191.12 1817.79,1192.31 1817.68,1192.35
  		1815.75,1192.59 1813.35,1192.38 1812.11,1192.37 1811.63,1192.51 1811.53,1192.96 1811.42,1193.36 1811.41,1194.06
  		1811.26,1195.17 1811.08,1196.5 1811.81,1197.36 1812.69,1198.29 1812.92,1198.65 1812.69,1199.56 1812.85,1200.09
  		1813.32,1200.98 1814.1,1202.11 1814.91,1203.28 1815.47,1203.43 1816,1203.52 1816.32,1203.73 1816.8,1203.93 1817.26,1204.06
  		1817.67,1204.32 1817.94,1204.57 1818.27,1205.28 1819.18,1205.76 1819.82,1206.22 1819.56,1206.46 1818.77,1206.37
  		1818.03,1206.16 1817.93,1206.5 1817.9,1207.82 1818.02,1208.91 1818.19,1209.06 1818.94,1209.26 1820.72,1210.68 1822.34,1212.02
  		1822.88,1212.37 1823.78,1212.5 1824.78,1212.56 1825.21,1212.43 1826.18,1211.76 1826.69,1211.68 1827.17,1211.7 1827.43,1211.81
  		1827.89,1212.36 1828.33,1213.19 1828.45,1213.81 1828.41,1214.14 1828.26,1214.26 1827.47,1214.42 1827.12,1214.55
  		1827.04,1214.73 1827.16,1215.14 1827.32,1215.41 1828.18,1216.61 1829.44,1218.22 1829.82,1218.64 1830.02,1217.77
  		1829.61,1216.17 1829.63,1215.88 1830.5,1215.53 1831.59,1215.27 1832.01,1215.25 1832.28,1215.11 1832.68,1214.76
  		1833.33,1214.51 1833.71,1214.59 1833.94,1214.64 1836.23,1216.76 1837.22,1217.83 1837.49,1218.38 1838,1218.78 1838.76,1219.02
  		1839.45,1219.57 1839.98,1220.34 1840.47,1220.04 1840.57,1219.78 1840.42,1219.47 1840.18,1219.23 1840.12,1218.72
  		1840.19,1218.39 1840.36,1218.02 1840.3,1217.24 1840.31,1216.05 1840.39,1214.32 1840.42,1213.26 1841.45,1212.48
  		1842.95,1211.18 1843.72,1209.82 1844.13,1208.8 1844.63,1205.37 1845,1205.16 1845.43,1205 1846.94,1203.75 1848.1,1203.29
  		1848.99,1203.02 1850.33,1202.8 1851.15,1202.89 1852.66,1202.94 1853.85,1202.82 1854.86,1202.14 1855.32,1201.95
  		1855.96,1201.83 1858.87,1202.75 1861.79,1203.61 1862.32,1203.53 1862.76,1203.66 1863.49,1204.13 1864.57,1205.13
  		1865.2,1205.78 1865.51,1206.17 1867.01,1208.35 1867.57,1208.9 1868.13,1209.2 1868.7,1209.3 1869.14,1209.27 1869.55,1209.02
  		1870.12,1208.53 1870.97,1208.31 1871.67,1208.35 1875.3,1206.39 1875.65,1206.36 1876.71,1206.52 1877.88,1206.79
  		1880.92,1208.76 1883.4,1210.05 1885.15,1210.48 1887.2,1210.79 1890.69,1210.88 1893.33,1208.12 1894.31,1207.51 1895.48,1206.97
  		1895.86,1206.87 1897.93,1206.46 1902,1206.1 1905.82,1206.26 1906.57,1206.37 1908.19,1206.73 1910.69,1207.63 1911.76,1208.5
  		1913.46,1208.64 1914.67,1208.47 1915.07,1207.61 1916.29,1206.49 1917.17,1206.03 1918.11,1205.44 1919.6,1204.72
  		1920.83,1204.39 1921.92,1203.55 1922.79,1203.29 1924.3,1203.27 1923.45,1199.6 1922.97,1197.5 1923.52,1196.86 1924.6,1195.36
  		1925.99,1192.49 1929.12,1188.05 1930.72,1185.77 1933.9,1182.37 1937.82,1178.18 1940.03,1175.82 1940.39,1171.51
  		1940.77,1166.94 1941.05,1163.48 1941.41,1159.38 1941.71,1155.94 1941.92,1153.44 1942.23,1149.88 1942.49,1149.2 1944.03,1146.4
  		1944.15,1146.02 	"/>
    </>,
    NCL: <>
        <path className="st0" d="M3156.7,1481.07l0.07,1.38l0.13-0.19l0.2-0.64l-0.13-1.07L3156.7,1481.07z M3225.17,1500.91l-0.4-0.16
  		l-0.14-0.2l-0.08-0.33l0.03-0.28l-0.53,0.31l-0.93,0.17l0.54,1.15l-0.03,0.51l0.55,0.21l0.21,0.31l0.33,0.01l0.96-0.24l0.15-1.5
  		L3225.17,1500.91z M3214.74,1505.9l-1.1-0.56l-0.86-0.7l-0.8-0.79l-1.6-1.28l-2.07-1.34l-0.91-0.36l-0.54-0.47l-0.53-0.22
  		l-0.41-0.46l-0.94-0.39l-0.68-0.76l-1.14-0.87l-0.22-0.33l-0.07-0.54l-0.27-0.4l-0.62-0.43l-0.46-0.6l-0.51-0.43l-0.67-0.21
  		l-1.15-0.55l-3.26-2.62l-1.28-0.86l-1.02,0.23l-0.95-0.55l-1.2-0.91l-0.16,0.27l-0.03,0.53l0.23,0.39l0.49,0.23l0.29,0.38
  		l-0.05,0.58l0.15,0.57l1.21,1.33l0.52,0.93l0.68,0.79l0.88,0.67l0.81,0.75l1.68,1.83l0.61,0.77l0.7,0.32l1.95,1.74l0.73,0.48
  		l0.83,0.31l1.62,0.96l1.04,0.47l0.67,0.67l0.92,0.48l1.37,0.43l0.23,0.28l0.16,0.49l0.28,0.39l0.97,0.58l1.05,0.36l0.18,0.31
  		l0.26,0.22l0.46-0.06l0.41,0.14l1.71,0.97l0.51-0.18l0.55-0.02l0.59-0.27l0.29-0.54l-0.52-1.51L3214.74,1505.9z M3212.79,1494.02
  		l0.28-0.57l-0.17-0.1l-0.02-0.52l0.15-0.42l0.45-0.24l-0.39-0.28l-0.36-0.04l0.02,0.32l-0.22,0.97l-0.02,0.49l-0.54,0.8l0.45-0.09
  		L3212.79,1494.02z M3219.99,1510.51l-0.18,0.68l0.18,0.18l0.25,0.12l0.33,0.07l0.27-0.33l-0.13-0.39L3219.99,1510.51z
  		 M3219.88,1497.46l-0.59-0.99l-0.57-0.44l0.04-1.39l-0.24-0.28l-0.55-0.24l-1.25,0.41l-0.09,0.34l0.77,0.06l0.45,0.33l-0.65,0.88
  		l-0.67,0.16l0.34,0.65l0.52,0.55l1.17,0.32l0.61,0.63l0.46-0.07l0.25-0.64L3219.88,1497.46z"/>
    </>,
    NAM: <>
        <polygon className="st1" points="2021.04,1467.73 2020.28,1467.16 2019.69,1466.95 2018.01,1466.73 2014.16,1466.41 2013.75,1466.48
  		2012.15,1466.75 2010.15,1467.09 2008.43,1467.43 2006.62,1467.79 2003.99,1468.29 2000.25,1469 1997.73,1469.49 1994.67,1470.07
  		1992.62,1470.44 1990.08,1470.9 1989.68,1470.89 1989,1470.57 1987.53,1470.51 1985.8,1470.94 1984.43,1471.06 1983.42,1470.86
  		1982.43,1470.48 1981.46,1469.92 1979.79,1469.71 1977.41,1469.87 1975.11,1469.76 1972.91,1469.38 1971.32,1469.24
  		1970.37,1469.32 1969.35,1469.19 1968.26,1468.87 1967.35,1468.33 1966.26,1467.18 1965.4,1466.08 1965.18,1465.92
  		1964.91,1465.75 1964.64,1465.71 1962.22,1465.68 1959.92,1465.65 1958.6,1465.65 1955.38,1465.64 1952.16,1465.64
  		1948.93,1465.63 1945.71,1465.63 1942.49,1465.62 1939.27,1465.62 1936.04,1465.61 1932.82,1465.61 1931.11,1465.6
  		1929.52,1465.69 1927.76,1465.79 1927.51,1465.75 1927.09,1465.61 1926.81,1465.37 1925.86,1464.75 1925.04,1464.27
  		1923.92,1463.48 1923.2,1462.61 1922.59,1462.33 1921.52,1462.17 1920.71,1462.02 1920.05,1461.98 1918.88,1462.39 1918.01,1462.8
  		1917.38,1463.19 1916.3,1463.65 1915.39,1464.09 1913.8,1464.04 1913.45,1464.1 1912.57,1464.07 1911.73,1463.68 1910.89,1463.71
  		1909.95,1464.21 1908.61,1464.41 1908.43,1466.29 1908.53,1468.74 1908.88,1470.91 1910.36,1473.23 1911.12,1474.96
  		1911.58,1475.57 1913.54,1477.39 1914.63,1478.92 1919.55,1488.49 1920.61,1489.86 1921.59,1492.82 1922.99,1496.25
  		1926.26,1501.12 1926.67,1502.29 1927.39,1503.71 1930.33,1507.42 1931.51,1509.7 1932.05,1511.93 1932,1512.84 1931.79,1513.86
  		1931.48,1513.75 1931.31,1513.5 1931.01,1514.27 1931.19,1515.25 1931.61,1517.04 1931.6,1518.77 1931.8,1520.24 1931.69,1523.85
  		1931.84,1525.2 1932.9,1528.28 1934.08,1530.41 1934.67,1532.6 1934.51,1534.5 1934.54,1535.5 1934.89,1537.07 1934.73,1538.78
  		1935.46,1540.87 1935.77,1544.1 1936.85,1545.06 1937.21,1545.8 1937.41,1546.63 1937.08,1547.24 1937.16,1548.32 1937.86,1550.19
  		1938.46,1552.71 1938.92,1553.72 1942.09,1558.96 1943.54,1560.66 1944.52,1561.38 1947.28,1564.15 1948.23,1564.88
  		1948.56,1564.48 1949.73,1563.7 1950.27,1563.49 1950.55,1563.59 1950.83,1563.38 1951.09,1562.86 1951.15,1562.37
  		1951.28,1561.67 1951.55,1561.26 1951.83,1560.43 1952.32,1559.9 1953.36,1559.55 1954.14,1560.02 1954.47,1560.48
  		1954.61,1561.08 1954.95,1561.37 1955.51,1561.35 1955.9,1561.72 1956.13,1562.48 1956.09,1563.03 1955.77,1563.37
  		1955.81,1563.82 1956.22,1564.38 1956.39,1564.92 1956.66,1565.62 1958.08,1566.03 1958.77,1566.26 1959.97,1566.33
  		1961.1,1566.65 1962.17,1567.2 1963.92,1567.33 1966.36,1567.05 1968.37,1567.17 1969.95,1567.71 1971.09,1567.81 1971.8,1567.47
  		1972.11,1566.98 1972.01,1566.34 1972.36,1565.94 1973.16,1565.77 1973.8,1565.28 1974.28,1564.49 1975.38,1563.85
  		1977.12,1563.35 1977.99,1563.37 1977.99,1562.09 1977.99,1558.05 1977.99,1554.03 1977.99,1550.02 1977.99,1546.01
  		1977.99,1542.02 1977.99,1538.03 1977.99,1534.06 1977.99,1530.31 1977.99,1530.09 1977.98,1528.17 1977.98,1525.62
  		1977.98,1523.07 1977.98,1520.53 1977.97,1517.99 1977.97,1515.46 1977.97,1512.93 1977.96,1510.4 1977.96,1507.88
  		1977.96,1505.75 1979.88,1505.75 1982.26,1505.75 1985.08,1505.75 1986.33,1505.75 1986.4,1505.41 1986.4,1503.85 1986.39,1500.27
  		1986.39,1496.7 1986.38,1493.13 1986.38,1489.58 1986.37,1486.02 1986.37,1482.47 1986.36,1478.93 1986.36,1475.4 1986.36,1473.65
  		1988.53,1473.55 1991.03,1473.19 1995.09,1472.61 1998.87,1471.89 2001.34,1471.47 2004.26,1470.97 2005.27,1470.89
  		2005.54,1470.96 2005.93,1471.13 2007.29,1472.89 2008.14,1474.23 2008.31,1474.81 2008.47,1474.87 2008.87,1474.78
  		2009.32,1474.56 2010.7,1473.22 2010.98,1472.87 2011.86,1472.22 2012.93,1471.56 2013.9,1471.09 2014.86,1470.7 2015.31,1470.8
  		2015.84,1471.14 2016.31,1471.35 2018.51,1469.72 2019.5,1469.35 2022.08,1469.06 2022.44,1469.11 	"/>
    </>,
    MOZ: <>
        <polygon className="st1" points="2153.51,1441.97 2153.57,1441.4 2153.44,1441.12 2153.49,1440.54 2153.13,1440.14 2152.72,1440.14
  		2152.04,1441.15 2151.95,1440.4 2151.99,1439.88 2152.61,1439.03 2152.63,1438.38 2152.07,1438.24 2151.68,1437.97
  		2151.62,1437.59 2151.57,1435.22 2151.32,1433.31 2151.3,1432.55 2151.19,1431.97 2151.51,1431.21 2151.25,1430.52 2151.4,1429.93
  		2151.35,1429 2151.43,1428.52 2151.39,1427.89 2150.28,1427.88 2150.27,1427.48 2150.37,1427.21 2151.26,1426.53 2151.42,1425.97
  		2151.49,1424.92 2151.22,1424 2150.7,1423.71 2151.01,1422.86 2150.89,1422.18 2150.82,1420.54 2151.08,1419.56 2150.9,1419.02
  		2150.76,1418.2 2150.25,1416.62 2150.52,1414.85 2149.99,1413.86 2150.15,1413.3 2150.74,1412.56 2151.19,1411.6 2151.04,1411.26
  		2150.86,1411.03 2150.95,1410.45 2151.63,1409.61 2150.7,1409.06 2151.28,1408.64 2151.75,1408.18 2150.95,1407.39
  		2150.51,1406.51 2149.53,1407.25 2148,1408.4 2146.51,1409.53 2145.06,1410.31 2144.03,1410.66 2142.92,1410.87 2141.88,1411.34
  		2140.89,1412.08 2139.62,1412.46 2138.07,1412.46 2136.45,1412.99 2134.84,1413.97 2133.9,1414.55 2132.41,1413.68
  		2131.24,1413.41 2129.9,1413.44 2129.08,1413.54 2128.79,1413.73 2128.54,1414.26 2128.32,1415.13 2127.44,1415.97 2125.9,1416.77
  		2124.47,1417.07 2123.17,1416.87 2122.29,1416.53 2121.83,1416.07 2121.16,1415.85 2120.26,1415.89 2119.41,1416.22
  		2118.59,1416.85 2117.28,1417.12 2115.49,1417.03 2114.52,1416.73 2114.39,1416.21 2113.6,1415.6 2112.16,1414.9 2111.1,1414.88
  		2110.42,1415.56 2109.8,1415.98 2109.24,1416.15 2108.74,1416.17 2108.29,1416.04 2108.01,1415.99 2106.03,1415.92
  		2104.15,1415.95 2104.14,1415.95 2104.13,1417.27 2103.93,1418.43 2103.57,1419.4 2103.28,1419.93 2103.07,1420.02
  		2102.71,1420.32 2102.18,1420.86 2101.96,1421.22 2102.05,1421.43 2102.08,1421.9 2102.05,1422.62 2101.98,1422.95
  		2101.91,1423.11 2102.73,1424.99 2102.87,1426.04 2102.57,1426.92 2102.55,1427.71 2102.81,1428.4 2102.89,1429.24 2102.8,1430.22
  		2102.9,1430.85 2103.19,1431.14 2103.32,1431.94 2103.31,1432.47 2103.7,1432.72 2104.61,1433.5 2106.57,1435.67 2107.65,1437.05
  		2108.6,1438.26 2110.3,1440.52 2111.62,1442.28 2111.79,1443.93 2112.01,1444.16 2111.56,1445.39 2111.27,1447.37 2111.48,1448.68
  		2111.39,1450.92 2111.15,1453.31 2110.85,1454.16 2110.46,1454.48 2109.54,1454.74 2107.51,1455.04 2107.2,1455.32
  		2106.94,1455.79 2106.53,1456.89 2106.05,1458 2105.9,1458.48 2105.99,1458.59 2106.42,1459.16 2106.86,1460.61 2106.93,1463.1
  		2106.78,1463.28 2106.18,1463.39 2105.54,1463.36 2105.27,1463.22 2105.03,1462.94 2104.86,1462.41 2105.28,1462.04
  		2105.43,1461.39 2105.16,1460.83 2104.62,1460.71 2103.93,1460.2 2102.46,1458.54 2101.23,1457.37 2100.51,1456.4 2099.78,1456.02
  		2099.57,1455.78 2099.4,1455.37 2099.39,1454.79 2099.46,1454.35 2099.23,1453.87 2098.49,1453.11 2098.16,1452.7 2098.14,1452.2
  		2098.45,1451.72 2099.08,1451.13 2099.56,1449.95 2099.73,1449.18 2100.62,1447.64 2100.74,1446.3 2100.76,1445.23
  		2100.71,1444.43 2100.48,1442.79 2100.32,1441.65 2099.22,1440.17 2098.87,1440.03 2097.82,1440.16 2096.92,1440.38
  		2096.48,1440.69 2095.81,1440.7 2094.06,1440.96 2093.51,1441.07 2093.19,1441.34 2093,1441.4 2091.9,1440.25 2090.93,1439.02
  		2089.69,1436.91 2089.34,1436.66 2087.53,1437.27 2086.59,1437.59 2083.88,1438.51 2081.52,1439.3 2080.9,1439.46 2079.68,1439.85
  		2079.07,1440.08 2076.94,1440.78 2076.05,1441.13 2075.33,1441.47 2073.57,1441.99 2071.9,1442.48 2070.08,1442.98
  		2068.05,1443.54 2066.9,1443.95 2066.13,1444.3 2064.33,1445.01 2064.24,1445.18 2064.27,1445.66 2064.5,1446.66 2064.95,1447.56
  		2065.33,1448.09 2065.58,1449.42 2065.71,1450.6 2065.73,1451.95 2065.82,1453.48 2066.06,1453.62 2067.68,1453.66
  		2070.28,1453.76 2072.79,1453.87 2074.39,1454.97 2074.92,1455.21 2076.59,1455.5 2078.71,1457.35 2081.27,1457.52
  		2083.03,1458.09 2084.58,1458.73 2085.47,1459.49 2086.05,1459.66 2086.83,1459.72 2087.21,1459.79 2087.12,1460.33
  		2086.6,1461.26 2086.67,1462.59 2087.39,1464.43 2087.48,1466.03 2087.26,1468.86 2087.27,1471.61 2087.35,1472.59
  		2087.46,1473.24 2087.61,1473.6 2087.58,1474 2087.16,1475.15 2086.82,1476.37 2086.8,1476.86 2086.67,1477.2 2086.42,1477.5
  		2085.3,1478.06 2085.11,1478.41 2085.11,1479.04 2085.26,1479.57 2085.67,1479.76 2086.18,1480.06 2086.38,1480.46
  		2086.38,1480.88 2086.22,1481.65 2085.77,1482.93 2086.22,1484.4 2086.72,1485.36 2087.41,1486.47 2087.7,1487.15 2087.68,1487.64
  		2087.58,1488.12 2086.55,1490.14 2085.8,1491.4 2084.89,1492.75 2083.68,1493.6 2083.37,1494 2083.24,1494.47 2083.29,1495.48
  		2083.23,1496.54 2082.2,1498.17 2082.84,1499.58 2082.69,1499.71 2082.35,1499.91 2080.86,1501.49 2079.36,1503.1 2078.26,1504.27
  		2077.01,1505.6 2075.61,1507.1 2074.42,1508.38 2073.22,1509.28 2073.27,1509.75 2073.33,1509.96 2073.73,1511.18 2074.33,1513.01
  		2074.73,1514.7 2075.28,1517.03 2075.26,1518.32 2075.39,1518.82 2075.89,1519.44 2076.49,1520.52 2076.69,1521.13 2076.9,1521.58
  		2077.53,1522.45 2078.03,1523.76 2078.45,1525.5 2078.8,1526.34 2078.94,1526.75 2079.1,1527.5 2079.08,1529.08 2079.09,1530.91
  		2079.1,1532.96 2079.11,1534.65 2079.05,1535.51 2079.09,1537.95 2078.55,1539.22 2078.62,1540.21 2078.79,1540.86
  		2078.96,1540.99 2079.73,1541.41 2079.8,1542.23 2079.73,1543.17 2079.57,1543.77 2079.63,1544.36 2079.88,1545.28
  		2080.11,1545.91 2080.17,1548.79 2080.9,1548.73 2082.2,1548.99 2083.24,1548.96 2084.18,1548.93 2085.76,1548.89 2086.68,1548.87
  		2086.71,1548.7 2087.08,1543.51 2087.26,1541.99 2086.94,1542.02 2086.75,1542.41 2086.37,1543.65 2085.99,1543.41 2085.7,1543.06
  		2085.15,1542.66 2084.67,1542.07 2084.19,1541.28 2084.74,1540.36 2085.31,1539.64 2085.89,1538.06 2087.32,1536.68
  		2090.57,1534.63 2092.11,1533.99 2094.69,1532.91 2101.18,1530.71 2104.42,1529.19 2105.8,1528.22 2106.64,1527.23
  		2108.18,1524.93 2108.61,1523.99 2109.05,1521.85 2108.89,1521.5 2108.58,1521.5 2108.38,1522.09 2107.85,1521.97 2107.61,1521.62
  		2107.66,1520.82 2108.65,1516.19 2109.33,1514.23 2108.75,1512.54 2108.62,1511.54 2109.05,1509.06 2109.04,1508.41
  		2108.95,1507.93 2108.74,1507.42 2108.65,1506.84 2108.33,1506.77 2108.02,1507.31 2107.86,1508.53 2107.92,1509.29
  		2107.71,1509.75 2107.15,1509.24 2107.23,1508.04 2107.26,1506.08 2106.79,1503.65 2106.74,1502.68 2105.57,1500.44
  		2105.48,1498.69 2104.34,1495.28 2103.45,1494.1 2102.51,1493.15 2102,1492.37 2101.95,1491.77 2102.38,1489.04 2102.34,1487.63
  		2102.43,1486.7 2102.08,1486.22 2101.54,1485.65 2102.14,1485.72 2103.24,1486.68 2103.57,1486.7 2104.05,1486.62 2107.57,1483.84
  		2109.97,1480.97 2111.68,1479.5 2112.74,1478.79 2113.96,1478.19 2114.45,1478.44 2114.9,1478.35 2115.12,1477.12 2115.67,1477.76
  		2116.31,1477.56 2116.38,1476.89 2117.11,1475.87 2117.46,1475.38 2119.28,1473.55 2120.49,1472.01 2120.65,1471.58
  		2120.82,1470.83 2121.33,1470.33 2121.76,1470.11 2123.39,1468.64 2125.65,1467.18 2128.4,1465.65 2130.16,1465.03
  		2130.49,1464.64 2130.98,1464.35 2132.96,1463.73 2135.09,1462.94 2135.4,1462.7 2135.76,1462.66 2136.14,1462.74 2137.21,1462.62
  		2137.81,1462.3 2138.89,1462.03 2139.71,1460.9 2140.22,1460.48 2143.45,1458.64 2144.62,1457.68 2145.29,1457.41 2144.84,1456.19
  		2145.42,1455.82 2146.46,1455.6 2147.44,1454.22 2147.52,1453.88 2147.52,1453.48 2148.35,1452.52 2149.24,1451.64
  		2151.31,1449.15 2152.08,1447.32 2152.1,1446.74 2151.8,1446.08 2152.01,1445.8 2152.45,1445.65 2152.39,1445.19 2152.5,1444.49
  		2153.14,1443.74 2153.64,1443.31 2153.71,1442.68 	"/>
    </>,
    MSY: <>
        <path className="st0" d="M2747.62,1298.54l0.26-0.11l-0.07-0.36l-0.04-0.98l0.02-1.11l-0.2-0.46l-0.18-0.03l-0.28,0.23l0.09,2.54
  		L2747.62,1298.54z M2687.1,1295.87l0.41-0.09l0.01-0.36l-0.32-0.82l-0.13,0.12l-0.34,0.75l0.15,0.33L2687.1,1295.87z
  		 M2796.32,1258.57l0.82-0.14l0.23-0.3l0.12-0.59l-0.14-0.51l-0.99,0.12l-0.7,0.64l-0.03,0.69l0.17,0.53L2796.32,1258.57z
  		 M2650.45,1264.65l-0.61,0.3l-0.33-0.15l-0.54,0.08l0.09,0.43l0.4,0.25l0.33,0.62l0.33-0.07l0.34-0.35l0.36,0.13l0.15-0.12l0.3-0.4
  		l-0.59-0.9L2650.45,1264.65z M2814.13,1273.71l-0.27-0.39l-0.38-0.15l-1.08,0.13l-0.4-0.02l-0.38-0.09l-2.05-1.09l-1-0.28
  		l-0.27-0.78l-0.41-0.37l-0.49-0.29l-0.87-0.36l-0.45-0.11l-0.43-0.01l-0.88,0.56l-0.96,0.35l-0.48,0.05l-0.38-0.53l0.06-0.15
  		l1.16-0.37l0.37-0.26l-0.46-1.02l-0.49-0.59l-0.91,0.68l-0.66,0.27l-2.67,0.47l0.98-0.47l0.23-0.51l0.04-0.6l-0.29-1.03l0.67-0.64
  		l-0.02-0.65l-0.2-0.64l-0.2-0.39l-0.3-0.33l-0.93-0.49l-1-0.34l-0.73-0.54l-0.33-0.9l-0.08-0.42l0.06-0.72l-0.19-0.17l-0.85-0.24
  		l-0.43,0.44l-0.5,1l-0.89,1.16l-0.85,0.44l-0.21,0.02l0.17-0.71l0.29-0.68l0.07-0.46l-0.14-1.05l-0.48-0.32l-0.22,0.11l-1.78,3.31
  		l-0.37,0.51l-3,3.29l-0.24,1.06l-0.42,1.01l-1.19,1.32l-0.35,0.94l-0.68,0.65l-0.47,0.03l-0.47-0.02l-0.51-0.12l-0.17-0.46
  		l-0.4,0.31l-1.16,1.29l0.02,0.69l0.38,0.64l0.97,0.5l-0.23,0.84l-0.29,0.37l-0.78,0.67l-0.44,0.31l-1.98,0.28l0.24,0.28l0.5,0.97
  		l0.33,0.98l0.11,1.49l0.4,0.63l-0.06,0.13l-0.24,0.11l-0.37,0.04l-0.64-0.14l-0.54-0.22l-0.47-1.61l-0.21-0.91l0.02-1.09
  		l-0.02-0.66l-0.69,0.36l-0.68,0.46l-0.68,0.39l-0.32,0.31l0.11,0.43l0.16,0.95l0.09,0.75l0.24,0.29l0.19,0.3l-0.08,0.32l-0.4,0.62
  		l0.23,0.12l-0.29,0.82l-0.43,0.61l-0.6,0.49l-0.39,0.11l-0.31-0.21l-0.5-0.54l-0.55-0.75l-0.26-0.44l-0.79-0.06l-0.28-0.35
  		l-0.02-0.42l-0.22-0.5l-0.31-0.53l-0.47-0.41l-0.62-0.32l-0.26-0.23l-0.09,0l-0.35,0.15l-0.24,0.25l0.05,0.53l-0.02,0.52l-0.3,1.11
  		l-0.24,0.38l-1.78,2.03l-2.24,2.19l-1.06,1.5l-1.52,1.83l-0.8,1.16l-0.48,0.36l-0.57,0.26l-1.54,0.5l-5.21,1.3l-3.29,0.51
  		l-0.88,0.3l-0.93,0.63l-0.58,0.91l-0.02,1.14l0.09,0.62l-0.06,0.36l-0.32,0.11l-0.46,0.03l-0.47-0.29l-0.45-0.31l-0.28,0.47
  		l-0.11,0.69l0.11,0.83l0.5,0.48l-0.15,0.63l-0.44,0.66l-0.24,0.69l-0.13,1.37l-0.37,0.46l-0.54,0.42l-0.15,0.63l0.25,0.6l0.55,0.32
  		l0.84,0.44l-0.66,0.07l-0.39-0.12l-1.33-0.97l-0.38-0.13l-0.95,0.1l-0.9-0.23l-2.32-1.27l-0.42-0.17l-0.43,0.16l-0.44,0.05
  		l-1.11-0.03l-1.09-0.16l-1.01-0.39l-1.22-0.78l-0.21-0.26l-0.55-1.16l-0.76,1.1l0.08,0.4l0.18,0.35l0.55,0.25l0.15,1.35l0.69,0.77
  		l0.69,0.7l0.51,0.34l0.56,0.5l0.39,0.46l0.41,0.39l0.62,0.38l1.69,1.62l0.71,0.48l0.53,0.48l0.37,0.17l0.92-0.14l2.72-1.17
  		l0.49-0.08l0.89-0.2l1.56,0.06l1.65,0.4l0.53,0.01l0.51-0.24l0.71,0.07l0.66,0.12l0.33-0.1l0.96-0.85l1.31-0.25l0.42-0.84
  		l0.33-0.79l0.15-0.84l0.55-0.34l0.77-0.29l1.13-0.37l3.93-0.07l0.38,0.16l0.08,0.43l-0.08,0.33l0.15,0.19l0.52,0.02l0.49,0.2
  		l1.96,0.68l0.84,0.21l0.46-0.05l0.92,0.61l0.5-0.21l0.66-0.42l0.63-0.57l0.56-0.46l0.82-0.18l1.06,0.02l1.25-0.16l0.95-0.24
  		l1.06,0.4l0.28-0.13l0.18-0.39l0.54-0.86l0.24-0.58l0.21-1.11l0.15-0.27l0.4-0.15l0.41-0.21l0.11-0.33l0.15-0.39l-0.12-0.33
  		l-0.23-0.27l-0.25-0.93l0.08-0.42l0.15-0.31l0.42-0.16l1.12-0.68l0.99-0.8l0.54-0.39l0.24-0.25l0.01-0.37l-0.43-0.38l-0.41-0.18
  		l-0.03-0.44l0.01-0.3l0.12-0.29l-0.06-0.28l0-0.42l0.26-0.45l0.61-0.67l0.48-0.43l0.53,0.27l0.62-0.12l0.59-0.21l0.33-0.79
  		l0.05-0.38l-0.08-0.3l0.21-1.12l0.05-0.16l0.39-0.7l0.04-0.47l-0.22-1.1l0.14-0.83l0.06-1.72l0.23-0.31l0.26-0.89l0.43-0.93
  		l0.87-0.51l0.46-0.67l0.2-0.12l0.3-0.01l1.06,0.49l0.95-0.54l0.86-0.06l0.71,0.07l0.4,0.22l0.39,0.16l0.84-0.52l0.32,0.09l0.3,0.18
  		l0.42-0.01l0.5-0.13l1.23,0.12l2.17,0.03l1.49,0.32l1.46,0.89l0.73,0.18l0.31,0.01l0.25-0.25l0.39-0.88l0.39-0.32l0.38,0.04
  		l1.3,0.63l0.95,0.11l0.92-0.31l0.94-0.24l1.14-0.17l1.13-0.22l0.42-0.14l0.32-0.26l0.07-0.43l-0.28-0.35l-2.01-1.4l-1.17-1.34
  		l0.63-1.35l0.5-0.19l0.52-0.05l1.43,0.42l1.02,0.03l2.02-0.49l1.85-0.65l0.74-0.5l0.25-0.33l0.11-0.4l0.03-0.52L2814.13,1273.71z
  		 M2685.37,1299.72l-1.14-2.07l-0.17-0.6l-2.32-1.63l-0.44-0.52l-0.39-0.81l0.05-2.74l-0.13-0.99l0.2-1.19l-0.68-1.26l-0.1-0.82
  		l0.49-1.74l0.41-3.49l-0.13-2.32l-0.32-1.52l-1.84-3.45l-0.84-1.23l-0.97-0.98l-0.71-0.33l-0.91-0.68l-2.16-1.83l-1.64-2.6
  		l-0.56-0.26l-1.46-0.33l-0.28,0.48l-0.11,0.74l-1,0.99l-0.16,0.57l-0.37,0.72l-0.7,0.39l-0.6,0.07l-0.35-0.07l-0.24-0.14
  		l-0.41-0.68l-0.21-0.21l-0.17-0.05l-1.28,0.47l-1.24,0.52l-0.23,0.47l-0.33,0.55l-0.36,0.22l-0.28,0.05l-0.27-0.32l-0.48-0.42
  		l-0.37-0.39l0.09-0.63l0.7-0.93l0.09-0.65l-0.09-1.11l-0.19-0.64l-0.2-0.02l-0.34-0.1l-0.97,0.1l-0.48-0.72l-0.19-0.79l-0.33-0.28
  		l-0.33-0.17l-0.73,0.27l-0.55-0.16l-1.84-0.69l-0.71-1.12l-0.38-0.03l-0.34,0.12l-0.13,0.25l-0.2,1.29l-0.16,0.39l0.33,0.99
  		l0.88,1.19l0.67,1.67l0.26,1.73l-0.18,1.6l1.02,4.56l1.19,3.29v2.34l0.39,1.24l0.84,1.08l0.3,0.62l-0.32,0.18l-0.35,0.3l0.56,0.85
  		l0.58,0.74l1.46,1.27l0.76,1.28l1.55,1.83l0.25,0.93l0.2,1.1l-0.49,1.05l0.47,0.39l0.47,0.21l0.95,1.09l2.2,0.92l0.92,1.04
  		l2.15,1.68l3.39,1.72l1.51,1.56l1.43,0.53l3.87,2.06l0.36,0.4l0.23,0.57l0.45,0.84l0.59-0.03l1.22-0.98l1.03-0.22l0.83,0.25
  		l0.64-0.07l0-0.8l-0.08-0.62l0.29,0.37l0.51,0.41l0.21,0.35l-0.05,0.35l0.17,0.28l0.52,0.4l0.62-0.2l0.26-0.23l0.07-0.54
  		l-0.59-2.03L2685.37,1299.72z M2654.02,1272.86l-0.35,0.18l-0.11,1.38l0.61,0.13l0.21-0.23l0.42-0.97l-0.24-0.23L2654.02,1272.86z
  		 M2801.1,1282.96l-0.39,0.1l0.04,0.39l-0.15,0.29l0.81,0.02l1.17-0.16l-1.04-0.55L2801.1,1282.96z M2663.14,1293.14l-0.14-0.17
  		l-0.31,0.29l-0.07,0.3l0.02,0.22l0.43-0.15L2663.14,1293.14z"/>
    </>,
    MEX: <>
        <path className="st0" d="M865.32,1108.07l-0.03-0.67l-0.28-0.62l-0.26-0.37l-0.03-0.4l0.56-3.02l-0.23-0.55l-0.53,2.99l-0.63,1.44
  		l0.73,0.24l0.29,0.3l0.11,0.71l0.72,1.03l0.17-0.1l-0.09-0.34L865.32,1108.07z M866.88,1108.99l-0.61,0.16l2.53,1.66l0.11-0.42
  		l-1.33-1.28L866.88,1108.99z M912.4,1134.39l-0.51-0.31l-0.35,0.13l0.04,0.74l0.23,0.45l0.64,0.29l0.25-0.72l-0.18-0.36
  		L912.4,1134.39z M874.89,1158.93l-0.43,0.29l-0.2,0.42l0.75,0.53l0.51-0.18l-0.23-0.52L874.89,1158.93z M839.15,1074.67l-0.34,0.23
  		l0.11,1.11l-0.78,1.06l1.42,0.6l0.12-0.29l0.19-0.93l-0.41-1.41L839.15,1074.67z M1037.5,1160.54l0.25-0.3l0.99-0.42l-0.11-0.12
  		l-0.33-0.04l-1.91,0.89l0.17,0.19L1037.5,1160.54z M813.61,1068.13l-0.39-0.4l0-0.48l-0.47-0.04l-0.28,0.23l0.01,0.46l0.97,1.9
  		l0.36-0.35l-0.04-0.93L813.61,1068.13z M1078.08,1141.32l0.41-1.05l0.37-1.27l-0.27-0.43l-0.11-0.3l-0.06-1.64l-0.74-0.36
  		l-1.04-1.13l-0.79-0.26l-0.74,0.34l-0.5,0.09l-0.78-0.02l-0.15,0.2l0.77,0.23l0.39-0.01l0.33-0.15l0.19-0.02l0.2,0.28l-0.2,0.32
  		l-0.26,0.17l-0.28,0.1l-1.93-0.22l-1.75-0.56l-0.71-0.12l-1.96-0.48l-1.05-0.1l-0.33,0.11l0,0.11l-0.11,0.11l-0.56,0.11l-1.82-0.02
  		l-1,0.27l-1.36,0.79l-1.11,0.3l-7.93,1.22l-0.57,0.19l-2.49,1.15l-1.43,0.98l-0.69,2.2l-0.2,0.38l-0.22,1.38l0.05,1.54l-0.07,1.36
  		l0.03,1.73l-0.08,0.69l-0.13,0.3l-1.2,1.01l-0.36,0.58l-0.39,3.28l-1.82,1.74l-0.88,0.46l-0.65,0.53l-2.53,1.28l-0.18,0.22
  		l-0.1,0.27l0.2,0l0.86-0.59l0.07,0.21l-0.28,0.61l0.1,0.26l0.4,0.03l0.25,0.46l0.03,0.83l-1.39,0.72l-0.79,0.74l-0.55,0.08
  		l-1.71-0.2l-0.93-0.5l-0.25-0.3l0.52-0.31l0-0.33l-0.79-0.68l-1.09,0.1l-0.93,0.17l-1.92,0.08l-0.37,0.09l-1.89,0.46l-0.16,0.32
  		l-0.34,0.44l-0.97,0.48l-2.04,0.39l-0.85-0.18l-2.73,0.12l-1.79,0.63l-0.92,0.46l-2.66,0.94l-1.71,0.25l-0.57-0.01l-0.73-0.07
  		l-1.14-1.5l-0.98-1.44l-1.82-0.48l-1.41-1.13l-3.2-0.16l-1.14-0.48l-0.19,0.06l0.55,0.39l0.65,0.29l-0.41,0l-1.64-0.56l-0.84-0.56
  		l-0.07-0.26l0.99,0.4l0.27-0.01l-1.14-0.79l-0.6-1.36l-0.75-0.45l-0.43-0.81l-1.39-1.26l-0.22-1.12l-0.45-0.82l-0.74-2.63
  		l-2.13-2.78l-3.48-3.72l-0.55-0.89l-0.07-0.73l-1.37-2.66l-1.21-2.58l0-0.3l-0.11-0.4l-0.44-0.26l-0.26-0.25l-0.34-0.59l-0.98-3.71
  		l1.38,2.32l1.13,1.31l0.62,0.4l-0.01,0.38l-0.33,0.51l-0.08,0.95l0.21,0.74l0.18-0.89l0.43-0.56l0.19-1.11l-0.38-0.45l-1.05-0.79
  		l-0.84-0.91l-1.5-2.61l-0.16-1.53l-0.51-2.03l0.01-0.41l-0.14-0.59l0.35-1.34l0.49-0.97l0.11-0.5l-0.17-3.21l0.32-3.77l-0.13-0.25
  		l0.12-0.24l0.1-1.71l0.42-3.64l1.35-5.56l0.7-1.95l1.68-3.15l0.51-1.52l0.15-1.85l-1.14,0.18l-0.48,0.27l-0.09,0.24l-0.07,0.13
  		l-0.15-0.01l-0.54-0.17l-1.24-0.84l-1.8-0.52l-2.37-0.2l-1.62-0.42l-0.87-0.64l-0.91-0.38l-0.95-0.12l-0.78-0.35l-0.62-0.57
  		l-0.91-0.37l-1.2-0.16l-0.78-0.43l-0.54-1.05l0-0.02l-0.48-1.76l-0.61-1.11l-1.19-1.37l-0.11-0.18l0,0l-0.01-0.23l0.15-0.8
  		l-0.13-0.57l-0.38-0.47l-0.09-0.5l0.2-0.52l0.01-0.63l-0.18-0.73l-0.76-0.79l-1.34-0.85l-1.14-1.24l-0.94-1.64l-0.93-1.14
  		l-0.92-0.63l-0.63-0.77l-0.34-0.91l-0.1-0.53l0.14-0.15l-0.57-1.02l-1.27-1.89l-0.73-1.38l-0.19-0.87l-0.81-1.04l-1.43-1.21
  		l-0.78-0.79l-0.19-0.55l0,0l-2.23-1.59l-0.65-0.99l-0.51-0.31l-0.58,0.03l-0.3-0.1l-0.01-0.22l-0.19-0.01l-0.36,0.2l-1.19,0.04
  		l-2.01-0.12l-1.45-0.27l-0.89-0.42l-0.62,0.06l-0.36,0.53l-0.76,0.34l-1.17,0.15l-1,0.99l-0.84,1.83l-0.37,1.18l0.1,0.52
  		l-0.22,0.38l-0.55,0.24l-0.56,0.53l-0.57,0.82l-0.66,0.4l-0.75-0.03l-1.39-0.63l-2.03-1.24l-1.59-0.77l-1.15-0.29l-1.02-0.58
  		l-0.88-0.86l-0.81-0.57l-0.74-0.29l-0.87-0.95l-1-1.62l-0.5-1.25v-1.32l-1.3-2.88l-0.69-1.25l-0.51-0.58l-1.01-0.69l-1.5-0.8
  		l-2.01-1.6l-2.52-2.41l-1.78-1.45l-1.04-0.49l-0.91-0.87l-0.77-1.25l-0.75-0.79l-0.08-0.04l-0.07-0.02l-1.85-0.01l-1.85-0.01
  		l-1.85-0.01l-1.85-0.01l-1.85-0.01l-1.85-0.01l-1.85-0.01l-1.85-0.01l-0.01,1.04l-0.01,1.04l-0.01,1.04l-0.01,1.04l-2.98,0.01
  		l-2.98,0.01l-2.98,0.01l-2.98,0.01l-2.98,0.01l-2.98,0.01l-2.98,0.01l-2.98,0.01l-3.99-1.37l-3.99-1.37l-3.99-1.37l-3.99-1.38
  		l-3.99-1.38l-3.99-1.38l-3.99-1.38l-3.99-1.38l0.4-0.53l0.53-1.41l-0.96,0.1l-2.41,0.2l-2.41,0.2l-2.41,0.2l-2.41,0.2l-2.41,0.2
  		l-2.41,0.2l-2.41,0.2l-2.41,0.2l0.55,1.77l0.24,0.36l1.02,0.99l0.55,1.87l1.91,1.36l-0.03,0.86l-0.24,0.16l-0.42-0.03l-0.17,0.08
  		l0.45,0.33l0.05,1.24l0.44,0.61l1.27,1.28l1.05,1.46l0.2,0.7l0,0.71l0.11,0.74l1.97,1.54l0.23,0.91l0.06,1.31l0.28,1.37l1.16,0.5
  		l0.36,0.52l0.22,2.02l-0.16,1.14l0.5,0.22l0.63,1.64l0.91,0.7l2.14,1.36l1.22,0.96l1.46,0.39l0.47,0.3l0.52,0.64l1.78,1.71
  		l2.99,3.32l1.38,1.13l0.82,1.63l-0.37,1.86l-0.78,1.89l-0.68,0.71l0.11,0.24l0.79-0.11l0.01,0.47l-0.15,0.34l0.34,0.94l0.55,0.46
  		l-0.57,0.04l-0.8-0.42l-0.59-0.52l0.11-0.57l-0.1-0.31l-0.61,0.29l-0.64,0.4l-1.02,0.12l-2.14-0.42l-1.79-0.11l0.03,0.39l0.33,0.57
  		l1.14,0.7l1.21,1.08l1.48,0.98l0.35,0.5l0.16,0.84l0.29,0.59l0.94,0.54l1.11,0.13l0.77,0.34l0.96,1.07l0.51,0.02l0.8,0.17
  		l0.71,0.86l0.47,0.72l0.87,0.63l1.45-0.67l1.29,0.04l0.56-0.59l0.42-0.8l0.11,1.39l0.2,0.68l0.83,1.2l3.05,2.4l1.11,0.39l1.25,0.53
  		l1.71,2.7l0.46,1.32l0.04,1.21l0.18,0.41l0.2,0.1l0.12,0.76l-0.19,1.47l-0.43,2.51l0.08,0.97l0.39,0.84l1.89,1.51l0.19,0.34
  		l0.02,0.52l0.17,0.27l0.44-0.1l0.57-0.01l0.88,1l1.34,1.01l3.23,1.99l1.18,1.2l1.1,0.83l1.14,1.24l2.25,1.17l0.62,0.77l0.38,0.93
  		l0.53,0.63l0.8,2.97l0.67,0.98l0.7,0.07l0.85-0.32l0.8-0.53l0.82-0.85l1.14-0.72l0.32-0.49l0.36-1.69l-0.05-0.66l-0.75-1.04
  		l-1.41-0.56l-0.29-1.26l-0.55-0.54l-0.3-0.66l-0.69-0.83l-0.75-0.68l-0.34-0.58l-2.02-1.51l-0.34,0.04l-0.18,0.3l0.04,0.42
  		l0.2,0.57l-0.2,0.49l-0.4,0.35l-0.36-0.27l0.08-0.3l-0.18-0.16l-1.06-0.27l-0.95-1.13l-0.63-2.21l0.05-0.73l0.44-1.04l-0.08-0.71
  		l-0.58-1.13l-1.16-1.34l-1.01-2.47l-0.17-0.95l-0.97-0.41l-1.2-1.94l-0.33-1.27l-0.01-1.74l-0.61-1.25l-0.12-0.76l-0.39-0.53
  		l-0.05-0.88l-0.64-0.65l-0.2-1.16l-1.9-1.55l-0.22,0.13l0.04,0.98l0.32,0.62l0.67,0.96l-0.2,0.15l-0.26-0.07l-0.92-0.95l-0.17-1.46
  		l-1.06-1.14l-0.05-0.38l0.1-0.63l-0.79-0.6l-0.79-0.37l-0.77-1.45l-0.39-1.59l-1.88-1.21l-1.53-1.53l-0.2-0.68l0.07-0.85
  		l-0.49-0.88l0.1-1.04l-0.61-0.77l0.03-0.53l-0.05-0.67l-0.72-0.29l-0.65-0.15l-0.51-0.36l-0.94-2.61l-0.45-0.18l-0.52,0.05
  		l-0.12-0.24l0.05-0.31l-0.45-0.67l-0.99,0.18l-0.33-0.88l-0.06-0.72l-1.77-2.42l-0.62-0.66l-1.96-1.56l-0.99-1.14l-1.63-0.88
  		l-0.26-0.61l-1.24-1.15l-0.67-1.23l-0.17-0.75l0.14-2.47l-0.44-1.05l-0.15-1.33l-0.49-1.79l-0.7-1.12l-0.31-0.7l0.28-3.53
  		l0.49-1.01l-0.42-1.41l-0.47-0.48l-0.32-0.47l1.62,0.88l0.37,0.27l0.75,0.14l0.51,0.27l2.4,1.66l0.97,0.44l0.58-0.03l0.66-0.14
  		l0.21-0.63l0.25-0.34l1.59,0.66l0.5,0.32l0.56,0.52l0.08,1.13l1.2,0.48l2.1,0.35l0.38,0.18l0.86,0.27l0.31,0.26l0.03,0.85
  		l-0.25,0.24l-0.3-0.15l-0.09,0.27l0.11,0.19l0.15,0.82l-0.2,1.34l0.44,1.31l0.89,1.3l1.07,1.93l0.55,1.6l0.18,1.29l0.35,0.63
  		l0.37,0.43l0.68,1.38l1.33,1.68l0.22,0.7l-0.04,0.37l0.13,0.66l0.65,0.23l0.66,0.49l0.27,1.38l0.25,0.9l0.98,1.12l0.88,0.66
  		l0.19,0.23l0.1,0.41l0.63,0.95l0.72,0.77l0.57,0.85l1.76,0.79l1.59,2.44l1.36,1.34l1.14,0.37l0.55,0.34l0.61-0.26l0.75,0.02
  		l1.93,0.46l-0.41,0.62l-0.31,1.28l0.19,0.99l0.27,0.85l0.35,0.49l0.35,0.66l0.85,0.81l0.84,0.64l2.81,0.75l0.15,0.46l0.03,0.46
  		l0.26,0.85l0.53,1.02l0.62,0.6l0.66,0.06l1.63-0.12l1.74,1.59l0.28,0.75l0.02,0.41l0.21,0.44l0.59,0.45l0.25,0.48l-0.35-0.05
  		l-0.34-0.42l-0.6,0.56l-0.7,0.94l-0.6,0.95l0.34,2.74l0.68,0.84l0.42,0.22l0.48,0.15l0.95-0.2l0.64-0.24l0.62-0.43l0.41-0.39
  		l0.01,0.33l-0.74,0.96l-0.81,0.33l0.04,0.31l0.29,0.32l1.14-0.28l0.42-0.28l0.47,0.05l0.31,1.02l0.46,0.37l1.94,1.05l0.78,0.63
  		l2.37,0.9l0.35,0.24l0.13,0.28l-0.37,0.15l-0.51,0l-0.44-0.11l-0.43-0.38l-0.32-0.07l0.62,0.95l1.62,1.71l0.05,0.8l0.49,0.7
  		l1.13,0.68l0.9,0.12l0.3,0.19l0.6,0.12l0.45-0.13l0.31,0.14l0.19,0.58l-0.04,0.49l-0.28,0.08l-1.68-0.99h-0.32l5.73,4.05l1.26,1.2
  		l1.74,2.4l1.37,1.43l1.38,2.24l1.41,1.19l1.79,2.05l0.66,0.46l1.28,1.32l1.23,2.65l-0.03,2.98l1.03,1.49l0.59,1.28l0.22,0.48
  		l1.88,1.12l-0.21,0.97l0.07,1.15l-0.1,1.14l-0.55,0.81l-0.78,0.88l-0.53,0.72l-0.46,0.31l0.16,0.28l0.61,0.01l0.78,0.2l0.63,0.74
  		l0.06,0.3l-0.13,0.48l-0.98,0.59l-1.39,0.12l-0.84,0.54l-0.23,0.44l0.45,0.6l0.38,0.77l0.32,1.33l0.42,0.87l1.65,2.35l1.51,1.26
  		l0.53,1.03l0.9,1.16l2.83,1.36l1.67,0.54l1.08,0.7l1.95,0.86l1.12,0.72l1.8,1.69l1,1.29l1.17,1.38l3.56,1.2l2.69,1.07l1.29,0.19
  		l2.78,0.73l1.86-0.13l0.65,0.11l0.6,0.32l0.71,0.69l1.37,1.64l0.95,0.31l0.86,0.87l2,1.04l1.23,1.01l1.3,0.65l3.5,1.18l1.59,0.69
  		l1.84,0.55l2.81,1.73l2.89,0.47l2.92,0.72l0.79,0.32l1.23,0.08l2.04,1.98l3.21,0.85l3.24,2.06l4.8,0.49l3.17,1.57l2.5,0.64
  		l0.86-0.27l1.64-0.09l3.72-1.67l2.59-0.75l2.78-1.74l1.56-0.28l1.26,0l0.11-0.17l-0.51-0.15l-1.47-0.27l-0.02-0.25l0.75-0.63
  		l0.29-0.33l0.35-0.02l0.55,0.47l-0.04,0.32l0.06,0.34l0.32-0.03l0.86-0.52l0.29,0.04l0.25,0.27l-0.8,0.75l0.17,0.23l1.61,0.13
  		l3.95,1.44l-0.23-0.37l-1.39-0.72l-0.47-0.19l-0.45-0.02l-0.57-0.22l-0.47-0.27l0.15-0.52l0.29,0.02l0.53,0.39l0.6,0.29l1.36,0.51
  		l1.37,0.79l1.53,1.42l1.63,1.18l3.15,2.59l1.2,1.18l0.89,0.63l0.92,0.84l2.34,2.56l2.24,2.32l0.25,0.19l0.22-0.22l0.19-0.51
  		l0.23-0.52l-0.14-0.6l-0.08-0.49l0.26-0.71l-0.02-0.53l0.12-0.33l0.38-0.21l0.2-0.41l-1.09-1.4l0-0.32l0.14-0.39l0.88-1.5
  		l1.05-1.78l1.16-1.97l0.7-1.18l2.55,0l1.69,0l2.14,0l2.33,0l1.53,0l0.63-0.01l-0.11-0.77l0.08-0.85l0.28-0.77v-0.34l-0.46-0.42
  		l-0.88-0.24l-0.49-0.37l0-0.47l-0.22-0.57l-0.43-0.66l-0.89-0.68l-1.35-0.7l-1.15-0.93l-0.95-1.17l-0.8-0.75l-0.62-0.32l-0.15-0.17
  		l1.8,0.02l1.71,0.01l0.01-1.68l0.01-1.5l0.01-1.69l3.09,0l3.69,0l3.83,0l3.01,0l1.77,0l-0.01-0.75l0.24-0.6l0.7-0.25l0.91,0.52
  		l0.38,0.22l0.34-0.12l0.43-0.32l0.53-0.92l1.33-1.89l0.53-1.35l0.52-0.27l0.75-0.05l0.65,0.09l0.17-0.36l0.68-1.11l-0.01-0.67
  		l0.59-0.46l0.45-0.53l0.35-0.04l0.17,0.97l-0.38,1.75l0.15,0.35l0.67,0.37l0.65,1.45l0.24,0.04l0.41-0.76l0.36-0.77l0.24-1.81
  		l0.68-1.24l0.5-2.15l0.78-2.1l-0.07-0.26l-0.35-0.03l-0.6,0.61l-0.28-0.06l-0.02-0.82l0.26-0.26l0.51-0.29l0.46-0.09l0.25-0.16
  		l0.4-0.5l0.08-0.71l-0.38-0.03l-0.36,0.1l-0.64,0.02l-0.49,0.17l-0.38-0.35l0.02-0.38l0.86-1.24l0.66-0.42l0.34,0.03l0.21-0.33
  		l0.08-0.32l-0.29-0.87l-0.01-0.9l0.39-1.13l1.69-2.41l1.36-1.08l1.12-1.36L1078.08,1141.32z M854.81,1065.83l-0.02-0.72l-0.34-0.63
  		l-0.78-0.68l-0.67-0.12l-0.06,1.01l0.12,0.45l0.71,0.97l1.95,1.93l0.86,0.57l0.06-0.16l-0.19-0.73l-0.2-1.55L854.81,1065.83z
  		 M1078.39,1144.17l-0.84,0.06l-0.42,0.54l-0.35,0.94l0.24,0.96l0.44-0.27l1.1-1.44l0.45-0.73l-0.07-0.24L1078.39,1144.17z
  		 M1079.35,1138.22l-0.11-0.22l-0.1-0.14l-0.12,0.01l0.14,0.4l0.19,0.32l0.15,0.05L1079.35,1138.22z M843.04,1043.21l-0.11,0.04
  		l-0.04,0.39l0.52,0.43l0.28-0.04l0.05-0.17l-0.18-0.3L843.04,1043.21z M878.21,1104.61l-0.8-0.41l-0.08,0.06l-0.03,0.31l0.39,0.69
  		l0.57,0.54l0.43,0.15l-0.24-1L878.21,1104.61z M864.16,1067.04l-0.18-0.31l-1.17,0.34l-0.39,0.33l-0.52,2.5l0.14,0.42l1.34,0.68
  		l0.65,0.03l0.63-2.15l-0.38-1.1L864.16,1067.04z M884.07,1110.95l0.19,1.16l0.43,0.47l0.18-0.03l0.08-0.11l0.02-0.18l-0.81-1.43
  		L884.07,1110.95z M873.63,1095.42l-0.37,0.26l-0.35,1.84l0.17-0.12l0.58-1.34l0.34,0.22l0.06-0.09l-0.11-0.32l0.08-0.49
  		L873.63,1095.42z"/>
    </>,
    MWI: <>
        <path className="st0" d="M2104.14,1415.95L2104.14,1415.95l-0.05-0.26L2104.14,1415.95L2104.14,1415.95z M2102.12,1420.46l0.22-0.19
  		l0.09-0.25l0-0.24l-0.14-0.15l-0.2-0.09l-0.3,0.05l-0.1,0.33l-0.05,0.45l0.19,0.15L2102.12,1420.46z M2112.01,1444.16l-0.22-0.24
  		l-0.16-1.65l-1.32-1.76l-1.7-2.26l-0.95-1.22l-1.08-1.38l-1.97-2.16l-0.9-0.78l-0.39-0.25l-0.01,0.8l0.07,0.73l0.17,0.13l1.19,0.11
  		l0.22,0.11l0.35,0.7l0.7,2.04l-0.02,0.21l0.69,1.61l0.2,0.75l-0.09,0.22l-0.13,0.1l-0.18-0.01l-0.36-0.41l-0.53-0.55l-0.69-0.96
  		l-0.29-0.17l-0.47-0.08l-0.09-0.12l-0.05-0.54l-0.1-0.25l-0.2-0.18l-0.21-0.08l-0.21,0.02l-0.07,0.18l0.08,0.33l-0.07,0.33
  		l-0.21,0.32l-0.07,0.24l0.07,0.16l-0.05,0.17l-0.42,0.32l-0.34,0.11l-0.19-0.05l-0.05-0.22l-0.19-0.19l-0.34-0.17l-0.31-0.31
  		l-0.28-0.45l-0.17-0.5l-0.07-0.54l0.07-0.4l0.2-0.26l0.21-0.53l0.22-0.81l-0.14-0.7l-0.49-0.59l-0.41-0.28l-0.33,0.04l-0.41-0.36
  		l-0.75-1.28l-0.07-0.28l0.05-0.32l0.16-0.37l0.05-0.91l-0.12-1.82l-0.09-0.2l-0.17-0.55l-0.2-0.28l-0.07-0.29l0.05-0.3l-0.08-0.22
  		l-0.22-0.14l-0.19-0.25l-0.15-0.35l0.01-1.37l-0.14-0.47l-0.25-0.27l-0.36-0.07l-0.28-0.27l-0.31-0.88l-0.04-0.35l0.22-1.36
  		l0.35-0.61l0.53-0.43l0.26-0.38l-0.02-0.34l0.25-0.44l0.81-0.93l0.04-0.22l-0.28-1.93l-0.23-0.96l-0.26-0.71l-0.06-0.51l0.15-0.31
  		l0.03-0.27l-0.09-0.22l-0.27-3.89l-0.11-0.2l0.04-0.2l0.2-0.19l0.09-0.23l-0.01-0.27l0.09-0.2l0.19-0.12l-0.1-0.4l-0.38-0.68
  		l-0.41-0.54l-0.83-0.83l-0.35-0.5l-0.26-0.63l-0.37-1.27l-0.19-0.26l-0.1-0.35l-0.01-0.43l0.09-0.33l0.32-0.46l-0.08,0.12
  		l-0.46-0.02l-0.29-0.06l-0.74-0.44l-0.58-0.11l-1.43,0.08l-0.5,0.1l-0.39-0.1l-0.76-0.75l-0.89-0.16l-0.8-0.04l-1.32-0.85
  		l-0.31,0.04l-0.15,0.06l0.03,0.22l0.23,0.42l0.24,0.3l0.02,0.45l0.12,0.42l0.35,0.1l0.29,0.03l0.27-0.3l0.37,0.01l0.4,0.19
  		l0.14,0.48l0.31,0.65l0.51,0.44l0.34,0.43l-0.12,0.78l-0.22,0.71l0.69,0.7l0.9,0.67l0.24,0.3l0.07,0.99l0.14,0.34l0.61,0.82
  		l0.3,0.55l-0.02,0.32l-1.64,1.63l-0.52,0.16l-0.49,0.09l-0.44,0.34l-0.27,0.35l0.1,0.18l0.17,0.56l0.38,0.88l0.35,0.62l-0.29,0.77
  		l-0.65,1.31l-0.3,0.12l-0.05,0.99l0.2,0.36l0.32,0.28l0.13,0.68l0.01,0.93l-0.03,0.75l-0.41,1.9l0.74,1.66l0.25,0.18l1.02,0.01
  		l0.18,0.14l-0.25,0.47l-0.44,0.48l-0.28,0.25l-1.3,0.57l-1.87,0.63l-0.39,0.6l-0.25,0.88l0.21,0.51l0.25,0.3l-0.08,0.76l-0.16,0.81
  		l0.06,0.64l-0.08,0.56l-0.24,0.28l-0.33,0.85l-0.4,0.85l-0.32,0.39l-0.47,0.41l-0.74,0.34l0.01,0.17l0.84,0.39l0.22,0.27l0.08,0.19
  		l-0.18,0.18l-0.17,0.25l0.39,0.26l0.47,0.22l0.45,0.57l0.4,0.79l0.12,0.28l0.09,0.11l0.14,0.01l0.28-0.12l0.52-0.43l0.37-0.16
  		l0.45,0.62l0.35,0.25l1.23,2.1l0.97,1.24l1.1,1.15l0.18-0.06l0.32-0.27l0.55-0.11l1.76-0.26l0.67-0.02l0.44-0.31l0.9-0.22
  		l1.04-0.13l0.36,0.14l1.1,1.48l0.16,1.13l0.23,1.64l0.05,0.8l-0.02,1.07l-0.12,1.34l-0.89,1.54l-0.17,0.77l-0.48,1.19l-0.63,0.58
  		l-0.31,0.48l0.02,0.5l0.34,0.42l0.74,0.75l0.23,0.49l-0.07,0.43l0,0.59l0.18,0.41l0.21,0.24l0.73,0.38l0.71,0.97l1.23,1.17
  		l1.47,1.66l0.69,0.51l0.54,0.12l0.27,0.56l-0.15,0.65l-0.42,0.37l0.17,0.53l0.24,0.28l0.27,0.14l0.65,0.03l0.6-0.11l0.15-0.19
  		l-0.08-2.49l-0.43-1.45l-0.43-0.57l-0.09-0.11l0.15-0.48l0.48-1.11l0.41-1.1l0.26-0.46l0.3-0.28l2.03-0.3l0.92-0.26l0.39-0.32
  		l0.3-0.86l0.24-2.39l0.09-2.24l-0.21-1.31l0.29-1.98L2112.01,1444.16z M2101.3,1420.09l0.17-0.45l-0.15-0.25l-0.28-0.12l-0.31,0.09
  		l-0.1,0.31l0.33,0.4L2101.3,1420.09z"/>
    </>,
    MDV: <>
        <path className="st0" d="M2427.92,1291.12l-0.14,0.15l-0.02,0.21l0.11,0.14l0.18-0.02l0.15-0.16l0.07-0.2l-0.13-0.13L2427.92,1291.12z
  		 M2428.9,1283.09l-0.11,0.11l-0.08,0.2l-0.11,0.19l-0.07,0.15l0.05,0.1l0.14,0.03l0.15-0.08l0.06-0.19l0.03-0.2l0.04-0.16
  		l0.01-0.11L2428.9,1283.09z"/>
    </>,
    MSU: <>
        <polygon className="st1" points="2295.99,1489.11 2295.31,1488.16 2294.63,1488.22 2294.12,1488.74 2293.88,1489.5 2293.29,1489.85
  		2293.03,1490.24 2292.83,1491.19 2292.86,1491.79 2292.46,1491.98 2292.55,1492.17 2293.01,1492.64 2294.2,1492.72
  		2295.27,1492.48 2295.73,1492.04 2295.89,1491.46 2296.36,1491.1 2296.45,1490.1 	"/>
    </>,
    MLT: <>
        <path className="st0" d="M1929.83,1002.83l-0.7,0.15l0.12,0.17l0.5,0.29l0.5-0.15l0.06-0.08l-0.14-0.25L1929.83,1002.83z
  		 M1932.14,1004.64l-0.75-0.68l-0.82-0.2l0.01,1.02l0.71,0.48l0.81,0.01l0.28-0.31L1932.14,1004.64z"/>
    </>,
    MSR: <>
        <polygon className="st1" points="1286.03,1176.67 1285.9,1176.71 1285.63,1177.17 1285.64,1177.62 1286.21,1177.77 1286.26,1177.26
  		"/>
    </>,
    MRT: <>
        <path className="st0" d="M1759.5,1105.04l2.69,0l3.94,0l2.95,0l-1.91-1.25l-1.9-1.24l-2.04-1.33l-1.33-0.83l-1.58-0.99l-1.58-0.99
  		l-1.58-0.99l-1.58-0.99l-1.58-0.99l-1.58-0.99l-1.58-0.99l-1.58-0.99l-1.58-0.99l-1.58-0.99l-1.58-1l-1.58-1l-1.58-1l-1.58-1
  		l-1.58-1l-1.58-1l0,1.5l0,1.78l0,1.78l0,2.03l0,2.02v1.47l0,1.02h-1.71h-1.57h-1.57h-1.57h-1.57l-1.57,0h-1.57h-1.57h-1.57h-1.57
  		h-1.57h-1.57h-1.57l-1.57,0h-1.57h-1.57h-1.57h-1.26v1.07v1.22v1.22v1.22v1.22v1.22v1.22v1.21v1.21v1.21v1.21v1.21v1.21v1.21v1.21
  		v1.21v1.21v1.08l-0.06,0.96l-0.5,0.28l-1.2,0.51l-1.24,0.53l-1.57,0.24l-0.51,0.17l-1,0.69l-1.32,0.91l-1.14,0.79l-0.75,1.03
  		l-0.27,0.56l-0.11,0.59l0.09,0.56l0.41,1.13l0.11,0.57l0.06,0.99l0.07,1.08l0.07,1.16l0.08,1.16l0.08,1.24l0.08,1.24l0.08,1.24
  		l0.06,0.92l0.07,1.16l-1.27,0l-1.93,0l-1.93,0l-1.93,0l-1.93,0l-1.93,0l-1.93,0l-1.93,0l-1.93,0l-1.93,0l-1.93,0l-1.93,0l-1.93,0
  		l-1.93,0l-1.93,0l-1.93,0l-1.93,0l-1.08,0l-0.35,1.64l-0.31,1.18l-0.18,0.95l0.13,0.81l0.42-2.04l0.23-0.32l0.36-0.34l0.44,0.25
  		l1.24,2.45l0.89,1.5l0.5,0.26l0.23-0.44l0.04-0.49l0.43,0.17l0.42,0.33l0.81,2.06l1.04,1.64l-0.26,0.76l0.07,1.22l-0.42,1.86
  		l-1.36,2.73l1.18-0.34l-0.56,0.89l-0.87,0.17l-0.33,0.25l0.32,0.67l1.43,1.14l0.78,1.31l0.53,2.47l0.55,1.71l0.32,2.58l0.14,2.9
  		l-0.41,2.95l-1.08,3.05l-1.17,2.29l-0.98,2.8l-0.15,1.27l-0.46,1.44l0,3.85l0.28-0.68l0.19-1.54l0.33-0.92l0.31-0.18l0.39-0.71
  		l0.47-1.24l0.53-0.69l0.6-0.14l0.46,0.06l0.33,0.26l0.97,0.16l1.61,0.06l1.24-0.18l0.88-0.43l1.15-0.22l1.43-0.01l0.75-0.18
  		l0.07-0.36l0.19-0.11l0.3,0.14l0.28-0.06l0.26-0.25l0.26-0.02l0.26,0.22l1.2,0.07l2.13-0.09l1.97,0.65l1.81,1.39l0.93,0.93
  		l0.06,0.46l0.3,0.47l0.54,0.47l0.49,0.09l0.45-0.3l0.35,0.03l0.26,0.36l0.52,0.07l0.57-0.22l0.41,0.08l0.07,0.21l0.09,0.11
  		l0.28,0.05l0.37,0.27l0.52,0.74l0.43,1.03l0.33,1.32l0.43,0.72l0.54,0.12l0.31,0.27l0.06,0.31l0.16,0.21l0.26,0.12l0.46-0.07
  		l0.54,0.44l0.57,0.96l0.09,0.43l-0.09,0.23l0.03,0.17l0.38,0.16l0.36,0.32l0.3,0.47l0.64,0.42l0.98,0.37l0.7,0.55l0.43,0.73
  		l0.89,0.62l0.18,0.07l1.48,0.54l0.2-0.18l0.51-0.33l0.68-0.7l0.57-0.93l0.26-1.15l0.11-0.99l0.26-0.84l0.32-0.71l0.71-0.74
  		l0.67-0.52l0.79-0.55l0.4,0.1l0.75,0.76l1.65,1.53l1.36,1.16l0.5,0.61l0.44,0.01l0.68-1.12l0.7-0.97l0.3-0.24l0.93-0.1l0.79-0.04
  		l0.69,0.02l1.26,0.19l0.58,0.17l0.54,0.11l1.58,0.08l1.57-0.24l1.49-0.31l1.1-0.18l0.06-0.46l-0.06-0.54l0.18-0.41l0.35-0.38
  		l0.29-0.08l0.13,1.3l0.35,0.2l0.98,0.06h1.6h1.72l1.72,0h1.72h1.72h1.72h1.72h1.72l1.72,0h1.72h1.72h1.72h1.72h1.72l1.72,0l1.72,0
  		h1.72h1.78l0.48-2.51l0.44-2.3l0.37-1.93l-1.26-1.37l-1-1.09l-0.23-2.07l-0.24-2.14l-0.24-2.14l-0.24-2.15l-0.24-2.15l-0.24-2.15
  		l-0.24-2.15l-0.24-2.15l-0.24-2.15l-0.24-2.16l-0.24-2.16l-0.24-2.16l-0.24-2.16l-0.24-2.17l-0.24-2.17l-0.24-2.17l-0.24-2.17
  		l-0.24-2.17l-0.24-2.18l-0.24-2.18l-0.24-2.18l-0.24-2.18l-0.24-2.19l-0.24-2.19l-0.24-2.19l-0.24-2.19l-0.24-2.2l-0.24-2.2
  		l-0.24-2.2l-0.24-2.2l-0.24-2.21l-0.24-2.21l-0.24-2.21l-0.22-2.03l2.58,0L1759.5,1105.04z M1671.26,1152.45l0.54-0.85l0.25-1.39
  		l-0.42,0.15l-0.23,0.41l-0.48,0.8l0.09,0.56L1671.26,1152.45z"/>
    </>,
    MTQ: <>
        <polygon className="st0" points="1296.86,1195.24 1296.49,1194.88 1296.61,1194.46 1296.54,1194.29 1296.33,1194.29 1295.7,1193.69
  		1294.86,1193.27 1294.41,1193.3 1294.13,1193.5 1294.08,1193.87 1294.74,1195.17 1295.05,1195.44 1295.83,1195.6 1295.17,1196.22
  		1295.18,1196.39 1295.39,1196.76 1296.78,1196.7 1297.09,1197.11 1297.31,1197.01 1297.39,1196.52 1297.02,1195.5 	"/>
    </>,
    MNP: <>
        <path className="st0" d="M3036.67,1160.12l0.25-0.32l-0.01-0.24l-0.14-0.1l-0.38-0.04l0.06,0.47L3036.67,1160.12z M3036.51,1180.74
  		l0.27,0.03l0.18-0.06l0.06-0.18l-0.2-0.17l-0.54,0.01l0.04,0.23L3036.51,1180.74z M3038,1165.22l-0.24-0.31l-0.15,0.15l-0.51,0.85
  		l0.41-0.19L3038,1165.22z M3032.24,1199.35l0.04,0.23l0.19,0.14l0.3,0.08l0.42-0.4l0-0.19l-0.28-0.08L3032.24,1199.35z
  		 M3036.97,1190.36l-0.24,0.77l0.12,0.1l0.43,0.05l0.02-0.22l0.26-0.36l0.05-0.41l0.28-0.37l-0.3,0.07L3036.97,1190.36z
  		 M3036.22,1191.69l-0.32,0.25l0.04,0.27l0.25,0.68l0.35-0.44l-0.13-0.76L3036.22,1191.69z"/>
    </>,
    MAC: <>
        <polygon className="st1" points="2765.88,1129.38 2765.6,1129.42 2765.49,1129.63 2765.48,1129.82 2765.52,1129.81 2765.64,1129.77
  		2766.04,1129.6 2766.06,1129.59 	"/>
    </>,
    MNG: <>
        <polygon className="st1" points="2819.54,894.42 2819.24,893.9 2818.62,893.14 2818.38,892.63 2818.36,891.96 2817.97,891.32
  		2817.03,890.55 2816.42,890.19 2815.15,888.86 2814.72,888.55 2814.58,888.33 2814.43,887.88 2813.96,887.67 2813.35,887.32
  		2813.01,886.97 2812.8,886.35 2812.67,885.94 2812.13,885.61 2811.58,885.42 2810.97,885.19 2809.96,884.84 2809.37,884.15
  		2808.34,882.85 2807.75,882.41 2805.57,882.24 2804.79,881.93 2803.91,882.04 2803.38,882.24 2802.21,882.24 2801.6,882.37
  		2800.83,883.22 2799.81,884.33 2798.97,885.01 2798.37,885.71 2798.09,885.97 2797.54,885.81 2796.79,885.02 2795.72,884.32
  		2794.73,883.99 2794.3,883.82 2793.12,883.64 2792.2,883.69 2791.03,883.96 2789.9,883.91 2789.38,883.74 2788.66,883.76
  		2787.34,884.5 2786.66,885.33 2785.85,885.59 2785.12,885.04 2784.28,884.4 2783.48,883.58 2782.98,882.83 2782.71,880.83
  		2783.67,880.24 2784.9,879.57 2785,878.52 2784.96,877.34 2785.2,876.02 2786.32,874.81 2786.92,873.81 2787,873.18
  		2787.54,872.14 2788.05,871.04 2788.76,869.6 2790.1,867.03 2791.68,863.98 2792.46,862.46 2792.03,861.88 2791.35,861.4
  		2789.67,860.77 2788.54,860.43 2787.84,860.41 2786.09,861.05 2784.99,861.56 2784.33,861.84 2783.24,861.78 2781.9,861.66
  		2781.36,861.5 2780.6,861.09 2779.11,859.88 2778.32,859.01 2777.27,858.52 2776.12,857.96 2775.55,857.83 2774.53,857.87
  		2773.12,857.72 2772.37,857.51 2771.73,857.7 2770.46,858.28 2768.86,859.42 2767.61,859.85 2766.28,860.45 2765.19,861.17
  		2764.13,861.91 2762.82,862.75 2762.22,863.9 2761.91,864.73 2760.73,865.25 2759.81,865.75 2758.89,865.92 2757.19,865.65
  		2756.18,865.84 2753.69,866.83 2752.47,866.92 2751.62,867.06 2750.79,867.12 2749.44,867.35 2748.91,867.52 2748.21,867.72
  		2747.43,867.58 2746.32,868.14 2743.15,869.64 2742.15,869.89 2741.49,869.95 2740.64,869.41 2739.78,869.06 2738.88,869.1
  		2737.86,869.6 2736.13,869.21 2734.07,868.85 2732.21,868.51 2731.57,868.22 2729.75,867.8 2727.08,867.8 2725.5,867.8
  		2724.5,867.94 2723.73,867.73 2722.76,867.13 2721.12,865.73 2720.16,865.32 2719.61,864.98 2719.41,864.4 2719.04,864.33
  		2718.8,863.92 2718.81,863.37 2718.78,862.18 2718.89,861.35 2718.63,861.1 2717.53,860.97 2716.22,860.71 2713.83,860.67
  		2712.87,860.64 2712.11,860.17 2711.25,859.58 2710.41,858.37 2709.68,857.8 2708.47,857.09 2707.32,856.91 2705.59,857.03
  		2704.32,857.18 2703.18,856.87 2702.46,856.48 2701.43,856.07 2699.9,855.97 2698.62,855.67 2697.29,855.31 2696.31,855.46
  		2695.63,855.8 2694.86,856.24 2693.87,856.31 2691.41,856.77 2690.66,857.04 2689.57,857.16 2688.62,857.5 2687.83,858.17
  		2687.15,858.67 2686.3,858.83 2685.29,858.8 2684.43,858.64 2683.98,858.59 2683.31,858.84 2682.55,859.01 2681.4,858.71
  		2680.77,858.47 2679.78,858.33 2679.19,857.62 2678.58,857.33 2677.55,857.22 2676.04,856.86 2675.24,856.49 2674.55,856.27
  		2673.4,855.45 2672.75,854.74 2672.22,854.62 2671.63,854.53 2671.22,854.08 2671.2,853.53 2671.35,853.19 2671.46,852.6
  		2671.12,852.05 2670.77,851.8 2670.61,851.37 2670.7,850.57 2670.57,849.76 2670.43,848.92 2670.07,848.28 2669.99,847.07
  		2670.14,846.57 2670.11,845.98 2669.73,845.54 2668.62,845.22 2667.29,844.79 2665.18,844.27 2664.28,844.22 2663.58,844.43
  		2662.94,844.19 2662.25,843.76 2661.09,843.31 2659.56,842.74 2657.94,842.09 2656.47,841.51 2655.9,841.37 2653.89,841.33
  		2652.24,841.25 2651.29,841.04 2650.16,840.23 2649.59,839.74 2648.69,839.5 2648.01,839.42 2646.96,839.15 2645.01,838.31
  		2644.3,837.9 2643.82,837.9 2643.17,837.15 2642.63,836.97 2642.25,837.51 2641.86,838.77 2641.51,839.36 2640.5,840.53
  		2638.08,841.47 2637.66,841.95 2637.44,842.4 2637.11,843.03 2636.96,843.84 2636.66,844.06 2635.97,844.09 2635.42,844.46
  		2635.01,845.28 2634.66,845.6 2634.46,846.36 2634.49,846.69 2634.41,847.05 2634.35,847.64 2633.72,848.9 2633.63,849.64
  		2633.89,850.11 2634.43,850.73 2634.71,851.09 2634.8,851.5 2634.78,852.04 2635.11,852.79 2635.36,853.42 2635.77,853.87
  		2636.33,854.26 2636.96,854.39 2637.46,854.65 2637.57,855.16 2637.44,855.87 2637.21,857.2 2636.79,858.02 2636.54,858.54
  		2636.13,859.36 2635.98,859.67 2635.14,860.37 2634.57,860.56 2633.87,861.11 2633.3,861.14 2632.75,861.14 2632.17,861.26
  		2631.65,861.5 2631.24,862.25 2630.21,863.02 2629.71,863.36 2628.44,863.48 2627.84,863.14 2627.5,862.67 2627.1,862.39
  		2626.56,861.81 2624.25,861.5 2623.65,861.65 2623.3,861.86 2622.84,861.71 2622.52,861.42 2622.19,861.5 2621.47,861.67
  		2620.91,861.61 2620.19,861.03 2619.2,860.72 2618.81,860.54 2618.42,860.54 2618.17,860.82 2617.72,860.97 2617.41,860.63
  		2617.01,860.39 2616.49,860.39 2615.8,860.9 2614.61,861.14 2614.24,861.5 2613.56,861.46 2613.09,861.17 2612.61,861.14
  		2611.24,861.14 2610.78,861.24 2610.21,860.95 2609.95,860.44 2609.25,860.05 2608.25,860 2607.46,860.05 2607.1,860.22
  		2606.59,860.27 2606.17,859.56 2605.6,859.07 2605.28,858.71 2604.79,858.55 2604.4,858.09 2604.34,857.19 2604.1,856.07
  		2603.83,854.89 2603.53,854.4 2602.05,854.21 2601.33,854.26 2599.69,854.16 2598.57,854.1 2598.26,854.07 2597.21,853.94
  		2596.25,853.82 2595.27,853.74 2594.87,853.84 2593.86,853.87 2593.08,853.31 2592.75,852.67 2592.69,852.31 2592.5,851.94
  		2591.78,851.82 2591.13,851.94 2590.79,852.69 2590.31,852.99 2589.85,852.94 2589.45,852.53 2588.67,852.09 2588.16,851.67
  		2587.56,850.99 2587.06,851.15 2586.92,851.56 2586.8,851.97 2586.19,852.8 2585.45,852.9 2584.2,852.83 2582.92,852.88
  		2582.1,853.19 2581.49,853.75 2581.17,854.19 2580.54,854.33 2579.91,854.78 2579.64,855.37 2579.02,855.35 2578.68,855.42
  		2578.12,855.55 2576.68,855.88 2576.33,855.95 2575.45,856.52 2574.78,856.97 2574.13,857.16 2573.74,857.67 2573.24,858.08
  		2572.08,858.18 2570.8,858.69 2570.35,858.87 2569.62,859.25 2568.6,859.39 2568.18,859.5 2567.77,859.77 2567.53,860.7
  		2566.7,861.04 2565.57,861.1 2564.73,861.59 2564.64,862.47 2564.94,863.26 2564.81,863.63 2564.18,863.82 2563.3,864.25
  		2562.63,864.78 2561.82,864.79 2561.36,864.62 2561.01,864.96 2560.82,865.65 2560.22,865.99 2559.37,866.3 2559.05,866.18
  		2558.84,865.92 2558.46,865.57 2558.16,865.7 2558.13,866.21 2557.88,866.57 2557.18,866.59 2556.63,866.39 2556.21,866.16
  		2555.46,866.19 2554.69,866.3 2554.19,866.19 2553.73,866.3 2552.5,866.53 2552.02,867.3 2552.01,868.2 2551.85,868.66
  		2551.12,869.06 2550.78,869.42 2550.33,869.66 2549.35,869.69 2549.32,869.68 2549.41,870.18 2549.33,870.58 2549.49,871.1
  		2549.8,871.45 2549.7,871.82 2549.25,872.04 2548.81,872.33 2548.72,872.73 2549.27,873.23 2549.46,873.71 2550.39,873.99
  		2551.12,874.31 2551.39,874.62 2551.3,874.97 2550.97,875.34 2550.65,875.74 2550.61,875.98 2550.71,876.26 2551.41,876.45
  		2552.21,876.76 2553.49,877.16 2554.37,877.9 2555.24,878.1 2555.65,878.83 2555.73,879.87 2556.62,880.41 2557.94,881.15
  		2558.61,881.28 2559.06,881.7 2559.7,882.21 2560.28,882.37 2560.96,882.44 2562.08,881.97 2563.34,881.93 2564.03,882.2
  		2564.68,883.21 2565.14,883.54 2565.41,883.82 2565.86,884.09 2566.3,884.13 2566.97,883.91 2567.38,883.46 2567.96,883.55
  		2568.18,883.84 2568.29,884.35 2568.59,884.97 2569.33,885.43 2570.36,885.71 2570.51,885.93 2570.65,886.56 2570.93,886.99
  		2571.31,887.55 2571.66,888.57 2571.74,889.42 2571.9,889.88 2572.38,890.64 2573.14,891.85 2573.75,892.87 2574.46,893.07
  		2575.05,893.4 2575.39,894.15 2576.03,895.57 2576.13,896.5 2576.18,897.19 2576.39,897.51 2576.43,897.9 2575.91,899.39
  		2575.46,900.06 2575.4,900.62 2575.71,901.6 2576.12,902.37 2576.16,903.09 2575.81,903.63 2575.2,904.29 2574.9,904.67
  		2574.43,905.01 2573.7,906.29 2573.37,907.71 2573.3,908.44 2573.57,908.97 2574.04,909.55 2574.15,910.05 2574.91,911.18
  		2575.11,911.87 2575.42,911.9 2575.76,911.67 2576.57,911.65 2577.31,911.9 2578.02,912.41 2578.78,912.68 2579.86,912.62
  		2580.44,912.89 2581.07,913.12 2582.36,913.2 2583.33,913.19 2584.82,913.2 2586.02,913.55 2588.14,913.82 2589.45,913.8
  		2591.21,913.54 2592.29,913.7 2595.47,914.09 2597.34,914.49 2598.52,914.94 2599.35,915.66 2600.3,916.77 2601.06,917.28
  		2603.09,917.59 2604.49,918.89 2605.58,919.38 2607.41,920.64 2608.71,921.13 2610.26,921.58 2612.79,921.39 2612.93,921.56
  		2612.73,922.24 2612.58,923.17 2612.58,923.85 2612.84,924.19 2613.81,924.39 2614.26,924.72 2614.61,925.36 2614.82,925.75
  		2615.63,927.7 2616.93,930.57 2617.08,931.67 2617.52,932.38 2618.94,933.5 2619.68,934.34 2620.78,935.21 2621.14,936.02
  		2621.23,937.06 2621.51,937.33 2623.53,937.09 2625.28,936.92 2628.41,936.62 2632.74,937.17 2637.2,937.69 2641.14,938.16
  		2643.08,938.39 2647.47,938.87 2649.91,938.25 2651.81,937.77 2652.68,937.83 2656.32,938.38 2658.46,938.67 2661.15,939.04
  		2663.02,939.18 2664.54,939.17 2665.25,939.33 2665.93,939.56 2666.39,939.91 2667.78,941.67 2668.57,942.44 2670.11,943.02
  		2673.64,943.69 2675.59,944.09 2677.83,944.56 2679.31,945.26 2681.01,946.07 2683.21,947.13 2685.62,946.67 2688.21,946.17
  		2689.84,945.86 2689.84,948.06 2692.16,948.23 2692.89,948.21 2693.91,948.69 2694.49,948.49 2695.03,948.01 2695.72,947.26
  		2696.71,946.93 2698.42,946.08 2698.83,945.88 2701.37,944.68 2705.16,943.2 2706.85,942.48 2707.36,942.32 2708.32,941.96
  		2708.97,941.7 2710.12,941.5 2711.67,941.37 2713.37,941.09 2717.21,940.57 2717.7,940.52 2719.85,940.3 2720.77,940.1
  		2722.14,940.2 2723.93,940.28 2725.12,940.41 2726.7,940.31 2728.86,940.17 2730.61,940.19 2731.48,940.01 2732.77,939.46
  		2733.63,939.02 2734.99,938.49 2736.66,937.94 2737.83,937.43 2738.61,937.1 2739.55,936.79 2739.79,936.38 2740.06,936.07
  		2740.56,935.55 2741.46,934.58 2742.14,933.73 2742.48,933.36 2743.25,932.51 2743.87,931.87 2744.66,931 2745.33,930.72
  		2746.17,930.48 2748.4,929.64 2748.84,929.45 2749.21,929.42 2750,928.73 2750.66,928.14 2751.09,927.69 2751.99,927.53
  		2752.46,927.21 2752.54,926.79 2752.45,926.15 2752.01,925.49 2751.65,924.92 2750.36,923.83 2749.67,923.15 2748.97,922.27
  		2748.69,921.45 2748.22,920.93 2747.99,920.47 2748.06,919.93 2748.72,918.97 2748.93,918.37 2749.21,917.31 2749.83,915.71
  		2750.34,914.96 2750.92,914.23 2752.16,913.25 2753.29,913.06 2753.97,913.26 2755.48,913.26 2756.48,913.31 2757.23,913.8
  		2758.05,914.77 2758.97,915.12 2761.86,915.88 2763.09,916.04 2763.98,916.08 2765.28,916.33 2765.72,916.38 2766.39,916.55
  		2766.94,916.37 2767.78,915.72 2768.83,914.99 2769.28,914.82 2770.12,914.51 2770.54,914.21 2771.27,913.39 2772.23,912.76
  		2773.4,911.81 2773.97,911.09 2774.09,910.62 2774.22,910.12 2774.58,909.85 2775.29,909.61 2776.09,909.54 2777.61,909.97
  		2779.66,909.85 2780.12,909.79 2781.99,909.54 2782.83,909.34 2784.02,909.14 2784.93,908.34 2786.16,907.39 2787.04,906.85
  		2787.64,906.75 2788.37,906.2 2788.74,905.61 2788.64,905.09 2788.5,904.65 2788.94,903.86 2789.72,902.45 2790.46,901.8
  		2791.06,901.27 2791.45,900.42 2791.93,900.18 2792.51,900.08 2793.34,899.51 2793.95,899.39 2794.95,899.66 2796.45,899.73
  		2797.4,899.76 2797.94,899.66 2798.14,899.35 2798.13,898.87 2798.44,897.81 2798.55,897.46 2798.82,897.29 2799.74,897.27
  		2800.36,897.66 2800.78,897.97 2801.38,898.01 2801.98,897.81 2802.8,896.94 2804.16,896.44 2804.88,896.32 2806.15,895.91
  		2806.96,896.06 2808.44,896.18 2809.02,896.07 2809.64,896.18 2810.21,895.59 2810.66,895.45 2811.62,895.72 2812.22,896.17
  		2813.34,896.74 2814.77,897 2815.97,896.87 2817.2,897.11 2817.93,897.08 2818.27,896.86 2819.28,896.39 2819.52,895.74
  		2819.42,895.12 	"/>
    </>,
    MMR: <>
        <path className="st0" d="M2635.74,1220.38l0.04-0.35l-0.18-0.2l-0.41-0.88l0.09,1.39l0.53,0.5L2635.74,1220.38z M2636.14,1215.4
  		l-0.15-0.08l-0.28-0.03l-0.12,0.1l-0.17,0.4l0.32,0.58l0.28,0.14l0.14,0.03l0.1-0.05l-0.14-0.35l-0.01-0.27l0.08-0.32
  		L2636.14,1215.4z M2635.66,1214.46l-0.05-0.07l-0.12,0.09l-0.46-0.06l-0.43,0.41l0.11,0.2l0.43,0.37l0.49-0.63L2635.66,1214.46z
  		 M2631.73,1179.94l-0.05-0.27l-0.12-0.22l-0.31-0.16l-0.22,0.07l-0.4,0.31l0.09,1.34l0.48,0.56l0.32-0.11l0.12-1.24
  		L2631.73,1179.94z M2592.65,1148.64l-0.38-0.35l-0.02,0.76l0.53,1.15l0.4,0.34l-0.11-0.83L2592.65,1148.64z M2636.29,1234.92
  		l-0.19,0.82l0.14,0.02l0.41-0.49l0.41-0.17l0.44-0.45l0.07-0.37l-0.6,0.05L2636.29,1234.92z M2598.2,1158.7l-1.1,0.18l0.69,0.94
  		l0.88,0.73l0.14-0.07l0.23-0.27l0.23-0.8l-0.01-0.5L2598.2,1158.7z M2606.01,1182.32l-0.44,0.67l-0.89,0.7l0.2,1.25l0.55-0.84
  		l0.75-0.63l0.44-1.05l-0.15-0.55L2606.01,1182.32z M2607.59,1185.15l0.08,0.09l0.35,0.16l0.17-0.22l0.28-0.62l-0.09-0.35
  		L2607.59,1185.15z M2597.13,1149.98l-0.66-0.5l0.06,0.63l0.21,0.62l0.58,0.45L2597.13,1149.98z M2661.45,1134.13l0.02-0.27
  		l-0.09-0.09l-0.34-0.09l-0.51,0.17l-1.55,0.71l-1.33,1.32l-0.61,0.29l-0.62,0.12l-0.72-0.23l-0.8-0.15l-1.14,0.33l-0.56-0.15
  		l-0.26-0.27l-0.23-0.41l0.14-0.52l-0.09-0.38l-0.46-0.19l-0.53-0.17l-0.32-0.5l-0.13-0.54l0.12-0.71l0.06-0.76l-0.25-0.35
  		l-0.78-0.19l-1.96-0.35l-1.72-0.19l-0.72,0.09l-0.59-0.08l-0.34-0.14l-0.16-0.24l-0.01-0.34l0.28-0.79l0.32-0.77l0.8-1.12
  		l0.05-0.78l-0.04-0.9l0.39-1.2l0.69-0.9l0.34-0.28l-0.08-0.4l-0.28-0.37l-0.39-0.2l-0.65-0.24l-1.01-0.07l-1.39-0.24l-1.61-0.54
  		l0.18-1.03l-0.02-0.64l-0.2-0.53l-0.33-0.38l-0.18-0.34l0.29-0.92l-0.38-1.01l-0.44-0.4l-0.46-0.52l-0.03-0.56l0.21-0.52l1.12-1.12
  		l0.01-0.27l-0.28,0.02l-0.32,0.02l-1.52,0.41l-0.16-0.26l-0.55-0.15l-1.11-0.03l-1.3,0.07l-1.65,0.4l-1.51,0.7l-0.69,0.48
  		l-0.59,0.3l-0.47,0.1l-0.55-0.21l0.03-0.69l1.03-1.26l0.15-0.87l-0.32-0.75l-0.03-0.6l-0.36-0.38l-0.51-0.19l-0.27-0.43l-0.02-1.24
  		l0.45-1.28l0.74-0.4l0.45-0.19l0.12-0.25l-0.23-0.9l0.04-0.57l0.44-1.1l0.44-0.84l0.83,0.14l0.37-0.21l0.41-0.3l0.45-0.5l0.3-0.6
  		l0.36-1.39l0.25-0.21l1.04,0.23l0.31-0.16l0.57-0.82l0.54-0.99l0.78-0.31l0.56-0.03l0.26-0.33l-0.01-0.49l-0.54-0.77l-0.23-0.62
  		l0.07-0.37l0.77-0.23l0.19-0.45l-0.11-0.98l0.32-1.18l0.19-1.38l0.06-1.03l-0.01-0.79l-0.08-0.83l-0.11-1.51l-0.35-1.31l0.06-0.49
  		l-0.05-1.6l-0.22-1.36l-0.43-0.24l-0.8-0.44l-0.44-0.09l-0.37,0.16l-0.14,0.47l-0.35,0.44l-0.44-0.11l-0.21-0.44l-0.28-0.58
  		l-0.93-2.76l-0.1-0.79l-0.16-0.79l-0.31-0.4l-0.33-0.23l-0.74-0.93l-0.39-0.39l-0.19-0.06l-0.41,0.07l-0.4,0l-0.33-0.46l-0.3-0.57
  		l-0.3-0.28l-0.5-0.15l-0.52,0.06l-0.3,0.49l-0.21,0.28l-0.39,0.65l-0.63,0.9l-0.29,0.33l-0.1,0.57l-0.06,0.63l0.31,0.5l0.04,0.44
  		l-0.07,0.4l-0.24,0.28l-0.67,0.15l-0.58,0.48l-0.91,0.7l-0.73,0.56l-0.53,0.49l-0.19,0.52l0.06,0.65l0.15,0.68l1.7,2.49l-0.01,0.43
  		l-0.54,0.12l-0.71-0.28l-0.62-0.4l-0.69-1.07l-0.56-0.32l-0.55-0.07l-3.3,0.55l-0.7,0.15l-1.09,0.4l-0.76,0.8l-0.55,0.73l-0.57,0.3
  		l-0.83,0.57l-2.31,1.75l-1.34,0.75l-0.87,0.28l-0.61,0.4l-0.33,0.65l-0.25,0.46l-0.07,1.14l0.15,1.4l0.33,0.89l0.18,0.19l0.03,0.26
  		l-0.33,0.48l-0.44,0.41l-0.21,0.25l-0.2,1.28l-0.39,0.63l-0.71,0.92l-0.63,0.7l-0.99,0.54l-0.38,0.44l-0.36,0.81l-0.21,0.68
  		l-0.01,0.25l0.11,0.22l0.41,0.24l0.5,0.23l0.24,0.36l0.03,0.44l-0.37,1.05l-0.67,1.46l-0.77,1.15l-0.79,1.1l-0.19,0.36l-0.71,1.35
  		l-0.62,1.85l-0.42,1.25l-0.36,0.85l-0.45,0.04l-0.54-0.27l-1.31-0.36l-0.84-0.29l-0.61-0.26l-0.42,0.01l-0.58,0.17l-0.59,0.12
  		l-0.35-0.13l-0.81-0.77l-0.25,0.09l-0.16,0.38l0.55,2.2l0.36,0.82l-0.06,1.36l-0.14,1.67l-0.21,1.83l-0.14,0.42l-0.35,0.48
  		l-0.46,0.13l-0.42-0.19l-0.34,0.04l-0.11,0.31l0.1,0.79l-0.41,0.9l-0.3,0.77l0.08,0.75l0.14,0.76l0.48,1.65l0,0.6l-0.09,0.54
  		l-0.25,0.22l-0.43-0.04l-0.23,0.22l-0.18,0.34l-0.48,1.25l-0.46,0.13l-0.46-0.19l-0.7-0.83l-0.42-0.24l-0.27,0.01l-0.12,0.22
  		l-0.19,0.5l-0.19,0.33l-0.47,0.29l0.07,0.33l0.01,2.91l0.08,1.24l0.27,1.02l0.05,0.39l-0.27,0.32l-0.26,0.06l-0.25-0.49l-0.57-0.38
  		l-0.84-0.4l-0.35-0.27l-0.43,0.11l-0.6,0.61l-0.24,0.57l0.1,0.8l0.19,0.78l0.42,0.45l0.03,0.5l0.15,0.64l0.22,0.59l0.1,0.63
  		l0.46,0.65l1.93,2.16l0.97,1.52l0.08-1.38l-0.2-0.97l0.22,0.01l0.43,0.82l0.54,0.47l0.34,0.65l-0.16,0.34l-0.24,0.17l-0.44,0.62
  		l0.31,0.29l0.45,0.22l0.91-1.18l0.24-0.51l0.14-0.52l0.26,0.25l-0.44,1.68l0.45-0.03l0.23,0.06l-0.46,0.45l-0.32,0.48l1.07,1.89
  		l0.51,0.06l0.07-0.41l-0.36-1.24l0.79-0.26l0.95,0.1l0.4,0.17l0.25,0.25l1.2,0.87l1.05-0.02l-0.4,0.51l-0.41,0.68l0.48,0.38
  		l0.6,0.3l0.18,0.43l0.07,0.33l0.59,0.66l0.41,0.26l0.61,0.19l0.32,0.35l-0.3,0.97l-0.64,0.5l-0.52,0.29l-0.82-0.24l-1.26-1.17
  		l-0.41,0.03l-0.32,0.24l0.89,1.57l0.9,1.4l0.8,0.58l1.09,0.53l0.27-0.51l0.06-0.32l-0.23-1.31l0.51-0.31l0.18-0.76l0.19-0.16
  		l-0.05,1.23l0.26,2.19l0.18,0.38l1.3,0.94l-0.63,0.08l0.69,1.07l0.11,0.88l1.39,2.65l0.54,3.26l0.55,1.09l0.24,1.12l-0.21,2.25
  		l-0.59,1.22l-0.18,0.27l-0.17,1.56l-0.44,0.74l-0.39,1.96l-0.22,0.58l-0.47,0.47l-0.48,3.36l0.08,0.95l0.63,0.08l1.2-0.74
  		l0.46-0.79l0.77-0.88l0.42-0.17l0.23-0.23l0.12-0.77l0.2-0.74l0.11,0.51l0.03,0.46l-0.36,1.35l0.04,0.93l-0.25,0.59l0.04,0.65
  		l0.04,0.72l1.15-0.57l0.42-0.53l0.1-0.6l0.28-0.69l0.04,0.36l-0.13,0.45l0.08,0.43l-0.01,0.51l0.43,1.38l1.14-0.18l0.83,0.12
  		l0.41-0.44l0.9-1.34l0.12-0.55l0.15,0.96l-0.48,0.9l-0.05,1.07l0.4,0.23l0.35,0.06l1.4-0.99l1.04-1.19l0.27-0.83l0.44-0.82
  		l2.1-0.73l0.17-0.27l0.09-0.48l0.32-0.12l0.46,0.09l1.33-0.58l0.26-0.3l-0.74-1.06l0.01-0.55l-0.41-1.18l0.26-0.11l0.24,0.13
  		l0.11,0.91l0.17,0.54l0.69,0.65l0.56,0.13l0.64-0.08l0.98-0.43l1.2-1.26l0.38-0.59l0.4-1.23l0.43-0.95l-0.49-1.48l0.5-0.88
  		l-0.27-0.32l-0.22-0.51l1,0.72l0.88,0.95l0.22,0.37l0.84,0.6l-0.18,0.29l0.28,1.46l0.47,1.29l0.54,0.61l0.38,1.28l1.09-0.02
  		l0.96-0.1l0.41-0.12l0.48-0.15l-0.52,0.41l-0.26,0.54l0.06,1.75l-0.26,0.95l-0.21,1.07l1.06,1.24l0.54,3.81l-0.26,1.06l0.47,1.04
  		l0.11,2.79l0.48,1.03l0.57-0.22l0.69,0.95l-0.75-0.37l-0.16,0.37l0.56,1.63l0.18,1.08l0.86,1.48l-0.23,1.49l0.22,0.75l0.1,1.58
  		l0.33,0.56l0.43-2.84l0.32,0.39l0.08,0.8l-0.03,0.91l1.48,2.13l0.55,1.62l0.75,1.12l0.16,1.5l0.35,1.17l-0.01,0.66l0.23,0.92
  		l0.01,1.04l-0.34,0.84l0.46,0.78l-0.5,0.41l-0.16,0.46l0.25,0.17l0.55,0l-0.27,0.84l0.19,0.68l0.03,0.77l-0.37,0.39l-0.05,0.35
  		l-0.12,0.57l0.1,0.54l0.48,0.17l0.94-0.52l0.3,0.34l0.3,0.17l-0.72,0.46l-0.42,0.62l0.04,0.6l-0.11,0.73l-0.03,0.89l0.12,0.76
  		l-0.52,0.91l-0.06,1.24l-0.65,1.04l-0.53,1.05l-0.29,0.18l-0.3,0.36l0.49,2.73l-0.22,1.44l0.21,0.64l0.35,0.61l0.8-1.22l0.38-0.1
  		l0.13-0.64l0.24-0.72l0.18-0.68l0.06-1.07l-0.15-0.56v-0.32l0.25-0.4l0.84-0.68l1.17-1.11l1.39-1.57l1.42-2.41l0.71-1.4l0.3-0.49
  		l0.31-0.15l0.49-0.48l0.33-0.53l0.02-0.27l-0.77-2.62l-0.51-0.85l-0.26-1.01l-0.14-0.73l-0.19-0.67l0.09-0.63l-0.28-0.39l-0.63-0.5
  		l-0.65-0.74l-0.39-1.21v-0.68l-0.42-0.59l-0.14-0.62l0.25-0.59l0.33-0.51l-0.04-2.25l-0.13-0.67l-0.16-1.2l-0.43-0.9l-0.6-1.06
  		l-0.68-0.88l-1.79-1.59l-1.27-1.06l-0.63-0.97l-0.8-1.11l-0.57-0.8l-0.73-1.01l-0.37-1.38l-0.2-1.47l0.11-0.49l0.35-0.32l0.45-0.26
  		l0.36-0.06l1.03-0.68l0.72,0.06l0.17-0.15l0.07-0.31l-0.09-1.34l0.03-1.79l0.13-1.46l0.16-0.96l1.9-1.12l0.4-0.48l0.19-0.52l0-0.46
  		l-0.16-0.36l-0.29-0.2l-1.23,0.96l-0.24-0.21l-0.56-1.59l-0.24-0.48l-0.35-0.58l-0.38-0.81l-0.06-1.4l-0.27-0.69l-1.54-1.48
  		l-0.69-0.8l-0.94-1.15l-1.13-1.38l-1.15-1.28l-0.54-0.82l-0.19-0.18l-0.07-0.31l0.35-0.88l-0.17-0.88l-0.57-1.18l-0.24-0.73
  		l0.08-0.28l-0.28-0.11l-0.64,0.06l-0.61-0.55l-0.59-1.17l-0.06-0.21l0.19,0l0.74,0.2l0.25-0.03l0.52-0.27l0.79-0.28l0.47-0.09
  		l0.15-0.14l0.07-0.28l-0.4-2.7l0.07-0.56l0.76-1.16l-0.09-1.18l0.2-1.69l0.84-1.16l0.63-0.53l0.2-0.83l0.29-0.18l0.52,0.07
  		l1.08,0.62l0.46,0.03l0.65-0.02l0.71-0.05l0.33-0.06l2.25-0.61l0.5-0.06l0.47,0.08l0.34-0.03l0.35-0.28l0.25-0.49l0.28-1.57
  		l0.16-0.28l0.29-0.22l0.48-0.15l0.56,0.01l0.73,0.3l0.46,0.01l0.52-0.13l0.44-0.22l0.29-0.28l0.13-0.33l-0.12-0.64l-0.33-0.8
  		l0.09-0.1l0.61,0.18l0.9,0.19l0.69-0.04l0.45-0.14l0.44-0.38l0.55-0.35l0.54,0.08l0.42,0.31l1,0.55l0.06-0.48l0.46-1.89l0.55-1.23
  		l0.65-0.57l0.69-0.24l0.72,0.09l0.6-0.11l0.49-0.3l-0.04-0.17l-0.58-0.04l-0.23-0.33l0.12-0.62l0.26-0.4l0.4-0.19l0.38-0.62
  		l0.37-1.06l0.45-0.54l0.53-0.01l0.91-0.46l1.29-0.9l0.49-0.87l0.07-0.12L2661.45,1134.13z M2636.51,1226.41l-0.21,0.15l-0.52,0.65
  		l0.63-0.1l0.53,0.51l0.28,0.79l0.17,0.04l0.11-0.11l0.08-0.22l-0.7-1.46L2636.51,1226.41z M2639.77,1219.93l-0.25-0.51l-1.24,0.11
  		l0.19,0.65l-0.02,0.27l0.32,0.99l0.25,0l0.54,0.24L2639.77,1219.93z M2599.36,1154.91l0.5,0.23l0.73-0.29l0.27-0.29l0.11-0.37
  		l-0.01-0.18l-0.6-0.46l-0.38,0.05l-0.97-0.72l-0.22,0.12l-0.37,0.43l0.15,0.31l0.59,0.37L2599.36,1154.91z M2639.13,1226.42
  		l0.17,0.13l0.18,0.04l0.19-0.02l-0.13-1.06L2639.13,1226.42z M2637.45,1219.82l-0.33,0.96l-0.32,0.66l-0.12,0.8l0.19,0.13
  		l0.06,0.01l0.05-0.19l0.35-0.38l0.17-0.6l0.2-1.09l-0.07-0.51L2637.45,1219.82z M2637.76,1208.42l-0.14-0.45l-0.25-0.32l-0.03-0.11
  		l-0.09,0.12l-0.03,0.72l0.07,0.77l0.42,0.67L2637.76,1208.42z M2639.1,1218.62l0.35-0.05l0.51-0.17l0.28-0.27l-0.71-0.41l-0.5-0.67
  		l-0.1,0.2L2639.1,1218.62z M2638.97,1213.74l0.07-0.83l-0.27,0.01l-0.19-0.23l-0.15-0.42l-0.71-0.26l-0.08,0.57l0.24,0.85
  		l-0.15,1.49l0.17,0l0.39-0.15L2638.97,1213.74z"/>
    </>,
    MLI: <>
        <polygon className="st1" points="1845.36,1172.54 1845.35,1170.01 1845.34,1167.86 1845.34,1165.19 1845.33,1162.85 1845.32,1160.3
  		1845.31,1158.02 1845.31,1156.5 1842.63,1157.01 1840.73,1157.38 1838.66,1157.77 1838.34,1157.84 1837.97,1157.85
  		1837.69,1157.84 1837.12,1157.62 1836.44,1157.11 1835.98,1156.84 1835.86,1156.44 1836.13,1155.9 1836.46,1155.41
  		1836.59,1155.03 1836.82,1154.74 1837.11,1154.5 1837.12,1154.17 1836.88,1153.62 1836.67,1152.87 1836.68,1151.5 1836.68,1151.04
  		1836.68,1150.87 1836.07,1150.35 1834.91,1149.77 1833.84,1149.43 1833.35,1149.31 1832.17,1149.11 1830.54,1148.74
  		1829.97,1148.49 1828.91,1147.21 1828.39,1146.88 1825.95,1146.67 1825.13,1146.46 1824.47,1146.15 1823.9,1145.74
  		1823.58,1145.04 1823.48,1144.47 1823.27,1144.2 1820.57,1142.82 1819.88,1142.35 1819.52,1141.91 1819.51,1141.26
  		1819.58,1140.47 1819.46,1139.77 1819.35,1139.42 1818.12,1138.58 1815.36,1136.71 1812.6,1134.83 1809.84,1132.95
  		1807.09,1131.07 1804.33,1129.18 1801.57,1127.3 1798.81,1125.41 1796.05,1123.52 1793.3,1121.63 1790.54,1119.74 1787.78,1117.85
  		1785.02,1115.95 1782.26,1114.05 1779.5,1112.15 1776.75,1110.25 1773.99,1108.34 1771.66,1106.73 1769.08,1105.03
  		1766.13,1105.03 1762.19,1105.03 1759.5,1105.04 1756.75,1105.04 1754.16,1105.04 1754.39,1107.07 1754.63,1109.28
  		1754.87,1111.49 1755.11,1113.7 1755.35,1115.9 1755.59,1118.11 1755.83,1120.31 1756.07,1122.5 1756.31,1124.7 1756.55,1126.89
  		1756.79,1129.08 1757.03,1131.27 1757.27,1133.45 1757.5,1135.63 1757.74,1137.81 1757.98,1139.99 1758.22,1142.17
  		1758.46,1144.34 1758.7,1146.51 1758.94,1148.68 1759.18,1150.84 1759.42,1153.01 1759.66,1155.17 1759.9,1157.33 1760.14,1159.48
  		1760.38,1161.64 1760.62,1163.79 1760.86,1165.94 1761.1,1168.09 1761.34,1170.24 1761.58,1172.38 1761.82,1174.53
  		1762.06,1176.67 1762.29,1178.74 1763.3,1179.83 1764.56,1181.2 1764.19,1183.13 1763.75,1185.44 1763.27,1187.95 1761.49,1187.95
  		1759.77,1187.95 1758.06,1187.95 1756.34,1187.95 1754.62,1187.95 1752.9,1187.95 1751.19,1187.95 1749.47,1187.95
  		1747.75,1187.95 1746.03,1187.95 1744.31,1187.95 1742.6,1187.95 1740.88,1187.95 1739.16,1187.95 1737.44,1187.95
  		1735.73,1187.95 1734.01,1187.95 1732.41,1187.95 1731.42,1187.89 1731.07,1187.7 1730.95,1186.4 1730.65,1186.48 1730.31,1186.86
  		1730.13,1187.28 1730.19,1187.82 1730.13,1188.28 1729.03,1188.46 1727.54,1188.76 1725.97,1189 1724.39,1188.91 1723.84,1188.81
  		1723.27,1188.64 1722.01,1188.45 1721.32,1188.43 1720.54,1188.48 1719.61,1188.58 1719.31,1188.82 1718.61,1189.79
  		1717.93,1190.91 1717.49,1190.91 1716.99,1190.29 1715.63,1189.13 1713.97,1187.6 1713.22,1186.84 1712.82,1186.74
  		1712.03,1187.29 1711.36,1187.81 1710.65,1188.56 1710.33,1189.27 1710.07,1190.1 1709.96,1191.09 1709.7,1192.24 1709.13,1193.17
  		1708.45,1193.87 1707.94,1194.2 1707.75,1194.38 1706.27,1193.83 1707.06,1195.21 1706.89,1195.87 1706.71,1196.83
  		1707.16,1197.53 1707.68,1197.99 1708.06,1198.4 1708.47,1198.98 1708.54,1200.13 1708.46,1200.96 1708.73,1201.34
  		1708.96,1201.81 1708.91,1202.2 1708.76,1202.55 1708.26,1203.02 1708.17,1203.88 1708.99,1204.92 1709.52,1205.48
  		1709.51,1205.81 1709.66,1206.16 1710.05,1206.58 1710.29,1206.48 1710.55,1206.14 1710.67,1205.91 1711.37,1206.01
  		1711.71,1206.12 1712.16,1206.8 1712.32,1207.25 1712.43,1207.82 1712.9,1208.53 1713.31,1209.03 1713.4,1209.34 1713.77,1209.76
  		1713.54,1210.7 1713.56,1211.18 1713.31,1212.43 1713.26,1213.03 1713.27,1213.25 1713.83,1213.7 1713.77,1214.33 1713.53,1214.56
  		1713.28,1215.05 1713.06,1215.67 1712.82,1216.08 1712.91,1216.35 1713.56,1216.88 1714.48,1217.63 1714.86,1217.73
  		1715.29,1217.55 1715.97,1216.96 1716.5,1216.32 1717.02,1216 1717.62,1216.02 1718.1,1216.48 1718.68,1217.47 1719.22,1218.38
  		1719.29,1218.47 1719.5,1218.63 1719.77,1218.62 1720.05,1218.4 1720.26,1218.26 1720.51,1217.85 1721.55,1216.59 1722.34,1216.24
  		1722.61,1216.15 1723.16,1215.96 1724.07,1216.26 1725.39,1216.78 1726.99,1217.41 1727.55,1217.51 1727.88,1217.41
  		1728.35,1216.55 1728.95,1216.22 1729.8,1215.82 1730.49,1215.62 1730.88,1215.6 1731.03,1215.36 1731.11,1215.02 1731.03,1214.66
  		1730.58,1214.01 1730.57,1213.82 1730.82,1213.7 1731.37,1213.6 1732.08,1213.66 1732.88,1213.94 1733.54,1214.35 1733.91,1214.83
  		1734.31,1215.85 1734.62,1216.84 1735.43,1218.42 1735.41,1219.4 1735.4,1220.54 1735.75,1220.75 1736.15,1220.84 1736.33,1221.01
  		1736.72,1221.88 1737.09,1222.13 1737.53,1222.19 1738.36,1222.75 1738.89,1222.97 1738.96,1223.14 1738.95,1223.37
  		1738.74,1223.66 1738.42,1223.87 1737.94,1224.25 1737.54,1224.74 1736.73,1225.94 1736.71,1226.16 1736.88,1226.32
  		1737.22,1226.35 1737.57,1226.27 1738.32,1225.83 1738.91,1225.99 1739.48,1226.32 1739.69,1226.67 1739.74,1227.12
  		1739.61,1227.71 1739.59,1228.36 1739.78,1229.48 1740.08,1230.59 1740.37,1231 1742.26,1231.98 1742.44,1232.35 1742.54,1232.76
  		1742.4,1233.33 1742.65,1233.32 1743.3,1233.13 1743.89,1232.7 1744.43,1231.81 1745.18,1231.09 1746.01,1231.14 1746.26,1231.01
  		1746.55,1230.99 1746.9,1231.46 1747.25,1231.82 1747.5,1231.83 1747.68,1232.51 1749.21,1232.8 1749.86,1232.98 1750.41,1233.48
  		1750.6,1233.49 1750.83,1233.39 1751.01,1233.21 1751.05,1233.02 1750.81,1232.57 1750.92,1232.17 1751.16,1231.81
  		1751.55,1231.79 1752.14,1231.69 1752.82,1231.68 1753.33,1231.75 1753.53,1231.39 1753.34,1230.37 1753.39,1229.82
  		1753.47,1229.34 1753.66,1229.15 1754.41,1229.74 1755.1,1229.96 1755.6,1229.98 1755.73,1229.86 1755.52,1229.22 1755.58,1229.02
  		1755.76,1228.91 1756.09,1228.84 1756.97,1228.58 1757.06,1228.63 1757.22,1229.65 1757.15,1229.98 1757.33,1230.68
  		1757.56,1231.32 1757.54,1231.58 1757.36,1231.98 1757.13,1232.34 1757.16,1232.49 1757.51,1232.74 1758.18,1233 1758.88,1233.06
  		1759.26,1232.68 1759.67,1232.38 1759.94,1232.11 1760.04,1231.7 1760.48,1231.41 1761.74,1231.04 1762.9,1230.98 1763.18,1231.1
  		1763.32,1230.62 1763.55,1229.93 1763.58,1229.26 1763.74,1228.18 1763.64,1226.83 1763.46,1225.89 1764.02,1225.49
  		1764.66,1225.14 1765.06,1224.5 1765.48,1223.06 1765.66,1221.82 1765.53,1221.36 1765.31,1220.99 1765.14,1220.45 1765.05,1219.8
  		1765.16,1219.23 1765.65,1218.7 1766.26,1218.26 1766.7,1218.04 1767.85,1217.82 1769.29,1217.49 1770.12,1217.12 1770.73,1216.75
  		1771.07,1216.45 1771.41,1215.84 1771.97,1215.37 1772.4,1214.9 1772.46,1213.58 1772.46,1212.83 1772.14,1212.41 1771.96,1212.06
  		1774.1,1211.02 1774.11,1210.29 1773.82,1209.48 1773.4,1208.82 1773.24,1208.25 1773.83,1207.59 1774.36,1207.09 1774.74,1206.66
  		1775.58,1206.01 1776.45,1205.84 1777.25,1206.09 1779.58,1207.61 1779.99,1207.71 1780.48,1207.6 1781.09,1207.19
  		1781.89,1206.88 1782.19,1205.85 1782.16,1204.35 1782.34,1203.66 1782.76,1203.54 1784.11,1203.82 1784.46,1203.84
  		1784.85,1203.74 1785.13,1203.48 1785.12,1202.99 1785.06,1202.56 1785.5,1201.17 1786.3,1200.12 1787.91,1198.8 1788.42,1198.54
  		1789.01,1198.41 1791.9,1199.31 1792.38,1199.08 1793.08,1196.85 1793.87,1196.63 1794.81,1196.6 1795.42,1196.4 1795.74,1196.25
  		1797.12,1195.4 1799.55,1194.24 1800.86,1193.74 1801.12,1193.56 1802.05,1192.74 1803.3,1191.79 1804.09,1191.6 1805.18,1191.53
  		1805.87,1191.69 1806.06,1191.96 1806.29,1192.09 1807.71,1191.69 1809.76,1192.33 1811.53,1192.96 1811.63,1192.51
  		1812.11,1192.37 1813.35,1192.38 1815.75,1192.59 1817.68,1192.35 1817.79,1192.31 1819.15,1191.12 1820.65,1189.87
  		1822.92,1189.75 1825.36,1189.62 1827.29,1189.55 1830.09,1189.46 1832.35,1189.38 1834.98,1189.28 1835.06,1188.7
  		1835.22,1188.56 1835.48,1188.54 1837.4,1188.85 1839.22,1189.15 1839.35,1188.06 1840.94,1186.7 1841.85,1186.43 1842.07,1186.19
  		1842.35,1185.73 1842.53,1185.02 1842.61,1184.51 1842.95,1184.1 1843.19,1183.32 1843.52,1181.97 1844.41,1180.56
  		1844.93,1178.63 1845,1176.77 1845.1,1175.35 1845.37,1175.06 	"/>
    </>,
    MKD: <>
        <polygon className="st1" points="2003.45,947.24 2003.35,947.07 2002.95,946.89 2002.65,946.28 2002.04,944.68 2001.7,944.36
  		2000.74,944.02 1999.9,943.56 1999.19,942.95 1997.89,941.45 1997.67,941.37 1997.33,941.08 1997.02,941 1996.23,941.34
  		1995.43,941.54 1994.81,941.39 1994.19,941.37 1993.76,941.5 1993.43,941.56 1992.8,941.92 1991.78,942.17 1991.31,942.12
  		1991.3,942.12 1989.85,942.4 1989.37,942.73 1989.08,943.31 1988.99,943.61 1988.73,943.62 1988.31,943.32 1987.77,942.85
  		1987.08,942.89 1984.7,943.9 1984.47,944.43 1984.42,945.58 1984.26,945.89 1984,946.09 1983.02,945.97 1982.92,945.89
  		1982.81,946.01 1982.41,947.58 1982.5,948.37 1982.5,948.9 1982.16,949.11 1981.93,949.44 1982.3,950.74 1982.25,951.3
  		1982.27,951.93 1982.93,953.38 1983.33,953.83 1983.68,954.04 1984.12,955.37 1984.39,955.61 1985.48,955.48 1986.01,955.63
  		1986.22,955.94 1986.27,956.16 1987.42,956.1 1987.82,956.03 1989.3,955.99 1989.98,955.59 1990.44,955.62 1991.42,955.97
  		1991.86,955.69 1993.14,955.15 1994.4,953.59 1994.94,953.35 1996.17,953.25 1996.55,953.07 1997,953.11 1998.37,953.43
  		1999.15,953.47 2000.08,953.26 2001.1,952.87 2001.36,951.53 2001.6,951.34 2002.23,951.28 2002.71,951.29 2002.83,951.09
  		2003.01,948.59 2003.47,947.47 	"/>
    </>,
    MHL: <>
        <path className="st0" d="M3230.4,1257.16l-0.04,0.12l0.37,0.16l0.81,0.08l0.12-0.13l-0.63-0.11L3230.4,1257.16z M3215.07,1224.84
  		l-0.12,0.11l0.17,0.06l0.22-0.06l0.08-0.1l-0.1-0.03L3215.07,1224.84z M3238.59,1268.85l-0.2,0.75l-0.13,0.26l-0.18,0.19l0.21,0.02
  		l0.17-0.25l0.32-0.88l0.45-0.34l0.07-0.32l-0.29,0.31L3238.59,1268.85z M3255.74,1259.75l-0.62,0.01l-0.31-0.18l0.13,0.27
  		l0.18,0.08l0.38-0.02l0.29,0.08l0.31,0.2l0.22,0.03L3255.74,1259.75z M3250.81,1258.82l-0.43-0.28l-0.12,0.13l0.5,0.39l0.9,0.3
  		l0.28,0.04h0.24l0.35-0.1l0.52-0.12l0.23-0.13l-1.4,0.2L3250.81,1258.82z"/>
    </>,
    MDG: <>
        <path className="st0" d="M2229.61,1461.69l-0.27,1.32l0.95-1.58l0.73-1.79l-0.31,0.15L2229.61,1461.69z M2216.87,1431.43l-0.01-0.31
  		l0.07-0.46l-0.36-0.95l-0.33,0.05l-0.12,0.44l-0.54,0.03l0.17,1.07L2216.87,1431.43z M2234.54,1446.37l-1.08-1.82l-0.66-1.75
  		l-0.26-1.86l-0.26-4.05l-0.85-3.94l-0.89-2.62l-0.25-1.69l-0.51-0.84l-0.6-0.79l-1.41-2.06l-0.45-0.85l-0.39-0.89l-0.49-0.71
  		l-0.98-0.95l-0.28-0.4l-0.15-0.55l-0.41-0.37l-0.48,0l-1.44,2.01l-0.88,1.05l-0.27,0.16l-0.81-0.16l-0.15,0.26l0.57,1.18l0.34,0.95
  		l0.21,1l-0.08,0.82l-0.96,2.82l-1.47,1.35l-0.97,0.36l-0.85,0.59l-0.57,0.86l-0.69,0.69l-0.57-0.11l-0.85-0.72l-0.39-0.22
  		l-0.49,0.16l-0.34,0.41l-0.38,0.58l-0.1,0.66l0.15,0.43l0.79,0.87l-0.1,0.21l-0.22,0.17l-0.02,0.54l-1.53,2.58l0.32,1.49l1.29,1.09
  		l-0.79-0.23l-0.81-0.08l-0.49,0.37l-0.39,0.54l-0.65,1.03l-0.57,1.09l-0.39,0.15l-0.41-0.12l0.11-0.6l0.27-0.45l0.19-0.46
  		l-0.1-0.46l-0.17-0.44l-0.22-0.09l-0.73,0.54l-0.28,0.48l-0.32,1.03l-0.7,0.87l-0.89,0.91l0.01,0.38l0.11,0.42l0.23,0.49
  		l-0.01,0.52l-0.29,0.62l-0.33,0.19l-0.28-0.04l0.04-0.25l-0.33-1.54l-0.43-0.21l-0.51,0.09l-1.75,1.3l-1.68,1.13l-0.76,0.74
  		l-0.45,0.97l-0.04,0.45l0.13,0.4l0.84,0.71l-0.35,0.25l-0.41-0.06l-0.32-0.12l-1.04-1.35l-0.28-0.07l-1.29,0.38l-1,0.15l-1.56,0.12
  		l-0.33,0.22l-0.18,0.38l-0.13,0.54l-0.22,0.4l-0.48-0.07l-0.47,0.01l-1.21,0.44l-0.34-0.23l-0.26-0.41l-0.41-0.1l-0.47,0.28
  		l-1.03,0.96l-0.75,0.5l-0.39,0.18l-3.01,0.26l-0.64,0.11l-0.28,0.23l-0.13,0.39l-0.08,1.05l0.15,1.81l-0.12,0.7l-3.72,5.94
  		l-0.3,1.64l0.42,0.94l0.17,0.99l-0.06,1.11l0.28,3.07l0.58,1.86l0.59,1l0.46,1.06l0.11,1.06l-0.1,1.47l0.04,0.37l1.24,1.98
  		l0.52,1.09l0.03,1.06l-0.17,1.07l-0.23,2.16l-0.2,0.99l-0.28,0.96l-0.91,2.04l-1.03,1.45l-0.46,0.96l-1.28,1.83l-0.47,1.85
  		l-0.47,0.9l-0.81,0.66l-1.02,0.32l-0.68,0.56l-0.54,2.54l-0.23,0.44l-0.34,0.37l-0.23,0.46l-0.09,0.53l-0.35,0.71l-0.2,1.03
  		l-0.08,2l0.06,0.94l0.55,2.72l0.24,0.87l0.34,0.84l1.45,1.71l0.38,0.95l0.19,1.05l0.51,1.01l0.2,0.96l-0.48,0.89l-0.16,0.99
  		l0.14,2.1l-0.04,1.15l0.11,1.7l0.15,0.51l1.38,1.6l0.49,0.91l0.29,1.3l0.39,0.68l0.16,0.61l0.23,0.57l0.36,0.26l1.5,0.82l0.76,0.97
  		l0.51,0.24l0.57,0.16l1.87,0.26l0.99,0.31l2.55,1.87l0.76,0.25l2.55-0.07l0.81-0.31l0.74-0.54l1.93-1.14l2-0.99l1.92-0.51
  		l1.98-0.02l0.9-0.18l1.77-0.9l0.81-0.62l1.2-1.71l0.8-1.98l0.33-1.08l0.18-1.12l0.33-0.88l0.46-0.83l1.1-2.22l0.26-1.05l0.13-1.09
  		l1.14-3.54l0.54-2.14l0.46-2.15l0.42-2.48l0.22-0.63l2.03-4.84l1.47-4.33l0.99-3.93l1.17-3.87l0.85-2.18l0.75-2.21l1.02-3.68
  		l1.2-3.57l1.21-2.84l0.79-2.15l0.56-1.8l0.97-3.79l0.13-1.98l-0.48-2.79l0.1-0.91l0.76-1.79l0.47-0.88l0.35-0.33l0.87-0.37
  		l0.23-0.29l-0.24-0.49l-0.04-0.48l0.65-0.86l0.23-1l-0.07-1.98l-0.38-0.83l-0.37-0.32l-0.25-0.38l-0.02-1.27l-0.11-1.01l-0.25-0.99
  		l-0.14-1.1l0.12-0.39l0.67-0.62l0.92-0.09l0.33,0.16l0.29,0.99l0.79,1.96l0.62,0.83l0.76,0.51l0.2,0.02l0.45-0.51l0.25-0.37
  		l0.95-1.97l0.66-2.09L2234.54,1446.37z"/>
    </>,
    MF: <>
        <polygon className="st1"
                 points="1278.88,1165.42 1278.55,1165.4 1278.12,1165.62 1278.05,1165.8 1278.99,1165.8 1279,1165.5 	"/>
    </>,
    MNE: <>
        <polygon className="st1" points="1981.01,935.57 1980.41,935.14 1979.56,934.8 1977.68,933.65 1976.96,933.5 1976.31,933.37
  		1975.38,932.81 1974.9,932.72 1974.37,932.32 1973.22,930.99 1972.24,930.26 1971.57,929.89 1971.38,929.53 1971.34,929.16
  		1971.37,929.04 1971.11,929.02 1970.68,929.1 1970.4,929.2 1969.97,929.05 1969.51,928.95 1969.31,929.11 1969.23,929.41
  		1969.51,929.97 1970.04,930.84 1969.95,931.5 1969.55,931.57 1969.18,931.02 1968.85,930.93 1968.47,930.95 1967.62,931.59
  		1966.98,932.13 1966.84,932.51 1966.61,932.92 1966.54,933.21 1966.56,934.2 1965.42,934.36 1965.18,934.51 1965.04,934.81
  		1965.14,936.07 1965.23,936.75 1965.88,937.8 1965.9,938.13 1965.81,938.35 1965.35,938.76 1965.13,938.91 1964.98,938.96
  		1965,939.33 1965.32,939.76 1965.66,940.24 1965.97,940.29 1966.29,940.13 1966.75,940.14 1966.64,940.34 1966.52,940.59
  		1966.64,940.8 1968.84,942.1 1970.76,944.01 1971.3,945.14 1972.61,945.94 1972.64,945.44 1972.77,944.64 1972.69,944.38
  		1972.77,943.92 1972.6,943.52 1971.55,943.02 1970.56,941.82 1971.21,941.82 1972.2,941.95 1972.75,941.71 1973.09,941.17
  		1973.65,940.42 1974.31,939.65 1974.76,938.9 1975.24,938.26 1975.65,938.06 1975.86,938.2 1975.97,938.48 1975.94,939.31
  		1976.08,939.6 1976.37,939.81 1976.97,939.7 1977.64,939.5 1978.54,939.06 1978.69,939.08 1978.74,938.99 1978.9,938.23
  		1978.7,937.68 1978.4,937.21 1978.61,936.93 1979.25,936.93 1979.77,936.98 1979.96,936.53 1981.05,936.24 1981.08,935.98 	"/>
    </>,
    MDA: <>
        <polygon className="st1" points="2061.74,897.79 2061.83,896.88 2061.89,895.84 2061.69,895.22 2061.35,894.73 2060.02,894.15
  		2058.99,893.56 2058.77,893.29 2058.74,892.95 2058.7,892.41 2058.29,891.94 2058.26,891.55 2058.52,890.94 2058.58,890.29
  		2058.49,890.03 2058.25,889.82 2057.79,889.8 2057.18,889.42 2056.76,888.92 2055.64,888.18 2055.3,888.06 2055.09,887.7
  		2054.99,887.27 2055.22,886.73 2055.52,885.9 2055.73,885.12 2055.73,884.65 2055.59,883.5 2055.01,882.62 2054.74,882.5
  		2054.26,882.75 2053.73,882.96 2053.31,882.76 2052.82,882.29 2052.05,880.96 2050.6,880.69 2050,880.63 2049.43,881.27
  		2049.25,881.07 2049.09,880.66 2048.79,880.5 2048.4,880.69 2048.28,880.51 2048.46,879.95 2047.98,879.68 2046.87,879.68
  		2046.28,879.48 2046.21,879.06 2045.85,878.79 2045.22,878.65 2044.61,878.31 2044.02,877.76 2043.13,877.4 2041.94,877.24
  		2041.84,877.18 2041.73,877.1 2040.97,877.47 2040.51,877.77 2039.95,877.58 2039.03,878.25 2037.18,878.28 2036.27,878.24
  		2035.82,878.08 2034.08,879.08 2033.9,879.45 2034.7,879.41 2035.32,879.49 2036.27,879.97 2036.95,880.57 2037.21,881.05
  		2037.79,881.73 2038.39,882.68 2039.05,883.94 2039.2,884.57 2039.45,885.26 2039.95,886.1 2040.89,887.02 2041.02,887.2
  		2041.45,887.85 2042.28,889.29 2042.97,889.87 2043.58,890.49 2043.87,891.12 2044.3,891.7 2045.31,892.45 2046.14,893.14
  		2046.79,895.12 2047.25,896.02 2047.55,896.72 2047.41,898.12 2047.59,898.72 2047.21,899.81 2046.53,902.01 2046.37,903.76
  		2046.49,904.7 2046.5,905.3 2046.67,905.68 2046.85,906.48 2046.88,907.17 2046.63,907.36 2046.29,907.53 2046.16,907.67
  		2046.47,907.98 2046.9,908.56 2047.32,909.22 2047.76,908.87 2048.15,908.72 2049.5,908.63 2049.73,908.52 2049.76,908.27
  		2049.86,907.95 2049.82,907.47 2049.67,906.97 2050.27,906.24 2051.15,905.63 2051.67,905.02 2051.75,904.13 2052.69,903.69
  		2053.51,902.94 2053.72,902.13 2054.01,901.61 2053.48,900.43 2053.37,899.66 2053.34,899.01 2053.6,898.64 2054.37,898.24
  		2055.19,897.92 2055.52,897.95 2055.64,898.15 2055.68,899.48 2055.84,899.5 2056.1,899.34 2056.52,898.56 2056.81,898.78
  		2057.26,898.87 2057.6,898.67 2057.82,898.69 2058.09,898.89 2058.63,899.18 2059.13,899.27 2059.55,899.08 2059.91,898.75
  		2060.29,898.86 2061.01,899.78 2061.35,899.68 2063.01,899.49 2063.28,899.24 2063.48,899.02 	"/>
    </>,
    MCO: <>
        <polygon className="st1"
                 points="1872.34,926.7 1872.15,926.6 1871.99,926.66 1871.86,926.79 1871.84,927.01 1872.35,926.81 	"/>
    </>,
    MAR: <>
        <polygon className="st1" points="1799.91,1037.58 1800.73,1036.93 1800.34,1036.15 1799.69,1035.62 1798.78,1035 1798.31,1034.74
  		1797.49,1033.98 1796.98,1033.11 1796.64,1031.27 1796.01,1030.24 1795.56,1028.97 1795.96,1026.64 1795.36,1025.23
  		1795.26,1024.62 1795.26,1023.9 1795.44,1022.65 1795.32,1020.9 1794.61,1019.09 1794.95,1018.47 1795.1,1018.15 1795.05,1017.87
  		1794.4,1017.3 1794.12,1016.82 1794.27,1016.37 1794.61,1015.72 1794.58,1015.45 1793.52,1014.66 1791.75,1013.37 1791.25,1012.81
  		1791.01,1012.1 1789.29,1011.92 1787.49,1012.02 1786.7,1011.81 1785.78,1011.88 1785.53,1011.45 1785.06,1010.36 1784.82,1010.09
  		1784.79,1009.64 1784.67,1009.22 1783.9,1010.07 1782.7,1010.82 1781.11,1011.08 1779.46,1010.92 1778.59,1010.43 1777.8,1010.76
  		1776.16,1010.78 1773.23,1011.56 1770.72,1011.13 1768.96,1010.42 1766.7,1008.64 1765.46,1007.24 1764.74,1005.99
  		1764.74,1004.93 1765.25,1004.48 1764.24,1004.22 1763.19,1004.87 1762.34,1005.19 1761.29,1005.31 1759.8,1005.6 1759.52,1006.6
  		1756.19,1015.22 1752.8,1021.31 1751.58,1022.85 1749.53,1024.16 1746.01,1025.95 1739.78,1028.45 1738,1029.6 1737.3,1030.21
  		1735.28,1032.71 1731.83,1035.96 1731.8,1036.77 1731.49,1039.06 1730.97,1040.49 1728.21,1043.98 1727.09,1046.64
  		1726.88,1049.92 1726.89,1051.97 1726.52,1053.17 1726.71,1053.84 1727.39,1054.23 1728.4,1055.66 1728.64,1056.53
  		1728.28,1058.77 1727.64,1060.16 1726.72,1061.52 1725.39,1063.06 1723.79,1065.45 1721.38,1068.33 1719.8,1069.48
  		1716.37,1071.53 1714.53,1073.24 1713.43,1074.55 1712.4,1075.2 1708.75,1076.84 1704.68,1077.93 1701.95,1078.21 1700.64,1078.8
  		1699.87,1080.1 1698.73,1081.14 1698.71,1081.17 1698.05,1083.14 1696.76,1085.74 1696.03,1088.21 1695.36,1089.45
  		1694.35,1090.27 1692.19,1091.66 1690.37,1092.32 1689.15,1093.39 1688.3,1093.77 1687.82,1094.59 1687.38,1096.72
  		1686.71,1097.76 1685.83,1100.1 1685.09,1101.38 1684.69,1103.03 1684.58,1106.13 1684.17,1107.49 1683.04,1109.01
  		1681.78,1109.63 1678.43,1113.24 1676.81,1114.3 1675.79,1115.26 1675.34,1116.18 1675.1,1116.81 1675.68,1116.3 1676.16,1115.66
  		1676.61,1115.28 1676.72,1115.72 1676.58,1116.11 1675.42,1117.85 1674.97,1118.97 1673.98,1120.73 1673.17,1121.87
  		1673.51,1122.45 1673.24,1123.22 1672.38,1124.19 1671.92,1126.31 1670.61,1128.61 1669.18,1129.13 1668.26,1130.14
  		1667.1,1132.42 1666.49,1136.63 1666.49,1136.63 1666.93,1136.54 1668.79,1136.19 1670.05,1136.1 1673.33,1136.1 1674.6,1135.93
  		1675.61,1135.93 1677.04,1136.01 1678.22,1136.19 1679.48,1136.36 1680.91,1136.36 1682.09,1136.45 1683.61,1136.45
  		1684.7,1136.36 1685.46,1135.93 1686.14,1135.05 1686.39,1134.35 1686.64,1133.73 1686.56,1133.12 1686.47,1132.77
  		1686.89,1132.33 1687.4,1131.62 1687.9,1131.19 1688.07,1130.83 1688.58,1130.48 1689.17,1129.86 1689.5,1129.43 1689.92,1128.82
  		1690.01,1128.28 1690.18,1127.58 1690.18,1126.35 1690.35,1124.85 1690.6,1123.88 1690.77,1123.08 1690.94,1121.85
  		1691.44,1119.73 1691.61,1119.11 1691.95,1118.14 1692.37,1117.25 1692.7,1116.62 1693.13,1116.1 1693.72,1115.74 1694.64,1115.39
  		1695.31,1115.03 1696.16,1114.68 1696.91,1114.41 1697.59,1114.05 1697.84,1113.71 1698.26,1113.08 1698.85,1111.93
  		1699.19,1111.22 1699.69,1110.32 1700.28,1109.71 1700.65,1109.47 1700.96,1109.26 1701.72,1108.81 1702.64,1108.28
  		1703.32,1107.84 1703.91,1107.39 1704.41,1107.04 1705,1106.5 1705.25,1106.06 1705.59,1105.25 1706.01,1104 1706.35,1102.67
  		1706.69,1101.24 1706.94,1100.35 1707.19,1099.27 1707.53,1098.46 1707.78,1097.57 1707.95,1097.21 1707.95,1096.76
  		1708.12,1096.13 1708.15,1096.08 1708.37,1095.77 1708.96,1095.6 1709.63,1095.41 1710.7,1095.27 1711,1095.12 1711.16,1094.59
  		1711.29,1094.13 1711.69,1093.4 1712.14,1092.81 1712.39,1092.45 1712.74,1091.83 1713.09,1091.38 1713.69,1090.81
  		1714.21,1090.36 1714.39,1089.9 1714.39,1089.36 1714.01,1088.92 1713.75,1088.11 1714.83,1087.86 1715.79,1087.59
  		1716.66,1087.33 1717.7,1086.97 1718.48,1086.97 1719.09,1086.88 1719.97,1087.05 1720.83,1087.14 1721.44,1087.41
  		1722.49,1087.95 1723.36,1088.31 1723.88,1088.31 1724.44,1088.14 1724.91,1087.88 1725.2,1087.86 1725.64,1088.05
  		1726.32,1088.41 1727.01,1088.41 1727.71,1088.31 1728.23,1087.86 1729.1,1087.14 1729.79,1086.6 1730.42,1086.26 1730.93,1086.17
  		1731.49,1086.17 1732.14,1086.16 1733.19,1086.24 1733.88,1086.24 1734.83,1086.12 1735.63,1085.97 1735.97,1085.7
  		1735.97,1085.34 1735.8,1084.8 1735.61,1084.28 1735.56,1083.8 1735.68,1083.3 1735.8,1082.9 1735.71,1082.27 1735.47,1081.52
  		1735.43,1081.13 1735.43,1081.13 1736.57,1081.13 1736.57,1078.92 1736.57,1077 1736.57,1075.08 1736.57,1073.76 1736.57,1072.38
  		1736.61,1071.75 1736.76,1071.49 1737.62,1071.04 1738.96,1070.02 1739.45,1069.56 1740.09,1069.1 1742.33,1067.72
  		1742.79,1067.33 1744.97,1065.73 1745.48,1065.5 1746.65,1065.34 1747.14,1065.04 1747.8,1064.4 1748.77,1063.67 1749.39,1063.32
  		1749.54,1063.26 1749.94,1063.21 1751.96,1063.43 1752.81,1063.59 1753.82,1063.73 1754.13,1063.63 1754.4,1063.4 1754.78,1062.89
  		1754.86,1062.29 1754.89,1061.76 1754.95,1061.52 1755.13,1061.42 1755.56,1061.46 1756.15,1061.53 1757.36,1061.51
  		1757.76,1061.43 1759.13,1061.32 1761.06,1060.97 1762.59,1060.53 1763.81,1060.17 1765.12,1059.23 1766.07,1058.25
  		1767.07,1056.77 1767.86,1055.5 1769.45,1054.69 1770.79,1054.21 1771.55,1054.02 1773.29,1053.34 1774.76,1052.32
  		1776.13,1051.36 1777.19,1051.24 1778.52,1051.08 1778.82,1050.9 1779.15,1050.56 1779.17,1049.96 1778.77,1049.54
  		1778.28,1049.31 1777.94,1049.07 1777.6,1049.02 1777.41,1048.74 1777.52,1048.2 1777.57,1047.71 1777.79,1047.22 1777.73,1046.52
  		1777.38,1045.82 1777.28,1045.32 1777.3,1044.83 1777.47,1044.44 1777.96,1044.18 1778.54,1044.08 1779.34,1044.21
  		1780.73,1044.04 1784.29,1042.84 1784.53,1042.46 1784.76,1041.63 1785.02,1040.9 1785.38,1040.65 1785.58,1040.6 1786.77,1040.4
  		1788.45,1040.13 1789.08,1040.09 1790.91,1040.17 1792.24,1040.22 1794.4,1040.32 1795.93,1040.37 1797.26,1040.41
  		1798.96,1040.47 1799.38,1040.3 1799.38,1039.76 1799.07,1038.77 1799.25,1038.15 	"/>
    </>,
    LBY: <>
        <polygon className="st1" points="2021.23,1043.97 2020.47,1042.38 2019.85,1041.73 2019.24,1041.44 2017.6,1041.15 2015.88,1041.33
  		2012.93,1041.21 2012.17,1040.95 2010.99,1040.11 2010.14,1039.82 2005.83,1039.3 2004.31,1038.21 2004.35,1037.59
  		2004.51,1037.12 2004.18,1035.53 2002.72,1034.89 2001.35,1034.39 1999.4,1033.89 1997.86,1033.09 1996.57,1032.73
  		1993.64,1032.82 1992.65,1032.5 1991.93,1032.55 1990.15,1033.84 1989.26,1034.04 1987.1,1034.06 1983.38,1035.89 1981.27,1037.28
  		1979.17,1039.26 1978.41,1040.29 1977.93,1041.3 1977.53,1042.99 1977.82,1045.42 1978.32,1046.77 1979.03,1047.79
  		1979.34,1048.76 1979.42,1049.84 1979.09,1050.9 1978.26,1052.4 1975.74,1055.28 1974.7,1055.97 1972.19,1057.13 1970.77,1057.33
  		1969.19,1057.1 1966.95,1055.95 1962.91,1052.62 1960.88,1051.93 1959.88,1051.23 1955.82,1049.81 1951.04,1048.58
  		1948.26,1048.46 1945.5,1048.12 1943.05,1047.23 1941.98,1046.62 1941.06,1045.65 1940.22,1044.48 1939.53,1042.84 1939.1,1041.56
  		1939.06,1039.81 1938.29,1038.39 1937.53,1037.65 1931.94,1036.53 1931.19,1036.17 1929.61,1034.94 1928.93,1034.68
  		1926.23,1033.91 1924.65,1033.84 1923.71,1033.61 1921.58,1032.76 1920.36,1032.92 1917.12,1033.82 1914.37,1033.56
  		1913.13,1033.29 1909.2,1031.08 1907.88,1030.85 1906.6,1030.26 1906.58,1030.5 1906.28,1032.28 1906.22,1032.92 1906.17,1034
  		1906.17,1035.31 1906.84,1036.4 1906.86,1036.89 1906.6,1037.44 1905.36,1038.08 1903.77,1038.9 1902.39,1039.69 1900.89,1040.54
  		1900.42,1041.1 1899.68,1041.52 1899.05,1041.95 1898.94,1042.36 1898.5,1043.13 1897.93,1043.75 1896.5,1044.04 1896.24,1044.22
  		1895.58,1045.15 1895.27,1045.51 1894.89,1046.27 1895.38,1048.24 1895.98,1050.27 1896.09,1051.11 1896.08,1051.81
  		1895.75,1052.57 1894.99,1053.65 1894.43,1054.44 1893.36,1055.86 1893.04,1056.21 1892.3,1056.63 1890.88,1057.18
  		1889.87,1057.67 1889.05,1058.13 1888.12,1058.72 1888.8,1059.83 1890.1,1061.65 1890.9,1063.11 1891.17,1063.74 1891.79,1065.55
  		1892.29,1067.31 1892.42,1067.88 1892.6,1069.22 1892.37,1072.93 1892.73,1077.62 1893.22,1079.96 1892.45,1082.07 1891.8,1084.07
  		1891.84,1085.08 1892.2,1086.66 1892.55,1087.82 1893.04,1088.43 1892.94,1090.38 1892.74,1091.09 1891.27,1092.12
  		1889.64,1093.05 1889.19,1093.85 1889.06,1094.73 1889.28,1095.45 1890.4,1097.03 1892.08,1099.41 1893.93,1102.03
  		1894.09,1102.68 1894.16,1104.53 1894.93,1106.86 1895.77,1107.87 1896.08,1108.63 1896.67,1109.17 1897.26,1109.57
  		1897.62,1109.62 1899.71,1108.99 1903.26,1110.03 1906.62,1111.09 1906.87,1111.3 1907.61,1112.64 1908.81,1114.84 1909.7,1116.59
  		1910.5,1118.16 1914.89,1119.19 1919.06,1120.16 1923.25,1121.14 1924.24,1121.68 1926.46,1123.6 1929.31,1126.05 1929.43,1126.09
  		1929.56,1126.1 1932.3,1124.65 1935.86,1122.77 1935.86,1122.77 1938.96,1121.32 1941.32,1120.21 1944.33,1118.8 1947.11,1120.25
  		1951.15,1122.34 1955.18,1124.42 1959.22,1126.51 1963.25,1128.59 1967.29,1130.67 1971.32,1132.75 1975.36,1134.83
  		1979.39,1136.9 1983.43,1138.98 1987.46,1141.05 1991.5,1143.11 1995.54,1145.18 1999.57,1147.24 2003.61,1149.31 2007.64,1151.37
  		2011.67,1153.42 2011.67,1152.34 2011.67,1151.25 2011.67,1150.17 2011.67,1149.08 2013.75,1149.08 2015.83,1149.08
  		2017.91,1149.08 2019.99,1149.08 2020.01,1149.07 2020.04,1149.05 2020.06,1149.04 2020.09,1149.02 2020.09,1144.67
  		2020.09,1140.32 2020.1,1135.95 2020.1,1131.58 2020.1,1129.61 2020.1,1127.63 2020.1,1125.65 2020.1,1123.67 2020.1,1121.69
  		2020.1,1119.7 2020.1,1117.72 2020.1,1115.73 2020.1,1113.73 2020.1,1111.74 2020.1,1109.74 2020.1,1107.75 2020.1,1105.75
  		2020.1,1103.74 2020.1,1101.74 2020.1,1099.73 2020.1,1097.72 2020.1,1095.71 2020.1,1093.69 2020.1,1091.68 2020.1,1089.66
  		2020.1,1087.63 2020.1,1085.61 2020.1,1083.58 2020.1,1081.55 2020.1,1079.52 2020.1,1077.48 2020.1,1075.44 2020.1,1073.4
  		2020.1,1071.36 2020.1,1069.31 2020.1,1067.26 2020.02,1066.88 2019.56,1065.49 2019.13,1063.71 2018.67,1061.53 2018.61,1060.82
  		2017.83,1058.57 2017.76,1057.93 2017.96,1057.47 2019.23,1055.57 2019.62,1054.64 2019.94,1053.53 2020.04,1052.63
  		2019.67,1051.24 2019.23,1050 2019.08,1048.72 2019.02,1047.47 2019.67,1046.61 2020.45,1045.81 2020.75,1045.31 2021.21,1044.76
  		2021.53,1044.5 	"/>
    </>,
    LVA: <>
        <polygon className="st1" points="2047.15,788.32 2046.96,787.47 2046.46,785.99 2046.4,785.57 2045.59,784.93 2045.46,784.38
  		2045.04,783.68 2044.62,783.23 2044.53,782.24 2044.26,781.89 2043.9,781.72 2042.63,782.01 2042.49,781.98 2043.1,780.39
  		2043.15,779.46 2043.53,778.49 2043.97,778.11 2044.1,777.78 2044.17,777.13 2044.09,776.58 2043.82,776.3 2042.77,775.68
  		2041.65,774.93 2041.41,773.98 2041.06,773.79 2040.07,773.74 2039.86,773.77 2038.68,773.62 2037.39,773.12 2036.82,772.76
  		2036.26,772.76 2035.59,773 2033.17,773.7 2032.58,773.54 2031.2,772.85 2030.5,772.1 2028.94,770.61 2028.81,770.25
  		2028.61,769.97 2026.95,769.6 2026.34,769.05 2025.82,768.97 2025.07,768.69 2023.13,767.51 2022.64,767.4 2022.53,767.6
  		2022.56,767.88 2022.44,768.04 2022.19,768.04 2021.74,767.6 2021.2,767.22 2019.52,767.94 2018.91,768.13 2018.37,768.17
  		2015.71,769.12 2014.89,769.63 2014.56,769.57 2014.38,770.63 2014.9,772.32 2015.24,776.2 2015.06,777.11 2014.21,778.04
  		2012.3,779.33 2011.26,780.02 2008.87,780.47 2005.84,779.04 2004.57,776.21 2003.74,775.39 2000.46,772.92 2000.19,772.24
  		1999.67,771.36 1996.95,772.05 1994.51,772.89 1992.71,773.22 1990.44,776.23 1990.12,777.28 1989.99,778.54 1989.53,779.91
  		1988.74,780.93 1987.17,782.24 1986.84,784.49 1986.7,789 1986.96,791.24 1989.22,789.84 1992.08,788.33 1992.73,788.2
  		1995.36,787.31 1995.71,787.24 1998.08,787.4 1999.94,787.61 2001.51,787.59 2002.37,787.36 2003.15,787.55 2003.78,788.22
  		2004.43,788.14 2005.07,787.71 2008.58,788.11 2009.37,788.1 2010.26,788.16 2011.91,788.56 2012.86,788.93 2014.94,788.71
  		2015.83,788.7 2016.3,788.55 2017.73,787.54 2018.35,787.36 2018.92,787.18 2019.45,787.34 2019.79,788.2 2020.85,789.7
  		2022.01,789.96 2025.2,790.53 2025.85,790.83 2027.64,792.14 2028.72,792.79 2029.41,793.3 2030.45,794.3 2031.05,795.03
  		2032.06,795.59 2033.26,795.96 2033.69,796.01 2033.91,795.87 2035.19,795.7 2035.61,795.52 2036.71,794.29 2037.55,794.09
  		2039.71,794.4 2040.71,794.38 2040.98,794.41 2041.97,794.46 2042.07,794.34 2042.52,793.12 2042.96,792.77 2044.66,791.17
  		2045.8,790.49 2046.52,790.34 2046.78,790.38 2046.99,789.81 2047.23,788.98 	"/>
    </>,
    LUX: <>
        <polygon className="st1" points="1864.34,862.74 1863.95,862.66 1862.97,862.31 1862.39,861.93 1861.96,861.46 1861.4,860.81
  		1861.16,860.15 1861.15,859.49 1861.22,859.2 1861.16,859.17 1860.99,858.83 1860.7,858.83 1860.04,858.69 1859.11,859.62
  		1858.7,860.39 1858.45,860.95 1858.08,861.41 1858.01,861.89 1858.05,862.1 1857.93,862.36 1857.92,862.63 1858.45,863.17
  		1858.58,863.46 1859.23,864.42 1859.03,864.77 1858.87,865.15 1858.68,865.42 1858.47,865.59 1858.75,865.95 1859.41,866.12
  		1859.64,866.25 1859.89,866.5 1860.33,866.6 1860.86,866.5 1861.25,866.17 1861.76,866.02 1862.28,866.07 1862.57,866.25
  		1863.14,866.52 1863.17,865.86 1863.42,864.92 1863.66,864.42 1863.98,864.01 1864.32,863.73 1864.39,863.22 	"/>
    </>,
    LTU: <>
        <path className="st0" d="M1987.31,797.01l-0.25,1.25l-0.37,0.88l-0.96,1.36l0.49,0.09l0.62-0.84l0.61-1.62l0.1-0.95l-0.01-0.57
  		L1987.31,797.01z M2035.09,800.42l-0.66-0.15l-1.57-0.14l-0.32-0.29l0.1-0.35l0.42-0.9l0.4-1.16l0.2-0.9l0.02-0.53l-0.43-0.06
  		l-1.19-0.37l-1.01-0.55l-0.6-0.73l-1.04-1l-0.69-0.51l-1.08-0.64l-1.8-1.31l-0.65-0.3l-3.19-0.57l-1.15-0.26l-1.06-1.49l-0.34-0.87
  		l-0.52-0.15l-0.57,0.18l-0.62,0.17l-1.44,1.01l-0.47,0.15l-0.89,0.01l-2.08,0.22l-0.95-0.37l-1.65-0.41l-0.89-0.06l-0.79,0.01
  		l-3.51-0.4l-0.64,0.43l-0.65,0.08l-0.63-0.67l-0.78-0.19l-0.86,0.23l-1.57,0.03l-1.86-0.21l-2.37-0.17l-0.35,0.07l-2.63,0.89
  		l-0.65,0.14l-2.85,1.51l-2.26,1.4l0.06,0.56l0.07,2.48l0.92,2.31l0.56,1.92l-0.31,1.31l0.3,0.85l-0.01,0.08l0.52,0l0.77-0.13
  		l0.49,0.48l0.91,0.46l1.08,0.41l1.61,0.7l1.67,0.43l0.55,0.05l1.76-0.06l1.86,0.06l0.51,1.04l0.92,0.49l0.74,0.67l0.06,0.38
  		l-1.02,2.4l-0.21,0.81l-0.04,0.81l0.37,1.02l0.35,0.57l0.48-0.45l0.59,0.06l0.7,0.28l0.33,0.21l0.14,0.24l0.09,0.27l0.38,0.05
  		l0.7,0.21l0.94,0.48l0.74,0.46l0.7,0.66l0.23,0.75l0.01,0.84l-0.05,0.54l0.06,0.22l0.63,0.23l1.47,0.09l1.17-0.27l0.61-0.04
  		l0.54,0.08l0.8-0.15l0.74-0.06l0.38,0.35l0.69,0.31l1.35-0.45l1.2-0.55l1.24,0.06l0.18-0.27l0.31-1.4l0.37-0.3l1.49,0.14l0.55-0.25
  		l0.57-0.69l0.88-0.43l0.73,0l0.76-0.48l0.37,0.32l0.18,0.58l-0.25,0.46l0.11,0.18l0.53,0.23l0.91-0.01l0.58-0.19l0.13-0.26l0-0.48
  		l-0.14-0.45l-0.38-0.39l-0.72-0.2l-0.5-0.01l-0.09-0.25l0.17-0.52l0.44-0.97l0.55-0.88l0.33-0.33l0.06-0.3l-0.06-0.53l-0.01-0.95
  		l0.49-1.35l0.66-1.01l0.89-0.33l1.08-0.18l0.69-0.48l0.34-0.55l0.13-0.47l0.16-0.4l0.35-0.18l2.61,0.11l0.4-0.87l0.22-0.24
  		l0.5-0.26l0.35-0.31L2035.09,800.42z"/>
    </>,
    LSO: <>
        <polygon className="st1" points="2057.08,1570.36 2056.78,1569.86 2056.49,1569.19 2056.14,1569.08 2055.45,1568.7 2054.44,1567.95
  		2053.56,1567.29 2052.74,1566.33 2052.41,1566.17 2051.61,1565.52 2051.27,1565.15 2051.03,1564.7 2050.8,1564.56 2050.44,1564.67
  		2049.51,1564.87 2047.49,1565.65 2046.24,1566.36 2045.19,1567.21 2044.1,1567.54 2043.3,1567.82 2042.67,1568.8 2042.08,1569.7
  		2041.55,1570.52 2041.24,1570.89 2040.97,1571.13 2040.69,1571.66 2040.11,1572.53 2039.59,1573.11 2038.86,1573.43
  		2037.91,1573.84 2037.59,1574.09 2037.54,1574.44 2037.88,1575.26 2038.21,1576.05 2038.74,1576.98 2039.13,1577.66
  		2039.74,1578.49 2040.1,1578.97 2040.05,1579.79 2040.17,1580.08 2040.38,1580.42 2040.55,1580.51 2040.74,1580.63
  		2041.25,1580.86 2041.37,1581.02 2041.73,1581.3 2042.07,1581.81 2042.72,1582.5 2043.45,1583.04 2044.7,1583.26 2045.68,1583.43
  		2046.01,1583.32 2046.34,1582.89 2046.62,1582.35 2046.7,1581.65 2047.01,1581.28 2048.19,1579.52 2048.83,1578.87
  		2049.23,1578.83 2049.74,1578.7 2050.39,1578.65 2050.87,1578.7 2050.98,1578.68 2051.74,1578.45 2053.12,1577.87 2053.75,1577.51
  		2054.2,1577.22 2054.78,1576.78 2054.98,1575.69 2055.15,1574.78 2055.6,1574.32 2056.05,1574.02 2056.43,1573.55 2056.89,1572.41
  		2057.21,1571.29 2057.24,1570.83 	"/>
    </>,
    LBR: <>
        <polygon className="st1" points="1747.17,1271.32 1746.92,1269.72 1746.79,1269.62 1746.68,1269.69 1746.42,1269.71 1745.39,1269.16
  		1744.59,1269.07 1744.04,1268.6 1744,1268.06 1743.73,1267.75 1743.54,1267.13 1743.26,1266.42 1742.48,1265.99 1741.74,1265.88
  		1741.22,1265.97 1740.6,1265.95 1739.9,1265.71 1739.42,1265.44 1738.96,1264.92 1738.53,1264.5 1738.19,1264.56 1737.78,1264.46
  		1737.37,1264.27 1737.24,1264.12 1738.94,1262.47 1739.52,1261.65 1739.58,1261.16 1739.59,1260.66 1739.77,1260.15
  		1739.82,1259.36 1738.88,1256.52 1738.64,1255.64 1738.39,1255.39 1738.22,1255.29 1737.92,1255.06 1737.57,1254.73
  		1737.45,1254.29 1737.21,1254.2 1736.76,1254.2 1736.36,1254.44 1736.18,1254.9 1736.15,1255.42 1736.09,1255.82 1735.84,1256.06
  		1735.35,1256.69 1735.12,1257.27 1734.83,1257.78 1734.42,1257.75 1734.23,1257.68 1734.1,1257.81 1733.46,1258.09
  		1732.91,1258.17 1732.76,1257.88 1732.44,1257.65 1732.09,1257.18 1731.68,1256.81 1730.91,1256.55 1730.6,1256.67
  		1730.23,1256.64 1729.99,1256.49 1730.03,1256.26 1730.43,1255.7 1730.66,1255.18 1730.79,1254.61 1730.79,1254.07 1730.57,1253.3
  		1730.22,1252.7 1730.14,1252.34 1730.18,1251.84 1730.1,1251.38 1729.99,1251.13 1729.93,1250.67 1729.82,1250.25 1729.62,1250.09
  		1729.5,1249.38 1729.53,1248.66 1729.23,1248.38 1728.76,1248.18 1728.48,1247.9 1728.31,1247.58 1728.14,1247.49 1727.99,1247.51
  		1727.86,1247.71 1727.7,1247.75 1727.43,1247.07 1727.31,1247.04 1727.12,1247.2 1724.93,1247.95 1724.84,1247.66 1724.65,1247.31
  		1724.23,1247.2 1723.51,1247.46 1723.09,1247.49 1723.07,1247.75 1722.83,1248.96 1722.45,1249.99 1722.2,1250.25 1721.12,1250.52
  		1720.67,1250.97 1720.27,1252.45 1720.02,1253.6 1719.65,1253.79 1718.08,1255.46 1717.05,1256.09 1716.33,1256.63
  		1715.65,1257.34 1714.8,1258.03 1713.88,1259.19 1713.23,1260.4 1712.78,1260.77 1714.6,1262.61 1717.02,1263.71 1718.33,1264.48
  		1718.86,1265.78 1719.52,1266.22 1720.45,1266.62 1721.95,1266.98 1723.15,1267.74 1728.39,1272.43 1730.74,1274.76
  		1732.79,1276.32 1740.14,1280.22 1742.34,1280.9 1745.18,1282.09 1746.15,1282.22 1745.93,1281.92 1745.9,1280.37 1745.76,1278.28
  		1745.82,1277.48 1745.95,1276.73 1745.95,1276.1 1746.45,1275.87 1746.58,1275.61 1746.66,1274.8 1747.12,1274.06 1747.13,1272.77
  		1747.27,1272.5 1747.38,1272.16 	"/>
    </>,
    LKA: <>
        <path className="st0" d="M2481.99,1242.92l0.7,0.44l-0.24-0.64l-0.12-0.13l-0.95-0.33l0.16,0.29L2481.99,1242.92z M2482.72,1237.92
  		l0.53-0.09l-0.49-0.41l-0.2-0.52l-0.14-0.03l-0.11,0.08l-0.11,0.17l0.1,0.24L2482.72,1237.92z M2499.29,1257.56l-0.35-1.18
  		l-0.3-0.31l-0.58-1.35l-0.37-0.5l-0.06-0.22l0.02-0.26l-0.11-0.34l-1.93-2.83l-0.12-0.24v-0.57l-0.42-1.82l-0.33-0.34l-0.5-0.1
  		l-0.4-0.18l-0.09-0.37l0.03-0.5l-0.18-0.45l-1.53-2.28l-0.31-0.2l-0.37-0.12l-0.21-0.45l-0.14-0.52l-1.54-2.37l-2.82-2.33
  		l-1.04-1.3l-0.61-0.12l-0.86,0.02l-0.81-0.04l-0.13,0.17l-0.11,0.42l0.21,0.36l0.56,0.42l1.78,0.33l1.08,0.53l0.36,0.57l-0.51,0
  		l-0.42,0.13l-0.52-0.25l-0.51-0.37l-0.92-0.34l0.21,1.05l0.06,1.07l-0.16,0.99l-0.29,0.96l-1.14,1.66l-0.01,0.44l0.13,0.89
  		l-0.02,0.42l-0.77,2.36l-0.16,0.91l-0.19,2.14l-0.21,0.27l-0.28-0.26l0-2.07l-0.31,0.94l-0.04,0.98l0.44,2.27l0.27,1.78l0.57,6.36
  		l0.74,2.06l0.51,1.85l0.74,1.77l1.45,1.2l1.92,0.51l1.92-0.25l2.08-0.92l2.82-0.97l0.62-0.31l2.17-1.55l0.63-0.73l0.46-0.86
  		l0.43-1.19l0.36-1.22l0.13-1L2499.29,1257.56z"/>
    </>,
    LIE: <>
        <polygon className="st1" points="1890.57,891.51 1890.32,891.23 1890.18,890.94 1890.15,890.66 1890.07,890.42 1890.02,890.21
  		1889.95,890.03 1889.58,891.08 1889.54,891.88 1889.61,892.25 1889.73,892.25 1890.39,892.3 1890.52,892.11 1890.65,891.77 	"/>
    </>,
    LCA: <>
        <polygon className="st1" points="1296.7,1199.95 1296.4,1200.12 1295.96,1200.65 1295.39,1201.47 1295.31,1201.89 1295.42,1202.6
  		1296.34,1203.15 1296.81,1202.26 1296.88,1200.65 	"/>
    </>,
    LBN: <>
        <polygon className="st1" points="2117.16,1018.48 2116.75,1018.16 2116.08,1017.88 2115.69,1017.84 2115.66,1017.71 2116.18,1017.2
  		2116.56,1016.76 2116.14,1016.34 2115.41,1016.14 2115.13,1016.58 2114.18,1016.62 2112.71,1016.61 2112.72,1017.39
  		2112.25,1017.9 2111.26,1018.43 2109.95,1020.22 2109.64,1022.26 2108.79,1023.7 2107.32,1027.24 2106.61,1028.28 2106.2,1029.53
  		2105.79,1030.46 2105.4,1031.18 2106.37,1031.1 2107.09,1031.22 2107.95,1031.25 2108.64,1030.84 2108.97,1029.61 2109.37,1029.42
  		2109.57,1029.71 2109.77,1029.38 2110.67,1028.84 2111.12,1028.49 2111.57,1028.06 2111.81,1027.91 2112.19,1027.6
  		2112.29,1027.27 2112.64,1026.95 2113.1,1026.68 2113.2,1026.47 2113.14,1026.35 2112.67,1026.11 2112.43,1025.69 2112.65,1025.08
  		2112.79,1024.89 2113.07,1024.6 2113.69,1024.15 2114.17,1024.08 2114.59,1024.07 2115.3,1024.11 2115.85,1024.19 2115.99,1024.08
  		2115.97,1023.93 2115.29,1023.56 2115.25,1023.27 2115.42,1022.95 2115.9,1022.46 2116.47,1022.09 2116.76,1022.03
  		2117.42,1021.29 2117.84,1020.47 	"/>
    </>,
    LAO: <>
        <polygon className="st1" points="2716.14,1189.55 2715.66,1188.85 2714.78,1188.21 2713.76,1187.4 2713.26,1186.9 2712.87,1186.39
  		2712.5,1185.8 2712.3,1185.32 2712.5,1185.01 2713.94,1184.3 2714.21,1184.04 2714.36,1183.65 2714.25,1183.26 2713.86,1183.05
  		2713.4,1182.91 2712.74,1182.46 2711.49,1181.22 2710.92,1180.95 2710.32,1180.6 2710,1180.22 2709.65,1179.2 2709.5,1179.11
  		2709.15,1179.42 2708.71,1179.74 2708.35,1179.69 2708.01,1179.4 2707.85,1179.01 2707.49,1178.47 2707.08,1178.04
  		2706.98,1176.57 2706.92,1176.09 2706.71,1175.42 2706.4,1175.19 2706.07,1175.01 2705.29,1173.79 2704.76,1173.16
  		2702.54,1171.45 2702.26,1171.18 2701.67,1170.47 2700.63,1169.47 2699.89,1168.66 2699.35,1167.83 2699.1,1167.11
  		2699.02,1166.54 2698.43,1165.73 2697.92,1165.07 2697.43,1164.85 2696.87,1164.76 2696.36,1164.36 2695.44,1163.47
  		2695.03,1162.89 2694.79,1162.51 2694.8,1162.11 2695.02,1161.44 2695.29,1161.06 2695.3,1160.77 2695.03,1160.52 2694.01,1160.09
  		2691.67,1159.44 2690.81,1158.95 2690,1158.31 2689.4,1157.88 2686.55,1156.04 2685.69,1155.73 2684.95,1155.41 2684.73,1155.09
  		2684.77,1154.79 2685.07,1154.56 2685.87,1154.09 2686.17,1153.55 2686.07,1152.84 2685.75,1152.12 2685.91,1151.87
  		2686.17,1151.84 2686.71,1151.82 2687.83,1151.78 2690.24,1152.43 2690.59,1152.36 2691.9,1151.18 2692.39,1150.47
  		2692.51,1149.88 2692.76,1149.5 2693.46,1148.89 2693.47,1148.32 2693.12,1147.57 2692.78,1147.28 2692.48,1147.15
  		2691.52,1147.25 2691.34,1147.08 2691.21,1146.52 2691.17,1146.18 2690.85,1145.78 2689.82,1145.44 2688.94,1145.34
  		2688.74,1145.19 2689.07,1144.81 2689.67,1144.42 2690.13,1144.2 2690.48,1143.81 2690.55,1143.4 2690.11,1143.04 2689.53,1142.64
  		2688.58,1141.88 2687.29,1141.07 2686.49,1140.79 2686.08,1140.83 2684.65,1141.52 2683.88,1141.98 2683.24,1142.79
  		2682.57,1142.96 2681.89,1142.61 2681.12,1142.24 2678.99,1141.71 2678.1,1141.26 2676.24,1138.54 2675.96,1137.98
  		2676.14,1137.36 2676.27,1136.46 2676.46,1135.88 2676.78,1135.32 2676.87,1134.83 2676.79,1134.34 2676.52,1134.07
  		2676.17,1133.98 2675.91,1133.87 2675.67,1133.24 2675.52,1133.32 2675.29,1134.09 2675.02,1134.37 2674.65,1134.51
  		2674.37,1134.39 2674.19,1134.08 2674.11,1133.55 2673.93,1132.85 2673.7,1132.39 2672.9,1131.92 2672.52,1131.31 2671.33,1129.98
  		2670.34,1129.05 2669.87,1128.21 2669.57,1127.92 2669,1127.68 2668.34,1127.68 2667.46,1128.13 2666.77,1127.23 2666.6,1127.19
  		2666.33,1127.26 2666.03,1127.48 2665.81,1127.98 2665.59,1128.66 2665.16,1129.11 2664.79,1129.31 2664.9,1129.7 2665.1,1130.12
  		2665.09,1130.48 2665.22,1131.06 2665.45,1131.63 2666.27,1132.58 2666.58,1133.07 2666.64,1133.49 2666.67,1135.01
  		2666.63,1135.64 2666.47,1136.85 2666.46,1137.56 2666.8,1137.87 2667.13,1138.25 2667.12,1138.45 2666.97,1138.53
  		2666.51,1138.94 2666.31,1139 2666,1138.83 2665.61,1138.7 2665.29,1138.53 2664.94,1138.26 2664.11,1138.29 2662.74,1138.7
  		2662.46,1138.59 2662.26,1138.35 2662.16,1137.88 2662.22,1137.31 2662.1,1136.96 2661.85,1136.74 2662.03,1135.74
  		2661.54,1135.34 2661.05,1136.21 2659.76,1137.11 2658.85,1137.56 2658.32,1137.58 2657.87,1138.11 2657.5,1139.17
  		2657.12,1139.79 2656.72,1139.98 2656.47,1140.38 2656.35,1141 2656.58,1141.33 2657.15,1141.37 2657.2,1141.54 2656.71,1141.84
  		2656.11,1141.95 2655.38,1141.86 2654.7,1142.1 2654.05,1142.67 2653.5,1143.9 2653.04,1145.8 2652.98,1146.28 2652.92,1146.8
  		2653.13,1146.9 2653.42,1146.67 2653.79,1146.08 2654.19,1145.75 2654.63,1145.68 2655.09,1146.08 2655.58,1146.94
  		2656.09,1147.44 2656.33,1147.49 2656.5,1147.89 2656.52,1148.27 2656.28,1149.08 2655.88,1150.01 2655.3,1151.17 2655.49,1152.14
  		2656.28,1152.93 2657.22,1153.4 2658.22,1153.27 2658.75,1153.03 2659.18,1152.66 2659.58,1152.48 2660.09,1152.43
  		2661.68,1152.71 2662.16,1152.97 2662.23,1153.51 2662.04,1154.89 2662.28,1155.9 2662.73,1156.97 2662.78,1157.94
  		2662.23,1159.54 2661.77,1161.05 2661.27,1161.78 2660.88,1162.26 2660.77,1162.58 2660.8,1162.88 2661.15,1163.33
  		2661.53,1163.92 2661.63,1164.48 2661.58,1165.17 2661.33,1166.11 2660.36,1168.15 2659.6,1169.99 2660,1170.36 2660.76,1170.63
  		2661.26,1170.89 2661.78,1170.73 2662.9,1169.64 2663.86,1168.83 2665.05,1168.02 2665.12,1167.95 2666.16,1167.36
  		2666.64,1166.81 2666.9,1166.11 2667.27,1165.84 2667.75,1166 2668.35,1165.7 2669.09,1164.93 2669.65,1164.58 2670.04,1164.64
  		2670.75,1165.11 2671.76,1166 2672.66,1166.53 2673.45,1166.7 2673.83,1167.03 2673.82,1167.53 2673.99,1167.84 2674.36,1167.97
  		2674.52,1167.92 2674.48,1167.68 2674.84,1167.33 2675.6,1166.87 2676.36,1166.6 2677.15,1166.52 2677.65,1166.15 2677.99,1165.2
  		2678.47,1164.48 2678.9,1164.16 2679.43,1163.99 2679.57,1163.76 2679.31,1163.47 2679.34,1163.17 2679.65,1162.87
  		2680.31,1162.74 2681.33,1162.78 2682.52,1163.09 2683.89,1163.66 2684.79,1163.85 2685.22,1163.64 2686.05,1164.53
  		2687.29,1166.5 2688.36,1167.99 2689.25,1169 2690.18,1169.77 2691.16,1170.31 2691.87,1171.05 2692.51,1172.44 2692.03,1174.36
  		2691.9,1176.02 2691.96,1178.06 2692.54,1179.62 2693.64,1180.71 2694.28,1181.58 2694.46,1182.25 2695.32,1182.83 2696.85,1183.3
  		2697.48,1183.74 2697.23,1184.13 2697.21,1184.58 2697.42,1185.09 2697.95,1185.51 2698.8,1185.85 2699.3,1186.2 2699.46,1186.58
  		2699.44,1187.18 2699.25,1188.02 2698.89,1188.66 2698.38,1189.11 2698.32,1189.46 2698.19,1190 2698.19,1191.11 2698.55,1191.84
  		2698.67,1192.78 2698.47,1193.54 2698.28,1195.1 2698.25,1195.7 2698.07,1196.22 2697.62,1196.72 2696.94,1197.19 2696.11,1197.61
  		2695.61,1197.79 2695.63,1198.02 2695.81,1198.53 2696.13,1199.03 2696.46,1199.37 2697.01,1199.81 2697.37,1199.83
  		2698.54,1199.41 2700.29,1200.02 2700.5,1200.33 2701.07,1200.95 2701.68,1201.39 2703.05,1201.42 2703.54,1200.33 2703.3,1199.66
  		2702.52,1198.5 2702.31,1197.82 2702.56,1197.7 2703.88,1197.57 2704.09,1197.43 2704.38,1196.68 2704.74,1196.76 2705.47,1196.86
  		2706.25,1196.35 2706.71,1195.81 2706.96,1196.05 2707.23,1196.43 2707.53,1196.65 2708.09,1196.98 2708.7,1197.44
  		2709.08,1197.88 2709.39,1198.06 2710.18,1197.93 2710.39,1197.95 2710.84,1197.41 2711.16,1197.11 2711.43,1197.2
  		2711.83,1197.19 2712.65,1196.49 2713.12,1195.86 2713.37,1195.69 2714.11,1196 2714.4,1195.94 2714.82,1195.07 2715.28,1194.72
  		2715.23,1193.76 2715.33,1193.3 2715.16,1192.92 2714.95,1192.37 2715.09,1192.02 2715.58,1191.71 2715.87,1191.19
  		2716.24,1190.57 2716.41,1190.02 	"/>
    </>,
    KAZ: <>
        <path className="st0" d="M2253.42,909.62l0.09-0.51l-0.28-0.6l-0.43-0.1l-0.46,0.56l0.37,0.48l0.56,0.28L2253.42,909.62z
  		 M2231.15,914.44l0.05-0.63l0.6-0.5l-0.06-0.25l-0.43,0.16l-0.31,0.23l-0.24,1.12l0.84,1.1l0.45,0.04l0.3-0.29l-0.73-0.28
  		L2231.15,914.44z M2310.04,909.81l-0.14-0.82l0.03-0.69l-0.12-0.34l-0.26,0.01l-0.16-0.2l-0.05-0.42l-0.2-0.29l-0.36-0.17
  		l-0.3-0.34l-0.51-0.86l-0.26-0.2l-0.24,0.23l-0.22,0.65l-0.18,0.3l-0.15-0.05l-0.07-0.25l0.01-0.44l-0.1-0.2l-0.22,0.05l-0.16,0.4
  		l-0.1,0.74l-0.42,0.66l-0.74,0.58l-0.53,0.62l-0.1,0.21l0.49,0.29l1.16,0.69l1.16,0.69l1.16,0.69l1.16,0.68l0.63,0.37l0.1-0.23
  		l0.07-0.65l-0.11-0.8L2310.04,909.81z M2233.45,914.2l0.16-0.27l0.16-0.88l-0.46,0.07l-0.33,0.56l0.18,0.66L2233.45,914.2z
  		 M2544.96,869.84l-0.53-0.74l-0.72-0.26l-0.65-0.16l-0.59-0.36l-0.4-0.38l-1.19-1.81l-0.82-0.77l-0.74-0.04l-0.1-0.51l0.43-0.51
  		l0.55-0.43l-0.02-0.58l-0.45-0.31l-0.55,0.09l-0.74,0.67l-0.88,0.76l-1.06,0.56l-0.42,0.45l-0.52,0.51l-0.74-0.07l-0.53,0.02
  		l-0.47,0.04l-0.34-0.56l-0.45-0.07l-3.22-0.53l-1.07-0.2l-0.67,0.27l-0.5-0.18l-0.19-0.54l-0.62-0.94l-0.51-0.78l-0.64-0.8
  		l-0.22-0.62l0.21-0.65l-0.09-0.56l-0.55-0.29l-0.72-0.04l-1.95-1.22l-0.91-0.18l-0.83-0.22h-0.65l-0.55-0.54l-0.53-1.65l-0.16-0.92
  		l-0.65-0.93l-0.82-0.8l-0.48-1.08l-0.72-0.48l-1.2-0.77l-1.15-0.54l-1.89-0.65h-0.7l-0.96,0.06l-0.57,0.32l-0.62,0.7l-0.84,0.05
  		l-1.33,0l-0.36,0.27l-0.22,0.48l-0.68,0.61l-0.99,0.49l-1.41-0.16l-0.96,0.25l-0.96,0.1l-1.38-0.62l-1.53,0.02l-0.99,0.28
  		l-1.42-0.01l-0.29-0.35l0.17-0.58l-0.12-0.53l-0.23-0.43l-0.18-0.52l-0.58-0.11l-1.64,0.22l-0.45-0.25l0.05-0.51l0.29-0.64
  		l0.24-0.83l-0.12-0.5l-0.85,0.06l-0.51-0.05l-0.27-0.59l-0.48-0.43l-0.54-0.02l-0.66-0.11l-0.71,0.18l-0.38,0.59l-0.46,0.09
  		l-0.5,0.17l-0.36,0.21l-0.22,0.52l0.1,0.49l0.16,1.06l-0.24,0.57l-0.66,0.3l-0.63-0.06l-0.42,0.14l-0.78,0.59l-0.34,0.2l-0.12,0.36
  		l-0.05,0.54l-0.67-0.18l-1.07-2.01l-1.21-2.28l-1.37-2.43l-0.72-1.29l-2.7-4.2l-1.32-2.02l-2.28-3.49l-2.07-3.19l-2.34-3.31
  		l-1.39-1.88l-1.46-1.99l-0.51-0.55l-0.8-0.71l-1.98-1.37l-2.84-1.97l-2.63-1.76l-2.06-1.38l-0.53-0.59l-0.24-0.34l-0.22-0.38
  		l-0.31-0.67l0-0.44l1.96,0.07l0.41-0.43l0.72-1.62l0.41-1.4l-0.66,0.06l-1.21,0.58l-0.64,0.42l-0.36,0.18l-1.94,0.28l-1.06,0.62
  		l-2.19,1.05l-1.58,0.62l-0.3,0.1l-1.85,0.19l-0.33,0.24l-0.05,0.54l-0.13,0.6l-1.32,0.88l-1.42,0.77l-0.53,0.09l-0.86-0.17
  		l-0.44,0.1l-1.29,0.82l-1.93,1.23l-0.18,0.5l-0.01,0.61l-0.22,0.53l-0.43,0.19l-0.63-0.46l-0.57-0.56l-1.19-0.4l-1.77-0.1
  		l-1.08,0.19l-0.74,0.3l-1.46,1.23l-0.53,0.24l-0.3-0.08l-0.08-0.59l-0.3-0.42l-0.35-0.63l0.17-1.25l0.79-1.2l1.3-0.66l1.05-0.71
  		l0.31-0.77l-0.03-0.53l-0.39-0.24l-0.41-0.05l-0.24,0.26l-0.71,0.53l-1.05,0.42l-0.88,0.08l-0.39-0.03l-0.93-0.27l-1.73-1.46
  		l-1.46-0.2l-1-0.11l-0.33,0.15l-0.16,0.36l0.1,0.39l0.2,0.39l-0.11,0.31l-0.47,0.23l-0.7,0.39l-0.36-0.26l-0.18-1.03l0.04-0.8
  		l-0.49-0.68l-0.51-1.05l-0.7-0.62l-0.68,0.2l-0.33,0.89l-0.52,0.3l-0.99-0.18l-1.77,0.5l-2.87,0.23l-2.05-0.62l-0.34-0.56l0.84-1.2
  		l0.06-1.06l0-0.97l0.22-0.71l-0.5-1.35l-1.13-2.75l-0.69-2.08l-1.01-1.56l-0.44-0.52l-2.12,0.27l-0.58,0.34l-0.39,0.48l-0.66,0.34
  		l-0.93,0.25l-0.8-0.17l-0.89-0.26l-0.94-0.55l-1.09-0.73l-2.08-0.58l-2.07-0.18l-2.27-0.2l-1.13,0.37l-1.1,0.59l-1.58,1.22
  		l-0.73,0.12l-1.15,0.09l-0.81,0.3l0.16,0.54l0.16,0.74l-0.29,0.58l-0.45,0.31l-0.69,0.2l-1.13,0.07l-0.93,0.12l-1.15,0.83
  		l-1.76,0.21l-1.91,0.3l-1.34,0.47l-2.9,0.59l-1.68,0.26l-2.8,0.56l-2.26,0.09l-0.34-0.39l-1.74,0.87l-1.94-0.05l-0.36,0.35
  		l-0.47,0.34l-0.52,0.15l-0.66,0.41l-0.38,0.87l-0.29,0.89l-0.59,0.28l-0.78-0.33l-0.58-0.32l-0.99,0.33l-1.34,0.19l-1.05-0.11
  		l-0.54-0.26l-2.2,0.43l-1.15,0.52l-0.21,0.27l-0.28,0.15l-1.32,0.35l-1.06-0.1l-0.17,0.02l-1.01,0.25l-1.42,0.45l-1.02,0.15
  		l-0.85-0.01l-0.55,0.37l-0.44,0.39l-3.72,0.42l-0.37,0.29l-0.75,0.36l-3.86,0.12l-0.32,0.26l-0.14,0.29l-0.48,0.09l-2.78-0.56
  		l-2.23-0.63l-0.86,0.34l-0.74,0.64l-0.26,0.94v0.8v0.69l-0.33,0.5l-0.74,0.61l-0.05,0.41l1,0.44l1.26,0.37l0.74-0.16l0.62-0.25
  		l0.54,0.08l0.38,0.3l0.13,0.36l-0.07,0.25l-0.24,0.19l-0.82,0.33l-0.75-0.11l-0.7,0.23l-0.36,0.45l-0.2,0.8l0.31,0.57l0.94,0.14
  		l1.06,0.41l1.17,0.19l0.71-0.07l0.9,0.62l2.09,0.75l0.56,0.58l0.01,0.59l-0.38,0.45l-0.53,0.25l-0.72-0.14l-1.43-0.15l-1.56-0.1
  		l-1.12-0.2l-1.63,0.08l-1.34,0.19l-0.34,0.45l-0.52,0.83l-0.43,0.46l-0.77,0.85l-0.24,0.78l0.39,1.2l1.33,1.98l0.13,0.66
  		l-0.48,0.64l-0.92,0.53l-1.33,0.93l-1.44,0.05l-0.62,0.23l-1.61,1.14l-1.42,0.54l-0.3,0.49l0.31,0.48l1.79,0.63l0.9,0.69l0.26,0.77
  		l0.39,0.59l1.39,0.38l2.89,0.89l0.17,0.09l0.18,0.41l2.93,0.56l0.41,0.3l1.21,1.01l0.26,1.06l-0.61,1.03l-0.4,1.63l-0.64,1.43
  		l-1.37,0.96l-2.4,0.88l-2.56,0.35l-1.09-0.06l-0.71-0.11l-1.15-0.28l-0.85-0.73l-0.63-0.71l-0.45-0.18l-0.45,0.12l-0.42,0.45
  		l-0.57,1.21l-0.64,1.2l-0.52,0.42l-1.92,0.56l-0.21-0.2l0.22-0.79l-0.24-0.24l-0.36-0.18l-2.37-0.3l-0.9-0.22l-0.67-0.09l-0.85-0.2
  		l-0.59-0.47l-1.26-1.45l-0.99-1.14l-1.59-1.03l-1.44-0.2l-0.12,0.11l-1.09,0.04l-1.74-0.25l-0.08,0.03l-0.54,0.47l-0.4,0.73
  		l-0.53,0.62l-0.81,0.33l-0.97,0.07l-1.09-0.64l-1.12-0.99l-1.41-0.32l-1.37,0.22l-0.5,0.16l-1.43,0.56l-0.45-0.26l-0.64-0.17
  		l-1.4,0.93l-1.53,1.02l-0.33,0.76l-0.46,0.7l-1.01,0.66l-1.11,0.57l-0.94,0.21l-1.21-0.21l-1.53-0.7l-1.4-0.88l-1.52-1.39
  		l-1.24-0.79l-1.19-0.63l-0.72-0.15l-0.58,0.24l-0.23,0.49l0.16,0.39l0.34,0.35l0.27,1.1l0.1,1.34l-0.12,0.76l-0.34,0.45l-0.34,0.16
  		l-0.32-0.06l-0.39-0.47l-0.24-1l-0.18-1.18l-1.04-1.48l-0.9-0.91l-0.43-0.5l-0.83-0.83l-0.71-0.51l-1.52-0.59l-0.74-0.42
  		l-1.29-1.65l-0.72-0.5l-0.93-0.42l-0.76-0.13l-0.17,0.1l-1.59,0.24l-1.14-0.04l-0.69-0.31l-0.72-0.04l-0.06,0l-0.78,0.21
  		l-0.15-0.01l-0.39-0.01l-0.63-0.34l-0.62-0.92l-0.77-0.98l-0.94-0.31l-1.79,0.41l-1.95,1.33l-1.4,0.79l-1.14,0.02l-0.65,0.12
  		l-0.43-0.05l-0.37-0.25l-0.09-0.48l-0.18-0.61l-0.9-0.59l-1.23-0.38l-1.14-0.42l-0.75-0.11l-0.32,0.61l-0.95,0.96l-1.07,0.93
  		l-1.37,1.52l-0.37,0.54l-0.53,0.36l-1.2,0.39l-1.45,0.64l-0.93,0.73l-1.31,0.33l-1.42,0.21l-0.62,0.63l-0.38,1.03l-0.47,0.92
  		l-2.23,1.39l-1.22,0.9l-0.89,0.48l-0.62-0.06l-0.67-0.14l-0.25,0.08l0.34,0.69l0.29,2.17l0.41,1.38l0.3,0.79l0.28,0.62l0.21,0.95
  		l-0.28,0.56l-0.43,0.37l-1.34,0.59l-1.4,0.51l-0.84-0.33l-0.93-0.81l-0.37-0.42l-1.02-1.36l-1.78-2.08l-1.21-1.05l-0.89-0.39
  		l-0.81,0.12l-0.63,0.49l-0.45,0.44l-0.42,0.49l-0.27,0.62l0.02,0.85l-0.02,0.9l-0.4,0.63l-1,0.68l-1.16,0.95l-0.86,1.71l-0.56,2.13
  		l-0.18,1.47l0.43,0.69l0.85,0.56l0.55,0.57l0.11,0.54l-0.14,0.56l-0.44,0.65l-0.92,0.75l-1.27,1.78l-0.79,2.51l0.44,1.74l1.62,0.96
  		l1.27,0.42l0.51,0.56l0.46,1.13l-0.06,1.15l-0.15,0.78l0.32,0.76l0.6,0.9l0.76,0.55l0.8-0.3l0.8-0.38l1,0.15l2.82,0.31l1.48,0.16
  		l0.48,0.39l0.92,1.27l1.16,1.42l1.17,1.44l0.41,0.62l0.96,1.72l0.99,1.55l1.07,1.91l-0.08,0.52l-0.56,0.22l-0.9-0.05l-0.7-0.28
  		l-0.39-0.23l-0.35-0.08l-0.39,0.09l-0.34,0.24l-0.14,0.38l0.06,0.51l0.27,0.47l0.38,0.3l0.2,0.11l1.38,0.62l1.56,0.69l1.9,0.98
  		l0.4,0.12l-0.22-0.51l0.68-0.54l0.64,0.28l-0.15-0.79l0.03-0.35l0.76-0.19l1.24-0.08l0.4-0.24l1.09-0.04l1.06-0.26l0.96-0.41
  		l0.86-0.66l1.72-1.04l0.95-0.9l0.45-0.04l0.47,0.1l0.46-0.1l0.82-0.6l0.45-0.14l1.58-0.94l2.17-0.74l0.95,0.14l2.73,0.72l0.29,0.13
  		l0.8,0.89l1.69,0.41l0.56-0.07l0.63,0.66l0.44,0.12l0.43-0.12l1.28-0.59l0.38-0.29l0.3-0.44l0.53-0.29l1.64,0.36l2.01,0.03l1,0.65
  		l0.29,0.39l0.57,1.21l0.28,0.77l-0.32,0.64l-0.45,0.64l-0.12,0.76l0.38,0.65l0.22,2.34l-0.79,2.35l-1.3,1.97l-0.96,2.16l0.54,0.79
  		l2.09,0.93l0.97,0.79l-1.02,0.26l-1.42-0.13l-1.17-0.25l-2.03-0.58l-0.88-0.06l-3.18,0.17l-2.66-0.12l-1.63,0.59l-1.04-0.16
  		l-0.69,0.82l-0.33,0.52l-0.37,1.13l-0.81,0.85l-0.95,0.62l-0.26,0.61l0.1,0.7l0.31,0.44l1.35,1.06l0.78,0.93l0.47,0.2l0.55-0.02
  		l0.53,0.25l0.42,0.48l-1.41-0.11l-0.56,0.09l-1.13,0.32l-0.56-0.07l-0.52-0.23l-1.59-1.02l-1.75-0.05l-2.05,0.1l-0.94,0.44
  		l-0.28,0.57l-0.1,0.67l0,0.57l0.19,0.53l0.47,0.31l1.18,0.32l1.8,0.31l0.82,0.38l0.41,0.36l0.92,2.41l1.05,2.14l0.63,1.04
  		l0.84,0.74l0.3,0.45l0.23,0.52l0.1,0.63l0,0.67l-0.18,1.28l0.03,0.58l0.44,0.07l1.4-0.03l0.86,0.12l0.71,0.55l0.71,1.02l0.22,0.51
  		l0.28,0.45l0.46,0.42l0.53,0.19l0.49-0.1l0.48-0.2l0.91,0.11l0.75,0.7l0.43-0.17l0.93-0.08l0.5,0.04l0.47,0.15l0.39,0.46l0.35,2.08
  		l-0.17,1.29l-0.38,0.99l-0.47,0.95l-0.46,1.38l-0.03,0.53l0.08,1.64l0.22,1.06l1.71-1.65l1.46-1.17l1.2-0.71l0.36-0.17l1.64-0.59
  		l2.11-0.53l1.55-0.39l2.03-0.33l0.66-0.06l0.98,0.01l0.79,0.31l0.48,0.24l1.69,1.01l1.73,1.03l1.48,1.14l0.42,0.46l0.24,0.56
  		l0.17,0.54l1.26,1.72l0.51,0.79l0.74,1.03l0.59,0.5l0.58,0.62l0.39,0.51l0.44,0.24l0.49,0.1l1.12-0.16l1.35-0.32l0.81-0.13
  		l0.36,0.02l0-2.3l0-2.31l0-2.31l0-2.32l0-2.32l0-2.33l0-2.33l0-2.34l0-2.34l0-2.35l0-2.36l0-2.36l0-2.37l0-2.37l0-2.38l0-2.38
  		l1.05-0.3l1.33-0.37l1.27-0.36l1.51-0.43l1.71-0.48l1.46-0.41l1.74-0.49l1.33-0.37l1.25-0.35l1.59-0.42l2.48-0.65l1.38-0.36
  		l1.4-0.37l1.33-0.35l0.89-0.13l0.3,0.15l0.18-0.46l0.14-0.16l0.23-1.21l0.22-0.71l0.33-0.67l0.43-0.5l0.52-0.32l0.43-0.13
  		l0.34,0.06l0.2-0.11l0.06-0.27l0.2-0.07l0.79,0.2l0.55-0.02l0.51-0.16l0.48-0.3l0.37-0.01l0.26,0.29l-0.09,0.42l-0.44,0.55
  		l-0.26,0.56l-0.07,0.57l0.22,0.47l0.5,0.37l0.28-0.02l0.05-0.41l0.15-0.19l0.25,0.03l0.14,0.17l0.03,0.31l0.22-0.06l0.41-0.44
  		l0.42-0.22h0.42l0.22-0.23l0.03-0.46l0.23-0.79l0.42-1.13l-0.24-1.21l-0.89-1.28l-0.41-0.72l0.07-0.16l0.15-0.02l0.24,0.12
  		l1.03,0.02l0.43,0.1l0.25,0.22l0.18,0.49l0.12,0.77l-0.04,0.58l-0.2,0.39l0.15,0.18l0.49-0.03l1.39-0.51l0.64-0.06l0.51,0.21
  		l0.62,0.09l0.72-0.03l2.76,0.33l0.28-0.08l0.37-0.24l0.7-0.71l0.1-0.47l-0.01-0.31l-0.61-1.16l-0.18-0.51l-0.15-0.06l-0.13,0.07
  		l-0.11,0.2l-0.64,0.34l-0.2,0.24l-0.3,0.04l-0.82-0.45l-0.46-0.43l-0.78-0.09l-1.1,0.25l-0.79-0.12l-0.49-0.5l-0.19-0.56l0.1-0.62
  		l0.54-0.61l0.98-0.61l0.69-0.24l0.39,0.13l0.35,0.55l0.31,0.97l0.28,0.15l0.25-0.67l0.35-0.2l0.45,0.28l0.42,0.08l0.39-0.12
  		l0.63-0.39l0.02-0.53l-0.76-0.47l-0.44-0.36l-0.13-0.24l0.44-0.04l1.02,0.17l0.45,0.52l-0.12,0.88l0.14,0.67l0.39,0.47l0.77,0.36
  		l1.15,0.26l0.81,0.09l0.47-0.07l0.31,0.17l0.14,0.41l-0.12,0.62l-0.38,0.83l-0.39,0.42l-0.4,0.01l-2.13,0.97l-0.36,0.06l-0.47,0.31
  		l-0.42,0.54l-0.59,0.46l-0.75,0.37l-0.57,0.47l-0.38,0.58l-0.34,0.25l-0.85-0.16l-3.21-0.6l-0.44-0.01l-0.3,0.06l-0.15,0.13
  		l0.03,0.17l0.08,0.17l0,0.12l-0.16,0.24l-0.04,0.41l-0.1,0.12l-0.11,0.07l-0.09,0.14l-0.11,0.48l-0.18,0.23l-0.1,0.35l-0.02,0.46
  		l0.09,0.29l0.2,0.12l1.61-0.07l0.33,0.09l0.3,0.59l0.27,1.09l0.08,1.11l-0.12,1.13l-0.07,3.68l1.12,0.66l1.16,0.68l1.16,0.68
  		l1.16,0.68l1.16,0.68l1.16,0.68l1.08,0.63l0.48,0.47l0.27,1.03l0.54,0.82l0.93,0.89l0.96,0.91l1.19,1.2l0.82,0.83l0.84,0.85
  		l1.38,1.4l0.86,0.87l0.69,0.03l1.4-0.21l1.87-0.28l1.47-0.22l1.78-0.26l1.7-0.25l1.34-0.2l2,0.15l1.98,0.15l1.42,0.11l1.39,0.11
  		l1.65,0.12l0.92,0.07l1.05,0.08l0.45-0.21l0.91-0.43l0.86-0.4l0.89-0.42l0.79-0.21l0.82,0.67l0.69,0.77l0.72,0.81l0.84,0.78
  		l0.81,0.47l1.09,0.63l0.63,1.08l0.84,1.43l0.55,0.94l0.57,0.97l0.83-0.38l0.88-0.41l0.8-0.37l-0.1,1.19l-0.09,1.09l-0.13,1.64
  		l-0.11,1.34l-0.29,1.6l-0.02,1.22l-0.02,1.07l-0.01,0.85l1.55,0.04l1.14,0.03l1.43,0.03l0.14,1.06l0.19,1.49l0.29,1.35l0.25,1.13
  		l0.37,1.46l0.2,0.78l0.35,0.92l0.34,0.22l0.54,0.15l1.89-0.11l1.57-0.09l1.24-0.07l1.32-0.08l1.74-0.1l0.59,0.23l0.51-0.16
  		l0.59-0.16l0.47,0.67l0.24,0.34l0.33,0.35l0.45,0.33l-0.19,0.68l-0.28,1l-0.08,0.51l0.54,0.55l0.4,0.32l1.11,0.65l1.04,0.37
  		l0.68,0.11l0.65-0.14l0.24-0.37l-0.06-0.51l-0.31-0.54l0.02-0.64l0.21-0.47l0.66-0.85l0.63-0.8l0.96-0.82l1.14-0.81l0.48-0.59
  		l0.18-1.03l0.75-0.59l0.81-0.35l1-0.3l0.27-0.52l1.38-0.88l0.83-0.43l1.05-0.25l1.45-0.57l1.14-0.67l1.1-1.26l0.87-0.83l0.73-0.51
  		l0.62-0.02l0.43,0.41l0.37,0.03l0.24-0.19l0.41-0.53l0.44-0.62l0.42-0.26l0.81-0.13l0.73-0.42l-0.45-0.46v-0.47l0.5-0.8l0.42-0.4
  		l0.18-0.77l0.6-0.52l0.62-0.82l0.75-0.67l1.39-0.5l0.78,0.16l0.73-0.12l1.13-0.41l0.22-0.03l0.47-0.01l2.91,0.62l0.96,0.03
  		l2.25,0.81l1.04,0.18l0.73,0.23l0.33,0.35l0.53,0.43l2.83,0.35l0.78,0.23l0.28,0.38l0.81,0.48l0.69,0.11l-0.6-1.87l0.24-1.11
  		l0.89-3.05l0.47-0.46l0.9-0.41l1.41-0.46l0.53-0.64l1.15,0.07l0.5-0.06l0.34-0.11l0.19-0.36l1.3,0.62l2.18,1.26l1.64,0.79
  		l1.94,0.75l2.68,0.69l2.27,0.23l0.39-0.16l0.91-1.04l0.43-0.05l0.77,0.09l2.41,0.05l2.45,0.05l1.16-0.1l2.51-0.43l0.37-0.02
  		l0.58,0.03l1.5,0.59l1.12,0.08l0.76,0l0.45,0.05l0.93-0.02l1.51,0.07l1.87,0.38l2.25-0.07l0.72-0.07l1.25,0.07l0.99,0.37l1.26,0.39
  		l0.79,0.16l0.53,0.08l0.94,0.03l0.56-0.12l0.32,0.17l0.32,0.94l0.78,0.62l0.61,0.58l0.51,0.65l0.52,0.26l0.91,0.01l1.73,0.18
  		l0.99,0.26l1.26,1.11l1.16,1.14l0.2-0.18l0.22-0.28l-0.03-0.39l-0.42-1.27l-0.22-1.21l-0.15-1.09l0.03-0.41l0.31-0.7l0.41-0.64
  		l1.46-0.59l0.97-0.18l0.04-0.39l-0.78-0.24l-0.57-0.39l-0.1-0.22l0.03-0.25l0.13-0.23l0.98-0.43l0.93-0.43l1.13,0.26l0.22-0.17
  		l0.07-0.43l-0.24-0.44l-0.23-0.71l-0.52-0.37l-0.02-0.44l0.32-0.76l-0.45-1.4l-0.48-1.24l-0.82-2.12l-0.54-0.61l-0.3-0.98
  		l-0.34-0.52l0.03-0.76l0.05-0.54l-0.09-1.06l-0.16-1.16l0.16-1.17l0.3-0.77l-0.08-0.3l0.16-0.22l0.46-0.07l0.22-0.32l-0.22-0.33
  		l-0.8-0.25l-0.89-0.39l-1.07,0.04l-1.1,0.07l-0.55-0.29l-0.48-0.37l-0.03-0.24l0.66-0.63l0.92-0.65l1.42-0.29l1.57-0.43l0.79-0.31
  		l1.06-0.22l1.22-0.09l0.62,0.06l1.57-0.41l2.48-0.8l2.25-0.67l0.76-0.4l0.56,0.4l0.26,0.88l0.66,0.46l0.65,0.22l0.37-0.01
  		l1.12-0.34l1.21-0.25l0.48,0.14l0.62,0.45l0.69,0.4l0.36-0.02l0.32-0.31l0.32-0.63l0.2-0.8l0.04-0.85l-0.12-0.52l-0.25-0.19
  		l-1.1-0.31l-1.05-0.5l-0.12-0.46l0.03-0.33l0.28-0.8l0.69-1.47l0.69-2.03l0.36-1.61l1.15-2.4l0.91-2.51l1.47-3.62l0.25-0.72
  		l0.13-1.15l0.08-0.47l0.51-0.25l0.87,0.24l2.11,0.83l1.6,0.69l0.67,0.24l1,0.25l1.54,0.29l0.89-0.09l0.78-0.17l1.04-0.02l1.63,0.22
  		l0.5,0.01l0.63,0.03l0.45,0.35l0.22,0.8l0.34,0.36l0.61-0.13l1.3-0.7l0.83-0.55l1.04-0.8l1.03-0.11l1.09-0.18l0.38-0.4l0.4-0.93
  		l0.67-0.7l0.11-0.89l-0.24-0.63l-0.46-1.03l0.01-0.69l-0.22-2.01l-0.3-1.81l0.31-1.46l0.54-1.64l0.21-0.5l0.34-0.66l0.48-0.79
  		l0.68-0.25l1.91-0.17l1.76-0.33l0.9-0.34l0.93-0.21l0.56-0.25l0.96-1.15l0.46-0.67l0.34-1.77l-0.25-0.85l0.21-0.75l0.47-0.44
  		l0.65-0.44l0.44-0.08l0.93-0.13l1.53,0.04l0.78,0.22L2544.96,869.84z"/>
    </>,
    KY: <>
        <path className="st0" d="M1137.87,1151.08l-0.16,0l-0.15,0.07l-0.18,0.12l-0.69,0.36l0.3,0.05l0.39-0.13l0.68-0.34v-0.05
  		L1137.87,1151.08z M1125.08,1154.59l-0.1,0.18l-0.34,0.1l-0.27-0.17l-0.18-0.31l-0.24,0.09l0.12,0.84l0.85,0.06l0.22-0.05
  		l0.44-0.23l0.99-0.01l-0.2-0.36L1125.08,1154.59z M1135.77,1151.5l-0.43,0.07l-0.14,0.03l-0.15,0.09l-0.13,0.12l-0.08,0.12
  		l0.27,0.02l0.62-0.36l0.27,0.04l0.08-0.05l0.03-0.02l-0.14-0.08L1135.77,1151.5z"/>
    </>,
    KWT: <>
        <path className="st0" d="M2217.26,1072.28l-0.15-0.55l-0.27-0.65l-0.73-1.26l-0.58-0.71l-0.7-2.11l-0.12-0.59l-0.29-0.73l-0.45-0.28
  		l-0.53,0.17l-0.76,0.01l-1.03-0.25l0.02-0.22l0.78-0.64l1.28-1.18l0.66,0.18l0.35,0.17l0.45,0.06l-0.06-0.42l-0.49-0.89l-0.6-1.1
  		l-0.27-1.01l0.04-0.34l-1.89-0.86l-0.68-0.17l-0.24-0.02l-1.09,0.01l-1.55,0.15l-0.91,0.35l-0.63,0.37l-0.29,0.36l-0.1,0.2
  		l-0.49,1.07l-0.57,1.38l-0.59,1.24l-1.15,1.74l-0.64,0.8l-1.37,1.49l1.63,0.2l2.17,0.26l1.32,0.18l2.48,0.33l0.74,1.38l0.27,0.97
  		l0.25,0.94l0.74,0.86l1.69-0.02l1.5-0.02l1.85-0.03l1.46-0.02L2217.26,1072.28z M2215.18,1062.84l0.31,0.49l0.32,0.09l0.48-0.2
  		l0.55-0.65l0.06-0.23l0.01-0.57l-1.02-1.41l-0.36-0.39l-0.22,0.18l-0.17,0.58l-0.16,0.09l-0.06,0.15l0.01,0.2l-0.28,0.46
  		L2215.18,1062.84z"/>
    </>,
    KOR: <>
        <path className="st0" d="M2877.33,1026.77l-0.54,0.04l-3.01,0.84l-1.17,0.87l-0.28,0.53l0.11,0.28l0.42,0.54l0.09,0.1l0.35,0.12
  		l0.38-0.21l2.15-0.14l1.07-0.31l1.38-0.65l0.27-0.39l0.22-0.58l-0.25-0.67L2877.33,1026.77z M2877.28,1019.31l-0.45-0.49
  		l-0.41,0.05l-0.05,0.37l0.36,0.43l0.68,0.09L2877.28,1019.31z M2874.5,987.9l0.32,0.13l0.46,0.06l0.04-1.28l-0.23-0.44l-0.69-0.39
  		l-0.36,0.49l0.32,1.19L2874.5,987.9z M2885.57,1016.6l0.42,0.44l0.09-0.29l-0.1-0.64L2885.57,1016.6z M2872.9,1019.06l0.86-0.53
  		l0.37-0.68l-0.3-0.44l-0.81-0.17l-0.17,0.29l-0.88,0.84l-0.12,0.43l0.21,0.09l0.3,0.33L2872.9,1019.06z M2872.27,1015.88l0.11-0.23
  		l-0.03-0.94l-0.76-0.81l-0.6,0.45l0.37,0.28l0.16,0.52l0.38,0.65L2872.27,1015.88z M2912.24,988.58l-0.55,0.16l-0.24,0.26l0.06,0.3
  		l0.45,0.29l0.38-0.29l0.15-0.5L2912.24,988.58z M2874.45,998.83l-0.26-0.76l-0.57-0.4l0.16,1.37l0.56,0.41L2874.45,998.83z
  		 M2893.41,1013.01l-0.69,0.73l-0.81,0.64l0.26,0.43l1.07,0.78l0.79-0.59l-0.16-2.04L2893.41,1013.01z M2887.49,1014.11l-0.42-0.27
  		l-0.7,0.44l0.05,0.58l0.3,0.74l0.2-0.01l0.38-0.29l0.36,0.6l0.6-0.05l0.09-0.93l-0.23-0.69L2887.49,1014.11z M2900.06,1003.49
  		l-0.27-0.12l-0.19-0.32l-0.01-0.82l-0.09-0.62l0.01-1.16l0.29-0.6l0.15-0.82l-0.1-1.6l0.34-1.01l-0.4-1.77l-0.07-1.29l-0.7-2.08
  		l-2.39-3.91l-1.68-2.03l-1.97-2.81l-2.06-4.37l-0.3,0.15l-0.51,0.82l-0.47,1.04l-0.46,0.56l-0.53,0.31l-0.57,0.18l-1.13,0.08
  		l-1.02-0.07l-0.33-0.11l-1.4,0.07l-0.39,0.07l-2.01-0.08l-1.05,0.09l-0.67,0.2l-0.68,0.42l-0.59,0.63l-0.51,0.68l-1.05,1.24
  		l-0.74,0.6l0,0.87l-0.02,0.26l-0.26,0.18l-0.11,0.26l-0.36,0.1l-0.12,0.27l0.14,0.61l0.23,0.35l0.41,0.64l-0.05,1.01l0.39,0.35
  		l0.79,1.12l-0.37,0.98l0.25,0.34l0.1,0.54l0.69,1.23l0.76,0.18l0.14,0.18l-0.16,0.32l-0.66,0.43l-0.06,0.36l-0.28-0.21l-0.46-0.99
  		l-0.82-0.11l-0.92-0.57l-0.76,0.12l-0.49,0.37l-0.65,0.1l-1.13,0.84l-0.48,0.95l0.17,0.77l0.42,0.02l1.33,0.37l0.37-0.26l0.46-0.15
  		l0.16,1.04l0.35,1.04l0.03,0.46l-0.06,0.85l0.11,1.01l-0.14,0.67l0.48,0.59l0.72,0.64l0.09,0.23l-0.25,0.38l-0.14,0.5l0.61,0.23
  		l0.28,0.25l-0.3,0.99l-0.98,0.52l-0.5,0.43l-0.45,0.21l-0.02,0.39l0.66,0.16l0.41,0.18l-0.27,0.35l-0.75,0.32l-0.27,0.44
  		l-0.54,1.35l-0.3,0.93l-0.58,0.6l0.31,1.04l0.59,1.07l0.19,1.04l0.49,0.12l0.58-0.25l0.38,0.12l-0.46,0.43l-0.55,0.21l0.27,0.18
  		l0.16,0.38l-0.83,0.03l-1.05-0.24l-0.31,0.44l0.57,0.79l1.26,0.91l0.21,0.62l0.02,0.74l0.2,0.34l0.45-0.03l0.22-0.81l1.21-1.03
  		l0.35,0.17l0.25,0.41l0.6,0.12l1.12-1.59l1.82-1.41l0.18,0.33l-0.07,0.56l-0.55,0.54l-0.18,0.56l1.27,0.78l0.47-0.35l0.17-0.49
  		l0.66-0.69l-0.47-0.6l-0.28-0.52l0.12-0.76l0.61-0.2l0.39,0.04l0.35,0.7l0.56,0.72l0.7-0.29l0.23-0.58l-0.67-0.58l-0.2-0.44
  		l0.17-0.35l0.47-0.27l1.33-0.11l0.87-0.5l0.5-0.03l0.49,0.84l0.49,0.17l1.04,0.05l0.94,0.34l0.47,0.04l0.03-0.59l-0.24-0.79
  		l0.33-0.51l0.45-0.3l1.11-0.18l1.29,0.24l1.55-0.07l0.81-0.2l1.16-0.56l0.97-1.44l0.76-1.57l0.56-1.81l0.64-2.49l0.09-0.99
  		l-0.53,0.12L2900.06,1003.49z"/>
    </>,
    PRK: <>
        <path className="st0" d="M2910.17,941.31l-0.05-0.45l-0.28-0.44l-0.54-0.6l-0.23-0.62l-0.24-0.36l-0.4-0.11l-0.75-0.5l-0.55-0.55
  		l-0.41-0.61l0.02-1.3l-0.07-0.2l-0.75-0.27l-0.22-0.39l-0.36-0.18l-0.51,0.12l-0.38-0.12l-0.3-0.21l-0.36-0.03l-0.31,0.34
  		l-0.16,0.72l-0.52,1.2l-0.05,0.72l-0.23,1.03l-0.22,1.31l-0.18,0.27l-0.59,0.04l-0.2,0.09l-0.31,0.44l-0.37,0.08l-0.33-0.26
  		l-0.52-0.26l-0.49-0.03l-0.44,0.26l-0.52,0.56l-0.29,0.46l-0.1,0.43l-0.08,0.53l-0.52,0.51l-0.48,0.26l-0.98,0.74l-0.31,0.31
  		l-0.7,0l-0.76-0.03l-1.03,0.2l-1.68,0.1l-1.01-0.15l-1.24,0.14l-0.97,0.24l-0.14,0.36l0.04,0.54l0.19,0.35l0.24,0.23l0.4,0.72
  		l0.42,0.7l0.64,0.45l0.27,0.48l0.01,0.45l-0.3,0.57l-0.46,0.74l-0.43,0.45l-0.32-0.02l-0.49-0.26l-0.33-0.33l-0.8-0.12l-1.94,0.21
  		l-0.97-0.15l-0.47-0.27l-0.81-0.02l-1.26-0.36l-0.77-0.12l-0.36-0.23l-0.07-0.53l-0.36-0.37l-0.2-0.44l-0.46-0.55l-0.44-0.28
  		l-0.43-0.12l-0.47,0.33l-0.5,0.3l-0.38-0.07l-0.18,0.08l-0.21,0.25l-0.81,0.51l-0.19,0.47l-0.32,0.99l-0.22,1.02l-0.19,0.36
  		l-0.33,0.06l-0.33,0.31l-0.7,0.96l-0.63,0.88l-0.92,0.6l-0.43,0.55l-0.22,0.5l-0.66,0.69l-0.96,0.12l-0.77,0.2l-0.47,0.05
  		l-0.34,0.28l-0.25,0.43l-0.12,0.17h-0.43l-0.43,0.36l-1.06,0.82l-0.86,0.15l-1.08,0.55l-0.95,0.42l-0.4,0.23l-0.11,0.26l-0.14,0.33
  		l-0.46,0.07l-0.45-0.02l-0.99,0.76l-0.5,0.64l-1.95,1.37l-0.8,0.77l-0.21,0.99l0.11,0.08l-0.22,0.88l0.46,0.41l1.29,0.74l0.42,0.73
  		l0.26,1.01l0.51-0.17l0.28-0.2l0.06-0.88l0.31-0.16l0.78,0.56l1.96,1.1l0.67,0.07l1.52,0.56l0.11,0.98l0.33,1l-0.03,0.37l-2.13,4.1
  		l0.1,0.65l1.1,0.61l1.05-0.04l0.54,0.19l0.55,0.41l-0.53,0.1l-0.64-0.04l-0.89,0.15l-2.04,1.06l-0.79,0.75l-0.78,1.35l0.02,0.46
  		l-0.07,0.6l-1.55,1.02l0.75,0.27l1.07-0.11l0.74,0.34l1.41-0.15l0.37,0.12l0.34,0.24l-0.45,0.19l-1.72,1.04l0.32,0.09l0.63,0.39
  		l1.76,0.37l0.46,0.93l-0.06,0.23l0.41,0.06l0.36-0.11l1.11-0.82l0.8-1l0.16-0.44l0.62-0.22l1.45,1.09l0.91,0.04l0.56,1.23l0.37-0.2
  		l0.35-0.63l1.41-0.48l1.71,0.79l0.43,0.06l0.09,0.08l0.26-0.18l0.02-0.26l0-0.87l0.74-0.6l1.05-1.24l0.51-0.68l0.59-0.63l0.68-0.42
  		l0.67-0.2l1.05-0.09l2.01,0.08l0.39-0.07l1.4-0.07l0.33,0.11l1.02,0.07l1.13-0.08l0.57-0.18l0.53-0.31l0.46-0.56l0.47-1.04
  		l0.51-0.82l0.3-0.15l-0.38-0.56l-0.67-0.63l-0.73-0.4l-0.33-0.3l-1.27-0.8l-1.56-1.83l-0.73-0.4l-0.99-0.18l-0.71-0.36l-0.86-0.28
  		l-0.09-0.87l0.33-0.76l0.3-0.27l0.55,0.23l0.22-0.82l-0.01-1l-0.17-1.31l0.34-0.85l2.52-1.13l0.84-0.99l1.18-0.37l1.67-0.03
  		l0.75-0.53l1-0.41l0.84-0.67l0.76-1.19l1.19-0.41l0.86-0.69l1.39-0.63l1.14-1.69l0.81-0.65l3.1-1.31l0.28-0.75l-0.25-1.91l0.45-1.8
  		l-0.06-0.88l-0.65-1.03l0.03-1.01l0.59-1.18l1.01-0.94l0.44-0.92l0.67-0.95l0.51-0.55l0.94-0.52l0.47-0.87l0.67-0.31l1.2-0.89
  		l0.94,0.1l0.57,0.17l0.43-0.28L2910.17,941.31z M2861.96,968.48l-0.38,0.06l-0.37,0.43l0.02,0.51l0.47-0.28L2861.96,968.48z"/>
    </>,
    KNA: <>
        <path className="st0" d="M1281.82,1172.52l-0.32-0.54l-0.68-0.42l-0.27,0.14l-0.1,0.18l-0.01,0.16l0.01,0.07l0.53,0.31l0.62,0.14
  		l0.38,0.53l0.22-0.13l-0.08-0.19L1281.82,1172.52z M1283.01,1173.56l-0.34-0.27l-0.34,0.02l-0.08,0.6l0.36,0.25l0.42-0.18
  		L1283.01,1173.56z"/>
    </>,
    COM: <>
        <path className="st0" d="M2175.06,1416.25l0.11-1.74l-0.43-0.34l-0.36,0.05l-0.15,0.14l-0.21,0.35l-0.25,2.71l0.65,0.78l0.44,0.11
  		l0.77,0.48l0.16-0.11l0.22-0.33l-0.37-0.93L2175.06,1416.25z M2177.8,1421.7l-0.61-0.08l0.01,0.34l0.26,0.47l1.65,0.22l-0.59-0.52
  		L2177.8,1421.7z M2184.3,1420.22l-0.21-0.09l-0.33,0.18l-0.05,0.23l-0.24,0.39l-0.38,0.06l-0.36-0.07l-0.61,0.06l1.33,0.69l0.7,0.7
  		l0.38,0.18l0.18-0.28l0-0.88L2184.3,1420.22z"/>
    </>,
    KIR: <>
        <path className="st0" d="M531.14,1414.37l-0.09-0.01l-0.05,0.15l0.03,0.19l0.08,0.17l0.13,0.05l0.07-0.13l-0.07-0.23L531.14,1414.37z
  		 M504.33,1352.45l-0.24,0.08l0,0.06l0.17,0.14l0.2,0.12l0.1,0.07l0.02-0.05l0.05-0.27l0.06-0.12l-0.07-0.09L504.33,1352.45z
  		 M3266.47,1292.34l0-0.05l-0.64,0.4l0.16,0.04l0.53-0.28L3266.47,1292.34z M3264.92,1293.26l-0.23,0.01l0.17,0.17l0.62-0.32
  		l0.3-0.02l0.06-0.17l-0.51,0.02L3264.92,1293.26z M496.54,1365.62l-0.07-0.02l-0.08,0l0.01,0.09l0.11,0.11l0.23,0l0.2-0.04
  		l0.01-0.06l-0.08-0.07L496.54,1365.62z M467.42,1285.86l-0.18,0.15l-0.15,0.22l0.26,0.22l0.04-0.28h0.16l0.23,0.15l0.17,0.21
  		l-0.06,0.13l-0.38,0.06l0.22,0.12l0.48,0.03l0.13-0.36l-0.67-0.71L467.42,1285.86z M3266.95,1303.2l-0.31-0.43l-0.1-0.13
  		l-0.16-0.17l-0.13-0.09l-0.02,0.15l0.29,0.34l0.48,0.53L3266.95,1303.2z M3267.03,1304.03l-0.06,0.17l-0.23,0.12l0.3-0.04l0.13-0.2
  		l-0.02-0.31l-0.04-0.21l-0.12-0.04L3267.03,1304.03z M484.66,1302.17l-1.01-0.47l-0.42-0.04l1.07,0.7l-0.24,0.15l-0.36,0.67
  		l-0.61-0.32l-0.2-0.34l-0.4,0.21l1.34,0.96l1.47,0.47l0.59-0.07l-1.4-0.97L484.66,1302.17z M3237.53,1325.78l-0.02,0.11l0.16,0.09
  		l0.08-0.02l0.04-0.14l-0.14-0.08L3237.53,1325.78z M3395.95,1341.96l-0.27-0.16l-0.18-0.04l-0.05,0.05l-0.02,0.05l0.02,0.06
  		l0.06-0.02l0.1-0.05l0.15,0.05l0.13,0.07l0.1,0.09l0.17,0.26l0.01,0.15l-0.14-0.06l-0.19-0.13l-0.17-0.02l0.02,0.03l0.07,0.03
  		l0.14,0.12l0.2,0.09l0.16,0l0.01-0.07l-0.1-0.3L3395.95,1341.96z M3266.82,1310.07h-0.12l0.12,0.29h0.05l0.13-0.07l0.33,0.3
  		l-0.22,0.4l-0.58,0.6l0.18,0.06l0.59-0.67l0.15-0.26l0.06-0.23l-0.45-0.33L3266.82,1310.07z M3399.43,1355.83l-0.08,0.06l0,0.09
  		l0.06,0.06l0.1,0.01l0.08-0.04l0.01-0.08l-0.06-0.08L3399.43,1355.83z M3400.81,1344.71l-0.03,0.08l-0.04,0.1l0.06,0.05l0.04-0.07
  		l0.04-0.13L3400.81,1344.71z M3391.37,1356.47l-0.05-0.06l0-0.07l0.09-0.03h0.06l0.01-0.03l-0.01-0.03l-0.12,0.02l-0.14,0.11
  		l0.11,0.15l0.22-0.02l0.07-0.06l-0.11,0.01L3391.37,1356.47z M3371.96,1357.79l-0.16-0.13l-0.08-0.02l0,0.04l0.06,0.03l0.08,0.07
  		l0.1,0.1l-0.04,0.01l-0.11-0.04l0.05,0.07l0.14,0.03l0.05,0.01l0-0.05L3371.96,1357.79z M3279.21,1324.02l-0.3-0.13l-0.38-0.29
  		h-0.11l0.23,0.32l0.38,0.14l0.23,1.06l-0.13,0.26l0.1,0.21l0.27-0.23l-0.11-0.64L3279.21,1324.02z M3268,1307.09l-0.97,0.19
  		l-0.15,0.17l0.23-0.03l0.2-0.04l0.38-0.09l0.48,0l0.07-0.05l0-0.1l-0.16-0.1L3268,1307.09z M3281.67,1328.58l-0.19-0.33l-0.23-0.11
  		l0.2,0.43l0.06,0.43l0.06,0.17l0.19,0.06l-0.05-0.43L3281.67,1328.58z"/>
    </>,
    KHM: <>
        <path className="st0" d="M2677.28,1223l-0.11,0.3l-0.01,0.49l0.14,0.12l0.14,0l0.15-0.08l-0.07-0.89L2677.28,1223z M2679.1,1228.28
  		l0.49,0.68l0.31-0.33l-0.79-0.54L2679.1,1228.28z M2715.22,1210.66l-0.25-0.83l-0.05-0.83l0.59-1.66l0.51-1.81l-0.1-0.71
  		l-0.55-1.13l-0.56-1.38l-0.61-1.51l-0.23-0.23l-0.16-0.42l-0.09-0.49l0.24-1.55l0.03-0.52l0.71-0.71l0.38-0.81l0.35-0.89
  		l-0.13-0.47l-0.46,0.34l-0.42,0.87l-0.29,0.06l-0.74-0.32l-0.26,0.17l-0.47,0.63l-0.82,0.69l-0.4,0.01l-0.27-0.08l-0.32,0.3
  		l-0.46,0.54l-0.21-0.02l-0.79,0.13l-0.31-0.17l-0.38-0.45l-0.61-0.46l-0.56-0.33l-0.3-0.22l-0.27-0.38l-0.25-0.25l-0.46,0.54
  		l-0.77,0.52l-0.73-0.1l-0.36-0.09l-0.29,0.75l-0.21,0.13l-1.32,0.13l-0.25,0.12l0.21,0.68l0.78,1.16l0.24,0.67l-0.49,1.09
  		l-1.37-0.03l-0.62-0.44l-0.57-0.62l-0.2-0.31l-1.75-0.61l-1.17,0.42l-0.36-0.02l-0.55-0.44l-0.33-0.33l-0.33-0.5l-0.18-0.51
  		l-0.02-0.23l-0.12,0.09l-0.36,0.47l-0.44,0.46l-0.34,0l-0.25-0.23l-0.18-0.3l-0.11-0.65l-0.77-0.32l-0.84-0.2l-1.71,0.32
  		l-1.38,0.17l-1.55-0.22l-1.46,0.28l-0.61,0.04l-0.7-0.04l-0.68,0.01l-0.64-0.1l-1.19-0.4l-0.46,0.03l-0.96,0.33l-1,0.23l-0.96,0.16
  		l-1.42,0.68l-1.03,0.99l-0.3,0.7l-0.51,0.7l-0.71,1.11l-0.91,1.07l-0.64,0.49l0.18,0.29l-0.16,0.35l-1,0.15l-0.78,0.06l-0.14,0.17
  		l0.09,2.14l0.27,0.81l0.5,0.98l0.33,0.54l0.24,1.59l0.08,1.35l1.1,0.85l0.62,0.65l0.44,0.57l-0.15,0.36l-0.26,1.09l0.26,1.41
  		l1.53,3.04l0.13,0.22l-0.01-0.3l0.14-0.27l0.47,0.53l0.05,1.03l0.97,1.09l-0.15,0.79l-0.14,1.33l0.13,1.16l0.39,1.36l1.01,0.04
  		l0.69-0.1l0.49-0.47l0.47-0.91l0.55-0.53l0.53,0.33l0.5,0.42l0.57,1.43l-0.35,1.11l-0.75,0.32l-0.44,0.44l-0.07,0.54l0.46,0.44
  		l0.63,0.37l1.5-0.61l0.25-0.63l0.26,0.09l0.3,0.49l2.74,0.38l1.38,1.1l0.34-0.09l0.4-0.35l0.42-0.45l1.06-0.06l1.06,0.02l0.3-0.12
  		l0.43-0.47l0.7-0.61l0.53-0.34l0.12-0.27l-0.21-0.64l-0.12-0.66l0.2-0.21l0.96,0.12l1.05,0.31l0.26,0.14l0.61-0.8l0.16-0.1l0.4,0
  		l1.04-0.15l1.02-0.21l0.48,0.03l0.47,0.54l0.36,0.53l0.18,0.04l0.53-0.23l0.44,0.28l0.91,0.46l0.55,0.02l-0.27-1.08l0.31-0.76
  		l-0.06-0.21l-0.52-0.35l-1.75-1.41l-0.3-0.42l0.04-0.66l-0.06-0.97l-0.16-0.61l0.03-0.36l0.11-0.29l0.32-0.11l0.31-0.04l0.25-0.25
  		l0.42-0.64l0.82,0.06l1.15,0.36l0.85,0.22l0.5-0.04l0.11-0.09l-0.01-0.34l0.06-1.47l-0.03-0.31l0.72-0.15l1.11-0.03l0.58-0.09
  		l0.54-0.62l1.4-0.21l1.01-0.84l0.91-0.86l0.45-0.23l0.57-0.15l0.42,0.02l0.53,0.5l0.44-0.3l0.51-0.59l0.27-0.57l0.15-0.92
  		l-0.1-1.41L2715.22,1210.66z"/>
    </>,
    KGZ: <>
        <path className="st1" d="M2485.43,943.31l-0.17-0.61l-1.16-1.14l-1.26-1.11l-0.99-0.26l-1.73-0.18l-0.91-0.01l-0.52-0.26l-0.51-0.65
  		l-0.61-0.58l-0.78-0.62l-0.32-0.94l-0.32-0.17l-0.56,0.12l-0.94-0.03l-0.53-0.08l-0.79-0.16l-1.26-0.39l-0.99-0.37l-1.25-0.07
  		l-0.72,0.07l-2.25,0.07l-1.87-0.38l-1.51-0.07l-0.93,0.02l-0.45-0.05l-0.76,0l-1.12-0.08l-1.5-0.59l-0.58-0.03l-0.37,0.02
  		l-2.51,0.43l-1.16,0.1l-2.45-0.05l-2.41-0.05l-0.77-0.09l-0.43,0.05l-0.91,1.04l-0.39,0.16l-2.27-0.23l-2.68-0.69l-1.94-0.75
  		l-1.64-0.79l-2.18-1.26l-1.3-0.62l-0.19,0.36l-0.34,0.11l-0.5,0.06l-1.15-0.07l-0.53,0.64l-1.41,0.46l-0.9,0.41l-0.47,0.46
  		l-0.89,3.05l-0.24,1.11l0.6,1.87l-0.69-0.11l-0.81-0.48l-0.28-0.38l-0.78-0.23l-2.83-0.35l-0.53-0.43l-0.33-0.35l-0.73-0.23
  		l-1.04-0.18l-2.25-0.81l-0.96-0.03l-2.91-0.62l-0.47,0.01l-0.22,0.03l-1.13,0.41l-0.73,0.12l-0.78-0.16l-1.39,0.5l-0.75,0.67
  		l-0.62,0.82l-0.6,0.52l-0.18,0.77l-0.42,0.4l-0.5,0.8v0.47l0.45,0.46l0.27-0.18l0.48-0.18l0.79,0.35l0.7,0.44l0.17,0.2l-0.03,0.24
  		l-1.65,0.86l-1.03,0.4l-0.45,0.07l-0.12,0.11l-0.32,0.98l-0.64,0.18l-0.82,0.3l-0.57,0.45l-0.91,1.06l-2.31,1.54l-0.03,0.32
  		l0.2,0.26l0.75,0.18l0.99,0.47l0.54,0.37l1.47-0.48l0.36,0.11l0.38,0.49l0.4,1.38l0.66,0.38l0.86,0.29l0.53,0.09l0.71,0.34
  		l0.95,0.13l0.64-0.13l0.79,0.29l0.13-0.13l0.11-2.06l0.67,0.35l0.38-0.01l0.34-0.25l0.17-0.34l0.11-0.68l-0.15-0.68l0.3-0.31
  		l0.23-0.07l0.17,0.08l0.1,0.17l0.03,0.62l0.48,0.26l0.29,0.15l0.28,0.52l0.27,0.5l0.17,1.17l0.67,0.08l0.79,0.22l0.53-0.22
  		l0.41,0.13l0.14,0.55l0,0.52l0.06,0.41l0.22,0.12l0.69-0.26l0.58-0.04l0.53,0.24l0.66,0.37l0.96,0.98l0.32,0.14l1.45,0.08l0.3,0.2
  		l0.5,0l0.54-0.18l1.2,0.32l0.04,0.18l-0.2,0.25l-2.86,1.35l-0.21,0.42l-0.58,0.53l-0.64,0.3l-0.31,0.01l-1.39-0.53l-0.16,0.13
  		l-0.11,0.22l0.01,0.24l0.31,0.56l-0.14,0.35l-0.27,0.26l-0.87-0.22l-0.16-0.14l-0.02-0.16l-0.34,0l-0.52,0.16l-1,0.97l-0.35,0.51
  		l-0.13,0.31l-0.45,0.18l-0.48,0.07l-0.61,0.46l-0.68,0.35l-0.22-0.26l-0.13-0.29l-0.18-0.09l-0.42,0.07l-0.51,0.01l-0.53-0.33
  		l-0.68-0.33l-0.6-0.12l-1.77,0.16l-0.87,0.16l-0.28,0.16l0.02,0.18l-0.12,0.33l-1.75,0.56l-0.79,0.47l-0.17,0.84l-0.21,0.24
  		l-0.36,0.2l-0.35,0.05l-0.54-0.98l-0.61-0.2l-0.88-0.35l-1.71-0.67l-0.88-0.29l-1.7,0.44l-1.98,0.6l-0.31,0.37l-0.21,0.39l0.02,0.3
  		l0.14,0.4l-0.1,0.3l-0.37,0.1l-0.56-0.37l-0.49-0.21l-0.24,0.5l-0.29,0.9l-0.13,0.65l0.43,0.94l0.15,1.39l0.79-0.08l0.6,0
  		l1.14-0.41l0.57-0.01l0.89,0.18l1.55,0.04l1.23-0.07l0.3,0.03l0.29-0.26l0.32,0.09l0.3,0.32l1.24-0.39l0.92-0.05l0.56,0.11
  		l0.34,0.11l0.6,0.92l0.46,0.57l0.56,0.18l1.73-0.17l0.51-0.8l0.45-0.2l0.71-0.06l0.59-0.15l0.47-0.33l0.64-0.29l0.56-0.06
  		l0.28,0.21l0.12,0.28l-0.1,0.36l-0.02,0.38l0.36,0.25l1.06,0.06l0.5,0.24l0.03,0.45l-0.08,0.7l0.45,0.28l0.23,0.02l1.56-0.74
  		l0.44-0.01l0.35,0.41l0.53,0.49l0.7,0.52l0.17-0.08l0.32-0.57l0.59-0.62l1.12-0.2l0.62-0.2l0.64-0.09l1.96,0.25l0.65,0.03
  		l1.35-0.05l1.06-0.12l0.85-0.37l0.43-0.3l0.7-0.18l0.89,0.03l0.47,0.09l0.71-0.13l0.9-0.26l0.42-0.44l0.29-0.45l0.06-0.28
  		l-0.27-1.07l-0.36-0.48l-0.04-0.37l0.18-0.28l0.24-0.49l0.46-1l0.44-0.64l0.24-0.16l0.54-0.15l1.33-0.18l1.43-0.45l1.69-1.34
  		l0.56-0.38l0.74-0.19l0.53,0.01l0.1-0.16l-0.34-0.83l0.08-0.3l0.2-0.24l0.26-0.11l1.17,0.44l0.9-0.05l1.09-0.26l2.36-1.47
  		l0.29,0.02l0.24,0.2l0.29,0.88l0.32,1.86l0.18,0.23l1.64,0.03l1.11-0.68l0.49-0.16l0.79,0.11l0.42-0.32l0.44-0.22l0.51,0.78
  		l0.66-0.37l0.71-0.59l0.34-0.61l0.48-0.66l0.37-0.84l0.15-0.8l0.18-0.37l0.4-0.38l0.97-1.64l0.71-0.42l0.66-0.15l1.65,0.28
  		l0.86-0.04l2.51,0.22l1.16-0.32l0.81-0.31l1.19,0.05l1.41-0.25l1.88-2.06l0.02-0.44l0.11-0.47l0.68-0.46l0.84-0.42l1.68-1.01
  		l3.42-1.6l1.22-0.64l0.51,0.02l1.26-0.4l2.21-0.78l0.63-0.98l0.58-0.19l2.58-0.18l0.16-0.11l0.09-0.16L2485.43,943.31z
  		 M2404.43,966.72l-0.44-0.04l-0.34-0.37l-0.25-0.34l-0.05-0.19l0.13,0l0.58,0.16l0.82,0.11l0.29,0.1l0.03,0.2L2404.43,966.72z
  		 M2409.51,965.53l-0.08,0.14l-0.42,0.09l-0.77-0.01l-0.44-0.1l0.27-0.8l0.01-0.16l-0.03-0.02l-0.22-0.11l-0.34-0.33l-0.12-0.49
  		l0.13-0.45l0.25-0.19l0.16,0.03l0.47,0.69l0.42,0.2l0.83,0.11l-0.42,0.68L2409.51,965.53z M2414.26,965.1l-0.23,0.43l-0.4-0.1
  		l-0.31-0.28l0.1-0.22l0.47-0.12l0.24-0.12l0.21-0.02L2414.26,965.1z"/>
    </>,
    KEN: <>
        <path className="st0" d="M2155.75,1335.69l-0.92,0.61l-0.16,0.48l0.31-0.07l1.04-0.49l0.16-0.13l0.02-0.13l-0.07-0.14L2155.75,1335.69z
  		 M2161.24,1285.34l-2.15,0.13l-0.92,0.14l-0.45,0.03l-0.83,0l-0.68-0.16l-0.45-0.24l-0.56-0.55l-1.25-1.11l-0.9-0.69l-1.99,0.8
  		l-1.79,0.8l-2.54,1.13l-1.45,0.81l-0.44,0.81l-1.12,1.48l-1,0.91l-0.37,0.11l-2.27-0.19l-0.82-0.19l-1.35-0.17l-1.81-0.32
  		l-1.22-0.34l-1.32-0.04l-1.91-0.12l-1.17-0.25l-1.19-0.82l-1.53-0.99l-1.58-1.02l-1.63-1.05l-1.92-1.2l-2.1-1.32l-0.48-0.13
  		l-0.21-0.02l-2.28-0.06l-2.37-0.06l-1.6-0.04l-0.5-0.15l-0.36-0.3l-0.5-0.97l-0.63-0.69l-0.69-0.88l-0.06-1.2l0.19-1.3l0.18-0.43
  		l-0.1-0.43l0.02-0.59l-0.39-0.55l-2.33-0.63l-0.38,0.05l-0.39,0.24l-0.44,0.17l-0.32-0.16l-0.19-0.23l-0.01-0.38l0.04-0.29
  		l-1.55,1.51l-1.74,1.7l-2.01,1.96l-2.19,2.14l-1.71,1.69l-1.69,1.67l1.31,2.77l0.45,0.16l-0.06,0.24l-0.11,0.23l0.86,0.67
  		l1.06,0.35l0.38,0.34l0.03,0.37l-0.36,1.62l0.07,0.46l0.34,1.62l0.63,0.36l0.56,1.63l1.13,0.69l0.16,0.2l0.26,0.79l0.35,0.87
  		l0.27,0.2l0.16,0.05l0.33,0.92l-0.19,0.52l0.26,1.57l0.42,1.4l0.11,1.68l0.01,0.74l-0.01,0.45l-0.09,0.64l-0.2,0.37l-0.36,0.36
  		l-0.4,0.56l-0.35,0.6l-0.22,0.3l0.17,0.91l-0.04,0.24l-0.09,0.12l-0.51,0.14l-0.65,0.24l-0.4,0.24l-0.56,0.46l-0.45,0.5l-0.6,1.46
  		l-1,1.14l-0.17,0.38l-0.94,0.68l-0.41,0.84l-0.26,1.03l-0.37,0.74l-0.53,0.68l0,0l-0.01,1.04l0.07,0.54l0.18,0.41l0.22,0.32
  		l0.26,0.15l0.47-0.01l0.08,0.1l-0.15,0.35l0.08,0.25l0.91,0.8l0.5,0.76l0.21,0.05l0.2-0.16l0.16-0.2l0.14-0.59l0.42-0.39l1-0.44
  		l1.51-0.44l0.81,1.54l-0.13,0.17l-0.8,0.24l-1.07,0.04l-0.83,0.2l-0.16,0.13l0.14,0.8l-0.16,0.26l-0.27,0.06l-0.41-0.36l-0.4,0
  		l-0.45-0.12l-0.49-0.03l-0.36,0.24l-0.8,0.92l-0.16,0.55l0.07,0.67l0.32,0.61l0.48,0.68l-0.16,0.51l-0.19,0.29l-0.43,0.19
  		l-0.06,0.3l-0.06,0.07l0.64,0.35l1.79,1l1.8,0.99l1.8,1l1.8,1l1.8,1l1.79,1l1.8,1l1.8,1l1.8,1l1.79,1l1.8,1l1.79,1l1.8,1l1.8,1
  		l1.8,1l1.8,1l0.86,0.48l0.13,0.21l0.15,0.91l0.09,0.57l-0.05,0.5l-0.48,0.85l-0.14,0.44l0,0.31l0.12,0.12l0.4,0.04l0.34,0.2
  		l0.13,0.16l0.26,0.64l0.34,0.32l0.76,0.54l1.29,0.93l1.29,0.93l1.29,0.93l1.29,0.93l1.29,0.93l1.29,0.93l1.29,0.93l1.29,0.93
  		l0.63,0.45l0.27,0.13l0.05-0.22l0.5-0.48l0.75,0.14l0.96-1.23l1.23-2.73l0.18-0.28l0.24-0.43l0.38-0.63l0.12-0.32l0.13-0.35
  		l0.49-1.06l0.35-1.75l0.3-0.34l0.34-0.78l0.46-0.77l1.04-0.84l0.11-0.65l0.56-1.29l-0.13-1.68l0.36-1.09l0.47-0.5l1.06-0.61
  		l2.02-0.14l1.42-1.23l0.06-0.47l0.66-0.56l0.2-0.64l-0.14-0.47l-0.14-0.95l0.23,0.16l0.3,0.11l0.16-0.54l0.21-0.34l0.53,0.21
  		l0.41,0.06l1.35-0.31l1.01-0.65l1.23-1.44l0.04-0.69l-0.13-0.34l-0.8-1.03l-1.49-1.92l-1.13-1.45l-1.15-1.48l0-1.18l-0.01-3.53
  		l-0.03-7.05l-0.03-7.05l-0.03-7.05l-0.01-3.53l-0.01-1.44l0.12-0.23l1.32-1.29l1.74-1.71l2.29-3.26l1.24-1.77l1.04-1.48
  		L2161.24,1285.34z"/>
    </>,
    JPN: <>
        <path className="st0" d="M2898.56,1021.4l0.47-1.01l0.02-0.51l-1.03-0.34l-0.24,1.66l0.24,0.59L2898.56,1021.4z M2865.33,1106.73
  		l-0.17-0.43l-0.26-0.17l-0.12,1.24l0.76,0.14l0.71-0.23l-0.36-0.3L2865.33,1106.73z M2897.49,1031.93l-0.25-0.6l-0.12-0.61
  		l-0.63,1.53l-0.32,0.16l0.19,0.3l0.27,0.84l0.21-0.1l0.29-0.83l0.35-0.16l0.24-0.44L2897.49,1031.93z M2852.02,1110.41l-0.01,0.38
  		l-0.61,0.27l0.01,0.26l0.58,0.04l0.64,0.15l0.53-0.12l0.33-0.39l0.06-0.34l-1.38-0.47L2852.02,1110.41z M2855.86,1109.81
  		l-0.34,0.06l-0.42-0.16l-0.3,0.3l0.43,0.78l0.42,0.11l0.41-0.2l0.5-1.4l0.26-0.45l-0.19-0.18L2855.86,1109.81z M2895.8,1078.83
  		l-0.37,0.11l-0.21,0.5l0.15,1.04l0.47,0.23l0.35-0.17l0.15-0.45l-0.22-0.37L2895.8,1078.83z M2895.19,1034.83l-0.34-0.65
  		l-0.27-0.12l-0.38,0.03l-0.48,0.14l-0.33-0.25l-0.14,1.14l0.07,0.31l0.3,0.22l0.48,0.17l0.34-0.47l0.26-0.09l0.62-0.05
  		L2895.19,1034.83z M2898.54,1076.7l-0.17-0.29l-0.55-0.15l0.34,0.83l0.83,0.18l-0.05-0.21L2898.54,1076.7z M2889.6,1088.89
  		l-0.8,0.77l-0.2,0.39l-0.43,0.22l-0.14-0.03l-0.3-0.29l-0.73-0.13l-0.1,0.17l-0.03,0.39l0.46,0.33l-0.16,0.34l-0.89,0.81
  		l-0.21,0.16l-0.56,0.13l-0.02,1.13l-0.61,0.98l-0.04,0.4l0.03,0.54l0.64-0.02l0.63-0.5l0.02-0.17l-0.18-0.34l0.04-0.42l0.49-0.57
  		l0.47-0.08l-0.3-0.47l-0.02-0.56l0.71-0.13l0.73-0.69l0.75-0.17l0.3-0.49l0.81-0.41l0.44-0.61l0.17-0.82l-0.65-0.63L2889.6,1088.89
  		z M2984.29,943.86l0.19-0.05l0.2-0.16l0.45-1.4l-0.53,0.08l-0.54,0.28l-0.17,0.4l0.19,0.76L2984.29,943.86z M2997.56,911.11
  		l0.33-0.66l-0.02-0.7l-0.11-0.52l-0.46-0.16l-0.25-0.01l0.09,1.06L2997.56,911.11z M2999.18,912.75l0.58-0.07l0.28-0.32l-0.16-0.29
  		l-0.49-0.56l-0.49-0.16l-0.49,0.43l0.08,0.54L2999.18,912.75z M2974.39,985.66l-0.49,0.24l0.2,0.1l0.8-0.02l1.29-0.8l0.66-1.57
  		l-1.02-0.1l0.08-0.47l0.4-1.31l-0.05-0.56l-0.36,0.24l-1.31,1.27l-0.47,0.81l-0.03,0.82l0.64,0.04l0.01,0.21l-0.29,0.72
  		L2974.39,985.66z M2902.9,1024.93l-0.16-0.76l-0.5-0.28l-0.15,0.28l-0.21,0.84l0.44,0.31L2902.9,1024.93z M2899.77,1029.55
  		l-0.05,0.25l-0.39,0.52l0.43,0l0.59-0.44l0.14-0.57l0.52-0.72l-0.27,0.03l-0.64,0.25L2899.77,1029.55z M2898.71,1019.35l0.57,0.32
  		l0.17-0.46l-0.04-0.59l0.79-1.17l-0.05-0.71l0.09-0.32l-0.07-0.21l-0.18-0.14l-1.05,0.75l-0.03,0.27l0.06,0.54l-0.53,1.43
  		L2898.71,1019.35z M2932.63,1001.96l0.39-0.36l0.09-0.41l-0.72-0.9l-0.75,0.45l-0.14,0.58l0.42,0.52L2932.63,1001.96z
  		 M2940.44,1018.57l0.53-0.43l0.15,0.05l0.16-0.19l0.17-0.37l-0.39-0.11l-1.21,0.14l0.05,0.22l0.42,0.28L2940.44,1018.57z
  		 M2983.54,1015.23l-0.03,0.52l0.21,0.29l0.45,0.1l0.09-0.45l-0.25-0.47L2983.54,1015.23z M2986.98,1030.79l-0.07,0.17l0.06,0.27
  		l0.41,0.31l0.15-0.1l0.27-0.35l-0.55-0.33L2986.98,1030.79z M2946.41,1017.41l-0.48,0.24l-0.59,0.44l-0.65,0.99l-0.75,0.7
  		l0.13,0.45l0.4,0.36l0.79,0.06l0.91-0.81l-0.23-1.04l0.84-1.38L2946.41,1017.41z M2926.34,1021.48l-0.15-0.11l-0.14-0.43
  		l-0.17,0.08l-0.23,0.4l-0.3,0.33l0.74,0.11L2926.34,1021.48z M3006.75,1089.52l-0.15,0.04l0.53,0.95l0.15-0.01l0.12-0.29
  		l-0.34-0.55L3006.75,1089.52z M3037.29,930.44l-0.65,0.08l-0.76,0.62l-0.8,0.49l-0.44-0.03l-0.8-0.21l-0.57-1.64l-0.5-1.18
  		l-0.63-0.86l-0.32-1.05l0.21-1.08l1-2.13l0.9-1.58l0.15-0.53l0-0.48l-0.23-0.07l-2.03,1.73l-1.93,1.9l-0.62,0.43l-0.7,0.13l-1-0.02
  		l-0.97-0.2l-3.2-1.56l-0.81-0.16l-0.47,0.05l-1.05-0.05l-0.55-0.15l-0.88-0.92l-1.2-0.58l-1.88-1.23l-1.8-1.43l-1.6-1.4l-1.52-1.54
  		l-2.43-3.18l-2.06-2.09l-1.31-1.17l-0.3-0.48l-0.36-0.27l-0.5,0.27l-0.41,0.46l-0.43,0.21l-0.93,0.18l-0.12,0.26l-0.01,0.29
  		l-0.49,0.97l-0.09,1.04l0.61,1.09l0.53,1.14l0.53,2.33l-0.18,2.42l-0.38,1.15l-0.47,1.11l-0.14,2.52l-0.37,1.03l-1.3,1.75
  		l-0.41,1.09l0.01,1.33l0.12,1.34l-0.32,1.04l-0.66,0.82l-0.43,0.15l-0.9,0.05l-1.55-0.21l-1.13-0.05l-0.32-0.1l-1.65-0.99
  		l-0.82-0.27l-0.8,0.36l-0.11,0.67l0.15,0.71l0.75,1.2l-0.46,0.98l-0.87,0.89l-0.88,0.73l-0.92,0.64l-0.84,0.49l-0.54,0.14
  		l-0.5,0.23l-0.26,0.68l-0.27,1.35l-0.06,0.61l0.12,1.11l0.5,0.89l1.09,0.91l0.28,0.33l0.43,1.56l-0.2,1.1l-0.53,1.08l-0.22,1.2
  		l0.12,0.55l0.23,0.48l0.41,0.4l0.53,0.11l1.02-0.33l0.97-0.64l0.39-0.48l0.48-1.05l0.87-0.97l0.56-0.47l1.32,0.56l0.77,0.17
  		l0.77,0.06l0.67-0.23l0.61-0.46l-0.36-0.43l-1.65-1.31l-1.5-1.4l-0.42-0.07l-0.9,0.05l-0.42-0.13l-0.93-0.7l-0.76-0.94l-0.1-0.41
  		l0.07-0.42l0.23-0.6l0.29-0.53l0.8-0.73l0.57-0.1l0.58-0.02l0.78,0.16l0.66,0.56l1.35,1.42l0.32,0.18l3.54-2.07l3.75-0.33
  		l1.99,1.09l3.54,2.16l3.35,1.41l1.73,0.97l1.05,0.22l0.36-0.38l0.29-0.47l0.11-0.67l0.04-0.7l0.31-1.06l0.51-0.95l1.28-1.82
  		l1.53-1.52l1.75-1.35l1.92-0.94l0.88-0.11l1.81,0.42l0.96-0.03l1.49-0.48l0.96-0.07l0.9-0.31l0.83-0.58l0.87-0.48l0.99-0.42
  		l0.48-0.04l0.85,0.06l0.27-0.19l0.73-1.01l0.85-0.53l0.9-0.43L3037.29,930.44z M3005.63,967.18l-0.01-0.52l-0.11-0.52l-0.36-1.13
  		l-0.48-1.08l-0.68-2.22l-1.27-1.81l-0.88-0.57l-0.67-0.8l-0.27-1.12l-0.14-1.16l-0.11-2.57l0.17-1.55l0.3-1.54l-1.57,0.51
  		l-0.33-0.19l-1.04-0.83l-0.47-0.2l-0.95-0.3l-0.38,0.26l-0.27,0.55l-0.49,1.72l-0.01,1.15l2.27-0.54l0.38-0.15l0.34-0.28l0.38-0.07
  		l0.37,0.38l0.15,1.03l-0.31,1.14l-0.36,0.64l-0.54,0.42l-1.54-0.58l-0.5,0.11l-0.26,0.54l-0.38,0.41l-0.44,0.04l-0.39-0.27
  		l-0.19-0.35l-0.33-2.62l-0.1-0.4l-0.53-0.16l-0.56,0.06l-0.48-0.04l-0.47-0.2l-0.35,0.27l-0.25,0.42l0.24,1.55l-0.15,0.58
  		l-0.38,1.01l-0.24,0.37l-0.43,0.34l-0.46,0.23l-0.51,0.04l-0.47,0.14l-0.53,0.6l-0.37,0.74l0.01,0.64l0.34,1.19l0.42,0.99
  		l-0.03,0.54l-0.33,1.22l-0.54,1.14l-0.29,0.36l-0.4,0.19l-0.59,0.07l-0.12,0.38l0.58,0.43l0.68-0.09l0.45,0.02l0.42,0.3l0.51,1.05
  		l0.08,1.23l-0.14,1.59l-0.1,0.52l-0.22,0.52l-0.28,0.47l-0.32,0.37l-0.22,0.44l-0.28,1.22l-0.65,2.19l-0.45,0.92l-0.75,0.89
  		l-0.67,0.96l-0.5,0.94l-0.37,1l-0.26,1.28l-0.38,1.23l-0.31,0.42l-0.98,0.88l-3.05,1.61l-0.56,0.67l-0.41,1.08l-0.52,1l-0.64,0.86
  		l-0.71,0.78l-1.92,1.68l-0.86,0.44l-0.91,0.22l-1.65,0.84l-3.36,1.09l-0.26,0.26l-1.18,1.49l-0.38,0.16l-0.43,0.01l-1.03-0.2
  		l-0.9-0.61l-0.03-0.56l0.05-0.62l-0.2-0.65l-0.8-0.88l0.2-0.52l0.49-0.27l0.54-0.19l0.89-0.61l1.56-1.49l0.03-0.47l-0.16-0.35
  		l-1.04,0.24l-1.99,0.81l-1,0.31l-1.05,1.78l0.25,2.39l-0.43,2.02l-1.2,1.64l-1.66,2.02l-0.82,0.71l-0.89,0.62l-0.75,1.02
  		l-0.52,1.21l0.13,1.12l0.62,1.02l-0.67,0.81l-0.95,0.72l-0.91,0.55l-0.97,0.44l-0.66-0.14l-2.32-0.07l-0.5-0.24l-0.3-0.39
  		l0.31-0.65l-0.03-0.59l-0.38-0.18l-0.39-0.06l-2.46,0.8l-3.59,0.34l-1.01,0.48l-1.03,0.37l-1.96,0.31l-1.02,0.12l-1.02,0
  		l-1.04-0.15l-1.01,0.13l-0.5,0.24l-0.5,0.13l-0.92-0.93l-0.93-0.02l-1.97,0.45l-1.49,0.59l-0.41,0.29l-0.66,1.06l-1.73,1.43
  		l-1.3,1.27l-0.85,0.53l-0.79,0.63l-0.86,0.86l-0.9,0.79l-1.03,0.56l-1.06,0.49l-0.78,0.62l-0.7,0.76l-0.66,0.54l-0.78,0.19
  		l-1.09-0.13l-1.08,0.14l-0.44,0.41l-0.4,0.47l-0.13,0.36l0.25,2.7l0.65-0.3l0.64-0.13l0.66,0.42l0.69,0.26l0.76-0.16l0.72-0.36
  		l0.57-0.15l2.23-0.31l0.19,0.06l2.75,1.79l0.47,0.16l0.11-0.99l0.36-0.83l0.3-1.84l0.63-0.93l0.92-0.27l0.95,0.63l1.03,0.39
  		l0.99-0.09l2.06-0.7l1.04,0.26l0.57-0.39l1.06-0.39l0.92-0.45l0.25,0.03l0.88-0.33l0.84-0.2l1.67-0.08l0.77-0.31l0.89-0.62
  		l1.13-0.99l0.32-0.15l0.98-0.09l0.92-0.29l0.94-0.15l1.32,0.05l0.38,0.17l1.22,0.81l0.94,0.29l1.32-0.21l1.32-0.01l0.51,0.35
  		l-0.03,0.67l-0.23,0.44l-0.64,0.79l-0.37,0.34l-1.13,0.61l-0.27,0.27l0.3,1l-0.06,1.66l0.4,1.03l0.68,0.86l0.76,0.79l0.4,0.88
  		l0.49,0.71l2.04,0.62l1.86-0.7l1.32-2.04l1.64-2.99l0.52-0.77l1.81-0.76l2.09-0.39l0.52-0.23l0.23-0.53l-0.01-0.5l-0.33-0.29
  		l-1.9-1.18l-0.7-0.85l0.37-1.05l0.95-1.85l0.45-0.37l0.51-0.26l0.4-0.09l0.38,0.23l-0.37,0.54l0.03,0.63l0.24,1.01l-0.11,0.69
  		l0.35,0.23l0.26-0.12l-0.08-0.89l0.24-0.19l0.36,0.2l0.22,0.46l0.54,0.07l1.06-0.15l0.44,0.02l0.17,0.43l-0.06,0.23l-1.78,0.78
  		l-0.13,0.37l2.16-0.51l1.9-0.26l1.73,0.16l0.97-0.03l0.97,0.09l1.77,0.42l0.54-1.29l0.8-1.09l0.71-0.64l0.64-0.68l0.23-0.54
  		l0.34-0.4l1.2-0.36l0.85,0.27l0.7,0.67l-0.85,0.48l0.01,0.94l-0.37,1.67l0.29,0.46l0.36,0.3l0.5-0.09l0.72-0.66l0.28-0.36
  		l0.59-0.98l0.3-1.11l-0.05-1.34l0.15-0.55l0.24-0.53l0.73-0.64l0.96-0.19l0.93,0l0.76,0.53l0.61,0.96l0.33-0.07l0.47-0.69
  		l0.11-0.29l-0.37-0.21l-0.29-0.43l-0.13-0.85l0.99-0.82l0.05-0.24l-0.03-0.28l0.14-0.6l0.41-0.44l0.63-0.1l0.65,0l0.47,0.33
  		l0.45,0.46l-0.09,0.43l-0.5,0.53l-0.7,0.59l-0.32,0.74l-0.67,0.46l0.21,0.61l-0.18,1.52l0.11,0.59l-0.37,0.5l0.38,0.4l0.64,0.14
  		l0.33-0.45l0.84-0.86l0.84-0.55l1.31-0.56l0.34-0.25l0.32-0.38l0.2-0.44l-0.03-1.22l0.37-1.1l1.18-1.16l0.36-0.28l1.98-0.61
  		l-0.51-0.55l-0.45-0.6l-1.16-2.04l-0.27-0.8l-0.14-0.85l0.15-0.73l0.23-0.75l0-0.57l0.07-0.55l0.86-2.21l0.52-1.11l0.4-0.42
  		l0.47-0.34l0.62-0.74l0.28-1.09l0.29-3.41l-0.28-2.24l-0.37-1.21l-0.27-1.24l0.01-1.01l0.28-0.94l0.97-1.6l0.26-0.25l1.23-0.42
  		l0.96,0.02l0.84-0.24l0.35-0.92l0.08-1.31l0.23-1.28l0.28-0.53l0.36-0.48l0.19-0.52l0.12-0.56l0.29-0.2l0.41-0.04l0.28-0.17
  		l0.26-0.23l0.3-0.49l0.49-0.21l0.07-1.06l0.57-2.07l0.14-1.79l-0.12-0.57L3005.63,967.18z M2924.5,1024l0.43-0.31l0.29-0.31
  		l-0.12-0.1l-0.98,0.14l-0.41-0.35l-0.49-0.03l-0.06,0.19l0.07,0.52l0.49,0.01L2924.5,1024z M2907.83,1037.35l0.32-0.32l0.36-0.54
  		l-0.81-0.11l-1.04,0.6l0.12,0.3l0.31,0.11L2907.83,1037.35z M2906.28,1036.71l-0.24-0.46l-1.33,0.18l0.1,0.49l-0.35,1.14l0.3,0.3
  		l0.02,0.2l-0.48,0.45l0.28,0.15l0.09,0.32l0.67-0.33l0.99-1.03L2906.28,1036.71z M2908.81,1055.49l-0.92,0.71l0.48,1.14l0.53,0.21
  		l0.96-0.2l0.43-0.96l-0.25-0.2L2908.81,1055.49z M2902.16,1043.91l-0.17,0.73l0.26-0.16l0.64-0.79l-0.05-0.41L2902.16,1043.91z
  		 M2901.24,1073.7l-0.18,0.13l-0.14,0.27l-0.43,0.3l-0.38,0.02l-1.2,0.32l-0.6,0.42l-0.03,0.28l-0.26,0.18l-0.44,0.12l0.93,0.44
  		l0.77,0.66l0.73-0.74l-0.11-0.42l0.15-0.16l0.47-0.24l0.52-0.57l0.57-0.46l0.58-0.19l0.04-0.34l-0.21-0.44L2901.24,1073.7z
  		 M2913.38,1052.24l-0.23,0.25l-0.55,1.12l-0.06,0.89l-0.59,1.21l0.02,0.53l0.74-0.1l0.28-1.22l0.55-1.04l0.21-1.37l-0.19-0.35
  		L2913.38,1052.24z M2944.17,1023.24l-0.34-0.52l-0.17-0.58l0.02-1.72l-1.19,0.11l-1.17-0.39l-1.16-0.59l-1.21-0.37l-1.07,0.1
  		l-1.03,0.39l-1,0.66l-0.43,0.04l-0.45-0.1l0.34,1.03l-0.14,0.62l-0.38,0.49l-0.93,0.42l-1.03-0.04l-0.43,0.08l-0.89,0.34l-0.5,0.06
  		l-0.69-0.66l-0.51-0.86l-0.46-0.07l-0.81,0.7l-0.46,0.27l-0.27,0.81l-0.3,0.51l-0.15,0.58l-0.47,0.95l-0.9,0.54l-1.43,1.13
  		l-0.66,0.4l-1.46,0.7l-0.69,0.51l0.45,0l1.64-0.72l0.79-0.16l0.32,0.03l-0.06,0.93l0.34,0.25l0.56,0.11l0.03,0.35l-0.08,0.41
  		l-0.24,0.28l-0.01,0.51l-0.4,0.63l0.55,0.48l0.02,0.85l0.9-0.03l0.9,0.16l-0.57,1.31l0.42-0.13l0.94,0.22l0.55-0.02l0.9-0.82
  		l0.33-1.33l0.3-0.27l0.42-0.15l0.38-0.51l0.79-1.56l0.39-1.04l2.91-1.42l0.45-0.05l1.42,0.22l0.88,0.42l1.39,1.52l0.48,0.37
  		l0.2-0.94l0.31-0.87l0.54-0.82l0.59-0.77l1.45-1.14l1.6-0.86L2944,1024L2944.17,1023.24z M2920.94,1031.87l0.1-0.35l-0.39-0.38
  		l-0.46-0.28l0.06-0.6l0.35-0.68l-1.51,0.02l-1.51-0.21l0.66-1.11l0.8-1.04l0.11-0.48l-0.24-0.46l-0.45-0.33l-0.51-0.14l-0.71,0.27
  		l-0.68,0.37l-0.44,0.13l-0.48-0.02l-1.13-0.28l-0.98-0.66l-0.41-0.97l-0.47-0.91l-0.96-0.43l-1.04-0.09l-0.39,0.12l-1.56,0.76
  		l-0.22,0.43l-0.15,0.52l-0.63,0.94l-0.76,0.35l-0.9-0.01l-0.32,0.19l-0.23,0.36l-0.26,0.17l-1.29,0.36l-0.79,0.44l0.09,0.31
  		l0.18,0.27l-0.11,0.5l-1.2-0.36l-0.35-0.05l-0.42,0.2l-0.25,1.01l0.72,0.47l1.13,0.97l0.83,0.57l0.21,0.32l0.59,1.28l-0.77,0
  		l-0.61-0.38l-0.43-0.87l-0.83-0.7l-0.14,0.61l0.05,0.61l0.19,0.51l0.81,0.88l0.34,0.53l-0.16,0.75l-0.33,0.69l0.71-0.47l0.83-0.93
  		l0.87-0.46l0.83,0.21l0.34,0.39l0.44,0.27l0.44,0.02l0.36-0.25l0.17-0.5l-0.06-0.51l-0.23-0.4l-0.39-0.13l-0.49,0.19l-0.4-0.04
  		l-0.06-0.75l0.05-0.76l-0.39-0.86l0.42-0.37l0.51-0.31l0.42,0.21l0.8,0.58l0.49,1.32l0.9,1.12l0.19,0.91l-0.6,0.72l0.55,0.29
  		l0.65,0.07l-0.68,1.52l-0.83,1.41l-0.56,0.8l-0.64,0.7l-0.88,0.26l-0.17,0.23l0.01,1.31l0.13,0.94l-0.19,0.74l0.31,0.36l0.38,0.31
  		l0.45,0.88l-0.13,1.05l-0.11,0.34l-0.28,0.13l-0.95,0.26l0.45,1.08l0.42,0.17l0.51,0.06l2.34,0.82l0.28-0.36l0.19-0.46l-0.66-0.79
  		l-0.21-0.47l-0.1-0.52l0.24-0.96l0.48-0.95l0.35-0.49l0.5,0.01l0.52,0.11l0.17,0.32l0,0.44l-0.14,0.19l-0.26,0.05l-0.38,0.19
  		l0.04,0.48l0.55,1.32l0.13,1.06l-0.26,1.05l-0.19,0.31l-0.26,0.26l-0.17,0.32l0.01,0.41l1.82-0.9l1.65-1.33l-0.53-1.13l0.3-0.55
  		l0.58-0.05l0.93,0.3l0.74,0.05l1.03-2.47l0.13-1l-0.13-0.98l0.38-1.1l0.21-1.07l0.28-0.99l0.38-0.95l0.42-1.31l0.6-1.19l2.06-2.35
  		l0.22-0.36l0.05-0.34l-0.83-0.51L2920.94,1031.87z"/>
    </>,
    JOR: <>
        <polygon className="st1" points="2140.26,1038.03 2139.36,1038.21 2138.53,1038.45 2138.02,1036.89 2138.67,1036.7 2138.07,1034.67
  		2137.46,1032.57 2136.87,1030.55 2136.27,1028.47 2134.1,1029.74 2131.9,1031.03 2130.23,1032.01 2127.68,1033.55 2126.2,1034.46
  		2124.01,1035.79 2122.08,1036.96 2119.8,1038.34 2116.95,1037.93 2116.04,1037.69 2115.3,1037.03 2114.76,1036.68 2113.41,1036.32
  		2112.54,1035.08 2112.02,1034.64 2111.12,1034.44 2110.67,1034.5 2109.64,1034.94 2109.5,1035.07 2109.31,1035.32 2109.28,1035.52
  		2109.13,1037.61 2109.31,1039.08 2108.99,1040.34 2108.96,1041.44 2109.2,1043.47 2108.69,1044.34 2108.41,1045.36
  		2108.28,1046.13 2108.05,1047.56 2108.06,1047.56 2107.86,1048.43 2107.94,1048.58 2108.19,1049.34 2107.71,1050.73
  		2107.18,1051.86 2107,1052.39 2106.48,1053.58 2105.95,1054.96 2105.67,1055.9 2105.74,1056.24 2105.61,1057.98 2105.68,1058.47
  		2105.06,1059.98 2104.94,1060.72 2104.69,1061.73 2104.26,1063.85 2104.34,1064.5 2104.07,1065.7 2105.87,1065.99 2107.34,1066.24
  		2109.5,1066.6 2111.73,1066.97 2113.04,1067.18 2113.49,1067.09 2115.29,1065.68 2116.92,1064.4 2117.9,1062.83 2118.84,1061.32
  		2119.27,1061 2120.72,1060.72 2123.01,1060.27 2125.29,1059.82 2125.47,1059.67 2126,1058.45 2126.67,1056.89 2126.81,1056.73
  		2126.97,1056.57 2128.6,1055.7 2129.59,1055.18 2128.18,1053.62 2126.85,1052.14 2125.37,1050.49 2124.11,1049.21 2122.22,1047.28
  		2120.98,1046.02 2123.15,1045.42 2125.49,1044.77 2127.85,1044.12 2130.69,1043.33 2132.92,1042.71 2136.24,1041.79
  		2137.86,1041.34 2138.15,1041.23 2139.4,1040.14 2140.64,1039.02 	"/>
    </>,
    JAM: <>
        <polygon className="st1" points="1167.63,1166.66 1166.65,1165.09 1163.69,1164.18 1162.91,1163.77 1161.94,1163.02 1161.51,1162.92
  		1161.05,1162.91 1159.99,1162.75 1158.97,1162.44 1158.19,1162.36 1157.37,1162.36 1153.81,1161.88 1153.36,1162.07
  		1152.93,1162.35 1151.95,1162.55 1150.92,1162.52 1150.62,1162.71 1150,1163.37 1149.89,1163.92 1150.27,1164.51 1152.13,1164.75
  		1152.37,1164.9 1153.06,1165.99 1153.75,1166.23 1154.02,1166.51 1154.7,1167.46 1155.52,1167.61 1157.26,1167.64 1158.13,1167.84
  		1158.81,1168.3 1159.44,1168.86 1159.84,1167.74 1160.16,1167.44 1160.57,1167.25 1160.87,1167.66 1161.64,1167.71
  		1162.04,1167.23 1162.41,1166.63 1162.9,1166.6 1163.29,1166.7 1163.07,1166.91 1163.95,1167.03 1164.32,1167.26 1165.17,1167.56
  		1166.09,1167.54 1167.05,1167.44 1167.82,1167.15 	"/>
    </>,
    ITA: <>
        <path className="st0" d="M1879.49,954.26l0.28-0.23l0.21-0.39l-0.2-0.2l-0.44,0.23l-0.37,0.68l-0.15,0.34l0.4,0.03L1879.49,954.26z
  		 M1891.25,956.48l-0.42-0.64l-0.49-0.33l-0.17-0.17l0.3-0.6l0.27-0.12l-0.05-0.12l-0.33-0.13l-0.31-0.23l-0.32-0.53l-0.38-0.44
  		l-0.88-0.46l-0.57-0.06l-0.46-0.56l-0.39,0.15l-0.16,0.57l-0.47,0.42l-0.92,0.33l-1.49,1.6l-1.03,0.55l-1.07,0.45l-0.87,0.16
  		l-0.89-0.12l-0.45-0.11l-0.55-0.49l-0.18-0.06l-0.17,0.43l-0.19,0.99l0.08,1.19l0.34,0.45l0.55,0.47l0.49,0.58l0.27,0.58l0.2,0.9
  		l0.52,0.59l0,1.61l-0.13,0.53l-0.39,0.4l-0.08,0.59l0.07,0.6l0.74,0.2l0.44,0.58l-0.08,0.69l0.02,0.38l-0.25,0.1l-0.33-0.26
  		l-0.17,0.26l0.08,0.73l-0.12,0.84l-0.24,0.39l-0.16,0.41l0.1,1.87l0.06,0.85l0.57,0.94l0.57,0.79l0.35,0.65l0.45,0.37l0.59,0
  		l0.7,0.17l0.68-0.03l0.72-0.5l0.47-0.78l0.28-1.93l0.38,0.27l0.4,0.14l0.49-0.17l0.48-0.03l1.04,0.48l0.83,0.27l0.64-0.26
  		l0.18-0.86l0.28-0.99l0.58-5.63l0.17-0.92l-0.05-0.74l-0.35-0.67l-0.14-1.08l0.94-1.31l0.24-0.41l0.19-0.58l-0.09-0.56
  		L1891.25,956.48z M1897.53,936.32l-0.28-0.4l-0.3,0.36l-0.62-0.06l-0.32,0.13l-1.02,0.06l-0.15,0.26l0.18,0.44l0.65,0.05l1.07-0.25
  		l0.7,0.49l-0.08-0.59l0.19-0.26L1897.53,936.32z M1880.17,973.33l-0.07,0.17l0.02,0.59l0.51,0.69l0.48-0.97l-0.32-0.23
  		L1880.17,973.33z M1965.19,962.42l-1.12-1.49l-2.46-1.93l-0.68-0.9l-4.05-1.85l-1.68-1.35l-1.45-0.87l-4.65-1.7l-4.54-2.04
  		l-0.94-0.77l0.11-1.09l1.01-0.8l0.99-0.58l0.32-0.56l-0.21-0.83l-0.87-0.32l-0.82-0.11l-4.71,0.26l-1.99-0.21l-2.55-1.2l-2.74-1.94
  		l-3.02-2.65l-1.45-1.86l-0.72-1.65l-1.01-3.35l-0.94-2.14l-1.09-1.86l-0.47-0.41l-1.79-0.76l-3.27-2.41l-1.82-0.76l-1.72-1.44
  		l-0.76-0.92l-0.77-2.12l-0.48-3.03l0.26-1.14l0.34-0.01l0.55,0.36l0.67-0.49l0.29-0.56l0.21-0.71l-1.1-0.75l-0.89-1.75l-0.51-0.35
  		l0.19-1.33l0.21-0.81l0.67-0.48l1.16-0.57l0.37,0.01l-0.88,0.8l0.55,0.07l0.96-0.37l1.26-0.49l1.19-0.7l1.07-0.28l0.76-0.63
  		l0.31-0.51l0.42-0.26l2.18,0.64l0.78-0.64l0.59,0l1.31,1.5l-0.53,0.41l0.47,0.06l0.58-0.11l0.25-0.23l-0.37-0.69l-0.92-0.85
  		l-0.49-0.32l-0.67-0.21l-0.12-0.23l0.37-1.34l-0.11-0.19l-0.77,0.06l-0.18-0.14l-0.06-0.23l0.05-0.32l0.52-0.52l0.58-0.46
  		l0.15-0.26l-0.02-0.2l-0.74-0.21l-0.45-0.21l-0.35-0.07l-0.24,0.12l-0.18-0.13l-0.18-0.39l0.18-0.59l0.67-0.54l0.71-0.48l0.62-0.35
  		l0.36-0.15l0.17-0.61l-1.77-0.37l-1.17-0.02l-1.54-0.16l-3.06-0.56l-0.89-0.23l-0.85-0.07l-1.01-0.19l-0.77-0.32l-0.49-0.61
  		l-0.52-0.81l-0.96-1.05l-0.2-0.53l0.29-0.46l0.3-0.35l-0.03-0.15l-0.23-0.08l-1.68,0.45l-1.63,0.57l-0.64,0.02l-0.62-0.13
  		l-0.83-0.01l-0.79,0.15l-1.59,0.08l-0.93,0.42l-0.59,0.82l-0.32,0.66l-0.27,0.21l-0.56,0.08l-0.83-0.06l-0.58-0.19l-0.59-0.56
  		l-0.92-0.08l-0.85-0.02l-0.22-0.1l-0.39,1.38l-0.07,0.74l0.34,0.49l0.04,0.38l-0.1,0.35l-0.57,0.03l-0.77-0.19l-0.65-0.59
  		l-0.49,0.07l-0.43,0.15l-0.22,0.56l-0.19,0.67l0.06,0.37l0.31,0.29l0.23,0.61l0.17,0.79l0.13,0.36l-0.14,0.16l-0.4,0.11l-0.33-0.11
  		l-0.58-0.94l-0.27-0.36l-0.46-0.06l-0.81,0.23l-1.25,0.53l-0.5,0l-0.43-0.11l-0.4-0.45l-0.34-0.87l-0.11-0.54l-0.24,0.02l-0.8-0.16
  		l-0.37,0.21l0,0.89l-0.07,1.1l-0.4,0.71l-1.12,1.23l-0.41,0.54l-0.16,0.38l-0.03,0.33l0.17,0.58l0.23,0.55l-0.19,0.31l-0.59,0.16
  		l-0.42-0.33l-0.16-0.59l-0.9-0.81l0.41-0.68l-0.07-0.17l-1.49-0.35l-0.64-0.52l-0.9-0.91l-0.17-0.39l0.04-1.26l-0.05-0.31
  		l-0.12-0.15l-0.44,0.01l-0.61,0.44l-0.56,0.66l-1.15,0.74l-0.12,0.16l0.39,0.72l-0.02,0.28l-0.93,1.15l-0.18,0.38l-1.19,0.72
  		l-0.54,0.27l-1.65-0.53l-0.45-0.06l-0.73,0.35l-1.04,0.34l-1.68,0.33l-0.62-0.25l-0.29-0.23l-0.68,0.6l-1.15,0.56l-0.13,0.36
  		l0.01,0.41l0.13,0.32l0.63,0.41l0.68,0.94l0.43,0.84l0.95,0.8l0.23,0.24l-0.06,0.2l-0.25,0.34l-0.32,1.14l-0.39,0.18l-0.43,0.07
  		l-1.17,0.83l-0.52-0.1l-0.74,0.01l-0.54,0.27l0.06,0.52l0.48,0.47l0.28,0.52l0.11,0.54l0.53,0.4l0.74,0.24l0.43,0.02l0.28,0.14
  		l0.17,0.18l0.32,1.15l-0.19,0.29l-0.4,0.12l-0.24,0.47l-0.48,0.69l-0.27,0.56l0.3,0.49l0.13,0.36l-0.16,0.37l0.21,0.58l0.56,0.57
  		l1.53,0.81l1.42,0.66l0.44,0.11l1.92-0.42l0.32,0.04l0.23,0.5l0.1,0.34l-0.22,0.51l-0.52,0.7l-0.56,0.56l-0.34,0.47l0.07,0.43
  		l0.02,0.57l2.02-0.36l2.29-0.76l0.65-0.43l1.77-2.24l2.19-2.16l1.8-0.79l1.39,0.15l2.24,0.88l0.79,0.04l3.72,2.25l2.67,0.84
  		l1.18,0.75l0.49,0.98l0.63,3.48l1.07,1.45l0.62,1.71l0.1,0.65l-0.13,0.76l-0.02,0.99l0.64,0.14l0.46-0.04l0.54,0.21l0.24,0.37
  		l0.24,0.56l0.32,0.41l1.13,0.67l1.94,2.07l0.14,0.8l-0.36,0.13l-0.32,0.28l0.32,0.27l0.4-0.03l0.51-0.23l0.39-0.08l1.7,0.61
  		l1.17,0.76l1.43,2.08l2.26,1.42l1.1,1.29l3.58,3.45l1.84,0.61l1.47,1.08l0.14,0.35l0.4,0.22l0.8-0.34l0.53-0.11l0.97,0.1l1.62,0.46
  		l0.97-0.22l0.54,0.19l1.06,1.06l1.58,2.6l-0.03,0.58l0.27,0.18l0.22-0.33l0.38,0.06l1.36,0.08l1,0.53l0.27,0.31l-1.02,1.29
  		l0.36-0.01l0.65-0.33l0.82,0.06l0.46-0.18l1.3-0.24l0.62,0.38l0.57,0.74l0.34,0.86l0.32,0.91l-0.48,0.67l-0.02,0.44l0.2,0.26
  		l2.89,1.68l0.81,0.18l1.64-0.01l0.91,0.62l0.6,1.19l0.76,2.41l1.43,2.69l0.4,2.11l0.3,1.14l0.86,0.81l-0.11,1.78l-1.11,0.22
  		l-0.78,0.23l-0.38,0.4l-0.4,0.57l0.22,1.28l-0.69,1.76l-1.03,0.4l-0.48,0.85l0.02,1.38l0.66,0.93l2.8-0.03l0.45-0.75l0.29-0.66
  		l1.16-1.59l2.22-1.56l0.24-0.83l-0.13-2.16l0.49-0.84l1.17-0.88l1.65-0.49l1.24,0.2l0.64-0.77l-0.44-1.36l-0.07-2.4l-0.97-0.99
  		l-1.47-0.95l-1.91-0.6l-0.64-1.07l0.07-1.11l1.18-2.75l1.16-1.88l1.02-1.31l0.87-0.55l1.25,0.11l0.3,0.16l0.29,0.48l0.07,0.39
  		l1.16,0.58l0.68,0.25l3.28,0.35l1.79,3.4l1.19,0.84l1.05,0.31l0.42-0.81l0.25-0.82l0.53-1.17L1965.19,962.42z M1914.86,925.26
  		l-0.5,0.08l-0.25-0.42l0.37-0.49l0.53-0.08l0.09,0.38L1914.86,925.26z M1910.98,995.66l-0.64-0.21l-0.1,0.14l0.04,0.47l0.53,0.33
  		l0.4-0.11l-0.03-0.19L1910.98,995.66z M1927.1,957.6l0.19-0.13l0.01-0.21l-0.77-0.22l-0.15,0.38l0.12,0.15l0.22,0.12L1927.1,957.6z
  		 M1940.24,981.41l-1.33,0.72l-0.51-0.13l-0.47,0.19l-0.4,0.42l-0.48,0.15l-1.15-0.14l-1.15-0.04l-0.47,0.05l-0.44,0.16l-0.85,0.64
  		l-1.1,0.39l-0.76,0.03l-1.08,0.25l-2-0.23l-0.96,0.16l-1.24,0.42l-0.45-0.03l-0.45-0.16l-1.6-1l-0.49-0.07l-0.42-0.16l-0.27-0.52
  		l-0.51-0.11l-1.1,0.01l-0.87,0.58l-0.07,0.46l-0.79,0.42l-0.44,0.06l-0.44-0.28l-0.98-1.16l-0.27,0.4l-0.32,0.33l-0.53,0.22
  		l-0.45,0.31l-0.51,1.11l-0.43,1.16l0.16,0.45l0.61,1.01l0.96,0.73l0.5,0.22l0.49,0.04l0.96-0.08l0.45,0.05l0.98,0.62l1.09,0.26
  		l0.43,0.27l0.37,0.4l0.81,0.6l1.9,0.92l1.8,1.14l0.88,0.34l1-0.06l1,0.03l0.98,0.55l0.91,0.71l1.13,1.68l0.45,0.21l0.5,0.1
  		l1.36,0.54l0.96-0.13l0.95,0.29l0.93,0.06l0.03-0.47l-0.1-0.47l0.1-0.52l0.22-0.5l0.36-0.42l0.92-0.76l0.01-0.4l-0.06-0.4
  		l-0.44-0.4l-0.52-0.68l0.47-0.34l-0.31-0.37l-0.4-0.24l-0.24-0.26l-0.1-0.39l-0.05-0.8l0.27-0.71l0.28-0.56l0.21-0.59l0.14-0.68
  		l0.23-0.62l2.03-2.71l0.28-0.43l0.57-1.11l0.49-0.46l-0.56-0.28L1940.24,981.41z"/>
    </>,
    ISL: <>
        <polygon className="st1" points="1695.5,675.83 1695.32,675.58 1693.85,674.89 1694.25,674.58 1694.55,674.48 1694.74,674.28
  		1694.82,674 1694.81,673.76 1694.71,673.56 1694.12,673.54 1693.71,673.11 1693.43,672.66 1693.61,672.46 1694.25,672.29
  		1694.58,672.05 1694.7,671.47 1695.02,670.83 1695.01,670.39 1694.56,669.97 1694.27,669.96 1693.59,670.2 1693.13,669.47
  		1692.33,669.05 1690.38,668.68 1689.24,668.89 1687.8,669.61 1689.02,668.46 1689.11,668.1 1688.83,667.74 1688.68,667.35
  		1688.65,666.91 1688.49,666.67 1688.2,666.63 1685.41,667.11 1684.82,666.99 1684.72,666.76 1685.45,666.02 1685.99,665.15
  		1686.15,664.27 1686.11,663.85 1685.91,663.43 1685.55,663 1685.16,662.88 1684.26,663.18 1683.28,662.85 1682.38,662.28
  		1682.38,661.95 1683.11,661.22 1684.1,659.73 1685.15,659.07 1685.88,658.92 1686.78,658.47 1686.77,658.36 1686.05,658.44
  		1684.58,658.37 1683.62,658.67 1682,659.77 1681.33,660.08 1679.76,660.56 1678.79,660.51 1677.91,660.09 1677.44,659.71
  		1677.35,658.69 1676.96,658.22 1676.2,657.64 1675.06,656.49 1674.64,656.33 1672.84,656.38 1670.79,656.96 1670.39,657.45
  		1671.34,659.81 1671.26,660.18 1670.86,660.97 1669.68,661.31 1668.64,661.87 1667.88,661.96 1667.15,661.7 1666.78,661.37
  		1665.99,660.95 1665.55,660.83 1665.23,660.87 1663.7,662.47 1663,663.35 1662.59,663.71 1661.98,664.01 1661.88,664.21
  		1661.62,664.11 1661.18,663.72 1659.61,662.11 1658.88,661.71 1656.58,661.46 1655.59,661.51 1655.42,661.91 1655.44,662.41
  		1656.84,665.03 1657.26,666.07 1657.23,666.86 1657.1,667.18 1656.9,667.41 1656.72,667.38 1656.55,667.08 1655.76,665.32
  		1654.26,664.2 1653.09,662.71 1652.15,661.81 1651.55,661.35 1650.97,661.14 1650.42,661.18 1649.73,661.47 1648.89,662.01
  		1648.03,662.34 1646.45,662.65 1646.08,663.18 1645.83,663.92 1646.02,666.29 1645.94,666.66 1645.78,666.88 1645.55,666.94
  		1644.67,666.79 1644.22,666.48 1643.33,665.55 1642.31,664.68 1641.03,663.02 1640.39,662.58 1639.5,662.31 1638.79,662.53
  		1638.25,663.24 1638.1,664.44 1638.35,666.11 1638.25,667.62 1637.79,668.97 1637.42,669.67 1637.15,669.73 1636.63,669.55
  		1635.78,668.52 1635.53,668.4 1635.02,668.46 1634.48,668.77 1633.34,669.75 1633.01,671.05 1632.85,671.42 1632.65,671.62
  		1632.43,671.64 1632.19,672.24 1631.94,673.42 1631.74,673.88 1631.46,673.36 1630.89,671.75 1630.2,671.23 1629.75,671.13
  		1629.28,671.18 1629.19,671.01 1629.49,670.63 1629.68,670.15 1629.76,669.57 1629.57,669.38 1629.13,669.56 1629,669.48
  		1629.18,669.14 1628.9,668.78 1627.69,668.15 1627.28,667.56 1628.98,667.91 1629.35,667.7 1629.67,667.3 1630.27,665.43
  		1630.23,665.17 1629.56,664.56 1628.64,664.34 1628.48,664.16 1629.18,663.85 1629.49,663.58 1629.4,663.35 1627.56,662.46
  		1625.75,660.91 1624.84,660.33 1624.68,660.11 1622.97,659.41 1621.71,658.31 1620.82,657.68 1619.7,657.47 1618.31,657.64
  		1616.92,657.53 1616.45,657.69 1615.46,658.32 1614.97,658.71 1615,658.97 1616.22,659.17 1617.49,659.16 1618.63,658.98
  		1618.74,659.31 1618.96,659.49 1619.92,659.68 1620.33,659.98 1620.12,660.1 1618.04,660.09 1617.15,660.19 1616.56,660.45
  		1616.42,660.73 1617.09,661.3 1617.61,661.58 1620.66,662.73 1620.76,662.9 1620.83,663.74 1620.81,664.72 1620.69,664.99
  		1619.76,665.03 1619.22,665.56 1619.18,665.42 1619.32,664.49 1619.27,664.04 1619.17,663.71 1618.85,663.35 1618.31,663.16
  		1617.54,663.94 1617.23,664 1616.61,663.78 1615.82,663.96 1615.74,663.75 1615.84,663.23 1615.74,662.82 1615.46,662.5
  		1613.46,661.38 1612.81,661.17 1612.17,661.18 1611.54,661.41 1611.52,661.68 1611.33,662.01 1610.94,662.19 1610.98,662.4
  		1611.9,662.98 1612.32,663.37 1611.87,663.34 1609.78,662.74 1609.61,662.86 1609.49,663.1 1609.44,663.46 1609.53,663.75
  		1610.36,664.35 1611.56,665.38 1609.74,665.32 1609.15,665.54 1608.97,665.81 1609.47,666.41 1610.05,666.76 1611.19,667
  		1613.32,667.02 1613.58,667.19 1612.67,667.52 1612.01,667.96 1610.8,668.17 1608.33,666.97 1607.29,666.74 1606.78,666.82
  		1606.62,667.05 1607.01,667.74 1607.41,668.01 1607.51,668.63 1607.48,669.05 1608.77,670.12 1607.74,669.89 1606.25,669.16
  		1605.46,669.06 1604.69,669.25 1603.55,670.31 1603.73,670.65 1605.67,670.83 1607.4,671.41 1608.4,671.93 1609.28,671.72
  		1610.89,671.09 1614.96,670.17 1616.81,669.54 1617.56,670 1618.99,669.72 1621.12,670.17 1621.78,670.92 1624.36,670.75
  		1625.72,671.38 1625.67,671.74 1625.19,672.04 1623.15,672.82 1621.76,673.53 1620.42,674.42 1620.12,674.84 1621.04,675.35
  		1623.57,675.81 1624.07,675.82 1626.26,674.96 1626.4,675.15 1626.09,676.08 1625.84,676.45 1625.31,676.87 1621.81,676.91
  		1620.24,677 1619.36,677.18 1618.65,677.18 1617.77,676.9 1617.5,677.08 1617.44,677.24 1616.83,677.5 1615.07,678.01
  		1614.82,677.68 1614.32,677.51 1613.99,677.63 1613.33,678.11 1613.01,678.18 1611.9,678.28 1610.15,678.73 1608.71,678.58
  		1608.2,678.7 1607.5,678.96 1607.34,679.41 1607.71,680.05 1608.13,680.57 1608.58,680.95 1609.09,681.1 1610.17,680.87
  		1611.97,680.15 1613.06,679.94 1618.34,680.43 1620.47,680.34 1621.82,681.18 1622.32,681.27 1622.44,681.45 1621.71,682.36
  		1621.67,682.67 1622.01,683.18 1622.27,683.38 1623.06,683.83 1623.51,683.91 1625.04,683.51 1627.14,682.35 1627.58,682.46
  		1627.85,682.64 1627.64,682.86 1626.91,683.03 1624.82,684.15 1624.37,685.01 1624.4,685.54 1624.63,685.79 1625.24,685.83
  		1624.36,686.38 1623.99,686.7 1623.96,686.88 1624.82,686.88 1627.38,685.74 1628.14,685.74 1628.93,686 1627.72,686.17
  		1627.2,686.41 1626.74,686.78 1626.36,687.27 1625.81,688.35 1625.54,688.69 1624.95,689.05 1624.4,689.75 1623.93,690.16
  		1622.83,690.59 1620.11,691.23 1619.69,690.98 1619.33,690.45 1618.92,690.08 1618.5,690 1618.23,690.47 1618.15,690.86
  		1618.26,691.66 1618.57,692.89 1618.91,693.43 1619.29,693.31 1621.27,693.22 1629.05,693.02 1629.56,692.83 1630.76,691.99
  		1631.55,691.87 1631.94,691.93 1632.04,692 1631.52,692.37 1631.68,692.63 1632.76,693.29 1633.85,693.75 1635.1,693.9
  		1635.13,694.27 1635.77,694.65 1636.26,694.67 1637.35,693.91 1637.77,693.74 1638.19,694.28 1638.12,694.37 1637.56,694.38
  		1637.29,694.5 1637.12,694.72 1637.03,695.04 1637.09,695.32 1637.88,695.99 1639.58,697.08 1641.66,697.13 1643.12,697.33
  		1645.58,698.11 1647.57,698.59 1652.59,699.06 1655.55,698.43 1656.22,698.18 1655.86,697.64 1655.86,697.49 1655.97,697.42
  		1656.25,697.41 1656.89,697.86 1657.42,697.87 1658.54,697.34 1659.1,696.62 1659.05,696.39 1658.77,696.22 1658.81,696
  		1659.45,695.38 1659.65,694.97 1661.18,694.52 1665.72,693.7 1667.08,693.26 1668.71,693.11 1669.55,692.93 1671,692.24
  		1672.95,690.62 1674.43,689.62 1676.35,688.74 1679.19,687.64 1681.21,687.11 1683.18,687.13 1683.98,686.8 1685.14,685.99
  		1686.5,685.5 1687.18,685.09 1687.78,684.44 1688.14,683.84 1688.27,683.23 1688.01,682.99 1687.87,682.51 1688.63,681.95
  		1688.54,681.02 1689.29,681.31 1690.65,681.44 1691.41,681.07 1692.19,680.5 1693.03,679.42 1693.22,678.71 1693.24,678.11
  		1693.02,677.64 1693.66,677.35 1694.72,677.31 1695.16,677.04 1695.41,676.6 1695.53,676.2 	"/>
    </>,
    IRN: <>
        <path className="st0" d="M2281.99,1087.21l-1.41,0.66l-1.26-0.34l-0.09,0.15l0.31,0.66l-0.19,0.41l-1.94,0.92l-1.56,0.56l-0.43-0.09
  		l-0.01,0.17l0.14,0.42l0.24,0.06l0.7,0.02l1.01-0.31l1.11-0.61l0.61-0.06l0.85-0.35l0.39-0.01l0.51,0.28l1.18-0.9l0.78-1.08
  		l0.77-0.28l-0.55-0.46L2281.99,1087.21z M2342.36,1086.36l0.53-0.42l-0.03-0.24l-0.38-0.51l-0.51-0.33l-0.25-0.08l-2.12,0.31
  		l-0.87-0.1l-0.41-0.19l-0.09-0.14l0.08-0.31l0.02-0.51l0.31-0.79l0.09-0.47l-0.25-2.75l-0.36-1.83l0.19-1.81l-0.04-0.38l-0.07-0.08
  		l-0.27,0l-1.29,0.16l-1.1-1.17l-0.68-0.46l-1.87-0.58l-0.82-0.11l-1.21-0.5l-1.11-1.1l-1.14-1.13l-0.46-0.72l-0.51-1.23l-1.44-2.36
  		l0.01-0.61l-0.18-0.37l-1.4-1.56l-0.99-1.1l-1.61-1.79l0.21-0.24l1.99-2.24l1.92-2.16l1.92-2.17l1.89-2.14l0.22-0.75l0.03-1.47
  		l-0.5-1.97l-0.8-0.9l-2.64-0.36l-1.99-0.27l-2.16-0.3l-0.28-0.11l-0.25-1.53l0.11-0.69l-0.14-1.33l0.02-1.02l0.31-1.68l0.02-0.76
  		l-1-3.27l-0.56-1.82l-0.57-1.88l-0.12-0.6l-0.01-0.74l1.33-1.74l0.41-0.38l0.78-0.87l0.49-0.46l-0.08-0.32l-0.85-0.19l-1.28-0.02
  		l-0.68-0.26l-0.53-0.48l-0.21-0.69l0.35-1.23l-0.35-2.38l0.71-1.18l0.61-0.83l2.08-0.12l-0.72-0.93l-0.35-0.54l-0.22-0.16
  		l-0.08-0.25l0.11-0.25l0.53-0.09l0.36-0.31l0.58-0.44l0.31-0.19l0.06-0.53l0.28-0.38l0.42-0.47l0.33-0.53l-0.08-0.63l0.31-0.75
  		l0.14-0.47l0.22-0.41l-0.2-0.6l-0.17-0.5l-0.06-0.6l0.33-0.16l0.42-0.22l0.08-0.47l0.22-0.6l0.17-0.47l0.28-0.38l0.03-0.38
  		l-0.17-0.63l-0.19-0.38l-0.03-0.44l0.19-0.54l-0.05-1.01l-0.39-0.73l-0.45-0.32l0.06-0.22l0.19-0.51l0.25-0.44l0-0.88l-0.31-0.95
  		l-0.12-1.37l0.08-1.34l-0.42-0.68l-3.47,0l-3.09,0.05l-0.17-0.15l-1.2-1.69l-0.97-1.29l-0.96-0.76l-2.2-0.94l-1.05-0.39l-0.9-0.72
  		l-0.74-0.78l-0.19-1.07l-0.15-0.35l-0.21-0.29l-0.23-0.13l-0.28,0.03l-2.56-1.25l-1.03-0.33l-0.97,0.26l-0.43,0.05l-0.84-0.36
  		l-0.97,0.48l-0.41,0.03l-0.58-0.12l-0.48-0.18l-1.29-1.14l-1.08-0.46l-0.78-0.3l-1.5-0.43l-1.6-0.23l-0.82-0.19l-0.59-0.25
  		l-0.15-0.16l0.01-0.42l-0.04-0.55l-0.2-0.4l-0.4-0.48l-0.56-0.36l-0.96,0.06l-1.45-0.03l-1.11-0.36l-0.88-0.06l-1.06,0.07l-0.87,0
  		l-0.62,0.26l-0.36,0.31l-0.23,0.94l-0.21,0.14l-0.36,0.07l-0.49-0.05l-1.02,0.01l-1.76-0.17l-2.21-0.05l-1.66,0.47l-1.32,0.68
  		l-1.26,0.77l-1.48,1.21l-0.43,0.54l-0.87,2.14l-0.39,0.31l-0.5,0.25l-0.51,0.04l-1.01,0.32l-1.34,0.52l-0.91,0.2l-2.34-0.11
  		l0.32,1.57l0.55,2.21l0.06,0.49l-0.06,0.5l-0.39,0.3l-0.54,0.05l-1.16-0.06l-0.75-0.33l1.25-0.27l0.74-0.47h-1.24l-3.31,0.59
  		l-9.97,2.38l-3.61,0.07l-5.42-1.23l-1.61-0.65l-3.32-1.96l-1.65-1.31l-1.04-1.84l-0.32-0.4l-0.39-0.26l-1.26-0.37l-2.14-0.35
  		l-2.16-0.16l-0.82-0.23l-1.7-0.78l-0.76-0.65l-0.55-1.05l-0.47-1.11l-0.29-1.22l-0.2-1.25l-0.26-2.43l-0.02-0.42l-0.24-0.02
  		l-1.72,0.38l-0.36-0.12l-1.48-1.71l-0.3-0.19l-0.64-0.08l-0.37-0.28l-0.3-0.46l-0.17-0.34l-1.53-0.93l-0.23-0.34l-0.03-0.3
  		l0.22-0.27l0.26-0.23l0.74-0.23l0.87-0.2l0.28-0.14l0.14-0.25l-0.01-0.4l-0.14-0.39l-1.25-0.71l-0.14-0.31l-0.04-0.38l0.07-0.39
  		l0.2-0.31l1.02-0.42l0.55-0.43l-0.34-0.49l-1.1-1.11l-1.31-1.22l-0.87-0.01l-1.01,0.36l-1.61,1.04l-0.89,0.44l-1.16,0.73
  		l-1.26,0.82l-1.04,0.87l-0.64,0.72l-1.15,0.31l-0.58,0.6l-1.92,1.8l-0.54-0.02l-1.46-0.06l-1.24,0.43l-0.47-0.09l-1.62-0.3
  		l-2.92-0.64l-0.8-0.33l-0.76-0.88l-0.45-0.43l-0.67-0.55l-0.55-0.21l-0.42-0.38l-0.24-0.56l-0.35-0.51l-0.6-0.6l-1.36-2.03
  		l-0.18-0.21l-0.3-0.01l-0.48-0.3l-1.16-0.86l-0.59,0.37l-0.51,0.64l-0.56,2.41l-0.45,0.26l-0.8-0.01l-0.98-0.08l-0.67,0.12
  		l-0.17,0.15l0.09,0.26l0.2,0.4l0.14,0.5l0.04,0.41l0.36,0.37l0.48,0.35l0.02,0.36l-0.07,0.51l-0.11,0.39l-0.12,0.22l0.22,0.59
  		l0.52,0.7l0.33,0.27l-0.12,1.33l0.19,0.59l0.15,0.81l-0.06,1.35l0.06,0.33l0.18,0.11l0.47,0.05l0.46,0.12l0.16,0.22l0,0.16
  		l-0.58,0.62l-0.07,0.44l-0.2,0.62l-0.16,0.36l-0.52,0.69l-0.33,0.7l-0.15,0.58l0.1,0.27l0.95,0.08l0.52,0.41l1.38,0.82l0.24,0.33
  		l-0.38,0.51l0.01,0.21l0.18,0.27l0.08,0.47l-0.03,0.52l0.01,0.69l0.25,0.11l0.94,0.65l0.67,0.65l0.02,0.2l-0.32,0.51l0.07,0.59
  		l-0.01,0.13l0,0.07l0.28,0.69l0.69,2.55l0.4,0.32l0.45,0.27l0.32,0.38l0.12,0.38l-0.04,0.59l0.01,0.69l0.19,0.52l0.26,0.4l0.24,0.2
  		l0.36,0.02l0.43,0.1l0.29,0.4l0.92,2.89l0.09,0.38l0.39,0.12l0.64-0.06l0.66,0.3l0.7,0.47l0.66,0.88l0.45,0.14l1.39-0.13l1.9,0.14
  		l0.89,0.45l-0.09,0.28l-0.69,0.31l-1.21,0.37l-0.36,0.62l-0.2,0.8l0.04,0.45l0.3,0.5l0.85,0.98l0.05,0.36l0.15,0.5l0.16,0.34
  		l-0.18,0.66l-0.77,0.45l-1.02,0.49l-2.04,2.19l-0.15,0.47l0.01,1.29l-0.2,0.37l-0.65-0.01l-0.51-0.07l-0.03,0.45l-0.32,0.6
  		l-0.18,0.52l0.75,1.23l0.13,0.65l-0.12,0.59l-0.69,1.02l-0.41,0.69l0.1,0.15l0.54,0.27l1.69,2.25l0.54,0.79l0.71-0.21l0.27,0.01
  		l0.21,0.13l0.13,0.27v0.34l-0.18,0.5l0.91,0.21l0.33,0.51l1.06,1.75l-0.04,0.52l-0.51,0.82l0,0.55l0.11,0.49l0.17,0.17l1.56,0.07
  		l0.66,0.2l1.62,0.89l1.85,1.36l1.51,1.12l1.29,0.95l1.38-0.07l0.38,0.17l0.35,0.3l0.39,0.78l0.79,1.76l0.67,0.59l1.04,1.41
  		l0.97,1.32l-0.64,1.79l-0.63,1.86v2.4v1.29l1.32,0.05l1.47,0.06l0.01,1.53l0.01,1.54l0.01,1.76l0.43,0.07l0.69,0.38l0.29,0.57
  		l0.37,0.31l0.44,0.05l0.44,0.28l0.43,0.51l0.16,0.38l-0.12,0.26l0.09,0.46l0.3,0.66l0.37,0.31l0.57,0.38l0.41-0.12l0.64-0.49
  		l1.36-0.07l0.32-0.25l0.41-0.54l-0.09-1.11l-0.15-0.79l0.22-0.64l1.01-0.49l-0.3-0.52l1.08-0.03l0.79,0.34l0.19,0.55l-0.48,0.34
  		l-0.79-0.29l-0.4,0.09l-0.4,0.21l0.22,0.37l0.22,0.24l3.16,1.62l1.05,0.93l3.61-1.66l0.75,0.1l0.48,1.38l0.34,1.16l0.52,0.44
  		l1.32,1.78l1.32,1.2l0.89,1.17l0.15,0.73l-0.18,1.17l0.25,0.6l1.01,0.27l0.68,0.5v0.53l-0.28,0.7l0.2,0.53l1.3,0.8l0.34,0.51
  		l0.27,1.95l0.29,0.7l1.24,1.97l0.02,0.79l2.02,2.01l0.59,0.41l0.65,0.17l1.48-0.03l1.59,0.22l1.36,0.97l2.39,0.91l1.07,1.11
  		l0.3,0.92l0.45,0.62l2.45,1.64l3.02,1.24l0.95,0.55l0.44,0.82l1.67,1.14l0.98,0.16l2.08-0.22l1.5,0.32l2.32,0.97l1.03,0.72
  		l0.96,0.03l1.15-0.46l2.18-1.52l1.18-0.55l1.09,0.14l0.8-0.53l0.62-0.92l0.49-0.41l2.45-0.54l1.49-0.95l1.4-0.43l0.6-0.09
  		l3.13,0.65l0.71,0.34l0.82,0.86l0.6,0.8l0.45,0.94l0.3,1.09l0.27,2.77l0.82,1.91l0.04,1.09l0.47,1.06l0.62,1.14l3.35,0.6l0.54,0.64
  		l1.18-0.35l0.72,0.45l1.52,0.44l0.94,0.1l1.82-0.1l2.25,0.34l2.09,1.23l1.53-0.09l1.93-0.48l1.35,0.7l1.7,0.02l0.66,0.35l1.08-0.2
  		l3.16,0.65l0.93-1.12l0.65,0.21l0.23,0.75l0.41,0.43l3.75,0.88l1.13,0.37l1.43,0.36l0.66-0.46l0.36-0.37l0.46-0.06l0.23-0.75
  		l0.21-2.67l0.26-0.96l-0.08-0.53l0.06-0.16l0.58-0.47l0.14-0.2l0.22-1.37l0.25-1.52l0.27-0.54l0.23-0.15l1.85-0.68l0.31-0.46
  		l0.96,0.11l0.09-0.11l0.08-0.52l0.44-0.57l0.61-0.47l0.46-0.17l1.66-0.29l0.97-0.41l0.3-0.04l2.58,0.1l0.55-0.16l0.09-0.14
  		l0.15-1.55l0.47-0.24l0.07-0.13l-0.16-1.07L2342.36,1086.36z"/>
    </>,
    IRQ: <>
        <polygon className="st1" points="2218.01,1059.74 2217.64,1059.43 2217.34,1058.77 2217.25,1058.31 2217.36,1058.04 2217.2,1057.66
  		2216.77,1057.15 2216.33,1056.87 2215.89,1056.82 2215.52,1056.51 2215.22,1055.94 2214.54,1055.57 2214.11,1055.49
  		2214.09,1053.73 2214.08,1052.19 2214.07,1050.66 2212.6,1050.6 2211.28,1050.54 2211.28,1049.26 2211.28,1046.86 2211.91,1045
  		2212.55,1043.21 2211.58,1041.89 2210.54,1040.48 2209.87,1039.89 2209.08,1038.13 2208.69,1037.35 2208.34,1037.05
  		2207.96,1036.88 2206.58,1036.95 2205.29,1036 2203.78,1034.88 2201.94,1033.52 2200.31,1032.62 2199.65,1032.43 2198.09,1032.36
  		2197.92,1032.19 2197.81,1031.7 2197.82,1031.15 2198.32,1030.33 2198.36,1029.81 2197.3,1028.06 2196.98,1027.55 2196.07,1027.35
  		2196.25,1026.84 2196.25,1026.5 2196.12,1026.24 2195.91,1026.11 2195.64,1026.1 2194.93,1026.3 2194.39,1025.51 2192.7,1023.26
  		2192.16,1022.99 2192.06,1022.84 2192.47,1022.15 2193.17,1021.12 2193.28,1020.53 2193.15,1019.87 2192.4,1018.64
  		2192.58,1018.12 2192.91,1017.52 2192.93,1017.06 2193.44,1017.13 2194.08,1017.14 2194.28,1016.77 2194.27,1015.48
  		2194.42,1015.01 2196.47,1012.82 2197.49,1012.33 2198.26,1011.88 2198.44,1011.22 2198.28,1010.88 2198.13,1010.39
  		2198.08,1010.03 2197.23,1009.05 2196.93,1008.55 2196.89,1008.1 2197.09,1007.3 2197.45,1006.68 2198.66,1006.31 2199.35,1006
  		2199.44,1005.72 2198.55,1005.27 2196.64,1005.13 2195.25,1005.26 2194.81,1005.12 2194.15,1004.24 2193.44,1003.77
  		2192.79,1003.47 2192.15,1003.53 2191.76,1003.41 2191.67,1003.03 2190.74,1000.14 2190.45,999.74 2190.02,999.64 2189.66,999.63
  		2189.42,999.43 2189.16,999.03 2188.98,998.5 2188.96,997.82 2189,997.23 2188.88,996.84 2188.56,996.47 2188.11,996.19
  		2187.71,995.87 2187.02,993.32 2186.74,992.63 2186.74,992.56 2186.45,992.34 2185.93,992.26 2185.39,992.24 2185.06,992.41
  		2184.47,992.87 2183.68,993.37 2183.03,993.83 2182.66,994.15 2182.36,994.1 2182.12,993.82 2181.99,993.44 2182.05,991.98
  		2181.91,991.52 2181.61,991.2 2181.25,991.02 2180.84,990.92 2180.4,990.91 2179.79,991.33 2178.91,991.78 2177.56,991.74
  		2176.65,991.66 2176.21,991.57 2174.45,990.9 2174.08,990.88 2173.43,990.6 2172.65,990.38 2171.33,990.8 2170.77,990.7
  		2169.97,990.34 2169.69,990.44 2168.8,991.53 2167.29,992.69 2166.47,992.89 2166.47,993.02 2166.39,993.35 2165.44,994.31
  		2164.15,995.62 2163.23,996.44 2161.67,997.82 2160.5,998.12 2158.53,998.61 2158.01,999.1 2157.52,999.88 2157.23,1000.94
  		2157.14,1001.61 2157.09,1002.85 2157.55,1004.14 2157.99,1005.37 2158.05,1006.19 2158.01,1006.99 2157.58,1007.85
  		2157.11,1009.02 2156.85,1010.35 2156.7,1012.83 2156.7,1014.94 2156.66,1015.29 2155.86,1016.77 2154.91,1018.51 2154.48,1018.91
  		2152.41,1019.43 2150.15,1020.7 2147.63,1022.11 2145.34,1023.4 2142.93,1024.75 2140.44,1026.14 2138.66,1027.14 2136.27,1028.47
  		2136.87,1030.55 2137.46,1032.57 2138.07,1034.67 2138.67,1036.7 2138.02,1036.89 2138.53,1038.45 2139.36,1038.21
  		2140.26,1038.03 2140.64,1039.02 2139.4,1040.14 2141.28,1040.44 2144.11,1040.9 2146.84,1041.34 2149.71,1041.86 2150.63,1042.29
  		2153.41,1043.85 2155.21,1044.86 2157.32,1046.04 2159.95,1047.52 2161.76,1048.53 2164.07,1049.82 2165.88,1051.3
  		2168.16,1053.17 2170.67,1055.22 2172.74,1056.81 2175.58,1059 2178.39,1061.16 2181.13,1063.26 2183.33,1064.95 2186.11,1067.08
  		2186.33,1067.16 2189.14,1067.4 2192.92,1067.72 2196.71,1068.05 2200.14,1068.34 2201.61,1068.05 2202.98,1066.55
  		2203.62,1065.75 2204.77,1064.01 2205.36,1062.77 2205.93,1061.4 2206.42,1060.32 2206.52,1060.13 2206.81,1059.76
  		2207.44,1059.39 2208.35,1059.04 2209.89,1058.89 2210.98,1058.88 2211.22,1058.9 2211.91,1059.07 2213.8,1059.93 2213.83,1059.67
  		2214.59,1059.38 2215.17,1059.4 2216.97,1060.17 2217.81,1060.34 2218.58,1060.12 	"/>
    </>,
    IOT: <>
        <polygon className="st1" points="2420.28,1379.51 2419.87,1379.16 2419.79,1379.24 2420.04,1379.64 2420.11,1379.91 2420.01,1380.15
  		2420.06,1380.4 2419.89,1380.63 2419.72,1379.83 2419.26,1379.52 2419.07,1379.52 2419.55,1380.12 2419.73,1380.97
  		2420.07,1380.81 2420.26,1380.48 2420.32,1379.78 	"/>
    </>,
    IND: <>
        <path className="st0" d="M2590.99,1241.09l-0.2,0.11l-0.21,0.22l0.03,0.34l0.23,0.29l0.37-0.05l0.18-0.31l-0.2-0.57L2590.99,1241.09z
  		 M2590.38,1222.32l-0.42-0.39l-0.05,0.7l-0.32,0.34l0.41,0.21l0.41-0.17l-0.05-0.26L2590.38,1222.32z M2592.91,1213.19l-0.21-2.65
  		l-0.66-0.78l0.2-0.28l0.35-0.74l0.45-0.23l0.32-0.56l0.19-0.57l0.07-0.26l-0.49-0.72l0.51-0.55l-0.08-0.31l-0.04-0.93l-0.28,0.01
  		l-0.88,0.49l-0.57,1.09l0.02,1.09l-0.43,1.63l0.18,0.31l-0.2,1.05l-0.45,0.49l-0.11,0.35l0.16,0.94l-0.23,0.45l-0.1,0.63
  		l-0.01,1.56l0.13,0.18l0.37,0.28l0.09,0.65l-0.16,0.09l-0.63,0.01l-0.15,0.19l-0.3,0.68l-0.07,0.83l-0.2,0.55l-0.35,0.16
  		l-0.27,0.48l0.22,0.34l0.13,0.98l0.78,1.52l0.27,0.22l0.18-0.2l0.35-0.87l0.02-1.06l0.26-0.93l-0.01-0.36l-0.28-0.19l-0.14-0.44
  		l0.32-0.36l0.11-0.38l0.58-0.87l0.1-0.39l-0.13-1.77l0.58-0.14L2592.91,1213.19z M2425.18,1248.99l-0.16,0.27l-0.14,0.08
  		l-0.11-0.09l-0.02,0.08l0.04,0.1l0.09,0.01l0.12-0.04l0.12-0.1l0.1-0.2l0.03-0.15l-0.03-0.05L2425.18,1248.99z M2590.73,1209.71
  		l-0.17-0.11l-0.14,0.04l-0.12,0.15l0.05,1.18l0.27-0.55L2590.73,1209.71z M2588.87,1227.11l-0.53,0.27l-0.65,0.61l-0.15,0.36
  		l0.2,0.85l-0.06,0.87l0.87,0.23l0.25-0.29l0.6-1.26l-0.17-0.81L2588.87,1227.11z M2422.71,1224.18l-0.08,0.25l0.01,0.15l0.06-0.05
  		l0.06-0.11l0.04-0.22l0.03-0.16l-0.03-0.02L2422.71,1224.18z M2599.92,1258l-1.16,0.45l-0.23,0.4l0.01,1.01l0.43,0.13l1.01,2.12
  		l0.52-0.69l0.33-1.2l-0.59-1.96L2599.92,1258z M2629.54,1077.74l-0.31-0.5l0.06-0.63l0.1-0.57l-0.28-0.17l-1.22-0.94l-0.59-0.26
  		l-0.8,0.28l-1.25-0.22l-0.48-0.04l-1.04-0.75l-0.42-0.09l-1.47,0.49l-0.33,0.35l-0.19,0.01l-0.39-0.18l-0.32-0.23l-0.02-0.15
  		l0.4-0.37l0.03-0.26l-0.02-0.26l0.58-0.74l1.56-1.43l-0.26-0.6l-0.61-1.18l-0.08-0.57l-0.26-0.26l-0.75,0.21l-1.56,1.07l-0.21-0.12
  		l0.04-0.37l-0.16-1.08l0.49-0.32l0.76-0.4l0.58-0.44l0.14-0.36l-0.16-0.11l-0.86,0.14l-0.34-0.24l-0.56-1l-0.41-0.39l-0.37-0.21
  		l-1.27,0.51l-1.47,0.7l-1.64,0.98l0.01,0.5l-0.2,0.13l-0.31,0.32l-0.31,0.44l-0.26,0.15l-0.3,0.01l-0.62-0.12l-1.13-0.5l-1.23-0.41
  		l-0.26,0.05l-1.67-0.29l-0.05-0.23l-0.25-0.46l-0.47-0.41l-0.46-0.14l-1.31,0.88l-1.47,0.65l-0.84,0.77l-0.69,0.77l-0.79,0.15
  		l-0.04,0.47l-0.33,0.43l-0.6,0.52l-1.19,0.67l-0.81,0.36l-2.56,0.33l-0.92,0.22l-0.38,0.35l-0.41,0.89l-0.33,0.82l-0.71,0.68
  		l-1.29,0.9l-1.52,0.74l-0.42,0.49l-0.08,0.29l0.19,0.11l0.18,0.22l0,0.33l-0.2,0.36l-0.99,0.63l-0.56,0.3l-0.55,0.19l-0.62,0.04
  		l-0.6-0.09l-0.17-0.1l-0.24,0.13l-0.54,0.13l-0.47,0.04l-1.04,0.7l-0.57,0.01l-0.71-0.15l-0.94-0.12l-0.68,0l-0.05,0.21l-0.24,0.55
  		l-0.15,0.59l0.13,0.49l0.53,0.58l0.72,0.46l0.91,0.04l0.84-0.18l0.34,0.07l0.46,0.77l0.32,0.67l-0.44,0.69l-0.24,0.6l-0.09,0.43
  		l0.05,0.19l0.27,0.35l0.31,0.59l0.04,0.54l-0.2,0.36l-0.43,0.18l-0.46-0.05l-0.38,0.01l-0.48,0.06l-0.74,0.2l-0.69,0.26l-1.3-0.05
  		l-0.52-0.54l-0.25,0l-1.18,0.7l-1.29-0.12l-2.34,0.23l-0.98,0.05l-1-0.08l-0.51-0.15l-0.94-0.49l-0.86-0.36l-0.87,0.33l-0.31,0.06
  		l-0.7,0.84l-1.51,0.28l-1.51,0.2l-0.45-0.11l-0.85-0.05l-0.03-0.2l0.03-0.19l-0.19-0.15l-0.35-0.16l-0.59-0.06l-0.76-0.21
  		l-0.44-0.2l-1.55,0.29l-0.9-0.44l-1.03-0.61l-0.52-0.26l-0.19-0.94l-0.18-0.3l-0.4-0.32l-0.23-0.37l0.18-0.38l1.02-0.72l0.08-0.17
  		l-0.5-0.42l-0.57-0.61l-0.13-0.83l0.68-2.22l0.16-0.92l-0.17-0.35l-0.21-0.9l-0.4-0.3l-1.14-0.47l-0.36-0.01l-0.39,0.33l-0.38,0.21
  		l-0.51,0.21l-1.27,0.39l-1.13,0.18l-0.27,0.14l-0.08,0.26l0.09,0.31l0.34,0.25l0.03,0.4l-0.06,0.45l-0.35,0.97l-0.32,0.68
  		l-0.37,1.43l-0.33,2.48l0.07,0.43l0.99,1.42l0.39,1.09l0.04,0.74l-0.42,1.25l-0.48,1.41l-0.23,0.31l-0.27,0.11l-1.23-0.49
  		l-0.85,0.07l-0.97,0.27l-1.01-0.05l-0.84-0.16l-1.06,0.56l-1.02-0.31l-0.65-0.35l-0.43-0.97l-0.18-0.12l-2.14,1.02l-0.51,0.06
  		l-1.33-0.55l-1.09-0.54l-0.41-0.16l-1.05-0.21l-0.95-0.12l-1.03-0.34l-1.28,0.44l-0.51-0.04l-0.48-0.32l-0.25-0.66l-0.06-0.62
  		l-0.43-0.43l-0.67-0.1l-0.94,0.38l-1.38,0.51l-0.44-0.08l-0.41-0.15l-0.15-0.14l-0.19-0.59l-0.22-0.13l-0.32-0.02l-0.57-0.14
  		l-0.7-0.44l-2.12-1.03l-0.27-0.46l0.01-1.01l-0.12-0.42l-0.26-0.44l-1.09-0.45l-2.11-0.72l-1.17-0.57l-0.56,0.27l-1.08,0.24
  		l-0.58,0.52l-0.69-0.16l-1.65-0.55l-0.88-0.08l-0.53,0.19l-0.12,0.31l-0.67,0.35l-0.64-0.28l-1.26-0.38l-1.11-0.21l-1.68-0.46
  		l-0.19-0.7l-0.28-0.69l-0.4-0.12l-1.5,0.14l-1.38-0.77l-1.48-0.98l-0.63-0.32l-0.42-0.12l-0.36,0.13l-0.41,0.22l-0.37,0.07
  		l-0.8-0.42l-1.03-0.6l-1.26-0.74l-1.48-1.04l-0.61-0.59l-0.28-0.44l-0.31-0.41l-1.28-0.68l-1.01-0.54l-1.22-0.65l-0.21-0.13
  		l-0.46-0.39l-0.71-0.49l-0.58-0.14l-0.19,0.27l-0.14,0.28l-0.51-0.06l-0.79-0.5l-0.83-0.52l-0.65-0.48l-0.66-0.49l-0.16-0.37
  		l0.28-1.13l0.39-0.97l0.33-0.22l0.53-0.64l0.19-1.13l-0.01-0.96l0.52-1.36l0.72-1.45l1.24-1.55l0.54-0.51l0.6-0.35l1.14-1.15
  		l0.24-0.19l0.5-0.3l0.49-0.07l0.37,0.14l-0.21-0.67l-0.94-0.49l-1.07-0.64l-0.54-0.5l-0.62-0.31l-0.57-0.14l-1.11-0.42l-1.25-0.48
  		l-0.59-0.07l-0.04-0.34l0.18-0.72l-0.11-0.7l-0.38-0.28l-0.57,0.07l-1.32-0.99h0l-0.05-0.01l-0.02-0.04l-0.38-0.28l-0.65-0.4
  		l-1.1,0.03l-0.83,0.15l-0.61-0.41l-0.88-0.65l-0.16-0.15l-0.07-0.06l-0.19-0.17l-0.89-1.26l-1.06-1.49l-0.53-0.22l-0.27,0.11
  		l-0.31,0.79l-0.24-0.08l-0.39,0.05l-0.46,0.28l-0.45,0.07l-0.28-0.08l-0.12-0.2l0.13-1.04l-0.27-0.33l0.24-0.73l0.4-0.63
  		l-0.41-0.47l-0.51-0.67l-0.05-0.6l0.28-0.76l-0.52-0.72l0.49,0.69l0.02,0.03l0.13-0.65l-0.08-0.24l-0.4-0.37l-1.53-1.79l-0.08-0.19
  		l-0.26-0.6l-0.12-0.9l-0.2-0.65l-0.23-0.5l0.02-0.23l0.18-0.12l0.96-0.12l0.89-0.08l0.58-0.17l0.3,0.36l0.14,0.55l0.15,0.29
  		l0.56,0.52l0.68,0.5l0.66-0.06l0.58-0.21l0.51-0.82l0.36-0.2l0.42-0.04l-0.02-0.59l0.15-1.3l-0.05-0.52l-0.19-0.47l0.03-0.52
  		l-0.06-0.76l-0.48-0.52l-0.31-0.2l-0.05-0.28l0.16-0.52l0.11-0.6l-0.19-0.51l-0.39-0.23l-0.45-0.39l-0.54-0.52l-0.27-0.38
  		l-0.44-0.42l-0.53-0.64l-0.1-1.42l-0.05-1.49l-0.19-0.74l-0.3-1.19l0.04-0.4l0.18-0.3l1.51-0.96l0.33-0.37l0.05-0.28l-0.06-0.42
  		l-0.28-0.47l-0.6-0.36l-0.86-0.59l-0.78-0.62l-1.31-0.38l-1.59-0.46l-0.38-0.45l-0.39-1.1l-0.65-1.68l-0.7-1.79l-0.54-1.11
  		l-0.03-0.53l0.32-1.36l-0.04-0.29l-0.28-0.1l-0.54,0.18l-0.43,0.22l-0.36-0.11l-0.34-0.23l-0.1-0.11l-0.86,0.5l-1.06,0.61
  		l-1.25,0.73l-1.1,0.64l-1.05,0.61l-1.01,0.59l-0.15,0.45l-0.25,0.67l-0.92,0.51l-0.92,0.37l-0.21,0.21l-0.07,0.29l-0.44,0.58
  		l-0.86,0.48l-0.71-0.05l-0.45-0.14l-2.39,0.84l-1.11-0.02l-0.87,0.54l-0.64,0.5l-1.29,0.54l-0.87,0l-1.29-0.32l-1.59-0.61
  		l-0.65-0.36l-0.58,0.02l-1.4-0.09l-1.37-0.3l-1.64-0.36l-0.81-0.15l-1.66-0.32l-1.08,0.42l-0.98,0.38l-0.8,0.26l-0.66,1.18
  		l-0.28,0.41l-0.32,0.6l-0.15,0.42l0.13,0.5l0.97,0.35l0.4,0.48l0.06,0.43l-0.35,0.44l-0.29,0.35l0,0.31l0.15,0.31l0.23,0.23
  		l1.37,0.14l0.81,0l0.32,0.12l0.04,0.42l-0.3,0.56l-1.16,0.45l-0.65,0.48l-0.21,0.63l0.01,0.5l0.22,0.33l0.55,0.38l0.52,0.44
  		l0.16,0.36l-0.06,0.49l-0.21,0.67l-0.57,0.78l-0.47,0.55l-0.04,0.2l0.12,0.3l0.38,0.43l0.65,0.64l0.81,0.52l0.52,0.14l0.17,0.12
  		l0.16,0.6l0.06,0.63l-0.21,0.47l0.41,0.39l1.08-0.02l0.88,0.17l0.37-0.17l0.26,0.12l-0.17,1.4l0.12,0.83l0.23,0.23l0.87,0.34
  		l1.67-0.04l0.98,0.39l1.09,0.45l0.58,0.5l0.26,0.37l-0.07,0.6l-0.59,0.7l-0.97,0.33l-0.57,0.14l-2.8,1.31l-0.87,0.55l-0.68,0.66
  		l-0.25,0.5l-0.13,0.48l0.61,1.75l0.1,0.54l-0.5,1.89l-0.15,0.7l0.19,0.49l0.59,0.18l0.13,0.41l0.06,0.31l-1.04,0.69l-1.09,0.61
  		l-0.35,0l-1.04,1.15l-1.74,2.3l-0.92,0.78l-0.07,0.38l-0.07,0.39l0.35,0.65l0.07,0.55l-0.39,0.55l-0.65,0.63l-1.27,0.55l-1.6,0.56
  		l-0.72,0.34l-0.54,1.48l-0.5,1.49l-0.22,0.55l-0.87,1.71l-1.51,2.51l-0.38,0.55l-2.34,1.21l-2.39,1.31l-0.42,0.5l-0.49,1.2
  		l-0.46,1.31l-0.43,0.69l-1.52,1.54l-0.5,1.18l-0.16,0.77l-1.29,0.43l-1.46,0.41l-2.13,0.13l-0.89,0.21l-2.61,1.06l-0.65,0.04
  		l-0.51-0.18l-0.39-0.36l-0.36-0.6l-0.17-0.93l-0.5-0.42l-0.68-0.36l-0.71-0.02l-0.72,0.39l-0.62,0.43l-0.42,0.36l-0.42,0.42
  		l-0.8,1.4l-1.29,2l-1.44,1.45l-0.53,0.44l-0.33,0.33l-0.45,0.48l-0.26,0.47l-0.36,1.52l-0.21,1.35l0.09,0.3l0.22,0.25l0.79,0.39
  		l1.14,0.65l1.48,0.37l1.25,0.07l0.47,0.28l0.28,0.37l0.08,0.31l-0.06,1.11l-0.14,1.19l-0.46,1.28l0.01,0.73l0.18,0.72l1.39,1.82
  		l0.51,0.19l1.04,0.04l0.48-0.04l0.54-0.18l0.38,0.13l0.28,0.22l0.07,0.37l-0.04,1.81l0.43,0.82l0.83,1.12l0.65,1.28l0.62,1.53
  		l0.59,1.19l0.23,0.62l-0.38,0.3l-0.22,0.31l-0.06,0.42l0.08,0.44l-0.05,0.31l0.28,0.38l0.33,0.13l-0.01,0.27l-0.52,0.35l-0.46-0.01
  		l-0.35,0.17l-0.68,0.73l-0.32,0.15l-0.43,0.07l-0.48-0.07l-0.68-0.29l-0.2-0.46l0.08-0.49l-0.15-0.29l-0.48,0.05l-1.69,0.5
  		l-1.61,0.61l-0.28,0.42l-0.37,0.44l-0.74,0.18l-1.08,0.05l-0.75-0.07l-0.69-0.47l-0.63-0.43l-0.97-0.02l-1.76,0.06l-0.97,0
  		l-0.57-0.16l-0.56,0.12l-0.71-0.17l-0.31,0.23l-0.3,0.02l-0.24-0.4l-0.16-0.04l-0.19,0.06l-0.16,0.14l-0.1,0.23l-0.03,2.67
  		l-1.16-0.02l-0.82-0.01l-0.9,0.15l-0.83,0.2l-0.41,0.24l-0.58,0.38l0.23,1.14l0.36,1.17l0.91-0.18l0.69-0.79l0.61-0.38l1.23-0.54
  		l1.13-0.39l-1.26,0.87l-1.46,1.1l-0.31,0.51l0.94,1.84l0.94,1.54l1.49,1.2l3.53,1.81l3.61,0.79l0.63-0.14l0.93-0.72l2.26-0.8
  		l0.62-0.16l0.5-0.05l0.74,0.27l0.24-0.3l0.24-0.5l0.32-0.41l0.46-0.11l0.17,0.44l0.03,0.33l-0.62,0.28l-0.95,1.36l-1.27,2.14
  		l-0.78,0.17l-0.66,0.05l-1.57,0.85l-0.77-0.12l-0.61,0.54l-0.89-0.04l-2.3,1.08l-0.32-0.13l-0.38-0.32l-0.53-0.71l-0.67-0.19
  		l-0.57,0.46l-0.11,0.84l0.33,0.82l1.54,1.8l1.63,1.34l1.32,1.41l1.74,1.52l2.41,2.86l0.78,0.74l3.01,2.23l1.97,0.87l1.35,0.23
  		l1.22-0.21l3.13-1.14l1.47-0.88l3.74-1.62l0.52-0.6l1.49-2.69l0.02-1.14l-0.39-0.59l-0.33-0.4l-0.57-0.18l-0.55-0.25l0.32-0.35
  		l0.16-0.5l0.57-0.57l0.7-0.38l0.25-0.55l0.27-0.87l-0.54-0.49l-0.5-0.22l1.26,0l1.04,0.19l1.13-0.26l0.94,0.13l0.9,0.27l-0.85,0.23
  		l-0.68,0.07l-0.63,0.35l-0.26,1.62l0.8,0.13l0.7-0.09l-0.47,0.3l-0.43,0.53l-0.42,1.59l2.5,0.08l1.17-0.15l1.12-0.4l-0.76,0.45
  		l-1.79,0.7l-0.78,0.6l-0.88,0.78l0.46,0.05l0.56-0.13l-0.41,0.31l-0.53,0.56l0.58,1.7l0.5,0.42l0.52,0.1l0.09,0.29l0.14,1.15
  		l0.32,1.08l0.12,1.36l-0.11,0.96l-1.45,4.23l-0.35,2.15l0.07,0.29l0.18,0.36l0.25,1.56l0.61,0.51l-0.36,0.6l0.06,0.32l0.2,0.44
  		l1.68,0.74l-1.48-0.19l-0.14,0.41l0.07,0.29l0,1.21l0.27,0.9l0.56-0.34l0.6-1.2l0.28,1.15l-0.24,0.81l-0.66,1.29l-0.23,0.83
  		l0.04,0.35l0.35,0.58l0.22,1.82l0.24,0.92l0.18,1.4l0.45,1.65l0.92,2.46l-0.06,0.82l0.76,2.84l0.83,6.36l0.98,2.64l0.19,0.84
  		l1.11,1.57l0.61,1.39l0.45,0.45l0.84-0.02l-0.51,0.74l0.35,0.3l0.32,0.48l0.67,0.73h-1.1l0.7,0.78l0.55,1.98l0.77,1.07l0.41,0.4
  		l1.13,1.65l0.48,0.51l0.46,0.63l0.4,0.69l0.13,0.75l0.59,1.63l0,0.41l0.27,1.05l0.93,1.68l0.53,1.55l0.09,0.72l0.01,0.65l0.74,3.66
  		l0.27,0.86l0.55,1.13l0.65,2.38l2.12,4.31l0.28,0.29l0.71,0.55l0.91,1.24l0.86,0.93l1.02,1.99l0.65,0.9l1.02,2.58l0.66,2.32
  		l1.46,3.23l0.23,0.64l0.66,1.07l-0.07,0.31l0.02,0.66l0.23,0.52l0.22,0.05l0.82,0.81l0.95,3.26l-0.33,0.13l-0.37-0.16l-0.03-1.41
  		l-0.25-1.01l-0.49-0.7l-0.36-0.15l0.42,2.12l0.27,1.89l0.66,1.82l0.13,0.24l0.28,0.16l0.16,0.24l0.09,0.59l0.59,1.59l0.54,0.47
  		l2.94,3.71l0.83,0.77l1.98,1.44l1.82,0.56l0.59-0.43l1.54-0.51l2.44-1.64l0.56-1.07l0.08-1.28l0.48-1.92l0.69-0.84l1.24-0.97
  		l4.7-1.38l1.96,0.11l1.67,0.54l-0.46-0.5l-0.68-0.27l-1.42-0.21l-0.73-0.21l-0.56-0.51l-0.29-0.5l0.18-0.95l0.47-0.99l2.2-2.97
  		l-0.03-1.18l0.51-0.69l0.64-0.42l1.19-0.2l0.48,0.15l0.66,0.11l0.75-0.04l0.68-0.15l0.1-3.78l-0.01-3.62l-0.11-0.61l-0.31-0.59
  		l-0.89,0.22l0.47-0.49l0.37-0.64l-0.33-1.09l0.15-0.97l0.73-2.53l1.04-2.09l0.47-0.51l0.89-1.33l0.72-2.03l0.95-5.71l-0.44-0.64
  		l-1.48-0.78l-0.44-0.66l0.79-0.92l0.65,0.92l0.27,0.72l0.34,0.31l-0.51-1.71l-0.01-0.75l-0.17-0.72l-0.68-1.71l-0.27-1.31
  		l0.21-0.64l0.29-0.54l0.07-1.1l-0.11-0.85l-0.56-1.88l-0.38-2.36l0.4-2.14l1.62-3.32l0.77-0.7l2.2-0.88l0.52,0.06l0.62,0.18
  		l0.37,0.87l0.33-0.14l0.45,0.19l0.51,0.01l0.12-0.43l0.31-0.62l0.86-0.69l0.9-2.6l0.4-0.63l0.97-0.24l2.61,0.26l0.42,0.04l3.2-1.34
  		l0.99-0.64l0.58-0.9l0.1-0.36l0.18-0.66l-0.08-0.36l-0.36-0.46l-0.21-0.5l0.04-0.36l0.62-1.02l1.97-1.53l3.23-1.62l1.87-1.27
  		l1.6-1.53l1.55-1.87l0.69-0.57l3.79-1.93l0.65-0.93l2.37-2.51l1.24-1.69l0.69-0.7l0.5-0.74l0.18-0.65l3.83-3.33l1.82-1.03
  		l-0.04-0.26l-0.56-0.19l-1.2,0.68l-0.4,0.06l-0.15-0.23l0.72-1.19l1.78-1.2l0.37,0.07l0.43,0.18l0.04,0.99l-0.41,0.23l-0.12,0.26
  		l0.66,0.03l2.34-0.86l3.06-0.91l0.53-0.21l0.2-0.22l0.08-0.38l-0.56-0.57l0.41-0.01l0.7,0.41l0.58-0.72l0.45-0.72l2.12-1.24
  		l0.16-0.37l-0.06-0.55l0.62-1.01l0.75-0.75l0.43-0.7l-0.3-0.39l-0.37-1.93l-0.45-1.23l0.15-1.14l0.8-1.13l1.23-1.19l0.84-0.39
  		l4.02-0.95l1.23-0.65l1.05-0.86l0.86-1.54l0.45-0.41l0.46-0.65l-0.64-0.54l-0.61-0.26l-0.41-0.37l-0.17-1.05l0.45,0.96l0.78,0.42
  		l0.92,0.69l-0.13,0.94l-0.69,2.1l-0.36,0.87l0.55,0.51l1.11,0.12l0.22-0.65l0.07-0.54l0.15,0.31l1.18,0.96l1.17-0.4l0.13-0.47
  		l-0.28-1.04l0.63-2.55l0.15,0.48l0.41,0.09l0.19,0.18l0.08,0.27l-0.41,2.39l0.03,0.62l0.15,0.35l0.27,0.33l0.75-0.68l0.2-0.73
  		l0.42,0.8l0.5,0.1l0.71-0.11l-0.08-0.92l-0.19-0.66l-0.59-0.92l0.66,0.01l0.2-1.37l0.04-0.82l-0.05-0.78l-0.66-2.08l-0.43-1.07
  		l0.05-0.35l-0.03-0.14l-0.2-1.38l-0.28-0.84l-0.14-0.9l0.65-1.29l-0.26-0.21l-0.75-0.17l-0.7-0.22l-0.17-0.33l0.31-1.27l-0.36-0.5
  		l-0.52-0.5l-0.16-0.2l-0.17-0.26l-0.24-0.64l0.46-1.35l0.65-1.56l0.12-0.6l0.11-1.04l0.06-0.4l-0.08-0.39l-0.68-0.45l-1.21-0.18
  		l-0.86-0.38l-0.5-0.57l-0.42-0.23l-0.52,0.17l-0.67-0.22l-0.56-0.57l-0.47-0.69l0.06-0.33l0.13-0.43l0.88-1.8l0.33-0.05l0.76,0.34
  		l0.29,0l0.5-0.71l0.7-2.02l0.99,0l0.87,0.07l0.59,0.1l0.59-0.06l0.61-0.16l0.33-0.26l0.18-0.32l-0.06-0.28l-0.75-0.38l-0.29-0.29
  		l-0.21-0.81l-0.22-0.31l-1.48-0.04l-0.77-0.37l-0.42-0.33l-0.75-1.11l-0.93-0.82l-0.89-0.19l-0.34-0.27l-0.19-0.42l0.11-0.61
  		l0.27-0.55l0.18-0.62l0.71-0.82l0.83-0.71l0.37-0.49l0.53-0.52l0.06-0.28l-0.09-0.32l-0.43-0.31l-0.29-0.1l-0.05-0.2l0.2-0.53
  		l0.41-0.07l0.84,0.48l0.86,0.78l0.51,0.7l0.02,0.55l0.33,0.09l0.33,0.02l0.56,0.24l0.58-0.07l0.37,0.14l0.25-0.05l0.09-0.32
  		l-0.28-0.47l-0.2-0.33l0.23-0.33l0.26-0.07l0.3,0.08l0.41,0.3l0.3,0.62l0.05,0.95l0.66,0.86l0.87,0.61l0.68,0.28l0.82,0.2l0.7-0.19
  		l0.35-0.6l-0.16-0.54l0.11-0.48l0.28-0.27l0.44,0.02l0.33,0.38l0.95,2.06l-0.2,0.91l0.21,2.5l-0.24,1.65l0.04,0.35l0.11,0.27
  		l0.16,0.11l0.28,0l1.16,0.31l0.98,0.34l1.1,0.31l1.59,0.24l0.98-0.08l0.49-0.01l0.99,0.07l2.59-0.13l2.15-0.04l0.87,0.24l0.7,0.08
  		l2.39-0.17l2.41-0.08l1.31,0.52l1.42,0.85l0.8,0.63l0.14,0.36l-0.09,0.31l-0.27,0.17l-0.49,0.01l-1.13-0.41l-0.19,0.12l0.02,0.85
  		l-0.03,0.14l-0.24,0.76l-0.68,1.71l-0.13,0.76l-0.14,0.19l-0.18,0.1l-0.53,0.03l-0.42,0.13l-0.17,0.28l-0.27,0.58l-0.19,0.58
  		l-0.26,0.18l-0.62-0.31l-0.39,0.05l-0.49,0.13l-0.49,0.33l-0.33,0.41l-0.38,0.14l-1.13-0.08l-0.22,0.06l-0.14,0.29l-0.12,0.37
  		l-0.88,0.87l-0.33,1.4l-0.27,0.91l0.04,0.7l0.74,1.84l0.52,2.38l0.2,0.24l0.17,0.08l0.08-0.05l-0.02-0.49l0.03-0.6l0.24-0.14
  		l0.31,0.12l0.3,0.52l0.34,0.95l0.36,0.37l0.56,0.1l0.64-0.22l0.47-0.43l0.19-0.46l-0.13-0.92l-0.03-0.68l0.3-0.65l1.09-0.97
  		l0.16-0.3l-0.07-0.83l0-0.78l0.41-0.05l0.55,0.13l0.7-0.38l0.21-0.01l0.29,0.41l0.5-0.07l0.36,1.69l0.38,1.49l0,0.72l0.06,1.52
  		l0.17,1.24l0.27,0.28l0.31,0.66l0.29,0.77l0.23,0.43l0.15,1.41l0.19,1.02l0.25,3.19l0.12,0.62l0.47-0.29l0.19-0.33l0.19-0.5
  		l0.12-0.22l0.27-0.01l0.42,0.24l0.7,0.83l0.46,0.19l0.46-0.13l0.48-1.25l0.18-0.34l0.23-0.22l0.43,0.04l0.25-0.22l0.09-0.54l0-0.6
  		l-0.48-1.65l-0.14-0.76l-0.08-0.75l0.3-0.77l0.41-0.9l-0.1-0.79l0.11-0.31l0.33-0.04l0.42,0.19l0.46-0.13l0.35-0.48l0.14-0.42
  		l0.21-1.83l0.14-1.67l0.06-1.36l-0.36-0.82l-0.55-2.2l0.16-0.38l0.25-0.09l0.81,0.77l0.35,0.13l0.59-0.12l0.58-0.17l0.42-0.01
  		l0.61,0.26l0.84,0.29l1.31,0.36l0.54,0.27l0.45-0.04l0.36-0.85l0.42-1.25l0.62-1.85l0.71-1.35l0.19-0.36l0.79-1.1l0.77-1.15
  		l0.67-1.46l0.37-1.05l-0.03-0.44l-0.24-0.36l-0.5-0.23l-0.41-0.24l-0.11-0.22l0.01-0.25l0.21-0.68l0.36-0.81l0.38-0.44l0.99-0.54
  		l0.63-0.7l0.71-0.92l0.39-0.63l0.2-1.28l0.21-0.25l0.44-0.41l0.33-0.48l-0.03-0.26l-0.18-0.19l-0.33-0.89l-0.15-1.4l0.07-1.14
  		l0.25-0.46l0.33-0.65l0.61-0.4l0.87-0.28l1.34-0.75l2.31-1.75l0.83-0.57l0.57-0.3l0.55-0.73l0.76-0.8l1.09-0.4l0.7-0.15l3.3-0.55
  		l0.56,0.07l0.56,0.32l0.69,1.07l0.62,0.4l0.71,0.28l0.54-0.12l0.01-0.43l-1.7-2.49l-0.15-0.68l-0.06-0.65l0.19-0.52l0.53-0.49
  		l0.73-0.56l0.91-0.7l0.57-0.48l0.67-0.15l0.24-0.28l0.07-0.4L2629.54,1077.74z M2598.83,1256.53l-0.32,0.26l-0.34,0.18l-0.14,0.33
  		l0.35,0.48l0.8-0.8L2598.83,1256.53z M2592.96,1217.5l-0.34,0.25l0.22,0.36l0.68,0.51l-0.38-1.17L2592.96,1217.5z M2593.81,1248.63
  		l-0.16,0.18l-0.11,0.45l0.43,0.48l0.47,0.05l-0.25-0.32L2593.81,1248.63z M2596.16,1251.42l-0.35,0.09l-0.21,0.36l0.27,0.38
  		l0.19,0.36l0.65-0.01l0.04-0.18l-0.12-0.41L2596.16,1251.42z M2597.47,1249.77l-0.32-0.09l-0.32,0.44l0.04,0.53l0.07,0.3l0.02,0.17
  		l0.05,0.24l0.1,0.04l0.4-0.31l-0.21-0.87L2597.47,1249.77z"/>
    </>,
    IL: <>
        <polygon className="st1" points="2111.92,1031.98 2111.83,1031.59 2111.8,1031.13 2112.12,1030.69 2111.97,1030.16 2111.72,1029.62
  		2111.54,1029.35 2111.54,1028.86 2111.66,1028.49 2111.81,1027.91 2111.57,1028.06 2111.12,1028.49 2110.67,1028.84
  		2109.77,1029.38 2109.57,1029.71 2109.37,1029.42 2108.97,1029.61 2108.64,1030.84 2107.95,1031.25 2107.09,1031.22
  		2106.37,1031.1 2105.4,1031.18 2105.14,1032.27 2104.54,1033.58 2103.83,1035.57 2102.84,1039.47 2101.78,1042.26 2100.14,1045.08
  		2100.09,1045.15 2100.48,1045.55 2100.49,1045.7 2099.02,1047.21 2099,1047.86 2098.13,1048.64 2098.83,1050.61 2099.44,1052.15
  		2100.19,1054.29 2100.43,1055.11 2100.53,1055.67 2101.61,1058.01 2102.26,1059.94 2102.73,1061.5 2103.39,1063.77
  		2103.68,1064.56 2104.26,1063.85 2104.69,1061.73 2104.94,1060.72 2105.06,1059.98 2105.68,1058.47 2105.61,1057.98
  		2105.74,1056.24 2105.67,1055.9 2105.95,1054.96 2106.48,1053.58 2107,1052.39 2107.18,1051.86 2107.71,1050.73 2108.19,1049.34
  		2107.94,1048.58 2107.86,1048.43 2108.06,1047.56 2108.05,1047.56 2108.28,1046.13 2107.93,1046.1 2106.82,1046.65
  		2105.34,1047.18 2103.71,1047.32 2103.48,1047.16 2103.42,1046.89 2103.89,1045.6 2104.08,1044.99 2104.78,1044.33
  		2105.78,1043.76 2106.2,1043.62 2106.16,1043.37 2105.56,1043 2104.94,1042.8 2104.35,1043 2104.16,1042.94 2104.1,1042.77
  		2104.31,1042.54 2104.4,1042.1 2104.31,1041.37 2104.25,1040.48 2104.12,1039.8 2104.48,1038.68 2104.58,1038.15 2105.04,1037.01
  		2106.12,1036.32 2107.05,1036.52 2107.54,1036.57 2107.75,1036.7 2107.88,1037.1 2108.57,1037.55 2109.13,1037.61 2109.28,1035.52
  		2109.31,1035.32 2109.5,1035.07 2109.64,1034.94 2110.67,1034.5 2111.12,1034.44 2111.24,1034 2111.71,1033.25 2112.18,1032.43
  		"/>
    </>,
    IRL: <>
        <path className="st0" d="M1758.79,825.93l-0.23-0.86l-0.53-1.54l-0.14-0.75l0.19-0.28l-0.08-0.8l0.07-0.44l-0.09-0.9l-0.45-0.73
  		l-0.29-1.21l-0.35-1.09l-0.43-0.48l-0.22-0.68l0.02-0.53l0.32-0.28l0.65,0.09l0.62-0.16l-0.16-0.42l-0.36-0.41l-0.72-0.07
  		l-0.51,0.21l-0.33,0.19l-0.32-0.03l-0.91,0.07l-0.86-0.02l-0.12-0.3l0.15-0.91l-0.19-0.25l-0.82-0.13l-0.3-0.22l-0.47-0.63
  		l-0.09-0.29l-0.07-0.41l-0.5-0.52l-0.6-0.38l-0.35-0.02l-0.71,0.61l-0.58,0.62l0.21,0.31l0.19,0.41l-0.32,0.29l-0.96,0.67
  		l-0.15,0.26l-0.26,0.14l-0.46-0.19l-1.14,0.04l-0.52-0.12l-0.61-0.5l-1.48-0.33l-0.25-0.79l-0.29-0.15l-1.68-1.37l-0.22-0.46
  		l0.22-0.27l0.63-0.41l2.11-0.68l0.33-0.28l0.07-0.24l-0.62-0.28l-0.56-0.31l-0.19-0.2l-0.02-0.17l0.32-0.23l0.64-0.02l0.5,0.1
  		l0.4-0.21l0.7-0.21l0.47-0.26l0.41-0.67l0.43-0.6l0.04-0.33l0.38-1.15l0.21-0.29l1.33-0.75l0.39-0.53l0.98-0.48l0.8-0.7l-0.83-0.35
  		l-0.8-0.44l-0.77-0.56l-0.52-0.15l-0.48,0.07l0.54,0.72l-1.32,0.2l-0.5,0.4l-0.11,0.64l0.25,0.58l0.15,0.64l0.05,0.51l-0.89,0.62
  		l-0.63,0.27l0.58-0.63l-0.37-0.35l0.41-0.34l0.24-0.45l-0.11-0.58l-0.36-0.33l-0.14-0.52l-0.31-0.15l-0.8,0.1l0.1,0.73l-0.44-0.17
  		l-1.31,0.1l-0.4-0.04l-1.11,0.42l-1.15,0.16l-0.25,0.45l-0.18,0.61l-0.57,0.42l-0.16,0.65l0.29,0.88l-0.79,0.68l-0.48,0.26
  		l-0.09,0.31l-0.94,0.26l-0.55,0.34l-0.41,0.59l2.59,0.84l2.72-0.37l-0.5,0.71l-0.32,0.85l-0.47,0.26l-1.08,0.28l-0.47,0.22
  		l-0.7,0.45l-0.58,0.66l0.46,0.5l0.19,0.72l-0.36,0.12l-1.34-0.38l-2.15-0.28l-0.27,0.07l-0.57,0.65l-0.37,0.18l-1.43-1.03
  		l-2.08-0.11l-1.3,0.09l-0.9,0.37l-0.94,0.01l-0.51-0.09l-0.51,0.21l-0.28,0.49l-0.02,0.7l0.97-0.36l0.28,0.53l0.08,0.77l0.66-0.23
  		l0.07,0.54l-0.07,0.51l-0.34,0.77l-0.15,0.85l1.4-0.31l1.42,0.13l-0.1,0.45l0.1,0.41l-1.41,0.28l-1.32,0.63l-0.09,0.37l0.02,0.43
  		l0.45,0.28l1.14,0.33l-1.33,0.16l-1.04,0.33l-0.51-0.07l-0.47,0.22l0.09,0.45l0.44,0.36l-0.33,0.38l0.02,0.38l0.74,0.18l0.88-0.12
  		l0.87,0.14l-0.68,0.6l0.42,0.26l0.43,0.02l0.62-0.18h0.63l0.2,0.13l0.17,0.58l0.22,0.23l0.35,0.16l0.37,0.04l2.78-0.18l0.9,0.17
  		l0.87,0.33l-0.56,0.51l-0.26,0.1l-0.28,0l-0.64,0.27l-0.88,0.05l-0.48,0.31l-1.37,1.71l0.39,0.21l0.19,0.37l-0.6,0.83l-0.42,0.48
  		l-1.89,1.51l-1.49,0.89l1.28-0.12l1.22-0.49l0.24-0.24l0.26-0.12l0.82,0.31l0.58,0.11l1.84-0.2l0.65-0.38l0.91-0.99l0.56,0.49
  		l1.18,0.37l-2.3,0.66l-2.32,0.48l-2.15,0.22l-0.39,0.14l-1.09,0.91l-0.65,0.27l-0.57,0.44l0.45,0.32l0.1,0.95l0.58,0.47l-1.39,0.14
  		l-0.47-0.24l-0.58-0.19l-0.59-0.07l-0.66,0.12l-1.23,0.73l-0.22,0.43l-0.06,0.39l1.19,0.1l2.47-0.12l0.39,0.15l-0.31,0.49
  		l-0.83,0.4l-0.86,0.28l-0.72,0.51l-1.24,1.19l0.32,0.78l0.84-0.15l0.25,0.32l1.07,0.14l4.09-1.16l-1.27,0.56l-0.45,0.5l-0.39,0.16
  		l-0.65,0.4l-1.2,0.84l-0.43,0.61l0.93-0.12l0.93-0.4l2.69-0.47l0.46,0.09l-0.15,0.19l-1.41,0.68l-1.05,1.35l0.83,0.11l1.7-0.54
  		l0.61-0.08l0.61,0.11l0.56,0.25l0.23-0.01l4.07-0.97l0.66-0.57l1.23-0.17l0.93-0.62l0.59-0.06l0.49-0.31l0.11-0.6l-0.1-0.61
  		l-0.2-0.32l-0.32-0.14l1-0.02l0.3,0.14l0.27,0.27l0.65,0.45l0.73-0.14l0.89-0.45l0.68-0.78l0.29-0.14l1.46-0.36l0.33-0.15l0.3-0.29
  		l0.22-0.49l0.3-0.42l0.73-0.27l1.89-0.25l1.13,0.06l0.66-0.3l0.32-0.94l0.43,0.91l0.21,0.11l0.26-0.22l0.65-0.36l0.71-0.03
  		l1.15,0.28l1.04-0.16l0.95-0.5l-1.16-1.11l0.53-0.24l0.46-0.4l1.08-1.6l0.15-1.37l0.25-0.85l0.33-0.79l0.5-0.66l0.37-0.7
  		L1758.79,825.93z M1725.25,816.41l0.62,0.41l0.04-0.33l-0.07-0.85l-0.34-0.19l-1.21-0.02l-0.35-0.13l-0.71,0.45l1.71,0.21
  		L1725.25,816.41z"/>
    </>,
    IDN: <>
        <path className="st0" d="M2720.08,1286.4l1.3,0.35l-0.06,0.22l-0.17,0.1l-0.25,0.04l-0.72,0.53l0.67,0.43l1.15-0.31l0.65-1.23
  		l0.04-0.33l-0.05-0.92l-1.16-1.39l-0.06-0.55l-0.39,0.14l-1.67,1.32l-0.01,0.5l0.36,0.79L2720.08,1286.4z M2715.83,1343.26
  		l0.06,0.3l0.03,0.69l0.35,0.56l-0.19,0.71l0.38-0.03l1.37-0.38l0.13-0.54l0.18-0.09l0.7,0.36l0.22,0.31l0.08,0.46l0.66,0.04
  		l0.24-0.27l0.71-0.44l0.21-0.33l0.13-0.88l0.7-1.4l-0.64-1.11l-1.19-0.84l-1.68-0.31l-0.31-0.25l-1.44,0.3l-0.21,1.38l-0.37,0.57
  		l0.06,0.53l-0.35,0.48L2715.83,1343.26z M2714.55,1343.13l0.34-0.22l0.22-0.46l-0.21-0.09l-0.46,0.03l-0.14,0.29l0.06,0.23
  		L2714.55,1343.13z M2700.02,1294.04l-0.01,0.43l0.1,0.25l0.35-0.03l0.41-0.34l0.23-0.61l-0.12-0.07l-0.23-0.1l-0.29-0.14l-0.25-0.2
  		l-0.32-0.21v0.43l0.1,0.25L2700.02,1294.04z M2704.87,1292.8l0.01-0.58l-0.12-0.5l-0.4-0.11l-0.19,0.21l0.11,0.64L2704.87,1292.8z
  		 M2696.53,1336.34l0.42,0.09l1.77-0.39l0.39,0.2l0.89,0.25l0.68,0.41l0.18,1.06l0.47,0.9l0.38,0.31l0.26,0.35l-0.26,1.25l0.25,0.84
  		l0.52,0.68l1.07,0.26l1.05,0.32l0.77,0.46l0.47,0.15l0.43,0.23l0.4,0.29l0.43,0.22l0.54,0.13l0.48,0l-0.08-0.59l-0.33-0.54
  		l-0.06-0.34l0.56-1.6l0.23-0.39l0.32-0.34l0.62-0.37l-3.81-0.91l-1.32-2.31l-0.4-2.69l-0.29-0.56l-0.4-0.52l-0.29-0.58l-0.15-0.64
  		l-0.39-0.45l-0.6-0.29l-0.79,0.01l-0.81,0.23l0.29,1.04l-0.45,0.61l-0.28-0.42l-0.23-0.59l-0.46-0.7l-1.06,0.4l-0.39,0.3
  		l-0.33,0.39l-0.07,0.4l0.19,0.38l-0.1,0.52l-0.49,0.4l-1.05,0.47l-0.45,0.47l-0.04,0.58l0.96,0.31L2696.53,1336.34z
  		 M2850.57,1363.59l0.34,0.05l0.03-0.85l-0.56-0.18l-0.15,0.18l-0.02,0.23l0.08,0.28L2850.57,1363.59z M2708.74,1343.19l0.05,0.23
  		l0.21,0.22l0.34,0.23l0.47,0.09l0.14-0.17l0.2-0.6l-0.96-0.29L2708.74,1343.19z M2825.73,1377.46l0.06,0.82l0.27,0.07l0.86-0.05
  		l0.06-0.47l-0.3-0.02L2825.73,1377.46z M2835.73,1362.21l-0.1,0.45l0.43,0.79l1.01,0.96l0.53-0.22l0.16-1.82l-0.18-0.52l-0.35-0.15
  		l-0.29-0.55l-0.44-0.03l-0.4,0.2l0.06,0.41l-0.14,0.1L2835.73,1362.21z M2802.91,1284.71l-0.04-0.3l-0.28-0.8l-1.17,0.16
  		l-0.81-0.02l-0.2,0.4l0.94,0.98L2802.91,1284.71z M2835.13,1322.05l-0.65,0.6l-0.14,0.4l0.22-0.01l0.34-0.25l0.29-0.03l0.76-0.01
  		l0.29,0.11l0.22-0.43l-0.36-0.37l-0.56,0.09L2835.13,1322.05z M2812.64,1386.9l-0.35,0.15l-0.13,0.35l0.08,0.34l0.37-0.01
  		l0.28-0.13l0.24-0.22l-0.06-0.17l-0.26-0.32L2812.64,1386.9z M2824.28,1371.04l0.06,1.28l0.16,0.49l0.34-1.4l0.18-2.76l-0.13-0.55
  		l-0.48-1.08l-0.26,0.85l0.04,1.84l-0.13,0.72L2824.28,1371.04z M2816.49,1390.68l0.16-0.3l0.01-0.29l-0.09-0.31l-0.38-0.01
  		l-0.17-0.07l-0.09-0.15l-0.2-0.22l-0.14,0.22l-0.09,0.71l-0.35,0.4l0.19,0.51l-0.14,0.75l0.33,0.12l0.33-0.08l-0.17-0.58l0.33-0.37
  		L2816.49,1390.68z M2726.8,1294.33l-0.01-0.78l-0.16,0.06l-0.68,0.89l0.44,0.27L2726.8,1294.33z M2672.72,1310.69l-0.11,0.51
  		l0.31,0.28l0.43,0.21l1.42,0.4l0.55,0.04l1.64,0.36l0.31,0.24l0.16-0.32l-0.21-0.94l-0.49-0.28l-1.38-0.56l-0.45-0.25l-0.78-0.55
  		l-0.71-0.63l-0.36,0.35l-0.13,0.85L2672.72,1310.69z M2621.99,1298.01l-0.22-0.41l-2-1.22l-0.42-0.5l-0.24-0.17l-0.87-0.34
  		l-0.86-0.9l-0.75-0.23l-0.29,0.51l-0.46,0.24l0.13,0.5l0.64,0.93l0.6,0.12l0.49,0.36l0.7,0.02l2.26,1.39l0.42,0.48l0.51,0.18
  		l0.53-0.08l-0.04-0.47L2621.99,1298.01z M2669.46,1306.11l0.7,0.28l0.95,0.1l0.78,0.32l0.49,0.43l0.63,0.28l-0.06-1.07l-0.19-0.43
  		l-3.6-0.97l-0.15,0.15l-0.05,0.19l0.02,0.23L2669.46,1306.11z M2680.84,1310.33l-0.05-0.46l-0.16-0.2l-0.21-0.51l-0.21,0.14
  		l-0.33,0.38l0.4,0.54L2680.84,1310.33z M2678.39,1314.05l0.27,0.11l0.95-0.05l0.09-0.6l-0.48-0.71l-0.43-0.01l-0.29,0.47
  		L2678.39,1314.05z M2691.19,1319.17l-0.19-0.37l-0.78-0.3l-0.38,1.21l-0.42,0.53l0.22,0.19l0.78,0.28l1.14-0.31l1.79,0.96
  		l0.12-0.05l0.19-0.27l0.46-0.01l-0.47-0.3l-0.34-0.3l-0.55-0.59l-0.3,0.43l-0.24-0.14l-0.55-0.61l-0.51-0.23L2691.19,1319.17z
  		 M2664.59,1304.14l0.86,0.15l0.98-0.61l0.12-0.78l0.33-0.51l-0.09-0.44l-0.46-0.69l-0.57-0.4l-0.81,0.55l-0.8-0.06l-0.34,0.39
  		l-0.05,1.01l0.54,1.19L2664.59,1304.14z M2671.81,1307.39l-0.69-0.42l-0.17-0.02l-0.23,0.41l0.05,0.7l0.18,0.98l0.2,0.6l0.38,0.57
  		l0.47,0.4l0.39-0.25l0.01-0.65l0.17-0.74l-0.05-0.65l-0.25-0.22L2671.81,1307.39z M2612.72,1270.2l0.16-0.24l0.03-0.25l-0.06-0.29
  		l-0.65-0.18l-0.34-0.08l-0.2,0.15l0.55,0.76L2612.72,1270.2z M2690.22,1316.78l-0.38-0.07l0.88,1.15l0.16,0.13l0.24,0.14l0.4,0.23
  		l-0.07-0.21l-0.25-0.36L2690.22,1316.78z M2688.7,1322.02l-0.19-0.17l-0.19-0.02l-0.14,0.05l-0.38,0.65l0.61,0.63l0.28,1l0.43-0.63
  		l0.6,0.24l0.18-0.14l0.31-0.64l0.39-0.45l-0.19-0.29l-0.79-0.81L2688.7,1322.02z M2680.3,1311.52l-0.18,0.61l0.72,1.06l0.17-0.11
  		l0.4-0.49l-0.23-0.46l-0.31-0.39l-0.4-0.37L2680.3,1311.52z M2688.12,1310.14l1.22-0.29l0.2,0.46l-0.28,0.33l0.44,0.2l-0.08,0.16
  		l0.08,0.22l0.2,0.29l0.6,0.17l0.2-0.23l0.45-0.85l0.08-0.74l-0.12-0.46l-0.48-0.31l-0.05-0.63l-0.72,0.3l-0.6-0.13l-0.56,0.12
  		l-0.94,0.66l-0.05,0.21l0.06,0.52L2688.12,1310.14z M2684.24,1312.19l-0.05-0.24l-0.19-0.38l-0.46-0.38l-0.1,0.16l0.02,0.35
  		L2684.24,1312.19z M2685.33,1310.17l0.86,0.2l0.52-0.86l0.09-0.3l0.02-0.31l-0.44,0.24l-0.53-0.36l-0.21,0.36l-0.37,0l-0.07,0.42
  		l-0.12,0.13l0.06,0.21L2685.33,1310.17z M2686.55,1310.84l-0.06,0.13l-0.02,0.18l0.66,0.76l0.53-0.24l-0.1-0.38l-0.48-0.14
  		l-0.4-0.4L2686.55,1310.84z M2675.43,1310.02l0.83,0.29l0.65,0.45l0.58,0.51l0.45,0.28l0.43,0.05l0.25-0.24l-0.83-1.21l-0.57-0.44
  		l-1.76-0.82l-0.54,0.06l-0.2,0.88l0.21,0.1L2675.43,1310.02z M2890.27,1301.62l1.33-0.14l0.79-0.38l0.64-1.07l0.55-2.09l-0.73-1.04
  		l-0.28,0.01l-0.81,0.21l-1.19,0.85l-0.95,1.44l0.35,1.8L2890.27,1301.62z M2910.62,1327.89l0.23,0.57l0.36,0.69l0.53,0.52
  		l1.02,0.22l0.3-0.23l0.37-1.07l0.23-1.84l-0.34-0.42l-0.79-0.02l-0.36-0.21l-1.89,0.58l-0.04,0.2l0.31,0.55L2910.62,1327.89z
  		 M2882.97,1309.11l0.77,1.81l0.74,0.63l-0.06,0.44l-0.28,0.45l-0.21,0.52l-0.04,0.58l0.15,1.01l0.51,0.89l0.44,0.39l0.34,0.41
  		l-0.2,1.16l-0.03,0.96l0.05,0.96l-0.11,0.69l0.14,0.66l0.41,0.49l0.95,0.67l0.3,0.37l1.03,1.96l0.3,0.41l1.57,0.68l0.38,0.69
  		l1.24,0.19l-0.77-0.64l-0.68-0.71l-1.38-2.06l-0.94-1.98l-0.75-2.49l0.2-0.85l0.02-0.47l-0.23-0.77l0.12-0.62l0.19-0.55l0.49-0.28
  		l1.04,0.09l0.96,0.39l0.95,0.14l0.96,0.05l0.79,0.45l3.02,1.02l-0.31-0.44l-0.4-0.31l-0.61-0.15l-0.44-0.31l-0.07-0.65l-0.24-0.58
  		l-0.37-0.35l-1.8-0.74l-1.16-0.79l-0.03-0.6l0.35-0.6l0.4-0.25l1.42-0.6l1.3-0.75l0.29-0.31l0.12-2.18l-0.24-0.81l0.14-0.54
  		l-0.14-0.38l-1.26,0.11l-0.97,0.35l-1.7,0.98l-0.55,0.7l-0.04,0.66l0.06,0.67l-0.37,0.26l-0.52,0.1l-0.74,0.61l-0.32,0.9
  		l-0.09,0.48l-0.32,0.37l-0.64,0.05l-0.61-0.19l-0.47-0.32l-0.36-0.41l-0.01-0.34l0.17-0.31l1.96-1.25l0.86-1.06l0.2-0.35l0.12-1.06
  		l-0.02-1.05l-0.11-0.99l-0.54-0.74l-0.5-0.36l0.03-0.61l0.14-0.33l1.05-1.46l0.1-0.31l-0.05-0.35l-0.61,0.2l-0.54,0.31l-1.42,1.43
  		l-0.84,1.02l-0.51,1.2l-0.11,0.55l-0.2,0.52l0.02,0.88l-0.98,1.8L2882.97,1309.11z M2850.7,1332.91l0.16-0.64l-0.29-0.31
  		l-0.17-0.33l-0.11-0.37l-0.17-0.04l-0.35,0.27l-0.03,1.23l0.39,0.25L2850.7,1332.91z M2900.15,1319.74l0.31,0.49l0.36-0.02
  		l-0.06-0.4l-1.96-1.54l0.52,0.94L2900.15,1319.74z M2883.25,1325.39l0.3-0.03l1.06-0.3l0.16,0.15l0.21,0.4l0.79,0.43l0.68-0.3
  		l0.32-0.33l-0.14-0.41l-0.21-0.3l-0.28-0.25l-1.23-0.04l-0.45-0.66l0.65-1.19l-0.97-1.25l-0.33-0.1l-0.3,0.25l-0.31,0.59
  		l-0.71-0.62l-0.39,0.04l0.04,0.46l-0.27,0.58l0.02,0.34l0.68,0.83l0.74,0.36l-0.26,0.8L2883.25,1325.39z M2882.5,1313.33l0.09,0.03
  		l0.3-0.09l0.22-0.34l-0.03-0.42l-0.15-0.09l-0.36,0.01l-0.17,0.57L2882.5,1313.33z M2883.18,1318.69l-0.03-0.63l-0.15-0.62
  		l-0.1,0.15l-0.19,0.9l0.18,0.09l0.26,0.36L2883.18,1318.69z M2903.67,1335.27l1.74,0.35l0.34,0.3l0.97-0.13l0.3-0.32l0.46-0.23
  		l0.28,0.08l0.39-0.17l-0.22-0.25l-0.11-0.33l0.2-0.11l0.17-0.71l-0.5-0.46l-0.1-0.5l-0.3,0.01l-1,0.34l-0.79-0.01l-0.94,0.24
  		l-2.16,0.9l0.14,0.23L2903.67,1335.27z M2946.04,1328.14l0.4-0.15l0.26-0.69l-0.33-0.03l-0.13-0.43l-0.43-0.34l-0.52,0.34
  		l-0.16,0.49l0.44,0.64L2946.04,1328.14z M2950.37,1325.08l0.44,0.27l0.07-0.13l0.28,0.02l1.03,0.79l0.85-0.49l0.66,1.72l0.11,0.76
  		l0.64,0.49l0.74-0.07l0.91,0.39l0.46-0.02l1.18-0.35l0.59-0.66l-0.78-0.25l-1.08-0.72l-0.72-0.84l-1.48-1.27l-0.44-0.12l-0.78,0.17
  		l-0.64-0.37l-0.65,0.02l-1.79-0.33l0.04,0.45L2950.37,1325.08z M2954.96,1332.33l-0.9,0.05l-3.33-0.42l-0.04,0.2l0.15,0.44
  		l3.19,0.7l1.54,0.6l1.21,0.29l0.3,0.29l0.83-0.18l1.14,0.15l1.36-0.15l0.73-0.3l1.55-0.32l-0.64-0.38l-0.83-0.17l-2.77-0.1
  		l-1.58-0.56L2954.96,1332.33z M2909.9,1323.07l0.49-0.5l-0.23-0.27l-0.35-0.16l-0.16,0.01l-0.28,0.19l-0.32,0.07l-0.51,0.32
  		l0.87,0.36L2909.9,1323.07z M2908.32,1326.07l-0.31,0.31l3.62-0.51l0.4-0.29l0.21-0.43l-0.83-0.1l0.05,0.41l-0.76,0.07l-0.74-0.09
  		l-0.35,0.13l-0.2-0.04l-0.18,0.04l-0.18,0.09l-0.36-0.04l-0.23,0.16L2908.32,1326.07z M2941.47,1336.41l0.38-0.6l-0.24-0.18
  		l-0.18-0.03l-0.16,0.08l-0.13,0.49l0.09,0.36L2941.47,1336.41z M2908.8,1320.88l0.46,0.83l0.2-0.04l0.27-0.28l0.69-0.27l0.09,0.8
  		l0.42,0.44l1.23-0.23l0.02-0.6l-0.47-0.39l-0.35,0.03l-0.34-0.09l-0.59-0.93l-0.45-0.31l-0.14-0.48l0.52-0.04l1.05,1.22l0.74,0.35
  		l0.42,0.58l0.5,0.19l0.78-0.26l0.67,0.13l0.34,0.24l0.33-0.07l0.69-0.63l-0.31-0.41l0.12-0.31l-0.34-0.46l-2.11-0.92l-0.33,0.06
  		l-1.46-0.36l-0.77,0.22l-1.16,0.13l-0.3,0.21l-0.41-0.08l-0.58,0.32l-0.58-0.21l-0.57,0.24l-0.06,0.44l-0.43,0.46l0.88,0.44
  		L2908.8,1320.88z M2882.21,1312.3l0.29-0.27l-0.16-0.47l-0.29-0.12l-0.23,0.16l-0.05,0.26l0.17,0.36L2882.21,1312.3z
  		 M2845.41,1352.38l0.02,0.26l0.2,0.73l0.69,0.66l0.58,0.05l0.82-1.01l0.04-0.6l-0.3-0.36l-1.57-0.14L2845.41,1352.38z
  		 M2839.63,1363.19l0.2,0.52l0.54,0.02l0.17-0.4l0.69,0.38l0.39,0.09l0.37-0.03l0.47-0.43l0.22-0.56l-0.26-1.1l1.23-1.72l-0.17-2.17
  		l-0.32-0.47l-0.35,0.13l-1.14,0.61l-1.31,0.5l-0.29,0.67l0.47,1.27l0.05,0.6l-0.57,0.57L2839.63,1363.19z M2846.83,1333.48
  		l-0.26,0.12l-0.17,0.24l-0.13,0.33l0.07,0.37l0.62-0.69L2846.83,1333.48z M2845.4,1363.91l0.14-0.1l0.49,0.22l0.65-0.22l0.56-0.5
  		l0.12-0.5l-0.44-0.41l-0.83-0.57l-0.23,0.05l-0.37,0.2l-0.08-0.4l0.12-1.47l0.24-0.44l0.03-0.66l0.31-0.7l0.24,0.01l0.17-0.08
  		l0.13-0.14l0.17,0.14l0.47,0.69l0.06-0.47l-0.2-1.8l-0.93-0.99l0.05-0.39l-0.31,0.07l-0.77,0.4l-0.79,1.47l-0.03,1.79l-0.24,0.95
  		l-0.15,0.47l-0.09,0.44l-0.22,1.05l-0.01,0.28l-0.3,0.43l-0.52,0.58l-0.23,0.42l0,0.38l-0.47,0.53l-0.01,0.47l0.51,1l0.33,0.02
  		l0.41-0.26l0.67,0.31l0.32-0.28l0.56-1l-0.06-0.35l0.22-0.34L2845.4,1363.91z M2849.45,1331.19l0.53-0.43l0.3-0.92l-0.17-0.43
  		l-0.32-0.22l-0.46-0.19l-0.81,0.14l-0.85,1.13l-0.33-0.85l0.31-0.45l-0.19-0.52l-0.45-0.12l-1.57,0.26l-0.54-0.06l-0.64,0.84
  		l-0.18,1.25l0.4,0.97l0.27,0.33l0.79-0.64l1.02-1.43l0.38-0.3l0.27,1.58l-0.5,0.53l0.06,0.18l0.36,0.32l0.4-0.15l0.14-0.18
  		l0.32-1.17l0.45,0.05l0.32,0.54L2849.45,1331.19z M2852.21,1334.7l0.22,0.63l0.53,0.01l-0.15-0.34l-0.55-0.65L2852.21,1334.7z
  		 M2880.93,1323.01l0.53-0.22l0.26-0.87l-0.23-0.61l0.31-0.29l-0.85-0.24l-0.53,0.19l-0.19,1.13l0.12,0.89L2880.93,1323.01z
  		 M2845.76,1389.49l-0.65,0.41l-0.25,0.28l-0.13,0.48l0.49,0.14l0.27-0.5l0.44-0.42l1.04-0.16l-0.13-0.16l-0.41-0.14
  		L2845.76,1389.49z M2848.34,1388.71l0.16-0.72l-1-0.28l-0.7,0.15l-0.21,0.18l-0.64,0.53l-0.06,0.49l2.29,0.03L2848.34,1388.71z
  		 M2877.5,1286.48l-0.01,0.25l0.19,0.25l0.3,0.23l0.14-0.1l0.05-0.16l-0.01-0.21l-0.44-0.38L2877.5,1286.48z M2876.87,1284.57
  		l0.06,0.42l0.55,0.06l0.34-0.18l0.26-1.23l0.62-0.93l-0.29-0.68l-0.18-0.9l-0.44-0.48l-0.46-0.09l-0.31,1.11l0.01,0.6l0.38,0.52
  		l0.39,0.2l-0.37,0.81L2876.87,1284.57z M2877.01,1286.57l-0.02-0.35l0.17-0.36l-0.46-0.7l-0.4-0.34l0.2,0.95L2877.01,1286.57z
  		 M2853.48,1389.5l0.75-0.04l0.41,0.91l0.68-0.17l0.32-0.28l0.35-0.91l0.51-0.52l0.15-0.85l-0.15-0.17l-0.18-0.06l-0.22,0.01
  		l-1.09,1.35l-0.12-0.07l-0.23-0.32l-0.46,0.01l-0.36,0.3L2853.48,1389.5z M2854.52,1368.68l-0.24-0.6l-0.4-0.18l-0.03,0.5
  		l0.28,0.23l0.31,0.46L2854.52,1368.68z M2866.73,1288.57l0.14,0.36l-0.05,0.74l1.03,0.47l0.21-0.26l-0.12-0.34l-0.49-0.79
  		l-0.36-0.83l-0.63-0.53l-0.11,0.41l0.11,0.38L2866.73,1288.57z M2811.2,1340.67l-0.46,0.16l-0.37,0.27l-0.22,0.32l0,0.37l0.38,0.71
  		l0.25,0.66l-0.31,0.94l-0.08,0.97l0.17,1.03l0.29,0.99l0.46,0.71l0.61,0.46l2.07-0.52l1.03-0.14l0.49,0.14l0.4,0.31l0.23,0.34
  		l-0.02,0.45l-0.11,0.5l0.01,0.52l0.11,0.32l0.99,1.94l0.1,0.29l-0.1,3.26l-0.15,0.83l-0.41,0.9l-0.25,0.93l0.03,1.14l-0.72,1.69
  		l-0.36,1.02l-0.25,0.95l0.13,0.93l0.73,0.81l0.79,0.75l1.35,0.69l0.4-0.04l0.46-0.22l0.75-0.55l0.37-0.16l1.06-0.02l1.04-0.14
  		l0.47-0.13l0.46-0.02l1,0.41l-0.12-0.85l-0.22-0.82l-0.94-2.07l0.02-0.45l0.24-1.09l0.8-1.98l0.13-0.92l-0.3-1.7l-0.19-2.76
  		l0.17-1.96l0.44-0.88l0.01-0.34l-0.37-3.01l-0.27-0.85l-0.51-0.78l-0.39-0.85l0.06-0.87l0.68-0.67l1.71-1.15l0.92-0.54l0.94-0.22
  		l0.96,0.03l0.93,0.21l0.52,0.68l0.12,1.08l0.03,1.08l-0.14,1.31l-0.14,0.32l-1.1,1.67l-0.14,0.47l0.01,0.5l0.19,0.29l3.36,3.05
  		l0.87,0.54l1.02,0.26l0.68,0.65l-0.06,0.53l-0.24,0.45l-0.22,0.29l-0.13,0.32l-0.46,2.5l0.23,0.84l0.63,0.66l0.48,0.22l0.86,0.26
  		l1.42,0.26l1.02-0.13l0.3-0.34l-0.2-0.98l0.04-0.46l0.51-0.67l0.78-0.37l2.23-0.62l1.21-0.04l0.48,0.04l0.42-0.1l-0.06-0.29
  		l0.04-0.3l0.82,0.41l0.46,0.02l0.21-0.36l0.02-1l-0.05-0.53l-0.14-0.48l-0.25-0.37l-0.42-0.09l-0.16,0.23l-0.24,0.15l-0.51-0.13
  		l-0.33-0.54l-0.36-0.3l0.03-0.51l-0.26-0.34l-0.42-0.25l-0.8-0.95l-0.41-0.24l-0.47-0.14l-0.35-0.27l-0.3-0.35l-0.01-0.37l0.1-0.41
  		l0.42-1.21l0.04-0.9l0.69-0.62l-0.15-0.49l-0.63-0.76l-0.14-0.4l0.11-0.44l-0.1-0.37l-1.76-1.32l-0.58-0.78l-0.35-0.95l-1.04-1.77
  		l-0.66-0.76l-0.37-0.28l-0.88-0.29l-0.4-0.19l-0.62-0.89l-0.8-0.63l-0.49-0.2l0.06-0.57l0.33-0.37l1,0.45l0.5,0.15l0.66-0.09
  		l0.57-0.27l0.51-0.8l0.66-0.62l2.52-0.83l0.78-0.32l0.7-0.48l1.45-1.26l1.26-1.44l0.58-0.93l0.7-0.82l0.38-0.32l0.42-0.23
  		l1.23-0.24l0.87,0.29l0.61,0.79l0.62,0.2l0.66-0.18l0.15-0.36l0.32-1.53l-0.14-0.59l-0.32-0.49l-0.83-0.48l-0.93-0.17l-1.27,0.24
  		l-1.25,0.34l-0.36,0.15l0.1,0.24l0.37,0.29l0.03,0.28l-1.94,0.12l-1.09-0.11l-2.1,0l-0.88,0.31l-0.31,0.38l-0.37,0.3l-1.04,0.49
  		l-0.98,0.11l-0.97-0.17l-0.48-0.32l-0.41-0.4l-0.48-0.1l-0.47,0.23l-0.74,0.69l-1.3,1.5l-0.54,0.79l-0.54,1.06l-0.97,0.56
  		l-0.99-0.24l-1-0.12l-0.58,0.07l-0.52-0.01l-0.52-0.93l-0.74-1.83l-0.78-0.66l-1.31-0.51l-0.25-0.26l-1.21-1.83l-0.29-0.79
  		l-0.26-1.03l-0.17-1.04l0.01-0.93l0.19-0.89l0.36-1.09l0.41-1.06l0.55-0.85l0.97-1.17l0.35-0.34l0.93-0.51l1-0.15l1.02,0.11
  		l1.76,0.57l0.87,0.04l3.48-0.44l0.76-0.03l0.75,0.1l1,0.29l1,0.12l1.84-0.26l1.85-0.11l5.3-0.04l0.74-0.06l0.72,0.06l0.82,0.59
  		l0.72,0.74l0.38,0.08l1.82,0.14l0.96,0.02l0.96-0.07l2.93-0.58l0.97-0.05l0.52-0.15l0.9-0.39l0.36-0.21l0.73-0.72l0.63-0.82
  		l0.43-0.74l0.49-0.69l1.61-1.42l0.66-0.73l0.52-0.82l0.75-1.66l0.2-0.25l0.68-0.59l0.1-0.2l-0.58-1.18l-0.45-0.35l-1.02-0.13
  		l-0.36,0.24l-0.73,0.8l-0.61,0.91l-0.35,0.22l-0.87,0.21l-0.37,0.2l-0.21,0.74l-0.35,0.62l-1.03,0.38l-1.16,1.36l-2.89,1.44
  		l-0.71,0.1l-4.79-0.75l-1.78-0.11l-0.45,0.02l-0.44,0.13l-0.57,0.61l-0.46,0.04l-0.41-0.14l-1.11-0.65l-0.91-0.37l-0.95-0.28
  		l-2.77-0.11l-2.03-0.48l-2.32,0.17l-0.35-0.1l-0.32-0.21l-0.34-0.43l-0.28-0.49l-0.3-0.24l-0.4-0.09l-0.63,0.04l-0.62-0.11
  		l-1.07-0.55l-0.48,0.02l-0.5,0.12l-0.45,0.19l-0.37,0.3l-0.54,0.87l-0.41,0.95l-0.37,0.41l-0.44,0.36l-0.27,0.35l-0.2,0.4
  		l-0.72,0.31l-0.85-0.26l-0.42-0.33l-0.37-0.8l-0.24,0.03l-0.21,0.07l-0.33,0.92l-0.62,0.73l-0.47,0.28l-0.37,0.4l-0.18,1.05
  		l-0.31,0.39l-0.71,0.63l-0.88,1.73l0.02,0.43l0.45,1.23l-0.23,0.52l-0.44,0.29l-0.43-0.05l-0.12,0.31l0.42,3.31l0.49,1.7l0.13,0.73
  		l-0.01,0.74l-0.49-0.82l-0.63-0.7l-0.49,0.39l-1.22,1.5l-1.26,2.82l-0.42,1.38l0.01,0.73l0.12,0.74l-0.13,0.63l0.34,1.39
  		l-0.22,0.87l-0.68,0.85l-0.58,0.91l-0.29,0.99l-0.02,1.04l-0.36,0.84l-1.13,0.96L2811.2,1340.67z M2726.39,1332.55l0.97-0.35
  		l0.03-0.46l-0.67-0.2l-0.62,0.23L2726.39,1332.55z M2886.51,1343.89l0.74,0.03l0.42-0.7l-0.42,0.13l-0.88,0.44L2886.51,1343.89z
  		 M2888.3,1332.97l0.26-0.09l0.52-0.34l-0.04-0.48l-0.98-0.6l-1.08-0.77l-1.37-0.66l-0.81-0.23l-0.46,0.15l-1.14,0.86l-0.52,1.14
  		l-0.02,0.46l0.55,0.43l0.88,0.27l1.51-0.32l1.45-0.05L2888.3,1332.97z M2884.03,1345.88l-0.2,0.06l-0.36,0.22l0.37,0.36l0.83,0.23
  		l-0.19-0.37L2884.03,1345.88z M2887.77,1386.89l-1.47-0.34l-0.31,0.18l0.29,0.59l1.71,0.55l0.81-0.71l-0.17-0.3L2887.77,1386.89z
  		 M2893.32,1378.07l-0.26-0.19l-0.42,0.12l-0.4,0.43l0.17,0.18l0.63,0.44l0.38-0.21l-0.03-0.38l0.05-0.21L2893.32,1378.07z
  		 M2893.39,1348.08l-0.6-0.18l-0.08,0.15l0.29,0.64l0.33-0.01l0.46,0.12l0.06-0.11l0.02-0.35l-0.24-0.19L2893.39,1348.08z
  		 M2882.52,1382.12l-0.17,0.62l0.54-0.2l0.38-0.22l0.08-0.15l0.01-0.4l-0.88-0.15L2882.52,1382.12z M2888.78,1348.67l-0.87,0.11
  		l-0.75,0.66l-0.02,0.17l0.08,0.37l0.37,0.23l0.63-0.47l0.49-0.24l0.3-0.08l0.1,0.17l-0.13,0.29l0.41,0.02l0.49-0.2l0.22-0.31
  		l0.02-0.35l0.11-0.3l0.19-0.28l0.13-0.4l-0.55-0.03L2888.78,1348.67z M2892.3,1348.28l-0.71-0.22l-0.2,0.22l-0.31,0.82l1.44-0.44
  		L2892.3,1348.28z M2870.57,1334.68l2.79-0.48l0.37-0.3l-2.59-0.28l-2.56,0.2l-1.68-0.11l-0.64,0.07l-0.48,0.29l0.33,0.33l0.06,0.47
  		l0.39,0.02l3.03-0.28L2870.57,1334.68z M2865.94,1296.46l-0.03-0.46l0.36-0.47l-0.09-0.17l-0.37-0.18l-0.26,0.49l0.31,0.98
  		L2865.94,1296.46z M2857.3,1389.21l0.54,0.25l5.5-0.77l0.29-0.22l-0.06-1.03l-0.63-0.21l-1.06-0.11l-1.45-0.05l-0.63,0.07
  		l-0.65,0.28l-0.2-0.51l-0.56-0.05l-0.66,0.4l-0.31,0.59l0.27,0.36l-0.59,0.76L2857.3,1389.21z M2633.31,1309.06l-0.74-0.32
  		l-1.83-2.35l-1.06-0.62l-0.11,0.1l-0.15,0.39l-0.68,0.49l-1.39-0.02l1.83,1.99l0.61,1.09l0.31,0.92l0.47,0.05l1.2,0.9l0.66,1.61
  		l0.01,0.38l1.15,0.26l0.47-0.53l0.22-2.14l0.25-0.75l-0.24-0.37L2633.31,1309.06z M2880.73,1346.58l-0.33-0.23l-0.27-0.28
  		l-0.25-0.51l-0.32-0.43l-1.38-0.65l-0.45-0.16l-2.13-0.03l-2.1,0.32l-0.73,0.38l-1.11-0.36l-0.32,0.19l-0.2,0.35l0.06,1.55
  		l0.19,0.55l0.76,0.85l0.27,0.47l0.31,0.22l1.66,0.88l1.14,0.51l1.18,0.44l0.46-0.08l0.45-0.21l0.64-0.05l0.6-0.15l1.21-0.78
  		l0.59-0.2l0.63-0.12l0.12-1.36l-0.14-0.67L2880.73,1346.58z M2877.05,1382.87l-0.98-0.76l-1.24,0.3l-0.87,0.58l-1.23,0.25
  		l-1.08-0.08l-0.93-0.29l-1.11,1.29l-0.29,0.54l-0.09,0.87l0.24-0.04l1.06-0.58l0.75-0.21l0.58-0.02l0.53,0.24l1.19,0.05l1.34,0.28
  		l0.39-0.68l0.5-0.52l0.97-0.46l1.01-0.13l0.02-0.18l-0.11-0.41L2877.05,1382.87z M2881.12,1323.83l-0.44,1.18l0.24,0.12l0.88,0.22
  		l0.1-0.17l0.01-0.19l-0.36-1.13L2881.12,1323.83z M2864.69,1333.46l-0.51,0.08l-0.08-0.56l-0.35-0.17l-0.16,0.06l-0.26,0.35
  		l-0.28,0l-0.79-0.3l-2.57-0.58l-1.52,0.07l-0.55,0.12l-0.31,0.24l-0.43,1.43l0.74,1.22l0.87,0.01l1-0.24l1.65-0.7l1.45,0.41
  		l1.08-0.45l1.51-0.1l0.05-0.56l-0.13-0.13L2864.69,1333.46z M2944.31,1368.48l0.36-0.56l-0.14-0.55l0.01-0.6l0.07-0.32l-0.39-0.87
  		l-0.47-0.18l0.1-0.35l-0.1-0.39l-0.25-0.45l-0.38-0.09l-0.54,0.09l-0.14,0.73l-0.29,0.27l-0.97,1.3l-0.79-0.26l-0.35,0.21
  		l0.18,0.31l0.98,0.74l-0.37,1.16l0.03,0.33l-0.65-0.01l-0.61,0.09l0.02,0.36l0.17,0.23l0.75,0.68l0.13,0.24l0.64,0.59l0.72,0.54
  		l1.65,0.26l0.2-0.24l0.19-0.08l0.25-0.28l0.26-0.78l0.1-0.27l0.02-0.59l-0.05-0.42L2944.31,1368.48z M2940.99,1371.56l-0.7-0.75
  		l-0.56-0.42l-0.45,0.12l-0.03,0.54l0.11,1.44l0.59,0.28l-0.08,0.16l-0.26,0.02l-0.4-0.08l-0.4,2.5l0.27,0.54l0.92,0.63l1.03-0.5
  		l0.28-0.28l0.48-1.14l0.77-0.74l0.14-0.66l0.14-0.59l-1.03-0.47L2940.99,1371.56z M2945.25,1371.84l-0.23,0.37l0,0.41l0.21-0.07
  		l0.56-0.93l-0.29,0.01L2945.25,1371.84z M2943.9,1374.43l-0.29,0.46l0.02,0.17l0.22,0.27l0.14-0.13l0.44-0.68l0.08-0.38l-0.32,0.02
  		L2943.9,1374.43z M2944.45,1373.15l-0.42-0.42l-0.38,0.18l0.23,0.69l0.47-0.08L2944.45,1373.15z M2978.62,1386.22l0.9-1.49
  		l0.81-1.57l-0.22-0.91l-0.53-0.64l-0.82-0.82l-0.27-0.2l-1.9-0.09l-2.09,0.5l-0.93,0.48l-0.87,0.6l-0.63,0.63l-1.47,2.45
  		l-1.24,2.78l-0.29,1.04l0.31,0.22l1.55-0.27l0.93,0.02l2.64,0.2l2.01-1.11l0.64-0.63L2978.62,1386.22z M2997.07,1395.04v-1.71
  		l0-1.71l0-1.71l0-1.71l0-1.71v-1.71l0-1.71l0-1.71l0-1.71l0-1.71v-1.41l-0.47-0.55l-0.48-0.84l0.1-1.08l0.58-1.34l0.26-0.89v-0.73
  		l0-1.71l0-1.71l0-1.71l0-1.71v-1.71l0-1.71l0-1.7l0-1.71l0-1.7v-1.7l0-1.7v-1.7l0-1.7l0-1.7l0-1.7l0-1.7l0-1.7l0-1.62l-1.9-0.02
  		l-0.23-0.83l-0.4-0.3l-0.42-0.22l-2.76-0.21l-0.37-0.07l-0.4-0.3l-0.42-0.21l-2.41,0.05l-0.66-0.07l-2.59-1.14l-1.93-0.94
  		l-0.87-0.51l-0.92-0.39l-1.01-0.2l-0.91-0.42l-0.63-0.6l-0.73-0.46l-4.54-1.47l-0.87-0.5l-0.81-0.61l-0.88,0l-1.6,0.69l-1.99,1
  		l-1.72,0.98l-0.45,0.32l0.02,0.34l0.42,0.77l-0.04,0.44l-0.83,0.67l-1.93,0.77l-1.95,0.22l-1.87,0.41l-0.32,0.43l-0.42,0.84
  		l-0.28,0.87l-0.22,0.45l-1.94,1.27l-0.19,0.25l-0.54,1.17l-0.56,0.76l-1.95,1.6l-0.56,0.69l-0.62,0.64l-0.97,0.25l-1.01-0.05
  		l-1.34-0.17l-0.46-0.13l-1.01-0.7l-0.26-0.34l-0.29-0.86l0.02-1.08l-0.1-0.58l-0.62,0.29l-0.57-0.09l-0.45-1.91l-0.04-0.97
  		l-0.15-0.44l-0.51-0.22l-0.41,0.21l-0.29,0.4l0.07,1.1l-0.26,0.99l-0.82-1.77l-1.41-2.61l-0.33-0.95l-0.11-0.94l0.02-0.96
  		l-0.12-1.04l-0.21-1.03l0.33-0.84l0.6-0.77l0.17-0.46l0.19-0.93l-0.1-0.44l-0.5-0.9l-0.61-0.84l-0.37-0.84l0.12-0.87l0.21-0.42
  		l-0.73-0.64l-0.42-0.21l-1.05-0.11l-1.07,0.08l-2.11-0.13l-1.72-0.76l-1.61-1.04l-1.86-0.79l-1.95-0.49l-0.99-0.1l-0.96,0.07
  		l-2.23,0.83l-0.37,0.31l-0.32,0.38l-0.7,0.38l-0.6,0.62l-0.73,0.39l-2.89,0.65l-1.39,0.43l-0.33,0.18l-0.04,0.35l0.06,0.46
  		l-0.04,0.45l-0.53,1.33l-0.33,0.44l-0.52,0.24l-0.37,0.31l-0.38,0.84l-0.04,0.34l0.51,0.19l0.51,0.06l0.52-0.06l0.52-0.16l0.45-0.3
  		l3.69,1.24l0.83,0.13l0.85,0.03l0.05,1.3l0.52,1.82l0.21,0.48l0.48,0.36l0.36,0.5l0.72,0.7l0.84,0.56l0.81-0.02l0.84-0.18
  		l1.08,0.24l1.96,0.2l0.84,0.02l2.21-0.49l1.11,0.01l1.11,0.08l1.87-0.3l1.78-0.73l-0.01,0.38l-0.15,0.3l-0.44,0.3l-0.49,0.62
  		l0.91,0.09l0.05,0.72l-0.23,0.2l-0.36,0.06l-0.68,0.24l-0.36,0.78l-0.09,0.67l-0.41-0.2l-0.36-0.45l-0.69-0.05l-0.97-0.23
  		l-1.23-0.5l-0.62-0.14l-0.61,0.1l-0.72,0.31l-1.15,1.43l-1.45,1.1l-0.61-0.19l-0.65-0.33l-2.12-0.36l-0.78-0.03l-1.38,0.66
  		l-0.81,0.24l0.3,0.56l0.4,0.49l0.41,0.13l1.29,0.12l0.79,0.27l1.73,1.3l1.67,1.37l0.66,0.99l0.34,1.16l-0.98,1.28l0.31,1.04
  		l0.39,1.01l0.28,0.49l0.37,0.42l0.46,0.32l0.98-0.22l0.95,0.01l0.43-0.07l1.28-1.37l0.18-0.47l-0.13-0.48l0.07-0.44l0.79-0.98
  		l0.28-0.83l-0.2-0.88l0.68,0.04l0.45-0.43l0.06-1.5l0.09-0.45l0.24-0.37l0.56-0.36l0.62,0.09l-0.5,0.79l-0.71,0.83l-0.12,0.51
  		l-0.04,1.43l0.38,0.82l0.72,0.6l0.44,0.25l0.36,0.33l0.25,0.46l0.34,0.36l0.53,0.03l0.53-0.19l0.4-0.02l0.28,0.24l0.18,0.52
  		l0.54,0.49l1.05-0.3l0.64,0.32l0.67,0.26l0.48-0.03l0.88-0.39l0.44-0.06l1.07,0.14l-1.52,0.14l-0.17,0.47l-0.06,0.57l0.63,0.97
  		l3.72,2.14l0.65,0.02l0.68-0.09l0.81,0.01l2.24,0.3l2.22,0.44l0.99,0.45l0.95,0.55l1.54,0.42l1.9,0.99l2,0.62l0.51,0.02l0.49,0.1
  		l0.46,0.18l0.5-0.04l0.46,0.22l0.44,0.33l0.35-0.12l0.35-0.25l0.23,0.58l3.81,2.03l0.3,0.47l0.38,0.32l0.39-0.01l0.3,0.18
  		l0.53,0.48l0.64,0.32l0.12,0.68l-0.11,0.7l0.02,0.4l0.18,0.28l0.34,0.06l0.33-0.04l0.72-0.2l0.74-0.1l-0.81,0.41l-0.37,0.69
  		l0.7,0.26l0.77,0.04l-0.51,0.37l-0.15,0.52l0.61,1.42l0.59,1.89l0.7,0.93l1.02,0.9l0.47,0.55l0.24,0.89l0.69,0.5l0.47,0.57
  		l-1.52,0.24l-0.7,0.2l0.01,0.22l1,1.12l1.06,0.56l1.71,0.54l0.54,0.01l0.54-0.1l-0.96,0.31l-0.38-0.01l-0.83-0.19l-0.46-0.02
  		l-0.56,0.12l-0.43,0.3l0.39,0.4l0.5,0.34l0.27,0.28l0.44,0.83l0.38,0.3l0.55,0.17l0.33,0.5l-0.12,0.44l-0.24,0.44l-0.36,1.23
  		l-0.56,0.64l-0.26,1.08l-0.17,0.45l-0.24,0.42l0.07,0.4l0.22,0.38l0.36,0.21l1.26-1.01l0.92-0.48l0.47-0.87l0.08,0.54l0.18,0.51
  		l0.34,0.5l0.56,0.2l1.12-0.14l1.1-0.4l1.19-0.16l1.21-0.04l0.83-0.66l0.7-0.83l-0.67,1.35l-0.38,0.47l-0.08,0.23l0.17,0.24
  		l0.83,0.89l3.27,2.69l0.77,0.91l0.68,1l1.05,1.07l1.16,0.94l0.43,0.28L2997.07,1395.04z M2978.43,1387.18l-0.72,0.22l-0.47,0.58
  		l-0.49,0.34l0.03,0.18l0.23,0.35l2.12,0.26l0.42-0.11l0.02-0.43l-0.85-1.38L2978.43,1387.18z M2930.86,1363.12l-0.89,2.61
  		l-0.6,0.51l-0.14,0.86l-0.65,1.7l0.68-0.72l0.38-1.4l1.25-1.34l0.45-1.91l-0.29-0.25L2930.86,1363.12z M2913.22,1386.49l-1.57,1.51
  		l-0.49,0.67l0.73-0.26l0.39-0.62l1.14-0.28l1.12-0.68l-0.75-0.05L2913.22,1386.49z M2911.87,1348.53l-0.12-0.31l-0.61-0.96
  		l-0.47-0.06l-0.4-0.17l-0.25-0.67l-0.14-0.7l-0.47-0.81l-1.61-1.19l-0.64-0.09l-1.69,0.12l-1-0.14l-1.93-0.93l-1.3-0.5l-0.48-0.13
  		l-0.5-0.04l-0.48,0.04l-0.47,0.25l-0.77,0.57l-0.89,0.37l-0.49,0.03l-0.35-0.35l-0.14-0.47l-0.56-0.08l-0.68,0.18l-1.01,0.06
  		l-1.86-0.12l-3.13,0.2l-0.72,0.58l-1.98,2.41l0.16,0.5l0.26,0.49l0,0.47l-0.21,0.83l0.15,0.08l0.42-0.52l0.51-0.87l0.12-0.31
  		l0.1-0.54l0.22-0.46l0.42-0.22l0.41,0.12l0.44,0.26l0.4,0.32l1.17,1.47l0.39,0.2l0.43,0.08l1.03-0.13l0.32-0.31l0.63-0.8l0.43-0.29
  		l0.51-0.26l0.53-0.05l0.27,0.1l0.05,0.26l-0.1,0.27l0.13,0.18l1.18,0.19l0.88,0.36l1.02,0.13l1.14,0.37l0.45-0.16l-0.01-0.59
  		l-0.07-0.29l0.28-0.08l0.69-0.01l1.83,0.08l0.92,0.54l0.24,0.4l0.32,0.3l2.11,0.88l0.79,0.38l1.83,1.04l1.89,0.91L2911.87,1348.53z
  		 M2933.8,1353.8l-1.21-0.74l0.1,0.49l1.43,0.74l1,0.35l-0.43-0.45L2933.8,1353.8z M2918.95,1378.48l-0.48-0.23l-0.7,0.19
  		l-0.25,0.25l0.04,0.47l-0.44,0.25l-0.31,0.54l-0.3,0.21l-0.52,0.72l-0.44,0.11l-0.31,0.27l-0.53,1.23l-0.06,0.46l-0.44,0.11
  		l-0.01,0.82l-0.42,0.7l0.31,0.48l-0.08,0.64l0.6,0l1.05,0.11l0.14-0.1l0.15-0.15l0.04-0.28l0.25-0.66l0.81-0.78l0.21-0.39
  		l0.69-0.41l0.37-0.47l0.56-1.58l-0.4-1.45l0.78-0.59L2918.95,1378.48z M2901.72,1383.99l-0.39,0.07l-0.09,0.24l-0.06,0.72
  		l1.02,1.04l0.56,0.05l0.5-0.77l0.04-0.55l-0.26-0.59L2901.72,1383.99z M2927.68,1366.06l-0.18-0.11l-0.16-0.33l-0.57-0.02
  		l0.43,1.11l-0.12,0.98l0.12,0.47l0.2,0l0.35,0.29l0.51-0.81l-0.02-0.52L2927.68,1366.06z M2919.37,1378.29l0.23,0.23l0.38,0.13
  		l0.52,0.07l0.36,0.48l0.36,0.22l0.11-0.41l-0.5-0.82L2919.37,1378.29z M2870.62,1335.18l-0.34-0.01l-0.42,0.51l-0.09,0.35
  		l0.34,1.21l0.29,0.34l0.34,1.28l0.51,0.45l0.14-0.15l0.09-0.71l-0.76-1.66l0.14-1.31L2870.62,1335.18z M2733.73,1328.52l0.34-0.3
  		l0.08-0.33l-0.14-0.55l-0.37-0.27l-0.71-0.24l-1.17,0.05l-0.22,0.49l-0.19,1.65l0.3,0.3l0.4,0.04L2733.73,1328.52z M2773.92,1390.8
  		l-0.28-0.38l-0.52-1.3l-0.05-0.6l0.03-0.6l0.49-2.18l0.01-0.92l-0.29-0.87l-0.22-0.18l-2.63-1.16l-0.28-0.01l-1.36,0.38l-1.08,0.22
  		l-2.1,0.18l-2.1-0.05l-1.98-0.51l-1.84-0.89l-0.1-1.02l0.1-1.07l-0.36-0.33l-0.87-0.37l-0.19-0.36l-0.33-1.07l-0.4-1.04l-0.89-0.2
  		l-1.02-0.07l-1.48,0.09l-0.42-0.1l-0.82-0.73l-2.12-0.27l-0.42-0.27l-0.38-0.36l-0.87-0.42l-0.47,0.03l-0.82,0.34l-0.37,0.06
  		l-1.35-0.11l-0.23-0.15l-1.29-1.72l-0.24-0.24l-1.16-0.1l-0.43,0.16l-0.4,0.25l-0.3,0.38l-0.23,0.43l-0.33,1.01l-0.43,0.97
  		l-0.53,0.77l-0.8,0.42l-0.45,0l-0.44-0.08l-0.51-0.22l-0.53-0.15l-1.11,0.03l-1.1,0.14l-0.97-0.11l-1.97-0.5l-0.73-0.27l-0.82,0.42
  		l-0.92,0.06l-2.32-0.42l-1-0.07l-1.01,0l-0.86-0.15l-0.63-0.52l-0.55-1.79l-0.19-0.38l-0.81-0.74l-0.75-0.81l-0.3-0.18l-0.34,0.01
  		l-0.48,0.19l-0.5,0.06l-1.09-0.17l-1.05-0.37l-0.91-0.12l-0.92-0.03l-0.87-0.28l-0.74-0.51l-0.85-0.96l-0.35-0.25l-1.43-0.18
  		l-0.98-0.44l-0.29,0.88l-0.67,0.55l-0.45,0.16l-0.44,0.05l-1.26-0.5l-0.9-0.14l-0.92-0.04l-0.92-0.28l-1.55-0.16l-0.76-0.42
  		l-0.39,0.17l-0.78,0.69l-0.57,0.83l-0.69,2.86l-0.25,0.2l-0.43,0.15l-0.43-0.24l-0.4,1.24l-0.23,0.46l-0.82,0.93l-0.2,0.04
  		l-0.46-0.16l-0.15-0.14l-0.14-0.73l-0.3,0.08l-0.52,0.46l-0.25,0.41l0.1,0.48l0.4,0.05l0.5-0.12l0.5,0.06l0.49,0.17l1.03,0.06
  		l1.04-0.12l0.93,0l0.92,0.11l2.14,0.58l2.71,1.06l-0.24,0.51l-0.36,0.53l-0.27,0.53l-0.05,0.61l0.37,0.48l0.67,0.22l0.81,0.18
  		l3.7,0.27l1.8,0.2l2.21,0.59l0.43,0.21l0.83,0.58l0.91,0.44l0.95,0.3l2.55,0.49l0.97,0.1l0.98,0.02l0.56-0.51l0.44-0.24l1.44-0.34
  		l0.97,0.01l1.1,0.3l1.74-0.08l0.74,0.08l4.81,1.04l1.57,0.52l4.79,2.18l1.88,0.44l1.9,0.32l2.39,0.19l1.44,0.37l5.1,0.16l1.99,0.25
  		l1.97,0.39l0.78,0.08l0.78-0.11l1.06-0.29l1.02-0.41l0.97-0.21l1.01-0.01l3.7,1.61l2.09,0.76l1.85,0.49l0.99-0.1l0.52,0.28
  		l0.37,0.49l0.64,0.3l1.05,0.25l0.13-0.36l-0.04-0.36L2773.92,1390.8z M2696.4,1373.62l-0.14-0.32l-0.29,0.04l-0.28,0.14l-0.6,0.58
  		l0.18,0.24l0.4,0.16l0.53-0.19L2696.4,1373.62z M2696.62,1365.29l0.38-0.17l1.46,1.03l0.28,0.33l0.19,0.41l0.34,0.33l0.49,0.15
  		l0.6,0.01l0.46-0.86l0.11-0.33l0.6-5.6l-0.07-1.81l0.09-1.12l-0.03-0.89l-0.46-3.28l0.08-0.34l0.73-2.01l0.02-0.41l-0.29-0.45
  		l-0.37-0.41l-0.07-0.98l0.35-1.36l0.14-0.35l1.11-1.25l0.21-0.37l-0.02-0.47l-0.1-0.46l-1.22-1.83l-2.67-3.31l-0.73-0.52
  		l-0.83-0.41l-0.93-0.2l-2.2,0.01l-0.46,0.11l-0.45,0.18l-0.33,0.22l-1.2,1.27l-0.32,0.23l-0.4-0.02l-0.17-0.43l0.14-0.95l0.18-0.37
  		l1-0.86l0.33-0.41l0.16-0.52l0.01-0.66l-0.46-0.44l-0.97-0.45l-0.91-0.55l-0.44-0.86l0.02-1.01l-0.34-0.83l-0.27-1.99l-0.18-0.93
  		l-0.37-1.48l-0.17-0.3l-0.87,0.13l-0.5,0.01l-1.16-0.28l-1.02-0.35l-1.84-0.77l-1.21-0.76l-0.38-0.34l-0.79-1.5l-0.06-0.35
  		l0.66-0.57l-0.12-0.4l-0.76-0.47l0.33-0.76l-0.28-0.26l0.14-0.41l1.36-1.03l0.99-0.41l0.68-0.56l-0.37-1.07l-0.59-0.96l-0.79-0.82
  		l-0.84-0.78l-0.56-0.22l-0.62-0.06l-0.53,0.16l-1.41,0.79l-0.89,0.57l-0.9,0.45l-0.98,0.29l-1.93,0.23l1.99-0.68l1.86-0.98
  		l0.5-0.64l-0.29-0.73l-0.7-0.71l-0.84-0.43l-2.38-0.28l-0.82-0.26l-0.67-0.52l-1.27-1.24l-0.13-0.24l-0.21-1.03l-0.34-0.98
  		l-0.5-0.83l-0.66-0.7l-1.98-1.5l-0.85-0.33l-0.92-0.08l-0.83-0.19l-0.6-0.54l-0.4-1.08l-0.48-1.04l-0.64-0.76l-1.51-1.3l-0.93-0.31
  		l-0.5,0.1l-0.41,0.34l-0.09,0.4l-0.01,0.45l0.5,0.75l0.09,0.86l-0.6-0.34l-1.11-1.09l-0.69-0.14l-0.67-0.44l-0.56-0.57l-0.47-0.62
  		l-0.41-0.67l-0.38-0.46l-1.52-1.52l-0.89-1.23l-0.44-0.84l-0.53-0.78l-1.47-1.63l-1.78-1.07l-3.12-2.26l-2.38-1.08l-0.76-0.41
  		l-0.61-0.63l-0.16-0.43l-0.23-0.36l-1.1-0.58l-1.86-0.8l-0.56-0.85l0.27-1.07l-0.21-0.77l-1.92-1.85l-0.18-0.23l-0.28-0.97
  		l-0.49-0.86l-1.7-1.34l-1-1.09l-0.34-0.3l-0.4-0.19l-0.41-0.06l-2.2,0.24l-0.88-0.19l-0.99-0.33l-1.05-0.04l-1.92,0.46l-1.03-0.08
  		l-2.04-0.32l-0.99-0.23l-0.89-0.48l-1.57-1.37l-0.88-0.54l-0.91-0.25l-0.94-0.13l-1.02-0.04l-0.98,0.3l-0.44,0.24l-0.03,0.4
  		l0.16,0.44l0.03,0.45l-0.22,0.54l-0.12,0.52l1.47,2.58l0.43,0.93l0.53,0.87l0.71,0.83l3.45,3.34l2.04,1.6l0.68,0.72l1.13,1.42
  		l0.68,0.42l2.32,0.49l0.78,0.46l0.63,0.66l1.85,2.51l0.5,0.73l0.55,0.94l0.66,0.85l1.68,1.08l0.22,0.51l0.2,0.91l0.18,1.52
  		l0.33,1.14l0.79,0.63l1.04,0.16l0.73,0.22l0.69,0.36l4.02,2.45l0.26,0.31l0.9,1.36l0.79,1.73l1.17,3.87l1.04,2.89l0.44,1.9l0.4,0.9
  		l0.65,0.7l0.83,0.49l2.22,0.89l0.61,0.48l0.43,0.65l1.17,2.35l0.59,0.72l0.72,0.62l0.6,0.66l1.69,2.06l0.16,0.23l0.72,2.3
  		l0.78,1.66l3.11,5.31l-0.06,1.76l0.35,0.88l0.46,0.81l1.47,2.03l0.74,0.64l0.84,0.54l0.51,0.67l0.4,0.76l1.38,2.25l0.59,0.65
  		l1.42,1.12l2.61,1.85l0.51,0.63l1.55,2.47l1.4,1.53l3.21,2.67l1.85,1.05l0.84,0.67l0.79,0.75l0.62,0.43l3.07,1.81l0.51,0.39
  		l1.98,2.57l0.71,0.68l0.78,0.61l1.07,1.27l0.94,0.94l1.02,0.85l0.25,0.03l0.45-0.13l-0.07-0.64l-0.49-1.47l0.03-0.58l0.15-0.43
  		l2.45,1.35l0.78,0.38l0.49,0.16l0.39-0.19L2696.62,1365.29z M2681.84,1320.55l-0.59,0.58l-0.15,0.5l1.22,0.21l1.1-0.29l0.23-0.25
  		l-0.34-0.34l-0.95-0.39L2681.84,1320.55z M2773.34,1378.43l-0.24-0.44l-0.29-0.06l-0.23,0.06l-0.2,0.14l0.4,0.55l0.43,0.08
  		L2773.34,1378.43z M2758.48,1366.64l-0.53,0.61l0.14,0.34l0.81,0.02l0.18-0.3l0.07-0.49l-0.31-0.22L2758.48,1366.64z
  		 M2761.78,1379.09l0.73,0.1l0.13-0.14l0.2,0l0.27,0.09l2.29,0l0.64-0.21l0.92-0.69l1.43,0.07l0.16-0.12l0.34-0.47l1.67-0.5
  		l-0.08-0.25l-0.83-0.73l-7.64,0.06l-1.68,0.17l-0.84,0.85l-0.36,0.6l0.32,0.56L2761.78,1379.09z M2782.84,1391.41l-0.51,0.34
  		l0.17,0.23l0.68,0.52l0.24-0.29l0.03-0.48l-0.44-0.36L2782.84,1391.41z M2638.6,1318.79l-0.34-0.21l-0.43,0.07l0.88,1.89
  		l-0.15,0.69l-0.45,0.59l-0.12,0.74l-0.26,0.54l0.76,0.38l0.5-0.39l0.51-1.26l0.2-1.02l-0.5-0.75L2638.6,1318.79z M2670.47,1362.93
  		l-0.38-0.02l-0.36,0.3l0.21,0.32l1.27,1.03l0.68-0.04l0.32-0.62l-0.28-0.32L2670.47,1362.93z M2629.5,1301.28l-0.05-0.61
  		l-0.31-0.44l-0.33-0.13l-0.81-0.14l-0.41,0.13l0.98,0.49l0.87,0.88L2629.5,1301.28z M2645.3,1331.69l-0.67-0.98l0.08-0.2l-0.1-0.29
  		l-0.23-0.37l-0.31-0.83l-0.93-1.54l-0.19-0.86l-0.54-0.32l-1.63,0.46l-0.63,1.9l0.21,0.53l1.6,2.32l0.1,0.6l0.39,0.45l1.66,1
  		l0.78-0.05l0.91-0.33l-0.03-0.93L2645.3,1331.69z M2655.86,1344.73l0.04-0.65l-0.12-0.31l-1.76-1.83l-0.4,0.02l-0.16,0.29l0.21,1.4
  		l0.47,0.59l0.61,0.47l0.14,0.38l-0.02,0.59l1,0.83l-0.34-1.22l0.07-0.35L2655.86,1344.73z M2653.65,1341.07l-1.6-1.42l-0.2,0.13
  		l-0.16,0.58l-0.01,0.13l0.24,0.33l-0.04,1.01l0.19,0.42l0.99,0.01l0.6-0.67L2653.65,1341.07z M2650.4,1337.76l-0.68-0.89
  		l-0.41-0.96l-0.54-0.39l-0.42,0.08l-0.09,0.21l-0.02,0.54l-0.18,0.39l0.59,0.8l0.66,0.2l1.37,0.74l-0.04-0.22L2650.4,1337.76z
  		 M2850.65,1389.74l0.32-0.47l0.58,0.02l0.72-1.05l1.19-0.23l-0.24-0.28l-0.43-0.22l-0.59-0.19l-1.48,0.85l-0.23,0l-0.36-0.22
  		l-0.48,0.02l-0.69,0.11l0.03,0.17l0.25,0.11l0.43,0.07l-0.18,0.26l-1.09,0.72l-0.8,0.77l0.2,0.07l0.65-0.02l0.68,0.43l0.19-0.09
  		l0.46-0.37l0.54,0.29l0.23-0.18l0.06-0.18l-0.04-0.19L2850.65,1389.74z M2836.12,1406.3l-0.6,0.58l-0.77,0.41l0.18,0.15l0.9,0.25
  		l0.42-0.1l0.83-0.52l0.14-0.69l-0.41-0.12L2836.12,1406.3z M2782.91,1387.47l-0.86-0.45l-0.91-0.33l-1.26-0.4l-0.31-0.02
  		l-1.31,0.92l-0.5,0.11l-0.89-0.04l-1.79-0.46l-0.97-0.09l-0.24,0.02l-0.06,0.39l0.1,0.41l0.19,0.39l0.58,0.71l0.36,0.28l1,0.13
  		l0.93,0.29l0.93,0.57l0.87,0.65l0.43,0.48l0.3,0.57l-0.02,0.61l-0.41,0.51l0.45,0.17l0.42-0.11l0.22-0.13l0.13-0.19l0.09-0.34
  		l0.4-0.79l0.33-0.4l1.91-0.86l0.85-0.56l0.36-0.35l-0.11-0.37L2782.91,1387.47z M2826.3,1401.77l-0.57-0.82l-0.65-0.74l-0.44-0.38
  		l-0.51-0.24l-0.66,0.08l-0.62-0.06l-0.28-0.38l-0.09-0.51l-0.23-0.31l-1.38-0.73l-0.38-0.38l-0.33-0.45l-0.27-0.17l-0.77,0.49
  		l-0.47,0.18l-1.52-0.24l-1.61,0.15l-1.08-0.02l-0.93,0.15l-1.3,0.47l-0.31,0.27l-0.3,0.4l0.16,0.45l0.26,0.4l0.29,0.41l0.36,0.32
  		l2.33,0.55l0.45-0.01l0.45-0.09l1.1,0.11l1.78,1.22l0.99,0.41l0.57,0.62l0.45,0.7l0.78,0.65l0.93,0.36l1.17,0.18l0.38,0.26
  		l1.03-0.49l0.66-0.07l0.49-0.18l0.89-0.83l0.24-0.6l-0.41-0.68L2826.3,1401.77z M2848.89,1407.39l-0.1-0.78l-0.11-0.02l-0.16,0.12
  		l-0.62,0.27l-1.01,1.03l-0.71,0.5l-1.82,0.54l-0.23,0.42l0.06,0.75l0.25,0.09l0.78,0l0.47-0.28l1.76-0.59l0.81-0.91l0.9-0.4
  		l-0.04-0.24L2848.89,1407.39z M2812.03,1392.03l0.61-0.16l0.21-0.17l0.21-0.35l-0.24-0.34l-0.32-0.24l-0.17-0.33l0.01-0.88l-0.47-1
  		l-0.52-0.34l-0.68-0.04l-0.43,0.11l-0.39,0.21l-0.29,0.71l-0.19-0.18l-0.18-0.59l-0.49-0.36l-0.5-0.09l-0.52,0.01l-0.48,0.18
  		l-0.8,0.51l-0.38,0.03l-0.42-0.33l-0.33-0.43l-0.44-0.99l-0.28-0.23l-1.65-0.28l-0.44,0.1l-0.95,0.41l-0.14,0.46l0.24,0.63
  		l0.41,0.53l1.38,0.98l0.33,0.07l0.36-0.03l0.33,0.09l0.62,0.44l0.51,0.54l-0.24,0.51l-0.86-0.02l-1.13,0.66l-0.64-0.2l-0.73,0.06
  		l-0.79-1.08l-0.33-0.16l-0.25-0.23l-0.18-0.64l-0.46-0.28l-1.12,0.07l-0.66-0.05l-1.12-0.45l-0.5-0.06l-0.85,0.65l-0.93,0.5
  		l-0.56,0.04l-0.43,0.2l-0.28,0.55l-0.15,0.56l0.2,1.23l-0.29,0.7l-0.03,0.52l0.17,0.43l0.7,0.34l0.73,0.25l0.87,0.19l0.84-0.25
  		l0.41-0.3l0.46-0.07l0.52,0.06l0.52-0.01l1.01-0.21l1.88-0.74l0.54,0l0.55,0.1l1.76-0.68l0.51,0.05l0.49-0.13l0.37-0.28l1.22-1.12
  		l0.18,0.25l-0.02,0.92l0.25,0.35l0.44,0.01l1.64-0.38l0.46-0.06l0.68,0.28l0.2-0.04l0.03-0.21l-0.16-0.15l-0.52-0.14l-0.09-0.32
  		l0.63-0.2l0.69-0.08l0.3,0.09l0.27,0.24L2812.03,1392.03z M2863.56,1394.28l-0.2-0.1l-1.07,0.51l-0.31-0.09l-0.18-0.18l0.06-0.75
  		l-0.27,0.22l-1.53,0.79l-0.52,0.46l-0.59,0.33l-1.1,0.29l-0.05,0.41l-0.21,0.64l-0.31,0.3l-0.47,0.54l-0.31,0.12l-1.24-0.12
  		l-0.16,0.08l-0.21-0.06l-0.32-0.35l-0.14-0.29l-0.5,0.26l-0.85,0.68l-1.41,1.37l-0.37,0.76l-0.25,1.12l-0.39,1.09l0.09,0.41
  		l0.99,0.54l-0.22,0.42l-0.35,0.33l-0.29,0.4l-0.08,0.47l0.33,0.35l0.87,0.31l0.93-0.03l0.96-0.41l1.73-0.94l1.27-0.11l0.85-0.18
  		l0.68-0.53l0.79-0.79l0.9-0.67l1.12-1.3l1.02-0.8l0.29-0.85l0.59-0.45l-0.29-1.1l-0.31-0.47l-0.24-0.27l-0.08-0.33l0.01-0.35
  		l0.15-0.16l1.04-0.04l0.41-0.56l0-0.68L2863.56,1394.28z M2849.53,1403.77l-0.53,0.27l-0.59,0.78l0,0.62l0.76-0.29l-0.09-0.64
  		l0.77-0.28l-0.02-0.14L2849.53,1403.77z M2844.38,1390.45l-0.3-0.68l0.77-0.55l0.18-0.3l0.27-0.23l0.42-0.21l-0.23-1.49l-0.52-0.39
  		l-0.6-0.1l-0.45,0.28l-0.28,0.5l1.35,0.3l-0.58,0.7l-0.85,0.41l-1.23,0.42l-0.4,0.25l-0.35,0.32l-0.25,0.37l-0.14,0.45l-0.28,0.29
  		l-0.93,0.23l-0.51-0.03l-1.65-1.08l-0.4-0.21l-0.45-0.14l-0.46,0.23l-0.41,0.1l-0.98,0.11l-0.54-0.01l-0.62,0.17l-0.53,0.41
  		l-0.42,0.08l-0.45-0.06l-0.61-0.23l-0.8-0.61l-1.33-0.46l-0.92-0.49l-1.03-0.33l-1.14-0.04l-0.35-0.12l-0.84-0.57l-0.53,0.16
  		l-0.52,0.05l-0.51-0.14l-0.6,0.07l-1.04,0.27l-1.11,0.74l-1.14,0.49l-0.38,0.08l-0.37-0.21l-0.07,0.45l-0.16,0.42l-0.25,0.4
  		l-0.09,0.44l0.01,0.63l0.28,0.56l0.32,0.37l0.26,0.42l0.86-0.4l0.92-0.28l1.67,0.37l1.95-0.16l1.94,0.4l1.69,0.67l0.45,0.06
  		l0.43-0.08l0.43-0.18l0.45-0.08l1.16,0.18l0.73-0.86l0.72-0.02l0.72,0.07l0.31,0.28l0.25,0.38l0.73-0.24l0.85-0.08l2.15-0.97
  		l0.77-0.12l1.14,0.07l0.81-0.03l0.45-0.08l0.7-0.38l0.74-0.28l1.19-0.3l0.31-0.14L2844.38,1390.45z M2788.73,1345.93l-1.03,0.66
  		l-0.45,0.99l-0.35,1.29l-0.03,0.73l0.49,0.98l-0.15,1.59l0.29,0.4l1.77-1.56l-0.12-0.4l0.25-0.49l-0.11-0.37l-0.19-1.54l0.11-0.33
  		l-0.07-0.39l-0.21-0.45l0.06-1.2L2788.73,1345.93z M2792.5,1388.28l-0.34-0.18l-2.07-0.66l-0.82,0.28l-0.71,0.48l-1.34,1.2
  		l0.14,1.47l-0.01,1.13l-0.38,0.17l-0.99-0.06l-0.38-0.13l-0.1,0.38l0.15,0.32l1.28,0.4l1.79,0.33l0.43-0.05l0.74,0.19l1.76-0.36
  		l-0.23-0.27l-0.38-0.28l1.07-1.75l0.79-1.91l-0.13-0.43L2792.5,1388.28z M2781.86,1376.81l1.03-0.02l-0.18-0.31l-0.38-0.27
  		l-0.55-0.26l-0.51-0.01l-0.95,0.19l-0.15,0.37l-0.02,0.4l0.64,0.3l0.68-0.14L2781.86,1376.81z M2799.66,1290.3l0.19,0.49l0.72,0.67
  		l0.11-0.27l0.19-1.06l-0.37-0.24l-0.75,0.03L2799.66,1290.3z M2790.31,1347.1l-0.27,0.2l-0.58,0.97l0.52,0.82l0.31-1.44
  		L2790.31,1347.1z M2799.63,1388.82l0.19-0.01l0.95-1.5l-0.04-0.35l-1,0.03l-0.47,0.27l-0.07,0.47l0.2,0.57l-0.13,0.35
  		L2799.63,1388.82z M2793.1,1328.74l-0.26-0.47l-0.02-0.44l0.12-0.45l0.17,0.61l0.31,0.56l0.44,0.29l0.54,0.04l0.75-0.3l0.56-0.63
  		l0.64-0.87l0.79-0.7l0.98-0.49l1.39-0.59l0.34-0.22l0.1-0.36l-0.14-0.44l-0.07-1.01l-0.72-1.93l0.01-1.03l0.49-3.65l0.26-0.88
  		l1.62-3.25l0.27-0.2l0.64-0.29l0.59-0.36l0.35-0.49l0.11-0.62l-0.21-0.57l-0.34-0.56l0.88,0.5l0.67,0.92l0.85,0.46l0.97,0.23
  		l1.88,0.28l1.88-0.21l1.14-0.4l0.78-0.8l-0.18-0.52l-0.93-0.43l-1.8-1.87l-1.41-0.82l-2.65-1.87l-0.64-0.51l-1.28-1.38l-0.54-0.85
  		l-0.28-0.28l-0.35-0.21l0.77-0.28l0.07-0.22l0.57-0.61l0.71-0.46l0.21-0.4l0-0.46l-0.27-0.5l-1.25-1.37l-0.68-1.07l-0.16-0.65
  		l-0.31-0.24l-0.7-0.26l-0.24-0.15l0.22-0.28l0.28-0.23l-0.51-0.23l-0.57-0.12l0.58-0.18l-0.21-0.45l-0.01-0.5l-0.37-0.29
  		l-0.51-0.05l-0.71-0.51l-0.59-0.24l-0.26-0.42l0.53-1.02l-0.32-0.51l-1.52-1.39l-0.44-0.17l-0.49-0.08l0.97-0.14l0.98,0l1.37,0.09
  		l0.38-0.31l0.12-0.54l0.49,0.44l0.53,0.35l0.71-0.07l0.53-0.37l-0.13-0.37l-0.25-0.3l-0.03-0.22l-0.75-0.68l-0.62-0.44l-0.06-0.49
  		l-0.79-0.73l0.27-0.48l0.58-0.24l0.07-0.07l-0.31-0.01l-0.73-0.18l-1.46-0.89l-1.49-0.32l-2.16-0.03l-1.23-0.12l-0.5,0.13
  		l-0.42,0.01l-0.3-0.18l-0.32-0.09l-0.84,0.52l-0.39-0.16l-0.4-0.22l-0.71-0.07l-0.86,0.06l-0.95,0.54l-1.06-0.49l-0.3,0.01
  		l-0.2,0.12l-0.46,0.67l-0.87,0.51l-0.43,0.93l-0.26,0.89l-0.23,0.31l-0.06,1.72l-0.14,0.83l0.22,1.1l-0.04,0.47l-0.39,0.7
  		l-0.05,0.16l-0.21,1.12l0.08,0.3l-0.05,0.38l-0.33,0.79l-0.59,0.21l-0.62,0.12l-0.53-0.27l-0.48,0.43l-0.61,0.67l-0.26,0.45l0,0.42
  		l0.06,0.28l-0.12,0.29l-0.02,0.31l0.03,0.44l0.41,0.18l0.43,0.38l-0.01,0.37l-0.24,0.25l-0.54,0.39l-0.99,0.8l-1.12,0.68
  		l-0.42,0.16l-0.15,0.31l-0.08,0.42l0.25,0.93l0.23,0.27l0.12,0.33l-0.15,0.39l-0.11,0.33l-0.41,0.21l-0.4,0.15l-0.15,0.27
  		l-0.21,1.11l-0.24,0.58l-0.54,0.86l-0.18,0.39l-0.28,0.13l-1.06-0.4l-0.95,0.24l-1.25,0.16l-1.06-0.02l-0.82,0.18l-0.56,0.46
  		l-0.63,0.57l-0.66,0.42l-0.5,0.21l-0.92-0.61l-0.46,0.05l-0.84-0.21l-1.96-0.68l-0.49-0.2l-0.52-0.02l-0.15-0.19l0.08-0.33
  		l-0.08-0.43l-0.38-0.16l-3.93,0.07l-1.13,0.37l-0.77,0.29l-0.55,0.34l-0.15,0.84l-0.33,0.79l-0.42,0.84l-1.31,0.25l-0.96,0.85
  		l-0.33,0.1l-0.66-0.12l-0.71-0.07l-0.51,0.24l-0.53-0.01l-1.65-0.4l-1.56-0.06l-0.89,0.2l-0.49,0.08l-2.72,1.16l-0.92,0.14
  		l-0.37-0.17l-0.53-0.48l-0.71-0.48l-1.69-1.62l-0.62-0.38l-0.41-0.39l-0.39-0.46l-0.56-0.5l-0.51-0.34l-0.69-0.7l-0.69-0.77
  		l-0.15-1.35l-0.55-0.25l-0.18-0.35l-0.08-0.4l0.76-1.1l-2.11,0.88l-0.51,0.85l-0.38,0.97l-0.9,0.82l-0.77,0.93l-0.17,0.48
  		l-0.38,1.66l0.46-0.06l0.26-0.09l0.3,0.04l-0.36,0.25l-0.49,0.16l-0.61,0.58l-0.35,1.86l-0.09,0.99l0.14,2.18l0.18,1.48l1.1,0.86
  		l0.62,0.71l0.27,0.42l0.33,0.37l0.23,0.15l0.09,0.21l-0.53,0.34l-0.25,1.11l-0.13,0.36l-0.23,0.67l-0.01,1.05l0.08,0.46l0.25,0.41
  		l0.81,0.69l0.98,0.51l-0.05,0.24l-0.46,0.11l-0.34,0.43l-0.1,0.63l0.25,0.32l0.38,0.25l1.01-0.06l1.92,0.63l0.89,0.56l0.72,0.75
  		l0.54,0.67l0.38,0.78l0.3,1.04l0.14,1.06l-0.11,0.98l-0.5,0.84l-0.03,1l0.97,0.7l0.18,0.46l0.23,1.95l0.84,3.81l0.07,1.98l0.2,0.34
  		l0.39,0.16l0.41-0.32l0.22-0.11l1.65-0.35l0.79,0.95l0.29,0.14l0.28-0.27l0.63-0.42l0.74-0.25l-0.4,0.31l-0.19,0.41l0.33,0.45
  		l0.52,0.19l0.96-0.13l1.81-0.83l0.91-0.19l1.07,0.33l1.1,0.02l0.28-0.42l0.31-0.3l0.55,0.42l0.41,0.58l0.12,0.41l0.11,2.1
  		l-0.01,0.94l-0.1,0.94l0.3,0.16l0.42,0.01l0.4-0.19l1.45-1.24l1.33-0.51l1.34,0.42l1.32,0.25l1.33-0.66l1.8-1.13l0.53-2.12
  		l2.6,2.62l0.2-0.19l0.35,0.04l0.99-0.38l0.34-0.05l0.37,0.15l0.17,0.42l0.06,0.72l-0.03,0.74l0.6,0.3l0.76,0.01l1.37-0.52
  		l1.04-0.97l0.23,0.05l0.16,0.35l0.42,0.23l0.49,0.06l0.47-0.46l0.44-0.6l-0.36,1.09l0.02,0.38l0.33,0.29l0.44,0.22l0.41,0.09
  		l0.67-0.88l0.09,0.99l0.59,1.75l0.16,3.42l0.23,0.34l0.35,0.15l4.76-2.2l5.88-2.61l0.36-0.6l0.15-0.76l0.34-0.71l0.78-0.96
  		l0.14-0.42l0.32-0.29l0.44-0.19l-0.72-0.85l-0.15-0.35l0.1-0.41l0.5,0.35l0.53-0.15l0.35-0.47l0.19-0.58l0.16-1.05l0.03-1.08
  		l-0.57,0.21l0.08-0.43l0.3-0.25l0.41-0.01l0.41,0.15l0.66-0.23l0.17-0.83l0.14-0.93l-0.31-0.77l-0.93-0.18l-0.42-0.24l-0.46-0.15
  		l0.92-0.73l0.24-1.08l-0.19-0.49l-0.04-0.66l-0.6-0.05l-0.65,0.05l0.2-0.34l0.27-0.27l1.23-0.67l0.33-0.29l0.23-0.38l0.08-0.66
  		l0.48-0.38l0.87-0.44l0.32-0.41l0.15-0.51L2793.1,1328.74z"/>
    </>,
    HUN: <>
        <polygon className="st1" points="2002.21,882.67 2002.13,882.27 2002.22,881.92 2002.04,881.59 2001.58,881.22 2001.47,881.06
  		2000.9,881.09 2000.75,881.13 2000.69,881.12 1999.9,880.8 1999.38,880.03 1998.57,879.63 1997.95,879.49 1997.66,879.16
  		1997.48,878.72 1997.29,878.39 1997.27,878.36 1997.13,877.86 1996.94,877.81 1996.91,877.79 1996.11,877.88 1995.93,878.01
  		1993.03,878.6 1992.65,878.51 1992.26,878.17 1992.04,877.92 1991.9,877.74 1991.65,877.25 1991.32,876.9 1990.82,876.62
  		1990.38,876.29 1989.79,876.28 1988.23,876.74 1987.14,876.79 1986.41,876.65 1985.45,876.37 1983.57,876.32 1982.28,876.57
  		1982.15,876.91 1980.96,879.06 1979.23,879.85 1977.73,880.66 1977.3,880.83 1976.55,880.57 1975.7,880.09 1975,879.84
  		1974.48,879.95 1973.92,880.5 1973.66,881.05 1971.96,881.46 1969.01,881.69 1967.98,882.24 1967.62,882.89 1967.61,883.39
  		1967.86,883.82 1967.54,884.31 1967.41,884.52 1965.32,884.63 1962.53,884.78 1960.87,884.74 1959.3,884.7 1958.23,884.28
  		1956.93,883.45 1955.56,882.34 1955.42,882.31 1955.22,882.19 1954.35,882.11 1954.12,882.18 1953.63,882.63 1953.54,883.3
  		1953.22,883.6 1953.14,883.99 1953.27,884.33 1953.27,884.77 1953.44,885.37 1952.66,885.5 1951.73,885.48 1951.39,885.52
  		1951.08,885.68 1950.76,885.6 1949.91,885.04 1949.44,884.91 1949.1,884.95 1948.85,885.19 1948.42,885.51 1948.01,885.73
  		1948.1,885.92 1949.85,886.43 1950.16,887.21 1949.82,887.84 1949.71,888.15 1949.3,888.39 1948.8,888.61 1948.19,888.66
  		1948.12,889 1948.36,890.01 1948.16,890.23 1947.97,890.54 1948.15,891.36 1948.53,891.42 1948.61,891.61 1948.54,891.94
  		1948.48,892.3 1948.35,892.67 1948.28,892.84 1948.03,892.94 1947.26,892.89 1946.59,893.21 1945.24,894.36 1946.85,894.43
  		1947.06,894.74 1947.14,895.22 1947.29,895.86 1947.55,896.04 1947.7,896.29 1947.67,896.74 1947.99,897.07 1948.72,897.97
  		1948.81,898.21 1949.26,898.36 1950.76,899.09 1951.8,899.9 1952.38,900.8 1953.16,901.5 1954.14,901.99 1954.92,902.66
  		1955.5,903.51 1956.31,903.98 1957.34,904.08 1958,904.37 1958.27,904.85 1958.83,905.28 1959.68,905.67 1961,905.88
  		1963.53,905.93 1963.75,905.94 1964.32,906.06 1964.99,905.91 1965.8,905.61 1966.06,905.43 1966.92,904.44 1967.39,904.52
  		1968.32,904.4 1968.89,904.18 1968.93,904.18 1969.12,904.19 1969.86,903.89 1970.13,903.65 1970.29,903.37 1970.46,903.3
  		1970.96,903.6 1971.48,903.63 1972.07,903.44 1972.51,903.17 1973.04,902.95 1973.28,902.79 1973.58,902.55 1974.2,901.84
  		1974.9,901.69 1975.83,901.87 1976.84,901.93 1977.6,901.77 1979.51,901.98 1979.92,902.14 1980.19,902.33 1980.52,902.07
  		1982.43,901.71 1983.32,902.07 1983.72,901.94 1984.11,901.65 1984.32,901.42 1984.36,901.18 1984.55,900.88 1985.2,900.74
  		1986.91,900.92 1987.6,900.5 1987.85,900.27 1988.01,899.76 1988.19,899.35 1988.8,899.13 1988.79,898.76 1988.7,898.35
  		1989.05,897.44 1989.27,897.07 1989.62,896.93 1990.04,896.64 1990.76,896.05 1990.59,895.53 1990.74,895.14 1991.49,894.2
  		1992.07,893.3 1992.06,892.84 1992.14,892.45 1992.65,892.01 1993.19,891.44 1993.9,889.67 1994.15,889.38 1994.61,889.04
  		1994.96,888.7 1994.99,887.54 1995.32,887.2 1995.94,886.82 1996.55,886.21 1997.06,885.49 1997.44,885.16 1997.96,885.07
  		1998.51,884.78 1999.13,884.68 1999.74,884.82 2000.12,884.74 2000.7,884.39 2002.17,883.07 2002.38,882.81 	"/>
    </>,
    HTI: <>
        <path className="st0" d="M1195.88,1148.8l0.29-0.07l0.88,0.28l0.85,0.15l0.13-0.25l-0.34-0.2l-1.07-0.47l-0.51-0.02l-0.47,0.07
  		l-0.45,0.21l0.51,0.27L1195.88,1148.8z M1206.17,1156.6l0.1-0.28l-0.02-0.28l-0.84-0.78l-0.06-0.34l0.39-0.85l-0.04-0.56
  		l-0.39-1.75l-0.18-0.26l-0.47,0.19l-1.01-0.22l-2.24-0.2l-1.77-0.6l-1.74-0.76l-2.02-0.24l-2.03,0.21l-0.84,0.18l-0.82,0.25
  		l-0.72,0.41l-0.32,0.74l0.35,0.55l0.68,0.19l2.21,0.23l1.59,0.74l1.35,0.74l-0.32,0.86l-0.22,0.88l0.23,0.95l-0.59,0.52l1.36,1.54
  		l1.55,1.31l0.99,0.59l0.01,0.44l-0.25,0.43l-0.35,0.14l-1.68,0.07l-0.35,0.31l-0.31,0.41l-0.37,0.23l-0.42,0.06l-1.08-0.18
  		l-5.68-0.58l-0.8-0.37l-1.48-0.09l-0.96-0.23l-1.05-0.34l-1.07-0.19l-0.48,0.05l-0.87,0.28l-0.76,1.51l0.15,0.49l0.34,0.41
  		l1.89,0.67l0.92,0.47l0.81,0.62l0.88,0.88l0.39-0.14l0.12-0.55l0.65-0.59l0.87-0.34l1.09-0.14l1.09-0.05l0.95,0.15l0.95,0.24
  		l2.39,0.47l1.02-0.04l1.03-0.17l0.35-0.09l0.33-0.19l0.42-0.1l3.74-0.07l0.49,0.14l0.47,0.22l0.78,0.58l0.71,0.69l0.04-1.43
  		l0.22-0.58l-0.21-0.61l-0.93-0.65l-0.57-0.83l-0.51-0.74l0.11-0.11l1.01-0.03l0.36-0.27l0.68-0.76l0.14-0.61l-0.06-0.46l-0.44-0.55
  		l-0.17-0.58l0.55-0.51L1206.17,1156.6z M1196.51,1159.67l-0.97-0.73l-1.26-0.61l-0.85-0.31l-0.89,0.11l-0.07,0.5l1.75,0.92
  		l2.15,0.73L1196.51,1159.67z"/>
    </>,
    HRV: <>
        <path className="st0" d="M1935.64,923.04l-0.74-0.52l0.22,0.44l2.06,2.25l0.12-0.05l0.44,0.14l0.01-0.1l-0.12-0.15L1935.64,923.04z
  		 M1938.31,924.14l0.32,0.51l0.55,0.47l0.53,0.16l-0.55-0.76L1938.31,924.14z M1935.3,919.24l0.7,0.53l0.36,0.43l0.5,0.36l0.54,0.51
  		l0.22-0.27l0.21-0.12l0.22-0.03l-1.07-0.88l-0.9-1.02l-0.91-0.79l-0.36-0.08l-0.43-0.31l-0.52-0.5l0.16,0.34l1.05,1.24
  		L1935.3,919.24z M1936.59,922.63l0.07,0.2l0.4,0.46l1.05,0.68l-0.13-0.36L1936.59,922.63z M1931.55,916.76l-0.13-1.49l0.12-1.05
  		l-0.62-0.63l-0.21-0.52l-0.09-0.9l-0.23,0.03l-0.38,0.21l0.46,1.3l0.02,0.42l-0.33,0.41l0.08,0.42l0.64,1.47l0.26,0.91l0.51,0.51
  		l0.07-0.4l-0.05-0.34L1931.55,916.76z M1934.44,914.15l-0.6-0.92l-0.32-0.26l-0.6-0.92l-0.5-0.49l-0.39,0.81l-0.73,0.5l0.11,0.2
  		l0.52,0.46l0.85,0.1l0.13,0.33l0.49,0.4L1934.44,914.15z M1934.83,916.87l-0.21-0.46l-0.57-0.65l-0.08-0.24l-0.54-0.03l-0.15,0.25
  		l-0.1,0.25l0.15,0.31l0.71,0.16L1934.83,916.87z M1953.93,933.31l0.59-0.11l-1.12-0.18l-3.06-0.32l-0.35-0.4l-1.13-0.16l-1.22,0.16
  		l0.25,0.17l1.21,0.55l1.09,0.21L1953.93,933.31z M1956.52,936.52l-0.36,0.02l-0.38,0.08l3.37,0.91l-1.15-0.7L1956.52,936.52z
  		 M1973.1,911.7l-0.15-0.19l-0.25-0.15l-0.18-0.24l-0.48-0.1l-1.51-0.61l-0.74-0.65l0.02-0.69l0.22-0.38l0.26-0.13l-0.08-0.13
  		l-0.85-0.32l-0.3-0.45l0.25-0.58l-0.44-1.16l-0.47-0.72l0.46-0.31l0.06-0.44l0.04-0.25h-0.04l-0.57,0.22l-0.94,0.12l-0.47-0.08
  		l-0.85,0.99l-0.26,0.18l-0.81,0.3l-0.67,0.15l-0.57-0.12l-0.22-0.01l-2.53-0.05l-1.32-0.21l-0.85-0.39l-0.56-0.43l-0.27-0.48
  		l-0.66-0.29l-1.03-0.1l-0.81-0.47l-0.58-0.85l-0.78-0.67l-0.98-0.49l-0.78-0.7l-0.58-0.9l-1.04-0.81l-1.5-0.72l-0.45-0.16
  		l-0.75-0.26l-0.9-0.11l-0.17,0.14l-0.36,0.14l-0.18,0.25l0.14,1l-0.22,0.17l-1.02-0.1l-0.34,0.11l-0.55,0.7l-0.57,0.29l-0.72,0.21
  		l-0.53,0.25l-0.67,0.22l-0.58,0.13l-0.23,0.3l-0.14,0.34l0.04,0.32l0.58,0.64l0.08,0.68l-0.06,0.83l-0.13,0.44l-0.23,0.29
  		l-1.44,0.38l-1.49,0.68l-0.03,0.16l0.68,0.6l0.03,0.15l-0.56,0.34l-0.05,0.34l0.06,0.4l0.3,0.41l0.11,0.37l-0.82,0.27l-1.11-0.1
  		l-1.31-0.51l-0.46,0.08l-0.45,0.26l-0.45-0.11l-0.5-0.32l-0.71-0.66l-0.34-0.4l-0.14-0.43l-0.19-0.06l-0.29,0.13l-0.24,0.52
  		l-0.66,0.93l-0.48,0.25l-0.73-0.05l-1.03,0.02l-0.64,0.08l-0.78-0.33l-0.19,0.06l0,0.22l-0.29,0.34l-0.48,0.22l-2.22-0.51
  		l-0.31-0.42l-0.51,0.37l0.73,2.61l0.09,0.71l0.13,0.57l0.95,1.21l1,1.6l0.33,0.08l0.56-0.07l0.64-0.95l0.41-0.73l1.23-1.69
  		l0.27-1.28l0.37-0.58l0.62-0.05l1.38,0.46l0.69,0.78l1.87,1.48l0.44,1.14l-0.18,1.59l0.08,1.16l0.73,1.07l2.43,2.27l0.94,0.57
  		l0.76,0.58l-0.85-0.18l-0.72,0l-0.45,0.18l-0.39-0.01l-0.52,0.17l0.53,0.87l2.64,2.71l1.32,1l1.39,0.77l1.02,0.81l0.06,0.51
  		l-0.06,0.39l0.36,0.5l0.51,0.15l0.72-0.01l1.16-0.26l1.05-0.12l1.74,0.81l2.55,0.73l1.91,1.85l1.69,0.98l1.75,1.56l0.4,0.24
  		l0.49-0.22l0.12-0.21l-0.06-0.27l-0.22-0.37l-1.87-1.59l-0.92-1.09l-0.15-0.39l-0.01-1.04l-0.22-0.25l-1.38-0.47l-1.54-1.36
  		l-1.59-1.33l-0.22-0.37l-0.82-1.01l-1-0.92l-0.8-0.59l-0.65-0.67l-0.72-0.93l-0.37-1.41l-0.33-1.26l-0.23-0.49l-0.46-0.17
  		l-1.42-1.49l-1.21-0.87l0.01-0.94l0.2-1.57l0.22-1.78l0.29-0.25l0.55-0.13l0.63,0.05l0.55,0.22l1.09,1.22l0.62,0.47l0.52,0.18
  		l0.6-0.52l0.75-1.08l0.65-0.57l2.19,0.21l1.08-0.83l1.74,1.09l0.72,0.16l0.4-0.15l0.55,0.07l1.22,0.32l0.28,0.13l0.37-0.02
  		l0.9-0.43l0.31,0.05l1.03,0.83l0.52,0.01l0.63-0.36l0.4-0.31l1.19,0.23l0.68-0.14l0.56-0.01l0.61,0.14l0.56,0.19l0.54,0.17
  		l1.47,0.09l0.7,0.53l0.28,0.51l0.01,0.31l0.07,0.34l0.4,0.33l0.88,0.19l0.55-0.04l-0.1-0.36l0.12-0.16l0.24,0.02l0.19,0.07
  		l0.21-0.16l0.12-0.49l-0.31-1.7l0.56-0.15l0.01-0.25l0.05-0.22l0.58,0.3l0.82,0.01l0.72-0.06l0.11-0.17L1973.1,911.7z M1965,939.33
  		l-0.01-0.37l-0.76-0.27l-0.36-0.13l-1.52-0.93l-0.67-0.51l-1.06-0.67l-0.65-0.38l-0.33-0.58l-0.52-0.13l-0.61,0.19l0.47,0.47
  		l-4.24-1.78l-0.79,0l-0.68,0.11l1.79,0.47l2.75,1.34l2.01,0.4l2.84,1.66l1.45,1.08l1.55,0.96l-0.34-0.49L1965,939.33z
  		 M1951.07,931.72l0.74-0.28l0.15-0.17l-0.48-0.37l-1.96-0.32l-1.29-0.05l-0.18,0.45l-0.04,0.27l0.57,0.32l1.15,0.18L1951.07,931.72
  		z M1953.67,934.85l-1.03-0.17l-1.14,0.13l-1.56-0.28l0.13,0.37l0.25,0.27l0.36,0.21l0.94,0.18l1.07-0.33l0.94,0.13l0.83-0.02
  		l-0.16-0.16L1953.67,934.85z"/>
    </>,
    HND: <>
        <path className="st0" d="M1081.83,1180.38l1.38-0.43l-0.69-0.09l-0.85,0.22l-1,0.44l-0.62,0.52l0.42,0.01L1081.83,1180.38z
  		 M1086.45,1179.66l0.22-0.23l0.09-0.2l-0.37-0.03l-0.39,0.26l-0.31,0.46l0.12,0.22L1086.45,1179.66z M1108.73,1191.84l-0.55-0.31
  		l-0.66-1.38l-2.33-1.1l-0.25,0.03l1.05,0.61l0.45,0.62l-0.32,0.02l-0.45-0.4l-0.7,0.04l-0.37,0.36l-0.38-0.01l-0.35-0.59
  		l-0.58-0.54l-0.48-0.35l-0.72-0.17l-0.29,0.14l-0.4-0.03l-0.09-0.25l-0.05-0.53l0.24-0.16l0.93-0.07l1.75,0.98l-0.09-0.27
  		l-4.09-3.31l-2.17-0.43l0.25,0.22l0.54,0.15l-0.12,0.14l-0.44,0.16l-0.57-0.07l-0.73-0.7l-2.76-0.91l-0.63,0.14l-0.97,0.48
  		l-2.7,0.16l-2.53-0.89l-1.7-0.18l0.27,0.19l0.15,0.42l-1.11,0.41l-0.95,0.18l-1.48,0.87l-1.05-0.15l-2.33,0.06l-1.27,0.27
  		l-3.19-0.62l-0.77,0.07l-0.92,0.31l-0.49-0.36l-0.62-0.67l-0.7-0.01l-1.46,0.27l-0.27,0.14l-0.87,0.66l-0.37,0.18l-0.64,0.55
  		l-0.82-0.24l-0.36,0.29l-0.78,0.68l-1.42,1.16l-1.27,1.03l-1.22,0.94l-1.1,0.84l-0.13,0.08l-1.4,0.6l-0.23,0.28l-0.3,1.19
  		l-0.14,0.3l0.25,0.66l0.25,1.02l-0.08,0.53l-0.97,0.66l-0.44,0.59l-0.2,0.38l0.21,0.04l1.41,0.44l0.42-0.08l0.53,0.35l0.26,0.27
  		l0.22,0.38l1.11,0.76l0.19,0.33l0.83,0.45l0.33,0.34l0.35,0.14l0.69,0.13l0.59,0.18l0.07,0.13l0.06,0.51l0.13,0.43l0.28,0.03
  		l0.34-0.21l1.11-0.57l1.05-0.38l0.59,0.23l0.35,0.48l0.4,0.21l0.83-0.13l0.76,0.04l0.6,0.42l0.14,0.24l-0.36,1.39l-0.13,0.59
  		l-0.06,0.5l0.21,0.13l0.21,0.2l-0.05,0.27l-0.65,0.44l0.38,0.19l0.51,0.14l0.89-0.22l0.95,0.28l0.03,0.36l-0.11,0.3l0.34,0.51
  		l0.38,0.75l0.68,0.36l-0.04,0.9l2.34-0.1l0.42-0.14l0.42-0.39l0.22-0.54l0.04-0.53l0.09-0.38l0.38-0.37l0.69-0.11l0.53-0.04
  		l0.16-0.25l-0.16-0.8l-0.29-1.94l-0.06-0.54l0.1-0.4l0.21-0.15l1.04-0.1l1.96,0.16l0.38-0.12l0.79-1.1l0.73-0.81l0.52-0.36
  		l0.41-0.11l0.48,0.72l1.66,1.03l0.28-0.06l0.16-0.06l0.05-0.15l-0.03-0.48l0.42-0.43l0.86-0.39l0.87-0.69l0.87-0.98l0.75-0.58
  		l0.64-0.17l0.24-0.27l-0.15-0.36l0.05-0.52l0.25-0.67l0.37-0.39l0.49-0.11l0.19-0.21l-0.1-0.32l0.1-0.35l0.44-0.57l1.05-0.49
  		l0.6,0.17l0.5,0.66l0.71,0.45l0.91,0.24l0.71-0.09l0.5-0.41l0.45-0.12l0.4,0.16l0.21-0.09l0.02-0.34l0.21-0.08l0.39,0.19l0.35,0.05
  		l0.31-0.09l0.12-0.17l0.06-0.17l0.23-0.13l0.79,0.13l0.88-0.2l0.98-0.53l0.65-0.23l0.32,0.06l0.39-0.27l0.45-0.59l1.02-0.27
  		l2.17,0.13L1108.73,1191.84z"/>
    </>,
    HMD: <>
        <polygon className="st1" points="2430.7,1811.36 2429.48,1810.63 2427.81,1810.32 2427.11,1809.94 2426.66,1810.05 2426.68,1810.2
  		2426.95,1810.57 2427.38,1810.66 2428.02,1812 2428.46,1812.43 2429.49,1812.43 2430.5,1811.89 2431.24,1811.81 2431.6,1811.61
  		"/>
    </>,
    HKG: <>
        <path className="st0" d="M2771,1128.97l0.04,0.21l0.58,0.64l0.21-0.14l0.1-0.2l0.03-0.26l-0.5-0.29L2771,1128.97z M2768.51,1129.42
  		l0.11,0.19l0.22,0.09l1.01,0l0.05-0.59l0.34-0.49l-1.37,0.47L2768.51,1129.42z M2772.6,1127.7l-0.34-0.18l0.06-0.37l-0.18-0.33
  		l-0.03-0.04l-0.32-0.11l-0.34-0.1h-0.55l-0.21,0.12l-0.4,0.07l-0.27,0.25l-0.02,0.02l-0.07,0.25l-0.93,0.49l0.05,0.28l0.29,0.27
  		l0.81-0.1l0.89,0.24l1.09,0.47l0.17-0.26l0.02-0.43l0.38-0.2L2772.6,1127.7z"/>
    </>,
    GYM: <>
        <polygon className="st1" points="1333.64,1302.12 1333.3,1301.87 1332.76,1301.78 1332.11,1301.61 1331.63,1300.95 1331.14,1300.01
  		1330.96,1299.59 1330.58,1299.18 1330.2,1298.6 1330.08,1298.09 1329.79,1297.62 1329.64,1297.46 1329.42,1296.81 1329.38,1296.57
  		1329.27,1296.54 1329.11,1296.34 1328.8,1295.65 1328.73,1295.48 1328.6,1295.42 1328.24,1294.94 1327.96,1294.77 1327.85,1294.52
  		1327.88,1293.85 1327.72,1293.51 1327.68,1292.88 1327.67,1292.63 1327.52,1292.35 1327.28,1292.16 1327.24,1291.71
  		1327.18,1290.58 1327.06,1290.38 1326.03,1290.4 1325.93,1290.51 1325.49,1290.57 1324.99,1290.58 1324.54,1290.43
  		1324.17,1290.24 1324.09,1289.99 1324.15,1289.21 1323.55,1288.61 1322.61,1287.88 1322.32,1286.95 1321.97,1286.36
  		1320.93,1285.15 1320.74,1284.31 1320.74,1283.72 1321.11,1283.18 1321.62,1282.23 1321.83,1281.37 1321.99,1280.92
  		1322.25,1280.33 1322.49,1279.56 1322.31,1279.09 1322,1278.63 1321.9,1278.29 1322.2,1277.78 1322.5,1277.43 1322.85,1277.38
  		1323.29,1277.16 1323.63,1276.85 1324.15,1276.77 1324.81,1276.74 1326.15,1276.74 1326.83,1276.61 1327.04,1276.36
  		1327.01,1275.89 1327.35,1275.46 1327.71,1275.28 1327.85,1275.14 1327.87,1274.98 1327.78,1274.84 1327.64,1274.74
  		1327.26,1274.71 1326.94,1273.97 1327.16,1273.64 1327.45,1273.05 1327.53,1272.71 1327.98,1272.18 1327.89,1272.04
  		1328.21,1269.35 1328.02,1267.57 1327.7,1266.89 1326.72,1266.1 1325.07,1265.6 1324.5,1264.61 1322.94,1263.36 1321.34,1261.79
  		1320.59,1261.49 1319.74,1261.42 1318.68,1261 1317.7,1261.24 1316.97,1262.22 1316.4,1263.12 1316.19,1264.6 1315.53,1265.11
  		1316.03,1264.17 1316.08,1262.53 1316.29,1261.3 1317.15,1259.67 1317.18,1257.25 1316.89,1256.64 1315.92,1255.4 1315.29,1254.88
  		1314.36,1253.8 1311.09,1250.94 1308.76,1249.43 1307.16,1248.52 1306.55,1248.37 1306.54,1248.72 1306.4,1248.71 1305.73,1248.42
  		1304.51,1247.09 1304.2,1246.95 1305.77,1249 1305.79,1249.22 1305.62,1249.48 1304.65,1249.96 1304.43,1250.21 1304.08,1251.12
  		1302.85,1251.62 1302,1252.25 1301.43,1252.8 1301.15,1253.02 1300.03,1253.15 1299.67,1253.49 1299.21,1254.53 1298.88,1254.97
  		1298.3,1255.48 1298.29,1255.79 1299.1,1256.93 1299.24,1257.29 1298.99,1257.83 1299.02,1258.21 1299.44,1258.67 1299.95,1258.78
  		1300.44,1258.59 1301.05,1258.6 1301.45,1258.73 1301.61,1258.86 1301.64,1259.21 1301.39,1259.96 1301.02,1260.45
  		1299.42,1261.19 1298.7,1261.62 1298.3,1261.93 1297.44,1261.77 1297,1261.78 1296.66,1262.02 1296.45,1262.23 1295.87,1262.28
  		1295.05,1262.41 1294.7,1262.55 1294.44,1262.92 1294.21,1263.45 1294.4,1264.08 1294.66,1264.64 1294.65,1265.15 1294.85,1266.59
  		1294.59,1266.92 1294.03,1267.3 1293.38,1267.97 1292.64,1268.9 1292.76,1269.17 1294.52,1271.12 1296.32,1273.11 1298.1,1275.09
  		1298.87,1274.93 1299.5,1275.17 1300.48,1275.2 1300.91,1275.02 1301.53,1275.11 1302.32,1274.62 1302.82,1274.78 1303.16,1274.78
  		1303.46,1275.15 1303.69,1275.57 1304.43,1276.08 1304.36,1276.87 1304.22,1277.56 1304.13,1278.35 1304.08,1278.96
  		1303.77,1279.58 1303.3,1280.15 1303.16,1280.39 1303.1,1280.69 1303.42,1280.88 1303.97,1280.93 1304.67,1280.96 1305.14,1281.14
  		1305.76,1281.17 1306.49,1281.67 1306.85,1281.97 1306.88,1282.2 1306.65,1282.75 1306.55,1283.26 1306.74,1283.59
  		1306.95,1283.82 1307.55,1284.97 1307.84,1285.37 1308.08,1285.5 1308.13,1285.72 1307.93,1286.14 1307.68,1286.67
  		1307.13,1287.23 1307.06,1287.68 1306.61,1287.96 1305.58,1288.62 1305.76,1289.67 1305.79,1290.2 1305.77,1290.62
  		1305.42,1291.17 1304.81,1292.8 1304.58,1293.62 1304.39,1295.5 1304.4,1296.14 1304.68,1296.99 1305.28,1298.87 1305.62,1299.17
  		1306.41,1299.62 1306.51,1300.89 1306.44,1302.23 1306.41,1302.74 1306.54,1302.96 1306.89,1303.07 1307.14,1303.23
  		1307.18,1303.62 1307.16,1304.03 1307.75,1304.27 1308.26,1304.42 1308.74,1304.99 1309.59,1305.87 1309.93,1306.03
  		1310.1,1306.39 1310.83,1307.13 1311.93,1307.4 1313.04,1307.73 1313.48,1308.2 1313.93,1308.58 1314.28,1308.6 1314.57,1308.53
  		1315.05,1308.21 1315.43,1307.93 1316.1,1307.94 1316.89,1307.9 1317.02,1307.67 1317.1,1307.37 1316.93,1306.61 1317.21,1306.38
  		1317.86,1306.25 1317.99,1305.84 1318.14,1305.62 1318.33,1305.36 1318.55,1305.33 1318.83,1305.47 1319.26,1305.57
  		1319.74,1305.69 1320,1305.95 1320.43,1305.98 1320.9,1305.93 1321.1,1305.76 1321.24,1305.47 1321.34,1304.85 1321.65,1304.84
  		1322.26,1304.7 1322.92,1304.42 1323.79,1304.38 1324.61,1304.39 1325.02,1304.2 1325.4,1303.8 1326.14,1302.67 1326.53,1302.41
  		1326.95,1302.22 1327.3,1302.25 1328.02,1302.07 1328.62,1301.79 1328.84,1301.86 1329.3,1302.44 1329.53,1302.57 1329.88,1302.61
  		1330.99,1302.91 1331.59,1302.81 1332.23,1302.63 1332.85,1302.56 1333.3,1302.69 1333.62,1302.52 1333.98,1302.4 	"/>
    </>,
    GNB: <>
        <path className="st0" d="M1675.77,1222.3l-0.11-1.05l-0.41-0.08l-0.46,0.6l-0.03,0.43l0.22,0.24l0.41,0.12L1675.77,1222.3z
  		 M1674.2,1224.56l-0.15,0.07l-0.33,0.2l-0.27,0.31l-0.16,0.01l-0.35,0.14l0.05,0.16l0.31,0.42l0.67-0.13l0.36-0.21l0.16-0.28
  		l-0.12-0.67L1674.2,1224.56z M1675.71,1224.88l-0.24-0.27l-0.07,0.11l-0.14,0.72l0.11,0.07l-0.11,0.24l0.49,0.04l0.08-0.23
  		l-0.08-0.56L1675.71,1224.88z M1674.76,1218.73l-1.1,0.08l0.04,0.26l0.38,0.62l0.54,0.11l0.44-1.04L1674.76,1218.73z
  		 M1694.04,1212.73l0.03-0.69l-2.83-0.01l-2.4-0.01l-3.02-0.01l-2.13-0.01l-1.98-0.01l-1.53,0.77l-1.66,0.84l-2.23,0.45l-2.57-0.17
  		l-0.82,0.12l-0.85,0.37l-0.62,0.27l-0.88,0.16l-1.14-0.13l-0.46,0.08l2.32,1.28l1.05-0.33l0.57,0.05l-0.09,0.26l-0.54,0.53
  		l-0.08,0.78l0.45,0.62l1.14,0.52l1.51-0.36l0.32,0.19l0.15,0.15l-0.33,1.13l1.03,0.2l1.42-0.47l1.16-0.84l0.66-0.22l2.08,0.14
  		l0.64-0.36l0.28,0.01l0.05,0.19l-0.25,0.28l-0.26,0.06l-0.65,0.31l-1.73-0.01l-0.43,0.25l-0.28,0.55l-0.01,0.46l0.75,0.92
  		l0.45-0.06l0.36-0.39l0.73-0.15l0.91,0.21l0.42,0.54l-0.77,0.14l-0.75,0.06l-1.49,0.63l-0.42,0.75l0.26,0.17l0.42-0.1l0.37,0.04
  		l0.05,0.15l-0.39,0.37l-0.05,0.58l0.06,0.42l0.64,0.55l0.46-0.08l0.39,0.04l-0.05,1.06l0.35-0.03l0.71-0.9l0.36-0.02l-0.33,1.11
  		l0.43,0.6l0.37-0.44l0.46-0.68l1.39-2.82l0.5-0.65l0.31-0.23l0.66-0.03l1.28-0.38l1.05-0.62l0.52-0.26l1.21,0.07l1.42-0.11
  		l1.86-0.61l0.04-0.83l-0.02-1.07l-0.06-0.42l-0.66-0.38l-0.38-0.33l-0.33-0.42l-0.4-0.3l0.01-0.31l0.5-0.27l0.32-0.14l0.76,0.01
  		l0.25-0.16l0.19-0.27l0.22-0.69l0.07-0.72L1694.04,1212.73z M1677.32,1223.69l-0.31,0.28l-0.21,0.63l0.1,0.1l0.36,0.07v-0.35
  		l0.32-0.16l0.17-0.2l0.07-0.24l-0.11-0.08L1677.32,1223.69z M1678.15,1221.72l0.48,0.17l0.08-0.2l0.23-0.14l0.35-0.12l0.02-0.55
  		l-0.46,0.12L1678.15,1221.72z"/>
    </>,
    GUM: <>
        <polygon className="st1" points="3030.2,1204.17 3029.91,1204.03 3029.59,1203.97 3029.2,1204.78 3028.01,1205.62 3028.01,1206.6
  		3028.12,1206.79 3028.43,1207.07 3028.79,1207.06 3029.11,1205.77 3030.46,1204.41 	"/>
    </>,
    GTM: <>
        <polygon className="st1" points="1063.52,1184.06 1063.49,1184.25 1063.71,1184.48 1064.01,1184.92 1063.44,1185.65 1062.55,1185.29
  		1061.8,1184.81 1061.45,1184.75 1060.99,1184.57 1060.63,1184.57 1059.14,1184.48 1058.14,1184.58 1058.1,1184.53 1058.18,1182.4
  		1058.31,1179.1 1058.4,1176.67 1058.5,1174.3 1058.57,1172.52 1058.66,1170.09 1058.74,1168 1056.97,1168 1053.96,1168
  		1050.13,1167.99 1046.44,1167.99 1043.35,1167.99 1043.34,1169.68 1043.33,1171.17 1043.31,1172.85 1041.61,1172.84
  		1039.8,1172.82 1039.95,1172.99 1040.57,1173.31 1041.37,1174.06 1042.31,1175.23 1043.46,1176.17 1044.8,1176.86 1045.69,1177.54
  		1046.12,1178.21 1046.33,1178.77 1046.34,1179.24 1046.83,1179.61 1047.71,1179.85 1048.17,1180.27 1048.17,1180.61
  		1047.89,1181.38 1047.8,1182.23 1047.91,1183 1047.28,1183.02 1045.75,1183.02 1043.43,1183.02 1041.29,1183.02 1039.6,1183.02
  		1037.05,1183.03 1036.35,1184.21 1035.19,1186.18 1034.14,1187.96 1033.26,1189.45 1033.11,1189.85 1033.11,1190.17
  		1034.2,1191.57 1034,1191.97 1033.62,1192.18 1033.5,1192.51 1033.52,1193.04 1033.26,1193.75 1033.35,1194.24 1033.49,1194.84
  		1033.26,1195.36 1033.07,1195.87 1032.85,1196.09 1036.36,1198.8 1037.86,1199.76 1040.08,1200.83 1042.02,1201.38
  		1046.57,1201.35 1047.64,1201.59 1050.88,1202.99 1050.79,1202.6 1050.79,1202.16 1051.27,1201.56 1052.16,1200.77
  		1052.75,1200.36 1053.03,1200.28 1053.41,1200.32 1053.79,1200.09 1054.11,1199.54 1054.45,1199.19 1055.3,1198.83
  		1055.49,1198.69 1055.42,1198.38 1055.24,1197.78 1055.27,1197.42 1055.55,1197.25 1055.88,1197.21 1056.57,1197.06
  		1056.87,1197.09 1057.05,1197.19 1057.24,1196.81 1057.68,1196.22 1058.65,1195.56 1058.73,1195.03 1058.48,1194.01
  		1058.23,1193.35 1058.36,1193.05 1058.67,1191.86 1058.9,1191.58 1060.3,1190.98 1060.43,1190.9 1061.53,1190.05 1062.76,1189.11
  		1064.03,1188.08 1065.45,1186.92 1066.24,1186.25 1066.6,1185.95 	"/>
    </>,
    SGS: <>
        <path className="st0" d="M1507.15,1829.19l0.22-0.96l-0.58,0.15l-0.58,0l-0.34-0.16l-0.37-1.11l-0.47-0.88l-0.53-0.25l-0.42-0.83
  		l-0.35-0.43l-0.5,0.32l-0.18,0.28l-0.35,0.06l-0.78-0.7l-0.9,0.17l0.34-0.84l-0.82-0.95l-0.47,0l-0.38-0.07l-0.37-0.19l-0.67-0.05
  		l-0.65,0.38l-0.82-0.56l-1.09-0.06l-1.15-0.59l-0.11-0.29l-1.29,0.11l-3.45,0.02l-0.61,0.14l0.88,0.24l2.48,0.15l-0.59,0.4
  		l-0.03,0.67l0.52,0.38l1.12-0.13l2.86,1.34l0.63,0.46l0.64,0.34l1.02-0.02l0.29,0.31l0.23,0.44l0.75,0.72l0.9,0.34l1.02,0.18
  		l0.29,0.27l0.22,0.42l1.13,1.43l0.5,1l1.08,0.85l0.32,0.16l1.23-0.38l0.61-0.49l0.58-0.34l-0.97-0.61L1507.15,1829.19z
  		 M1588.49,1874.63l-0.13-0.41l-0.2-0.24l-0.82,0.01l-0.42,0.4l0.3,0.3l1.31,0.65L1588.49,1874.63z"/>
    </>,
    GRC: <>
        <path className="st0" d="M2022.9,989.2l-0.05,0.74l0.81-0.06l0.46-0.27l0.05-0.23l-0.97-0.37L2022.9,989.2z M2026.97,995.8l-0.45,0.17
  		l0.24,0.07l0.74-0.08l1.06-0.86l0.67-0.22l-0.54-0.33l-0.49,0.49l-0.75,0.38l-0.15,0.21L2026.97,995.8z M2020.23,987.38l0.17,0.3
  		l0.3,0.31l0.88,0.67l0.53,0.1l0.31-0.63l-0.26-0.3l-1.13-0.17l-0.44-0.32L2020.23,987.38z M2023.19,964.76l-0.52,0.28l-0.43-0.49
  		l-1.48,0.06l-0.05,0.23l0.11,0.66l-0.03,0.57l0.54,0.26l0.49-0.04l0.15-0.19l0.17-0.43l0.22-0.02l0.02,0.39l0.1,0.31l0.3,0.17
  		l0.49-0.02l0.13-0.82l0.23-0.58l0.32-0.33l0.1-0.51l-0.64,0.19L2023.19,964.76z M2021.94,994.02l0.67-0.74l-0.06-0.15l0.03-0.52
  		l-0.34-0.1l-0.75,0.4l-0.35,0.7l0.23,0.34L2021.94,994.02z M2023.33,997.23l0.15-0.15l0.21-0.42l-0.94-0.69l-0.3,0.3l0.24,0.36
  		L2023.33,997.23z M2025.86,960l-0.34-0.28l-0.47-0.24l-1.01,0.33l1.05,0.82l0.95-0.26L2025.86,960z M2025.18,993.56l0.03-1.09
  		l-0.2-0.31l-0.33-0.11l-1.38,1.22l0.29,0.83l0.51,0.53l0.75-0.37L2025.18,993.56z M2024.33,999.79l-0.13-0.41l-0.49-0.37l-0.1,0.08
  		l0.15,0.22l-0.02,0.36l-0.13,0.25l-0.22,0.19l0.55,0.18L2024.33,999.79z M1983.5,976.26l-0.36,0.56l-0.29,0.96l-0.03,0.78
  		l0.25-0.19l0.26-0.04l0.28,0.05l0.33-0.08l0.24-0.29l0.04-1.58L1984,976L1983.5,976.26z M1984.73,983.38l0.07-0.52l-0.79-1.02
  		l-0.59-0.21l-0.1-1.13l-0.41-0.9l-0.11,0.18l-0.02,0.61l-0.24,0.61l-0.32,0.14l-0.39-0.37l-0.22,0.19l-0.48,1.12l0,0.41l0.32-0.08
  		l0.51-0.45l0.18,0.15l0.21,0.34l-0.03,0.2l0.24,0.56l0.38,0.09l0.31-0.21l1.31,0.48L1984.73,983.38z M1983.83,979.6l-0.21-0.07
  		l-0.22,0.04l0.19,0.65l0.53,0.93l0.42-0.11l-0.16-0.35l-0.23-0.32l-0.09-0.27l0-0.25L1983.83,979.6z M1978.38,970.12l-0.61-0.28
  		l-0.24-0.35l-0.2-0.93l0.01-0.19l-0.48-0.48l0.13-0.24l0.63-0.53l-0.09-0.27l-0.29-0.23l-0.45-0.23l-1.11,0.22l-0.51,0.31l0.02,0.4
  		l1.35,1.39l0.63,1.22l0.77,0.49l1.05,0.34l-0.18-0.55L1978.38,970.12z M2020.19,986.58l-0.09-0.36l-0.27-0.59l0.07-0.46l-0.85-0.09
  		l-0.54-0.74l-0.23,0.03l-0.53,0.25l0.12,0.61l0.44,0.27l0.27,0.45l0.72,0.52l0.66,0.76L2020.19,986.58z M2003,999.87l-0.15,0.15
  		l-0.23,0.47l0.04,0.95l0.24,0.43l0.87,0.29l0.1-0.42l0.36-0.55l-0.84-0.78L2003,999.87z M2006.86,988.7l-0.11-0.37l-0.41-0.17
  		l-0.71,0.02l-0.55-0.24l-0.42-0.93v-0.77l-0.51-0.56l-0.42-0.25l0.09-0.24l0.34-0.1l0.9-0.45l1.91-0.32l0.69-0.42l0.3,0.02
  		l0.36,0.22l1.28,1.23l1.22,1.03l0.79,0.98l0.41-0.01l0.3-0.31l0.06-0.63l-0.01-0.42l-0.24-1.34l-0.07-1.8l-0.16-0.85l-0.32-0.47
  		l-1.1-0.49l-1.28-0.26l-0.96-1.34l-1.69-0.35l-0.98-1.33l-0.97-0.07l-0.99-0.72l-2.07-0.57l-0.73-0.48l-0.45-0.01l-0.55-0.16
  		l0.23-0.23l0.67-0.08l1.06-0.03l1.08-0.45l1.15-0.89l-0.85,0.07l-0.22-0.79l-0.45-0.57l-0.4-0.87l0.69-0.47l0.6-0.24l1.42,0.72
  		l0.06,0.47l-0.42,0.76l0.3,0.31l0.54-0.03l0.92-0.69l-0.33-1.12l-0.46-0.69l-1.09-1.32l-1.05-0.71l-0.5-0.64l-0.7-1.7l-2.05-2.34
  		l-0.19-0.82l0.3-1.56l0.31-0.9l-0.15-0.62l0.04-0.67l0.95-0.41l0.59-0.42l0.93-0.12l-0.25,0.66l-0.35,0.33l0.38,0.9l1.7,0.95
  		l1.8,0.87l0.14,1.25l0.57,0.99l1.95,0.65l0.25-0.1l0.14-0.24l-1.74-1.14l-0.29-0.41l-0.39-1.06l0.34-0.42l2.01,0.4l1.44,2
  		l0.94,0.56l0.29-0.28l0.16-0.3l-0.28-0.89l-0.42-0.4l-0.79-0.5l-0.87-0.81l0.06-0.43l0.8-0.38l0.76,0.09l1.2,0.55l0.87,0.23
  		l0.62,0.64l0.93,0.67l-0.43-0.93l-0.67-0.85l-1.53-0.81l-0.83,0.04l-0.55-0.13l-0.29-0.63l0.4-0.63l-0.84-0.83l-0.3-0.49l0.16-0.71
  		l1.54-0.01l1.15,0.24l1.28-0.62l1.26-1.26l0.79-0.35l0.67,0.12l1.03,0.66l0.96,0.12l1.78-1.1l0.84-0.27l1.23,0.62l0.63-0.1
  		l1.44,0.55l3.02,0.44l1.31,0.75l0.24,0.42l0.26-0.13l0.33-0.09l0.59-0.77l0.52-0.57l0.76-0.71l0.19-0.43v-0.4l-0.18-0.28
  		l-0.04-0.33l-0.02-0.46l0.04-0.96l1.73-1.05l0.56-0.11l0.19-0.48l-0.13-1.11l-0.24-0.9l-0.31-0.06l-0.42-0.26l-0.27-0.3l-0.44-0.33
  		l-0.76-0.2l-1.01-0.27l-0.78,0.18l-0.18,0.22l-0.16,0.31l0.09,0.33l0.29,0.32l0.27,0.87l0.1,0.87l-0.17,0.49l-0.58,0.35l-1.21,0.39
  		l-1.17-0.19l-0.51,0.15l-0.86,0.05l-0.8,0.1l-1.22,0.36l-1.1,0.21l-0.99-0.72l-1.18-0.49l-1.24-0.29l-0.43,0.21l-0.19,0.17
  		l-1.03-0.64l-0.46-0.23l-0.22-0.25l-0.43-0.85l-0.26-0.03l-0.85,0.32l-0.82-0.02l-0.5-0.06l-1.47,0.04l-0.2,0.58l-0.18,0.09
  		l-0.32,0.08l-0.78-0.04l-1,0.43l-1.07,0.26l-0.84,0.01l-0.86-0.13l-0.52,0.09l-1.11,0.05l-0.71,0.63l-1.1-0.04l-0.92-0.11
  		l-0.48-0.01l-0.63,0.05l-0.24,0.19l-0.25,1.34l-1.02,0.38l-0.93,0.22l-0.78-0.05l-1.37-0.32l-0.45-0.03l-0.38,0.18l-1.23,0.1
  		l-0.54,0.24l-1.26,1.57l-1.28,0.54l-0.44,0.27l-0.98-0.35l-0.47-0.04l-0.68,0.4l-1.48,0.04l-0.4,0.07l-1.14,0.06l-0.07,0.74
  		l0.27,0.57l0.36,0.59l0,0.36l-0.24,0.59l-0.44,0.69l-0.58,0.26l-0.64,0.22l-0.3,0.53l-0.15,0.57l-0.28,0.42l-0.18,0.46l-0.27,0.94
  		l-0.06,0.34l-0.43,0.34l-0.67,0.14l-0.6,0.03l-0.4,0.16l-0.2,0.32l-0.38,0.26l-0.23,0.11l0,0.28l0.28,0.6l0.31,0.48l0.01,0.39
  		l-0.15,0.11l-0.49-0.05l-0.1,0.14l-0.05,0.43l-0.13,0.37l-0.2,0.23l-0.35,0.25l-0.64-0.08l-0.6-0.37l-0.31-0.11l-0.18,0.01
  		l0.83,0.67l0.77,0.94l0.92,2.16l1.41,0.71l0.87,1.06l1.01,0.79l0.19,0.32l0.56,0.26l1.21-0.28l0.94,0.1l0.29-0.06l0.42,0.02
  		l0.22,0.5l0.07,0.56l-0.34,0.25l-0.32,0.11l-1.52-0.55l-0.98,0.13l-0.07,0.52l0.07,0.66l0.81,0.31l1,1.19l0.57,1.47l0.45,1.16
  		l0.58,0.38l1.02-0.28l0.22-0.49l0.01-0.62l0.21,0.12l0.29,0.65l0.69,0.84l0.8-0.12l0.69-0.2l0.56-0.01l0.74-0.12l1.35-0.44
  		l2.2,0.58l0.78-0.04l0.55-0.28l0.31-0.52l1.36,0.91l1.44,0.54l0.25,0.27l0.43,0.26l0.83,0.32l0.53-0.13l0.33,0.13l0.5,0.06
  		l0.47,0.2l0.29,0.41l-0.26,0.36l-0.26,0.22l-1.41-0.01l-0.52,0.23l0.2,0.42l0.03,0.48l-0.62-0.09l-0.39-0.13l-0.74-0.64l-1.31-0.65
  		l-2.63-0.74l-2.45-1.29l-1.08-0.07l-0.64,0.53l-0.76,0.97l-0.92,0.1l-0.82-0.39l-0.4,0.08l-0.81,1.65l-1.37,1.05l-0.17,0.27
  		l0.11,0.36l0.57,0.25l0.7,0.53l0.34,1.02l0.73,0.29l1.31,0.96l0.91,1.49l0.11,0.75l-0.96,1.05l0.03,1.15l1.31,2.1l1.3,1.21
  		l0.35-0.64l0.05-0.85l0.13-0.95l0.47-0.26l0.58-0.12l0.45,0.63l0.26,0.59l0.56,0.2l1.22,1.74l0.04,0.54l-0.05,1.28l0.45,0.36
  		l0.52,0.28l0-1.17l1.01-2.04l0.92-0.14l0.53,0.07l0.44,0.95l1.27,1.53l0.65,0.4l0.39,0.34l0.45,0.04l-0.41-0.96l-0.6-0.93
  		l0.27-1.26l-0.11-0.76l-0.55-1.57l-1.21-2.66l-0.72-0.99l-0.33-1.44l0.42-0.42l0.64,0.51l0.75,0.15l0.63,0.34l0.68,0.4l0.03,0.74
  		l0.52,0.29l0.35-0.14l0.42-0.28l1.99-0.61l0.01-0.23l-0.27-0.32L2006.86,988.7z M2029.62,978.93l-0.82-0.56l-1.4,0.27l0.04,0.64
  		l0.92,0.9l0.26,0.61l-0.33,0.5l-0.66,0.32l0.15,0.26l0.9,0.8l0.8-0.55l0.08-0.16l0.06-0.44l0.39-0.23l-0.06-1.62l-0.07-0.17
  		l0.16-0.53L2029.62,978.93z M1985.22,985.79l-0.68-0.12l-0.57-0.74l-0.61,0.72l0.13,0.31l0.58,0.77l0.97,0.76l0.76-0.65l0.71,0.23
  		l-0.89-0.95L1985.22,985.79z M2028.66,988.46l-0.12,0.39l0.39-0.04l1.48-0.38l0.78-0.5l0.46-0.53l-0.22,0.01l-0.96,0.34l-1.05,0.03
  		L2028.66,988.46z M2044.17,998.39l0.18-0.15l0.07-0.28l0.01-0.38l-0.06-0.18l-0.22,0.06l-0.43,0.26l0.02,0.23L2044.17,998.39z
  		 M2017.76,994.74l0.15-0.04l0.36-0.27l-0.4-0.72l-0.29,0.02l-0.09,0.22l0.05,0.38L2017.76,994.74z M2031.8,997.42l0.12,0.3
  		l-0.4,0.26l-0.57-0.14l0,0.47l0.52,0.34l0.42-0.48l0.67-0.23l-0.33-0.37L2031.8,997.42z M2007.92,984.53l-0.16-0.15l-0.27-0.05
  		l-0.18,0.1l-0.19,0.39l-0.17,0.09l0.13,0.19l0.27,0.09l0.38,0.01l0.33-0.24L2007.92,984.53z M2030.1,1011.05l-1.18,0l-1.13,0.34
  		l-0.47,0.44l-0.39,0.09l-0.39-0.19l-0.08-0.39l0.17-1.36l-0.22-0.21l-1.35,0.2l-0.79,0.21l-1.51-0.32l-1.62-0.07l-0.85-0.6
  		l-2.37-0.14l-0.79,0.14l-0.78,0.28l-0.76,0.14l-0.77,0.06l-0.35-0.04l-0.32-0.21l-0.14-0.35l-0.02-0.43l-0.65,0.09l-0.47-0.23
  		l-0.12-0.12l0.13-0.14l0.62-0.25l-0.27-0.55l-0.61,0.01l-0.5,0.56l-0.18,0.06l-0.78,0.01l-0.58-0.07l-0.5-0.2l-0.19-0.74l-0.29-0.2
  		l-0.18,0.48l0,0.52l-0.36,0.35l-0.39-0.16l-0.15-0.34l-0.33,0.3l-0.19,1.14l0.12,1.15l0.26,0.36l0.38,0.21l0.55,0.02l1.51-0.12
  		l0.93,0.23l3.95,0.59l1.01,0.43l1.06,0.25l0.22,0.29l0.07,0.42l0.01,0.61l0.46,0.15l3.42-0.24l3.41-0.46l1.84-0.17l1.83,0.1l1-0.04
  		l0.66-0.25l0.09-0.48l0.21-0.61l0.15-1.04l0.18-0.44l-0.29,0.05L2030.1,1011.05z M2037.57,987.07l-0.13-0.59l-0.49-0.12l-0.29,0.03
  		l-1.04-0.32l-0.68,0.02l-0.88,0.28l-0.49,0.55l0.27,0.13l0.91,0.05l0.57,0.47l0.48,0.12l1.12-0.54L2037.57,987.07z M2032.8,973.73
  		l0.36,0.1l0.44,0.32l0.1-0.17l-0.15-0.6l-0.4-0.61l-1.16-0.97l0.15-0.58l-0.53-0.53l-1.54,0.09l0,0.41l-0.64,0.27l-0.52,0.19
  		l-0.98-0.03l-0.55,0.86l0.1,0.21l0.43,0.39l1.4,0.43l0.87-0.97l0.82-0.03l-0.24,0.33l-1.16,0.82l0.45,0.54l1.93,0.51l0.66,0.01
  		l0.66-0.21l-0.37-0.37L2032.8,973.73z M2019.39,990l0.13,0.16l0.22,0.01l0.04-1.01l-0.31-0.15l-0.09,0.61L2019.39,990z
  		 M2046.98,999.46l-2.16,0.78l-0.84,0.66l-0.34,0.61l-0.49,0.4l0.02,0.29l0.33,0.69l-0.35,1.07l0.25,0.44l0.21,0.03l0.61-0.2
  		l1.03-1.13l1.03-0.17l-0.17-0.62l0.64-0.77l0.72-1.54l0.01-0.61L2046.98,999.46z M2038.43,1007.1l0.42-0.81l0.14-1.01l-0.55,0.3
  		l-0.02,0.6l-0.72,1.23l0.38,0.83l-0.14,0.52l0.33,0.45l0.32-0.54l0.48-0.13l-0.21-0.77L2038.43,1007.1z M2036.43,994.47l0.84-0.13
  		l0.13-0.16l0.05-0.25l-0.2-0.08l-0.42-0.41l-0.66-0.34l0.41,0.61L2036.43,994.47z M2038.92,994.92l-1.3,0.56l-0.89,0.64l-0.31,0.47
  		l0.26-0.01l0.71-0.42l0.99-0.07l0.36-0.3l1.34-0.58l-0.73-0.35L2038.92,994.92z M2014.54,987.38l-0.36,0.74l0.1,0.71l0.56-0.47
  		l0.38-0.7l-0.18-0.33L2014.54,987.38z M2015.94,992.87l0.31-0.15l0.1-0.41l-0.06-0.24l-0.38-0.17l-0.36,0.23l-0.14,0.53
  		L2015.94,992.87z M2016.37,996.78l-0.01-0.57l-0.64,0.16l-0.09,0.18l-0.21,0.15l-0.57-0.3l-0.11,0.21l-0.16,0.65l1.72-0.27
  		L2016.37,996.78z M2015.47,989.34l-0.31,0.24l-0.21,0.3l0.24,0.35l-0.16,0.67l0.48-0.29l0.38-0.62l-0.28-0.4L2015.47,989.34z
  		 M2018.36,957.35l-0.46-0.56l-0.81-0.07l-0.32,0.24l-0.58,0.81l-0.01,0.4l1.1,0.67l1.08-0.36l-0.05-0.44l0.15-0.45L2018.36,957.35z
  		 M2016.74,975.66l-0.14-0.62l-0.66-0.37l-0.1,0.18l-0.1,0.72l0.89,0.55l-0.02,0.13l-0.2,0.3l0.24,0.04l0.89-0.24L2016.74,975.66z
  		 M2016.8,983.04l-0.21-0.23l-0.84,0.02l-0.88-0.17l-0.71-0.56l-0.47-1.16l-0.27-1.22l0.2-0.4l-0.1-0.36l-0.38-0.46l-0.23-0.59
  		l-0.24-0.22l-1.86-0.15l-1.6-0.77l-0.44-0.05l-0.94-0.42l-0.45-0.36l-0.48-1.06l-0.87-0.75l-0.46,0.04l-0.95,0.28l-1.34,0.85
  		l-0.98,0.45l0.09,0.22l0.46,0.08l0.79-0.33l0.97,0.28l0.91,0.43l0.94,0.65l0.85,0.77l0.34,0.42l0.4,0.3l0.54,0.29l0.28,1.07
  		l0.91,0.41l1.07,0.01l1.3,0.1l0.01,0.15l0.18,0.36l0.33,0.2l0.35,0.72l0.38,0.38l0.03,0.51l0.16,0.33l0.89,0.56l0.35,0.41
  		l0.48,0.02l0.25,0.11l0.23,0.24l0.25,0.1l0.29-0.1l0.35-0.39L2016.8,983.04z M2011.33,972.5l0.26-0.66l-0.7,0.41l-0.4,0.78
  		l0.39-0.12L2011.33,972.5z M2009.61,973.68l0.38-0.33l-1.57-0.93l0.61,1.11L2009.61,973.68z"/>
    </>,
    GNQ: <>
        <path className="st0" d="M1883.49,1287.22l-0.21-0.03l-0.5,0.19l-0.32,0.56l-0.13,0.74l-0.38,0.82l-0.23,0.12l-0.72,0.14l-0.11,0.23
  		l-0.15,0.76l0.09,0.33l0.25,0.24l1.49,0.4l0.44-0.05l0.5-0.68l0.24-0.8l1.3-1.9l0.04-0.65l-0.34-0.44L1883.49,1287.22z
  		 M1905.17,1309.27l-0.01-1.56l-0.01-1.85l-0.01-1.77l-0.01-1.64l-0.01-1.94l-1.96,0l-2.57,0l-2.43,0l-1.64,0l-2.76,0l-0.92-0.38
  		l-0.28-0.24l-0.06-0.28l-0.04-0.19l-0.21-0.06l-0.18,1.98l0.23,1.18l-0.74,1.16l-0.6,1.43l-0.13,0.44l-0.4,0.21l-0.76,0.88
  		l-0.51,1.16l-0.41,1.31l0.5,0.16l0.54,0.05l0.75,0.5l-0.07,0.19l0.38-0.12l0.34-0.23l0.24-0.04l0.47,0.04l0.24,0.41l0.13,0.23
  		l0.48,0.1l0.39,0.22l0.34-0.06l0.28-0.26l0.41-0.05l1.27,0l1.15,0l2.29,0.01l2.29,0.01l2.29,0.01l1.72,0.01L1905.17,1309.27z"/>
    </>,
    GLP: <>
        <path className="st0" d="M1290.9,1181.12l-0.37-0.29l-0.9-0.25l-0.16,0.13l-0.23,0.34l0.29,2.05l0.41,0.74l0.34,0.12l0.68-0.39
  		l0.22-0.35l-0.09-1.54l0.19-0.38L1290.9,1181.12z M1293.82,1183.57l-0.21,0.09l-0.36,0.36l0.06,0.52l0.21,0.07l0.47-0.03l0.23-0.27
  		l-0.08-0.33L1293.82,1183.57z M1292.94,1180.51l-0.34-0.43l-0.09-0.47l-0.55-0.33l-0.33,0.25l-0.15,0.38l0.24,0.63l-0.33,0.52
  		l0.15,0.62l0.65,0.07l0.99-0.1l1.3-0.22L1292.94,1180.51z"/>
    </>,
    GIN: <>
        <polygon className="st1" points="1744.92,1246.83 1744.68,1246.16 1744.14,1245.5 1742.73,1244.94 1742.7,1244.16 1742.84,1243.32
  		1743.15,1243 1744.19,1242.46 1744.01,1242.18 1743.67,1241.87 1743.01,1241.56 1743.16,1240.54 1743.2,1239.64 1742.64,1239.74
  		1742.06,1239.79 1741.57,1239.51 1741.17,1238.96 1741.09,1237.45 1741.09,1235.7 1741.01,1234.93 1741.17,1234.52
  		1741.67,1234.14 1742.21,1233.64 1742.4,1233.33 1742.54,1232.76 1742.44,1232.35 1742.26,1231.98 1740.37,1231 1740.08,1230.59
  		1739.78,1229.48 1739.59,1228.36 1739.61,1227.71 1739.74,1227.12 1739.69,1226.67 1739.48,1226.32 1738.91,1225.99
  		1738.32,1225.83 1737.57,1226.27 1737.22,1226.35 1736.88,1226.32 1736.71,1226.16 1736.73,1225.94 1737.54,1224.74
  		1737.94,1224.25 1738.42,1223.87 1738.74,1223.66 1738.95,1223.37 1738.96,1223.14 1738.89,1222.97 1738.36,1222.75
  		1737.53,1222.19 1737.09,1222.13 1736.72,1221.88 1736.33,1221.01 1736.15,1220.84 1735.75,1220.75 1735.4,1220.54 1735.41,1219.4
  		1735.43,1218.42 1734.62,1216.84 1734.31,1215.85 1733.91,1214.83 1733.54,1214.35 1732.88,1213.94 1732.08,1213.66
  		1731.37,1213.6 1730.82,1213.7 1730.57,1213.82 1730.58,1214.01 1731.03,1214.66 1731.11,1215.02 1731.03,1215.36 1730.88,1215.6
  		1730.49,1215.62 1729.8,1215.82 1728.95,1216.22 1728.35,1216.55 1727.88,1217.41 1727.55,1217.51 1726.99,1217.41
  		1725.39,1216.78 1724.07,1216.26 1723.16,1215.96 1722.61,1216.15 1722.34,1216.24 1721.55,1216.59 1720.51,1217.85
  		1720.26,1218.26 1720.05,1218.4 1719.77,1218.62 1719.5,1218.63 1719.29,1218.47 1719.22,1218.38 1718.68,1217.47 1718.1,1216.48
  		1717.62,1216.02 1717.02,1216 1716.5,1216.32 1715.97,1216.96 1715.29,1217.55 1714.86,1217.73 1714.48,1217.63 1713.56,1216.88
  		1712.91,1216.35 1712.82,1216.08 1713.06,1215.67 1713.28,1215.05 1713.53,1214.56 1713.77,1214.33 1713.21,1214.22
  		1712.22,1214.15 1710.25,1214.48 1709.57,1214.34 1708.27,1214.39 1707.35,1214.57 1706.18,1214.98 1705.27,1214.88
  		1704.78,1214.55 1704.13,1214.57 1703.4,1214.4 1702.63,1214.09 1701.92,1213.93 1701.15,1213.35 1700.79,1213.24 1700.54,1213.4
  		1700.33,1213.59 1700.11,1213.71 1699.69,1213.6 1699.54,1213.21 1699.67,1212.83 1699.71,1212.54 1699.51,1212.38
  		1699.04,1212.33 1698.29,1212.33 1697.07,1212.21 1696.79,1212.14 1694.07,1212.04 1694.04,1212.73 1694.54,1213.7
  		1694.46,1214.42 1694.25,1215.11 1694.06,1215.38 1693.81,1215.54 1693.05,1215.53 1692.73,1215.67 1692.23,1215.94
  		1692.22,1216.25 1692.62,1216.55 1692.95,1216.97 1693.33,1217.3 1693.99,1217.68 1694.05,1218.11 1694.07,1219.17
  		1694.04,1220.01 1692.18,1220.61 1690.76,1220.72 1689.55,1220.65 1689.03,1220.91 1687.98,1221.53 1686.69,1221.91
  		1686.03,1221.94 1685.72,1222.16 1685.22,1222.81 1683.83,1225.63 1683.37,1226.31 1683,1226.75 1682.93,1227.64 1683.26,1227.9
  		1683.57,1227.91 1684,1226.71 1684.32,1226.51 1684.73,1226.56 1685.25,1226.82 1685.41,1227.41 1685.95,1228.44 1686.08,1228.88
  		1686.79,1228.22 1686.84,1228.49 1686.62,1229.48 1686.65,1230.05 1688.19,1232.6 1690.35,1233.62 1691.06,1233.63
  		1691.41,1233.51 1691.53,1233.73 1691.6,1234.3 1692.17,1234.97 1693.3,1235.66 1693.86,1235.8 1694.21,1235.35 1694.4,1235.31
  		1694.31,1235.96 1694.66,1236.59 1694.67,1237.75 1694.39,1238.62 1695.42,1238.56 1696.53,1239.6 1696.79,1240.1 1696.87,1240.49
  		1697.72,1241.3 1697.94,1241.71 1697.66,1242.48 1697.74,1242.73 1698.24,1242.55 1698.71,1242.63 1699.12,1242.74
  		1699.56,1242.56 1699.97,1242.27 1700.22,1241.91 1700.56,1240.92 1701.63,1240.6 1702.27,1239.99 1702.87,1239.06 1703.14,1238.4
  		1703.39,1238.08 1703.55,1237.79 1703.66,1237.48 1703.93,1237.2 1704.21,1236.5 1704.41,1235.87 1705.03,1235.56 1706.29,1235.3
  		1707.43,1235.76 1709.28,1235.36 1709.38,1234.76 1711.07,1234.75 1713.08,1234.74 1714.75,1234.73 1715.32,1234.89
  		1715.53,1235.33 1716.08,1236.03 1716.65,1236.51 1717.36,1237.56 1718.19,1238.79 1719.09,1239.89 1719.66,1240.49
  		1719.73,1240.7 1719.68,1240.94 1719.4,1241.51 1719.16,1242.11 1719.18,1242.34 1719.35,1242.46 1720.29,1242.65 1720.37,1243.32
  		1720.38,1244.26 1720.83,1245.14 1721.26,1245.78 1721.24,1246.01 1720.18,1247.11 1719.77,1248.2 1719.56,1248.51
  		1719.48,1248.75 1719.69,1248.86 1719.98,1248.79 1720.39,1248.88 1720.78,1248.91 1721.29,1248.52 1722.15,1247.52
  		1722.44,1247.4 1723.09,1247.49 1723.51,1247.46 1724.23,1247.2 1724.65,1247.31 1724.84,1247.66 1724.93,1247.95 1727.12,1247.2
  		1727.31,1247.04 1727.43,1247.07 1727.7,1247.75 1727.86,1247.71 1727.99,1247.51 1728.14,1247.49 1728.31,1247.58 1728.48,1247.9
  		1728.76,1248.18 1729.23,1248.38 1729.53,1248.66 1729.5,1249.38 1729.62,1250.09 1729.82,1250.25 1729.93,1250.67
  		1729.99,1251.13 1730.1,1251.38 1730.18,1251.84 1730.14,1252.34 1730.22,1252.7 1730.57,1253.3 1730.79,1254.07 1730.79,1254.61
  		1730.66,1255.18 1730.43,1255.7 1730.03,1256.26 1729.99,1256.49 1730.23,1256.64 1730.6,1256.67 1730.91,1256.55 1731.68,1256.81
  		1732.09,1257.18 1732.44,1257.65 1732.76,1257.88 1732.91,1258.17 1733.46,1258.09 1734.1,1257.81 1734.23,1257.68
  		1734.42,1257.75 1734.83,1257.78 1735.12,1257.27 1735.35,1256.69 1735.84,1256.06 1736.09,1255.82 1736.15,1255.42
  		1736.18,1254.9 1736.36,1254.44 1736.76,1254.2 1737.21,1254.2 1737.45,1254.29 1737.57,1254.73 1737.92,1255.06 1738.22,1255.29
  		1738.7,1254.92 1739.36,1255.02 1740.37,1255.3 1740.59,1255.02 1741.35,1253.59 1741.33,1253.05 1741.25,1252.69 1741.7,1251.7
  		1742.05,1251.32 1742.24,1250.91 1742.18,1250.35 1741.91,1250.14 1741.56,1250.18 1741.14,1250.05 1740.49,1249.72
  		1740.16,1249.44 1740.26,1248.14 1740.33,1247.73 1740.55,1247.5 1740.91,1247.44 1741.91,1247.4 1742.72,1247.55 1743.43,1247.63
  		1743.81,1247.63 1744.11,1248.02 1744.52,1248.41 1744.88,1248.41 1745.01,1248.12 	"/>
    </>,
    GMB: <>
        <polygon className="st1" points="1693.03,1205.19 1691.98,1204.64 1690.55,1204.7 1690.11,1204.85 1689.44,1205.03 1689.04,1205.11
  		1688.37,1204.98 1687.52,1204.5 1686.98,1204.02 1686.23,1203.79 1685.33,1203.57 1683.9,1202.58 1683.16,1202.4 1682.45,1202.35
  		1681.09,1202.54 1679.77,1203.07 1679.07,1204.27 1677.74,1204.26 1674.93,1204.22 1672.34,1204.18 1670.21,1204.27
  		1670.48,1205.37 1671.23,1206.26 1671.98,1206.34 1673.8,1205.45 1676.21,1205.35 1678.57,1205.01 1679.67,1205.15
  		1679.76,1205.28 1679.39,1205.36 1678.16,1205.35 1676.59,1205.65 1675.06,1205.79 1673.61,1206 1673.36,1206.49 1673.38,1206.86
  		1672.65,1206.77 1671.46,1206.97 1670.25,1206.69 1669.9,1206.23 1669.76,1205.56 1669.3,1205.22 1668.62,1205.65 1667.99,1206.36
  		1668.46,1208 1668.51,1208.72 1669.01,1208.25 1669.48,1207.95 1671.31,1207.93 1673.02,1207.9 1674.66,1207.92 1676.34,1207.94
  		1676.5,1206.5 1677.03,1206.39 1677.83,1206.24 1679.31,1206.06 1680.95,1205.9 1681.3,1205.61 1681.58,1205.14 1681.75,1204.71
  		1682.09,1204.53 1682.55,1204.67 1683.16,1204.9 1683.78,1205.24 1684.5,1205.56 1684.98,1205.77 1686.13,1206.27 1688.09,1206.98
  		1689.71,1207.26 1691.66,1206.74 1693.07,1206.41 1693.25,1205.79 	"/>
    </>,
    GRL: <>
        <path className="st0" d="M1415.61,733.3l0.02,0.27l1.03-0.1l0.95,0.32l0.48,0.69l0.97,0.34l0.97-1.54l0.1-0.77l0.3-0.61l0.11-0.69
  		l-1.59,0.44L1415.61,733.3z M1496.19,667.95l-0.13,1.19l0.43,1.09l1.31-0.01l0.37-0.6l0.29-1.22l-0.8-0.82L1496.19,667.95z
  		 M1356.96,616.76l1.48,0.05l4.84-1.28l1.96-0.32l5.54-1.87l1.05-0.91l0.74-0.82l-0.06-0.48l-0.62-0.41l-0.22-0.33l0.19-0.26
  		l0.07-0.28l-0.05-0.3l-0.23-0.58l-0.29-0.39l-2.97-0.85l-2.8-1.23l-3.13-2.96l-1.63-0.98l-0.66-0.24l-5.32-1.14l-3.07-0.32
  		l-2.82,0.93l-0.68,0.58l-0.25,0.43l-0.13,0.43l0,0.43l0.18,0.72l0.3,0.49l1.02,0.62l2.78,1.05l-0.34,0.27l-2.54-0.63l-1.04,0.24
  		l-0.45,0.72l-0.66,2.83l0.45,0.73l0.52,0.52l0.59,0.3l2,0.5l3.06,0.18l1.17,0.18l0.61,0.29l0.81-0.1l0.35,0.51l0.51,0.23l0.54,0.38
  		l-1.95,0.42l-1.33-0.01l-0.93,0.14l-0.21,0.36l0.52,0.58l0.59,0.41l1.26,0.52l0.92,0.56L1356.96,616.76z M1205.53,483.48l-2.1-0.51
  		l-0.9-0.13l-1.33,0.06l-1.6,0.29l-0.45,0.29l-0.04,0.83l1.01,0.54l2.96,0.7l3-0.16l1.69-0.51l0.28-0.73l-1-0.16L1205.53,483.48z
  		 M1341.69,565.86l0.36-0.06l2.11-1.85l0.97-0.62l1.19-1.09l-0.14-0.47l-1.46-0.34l-0.24,0.27l-1.63,0.58l-0.74,0.13l-0.49,0.01
  		l-0.78-0.21l-2.75,0.18l-0.71,0.47l-1.15,0.55l0.62,0.81l0.83,0.19l0.9-0.19l0.56,0.1l0.35,0.16l0.11,0.25l0.28,0.31l0.8,0.12
  		l0.44,0.49L1341.69,565.86z M1646.56,397.59l2.85,1.84l2.22,0.26l0.87-0.66l-1.25-2.1l-2.14-2.04l-2.09-1.52l-1.52,0.13l-0.98,0.79
  		l0.14,1.78L1646.56,397.59z M1415.93,388.72l4.98,1.46l5.65,2.18l3.57,2.18l1.71-0.36l0.74-3.27l0.22-3.28l-1.41-1.65l-4.16-2.02
  		l-8.32-2.4l-3.27,0.55l-4.09,0.18l-0.67,1.21l0.37,1.37L1415.93,388.72z M1660.1,523.44l-1.04-1.05l-0.3-1.65l-0.67-1.8l-1.93,0.6
  		l-1.86,0.75l-1.56-1.05l-1.86,1.2l-0.22,2.1l-0.07,2.09l1.86,1.19l2.67-0.15l6.46,0.3l1.63-0.75l-0.89-1.94L1660.1,523.44z
  		 M1658.91,475.69l-2.28,2.69l-0.39,0.83l0.61,0.47h1.64l1.89-1.16l0.74-1.38l-0.34-1.38l-1.11-0.29L1658.91,475.69z M1380.9,609.33
  		l1.33-0.62l0.48-0.47l0.16-0.29l-2.2-0.91l-1.3-0.23l-0.96,0.15l-1.19,0.89l0.26,0.76l-0.17,1.08l0.2,0.87l0.68,1.83l0.27,0.4
  		l0.27,0.12l1.32-0.53l0.36-0.46l-0.06-0.52l0.09-0.7L1380.9,609.33z M1358.01,589.88l0.79-0.1l0.4-0.24l0.27-0.41l0.16-0.5
  		l0.04-0.59l-0.08-0.5l-0.6-0.99l-0.61-0.74l-0.98,0.22l-1.36,1.17l-0.72,0.8l-0.09,0.43l0.14,0.36l0.37,0.3L1358.01,589.88z
  		 M1653.19,508.01l-0.67-6.3l-0.59-4.18l-1.26-1.09l-1.49,0.16l-0.22,2.02v2.63l3.27,7.22L1653.19,508.01z M1574.38,596.32
  		l-0.24,0.31l-0.58,1.23l0.01,0.42l0.27,0.3l0.88,0.2l1.75-0.38l4.93-0.81l4.21-0.33l2.24,0.64l0.55,0.87l0.47,0l1.42-0.84
  		l1.17-0.98l0.93-0.4l3.36-0.82l0.47-0.62l-0.04-0.32l-0.24-0.4l-0.74-0.6l0.4-0.23l0.16-0.26l0.06-0.36l-0.03-0.43l-0.3-0.91
  		l-0.22-0.33l-1.3-0.52l-0.41-0.33l-0.56-0.68l-0.78-0.02l-4.37,1.92l-2.39,0.67l-2.98,0.2l-2.22-0.08l-1.25-0.12l-1.93-0.59
  		l-0.77,0.26l-0.29,1.65l0.25,1.18l-0.77,1.08L1574.38,596.32z M1373.34,592.68l1.12-0.04l0.57-0.21l-0.21-0.36l-1.48-0.76
  		l-1.37-0.54l-1.15,0.13l-0.35,0.98l0.03,0.34l0.21,0.18L1373.34,592.68z M1650.22,465.41l-0.59-0.33l-0.89,0.33l-1.71,1.46
  		l0.15,2.92l0.67,2.58l0.74,1.93l1.11-0.64l0.97-2.58l-0.59-1.78v-2.59L1650.22,465.41z M1712.36,406.01l-2.39-1.51l-2.96-1.45
  		l-2.03-0.68l-4.39-0.76l-6.3-1.4l-4.53-0.51l-8.3-0.16l-1.88-0.31l-0.88,0.07l-3.48,0.98l-1.28,0.18l-1.23,0.46l-0.78,0.51
  		l-2.34,2.09l-2.53,1.67l-1.87,1.88l-0.57,0.41l-1.94,0.66l-2.2-0.61l-2.13-0.26l-1.25-0.52l-2.85-0.63l-1.78,0.11l-4.09-0.4
  		l-0.6-2.58l-3.41,0l-3.25,1.53l-6.23,5.08l-1.13,0.71l-2.13,1l-2.59,0.96l-4.06,2.55l-1.33,0.6l-6.2,2.14l-0.79,0.81l-0.45,0.77
  		l-1.22,1.08l-0.72-0.22l0.06-1.15l1.04-1.57l4.21-3.4l1.33-0.78l3.83-2.94l2-1.29l1.85-1.79l2.3-3.31l0.71-1.9l0.19-1.93l0.05-1.62
  		l-0.1-1.32l-0.31-1.02l-1.44-1.74l-2-0.13l-8.32,0.45l-3.17,0.46l-1.38,0.38l-0.64,0.46l-0.58,0.86l-0.52,1.27l-0.69,1.19
  		l-0.87,1.1l-1.18,0.63l-5.53,0.83l-2.49-3.7l-4.71-2.42l-15.98-0.93l-6.68-0.05l-9.1,1.09l-5.26,1.15l-2.25-0.32l-0.64-2.04
  		l0.97-1.57l1.63-0.62l48.15-2.6l6.27-0.78l5.44-1.24l4.03-1.62l3.19-1.77l0.8-0.99l-0.52-0.81l-0.92-1.01l-1.92-0.71l-5.1-1.53
  		l-7.43-0.85l-2.42,0.22l-1.17-0.41l-0.72-0.98l-2.14-0.17l-2.5,0.33l-3.16-2.98l-2.34-2.99l-8.57,1.34l-7.25,0.62l-6.2-0.21
  		l-3.48,0.29l-8.62-0.81l-6.64-0.18l-3.56,0.36l-9.51,2.16l-2.71,0.29l-1.65-0.12l0.34-2.16l3.86-0.08l12.15-1.45l21.23-0.75
  		l11.7-0.61l2.28-0.34l0.99-0.5l-1.28-0.61l-9.16-1.86l-5.51-0.72l-5.8-0.57l-0.9,0.05l-4.28-1.5l-8.09-1.29l-6.32-0.62l-19.22-0.13
  		l-3.49,0.48l-3.7,1.02l-2.48,0.03l-2.5-0.62l-2.02-0.29l-2.31,0.06l-1.89,0.49l-2.41,0.15l-9.72,0.04l-0.32,0.17l-0.23,0.41
  		l-0.15,0.64l-0.97,0.3l-2.68-0.06l-3.06-0.66l-1.99,0.03l-0.88,0.26l-1.12,1.03l-0.93,0.55l-0.98,0.21l-2.98-0.27l-0.9,0.29
  		l-0.85,0.65l0.01,0.82l0.88,0.99l0.87,0.6l4.61,1.56l0.49,0.52l-0.67,1.4l-0.19,1.03l-0.52,0.69l-0.49,0.32l-1.03-0.01l-5.5-3.74
  		l-3.24-0.61l-2.29-1.1l-2.51-0.92l-3.96-0.71l-2.8,1.22l-2.44,1.92l-2.7,1.79l-1.87-0.55l-1.36-0.07l-1.15-0.38l-1.98-1.22
  		l-1.73-0.57l-4.35-0.57l-1.97-0.12l-1.56,0.2l-8.45,2.3l-3.87,0.38l-3.91,1.07l-2.47,1.29l-4.16-0.92l-2.19-0.05l-2.6,2.37
  		l2.88,1.96l2.22,0.08l4.8-0.21l2.32-0.43l2.24,0.04l4.4,0.56l23.62,1.64l0.55,0.6l0.1,0.94l-4.37,0.52l-0.84-0.18l-0.66-0.43
  		l-1.5-0.33l-3.52-0.33l-20.35-0.91l-2.46,0.29l-1.66,0.5l0.03,0.46l8.21,3.82l2.11,1.48l0.74,2.15l-0.79,1.19l-1.8,1.05l-0.76,3.21
  		l0.08,1.61l0.31,1.42l0.54,1.22l-0.05,0.98l-0.63,0.73l-1.03,0.67l-1.36-0.17l-3.37-0.83l-11.17-5.49l-6.23-1.58l-12.67-4.79
  		l-9.9-1.39l-6.59-0.03l-1.44,0.29l0.46,1.61l1.87,3.01l2.68,2.4l5.9,3.05l1.29,1.11l-0.91,0.41l-1.84,0.1l-4.15-0.33l-4.5,0.28
  		l-3.85-2.67l-3.39-1.08l-8.6-5.02h-2.08l-0.74,1.46l0.07,2.73l1.49,1.65l-0.36,1.46l-0.61,1.96l-0.87,1.45l-1.14,0.94l-1.26,1.32
  		l-1.06,0.71l-0.3-0.48l-0.04-1.24l0.11-6.61l-0.75-2.11l-1.53-1.5l-1.13-0.88l-2.44-0.97l-2.29-0.51l-1.49-0.02l-5.2,1.07
  		l-1.2,0.35l-0.53,0.77l-5.59,0.51l-3.18-0.12l-9.5,1.21l-8.43,1.55l-4.59,1.77l-0.05,0.5l3.8,1.26l2.09,1.27l2.85,2.13l3.18,1.86
  		l5.77,2.63l2.6,1.46l1.7,1.32l0.36,0.63l-0.97-0.05l-1.11-0.34l-1.86-0.95l-3.55-2.22l-2.4-1.05l-2.44-0.62l-2.95-1.37l-4.44-2.75
  		l-2.74-1.2l-3.04-0.38l-2.19-0.62l-1.66-0.09l-2.8,0.35l-3.46,1.32l-1.45,0.93l-1.58,1.28l-0.28,1.06l0.5,1.27l0.38,2.01l0.26,2.75
  		l-0.52,2.3l-1.3,1.86l-1,1.1l-0.7,0.33l-0.98,0.02l-1.89-0.44l-1.59-0.7l-2.1-0.43l-3.14-0.39l-1.95-0.08l-0.76,0.23l-1.85,1.28
  		l-0.19,1.19l1.18,2.89l0.56,0.96l-0.25,0.08l-3.23-2.55l-1.15-0.58l-1.21-0.28l-1.43,0.02l-5.26,1.12l-1.5,0.67l-3.1,2.57
  		l-2.49,1.38l-2.42,1.61l-1.31,0.5l-1.37,0.21l-1.45,0.47l-1.99,0.8l-2,1.07l-3.25,2.28l-0.46,0.56l-1.2,2.04l-0.07,1.13l0.51,1.08
  		l0.68,0.84l0.85,0.6l0.98,0.31l3.33-0.08l1.32,0.15l2.61,0.83l1.44,0.1l2.17-0.46l1.33-0.57l1.46-0.16l2.02,0.07l2.08-0.43
  		l1.61-0.63l0.88-0.1l0.95,0.16l1.02,0.42l0.22,0.25l-2.41,0.53l-1.4,0.6l-1.35,0.77l-0.39,0.61l-0.55,1.69l-0.71,2.77l-1.08,2.83
  		l-1.44,2.89l-1.11,1.84l-1.18,1.21l-2.24,1.94l-1.2,0.78l-0.91,0.27l-1.41,0.01l-2.87-0.37l-6.49,0.27l-1.08,0.12l-1.9,0.69
  		l-1.35,0.23l-1.68,0.04l-2.61,0.53l-3.81,1.09l-1.69,0.69l0.16,0.37l0.74,0.77l-0.05,0.28l-0.54,0.18l-8.25,1.49l-3.71,0.98
  		l-1.78,0.65l-1.08,0.64l-1.28,0.32l-4.12-0.08l-1.02,0.07l-1.14,0.29l-3.14,1.3l-3.13,0.9l-0.65,0.41l-1.75,1.52l-0.3,0.69
  		l0.36,0.5l0.85,0.67l-0.09,0.36l-1.99,1.56l0.22,0.72l1.73,1.28l2.86,1.73l0.75,0.61l0.79,0.37l3.5,0.67l1.16,0.44l1.03,0.79
  		l1,0.34l2.34,0.39l2.23-0.02l0.97-0.13l1.69-0.78l2.51,0.03l0.28,0.18l-1.73,0.6l-2.32,1.47l0.22,0.32l1.83,0.17l1.62,0.95
  		l0.07,0.98l1.19,0.65l5.27,1.45l1.28,0.08l0.92-0.16l0.98-0.37l1.02-0.65l1.06-0.93l0.9-0.38l0.74,0.16l2.03,0.88l1.3,0.25
  		l1.35,0.21l2.44-0.09l1.46-0.35l3.09-1.65l1.49-0.66l1.24-0.29l1.11,0.1l2.07,1.19l1.17,0.92l0.34,0.89l-0.5,0.85l-1.08,1.35
  		l0.05,0.78l0.64,0.93l-0.15,0.31l-2.66-1.04l-1.96-0.47l-4.18-0.37l-5.91,0.09l-3.84,0.67l-1.31,0.64l-1.94,2l-5.71-0.6l-7.98,0.63
  		l-2.18,0.33l-0.8,0.38l-0.82,0.6l-0.84,0.83l0.11,0.8l1.06,0.78l2.36,0.99l-0.31,0.22l-0.16,0.35l-0.02,0.49l0.5,0.44l1.02,0.4
  		l1.45,0.26l1.83-0.84l4.47-2.39l-0.15,0.36l-0.5,0.67l-0.85,0.97l-0.13,0.88l0.59,0.78l0.6,0.54l0.62,0.29l3.55,0.88l4.08,0.32
  		l4.58-0.17l0.92,0.48l-0.28,0.26l-0.83,0.33l-3.5,0.53l-1.72,0.44l-4.5,2.2l-0.71,0.65l0.2,0.48l0.74,0.7l2.23,0.89l2.9,1.64
  		l1.71,0.63l2.05,1.03l1.42,0.41l11.14,1.7l1.27-0.15l-1.51-1.26l-1.69-1.77l-0.2-0.75l0.73-0.32l1-0.09l1.8,0.9l0.9,0.35l0.74,0.12
  		l0.88-0.27l1.92-1.13l1.52-0.38l0.66,0.06l0.76,0.4l0.86,0.74l0.92,0.5l0.98,0.25l0.73-0.01l0.48-0.28l1.9-0.09l1.48-0.37
  		l1.85-0.76l1.25-0.64l1.31-0.89l0.67-0.22l0.71,0.23l0.74,0.68l1.47,0.97l0.99-0.14l1.86-1.06l1.54-1.08l1.24-0.22l2.4,0.58l1.54,1
  		l0.68,0.16l2.08-0.14l3.36,0.32l4.01,0.99l2.07,0.1l1.57,0.38l2.64,1.06l5.91,1.8l3.83,1.68l2.29,0.65l1.53,0.68l1.53,0.93
  		l1.69,0.59l1.84,0.24l1.24,0.47l1.14,1.32l1.11,1.8l-0.27,0.59l-2.71,1.48l0.32,0.56l2.63,1.26l0.62,0.54l0.6,0.72l1.19,1.69
  		l1.3,1.1l3.78,1.6l1.46,0.87l1,0.9l0.73,0.9l0.45,0.9l0.52,0.64l0.59,0.39l2.35,0.96l2.25,1.46l-0.8,0.61l-0.8,0.07l-1.77,0.48
  		l-0.52,0.47l0.53,0.85l-0.08,0.81l0.21,0.85l-0.57,0.98l-1.95,0.4l-1.47,0.59l-1,0.57l0.33,0.12l1.99-0.21l2.52-0.45l1.36-0.39
  		l0.85,0.02l0.79,0.29l0.62,0.57l0.85,1.49l0.49,0.52l0.44,0.72l0.13,0.3l0.02,0.25l0.56,0.58l0.48,1.02l0.29,1.21l-0.5,0.13
  		l-0.6-0.13l-0.54,1.46l-0.42,0.7l-0.22,0.6l0.04,0.53l0.94,0.35l0.98,0.53l0.75,0.72l0.25,0.53l0.16,0.72l0.7-0.25l1.77-1
  		l0.96,1.03l0.37,1.14l-0.07,1.05l-0.52,0.96l-0.79,0.66l-1.18,0.35l-0.85,0.44l0.02,0.95l0.19,0.75l0.29,0.27l0.75,0.1l0.72,0.33
  		l0.73,0.13l0.7,0.37l0.76-0.08l0.54-0.36l0.51-0.88l1.74,0.78l0.78,0.79l0.3,0.73l-0.19,0.66l0.03,0.65l0.24,0.65l-0.1,0.81
  		l-0.42,0.94l-1.13,1.11l-1.02,0.6l-0.64,0.56l-2.82-0.06l-1.22,0.79l0.28,0.26l1.19,0.27l1.11,1.04l-0.69,0.69l-1.78-0.12
  		l-0.39,0.29l-0.2,1.23l0.66,0.69l2.2-0.33l3.57-3.09l0.47,0.24l0,0.37l-0.27,0.49l-0.83,0.91l-2.9,2.5l-1.15,2.41l-0.82,2.98
  		l-0.68,0.46l-0.33,0.74l0.01,1.01l0.62,1.14l1.23,1.27l0.94,0.7l2.37,0.28l2,0.52l1.09,0.13l3.13-0.27l1.22-0.51l0.62-0.02
  		l1.15-0.63l0.43-1.04l-0.36-1.04l-0.55-1.02l0.47,0.03l0.59,0.21l0.96-0.57l0.17-0.62l0.37-0.62l1.24-0.75l0.77-0.7l0.13-0.69
  		l-0.43-1.3l-0.99-1.19l-0.61-0.45l-0.76-0.85l-0.29-0.78l-0.17-0.89l-0.28-0.72l-0.39-0.54l0.23-0.36l2.09-0.33l-0.17,0.18
  		l-0.88,0.4l-0.29,0.53l0.99,2.11l0.45,0.57l0.54,0.4l1.31,1.55l0.39,1.01l0.15,1.02l-0.03,0.81l0.21,0.47l1.59-0.28l0.05,0.51
  		l-0.93,1.02l-0.46,0.38l-1.44,0.72l0.09,0.52l0.21,0.43l1.31,0.61l0.98,0.06l2.13-1.03l1.57-0.95l0.6-0.15l3.88,0.66l0.96-0.11
  		l1.43-0.51l1.12-0.21l0.07,0.18l-1.66,1.14l-6.59,1.52l-1.2,0.68l-0.38,0.7l-0.55,0.67l-0.72,0.27l-0.25,0.62l0.92,2.06l0.93,0.14
  		l1.02-0.05l2.03-0.41l0.99,0.17l1.54,0.65l1.45,0.4l2.27-0.13l3.5,0.17l1.68,1.17l1.34,0.65l-0.1,0.23l-0.84,0.22l-1.15-0.08
  		l-2.2-0.57l-1.03-0.08l-1.05,0.13l0.18,0.28l2.18,1.13l0.82,0.24l1.17,0.77l-0.22,1.3l-0.32,0.39l-0.16,0.85l0.14,1.51l1.26,0.91
  		l2.03,1.15l1.73,0.24l0.54,0.31l-0.16,0.32l-1.6,0.49l-0.63,0.02l-3.91-1.04l-0.95-0.12l-2.18-0.97l-5.23-2.81l-1.9-0.66
  		l-1.44-0.32l-2.44-0.29l-2.4,0.13l-1.15-0.09l-1.53-0.45l-1.39-0.21l-2.58-0.16l-1.5,0.47l-0.8,0.58l-0.78,0.8l0.25,0.65l1.33,1.31
  		l1.75,1.57l1.02,0.71l2.07,0.5l3.46,0.54l2.82,0.78l2.17,1.03l1.63,0.94l1.98,1.42l0.69,0.29l5.53,0.82l0.83,0.26l0.68-0.03
  		l1.93-0.95l0.71-0.08l1.12,0.26l1.43,0.55l1.62-0.18l1.46-0.37l0.95,0.18l0.26,0.19l-0.39,0.31l-1.03,0.42l-0.33,0.46l1.32,1.67
  		l-0.05,0.44l-0.92,0.4l-2.2,0.67l-0.71,0.93l-0.05,0.96l-0.54,1.87l-0.14,0.93l-1.39,2.04l-0.16,0.97l1.9,0.05l1.52-0.42l1.13-0.2
  		l0.65,0.63l1.35,0.3l0.01,0.22l-0.8,0.49l-3.37,0.3l-2.46-0.17l-0.29,0.56l-0.31,2.25l-0.79,2.93h0.85l1-0.24l1.87-0.89l0.06,0.38
  		l-1.22,1.6l-1.05,0.93l-0.53,0.3l-2.91,0.75l-1.22,0.18l-1.33-0.2l-4.4-2.25l-2.54-0.11l-2.44,0.69l-1.23,0.74l-1.46,2.9
  		l-1.05,0.89l-0.39,0.8l0.79,0.06l0.99-0.14l3.31-0.1l0.28,0.46l0.41,0.25l2.69,0.63l1.52-0.03l3.32-0.45l1.45-0.31l1.33-1.35
  		l-0.03-0.27l0.18-0.14l1.37-0.33l0.7-0.05l0.35,0.51l-0.32,0.87l-1.05,1.22l-0.52,0.35l-0.18,0.29l-0.15,0.8l-0.2,0.39l-0.52,0.57
  		l-0.66,0.32l-1.54-0.03l-2.35-0.27l-3.18-1.02l-3.82-0.86l-1.27-0.19l-0.93,0.15l-0.5,1.32l-1.19,2.13l-1.9,1.93l-0.32,1.01l0,0.74
  		l-0.22,0.68l-0.78,1.72l1.11,0.18l1.56-0.55l3.7-2.64l0.68-0.22l1.9-0.31l1.07-0.33l1.7-0.27l2.03,0.84l1.35,0.2l1.5,0.39
  		l2.88-0.24l0.86-0.46l2.97-0.29l0.69,0.33l-1.22,0.57l-1.14,0.15l-0.03,0.58l4.47,1.94l0.22,0.44l-0.77,0.27l-4.01-1.84l-2.27-0.45
  		l-1.81,0.31l-2.06-0.25l-4-1.28l-1.08-0.13l-1.3,0.17l-2.55,0.9l-2.14,1.47l-1.6,0.87l-1.13,0.38l-2.11,1.15l-0.06,1.31l-0.67,2.73
  		l1.66,2.12l1.07,0.58l0.98,0.3l0.59-0.1l1.23,0.18l2.7,0.18l2.92-0.03l1.1,0.16l0.36,0.23l-0.38,0.3l-0.5,0.14l-0.94-0.04
  		l-3.67,0.37l-0.64,1.03l-0.91,0.46l-1.65,1.03l-0.14,0.37l-0.34,0.54l-0.8,0.99l-0.54,1.4l0.1,0.98l-0.21,0.99l0.28,1.67l0.64,0.21
  		l1.06-0.29l2.16-0.25l1.01-0.33l0.96-0.56l0.91-0.78l1.17-0.82l2.14-1.29l5.04-3.54l1.48-0.79l2.07-1.41l1.02-0.53l0.47,0.13
  		l-0.28,0.57l-2.18,1.55l-1.34,0.69l-1.23,0.45l-0.58,0.4l-0.35,0.5l-1.04,1.14l-0.86,0.52l-1.13,0.46l-1.84,1.06l-4.27,2.69
  		l-2.86,1.37l-0.3,0.35l0.4,0.2l0.61,0.66l0.24,0.12l1.16,0.03l-1.07,2.87l0.29,2.45l0.39,0.27l1.33,0.12l1.97-0.34l1.77,1.79
  		l1.71,0l2.64-1.5l0.94-0.66l1.21-1.2l0.48-0.28l2.78-0.77l2.08-0.09l0.46,0.14l0.01,0.34l-1.37,0.06l-3.08,0.46l-0.86,0.6
  		l-2.1,1.93l-1.76,1.23l-2.37,1.09l-0.38,0.2l-0.27,0.27l0.32,0.74l0.42,0.74l0.02,0.22l1.59,0.69l0.2,1.3l0.94,3.63l0.3,1.55
  		l-0.07,1.15l0.03,2.46l0.25,0.95l0.54,1.21l0.64,0.51l0.74-0.18l0.65-0.65l0.69-1.31l1.74-2.37l0.59-0.97l0.31-1.07l0.91-0.77
  		l0.98-0.38l-0.27,1.06l-0.42,1.08l1.95-0.5l0.83-0.41l0.93-0.69l0.49-0.7l-0.36-0.89l-0.74-2.21l-0.52-1.01l-0.4-1.43l0.31,0.06
  		l0.59,1.14l0.35,0.85l1.1,1.7l0.03,0.58l0.25,0.44l1.1,1.19l1.84-0.16l0.67,0.34l0.83,0.68l0.24,1.62l0.65,1.05l0.05,0.82
  		l-1.26-0.57l-0.93-1.7l-0.73-0.92l-1.15-0.14l-1.62,0.2l-1.39,0.46l-0.07,0.38l0.19,0.79l-0.6-0.12l-1.71-0.07l-1.02,0.17
  		l-1.45,1.32l-1.1,2.02l-1.47,1.48l0.22,0.54l0.82,0.83l0.36,0.08l0.46-0.08l0.81-0.29l0.37,0.03l2.31-0.48l2.96-0.87l2-1.1
  		l0.39-0.11l-0.18,0.64l-0.28,0.49l0.05,0.28l0.76,0.08l1.14-0.15l-0.68,0.59l-2.05,0.11l-0.96,0.18l-1.67,0.59l-3.22,0.71
  		l-2.25,0.63l0.81,1.36l-0.73,1.97l0.58,1.55l2.37,2.75l1.47,2.38l1.03,1.2l0.73,1.01l0.51,0.52l1.18,0.68l0.27,0.38l0.59,0.35
  		l0.79,1.27l0.15,0.35l0.44-0.09l2.07-1.95l2.52-0.9l-2.38,1.86l-1.08,1.25l-0.8,1.14l0.36,0.56l-0.03,1.34l-0.18,0.62l-0.32,0.76
  		l0.29,0.09l0.89,0.72l0.92,0.61l1.07,0.52l1.16,0.5l1.02,0.17l1.11,0.53l-0.96,1.07l-0.13,0.76l0.16,0.98l0.34,0.24l2.32-0.15
  		l0.59-0.46l0.64-0.7l0.69-0.17l0.95,0.06l1.51,0.37l-1.78,0.52l-0.26,0.31l-0.5,0.29l-1.55,0.71l-0.56,0.63l0.15,0.67l0.49,0.86
  		l-0.06,0.32l0.39,0.49l0.61,0.32l-0.25,0.69l-0.56,0.55l0.71,0.53l1.31,0.32l0.52,1.23l0.19,0.99l0.36,0.96l2.74,0.39l0.33,0.17
  		l0.53,0.12l0.56,0.48l0.03,0.2l0.39,0.43l-0.07,1.71l1.62-0.1l0.4,0.17l2.42-0.21l0.75,0.23l-1.14,0.67l-2.52,1.15l-0.31,0.63
  		l0.51,0.48l0.62,0.34l0.79,0.26l1.57-0.04l0.83-0.06l-0.5-0.9l-0.06-0.37l0.75,0.02l1.08-0.26l0.96,0.06l0.8,0.54l1.22,0.22
  		l0.84-0.36l1.22-0.12l0.89,0.05l0.58-0.56l0.74-0.57l1.14-0.73l2.41-0.77l2.4-0.95l0.35-0.41l-0.04-0.6l0.28-0.38l0.61-0.16
  		l0.18,0.48l-0.26,1.12l-0.45,0.84l-0.72,0.72l-1.04,2.49l0.8,2.05l0.59,0.2l0.36,0.26l2.01,0.48l0.88,0.29l1.36,0.64l0.41,0.3
  		l0.82-0.12l1.69-0.67l2.75-2l0.12,0.12l-0.94,1.57l-1.02,0.95l-0.91,0.52l-1.01,0.42l-1.39,0.12l0.05,0.98l-0.14,1.18l4.78,1.93
  		l1.68,0.42l0.67-0.16l0.48-0.83l1.08-1.38l1.04-0.87l0.41,0.37l-0.46,0.81l-1.46,1.51l-0.41,0.58l0.34,1.16l0.25,0.3l0.44-0.03
  		l0.52,0.11l0.9-0.3l0.81-0.1l-0.34,0.59l-0.1,0.58l1.77,0.21l0.98-0.38l0.7-0.04l0.41-0.12l-0.61-0.57l-1.75-1.14l-0.15-0.39
  		l2.41,0.84l0.43,0.28l2.5,0.11l0.72-0.8l0.94-0.88l-0.36-2.55l0-0.48l0.07-0.4l-0.48-0.73l-0.7-0.69l-2-0.36l-1.12-0.38l-1.4-0.24
  		l-0.91-0.58l0.14-0.36l1.1,0.01l1.63,0.24l2.11,0.71l1.34,0.16l0.25-0.12l0.98-0.09l2.75-3.1l0.6-3.79l1.28-3.84l0.63-2.25
  		l0.6-1.04l0.2-0.83l-2.2-0.46l0.46-0.49l1.39-0.25l0.97,0.04l1.17-1.11l-0.37-1.25l0.09-0.78l-0.78-0.59l-0.72-1.22l0.62-1.78
  		l0.13-0.77l0.3-0.65l-0.38-0.9l0.66-0.61l0.1-0.74l-2.65-0.39l-1.74-0.52l-1.53-0.52l-0.73-0.57l0.78-0.09l0.91,0.18l2.67-0.13
  		l0.91,0.21l2.17,0.18l0.7-0.52l0.56-0.04l1.56-2.03l0.67-0.33l0.08-0.75l-2.51-1.06l-1.36-0.85l-0.41-0.46l-0.27-0.76l0.69,0.26
  		l0.61,0.39l1.48,1.18l1.82,0.08l1.52-0.06l0.51,0.09l0.95-0.91l0.67-1.04l0.74-0.86l-0.23-0.47l-0.14-0.53l0.81-0.84l0.06-1.35
  		l1.2,0.08l1.11-0.34l0.03-1.24l1.86-1.33l-0.09-0.5l-0.77-2.22l0.29-2.75l-1.75-0.42l-1.18,0.11l-0.54,0.45l-1.22-0.76l-3.42-1.63
  		l3.4,0.23l0.83,0.2l0.8,0.43l1.71,0.18l0.8-0.61l-0.1-0.85l1.86-0.2l1.68-1.08l0.81-0.76l-0.23-0.77l-0.58-0.81l-1.3-1.05
  		l-0.74-2.08l-1.13-1.23l-1.46,0.51l-1.15,0.13l-0.52-1.62l-0.51-0.66l0.04-0.9l1.72,0.26l1.79-0.37l3.49,0.82l1.9-0.74l0.76-0.54
  		l1.47-1.56l0.93-0.45l0.63-0.73l-0.66-0.39l-4.51-2.12l0.15-0.47l1.79,0l4.61-0.42l2.73-0.34l3.81-0.18l3.65-1.21l0.01-1.38
  		l-0.12-0.38l-1.91-1.16l-0.65-1.23l1.03,0.37l2.17,1.11l0.56-0.96l0.88-2.35l0.85-0.87l0.98-0.82l-1.99-0.86l-1.41-0.88l0.88-0.18
  		l2,0.18l2.06,0.53l2.35,0.34l0.1,0.27l-1.73,1.54l-2.56,3.03l-0.33,0.95l0.25,0.74l-0.38,0.6l-1.34,1.45l0.39,1.05l1.69,0.56
  		l0.76-0.52l1.24,0.03l0.89-0.38l0.68-0.89l0.12-0.97l0.7,0.03l1.44-1.16l0.31,0.42l0.79,0.81l0.93,0.16l0.91-0.33l0.42,0.07
  		l0.24-0.31l0.84-1.79l0.12-0.45l-0.04-0.48l1.16,0.67l-0.09,0.41l0.17,1.38l0.76-0.47l2.06-1.7l1.91-1.01l0.75-0.6l0.84-0.53
  		l-1.06-2.57l-0.48-0.49l-0.19-0.4l-0.23-0.27l-0.05-0.49l1.36,0.95l0.37,0.42l2.11,1.15l1.02-0.1l0.86,0.26l0.96-0.4l3.72-2.19
  		l0.48-0.51l0.44-0.74l0.4-0.97l0.45-0.54l0.92-0.08l0.38,0.15l0.6-0.42l0.81-1l1.86-3.07l2.3-3.3l0.68-1.2l0.25-0.86l-0.17-0.52
  		l0.11-0.33l0.39-0.14l0.92-0.8l0.47-0.62l1.15-2.03l0.41-0.45l0.5-0.3l1.1-0.31l3.95-2.46l0.67-0.17l0.8-0.58l0.93-0.99l0.07-1.05
  		l-0.78-1.1l-0.99-1.08l0.1-0.18l0.61,0l0.49-0.16l0.37-0.31l-0.13-0.5l-0.63-0.69l-0.37-0.71l-0.12-0.73l1.6,0.77l3.33,2.26
  		l2.72,1.48l2.11,0.71l1.6,0.27l1.08-0.17l2.01-0.65l0.04-0.65l-0.89-0.91l-0.07-0.38l3.38,0.84l1.03-0.08l1.22-1.06l0.74-0.39
  		l0.8-0.19l1.31,0.01l2.42,0.31l1.49-0.14l1.36-0.48l1.97-0.41l4.13-1.27l2.01-0.48l2.32-0.21l4.93-1.33l1.56-0.26l2.24-0.77
  		l1.36-0.27l1.44-0.04l1.19-0.39l0.94-0.73l0.76-0.44l1.54-0.53l2.17-1.07l0.61-0.56l0.17-0.39l0.21-0.98l0.31-0.37l2.29-0.68
  		l1.51-1.09l0.1-0.41l-0.91-1.01l0.47-0.17l2.24-0.31l1.06-0.38l2.44-1.32l1.31-0.48l0.63-0.6l-0.05-0.71l-0.21-0.53l-0.37-0.35
  		l0.41-0.07l2.56,0.48l0.74-0.11l0.98-0.35l0.26-0.58l-0.47-0.81l-0.44-0.55l-0.41-0.28l0.46-0.11l2.18,0.05l2.66-0.77l1.58-0.02
  		l0.29-0.18l-0.62-1.18l0.46-0.27l1.79-0.33l0.8-0.34l0.94-0.13l1.51-0.48l1.25-0.72l0.43-0.52l0.24-0.61l-0.66-0.27l-7.49,0.17
  		l-4.16-0.37l-3.15-0.63l-5.96-1.73l-6.58-0.88l-0.8,0.09l-4.47,1.54l-2.19,0.37l-2.83-0.32l-2.32,0.62l-0.98,0.92l-1.09,1.56
  		l-0.93,0.7l-2.06-0.55l0.57-1.46l1.96-1.4l1.05-0.59l1.1-0.39l2.54-0.57l1.63-0.58l0.58-0.6l-0.48-0.62l-0.47-0.39l-0.47-0.17
  		l-0.59-0.02l-7.15,1.05l-3.53,0.07l-4.43-1.14l-0.78-0.01l-2.7,0.47l-1,0.03l0.3-0.25l4.27-1.31l0.95-0.4l2.29-1.26l0.64-0.66
  		l0.39-0.89l0.38-1.27l-0.11-0.86l-1.04-0.45l-1.48-0.38l-0.9-0.67l0.8-0.22l3.49,0.08l1.69,0.13l2.97,0.63l2.26,0.12l2.94-0.09
  		l1.2-0.28l3.52-1.26l1.21-0.66l2.29-1.4l0.63-1.27l-0.27-1.6l-0.49-1.11l-0.72-0.63l-1.95-0.28l-3.18,0.07l-2.4-0.11l-3.11-0.5
  		l-0.47-1.09l0.63-0.38l0.65-0.06l2.71,0.74l4.02-0.1l2.75,0.28l1.93,0.65l1.77,0.92l1.61,1.18l1.87,0.96l2.13,0.74l1.84,0.97
  		l1.56,1.19l0.94,1.55l0.31,1.9l0.83,2.03l1.34,2.17l1.51,1.44l3.91,1.59l1.16,0.13l2.09-0.13l2.12,0.21l0.68-0.85l0.46-3.49
  		l0.2-0.67l0.04-0.66l0.75-0.8l0.13,3.24l0.18,0.56l0.01,0.62l0.13,0.89l0,0.77l2.65-0.14l1.06,0.43l2.68-0.38l0.87-0.88l-0.43-0.98
  		l-0.43-3.28l-0.42-0.79l0.07-0.92l-0.19-2.72l0.16-1.76l-0.23-2.04l-0.46-2.19l-1.76-0.47l-1.75,0.87l-0.54,0.04l-0.55,0.27
  		l-0.41,0.92l-0.59,1.94l-0.52-2.09l-0.08-1.14l-0.12-0.68l0.32-0.38l1.3-0.62l2.51-1.94l0.45-0.88l-2.57-0.14l-0.89-0.25
  		l-1.07-2.26l-0.55-0.23l-2.58-0.67l-0.73-0.45l-2.82-1.29l-2.49-0.93l-1.77-0.96l-3.74-1.75l-1.47-0.59l-1.31-1.14l-0.8-0.27
  		l-1.49,0.75l-2.31,0.69l-0.72-0.73l0.64-0.42l2.45-0.86l1.15-0.53l0.43-1.22l-1.02-4.58l-0.15-0.34l-0.2-0.19l-1.44,0.2l-2.13,0.75
  		l-1.01,0.52l-2.78,0.21l-3.47,1.21l-0.92,0.44l-1.54,0.35l-0.71-0.08l-1.52-0.61l3.81-1.28l1.06,0.02l1.85-0.85l5.1-1.24l0.72-0.9
  		l1.5-0.53l1.72-0.5l1.34,0.1l0.7,1.87l1.59,3.76l2.44,3.01l1.8,0.74l1.53,0.95l3.93,1.05l4.22,1.63l1.76,1.05l1.72,0.6l0.45-1.59
  		l-0.35-1.98l1.73-0.87l0.57-3.77l-0.14-1.37l-0.11-3.17l-1.33-0.75l-2.16-0.34l-3.39-1.58l-1.21-1.42l-2.09-0.35l-1.78-1.07
  		l-2.14-0.94l-1.59-1.32l-1.97-0.18l-3.83-0.22l-3.96,0.43l-1.78,0.56l-1.1,1.4l-5.3,1.25l-1.46,0.09l-1.94,0.35l-2.7,0.82
  		l-2.67-0.26l-1.01,0.07l-1.33,1.04l-0.55,0l-0.59-0.34l-0.42-0.38l-0.24-0.42l0.75-0.35l1.75-0.27l1.71-0.04l1.67,0.19l1.13-0.07
  		l0.6-0.34l0.97-0.92l-0.52-0.49l-4.77-1.54l-0.85-1.01l2.47,0.92l1.78,0.51l3.02,0.57l2.01,0.88l0.9,0.1l2.71-0.4l0.63-0.25
  		l1.81-1.59l1.17-0.66l2.4-0.89l1.98-0.42l0.11-0.47l-1.1-0.66l-3.13-2.61l-1.24-0.89l-0.84-0.77l0.05-0.18l1.43,0.62l2.04,1.31
  		l1.71,1.33l1.02,0.57l0.9,0.26l0.93-0.06l0.97-0.37l0.94-0.72l1.53-1.51l3.34,3.62l4.44,2.37l2.07,0.84l5.4,1.25l1.36-0.01
  		l2.63-1.43l2.74-1.2l1.87-0.41l5.8-0.11l1.07-0.48l0.51-2.62l0.93-2.73l-0.25-0.47l-5.52-1.53l-2.33-0.35l-5.81-0.14l-1.24-0.32
  		l-1.14-0.65l-0.54-0.55l0.05-0.46l0.32-0.56l0.59-0.67l0.2-0.67l-0.18-0.66l-0.39-0.45l-0.6-0.23l0.11-0.27l1.22-0.46l1.72-1
  		l0.44-0.82l-0.53-2.14l0.33,0.03l1.52,1.39l-0.59,2.09l-1.04,1.88l3.15,1.34l3.8,0.87l4.01-0.44l3.56-1.11l-0.22-1.3l1.76,0.01
  		l3.38,0.4l1.51-0.19l0.82-0.24l0.46-0.38l0.37-0.6l0.25-0.96l0.14-1.32l-0.52-1.12l-1.18-0.91l-0.93-0.4l-2.21-3.82l-1.56-2.08
  		l-1.93-0.74l-1.71,0.74l-0.97,2.23l-0.67,1.93l-2.11,1.55l-1.49-0.31l0-0.26l0.57-0.34l0.68-0.76l0.8-1.19l0.08-1.44l-0.63-1.7
  		l-0.56-1.03l-0.49-0.35l-0.6-0.09l-0.71,0.18l-2.67,1.2l-1.18,0.43l-0.82,0.13l-0.75-0.12l-1.01-0.55l-1.63-1.06l-1.14-0.9
  		l3.13,1.35l1.78,0.28l2.02-0.7l1.37-1.16l1.29-0.27l1.58-0.13l3.54-2.67l2.41,0.11l1.45,0.91l1.12,0.85l0.8,0.8l1.03,0.14
  		l1.26-0.52l0.85-0.84l0.43-1.16l0.07-1.5l-0.28-1.85l-0.27-1.24l-0.26-0.63l-0.15-0.7l-0.05-0.77l-0.2-1.17l-0.48-0.65l-2.03-1.77
  		l-1.27-1.72l0.8-2.15l-2.03-1.71l-1.48-0.23l-1.31,0.02l-1.08-0.15l-1.85-0.62l-0.88-0.5l-2.51,0.63l-1.95,0.07l-0.6-0.14
  		l-0.68-0.38l-1.59-1.87l-1-3.03l-1.06-0.26l-2.45-0.24l-0.71-0.19l-0.55-0.23l-1.37-0.98l-0.02-0.42l0.46-0.44l1.85-1.14l1.26-0.01
  		l2.14,0.9l1.53,0.94l1.13,0.04l5.51-2.73l0.15-0.79l3.84-0.6l3.56-0.12l1.68,0.23l3,0.94l2.97,0.43l1.48,0.4l0.97,0.52l1.05,0.3
  		l1.13,0.08l0.8-0.26l0.96-1.45l0.5-1.08l0.29-1.62l0.09-2.15l-0.39-1.47l-0.87-0.79l-1.21-0.42l-2.67,0.05l-1.92,0.85l-1.43,0.19
  		l-1.06-0.42l-1.36-0.87l-1.86-0.68l-3.57-0.65l-1.95-1.42l-1.83-3.09l2.03-0.77l2.33-0.51l1.76,0.43l3.61,1.71l0.48,0.11l1.44-0.35
  		l-0.01-0.65l-0.82-1.03l-0.81-0.73l-1.97-0.87l-2.28-0.66l-2.72-1.06l-2.13-0.54l-2.45-0.37l-2.27,1.18l-2.09,2.72l-1.68,0.84
  		l-1.27-1.03l-0.15-1.49l0.97-1.94l0.98-1.72l1-1.49l0.72-1.84l0.44-2.19l0.55-1.6l2.01-3.24l0.07-0.76l-1.63-0.87l0.06-0.3
  		l4.36-2.7l1.85-0.46l1.65-0.03l1.26-0.21l0.65-0.48l0.72-0.81l0.47-0.9l0.22-0.99l0.31-0.66l0.39-0.33l3.88-1.09l1.58-0.55
  		l0.7-0.49l0.18-0.62l-0.34-0.76l-0.51-0.72l-0.68-0.68l-0.6-0.31l-0.51,0.07l-1.09-0.2l-0.14-0.93l0.27-1.71l0.38-1.5l0.49-1.29
  		l0.1-0.92l-0.58-0.98l-0.32-0.31l-1.06-0.1l-2.71,0.17l-1.23-0.18l-0.7-0.34l-0.59-0.57l-0.36-1.05l-0.14-1.53l0.4-1.42l0.93-1.3
  		l1.45-1.28l2.96-1.88l0.72-0.32l1.88-0.08l1.49,0.27l2.83,0.79l5.24,0.68l2.93-0.08l3.08-0.48l1.4-0.06l1.52,0.26l1.2-0.16
  		l3.2-1.04l2.7-1.51l1.98-1.28l-0.04-0.64l-4.15-1.11l-1.33-0.53l-1.46-1.22l3.72-1.5l2.71,0.16l3.83-0.17l2.93-1.4l5.82-0.83
  		l0.61-0.25l1.61-1.11l0.1-0.75l-0.67-0.85l-1.53-1.19l0.32-0.39l2.15-0.41l3.31-0.09l2.97-0.39l2.74-0.99l5.6-2.89l1.94-1.54
  		l5.92-2.31l0.83-0.66l0.04-0.48L1712.36,406.01z M1657.79,441.2l3.57-2.2l1.78-2.2l-0.59-1.7l-4.31-0.51l-4.75,0.85l-3.79,1.36
  		l-1.19,1.7l0.89,1.52l3.12,1.01L1657.79,441.2z"/>
    </>,
    GHA: <>
        <polygon className="st1" points="1819.68,1267.17 1819.3,1267.09 1818.84,1266.93 1818.54,1266.69 1818.14,1266.13 1818,1265.7
  		1817.38,1265.63 1816.63,1265.14 1815.91,1264.59 1815.66,1264.03 1815.73,1263.77 1815.62,1263.51 1815.37,1263.41
  		1814.72,1262.15 1814.32,1261.65 1814.13,1261.24 1814.19,1260.93 1814.11,1260.5 1814.23,1260.16 1814.58,1259.95 1814.69,1259.7
  		1814.72,1259.17 1814.92,1258.08 1815.05,1257.01 1814.68,1256.72 1814.23,1256.63 1813.99,1256.33 1813.9,1255.82
  		1813.91,1255.39 1814.8,1253.86 1814.62,1250.35 1814.75,1249.81 1815.15,1249.44 1815.5,1249.01 1815.48,1248.58 1814.89,1247.53
  		1813.77,1246.73 1813.2,1246.07 1812.89,1245.49 1812.84,1245.18 1813.52,1244.72 1813.82,1244.4 1813.86,1244.03 1813.58,1243.36
  		1813.63,1242.17 1813.89,1241.28 1814.16,1240.12 1814.13,1239.78 1813.47,1239.09 1813.11,1239 1812.83,1239.05 1812.14,1239.5
  		1811.89,1239.55 1811.74,1239.42 1811.67,1239.23 1811.91,1238.96 1811.82,1238.63 1812.02,1238.33 1812.46,1238.2
  		1812.59,1238.05 1812,1237.91 1811.93,1237.7 1811.97,1237.51 1812.14,1237.47 1812.33,1237.48 1812.43,1237.34 1812.52,1236.36
  		1812.59,1236.02 1812.66,1235.34 1812.76,1232.7 1812.89,1232.43 1812.91,1232.24 1812.5,1232.11 1811.52,1231.4 1810.95,1230.86
  		1810.45,1230.3 1810.03,1229.94 1809.22,1229.37 1808.97,1229.01 1808.94,1228.65 1809.19,1227.93 1809.58,1227.16
  		1809.78,1226.07 1809.66,1225.77 1809.12,1225.26 1807.18,1224.83 1807.07,1225.24 1806.79,1225.5 1806.37,1225.52
  		1806.08,1225.45 1805.88,1225.77 1805.56,1226.18 1805.11,1226.38 1804.67,1226.64 1804.42,1226.86 1804.24,1226.86
  		1803.79,1226.34 1803.2,1226.28 1802.1,1226.37 1801.6,1226.23 1800.92,1226.16 1799.32,1226.27 1796.76,1226.05 1796.34,1226.17
  		1796.23,1226.26 1793.69,1226.29 1790.9,1226.32 1788.57,1226.34 1786.53,1226.36 1786.52,1226.27 1785.87,1226.26
  		1785.79,1226.43 1785.21,1228.55 1785.15,1229.7 1785.46,1230.41 1785.8,1230.86 1786.19,1231.05 1786.23,1231.31 1785.92,1231.64
  		1785.95,1231.97 1786.31,1232.32 1786.4,1232.69 1786.22,1233.08 1786.26,1234 1786.53,1235.47 1786.54,1236.42 1786.28,1236.85
  		1786.4,1237.59 1786.91,1238.64 1787,1239.08 1787.08,1239.5 1786.91,1240.18 1786.95,1240.6 1787.18,1240.76 1787.05,1241.3
  		1786.57,1242.22 1786.57,1242.77 1787.05,1242.93 1787.39,1243.51 1787.59,1244.5 1787.8,1244.83 1787.82,1245.03 1788.17,1247.42
  		1788.6,1249.82 1788.32,1250.13 1787.95,1250.22 1787.7,1250.33 1787.63,1250.55 1787.79,1250.88 1787.69,1251.18 1787.22,1251.39
  		1786.2,1252.15 1786.13,1252.45 1785.86,1253.1 1785.64,1253.49 1785.31,1254.22 1784.78,1256.16 1784.58,1257.77 1784.55,1258.26
  		1784.35,1258.61 1784.12,1259.11 1783.01,1260.48 1782.45,1261.61 1782.52,1262.1 1782.55,1262.59 1782.38,1262.94
  		1782.41,1263.89 1782.55,1264.68 1782.74,1265.46 1783.54,1267.67 1783.96,1269.01 1784.22,1270.09 1784.45,1270.81
  		1784.66,1271.11 1784.75,1271.39 1785.94,1271.59 1786.17,1271.75 1786.5,1273.15 1786.44,1273.79 1786.21,1274.03
  		1786.21,1274.56 1786.16,1275.23 1785.99,1275.5 1785.32,1275.53 1784.87,1275.78 1784.27,1275.68 1783.7,1275.7 1783.47,1276.04
  		1783.74,1276.09 1784.73,1276.39 1786.77,1276.66 1789.5,1277.37 1790.61,1277.84 1792.1,1278.76 1792.84,1278.77 1794.74,1277.78
  		1795.9,1276.94 1797.05,1276.46 1800.74,1275.25 1802.98,1274.88 1804.06,1274.11 1805.61,1273.47 1806.76,1272.58
  		1808.64,1272.01 1811.89,1270.43 1815.36,1270.41 1816.01,1270.4 1817.7,1269.98 1818.19,1269.17 1818.55,1268.44 1819.01,1267.96
  		1819.7,1267.64 	"/>
    </>,
    GUF: <>
        <polygon className="st0" points="1374.65,1284 1374.55,1283.25 1374.27,1282.76 1373.54,1280.38 1373.18,1279.83 1372.74,1279.85
  		1372.41,1280.77 1372.34,1281.51 1372.11,1281.82 1371.71,1282.21 1371.72,1281.93 1371.91,1281.56 1372.05,1280.85
  		1371.63,1279.75 1371.24,1279.15 1369.88,1277.93 1369,1278.7 1369.3,1277.82 1369.28,1277.26 1367.91,1276.6 1365.29,1274.49
  		1364.16,1273.21 1361.03,1272.22 1359.48,1272.05 1356.17,1270.22 1355.56,1270.33 1354.97,1271.11 1354.17,1273.33
  		1353.57,1273.77 1352.86,1274.36 1352.09,1275.21 1351.08,1276.67 1351.12,1277.13 1350.9,1277.5 1350.85,1278.15 1350.92,1278.88
  		1351.18,1279.36 1351.3,1280.28 1351.1,1281.1 1351.18,1281.58 1351.38,1282.33 1351.55,1283.14 1351.53,1283.47 1351.78,1283.73
  		1352.01,1283.99 1351.93,1284.71 1352.73,1285.99 1353.22,1286.55 1353.94,1287.09 1354.2,1287.63 1354.6,1288.27 1354.84,1288.34
  		1354.97,1288.6 1354.84,1289.1 1354.81,1289.78 1354.35,1290.58 1353.3,1292.04 1353.18,1292.38 1353.45,1293.59 1353.3,1294.59
  		1353.24,1295.06 1352.72,1295.94 1351.5,1298.05 1350.8,1298.43 1350.37,1299.04 1350.1,1299.04 1349.79,1299.1 1349.7,1299.18
  		1349.9,1299.28 1350.25,1299.46 1350.55,1299.86 1351.24,1300.17 1352.42,1300.62 1352.97,1300.63 1353.48,1300.76 1353.79,1300.9
  		1354.13,1300.65 1355.34,1299.96 1355.93,1299.58 1356.32,1299.29 1356.62,1299.02 1356.84,1298.94 1356.99,1299.11
  		1357.12,1299.33 1357.55,1299.46 1358.56,1299.72 1359.02,1299.79 1359.67,1299.57 1360.23,1299.2 1360.49,1299.07 1360.9,1299.44
  		1361.19,1299.97 1361.37,1300.2 1361.79,1300.14 1362.62,1300.22 1363.23,1300.39 1363.61,1300.37 1364.12,1300.14 1364.4,1299.68
  		1365.13,1299.26 1365.83,1298.87 1366.23,1298.35 1366.82,1297.48 1367.02,1297.11 1367.06,1296.49 1367.89,1294.68
  		1368.21,1294.34 1368.39,1293.77 1368.73,1293.11 1368.73,1292.55 1368.97,1292.02 1369.45,1291.55 1369.8,1291.26
  		1370.36,1290.49 1370.75,1289.75 1371.74,1288.12 1371.81,1287.66 1372.2,1287.38 1372.33,1287.03 1372.75,1286.6 1373.18,1286.26
  		1373.37,1285.75 1373.69,1285.22 1374.4,1284.83 1374.66,1284.65 1374.61,1284.34 	"/>
    </>,
    GEO: <>
        <polygon className="st1" points="2202.35,951.22 2201.42,950.6 2200.38,950.05 2199.71,949.57 2199.28,948.63 2198.85,948.52
  		2198.74,948.4 2198.67,948.07 2198.69,947.62 2198.84,947.27 2199.26,947.12 2199.68,947.07 2200.07,946.73 2200.55,946.08
  		2200.76,945.72 2200.6,945.58 2199.39,945.02 2198.93,944.72 2198.48,944.7 2197.54,944.53 2196.75,944.26 2196.38,943.91
  		2195.84,943.51 2194.83,943.02 2194.09,942.55 2194.06,942.25 2194.51,941.01 2194.84,939.82 2194.65,939.58 2194.23,939.39
  		2193.45,939.2 2191.61,939.26 2190.47,938.06 2190.06,937.79 2189.32,937.59 2188.24,937.23 2187.63,936.96 2187.45,937.06
  		2186.79,938.38 2186.12,937.44 2185.72,937.18 2185.15,937.04 2184.55,937.04 2183.07,937.5 2181.97,938.01 2181.16,938.38
  		2180.33,938.59 2179.93,938.89 2178.83,938.84 2178.27,938.61 2178.09,938.38 2178.18,937.97 2178.57,937.5 2178.6,937.25
  		2178.46,937.06 2177.12,936.44 2176.57,936.07 2174.8,935.54 2172.62,934.6 2171.87,933.98 2171.8,933.55 2170.94,933.13
  		2169.85,932.76 2169.01,932.86 2168.21,932.9 2166.98,932.2 2165.8,932.16 2164.48,932.37 2164.19,932.45 2163.87,932.55
  		2159.91,932.25 2158.9,931.67 2158.04,931.08 2155.72,930.66 2154.53,930.22 2153.35,929.59 2152.06,929.03 2150.97,929.26
  		2149.48,928.94 2147.87,928.67 2147.31,928.84 2146.8,929.53 2146.42,930.2 2148.21,931.3 2150.49,933 2151.01,933.25
  		2153.65,933.84 2155.54,935.19 2156.11,936.23 2158.56,937.15 2159.14,937.95 2159.89,940.6 2160.61,943.14 2161.45,944.92
  		2161.43,945.78 2161.41,946.46 2160.93,947.59 2159.32,949.48 2159.88,949.68 2160.93,949.94 2161.59,950.25 2161.96,950.33
  		2162.82,949.69 2164.1,949.71 2165.22,949.78 2165.8,949.9 2166.51,950.11 2167.37,950.25 2167.72,949.95 2168.22,949.06
  		2168.42,948.94 2168.56,948.86 2169.19,948.79 2169.8,948.86 2170.08,949.01 2170.37,949.73 2171.08,949.98 2172.35,951.13
  		2173.12,951.59 2173.31,951.78 2173.06,952.01 2173.16,952.29 2173.6,952.67 2174.22,952.81 2174.89,952.76 2175.26,952.89
  		2175.52,953.1 2175.59,953.4 2175.57,953.59 2176.01,953.5 2177.3,953.49 2178.55,953.35 2179.53,953.07 2180.94,952.83
  		2181.52,952.63 2182.21,952.53 2184.27,952.75 2185.05,952.58 2187.38,952.54 2187.44,952.46 2187.12,952.17 2187.12,952.06
  		2188.51,951.88 2188.72,951.75 2190.54,950.42 2191.08,950.16 2192.27,950.4 2194.74,951.28 2194.57,951.77 2194.82,952.04
  		2195.39,952.41 2196.48,952.79 2197.4,952.99 2197.86,952.82 2198.57,952.68 2199.49,953.11 2200.34,953.67 2200.77,953.89
  		2200.99,953.96 2201.64,953.77 2202.41,953.06 2202.71,952.2 2202.8,951.79 	"/>
    </>,
    GRD: <>
        <polygon className="st1" points="1290.37,1215.75 1289.91,1216.19 1289.61,1216.85 1289.56,1217.38 1289.34,1217.69 1289.9,1217.66
  		1290.65,1217.31 1290.82,1215.87 	"/>
    </>,
    GAB: <>
        <polygon className="st1" points="1931.19,1327.88 1931.07,1326.78 1931.36,1325.33 1931.66,1323.82 1931.61,1323.44 1931.19,1322.98
  		1930.68,1322.57 1929.36,1322.22 1928.87,1321.67 1928.48,1321.09 1928.2,1320.91 1926.76,1320.67 1926.44,1320.35
  		1926.57,1319.41 1926.69,1318.02 1926.64,1317.05 1926.9,1316.27 1927.19,1315.69 1927.83,1315.07 1928.17,1314.34
  		1928.35,1314.16 1929.56,1314.03 1930,1313.73 1930.35,1313.42 1930.49,1313.01 1930.91,1312.32 1931.28,1311.86 1931.31,1311.54
  		1931.24,1311.1 1930.87,1310.24 1930.43,1309.52 1930.17,1309.27 1929.64,1307.58 1929.14,1307.18 1928.17,1306.97
  		1926.36,1306.78 1925.27,1307.08 1923.6,1307.65 1922.33,1308.04 1921.5,1308.26 1921.02,1308.2 1920.8,1307.94 1921.12,1307.72
  		1921.28,1307.21 1921.07,1306.48 1920.75,1305.8 1920.56,1304.86 1920.64,1303.68 1920.96,1302.58 1921.62,1301.14
  		1921.67,1300.56 1921.47,1300.03 1921.05,1299.76 1920.3,1299.74 1918.08,1299.85 1916.38,1299.76 1915.84,1299.69
  		1915.23,1299.56 1913.81,1299.43 1912.06,1299.53 1911.66,1299.5 1910.26,1299.52 1907.06,1299.38 1905.28,1299.4 1905.32,1299.72
  		1905.21,1299.95 1905.12,1300.51 1905.13,1302.45 1905.14,1304.09 1905.15,1305.86 1905.16,1307.71 1905.17,1309.27
  		1905.17,1310.28 1903.45,1310.28 1901.16,1310.27 1898.87,1310.26 1896.58,1310.25 1895.43,1310.25 1894.17,1310.25
  		1893.76,1310.3 1893.48,1310.55 1893.14,1310.61 1892.75,1310.39 1892.27,1310.29 1892.15,1310.06 1891.91,1309.65
  		1891.44,1309.61 1891.2,1309.65 1890.86,1309.89 1890.48,1310.01 1890.35,1310.35 1890.78,1312.12 1890.77,1313.36
  		1890.71,1313.82 1890.57,1313.9 1890.19,1313.67 1890.04,1313.13 1889.68,1313.08 1888.28,1313.54 1888.24,1314.03
  		1888.86,1314.57 1889.46,1315.62 1890.11,1316.17 1892.05,1317.04 1893.46,1316.81 1893.94,1317.01 1892.35,1317.6
  		1892.21,1318.28 1891.72,1317.93 1890.34,1317.4 1889.45,1317.31 1888.97,1316.97 1888.76,1316.59 1888.67,1316.08
  		1888.49,1315.77 1888.05,1316.23 1888.24,1317.68 1888.36,1319.13 1888,1321.58 1886.65,1323.44 1886.19,1323.87 1885.82,1323.97
  		1885.46,1323.96 1885.05,1324.41 1884,1324.57 1883.46,1323.79 1883,1323.59 1884.19,1326.29 1884.46,1326.56 1884.74,1327.22
  		1885.01,1327.61 1886.05,1329.51 1887.22,1330.21 1887.69,1330.14 1888,1330.03 1888.18,1329.8 1888.42,1329.73 1887.86,1331.04
  		1887.99,1331.32 1888.29,1331.48 1888.85,1331.45 1889.28,1331.27 1889.72,1331.66 1889.91,1332.02 1888.93,1332.32
  		1888.51,1332.35 1888.19,1332.3 1885.81,1329.6 1885.95,1330.18 1886.83,1331.43 1887.68,1333.09 1887.59,1333.53 1887.74,1333.92
  		1888.39,1333.95 1889.57,1334.5 1889.68,1334.84 1889.57,1335.06 1888.39,1334.49 1888.02,1334.57 1888.62,1335.17
  		1888.89,1335.61 1889.99,1336.75 1890.34,1337.31 1890.48,1337.83 1890.76,1338.45 1891.2,1338.86 1891.98,1338.84
  		1892.75,1339.08 1893.58,1339.48 1894.45,1339.98 1894.22,1340.2 1893.94,1340.31 1891.94,1339.35 1891.59,1339.29 1891.9,1339.72
  		1893.98,1341.64 1896.85,1343.86 1898.86,1346.08 1899.32,1347.08 1901.07,1348.45 1901.9,1349.3 1902.62,1350.67 1903.44,1351.43
  		1903.95,1350.13 1904.32,1349.54 1904.78,1349.12 1906.6,1348.11 1906.87,1348.15 1908.12,1349.46 1908.53,1349.57
  		1908.97,1349.53 1909.5,1349.59 1909.76,1349.33 1909.8,1348.99 1909.42,1348.61 1909.36,1348.2 1909.63,1347.76 1909.78,1347.27
  		1910.18,1346.69 1910.22,1346.42 1909.8,1346.12 1908.96,1345.67 1908.37,1345.23 1908.15,1344.81 1908.31,1344.28
  		1908.78,1343.85 1908.75,1343.61 1908.34,1343.22 1908.04,1342.8 1907.73,1342.54 1906.88,1342.38 1907.04,1341.82 1907.36,1341
  		1907.43,1340.36 1907.19,1338.7 1907.21,1338.4 1907.45,1338.25 1907.96,1338.43 1908.47,1338.68 1909.86,1338.32 1910.35,1338.27
  		1910.76,1338.58 1911.31,1338.83 1914.53,1338.14 1914.59,1337.44 1914.78,1336.8 1914.8,1336.32 1914.67,1336.01 1914.51,1335.78
  		1914.41,1335.3 1914.41,1334.78 1914.72,1334.54 1915.74,1333.93 1916.06,1333.95 1916.78,1334.29 1917.45,1334.81
  		1918.05,1335.91 1918.47,1336.86 1919.13,1338 1920.53,1338.47 1922.2,1338.77 1923.11,1338.69 1924.4,1337.71 1925.14,1336.95
  		1925.37,1336.54 1925.8,1336.75 1926.28,1337.75 1926.59,1338.14 1926.67,1338.51 1926.45,1338.98 1926.66,1339.28
  		1927.56,1339.49 1928.35,1339.29 1928.71,1338.88 1929.3,1338.34 1929.3,1337.9 1928.99,1337.6 1928.99,1337.2 1929.32,1336.89
  		1929.63,1336.02 1929.73,1335.39 1930.05,1334.99 1930.64,1334.71 1930.85,1334.46 1931.18,1332.97 1931.01,1332.43
  		1931.01,1331.98 1931.38,1331.41 1931.45,1330.47 1931.3,1328.93 	"/>
    </>,
    FRA: <>
        <path className="st0" d="M1877.76,871.6l-0.67-0.27l-1.7-0.34l-1.59-0.22l-0.72-0.27l-0.63-0.72l-0.39-0.01l-0.76,0.26l-0.96,0.17
  		l-0.69-0.15l-0.44,0.03l-0.24,0.13l-0.12-0.12l-0.17-0.61l-0.36-0.16l-0.56-0.14l-0.35,0.06l-0.24,0.3l-0.37,0.21l-0.34-0.07
  		l-1.08-1.42l-0.28-0.31l-0.07-0.29l-0.27-0.53l-0.64-0.53l-0.64-0.17l-0.32,0.06l-0.56-0.27l-0.3-0.18l-0.51-0.05l-0.52,0.15
  		l-0.39,0.33l-0.53,0.1l-0.44-0.1l-0.26-0.25l-0.23-0.13l-0.66-0.17l-0.28-0.36l-0.67-0.01l-0.85,0.12l-0.57,0.19l-0.3,0l-0.61-0.48
  		l-0.68-0.71l-0.43-0.34l-0.2-0.29l-0.54-0.12l-0.77-0.35l-0.53-0.38l-0.46-0.24l-0.64-0.12l-0.53,0.01l-0.16-0.65l-0.06-0.74
  		l-0.43-0.49l0.59-1.94l-0.35-0.19l-0.39,0.16l-0.56,0.46l-0.27,0.55l-0.16,0.49l-0.94,0.46l-1.48,0.17l-1.62-0.17l-0.23-0.12
  		l-0.1-0.14l0-0.17l0.11-0.26l0.28-0.32l0.07-0.45l-0.29-0.39l-0.19-0.15l0.07-0.38l0.21-0.48l0.04-0.27l-1.1-0.82l-0.8-0.16
  		l-0.77-0.03l-0.59-0.09l-0.34,0.04l-0.25,0.24l-0.25,0.17l-0.19-0.21l-0.34-1.46l-0.26-0.22l-1-0.24l-1.35-0.09l-0.36-0.27
  		l-0.2-0.66l-0.12-0.79l-0.45-0.76l-0.23-0.19l-0.4-0.34l-0.71,0.14l-0.85,0.44l-0.5,0.12l-0.19,0.05l-0.68-0.43l-0.76-0.67
  		l-0.61-0.72l-0.15-0.4l0.19-0.48l-0.22-0.37l-0.33-0.68l-0.09-0.53l-0.67,0.34l-4.49,0.84l-1.22,0.61l-0.8,0.56l-0.53,0.73
  		l-0.25,0.89l-0.24,4.92l0.35,0.46l-0.37,0.24l-0.29,0.28l-0.9,1.28l-1.36,0.99l-2.71,0.97l-2.59,0.52l-3.62,1.75l-0.51,1.11
  		l-0.14,0.48l0.17,0.54l1.25,0.49l1.36-0.11l-0.19,0.27l-2.36,0.51l-1.24,0.78l-1.28,0.36l-3.01-0.63l-2.06-0.06l-1.63-0.36
  		l-1.51,0.06l-0.48-0.62l-0.31-0.55l-0.28-1.13l0.05-0.9l-0.9-0.3l-1.87,0.43l-0.98-0.15l-1.27-0.03l-0.16,0.57l0.04,0.4l0.48,1.15
  		l1.04,1.93l0.9,1.21l0.15,4.31l0.72,1.17l0.88,0.49l-0.52,0.12l-3.26,0.12l-0.23-0.41l-0.45-0.31l-0.57,0.67l-0.26,0.57l-0.64-0.68
  		l-3.09-0.04l-2.07,1.2l-0.85-0.69l-1.77-2.05l-1.92-0.54l-2.02,0.3l-0.63,0.51l-1.42,0.6l-1.19,0.17l-1.71-0.14l-3.98,0.95
  		l-1.6,0.86l-0.35,0.97l0.12,0.43l0.25,0.51l1.13,0.06l0.5-0.16l1.11,0.05l0.24,0.12l0.53,0.1l0.51,0.47l-1.36,0.11l-0.8-0.07
  		l-0.28-0.11l-0.39,0.21l0.28,0.46l0.27,0.19l0.65,0.13l0.89,0.52l-0.41,0.44l-1.13,0.34l-0.98,0.12l-0.42,0.5l2.11,0.76l0.45,0.98
  		l0.53,0.58l0.72,0.14l1.31-0.41l1.43,0.11l3.31,0.9l0.54,0.45l0.4-0.1l0.57,0.08l0.54,0.3l0.36-0.1l0.53-0.01l0.8,0.78l0.84,0.22
  		l0.88-0.14l0.61-0.12l0.46,0.25l-0.54,0.69l0.22,0.25l0.88-0.13l0.94-0.01l0.6,0.16l0.46,0.43l-0.41,0.62l-0.45,0.33l0.23,0.74
  		l0.58,0.22l0.69,0.13l3.18-0.34l1.96,1.01l-0.66-0.1l-0.85-0.38l-0.89-0.14l-0.68,0.11l-0.34,0.42l-0.41,0.65l0.45,0.39l0.52,0.16
  		l0.53,0.79l-0.6,1.24l-0.02,0.59l0.28,0.58l1.16,1.33l1.14,1.8l3.32,1.74l0.67,0.24l0.62,0.03l0.78,0.14l0.12,0.62l-0.04,0.5
  		l0.27,2.94l0.32,1.25l0.21,0.35l0.08,0.33l-0.7-0.29l-0.81-0.02l0.12,0.59l0.22,0.3l2.44,1.55l0.76,0.73l0.49,0.87l0.77,3.07
  		l0.5,0.4l0.28,0.53l-0.72-0.48l-0.48-0.48l-0.64-2.3l-0.5-0.69l-0.97-0.8l-1.17-0.79l-0.57,1.98l-0.34,1.89l-0.47,5.14l0.21-0.21
  		l0.17-0.41l0.4-0.39l0.64,0.77l-0.79,0.29l-0.63,1.06l-0.85,5.57l-1.17,4.68l-1.23,1.29l-1.37,0.31l0.01,0.36l0.33,0.49l0.34,0.18
  		l0.72,0.25l0.55,0.03l0.76,0.12l0.51,0.28l0.03,0.44l-0.13,0.49l-0.31,0.45l-0.18,0.35l0.17,0.2l0.27,0.15l0.29,0.04l0.2-0.05
  		l0.15-0.27l0.29-0.33l0.16-0.04l-0.01,0.19l0.14,0.23l0.93,0.39l2.04,0.73l0.8,0.01l0.64,0.09l0.19,0.31l1.3,1.12l0.31-0.03
  		l0.58,0.03l0.7-0.09l0.5-0.21l0.33,0.04l0.36,0.22l0.43,0.19l0.55,0.37l0.49,0.46l0.34,0.15l2.04-0.31l0.46,0.27l0.48,0l0.54-0.07
  		l1.18,0.14l0.96-0.03l0.09-0.11l0.08-1.01l0.15-0.36l0.23-0.1l0.57,0.07l2.07,0.6l0.85,0.37l0.82,0.29l0.72,0.03l0.47,0.2
  		l0.66,0.96l0.26-0.26l0.36-0.21l0.56,0.08l1.19,0.31l0.25,0.29l0.01,0.19l-0.22,0.32l-0.07,0.23l1.29,0.47l0.57,0.31l0.2,0.34
  		l0.3,0.35l0.39,0.05l0.55-0.33l0.86-0.35l1.47,0.31l1.63,0.45l0.7,0.05l0.03-0.22l0.13-0.31l0.27-0.16l0.4-0.05l0.56-0.16
  		l0.64-0.27l0.66-0.11l0.7,0.2l0.84,0.16l0.5,0l-0.11-0.3l-0.9-1.31l-0.4-2.51l0.07-0.79l0.94-1.69l0.81-1.15l4.43-2.74l0.65-0.56
  		l0.41-0.48l1.19-0.31l0.19,0.12l0.32,0.19l0.42,0.61l0.52,0.25l1.28,0.24l0.28,0.09l1.84,0.61l0.7,0.14l0.65-0.06l-0.02-0.23
  		l0.17-0.04l0.3,0.12l0.25-0.18l0.32-0.16h0.54l0.71-0.18l0.01,0.39l0.1,0.41l0.4,0.18l0.67-0.04l1.02,0.08l0.73,1.19l2.23,0.52
  		l1.16,0.82l1.86-0.03l0.72,0.29l1.6-0.68l1.59-0.31l0.64-0.3l0.73-0.64l0.25-0.74l0.25-0.4l1.25-0.66l2.67-2.26l0.67-0.38
  		l0.98-0.37l0.02-0.22l0.13-0.12l0.16-0.06l0.19,0.1l0.01,0.11l0.46-0.17l-0.02-0.57l-0.07-0.43l0.34-0.47l0.56-0.56l0.52-0.7
  		l0.22-0.51l-0.1-0.34l-0.23-0.5l-0.32-0.04l-1.92,0.42l-0.44-0.11l-1.42-0.66l-1.53-0.81l-0.56-0.57l-0.21-0.58l0.16-0.37
  		l-0.13-0.36l-0.3-0.49l0.27-0.56l0.48-0.69l0.24-0.47l0.4-0.12l0.19-0.29l-0.32-1.15l-0.17-0.18l-0.28-0.14l-0.43-0.02l-0.74-0.24
  		l-0.53-0.4l-0.11-0.54l-0.28-0.52l-0.48-0.47l-0.06-0.52l0.54-0.27l0.74-0.01l0.52,0.1l1.17-0.83l0.43-0.07l0.39-0.18l0.32-1.14
  		l0.25-0.34l0.06-0.2l-0.23-0.24l-0.95-0.8l-0.43-0.84l-0.68-0.94l-0.63-0.41l-0.13-0.32l-0.01-0.41l0.13-0.36l1.15-0.56l0.68-0.6
  		l-0.14-0.35l-0.42-0.61l-0.48-0.36l-0.33-0.4l-0.44-0.43l-0.28-0.36l0.38-1.16l-0.27-0.41l-0.14-0.58l0.07-0.39l-0.15-0.1
  		l-1.52-0.23l-1.26,0.07l-0.9,0.39l-0.73,0.64l-0.09,0.14l0.05,0.12l0.37,0.59l-0.62,0.62l-0.95,0.48l-0.67,0.05l-0.3-0.09
  		l-0.01-0.67l0.56-0.25l0.5-0.43l0.17-0.61l0.06-0.43l-0.53-0.52l0.06-0.32l0.33-0.61l0.19-0.54l0.26-0.47l1.05-0.76l1.05-0.77
  		l0.16-0.81l0.08-0.99l0.15-0.24l1.42-0.59l0.35-0.24l0.18-0.34l1.12-1.12l1.11-1.11l0.22-0.37l0.19-0.22v-0.18l-0.14-0.14
  		l-0.53-0.09l-0.18-0.35l0.57-0.63l0.72-0.39l0.7,0l0.28,0.18l-0.02,0.21l0.3,0.22l0.53,0.07l0.65-0.08l0.65-0.24l0.4-0.56
  		l0.23-0.42l1.02-0.48l-0.42-0.15l-0.3-0.72l0.08-1.07l0.46-1.41l0.13-1.04l-0.2-0.66l0.27-0.99l0.75-1.33l0.5-1.4l0.25-1.48
  		l0.36-0.96l0.71-0.68l1.7-1.89l0.14-0.14l-0.05-0.95L1877.76,871.6z M1889.79,938.7l-0.3-0.3l-0.16-0.44l0.22-1.49l-0.02-0.56
  		l-0.15-0.86l0.02-0.36l-0.4-0.41l-0.44,0.04l-0.27,0.75l-0.07,1.32l0.13,0.48l-0.21,0.55l-0.22,0.19l-0.29-0.18l-0.47-0.17
  		l-0.51-0.04l-0.42,0.28l-0.38,0.44l-0.41,0.17l-1.52,0.38l-0.86,0.59l-0.61,1.25l-0.44,0.42l-0.18,0.28l0.23,0.13l0.28,0.01
  		l0.42,0.6l-0.57,0.26l-0.35,0.4l0.18,0.59l0.45,0.43l0.5,0.23l0.01,0.53l-0.41,0.48l-0.32,0.37l0.06,0.29l0.44,0.08l0.56-0.03
  		l0.15,0.55l-0.34,0.67l-0.01,0.43l0.45,0.24l0.98,0.37l-0.07,0.32l-0.41,0.41l-0.19,0.4l0.29,0.3l0.45,0.43l0.91,0.4l1.54,0.92
  		l0.57-0.75l0.65-1.68l0.37-0.52l0.17-0.53l0.05-1.96l0.23-0.47l1.03-1.59l0.05-0.32l-0.26-3.96L1889.79,938.7z M1799.48,905.39
  		l0.3-0.92l-0.9-1.04l-0.87-0.5l0,0.18l0.17,0.69l0.74,0.74L1799.48,905.39z"/>
    </>,
    FRO: <>
        <path className="st0" d="M1753.24,724.11l-0.32-0.52l0-0.44l-0.25-0.18l-0.94-0.24l-0.2-0.36l-0.24-0.05l0.05,0.41l0.35,0.88l0.99,1.06
  		l0.57,0.45l0.2,0.04l-0.17-0.39L1753.24,724.11z M1749.17,715.77l-0.41-0.16l-0.85,0.16l-0.72-0.02l0.37,0.85l1.05,0.37l0.64,0.08
  		l0.53-0.09l0.43-0.34l-0.27-0.36L1749.17,715.77z M1753.06,718.21l0.09-0.17l-0.07-0.34l-0.73-1.17l-0.24-0.2l-0.02-0.31l0.14-0.26
  		l0.46,0.1l0.61,0.48l0.34,0.01l0.21-1.75l-1.45-0.5l-1.31-0.66l-1.8,0.4l1.34,2.5l1.72,1.52L1753.06,718.21z M1753.59,719.38
  		l-1.08-0.44l-0.43-0.11l-0.36,0.06l0.18,0.48l0.21,0.28l0.63,0.33l0.79,0.6l0.23,0l0.16-0.49l-0.02-0.27L1753.59,719.38z
  		 M1755.18,713.78l-0.69-0.83l0.02,1.01l-0.06,0.7l0.13,0.25l0.16,0.1l0.6,0.15l0.4-0.94L1755.18,713.78z"/>
    </>,
    FSM: <>
        <path className="st0" d="M3088.92,1256.06l-0.14,0.01l-0.05,0.07l-0.03,0.22l0.07,0.04l0.15-0.04l0.26-0.18l-0.02-0.05L3088.92,1256.06
  		z M3086.8,1256.7l-0.2,0.01l-0.11,0.08l0.13,0.03l-0.03,0.15l-0.25,0.05l-0.05,0.05l0.07,0.06l0.51,0.04l0.07-0.11l0.02-0.14
  		l-0.06-0.14L3086.8,1256.7z M2973.24,1238.22l-0.25,0.28l-0.27,0.47l-0.2,0.41l0.04,0.23l0.64-0.69l0.34-0.06l0.26-0.34l-0.23-0.39
  		L2973.24,1238.22z M3142.94,1260.4l-0.91-0.22l-0.43,0.28l-0.06,0.34l0.28,0.18l0.19,0.69l0.62,0.09l0.49-0.19l-0.05-0.34
  		l0.22-0.32L3142.94,1260.4z M3182.43,1274.05l-0.21-0.08l-0.31,0.14l0.07,0.14l0.54,0.2L3182.43,1274.05z"/>
    </>,
    FLK: <>
        <path className="st0" d="M1307.95,1795.41l0.5-0.61l0.65-0.38l0.38-0.41l0.32-0.51l0.51-0.36l0.22-0.57l-0.44-0.49l-0.56-0.26
  		l-0.65,0.57l-0.24-0.17l-1.84,0.49l-0.65,0.08l-0.45-0.48l-0.64-0.17l-0.77,0.25l-1.12,0.79l-1.22-0.21l-1.34-0.7l-0.59-0.5
  		l-0.44,0.04l0.42,0.78l-0.04,0.4l0.14,0.25l0.77,0.67l0.95,0.4l0.48,0.65l-0.3,0.19l-1.57,0.46l-0.51-0.01l-0.46,0.18l0.69,0.49
  		l1.01-0.24l0.43-0.19l0.44-0.02l0.32,0.19l0,0.43l-0.42,0.33l-0.39,0.43l-0.97,0.42l-1.19,0.84l-1.44-0.06l-1.68,1.25l1.26,1.02
  		l1.06,0.46l1.5,0.07l0.21-0.28l0.27-0.11l0.57-0.07l0.26-0.16l0.55-0.75l0.35-0.98l0.44-0.04l0.52,0.13l1.2-0.11l0.58-0.16
  		l1.74-1.82L1307.95,1795.41z M1296.64,1797.84l0.34-1.15l-0.6,0.06l-0.6-0.15l-0.28,0.32l-0.79,0.29l0.25,0.4l0.71,0.76l0.71,0.04
  		L1296.64,1797.84z M1303.41,1792.22l0.3-0.6l0.06-0.39l-0.86-0.38l-0.88,0.08l0,0.92l0.23,0.37L1303.41,1792.22z M1322.32,1794.56
  		l-0.79-0.24l0.38-0.55l0.9-0.18l-0.96-1.28l-0.45-0.21l-1.94,0.23l-0.44,0.14l-0.25,0.45l0.11,0.54l0.3,0.5l0.06,0.31l-0.31-0.04
  		l-1.7-0.73l-0.3-0.29l-0.09-0.67l0.44-0.14l0.51,0.07l0.24-0.51l-0.4-0.55l-0.68-0.18l-1.61,0.23l-1.29-0.65l-0.57,0.02l0.26,0.96
  		l-1.77,1.49l-0.02,1.1l0.29,0.68l0.05,0.4l-0.3,0.21l-0.71,0.09l-0.69,0.28l-0.4,0.48l-2.21,1.63l0.29,0.51l-0.95,1.2l0,0.64
  		l0.98,1.15l1.15,0.81l0.46-1.26l0.72-0.15l0.79,0.21l0.8-0.32l-1.08-1.75l0.54-0.11l4.03,1.03l0.13-0.86l-0.05-0.32l-0.34-0.66
  		l2.93-0.81l1-0.46l0.56-0.64l1.24-0.25l1.4-0.38l0.06-0.28l0.33-0.54L1322.32,1794.56z M1317.32,1799.01l-0.31-0.02l-0.37,0.33
  		l0.24,0.48l0.67,0.31l-0.05-0.99L1317.32,1799.01z M1306.09,1800.51l0.06,0.25l0.01,0.55l0.17,0.42l0.15,0.1l0.54-0.22l0.01-0.58
  		l-0.61-0.44L1306.09,1800.51z"/>
    </>,
    FJI: <>
        <path className="st0" d="M3324.82,1459.4l0.13-0.72l0.17-0.27l0.03-0.28l-0.38-0.01l-0.37,0.16l-1.21,0.81l-1.09,0.99l-0.03-0.95
  		l0.46-0.94l1.09-0.7l0.39-0.65l0.46-0.59l1.27-1.14l0.01-0.13l-1.28,0.53l-0.5,0.06l-0.62-0.12l-0.67,0.13l-0.7,0.23l-0.65,0.38
  		l-0.98,0.74l-0.55,0.16l-0.58,0.06l-1.12,0.28l-1.1,0.39l-0.8,0.49l-0.51,0.79l-0.51,0.02l-0.49,0.27l-0.44-0.15l-0.43-0.23
  		l-0.13,0.36l-0.22,0.32l-0.24,0.22l-0.14,0.53l0.89,0.11l0.29,0.44l0.23,0.59l0.35,0.48l0.81-0.21l0.68-0.57l0.56,0.15l0.48-0.03
  		l0.41-0.75l1.24-0.87l0.83-0.02l0.19,0.07l0.19,0.44l0.24,0.19l0.37,0.13l0.39,0l1.04-0.16l1.06-0.37l0.89-0.06l0.9,0.06
  		L3324.82,1459.4z M3315.53,1467.65l-0.33,0.53l0.24,0.53l0.43-0.15l0.21-0.41l-0.18-0.54L3315.53,1467.65z M3319.91,1470.41
  		l-0.29,0.23l-0.13,0.24l-0.02,0.27l0.73,0.69l0.07-0.07l0.11-0.32l-0.21-0.65L3319.91,1470.41z M3325.75,1455.11l0.37-0.16l0.1-0.2
  		h-0.21l-0.26,0.23v0.03V1455.11z M3325.75,1458.3l0.59-0.32l0.02-0.2l0.22-0.41l-0.36,0.08l-0.47,0.41V1458.3z M3320.76,1464.48
  		l-0.29-0.01l0.13,1.19l0.28-0.23l0.21-0.52l-0.12-0.3L3320.76,1464.48z M3280.3,1502.83l-0.14,0.11l0.05,0.2l0.24,0.03l0.07-0.1
  		l-0.02-0.17L3280.3,1502.83z M3301.05,1423.58l-0.35,0.09l-0.17,0.03l0.11,0.14l0.53,0.07l0.33-0.09l0.05-0.11l-0.07-0.09
  		L3301.05,1423.58z M3311.72,1478.98l-0.44,0.2l-0.62,0.11l-0.44,0.51l-0.44,0.33l-0.44-0.05l-0.43,0.35l-0.35,0.18l0.52,0.26
  		l1.19-0.26l0.17-0.08l0.06-0.17l0.19-0.22l0.65-0.54l0.23,0.06l0.36,0.31l1.08-0.25l0-0.37l-0.57-0.2L3311.72,1478.98z
  		 M3302.9,1462.68l-0.25,0.05l-0.15,0.04l-0.25,0.21l-0.23,0.69l0.43-0.14l0.35-0.36L3302.9,1462.68z M3313.99,1469.5l-0.24-0.78
  		l0.18-0.43l-0.03-0.41l-0.58-0.48l-0.95-0.63l-0.61-0.73l-0.24-0.03l-0.25-0.17v-0.38l-0.28-0.37l-0.5-0.14l-0.5,0.23l-1.58,0.48
  		l-1.03-0.06l-1.68,0.63l-0.96,0.68l-0.87,0.8l0.04,0.44l0.15,0.48l-0.1,0.14l-0.21,0.07l-0.16,0.2l-0.05,0.29l-0.37,0.23
  		l-0.44,0.15l-0.08,0.44l0.07,0.46l0.49,0.94l0.52,0.37l0.62,0.24l1.51,0.28l1.13,0.33l0.64,0.31l0.91,0.08l0.91-0.12l0.81,0
  		l0.7-0.57l0.74-0.42l0.77-0.1l0.32,0.13l0.22-0.23l0.93-0.03l0.59-0.24l-0.42-1.28L3313.99,1469.5z M3335.93,1472.5l-0.48,0.14
  		l0.17,0.17l0.45,0.26l0.1-0.16l-0.01-0.37L3335.93,1472.5z M3334.57,1463.83l-0.44-0.29l-0.11,0.11l0.02,0.18l0.37,0.26l-0.03,0.21
  		l-0.24,0.5l0.19,0.1l0.21-0.29l0.27-0.21l0.08-0.22l-0.05-0.13L3334.57,1463.83z M3336.58,1494l-0.04-0.06l-0.05-0.06l-0.02,0
  		l-0.03,0.07l0.01,0.06l0.05,0.06l0.07,0.03l0.05,0l-0.02-0.02L3336.58,1494z M3340.45,1470.29l-0.22-0.38l-0.38-0.09l-0.27,0.39
  		l0.42,0.37l0.47-0.09L3340.45,1470.29z M3325.64,1458.31l0.11-0.01v-0.44l-0.13,0.29L3325.64,1458.31z M3337.9,1480.89l-0.14-0.1
  		l-0.02-0.16l0.11-0.06l0.05-0.05l-0.09-0.03l-0.18,0.08l-0.08,0.16l0.02,0.12l0.19,0.12l0.23,0.09l0.09-0.08l-0.05-0.08
  		L3337.9,1480.89z M3326.92,1459.58l-0.27,0.1l-0.9,0.73v0v0.33l0,0.3l0,0.42v0.48l0.21-0.33l0.9-0.64l0.38-0.73L3326.92,1459.58z
  		 M3333.77,1470.2l-0.26,0.21l0.13,0.24l0.21,0.14l0.18,0.03l0.26-0.13l-0.09-0.25L3333.77,1470.2z M3324.88,1461.95l0.24,0.31
  		l0.62-0.32l-0.01-0.9l0.01-0.63l-0.58,0.78L3324.88,1461.95z M3327.17,1478.9l-0.21,0.17l-0.06,0.18l-0.04,0.12l0.02,0.18
  		l0.12,0.04l0.02-0.1l0.03-0.18l0.05-0.08l0.08-0.05l0.15,0.11l0.13,0.01l-0.02-0.26L3327.17,1478.9z"/>
    </>,
    FIN: <>
        <path className="st0" d="M1991.36,741.01l-0.52,0.3l-0.17,0.27l0.46,0.36l0.74-0.13l0.17-0.42l-0.12-0.36L1991.36,741.01z
  		 M1992.51,741.01l-0.08,0.73l0.32,0.1l0.84-0.43l0.26-0.77l-0.84,0.04L1992.51,741.01z M1988.56,700.78l-1.28,0l0.55,1.04
  		l0.88,0.63l0.47-0.14l0.07-0.22l0.41-0.37l0.09,0.11l0.32,0.02l0.05-0.64l-0.47-0.21l-0.66,0.45l-0.49,0.07l-0.09-0.25l0.03-0.24
  		L1988.56,700.78z M1988.83,735.1l-0.37,0.23l-0.09,0.21l0.25,0.99l0.47,0.59l0.58-0.11l0.57,0.07l0.12-0.59l-1.26-0.84
  		L1988.83,735.1z M1994.22,738.22l0.36-0.11l-0.37-0.47l-0.67-0.4l-0.07,0.22l0.22,0.51l-0.34,0.14l0.11,0.25l0.87,0.63l0.61-0.06
  		l-0.12-0.23L1994.22,738.22z M2075.09,705.05l-0.61-0.69l-0.84-0.8l-0.75-0.98l-0.56-0.88l-1.74-1.23l-2.69-1.56l-1.99-1.15
  		l-3.06-2.47l-0.54-0.62l0.11-0.16l1.74-0.75l1.73-1.94l0.75-0.98l0.19-0.76l0.02-0.86l-0.12-0.8l-0.22-0.49l-0.82-0.62l-2.38-1.13
  		l-0.56-1.05l-0.47-1.1l-0.01-0.45l1.13-1.18l0.05-0.59l-0.13-0.61l-0.32-0.44l-2.44-0.54l-0.69-0.57l-0.54-0.9l-0.28-0.78
  		l-0.03-0.46l0.18-0.51l0.82-0.56l0.76-0.38l0.14-0.51l-0.01-0.55l-0.13-0.27l-1.52-0.26l-0.15-0.15l-0.04-0.19l0.08-0.23l0.82-0.99
  		l0.11-1.88l0.77-1.32l-0.87-0.77l0.07-0.14l1.34-0.41l1.23-0.1l0.56-0.15l0.06-0.62l-0.13-0.84l-1.27-3.29l-0.28-0.95l-0.84-1.2
  		l-0.7-0.81l-0.42-0.58l-0.68-1.13l-0.39-1.16l-0.67-1.3l-0.78-1.2l-0.66-1.11l-1.69-2.18l-0.23-0.6l0.02-0.55l0.15-0.58l1.32-1.79
  		l1.22-1.5l1.55-1.76l1.5-1.46l1.61-1.74l0.4-1.74l-0.07-0.29l-1.33-0.94l-2.5-2.53l-1.52-1.92l-2.37-0.82l-3.18-1.04l-1.05-2.36
  		l-0.75-2l0.07-0.72l2.3-3.43l0.22-0.62l-0.04-0.39l-0.24-0.24l-0.33-0.13l-2.13-0.1l-0.33-0.47l1.28-0.36l1.06-0.48l1.74-0.72
  		l0.56-0.18l-0.62-0.57l-0.5-0.87l0.12-0.86l1.5-1.65l1.41-1.17l0.4-0.41l0.8-1.18l-1.62-2.97l-2.87-0.9l-3.27-1.37l-1.2-0.73
  		l-1.87-1.51l-1.32-1.36l-1.2-0.05l-1.32,0.34l-2.05,1.24l-1.2,0.62l-0.66,0.18l-0.16,0.03l-1.47-0.35l-1.63-0.07l-1.31,0.1
  		l-0.5,0.17l-1.83,2l-1.28,1.01l-0.7,0.35l-0.51,0.58l-0.42,0.96l-0.94,3.32l-0.7,1.25l-0.16,0.76l0.16,2.3l-0.17,1.27l-0.86,1.05
  		l-0.6,0.47l-0.8,0.1l-1.04,0.27l-0.91,0.61l-0.64,0.83l-0.72,1.85l-1.23,0.68l-1.17-0.19l-0.84-0.68l-1.79-0.53l-1.33-0.34
  		l-1.5-0.73l-1.32-0.55l-1.21-0.11l-0.69,0.7l-0.55,0.66l-2.06,0.53l-1.17,0.42l-1.51,0.09l-0.61-0.47l-2.2-0.31l-2.61-0.37
  		l-0.76,0.01l-0.24-0.84l-0.69-1.17l-1.86-2.02l-0.76-0.72l-1.43-1.68l-1.67-1.73l-0.24-0.04l-1.12-0.06l-1.64,0.06l-1.04,0.39
  		l-0.65,0.49l-0.11,0.41l0.63,1.57l-0.2,0.39l-0.33,0.19l-1.49-0.44l-1.8,0.03l-0.45,0.48l2.3,0.84l0.1,0.18l0.02,0.44l0.08,0.46
  		l2.23,1.15l0.64,0.61l1.37,0.93l0.36,0.5l1.27,0.58l0.91,0.62l1.06,0.51l1.24,0.78l1.66,0.62l1.41,0.2l3.54,1.07l0.6,0.35
  		l1.02,0.74l1.03,0.86l0.71,1.76l1.15,0.09l0.31,0.61l1,1.04l1.39,0.91l-0.05,0.31l-1.1,0.84l-0.12,1.14l0.1,1.45l0.35,1.18
  		l-0.04,0.34l-0.27,0.41l-0.33,0.64l-0.12,0.56l0.03,0.27l0.11,0.15l0.58,0.01l1.04,0.13l0.61,0.25l0.35,1.35l-0.12,0.26l-0.88,0.61
  		l-0.26,0.48l-0.03,0.71l0.16,0.78l0.3,0.87l0.69,0.93l0.93,0.97l0.61,0.8l0.29,0.56l0.11,0.39l-0.42,0.49l-0.38,0.97l-0.07,1.11
  		l-0.17,0.73l-0.82,1l-0.57,0.35l-0.16,0.53l-0.07,0.88l0.17,1.07l0.06,0.72l0.17,0.52l0.26,0.34l1.31,0.6l0.74,1.23l0.46,0.98
  		l0.9,2.57l0.69-0.1l1.4,0.44l1.08-0.58l0.5-0.51l0.31-0.01l-0.04,0.38l-0.35,1.04l0.79,1.2l0.75,0.2l0.63-0.05l3.39,1.58l0.89,0.93
  		l-0.34,1.75l-0.08,1.51l-0.35,1.37l0.71,0.61l0.19,0.46l0.09,0.76l-0.86,0.35l-0.36,0.46l0.44,0.47l0.06,0.77l-0.62,0.09l-0.67-0.3
  		l-1.62-0.12l-1.64,0.44l-0.76,0.63l-0.84,0.07l-0.23,0.85l-0.75,0.8l-1.37,2.24l-2.16,1.75l-0.82,1.51l-0.53,0.21l-1.76,1.68
  		l-0.45,1.26l-0.88,0.09l-2.07,1.86l-0.97,0.42l-1,0.58l-2.17,1.86l-1.89,0.48l-0.04,0.91l-1.09,1.19l-0.67-0.18l-0.05,0.42
  		l0.28,0.4l-0.61-0.17l-0.25,0.22l0.36,0.81l0.26,0.42l0.03,0.47l-1.68,0.88l-1.89,0.45l-0.8-0.36l-2.15,0.44l0.03,0.65l0.16,0.55
  		l0.69,0.99l-1.49,0.08l-0.13,1.1l-2.21,2.11l-0.44,0.67l-0.22,0.67l-0.12,0.87l0.32,1.42l0.2,1.32l1.33,0.94l0.17,0.85l0.09,0.7
  		l-0.44,1.45l-0.39,1.6l1.09,0.97l0.72,1.34l0.63,1.41l0.05,0.46l0.46,0.97l-0.07,0.18l-0.05,0.12l-0.59,0.01l-0.2,0.2l0.24,0.37
  		l0.22,0.18l0.11,0.33h-0.49l-0.04,0.38l0.16,0.57l-0.07,1.67l-0.29,1.42l-0.24,0.56l-0.62,0.87l-0.14,1.17l0.15,1.5l0.21,1.05
  		l0.07,0.9l-0.01,0.76l0.21,0.52l0.77,0.33l0.72,0.5l0.96-0.66l0.66-0.14l0.41,1.13l0.67,0.07l2.73,1.26l2.21,0.31l0.34-0.11
  		l0.21,0.06l-0.08,0.27l-0.54,0.98l0.04,0.24l0.6,0.09l0.02,0.35l-0.22,0.29l-0.43,0.08l-0.36-0.03l-0.23,0.56l0.24,0.13l-0.27,0.72
  		v0.23l0.2,0.54l1.55,0.01l0.43-0.12l0.44-0.25l0.37-0.25l0.22-0.31l0.21-1.08l0.19-0.37l0.38,0.08l0.69,1.4l0.72,0.65l0.58,0.08
  		l0.42,0.25l-0.08,0.62l-0.61,0.75l-0.89,0.55l-0.39,0.53l0.48,0.13l1.35-0.36l1.22-1.02l1.15-0.76l1.09,0.23l1.09,0.03l2.56-0.55
  		l2.67-0.42l0.87,0.27l0.61-0.31l0.69-0.86l2.09-0.56l0.92,0.01l1.67-0.46l0.94-0.69l1.58-0.16l0.78-0.52l0.91-0.39l0.5,0.83l0.36,0
  		l0.74-0.6l0.84-0.41l0.76,0.07l0.04-0.38l-0.29-0.68l-0.42-0.62l0.67-0.01l1.42,0.87l1.46-0.22l0.66-0.54l0.33-1.08l0.62-0.93
  		l0.31-0.04l-0.04,0.41l-0.43,0.63l-0.26,0.95l0.13,0.74l0.61-0.31l0.96-0.22l1.93-0.21l1.05-0.68l1.09-0.23l0.31,0.06l1.86,0.94
  		l0.53-0.33l1.21-0.1l0.78-0.43l0.3-0.04l2.98-2.67l2.15-1.93l0.4-0.29l0.95-0.52l0.8-0.55l0.64-0.72l2.14-1.41l2.18-1.53l2.03-2.01
  		l0.73-0.63l0.93-0.68l2.05-2.14l0.65-0.59l2.5-2.69l1.46-1.35l0.72-0.77l3.12-2.56l2.11-2.06l0.83-1.13l0.82-1.63l0.46-1.11
  		l0.81-1.44l0.02-0.48L2075.09,705.05z M2018.99,677.66l0.37-0.48l0.89-0.17l-0.23-0.23l-1.55-0.43l-1.14,0.17l-0.63,0.43l0.02,0.88
  		l1.01,0.28L2018.99,677.66z M1998.5,739.35l-0.51-0.66l-0.45,0.11l-1.07-0.29l-0.41,0.19l-0.15,0.52l-0.26,0.36l0.53,0.27l0.4,0.36
  		l0.18,0.5l0.41,0.4l0.86,0l-0.12-0.47l-0.34-0.33l0.06-0.52L1998.5,739.35z M1983.3,742.67l-0.07,0.3l-0.69,0.07l-0.29-0.27
  		l-0.64,0.03l-0.11-0.13l0.27-0.27l0.51-0.17l0.67,0.07L1983.3,742.67z M1975.31,740.82l0.04,0.28l-0.32-0.07l-0.25,0.1l-0.17,0.35
  		l-0.37-0.13l-0.15-0.5l0.27-0.75l0.65-0.03L1975.31,740.82z M1978.06,738.75l0.26,0l0.12-0.11l0.45,0.07l0.68,0.49l0.14,0.27
  		l0.47,0.14l0.16,0.27l-0.54,0.86l-0.33,0.02l-0.25-0.11l-0.44,0.09l-0.26,0.16l-0.09,0.36l0.01,0.74l-1.97,0.15l-0.45-0.22
  		l-0.62-1.69l0.12-0.44l0.42-0.19l0.36-0.04l0.05,0.91l0.53-0.09l0.16-0.6l0.04-0.42l-0.14-0.21l-0.36-0.17l-0.21-0.28l0.3-0.46
  		l0.55-0.2l0.47,0.61L1978.06,738.75z"/>
    </>,
    ETH: <>
        <polygon className="st1" points="2210.93,1251.6 2208.13,1251.6 2205.37,1251.6 2204.88,1251.35 2202.57,1250.58 2199.63,1249.59
  		2195.98,1248.37 2193.39,1247.5 2190.62,1246.6 2187.82,1245.67 2185.61,1244.95 2182.87,1244.05 2180.48,1243.26 2180.15,1243.07
  		2178.83,1241.87 2177.09,1240.3 2176.76,1240.27 2175.93,1239.94 2175.19,1239.09 2174.42,1238.01 2173.71,1236.65 2173.4,1235.72
  		2172.45,1235.33 2171.99,1234.6 2171.13,1233.51 2170.53,1232.99 2170.32,1232.53 2170.05,1231.58 2169.55,1230.55 2169.08,1229.9
  		2168.97,1229.63 2169,1229.45 2169.87,1228.05 2170.27,1227.55 2170.71,1227.06 2171.08,1226.58 2171.22,1226.25 2170.56,1226.26
  		2170.04,1226.17 2169.69,1225.89 2168.96,1225.58 2168.14,1225.56 2167.36,1225.85 2166.04,1226.2 2164.85,1226.32
  		2163.88,1226.51 2163.09,1226.74 2162.37,1226.62 2161.75,1226.41 2161.61,1224.65 2161.46,1222.75 2161.48,1221.25
  		2161.7,1220.43 2161.89,1220.11 2163.02,1218.97 2163.41,1218.51 2164.7,1216.63 2165.81,1215.01 2166.63,1213.8 2165.89,1212.92
  		2165.34,1212.14 2164.58,1211.21 2163.84,1210.79 2163.04,1210.27 2162.26,1209.05 2161.47,1207.7 2160.29,1206.6 2158.08,1205.01
  		2156.05,1203 2154.51,1200.89 2153.51,1199.79 2153.08,1199.51 2151.02,1198.82 2149.58,1197.86 2148.47,1197.06 2147.79,1196.85
  		2147.12,1196.83 2145.72,1196.98 2144.55,1196.48 2144.06,1196.48 2143.27,1196.34 2142.66,1196.16 2141.94,1196.37
  		2140.45,1196.73 2139.85,1196.65 2139.51,1196.16 2139.32,1195.78 2138.8,1195.38 2138.38,1195.38 2138.14,1195.73 2136.6,1196.63
  		2134,1197.12 2133.39,1197.09 2132.93,1196.73 2131.62,1195.2 2131.25,1194.95 2130.95,1194.92 2130.34,1194.74 2129.78,1194.45
  		2129.28,1193.82 2128.78,1193.46 2128.24,1194.69 2127.3,1196.84 2126.79,1197.99 2126.14,1199.47 2125.94,1199.52
  		2125.61,1199.41 2124.31,1197.57 2123.5,1196.87 2122.89,1196.94 2122.45,1197.28 2122.17,1197.89 2121.87,1198.28
  		2121.54,1198.42 2120.83,1198.35 2119.75,1198.06 2118.63,1198.12 2117.48,1198.54 2117.33,1198.55 2116.65,1200.84
  		2116.68,1202.09 2116.2,1203.93 2115.83,1204.79 2115.5,1205.29 2115.21,1205.81 2114.7,1206.96 2114.26,1208.47 2114.07,1210.02
  		2114.05,1210.92 2113.97,1211.33 2113.82,1211.59 2112.81,1211.77 2111.4,1211.95 2110.64,1212.15 2110.13,1212.47 2109.51,1213.2
  		2108.28,1215.21 2107.63,1216.45 2106.61,1218.13 2105.43,1219.32 2105.19,1219.9 2104.99,1220.98 2104.55,1222.69 2104.16,1223.9
  		2104.23,1224.87 2103.86,1226.57 2103.91,1227.39 2103.5,1227.85 2102.94,1228.28 2102.56,1228.39 2101.75,1227.9 2101.13,1227.39
  		2100.88,1227.26 2100.34,1227.57 2099.7,1228.04 2098.96,1229.13 2098.39,1230.24 2098.72,1232.58 2098.69,1233.09
  		2098.52,1233.65 2097.63,1235.39 2097.41,1235.94 2097.08,1236.99 2096.73,1238.81 2096.72,1239.25 2096.72,1239.59
  		2096.78,1241.3 2096.83,1242.8 2096.92,1245.24 2096.92,1245.88 2096.86,1246.67 2096.68,1246.98 2096.23,1247.43 2095.67,1247.84
  		2094.26,1247.94 2093.07,1247.93 2092.24,1247.84 2091.09,1247.8 2090.01,1247.89 2089.62,1248.23 2089.03,1249.46
  		2088.19,1251.23 2087.75,1251.98 2087.63,1252.42 2087.77,1252.68 2088.32,1253.06 2089.55,1253.59 2090.95,1253.9
  		2091.99,1254.03 2092.71,1254.18 2093.25,1254.34 2095.24,1255.7 2095.88,1256.33 2096.24,1256.89 2096.32,1257.49
  		2096.61,1258.09 2097.75,1259.28 2098.42,1259.96 2100.15,1260.84 2100.81,1261.84 2101.45,1262.32 2102.05,1262.84
  		2102.38,1263.62 2103.12,1265.87 2103.63,1267.05 2104.14,1268.01 2104.35,1269.58 2104.76,1270.28 2105.18,1271.13
  		2105.87,1271.9 2106.61,1272.49 2106.75,1272.65 2106.71,1272.94 2106.72,1273.32 2106.91,1273.56 2107.23,1273.72
  		2107.67,1273.55 2108.06,1273.31 2108.44,1273.26 2110.76,1273.89 2111.15,1274.44 2111.13,1275.03 2111.23,1275.46
  		2111.05,1275.89 2110.86,1277.2 2110.92,1278.39 2111.61,1279.27 2112.24,1279.97 2112.73,1280.94 2113.1,1281.24 2113.6,1281.39
  		2115.2,1281.44 2117.57,1281.5 2119.85,1281.56 2120.06,1281.58 2120.54,1281.71 2122.64,1283.03 2124.56,1284.23 2126.18,1285.28
  		2127.76,1286.3 2129.29,1287.29 2130.48,1288.11 2131.65,1288.36 2133.56,1288.47 2134.88,1288.51 2136.1,1288.86 2137.91,1289.18
  		2139.26,1289.34 2140.08,1289.53 2142.34,1289.72 2142.72,1289.61 2143.72,1288.7 2144.83,1287.22 2145.27,1286.41 2146.72,1285.6
  		2149.26,1284.47 2151.05,1283.67 2153.05,1282.87 2153.95,1283.57 2155.2,1284.68 2155.76,1285.23 2156.21,1285.47
  		2156.88,1285.64 2157.71,1285.64 2158.16,1285.61 2159.08,1285.47 2161.24,1285.34 2162.47,1285.35 2162.73,1284.9
  		2163.65,1284.01 2165.37,1283.47 2166.44,1283.38 2170.11,1282.72 2170.66,1282.45 2170.98,1282.14 2171.29,1281.43 2172,1280.44
  		2172.93,1279.76 2174.68,1278.87 2176.4,1278.12 2176.78,1278 2178.85,1277.52 2179.36,1277.36 2180.2,1277.19 2180.53,1277.19
  		2183.4,1277.36 2185.65,1277.49 2187.97,1277.62 2188.21,1277.52 2189.83,1275.76 2192.41,1272.96 2194.05,1271.17
  		2196.59,1268.41 2198.54,1266.42 2200.7,1264.21 2202.79,1262.2 2205.32,1259.77 2206.9,1258.24 2209.37,1255.86 2211.72,1253.6
  		2213.8,1251.6 	"/>
    </>,
    ESP: <>
        <path className="st0" d="M1665.43,1076.6l-0.71-0.39l-0.37-0.04l-0.27,0.24l-0.29,0.53l0.43,0.72l0.41,0.22l0.34-0.08l0.7-0.56
  		l-0.02-0.25L1665.43,1076.6z M1672.25,1072.95l-1.67,1.32l-0.33,0.11l-1.64,0.28l-0.77-0.06l-0.52,0.33l0.33,0.42l0.6,1.31
  		l1.15,1.29l0.97-0.23l0.39-0.27l0.66-0.81l0.71-2.08l1.81-1.35l-0.04-0.43L1672.25,1072.95z M1679.99,1076.68l-0.13-0.11
  		l-0.15,0.05l-0.17,0.16l-1.94-0.16l-0.32,0.82l-0.75,0.63l0.02,0.97l0.82,0.94l0.46,0.23l0.81,0.1l1.03-0.58l0.4-0.58l0.05-1.07
  		l-0.2-0.7L1679.99,1076.68z M1657.73,1080.12l-0.99,0.06l0.21,0.3l0.25,0.19l1.03,0.55l0.82-1.48l-0.31-0.37L1657.73,1080.12z
  		 M1692.74,1071.25l-0.57,0.03l-0.41,0.32l-0.21,0.81l-1.05,1.92l-0.67,1.73l-1.04,0.78l-1.15,0.26l0.2,0.17l1.15,0.24l1.14-1.03
  		l2.26-0.76l0.55-1.42l0.3-1.6l0-0.97l-0.25-0.43L1692.74,1071.25z M1660.25,1070.87l-0.45-0.55l-1.11,0.02l-0.61,0.79l1,1.76
  		l0.19,0.72l0.21-0.07l0.64-0.69l0.11-0.43l-0.06-0.66l0.21-0.33L1660.25,1070.87z M1846.05,965.43l-0.75-1.14l-1.4-0.42l-1.73,0.12
  		l-0.07,0.26l-0.02,0.6l0.21,0.17l0.85,0.13l2.59,1.14l0.15-0.11l0.24-0.55L1846.05,965.43z M1695.99,1067l-0.28,0.61l-0.97,0.23
  		l-1.16,0.57l-0.3,0.39l-0.31,1.32l0.64,0.22l0.57-0.6l1.36-0.45l0.65-0.42l0.2-1.32l0.26-0.42l-0.34-0.36L1695.99,1067z
  		 M1836.21,946.02l0.85-0.84l-0.08-1.39l-0.11-0.29l-0.42-0.25l-0.21-0.27l0.13-0.95l0.44-0.04l0.74-0.29l-0.16-0.55l-0.4-0.24
  		l-0.24-0.64l-0.5,0l-0.84-0.16l-0.7-0.2l-0.66,0.11l-0.64,0.27l-0.56,0.16l-0.4,0.05l-0.27,0.16l-0.13,0.31l-0.03,0.22l-0.7-0.05
  		l-1.63-0.45l-1.47-0.31l-0.86,0.35l-0.55,0.33l-0.39-0.05l-0.3-0.35l-0.2-0.34l-0.57-0.31l-1.29-0.47l-0.23,0.07l-0.78,0.41
  		l-0.44,0.14l-0.4,0.07l-0.32-0.03l-0.17-0.24l0.02-0.37l-0.07-0.33l-0.06-0.18l0.11-0.48l-0.66-0.96l-0.47-0.2l-0.72-0.03
  		l-0.82-0.29l-0.85-0.37l-2.07-0.6l-0.57-0.07l-0.23,0.1l-0.15,0.36l-0.08,1.01l-0.09,0.11l-0.96,0.03l-1.18-0.14l-0.54,0.07
  		l-0.48,0l-0.46-0.27l-2.04,0.31l-0.34-0.15l-0.49-0.46l-0.55-0.37l-0.43-0.19l-0.36-0.22l-0.33-0.04l-0.5,0.21l-0.7,0.09
  		l-0.58-0.03l-0.31,0.03l-1.3-1.12l-0.19-0.31l-0.64-0.09l-0.8-0.01l-2.04-0.73l-0.93-0.39l-0.14-0.23l0.01-0.19l-0.16,0.04
  		l-0.29,0.33l-0.15,0.27l-0.2,0.05l-0.29-0.04l-0.27-0.15l-0.17-0.2l0.18-0.35l0.31-0.45l0.13-0.49l-0.03-0.44l-0.51-0.28
  		l-0.76-0.12l-0.55-0.03l-0.72-0.25l-0.34-0.18l-0.33-0.49l-0.01-0.36l-0.29,0.07l-1.37,0.57l-1.73,0.24l-1.18-0.06l-2.27-0.87
  		l-2.26-0.43l-0.61,0.15l-0.82,0.7l-3.14-0.82l-0.89-0.61l-0.68-0.09l-1.43,0.43l-0.97-0.22l-1.06,0.37l-2.51,0.49l-1.77-0.01
  		l-4.9-0.88l-1.77-0.53l-2.95-0.3l-1.52-0.64l-1.97,0.51l-1.21-0.09l-2.12,0.26l-1.19-0.14l-2.39,0.07l-1.35,0.32l-1.69-0.42
  		l-1.16-1.04l-0.88-0.45l-0.77,0.13l-0.87-0.38l-1.3,0.59l-1.28,0.13l-1.12,0.67l-1.01,0.5l-0.27,0.41l0.31,0.44l0.03,0.59
  		l-0.9,0.44l-0.56,0.11l-0.97,0.5l-1.08,0.21l-1.75-0.18l-1.27,0.97l-0.6,0.25l-0.7,0.41l-0.48,1.41l0,0.6l0.47,0.67l0.44,0.47
  		l0.72,0.52l0.96,0.16l-0.08,0.32l-0.82,1.06l0.02,0.7l0.38,0.08l1.48-0.55l0.1,0.41l-0.08,0.38l-0.02,0.94l0.3,0.36l0.39,0.23
  		l-0.33,0.54l-0.34,0.24l-0.05,0.5l0.73-0.02l0.32,0.13l-0.69,0.64l-0.97,1.06l0.07,1.6l0.22,0.2l0.63-0.14l0.79-0.68l0.79-0.45
  		l0.43-0.17l1.82-0.46l0.48-0.23l0.45,0.04l0.08,0.22l0.26,0.43l0.29,0.3l0.08,0.22l-0.7,0.92l-0.1,0.31l0.37,0.59l0.06,0.17
  		l0.18,0.08l0.49-0.02l0.87-0.38l0.59-0.32l0.21,0.13l1.71-0.18l0.41,0.15l0.27,0.16l0.84,0.22l0.92,0.02l1.14-0.31l0.5-0.31
  		l0.09-0.34l0.03-0.26l0.15-0.17l0.26-0.1l0.4,0.17l0.58,0.14l1.39,0.05l0.27-0.19l0.47,0.06l0.62,0.24l0.72-0.08l0.36,0.3
  		l0.15,0.39l0.04,0.85l-0.05,0.86l0.14,0.32l0.48,0.08l0.78-0.01l0.7,0.23l0.55,0.41l0.18,0.42l0.08,0.29l-0.27,0.16l-0.38,0.61
  		l-0.96,0.8l-1.37,0.72l-1.05,0.89l-0.72,1.07l-0.9,0.45l-0.28,0.24l-0.11,0.29l0.6,1.31l0.18,1.01l0.15,1.23l-0.1,0.35l-0.05,1.35
  		l-0.14,0.4l0.03,0.32l0.22,0.35l0.1,0.33l-0.41,0.42l-0.75,0.49l-0.56,0.43l-0.15,0.4l0.04,0.25l0.94,0.85l0.17,0.35l-0.13,0.84
  		l-0.54,1.37l-0.52,0.83l-0.09,0.08l-0.59,0.24l-2.83,0.01l-0.69,0.19l0.1,0.17l0.67,1.07l0.69,0.57l0.23,0.13l0.25,1.25l1.12,2
  		l1.09,0.28l0.38,0.5l-0.07,0.7l-0.34,0.77l-0.67,0.79l-0.8,0.55l-0.52,0.55l-0.04,0.64l-0.17,0.81l-0.25,0.64l-0.06,0.43l1.99,2.7
  		l1.11-0.13l0.14,0.06l-0.2,0.64l-0.35,0.75l-0.42,0.14l-0.95,0.23l-0.9,0.97l-0.73,1.17l-0.55,0.56l-0.5,1.39l0.06,0.6l0.24,0.93
  		l0.51,2.41l1.95-0.28l1.69,0.1l0.38-0.16l0.55-0.62l0.04,0.29l-0.21,0.53l3.3,2.31l0.81,1.19l0.63-0.74l0.88-0.05l-0.36,0.14
  		l-0.58,0.49l-0.71,1.15l0.24,0.88l0.97,0.39l0.09,0.31l-0.07,0.37l0.33,0.96l0.47,0.89l1.09,1.4l0.67,0.06l1.28,0.9l1.54,0.6
  		l0.63-0.12l0.75-0.33l0.16-0.74l0.31-0.08l0.22,0.24l0.17-0.01l0.26-0.97l0.84-1.32l0.5-0.48l1.99-0.75l2.2-0.04l1.45-1.18
  		l0.57-0.68l0.57-0.17l4.54-0.37l2.1,0.16l1.24,0.31l1.45-0.46l0.93-0.03l2.08,0.15l0.96,0.27l0.98-0.32l0.63-0.57l1.2-0.24
  		l1.24,0.11l0.99,0.72l0.64-0.3l1.45-1.63l1.19-2.77l1.32-1.49l2.64-1.69l3.28-0.1l0.98-0.09l0.42-0.15l0.42-0.34l-0.86-0.78
  		l0.07-0.57l0.52-0.78l0.09-0.35l0.49-1.03l0.31-1.56l0.81-0.5l0.25-1.11l1.18-1.16l2.77-1.47l1.59-1.09l0.55-0.61l-0.39-0.64
  		l-1.59-0.65l-0.84-0.77l-0.6-0.91l-1.04-3.49l0.02-1.01l2.12-3.52l1-1.37l0.97-0.92l1.73-2.11l1.96-2.94l0.26-0.08l0.28,0.09
  		l0.51-0.17l1.17-0.56l0.27-0.36l-0.8-0.81l-0.69-0.19l0.86-0.69l1.82-1.7l1.46-0.36l3.04-0.98l4.35-0.92l0.53-0.33l1.39-1.46
  		l5.84-3.02L1836.21,946.02z M1838.3,966.81l-0.45-0.09l-0.85,0.3l-0.81-0.33l0.11-0.46l0.27-0.25l0.06-0.28l-0.27-0.18l-0.02-0.16
  		l0.28-0.37l-0.33-0.09l-2.14,0.62l-1.01,0.53l-3.48,2.39l-0.01,0.4l0.2,0.31l0.54,0.1l0.34,0.52l0.64-0.52l0.49-0.25l0.56,0.14
  		l0.38,0.31l0.2,0.99l0.25,0.25l0.84,0.16l1.45,0.66l0.69-0.31l0.76-0.53l0.41-0.89l0.47-0.78l0.56-0.71l0.4-0.7l-0.11-0.63
  		L1838.3,966.81z M1822.31,977.29l-0.51-0.55l-0.16,0.28l-0.13,0.28l0.03,0.4h0.84l0.56,0.12l0.19-0.13l-0.01-0.29L1822.31,977.29z
  		 M1822.88,973.28l-1.82,0.4l-0.39,0.49l-0.02,0.49l-0.37,0.08l-0.28,0.68l0.28,0.24l1.28,0.21l0.31-0.6l0.42-0.14l1.09-1.04
  		l-0.09-0.48L1822.88,973.28z"/>
    </>,
    ERI: <>
        <path className="st0" d="M2147.53,1183.75l-0.28-0.83l-0.24-0.19l-0.08,0.2l-0.36,0.33l0.14,0.17L2147.53,1183.75z M2172.57,1210.76
  		l-0.71-0.64l-0.25,0.78l-1.46-0.48l-0.52-1.31l-1.78-1.73l-1.04,0.08l-0.45-1.58l-0.85-1.62l-4.94-3.37l-1.5-2.23l-2.55-3.22
  		l-3.18-1.05l-1.39-1.2l-0.74-0.43l-0.92-0.26l-1.11-0.09l-0.85-0.34l-1.01-1.18l-0.22-0.56l-0.14-1.01l-0.53-0.5l-0.96-0.66
  		l-0.42,0.49l-0.19,0.81l0.21,0.63l0.03,0.38l-0.29,0.65l-0.55-0.76l-0.75-2.05l-0.44-0.6l-0.61-0.08l-0.71-2.18l-1.04-1.15
  		l-0.64-2.34l-0.67-4.6l-0.91-3.07l-1.03-2.95l-2.55-4.99l-0.73,0.58l-0.85,0.99l-0.21,0.39l-0.1,0.23l-0.1,0.29l-0.22,0.29
  		l-0.48,0.4l-0.19,0.18l-0.12,0.28l-0.29,0.18l-0.32,0.01l-0.28,0.12l-0.42,0.19l-0.62-0.1l-0.63,0.17l-0.23,0.22l-0.5,0.19
  		l-0.5,0.04l-0.18,0.06l-0.48,0.32l-0.58,0.45l-0.68,0.29l-0.24,0.09l-0.31,0.31l-0.48,1.55l-0.35,0.4l-0.59,0.04l-0.77,0
  		l-0.67,0.13l-0.91-0.17l-0.44,0.02l-0.12,0.33l-0.16,1.33l0.02,0.57l-0.36,0.67l-0.4,0.84l0.15,1.42l0.07,1.4l-0.74,2.11l-0.1,0.48
  		l-0.75,1.67l-0.38,0.62l-0.95,3.12l-0.37,0.96l-0.8,1.01l0.18,1.64l0.19,1.74l0.18,1.64l0.27,2.46l0.15-0.01l1.15-0.42l1.12-0.06
  		l1.08,0.29l0.71,0.07l0.33-0.15l0.3-0.38l0.28-0.62l0.44-0.34l0.61-0.07l0.81,0.69l1.29,1.85l0.33,0.11l0.21-0.04l0.65-1.48
  		l0.5-1.15l0.94-2.15l0.54-1.23l0.5,0.36l0.5,0.63l0.57,0.29l0.61,0.18l0.3,0.02l0.37,0.25l1.31,1.53l0.46,0.36l0.61,0.04l2.59-0.49
  		l1.55-0.89l0.24-0.35h0.42l0.52,0.4l0.2,0.38l0.33,0.5l0.61,0.08l1.48-0.35l0.72-0.21l0.62,0.18l0.78,0.15h0.49l1.17,0.5l1.41-0.16
  		l0.66,0.03l0.68,0.21l1.11,0.8l1.44,0.96l2.06,0.69l0.43,0.28l1,1.1l1.55,2.11l2.03,2.01l2.21,1.59l1.18,1.1l0.8,1.35l0.78,1.22
  		l0.8,0.53l0.74,0.42l0.76,0.93l0.55,0.78l0.75,0.88l0.25-0.24l0.35-0.23l0.25,0.07l1.61,1.16l0.28-0.03l0.54-0.36l0.49-1.24
  		l0.34-0.45l0.15,0.01l1.03-0.35l0.94-0.39L2172.57,1210.76z M2147.19,1186.4l-0.06,0.09l-0.33,0.09l-0.64-0.35l0.23,0.72l1.01,0.18
  		l0.85-0.06l0.92,0.18l0.79-0.02l0.08-0.42l-1.33-0.64l-0.13,0.32l-0.19,0.15l-0.24,0.04l-0.35-0.46l-0.07-0.85l-0.3-0.37
  		l-0.46-0.32l-0.72-0.12l0.37,0.52l-0.18,0.19l-0.29,0.15l-0.04,0.38l0.64,0.1L2147.19,1186.4z"/>
    </>,
    ESH: <>
        <polygon className="st1" points="1666.16,1140.24 1666.16,1140.24 1665.98,1141.2 1666.11,1142.01 1665.69,1141.56 1666.44,1137.01
  		1667.1,1132.42 1668.26,1130.14 1669.18,1129.13 1670.61,1128.61 1671.92,1126.31 1672.38,1124.19 1673.24,1123.22
  		1673.51,1122.45 1673.17,1121.87 1673.98,1120.73 1674.97,1118.97 1675.42,1117.85 1676.58,1116.11 1676.72,1115.72
  		1676.61,1115.28 1676.16,1115.66 1675.68,1116.3 1675.1,1116.81 1675.34,1116.18 1675.79,1115.26 1676.81,1114.3 1678.43,1113.24
  		1681.78,1109.63 1683.04,1109.01 1684.17,1107.49 1684.58,1106.13 1684.69,1103.03 1685.09,1101.38 1685.83,1100.1
  		1686.71,1097.76 1687.38,1096.72 1687.82,1094.59 1688.3,1093.77 1689.15,1093.39 1690.37,1092.32 1692.19,1091.66
  		1694.35,1090.27 1695.36,1089.45 1696.03,1088.21 1696.76,1085.74 1698.05,1083.14 1698.73,1081.14 1701.09,1081.14
  		1703.46,1081.14 1705.82,1081.13 1708.19,1081.13 1710.55,1081.13 1712.92,1081.13 1715.28,1081.13 1717.65,1081.13
  		1720.01,1081.13 1722.38,1081.13 1724.74,1081.13 1727.11,1081.13 1729.47,1081.13 1731.84,1081.13 1734.2,1081.13
  		1736.57,1081.13 1736.57,1082.63 1736.57,1084.48 1736.57,1085.98 1736.57,1087.77 1736.57,1089.55 1736.57,1091.58
  		1736.58,1093.6 1736.58,1095.07 1736.58,1096.09 1734.86,1096.09 1733.29,1096.09 1731.72,1096.09 1730.15,1096.09
  		1728.59,1096.09 1727.02,1096.09 1725.45,1096.09 1723.88,1096.09 1722.31,1096.09 1720.74,1096.09 1719.17,1096.09
  		1717.6,1096.09 1716.03,1096.09 1714.46,1096.09 1712.89,1096.09 1711.32,1096.09 1709.75,1096.09 1708.49,1096.09
  		1708.49,1097.16 1708.49,1098.38 1708.49,1099.6 1708.49,1100.81 1708.49,1102.03 1708.49,1103.25 1708.49,1104.46
  		1708.49,1105.67 1708.49,1106.89 1708.49,1108.1 1708.49,1109.31 1708.49,1110.52 1708.49,1111.73 1708.49,1112.94
  		1708.49,1114.15 1708.49,1115.36 1708.49,1116.56 1708.49,1117.64 1708.43,1118.6 1707.93,1118.89 1706.73,1119.4 1705.49,1119.93
  		1703.92,1120.17 1703.41,1120.34 1702.4,1121.03 1701.08,1121.94 1699.94,1122.73 1699.19,1123.76 1698.92,1124.32
  		1698.81,1124.91 1698.9,1125.48 1699.3,1126.61 1699.41,1127.18 1699.48,1128.17 1699.55,1129.25 1699.62,1130.42 1699.7,1131.58
  		1699.78,1132.82 1699.86,1134.06 1699.94,1135.3 1700,1136.22 1700.07,1137.39 1698.8,1137.39 1696.87,1137.39 1694.94,1137.39
  		1693,1137.4 1691.07,1137.4 1689.14,1137.4 1687.21,1137.4 1685.28,1137.41 1683.35,1137.41 1681.42,1137.41 1679.49,1137.41
  		1677.55,1137.42 1675.62,1137.42 1673.69,1137.42 1671.76,1137.42 1669.83,1137.43 1667.9,1137.43 1666.82,1137.43
  		1666.47,1139.07 	"/>
    </>,
    EGY: <>
        <polygon className="st1" points="2120.24,1131.41 2119.9,1130.69 2116.4,1128.08 2114.85,1126.01 2112.18,1125.03 2111.61,1124.63
  		2111.2,1124.07 2110.37,1123.21 2109.24,1120.34 2108.89,1118.82 2108.74,1115.84 2108.83,1115.28 2109.04,1114.59
  		2109.49,1114.39 2109.81,1114.33 2111.09,1114.44 2109.75,1113.3 2108.51,1112.51 2107.83,1111.49 2106.12,1109.66
  		2103.25,1103.74 2101.79,1101.04 2100.83,1098.82 2098.84,1095.83 2096.48,1091.1 2095.72,1090.22 2095.17,1086.61 2094.8,1085.39
  		2094.4,1084.64 2093.52,1083.98 2093.18,1083.17 2092.27,1081.57 2092.35,1080.72 2092.25,1078.94 2091.81,1078.25
  		2090.78,1077.56 2089.35,1076.13 2087.84,1074 2086.79,1072.88 2086.44,1072.29 2086.21,1071.63 2085.83,1070.87 2084.77,1069.58
  		2084.54,1068.99 2084.6,1067.26 2084.27,1065.98 2083.98,1065.4 2082.57,1064.05 2082.25,1063.16 2082.66,1062.07 2083.34,1061.14
  		2083.21,1060.46 2083.99,1060.01 2084.67,1061.62 2085.3,1064.16 2085.68,1064.81 2086.06,1065.27 2086.55,1066.31
  		2088.28,1068.26 2088.74,1069.12 2089.36,1070.95 2089.34,1071.7 2089.73,1072.86 2091.15,1074.48 2092.65,1075.7 2094.05,1077.59
  		2096.45,1079.57 2097.92,1080.15 2098.75,1079.02 2099.43,1077.87 2099.66,1077.05 2099.83,1074.77 2101.26,1071.13
  		2102.27,1066.45 2103.21,1064.98 2103.68,1064.56 2103.39,1063.77 2102.73,1061.5 2102.26,1059.94 2101.61,1058.01
  		2100.53,1055.67 2100.43,1055.11 2100.19,1054.29 2099.44,1052.15 2098.83,1050.61 2098.13,1048.64 2097.86,1047.86
  		2097.73,1047.58 2097.55,1047.75 2095.24,1048.89 2093.26,1049.36 2090.83,1049.35 2089.28,1049.78 2088.96,1049.4
  		2088.74,1049.01 2086.81,1049.54 2086.42,1049.48 2084.99,1049.88 2084.3,1049.93 2083.71,1049.63 2081.95,1048.2 2081.33,1047.84
  		2081.04,1047.85 2081.27,1048.29 2081.59,1048.71 2080.96,1049.46 2080.08,1049.71 2079.77,1049.15 2079.29,1048.53
  		2078.4,1048.34 2077.29,1047.86 2078.18,1046.74 2078.31,1046.1 2079.86,1047.38 2080.37,1047.41 2078.92,1045.92 2078.29,1045.55
  		2077.87,1045.69 2075.91,1046.35 2075.22,1046.32 2072.43,1045.13 2071.5,1044.98 2071.24,1045.09 2071.06,1045.87
  		2070.81,1046.28 2069.46,1046.49 2068.28,1046.83 2067.12,1046.71 2069.82,1045.73 2070.15,1045.32 2067.19,1046.19
  		2065.7,1046.33 2065.27,1046.84 2065.01,1047.26 2064.59,1047.63 2064.25,1048.18 2063.45,1048.2 2062.79,1048.11 2061.78,1048.46
  		2058.94,1050.46 2057.56,1051.23 2056.3,1051.79 2055.3,1052.09 2054.56,1052.13 2053.72,1051.89 2052.33,1051.09 2049.87,1050.1
  		2045.26,1049.66 2044.1,1048.76 2042.33,1048.79 2041.66,1048.6 2039.2,1047.07 2035.16,1046.21 2032.54,1045.83 2027.79,1044.82
  		2023.48,1045.82 2022.16,1045.62 2021.53,1044.5 2021.21,1044.76 2020.75,1045.31 2020.45,1045.81 2019.67,1046.61
  		2019.02,1047.47 2019.08,1048.72 2019.23,1050 2019.67,1051.24 2020.04,1052.63 2019.94,1053.53 2019.62,1054.64 2019.23,1055.57
  		2017.96,1057.47 2017.76,1057.93 2017.83,1058.57 2018.61,1060.82 2018.67,1061.53 2019.13,1063.71 2019.56,1065.49
  		2020.02,1066.88 2020.1,1067.26 2020.1,1069.31 2020.1,1071.36 2020.1,1073.4 2020.1,1075.44 2020.1,1077.48 2020.1,1079.52
  		2020.1,1081.55 2020.1,1083.58 2020.1,1085.61 2020.1,1087.63 2020.1,1089.66 2020.1,1091.68 2020.1,1093.69 2020.1,1095.71
  		2020.1,1097.72 2020.1,1099.73 2020.1,1101.74 2020.1,1103.74 2020.1,1105.75 2020.1,1107.75 2020.1,1109.74 2020.1,1111.74
  		2020.1,1113.73 2020.1,1115.73 2020.1,1117.72 2020.1,1119.7 2020.1,1121.69 2020.1,1123.67 2020.1,1125.65 2020.1,1127.63
  		2020.1,1129.61 2020.1,1131.58 2023.32,1131.58 2026.53,1131.58 2029.75,1131.58 2032.97,1131.58 2036.19,1131.58 2039.4,1131.58
  		2042.62,1131.58 2045.84,1131.58 2049.06,1131.58 2052.27,1131.59 2055.49,1131.59 2058.71,1131.59 2061.93,1131.59
  		2065.14,1131.59 2068.36,1131.59 2071.58,1131.59 2072.56,1131.59 2072.99,1131.52 2073.82,1129.89 2074.17,1129.76
  		2074.71,1129.86 2074.89,1130.24 2074.73,1130.8 2074.46,1131.58 2076.03,1131.58 2078.8,1131.58 2081.56,1131.58 2084.33,1131.58
  		2087.09,1131.58 2089.85,1131.58 2092.62,1131.58 2095.38,1131.58 2098.14,1131.58 2100.91,1131.58 2103.67,1131.58
  		2106.43,1131.58 2109.2,1131.57 2111.96,1131.57 2114.72,1131.57 2117.49,1131.57 2120.25,1131.57 	"/>
    </>,
    EST: <>
        <path className="st0" d="M1997.59,756.98l0.87,0.4l0.57,1.36l-0.05,0.52l0.59,0.27l1-0.24l0.89-1.38l0.22,0.29l0.41,0.24l0.69-0.61
  		l0.72-0.09l-0.23-1.06l-0.6-0.88l-1.55-0.3l-0.11-0.21l-0.08-0.53l-0.44-0.16l-0.52,0.07l-0.7,0.68l-0.35,0.65l-3.42,0.38
  		l0.89,0.56L1997.59,756.98z M2003.12,760.56l-1.21-0.2l-0.56,0.21l-0.55,0.09l-0.6-0.3l-0.59-0.08l-0.61,0.28l-1.23,0.3l-0.52,0.9
  		l-0.51-0.17l-0.31,0.07l-0.73,0.46l-0.67-0.4l-0.65-0.05l-0.52,0.21l1.03,1.36l-0.16,0.46l-0.34,0.4l-0.28,0.14l-0.31,0.04
  		l0.23,0.48l1.28,0.6l0.59,0.51l0.7,0.21l-0.29,0.48l-1.41,1.47l-0.06,0.39l0.16,0.39l0.67-0.06l0.64-0.38l0.63-1.04l0.35-1.33
  		l0.86-0.69l1.07-0.23l1.95,0.07l0.23-0.37l1.08-0.62l0.8-0.65l0.47-0.1l0.4-0.32l0.37-0.46l1.65-0.18l-0.26-0.4L2003.12,760.56z
  		 M2046.65,750.67l-0.57-0.63l-0.45-0.39l-0.09,0.18l-0.92,0.69l-4.69-0.45l-3.04,0l-1.03-0.26l-1.91-1.03h-1.38l-5.62-1.01
  		l-1.5,0.09l-0.9-0.14l-0.02,0.51l0.11,0.48l-0.65,0.48l-4.77-0.01l-2.48,0.83l-1.71-0.21l-1.73,1.21l-1.03,0.04l0.25,1l-2.53,0.21
  		l-1.2,0.41l-1.23,0.58l0.19,1.1l-0.31,0.47l-0.1,0.47l0.4,0.41l-0.21,0.48l-0.49,0.49l0.55,1.25l0.05,0.37l0.25,0.1l1.24-0.06
  		l-0.28,0.41l-0.98,0.47l-0.18,0.71l0.45,1.02l1.08,0.86l0.12,0.89l0.52,0.89l2.05,0.67l0.88,0.5l1.02-0.29l0.43-0.47l0.42-0.66
  		l0.47-0.06l1.15,0.39l0.17,0.61l-0.12,0.26l-0.41,0.26l-0.2,1.91l-1.11,2.4l-0.08,0.48l0.34,0.05l0.81-0.51l2.67-0.94l0.53-0.04
  		l0.61-0.19l1.68-0.72l0.54,0.38l0.45,0.43l0.25,0.01l0.12-0.16l-0.03-0.28l0.12-0.2l0.48,0.11l1.95,1.18l0.75,0.28l0.51,0.08
  		l0.61,0.55l1.66,0.37l0.2,0.29l0.13,0.36l1.56,1.49l0.7,0.75l1.38,0.69l0.59,0.16l2.42-0.7l0.67-0.24l0.56,0l0.57,0.37l1.3,0.49
  		l1.17,0.16l0.21-0.03l0.02-0.27l0.15-0.76l0.24-0.66l0.77-0.71l0.19-0.48l0.23-0.43l1.77-0.51l0.2-0.19l0.01-0.17l-0.08-0.16
  		l-0.39-0.26l-0.41-0.36l-0.25-0.97l-0.01-0.02l-0.16,0.02l-1.07-2.08l-0.21-0.4l-0.4-1.06l-0.86-1.21l-0.74-0.62l-0.67-2.41
  		l-1.6-1.95l0.07-0.97l1.12-0.87l1.61-0.5l1.9-0.15l1.22,0.31l0.75-0.87l0.77-1.75l0.41-1.06l0.34-0.24l0.66-0.06l0.25-0.33
  		l0.13-0.19l0.56-0.18l0.19-0.21L2046.65,750.67z M2004.81,759.67l-0.47,0.23l-0.38,0.59l1.66,0.88l0.7-0.13l0.11-0.31l-0.2-0.9
  		L2004.81,759.67z"/>
    </>,
    ECU: <>
        <path className="st0" d="M1045.1,1319.99l-0.34,0.27l-0.4,0.66l0.49,0.49l1.59,0.29l0.39-0.25l0.17-0.46l-0.96-0.74L1045.1,1319.99z
  		 M1039.89,1322.17l0-0.82l-0.51-0.56l-1.57,0.24l-0.06,0.22l0.06,0.67l0.3,0.44l0.71,0.29l0.85-0.15L1039.89,1322.17z
  		 M1043.6,1323.63l-0.08-0.17l0.15-0.42l-0.21-0.91l-1.69-1.62l-0.28-1.54l-0.52-0.54l-0.28-0.55l-0.47-0.29l-1.09,0.17l-0.15,0.36
  		l-0.74,0.5l0.06,0.14l0.34,0.27l0.68-0.3l0.34,0.11l0.5,2.21l1.01,0.72l0.44,1.03l0.64,0.52l-0.2,0.53l-1.6,0.7l-1.05,0.78
  		l-0.31,0.51l0.1,0.53l0.54,0.6l0.4,0.17l2.03,0.02l1.9-0.66l0.89-1.58l-0.53-0.67L1043.6,1323.63z M1145.09,1308.16l0.08-0.9
  		l-0.2,0.09l-0.58,0.47l0.22,0.4L1145.09,1308.16z M1175.63,1324.56l0.04-0.45l0.16-0.53l-0.03-0.29l-0.52-0.41l-0.84-0.82
  		l-0.35-0.73l-0.21-0.61l-0.59-0.4l-0.6-0.36l0.05-0.29h0.36l0.91,0.29l0.65-0.09l0.49-0.03l0.47-0.3l-1.51-0.57l-1.29-0.85
  		l-1.34-0.22l-0.87-0.52l-0.8-0.81l-0.43-0.55l-0.35-0.27l-1.71-0.79l-0.34-0.08l-0.65,0.36l-0.21,0.22l-0.04,0.63l-0.08,0.36
  		l-0.57,0.22l-0.91-0.05l-0.64-0.23l-0.43-0.03l-0.09,0.18l-0.24,0.08l-0.52-0.05l-0.76-0.17l-0.69-0.23l-0.94-0.49l-0.43,0.06
  		l-1.07-0.11l-0.87-0.28l-0.22-0.26l-0.38-1.77l-0.12-0.12l-0.38-0.08l-0.63-0.24l-0.4-0.29l-0.21-0.49l-0.25-0.47l-1.07,0.1
  		l-1.75-0.61l-1.21-0.58l-1.11-0.65l-1.68-1.28l-0.64-0.32l-0.79-0.39l-0.47-0.63l-0.77-0.64l-0.26-0.17l0.27,1.33l-0.61,0.75
  		l-2.77,0.85l-1.99,0.37l-1.24,0.74l-1.08-0.07l-0.46,0.48l-0.91,0.52l-1.12,0.21l-0.44,0.42l0.23,1.61l0.3,1.52l-0.18,2.13
  		l-0.74,1.35l-0.87,0.9l-0.71,0.44l-1.36,1.69l0.12,0.57l0.7,1.24l0.86,0.31l-0.64,0.04l-0.82-0.33l-0.42,0.82l-0.41,1.37
  		l-0.59,0.42l-1.83,0.64l-0.51,0.87l0.69,1.73l0.16,0.82l-0.28,2.08l0.61,1.59l0.02,0.93l-0.08,1.19l-0.82,0.54l-0.8,0.4l0.09,0.39
  		l0.16,0.28l0.78,0.67l1.3,0.4l1.98,1.92l0.6,0.35l0.79,0.32l0.25-0.35l0.06-0.29l1.02-0.86l0.62-1.15l0.39-0.31l-0.2,1.7l0.35,0.18
  		l0.53-0.25l0.38-1.05l-0.11-2.33l0.43-0.66l0.08,0.36l-0.06,0.48l0.15,1.58l0.65,1.07l0.13,0.79l-0.78,1.66l-0.83,2.62l-0.35,0.57
  		l-0.53,0.59l-0.62,0.38l-0.5,0.42l-1.21,0.42l-0.18,0.11l0.22,0.16l0.21,0.15l0.01,0.31l0.06,0.26l0.17,0.25l0.01,0.46l0.19,0.3
  		l0.01,0.35l0.01,0.47l-0.1,0.24l0.09,0.41l0.32,0.75l-0.13,0.24l-0.31,0.15l-0.31,0.21l-0.31,0.47l-0.46-0.01l-0.67-0.21
  		l-0.45,0.26l-0.17,0.5l0.14,0.42l0.04,0.39l0.29,0.33l0.85,0.03l-0.77,1.07l-0.38,0.48l0.08,0.31l0.46,0.26l0.34,0.02l0.76-0.39
  		l0.52-0.57l0.29-0.32l0.49-0.13l0.64,0.26l0.85,0.53l0.99,0.47l0.4,0.26l0.73-0.07l0.61-0.11l0.51,0.38l0.52,0.32l0.12,1.1
  		l0.39,0.8l0.47,0.62l0.58,0.74l0.53,0.25l0.69,0.01l0.93,0.27l0.36-0.18l0.32-0.51l0.17-0.29l0.47-0.12l0.04-0.33l-0.1-0.4
  		l0.15-0.47l0.39-0.41l1-0.61l0.48-0.25l0.1-0.38l0.18-0.5l-0.07-0.28l-0.2-0.34l0.05-0.49l0.26-0.65l0.38-0.76l0.32-0.97l0.12-0.46
  		l0.35-0.29l0.13-0.42l0.19-0.49l0.43-0.55l-0.01-0.6l0.18-0.26l0.02-0.67l0.43-1.37l0.02-0.28l0.19-0.08l0.33,0.09l0.28,0.31
  		l0.09,0.31l0.12,0.14l0.26-0.03l0.31-0.17l-0.02-0.28l-0.22-0.27l-0.06-0.16l0.1-0.25l0.46-0.56l0.51-0.65l1.09-1.34l0.66-0.55
  		l1.7-0.58l1.28-0.44l1.23-0.42l1.67-0.6l2.36-0.85l1.7-0.61l1.51-1.09l1.17-0.85l1-0.73l1.27-0.93l1.72-2.01l1.19-1.38l0.87-1.01
  		l0.6-0.64l0.48-1.8l0.54-2.05l0.24-0.91l0.1-0.32l0.24,0.13l0.27,0.22l0.33,0.01l0.31-0.01l0.19-0.12L1175.63,1324.56z
  		 M1057.68,1324.42l-0.26-0.08l-0.88,0.35l-0.48,0.59l-0.53,0.28l-0.55,0.52l0.05,0.21l0.21,0.17l0.34,0.16l0.99-0.35l0.51-0.71
  		l0.53-0.34l0.3-0.48l-0.07-0.2L1057.68,1324.42z M1134.79,1343.53l0.32-1.07l0.19-0.1l0.49-0.12l0.88-0.79l-0.74-0.43l-0.7-0.04
  		l-0.55,0.23l-0.66,0.48l-0.22,0.49l-0.19,1.17l-0.01,0.37l0.23,0.1l0.8,0.03L1134.79,1343.53z M1048.04,1329.02l-0.38-0.16
  		l-0.36,0.65l0.46,0.36l0.34-0.02l0.38-0.4l-0.17-0.25L1048.04,1329.02z M1047.72,1322.97l-0.52,0.54l-0.09,0.79l1.31,0.81
  		l0.44-0.01l0.16-0.12l0.46-0.13l0.58-0.7l0.06-0.95l-0.71-0.5L1047.72,1322.97z"/>
    </>,
    DZA: <>
        <polygon className="st1" points="1909.7,1116.59 1908.81,1114.84 1907.61,1112.64 1906.87,1111.3 1906.62,1111.09 1903.26,1110.03
  		1899.71,1108.99 1897.62,1109.62 1897.26,1109.57 1896.67,1109.17 1896.08,1108.63 1895.77,1107.87 1894.93,1106.86
  		1894.16,1104.53 1894.09,1102.68 1893.93,1102.03 1892.08,1099.41 1890.4,1097.03 1889.28,1095.45 1889.06,1094.73
  		1889.19,1093.85 1889.64,1093.05 1891.27,1092.12 1892.74,1091.09 1892.94,1090.38 1893.04,1088.43 1892.55,1087.82
  		1892.2,1086.66 1891.84,1085.08 1891.8,1084.07 1892.45,1082.07 1893.22,1079.96 1892.73,1077.62 1892.37,1072.93 1892.6,1069.22
  		1892.42,1067.88 1892.29,1067.31 1891.79,1065.55 1891.17,1063.74 1890.9,1063.11 1890.1,1061.65 1888.8,1059.83 1888.12,1058.72
  		1889.05,1058.13 1889.87,1057.67 1889.36,1055.5 1888.92,1053.64 1888.56,1052.11 1887.93,1049.41 1887.39,1047.11
  		1886.85,1044.81 1886.37,1042.73 1885.87,1040.62 1885.66,1040.31 1884.19,1039.32 1882.83,1038.4 1881.42,1037.36
  		1879.89,1036.23 1879.64,1034.8 1878.86,1032.65 1878.03,1031.44 1877.72,1031.13 1876.05,1030.35 1875.08,1029.78
  		1874.82,1029.44 1874.63,1028.56 1873.94,1026.81 1873.16,1025.22 1872.87,1024.14 1872.83,1022.78 1872.99,1021.8
  		1873.33,1021.38 1874.96,1020.16 1875.72,1018.69 1876.65,1018.13 1877.47,1017.72 1878.12,1017.23 1878.7,1016.45
  		1879.15,1015.62 1879.23,1014.72 1879.41,1013.29 1879.71,1012.29 1880.4,1011.15 1880.11,1010.24 1879.75,1009.26
  		1879.85,1007.55 1879.76,1006.85 1879.46,1006.24 1879.16,1005.45 1879.15,1004.79 1879.44,1003.06 1879.66,1001.74
  		1880.02,1000.02 1879.89,999.54 1879.63,999.18 1878.84,998.8 1878.83,998.57 1879.02,998.32 1880.19,997.48 1880.82,996.24
  		1881.35,995.99 1882.15,995.54 1882.11,995.06 1881.94,994.54 1878.15,994.8 1876.33,995.32 1875.33,995.09 1873.78,993.94
  		1872.3,993.36 1870.38,993.04 1870.67,994.24 1869.87,994.48 1868.05,994.71 1865.09,993.91 1864.33,993.11 1863,993.49
  		1862.33,994.53 1860.78,995.25 1857.92,995.87 1855.39,997.07 1854.3,997.33 1853.46,997.05 1851.77,995.79 1850.78,995.26
  		1849.78,994.94 1841.53,994.94 1839.35,995.91 1834.74,996.02 1833.68,996.46 1831.54,997.79 1829.43,997.69 1826.33,998.1
  		1820.29,998.57 1817.89,999.29 1816.36,1000.13 1814.04,1001.04 1812.33,1002 1810.98,1002.95 1810.1,1004.51 1809.29,1005.15
  		1808.11,1005.28 1806.75,1004.86 1806.11,1004.88 1801.97,1006.72 1800.54,1007.58 1799.55,1008.37 1798.45,1009.63
  		1797.2,1010.21 1795.6,1011.35 1793.59,1012.2 1792.71,1012.28 1791.01,1012.1 1791.25,1012.81 1791.75,1013.37 1793.52,1014.66
  		1794.58,1015.45 1794.61,1015.72 1794.27,1016.37 1794.12,1016.82 1794.4,1017.3 1795.05,1017.87 1795.1,1018.15 1794.95,1018.47
  		1794.61,1019.09 1795.32,1020.9 1795.44,1022.65 1795.26,1023.9 1795.26,1024.62 1795.36,1025.23 1795.96,1026.64 1795.56,1028.97
  		1796.01,1030.24 1796.64,1031.27 1796.98,1033.11 1797.49,1033.98 1798.31,1034.74 1798.78,1035 1799.69,1035.62 1800.34,1036.15
  		1800.73,1036.93 1799.91,1037.58 1799.25,1038.15 1799.07,1038.77 1799.38,1039.76 1799.38,1040.3 1798.96,1040.47
  		1797.26,1040.41 1795.93,1040.37 1794.4,1040.32 1792.24,1040.22 1790.91,1040.17 1789.08,1040.09 1788.45,1040.13 1786.77,1040.4
  		1785.58,1040.6 1785.38,1040.65 1785.02,1040.9 1784.76,1041.63 1784.53,1042.46 1784.29,1042.84 1780.73,1044.04 1779.34,1044.21
  		1778.54,1044.08 1777.96,1044.18 1777.47,1044.44 1777.3,1044.83 1777.28,1045.32 1777.38,1045.82 1777.73,1046.52
  		1777.79,1047.22 1777.57,1047.71 1777.52,1048.2 1777.41,1048.74 1777.6,1049.02 1777.94,1049.07 1778.28,1049.31 1778.77,1049.54
  		1779.17,1049.96 1779.15,1050.56 1778.82,1050.9 1778.52,1051.08 1777.19,1051.24 1776.13,1051.36 1774.76,1052.32
  		1773.29,1053.34 1771.55,1054.02 1770.79,1054.21 1769.45,1054.69 1767.86,1055.5 1767.07,1056.77 1766.07,1058.25
  		1765.12,1059.23 1763.81,1060.17 1762.59,1060.53 1761.06,1060.97 1759.13,1061.32 1757.76,1061.43 1757.36,1061.51
  		1756.15,1061.53 1755.56,1061.46 1755.13,1061.42 1754.95,1061.52 1754.89,1061.76 1754.86,1062.29 1754.78,1062.89 1754.4,1063.4
  		1754.13,1063.63 1753.82,1063.73 1752.81,1063.59 1751.96,1063.43 1749.94,1063.21 1749.54,1063.26 1749.39,1063.32
  		1748.77,1063.67 1747.8,1064.4 1747.14,1065.04 1746.65,1065.34 1745.48,1065.5 1744.97,1065.73 1742.79,1067.33 1742.33,1067.72
  		1740.09,1069.1 1739.45,1069.56 1738.96,1070.02 1737.62,1071.04 1736.76,1071.49 1736.61,1071.75 1736.57,1072.38
  		1736.57,1073.76 1736.57,1075.08 1736.57,1077 1736.57,1078.92 1736.57,1081.13 1736.57,1082.63 1736.57,1084.48 1738.15,1085.48
  		1739.73,1086.47 1741.32,1087.47 1742.9,1088.47 1744.48,1089.46 1746.07,1090.46 1747.65,1091.45 1749.24,1092.45
  		1750.82,1093.44 1752.4,1094.43 1753.99,1095.43 1755.57,1096.42 1757.16,1097.41 1758.74,1098.4 1760.32,1099.39 1761.91,1100.38
  		1763.23,1101.21 1765.27,1102.54 1767.17,1103.78 1769.08,1105.03 1771.66,1106.73 1773.99,1108.34 1776.75,1110.25
  		1779.5,1112.15 1782.26,1114.05 1785.02,1115.95 1787.78,1117.85 1790.54,1119.74 1793.3,1121.63 1796.05,1123.52 1798.81,1125.41
  		1801.57,1127.3 1804.33,1129.18 1807.09,1131.07 1809.84,1132.95 1812.6,1134.83 1815.36,1136.71 1818.12,1138.58 1819.35,1139.42
  		1819.46,1139.77 1819.58,1140.47 1819.51,1141.26 1819.52,1141.91 1819.88,1142.35 1820.57,1142.82 1823.27,1144.2
  		1823.48,1144.47 1823.58,1145.04 1823.9,1145.74 1824.47,1146.15 1825.13,1146.46 1825.95,1146.67 1828.39,1146.88
  		1828.91,1147.21 1829.97,1148.49 1830.54,1148.74 1832.17,1149.11 1833.35,1149.31 1833.84,1149.43 1834.91,1149.77
  		1836.07,1150.35 1836.68,1150.87 1836.68,1151.04 1836.68,1151.5 1836.67,1152.87 1836.88,1153.62 1837.12,1154.17 1837.11,1154.5
  		1836.82,1154.74 1836.59,1155.03 1836.46,1155.41 1836.13,1155.9 1835.86,1156.44 1835.98,1156.84 1836.44,1157.11
  		1837.12,1157.62 1837.69,1157.84 1837.97,1157.85 1838.34,1157.84 1838.66,1157.77 1840.73,1157.38 1842.63,1157.01
  		1845.31,1156.5 1847.15,1156.14 1849.05,1155.76 1851.82,1155.21 1854.83,1154.62 1858.12,1153.97 1858.86,1153.58
  		1861.34,1151.38 1862.45,1150.38 1864.68,1148.41 1866.39,1146.88 1868.57,1144.94 1870.88,1142.98 1872.72,1141.42
  		1875.61,1139.65 1879.97,1136.98 1884.33,1134.3 1888.69,1131.62 1893.05,1128.93 1897.42,1126.25 1901.78,1123.55
  		1906.14,1120.86 1910.5,1118.16 	"/>
    </>,
    DOM: <>
        <polygon className="st1" points="1233.76,1160.59 1233.22,1160.21 1231.2,1158.56 1229.38,1157.84 1228.29,1157.62 1227.18,1157.49
  		1226.19,1157.29 1225.22,1156.99 1224.27,1156.8 1223.3,1156.72 1223.3,1156.35 1223.45,1155.95 1224.17,1155.9 1225.83,1155.99
  		1226.32,1155.78 1226.59,1155.38 1225.81,1154.89 1222.32,1155.14 1221.61,1154.55 1221.15,1153.63 1221.04,1152.61
  		1220.49,1151.9 1220.01,1151.89 1219.04,1152.21 1218.49,1152.19 1217.56,1151.86 1216.45,1151.04 1216.09,1150.99 1214.77,1151
  		1214.35,1150.84 1213.51,1150.34 1213.1,1150.03 1212.09,1149.79 1211.01,1150 1209.72,1150.37 1209.33,1150.37 1207.98,1149.97
  		1207.01,1149.95 1206.52,1150.11 1206.08,1150.36 1205.76,1150.83 1205.51,1151.35 1205.14,1151.5 1205.32,1151.76
  		1205.71,1153.51 1205.75,1154.07 1205.36,1154.92 1205.42,1155.26 1206.25,1156.04 1206.27,1156.32 1206.17,1156.6
  		1205.45,1157.34 1204.91,1157.85 1205.08,1158.43 1205.53,1158.98 1205.58,1159.45 1205.44,1160.05 1204.76,1160.81
  		1204.41,1161.08 1203.39,1161.12 1203.28,1161.22 1203.78,1161.96 1204.36,1162.8 1205.29,1163.45 1205.49,1164.06
  		1205.27,1164.64 1205.23,1166.06 1205.7,1166.35 1206.03,1166.8 1206.17,1167.36 1206.16,1167.95 1206.38,1168.36 1206.91,1168.5
  		1207.34,1168.78 1208.01,1169.55 1208.37,1169.46 1208.69,1169.04 1209.45,1167.7 1210.81,1165.79 1211.01,1165.29
  		1211.01,1164.46 1211.12,1164.23 1211.47,1164.04 1212.34,1163.87 1213.74,1163.41 1214.7,1163.49 1215.36,1164.09
  		1216.08,1164.52 1218.58,1164.22 1218.93,1164 1219.59,1163.41 1219.97,1163.17 1221,1162.79 1222.06,1162.56 1223.12,1162.62
  		1224.17,1162.8 1225.21,1162.76 1226.24,1162.59 1227.94,1162.95 1229.1,1162.87 1230.07,1163.46 1230.42,1164.1 1230.9,1164.51
  		1231.18,1164.54 1231.42,1164.48 1231.82,1163.75 1232.22,1163.32 1232.82,1163.12 1233.23,1162.79 1233.95,1161.74
  		1234.12,1161.11 	"/>
    </>,
    DMA: <>
        <polygon className="st1" points="1293.59,1187.69 1293.23,1187.19 1292.07,1186.78 1291.97,1187.03 1291.88,1187.7 1292.43,1188.78
  		1292.77,1190.25 1293.56,1190.07 1293.81,1189.01 	"/>
    </>,
    DNK: <>
        <path className="st0" d="M1893.92,804.02l-1.42-0.84l-0.41-0.13l-0.08,0.11l0.3,1.8l0.82,0.11l0.45,0.28l0.88-0.16l-0.03-0.25
  		L1893.92,804.02z M1898.36,794.64l0.53,0.22l0.14-0.21l0.17-0.6l0.29-0.52l-0.21-0.43l-0.75-0.92l-0.26,0.4l0.24,0.62l-0.2,0.69
  		L1898.36,794.64z M1902.79,777.08l0.21-0.29l0.82-0.56l-0.75-0.09l-1.27,0.26l-0.51,0.56l1.16,0.4L1902.79,777.08z M1896.85,804.97
  		l-0.69-0.5l-0.55-0.16l0.13,0.25l1.05,0.96l0.65,0.25l0.57-0.12l0.17-0.15l-0.77-0.42L1896.85,804.97z M1891.67,804.24l-0.37-0.38
  		l-0.36-0.27l-0.62-0.21l-1,0.01l0.43-0.9l1.17-1.04l0.23-0.72l-0.26-0.91l-0.12-0.82l-0.29-0.94l0.59-0.76l0.94-0.6l0.31-0.51
  		l0.75-0.67l0.84-0.33l0.21-0.31l-0.52-0.61l-0.49-0.35l0.96-0.39l0.76,0.02l0.44,0.24l0.2-0.13l0.37-1.66l0.78-2.47l0.46-0.46
  		l0.45-0.29l0.94,0.9l0.69-0.02l1.11-0.47l0.87-0.64l0.32-0.76l0.27-1.01l-0.37-0.59l-0.31-0.35l-3,0.01l-0.9-0.41l-0.85-0.79
  		l0.12-1.92l-0.08-0.5l0.08-2.12l0.36-0.27l0.83-1.82l0.73-0.86l-0.06-1.65l0.16-0.84l-0.78-1.38l0.13-0.64l0.17-0.41l1.09-1.07
  		l-0.65,0.02l-2.31,1.44l-2.5,0.44l-1.24,1.24l-2.2,2.98l-1.02,0.7l-1.14,0.34l-2.21-0.11l-0.71,0.06l-1.19,0.49l-1.63-0.01
  		l-1.61,1.53l-1.2,1.59l-0.15,0.44l0.02,0.74l0.66,0.5l1.02,0.57l1.14-0.55l1.42-0.18l0.88-1.94l0.98-1.55l0.99-0.33l1.22,0.38
  		l-0.38,2.44l-0.11,1.28l-0.47-0.58l-0.62-0.52l-0.61,0.23l-0.9,0.48l-1.28,1.29l-0.15,1l-0.39,0.58l-0.54-0.23l-0.46-0.55
  		l-0.67-0.06l-1.61-0.61l-0.42-0.02l-0.57,0.13l-0.29,3.41l-0.07,2.16l0.68,1.87l-0.18,0.96l-0.41,3.56l1.8,1.06l2.28,1.08l0.3,1.05
  		l0.16,2.03l-0.82,0.25l0.55,1.04l0.19,0.7l0.07,0.97l0,0l1.57,0.03l0.38,0.05l2.38,0.61l0.58,0.43l0.73,0.02l1.32-0.4l0.99-0.17
  		l0.38,0.25l0.54,0.1h0.12l-0.29-1.2L1891.67,804.24z M1900.14,798.62l-0.43-1.31l-0.35-0.62l-0.19-0.04l-0.99,0.65l-0.68-0.03
  		l-0.59-0.46l-0.57-0.14l-2.46,0.89l-1.13,0.23l-0.01,1.86l0.6,1.51l0.31,0.28l0.18,0.5l2.24,0.88l1.59,0.46l1.52-0.04l1.36-0.95
  		l0.19-0.82l-0.19-0.78l0.29-0.61L1900.14,798.62z M1901.14,803.25l-1,1.06l-0.39,0.69l-0.6,0.61l0.07,0.3l0.51,0.95l0.37-0.07
  		l0.26-0.57l1.31-3.07l0.25-1.1l-0.22-0.02L1901.14,803.25z M1908.58,806.12l-0.69-0.3l-1.01,0.04l-1.49-0.73l-0.87-0.7l-1.68,0.13
  		l-0.14,0.55l-0.05,1.4l3.55,1.68l1.08-0.39l0.8,0.1l0.72-0.3l-0.06-1.03L1908.58,806.12z M1915.99,795.87l-0.18-0.01l-0.25,0.36
  		l-0.42,0.42l0.26,0.69l0.17,0.03l0.79-0.5l-0.15-0.59L1915.99,795.87z M1937.16,802.17l-3.09-1.79l-0.44,0.69l-0.25,1.59l1.7,0.81
  		l1.39,0.33l0.31-0.2l0.42-0.76L1937.16,802.17z M1914.73,803.66l-0.44-0.16l-0.9-0.11l-0.3-0.27l-0.13,0.5l-0.33,0.32l-0.49,0.22
  		l-0.15,0.19l-0.21,0.52l0.55,0.26l1.46-0.81l1.29,0.13l0.32-0.17l-0.3-0.37L1914.73,803.66z M1913.49,801.08l0.76-0.58l-0.23-1.17
  		l-0.92-0.34l-0.51-0.62l0.24-0.84l0.65-0.59l0.73-0.34l0.84-0.24l0.32-0.23l0.22-0.34l-0.02-1.18l-0.37-1.58l0.15-0.48l0.55-0.88
  		l-0.25-0.37l-0.45-0.23l-0.82-0.27l-0.88-0.19l-0.88,0.04l-1.51,0.79l-1.46,1l0.39,0.37l0.18,0.49l-0.11,0.8l-0.31,0.24l-0.23,0.43
  		l-0.32,0.88l-0.3-0.05l-0.78-0.32l-0.07-1.19l0.12-0.93l-0.57-0.58l-1.29,0.16l-0.13,0.43l0.03,0.33l-1.19,1.5l-0.39,0.19
  		l-0.43,0.06l-1.47-0.11l-0.6,0.22l0.25,0.91l0.52,0.18l0.43,0.34l-0.01,0.41l0.07,0.37l0.52,0.82l-0.16,1.61l0.97,1.46l0.2,0.08
  		l0.81-0.2l0.58,0.04l1.5,0.29l0.36,1.07l0.06,0.67l0.31,0.78l0.01,0.67l1.02,1.67l1.58-0.5l0.16-1.09l0.04-0.79l-0.06-1.09
  		l0.21-1.39L1913.49,801.08z"/>
    </>,
    DJI: <>
        <polygon className="st1" points="2174.85,1214.65 2174.38,1213.83 2172.97,1212.16 2172.85,1211.75 2171.92,1212.14 2170.89,1212.49
  		2170.74,1212.48 2170.4,1212.93 2169.91,1214.17 2169.37,1214.54 2169.09,1214.57 2167.48,1213.4 2167.24,1213.34 2166.89,1213.57
  		2166.63,1213.8 2165.81,1215.01 2164.7,1216.63 2163.41,1218.51 2163.02,1218.97 2161.89,1220.11 2161.7,1220.43 2161.48,1221.25
  		2161.46,1222.75 2161.61,1224.65 2161.75,1226.41 2162.37,1226.62 2163.09,1226.74 2163.88,1226.51 2164.85,1226.32
  		2166.04,1226.2 2167.36,1225.85 2168.14,1225.56 2168.96,1225.58 2169.69,1225.89 2170.04,1226.17 2170.56,1226.26
  		2171.22,1226.25 2172.28,1224.6 2173.21,1223.15 2173.94,1222.01 2173.23,1221.45 2172.23,1221.26 2171.12,1221.27 2170.1,1221.48
  		2168.94,1221.93 2168.36,1222.03 2167.99,1221.97 2167.84,1221.4 2168.84,1221.5 2170.18,1219.98 2172.27,1219.22 2174.16,1218.02
  		2174.7,1217.54 2175.07,1216.99 2175.32,1216.15 	"/>
    </>,
    DEU: <>
        <path className="st0" d="M1920.56,811.27l0.24,0.45l1.47,0.93l0.42-0.04l0.57-1.02l0.95-0.01l0.95,0.66l0.23-0.4l-0.21-0.78l-0.9-0.49
  		l-0.18-0.45l0.19-0.28l0.57-0.55l-0.11-0.28l-0.18-0.2l-1.22-0.45l-0.35-0.4l-0.23-0.58l-0.72,0.03l-0.82,0.68l-0.07,0.65
  		l-0.46,0.45l0.04,0.41l-0.21,1.3L1920.56,811.27z M1926.54,815.07l0.41,0.46l-0.16,0.67l0.2,0.69l1.03,0.18l1.04-0.13l0.35,0.04
  		l-0.13-0.56l0.11-0.36l-1.45-0.97l-1.78-1.07l-0.06,0.4l0.06,0.38L1926.54,815.07z M1903.44,810.67l1.29-0.02l-0.02-0.24
  		l-0.39-0.73l-1.25-0.37l-0.35,0.21l-0.27,0.57l0.5,0.12L1903.44,810.67z M1881.02,806.7l-0.43,0.22l-0.15,0.29l0.45,0.27l0.8,0.03
  		l0.33-0.29l-0.12-0.42l-0.53-0.14L1881.02,806.7z M1935.64,845.28l-0.15-0.65l-0.25-0.31l-1.53-0.68l-0.12-0.24l0.23-0.92
  		l-0.12-0.39l-0.37-0.41l-0.48-0.81l-0.19-0.69l0.62-0.81l0.15-0.6l0.27-0.82l0.2-0.45l0.04-0.12l-0.4-0.32l-0.1-0.45l0.11-0.65
  		l-0.21-0.48l-0.54-0.31l-0.35-0.41l-0.16-0.51l0.13-0.81l0.42-1.1l-0.89-1.33l-2.19-1.56l-1.05-1.09l0.09-0.62l0.46-0.56l0.84-0.51
  		l0.63-0.9l0.36-1.07l0.01-0.2l0.02-0.76l-0.98-3.13l-0.16-0.78l-0.12-0.95l-0.06-0.26l-0.07-0.03l-1.9-0.41l-0.63-0.39l-0.71-0.6
  		l-0.36-1.91l-0.83-1.55l-2.33,0.14l-2.53-1.64l-1-1.49l-1.1-0.14l-1-0.27l-1.72-0.25l-1.66,1.4l-0.69,0.73l-1.07,0.67l-0.48,0.67
  		l-2.65,0.26l-0.81,0.37l-2.02,1.72l-0.52,0.23l-2.49-0.75l-1.57,0.16l-0.53-0.17l-0.37-0.76l1.67-1.23l0.47-1.15l-0.43-1.14
  		l-0.48,0.04l-1.89,0.69l-3.13-1.42l-1.25,0.34l-0.35-0.48l-0.23-0.44l-2.31,0.19l0.61-0.49l0.74-0.78l-0.06-1.08l-0.58-0.75
  		l-0.52-0.49l-1.23-0.31l-0.05-0.21h-0.12l-0.54-0.1l-0.38-0.25l-0.99,0.17l-1.32,0.4l-0.73-0.02l-0.58-0.43l-2.38-0.61l-0.38-0.05
  		l-1.57-0.03l0,0l0.1,1.3l0.9,1.12l0.77,1.19l0.64,0.65l-0.05,0.82l-1.02,0.47l-1.54,0.35l-0.19,0.51l0.16,0.68l0.77,0l0.37-0.21
  		l0.6,0.16l0.46,0.45l-0.03,3.01l0.14,0.4l0.49,0.45l0.77,0.29l1.24,0.11l0.81,0.37l2.69,2.98l1.29,0.52l-0.93-0.13l-0.74-0.4
  		l-2.22-2.45l-0.98-0.49l-2.59,0.23l-2.35-0.45l-0.37,0.42l-0.4,0.66l-0.19,1.27l0.27,1.31l-0.36,1.87l-0.02-1.38l-0.35-0.43
  		l-0.99-0.63l-0.27,0.25l-0.19,0.84l-0.28,0.76l-0.37,0.15l-0.78-0.4l0.49-0.87l-1.33-1.69l-3.2-0.08l-2.9,0.18l-0.66,0.31
  		l-0.84,1.12l-0.28,0.91l-0.18,1.17l0.83,0.56l0.38,0.51l-0.07,1.09l0.01,2.14l-0.09,0.38l-0.53,0.9l-0.56,1.62l-0.15,1.06
  		l-0.17,0.2l-2.23-0.01l-0.32,0.18l-0.05,0.23l0.11,0.27l-0.05,0.27l-0.18,0.22l0.1,0.35l0.39,0.4l0.7,0.25l0.75,0.02l0.39-0.04
  		l0.28,0.28l0.28,0.44l-0.02,0.55l-0.11,0.74l-0.36,0.68l-1.03,0.79l-0.46,0.28l-0.43,0.14l-0.21,0.21l-0.1,0.26l0.02,0.23
  		l0.73,0.63l-0.02,0.14l-0.21,0.33l-0.28,0.31l-1.89,0.64l-0.78-0.05l-0.45,0.32l-0.14,0.06l-0.49-0.29l-1.1-0.34l-0.42,0.12
  		l-0.23,0.18l-0.69,0.22l-0.5,0.35l0,0.45l0.88,1.17l0.31,0.23l0.01,0.44l0.43,0.55l0.43,0.68l0.05,0.44l-0.05,0.44l-0.22,0.62
  		l-0.76,1.46l-0.01,0.28l0.06,0.21l0.26,0.06l0.2,0.11l-0.06,0.19l-1.42,1.01l-0.18,0.18l-0.6-0.05l-0.09,0.17l0.08,0.27l0.23,0.24
  		l0.51,0.13l0.44,0.26l0.35,0.5l-0.46,1.71l0.1,0.2l0.96,0.59l0.3,0.46l0.69,0.45l-0.57,0.57l0.09,0.25l0.2,0.26l0.77,0.15
  		l0.39,0.37l0.02,0.57l0.18,0.93l-1.59,0.92l-0.45,1.03l-0.04,0.2l-0.07,0.29l0.01,0.66l0.24,0.66l0.56,0.65l0.43,0.47l0.58,0.38
  		l0.98,0.36l0.39,0.07l0.05,0.48l-0.08,0.51l-0.34,0.28l-0.32,0.41l-0.24,0.5l-0.25,0.95l-0.03,0.65l0.32-0.06l0.64,0.17l0.64,0.53
  		l0.27,0.53l0.07,0.29l0.28,0.31l1.08,1.42l0.34,0.07l0.37-0.21l0.24-0.3l0.35-0.06l0.56,0.14l0.36,0.16l0.17,0.61l0.12,0.12
  		l0.24-0.13l0.44-0.03l0.69,0.15l0.96-0.17l0.76-0.26l0.39,0.01l0.63,0.72l0.72,0.27l1.59,0.22l1.7,0.34l0.67,0.27l0.46,0.13
  		l0.05,0.95l-0.14,0.14l-1.7,1.89l-0.71,0.68l-0.36,0.96l-0.25,1.48l-0.5,1.4l-0.75,1.33l-0.27,0.99l0.2,0.66l-0.13,1.04l-0.46,1.41
  		l-0.08,1.07l0.3,0.72l0.42,0.15l0.69,0.24l1.93,0.06l1.4-0.13l0.88-0.33h1.09l0.73,0.19l0.13-0.03l0.2-0.04l0.2-0.18l0.69-0.12
  		l0.09-0.15l-0.03-0.15l-0.12-0.08l-0.85,0.08l-0.32-0.12l-0.08-0.27l0.27-0.47l0.62-0.38l0.53-0.09l0.38,0.1l0.93,0.71l0.22,0.02
  		l0.13-0.13l0.19-0.07l0.32,0.14l0.36,0.44l0.06,0.07l2.08-0.15h0.47l-0.59-1.09l0.16-0.11l0.83,0.52l1.2,0.61l1.19,0.55l1.1,0.62
  		l0.43,0.25l0.17-0.08l0.28-0.26l0.76,0.25l1.12,0.5l0.53,0.34l0.22,0.26l0.12,0.22l-0.07,0.37l0.25,0.15l0.52,0.06l0.35,0.12
  		l-0.12,0.49l-0.02,0.41l0.49-0.06l0.61-0.31l0.47-0.56l0.29-0.54l0.22-1.32l0.08-0.11l0.37,0.1l1.48-0.06l0.7,0.25l1.11,0.04
  		l-0.02,0.21l0.2,0.32l0.49,0.47l0.24,0.3l0.51,0.05l0.79-0.17l0.46-0.17l0.17,0.12l0.72-0.12l0.64-0.38l0.16-0.29l0.65-0.2
  		l0.88-0.47l1.2-0.36l3.95-0.39l0.15-0.29l-0.06-0.67l0.1-0.1l0.5,0.17l0.8,0.16l0.61,0.24l0.4,0.31l0.37,0.01l0.57-0.22l0.77-0.14
  		l0.72,0.32l0.21,0.35l-0.13,0.18l0.01,0.28l0.22,0.23l0.59,0.38l0.75,0.33l0.39-0.03l0.14-0.32l0.14-0.76l0.05-0.81l-0.17-0.47
  		l-0.4-0.11l-0.48-0.04l-0.26-0.1l0.09-0.26l0.39-0.66l-0.01-0.89l-0.87-1.01l-0.76-0.98l0-0.33l0.45-0.58l0.7-0.46l1.56-0.77
  		l0.49-0.16l0.63-0.13l0.91-0.32l0.44-0.32l0.29-0.35l0.43-1.84l0.1-0.08l0.13-0.11l1.59,0.64l0.14-0.11l0.27-0.1l0.52-0.49
  		l0.11-0.37l-0.01-0.7l0.05-0.66l0.1-0.21l-0.38-0.53l-0.72-0.66l-1.16-0.9l-0.9,0.04l-0.33-0.24l-0.15-0.33l-0.38-0.57l-0.42-0.4
  		l-0.51-0.15l-0.74-0.51l-0.98-1.11l-0.9-0.77l-0.87,0.01l-0.55-0.4l-0.56-0.53l-0.41-0.51l-0.64-1.24l-0.47-0.71l-0.36-0.44
  		l-0.41-0.37l-0.15-0.29l0.5-0.66l0.18-0.33l0.22-0.25l0.13-0.27l0-0.2l-0.46-0.66l-0.62-0.47l-0.91-0.48l-0.58-0.61l-0.21-0.56
  		l-0.06-0.31l-0.4-0.41l-0.32-0.61l0-0.37l0.08-0.1l0.3,0l0.34,0.25l0.47,0.48l0.39,0.7l0.24-0.27l0.45-0.74l0.79-0.84l0.81-0.48
  		l0.73-0.04l0.6-0.13l0.5-0.24l0.87,0.1l0.63,0.17l0.2-0.11l0.26-0.44l0.16-0.38l1.39-0.22l0.48-0.73l0.27,0l0.31-0.11l0.29-0.28
  		l0.28-0.11l0.22,0.14l0.29,0.09l0.31-0.18l0.45-0.84l0.25-0.13l1.22-0.13l1.66-0.5l0.84-0.44l0.83-0.24l0.89-0.43l1.41-0.42
  		l0.07-0.17l-0.65-0.43l-0.22-0.27l-0.15-0.28l0.23-0.31l0.31-0.09l0.4,0.13l1.18,0.18l0.32,0.18l0.12,0.43l0.3,0.4l0.24,0.04
  		l-0.09,0.66l0.38,0.25l0.55,0.2l0.36-0.04l0.26-0.27l0.1-0.18l0.04-0.14l0.87-1.52l0.39-0.96l0.44-1.76L1935.64,845.28z
  		 M1880.01,805.65l2.13-0.21l0.24-0.31l-2.1-0.09l-0.07-0.35l0.16-0.67l0.11-0.33l0.4-0.48l-0.39-0.04l-0.92,1.76l-0.09,1.65
  		l0.19-0.23L1880.01,805.65z"/>
    </>,
    CZE: <>
        <polygon className="st1" points="1968.29,865.57 1968.1,864.76 1966.31,863.18 1966.1,862.53 1966.17,862.27 1966.04,861.85
  		1965.65,861.6 1964.24,861.3 1963.88,861.47 1963.55,861.29 1963.03,860.92 1962.14,860.61 1962.04,860.45 1961.72,860.18
  		1961.54,860.14 1961.43,860.31 1961.17,860.54 1960.25,860.83 1959.88,860.71 1959.55,860.46 1959.17,859.9 1958.62,859.42
  		1958.16,859.25 1957.91,858.99 1957.85,858.8 1958.86,858.4 1959.08,857.99 1958.95,857.24 1958.8,857.15 1958.4,857.4
  		1957.55,857.62 1956.78,857.73 1956.38,857.73 1954.16,856.36 1952.72,855.95 1951.87,855.83 1951.78,855.96 1952.17,856.73
  		1952.83,857.67 1952.8,857.92 1952,858.31 1951.55,858.48 1951.02,858.8 1950.57,859.25 1950.18,859.46 1949.84,859.41
  		1949.49,859.19 1948.57,857.8 1947.41,856.73 1947.28,856.49 1946.91,856.43 1946.4,856.19 1946.23,855.86 1946.49,855.52
  		1946.84,855.21 1947.46,855.01 1947.65,854.83 1947.77,854.56 1948,854.21 1947.94,854.08 1947.49,853.68 1946.84,853.3
  		1945.02,853.58 1944.52,853.79 1944.24,853.52 1944.03,853.14 1943.57,853.06 1942.94,852.71 1942.19,852.37 1941.46,852.27
  		1939.95,851.77 1939.36,851.74 1939.02,851.57 1938.67,851.19 1938.37,850.78 1938.22,849.94 1937.1,849.56 1935.99,849.32
  		1935.91,849.44 1935.95,850.29 1935.89,850.74 1935.16,851.02 1934.43,851.05 1934.33,851.23 1934.07,851.5 1933.71,851.54
  		1933.16,851.34 1932.78,851.08 1932.87,850.43 1932.63,850.38 1932.33,849.98 1932.21,849.55 1931.89,849.37 1930.71,849.19
  		1930.31,849.06 1930,849.15 1929.77,849.46 1929.92,849.74 1930.14,850.01 1930.79,850.43 1930.72,850.6 1929.32,851.02
  		1928.43,851.45 1927.6,851.69 1926.76,852.13 1925.1,852.62 1923.88,852.76 1923.63,852.89 1923.17,853.73 1922.87,853.9
  		1922.57,853.81 1922.35,853.67 1922.07,853.79 1921.77,854.07 1921.46,854.18 1921.2,854.17 1920.72,854.9 1919.33,855.13
  		1919.17,855.51 1918.91,855.95 1918.71,856.05 1918.08,855.88 1917.22,855.78 1916.72,856.02 1916.12,856.16 1915.4,856.2
  		1914.58,856.68 1913.79,857.52 1913.35,858.27 1913.11,858.53 1912.72,857.83 1912.24,857.36 1911.91,857.11 1911.61,857.11
  		1911.53,857.21 1911.53,857.57 1911.85,858.18 1912.24,858.6 1912.31,858.9 1912.52,859.46 1913.1,860.06 1914.01,860.54
  		1914.63,861.02 1915.08,861.67 1915.09,861.87 1914.96,862.14 1914.75,862.39 1914.56,862.72 1914.06,863.38 1914.21,863.67
  		1914.62,864.04 1914.98,864.48 1915.45,865.19 1916.09,866.43 1916.51,866.94 1917.07,867.46 1917.62,867.86 1918.49,867.85
  		1919.39,868.62 1920.38,869.73 1921.11,870.23 1921.63,870.39 1922.05,870.79 1922.43,871.36 1922.57,871.69 1922.91,871.93
  		1923.81,871.88 1924.96,872.78 1925.68,873.44 1926.06,873.97 1926.3,874.05 1926.98,874.39 1927.52,874.78 1928.03,875.75
  		1929.21,876.01 1930.71,876.04 1931.25,875.6 1931.73,875.5 1932.28,875.63 1933.44,875.79 1933.57,875 1934.24,874.18
  		1934.54,873.9 1935.39,873.93 1935.6,873.32 1935.8,871.62 1935.98,871.43 1936.6,871.47 1937.22,871.78 1937.4,872.03
  		1937.72,872.01 1938.17,871.84 1938.66,871.73 1939.43,871.91 1941.09,872.68 1941.94,872.96 1942.48,872.91 1942.99,872.92
  		1944.94,874.1 1946.31,874.27 1947.55,874.28 1947.95,873.92 1948.49,873.61 1949.04,873.66 1949.52,873.81 1950.46,874.33
  		1950.9,874.46 1951.48,874.54 1951.9,874.66 1952.28,875.55 1952.49,875.79 1952.76,874.95 1953.42,873.82 1954.03,873.17
  		1954.47,872.95 1955.38,873.15 1956.95,873.31 1958.15,873.16 1959.27,872.66 1959.88,872.22 1960.4,871.76 1960.58,871.46
  		1960.81,871.32 1961.73,871.05 1962.03,870.74 1962.15,870.15 1962.23,869.49 1962.42,869.01 1962.66,868.65 1964.38,867.79
  		1964.53,867.49 1964.81,867.19 1965.32,866.86 1965.81,866.39 1966.33,866.1 1967,866.13 1967.62,866.07 1968.1,865.9
  		1968.32,865.89 	"/>
    </>,
    CYP: <>
        <polygon className="st1" points="2099.97,1007.44 2100.75,1006.79 2099.53,1007.1 2098.36,1007.66 2097.69,1007.9 2096.6,1008.58
  		2092.76,1009.72 2091.51,1009.9 2090.24,1009.84 2088.68,1009.68 2087.15,1009.38 2087.02,1010.45 2086.63,1011.38
  		2085.73,1011.58 2085.22,1011.47 2084.72,1011.36 2083.9,1011.61 2083.22,1012.24 2082.51,1012.62 2081.76,1012.3 2081.89,1013.54
  		2082.71,1015.2 2083,1015.66 2083.48,1015.88 2085.06,1016.42 2085.54,1016.44 2086.52,1016.31 2086.92,1016.55 2087.15,1017.12
  		2087.71,1017.18 2087.84,1016.89 2087.85,1016.54 2088.17,1016.18 2088.62,1015.98 2089.13,1015.96 2090.14,1015.77
  		2091.14,1015.46 2091.98,1014.93 2093.53,1013.38 2094.04,1013.35 2094.57,1013.42 2095.53,1013.36 2096.49,1013.2
  		2096.26,1012.66 2096.1,1012.47 2095.49,1011.76 2095.29,1011.17 2095.58,1010.31 	"/>
    </>,
    CXR: <>
        <path className="st0" d="M2625.44,1421.11l-0.1-0.04l-0.04-0.09l0.03-0.09l-0.04-0.14l-0.01-0.07v-0.08h-0.06l0.02,0.21l0.06,0.25
  		l0.12,0.15l0.2-0.08l-0.05-0.05L2625.44,1421.11z M2626,1421l-0.05,0.07l-0.08,0.04l-0.1,0.01l0.01,0.05l0.02,0.03l0.08,0.04
  		l0.1-0.05l0.05-0.08l0.01-0.1l-0.04-0.1L2626,1421z M2700,1406.23l-0.3,0.16l-0.21,0.02l-0.42,0.06l-0.1,0.45l0.51,0.11l0.44,0.33
  		l0.24-0.6L2700,1406.23z"/>
    </>,
    CW: <>
        <polygon className="st1" points="1230,1216.42 1228.44,1215.8 1227.9,1214.86 1227.55,1214.6 1227.21,1214.54 1227.25,1215.23
  		1228.59,1216.56 1230.21,1217.38 1230.65,1217.26 	"/>
    </>,
    CPV: <>
        <path className="st0" d="M1599.67,1175.7l-0.46,0.08l-0.67,0.37l-0.19,0.33l0.62,0.3l0.43,0.03l0.69-0.21l-0.04-0.24L1599.67,1175.7z
  		 M1604.26,1191.87l-0.88,0.5l-0.17,0.42l0.21,0.49l0.44,0.34l0.46,0.14l0.66-0.33l0.1-0.63l-0.28-0.77L1604.26,1191.87z
  		 M1599.31,1174.21l-0.46-0.7l-0.66-0.15l-1.88,0.88l-0.04,0.2l0.17,0.45l0.11,0.68l0.35,0.09l0.82-0.18l1.29-0.89L1599.31,1174.21z
  		 M1606.82,1178.28l-1.54-0.19l-0.89-0.28l-0.14,0.11l-0.04,0.4l0.64,1.08l0.33-0.71l0.33-0.2l1.25,0.33l0.52-0.09l-0.11-0.18
  		L1606.82,1178.28z M1611.1,1190.82l-1.02-0.95l-0.06-0.39l-0.34-0.1l-0.09,0.15l0.04,0.58l-0.24,0.66l-0.02,0.76l0.67,0.99
  		l0.57,0.32l1.12,0.06l0.51-0.79l-0.77-1.12L1611.1,1190.82z M1616.92,1177.96l-0.13-0.63l-0.01-0.96l-0.24,0.02l-0.49,0.28
  		l0.09,0.93l0.18,0.15l0.33,0.65L1616.92,1177.96z M1618.57,1182.18l-0.48-0.45l-0.45-0.03l-0.27,0.06l-0.7-0.16l0.01,0.76
  		l-0.36,0.89l0.63,0.45l0.54,0.06l0.93-0.49l0.24-0.6L1618.57,1182.18z M1614.82,1189.48l-0.61-0.05l-0.31,0.57l0.04,0.14
  		l-0.08,0.53l0.35,0.39l0.23-0.03l0.56-0.26l-0.03-0.87L1614.82,1189.48z"/>
    </>,
    CUB: <>
        <path className="st0" d="M1147.45,1127.25l0.68,0.21l1.04,0.03l0.29,0.13h0.4l0.5-0.09l-0.57-0.73l-0.41-0.08l-0.39,0.03l-0.4,0.11
  		l-1.16-0.19l-0.55,0.16l0.18,0.22L1147.45,1127.25z M1153.43,1130.77l0.16,0.45l0.65,0.43l0.33,0.15l0.24,0.04l0.38,0.39l0.35-0.27
  		l0.19-0.39l0.1-0.51l-1.19-0.25l-0.68-0.08L1153.43,1130.77z M1152.65,1130.08l0.84,0.37l0.28-0.02l0.31-0.19l-0.39-0.46
  		l-0.04-0.12l-0.64-0.23l-0.14-0.54l-0.11,0.03l-0.06,0.45l-0.29,0.41L1152.65,1130.08z M1150.84,1128.2l0.39,0.51l0.66,0.14
  		l0.46,0.33l0.17-0.15l-0.29-0.18l-0.27-0.72l-0.48-0.39l-0.42-0.05l-0.61,0.12l0.03,0.19L1150.84,1128.2z M1185.03,1146.5
  		l-0.26-0.17l-0.3-0.13l-0.33,0.08l-0.94-0.11l-1.08-0.47l-1.26-1.2l-0.59-0.45l-1.27-0.68l-0.65-0.19l-2.14-0.36l-1.05,0.11
  		l-1.57-0.13l-1-0.15l-0.69,0.17l-0.24-0.19l-0.06-0.34l0.17-0.32l1.2-0.22l-0.55-0.53l0.21-0.43l0.36-0.41l-0.32-0.58l-0.75-0.44
  		l-1.48-0.03l-1.47-0.17l-1.56-0.82l-1.65-0.41l-0.81,0.01l-0.81-0.11l-0.35-0.49l-0.32-0.16l-0.33-0.03l-0.86,0.28l0.06-0.3
  		l0.2-0.3l-0.77-0.52l-1.44-1.14l-0.36,0.44l-0.56,0.52l-0.38-0.04l-0.14-0.48l-0.81-0.66l1.02,0.17l0.53-0.03l0.31-0.4l-0.66-0.25
  		l-0.65-0.35l-0.36-0.38l-1.31-1.02l-0.72-0.16l0.08,0.18l0.56,0.5l0.08,0.2l-0.4,0.12l-0.77-0.2l-1.92-0.91l-0.89-0.63l-1.45-1.21
  		l-4.58-2.26l-0.28,0.08l-0.48-0.08l-0.5-0.21l-0.56-0.04l-2.37,0.07l-0.78-0.17l-0.7-0.36l-0.83-0.54l-0.78-0.6l-1.07-1.46
  		l-1.47-0.74l0.26-0.53l-0.87,0.16l-0.31-0.07l-0.97-0.58l-0.78-0.06l-0.83,0.13l-0.83-0.07l-0.79-0.28l-0.77-0.37l-0.53-0.59
  		l-0.31-0.17l-3.01,0.12l-1.15,0.31l-0.29-0.04l-0.78-0.61l0.08-0.25l-0.85,0.24l-1.78,0.12l-2.21-0.41l-2.22-0.24l-2.1,0.32
  		l-2,0.79l-0.66,0.19l-4.31,0.53l-0.68,0.14l-6.63,2.66l-0.65,0.42l-1.34,1.27l-0.67,0.84l-0.18,1.09l0.48,1.59l-0.39,0.34
  		l-0.5,0.04l-0.52-0.09l-0.32,0.09l-2.9,1.2l-0.08,0.33l0.41,0.25l0.44-0.13l0.87-0.5l0.47-0.19l0.56-0.11l0.49,0.02l0.09,0.67
  		l-0.1,0.69l0.45-0.14l1.75-0.94l0.86-0.27l0.9-0.12l0.28-0.33l0.29-0.98l0.26-0.51l0.27-0.18l1.8-0.09l0.37-0.08l0.35-0.17l0.48,0
  		l0.49,0.19l0.9-0.32l0.74-0.71l0.87-0.46l0.38-0.27l0.31-0.38l0.82-0.74l1.25-0.71l0.63-0.56l0.41-0.27l7.03,0.09l0.54,0.06
  		l0.41,0.14l0.37,0.21l0.36,0.36l0.16,0.5l-0.23,0.34l-0.39,0.26l-1.82,0.4l-0.88,0.3l0.89,0.85l1.04,0.68l0.28,0.12l3.16,0.14
  		l0.72,0.7l0.6-0.05l0.52-0.29l0.19-0.53l0.12-0.57l0.37,0.54l0.21,0.64l0.28,0.32l0.4,0.21l0.62,0.18l3.9-0.09l0.12-0.21
  		l-0.01-0.32l0.78,0.79l0.69,0.88l0.67,0.54l0.78,0.38l1.92,0.76l4.66,1.38l0.7,0.2l0.72,0.09l3.08-0.58l0.8,0.23l0.77,0.68
  		l0.5,0.89l0.33,1.03l0.39,2.13l0.31,0.37l0.4,0.32l0.78,0.41l1.66,1.45l1,0.41l1.18,0.02l2.22,0.21l1.06,0.01l1.01,0.15l0.99,0.25
  		l0.2,0.29l0.14,0.44l0.68,0.6l0.13,0.34l-0.09,0.4l-0.38,0.53l-0.54,0.41l-2.87,1.9l-1.36,1.97l2.12-0.05l2.12-0.28l1.79,0.01
  		l0.92-0.25l0.93-0.16l2.22-0.14l2.21-0.27l0.79-0.02l3.31,0.26l0.91,0.25l0.89,0.36l2.2-0.02l0.6-0.27l0.35-0.31l0.22-0.43
  		l0.25,0.47l-0.02,0.25l0.06,0.2l0.95-0.24l0.4-0.26l0.89-0.39l1.81-0.49l1.87-0.15l1.34-0.04l0.3-0.33l0.54-0.45l0.14-0.55
  		L1185.03,1146.5z M1139.92,1125.28l1.47,0.65l-0.01-0.23l-0.27-0.15l-1.66-1.11l-0.41,0.01l0.26,0.16L1139.92,1125.28z
  		 M1113.76,1133.59l-0.44-0.48l-0.28-0.61l-0.35-0.17l-1.98-0.29l-0.73,0.96l-0.04,0.37l0.92,1.75l-0.28,0.23l-0.4,0.14l-0.49-0.21
  		l-0.57-0.43l-0.03,0.26l0.36,0.54l0.63,0.55l0.91,0.25l0.9-0.02l1.67-0.66l0.78-0.46l-0.05-0.44L1113.76,1133.59z"/>
    </>,
    CRI: <>
        <polygon className="st1" points="1113.92,1237.95 1112.5,1237.5 1112.23,1236.95 1111.76,1236.64 1110.39,1234.78 1109.58,1234.35
  		1107.71,1232.04 1106.86,1230.76 1105.79,1228.49 1105.68,1227.81 1105.44,1227.28 1105.23,1226.94 1105.08,1227.63
  		1104.63,1228.06 1103.8,1228.42 1102.89,1228.48 1101.4,1228.14 1100.79,1228.1 1100.56,1227.92 1100.49,1227.59 1100.06,1227.08
  		1099.28,1226.41 1098.83,1226.46 1098.09,1226.31 1096.87,1225.86 1096.31,1225.8 1095.5,1226.19 1094.55,1226.71 1092.28,1225.91
  		1090.69,1225.34 1089.25,1224.84 1088.87,1224.64 1088.56,1224.68 1088.28,1224.95 1087.97,1225.42 1087.87,1225.55
  		1087.71,1225.68 1087.52,1225.72 1087.53,1225.88 1087.45,1226.37 1086.32,1226.91 1086.14,1227.11 1086.77,1227.51
  		1087.77,1228.02 1088.17,1228.4 1088.13,1228.95 1088.2,1229.33 1087.87,1229.94 1086.79,1231.34 1086.63,1232.23 1087.08,1233.58
  		1088.05,1235.05 1088.53,1235.53 1091.14,1236.3 1092.49,1237.91 1092.83,1238.24 1093.14,1238.07 1093.29,1237.51
  		1093.78,1237.24 1094.75,1236.22 1094.56,1235.68 1094.1,1235.27 1092.44,1234.56 1091.8,1233.8 1091.57,1232.53 1091.8,1232.66
  		1092.12,1233.05 1093.58,1233.73 1096.19,1235.55 1096.8,1236.48 1096.57,1237.21 1096.66,1237.69 1097.31,1238.35
  		1098.15,1238.71 1100.34,1239.24 1101.22,1239.94 1103.09,1240.81 1104.43,1241.88 1105.27,1242.85 1105.44,1243.48
  		1105.47,1244.8 1105.23,1245.43 1104.45,1246.4 1105.54,1247.53 1106.05,1247.82 1106.83,1247.88 1107.46,1248.08 1108.18,1248.15
  		1108.2,1247.67 1108.13,1247.3 1107.08,1246.36 1106.68,1245.62 1107.34,1245.53 1108.23,1245.98 1109.27,1246.62 1109.54,1247.32
  		1109.6,1248.6 1110.29,1249.15 1111.08,1250.04 1111.65,1250.98 1111.62,1250.47 1111.37,1249.89 1111.07,1249.41 1110.44,1248.91
  		1110.4,1248.73 1110.66,1248.48 1111.8,1247.75 1111.94,1247.45 1111.96,1246.82 1111.85,1246.22 1111.33,1245.34 1111.63,1244.79
  		1112.22,1244.35 1112.82,1244 1112.93,1243.85 1112.81,1243.55 1112.46,1243.23 1111.63,1242.67 1111.14,1242.64 1111.12,1241.05
  		1111.14,1239.36 1111.27,1239.19 1111.57,1239.09 1111.81,1238.83 1111.95,1238.33 1112.31,1238.15 1112.96,1238.54
  		1113.63,1238.88 1113.91,1238.76 1114.12,1238.6 1114.26,1238.44 1114.31,1238.28 	"/>
    </>,
    COL: <>
        <path className="st0" d="M1151.56,1297.56l0.23-0.18l-0.05-0.52l-0.16-0.26l-0.34-0.1l-0.27,0.31l0.15,0.42L1151.56,1297.56z
  		 M1246.27,1307.85l0.09-0.57l-0.39-0.83l-0.23-0.89l-0.2-0.31l-0.06-0.66l-0.47-1.2l-0.38-0.98l-0.35-0.5l0.15-0.42l-0.15-0.42
  		l-0.29-0.35l-0.42-1.11l0.15-0.48l-0.11-0.48l-0.35-0.33l-0.5-0.35l-0.67-0.74l-0.8-0.7l-0.41-0.28l-0.28-0.11l-0.24-0.66
  		l-0.19-0.2l-0.41-0.06l-0.84-0.28l-0.78,0.33l-0.02-0.52l0.22-0.31l2.69-2.47l1.36-1.13l0.15-0.17l0.12-0.26l0.09-0.35l-0.31-0.4
  		l-1.27-1.9l-0.44-0.36l-0.43-0.29l-0.5-0.8l-0.6-1.86l-0.43-0.94l-0.13-0.72l0.03-0.81l-0.16-0.62l-0.34-0.43v-1.33l0.34-2.22
  		l0.09-1.69l-0.17-1.16l0.31-0.88l0.79-0.6l0.44-0.93l0.09-1.26l0.47-1.04l0.85-0.81l0.29-0.8l-0.27-0.79l-0.09-0.51l-0.72-0.52
  		l-1.34-0.36l-1.11-0.04l-0.67,0.4l-1.72,0.37l-2.77,0.34l-2.23,0l-1.69-0.35l-1.29,0.12l-0.88,0.58l-0.62,0.13l-0.36-0.32
  		l-0.39-0.09l-0.59,0.2l-0.1-0.09l-1.31-1.57l-1.21-1.45l-1.39-1.73l-1.61-2l-0.29-0.13l-0.5,0.01l-0.66,0.03l-1.02-0.21l-0.7-0.29
  		l-0.55-0.28l-1.01-0.36l-0.69-0.06l-0.62,0.1l-1.71,0.7l-0.97,0.06l-0.75,0.01l-2.02-0.35l-1.38-0.05l-1.6,0.23l-0.69,0.13
  		l-0.96-0.36l-0.65-0.54l-0.61-1.29l-0.43-1.01l-0.75-0.2l-0.83-0.17l-0.41-0.33l-0.24-0.58l-0.06-0.75l0.08-1.22l0.08-0.44
  		l0.11-1.32l0.46-0.69l0.29-0.33l-0.05-0.55l-0.22-1.13l-0.22-0.8l-0.92-0.91l-1.18-1.16l-0.51-1.86l-0.6-2.2l-0.47-0.22l-0.44,0.11
  		l-0.47-0.11l-0.41-0.88l-0.41-0.17l-0.66,0.31l-0.48,0.24l-1.21,0.22l-0.25-0.22l0.08-0.28l0.51-0.8l0.6-1.03l0.7-0.94l0.65-0.96
  		l0.48-1.02l0.33-2.03l0.23-1.4l0.6-2.5l1.1-2l0.41-0.92l0.99-1.2l0.46-0.65l0.61-0.51l1.66-0.7l1.99-3.44l0.46-0.55l2.01-0.51
  		l1.54-0.4l1.14-0.42l0.38-0.22l0.3-0.1l0.3-0.48l1.24-1.09l-0.15-1l-0.9-1.45l-1.95-0.82l-0.87-0.02l-0.99,0.12l-1.72,0.94
  		l-0.1,0.34l-0.33,0.27l-0.72,0l-0.68,0.42l-0.25,1.09l-0.92,1.45l-1.45,0.74l-2.31,0.76l-4.98,3.53l-3.06,0.21l-1-0.04l-0.96-0.28
  		l-1.26-0.27l-0.71,0.17l-0.48,0.47l-0.16,1.36l-0.68,1.3l-0.42,1.17l-0.43,0.41l-0.5-0.19l-0.47-0.64l0.2-0.61l0.7-0.28l0.48-0.06
  		l0.19-0.19l-1.04,0.06l-3.29-1.02l-0.65,0.44l-1.7,1.58l-1.05,0.74l-0.28,0.47l-1.39,0.98l-0.39,0.7l-0.51,1.69l-0.99,1.11
  		l-0.32,0.45l0.56-0.24l0.71-0.54l0.17,0.26l-0.48,0.67l0.03,1.13l-0.37,1.34l-0.36,0.88l0.38,0.61l0.27,1.01l-0.3,0.74l-0.98,0.29
  		l-1.26-0.13l-1.03,0.55l-0.91,0.84l-1.19,2.33l-3.47,2.48l-0.95,0.46l-0.72,0.18l-0.27,0.39l0.41,0.51l0.45,0.4l0.39,1.3l0.25,2.6
  		l-0.37,0.59l-0.68,0.11l-0.25-0.18l-0.24-0.28l0.1-0.51l0.36-0.25l0.15-0.23l-0.33-0.32l-0.38-0.16l-0.48-0.87l-1.16-1.27
  		l-1.11-0.78l-0.7-1.21l-0.25-0.18l-0.16,0.11l-0.47,0.66l-0.25,0.57l0.6,0.6l0.18,0.64l0.34,0.69l0.53,0.69l0.59,1.29l0.14,0.52
  		l-0.17,0.34l-0.56,0.21l-0.53,0.6l-0.14,0.74l0.1,0.36l-1.58,1.17l-0.41,0.2l-0.27-0.18l-0.34-0.59l-0.4-0.48l-0.22-0.17
  		l-0.13-0.01l-0.13,0.11l-0.06,0.26l0.21,1.1l-0.18,0.45l-0.54,0.34l-0.61,1.8l0.82,0.77l1.03,1.49l0.3,0.76l0.37,0.27l0.64,1.21
  		l0.73,0.02l0.59,0.96l0.08,0.6l-0.32,1.93l-0.35,0.03l-0.28-0.12l0.03,0.91l1.05,1.52l0.8,1.81l-0.63,0.88l-1.77,1.16l1.12,1.01
  		l0.24,0.77l0.12,0.91l-0.06,1.16l0.23,2l0.28,0.45l0.17,0.53l-0.23,1.07l-0.12,1l-0.21,0.64l-0.51,0.43l-0.27,0.39l-0.59,0.37
  		l-0.04,0.36l0.94-0.29l0.04,0.39l-0.24,0.59l0.05,0.59l0.58,0.97l0.68-0.95l0.25,0.15l-0.13,1.24l0.43,0.22l0.38,0.04l0.33-0.37
  		l0.42-0.06l-1.4,2.75l-0.69,0.93l-0.27,1.06l-0.51,0.06l-0.46,0.9l-0.4,0.62l-0.33,0.71l-0.61,0.21l-0.52,0.09l-0.06,0.27
  		l0.25,0.74l0.01,0.34l-0.9,0.77l-0.26,0.34l-0.05,0.25l-0.51-0.08l-0.22,0.23l-0.27,0.58l-0.46,0.5l-0.36,0.22l-0.31,0.28
  		l-0.45,0.18l-1.48-0.19l-0.39,0.42l-0.62-0.19l-0.37,0.11l-0.63,0.39l-0.48,0.56l-0.21,0.42l-0.1,2.1l0.66,1.11l-0.22,1.25
  		l-1.82-0.63l-1.39,0.81l-0.57,1.08l1.15,0.83l0.24,0.57l0.26,0.17l0.77,0.64l0.47,0.63l0.79,0.39l0.64,0.32l1.68,1.28l1.11,0.65
  		l1.21,0.58l1.75,0.61l1.07-0.1l0.25,0.47l0.21,0.49l0.4,0.29l0.63,0.24l0.38,0.08l0.12,0.12l0.38,1.77l0.22,0.26l0.87,0.28
  		l1.07,0.11l0.43-0.06l0.94,0.49l0.69,0.23l0.76,0.17l0.52,0.05l0.24-0.08l0.09-0.18l0.43,0.03l0.64,0.23l0.91,0.05l0.57-0.22
  		l0.08-0.36l0.04-0.63l0.21-0.22l0.65-0.36l0.34,0.08l1.71,0.79l0.35,0.27l0.43,0.55l0.8,0.81l0.87,0.52l1.34,0.22l1.29,0.85
  		l1.51,0.57l0.5-0.54h0.34l0.39,0.07l0.7,0.55l0.42,0.33l0.5,0.27l0.48,0.09l0.43,0.03l0.3-0.03l0.18,0.37l0.21,0.45l0.54,0.31
  		l0.63,0.29l0.52,0.5l0.35,0.34l0.41,0.4l0.4,0.53l0.36,0.93l0.18,0.63l0.21,0.35l-0.05,0.36l0.43,0.64l0.32,0.36l0.55,0.23
  		l1.06,0.26l0.57,0.58l0.5,0.23l0.54,0.6l0.47,0.18l0.6-0.03l0.6,0.29l0.46,0.57l0.29,0.71l0.46,0.4l0.23,0.73l-0.26,0.86l0.24,0.45
  		l0.47,0.37l0.76,0.39l0.7-0.1l0.36,0.13l0.23,0.36l0.13,0.42l0.31,1.03l0.16,0.65l-0.28,0.63l-0.11,0.44l0.15,0.58l0.73,0.28
  		l0.66,0.23l0.41,0.45l0.45,0.12l0.64-0.03l0.82-0.11l0.45-0.26l0.29-0.08l0.33,0.11l0.72,0.25l0.89,0.28l0.8-0.16l0.69-0.07
  		l0.69-0.17l0.7-0.47l0.59,0.02l0.44-0.32l0.55-0.51l0.54-0.51l0.42-0.11l0.68,0.25l0.94,0.35l0.53,0.46l0.41,0.12l0.43,0.34
  		l0.82,0.01l0.87-0.18l0.7-0.57l0.73-0.16l0.49-0.16l0.45,0.1l1.76,1.03l0.48,0.53l0.61,0.1l0.5,0.29l0.83,0.31l0.46,0.32l0.59,0.19
  		l0.42,0.45l0.67,0.28l0.58,0.15l0.26,0.36l0,0.24l-0.08,0.16l-0.62,0.95l-1.2,1.87l-1.09,1.68l-1.71,2.65l-0.96,1.48l0.24,0.06
  		l1.49,0.65l0.37,0.02l0.55-0.16l0.35-0.26l0.33-0.04l0.35,0.25l0.49,0.32l0.35,0.94l0.26,0.46l0.61,0.35l0.65,0.59l0.43,0.62
  		l0.15-0.3l0.31-1.71l0.52-2.82l0.47-2.56l0.52-2.83l0.54-2.92l0.54-2.96l0.45-2.43l0.38-2.09l0.23-1.28l0.37-1.68l0.14-1.47
  		l0.15-0.42l-0.09-0.36l-0.32-0.51l0-0.22l0.04-0.3l-0.04-0.26l-0.33-0.28l-0.26-0.17l-0.2-0.24l-0.09-0.33l-0.17-0.33l-0.07-0.35
  		l-0.24-0.28l-0.07-0.35l0.17-0.33l0.07-0.35l-0.07-0.33l-0.09-0.39l-0.19-0.37l-0.28-0.22l-0.67-0.25l-0.68-0.6l-0.8-0.53
  		l-1.02-1.01l-0.22-0.48l0-1.32l0.04-1.43l0.07-2.16l0.03-1.1l0.58-0.06l0.51-0.03l0.53-0.08l0.46-0.08l0.42-0.16l0.32-0.2
  		l0.38-0.13l0.3,0.05l0.3-0.17l0.33-0.17l0.32-0.14l0.46-0.11l0.43,0.26l0.24,0.26l0.28,0.13l0.27-0.03l0.18,0.02l0.19,0.21
  		l0.24,0.02l0.35-0.04l0.33-0.05l0.15-0.06l0.02-0.14l-0.08-0.23l-0.11-0.22l0.09-0.34l0.01-0.41l0.02-0.52l-0.26-0.29l-0.26-0.54
  		l-0.29-0.44l-0.45-0.29l-0.42-0.11l-0.35,0.18l-0.33,0.03l-0.24-0.17l-0.39-0.01l-0.42-0.05l-0.45-0.06l-0.81,0.12l-0.29-0.15
  		l-0.39-0.02l-0.45,0.16l0.01-2.09l0.01-1.97l0.01-1.38l0.41,0.03l0.51-0.25l0.75-0.04l0.58-0.26l0.32-0.02l0.61,0.13l0.64,0.27
  		l0.63,0.04l1.65,0l1.78,0l1.98,0l1.98,0l1.72,0l0.53,0.02l-0.31-0.46l-0.36-0.59l0.14-0.47l0.18-0.47l0.21-0.25l0.54,0.26l0.45,0.8
  		l0.37,0.6l0.36,0.3l0.45,0.03l0.51-0.1l0.51-0.25l0.87-1.11l0.86-0.95l0.45-0.32l0.48-0.29l0.35-0.11l0.48,0.04l0.41,0.26
  		l0.26,0.45l0.97,1.57l0.73,1.18l0.25,0.74l0.02,1.8l-0.04,1.59l0.1,0.21l0.14,0.06l1.59-0.37L1246.27,1307.85z"/>
    </>,
    CHN: <>
        <path className="st0" d="M2830.8,1076.37l-0.24-0.21l-0.6,0.63l-0.02,0.66l0.28,0l0.73-0.22l0-0.53L2830.8,1076.37z M2759.61,1133.54
  		l-0.18,0.34l-0.4,0.31l0.18,0.25l0.07,0.45l0.25,0.32l0.16-0.18l0.09-0.82l0.1-0.15l0.42-0.36l-0.2-0.1L2759.61,1133.54z
  		 M2819.04,1099.71l-0.08-0.15l-0.26-0.14l-0.16-0.27l-0.47,0.13l-0.22,0.43l0.23,0.36l-0.2,0.5l0.01,0.55l0.39,0.2l0.62-0.41
  		l0.1-0.2l-0.2-0.25l0.25-0.46L2819.04,1099.71z M2804.43,1108.98l-0.09,0.1l-0.14,0.35l0.1,0.11l0.01,0.38l0.5,0.09l0.28-0.53
  		l-0.1-0.2L2804.43,1108.98z M2739.73,1139.8l-0.31-0.3l-0.63,0.16l-0.38,0.43l0.14,0.21l0.5,0.03l0.69-0.08l0.69,0.34l0.29-0.44
  		l0.01-0.18l-0.15-0.39L2739.73,1139.8z M2757.44,1134.85l0.17,0.04l0.83-0.19l0.03-0.62l-0.75,0.31L2757.44,1134.85z
  		 M2744.35,1150.06l-0.27-0.56l-0.42-0.39l-0.67-0.2l-0.54-0.39l-0.56-0.68l-0.23-0.01l-0.45,0.35l-0.08,0.22l0,0.84l-1.44-0.68
  		l-0.2-0.04l-0.05,0.36l0.04,0.37l-0.41-0.55l-1.1-0.15l-0.35,0.02l-0.75,0.53l-1.49,0.26l-2.15-0.19l-0.57,0.12l-0.59,0.58
  		l-0.8,0.13l-0.87-0.14l-0.43,0.19l-0.38,0.35l-0.35,0.65l0.35,0.1l0.48-0.03l-0.82,0.76l-0.98,0.53l-1.35,1.15l-0.94,0.55
  		l-0.82,0.69l-0.24,0.3l-0.13,0.34l-0.12,3.1l0.02,0.36l0.32,1.01l0.21,1.86l1.86,1.03l0.91,0.42l1.29,0.37l1.33,0.22l0.5,0.16
  		l1,0.54l0.59-0.07l0.77-0.18l0.18-0.1l0.48-0.77l0.47-0.42l1.28-0.22l0.44,0.05l0.4-0.27l-0.01-0.24l-0.15-0.26l0.91-0.56l0.8-0.74
  		l0.33-0.12l0.36-0.03l0.55-0.22l0.44-0.43l0.22-0.56l0.35-1.37l0.39-1.11l-0.03-0.32l0.08-0.32l0.26-0.31l0.32-0.73l1.53-2.32
  		l0.76-0.25l0.85-0.6l-0.13-0.95L2744.35,1150.06z M2833.68,1044.66l-0.29-0.34l-0.43-0.18l-0.23-0.58l-1.05-0.38l-1.08-0.07
  		l0.13,0.44l0.92,1.06l1.55,0.87l2.19,0.8l0.69-0.26l-0.16-0.32l-0.3-0.24L2833.68,1044.66z M2765.36,1123.58l-0.51,0.02l0.18,0.39
  		l0.31,0.23l0.19,0.04l0.66,0.62l-0.07-0.41l-0.29-0.42L2765.36,1123.58z M2944.2,884.37l-0.41-0.78l-0.5-1.08l-0.21-0.51l0.33-0.65
  		l0.35-0.4l0.19-0.36l0.1-0.61l-0.13-0.47l-0.86-0.73l-0.9-0.36l-1.02-0.15l-0.35-0.05l-0.74,0.15l-3.06,0.93l-1.44,0.71l-0.83,0.8
  		l-0.88,0.38l-1.41-0.05l-1.32-0.04l-1.04,0.44l-1.2,0.92l-0.88,0.42l-0.55-0.08l-0.59,0.61l-0.63,1.3l-0.72,0.57l-0.81-0.16
  		l-1.94,0.12l-3.07,0.4l-1.92-0.02l-0.78-0.43l-1.22-0.06l-1.66,0.32l-1,0.07l-0.34-0.19l-0.25-0.54l-0.15-0.89l-0.56-0.93
  		l-0.98-0.96l-0.17-1.16l0.63-1.37l0.14-0.94l-0.34-0.51l-0.14-0.45l-0.74-0.57l-0.52-0.99l-0.38-0.3l0.11-0.84l0.43-1.01
  		l-0.54-0.95l-1.67-0.06l-1.34-0.27l-1.34-0.87l-2.06-2.46l-1.02-0.87l-0.67-0.09l-0.49-0.4l-0.3-0.71l-0.48-0.01h-0.47l-0.29,0.3
  		l-0.34,0.09l-0.52-0.27l-0.53-0.03l-0.55,0.21l-0.46-0.14l-0.38-0.49l-0.69-0.32l-1-0.16l-0.41-0.34l0.17-0.51l-0.19-0.38
  		l-0.55-0.25l-1.49,0.06l-2.44,0.38l-2-0.1l-1.56-0.59l-0.87-0.54l-0.18-0.49l-0.45-0.48l-0.72-0.46l-0.41-0.79l-0.09-1.11
  		l0.17-1.06l0.66-1.51l-1.64-0.99l-0.49-0.57l0.12-0.48l-0.09-0.38l-0.29-0.28l0.02-0.45l0.33-0.62l0-0.79l-0.34-0.96l-0.92-1.35
  		l-1.5-1.74l-0.81-1.27l-0.11-0.8l-0.2-0.64l-0.28-0.35l-0.17-0.6l0.12-0.66l-0.17-0.43l-0.22-0.4l0.03-0.64l-0.26-0.44l-0.55-0.24
  		l-0.17-0.49l0.1-1.04l-0.4-0.88l-1.21-1.62l-0.36-1.19l-0.11-1.07l-0.51-0.52l-0.03-0.47l-0.07-0.81l-0.31-0.22l-0.19-0.29
  		l0.15-0.34l-0.24-0.43l-0.63-0.51l-0.29-0.44l-0.07-0.4l-0.32-0.31l-0.93-0.3l-0.25-0.42l0.06-0.37l0.2-0.35l0.11-0.21l-0.03-0.27
  		l-0.07-0.27l-0.37-0.32l-0.53-0.37l-0.59-0.81l-0.75-0.22h-0.46l-0.4-0.46l0.12-0.29l-0.03-0.54l-0.36-0.44l-0.45-0.17l-0.42,0.11
  		l-1.04-0.41l-1.66-0.94l-1.27-0.43l-0.88,0.07l-0.54,0.28l-0.21,0.49l-0.59-0.05l-1.45-0.88l-1.47-0.22l-0.81-0.47l-0.65-0.8
  		l-0.6-0.33l-0.55,0.13l-1.34-0.54l-2.14-1.21l-1.12-0.41l-0.4,0.23l-0.21,0l-0.38-0.03l-0.55-0.02l-0.96-0.29l-1.31,0.13
  		l-1.65,0.54l-1.79,0.33l-1.93,0.13l-1.14-0.06l-0.36-0.26l-2.1,0.38l-2.91,0.78l-2.85,0.76l-3.54,0.37l-2.37,1.29l-2.38,2.33
  		l-1.71,1.46l-1.04,0.6l-0.42,0.78l0.2,0.97l0.89,0.35l1.58-0.28l1.36,0.14l1.14,0.55l0.36,0.83l-0.41,1.11l0.13,1.09l0.67,1.07
  		l0.04,1.23l-0.58,1.39l-1.44,1.4l-2.3,1.41l-1.43,1.37l-0.84,2l-1.3,1.73l-0.48,0.98l-0.09,0.8l-0.51,0.86l-0.94,0.93l-0.51,0.93
  		l-0.09,0.93l-0.47,0.85l-0.86,0.76l-0.53,0.81l-0.21,0.85l-0.33,0.57l-0.45,0.29l0.24,0.29l0.92,0.29l0.38,0.83l-0.17,1.37
  		l-0.56,0.97l-0.95,0.58l-1.41,0.38l-1.88,0.18l-2.56,1.3l-2.23,1.66l-2.64,1.96l-0.51,0l-0.96-0.24l-1.86-0.8l-1.95-0.17
  		l-1.89-0.75l-1.12-0.49l-1.73-0.94l-0.79,1.52l-1.58,3.04l-1.34,2.57l-0.71,1.44l-0.52,1.1l-0.54,1.04l-0.07,0.63l-0.6,1.01
  		l-1.12,1.21l-0.24,1.31l0.04,1.18l-0.09,1.06l-1.23,0.67l-0.96,0.6l0.27,1.99l0.49,0.75l0.8,0.81l0.84,0.65l0.73,0.55l0.81-0.26
  		l0.68-0.84l1.32-0.74l0.72-0.02l0.51,0.17l1.14,0.05l1.17-0.27l0.91-0.06l1.18,0.18l0.43,0.18l0.99,0.32l1.07,0.71l0.75,0.79
  		l0.55,0.15l0.28-0.25l0.6-0.7l0.84-0.68l1.02-1.11l0.77-0.85l0.61-0.13l1.17,0l0.53-0.21l0.89-0.11l0.78,0.32l2.18,0.17l0.58,0.44
  		l1.03,1.3l0.58,0.69l1.01,0.35l0.61,0.24l0.54,0.19l0.54,0.33l0.13,0.4l0.22,0.62l0.33,0.36l0.61,0.35l0.47,0.21l0.15,0.45
  		l0.15,0.22l0.43,0.31l1.27,1.33l0.62,0.36l0.93,0.77l0.39,0.64l0.02,0.67l0.24,0.51l0.63,0.77l0.3,0.52l-0.11,0.7l0.1,0.62
  		l-0.24,0.64l-1.01,0.48l-0.34,0.22l-0.73,0.02l-1.23-0.24l-1.2,0.14l-1.43-0.26l-1.13-0.57l-0.6-0.45l-0.95-0.27l-0.45,0.14
  		l-0.57,0.58l-0.63-0.1l-0.58,0.1l-1.48-0.12l-0.81-0.15l-1.28,0.41l-0.72,0.13l-1.35,0.5l-0.82,0.86l-0.61,0.21l-0.59-0.04
  		l-0.43-0.32l-0.62-0.38l-0.92,0.02l-0.27,0.16l-0.11,0.35l-0.3,1.07l0.01,0.48l-0.2,0.31l-0.54,0.1l-0.95-0.03l-1.49-0.07
  		l-1.01-0.28l-0.61,0.12l-0.83,0.58l-0.59,0.09l-0.48,0.25l-0.39,0.85l-0.61,0.53l-0.73,0.65l-0.78,1.4l-0.43,0.8l0.14,0.43
  		l0.1,0.52l-0.36,0.59l-0.74,0.55l-0.59,0.1l-0.89,0.53l-1.22,0.96l-0.91,0.8l-1.19,0.2l-0.84,0.2l-1.87,0.25l-0.46,0.06l-2.05,0.12
  		l-1.52-0.43l-0.8,0.07l-0.71,0.24l-0.36,0.26l-0.13,0.5l-0.13,0.46l-0.57,0.72l-1.16,0.95l-0.96,0.63l-0.73,0.82l-0.42,0.3
  		l-0.84,0.31l-0.45,0.17l-1.05,0.73l-0.84,0.65l-0.55,0.18l-0.67-0.17l-0.44-0.05l-1.3-0.25l-0.88-0.03l-1.24-0.16l-2.89-0.76
  		l-0.93-0.36l-0.82-0.97l-0.74-0.49l-1-0.05l-1.51,0l-0.68-0.19l-1.13,0.18l-1.24,0.98l-0.59,0.73l-0.51,0.75l-0.62,1.6l-0.28,1.07
  		l-0.21,0.6l-0.66,0.95l-0.07,0.54l0.23,0.46l0.48,0.52l0.28,0.82l0.7,0.87l0.68,0.68l1.29,1.09l0.36,0.57l0.43,0.66l0.09,0.64
  		l-0.08,0.42l-0.46,0.32l-0.9,0.16l-0.43,0.45l-0.66,0.59l-0.79,0.68l-0.37,0.04l-0.44,0.18l-2.23,0.85l-0.84,0.24l-0.67,0.28
  		l-0.79,0.86l-0.62,0.64l-0.77,0.85l-0.34,0.38l-0.68,0.85l-0.9,0.97l-0.5,0.52l-0.27,0.31l-0.25,0.41l-0.94,0.31l-0.78,0.33
  		l-1.17,0.5l-1.68,0.55l-1.35,0.53l-0.86,0.44l-1.28,0.55l-0.87,0.18l-1.75-0.02l-2.17,0.14l-1.58,0.1l-1.19-0.13l-1.79-0.08
  		l-1.37-0.11l-0.92,0.2l-2.16,0.22l-0.48,0.05l-3.84,0.52l-1.7,0.28l-1.56,0.13l-1.15,0.2l-0.65,0.25l-0.96,0.37l-0.51,0.16
  		l-1.7,0.72l-3.79,1.48l-2.54,1.2l-0.42,0.2l-1.71,0.84l-0.99,0.33l-0.69,0.75l-0.55,0.48l-0.58,0.21l-1.02-0.48l-0.73,0.03
  		l-2.32-0.18v-2.2l-1.63,0.31l-2.59,0.5l-2.41,0.46l-2.2-1.05l-1.7-0.81l-1.47-0.7l-2.24-0.46l-1.95-0.4l-3.53-0.67l-1.55-0.58
  		l-0.78-0.77l-1.4-1.76l-0.45-0.35l-0.68-0.24l-0.71-0.15l-1.53,0.01l-1.87-0.14l-2.69-0.37l-2.14-0.29l-3.64-0.55l-0.86-0.07
  		l-1.91,0.49l-2.44,0.62l-4.39-0.49l-1.94-0.23l-3.94-0.46l-4.46-0.53l-4.32-0.54l-3.14,0.3l-1.75,0.17l-2.02,0.24l-0.28-0.27
  		l-0.08-1.04l-0.36-0.81l-1.1-0.87l-0.74-0.83l-1.41-1.13l-0.45-0.71l-0.15-1.1l-1.3-2.87l-0.81-1.94l-0.2-0.4l-0.35-0.63
  		l-0.46-0.33l-0.97-0.2l-0.26-0.34v-0.67l0.15-0.93l0.2-0.68l-0.14-0.17l-2.53,0.19l-1.55-0.45l-1.3-0.49l-1.83-1.26l-1.09-0.49
  		l-1.39-1.3l-2.03-0.31l-0.76-0.51l-0.95-1.12l-0.83-0.72l-1.18-0.45l-1.87-0.4l-3.19-0.38l-1.08-0.16l-1.76,0.26l-1.31,0.02
  		l-2.12-0.27l-1.2-0.35l-1.49-0.01l-0.97,0l-1.29-0.08l-0.63-0.23l-0.58-0.28l-1.09,0.07l-0.76-0.27l-0.71-0.51l-0.74-0.24
  		l-0.81,0.02l-0.33,0.23l-0.31-0.02l-0.2-0.7l-0.76-1.12l-0.11-0.5l-0.46-0.58l-0.28-0.53l0.07-0.73l0.33-1.42l0.73-1.29l0.48-0.33
  		l0.29-0.38l0.61-0.67l0.35-0.53l-0.04-0.73l-0.41-0.76l-0.3-0.98l0.06-0.56l0.45-0.67l0.53-1.49l-0.04-0.39l-0.21-0.31l-0.05-0.69
  		l-0.1-0.93l-0.63-1.42l-0.34-0.76l-0.6-0.33l-0.7-0.2l-0.61-1.02l-0.76-1.21l-0.48-0.76l-0.17-0.46l-0.08-0.85l-0.36-1.02
  		l-0.38-0.56l-0.28-0.43l-0.14-0.62l-0.15-0.22l-1.03-0.28l-0.74-0.46l-0.31-0.62l-0.11-0.5l-0.22-0.29l-0.58-0.09l-0.41,0.45
  		l-0.67,0.23l-0.45-0.04l-0.44-0.27l-0.27-0.29l-0.46-0.32l-0.65-1.02l-0.69-0.27l-1.26,0.05l-1.12,0.47l-0.68-0.07l-0.57-0.16
  		l-0.64-0.51l-0.45-0.42l-0.67-0.14l-1.32-0.74l-0.89-0.53l-0.08-1.05l-0.42-0.72l-0.87-0.2l-0.88-0.74l-1.28-0.4l-0.81-0.31
  		l-0.7-0.19l-0.1-0.28l0.04-0.24l0.32-0.4l0.33-0.37l0.08-0.35l-0.27-0.31l-0.72-0.32l-0.93-0.29l-0.19-0.48l-0.56-0.5l0.1-0.4
  		l0.44-0.29l0.45-0.22l0.1-0.38l-0.32-0.35l-0.15-0.52l0.08-0.39l-0.09-0.5l-0.44-0.04l-0.79,0.2l-0.77,0.16l-0.51,0.11l-0.33,0.34
  		l-0.5,0.16l-0.79-0.1l-0.78-0.22l-1.53-0.04l-0.93,0.13l-0.44,0.08l-0.65,0.44l-0.46,0.44l-0.21,0.75l0.25,0.85l-0.34,1.77
  		l-0.46,0.67l-0.96,1.16l-0.56,0.25l-0.93,0.21l-0.9,0.34l-1.76,0.33l-1.91,0.17l-0.68,0.25l-0.48,0.79l-0.34,0.66l-0.21,0.5
  		l-0.54,1.64l-0.31,1.46l0.3,1.81l0.22,2.01l-0.01,0.69l0.46,1.03l0.24,0.63l-0.11,0.89l-0.67,0.7l-0.4,0.93l-0.38,0.4l-1.09,0.18
  		l-1.03,0.11l-1.04,0.8l-0.83,0.55l-1.3,0.7l-0.61,0.13l-0.34-0.36l-0.22-0.79l-0.45-0.35l-0.63-0.03l-0.5-0.01l-1.63-0.22
  		l-1.04,0.01l-0.78,0.17l-0.89,0.09l-1.54-0.29l-1-0.25l-0.67-0.24l-1.6-0.7l-2.11-0.83l-0.87-0.24l-0.51,0.25l-0.08,0.47
  		l-0.13,1.15l-0.25,0.72l-1.47,3.62l-0.91,2.51l-1.15,2.4l-0.36,1.61l-0.69,2.03l-0.69,1.47l-0.28,0.8l-0.03,0.33l0.12,0.46
  		l1.05,0.5l1.1,0.31l0.25,0.19l0.12,0.52l-0.04,0.85l-0.2,0.8l-0.32,0.63l-0.32,0.31l-0.36,0.02l-0.69-0.4l-0.62-0.45l-0.48-0.14
  		l-1.21,0.25l-1.12,0.34l-0.37,0.01l-0.65-0.22l-0.66-0.46l-0.26-0.88l-0.56-0.4l-0.76,0.4l-2.25,0.67l-2.48,0.8l-1.57,0.41
  		l-0.62-0.06l-1.22,0.09l-1.06,0.22l-0.79,0.31l-1.57,0.43l-1.42,0.29l-0.92,0.65l-0.66,0.63l0.03,0.24l0.48,0.37l0.55,0.29
  		l1.1-0.07l1.07-0.05l0.89,0.39l0.8,0.25l0.22,0.33l-0.22,0.32l-0.46,0.07l-0.16,0.22l0.08,0.3l-0.3,0.77l-0.16,1.17l0.16,1.16
  		l0.09,1.06l-0.05,0.54l-0.03,0.76l0.34,0.52l0.3,0.98l0.54,0.61l0.82,2.12l0.48,1.24l0.45,1.4l-0.32,0.76l0.02,0.44l0.52,0.37
  		l0.23,0.71l0.24,0.44l-0.07,0.43l-0.22,0.17l-1.13-0.26l-0.93,0.43l-0.98,0.43l-0.13,0.23l-0.03,0.25l0.1,0.22l0.57,0.39l0.78,0.24
  		l-0.04,0.39l-0.97,0.18l-1.46,0.59l-0.4,0.64l-0.31,0.7l-0.03,0.41l0.15,1.09l0.22,1.2l0.42,1.27l0.03,0.39l-0.22,0.28l-0.2,0.18
  		l0.17,0.61l0.14,0.71l-0.09,0.16l-0.16,0.11l-2.58,0.18l-0.58,0.19l-0.63,0.98l-2.21,0.78l-1.26,0.4l-0.51-0.02l-1.22,0.64
  		l-3.42,1.6l-1.68,1.01l-0.84,0.42l-0.68,0.46l-0.11,0.47l-0.02,0.44l-1.88,2.06l-1.41,0.25l-1.19-0.05l-0.81,0.31l-1.16,0.32
  		l-2.51-0.22l-0.86,0.04l-1.65-0.28l-0.66,0.15l-0.71,0.42l-0.97,1.64l-0.4,0.38l-0.18,0.37l-0.15,0.8l-0.37,0.84l-0.48,0.66
  		l-0.34,0.61l-0.71,0.59l-0.66,0.37l-0.51-0.78l-0.44,0.22l-0.42,0.32l-0.79-0.11l-0.49,0.16l-1.11,0.68l-1.64-0.03l-0.18-0.23
  		l-0.32-1.86l-0.29-0.88l-0.24-0.2l-0.29-0.02l-2.36,1.47l-1.09,0.26l-0.9,0.05l-1.17-0.44l-0.26,0.11l-0.2,0.24l-0.08,0.3
  		l0.34,0.83l-0.1,0.16l-0.53-0.01l-0.74,0.19l-0.56,0.38l-1.69,1.34l-1.43,0.45l-1.33,0.18l-0.54,0.15l-0.24,0.16l-0.44,0.64
  		l-0.46,1l-0.24,0.49l-0.18,0.28l0.04,0.37l0.36,0.48l0.27,1.07l-0.06,0.28l-0.29,0.45l-0.42,0.44l-0.9,0.26l-0.71,0.13l0.04,0.51
  		l-0.11,0.97l-0.13,0.68l0.7,1.23l0.45,0.59l0.44,0.42l0.08,0.33l-0.09,0.27l-0.54,0.26l-0.2,0.28l-0.09,0.31l0.17,0.36l0.31,1.16
  		l0.4,0.9l0.57,0.43l0.85,0.29l0.47-0.06l0.33-0.67l0.56-0.52l0.47,0.04l0.76-0.02l1.99,0.58l1.95,0.88l0.57,0.49l0.2,0.55
  		l-0.54,1.26l0.03,0.81l0.12,0.86l0.45,0.64l0.41,1.09l0.08,0.9l0.18,0.27l0.14,0.32l-0.22,0.83l-0.15,0.83l0.18,0.28l0.59,0.41
  		l0.93,0.77l0.18,0.64l-0.33,0.4l-0.59,0.49l-0.76,0.42l-0.23,0.18l-0.43,0.06l-0.61-0.23l-0.24-0.35l-0.1-0.05l-0.49,0.23
  		l-0.93,0.29l-1.57,0.76l0.03,0.2l1.03,0.77l0.24,0.26l0.13,0.08l0.5-0.14l0.77,0.01l0.62,0.22l0.63,0.32l0.4,0.26l0.5-0.15
  		l0.88-0.18l0.77,0.13l1.7,0.58l0.25,0.29l0.4,1.4l0.3,0.13l0.96-0.33l0.79,0.17l0.88,0.45l0.57,0.44l0.38,0.47l0.4,0.76l0.16,0.61
  		l0.19,0.73l-0.05,2.05l-0.29,0.33l-0.25,0.44l0.06,0.38l0.28,0.3l0.55,0.2l0.51,0.13l0.27,0.32l0.38,1.15l0.25,0.18l0.62,0
  		l1.13-0.25l0.98-0.39l0.41-0.08l0.1,1.09l0.58,0.42l0.81,0.48l0.33,0.16l0.94,0.46l1.78,0.58l1.72,0.42l1.28,0.31l0.62,0.02
  		l0.44,0.02l1.28-0.08l0.63-0.15l0.03,0.03l0.07,0.08l0.34,0.23l0.36,0.11l0.43-0.22l0.54-0.18l0.28,0.1l0.04,0.29l-0.32,1.36
  		l0.03,0.53l0.54,1.11l0.7,1.79l0.65,1.68l0.39,1.1l0.38,0.45l1.59,0.46l1.31,0.38l0.78,0.62l0.86,0.59l0.6,0.36l0.28,0.47
  		l0.06,0.42l-0.05,0.28l-0.33,0.37l-1.51,0.96l-0.18,0.3l-0.04,0.4l0.3,1.19l0.19,0.74l0.05,1.49l0.1,1.42l0.53,0.64l0.44,0.42
  		l0.27,0.38l0.54,0.52l0.45,0.39l0.39,0.23l0.03,0.07l0.16,0.44l-0.11,0.6l-0.16,0.52l0.05,0.28l0.31,0.2l0.48,0.52l0.06,0.76
  		l-0.03,0.52l0.19,0.47l0.05,0.52l-0.15,1.3l0.02,0.53l0,0.06l-0.42,0.04l-0.36,0.2l-0.51,0.82l-0.58,0.21l-0.66,0.06l-0.68-0.5
  		l-0.56-0.52l-0.15-0.29l-0.14-0.55l-0.3-0.36l-0.58,0.17l-0.89,0.08l-0.96,0.12l-0.18,0.12l-0.02,0.23l0.23,0.5l0.2,0.65l0.12,0.9
  		l0.26,0.6l0.08,0.19l1.53,1.79l0.4,0.37l0.08,0.24l-0.13,0.65l-0.28,0.76l0.05,0.6l0.51,0.67l0.41,0.47l-0.4,0.63l-0.24,0.73
  		l0.27,0.33l-0.13,1.04l0.12,0.2l0.28,0.08l0.45-0.07l0.46-0.28l0.39-0.05l0.24,0.08l0.31-0.79l0.27-0.11l0.53,0.22l1.06,1.49
  		l0.89,1.26l0.26,0.24l0.16,0.15l0.88,0.65l0.61,0.41l0.83-0.15l1.1-0.03l0.65,0.4l0.38,0.28l0.07,0.05l1.32,0.99l0.57-0.07
  		l0.38,0.28l0.11,0.7l-0.18,0.72l0.04,0.34l0.59,0.07l1.25,0.48l1.11,0.42l0.57,0.14l0.62,0.31l0.54,0.5l1.07,0.64l0.94,0.49
  		l0.21,0.67l0.38,0.6l0.46,0.57l0.56-0.03l0.66-0.49l1.37-2.25l1.89-0.46l1.79,0.23l1.59,0.33l0.47,0.75l0.31,0.79l0.2,0.4
  		l0.52,0.47l2.24,1.12l1.3,1.01l1.8,1.35l1.34,0.6l1.19,0.05l0.67,0.53l1.01,1.06l0.85,1.21l1.07,1.12l0.74-0.04l1.01-0.36
  		l1.23-0.48l0.72,0.23l0.67,0.31l0.22,0.58l0.4,1.09l0.45,1.14l0.7,0.4l0.83,0.59l0.46,0.46l1.56,0.85l0.22,0.35l0.32,0.24
  		l0.38,0.15l0.32,0.17l0.49,0.06l1.8-0.51l0.48,0.06l0.28,0.09l0.01,0.19l-0.33,0.8l-0.28,1.02l0.29,0.51l0.76,0.21l1.67,0.15
  		l2.25-0.01l0.68,0.52l0.68,0.77l0.69,1.32l0.27,0.56l0.34,0.16l0.59-0.22l0.1-0.54l0.03-0.81l0.49-0.28l0.31,0.21l0.37,0.63
  		l0.93,0.57l0.67,0.28l0.64-0.1l0.27-0.22l0.32-1.1l0.5-0.16l0.64,0.07l0.25,0.22l0.26,0.44l0.77,0.21l0.77,0.28l0.73,0.36
  		l1.02,0.82l1.26,0.15l1.46-0.02l0.77,0.02l0.57,0.06l0.51-0.06l1.5-0.59l0.61-0.04l0.76,0.07l0.73,0.12l-0.09-0.31l0.08-0.26
  		l0.27-0.14l1.13-0.18l1.27-0.39l0.51-0.21l0.38-0.21l0.39-0.33l0.36,0.01l1.14,0.47l0.4,0.3l0.21,0.9l0.17,0.35l-0.16,0.92
  		l-0.68,2.22l0.13,0.83l0.57,0.61l0.5,0.42l0.47-1.34l0.66-0.49l0.65-0.68l0.49-1.07l0.95-1.1l1.04-1.13l0.71-0.92l0.47-0.43
  		l0.98-0.46l0.82-0.27l0.56-0.62l0.68-0.34l0.7-0.15l1.04,0.08l0.98,0.22l1.07,0.31l0.12,0.25l-0.09,0.44l-0.15,0.44l-0.01,0.23
  		l0.17,0.12l1.05,0.09l1.29-0.07l0.72,0.06l1.61,0.41l0.47,0.29l0.49,0.22l0.48-0.04l0.61-0.47l0.64-0.4l0.4-0.06l0.28,0.13
  		l0.51,0.38l1.06,0.36l0.94,0.27l0.31,0.26l-0.11,1.11l0.02,0.37l0.68,0l0.94,0.12l0.71,0.15l0.57-0.01l1.04-0.7l0.47-0.04
  		l0.54-0.13l0.24-0.13l0.17,0.1l0.6,0.09l0.62-0.04l0.55-0.19l0.56-0.3l0.99-0.63l0.2-0.36l0-0.33l-0.18-0.22l-0.19-0.11l0.08-0.29
  		l0.42-0.49l1.52-0.74l1.29-0.9l0.71-0.68l0.33-0.82l0.41-0.89l0.38-0.35l0.92-0.22l2.56-0.33l0.81-0.36l1.19-0.67l0.6-0.52
  		l0.33-0.43l0.04-0.47l0.79-0.15l0.69-0.77l0.84-0.77l1.47-0.65l1.31-0.88l0.46,0.14l0.47,0.41l0.25,0.46l0.05,0.23l1.67,0.29
  		l0.26-0.05l1.23,0.41l1.13,0.5l0.62,0.12l0.3-0.01l0.26-0.15l0.31-0.44l0.31-0.32l0.2-0.13l-0.01-0.5l1.64-0.98l1.47-0.7l1.27-0.51
  		l0.37,0.21l0.41,0.39l0.56,1l0.34,0.24l0.86-0.14l0.16,0.11l-0.14,0.36l-0.58,0.44l-0.76,0.4l-0.49,0.32l0.16,1.08l-0.04,0.37
  		l0.21,0.12l1.56-1.07l0.75-0.21l0.26,0.26l0.08,0.57l0.61,1.18l0.26,0.6l-1.56,1.43l-0.58,0.74l0.02,0.26l-0.03,0.26l-0.4,0.37
  		l0.02,0.15l0.32,0.23l0.39,0.18l0.19-0.01l0.33-0.35l1.47-0.49l0.42,0.09l1.04,0.75l0.48,0.04l1.25,0.22l0.8-0.28l0.59,0.26
  		l1.22,0.94l0.28,0.17l0.29-0.33l0.63-0.9l0.39-0.65l0.21-0.28l0.3-0.49l0.52-0.06l0.5,0.15l0.3,0.28l0.3,0.57l0.33,0.46l0.4,0
  		l0.41-0.07l0.19,0.06l0.39,0.39l0.74,0.93l0.33,0.23l0.31,0.4l0.16,0.79l0.1,0.79l0.93,2.76l0.28,0.58l0.21,0.44l0.44,0.11
  		l0.35-0.44l0.14-0.47l0.37-0.16l0.44,0.09l0.8,0.44l0.43,0.24l0.22,1.36l0.05,1.59l-0.06,0.49l0.35,1.31l0.11,1.51l0.08,0.83
  		l0.01,0.79l-0.06,1.03l-0.19,1.38l-0.32,1.18l0.11,0.98l-0.19,0.45l-0.77,0.23l-0.07,0.37l0.23,0.62l0.54,0.77l0.01,0.49
  		l-0.26,0.33l-0.56,0.03l-0.78,0.31l-0.54,0.99l-0.57,0.82l-0.31,0.16l-1.04-0.23l-0.25,0.21l-0.36,1.39l-0.3,0.6l-0.45,0.5
  		l-0.41,0.3l-0.37,0.21l-0.83-0.14l-0.44,0.84l-0.44,1.1l-0.04,0.57l0.23,0.9l-0.12,0.25l-0.45,0.19l-0.74,0.4l-0.45,1.28l0.02,1.24
  		l0.27,0.43l0.51,0.19l0.36,0.38l0.03,0.6l0.32,0.75l-0.15,0.87l-1.03,1.26l-0.03,0.69l0.55,0.21l0.47-0.1l0.59-0.3l0.69-0.48
  		l1.51-0.7l1.65-0.4l1.3-0.07l1.11,0.03l0.55,0.15l0.16,0.26l1.52-0.41l0.32-0.02l0.28-0.02l-0.01,0.27l-1.12,1.12l-0.21,0.52
  		l0.03,0.56l0.46,0.52l0.44,0.4l0.38,1.01l-0.29,0.92l0.18,0.34l0.33,0.38l0.2,0.53l0.02,0.64l-0.18,1.03l1.61,0.54l1.39,0.24
  		l1.01,0.07l0.65,0.24l0.39,0.2l0.28,0.37l0.08,0.4l-0.34,0.28l-0.69,0.9l-0.39,1.2l0.04,0.9l-0.05,0.78l-0.8,1.12l-0.32,0.77
  		l-0.28,0.79l0.01,0.34l0.16,0.24l0.34,0.14l0.59,0.08l0.72-0.09l1.72,0.19l1.96,0.35l0.78,0.19l0.25,0.35l-0.06,0.76l-0.12,0.71
  		l0.13,0.54l0.32,0.5l0.53,0.17l0.46,0.19l0.09,0.38l-0.14,0.52l0.23,0.41l0.26,0.27l0.56,0.15l1.14-0.33l0.8,0.15l0.72,0.23
  		l0.62-0.12l0.61-0.29l1.33-1.32l1.55-0.71l0.51-0.17l0.34,0.09l0.09,0.09l-0.02,0.27l0.16,1.08l-0.07,0.12l0.49,0.4l-0.18,1
  		l0.25,0.21l0.12,0.36l-0.07,0.56l0.11,0.48l0.2,0.23l0.28,0.12l1.37-0.41l0.83-0.03l0.35,0.27l0.32,0.17l0.4,0.13l0.3,0.17
  		l0.2-0.05l0.47-0.42l0.14-0.07l0.01-0.21l-0.33-0.38l-0.34-0.32l0.01-0.7l0.16-1.22l0.03-0.63l-0.03-1.51l-0.06-0.43l-0.31-0.49
  		l-0.81-0.94l-0.23-0.58l-0.13-0.58l0.01-0.36l-0.21-0.42l-0.11-0.39l0.37-0.2l0.44-0.45l0.22-0.69l0.21-0.5l0.3-0.21l0.26-0.08
  		l0.18,0.04l0.69,0.9l0.87-0.45l0.67,0l0.56,0.24l0.3,0.29l0.41-0.31l0.51-0.45l0.55-0.7l0.62-0.89l0.26-0.54l0.18-0.22l0.36-0.16
  		l0.39,0.09l0.69,0.36l1.03,0.46l0.92,0.54l0.37,0.54l0.51,0.52l0.39,0.16l0.2-0.04l0.59-0.39l0.51-0.39l0.01-0.45l0.47-0.4
  		l0.61-0.66l0.29-0.45l0.22-0.05l0.25,0.13l0.97,1.39l0.19,0.08l0.27-0.21l0.38-1.08l0.42-0.42l0.14,0.11l2.34,2.04l0.22-0.02
  		l0.25-0.09l0.16-0.31l0.18-0.71l0.35-0.76l0.75-0.42l0.58-0.08l0.22,0.36l0.51,0.5l0.62,0.07l1.31-0.88l0.43-0.14l0.46,0.02
  		l0.47-0.04l0.44-0.34l0.47-0.45l0.16-0.88l0.1-0.79l0.32-0.32l0.38-0.21l0.72-0.3l1.63-0.77l0.42-0.36l0.31-0.2l0.63,0.33
  		l0.76,0.64l0.46,0.48l0.31,0.52l0.15,0.44l1.21,0.38l0.77,0.54l0.51,0.41l0.5-0.02l0.5-0.11l0.33-0.33l0.57-0.01l0.67,0.05
  		l0.3,0.13l0.55,0.76l0.25,0.11l0.5-0.05l0.95-0.27l0.77-0.13l0.69,0.3l1.32,0.84l-0.37,0.6l-0.29,0.65l-0.58,0.46l-0.43,0.11
  		l-0.27,0.63l-0.12,0.93l0.15,0.47l0.33,0.15l0.37,0.32l0.15,0.41l0.05,0.92l-0.02,1.04l0.05,0.35l0.29-0.06l0.27-0.12l0.54,0.16
  		l0.68,0.27l0.43,0.27l0.39-0.03l0.3,0.27l0.11,0.51l0.35,0.36l0.99,0.68l0.79,0.06l0.67,0.89l0.69-0.29l0.32,0.39l1.43-0.14l1-0.36
  		l0.36,0.09l0.9,0.74l0.54,0.46l0.8-0.16l0.66-0.34l0.85,0.06l0.47-0.56l0.19-0.63l0.25-0.03l0.24,0.16l0.52,0.63l0.49-0.23
  		l0.2-0.33l-0.28-0.6l-0.1-0.78l-0.01-0.67l0.96,0.03l0.19,0.28l-0.22,0.47l0.22,0.4l0.49,0.4l0.58,0.64l0.23,0.18l0.63-0.04
  		l0.64,0.09l0.92-0.02l0.6,0.32l0.27,0.41l-0.3,0.49l-0.14,0.41l0.56,0.13l0.6-0.16l1.06-0.09l0.75-0.22l0.91-0.51l-0.19-1.36
  		l0.38,0.02l0.24,0.16l0.78,1.29l0.48-0.03l0.13-0.28l0.59,0.67l0.85,0.03l-0.08,0.91l-1.19,0.34l-0.16,0.96l-0.66,0.85l0.2,0.69
  		l-0.35,1.19l0.19,0.38l0.35,0.3l0.35,0.51l0.32,0.6l-0.11,0.78l0.58,0.94l0.72,0.35l0.19,0.23l0.13,0.39l-0.44,0.04l-0.39-0.12
  		l-0.03,0.43l0.47,0.6l1.56,0.27l1.87-0.27l0.88-0.53l0.31-0.62l0.26-0.29l-0.05-0.51l-1.67-1.34l0.11-0.42l0.37-0.28l0.15-0.34
  		l-0.19-0.41l-1.56-0.18l-0.22-0.75l0.33-0.81l1.16-0.82l0.37-0.36l0.19-0.66l0.11-0.79l0.2,0.1l0.2,0.84l0.39,0.2l0.53-0.06
  		l0.72-0.57l1-0.94l0.9-0.08l1-0.3l0.17-0.71l0.37,0.01l0.33,0.23l0.37,0.02l0.64-0.1l0.83,0.07l0.62-0.43l1.77-0.21l0.66-0.43
  		l0.25-0.41l0.54-0.56l0.41,0.08l0.41-0.06l0.45-0.52l0.15-0.64l0.68,0.06l0.77,0.32l0.64,0.38l0.94,0.19l0.43-0.23l0.28-0.3
  		l-0.1-1.02l-0.15-0.53l0.31-0.03l0.28,0.2l0.09,0.27l-0.15,0.41l0.62,0.55l0.77,0.36l0.4-0.38l0.22-0.35l0.54-0.38l0.7-0.37
  		l0.8,0.56l0.42-0.23l0.25-0.27l0.21-1.59l0.68-0.78l0.51,1.17l0.99-0.12l0.52-0.5l0.33-0.17l0.41-0.12l0.49-0.14l0.05-0.01
  		l0.02-0.19l0.11-0.21l0.28-0.04l0.17,0.19l0.02-0.01l0.23-0.63l0.1-0.47l-0.31-0.47l0.01-1.67l-0.58-0.87l-0.29-0.3l-0.15-0.56
  		l-0.73-0.66l-0.06-0.21l-0.06-0.2l0.93-0.25l0.16-0.49l-0.13-0.52l0.63-0.42l0.85-0.22l-0.24,0.45l-0.05,0.5l0.14,0.45l0.14,0.95
  		l0.35,0.53l0.79,0.6l0.62,1.11l0.87,0.67l0.71,0.17l0.02-0.02l0.27-0.25l0.4-0.07l0.21-0.12h0.55l0.34,0.1l0.32,0.11l0.63-0.46
  		l0.67,0.09l0.64,0.5l0.49-0.02l-0.08-0.81l0.23-0.29l0.17-0.39l0.5-0.5l0.5,0.15l0.33,0.99l0.87,0.08l0.36-0.2l0.15-0.4l0.82-0.21
  		l0.67-0.64l0.88-0.31l0.79,0.36l0.78,0.5h0.98l0.31-0.41l0.22-0.53l0.67-0.25l0.97,0.26l0.81,0.2l1.77-0.68l0.8-0.07l0.41-0.28
  		l0.13-0.27l0.25-0.28l0.79,0.36l1.05-0.04l0.41-0.54l0.16-1.53l0.41-0.34l0.7-0.09l0.25-0.44l-0.14-0.44l-0.45-0.23l0.7-0.06
  		l0.4-0.19l0.85-0.62l0.42-1.71l1.03,0.21l0.42,0.4l0.55-0.18l0.65-0.43l0.55-0.6l0.34,0.05l0.13,0.65l0.18,0.42l0.42-0.29
  		l0.38-1.02l-0.02-0.31l-0.22-0.18l0.28-0.43l0.95-0.15l0.41,0.18l0.33-0.91l0.62-0.67l0.82,0.02l0.54-0.84l1.28-1.24l-0.05-0.72
  		l-0.22-0.47l-1.22-0.14l-0.26-0.33l-0.05-0.37l0.46-0.05l0.32,0.05l0.6-0.07l0.07-0.69l0.62-0.6l0.9,0.01l0.85,0.47l0.98-0.25
  		l1.25,0.18l0.81-0.37l0.52-1.11l-0.23-0.32l-0.43-0.24l-0.03-0.24l0.59-0.13l0.96-0.55l0.73-0.16l0.39-0.68l-0.35-1.09l0.53-0.74
  		l0.4-0.13l1.78,0.16l0.42-0.23l-0.35-0.67l-0.62-0.43l-0.19-0.52l0.28-0.32l0.7-0.16l0.68,0.19l0.66-0.12l0.65,0.46l0.79,0.36
  		l0.25-0.21l-0.03-0.41l-0.67-1.38l0.11-0.96l0.54-1.11l0.26-0.86l-0.25-0.76l-0.99-0.05l-0.7,0.49l-0.72,0.05l-0.57-0.23
  		l-1.05-1.32l0.78,0.16l0.68,0.38l0.48,0.08l0.79-0.01l0.88-0.65l1.06-0.98l0.88-0.57l0.71-0.31l-0.04-0.33l-0.3-0.39l-0.08-0.32
  		l-0.39-0.87l-0.5-0.56l-0.74-0.11l-0.12-0.49l-0.28-0.49l-0.02-0.49l0.53,0.34l0.5,0.17l0.47-0.41l0.19-0.51l0.3-0.13l-0.08,0.44
  		l0.05,0.55l0.18,0.43l0.31,0.06l0.02,0.65l0.72,0.22l0.63-0.43l0.37-0.34l0.09-0.98l0.35-0.95l1.18-1.9l0.89-0.53l0.71-0.91
  		l0.6-0.56l0.57-0.85l0.18-0.63l-0.35-0.89l0.65-0.53l-0.03-0.44l0.2-0.51l1.25-1.33v-0.42l-0.59-0.36l-0.13-0.3l0.55-0.03
  		l0.67,0.09l0.56-0.3l0.65-1.09l0.53-1.21l0.4-0.33l0.6-0.18l0.47,1.13l0.69-0.07l1.31-0.86l0.84,0.29l-0.07-0.68l-0.54-1.4
  		l-0.53-1.09l0.37-0.66l0.6-0.19l0.33-0.3l0.27-0.76l-1.03-0.73l0.85,0.15l0.33-0.34l-0.12-0.52l-1.21-0.98l-0.62-0.12l0.33-0.56
  		l0.39-0.4l1.03,0.01l0.52-0.18l0.62,0.28l0.53,0.88l0.54-0.06l0.43-3.25l-0.23-1.06l-0.45-0.2l-0.56,0.21l-1.11,0.86l-1.55,0.24
  		l0.58-0.48l0.86-0.43l1.93-1.79l1.49-0.83l-0.55-0.16l-0.61-0.06l-1.11-0.53l-1.13-0.25l-2.07-2.27l-0.78-0.51l-0.7-0.2l-0.83,0.02
  		l-2.15,1.3l-2.28,0.25l-1.17-1.56l-1.2,0.51l-0.77-0.14l-0.27,0.12l-0.29,0.08l0.43-0.39l1.72-0.96l1.52-0.03l1.61,0.33l0.64-0.35
  		l0.34-0.71l0.5-0.82l2.63-1.3l0.92-0.83l0.91-0.47l1.24-0.21l0.79-0.06l0.91-0.43l-0.37-1.34l-0.41-0.94l-1.06-1.45l-2.61-1.53
  		l-1.23-1.32l-1.26-0.85l-0.99-0.29l-1.26-0.65l-0.3-0.96l-0.31-0.57l-1.84-0.34l-1.26,0.63l-1.31,0.43l-1.31-0.28l0.32-0.22
  		l0.21-0.15l0.72,0.09l2.83-1.3l1.18,0.23l1.1,0.46l1.53,1.51l1.45,0.25l1.02-0.19l0.72,0.04l2.77,1.36l0.7,0.12l0.87-0.04
  		l-0.08-1.05l-0.2-0.77l-0.69-0.87l-0.65-0.54l-1.55-0.65l-0.33-0.3l-0.39-0.49l-0.02-1.54l-0.5-0.5l-0.41-0.3l-2.56-1.02
  		l-1.15-0.88l0-0.96l0.37-0.74l-0.22-1.62l-1.15-2.06l-1-2.38l-0.93-1.39l-0.04-0.74l-0.62-1.41l-0.87-2.86l-0.47-0.99l-0.55-0.49
  		l-2-1.16l-1.63-0.46l-1.57-0.81l-1.32-1.25l-0.64-0.33l-1.27,0.01l-0.3-0.95l0.43-1.55l1.15-0.97l0.65-1.79l0.81-0.54l0.69-1.06
  		l0.94-1.13l0.76-0.28l0.47-0.25l0.38-0.47l0.56-0.45l0.41-0.57l0.23-0.59l1.38-0.71l0.55-0.47l-0.17-0.22l-0.7-0.1l-0.74-0.97
  		l0.19-0.3l0.56-0.5l0.73-0.23l0.49-0.02l0.13,0.37l-0.11,0.76l0.15,0.3l0.38,0.24l1.06-0.53l1-0.2l0.36-0.37l0.01-1.65l0.25-0.7
  		l0.54-0.41l0.6,0.29l0.41-0.17l0.08-0.4l-0.19-0.52l-0.72-0.66l0.12-0.25l0.57-0.02l0.94,0.36l0.54-0.13l0.76-0.47l2.27-0.75
  		l2.16-0.94l2.22-1.19l0.98-0.11l0.95,0.12l0.34,0.3l0.14,0.46l0.19,0.29l0.27,0.15l0.56,0.02l0.98-0.8l0.53-0.31l0.03-0.54
  		l-0.3-0.19l-0.34-0.44l0.58-0.67l0.6-0.42l-0.12-1.32l0.79-0.82l-0.54-0.23l-0.92,0.18l-1.31,0.03l-1.42-0.49l-0.5-0.64l-0.45-0.06
  		l-0.39,0.32l-0.38,0.49l-1.25-0.11l-1.48-0.04l-1.14-0.53l-0.99-0.62l-1.42-0.21l-1.44-1.21l-2.52-1.06l-3.2,1.29l-0.72,0.09
  		l-0.23,0.13l0.25,0.22l0.21,0.33l-1.31,1.24l-2.3,1.4l-0.02,0.53l0.06,0.41l-1.07,0.95l-2.62,0.29l-1.37-0.13l-1.48-0.61
  		l-0.96-0.73l-0.38-0.52l0.02-1.58l0.3-1.43l0.36-0.19l0.47-0.39l-0.16-0.46l-0.27-0.27l-0.02-0.32l-0.07-0.92l-0.74-1.35
  		l-1.18-0.82l-1.12,0l-1.04,0.31l-4.45-0.86l-2.09-1.25l-0.93-1.1l-0.83-1.96l-0.03-0.65l0.53-1.58l1.42-2.76l0.68-0.56l1.48-0.35
  		l0.9,0.31l1.26,1.26l1.47-0.5l1.3-0.58l1.06,0.16l0.62-0.11l0.72,0.06l0.54-0.16l0.53-0.39l1.55-1.83l0.31-1.51l0.51-0.99l0.58-0.9
  		l1.68-1.48l2.18-0.84l4.37-2.15l0.93-0.27l2.46-3.56l0.59-0.6l0.68-0.34l0.68-0.66l0.7-0.92l0.75-0.6l3.05,0.23l0.52,0.35l1.1-0.03
  		l0.31-0.3l0.36-0.93l0.22-0.06l0.2,1.32l2.37,1.53l0.32,0.85l0.81,0.61l-0.09,0.41l-0.51,1.04l-0.1,0.38l-1.75,2.21l-0.96,0.89
  		l-0.57,0.95l-2.39,1.05l-0.36,0.89l0.34,0.69l0.03,0.46l-0.4-0.01l-0.53,0.19l-1.17,0.76l0.16,0.25l0.11,0.66l-0.2,0.66l0.68,0.08
  		l1.32,0.02l2.3-0.26l0.28,0.14l-0.51,0.38l-0.79,0.78l-0.31,0.48l0.44,1.1l-3.51,1.46l-0.63,0.13l-0.69,0.25l0.13,1.05l0.35,0.8
  		l0.37-0.12l0.24-0.23l0.71-0.4l1.66-0.22l1.12-0.34l0.17-0.26l-0.32-0.62l0.37-0.48l0.57-0.06l0.51,0.18l0.5-0.05l0.49-0.39
  		l0.5-0.16l0.55-0.4l0.62-0.57l0.88-1.14l0.93-0.97l4.25-2.31l1.02-0.19l0.6-0.53l1.64-0.13l0.36-0.4l0.67-0.36l1.2-0.05l0.76-0.18
  		l0.26-0.54l0.33-0.4l0.92,0.59l2.91-0.18l1.36-0.82l0.7-0.87l0.1,0.07l0.21-0.99l0.8-0.77l1.95-1.37l0.5-0.64l0.99-0.76l0.45,0.02
  		l0.46-0.07l0.14-0.33l0.11-0.26l0.4-0.23l0.95-0.42l1.08-0.55l0.86-0.15l1.06-0.82l0.43-0.36h0.43l0.12-0.17l0.25-0.43l0.34-0.28
  		l0.47-0.05l0.77-0.2l0.96-0.12l0.66-0.69l0.22-0.5l0.43-0.55l0.92-0.6l0.63-0.88l0.7-0.96l0.33-0.31l0.33-0.06l0.19-0.36l0.22-1.02
  		l0.32-0.99l0.19-0.47l0.81-0.51l0.21-0.25l0.18-0.08l0.38,0.07l0.5-0.3l0.47-0.33l0.43,0.12l0.44,0.28l0.46,0.55l0.2,0.44
  		l0.36,0.37l0.07,0.53l0.36,0.23l0.77,0.12l1.26,0.36l0.81,0.02l0.47,0.27l0.97,0.15l1.95-0.21l0.8,0.12l0.33,0.33l0.49,0.26
  		l0.32,0.02l0.43-0.45l0.46-0.74l0.3-0.56l-0.01-0.45l-0.27-0.48l-0.64-0.45l-0.42-0.7l-0.4-0.72l-0.24-0.23l-0.2-0.35l-0.03-0.54
  		l0.14-0.36l0.97-0.24l1.24-0.14l1.01,0.15l1.68-0.1l1.03-0.2l0.77,0.03l0.7,0l0.31-0.31l0.98-0.74l0.48-0.26l0.52-0.5l0.08-0.53
  		l0.1-0.43l0.29-0.46l0.52-0.56l0.44-0.26l0.49,0.03l0.52,0.26l0.33,0.26l0.37-0.08l0.31-0.44l0.2-0.09l0.59-0.04l0.18-0.27
  		l0.23-1.31l0.23-1.03l0.05-0.72l0.52-1.2l0.16-0.72l0.31-0.34l0.36,0.03l0.3,0.21l0.38,0.12l0.51-0.12l0.36,0.18l0.22,0.39
  		l0.75,0.27l0.07,0.2l-0.02,1.3l0.41,0.61l0.55,0.55l0.75,0.5l0.4,0.11l0.24,0.36l0.48-0.32l-0.07-0.57l-0.47-0.52l-0.69-0.11
  		l-0.16-0.15l0.04-0.28l0.24-0.29l0.34-0.24l0.71-0.33l1.22-0.25l0.68-0.21l0.55-0.07l0.63,0.12l0.53-0.32l0.53-0.19l0.13-0.55
  		l0.02-0.83l0.19-0.25l0.22-0.36l0.34-0.45l0.31-1.18l0.23-0.82l0.15-0.41l0.04-0.56l-0.15-0.37l-0.29-0.22l-0.23-0.16l-0.02-0.63
  		l0.03-0.86l-0.08-0.55l0.33-3.06l0.35-0.71l-1.09-4.1l-0.33-1.31l-0.22-0.66l-0.48-0.97l-0.3-0.48l0.12-0.46l0.43-0.46l0.42-0.22
  		l1.23-0.11l0.34-0.17l1.5-0.5l0.34-0.3l0.77-0.73l0.3-0.55l0.34-0.72l0.74-0.39l0.45-0.65l0.48-0.23l0.48,0.55l0.57,0.31l0.39,0.1
  		l0.4-0.54l1.34-0.43l1.45-0.01l1.5,0.34l1.23,0.69l0.7,0.85l0.01,0.86l0.25,0.05l0.42,0.16l0.4,0.17l0.64-0.46l0.86-0.58
  		l-0.14-0.93l0.14-1.05l0.61-1.81l0.68-0.53l0.36-0.08l0.39-0.2l0.68-0.34l0.25-0.49l-0.14-0.56l0.22-0.55l0.07-0.55l0.24-0.72
  		l0.32-0.2l0.48-0.24l0.34-0.37l0.32-0.56l0.21-0.64l-0.09-0.74l0.42-0.49l0.7-0.4l0.24-0.25l0.11-0.65l0.05-0.28l0.19-0.33
  		l-0.13-0.67l-0.17-0.72l0.77-1.22l0.55-1.05l0.13-1.54l0.06-0.25l0.21-0.73l0.13-0.29l0.43-0.96l0.55-0.63l-0.11-0.7l-0.22-0.69
  		l0.04-0.46l0.48-0.54l0.29-0.27l0.26-0.38l0.41-0.17l0.36-0.09l0.85-0.1l0.49-0.4l0.46-0.41l0.84-1.08l0.27-0.64l0.2-0.33
  		L2944.2,884.37z M2838.25,1061.77l-0.48,0.09l-0.17,0.34l0.35,0.1l0.72,0.6l0.03-0.17l-0.06-0.2L2838.25,1061.77z M2840.2,1060.18
  		l-0.16,0.19l0.3,0.75l0.22,0.06l0.08-0.42l-0.02-0.53L2840.2,1060.18z M2838.18,1058.49l-1.19-0.03l0.07,0.73l0.39,0.46l1.13,0.11
  		l1.04,0.53l0.12-0.18l0.22-0.62l-0.32-0.34L2838.18,1058.49z"/>
    </>,
    CMR: <>
        <polygon className="st1" points="1945.61,1298.87 1945.36,1297.95 1945.27,1296.9 1945.32,1296.62 1945.16,1296.3 1945.15,1296.23
  		1944.96,1295.44 1945.16,1294.89 1945,1294.31 1944.53,1293.74 1944.11,1293.3 1943.86,1292.91 1943.66,1292.74 1943.19,1292.68
  		1942.57,1292.47 1941.74,1291.62 1940.93,1290.78 1939.9,1289.71 1939.07,1288.79 1938.06,1287.66 1937.12,1286.61
  		1936.56,1285.61 1936.33,1285.03 1936.61,1284.97 1937.01,1284.96 1937.18,1284.85 1937.19,1284.58 1936.78,1283.79
  		1936.57,1282.78 1936.23,1282.16 1935.14,1281.21 1934.1,1280.49 1933.78,1280.11 1933.59,1279.58 1933.19,1276.23
  		1933.01,1275.28 1932.68,1274.86 1932.45,1274.67 1932.36,1274.43 1932.4,1273.84 1932.54,1273.3 1932.53,1273.09 1932.81,1272.62
  		1932.81,1269.52 1932.66,1269.36 1932.48,1269.09 1932.19,1269.11 1931.85,1269.09 1931.52,1268.63 1931.25,1268.06
  		1931.33,1267.66 1931.62,1267.32 1931.93,1267.03 1932.33,1266.78 1933.51,1266.29 1933.84,1266.04 1934.05,1265.73
  		1934.19,1265.32 1934.88,1263.72 1935.89,1262.13 1936.33,1261.8 1936.77,1260.74 1937.36,1259.45 1937.6,1258.85 1937.78,1258.25
  		1938.11,1257.77 1939.23,1256.97 1940.08,1255.58 1940.52,1254.9 1940.69,1254.4 1940.74,1253.78 1940.67,1253.36 1940.12,1253.15
  		1939.77,1252.82 1938.98,1250.87 1938.16,1248.86 1937.02,1246.88 1935.77,1245.61 1934.87,1244.84 1934.58,1244.75
  		1934.11,1244.5 1933.79,1244.28 1932.13,1242.93 1930.41,1241.43 1929.98,1240.74 1929.11,1239.72 1928.16,1238.66
  		1927.66,1238.18 1927.42,1237.31 1928.09,1236.53 1928.79,1235.53 1929.66,1234.87 1930.79,1234.83 1932.65,1235.1 1934.66,1235.2
  		1936.64,1234.99 1937.16,1234.85 1937.67,1234.86 1938.73,1235.09 1940.59,1235.04 1941.55,1234.64 1940.52,1233.96
  		1939.41,1232.87 1938.36,1231.68 1937.73,1230.61 1937.15,1229.22 1936.62,1227.51 1936.29,1225.28 1936.34,1224.02
  		1936.51,1223.12 1937.07,1221.66 1936.7,1220.8 1936.78,1220.1 1936.72,1219.08 1936.54,1218.55 1935.82,1216.85 1935.67,1216.66
  		1935.03,1215.48 1934.75,1213.5 1934.03,1212.2 1932.87,1211.56 1932.2,1210.8 1931.96,1209.44 1931.5,1209.08 1929.68,1208.61
  		1928.15,1208.6 1928.96,1212.56 1929.05,1213.31 1929.11,1213.65 1929.17,1213.97 1929.28,1214.51 1929.91,1214.74
  		1931.11,1214.84 1931.99,1215.23 1932.51,1215.88 1932.56,1215.99 1932.84,1216.48 1932.9,1216.84 1932.82,1217.88
  		1932.65,1219.21 1932.35,1220.07 1932.51,1221.23 1932.46,1221.73 1932.33,1222.07 1931.79,1222.46 1931.06,1222.85
  		1929.32,1223.97 1928.82,1224.14 1928.09,1224.17 1927.46,1224.45 1926.71,1225.06 1925.09,1227.32 1923.7,1229.59
  		1923.22,1231.46 1922.69,1233.25 1921.47,1234.4 1921.3,1235.04 1921.25,1235.41 1921.2,1236.28 1921.06,1237.7 1920.87,1238.39
  		1920.67,1238.59 1919.36,1239.03 1918.6,1239.55 1918.15,1240.59 1917.98,1241.71 1917.71,1242.98 1917.56,1244.1 1917.36,1244.68
  		1916.93,1245.29 1916.26,1245.95 1915.68,1246.32 1914.17,1246.56 1913.39,1248.04 1912.74,1249.2 1912.72,1249.66
  		1912.08,1252.06 1910.98,1253.86 1910.91,1254.5 1910.91,1255.03 1909.53,1256.62 1909.16,1257.08 1908.81,1257.7 1909.16,1258.29
  		1909.55,1258.83 1909.6,1259.01 1908.98,1259.51 1907.89,1260.39 1907.23,1260.92 1907.09,1261.21 1906.99,1262.53
  		1906.81,1262.89 1906.37,1263.37 1905.73,1263.9 1905.08,1264.32 1904.35,1264.6 1903.64,1264.71 1903.25,1264.54 1903.02,1264.14
  		1902.62,1262.53 1902.42,1262.18 1901.96,1261.86 1901.06,1260.98 1900.14,1260.09 1899.03,1259.46 1898.8,1259.5 1898.61,1259.67
  		1898.3,1260.57 1897.99,1260.9 1897.41,1261.01 1896.39,1261.02 1895.66,1260.9 1895.49,1260.72 1895.34,1260.33 1895.14,1260.02
  		1894.25,1260.65 1892.87,1261.64 1892.42,1261.8 1892.07,1262 1891.61,1262.93 1891.06,1263.92 1890.34,1264.44 1889.63,1264.87
  		1889.23,1265.25 1888.65,1265.7 1887.51,1266.83 1886.01,1268.31 1885.48,1269.08 1884.96,1270.23 1884.65,1271.5 1884.32,1272.89
  		1883.83,1275.12 1883.11,1276.39 1882.48,1277.39 1882.01,1278.18 1881.76,1278.83 1881.89,1278.86 1881.57,1280.08
  		1881.63,1280.37 1881.92,1280.75 1882.61,1280.84 1882.89,1280.55 1882.64,1279.54 1883.04,1279.75 1883.5,1280.3 1883.88,1280.36
  		1884.29,1280.31 1884.57,1280.36 1884.82,1280.52 1884.69,1281.52 1884.78,1282.16 1884.93,1282.73 1885.31,1283.23 1885.5,1284.4
  		1886.46,1284.82 1887.6,1285.18 1888.01,1285.39 1888.12,1285.66 1888.56,1285.79 1889.09,1285.81 1889.39,1285.64
  		1889.72,1285.16 1889.58,1284.61 1889.81,1284.65 1890.14,1284.92 1890.56,1284.94 1891.14,1284.52 1891.31,1284.69
  		1890.97,1285.09 1890.89,1285.45 1891.7,1286.17 1891.73,1286.4 1890.79,1286.25 1890.5,1286.72 1890.19,1286.86 1890.69,1287.71
  		1890.91,1288.42 1891.95,1288.31 1891.16,1289.04 1892.88,1290.95 1893.21,1291.53 1893.49,1292.88 1892.96,1294.24
  		1892.81,1295.76 1892.43,1297.4 1892.25,1299.36 1892.46,1299.42 1892.5,1299.61 1892.55,1299.88 1892.83,1300.13 1893.76,1300.51
  		1896.51,1300.51 1898.16,1300.51 1900.59,1300.51 1903.16,1300.51 1905.12,1300.51 1905.21,1299.95 1905.32,1299.72
  		1905.28,1299.4 1907.06,1299.38 1910.26,1299.52 1911.66,1299.5 1912.06,1299.53 1913.81,1299.43 1915.23,1299.56 1915.84,1299.69
  		1916.38,1299.76 1918.08,1299.85 1920.3,1299.74 1921.05,1299.76 1921.47,1300.03 1921.67,1300.56 1923.69,1300.58 1925.7,1300.59
  		1927.91,1300.58 1930.03,1300.57 1931.69,1300.62 1932.49,1300.24 1933.25,1300.81 1933.62,1300.93 1933.75,1300.89
  		1934.04,1301.28 1934.99,1301.24 1935.14,1301.33 1935.22,1301.81 1936.09,1301.8 1936.53,1301.9 1936.88,1301.89 1937.39,1301.61
  		1937.75,1301.71 1938.42,1302.06 1938.89,1302.37 1939.55,1302.27 1941.09,1302.33 1942.28,1302.62 1943.46,1303.45
  		1944.25,1303.92 1944.96,1304.62 1945.22,1304.49 1945.47,1304.3 1945.61,1304.22 1945.6,1303.62 1945.2,1302.6 1945.05,1301.73
  		1945.14,1301.02 1945.44,1300.51 1945.95,1300.2 1946,1299.71 1946.01,1299.65 	"/>
    </>,
    CHL: <>
        <path className="st0" d="M1177.18,1722.36l0.26,0.05l0.21-0.17l0.09-0.2l-0.14-0.48l-0.26-0.3h-0.24l-0.29,0.21l0.15,0.56
  		L1177.18,1722.36z M888.36,1550.85l-0.33,0.43l-0.04,0.5l1.3-0.28l0.48-0.36l-0.45-0.05L888.36,1550.85z M1145.69,1610.82
  		l-0.18-0.07l-0.15-0.03l-0.09,0.01l-0.42,0.35l-0.35,0.29l-0.09,0.17l0.05,0.06l1.51-0.2l0.25-0.05l0.05-0.13l-0.13-0.16
  		L1145.69,1610.82z M1178.89,1753.33l-0.67,0.89l-1.88,0.56l-0.41,0.56l0.11,0.29l0.18,0.95l0.46,0.9l0.22,0.58l0.45,0.89l0.23,0.32
  		l0.33,1.56l0.76,0.98l1.8-0.27l1.04,0.16l0.16-0.26l-0.43-1.8l0.15-0.35l-0.02-0.25l-0.11-0.29l-0.41-0.47l-0.32-1.01l-0.11-0.65
  		l-0.12-0.21l-0.64-0.51l-0.34-0.62l0.16-1.83L1178.89,1753.33z M1175.49,1775.09l1.12,0.09l0.85-0.04l0.29-0.18l0.32-0.93l0.02-1.6
  		l0.08-0.45l0.18-0.34l1.82-1.21l0.17,0.01l0.04,0.14l-0.07,0.17l-0.45,0.4l-0.2,0.29l-0.06,0.98l-0.4,0.32l-0.19,0.63l0.02,0.37
  		l0.49,0.96l-0.02,0.72l0.12,0.55l0.4,0.9l0.5,0.09l0.92-0.14l0.21-0.17l0.23-0.79l0.39-0.65l0.2-0.8l0.11-1.04l-0.1-0.25l-0.38-0.1
  		l-0.05-0.4l0.32-1.98l0.14-1.61l-0.08-1.6l-0.46-3.64l-0.13-0.49l-0.17-0.13l-0.72-0.05l-0.8-0.44l-0.39-0.04l-0.87,0.3l-0.62,0.63
  		l-0.09,0.3l0.3,0.77l-0.03,0.77l-0.74,0.67l-1.24,0.67l-0.22,0.7l0.98,0.41l0.06,0.92l-1.1,0.25l-0.44-0.33l-0.48,0.06l-0.9,0.58
  		l-0.29,0.4l0.33,0.54l0.54,0.59l0.49,0.34l-0.26,1.47l-1.55-0.07l-0.42,0.82l0.17,1.03l0.83-0.23L1175.49,1775.09z
  		 M1177.98,1751.78l-0.72-0.04l-0.95,0.4l-0.49,0.38l0.56,0.58l0.07,0.35l0.62-0.14l0.23-0.14l0.28-0.26l1.14-0.47l-0.09-0.36
  		L1177.98,1751.78z M1174.73,1767.57l1.07-0.98l1.24-1.66l0.07-0.86l-0.1-0.69l-0.99,0.06l-0.51,0.35l-1.63,0.43l-0.38-0.13
  		l-0.4,0.22l-0.3,0.29l-0.16,0.61l0.52,0.41l0.3,0.08l0.22,0.23l0.23,0.79l-0.72,0.61l-0.58,0.61l0.51,0.38l0.63,0.01
  		L1174.73,1767.57z M1184.43,1706.06l1.05,0.4l1.05-0.01l0.6,0.16l0.59-0.05l0.63-0.21l0.3-0.56l-0.1-1.35l0.84-0.33l0.92-0.54
  		l0.57-0.82l0.31-0.58l-0.63-0.91l-0.48-0.87l-0.88-0.58l-0.79-0.84l-0.19-0.37l0.63-0.42l0.52-0.16l0.16-0.21l0.71-0.16l0.66-0.27
  		l-0.45-0.75l-0.07-0.79l0.79-0.37l0.14-0.86l-0.27-0.27l-0.2-1.2l-0.33-0.67l-0.09-0.85l-1.71-0.19l-2.58-0.82l-0.22,0.27
  		l0.27,0.31l0.1,0.37l-0.32,1.12l-0.02,0.54l-0.11,0.5l-0.74,1.12l-0.09,0.53l0.05,0.57l-0.08,0.57l-0.16,0.55l-0.04,0.46l0.36,1.11
  		l-0.45,2.93l-0.67,2.04l-0.71,1.08l-0.12,0.47l0.27,0.33L1184.43,1706.06z M1189.08,1718.67l-0.32-0.02l-0.47,0.15l-0.32,0.57
  		l-0.01,0.4l0.15,0.33l0.55,0.7l0.08,0.47l0.25,0.36l0.25,0.02l0.28-0.12l0.13-0.71l-0.1-0.75l-0.11-0.72L1189.08,1718.67z
  		 M1192.21,1718.52l-0.75,0.67l-0.35,0.47l0.21,0.87l0.19,0.51l0.4,0.61l1.02,0.28l2.04-0.83l0.75-0.71l0.44-0.76l0.68-0.93
  		l-0.1-0.42l-0.56-0.53l-1.57-0.76l-1.51-0.51l-0.44,0.16l-0.1,0.45l0.05,0.48l-0.13,0.51L1192.21,1718.52z M1180.8,1709.02
  		l0.03-0.08l-0.07-0.23l-0.21-0.25l-0.4-0.18l-0.61,0.14l-0.2,0.21l-0.01,0.26l0.27,0.31L1180.8,1709.02z M1176.98,1777.37
  		l-0.73-0.11l-0.99-0.37l-0.36,1.11l-0.07,0.61l-0.18,0.27l-0.12,0.7l-0.31,0.96l1.19,0l0.48,0.37l1.65-0.88l0.77-0.65l1.05-0.44
  		l-0.31-0.96l-1.08-0.23L1176.98,1777.37z M1187.64,1710.82l-0.93,0.34l-1.45,0.05l-0.03,0.53l0.21,0.16l1.37,0.35l0.15-0.08
  		l0.29-0.4l0.59,0.08l0.37-0.07l-0.18-0.51l-0.26-0.39L1187.64,1710.82z M1180.73,1731.08l0.98-0.17l0.81,0.36l0.82-0.22l0.47-0.46
  		l0.58-0.4l0.12-0.44l-0.09-0.38l-0.64-1.15l0.25-1.95l-0.21-1.09l-0.94,0.32l-0.24,0.52l-0.44,0.34l0.06,1.47l-0.54,1.04
  		l-0.74,0.78l-0.37,0.65L1180.73,1731.08z M1182.24,1720.76l0.67,1.21l0.58,0.47l0.69,1.54l0.61,0.89l0.89,0.53l-0.08,1.35l0.7,0.2
  		l1.41-0.04l0.12-0.15l0.04-0.45l0.5-0.07l0.16-0.1l0.2-0.74l0.05-0.4l-0.05-0.4l-0.5-0.89l-0.07-0.57l0.03-0.34l-0.3-1.1
  		l-0.42-1.15l-0.35-0.77l-0.7-0.66l0.05-0.55l0.11-0.45l1.02-0.51l0.65-0.08l0.41-0.44l0.27-0.71l0-0.53l-0.96-0.4l-0.39-0.51
  		l-0.3-0.52l-0.8,0.06l-0.74,0.48l-0.21,0.92l0.09,1.17l-0.3,0.27l-0.67,0.11l-0.75-0.32l-1.01,0.41l-0.67,0.39l0.18,1.15
  		l-1.16,0.66L1182.24,1720.76z M1172.53,1761.36l0.24,1.93l0.95-0.01l0.64-0.46l1.76-0.54l0.56-0.53l0.03-2.13l-1.01-2.23
  		l-0.53-1.55l-0.45-0.58l-1.43,0.55l-0.09,0.27l0.15,0.65l0.37,0.8l-0.08,1.06L1172.53,1761.36z M1240.81,1843.15l-1.83-0.12
  		l-0.61,0.14l-0.13,0.18l0.03,0.17l0.13,0.09l1.11,0.1l0.74,0.22l0.31-0.02l0.25-0.14l0.3-0.39l-0.06-0.14L1240.81,1843.15z
  		 M1222.05,1830.38l0.41-0.32l1.14,1.17l0.85,0.54l3.41,0.6l2.01-0.39l0.33-0.27h1.27l0.05-2.63l0.06-3.53l0.01-3.14l0.01-3.05
  		l0.01-3.14l0.01-3.13l0.01-3.34l0.01-3.38l-0.25-0.24l-0.83-0.56l-0.27-0.06l-2.44,1.11l-0.73-0.08l-2.08-2.06l-0.71,0.06
  		l-0.62,0.66l-0.77,1.1l-0.84,0.97l-1.01,0.77l-0.44,0.25l-0.49,0l-0.8-0.6l-0.85-0.51l-1.22,0.12l-0.38,0.21l0.7,0.74l0.32,0.46
  		l0.81,0.48l0.2,0.23l0.07,0.27l-0.25,0.31l-0.3,0.23l-0.5,0.16l-0.54-0.04l-0.6,0.29l-0.44,0.67l-0.14,0.66l0,0.72l0.38,1.13
  		l0.73,0.83l0.98,0.42l1.03,0.05l1.82-0.78l1-0.15l1-0.04l1.05,0.09l1,0.36l0.32,0.49l0.03,0.73l-0.32,0.22l-2.53,1.17l-2.19,0.81
  		l-1.19,0.58l-0.49,0.45l-0.02,1.46l0.55,1.42l0.82,1.13l6.67,2.84l1.27,0.61l-0.01,0.25l-0.27,0.19l-0.43,0.15l-0.35,0.3
  		l-0.71,0.86l-0.5,0.16l-0.09-0.34l-0.02-0.63l-0.33-0.59l-0.46-0.35l-1.7-0.5l-1.01-0.68l-0.57,0.17l-0.49,0.54l-1.04,0.16
  		l-1.51-0.02l-0.63-0.37l-0.02-0.81l-1.13-1.12l-1.31-0.52l-0.8-1.51l1.43,0l0.68-0.22l-0.53-1.08l-0.74-3.06l-0.74,0.32l-0.65,0.83
  		l0.43,1.1l-1.88,0.71l0.09,1.29l-0.05,1.32l0.87,1.52l1.04,0.24l0.81,0.48l0.6,0.81l1.44,1.3l-0.11,0.5l-0.9-0.31l-1.31,0.03
  		l-1.08-0.22l0.11-0.82l-0.1-0.77l-0.83-0.25l-0.85,0.12l-0.25,0.26l-0.15,0.44l-0.17,0.25l-0.96,0.29l-0.66,0.07l-1.65-0.64
  		l-0.32,0.06l-0.9,0.52l-0.61,0.59l-0.28,0.02l-0.92-0.62l-0.71-0.11l-0.2,0.47l-0.7,0.24l-0.17,0.39l0.22,0.85l0.59,0.29l3.29-0.08
  		l1.78,0.87l2.56,0.24l1.6,0.42l2,0.69l1.81-0.67l0.19,0.05l0.18,0.25l0.84,0.49l0.91-0.04l1.1-0.39L1222.05,1830.38z
  		 M1242.44,1840.62l-0.2-0.27l-0.2-0.05l-0.43,0.65l-0.54,0.25l-0.28,0.26l-0.15,0.29l0.03,0.2l1.4,0.33l0.35,0.16l0.23,0.22
  		l0.31-0.09l0.18-0.17l0.04-0.23l-0.4-0.65L1242.44,1840.62z M1236.21,1839.04l-0.53-0.49l-1.29-0.94l-0.18-0.28l0.03-0.29
  		l0.34-0.62l-0.4-0.42l-0.44-0.33l-1.72-0.16l-0.23-0.26l0.13-0.32l1.67-1.01l-0.49-0.96l-1.65-0.02l-2.08,0.7l-6.76-1.16
  		l-1.53-0.43l-0.31,2.1l-0.22,0.58l-0.35,0.23l0.07,0.2l0.78,0.31l0.18,0.19l0.1,0.34l0.25,0.2l1.21-0.21l0.19,0.12l0.01,0.82
  		l0.09,0.26l0.3,0.22l0.86,0.36l0.45,0.63l0.37,0.24l1.44,0.38l0.51-0.02l-1-1.24l-0.51-0.81l0.03-0.31l0.5-1.27l0.88,0.07
  		l0.35,0.14l0.88,0.72l0.33,0.13l0.8-0.2l0.19,0.03l0.01,0.26l-0.36,0.99l0.01,0.27l0.3,0.63l0.24,0.31l0.69-0.17l0.77,0.2
  		l0.84-0.03l1.07,0.46l1.08,0.19l0.38,0.19l0.54,0.95l0.61,0.38l0.63,0.2l0.29-0.09l0.02-0.66l-0.11-0.82L1236.21,1839.04z
  		 M1249.41,1835.37l-0.16,0.05l-0.49,0.28l-0.21,0.23l-0.1,0.25l0.05,0.23l0.16,0.19l0.5,0.03l0.67-0.51l0.31-0.46L1249.41,1835.37z
  		 M1242.34,1513.28l-1.83,0.32l-1.07-0.02l-0.73-0.09l-0.22-0.19l-0.5-0.31l-0.08-0.34l-0.01-0.48l0.14-0.87l-0.07-1.21l-0.58-1.41
  		l0.05-0.45l-0.08-0.69l-0.29-1.29l-0.74-0.65l-0.21-1.07l-0.09-0.94l-0.63-1.18l-0.1-1.5l0.01-1.29l-0.98-1.49l-1.02-1.59
  		l-0.83-0.22l-0.21-0.19l-0.09-0.46l-0.02-0.71l0.07-0.43l0.64-0.69l0.03-0.11l-0.13-0.14l-1.65-1.04l-0.42-0.3l-0.13-0.37
  		l0.01-0.33l0.4-0.34l0.2-0.25l-0.39-0.74l0.04-0.67l-0.25-0.29l0.03-0.22l0.24-0.18l1.07-0.22l0.33-0.68l0.01-0.56l-0.16-0.4
  		l-0.99-1.01l-0.02-0.17l1.04-1.4l0.74-0.92l0.2-0.19l-0.06-0.2l-0.18-0.24l-0.47-0.35l-0.61-0.39l-0.51-0.47l-0.66-0.7l-0.83-0.6
  		l-0.62-0.59l-0.31-0.5l-0.01-0.51l-0.08-0.84l-0.4-1.36l-0.11-0.92l-0.17-1.01l-0.18-0.66l-0.1-0.64l-0.29-0.69l-0.16-0.51
  		l0.23-0.36l0.23-0.28l-0.03-0.17l-1.59-0.74l-0.26-0.19l-0.38-1.48l-1.15-1.31l-0.13-0.98l-0.64,0.58l-0.83,0.66l-1.02,0.13
  		l-0.39,0.34l0.09,0.7l0.33,0.99l0,0.78l-0.31,0.89l-0.73,0.97l-1.12,0.67l-1.05,0.36l-0.83,0l-0.8,0.07l-0.34,0.1l0.48,0.45
  		l0.22,1.71l0.01,2.01l0.5,3.82l0.55,1.91l0.1,1.1l0.34,0.81l0.08,0.86l0.01,3.7l-0.39,2.63l-0.03,1.69l0.92,4.62l0.07,0.91
  		l-0.06,1.19l-0.35,1.3l-0.21,1.98l-0.26,0.95l-0.36,1.92l-0.26,3.19l-0.61,2.58l-0.48,1.07l-0.51,0.57l-0.96,0.2l-0.05,1.03
  		l-0.21,0.73l0.04,1l0.64,1.01l0.78,0.4l0.23,0.33l-0.15,0.79l-0.66,1.12l-0.17,0.92l-0.11,0.73l0.11,1.43l-0.33,1.79l-0.23,2.78
  		l0.13,1.19l0.95,3.51l-0.06,0.71l-0.31,1.11l-0.75,0.99l-0.46,0.52l-0.68,2.13l0.12,0.69l0.54,1.18l-0.23,2.09l0.13,0.93
  		l-0.34,0.83l-0.18,1.57l-0.8,2.2l-0.08,0.99l-0.72,2.14l-0.14,1.09l0.04,1.78l-0.14,0.76l-0.17,0.26l-0.9,0.99l-0.29,0.79
  		l-0.57,2.26l-0.27,2.85l-0.68,1.18l-0.34,1.5l-0.65,0.97l-0.92,0.7l-0.22,0.65l0.28,2.48l1.12,1.39l0.22,0.85l0.09,1.89l-0.27,2.6
  		l-0.44,1.93l-2.27,1.72l-0.33,2.74l0.03,1.21l0.44,2.1l-0.07,1.69l0.71,3.03l0.43,2.87l0.11,3.74l0.77,1.67l-0.34,1.41l0.08,1.14
  		l-1.18,2.9l-0.37,0.5l-0.9,0.68l0.39,1.82l0.01,1.31l0.5,0.85l-0.24,1.25l-1.4,1.57l-0.19,0.66l-0.61,1.19l-0.64,1.41l0.1,1.16
  		l-0.33,1.25l-0.21,1.85l-1.07,2.89l-0.35,1.67l-1.37,1.37l-0.57,0.96l-0.42,1.01l-0.48,0.56l-0.52,0.77l0.31,1.66l-0.81,1.12
  		l-0.8,0.97l-0.81,3.95l-0.79,1.42l-0.33,1.02l-0.94,0.43l-0.17,1.07l-0.11,0.74l-0.18,1.71l-0.36,1.1l-0.46,0.39l-0.87,0.16
  		l-1.91-0.35l-0.27,0.65l-0.24,0.83l0.5,1.34l-0.52,1.08l0.02,1.05l1.22,2.06l0.44,1.26l-0.06,0.87l-0.51,2.31l0.1,1.39l0.33,1.12
  		l2.14,5.89l-0.2,1.95l-1.35,3.62l-0.6,0.64l-1.59,1.08l0.01,1.18l-0.62,1.79l-0.35,2.04l-1.15,4.02l-0.53,1.03l0.15,1.44l0.76,2.01
  		l0.18,1.27l0.37,0.71l0.84,0.56l0.73,0.08l-0.01,0.31l-0.53,0.28l-0.28,0.54l-0.11,0.5l0.94,0.31l0.87,0.24l2.35-0.16l0.57-0.34
  		l1.34-2.04l0.52-0.29l0.61,0.03l0.63,0.27l1.14,1.16l0.58,0.25l0.49,0.07l0.97-0.45l0.57-1.33l0.35-0.15l-0.35,1.51l-1.06,0.73
  		l-1.46,0.21l-0.71,0.58l-0.34,0.47l-0.34,0.62l0.36,0.51l0.36,0.35l0.96,0.16l1.05-0.3l0.33,2.28l0.4,1.84l-0.15,0.46l-1-1.8
  		l-0.7-0.57l-0.64,0.21l-0.55,0.38l-0.1,0.44l0.58,1.11l0.71,1.01l-0.19,0.07l-1-0.12l-0.62,1.66l0.03,1.41l0.66,1.02l0.09,0.86
  		l-0.02,0.47l-1.01,0.09l-0.32,0.87l-0.21,0.79l-1.15,1.15l-0.21,1.34l0.88,1.81l-0.61,2.36l-1.31,0.37l-0.14,1.73l-0.21,1.06
  		l1.05,0.71l1.18,0.57l1.46,1.06l1.38,0.42l-0.14,1.63l-0.5,1.45l-2.86,1.93l-1.5,0.42l-0.89,0.18l-0.36,1.29l-0.34,1.41l1.84,0.18
  		l1.37,1.09l0.74,0.34l0.29,0.26l0.06,0.37l-0.37-0.01l-1.89-1.02l-0.54-0.08l-0.95,0.38l-1.44,1.05l-1.52-0.04l-0.42,1.55l0.2,0.78
  		l0.8,0.29l0.57,0.48l0.02,1.28l-0.32,0.92l-0.19,1.82l-0.14,0.56l0.05,0.9l-0.46,1.24l-0.46,0.89l-0.63,0.7l-0.83,0.06l-0.04-0.41
  		l0.12-0.34l1.05-1.3l0.86-3.23l0-1.09l0.11-1.12l-0.34-0.5l-0.65,0.07l-0.56,0.3l-0.43,2.13l-0.32,1.1l-1.03,0.72l-1.04,0.18
  		l-1.34,0.07l-0.17-0.3l0.3-0.05l2.32-0.85l0.52-0.8l-0.35-1.14l0.52-0.55l0.33-0.63l0.66-2.78l0.32-0.7l0.16-0.58l-0.8-0.41
  		l-0.31-0.03l-0.68,0.14l-0.52,0.45l-0.2,0.37l0.2,1.12l0.14,0.43l0.01,0.35l-0.12,0.4l-0.52,0.53l-1.21,0.38l-0.57,0.07l-0.79,0.33
  		l-1.41,0.04l-1.12-0.22l-2.56,0.54l-0.07,1.36l0.65,0.98l0.62,0.65l-1.27,0.79l-1.45,1.42l-1.09,0.63l-0.51,0.57l-1.85,1.34
  		l-0.42,1l-0.01,0.74l0.61,0.93l1.17,0.82l0.56-0.06l0.25-0.31l0.12-0.46l-0.5-1.19l-0.69-0.05l-0.32-0.19l0.21-0.32l0.52-0.38
  		l1.19-0.16l1.61-0.5l1.07-0.95l0.29,0.02l-0.58,1.22l0.18,0.71l0.21,0.48l1.65,0.62l1.01,0.68l1.5,0.22l0.27,0.01l0.11-0.23
  		l-0.19-0.32l0.04-0.42l0.25-0.3l1.18,0.23l0.88,1.02l0.48,0.95l-0.47,1.15l0.42,1.06l-0.48,0.29l-1.58,1.26l-0.67,1.1l1.34,1.08
  		l0.68,0.3l0.44,0.09l0.48,0.24l-0.15,0.38l-0.77,0.56l-0.67-0.13l-0.68-0.71l-0.53-0.24l-0.56-0.11l-0.46,0.54l-0.56,0.9l0.39,0.6
  		l0.33,0.16l1.18,0.29l0.45,0.32l-0.03,0.66l0.24,0.57l1.04,0.26l1.2-0.15l1.21-0.27l0.79-0.67l0.57-1.38l0.26-0.83l0.27-0.06
  		l0.68,2.41l0.05,0.66l0.16,0.56l0.34,0.27l0.58,0.94l0.93,0.42l0.06,0.34l-1.21,0.22l-2.74-1.68l-3.34,0.03l-1.26-0.34l-1.55-0.15
  		l-0.05,1.75l0.11,1.21l0.66,0.95l0.21,1.1l1.11,0.31l0.61-0.41l0.83-0.29l1.37,0.51l-0.4,0.31l-0.61,0.01l-0.4-0.11l-0.43,0.25
  		l-0.95,0.85l-0.35,2.14l0.02,2.76l0.18,3.3l-0.07,0.54l0.15,0.28l0.4,0.41l0.68,0.4l0.31-1.05l0.14-0.91l0.24-0.76l0.56-0.68
  		l0.11-0.84l0.28-0.92l0.78-0.06l-0.02,0.27l-0.39,0.15l-0.26,0.34l0.08,0.73l-0.15,0.94l-0.22,0.67l-0.29,0.61l-0.09,0.74l0.9,0.67
  		l0.8,0.36l0.47,0.94l-0.46,0.16l-0.54-0.34l-1.23-0.41l-1.08,0.26l-0.51,0.27l-0.24,1.27l-0.04,0.69l1.29,1.36l0.83,0.45l0.52-0.22
  		l0.44,0.73l-0.51,0.31l-2.65-0.53l-0.85,1l-1.64,1.42l0.95,0.79l0.77,0.93l0.43,0.14l0.58,0.39l2.31,0.79l0.68,0.45l-1.98-0.28
  		l-0.1,1.38l0.28,0.31l0.57,0.88l1,1.22l0.73-0.49l1.19-2.69l0.59-0.55l0.21,0.03l-0.33,0.85l0.67,0.64l-0.04,0.26l-0.34-0.01
  		l-0.69,0.51l-0.71,1.54l0.15,1.14l-0.34,0.02l-2.46-1.36l-0.14-0.23l-0.29-0.22l-1.19-2.42l-0.29-0.79l-1.67-1.17l-0.68-0.23
  		l-0.65,0.52l-0.46,0.68l0.62,0.72l0.45,0.92l-0.31,0.48l-1.27,0.19l-2.17,0.03l0.33,1.16l0.61,1.06l1.42,2.02l1.05,0.26l0.87,0.49
  		l0.67,0.21l0.79,0.14l0.69,0.36l1.03,0.11l0.75-0.1l1.53,0.79l0.37,0.73l-0.29,3.2l-1.18-0.44l-1.07,1.14l0.42,0.35l1.46,0.81
  		l0.66-0.3l0.31,0.36l0.4,0.13l0.49-0.06l0.86,0.68l0.57,1.17l0.54,0.91l1.14,0.33l1.64-0.89l2.19-1.47l2.77-0.68l0.14-0.69
  		l-0.55-0.47l-0.47-0.19l-0.71-0.71l-2.74-1.23l-0.41-0.1l-0.28-0.2l0.24-0.27l0.35-0.16l3.08,1.49l1.84,1.49l0.45,0.65l-0.04,0.94
  		l-0.24,0.49l-0.86,0.62l0,0.68l0.09,0.34l0.75,1.5l0.04,0.53l-0.03,0.43l-0.08,0.3l-0.3,0.58l-0.53,0.43l-0.38,0.15l-0.31-0.32
  		l0.17-0.3l0.93-0.86l0.11-0.61l-0.14-0.62l-0.52-0.51l-0.38-0.62l-0.01-0.67l-0.34-0.28l-0.5-0.12l-0.41,0.13l-0.85,0.97
  		l-1.63,0.93l-1.04,0.32l-0.56,0.09l-1.1-0.22l-0.63,0.08l-0.98-0.85h-0.3l-0.01,0.66l-0.15,0.7l-0.39,0.19l-0.72,0.2l-1.73-0.84
  		l-0.79-0.05l-0.52-0.39l-0.59-0.17l-0.26,0.15l0.25,0.6l0.23,0.35l0.52,1.3l0.22,0.74l0.48-0.07l0.47,0.3l0.32,1.24l-0.3,0.73
  		l0.18,0.7l0.84,0.55l1.72-0.3l1.05,0.28l1.71-1.03l1.16,0.33l0.56-0.7l-0.05-0.85l0.5,0l0.43,0.53l-0.6,0.76l-0.81,1.19l-0.89,0.54
  		l-2.52,0.94l1.16,0.76l0.4,0.7l1.02,1.02l1.82,0.22l0.56-0.32l0.27-0.27l0.06-0.51l-0.04-0.98l0.74-1.25l1.09-0.79l0.3-0.79
  		l-0.09-0.74l0.54-0.47l0.57-0.07l1.18,0.35l0.22,0.5l0.34,0.25l1.03-0.99l0.76-0.2l3.48,0.18l1.24,0.26l1.29,0.51l-0.36,0.44
  		l-0.31,0.19l-1.73,0.25l-1.53-0.42l-1.16,0.04l-2.83,1.87l-1.46,0.04l-0.05-0.5l-0.37-0.28l-0.44,0.15l-0.88,0.65l-0.48,0.59
  		l-0.17,0.74l-0.05,2.11l-0.7,0.63l-0.46,0.76l0.46,0.54l2.29,1.48l1.5,0.47l0.15-1.02l0.32-0.93l0.29-0.41l1.52-1.4l1.25-0.78
  		l1.95-0.71l4.3-2.7l1.35,0.53l0.61,0.4l-0.07,0.48l-0.14,0.37l-0.92,1.29l-0.94,0.84l-2.86,1.43l-0.43,2.89l-0.84,0.45l-0.09-0.32
  		l0.3-0.43l0.33-0.69l-0.21-1.29l-0.75-0.59l-1.17,0.18l-1.41-0.03l-0.48,0.08l-0.9,1.1l0.12,0.77l0.18,0.61l1.7,1.85l0.62,0.38
  		l1.93,0.65l1.5,0.93l2.1,0.44l1.23,0.49l1.81-0.67l0.73-0.53l0.4-2.4l-0.31-1.4l0.01-0.86l0.27-1.68l1.1-3.01l-0.15-0.84l0.37-1.37
  		l0.97-0.64l0.99-0.44l1.45-0.14l4.07-1.67l1.21-0.09l1.2-0.46l0.5-0.49l0.96-1.72l1.73-0.72l0.9,0.07l1.07,0.58l4.75,1.06
  		l-0.15-0.75l-1.09-0.19l-1.06-0.2l-1.76-0.53l-2.37-0.81h-2.38l-1.89-0.68l-2.09-0.76l-4.4-0.07l-3.88-0.05l-3.97-0.05l-2.54-0.03
  		l-1.7-0.02l-0.44-0.29l0.15-0.94l-0.63-0.69l-0.91-0.84l-1.11-0.59l-0.55-0.79l-0.62-0.89l0.35-0.79l0.53-1.91l0.01-0.84
  		l-0.48-0.59l-0.15-0.83l0.15-0.39l0.44-0.3l0.26-1.37l-0.2-1.34l-0.33-1.19l-0.44-0.53l-0.57-0.25l-0.42-0.05l-0.93,0.44
  		l-1.54-0.11l-0.52,0.17l-0.76,0.48l-1.07,0.71l-0.59-0.24l-0.18-0.76l-0.4-0.66l-0.25-0.58l-0.19-0.95l-0.32-1.22l-0.63-1.44
  		l-0.97-1.16l-0.05-1.04l-0.18-1.31l0.49-1.28l-0.29-1.06l-0.6-1.26l0.19-1.3l0.59-0.72l0.19-0.91l2.75-0.14l-0.11-1.23l0.46-0.99
  		l0.51-0.9l0.44-0.41l0.98-0.36l1.15-0.52l0.65-0.59l0.31-0.53l0.19-0.68l0.05-0.73l-0.19-1.55l0.21-0.47l0.71-0.63l1.21-0.56
  		l0.52-1.47l-0.3-1.28l-0.67-1.01l-0.85-0.46l-0.07-1.04l0.38-0.99l0.5-1.06l0.6-1.21l-0.04-0.85l0.53-0.49l1.51-1.1l0.52-1.08
  		l0.53-0.29l0.62-0.13l0.04-0.61l-0.45-0.6l-0.07-0.76l0.05-0.84l0.14-1.12l0.71-0.42l1.04-0.91l0.28-0.58l0.04-0.77l-0.3-1.59
  		l-0.26-1.14l-0.13-0.42l-0.48-0.77l-0.35-0.49l0.56-0.61l1.05-0.64l0.45-0.93l-0.41-0.79l-0.59-0.42l-0.19-1.2l0.22-1.52l0.45-0.46
  		l1.56-0.23l0.15-0.78l1.19-1.1l-0.04-1.06l-0.76-0.65l-0.74-1.04l-0.55-0.92l-1.82-0.5l-1.93-0.27l-0.26-0.87l0.07-0.5l0.9,0.2
  		l1.47-0.18l1.1-0.04l0.77-0.09l0.89-0.13l0.82,0.37l0.82-0.23l0.33-1.37l0.52-0.73l0.07-0.68l-0.52-0.55l-0.95-0.17l-4.16-0.43
  		l-0.13-0.55l0.04-0.92l0.16-0.93l0-0.46l0.38-0.41l0.43-0.85l0.3-0.56l-0.3-0.73l-0.66-1.08l0.48-0.5l0.04-0.59l-0.15-0.58
  		l-0.69-0.65l-0.61-0.89v-0.95l0.71-0.25l0.47-0.29l0.11-0.59l-0.26-0.72l-0.99-0.22l-1.31-0.44l-0.4-0.37l-0.37-0.77l0.28-2.17
  		l-0.14-1.3l-0.12-0.72l0.32-0.55l0.44-0.5l-0.21-1.16l-0.39-0.61l0.14-0.47l0.37-0.47l0.32-0.58l0.28-0.14l0.41,0.33l0.7-0.19
  		l0.84-0.47l0.09-0.55l-0.17-0.79l-0.63-1.98l-0.56-1.22l0.11-0.44l0.22-0.46l-0.18-1.68l0.06-1.01l0.04-2.98l0.06-1.02l-0.58-1.04
  		l0.08-0.97l0.41-0.71l0.38-0.96l0.29-0.85l0.3-0.38l0.51-0.19l0.12-0.46l-0.23-0.35l-0.66-0.55l-0.15-0.67l0.14-0.52l0.32-0.3
  		l0.5,0l0.38-0.73l0.11-0.91l0.08-0.42l-0.29-0.53l-0.21-1.25l-0.19-0.71l0.23-0.3l0.32-0.11l0.57,0.17l0.4-0.09l0.07-0.38l0.05-0.4
  		l0.15-0.28l0.36-0.92l0.38-1.13l0.09-0.8l-0.14-2.16l0.2-0.5l0.41-0.45l0.57-0.43l0.75-0.35l0.93-0.51l1.14-0.19l0.46-0.56
  		l0.32-0.75l0.09-0.61l-0.44-0.43l-0.58-0.51l-0.27-1.28l-0.15-1.18l-0.08-1.49l-0.57-1.28l-0.6-1.44l-0.16-1.27l0.2-0.7l0.24-1.11
  		l-0.25-0.5l-0.3-0.9l0.31-0.7l0.38-1.09l-0.05-0.55l-0.3-1.32l-0.28-0.74l0.28-0.79l0.44-0.74l0.35-0.39l-0.06-0.64l0.15-0.52
  		l0.65-0.35l0.61-0.65l0.44-0.08h0.53l0.35-0.18l0.14-0.5l0.09-0.55l0.84-0.68l0.49-0.63l0.9-0.13l0.44-0.68l0.01-0.87l-0.1-0.88
  		l0.3-1.02l-0.33-1.55l0.03-0.82l-0.35-0.68l0.07-0.73l-0.18-0.47l-0.52-0.17l-0.19-0.58l0.25-0.29l0.49-0.22l0.62-0.45l0.46-2.14
  		l0.22-0.63l0.21-0.76l-0.03-0.4l0.3-0.57l0.37-0.87l0.58-0.84l0.34-0.58l0.32-0.78l0.09-0.47l0.41-0.23l0.48-0.06l0.56-0.15
  		l0.23-0.28l-0.04-0.41l-0.04-0.91l-0.17-1.45l-0.11-1.87l0.1-1.23l0.37-1.23l0.35-0.67l-0.09-0.51l-0.09-0.56l-0.64-0.31
  		l-0.61,0.27l-0.43-0.07l-0.55-0.65l-0.16-0.69l0.09-0.95l0.43-0.59l0.17-0.74l-0.25-0.23l-0.54-0.49l-0.51-1.7l0.06-1.44
  		l-0.51-0.38l-0.24-1.13l-0.52-0.4l-0.21-0.84l-0.16-0.87l0.07-0.38l0.54-0.11l0.31-0.68l-0.23-0.38l-0.41-0.33l-0.53,0.03
  		l-0.47-0.39l-0.64-1.63l-0.5-0.9l0.16-1.31l0.1-1.02l0.22-0.87l0.08-0.69l0.39-0.33l0.37,0.15l0.35-0.08l0.32-0.56l0.35-0.35
  		l-0.02-0.28l-0.21-0.3l-0.1-0.53l0.24-0.63l0.42-1.45l0.64-1.59l0.27-0.59l-0.07-0.5l0.14-0.23l0.43,0.25l1.23-0.28l0.41-0.7
  		l0.16-0.63l0.37-0.35l-0.16-0.5l-0.51-0.15l-0.31-0.24l0.12-0.57l0.18-1.31l-0.03-0.96l-0.46-2.05l-0.37-2.02l0.26-0.68l0.8-0.92
  		l0.61-0.42l0.11-0.53l0.6-2.39l0.07-1.3l0.4-0.72l0.26-1.35l1.09-1.16l0.32-0.77l0.44-0.07l0.23-0.25l0.58-0.86l0.75-0.88
  		l0.65-0.44l0.16-0.69l0.31-0.95l0.64-1.57l0.36-1.09l0.48-0.4l0.56-1.43l0.24-0.84l0.64-0.35l0.51-0.1l0.48,0.4l0.51-0.07
  		l0.46-0.49l1.11-0.34l0.5-0.18l0.23-0.49v-0.86l-0.46-0.64l-0.94-1.23l-0.81-1.37l-0.09-0.43l0-0.47l0.14-0.59l0.39-0.67l0.97-1.11
  		l-0.1-0.79l-0.71-2.91l-0.26-0.8l-0.49-1.48l0.07-0.59l0.43-1.64l0.37-0.66l0.55-0.12l0.3-0.22l0.09-0.29l-0.37-0.36l-0.16-0.46
  		l-0.16-0.66l-0.51-0.23l-0.29-0.55v-0.8l0.46-1.05l0.51-0.29l0.21-0.46l0.54-0.43l0.49-0.33l0.41-0.61l1.71-0.74l1.36-0.58
  		l2.65-1.1l1.82-0.76l0.17-0.52l0.14-0.36l0.84-2.66l1.09-3.44l0.68-2.15l-1.57-1.59L1242.34,1513.28z M1244.49,1834.17l-1.16-1.01
  		l-1.51-0.1l-3.79-0.46l-1.96,0l-1.64,0.6l1.07,1.06l0.33,1.19l0.3,0.4l0.25,0.17l2.55,0.45l0.26-0.04l0.38-0.16l0.89-0.6l0.42-0.16
  		l0.34-0.01l0.26,0.05l0.17,0.23l0.12,0.42l0.25,0.42l0.5,0.24l0.69-0.13l0.71-0.46l0.53-0.59l0.25-0.45l-0.05-0.45L1244.49,1834.17
  		z M1178.55,1792.59l0.7,0.07l0.47-0.25l0.56-0.5l0.34,0.03l0.59,0.28l0.23-0.09l0.27-0.3l0.02-0.94l-0.1-0.35l-0.35-0.43
  		l-1.05,0.01l-1.22,0.8l-1.34,0.43l-0.95-0.44l-0.47,1.17l-0.24,0.78l-0.52,1.15l0.09,0.77l0.81-0.66l0.39-0.47l0.83-1.41
  		L1178.55,1792.59z M1180.83,1800.8l1.13-1.89l-0.04-0.3l-0.93-1.11l-0.86-0.16l-0.26-0.3l-0.36-2.18l-0.73,0.22l-0.05,0.99
  		l-0.78-0.16l-0.82,0.73l0.46,1.29l0.28,1.51l0.84,1.29l0.56,1.34l1.33,0.1L1180.83,1800.8z M1189.66,1812.95l0.18,0.27l0.11,0.54
  		l0.13,0.08l0.56,0.13l0.77,0.02l1.55,0.43l0.76-0.05l-0.07-0.15l-1.34-0.93l-0.07-0.14l-1.18-1.18l-0.45-0.05l-0.38-0.16
  		l-0.88-0.64l-1.08-0.16l-0.82-0.5l-1.57-0.53l-1.76-0.23l-0.47-0.18l-0.33,0.02l-0.14-0.1l-0.31-0.71l-0.44-0.28l-0.82-0.73
  		l-0.83-0.43l-0.35,0.17l0,0.22l0.77,0.76l0.52,0.99l1.21,0.83l1.21,0.99l0.28-0.06l0.82,0.16l1.22-0.17l1.07,0.24l0.62,0.27
  		L1189.66,1812.95z M1176.3,1783.16l0.36-0.29l0.38-0.49l-0.34-0.15l-1.24-0.14l-1.04-0.04l0.07,0.55l-0.19,0.72l-0.29,0.65
  		l0.21,0.31l0.12,0.65l0.23,0.26l0.68,0.09l0.24-1.02l0.08-0.92L1176.3,1783.16z M1205.29,1824.46l0.76-0.05l0.95,0.23l0.71-0.17
  		l1.42,0.96l1.36,0.7l0.22-0.09l0.29-0.58l0.46-0.41l0.19-0.4l-0.15-0.98l0.01-0.58l-1.24,0.01l-1.87-0.92l-1.38-0.89l-1.27-0.38
  		l-2.45-0.44l-0.61,0.42l-0.65,0.2l-0.54,1.26l1,0.82l1,1.02l0.2,1.09l1.1-0.28L1205.29,1824.46z M1182.87,1797.26l0.72,0.59
  		l0.47,0.23l0.94,0.26l0.25-0.12l0.2-0.22l0.03-0.25l-0.15-0.2l-1.21-0.67l-0.41-0.46l-0.31-0.22l-0.75-0.29l-0.21,0.01l0.32,0.73
  		L1182.87,1797.26z M1215.62,1832.51l-0.68,0.28l-1.13,0.08l-0.47,0.18l-1.04-0.29l-0.14-0.14l-0.39-0.74l-0.82-0.01l-0.91-0.27
  		l-1.49-0.11l-0.31,0.06l-0.22,0.58l0.09,0.29l0.17,0.2l0.15,0.04l0.53-0.24l0.22-0.25l0.22-0.06l0.59,0.07l0.7,0.52l1.09,0.62
  		l0.2,0.19l0.02,0.38l0.21,0.26l1.05,0.21l0.6,0.28l0.28,0.03l0.59-0.26l0.37-0.03l0.39,0.35l0.1,0.27l-0.04,0.31l0.57,0.18
  		l0.6-0.13l0.9-0.61l0.12-0.56l-1.13-1.84L1215.62,1832.51z M1200.7,1818.63l-0.56-0.44l-0.73-1.02l-0.19-0.12l-1.71-0.35
  		l-1.04,0.09l-0.61,0.15l-0.12-0.18l-0.23-0.93l-0.2-0.45l-0.2-0.22l-0.43-0.1l-0.27-0.23l-0.17,0.03l-0.31,0.33l-0.04,0.26
  		l0.13,0.73l-2.24-0.48l-0.68-0.69l-2.02,0.19l-1.34,1.36l1.72,0.28l0.5,0.98l0.93,0.93l0.93-0.14l0.3-0.02l0.09,0.08l0.16,0.72
  		l0.02,0.5l-0.16,0.97l0.06,0.28l0.79,0.48l0.76,0.27l0.33-0.13l0.08-0.23l-0.12-0.72l0.11-0.47l0.28-0.49l0.47,0.32l0.41,0
  		l0.54-0.14l0.91,0.19l-0.15,1.04l-0.84,1l-0.65,0.28l0.11,0.3l0.33,0.26l0.31,0.14l0.26-0.02l0.44-0.25l0.94-0.28l0.96-0.06
  		l0.78-0.53l0.52-0.28l0.33-0.27l0.03-0.46l0.5-0.91l0.85-0.63L1200.7,1818.63z"/>
    </>,
    COK: <>
        <polygon className="st1" points="464.3,1498.8 464.05,1498.63 463.7,1498.61 463.52,1498.73 463.43,1498.98 463.45,1499.06
  		463.68,1499.1 464.02,1499.16 464.29,1499.16 464.32,1499.08 	"/>
    </>,
    CIV: <>
        <path className="st0" d="M1782.36,1275.82l1.11,0.21l0.23-0.33l-1.08-0.16L1782.36,1275.82z M1788.17,1247.42l-0.35-2.39l-0.02-0.2
  		l-0.21-0.33l-0.2-0.99l-0.34-0.58l-0.48-0.17l0-0.54l0.48-0.92l0.13-0.54l-0.23-0.16l-0.03-0.42l0.17-0.68l-0.08-0.42l-0.18,0.2
  		l-0.42,0.27l-0.42-0.01l-0.49-0.63l-0.22-0.28l-0.4-0.64l-0.34-0.65l-0.46-0.28l-0.45-0.26l-0.55-0.82l-0.53-0.39l-0.56,0.11
  		l-0.81-0.15l-1.64-0.2l-1.76,0.06l-0.73,0.19l-0.72,0.3l-1.83,0.66l-0.72,0.33l-0.55,0.82l-0.62-0.02l-0.62-0.26l-0.39-0.37
  		l-0.84,0.08l-0.81-0.36l-0.78-0.71l-0.57-0.23l-0.73-0.52l-0.2-0.98l-0.47-0.69l-0.43-0.96l-0.64-0.43l-0.73-0.23l-1.01,0.05
  		l-0.67-0.39l-0.52-0.56l-0.28-0.12l-1.16,0.06l-1.26,0.37l-0.44,0.29l-0.1,0.4l-0.28,0.27l-0.4,0.3l-0.39,0.37l-0.7-0.06
  		l-0.67-0.26l-0.35-0.25l-0.02-0.15l0.22-0.37l0.19-0.4l0.02-0.26l-0.23-0.64l-0.18-0.69l0.08-0.33l-0.17-1.02l-0.09-0.05
  		l-0.88,0.26l-0.32,0.07l-0.18,0.11l-0.06,0.2l0.21,0.65l-0.14,0.11l-0.49-0.02l-0.69-0.21l-0.75-0.59l-0.19,0.19l-0.08,0.47
  		l-0.05,0.56l0.19,1.01l-0.2,0.36l-0.5-0.06l-0.68,0l-0.59,0.1l-0.39,0.02l-0.25,0.36l-0.1,0.4l0.24,0.45l-0.04,0.19l-0.18,0.17
  		l-0.23,0.11l-0.19-0.01l-0.55-0.5l-0.65-0.19l-1.52-0.29l-0.18-0.68l-0.25-0.01l-0.35-0.36l-0.35-0.47l-0.29,0.03l-0.25,0.13
  		l-0.83-0.05l-0.74,0.72l-0.55,0.89l-0.59,0.43l-0.65,0.19l-0.25,0.01l-0.19,0.31l-0.54,0.49l-0.5,0.38l-0.16,0.41l0.08,0.77l0,1.75
  		l0.08,1.51l0.41,0.55l0.49,0.28l0.58-0.05l0.56-0.1l-0.03,0.9l-0.15,1.01l0.66,0.31l0.33,0.31l0.18,0.29l-1.05,0.54l-0.3,0.31
  		l-0.14,0.85l0.03,0.78l1.41,0.56l0.54,0.65l0.24,0.68l0.08,1.28l-0.13,0.29l-0.36,0l-0.41-0.39l-0.3-0.39l-0.38,0l-0.71-0.09
  		l-0.81-0.15l-1,0.04l-0.35,0.06l-0.23,0.23l-0.06,0.4l-0.1,1.3l0.33,0.29l0.64,0.32l0.42,0.14l0.35-0.04l0.27,0.21l0.06,0.56
  		l-0.18,0.41l-0.35,0.38l-0.45,0.98l0.08,0.37l0.02,0.53l-0.76,1.44l-0.22,0.28l-1.01-0.29l-0.66-0.09l-0.48,0.36l0.16,0.1
  		l0.25,0.26l0.24,0.88l0.94,2.84l-0.05,0.78l-0.19,0.51l-0.01,0.5l-0.06,0.49l-0.58,0.81l-1.7,1.66l0.13,0.15l0.41,0.19l0.41,0.1
  		l0.34-0.05l0.43,0.41l0.46,0.52l0.49,0.27l0.7,0.24l0.61,0.03l0.52-0.09l0.74,0.1l0.78,0.43l0.28,0.71l0.19,0.62l0.27,0.31
  		l0.04,0.54l0.56,0.47l0.79,0.1l1.03,0.55l0.26-0.03l0.11-0.07l0.13,0.1l0.26,1.59l0.2,0.85l-0.11,0.34l-0.14,0.27l-0.01,1.29
  		l-0.47,0.74l-0.08,0.81l-0.13,0.26l-0.5,0.23l0,0.62l-0.13,0.75l-0.05,0.8l0.14,2.09l0.03,1.56l0.22,0.29l1-0.21l1.64-0.92
  		l1.46-0.49l1.14-0.78l0.65-0.28l2.5-0.76l4.1-1.6l1.25-0.49l2.94-0.66l4.24-0.35l-0.36-0.26l-1.36,0.02l-0.86,0.08l0.27-0.35
  		l0.45-0.15l2.18,0.06l0.45,0.47l0.59,0.08l2.01-0.29l5.26-0.48l-0.21-0.22l-0.45-0.04l-4.16,0.22l0.47-0.37l1.65-0.18l2-0.07
  		l1.15,0.14l0.96,0.61l4.41,0.76l0.3-0.25l0.63-1.46l0.32-0.16l0.41,0.05l-0.15,1.22l0.88,0.38l0.32,0.06l0.06,0.17l0.6,0.1
  		l0.45-0.25l0.67-0.03l0.17-0.26l0.06-0.67l-0.01-0.54l0.23-0.24l0.06-0.63l-0.33-1.41l-0.23-0.16l-1.19-0.2l-0.09-0.28l-0.21-0.29
  		l-0.23-0.73l-0.26-1.08l-0.42-1.34l-0.8-2.21l-0.2-0.78l-0.14-0.79l-0.03-0.95l0.17-0.35l-0.03-0.49l-0.07-0.49l0.56-1.12
  		l1.11-1.38l0.23-0.5l0.21-0.35l0.03-0.49l0.2-1.61l0.53-1.94l0.33-0.73l0.23-0.4l0.27-0.65l0.07-0.3l1.02-0.76l0.47-0.21l0.1-0.3
  		l-0.16-0.33l0.07-0.22l0.24-0.11l0.37-0.09l0.27-0.31L1788.17,1247.42z"/>
    </>,
    CHE: <>
        <polygon className="st1" points="1897.74,894.34 1897.75,893.98 1897.42,893.29 1896.87,893.07 1895.44,894.37 1895.05,894.49
  		1893.9,894.13 1892.9,893.57 1892.79,893.17 1892.62,892.83 1891.78,892.52 1890.73,892.3 1890.39,892.3 1889.73,892.25
  		1889.61,892.25 1889.54,891.88 1889.58,891.08 1889.95,890.03 1890.63,888.74 1890.78,887.94 1890.17,887.47 1889.31,887.44
  		1887.61,886.54 1887.04,885.77 1886.58,885.77 1884.5,885.92 1884.44,885.85 1884.08,885.42 1883.76,885.28 1883.57,885.35
  		1883.44,885.47 1883.22,885.45 1882.29,884.75 1881.9,884.64 1881.38,884.74 1880.75,885.12 1880.48,885.58 1880.56,885.85
  		1880.89,885.97 1881.73,885.89 1881.86,885.97 1881.89,886.12 1881.79,886.27 1881.1,886.39 1880.91,886.56 1880.7,886.61
  		1880.57,886.63 1879.84,886.45 1878.75,886.45 1877.87,886.78 1876.47,886.91 1874.54,886.84 1873.84,886.6 1872.83,887.09
  		1872.6,887.51 1872.2,888.07 1871.55,888.3 1870.9,888.38 1870.37,888.31 1870.07,888.08 1870.08,887.88 1869.8,887.7
  		1869.11,887.7 1868.39,888.09 1867.82,888.72 1868,889.07 1868.52,889.16 1868.66,889.3 1868.66,889.48 1868.48,889.7
  		1868.25,890.07 1867.15,891.18 1866.03,892.29 1865.85,892.63 1865.5,892.87 1864.08,893.46 1863.93,893.7 1863.85,894.69
  		1863.69,895.5 1862.64,896.27 1861.59,897.03 1861.33,897.5 1861.14,898.03 1860.81,898.64 1860.74,898.96 1861.27,899.48
  		1861.21,899.92 1861.04,900.53 1860.54,900.97 1859.98,901.21 1860,901.88 1860.29,901.97 1860.97,901.92 1861.92,901.44
  		1862.54,900.82 1862.17,900.22 1862.12,900.11 1862.21,899.97 1862.95,899.33 1863.85,898.94 1865.11,898.87 1866.62,899.09
  		1866.77,899.19 1866.7,899.58 1866.84,900.17 1867.12,900.57 1866.74,901.73 1867.02,902.1 1867.46,902.53 1867.79,902.92
  		1868.27,903.29 1868.69,903.9 1868.84,904.25 1869.13,904.48 1869.75,904.72 1871.42,904.39 1872.46,904.05 1873.19,903.7
  		1873.65,903.76 1875.29,904.29 1875.84,904.02 1877.02,903.3 1877.2,902.92 1878.14,901.78 1878.15,901.5 1877.77,900.78
  		1877.89,900.62 1879.03,899.88 1879.6,899.22 1880.2,898.78 1880.64,898.77 1880.76,898.92 1880.81,899.23 1880.77,900.49
  		1880.94,900.88 1881.84,901.79 1882.49,902.3 1883.98,902.66 1884.04,902.83 1883.63,903.51 1884.54,904.32 1884.7,904.92
  		1885.11,905.25 1885.7,905.09 1885.9,904.77 1885.66,904.22 1885.49,903.65 1885.53,903.31 1885.69,902.93 1886.1,902.39
  		1887.22,901.16 1887.62,900.45 1887.69,899.35 1887.69,898.47 1888.07,898.25 1888.87,898.41 1889.11,898.39 1889.21,898.93
  		1889.56,899.8 1889.96,900.25 1890.39,900.35 1890.89,900.36 1892.14,899.83 1892.95,899.6 1893.41,899.66 1893.69,900.02
  		1894.27,900.97 1894.6,901.07 1895.01,900.96 1895.15,900.8 1895.02,900.44 1894.85,899.65 1894.62,899.04 1894.31,898.75
  		1894.25,898.38 1894.44,897.71 1894.66,897.15 1895.08,897 1895.57,896.92 1896.22,897.52 1896.98,897.71 1897.55,897.68
  		1897.65,897.33 1897.62,896.95 1897.28,896.46 1897.35,895.72 	"/>
    </>,
    COG: <>
        <polygon className="st1" points="1966.45,1289.53 1965.97,1289.27 1965.52,1288.48 1965.3,1288.32 1963.99,1288.68 1963.3,1288.99
  		1962.94,1289.31 1962.66,1289.35 1962.24,1288.92 1961.91,1288.85 1961.4,1288.93 1960.87,1288.92 1960.52,1288.86 1960.3,1288.9
  		1959.68,1288.65 1957.41,1288 1957.02,1287.78 1956.57,1287.81 1955.4,1288.37 1954.78,1288.53 1952.91,1288.88 1950.9,1289.05
  		1950.13,1289.06 1949.6,1289.31 1949.27,1289.66 1949.04,1290.44 1948.64,1291.79 1948.48,1292.16 1948.5,1292.69 1948.39,1293.6
  		1948.33,1294.41 1948.41,1294.95 1947.84,1296.04 1947.15,1297.37 1946.58,1298.51 1946.01,1299.65 1946,1299.71 1945.95,1300.2
  		1945.44,1300.51 1945.14,1301.02 1945.05,1301.73 1945.2,1302.6 1945.6,1303.62 1945.61,1304.22 1945.47,1304.3 1945.22,1304.49
  		1944.96,1304.62 1944.25,1303.92 1943.46,1303.45 1942.28,1302.62 1941.09,1302.33 1939.55,1302.27 1938.89,1302.37
  		1938.42,1302.06 1937.75,1301.71 1937.39,1301.61 1936.88,1301.89 1936.53,1301.9 1936.09,1301.8 1935.22,1301.81 1935.14,1301.33
  		1934.99,1301.24 1934.04,1301.28 1933.75,1300.89 1933.62,1300.93 1933.25,1300.81 1932.49,1300.24 1931.69,1300.62
  		1930.03,1300.57 1927.91,1300.58 1925.7,1300.59 1923.69,1300.58 1921.67,1300.56 1921.62,1301.14 1920.96,1302.58
  		1920.64,1303.68 1920.56,1304.86 1920.75,1305.8 1921.07,1306.48 1921.28,1307.21 1921.12,1307.72 1920.8,1307.94 1921.02,1308.2
  		1921.5,1308.26 1922.33,1308.04 1923.6,1307.65 1925.27,1307.08 1926.36,1306.78 1928.17,1306.97 1929.14,1307.18 1929.64,1307.58
  		1930.17,1309.27 1930.43,1309.52 1930.87,1310.24 1931.24,1311.1 1931.31,1311.54 1931.28,1311.86 1930.91,1312.32
  		1930.49,1313.01 1930.35,1313.42 1930,1313.73 1929.56,1314.03 1928.35,1314.16 1928.17,1314.34 1927.83,1315.07 1927.19,1315.69
  		1926.9,1316.27 1926.64,1317.05 1926.69,1318.02 1926.57,1319.41 1926.44,1320.35 1926.76,1320.67 1928.2,1320.91 1928.48,1321.09
  		1928.87,1321.67 1929.36,1322.22 1930.68,1322.57 1931.19,1322.98 1931.61,1323.44 1931.66,1323.82 1931.36,1325.33
  		1931.07,1326.78 1931.19,1327.88 1931.3,1328.93 1931.45,1330.47 1931.38,1331.41 1931.01,1331.98 1931.01,1332.43
  		1931.18,1332.97 1930.85,1334.46 1930.64,1334.71 1930.05,1334.99 1929.73,1335.39 1929.63,1336.02 1929.32,1336.89
  		1928.99,1337.2 1928.99,1337.6 1929.3,1337.9 1929.3,1338.34 1928.71,1338.88 1928.35,1339.29 1927.56,1339.49 1926.66,1339.28
  		1926.45,1338.98 1926.67,1338.51 1926.59,1338.14 1926.28,1337.75 1925.8,1336.75 1925.37,1336.54 1925.14,1336.95 1924.4,1337.71
  		1923.11,1338.69 1922.2,1338.77 1920.53,1338.47 1919.13,1338 1918.47,1336.86 1918.05,1335.91 1917.45,1334.81 1916.78,1334.29
  		1916.06,1333.95 1915.74,1333.93 1914.72,1334.54 1914.41,1334.78 1914.41,1335.3 1914.51,1335.78 1914.67,1336.01 1914.8,1336.32
  		1914.78,1336.8 1914.59,1337.44 1914.53,1338.14 1911.31,1338.83 1910.76,1338.58 1910.35,1338.27 1909.86,1338.32
  		1908.47,1338.68 1907.96,1338.43 1907.45,1338.25 1907.21,1338.4 1907.19,1338.7 1907.43,1340.36 1907.36,1341 1907.04,1341.82
  		1906.88,1342.38 1907.73,1342.54 1908.04,1342.8 1908.34,1343.22 1908.75,1343.61 1908.78,1343.85 1908.31,1344.28
  		1908.15,1344.81 1908.37,1345.23 1908.96,1345.67 1909.8,1346.12 1910.22,1346.42 1910.18,1346.69 1909.78,1347.27
  		1909.63,1347.76 1909.36,1348.2 1909.42,1348.61 1909.8,1348.99 1909.76,1349.33 1909.5,1349.59 1908.97,1349.53 1908.53,1349.57
  		1908.12,1349.46 1906.87,1348.15 1906.6,1348.11 1904.78,1349.12 1904.32,1349.54 1903.95,1350.13 1903.44,1351.43
  		1905.42,1353.22 1905.67,1353.81 1907.98,1355.77 1908.9,1356.87 1908.93,1357.8 1909.1,1358.04 1909.26,1358.46 1909.87,1359.38
  		1910.49,1360.13 1910.79,1360.36 1910.93,1360.55 1911.42,1360.11 1912.18,1359.15 1912.49,1358.65 1913.36,1358.54
  		1913.69,1358.2 1913.92,1357.64 1914.01,1357.32 1914.99,1357.05 1916.18,1356.58 1916.83,1356.06 1917.49,1355.74
  		1917.91,1355.72 1918.19,1355.86 1918.95,1356.75 1919.6,1357.32 1919.81,1357.45 1919.93,1357.17 1920.34,1357.19
  		1920.48,1357.33 1920.68,1357.62 1921.04,1358.04 1921.7,1358.54 1922.36,1359.08 1922.69,1359.15 1923.22,1358.88
  		1923.84,1358.47 1924.75,1358.18 1924.97,1357.9 1925.08,1357.31 1925.15,1356.68 1925.23,1355.94 1925.42,1355.84
  		1925.75,1355.76 1926.35,1355.97 1926.62,1356.19 1927.12,1356.19 1927.43,1355.99 1928.01,1355.63 1928.74,1355.48
  		1929.53,1355.13 1930.28,1354.68 1930.63,1354.64 1931.01,1355.23 1931.35,1355.64 1931.4,1355.9 1931.07,1356.39 1930.69,1357.04
  		1931.01,1357.84 1931.09,1358.62 1931.08,1359.1 1931.33,1359.29 1931.5,1359.38 1931.78,1359.27 1932.31,1359.3 1932.96,1359.55
  		1933.58,1359.52 1934.18,1359.22 1935.3,1358.04 1936.93,1355.99 1938.29,1354.71 1939.36,1354.18 1940.09,1353.57
  		1940.47,1352.87 1941.09,1352.39 1942.39,1352.01 1943.39,1351.58 1944.38,1350.17 1945.7,1347.64 1946.07,1345.38 1946.29,1344
  		1946.16,1339.27 1946.07,1337.72 1946.27,1336.87 1946.77,1336.29 1948.11,1335.05 1949.02,1334.04 1949.7,1332.86
  		1951.03,1330.16 1951.61,1329.29 1951.87,1328.9 1952.67,1328.18 1953.79,1327.55 1955.23,1327.01 1957.46,1325.13
  		1959.22,1323.24 1958.98,1320.97 1959.4,1319.08 1960.36,1316.69 1960.68,1314.15 1960.34,1311.48 1960.48,1309.29 1961.4,1306.75
  		1961.79,1305.8 1961.92,1304.26 1961.91,1301.8 1963.09,1298.44 1964.2,1296.42 1965.44,1294.17 1965.91,1292.81 1966.55,1290.99
  		"/>
    </>,
    CAF: <>
        <polygon className="st1" points="2039.91,1275.22 2039.27,1274.35 2039.07,1273.08 2039.04,1272.06 2038.91,1271.59 2038.64,1271.12
  		2038.32,1270.71 2037.81,1270.26 2036.62,1269.61 2035.39,1268.85 2034.8,1268.4 2033.69,1268.24 2033.02,1267.81 2032.45,1266.85
  		2032.23,1266.09 2031.66,1265.49 2031.42,1265.06 2031.29,1264.56 2031.73,1263.05 2031.08,1262.52 2030.11,1261.82
  		2029.42,1261.06 2028.99,1260.36 2027.75,1259.44 2025.04,1258.06 2023.47,1257.18 2022.61,1256.39 2021.86,1255.62
  		2021.79,1255.3 2022.27,1254.53 2022.35,1253.89 2021.95,1253.19 2020.32,1251.87 2019.03,1250.41 2018.05,1249.96
  		2015.68,1249.56 2015,1249.4 2014.3,1249.12 2013.6,1248.46 2013.36,1247.69 2013.7,1246.45 2013.48,1246.07 2013.08,1245.97
  		2012.25,1245.75 2011.18,1245.59 2009.14,1245.4 2008.33,1245.12 2007.94,1244.7 2008.07,1243.62 2007.87,1243.39 2007.54,1243.2
  		2007.32,1242.74 2007.36,1242.18 2008.44,1240.94 2008.66,1240.27 2008.83,1237.96 2008.95,1237.15 2008.86,1236.2
  		2008.01,1234.45 2007.26,1233.23 2006.05,1231.42 2005.57,1230.83 2003.12,1228.35 2002.84,1227.98 2002.24,1226.92
  		2001.88,1226.86 2001.15,1226.63 2000.25,1226.44 1999.16,1226.28 1998.11,1226.65 1996.98,1227.14 1996.63,1227.5
  		1996.31,1227.72 1995.81,1227.68 1995.36,1227.75 1995.11,1228.09 1994.7,1228.47 1993.07,1229.27 1992.73,1229.56
  		1992.52,1229.84 1992.52,1230.16 1992.69,1230.8 1992.69,1231.6 1992.32,1232.25 1991.9,1232.69 1991.42,1232.86 1991.02,1232.95
  		1990.76,1233.22 1989.91,1234.69 1989.54,1234.96 1988.8,1234.92 1986.65,1237.13 1986.44,1237.78 1985.66,1238.7 1984.66,1239.72
  		1983.78,1240.22 1983.71,1240.41 1983.47,1240.6 1982.93,1240.83 1981.03,1242.07 1978.76,1242.02 1977.76,1242.51
  		1976.78,1242.73 1975.36,1242.97 1974.93,1242.95 1973.1,1243.05 1970.95,1243.01 1970.13,1243.19 1969.36,1243.66
  		1968.79,1244.08 1968.7,1244.22 1968.79,1244.39 1968.77,1244.53 1970.27,1245.55 1970.64,1246.05 1970.27,1246.53 1970.09,1246.6
  		1970.06,1246.63 1969.82,1247.01 1968.94,1248.16 1967.6,1249.52 1966.92,1249.91 1966.64,1250.16 1966.29,1251.06
  		1966.06,1251.19 1965.14,1251.3 1963.32,1251.4 1960.8,1251.7 1959.29,1251.79 1958.35,1251.71 1957.03,1252.33 1956.56,1252.49
  		1956.27,1252.54 1954.96,1253.15 1953.88,1254.08 1953.49,1254.26 1951.96,1254.66 1951.35,1255.3 1951.07,1255.35 1950.09,1254.5
  		1949.42,1253.73 1949.1,1252.95 1949.05,1252.71 1948.87,1252.75 1948.33,1253.1 1947.87,1253.49 1947.65,1254.24 1946.07,1254.74
  		1944.72,1255.17 1944.1,1255.72 1943.16,1255.99 1941.94,1255.88 1941,1255.65 1940.08,1255.58 1939.23,1256.97 1938.11,1257.77
  		1937.78,1258.25 1937.6,1258.85 1937.36,1259.45 1936.77,1260.74 1936.33,1261.8 1935.89,1262.13 1934.88,1263.72 1934.19,1265.32
  		1934.05,1265.73 1933.84,1266.04 1933.51,1266.29 1932.33,1266.78 1931.93,1267.03 1931.62,1267.32 1931.33,1267.66
  		1931.25,1268.06 1931.52,1268.63 1931.85,1269.09 1932.19,1269.11 1932.48,1269.09 1932.66,1269.36 1932.81,1269.52
  		1932.81,1272.62 1932.53,1273.09 1932.54,1273.3 1932.4,1273.84 1932.36,1274.43 1932.45,1274.67 1932.68,1274.86 1933.01,1275.28
  		1933.19,1276.23 1933.59,1279.58 1933.78,1280.11 1934.1,1280.49 1935.14,1281.21 1936.23,1282.16 1936.57,1282.78
  		1936.78,1283.79 1937.19,1284.58 1937.18,1284.85 1937.01,1284.96 1936.61,1284.97 1936.33,1285.03 1936.56,1285.61
  		1937.12,1286.61 1938.06,1287.66 1939.07,1288.79 1939.9,1289.71 1940.93,1290.78 1941.74,1291.62 1942.57,1292.47
  		1943.19,1292.68 1943.66,1292.74 1943.86,1292.91 1944.11,1293.3 1944.53,1293.74 1945,1294.31 1945.16,1294.89 1944.96,1295.44
  		1945.15,1296.23 1945.16,1296.3 1945.32,1296.62 1945.27,1296.9 1945.36,1297.95 1945.61,1298.87 1946.01,1299.65 1946.58,1298.51
  		1947.15,1297.37 1947.84,1296.04 1948.41,1294.95 1948.33,1294.41 1948.39,1293.6 1948.5,1292.69 1948.48,1292.16 1948.64,1291.79
  		1949.04,1290.44 1949.27,1289.66 1949.6,1289.31 1950.13,1289.06 1950.9,1289.05 1952.91,1288.88 1954.78,1288.53 1955.4,1288.37
  		1956.57,1287.81 1957.02,1287.78 1957.41,1288 1959.68,1288.65 1960.3,1288.9 1960.52,1288.86 1960.87,1288.92 1961.4,1288.93
  		1961.91,1288.85 1962.24,1288.92 1962.66,1289.35 1962.94,1289.31 1963.3,1288.99 1963.99,1288.68 1965.3,1288.32 1965.52,1288.48
  		1965.97,1289.27 1966.45,1289.53 1966.33,1287.86 1966.64,1285.55 1966.53,1284.19 1966.09,1283 1966.31,1282.26 1967.2,1281.96
  		1968.31,1280.78 1970.31,1277.69 1972.45,1276.19 1973.95,1275.71 1975.51,1275.76 1976.52,1276.03 1976.99,1276.52
  		1978.17,1277.24 1980.06,1278.21 1981.47,1279.41 1982.25,1280.62 1982.85,1281.29 1983.6,1281.51 1984.83,1281.41 1986.2,1281.7
  		1987.63,1282.38 1988.51,1282.63 1988.83,1282.46 1989.52,1282.55 1991.1,1283.11 1992.36,1282.8 1994.22,1283.04 1998.55,1284.03
  		1998.78,1283.86 1998.89,1283.82 1999.25,1283.42 2000.19,1281.43 2000.99,1280.2 2001.36,1279.74 2002.28,1279.1 2003.36,1278.93
  		2004.39,1278.99 2005.26,1279.27 2006.05,1279.6 2006.93,1279.6 2007.83,1279.29 2009.16,1278.7 2010.56,1278.32 2011.77,1277.9
  		2013.76,1277.17 2014.53,1276.83 2015.52,1276.7 2018.29,1277.37 2020.08,1276.92 2020.81,1277.05 2022.36,1276.57
  		2022.65,1276.25 2023.63,1274.63 2024.69,1274.16 2026.28,1274.4 2027.17,1274.65 2030.15,1275.34 2034.01,1276.07
  		2035.15,1276.18 2035.61,1276.26 2036.01,1276.15 2037.28,1275.23 2037.71,1275.1 2038.08,1275.12 2040.51,1275.86 	"/>
    </>,
    COD: <>
        <polygon className="st1" points="2072.79,1300.31 2072.41,1299.97 2072.28,1299.65 2071.96,1299.49 2071.49,1299.5 2071.18,1299.27
  		2070.83,1298.82 2070.48,1298.54 2069.37,1298.56 2068.51,1298.1 2068.52,1297.47 2068.86,1296.24 2069.51,1294.82
  		2069.54,1294.43 2069.45,1294.1 2069.29,1293.81 2069,1293.53 2068.73,1293.19 2068.94,1292.17 2069.35,1291.17 2069.68,1290.67
  		2070.01,1290.11 2069.92,1289.66 2069.44,1289.43 2069.26,1289.07 2069.09,1288.74 2068.75,1288.31 2068.27,1288.15
  		2067.83,1288.23 2067.32,1288.31 2067.09,1288.07 2067.04,1287.48 2066.9,1286.95 2066.66,1286.54 2065.92,1286.14
  		2064.02,1285.31 2062.56,1283.67 2061.82,1282.91 2061.28,1282.42 2060.52,1281.13 2059.65,1280.24 2058.6,1279.83
  		2057.91,1280.04 2057.19,1280.99 2055.85,1281.88 2055.23,1281.91 2054.44,1281.43 2053.44,1281.08 2051.65,1280.93
  		2050.92,1281.35 2049.95,1282.04 2049.13,1282.45 2048.62,1282.49 2048.15,1282.33 2047.61,1282.24 2047.15,1282.23
  		2046.19,1281.6 2045.7,1281.15 2045.37,1280.7 2044.83,1280.4 2044.2,1280.15 2043.75,1279.76 2043.52,1279.27 2043.17,1278.64
  		2042.7,1278.07 2041.24,1277.05 2040.81,1276.45 2040.51,1275.86 2038.08,1275.12 2037.71,1275.1 2037.28,1275.23 2036.01,1276.15
  		2035.61,1276.26 2035.15,1276.18 2034.01,1276.07 2030.15,1275.34 2027.17,1274.65 2026.28,1274.4 2024.69,1274.16
  		2023.63,1274.63 2022.65,1276.25 2022.36,1276.57 2020.81,1277.05 2020.08,1276.92 2018.29,1277.37 2015.52,1276.7
  		2014.53,1276.83 2013.76,1277.17 2011.77,1277.9 2010.56,1278.32 2009.16,1278.7 2007.83,1279.29 2006.93,1279.6 2006.05,1279.6
  		2005.26,1279.27 2004.39,1278.99 2003.36,1278.93 2002.28,1279.1 2001.36,1279.74 2000.99,1280.2 2000.19,1281.43 1999.25,1283.42
  		1998.89,1283.82 1998.78,1283.86 1998.55,1284.03 1994.22,1283.04 1992.36,1282.8 1991.1,1283.11 1989.52,1282.55 1988.83,1282.46
  		1988.51,1282.63 1987.63,1282.38 1986.2,1281.7 1984.83,1281.41 1983.6,1281.51 1982.85,1281.29 1982.25,1280.62 1981.47,1279.41
  		1980.06,1278.21 1978.17,1277.24 1976.99,1276.52 1976.52,1276.03 1975.51,1275.76 1973.95,1275.71 1972.45,1276.19
  		1970.31,1277.69 1968.31,1280.78 1967.2,1281.96 1966.31,1282.26 1966.09,1283 1966.53,1284.19 1966.64,1285.55 1966.33,1287.86
  		1966.45,1289.53 1966.55,1290.99 1965.91,1292.81 1965.44,1294.17 1964.2,1296.42 1963.09,1298.44 1961.91,1301.8 1961.92,1304.26
  		1961.79,1305.8 1961.4,1306.75 1960.48,1309.29 1960.34,1311.48 1960.68,1314.15 1960.36,1316.69 1959.4,1319.08 1958.98,1320.97
  		1959.22,1323.24 1957.46,1325.13 1955.23,1327.01 1953.79,1327.55 1952.67,1328.18 1951.87,1328.9 1951.61,1329.29
  		1951.03,1330.16 1949.7,1332.86 1949.02,1334.04 1948.11,1335.05 1946.77,1336.29 1946.27,1336.87 1946.07,1337.72
  		1946.16,1339.27 1946.29,1344 1946.07,1345.38 1945.7,1347.64 1944.38,1350.17 1943.39,1351.58 1942.39,1352.01 1941.09,1352.39
  		1940.47,1352.87 1940.09,1353.57 1939.36,1354.18 1938.29,1354.71 1936.93,1355.99 1935.3,1358.04 1934.18,1359.22
  		1933.58,1359.52 1932.96,1359.55 1932.31,1359.3 1931.78,1359.27 1931.5,1359.38 1931.33,1359.29 1931.08,1359.1 1931.09,1358.62
  		1931.01,1357.84 1930.69,1357.04 1931.07,1356.39 1931.4,1355.9 1931.35,1355.64 1931.01,1355.23 1930.63,1354.64 1930.28,1354.68
  		1929.53,1355.13 1928.74,1355.48 1928.01,1355.63 1927.43,1355.99 1927.12,1356.19 1926.62,1356.19 1926.35,1355.97
  		1925.75,1355.76 1925.42,1355.84 1925.23,1355.94 1925.15,1356.68 1925.08,1357.31 1924.97,1357.9 1924.75,1358.18
  		1923.84,1358.47 1923.22,1358.88 1922.69,1359.15 1922.36,1359.08 1921.7,1358.54 1921.04,1358.04 1920.68,1357.62
  		1920.48,1357.33 1920.34,1357.19 1919.93,1357.17 1919.81,1357.45 1919.68,1357.59 1918.75,1357.96 1917.76,1358.3
  		1916.45,1359.72 1915.79,1360.33 1915.6,1360.48 1915.01,1360.82 1914.57,1361.11 1914.59,1361.27 1914.88,1361.46
  		1915.17,1361.76 1915.14,1364.07 1915.01,1366.35 1914.85,1366.54 1914.02,1366.62 1912.92,1366.77 1912.57,1366.88
  		1912.8,1367.29 1913.42,1368.02 1914.24,1368.79 1914.59,1368.91 1915.1,1368.94 1916.5,1368.57 1917.44,1367.88 1918.02,1367.68
  		1919.22,1367.53 1919.77,1367.77 1920.75,1367.7 1921.74,1367.91 1922.11,1367.76 1922.32,1367.74 1924.66,1367.74
  		1925.63,1367.69 1927.44,1367.7 1928.57,1367.77 1929.22,1367.86 1930.97,1368 1933.16,1367.97 1933.93,1367.9 1936.79,1367.85
  		1939.62,1367.8 1942.16,1367.76 1944.97,1367.77 1947.12,1367.77 1948.09,1368.06 1948.98,1368.62 1949.39,1369.12
  		1949.58,1369.34 1949.85,1369.86 1950.33,1370.28 1950.51,1370.93 1950.36,1371.81 1950.44,1372.86 1950.72,1374.1
  		1951.31,1375.39 1952.2,1376.75 1952.6,1377.83 1952.48,1378.62 1952.76,1379.47 1953.42,1380.36 1953.91,1380.83 1954.19,1381.18
  		1954.95,1382.55 1956.35,1384.72 1957.4,1386.36 1957.77,1386.55 1958.3,1386.48 1959.44,1386.32 1960.57,1386.29 1961.38,1386.62
  		1961.7,1386.57 1962.92,1385.92 1964.13,1385.72 1965.39,1385.45 1966.05,1385.18 1966.81,1385.18 1968.87,1385.7 1969.26,1385.73
  		1970.93,1385.73 1972.6,1385.44 1972.84,1383.25 1972.86,1382.81 1973.26,1381.99 1973.77,1381.28 1973.83,1380.59 1973.8,1379.65
  		1974.17,1378.52 1975.29,1377.62 1977.1,1377.19 1978.13,1377.11 1979.75,1376.85 1982.21,1376.6 1983.12,1376.63 1983.19,1376.76
  		1982.67,1378.33 1982.66,1378.84 1982.85,1379.36 1983.27,1379.64 1985.82,1379.67 1988.18,1379.7 1990.88,1379.8 1992.9,1379.87
  		1993.16,1379.95 1993.36,1380.07 1993.66,1380.84 1993.59,1382.36 1993.14,1384.58 1993.32,1386.66 1994.12,1388.59
  		1994.2,1391.56 1993.92,1393.33 1993.56,1395.57 1993.42,1398.1 1993.79,1399.16 1994.56,1400.27 1995.75,1401.43 1996.66,1402.93
  		1997.31,1404.78 1997.54,1405.94 1997.37,1406.42 1997.38,1407.25 1997.58,1408.43 1997.36,1409.22 1996.71,1409.6
  		1996.49,1410.13 1996.82,1411.15 1996.9,1412.08 1997.16,1412.43 1997.34,1412.69 1997.65,1412.73 1998.31,1412.4 1999.09,1411.78
  		1999.72,1411.52 2000.61,1411.55 2001.86,1411.73 2004.06,1411.79 2004.74,1411.68 2006.79,1410.85 2007.33,1410.79
  		2008.13,1410.87 2009.28,1411.11 2010.44,1411.16 2011.01,1410.9 2011.06,1410.57 2011.24,1410.13 2011.56,1409.96
  		2011.86,1410.02 2012.5,1410.13 2012.81,1410.67 2012.99,1411.27 2013.42,1411.3 2014.54,1411.65 2014.92,1412.15 2015.18,1413.21
  		2015.02,1413.75 2014.66,1414.19 2015.02,1414.58 2015.77,1414.84 2016.21,1414.76 2017.47,1414.04 2017.97,1413.91
  		2018.63,1413.77 2019.23,1413.58 2020.9,1413.25 2021.82,1413.1 2022.34,1412.85 2022.7,1412.85 2022.95,1413.05 2022.72,1413.8
  		2022.64,1414.48 2022.96,1415.74 2023.21,1416.33 2023.75,1416.76 2024.14,1416.98 2024.58,1417.43 2025.48,1417.35 2027.46,1418
  		2028.07,1418.3 2028.9,1418.59 2029.5,1418.7 2031.55,1418.93 2032.3,1419.08 2033.71,1419.29 2034.83,1419.32 2035.63,1419.23
  		2036.19,1419.05 2036.53,1418.84 2036.68,1418.67 2036.91,1418.04 2037.33,1416.67 2037.5,1416.27 2037.91,1416.08
  		2038.45,1415.96 2038.76,1416.17 2039.11,1417.69 2040.68,1419.05 2041.21,1420.2 2041.6,1421.18 2041.94,1421.45 2042.54,1421.79
  		2043.48,1421.91 2044.33,1421.94 2046.11,1422.65 2047.53,1423.22 2048.54,1423.62 2049.01,1423.93 2049.33,1424.43
  		2049.53,1424.82 2049.84,1425.83 2050.17,1426.63 2050.71,1426.78 2051.2,1426.84 2051.68,1427.39 2052.04,1427.87
  		2052.76,1429.04 2053.29,1429.85 2053.47,1430.64 2054.07,1431.16 2054.89,1431.39 2055.65,1431.42 2056.09,1431.18
  		2057.17,1430.77 2058.01,1430.31 2058.62,1430.14 2058.98,1430.24 2059.26,1430.57 2059.41,1431.2 2059.44,1431.55
  		2060.04,1431.89 2060.48,1431.75 2060.65,1431.37 2060.66,1431.17 2060.66,1429.45 2060.66,1427.96 2060.66,1426.55
  		2060.65,1424.84 2060.65,1423.35 2060.65,1422.12 2060.65,1420.85 2060.27,1420.92 2059.78,1421.21 2058.67,1421.24
  		2058.23,1421.46 2058.1,1421.79 2058.18,1422.22 2058.21,1422.8 2058.04,1423.08 2057.55,1423.19 2056.85,1422.96 2055.56,1422.67
  		2054.5,1422.49 2053.73,1421.71 2052.69,1420.55 2052.01,1419.96 2050.37,1418.75 2050.09,1418.5 2049.59,1417.93 2049.17,1416.97
  		2048.96,1416.33 2048.76,1415.85 2048.54,1415.14 2048.94,1414.05 2049.49,1411.98 2049.89,1410.48 2050.11,1409.37
  		2050.91,1408.25 2050.97,1407.24 2050.65,1405.95 2050.73,1405.23 2050.78,1403.42 2050.83,1401.9 2050.84,1401.16
  		2050.62,1399.87 2050.08,1398.45 2048.9,1396.47 2048.91,1396.04 2049.61,1395.57 2050.72,1394.75 2051.27,1394.27
  		2051.92,1393.58 2052.21,1393.23 2052.85,1392.34 2053.26,1391.62 2053.4,1390.69 2053.1,1389.81 2053.72,1389.63 2055.77,1389.32
  		2058.03,1388.98 2060.41,1388.61 2062.81,1388.25 2065.13,1387.89 2067.24,1387.57 2067.27,1387.24 2067.15,1386.69
  		2066.86,1386.26 2066.58,1386 2066.32,1385.89 2066.1,1385.69 2065.71,1385.12 2065.47,1384.9 2064.86,1384.65 2064.71,1384.44
  		2064.61,1383.76 2064.24,1382.94 2064.1,1382.24 2064.18,1381.99 2064.12,1381.29 2063.61,1379.82 2063.13,1379.11
  		2062.49,1378.53 2061.77,1378.1 2060.99,1377.84 2060.29,1377.27 2059.14,1375.81 2058.47,1375.24 2058.26,1374.95 2058,1374.22
  		2058.03,1373.42 2057.96,1373.23 2057.77,1373.24 2057.63,1373.18 2057.52,1373.03 2056.58,1370.51 2056.39,1370.38
  		2056.23,1370.01 2056.06,1369.82 2055.77,1369.65 2055.58,1369.22 2055.5,1368.55 2055.55,1368.19 2055.76,1368 2056.73,1367.21
  		2056.82,1366.94 2056.82,1366.77 2056.76,1366.06 2056.83,1365.84 2057.07,1365.8 2057.09,1365.68 2055.86,1363.63
  		2055.28,1362.49 2054.94,1361.55 2054.81,1360.96 2054.9,1360.73 2054.91,1358.11 2055,1357.04 2055.19,1356.56 2055.38,1356.35
  		2055.66,1356.41 2055.65,1355.79 2056.1,1352.98 2056.02,1352.67 2055.87,1352.72 2055.79,1353.12 2055.18,1354.93
  		2055.07,1355.01 2054.48,1354.79 2054.44,1354.53 2054.7,1354.18 2054.79,1353.85 2054.7,1353.54 2054.68,1352.9 2054.82,1351.25
  		2054.94,1350.89 2054.94,1350.59 2054.82,1350.35 2054.88,1350.03 2055.11,1349.64 2055.19,1348.92 2055.12,1347.86
  		2055.15,1347.17 2055.28,1346.85 2055.43,1346.65 2055.6,1346.59 2055.73,1346.61 2055.74,1346.11 2055.86,1344.91 2055.84,1344.2
  		2055.24,1343.38 2054.5,1342.5 2054.09,1342.07 2054.07,1341.73 2054.07,1341.41 2053.29,1341.09 2053.06,1340.7 2053.04,1340.03
  		2052.75,1339.12 2052.76,1339.11 2052.69,1339.13 2052.75,1338.49 2053.25,1337.79 2053.14,1337.39 2052.91,1337.29
  		2052.88,1336.94 2052.94,1336.66 2053.25,1336.07 2053.19,1335.45 2053.85,1334.02 2054.23,1332.43 2054.55,1332.08
  		2055.62,1332.4 2055.82,1332.75 2056.21,1332.21 2056.92,1331.34 2057.34,1331.26 2057.89,1330.93 2058.48,1330.44
  		2058.81,1330.26 2058.84,1330 2058.7,1328.03 2058.69,1326.82 2058.92,1326.07 2059.06,1325.2 2059.08,1324.43 2059.41,1323.12
  		2059.37,1322.88 2059.36,1322.89 2058.25,1323.76 2057.76,1323.72 2057.48,1323.91 2057.04,1324 2056.77,1323.48 2056.71,1323.08
  		2056.98,1322.43 2057.47,1321.48 2058.13,1320.15 2058.98,1319.77 2059.38,1319.65 2059.71,1319.65 2059.72,1319.6
  		2059.83,1319.15 2060,1317.82 2060.27,1317.41 2060.5,1317.25 2060.81,1316.44 2061.41,1315.14 2061.82,1314.47 2061.73,1313.01
  		2061.8,1312.01 2061.89,1311.79 2062.77,1311.42 2063.92,1310.5 2064.4,1309.42 2065.08,1308.73 2066.4,1308.28 2066.32,1308.12
  		2066.16,1308.11 2065.86,1308.15 2065.74,1308.14 2065.64,1307.79 2066.09,1307.08 2066.41,1306.38 2066.83,1305.82
  		2067.35,1305.43 2068.05,1304.62 2068.39,1304.4 2069.69,1303.09 2070.26,1302.62 2070.64,1302.48 2072.31,1301.3 2072.98,1300.63
  		2073.01,1300.57 	"/>
    </>,
    CAN: <>
        <path className="st0" d="M1003.7,614.83l-0.29,0.63l0.39,0.18l0.89,0l0.69-0.21l0.5-0.42l0.32-0.62l0.15-0.81l0.01-0.49l-1.12-1
  		l-0.75-0.42l-0.88-0.34l-0.77-0.02l-0.66,0.3l-0.51,0.58l-0.36,0.87l-0.13,0.59l0.14,0.55l-0.07,0.61l0.25,0.37l0.53,0.23l0.3-0.45
  		l0.39-0.97l0.11-0.79l-0.16-0.61l0.1-0.3l0.36,0.01l0.5,0.32l0.18,0.56l0.14,0.92l-0.04,0.55L1003.7,614.83z M999.59,616.78
  		l-0.98-1.28l-3.32-1.89l-1.52-0.58l-0.96-0.65l-0.9-0.92l-1.18-0.88l-0.36-0.09l-0.23,0.09l-0.38,0.49l-0.49,0.15l-0.26-0.18
  		l-0.25-0.36l0.08-0.24l0.63-0.26l0.03-0.18l-0.24-0.39l-1.63-0.96l-0.73-0.59l-0.84-0.31l-0.83,0.05l-1.62,0.38l-1.01,0.54
  		l-0.32,0.26l-0.25,0.38l-0.28,0.94l-0.03,0.34l0.13,0.6l1.06,1.75l1.02,0.83l-0.96-0.18l-0.56-0.23l-1.41-1.2l-0.73-0.21
  		l-0.59,0.09l-0.02,0.42l0.12,0.26l0.33,0.42l0.39,0.3l-0.74,0.02l-0.18,0.25l0.2,0.52l0.58,0.77l0.14,0.31l-0.05,0.29l-0.4,0.39
  		l-1.85,1.33l-1.59,0.77l-1.46,0.26l-3.12,0.28l-0.48,0.47l-0.37,0.67l-0.06,0.3l0.58,1.1l0.45,0.62l1.04,0.61l0.54,0.19l1.38,0
  		l0.15-0.2l-0.17-0.33l0.31-0.28l0.79-0.22l0.5,0.01l0.22,0.24l0.13,0.33l0.04,0.43l0.25,0.38l0.46,0.32l0.6,0.21l1.38,0.07
  		l0.91-0.3l0.47-0.34l0.46-0.02l0.21,0.18l0.13,0.35l0.06,0.52l0.13,0.33l0.19,0.15l2.95,0.98l1.52,0.68l1.96,1.21l1.75,0.23
  		l2.15-0.16l3.45,1.14l1.66-0.14l1.13-0.54l2.05-1.46l1.09-0.29l0.78-0.87l0.98-0.73l0.6-0.14l1.25-0.03l0.89-0.46l0.54-0.39
  		l0.23-0.31l-0.44-0.69l-0.46-0.28l-0.53,0.17l-1.25,0.67l-1.4-0.92l-0.87-0.83l-0.81-1.04l-0.92-1.51L999.59,616.78z
  		 M965.13,596.63l-1.33-0.84l-0.88-0.29l-0.82-0.03l-0.36,0.37l0.1,0.76l0.16,0.51l0.23,0.25l0.7,0.33l1.82,0.57l0.11-0.12
  		l0.02-0.19l-0.15-0.52l0.02-0.55L965.13,596.63z M995.77,569.4l1-0.81l0.4-0.66l0.28-0.84l-0.05-1.04l-0.37-1.24l-0.45-1.1
  		l-0.42-0.18l-0.67-0.05l-1.67,0.42l-1.54,0.8l-0.64,0.15l-0.26-0.24l0.16-0.48l0.57-0.72l-0.09-0.73l-1.3-1.2l-1.18-0.44l0.06-0.21
  		l0.51-0.32l0.12-0.32l-0.28-0.32l-1.24-0.88l-1.35-0.57l-0.75-0.15l-1.81,0.02l-1.02,0.24l-1.01,0.44l-1.57,0.95l-0.46-0.11
  		l-0.08-0.27l-0.05-0.68l0.17-0.36l0.35-0.36l1.68-1.15l3.2-2.75l2.63-0.88l1.78-0.77l0.36-0.56l-0.48-0.61l-0.53-0.37l-1.01-0.12
  		l-0.52,0.24l-0.45,0.04l-0.24-0.17l-0.1-0.34l0.24-0.56l0.9,0.16l0.8-0.61l0.93-0.11l1.08-0.35l0.52-0.37l0.78-0.85l0.04-0.14
  		l-0.12-0.51l-0.85-1.38l-0.5-0.57l-0.46-0.31l-0.86-0.3l-2.15-0.42l-0.74,0l-1.37,0.14l-0.8,0.22l-1.89,0.78l-3.1,0.43l-2.23,0.52
  		l-2.15,0.18l-1,0.29l-7.11-3.52l-1.15,0.28l-0.75,0.65l0.02,0.27l0.6,0.47l0.96,0l0.85-0.22l0.23,0.17l-0.67,0.85l-1.61-0.1
  		l-2.54-0.69l-3.63,0.63l-0.4,0.23l-0.19,0.43l0.03,0.63l0.24,0.59l0.46,0.55l0.97,0.74l1.05,0.32l0.43,0.29l0.6,1.07l0.23,0.72
  		l-0.11,0.26l-1.31-0.73l-1.49-1.26l-1.02-0.47l-1.17,0.06l-1.75,0.39l-1.18,0.62l-0.47,0.47l-0.04,0.3l0.35,0.66l0.26,0.24
  		l4.73,2.53l1.13-0.05l1.42-0.34l0.75-0.26l1.11-0.7l0.91,0.31l2.12,1.63l1.52,0.42l-1.19,0.2l-0.85-0.16l-1.34-0.71l-0.97-0.17
  		l-0.83,0.17l-0.43,0.33l-0.4,0.58l-0.12,0.55l0.16,0.41l0.72,0.29l0.94-0.13l0.44,0.13l0.39,0.4l0.44,0.65l0.74,1.49l0.04,0.29
  		l-0.3,0.62l-0.51,0.26l-0.33-0.14l-1.18-1.27l-0.24,0.01l-0.39,2.74l-0.21,0.45l-0.14,0.09l-3.46,0.75l-1.61,0.2l-1.56-0.13
  		l-0.65-0.39l-0.71-1.2l-0.92-1l-0.62-0.43l-1.15-0.53l-0.37-0.49l-0.31-0.72l-0.73-0.63l-0.82-0.21l-1.55-0.12l-1.11,0.21
  		l-1.41,0.94l-0.4,0.45l-0.65,1.09l-0.5,1.09l-0.22,0.96l0.04,0.3l0.44,0.72l2.15,2l3.61,1.73l0.54,0.88l0.64,0.35l0.24,0.39
  		l0.25,0.7l0.43,0.41l1.9,0.59l1.51-0.55l0.57-0.14l0.35,0.08l0.97,0.6l0.56,0.8l0.36,0.29l1.55,0.17l0.79,0.21l0.95,0.53l2.26,2.34
  		l1.7,1.45l3.28,2.42l1.29,1.66l1.5,1.47l1.07,2.07l0.44,0.58l0.48,0.31l1.52-0.04l0.74,0.27l0.97,0.6l1.02,0.18l1.07-0.24
  		l1.04-0.48l1.8-1.43l0.07-0.34l-0.04-0.45l-0.3-1.06l-1.59-2.46l-0.32-0.89l0.59-0.8l0.56-0.44l0.14,0.08l0.03,0.26l-0.09,0.44
  		l0.05,0.61l0.2,0.77l0.35,0.53l0.51,0.3l5.05,0.51l1.02-0.07l2.01-0.61l0.89-0.59l0.78-0.78l0.66-0.49l2.8-0.66l-0.09-2.1
  		l-0.78-0.91l-0.35-0.1l-0.07-0.22l1.25-1.58l0.15-0.43l0.07-0.5l-0.19-0.52l-0.45-0.54l-1.07-0.68l-0.05-0.14l0.47,0L995.77,569.4z
  		 M1041.15,553.95l1.53-1.81l0.55-0.84l0.22-0.57l0.35-0.41l1.42-0.86l1.68-1.73l0.84-1.11l0.71-1.16l0.23-0.72l-0.87-0.65
  		l-1.43-0.71l-3.88-0.95l-4.57-0.31l-2.05,0.25l-2.94,0.67l-0.77-0.33l-1.51-1.15l-0.79-0.34l-1.61-0.51l-3.31-0.78l-2.02-0.29
  		l-1.17,0.19l-1.98,0.81l-1.3-0.22l-4.58,0.31l-2.08,0.45l-2.06,0.74l-0.56,0.29l-0.68,0.64l-0.2,0.41l-0.07,0.45l0.03,0.43
  		l0.13,0.41l0.63,1.25l1.37,1.46l0.84,0.49l0.89,0.24l-0.06,0.13l-1,0.02l-1.51-0.38l-3.28-1.14l-0.52,0.06l-1.03,0.39l-0.53,0.54
  		l-0.1,0.4l-0.02,0.53l0.03,1.33l0.34,3.74l0.12,2.49l-0.02,0.95l-0.17,1.88l-0.01,0.93l0.09,0.92l0.18,0.86l0.28,0.8l2.5,4.47
  		l0.49,2.5l0.22,2.61l-0.22,2.41l1.56,0.23l3.34-0.47l0.96-0.02l2.98,0.67l0.89-0.44l0.55-1.6l0.86-1.94l2.66-2.68l0.11-0.25
  		l0.06-0.99l-0.11-0.51l-0.22-0.44l-1.67-1.75l-1.26-0.56l-1.95-0.52l-0.5-0.34l5.33-0.7l2-0.02l7.99,1.34l1.32-0.13l0.99-0.43
  		l1.79-1.53l0.99-1.06l1.41-1.78l1.36-1.93l0.28-0.8l-0.34-0.31l-0.74-0.35l2.16-0.79L1041.15,553.95z M959.58,609.95l0.25-1.45
  		l-0.03-0.87l-0.54-2.42l-0.58-1.23l-0.41-0.38l-0.49-0.18l-0.76-0.05l-2.72,0.24l-0.47-0.57l-0.07-1.15l-0.12-0.26l-0.3-0.19
  		l-0.47-0.12l-1.73,0.18l-0.44-0.16l-3.19-1.95l-1.86-0.85l-1.36-0.81l-2.52-2.04l-0.33,0.08l0.05,0.47l0.69,1.19l-0.03,0.23
  		l-0.13,0.15l-0.47,0.11l-0.23-0.02l-0.78-0.57l-0.82-0.38l-2.44-0.89L935,594.5l-0.84-0.44l-1.8-2.53l-2.93-2.11l-0.46-0.61
  		l0.05-0.44l0.64-1.79l0.42-0.81l0.44-0.58l0.24-0.69l0.05-0.81l-0.05-0.59l-0.15-0.37l-0.11-1.28l-1.12-1.91l-2.09-2.64l-0.36-0.56
  		l-0.57-1.21l-3-6.89l-0.11-0.77l-0.43-1.54l-0.22-1.19l-0.26-0.56l-0.48-0.62l-0.16-0.51l0.15-0.39l-0.03-0.38l-0.68-0.97
  		l-1.08-1.26l-1.59-1.34l-2.27-0.99l-3.37-2.01l-2.92-1.13l-1.03-0.16l-0.69,0.5l-0.35,0.78l-0.33,0.09l-3.22-1.56l-1.88-0.66
  		l-2.6-0.32l-0.51,0.73l0.1,0.36l0.76,0.78l0.43,0.26l-0.37,0.17l-1.17,0.08l-0.72,0.31l-0.28,0.54l-0.01,0.71l2.03,7.29l0.53,1.04
  		l0.09,0.5l-0.07,0.58l0.19,0.47l0.46,0.37l0.27,0.4l0.12,1.52l0.13,0.71l0.83,2.43l1.29,1.96l1.99,2.06l-0.6,0.14l-0.04,0.17
  		l0.1,0.25l0.34,0.37l-0.15,0.25l-2.87,1.62l-0.59,0.83l-0.24,0.52l-0.23,0.05l-0.95-0.2l-0.8-0.61l-1.04-0.43l-0.37-0.3l-0.19-0.43
  		l-0.56-2.34l-1.63-3.76l-0.82-2.83l-0.52-1.5l-0.59-1.39l-0.48-0.83l-0.36-0.26l-1.29-0.24l-0.37-0.21l0.08-0.65l0.15-0.28
  		l-0.16-0.27l-0.47-0.26l-0.66-0.63l-1.98-0.78l-0.94-0.54l-1.19-1.08l-3.36-1.74l-0.73-0.14l-3.49-0.11l-1.28-0.15l-0.24,1.03
  		l1.14,1.34l2.31,1.11l0.69,0.53l-0.09,1.25l0.02,0.32l-1.97,0.45l-0.62,0.54l-2.27,1.05l-1.49,1.62l-1.52,1.06l-0.38,0.14
  		l-0.7-0.11l-0.13-0.22l-0.03-0.37l0.09-0.38l0.2-0.39l-0.1-0.16l-0.39,0.07l-1.15,0.75l-0.81,0.9l-1.11,0.81l-0.73-0.56l-1.12-0.33
  		l0.67-0.48l1.73-0.78l2.15-2.18l0.89-1.54l-0.16-0.72l-1.56-0.84l-4.99-1.96l-3.42-0.78l-2.53-0.8l-2.69-0.15l-1.13,0.23l-0.71,0.5
  		l-1.33,1.39l-0.35,0.66l-0.04,1.1l0.08,0.5l-0.12,0.45l-0.66,0.68l-0.37,0.08l-0.59-0.42l-2.24,0.34l-1.82,0.44l-1.41,0.53
  		l-0.97,0.17l-0.53-0.2l0.2-0.53l1.83-1.81l0.86-1.06l0.58-0.9l0.29-0.74l0.18-0.82l0.06-0.91l-0.05-0.91l-0.37-1.77l-0.27-0.82
  		l-0.31-0.63l-0.36-0.45l-0.8-0.53l-2.83-0.68l-7.7,2.58l-8.6,2.56l-3.36,1.54l-2.4,0.72l-2.49,1.34l-4.9,3.18l-2.03,1.58
  		l-0.69,0.98l-0.21,0.71l0.28,0.45l0.49,0.47l1.22,0.93l0.32,0.38l-0.05,0.38l-1.31,0.91l-1.86,0.6l-2.99,2.87l-0.13,0.21
  		l-0.21,0.93l-0.08,1.73l0.05,0.61l0.3,0.51l0.7,0.7l2.4,0.59l1.78,0.14l4.08-0.33l1.22,0.03l-1.15,1.54l-2.27,0.55l-0.66,0.91
  		l0.04,0.28l0.28,0.2l2.13,0.68l1.79,0.02l3.25-0.68l4.69-0.15l10.05-1.59l2.09,0.55l0.29,0.27l-0.32,0.33l-1.1,0.1l-2.21-0.3
  		l-2.08,0.25l-0.54,0.23l-0.01,0.49l1.04,0.34l0.26,0.3l-1.27,0.31l-1.57,0.04l-1.63,0.33l-3.32,0.95l-4.2,1.01l-4.21,0.83
  		l-1.01,0.37l-2.82,1.54l-0.7,0.53l-0.2,0.5l0.95,1.22l5.7,3.97l5.01,0.4l5.6-0.31l0.85,0.11l1.18,0.39l0.8,0.07l5.72-0.23
  		l3.96-0.31l2.09-0.32l2.2-0.51l0.84,0.02l2.24-0.34l1.76,0.08l3.03,0.59l2.12,0.55l8.69,2.59l3.27,1.44l0.79,0.66l-0.61,0.35
  		l-0.66,0.2l-3.42-0.04l-0.64,0.32l-2.16,0.4l-0.97,0.05l-4.82-0.59l-3.83-0.09l-2.11-0.18l-2.11-0.39l-3.58-0.08l-7.89,0.84
  		l-8.63,1.25l-4.9,1.13l-0.23,0.12l-0.27,0.58l0.1,0.95l0.3,1.55l0.22,0.94l0.15,0.33l0.94,1.28l2.36,2.15l0.86,0.96l0.34,0.74
  		l0.27,0.44l0.2,0.13l3.47,1.3l2.03,0.5l2.04,0.31l3.87,0.27l3.87-0.12l3.17,0.05l2.1,0.27l3.19,0.84l0.11,0.19l0.61,2.25l0.13,1.04
  		l-0.21,1.79l0.52,1.04l1.83,2.48l0.9,0.93l0.87,0.6l0.91,0.19l1.31,0.06l1.67-0.12l3.04-0.45l6.63-0.25l1.74-0.13l1.54-0.68
  		l1.43-0.09l0.92,0.23l3.2-0.46l4.29-0.3l4.1-0.68l4.43-1.22l1.81-1l1.5-1.03l1.58-0.55l4.23-0.29l3.57-0.71l0.73-0.44l1.94-1.79
  		l0.75-0.42l1.5-2.48l0.81-0.89l0.85-0.48l1-0.05l1.01,0.44l0.49,0.49l0.14,0.62l-0.1,0.88l0.01,0.44l0.11,0.4l0.6,0.44l1.09,0.48
  		l1.12,0.21l1.71-0.08l2.29,0.29l2.28,0.59l2.04,0.19l0.05,0.2l-0.07,0.23l-1.07,1.1l-0.18,0.34l0.53,0.52l4.5,0.71l0.94-0.59
  		l0.9-0.23l2.4,0.92l2.08,0.26l2.97,0.58l2.58-0.3l2.25,0.07l0.51-0.14l0.81-0.47l2.1-0.35l1.09-0.5l3.19-0.97l1.04-0.52l0.58-1.6
  		l-0.01-0.73l-0.28-0.53l-0.42-0.34l-0.22-0.08l-0.79,0.14l-0.45-0.32l-0.15-0.35l-0.06-0.39l0.03-0.44l0.12-0.35l0.65-0.69
  		l-0.02-0.27l-0.57-0.59l-0.89-0.34l-2.49,0.17l-0.84,0.62l-1.95,0.85l-0.9,0.54l-1.74,1.92l-0.25,0.11l0.06-0.47l0.61-1.96
  		l0.07-0.98l-0.14-0.57l-0.12-0.2l-0.33,0.02l-0.34-0.21l-1.28-1.06l-1.04-0.64l-0.39-0.5l0.25-0.35l0.64-0.27l0.47,0.17l2.06,1.19
  		l1.17,0.45l1.48,0.25l1.03-0.06l0.49-0.33l0.19-0.28l0.05-0.43l-0.08-0.58l-0.17-0.49l-0.26-0.39l0.61-0.61l1.48-0.82l0.96-0.44
  		l0.44-0.06l0.71,0.32l2,1.3l1.07,0.51l0.72,0.08l0.38-0.34l0.31-0.52l0.48-1.17l0.21-0.26l0.23,0.25l0.48,1.27l0.53,0.59l1.02,0.46
  		l1.45,0.16l0.52-0.17L959.58,609.95z M956.89,614.84l0.69-0.13l0.38-0.26l0.34-0.43l0.32-0.2l0.41-0.07l-0.26-0.5l-0.57-0.68
  		l-1.23-0.49l-0.57-0.04l-0.32,0.25l-0.04,0.29l0.24,0.33l0.83,0.37l0.19,0.19l-0.09,0.25l-0.6,0.32l0.18,0.15l0.05,0.2l-0.06,0.41
  		L956.89,614.84z M967.3,621.29l0.2-0.88l-0.09-0.5l-0.25-0.58l-0.32-0.37l-0.68-0.27l-0.29,0.23l-0.42,0.63l-0.09,0.28l0.04,0.18
  		l0.39,0.72l0.46,0.32L967.3,621.29z M962.43,624.06l0.2,0l0.44-0.36l0.2-0.02l0.14,0.09l0.32,0.57l0.38,0.35l0.27-0.07l0.66-0.55
  		l0.33-0.13l0.27-0.47l0.33-1.43l-0.24-0.33l-0.69-0.47l-0.35-0.59l-0.71-0.45l-0.9-0.1l-0.38,0.12l-0.3,0.26l-0.09,0.28l0.11,0.31
  		l-0.01,0.41l-0.21,1.12l-0.01,0.73l0.08,0.49L962.43,624.06z M1047.13,615.8l-0.5-0.64l-0.53-0.09l-0.67,0.25l-0.2,0.32l-0.05,0.65
  		l0.72,0.08l0.16,0.41l0.35,0.13l0.43,0.62l0.69-0.17l0.06-0.38l-0.21-0.65L1047.13,615.8z M1170.72,630.36l-0.98,0.07l-0.89,0.27
  		l-0.71,0.07l-1.61-0.14l-1.95,0.58l-0.78,0.36l-0.44,0.34l-1.72,2.04l-1.53,2.09l-0.87,1.4l-0.65,2.08l0.01,0.3l0.68,2.55
  		l0.57,1.43l0.68,1.27l0.6,0.75l0.51,0.24l0.72,0.14l1.39,0.07l3.04-0.32l2.39-0.06l2.26-0.31l3.2-1.19l0.72-0.48l0.95-0.84
  		l0.63-1.12l0.31-1.4l0.03-1.68l-0.34-3.09l0.03-0.29l0.43-0.93l0.09-0.38l-0.01-0.96l-0.12-0.46l-0.21-0.42l-0.43-0.47l-4.4-1.29
  		L1170.72,630.36z M1153.23,702.95l1.27-0.2l1.13-0.46l0.54-0.32l0.52-0.6l0.05-0.47l-0.09-0.24l-0.98-1.45l-0.41-0.46l-0.74,0.04
  		l-0.71-0.57l-0.48-0.11l-2.54-0.14l-1.54,0.26l-0.76,0.25l-0.25,0.36l0.57,0.88l1.79,1.57l1.95,1.34L1153.23,702.95z
  		 M1155.16,691.85l0.61-0.62l0.66-0.41l-0.05-0.11l-0.4-0.09l-0.79,0.02l-1.86,0.28l-0.29,0.29l0.07,0.23l0.24,0.19L1155.16,691.85z
  		 M1163.31,614.61l-2.09-0.12l-0.97-0.38l-0.66-0.04l-1.1,0.35l-0.19,0.19l-0.09,0.38l-0.06,1l-0.18,0.56l0.48,1.2l0.39,0.47
  		l0.51,0.35l0.79,0.09l1.06-0.17l0.71-0.46l0.35-0.75l0.5-0.62l1.03-0.91l0.15-0.31l0.01-0.26l-0.13-0.21L1163.31,614.61z
  		 M1181.61,637.74l0.78,0.34l0.9,0.22l2.23,0.1l4.1-0.02l1.81-0.13l0.08-0.53l-0.03-0.71l-0.28-1.32l-0.2-0.29l-0.29-0.16
  		l-0.76-0.21l-2.5-0.1l-1.94-0.56l-2.26-0.48l-2.76,0.38l-0.21,0.27l-0.15,0.44l0.03,0.49l0.57,1.15L1181.61,637.74z
  		 M1079.18,606.03l1.49-0.29l0.36-0.33l-0.13-0.46l-0.27-0.45l-0.42-0.43l-0.66-0.14l-0.91,0.15l-0.96-0.12l-1.64-0.51l-0.51,0.29
  		l-1.31,0.38l0,0.33l0.5,0.55l0.62,0.38l1.24,0.28l0.5-0.17l1.09,0.38L1079.18,606.03z M1048.61,616.72l0.28,0.15l0.29-0.08
  		l0.24-0.22l0.76-1.25l-0.19-0.92l-0.24-0.25l-0.2,0.27l-0.58-0.16l-0.46,0.19l-0.66,0.38l-0.31,0.31l0.23,0.61L1048.61,616.72z
  		 M1077.72,636.68l0,0.51l0.13,0.44l0.27,0.38l1.31,0.87l0.57,0.22l0.36-0.02l0.42-0.23l0.48-0.45l0.36-0.48l0.43-1.04l0.12-0.55
  		l-0.07-0.89l-0.25-1.23l-0.09-0.94l0.08-0.65l-0.26-0.61l-0.99-0.91l-1.11-0.26l-1.11,1.11l-0.43,0.57l-0.12,0.4l-0.43,0.64
  		l-0.09,0.27l0.19,0.47l0.76,0.83l-0.4,1.05L1077.72,636.68z M952.05,623.58l-1.56-0.39l-0.51,0.76l-0.67,0.49l-0.99,0.48
  		l-0.32,0.38l0.35,0.27l2.71,0.89l0.48,0.26l0.35-0.02l0.43-0.74l0.52-0.22l0.09-1.06l-0.1-0.43l-0.22-0.31L952.05,623.58z
  		 M1179.54,628.06l-0.55-0.74l-0.83-1.51l-0.77-0.55l-1.05-0.17l-0.74,0.12l-0.7,0.76l-0.22,0.69l-0.06,0.57l0.03,0.36l0.76,0.75
  		l2,1.03l0.95,0.9l0.66-0.09l0.3-0.25l0.22-0.41l0.05-0.28l-0.13-0.15l-0.03-0.25l0.28-0.25L1179.54,628.06z M928.69,627.07
  		l-0.83-0.22l-1.74-0.07l-0.73,0.11l-0.48,0.22l0.08,0.4l0.64,0.58l0.96,0.55l1.28,0.52l0.87,0.23l0.47-0.05l0.7-0.37l0.14-0.27
  		l-0.03-0.31l-0.23-0.48L928.69,627.07z M998.24,517.25l0.91,0.53l0.68-0.04l0.66-0.56l0.93-0.54l0.07-0.21l-0.67-0.54l-0.89-0.48
  		l-1.87-0.71l-0.25,0.13l-0.17,0.28l-0.09,0.42l-0.8,0.39l-2.81,0.78l-1.07,0.48l-0.32,0.71l0.43,0.95l0.46,0.56l0.49,0.18l1.13-0.2
  		l0.37-0.23l0.48-0.63l1.35-1.08L998.24,517.25z M922.66,546.39l-1.64,0.02l-7.16,0.77l-2.12,0.38l-0.68,0.42l-1.15,1.16l-1,1.45
  		l0.24,0.5l1.44,0.36l1.89,0.72l2.91,1.78l0.57,0.75l0.35,1l2.28,1.67l1.91,1.68l1.17,0.83l0.8,0.37l0.43-0.08l0.74-0.44l1.06-0.8
  		l0.61-0.65l0.29-0.83l1.5-1.28l1.43-2.33l0.33-0.69l0.26-1.82l-0.02-1.23l-0.27-0.6l-0.52-0.59l-0.59-0.36l-0.98-0.18l-2.36-1.59
  		L922.66,546.39z M983.78,540.83l-4.41,1.16l-1.96,0.22l-0.73,0.23l-0.52,0.31l-0.32,0.38l-0.78,0.27l-2.1,0.37l-0.49,0.24
  		l-0.11,0.25l0.27,0.27l0.73,0.28l2.73,0.82l1.32-0.08l0.47-0.19l0.59-0.45l1.12,0.15l2.42-0.35l1.04-0.33l2.41-1.31l0.9-0.61
  		l0.69-0.78l0.12-0.31l-0.07-0.31l-0.26-0.31l-0.86-0.1L983.78,540.83z M989.73,533.82l0.43-0.56l0.11-0.28l-0.23-0.36l-0.83-0.48
  		l-0.84,0.4l-1.97,1.54l0.24,0.36l0.55,0.39L989.73,533.82z M998.25,526.16l0.21,0.58l0.21,0.35l0.2,0.12l0.74-0.51l0.74,0.31
  		l1.93,1.72l3.49,0.56l1.39,0.05l2.76,1.58l1.3,0.67l0.9,0.3l1.37,0.09l2.77-0.18l1.87,0.05l3.02-0.28l0.45-0.13l0.22-0.37
  		l-0.01-0.61l0.11-0.37l0.22-0.12l0.15-0.26l0.23-1.42l-0.03-1.09l-0.1-0.52l-0.54-1.28l-0.08-0.39l0.17-0.83l0.29-0.62l-0.79-1.59
  		l-0.64-0.74l-0.71-1.29l-1.33-1.25l-2.93-2.08l-1.44-0.84l-1.86-0.51l-1.93-0.12l-1.44,0.14l-5.23,1.6l-1.54,1.02l-0.85,0.43
  		l-1.43,1.46l0.05,0.98l-0.52,1.04l-0.94,0.35l-0.76,0.13l-1.54,1.91l-0.26,0.69l-0.02,0.44l0.07,0.51l0.26,0.19l1.46-0.15
  		L998.25,526.16z M1003.02,533.41l2.83,0.75l0.75,0.1l0.39-0.08l0.27-0.23l-0.03-0.34l-0.62-0.77l-1.33-0.86l-1.27,0l-0.72,0.35
  		l-0.24,0.29l-0.64,0.27l0.14,0.22L1003.02,533.41z M1052.99,533.45l2.41-0.1l0.92-0.22l1.58-0.69l0.56-0.48l0.06-0.49l-0.07-0.53
  		l-0.19-0.56l0.23-0.22l0.65,0.12l0.48-0.16l0.32-0.45l0.33-0.24l0.34-0.02l0.27,0.43l0.21,0.89l0.27,0.35l0.62-0.42l0.81-1.46
  		l1.24-0.5l0.39,0.05l0.48,0.42l0.77,0.32l-0.3,0.68l-0.86,1.3l-0.51,0.97l-0.17,0.65l0.02,0.47l0.46,0.53l0.65,0.26l3.51,0.08
  		l3.48,0.32l1.93-0.53l3.11,0.37l1.89,0.03l0.88-0.18l-0.54-1.13l0.63,0.03l2.65,0.7l1.09-0.37l0.85-0.07l1.3,0.68l1.24,0l2.23-0.6
  		l0.27-0.17l0.19-0.37l0.12-0.56l0.27,0l0.87,0.96l1.05,0.41l0.68,0.02l0.39-0.17l0.21-1.33l0.42,0.05l0.8,0.61l0.83,0.43l1.27,0.37
  		l2.03,0.19l1.52-0.12l3.18-0.82l2.07-0.03l0.76-0.33l1.01-1.16l0.16-0.25l0.44-1.59l-0.19-0.63l-1.04-0.54l-0.19-0.24l-0.28-0.74
  		l0.18-0.15l0.97,0.28l1.57,0.95l0.52,0.2l0.47,0l0.13,0.47l-0.21,0.95l-0.04,0.66l0.5,1.06l0.67,0.78l0.4,0.3l1.64,0.59l0.76,0.08
  		l1.94-0.17l2.92,0.89l1.08,0.16l1.11-0.07l1.7-0.43l2.25-0.85l0.96-0.22l7.99-0.25l0.13-0.05l0.35-1.22l0.09-0.78l-0.02-0.77
  		l0.17-0.52l0.36-0.26l-0.34-0.54l-1.05-0.82l-0.28-0.45l0.49-0.09l0.73,0.23l2.17,1.03l3.68-0.78l0.9-0.63l-0.5-0.69l-0.54-0.52
  		l-1.17-0.53l-0.58-0.01l-2.56,0.52l-0.83,0.05l-1.06-0.24l-1.02-0.54l0.21-0.29l3.2-1.14l2.89-1.37l1.06-1.03l0.01-0.61l-0.66-1.53
  		l-0.63-1.1l-0.65-0.2l-3.05-0.1l-1.35-0.21l-0.22-0.19l1.36-0.89l0.05-0.34l-0.33-0.33l-1.38-0.82l-1.73-0.22l-3.98-0.02
  		l-1.04-0.26l-0.41-0.19l-0.16-0.26l0.35-0.88l-0.99-0.35l-3.19-0.67l-4.26-0.62l-1.69-0.04l-1.68,0.26l-4.55,1.07l-1.21,0.1
  		l-4.27-1.07l-1.58-0.11l-0.69,0.67l-0.96,0.3l-4.02,1.88l-1.22-0.02l-1.99,0.17l-3.25,1.24l-1.76-0.12l-3.3,0.88l-0.81,0.45
  		l1.38,1.03l-0.4,0.8l-2.4-0.19l-1.69-0.51l-0.91-0.46l-2.27-0.48l-3.73-2.17l-0.91,0.45l-1.47,1.82l-0.28-0.15l-0.6-0.91
  		l-0.73-0.49l-3.97,1.09l-3.1-2.28l-0.64-0.23l-0.59,0l-0.58,0.19l-0.57,0.39l-0.1,0.62l0.37,0.86l0.13,0.62l-0.29,0.67l-0.25,0.2
  		l-0.4-0.03l-3.06-1.89l-0.48-0.14l-2.6,0.13l0.17-0.32l2.23-1.07l0.88-0.91l0.44-0.67l0-0.43l-0.61-0.57l-1.97-1.07l-1.17,0.22
  		l-0.38-0.16l-0.83-1.23l-1-0.72l-1.01-0.08l-1.21-1.03l-0.63-0.4l-3.88-0.39l-0.76-0.52l-1.83-0.62l-2.19-0.32l-1.08-1.05
  		l1.24-0.17l3.65,0.77l4.34,0.48l7.63-0.54l0.96-0.5l0.47-0.37l0.15-0.33l-0.55-0.76l-13.23-2.37l-4.04-0.16l-0.68-0.16l-0.24-0.75
  		l0.38-0.2l0.52-0.01l0.61,0.18l3.36,0.29l2.04,0.33l0.67-0.54l-0.1-0.35l-0.42-0.48l-1.13-0.67l-3.25-1.42l-1.52-0.33l-2.05-0.08
  		l-2.03,0.16l-4.27,1.05l-3.53,0.23l-2.35-0.31l-3.59,2.57l-0.95,0.46l0.42-0.78l0.49-0.62l0.92-0.82l0.45-0.93l0.53-0.75l0.09-0.3
  		l0.01-0.38l-0.19-0.82l-0.15-0.28l-0.39-0.25l-1.21-0.49l-1.58-1.09l-1.71-0.72l-1.15-0.05l-1.35,0.24l-1.57-0.15l-2.7-0.81
  		l-4.3-1.05l-4.31-0.83l-1.78-0.04l-1.78,0.29l-2.66,0.81l-1.46,0.3l-1.14,0.05l-0.62,0.23l-0.1,0.42l-0.37,0.62l0.36,0.44l0.93,0.6
  		l1.92,0.79l0.27,0.24l-0.42,0.41l-1.17,0.2l-0.75-0.05l-1.67-0.65l-0.16,0.52l0,0.34l0.15,0.28l0.3,0.21l1.73,0.41l5.63,2.35
  		l0.83,0.06l1.87-0.32l-0.38,0.37l-0.78,0.46l-1.89,0.42l-0.22,0.47l0.68,0.71l0.99,0.52l3.32,0.93l1.46,1.72l2.33,0.12l2.19-0.62
  		l1.27-0.07l1.71,0.26l4.47,0.22l1.58-0.06l0.17-0.27l0.71-0.4l2.12-0.84l0.98-0.11l0.85,0.21l1.76,2.44l1.47,1.73l1.98,2.22
  		l1.41,1.23l1.02,1.19l0.36,0.86l0.36,1.2l0.26,1.19l-0.1,0.4l-0.25,0.41l-1.85,2.25l-0.68,1.25l-0.14,0.4l0.01,0.62l0.16,0.84
  		l0.16,0.58l0.35,0.57l1.18,0.82l1.1,0.98l0.13,0.38l-0.88,0.49l-0.08,0.35l0.6,1.74l1.19,2.61l0.76,0.84l1.73,0.74l0.98,0.73
  		l0.34,0.08l1.42-0.28l1.45,0.36l0.28-0.07l-0.25-1.01l0.29-0.44l2.1-1.37l0.02,0.28l-0.67,0.94l-0.08,0.51l1.54,0.32l1.94,1.39
  		l1.61,0.04l2.92,0.83L1052.99,533.45z M1010.97,511.18l0.16,0.65l0.23,0.51l0.3,0.37l1.07,0.36l0.82-0.01l1.66-0.29l0.22-0.18
  		l0.06-0.2l-0.3-0.65l-0.69-1.02l-0.25-0.56l-0.24-0.94l-0.23-0.36l-0.33-0.07l-1.74,0.68l-0.8,0.05l-0.35,0.15l-0.17,0.26
  		l0.06,0.33l0.29,0.39L1010.97,511.18z M994.68,556.65l-0.21-0.46l-0.58-0.38l-1.29,0.51l-0.46,0.44l-0.16,0.51l-0.08,0.58
  		l0.04,1.06l1.25,1.13l1.36-0.16l0.62-0.17l0.33-0.23l0.3-0.51l0.27-0.79l0.04-0.52l-0.4-0.45l-0.83-0.39L994.68,556.65z
  		 M958.64,477.23l-0.68-0.63l-0.55-0.31l-1.08-0.44l-2.67-0.69l-2.35-0.14l-2.91,0.19l-1.55,0.16l-0.2,0.13l-0.03,0.67l0.14,1.21
  		l0.68,0.76l2.51,0.65l2.1,0.09l1.16-0.17l0.92-0.39l1.57-0.19l3.33-0.11l-0.15-0.5L958.64,477.23z M887.7,633.84l-0.24,0.74
  		l1.15,0.86l0.32-0.05l0-0.33l-0.15-0.47L887.7,633.84z M934.11,497.59l-0.28-0.32l-0.69-0.16h-1.1l-2.49,0.63l-0.72,0.42
  		l-0.15,0.42l0.22,0.75l0.59,1.07l0.83,1.99l0.42,0.53l0.73,0.15l6.72-0.05l1.35-0.32l0.94-0.41l0.98-0.6l0.42-0.46l-0.15-0.32
  		l-1.17-0.48l-3.32-1.08l-0.24-0.43l-0.92-0.66l-0.83,0.06l-1.28,0.35l-0.42-0.1l0.45-0.55L934.11,497.59z M891.2,635.67l-0.95-0.15
  		l-0.14,0.11l0.19,0.34l0.55,0.4l1.48,0.65l0.22-0.08l0.07-0.2l-0.2-0.59l-0.51-0.58L891.2,635.67z M927.95,520.42l-0.93,1.87
  		l-0.39,0.65l-0.28,0.21l-0.05,0.22l0.5,0.48l1.63,0.99l2.74,0.51l1.59-0.09l2.58-0.74l0.88-0.6l0.38-0.41l0.19-0.4l-0.01-0.4
  		l-0.2-0.41l-0.67-0.71l-0.1-0.62l-0.39-0.96l-0.4-0.43l-0.55-0.36l-1.33-0.56l-2.29-0.09l-1.08,0.29l-1.47,1.08L927.95,520.42z
  		 M943.1,468.21l-0.69,0.07l-1.16,0.39l-1.26,1.14l-0.11,0.33l0.02,0.28l0.16,0.23l1.13,0.22l0.97-0.37l1.81-1.32l-0.31-0.59
  		L943.1,468.21z M946.09,505.33l-0.4-1.49l-1.21-0.44l-3.12-0.08l-3.99,0.93l-3.71,0.61l-2.85,0.71l-0.47,1.28l1.38,1.07l2.17,0.01
  		l1.55,0.17l-1.55,1.8l1.81,0.7l4.79-1.14l1.35,0.69l-1.71,1.66l-0.59,1.01l3.13,0.01l3.07-0.29l0.57-0.49l0.74-1.05l0.89-0.45
  		l2.28-0.56l0.33-0.2l-0.08-0.24l-1.76-1.06l-1.67-1.24l-0.54-0.15L946.09,505.33z M953.87,498.19l0.07,0.31l1.01,0.21l2.18-0.08
  		l4.04-1.23l4.02-1.5l-1.67-0.29l-1.3-0.04l-2.23,0.17l-0.64,0.15l-1.71,1.21l-2.9,0.66L953.87,498.19z M1140.7,608.87l-1.38-0.34
  		l-1.02,0.22l-1.3,0.6l-0.85,0.25l-0.77-0.1l-0.53,0.12l-0.37-0.68l-0.38-0.29l-0.68,0.12l-1.09-0.06l-0.12,0.22l-0.1,0.57
  		l-0.13,0.11l-1.57-0.2l-0.62,0.15l-0.43,0.45l-0.15,0.32l0.14,0.18l2.78,0.41l0.42,0.26l0.57,0.69l0.25,0.06l0.26-0.26l0.2,0.1
  		l0.14,0.46l0.55,0.4l0.96,0.33l0.58,0.06l0.2-0.21l-0.14-0.49l-0.64-1.17l1.4,0.38l2.77-0.33l1.27-0.81l0.32-0.41l-0.21-0.27
  		L1140.7,608.87z M1262.4,719.14l-0.23,0.13l-0.19-0.11l-0.32-0.61l-0.48-0.21l-0.41-0.01l-0.31,0.19l-0.21,0.4l0.04,0.44l0.54,0.86
  		l2.01,0.84l0.26-0.01l0.42-0.37l0.01-0.24l-0.17-0.24l-0.49-0.33l-0.19-0.25l-0.12-0.37L1262.4,719.14z M1263.85,738l0.6,0.44
  		l1.51,0.66l0.98,0.32l0.29-0.88l-0.78-0.8l-0.28-0.14l-0.96-0.93l-1.15,0.06l-0.45,0.11l0.02,0.67L1263.85,738z M1120.41,827.45
  		l4.1,1.21l2.15,0.82l0.73,0.22l1.75,0.3l0.6-0.15l0.19-0.46l-0.01-0.5l-0.46-1.04l-1.14-1.3l-1.98-1.93l-1.68-0.21l-4.31,0.43
  		l-0.46,0.24l-0.42,0.38l-0.74,0.94l0.29,0.45L1120.41,827.45z M1225.58,756.17l0.19-0.01l0.25-0.19l0.68,0.11l0.51-0.9l0.04-0.29
  		l-0.21-0.11l-0.11-0.25l-0.01-0.66l-0.91,0.02l-0.23,0.29l0.16,0.58l-0.04,0.57L1225.58,756.17z M1237.16,735.97l-0.92-0.22
  		l-0.46,0.33l-0.78,1.35l-0.87,1.2l-0.25,0.58l0.02,0.77l0.35,0.27l0.76-0.1l1.87-0.81l0.76-0.45l0.66-0.66l0.21-0.73l-0.24-0.5
  		l-0.56-0.65L1237.16,735.97z M1160.04,695.39l-1.95,1.25l2.59,1.85l1.14,0.58l1.17,0.3l0.89-0.13l0.21-1.46l-0.94-0.93
  		L1160.04,695.39z M1216.4,707.98l-1.95-0.97l-1.35-0.44l-1.51-0.33l-1.02-0.17l-0.72,0.05l0.7,0.77l1.27,0.37l1.14,1.1l0.12,0.74
  		l0.6,0.67l0.67,0.31l1.22,0.27l1.14,0.1l0.58-0.05l0.41-0.17l0.17-0.22l-0.19-0.5l-0.63-0.66L1216.4,707.98z M1142.02,793
  		l0.39,0.52l0.44-0.08l0.77-2.17l0.56-1.15l0.69-1.2l0.24-0.65l-0.21-0.09l-0.09-0.2l0.02-0.32l-0.19-0.6l-0.27-0.18l-0.7-0.21
  		l-0.19-0.24l-0.2-0.55l-0.27-0.21l-0.47-0.14l-0.3-0.23l-0.13-0.33l-0.09-0.06l-0.28,1.64l-0.29,1.04l-0.45,1.19l-0.55,0.78
  		l-0.66,0.37l-0.15-0.14l0.36-0.65l0.3-0.79l0.37-1.61l-0.03-0.78l-0.1-0.28l-0.09-0.04l-0.08,0.21l0.01,0.74l-0.12,0.68l-0.28,0.92
  		l-0.68,0.98l-1.63,1.55l-1.78,2.16l-0.06,0.25l0.17,0.22l0.7,0.25l1.04-0.82l1.82-2.14l0.17-0.09l0.42,0.16l-0.02,0.26l-2.25,3.4
  		l1.34-0.82l0.66,0.06l0.27-0.05l0.34-0.25l0.4-0.46l1.56-2.86l0.33-0.43l0.34,0.37l0.05,0.24l-0.05,0.28L1142.02,793z
  		 M1183.11,708.48l-0.89-0.4l-0.53-0.09l-0.47,0.09l-0.05,0.18l1.07,0.58l2.07,0.62l1.68,0.16l0.45-0.12l0.1-0.23l-0.24-0.35
  		l-0.78-0.23L1183.11,708.48z M1258.6,722.13l0.04,0.5l0.28,0.62l2.55,1.42l0.31,0.27l0.84,0.28l0.63,0.69l0.19,0.03l0.2-0.15
  		l0.38-0.61l0.47-0.32l0.3-0.43l0.05-0.88l0.18-0.69l-0.73-0.58l-0.28-0.32l-1.39-0.3l-1.48-0.01l-1.7,0.23L1258.6,722.13z
  		 M1262.82,711.25l-0.22,0.35l0.47,0.49l0.54,0.19l1.52,0.3l0.93-0.1l0.57-0.35l0.51-0.91l-0.4-0.64l-0.42-0.21l-0.98,0.05
  		l-1.62-0.14l-0.21,0.2l-0.01,0.23l0.19,0.27l-0.12,0.14L1262.82,711.25z M1294.78,791.69l1.3,0.21l-0.1-0.29l0.33-0.49l-0.09-0.22
  		l-0.69-0.36l-0.32-0.14l-0.59,0.27l-0.26,0.35l-0.06,0.3l0.04,0.19L1294.78,791.69z M1343.42,839.53l0.57-0.45l0.16-0.73
  		l-0.61,0.14l-0.45,0.5l-0.17,0.43l0.09,0.13L1343.42,839.53z M1352.62,865.05l0.26-0.05l2.17-0.77l-0.33-0.19l-0.62-0.71
  		l-0.37-0.07l-0.52,0.69l-0.5-0.33l-0.16,0.08l-0.09,0.55l0.02,0.72L1352.62,865.05z M1348.26,866.05l0.36-0.13l0.3-0.25l1.3-0.64
  		l0.14-0.34l-0.18-0.13l-0.5,0.1l-0.96,0.66l-0.26,0l-0.16-0.11l-0.05-0.21l-0.2-0.09l-0.36,0.03l-0.07,0.22l0.38,0.67
  		L1348.26,866.05z M1288.78,773.12l-0.38,0.08l-0.37,0.23l-0.39-0.15l-0.2,0.11l-0.03,0.11l0.3,0.64l1.52,0.88l1.33,0.08l-0.19-1.32
  		l-0.71-0.36L1288.78,773.12z M1288.31,654.18l1.52,0.57l1.65,1.12l0.63-0.12l1.23-0.6l0.06-0.56l-0.45-0.57l-1.36-1.26l-2.61-2.17
  		l-1.22-1.24l-1.31-0.39l-2.16,2.01l-1.88-0.33l-0.91-0.36l-0.49,0.31l-0.55-0.01l-1.09-0.24l-1.53,0.35l-2.74,0.88l-1.41,0.59
  		l-0.55-0.02l3.33-2.44l0.41-0.43l0.19-0.62l0.34-0.69l0.28-0.82l1.02-0.87l-2.32-1.45l-1.73-0.32l-0.59-0.28l-0.71,0.46l-1.24,0.42
  		l-0.1,0.74l-1.91-0.03l-1.06,0.12l-1.41,0.02l-0.16-0.64l-1.8-0.21l-0.93-0.5l1.01-0.07l0.94,0.19l1.4-0.17l2.49,0.09l-0.59-2.13
  		l1.91-1.01l-1.43-1.28l-1.15,0.46l-2.02-1.69l-1.1-1.05l-0.93-0.4l-1.62,0.81l-1.61-0.05l-0.42-0.52l0.38-0.99l0.59-0.68l0.79-0.38
  		l0.23-0.36l-0.73-0.6l-0.46-0.17l-0.74,0.25l-1.99,1.26l-0.97,0.87l-0.23,0.68l0.24,1.56l0.22,0.63l-0.12,0.08l-1.18-1.31
  		l-0.09-0.49l0.51-1.97l-0.15-0.47l-0.51-0.2l-1.12-0.06l-0.48,0.43l-0.89,0.5l-0.93-0.5l0.26-1.07l0.01-0.58l-0.37,0.03l-2.02,1.59
  		l-0.99,1.53l-0.42,0.31l-0.43-0.03l-0.69-0.25l-0.03-0.13l0.98-0.64l1.17-1.98l0.07-1.19l0.45-2.3l-2.68,0.44l-0.84,0.58l0.21,1.46
  		l-0.48,1.1l-0.18,0.08l-0.15-1.33l-0.22-0.12l-1.44,0.96l-0.2-0.04l0.15-0.48l0.6-1.18l0.03-0.52l-0.58-0.7l-1.12-0.91l0.3-0.7
  		l1.98-0.74l0.41-0.31l-0.24-0.18l-0.94-0.2l-2.16,0.15l-0.77-0.06l-1-0.32l-1.14-0.15l-0.94-0.53l-0.75-0.25l-0.93,0.05l-0.92,0.35
  		l-0.53-0.02l-0.84-0.39l-0.93-0.96l-0.04-0.95l-0.49-0.32l-1.03-0.44l-1.75-0.25l-2.79-0.16l-3.77-1.43l-0.2-0.18l0.11-0.09
  		l0.93,0.04l4.16,0.92l0.49-0.02l1.04-0.46l0.78-0.12l1.06,0.1l2.6,0.73l1.12,0.16l0.74-1.17l0.37-1.04l-0.01-1.55l-0.68-0.4
  		l-2.43-0.99l-1.54-0.5l-0.94-0.08l-4.87,1.38l-0.4-0.28l3.55-1.6l1.79-0.39l1.75,0.44l2.19-0.68l1.46,0.71l1.33,0.4l1.04,0.09
  		l1.28-0.26l1.04,0.21l3.41,0.27l0.8-0.23l0.24-0.34l0.03-1l-0.08-0.41l-0.27-0.39l-0.46-0.37l-2.37-1.26l-1.55-0.58l-1.04-0.19
  		l-3.06-0.1l-0.84,0.07l-0.7,0.22l-1.26-0.24l-3.83-1.52l-2.29,0.2l-2.44,0.69l-1.48,0.09l0.19-0.53l0.87-0.41l2.42-0.73l1.41-0.3
  		l2.51-0.01l0.69-0.3l0.35-0.54l0.49-0.45l0.64-0.36l0.79-0.24l1.8-0.11l3.95,0.85l0.65,0l0.32-0.14l0.24-0.39l0.17-0.64l-0.2-0.92
  		l-1.03-2.08l-0.38-0.54l-2.97-2.81l-1.17-0.94l-1.72-0.54l-0.52,0.04l-0.7,0.5l-0.67,0.81l-0.37,0.73l0.07,0.3l0.91,0.49
  		l-0.17,0.25l-0.63,0.38l-0.73,0.24l-0.83,0.1l-0.74,0.52l-0.66,0.94l-0.74,0.41l-1.29-0.17l-0.94-0.4l1.43-1.53l0.52-0.32
  		l0.45-0.68l-0.1-0.52l-0.15-0.29l-0.21-0.07l-1.18-0.05l-2.76,0.33l-1.99,0.38l-1.28,0.47l-1.36,1.24l-0.99,0.27l-1.22-0.2
  		l-0.03-0.43l3.05-1.79l2.22-0.97l1.15-0.36l1.85-0.19l1.99-0.38l0.42-0.15l1.27-0.89l0.69-0.34l0.66-0.14l0.56-0.38l0.45-0.63
  		l0.04-0.64l-0.36-0.64l-0.39-0.44l-0.41-0.25l-3.33-1.18l-1.47-0.63l-0.87-0.55l-1.01-0.3l-0.9-0.09l-1.39,0.19l-1.14-0.13
  		l-0.84-0.75l-1.3-0.16l-1.14,0.24l-2.13,0.64l-1.88,0.76l-2.44,1.45l-1.09,0.96l-0.55,0.95l-2.26,4.71l-0.78,1.22l-0.2,0.01
  		l1.26-4.52l0.03-1.14l-0.41-0.47l-0.42-0.26l-0.43-0.06l-0.42,0.12l-0.74,0.59l-1,1.34l-0.37,0.34l-0.38,0.02l-0.99-0.57l0.76-0.39
  		l0.6-0.47l0.6-0.7l0.61-0.49l1.73-0.61l1.59-0.36l1.43-0.68l2.19-1.81l0.9-1.21l0.14-0.49l0.02-1.6l-0.3-0.7l-1.01-0.79l-0.28-0.08
  		l-0.52,0.15l-1.33,0.76l-1.19,1.11l-1.55,0.04l-3.13-1.11l-2.25,0.52l-1.19,1.12l-0.62,1.09l-0.75,0.58l-2.7,0.03l0.29-0.28
  		l1.25-0.54l1.28-0.84l2.31-1.95l0.72-0.45l0.69-0.16l2.22,0.28l0.83-0.29l0.83-0.65l1.49-1.42l0.28-0.62l-0.36-0.93l-0.22-0.36
  		l-0.65-0.65l-1.07-0.94l-1.52-0.82l-1.98-0.7l-2.03-0.49l-2.09-0.28l-1.3-0.07l-0.52,0.14l-1.03-0.52l-1.68-0.59l-2.4,1.76
  		l-0.77,0.42l-0.27,0.84l1,2.09l0.1,1.04l-0.69-0.61l-1.14-0.79l-0.71-1.65l-1.18-0.72l-0.77-0.97l-1.16,1.07l-1.02,0.72l-0.77,0.72
  		l-1.13,0.35l2.77-3.04l1.31-1.89l0.05-0.41l-0.9-0.39l-0.44,0.01l-1.06,0.34l-1.24,1.06l-2.23,0.15l-0.71,0.38l-0.94,0.99
  		l-0.81,1.8l-0.54,2.04l-2,1.86l-0.33-0.19l0.88-1.3l0.76-1.44l0.05-0.68l-0.29-0.84l0.33-1.03l1.02-1.19l0.06-0.45l-2.18,0.13
  		l-1.95-0.38l-0.12-0.27l2.63-0.26l0.88-0.26l1.41-0.69l2.58-0.89l0.57-0.8l0.3-0.71l0.03-0.63l-0.48-0.93l-0.22-0.2l-0.71-0.25
  		l-1.2-0.3l-1.48-0.18l-1.75-0.06l-2.06,0.59l-2.36,1.24l-1.44,1.04l-0.79,1.26l-1.08,1.45l-0.63,0.5l-0.22-0.06l0.1-0.22l1.04-1.13
  		l1.23-2.11l0.83-1.12l1.25-0.5l2.88-2.95l0.11-0.68l-0.27-0.85l-0.41-0.87l-0.55-0.9l-0.91-0.74l-3.45-1.45l-1.09-0.08l-1.14,0.22
  		l-1,0.02l-0.85-0.17l-2.4-0.98l-1.89-0.99l-1.65-0.41l-3.05-0.24l-2.62-0.02l-1.57,0.19l-2.09,0.6l-2.94,1.4l-0.61,0.46l-0.31,0.47
  		l-0.17,0.54l-0.04,0.61l0.26,0.56l1.4,1.2l3.04,1.05l1.95,0.88l1.34,0.31l0.16,0.65l-1.76-0.03l-3.29-1.59l-1.61,0.08l-1.02-0.07
  		l-1.29-0.79l-0.98-0.35l-1.02,1.36l-0.36,2.61l0.61,1.11l0.67,0.91l0.75,0.59l0.28,0.59l0.03,0.27l-0.25-0.01l-1.36-0.78
  		l-1.95-1.77l-0.08-0.97l0-1.33l0.15-1.33l-1.64-1.33l-1.09-0.56l-0.88,0.85l-1.32,0.36l-0.59-0.28l-0.33-0.7l-0.71-1l-0.45-0.12
  		l-0.8,0.29l-0.97,0.54l-0.21,0.25l-0.35,0.73l0.15,0.5l1.74,1.96l-0.37,0.04l-1.58-0.62l-0.55,0.08l-1.72,0.95l-2.69,2.54
  		l-0.82,0.93l-0.47,0.28l-0.56,0.06l-0.19-0.14l0.18-0.34l0.01-0.51l-0.15-0.69l0.01-0.53l0.17-0.38l0.28-0.25l0.38-0.12l0.93,0.11
  		l0.35-0.23l-1.92-1.39l-0.18-0.3l1.01-0.79l1-1.24l0.82-1.39l-0.06-0.4l-1.26-0.1l-4.04,2.85l0.09-0.54l1.94-1.82l2.73-2.11
  		l2.11-1.92l0.86-0.62l0.4-0.45l-0.02-0.39l-1.29-0.74l-0.59-0.65l-0.77-1.14l-0.23-1.12l0.31-1.09l-0.17-0.91l-0.65-0.72
  		l-1.18-0.67l-1.71-0.62l-1.07-1.12l-0.73-2.69l-0.89-1.93l-0.52-0.6l-1.68-1.01l-2.87-0.55l-2.16-0.11l-3.85,0.11l-1.55,0.23
  		l-0.84,0.27l-0.65,0.38l-3.28,0.73l-2.69,0.91l-1.47,0.78l-0.09,0.33l1.17,1.3l-0.05,0.19l-2.59-0.69l-2.75,0.05l-1.68,1.09
  		l-3.39,0.88l-3.68,3.73l0.6,0.97l2.44,0.69l6.9,2.68l0.15,0.64l-6.17-1.98l-2.3-0.55l-1.06,0.14l-0.57,0.32l-1.01,1.11l-0.38,0.6
  		l-0.21,0.72l-0.05,0.84l0.1,1.42l0.19,0.46l0.52,0.58l0.47,0.93l0.9,1.06l0.42,0.36l1.56,0.61l0.84-0.02l0.78-0.34l0.97-0.01
  		l1.91,0.47l-0.18,0.4l-1.4,0.35l-0.26,0.34l0.54,0.78l1.13,1.1l2.49,1.5l0.55,0.57l-0.01,0.22l-0.57-0.13l-2.16-1.21l-3.46-1.4
  		l-2.55-0.24l-0.71,0.29l-1.18,1.79l-1,0.61l-1.66,0.64l-0.42,0.56l0.83,0.47l1.83,1.42l0.71,0.78l0.97,1.4l0.49,0.47l0.75,0.34
  		l1.01,0.22l0.83,0.11l1.62-0.07l1.19,0.43l0.33,0.71l0.02,1.12l-0.14,1.18l-0.29,1.25l-0.68,4.11l-0.29,1l-0.39,0.41l-1-0.46
  		l-0.5-0.42l-0.15-0.31l0.19-0.2l0.35-0.71l-0.79-0.22l-4.62-0.01l-1.52,0.41l-2.55,1.19l-1.64,0.5l-1.92,0.43l-0.33-0.34l0.98-0.49
  		l2.47-0.82l1.97-1.03l1.58-0.48l2.96-0.51l2.26-1.19l0.96-0.77l-0.47-0.7l-2.09-1.46l-1.77-0.99l-1.79-1.34l-2.41-2.03l-1.54-2.01
  		l-0.66-2l-0.37-1.54l-0.08-1.08l0.02-1.12l0.2-2.22l0.01-0.93l-0.49-1.02l-1.8-2.18l-0.52-1.01l-0.1-0.62l0.32-1.74l0.46-0.64
  		l0.79-0.8l3.33-4.85l0.72-0.85l2.68-2.42l1.59-1.08l2.44-1.24l1.94-1.49l0.23-0.44l-0.31-0.69l-0.22-0.24l-0.85-0.49l-7.08-0.69
  		l-3.84-0.08l-3.05,0.34l-5.93,1.22l-2.08,0.6l-1.74,0.81l-2.05,1.28l-4.51,3.13l-0.19,0.25l-0.1,0.37l-0.02,0.5l-0.15,0.36
  		l-1.82,0.97l-1.16,1.14l-0.93,1.2l-0.32,0.63l-0.21,0.84l-0.2,1.19l-0.13,1.63l-0.26,0.6l-1.51,1.83l-1.39,1.95l-0.97,1.6
  		l-0.38,0.89l-0.11,0.71l0.01,0.87l0.12,1.02l0.3,0.64l0.95,0.44l0.45,0.08l-0.06,0.27l-2.25,1.72l-0.79,1.58l0.05,0.74l0.36,0.85
  		l0.37,1.65l0.38,2.46l0.36,1.46l0.34,0.47l0.94,0.6l2.32,1.11l2.85,1l4.13,0.74l4.45,0.2l1.59,0.29l0.94,0.47l1.58,1.1l1.72,0.85
  		l1.92,0.64l-0.35,0.37l-2.97,0.48l-2.62,0.19l-1.63-0.11l-2.28-0.51l-1.75-0.71l-1.51-0.23l-2.78,0.02l-2.87-0.35l-0.76,0.08
  		l0.39,0.4l0.32,0.61l0.96,2.85l0.41,0.78l3.03,3.62l0.55,0.43l1.01,0.36l2.2,0.43l1.88,1.13l2.34,1.77l0.53,0.08l0.41-0.18l1-0.78
  		l0.44-0.14l0.97-0.11l2.23,0.24l1.47-0.23l-0.09-0.3l-0.69-0.5l-0.14-0.34l0.42-0.18l2.64,0.36l0.89-0.04l0.67-0.16l1.92-0.98
  		l-0.29,0.32l-0.29,0.58l-0.29,0.85l0.14,0.94l1.02,1.75l0.33,0.42l1.04,0.61l3.52,1.04l2.93-1.13l3.2,0.5h1.21l0.67,0.23l0.54,0.45
  		l2.05,0.43l5.58,0.64l2.77-0.03l3.21-0.67l0.5,0.08l1.4,0.54l3.69,1.54l1.63,0.44l1.31-0.06l1.52-0.41l3.31-1.11l5.42,3.19
  		l0.69-0.61l-0.02-0.3l-0.69-0.89l-0.83-0.74l-1.46-1.25l-1.12-0.63l-0.78,0l-0.91-0.35l-1.03-0.7l0.78-0.25l3.89,0.3l2.3,0.52
  		l1.31,0.07l2.39,0.63l1.06,0.21l0.83,0.01l4.6,1.53l0.85,0.11l1.79-0.11l1.77-0.46l1.71-0.79l0.6-0.5l0.35-0.56l0.03-0.85
  		l-0.29-1.14l-0.45-0.96l-0.6-0.79l-0.7-0.48l-2.78-0.72l-0.49-0.43l0.07-0.56l0.43-0.68l0.79-0.81l0.79-0.62l0.79-0.43l0.73,0.34
  		l0.67,1.11l0.58,0.69l0.49,0.27l1.27,1.4l0.39,0.11l0.71,0.47l1.75,1.31l0.43,0.16l0.63-0.01l2.65-0.42l0.58,0.13l0.44,1.03
  		l0.4,1.96l0.1,1.14l0.23,0.98l0.37,0.83l0.82,0.14l2.21-0.28l1.82,0.28l0.45,0.17l0.87,0.74l-0.08,0.45l-1.26,0.84l-0.73,0.27
  		l-0.2,0.27l0.33,0.26l0.59,0.27l0.88,0.09l1.46,0.57l0.47-0.27l0.81-0.97l0.65-0.42l0.75-0.04l1.6,0.37l0.03,0.13l-1.96,0.5
  		l-0.44,0.43l-0.07,0.63l0.04,0.48l0.47,0.7l0.48,0.42l0.77,0.29l1.06,0.16l1.21,0.37l2.18,1.01l0.32,0.28l0.85,1.29l-0.17,0.8
  		l-0.8,0.83l-0.8,0.63l-0.8,0.44l-1.14,0.07l-2.46-0.39l-0.96,0.33l-0.52,0.31l-0.26,0.52l0.11,1.89l-0.06,0.45l-0.18,0.36
  		l-0.11,0.47l-0.03,0.57l0.29,0.33l1.53,0.09l1.42-0.52l3.31-1.65l1.85-0.7l0.85-0.96l0.55-0.13l0.79,0.19l1.26,0.58l0.92-0.46
  		l1.26-1.24l0.84-0.61l0.42,0.02l0.75,0.28l-0.44,0.37l-1.25,0.56l-0.31,0.32l0.24,0.3l0.92,0.07l0.61,0.34l-0.08,0.31l-1.33,1.02
  		l0.15,0.22l0.71,0.18l0.53-0.01l0.39-0.17l0.16,0.27l-0.13,0.51l0.4,0.71l1.9,1.87l0.61,0.35l0.67,0.22l0.74,0.08l1.63-0.19
  		l0.2-0.44l-1.28-1.79l0.38-0.21l1.16,0.06l0.7,0.14l0.25,0.23l0.2,0.4l0.15,0.58l-0.02,0.61l-0.44,1.19l-0.33,0.48l-0.05,0.51
  		l0.5,0.97l0.6,0.55l0.88,0.44l0.54-0.05l2.3-1.02l0.19,0.16l-0.4,0.7l0.03,0.62l2.23,2.33l1.34,2.35l1.5,1.93l1.25,2.21l1.88,4.55
  		l0.44,0.5l0.56,0.33l0.12,0.43l-1.22,1.73l-1.01,0.5l-1.54,0.39l-1.02,0.57l-1.33,2.09l-0.24,0.78l-0.09,0.89l-0.4,0.53l-1.41,0.41
  		l-0.67,0.34l-0.64,0.55l-0.62,0.75l-1.29,1.07l-2.94,2.09l-3.72,2.1l-0.35,0.58l-0.15,0.39l0.28,0.59l1.05,1.18l2.05,1.89l1.74,1
  		l0.67,0.54l0.86,1.58l0.7,1.53l0.08,0.8l-1.05,0.01l-1.71-0.48l-0.94,0.03l-1.25,0.19l-0.83,0.27l-1.3,1.19l-0.88,0.36l-0.67,0.11
  		l-0.76-0.04l-1.55-0.31l-1.11,0.11l-0.56,0.24l-0.52,0.45l-0.48,0.65l-1.27,0.13l-3.3-0.56l-0.76,0.25l0.22,0.56l2.26,1.68
  		l0.5,0.49l0.41,0.58l0.33,0.67l-0.05,0.54l-0.43,0.42l-0.18,0.52l0.07,0.61l-0.21,0.19l-0.87-0.51l-0.28-0.35l-0.01-0.31l0.25-0.26
  		l0.5-0.9l-0.15-0.59l-1.08-1.16l-1.52-1.19l-2.01-0.8l-3.49-1.16l-2.5-0.61l-1.51-0.06l-1.14-0.18l-1.32-0.44l-0.64,0.13
  		l-0.26,0.24l-0.59,0.87l-0.28,0.22l-0.01,0.38l0.82,1.49l0.03,0.32l-0.73,0.48l-3.61,1.22l-1,0.69l-0.51,0.54l-0.34,0.6l-0.41,1.8
  		l-0.38,0.76l-0.09,0.51l0.03,0.68l0.19,0.64l1.09,1.61l0.51,0.52l1.63,1.27l0.26,0.09l1.12-0.04l0.85,0.27l1.04,0.59l1.01,0.26
  		l0.98-0.06l1.2,0.19l1.41,0.45l1.11-0.06l0.82-0.57l0.55-0.24l0.56,0.12l0.74-0.14l2.43-0.99l0.73-0.16l1.82,0.05l0.41-0.1
  		l0.44-1.8l1.91-0.22l1.34,0.68l0.95,0.29l1.24,0.17l1.46-0.12l1.68-0.42l0.46-0.82l-0.76-1.23l-0.79-0.95l-0.81-0.66l0.03-0.36
  		l0.87-0.06l0.55-0.15l0.24-0.24l0.32-0.08l0.4,0.09l0.35,0.52l0.31,0.94l0.37,0.64l0.43,0.35l0.38,0.15l1.78,0.07l0.63,0.28
  		l0.27,1.87l-0.01,0.36l0.29,0.26l0.33,0.03l0.37-0.2l0.26-0.38l0.34-1.53l0.36-0.1l0.63,0.26l0.54-0.03l0.85-0.47l1.8,0.39
  		l1.19-0.11l-0.06,0.3l-1.39,1.86l-0.09,0.33l0.65,0.27l0.9,0.62l0.81,0.7l2.2,2.23l1.55,1.17l0.43,0.14l0.33,0.42l0.45,1.19
  		l0.74,1.04l0.41,0.07l1.88-0.98l0.44-0.03l0.13,0.05l-0.11,0.24l-0.35,0.44l-0.63,1.1l-0.01,0.39l0.57,0.26l3.25-0.21l0.95,0.25
  		l0.83,0.58l0.52,0.48l0.2,0.37l0.24,0.16l1.12,0.08l-0.06,0.34l-0.58,0.57l-1.33,0.91l-0.69,0.18l-1.03-0.07l-1.3,0.07l-0.16,0.18
  		l1.15,0.81l2.01,2.23l0.98,0.81l1.3,0.8l0.79,0.31l2.2-1.02l0.39-0.02l-1.26,1.34l-0.08,0.23l0.87,0.32l1.69,0.9l1.94,0.55
  		l1.91,1.02l0.91,0.37l1.39,0.08l0.92-0.25l1.37-0.19l1.64,0.3l0.5,0.31l1.51,2.27l2.03,1.94l0.36,0.25l3.01,1.13l0.76,0.49
  		l0.82,0.34l1.32,0.27l7.9,1.09l0.6,0.22l0.4,0.38l1.19,0.42l3.18,0.78l2.12,1.13l1.07,0.45l0.85,0.27l0.56,0.03l1.12-0.32
  		l0.55-0.27l0.02-0.7l-0.51-1.12l-0.14-0.63l0.89-0.45l0.19-0.28l0.2-0.66l-0.3-0.28l-0.67-0.21l-1.57-0.74l-0.64-0.64l-0.85-1.46
  		l-0.6-0.61l-0.96-1.21l-0.58-0.39l-1.75-0.61l-0.49-0.3l-1.96-1.88l-0.47-0.19l-0.83-0.75l-0.86-0.45l-2.26-0.81l0.11-0.32
  		l0.5-0.52l-0.09-0.28l-1.02-0.06l-0.99-0.21l-1.9-0.78l-0.56-0.56l-1.4-1.83l-1.53-1.42l-0.97-0.73l-1-1.09l-1.03-1.45l0.44-0.65
  		l1.9,0.14l1.17,0.21l2.11,1.18l3.42,2.51l0.67,0.39l0.31-0.04l-0.17-0.89l-0.66-1.95l-0.6-1.32l0.41,0.26l1.14,1.08l1.8,2.04
  		l1.97,1.88l0.68,0.47l1.36-0.15l0.15,0.15l0.21,0.66l0.43,0.37l0.77,0.35l0.49,0.52l0.21,0.68l0.21,0.41l0.22,0.14l0.51-0.14
  		l0.05-0.52l-0.24-1.54l0.04-0.4l0.46,0.61l0.88,1.61l0.69,0.93l1.02,0.4l0.54,0.08l0.23-0.2l-0.21-0.93l-0.2-0.42l0.21,0.01
  		l2.06,1.41l0.49,0.43l0.47,0.63l0.26,0.17l0.24-0.04l0.22-0.25l0.33-0.02l1.41,0.83l1.48,1.06l1.1,0.96l0.72,0.86l0.6,0.3
  		l0.49-0.26l0.03-0.73l-0.98-3.05l0.25-0.26l1.77,0.83l0.46,0.12l0.98-0.1l0.57-0.2l0.1-0.26l-0.39-0.31l-0.41-0.61l-0.44-0.9
  		l-0.63-0.87l-1.3-1.45l-0.17-0.38l0.09-0.26l0.35-0.13l0.1-0.54l-0.22-1.42l-0.49-2.21l-0.37-1.11l-0.42-0.75l-0.07-0.26l1.7,1.62
  		l0.48,0.59l0.4,0.68l1.61,3.73l0.26,0.31l0.65,0.03l0.61-0.28l0.14-1.24l0-1.4l-0.44-1.47l-0.09-1.43l0.67-0.1l0.6-0.26l-0.74-1.13
  		l-0.66-1.44l-0.51-0.28l-1.11,0.17l-0.25-0.14l0.94-0.6l0.14-0.65l-0.07-0.25l-0.92-0.06l-1.04,0.09l-0.83,0.23l-1.34-0.26
  		l-0.15-0.13l0.31-0.4l0.01-0.26l-0.36-0.58l-0.75-0.7l-0.55-0.68l-1.96-0.83l-0.11-0.23l0.73-0.1l1.41,0.04l1.07,0.16l1.17-1.81
  		l-0.17-0.65l-0.71-0.34l-2.62,0l-0.33,0.07l0.14-0.29l1.38-0.85l0.63-0.59l-1.32-1.29l-0.68,0.27l-0.78-0.49l-0.18-0.39l-1.19-1.13
  		l-1.44-0.44l-0.77,0.54l-0.65,0.76l-0.38,0.77l-0.52,0.17l-0.57-0.45l-0.16-0.31l0.77-0.69l-0.12-0.35l-1.02-0.76l-1.45-0.85
  		l-0.99-0.39l-0.26,0.36l-0.09,2.19l-0.17-0.02l-0.3-0.61l-0.56-2.19l-0.26-0.75l-0.25-0.24l-0.23-0.03l-0.2,0.17l-0.5-0.05
  		l-0.13-0.27l0.01-0.46l-0.12-0.48l-0.57-0.98l-0.94-0.82l-1.02-0.53l-0.32-0.06l0.09-0.14l1.61-0.88l0.14-0.27l-1.56-0.59
  		l-0.23-0.36l-0.14-0.56l-0.45-0.54l-0.76-0.52l-0.67-0.24l-0.58,0.04l-0.67,0.21l-1.59,0.86l-0.26-0.02l-0.21-0.24l0.06-0.57
  		l0.74-2.09l-0.23-0.27l-0.62-0.06l-1.24-0.42l-0.6-0.6l-0.59-0.94l-0.03-0.78l0.53-0.62l-0.16-0.56l-2.1-1.32l-0.88-0.22l-1.2-0.05
  		l-0.29-0.11l1.86-0.68l0.57-0.01l2.63,1.67l1.59,1.62l1.1,0.68l2.34,0.61l1.28,0l0.41-0.11l0.65-0.36l0.75-1.1l-0.96-0.78
  		l-2.11-1.36l-1.32-1.14l-0.81-1.39l-0.7-1.41l0.13-0.32l1.08,0.45l1.52,0.81l1.61,1.17l0.49,0.19l0.72-0.09l0.3-0.16l-0.02-1.56
  		l-1.06-1.23l0.09-0.7l0.99,0.52l0.95,0.11l0.91-0.31l-0.01-0.49l-0.37-0.57l0.24-0.07l1.04,0.45l0.64,0.56l0.23,0.66l0.4,0.68
  		l1.98,2.54l0.49,0.35l1.19,0.36l0.58,0.32l1.22,1.03l1.04,0.07l0.71-0.21l0.82-0.41l0.59-0.59l0.27,0.12l-1.69,1.66l-0.29,0.92
  		l0.55,0.33l2.38,0.14l1.08-0.1l0.93-0.19l2.53-0.97l0.99-0.83l1.06-1.31l0.89-0.79l1.27-0.54l0.5,0.12l-1,0.63l-0.92,1.11
  		l-0.84,1.6l-0.68,0.87l-1.5,0.38l-1.29,0.68l-0.78,0.69l-0.85,0.95l-0.2,0.81l0.54,0.75l0.22,0.11l0.25,0.35l0.63,1.21l0.26,0.3
  		l0.57,1.44l1.09,1.22l1.12,1.04l0.69,0.9l0.84,0.9l0.93,0.72l0.72-1.87l1.09-1.54l0.46-0.5l0.14-0.01l-0.34,1.05l-0.3,2.09l0.8,0.8
  		l0.83,0.66l0.76-0.77l0.72,0.01l0.68,0.17l0.53,0.36l0.37,0.44l0.44,0.85l1.1,0.84l1.02-1.28l0.71-1.33l0.32-1.53l-0.01-4.33
  		l0.23-1.02l-1.45-0.27l-1.19-0.35l-0.01-0.19l1.95-0.48l0.09-0.66l-0.46-1.11l0.05-0.25l1.84,2.19l0.6,0.53l1.68,0.48l1.28-0.35
  		l0.38,0.22l0.95-0.11l0.41-1.16l1.05-1.12l0.87-0.4l-0.05-0.49l-0.19-0.52l-0.32-0.55l-0.41-0.4l-1.07-0.59l0.28-0.25l1.03,0.24
  		l1.88,0.16l0.89-0.08l1.24-0.33l-0.27-0.45l-1.84-1.12l-2.46-1.11l0.32-0.62l0.64-0.25l0.12-0.38l-1.24-1.28l0.36-0.15l1.14,0.09
  		l0.82,0.27l1,0.75l1.7,0.4l0.79-0.05l2.46-0.84l-0.05-0.56l-0.64-1.28l-3.68-1.73l-0.29-0.24l0.92-0.43L1288.31,654.18z
  		 M1040.79,404.62l1.6-0.42l2.27-0.98l1.75-0.31l1.23,0.35l1.26,0.14l4.29-0.07l-0.2,0.47l-5.97,2.98l-0.47,0.7l1.62,0.49l0.95,0.09
  		l11.16-2.83l4.21-0.47l3.18,0.12l4.25,0.66l-0.17,0.33l-4.29-0.19l-4.17,0.35l-2.28,0.55l-4.5,1.74l-2.08,1.18l0.32,0.53l3.6,1.04
  		l-0.45,0.22l-2.53,0.25l-3.23,1.08l-0.28,0.96l1.59,1.19l1.43,0.64l1.89,0.14l4.31-0.66l13.57-0.44l5.49-0.83l6.3-2.37l3.98-0.88
  		l1.65-0.19l2.23,0.17l-1.2,0.79l-6.12,2.46l-5.62,1.75l-3.81,0.7l-3.87,0.24l-8.63-0.23l-6.35,1.16l-0.81,0.54l0.43,0.64l0.57,0.56
  		l0.7,0.48l1.18,0.47l2.5,0.7l3.31,1.31l1.92,0.56l2.46,0.38l3.22-0.27l2.1-1.11l7.13-4.43l2.25-1.23l1.57-0.46l9.65-1.26
  		l11.34-0.99l-0.51,0.89l-11.21,1.21l-4.77,1.08l-3.31,1.26l-5.16,2.67l-1.59,1.21l-1.37,1.26l-0.1,0.67l0.71,0.5l2.37,0.76
  		l1.29,0.07l3.12-0.37l3.53,1.08l1.36,0.09l6.13-0.11l1.67-0.22l1.21-0.36l1.61-0.89l2.01-1.42l2.07-0.79l5.23-0.43l2.37-0.53
  		l2.33-0.19l-0.96,0.86l-3.63,1.93l-0.98,1.04l2.28,0.37l2.06-0.05l6.87-1.21l2.12-0.09l2.48-0.54l7.36-2.15l3.13-1.54l1.3-0.79
  		l0.52-0.55l0.57-1.02l0.63-1.51l0.78-1.04l0.93-0.57l1.06-0.2l1.19,0.17l1.66-0.63l3.22-2.16l3.2-1.44l9.16-2l-1.23,0.9l-4.26,1.3
  		l-6.32,3.07l-1.49,1.06l-1.39,1.41l-0.44,0.84l-0.29,0.98l1.39,0.6l4.61,0.33l5.17-0.01l2.28,0.18l2.26,0.36l-0.11,0.26l-2.58,0.43
  		l-2.85,0.16l-7.4,1l-10.47,2.69l-0.38,0.44l-3.17,1.9l-7.82,1.63l-13.11,1.36l-2.09,0.42l-1.7,0.61l0.22,0.87l2.36,2l2.94,2.1
  		l3.98,2.11l1.82,1.29l2.41,1.97l1.51,1.04l1.42,0.77l2.49,0.35l3.59-0.08l1.37,0.18l-1.23,0.65l-1.73,0.55l-1.62,0.1l-3.12-0.25
  		l-3.58-0.76l-1.9-0.6l-1.41-0.7l-1.63-1.15l-2.76-2.41l-2.53-1.63l-2.75-1.19l-2.86-1.79l-3.2-1.59l-2.8-0.64l-5.21-0.33
  		l-4.08,0.14l-9.67-0.93l-1.61,1.19l-0.98,2.61l1.01,2.04l0.62,3.33l2.31,1.96l0.97,0.4l4.84,0.61l1.59,0.49l1.51,0.99l2.13,2.24
  		l2.64,2.24L1099,449l1.55,1.44l1.85,1.17l2.66,1.38l0.73,0.68l-2.1-0.1l-1.92-0.75l-1.72-0.44l-1.07,0.07l-1.24,0.33l-0.32,0.56
  		l0.61,0.8l0.71,0.6l0.81,0.4l1.43,0.29l3.09,0.27l6.06,0.11l3.49,0.6l1.73,0.07l1.7-0.38l1.76-0.71l1.17-0.24l1.17-0.02l-0.26,0.48
  		l-1.69,0.97l-1.43,0.64l-1.18,0.32l-1.27,0.12l-4.62-0.07l-1.33,0.68l-1.04,0.7l-0.99-0.17l-1.33-0.47l-3.04-0.65l-7.41-0.85
  		l-1.82-0.52l-1.9,0.19l-3.89,1.09l-4.64,0.37l-4.77,0.92l-1.22,2.06l-1.78,1.97l-1.66,1.46l-1.1,1.14l0,2.45l-0.51,1.98l1.79,0.81
  		l3.59,0.11l1.85-0.44l2.25-0.84l3-1.61l1.27-1.07l-1.2,2.87l-1.31,1.94l5.32-0.52l1.41-0.6l1.25-1.05l2.07-2.08l2.03-3.97
  		l-1.07,5.31l3.04-0.21l1.36,0.83l1.39,0.56l-2.54-0.39l-0.77,0.03l-3.5,2.45l-1.97,0.94l-2.38,1.51l2.15,2.98l0.02,3.71l1.7,0.79
  		l1.91,0.29l3.16-1.13l2.68,0.71l2.02,0.08l1.25-0.26l2.96-1.6l1.05-0.95l5.06-5.24l0.91-0.54l-0.26,1.02l-0.32,0.87l-0.38,0.72
  		l-1.62,2.12l-2.93,2.68l-1.91,1.28l-0.61,0.56l-0.49,0.73l-0.95,0.5l-2.12,0.43l-4.32,0.41l-2.12,0.12l-1.79-0.25l-5.37-1.55
  		l-1.2-0.86l-1.48-1.9l-2.24-2.39l-1.79-1.13l-3.12-1l-2.21-0.52l-1.84,0.01l-2.2,0.36l-2.19,0.65l-2.19,0.94l-0.65,1.19l1.32,2.16
  		l1.33,1.93l0.83,1.02l0.78,0.75l1.34,0.84l1.38,0.09l0.7,0.2l0.69,0.44l1.91,1.92l0.52,0.27l-0.34,0.19l-1.79,0.15l-2.5,0.53
  		l-2.1,0.17l-1.84-0.17l-2.69,0.22l-2.11,0.36l-1.33,0.57l-1.81,1.4l-6.14,2.95l-0.38,2.95l-0.22,2.95l1.69,0.31l4.77,0.31
  		l2.17,0.63l-0.14-2.22l-0.43-1.82l1-2.15l0.12,3.4l0.72,3.07l2.46-0.13l5.11,0.46l0.07-3.5l1.14,2.42l3.17,0.62l2.51,0.63l1-2.45
  		l0.91-1.2l0.29,0.09l0.44,0.54l0.59,1l1.52,1l3.67,1.5l2.84,0.62l1.7,0.15l7.29-0.91l0.43-5.6l2,3.17l0.85,0.73l4.18,0.24
  		l9.73-0.47l0.21-0.84l-0.45-1.07l-0.8-1.08l-1.15-1.09l-0.31-0.45l1.15,0.59l0.69,0.61l0.79,0.27l0.88-0.06l0.67,0.24l1.78,1.91
  		l0.89,0.45l1.06,0.19l0.99-0.06l0.92-0.3l1.63-0.14l0.81,0.25l0.84,0.5l1.2,1.08l-0.02,0.69l-0.56,0.83l-0.45,0.9l-0.35,0.96
  		l0.28,0.54l1.37,0.18l0.92-0.05l4.24-1.11l1.96-0.19l3.73-1.03l1.9-0.77l1.31-0.86l1.65-0.83l5.47-2.11l1.39-1.28l1.14-1.96
  		l-0.13-1.72l-1.4-1.47l-1.04-0.76l-0.68-0.06l-0.72,0.25l-1.71,1.05l-1.12,0.43l-1.58-0.16l-2.03-0.77l-0.83-0.79l0.38-0.81
  		l-0.06-1.06l-0.5-1.31l-1.32-0.67l-3.59,0.04l-2.48,0.84l-0.47-0.08l-3.35-1.67l-1.06-0.27l-2.69-0.18l-1.35,0.22l-2.16,0.76
  		l-1.87,0.19l-0.71-0.18l-1.07-0.6l-0.83-0.64l0.08-0.23l2.44,0.01l2.06-0.27l1.87-0.6l0.2-0.38l-0.85-0.36l-1.06-0.8l-1.27-1.24
  		l-0.04-0.48l1.18,0.29l1.2,0.49l4.23,2.22l2.54,0.79l2.45,0.24l3.16,0.03l6.48-0.56l2.26-0.03l1.36-0.17l1.81-0.49l1.76-0.78
  		l0.97-0.81l0.77-1.1l0.24-1.74l-0.29-2.39l-0.03-1.79l0.23-1.19l0.37-0.63l4.69-0.02l4.06,0.36l2.24-0.19l2.97-0.97l2.34,0.07
  		l0.91-0.11l0.87-0.3l2.66-3.87l3.01-1.96l-0.37-0.52l-2.12-0.88l-5.46-1.63l-2.35-0.37l0.36-0.18l3.43-0.16l4.79,0.13l4.36-0.41
  		l2.8-1.4l0.1-0.72l0.86-1.21l-0.45-0.48l-1.11-0.14l-4.04-1.88l-2.54-0.43l-3.33-0.16l-1.33-1.3l-1.05-0.49l-1.5-0.41l-2.26-0.33
  		l-2.53,0.12l-5.77,0.74l-1.58,0.45l-1.55,0.23l-1.3-0.4l-1.56-0.96l-1.68-0.62l-1.16-0.12l-0.19-0.38l2.73-0.13l2.39,0.11
  		l2.06,0.36l2.79-0.01l5.28-0.56l2.02,0.01l1.27-0.33l1.88,0.07l2.07-0.33l2.3,0.56l1.05,0.5l2.37,0.48h4.99l0.91-0.32l0.43-3.33
  		l-2.07-0.11l-3.09,0.59l-2.19-0.46l-2.1-0.21l-2.9-1.36l-1.42-0.28l-1.51-1.66l-3.16-1.22l-1.55-0.19l-0.37-0.46l4.4,0.34l2.6,0.4
  		l2.47,0.8l2.28,0.32l2.05-0.13l3.89-0.71l3.3,0.1l1.83-0.21l1.46-0.49l1.26-0.21l3.37,0.12l0.88-0.17l0.57-0.33l0.45-0.59
  		l0.33-0.84l-0.24-0.91l-1.49-1.69l-0.56-0.46l-1.43-0.28l-3.44-0.14l-4.12-0.72l-1.01-0.38l2.24-0.75l2.11-0.11l2.86,0.64
  		l3.01,0.37l8.52,2.54l1.86,0.14l2.11-0.27l4.86-1.16l0.75-0.4l1.59-1.25l0.03-0.53l-1.44-0.59l-0.66-0.09l5.04-1.68l1.67-1.41
  		l-0.08-0.44l-1.6-0.48l-1-0.07l-6.22,0.99l-2.8-0.29l-0.96-0.37l0.06-0.34l1.08-0.31l1.11-0.08l1.14,0.14l1.61-0.19l3.11-0.8
  		l7.04-0.9l0.44-0.86l-3.83-4.45l-0.38-0.67l0.62,0.24l1.99,1.34l2.18,1.2l1.64,0.47l1.82,0.13l1.54-0.32l1.27-0.77l3.71-3.21
  		l2.77-1.81l7.21-3.57l8.82-3.6l3.49-2.1l6.98-2.77l5.48-3.09l0.44-1.09l-3.87-0.34l-4.18,0.31l-21.75,4.68l-1.89,0.27l-1.5-0.27
  		l0.28-0.64l14.95-3.86l0.45-0.27l0.51-0.57l0.3-0.73l1.17-1.08l5.23-0.26l2.56-0.33l1.73-0.46l0.81-0.96l1.46-0.57l5.49,0.2
  		l1.16-0.18l2.59-1.04l4.51-1.06l9.23-3.3l2.69-0.75l1.75-1.37l2.98-1.53l2.88-1.97l0.56-1.27l-0.8-1.22l-0.76-0.87l-0.71-0.53
  		l-1.85-0.44l-6.55-0.64l-6.5,1.44l-0.03-0.35l1.38-1.37l0.01-0.68l-2.53-1.84l-1.75-0.85l-0.42-0.4l0.18-0.34l0.48-0.41l0.77-0.47
  		l-0.22-0.45l-1.2-0.42l-2.89-0.35l-1.27,0.12l-2.52,0.96l-0.59-0.01l-1.1-0.84l-1.2-1.22l-1.08-0.51l-0.67-0.03l-1.09,0.28
  		l-0.41,0.4l-0.71,1.09l-0.44,0.4l-0.85-0.06l-1.26-0.52l-1.5-0.33l-3.31,0.74l-4.14,1.37l-2.14,0.49l-1.11,0.06l-3.36,1l-2.85,0.33
  		l-3.68,0.14l-2.5-0.16l0.94-0.49l12.81-2.96l1.99-0.91l1.48-0.95l0.02-0.44l-1.42-0.36l-6.86-0.21l-1.84-0.22l-2.53,0.18
  		l-1.54-0.11l-2.55-0.93l-2.23,0.14l-6.87-0.38l-0.68-0.17l-1.79-1.43l-0.72-0.36l-0.86-0.14l-7.59,0.38l-1.8,0.33l-2.85,1.3
  		l0.18,0.42l1.72,0.67l2.23,1.23l-0.06,0.19l-1.61-0.44l-2.31-1.09l-4.86-2.68l-0.72-0.1l-6.25,0.53l-4.38,1.75l-0.61,0.46
  		l-0.31,0.66l-0.01,0.87l1.75,1.28l3.86,1.86l0.99,0.71l-5.17-1.05l-3.63-1.69l-1.8-1.1l-1.17-1.08l-1.19-0.71l-1.82-0.51
  		l-11.21-0.72l-11.62,0.82l0.7,0.87l1.12,1.02l4.2,2.18l1.87,1.22l0.85,0.72l3.74,1.68l0.65,0.73l-1.51,0.75l-2.23,0.78l-1.15-0.31
  		l-0.82-1.13l-0.77-0.84l-0.72-0.55l-6.78-3.49l-2.14-0.97l-1.17-0.27l-2.95-0.22l-4.69,0.32l-5.52-0.89l-5.94-0.11l-2.26,0.58
  		l0.12,0.36l1.41,0.74l1.18,0.89l1.62,0.66l3.66,1.09l3.5,0.81l0.36,0.3l-2.41,0.1l-8.77-0.66l-4.9-1.32l-2.97-0.21l-0.99,0.36
  		l-0.42,0.36l0.26,0.61l2.84,2.7l-0.44,0.31l-2.75-0.48l-3.29-1.01l-1.73-0.13l-2.79,0.43l-0.05,0.56l1.38,0.8l2.34,0.93l-0.31,0.26
  		l-2.57,0.25l-2.07,0.62l-1.54,0.78l0.03,0.67l1.63,1.22l2.16,1.2l4.46,1.77l11.27,3.37l4.21,1.97l1.72,1.59l-0.34,0.07l-1.85-0.71
  		l-1.88-1.03l-2.06-0.85l-3.34-0.55l-8.71-2.38l-5.83-2.02l-2.19-0.59l-0.85,0.03l-0.59,0.35l-0.33,0.67l0.76,0.79l2.78,1.35
  		l-0.24,0.54l-2.34-0.23l-1.18-0.36l-1.99-0.96l-1.39-0.94l-3.49-2.88l-1.96-0.5l-4.59-0.48l-1.56-0.51l-1.61-0.81l-1.28-0.25
  		l-3.19,0.92l-1.73,0.81l-2.64,1.55l-1.06,0.18l-2.25,0.73l-3.61,0.61l0.29,0.65l10.7,2.94l1.19,0.42l0.99,0.59l0.06,0.24
  		l-3.02,0.01l-1.14,0.56l-0.91,0.03l-1.93-0.46l-2.39-1.02l-1.85-0.4l-2.1-0.12l-1.75,0.36l-1.39,0.84l-0.16,0.68l-1.68-0.85
  		l-1.57-1.11l-1.98-0.63l-3.57-0.23l-4.24,0.73l-2.6,0.88l-2.37,1.28l-1.89,0.79l-2.13,0.45l-4.46,0.01l-2.76,0.34l-3.8,1.02
  		l-2.34,0.81l-1.72,0.88l-1.88,1.23l-0.31,0.98l2.37,1.16L1040.79,404.62z M1144.91,618.79l-1.81,0.53l-0.82,0.57l-0.53,0.84
  		l-0.42,0.54l-0.31,0.24l-0.12,0.25l0.13,0.49l0.25,0.48l0.69,0.28l0.58-0.1l1.32-0.55l1.69-0.48l0.62-0.51l0.62-0.93l0.92-0.97
  		l0.3-0.4l0.07-0.33l0.16-0.26l0.79-0.79l1.28-0.94l0.49-0.61l-0.04-0.16l-0.32-0.69l-0.24-0.26l-0.27-0.11l-1.06-0.05l-1.17,0.28
  		l-0.45,0.29l-0.33,0.39v0.38l0.33,0.37l-0.1,0.19l-0.92,0.15l-0.27,0.25l-0.81,1.48L1144.91,618.79z M1137.65,774.7l0.14-0.8
  		l0.36,0.08l0.13-0.1l0.03-0.48l-0.11-0.6l-0.13-0.04l-0.21,0.11l-0.36,0.48l-0.07,0.21l0.03,0.29l-0.19,0.42l0.25,0.49
  		L1137.65,774.7z M1136.76,781.74l-0.4,0.46l0.02,0.6l0.41,0.24l0.23-0.21l0.27-0.5l0.07-0.33l-0.29-0.25L1136.76,781.74z
  		 M1139.66,784.4l0.3-0.15l0.18-0.13l0.1-0.23l0.02-0.33l-0.07-0.34l-0.17-0.35l-0.35-0.33l-0.15,0.04l-0.11,0.18l0.02,0.19
  		l0.26,0.38l-0.22,1.06L1139.66,784.4z M1135.87,721.44l0.91,0.94l0.68,0.46l0.44-0.02l0.41-0.21l0.38-0.41l0.48-0.84l0.59-1.28
  		l0.67-1.12l0.76-0.96l0.41-0.76l0.15-1.01l0.29-1.08l-0.12-0.81l-0.42-0.6l-1.1-1.19l-0.67-0.36l-0.44-0.02l-0.44,0.2l-0.53,0.04
  		l-1.31-0.12l-0.49,0.15l-0.8,0.65l-1.32,1.7l-0.47,0.78l-0.21,0.57l-0.13,0.71l-0.04,0.85l0.03,1.7l0.09,0.52l0.51,0.53l0.95,0.39
  		L1135.87,721.44z M1135.57,823.57l0.3,0.2l0.55,0.07l0.01-0.34l-0.31-0.55l-0.63-0.14l-0.2,0.23l0.05,0.23L1135.57,823.57z
  		 M1145.79,790.35l0.55-0.35l0.43-0.46l0.31-0.57l0.13-0.68l-0.1-1.45l-0.47-0.01l-0.31,0.22l-0.32,0.45l-0.19,0.52l-0.06,0.59
  		l-0.24,0.69l-0.42,0.78l-0.06,0.4l0.31,0.02L1145.79,790.35z M1133.5,747.91l0.38-0.26l0.48-0.48l0.13-0.32l-0.36-0.2l-0.39,0.57
  		l-0.36,0.06l-0.22,0.51l0.06,0.15L1133.5,747.91z M1136.09,789.62l2.44-1.43l0.47-0.71l0.44-0.95l-0.22,0.09l-0.63,0.66l-0.57,0.33
  		h-0.52l-0.35,0.11l-0.19,0.22l-1.1,0.36l-0.44,0.36l-0.26,0.88l0.51,0.17L1136.09,789.62z M1136.33,745.58l0.43-0.54l-0.48-0.3
  		l-0.72,0.09l-0.36,0.23l-0.32,0.36l-0.38,0.74l0.87-0.09L1136.33,745.58z M1149.61,733.6l0.79-0.35l0.31-0.44l-1.33,0.13
  		l-1.79,0.46l-0.48,0.52l0,0.18l1.16-0.15L1149.61,733.6z M1092.64,666.2l0.34,0.9l0.21,0.26l1.19,0.67l0.52,0.54l0.33,0.71l0.4,0.4
  		l0.47,0.09l0.44-0.16l0.41-0.4l0.2-0.38l0-0.36l-0.75-1.89l-0.55-0.91l-0.94-1.15l-0.17-0.43l-0.26-0.35l-0.16-0.16l-0.78-0.24
  		l-1,0.14l-0.22,0.6l0.01,0.4l0.25,0.81L1092.64,666.2z M1104.79,665.24l-0.06-0.41l-0.72-0.64l-1.38-0.86l-1.45-0.7l-2.4-0.74
  		l-0.41,0.35l-0.09,0.25l0.14,0.33l0.68,0.73l0.84,0.3l0.4,0.29l0.25,0.39l0.42,0.36l0.11,0.54l0.1,1.47l0.92,0.28l0.59-0.1
  		l1.06-0.4l0.23,0.24l0.71,0.2l-0.81,0.64l-0.09,0.44l0.18,0.13l1.16,0.15l0.16-0.06l-0.12-0.22l0.07-0.18l0.25-0.14l0.2,0.04
  		l0.39,0.41l0.35,0.18l0.96,0.36l0.41-0.02l0.58-0.51l0.25-0.4l0-0.26l-0.25-0.11l-1.74-0.17l-1.08-0.31l-1.08-0.55l-0.33-0.48
  		l0.42-0.41L1104.79,665.24z M1133.36,694.31l0.34-0.53l-1.59-0.82l-1.83-0.52l0.75-0.1l0.09-0.31l-0.33-0.54l-0.73-0.71l-1.13-0.88
  		l-0.78-0.14l-0.71,0.91l-0.16,0.03l-0.68-0.08l-1.95-0.52l-3.2,0.73l-1.44,0.07l-0.13-0.2l1.53-1.18l0.34-0.36l0.11-0.34
  		l-0.07-0.57l-0.94-2.89l-1.19-1.81l-1.02-1.15l-0.92-0.63l-0.95-0.42l-2.65-0.56l-0.69-0.25l-2.72-1.69l-1.77-0.76l-0.18-0.11
  		l-1.56-1.86l-0.7-0.38l-1.95-0.51l-1.5-0.17l-1.56-0.5l-0.41-0.38l-0.39-0.98l-0.73-0.7l-1.98-1.26l-0.56-0.03l-0.38,0.18
  		l-1.34,1.96l-0.6,0.68l-0.36,0.1l-0.29-0.17l-1.15-2.43l-1.55-1.01l-0.02-0.22l0.14-0.27l0.81-0.64l0.21-0.42l-0.21-0.97
  		l-0.39-0.75l-0.55-0.68l-1.7-0.7l-0.45-0.75l-0.23-0.21l-0.27-0.06l-1.22,0.49l-0.97,0.71l-1.24,1.77l-0.47,0.89l-0.48,1.47
  		l-0.33,1.61l-0.62,5.6l-0.33,1.56l-0.98,3.19l-0.26,1.31l-0.01,0.85l0.17,1.72l0.68,1.87l0.19,0.77l0,0.6l-0.48,0.58l-0.95,0.57
  		l-3.91,1.72l-0.39,0.3l-0.84,0.96l-1.03,1.55l-0.29,0.57l-0.04,0.53l0.14,0.5l0.21,0.13l0.83,0.19l1.16,0.04l0.58-0.08l2.28-1.16
  		l2.31,0.07l4.18-0.67l0.3,0.08l0.25,0.22l0.21,0.35l1.25,5.16l0.59,1.75l0.87,0.26l1.3-0.26l2.33-0.77l1.4-0.66l1.37-0.83
  		l0.66-0.54l0.41-0.53l1-1.85l0.67-0.76l0.4-0.2l1-0.18l1.01-0.62l2.47-2.06l0.56-0.79l0.2-0.61l0.18-1.29l0.28-0.6l0.75-0.55
  		l1.6-0.6l1-0.19l1.01-0.02l0.41,0.43l-0.19,0.88l0.04,0.51l0.88,0.31l3.02,0.53l0.88,0.45l0.47,2.56l0.28,0.4l1.96,0.21l1.54,0.36
  		l4.98,1.69l1.62,0.73l1.12,0.29l0.27-0.01l0.51-0.24l2.04-1.55l1.75-1.04L1133.36,694.31z M1108.83,659.83l0.56,0.6l0.74,0.49
  		l0.41-0.13l0.67-0.69l-0.14-0.2l-0.64,0.02l-0.84-0.17l-0.83-0.75l-0.13,0.07l0.04,0.4L1108.83,659.83z M1144.72,630.12l-1.69,0.26
  		l-0.18,1.03l0.01,0.43l0.93,0.77l0.68-0.16l1.3-1.1l-0.34-0.61L1144.72,630.12z M1001.25,482.53l13.29,0.18l4.82,0.4l0.81-0.22
  		l1.65-0.04l0.21-0.14l1.52-2.8l1.77-0.55l-0.69-0.91l-0.76-0.54l-1.43-0.44l-0.94-0.12l-3.64,0.2l-5.49-0.3l-2.47,0.04l-2.3,0.37
  		l-2.12-0.69l-1.68,0.18l-3.87,1.23l-0.43,0.25l-0.38,0.51l-0.32,0.76l0.11,0.65l1.75,1.64L1001.25,482.53z M1119.36,706.74
  		l0.13-0.74l-0.1-0.56l-0.34-0.37l-0.4-0.22l-0.69-0.09l-0.89,0l-1.89,0.55l-2.08-0.11l-2.18,0.93l-0.51,0.02l-0.71-0.16l-1.5-0.49
  		l-0.73,0.22l-3.05,4.43l-1.35,1.21l-0.09,0.29l-0.02,0.37l0.08,0.3l1.2,1.3l0.27,0.61l0.12,1.09l0.13,0.17l2.71-1.02l1.05,0.07
  		l1.03,0.38l0.49,0.05l0.47-0.12l3.77-2.53l0.65-0.57l0.87-0.95l2.31-1.75l0.74-1.02l0.3-0.61L1119.36,706.74z M1147.17,613.14
  		l0.92,0.17l0.69-0.16l1.37-0.74l2.25-0.85l0.6-0.46l-0.07-0.39l-0.43-0.75l-0.98-0.37l-0.47,0l-0.52,0.34l-0.04,0.45l-0.24,0.3
  		l-0.41-0.12l-0.49,0.36l-1.49,0.18l-1.78,1.73l-0.5,0.6l0.45,0.05L1147.17,613.14z M1189,907.81l1.04-1.14l-1.28,0.24l-1.12,1.03
  		l0.35,0.09L1189,907.81z M1113.55,946.36l-0.17,0.22l-0.09,0.49l0.01,0.21l0.39-0.11l0.09-0.24l-0.13-0.45L1113.55,946.36z
  		 M1120.43,908.87l0.04,0.12l0.15,0.1l0.23-0.03l0.51-0.36l0.12-0.27l-0.19-0.04l-0.79,0.34L1120.43,908.87z M1111.17,905.45
  		l1.69,0.15l0.92,0.26l0.97,0.47l0.85,0.31l0.73,0.15l0.57,0.25l0.4,0.34l0.98,0.38l0.13,0.22l0.17,0.09l0.22-0.05l1.75-1.31
  		l0.52-0.06l0,0.13l-0.88,0.78l-0.32,0.08l-0.17-0.01l-0.46,0.35l0,0.09l0.26-0.05l0.27,0.09l0.28,0.23l0.45-0.12l0.62-0.46
  		l0.58-0.69l0.78-1.41l-0.07-0.1l-0.7,0l-0.11-0.14l0.22-0.31l0.07-0.29l-0.08-0.26l-0.2,0.03l-0.31,0.32l-0.24,0.45l-0.28,0.85
  		l-0.07-0.03l-0.29-0.47l-0.03-0.36l0.11-0.41l-0.1-0.24l-0.32-0.07l-0.15-0.19l0.02-0.3l-0.12-0.24l-0.25-0.18l-0.41,0.02
  		l-0.57,0.23l-0.44,0.38l-0.3,0.52l-0.28-0.02l-0.25-0.56l-0.26-0.23l-0.28,0.1l-0.17-0.1l-0.06-0.3l-0.32-0.09l-0.58,0.12
  		l-0.37,0.17l-0.16,0.22l-0.15,0.04l-0.15-0.14l-0.25,0.06l-0.35,0.25l-0.1,0.2l0.2,0.27l-0.03,0.11l0.33,0.11l0.05,0.16l-0.14,0.29
  		l-0.19-0.01l-0.25-0.31l-0.21-0.11l-0.16,0.09l-0.15-0.04l-0.13-0.18l-0.26-0.03l-0.39,0.11l-0.23-0.06l-0.08-0.23l-0.16-0.18
  		l-0.25-0.13l-0.08-0.28l0.09-0.42l-0.2-0.15l-0.5,0.13l-0.34,0.16l-0.17,0.18l-0.25,0.02l-0.33-0.15l-0.29,0.03l-0.24,0.2
  		l-0.23-0.03l-0.22-0.27l-0.21-0.06l-0.21,0.15l-0.13,0.23l-0.05,0.32l0.52,0.28l1.67,0.44L1111.17,905.45z M1108.14,903.71
  		l-0.41-0.17l-0.4,0.05l-0.38,0.26l-0.25,0.29l-0.11,0.31l0.22,0.24l0.56,0.16l0.46-0.12l0.53-0.69l-0.02-0.15L1108.14,903.71z
  		 M1163.18,922.48l-0.33,0.18l-0.09,0.16l0.19,0.1l0.47,0.04l0.37-0.11l0.4-0.45l-0.03-0.18L1163.18,922.48z M755.25,860.04
  		l0.09-0.58l0.6-0.73l0.33-0.63l-0.15-0.52l-0.3-0.58l-0.11-0.21l-0.48-0.55l-0.55-0.31l-0.34,0.04l-0.37,0.66l-0.11,0.47l0.83,2
  		L755.25,860.04z M1251.19,921.25l-0.1,0.35l0.42-0.36l0.53-1.03l-0.34,0.13L1251.19,921.25z M1166.82,921.79l-0.86,0.13l-0.18,0.25
  		l-0.27,0.25l-0.07,0.17l0.13,0.09l0.02,0.12l-0.09,0.14l0.13,0.03l0.39,0.03l0.49-0.36l0.1-0.2l0.19-0.33l0.78-0.26l0.08-0.22
  		l-0.44,0.08L1166.82,921.79z M1246.7,916.36l-0.44,1.4l1.13-0.55l0.08-0.29l0.07-0.85l-0.48-0.14L1246.7,916.36z M756.67,859.05
  		l0.21,1.25l0.2-0.1l0.58-0.46l0.01-0.14l-0.08-0.52l-0.18-0.38l-0.45-0.56l0.03,0.23l-0.07,0.23L756.67,859.05z M770.6,872.68
  		l-0.05-0.24l-0.88-0.5l-1.74-1.52l0.37,0.61l0.88,1.01l1.32,0.77L770.6,872.68z M1103.13,900.48l-0.67-0.2l-0.76-0.07l-0.3,0.23
  		l0.16,0.54l0.33,0.62l0.51,0.71l0.41,0.19l0.2-0.06l0.16-0.23l0.1-0.31l0.16-0.1l0.23,0.1l0.17-0.2l0.12-0.5l-0.23-0.41
  		L1103.13,900.48z M760.95,863.97l0.2,0.21l0.31,0.36l0.8,0.5l1.87,0.85l-0.12-0.23l-1.31-1.49l-0.95-0.66l-1.06-0.41l-0.64-0.11
  		l-0.22,0.18l0.16,0.25L760.95,863.97z M1304.22,904.72l1.46-0.64l0.12-0.25l-0.89-0.57l0.45-0.44l0.27-0.54l-0.01-0.3l-0.13-0.19
  		l-0.81-0.33l-1.1-0.16l-1.13,0.11l-0.15-0.78l-0.46-0.43l-0.66,0.28l-0.46,0.3l-0.62,0.54l-0.68,0.92l0.11,0.58l-1.35,1.1
  		l0.24,0.25l2.06-0.38l-0.1,0.23l-1.91,1.82l-0.33,0.23l-0.57,0.14l-0.6-0.11l-0.44,0l-1.08,0.46l-0.28-0.47l0.34-0.49l0.68-0.45
  		l0.01-0.19l-0.56-0.28l0.45-0.3l0.98-0.4l0.03-0.37l-0.6-0.02l-1.14,0.51l-0.29-0.08l1.63-1.05l0.69-0.31l0.72-0.2l1.41-0.84
  		l0.36-0.45l0.34-0.59l-0.11-0.35l0.21-1.16l0.83-2.11l0.43-1.31l0.01-0.33l-0.79-1.65l-0.05-0.42l0.19-0.43l-0.68-0.07l-0.69,0.12
  		l-0.38,0.24l-1.2,1.19l-0.93,0.71l-0.52,0.71l-0.43,0.84l-2.17,3.67l-0.52,0.62l-0.9,0.77l-0.61,1.16l-0.12,1.24l0.38,2.36
  		l0.35,0.49l0.72,0.74l0.33,0.26l0.4,0.01l0.42-0.13l0.87,0.03l0.89-0.25l0.9-0.05l0.91,0.21h0.76l2.42-0.67l1.52-0.92l0.76-0.79
  		L1304.22,904.72z M769.06,872.29l-0.23-0.04l0.55,1.9l0.15,0.19l0.19,0.04l0.35-0.28l0.24-0.02l-0.13-0.4l-0.55-0.57l0.17-0.23
  		L769.06,872.29z M1087.35,884.31l-0.52,0.02l-0.47,0.14l-0.41,0.26l-0.17,0.2l0.07,0.14l0.7,0.16l1.62-0.15l0.51-0.09l0.08-0.16
  		l-0.38-0.26L1087.35,884.31z M1071.33,873.38l-0.3-0.22l-0.25,0.01l-0.14,0.06l-0.04,0.11l0.18,0.33l0.01,0.17l-0.12,0.15l0.11,0.1
  		l0.34,0.05l0.58-0.07l-0.04-0.25L1071.33,873.38z M1069.24,872.91l-0.68,0.08l-0.51,0.22l-0.25,0.31l0.09,0.73l0.16,0.26l0.22-0.01
  		l0.27-0.28l0.48-0.15l0.69-0.02l0.36-0.1l0.03-0.19l0.25-0.11l0.1-0.17l0.04-0.33l-0.41-0.19L1069.24,872.91z M1295.24,908.1
  		l0.05,0.21l0.54,0.43l0.08,0.15l0.35-0.09l0.07-0.27l0.34-0.54l-0.96-0.11L1295.24,908.1z M899.29,649.55l0.28,0.83l0.8,0.88
  		l0.18,0.38l0.41-0.2l0.52-0.7l0.23-1.1l-1.36,0.02L899.29,649.55z M898.79,643.49l0.66,0.64l0.58,0.27l0.29,0.57l0.16,0.11
  		l0.42-1.2l0.07-0.51l-0.12-0.43l-0.22-0.14l-0.48-0.53l0.12-0.51l-0.13-1.05l-0.49-0.62l-0.66,0.52l0.06,0.87l-0.25,0.62
  		L898.79,643.49z M1024.75,718.64l0.17,0.34l0.77,0.66l0.34-0.03l0.37-0.3l0.06-0.29l-0.7-0.59L1024.75,718.64z M1134.46,915.32
  		l-0.37-0.07l-0.17,0.07l0.03,0.22l0.14,0.21l0.36,0.27l-0.01-0.13l0.14-0.4L1134.46,915.32z M1266.61,882.17l-0.16-0.07l-0.16,0.09
  		l-0.18,0.22l-0.43,0.83l0.97-0.55L1266.61,882.17z M1341.13,852.36l0.04,0.21l0.24,0.13l0.26,0l0.28-0.12l0.27-0.25l0.26-0.37
  		l0.03-0.23l-0.49-0.05l-0.65,0.23L1341.13,852.36z M1366.07,886.29l-0.26-0.76l-0.07-0.56l-0.28-0.25l-0.32-0.01l-0.29,0.44
  		l-0.47,1.16l-0.6,0.71l-0.94,0.74l-0.55,0.3l-0.3-0.35l-0.1-0.26l-0.05-1.5l0.18-0.87l0.36-0.83l0.95-1.76l0.36-0.57l0.7-0.69
  		l0.05-0.2l-0.15-0.2l-0.32-0.17l-1.39,0.84l-1.66,0.76l-1.86,2.72l-0.84,0.87l-0.58,0.15l-0.78-0.02l-0.34-0.34l-0.27-0.48
  		l-0.22-0.64l0.02-0.13l1.4-1.31l0.31-0.51l0.16-0.49l-0.12-0.12l-1.18,0.17l-0.64-0.11l0.94-0.24l0.4-0.16l0.05-0.12l1.14-0.22
  		l0.23-0.22l-0.15-0.7l-0.42-0.36l0.66-0.26l1.06-0.67l0.6-0.66l0.28-0.13l0.64,0.04l0.36-0.11l0.39-0.3l0.64-0.85l0.19-0.38
  		l0.14-0.6l-0.06-0.68l-0.13-0.24l-0.12-0.03l-0.59,0.29l-0.78,0.59l-0.46,0.16l-0.72-0.1l-0.42,0.11l-1.18,0.87l-0.78-0.32
  		l-0.8,0.17l-0.51,0.5l-0.45,0l-0.71,0.13l-1.41,0.52l-0.09-0.06l0.39-0.27l1.53-0.71l0.77-0.45l0.17-0.54l0.58-0.86l0.07-0.26
  		l-0.72-0.17l-0.86,0.12l-0.67-0.24l-0.03-0.2l0.07-0.15l0.63-0.31l0.33-0.31l-0.04-0.16l-0.82,0.05l-1.25,0.24l-0.52-0.03l2.17-1.1
  		l0.49-0.39l0.17-0.28l0.13-0.46l0.43-0.46l0.73-0.46l0.82-0.69l0.11-0.55l-0.08-0.79l-0.42-0.63l-1.14-0.69l-0.91-0.45l-0.8-0.17
  		l-2.64,0.25l-0.39-0.05l-0.33,0.1l-0.28,0.25l-0.5,0.68l-0.13-0.13l-0.02-0.64l-0.13-0.75l0.1-0.66l-0.28,0.03l-0.65,0.4l-0.6,0.51
  		l-0.56,0.61l-0.54,0.36l-0.52,0.11l-0.56,0.32l-0.61,0.52l-0.28,0.08l0.05-0.36l-0.13-0.13l-0.31,0.09l-0.95,0.58l-0.58,0.49
  		l-0.08,0.22l0.05,0.45l-0.04,0.19l-0.69,0.47l-0.16-0.02l0.18-0.5l-0.09-0.46l0.7-1.08l0.29-0.74l0.21-0.8l-0.06-0.8l-0.19-0.28
  		l-0.31-0.17l-0.14,0.11l0.02,1.1l-0.08,0.23l-0.45,0.21l-0.1-0.09l0.01-0.62l-0.21-0.38l0.03-0.18l-0.96,0.3l-1.59,0.3l-1.15-0.88
  		l-1.9,0.64l-0.39,0.05l1.64-1.4l0.08-0.72l0.11-0.26l-0.27-0.12l-0.65,0.03l-0.62,0.22l-0.59,0.4l-0.16,0.03l0.84-0.94l2.71-1.36
  		l1.13-0.69l0.48-0.49l0.2-0.51l-0.23-0.15l-1.22,0.34l-0.76,0.07l-0.91-0.58l-0.45-0.05l-1.25,0.39l-0.73,0.47l-0.02-0.19
  		l0.31-0.64l0.04-0.52l-0.22-0.41l-0.26-0.16l-0.57,0.27l-0.63,0.84l-0.46,0.88l-0.43,0.4l-0.62,0.3l-0.92,0.9l-1.23,1.49
  		l-0.55,0.42l0.13-0.65l-0.02-0.41l-0.17-0.16l-0.16-0.45l0.08-0.25l0.42-0.5l0.3-0.54l0.06-0.28l-0.02-0.64l0.15-0.45l0.32-0.57
  		l1.3-1.62l0.47-0.71l0.25-0.88l0-0.33l0.61-0.41l1.57-1.85l0.51-0.73l0.12-0.92l0.12-0.28l0.24-0.24l0.98-0.63l0.76-0.77l0.6-1.4
  		l0.13-0.6l-0.01-0.49l-0.09-0.39l-0.16-0.28l-0.22-0.15l-0.27-0.03l-0.74,0.18l-0.33-0.09l-0.26-0.31l-0.11-0.39l0.11-0.74
  		l0.76-0.16l1.77-0.17l0.84-0.16l0.4-0.18l0.43-0.54l0.62-1.11l0.05-0.29l-0.36-0.31l-0.21-0.07l-1.22,0.2l-0.29,0.22l0.35,0.54
  		l-0.26,0.44l-1.48-0.41l-0.31-0.62l-1.04-0.05l-1.53,0.89l-2.62,1l-0.85,0.41l-0.53,0.33l-0.57,0.65l-0.47,1.45l-0.17,0.21
  		l-1.27,1.09l-0.5,0.19l-0.01,0.17l0.21,0.31l0.06,0.31l-0.4,0.91l-0.66,0.78l-0.93,0.47l-0.28,0.22l-0.19,0.29l0.03,0.28l0.26,0.27
  		l0.71,0.38l-0.49,0.11l-0.78,0l-0.25,0.24l-0.61,0.86l-0.28,0.47l-1.2,2.92l-0.88,1.91l-1.8,3.55l-0.02,0.35l0.27,0.74l0.83,1
  		l0.06,0.2l-0.55,0.18l-0.89-0.63l-0.46-0.12l-0.77,0.47l-0.63,0.7l-0.26,0.53l-0.05,0.89l0.24,0.51l0.79,0.31l0.98,0l-0.09,0.22
  		l-0.49,0.32l-0.42,1.11l0.14,0.36l0.35,0.38l0.42,0.24l-0.13,0.07l-0.37-0.07l-1.15-0.81l-1.12-0.21l-0.34-0.16l-0.38,0.13
  		l-0.76,0.88l-0.44,1.15l-0.81,1.6l-0.39,1.38l-0.25,0.26l-1.07-0.08l-0.29-0.19l-0.25-0.3l0.16-0.45l0.57-0.6l-0.19,0l-1.87,1.28
  		l-0.88,0.75l0.01,0.4h1.88l1.86-0.2l0.98,0.08l0.96,0.21l1.36-0.1l-0.04,0.09l-1.41,0.77l-0.86,0.33l-0.89,0.93l-2.11,1.78
  		l-2.62,1.76l-0.58,0.66l-0.18,0.48l0,0.25l0.35,1.38l0.51,1.1l0.34,0.34l0.86,0.34l1.48-0.1l2.76-0.49l0.88-0.28l0.68-0.33
  		l0.77-0.51l0.08,0.12l-0.05,0.46l0.79,0.09l2.64-0.06l0.35,0.16l1.89,0.37l1.57-0.06l4.39,0.61l1.5,0.1l0.44-0.29l2.21-0.26
  		l1.13-0.4l0.53-0.04l0.35-0.14l0.6-1.1l0.25-0.16l0.27,0.18l0.59,0.09l0.86-0.3l0.51-0.29l0.11,0.34l-0.19,2.09l-1.82,0.72
  		l-0.37,0.23l0.39,0.03l1.85-0.32l0.43,0.15l0.31,0.19l1.67,0.35l0.65-0.1l0.32-0.1l0.22-0.18l0.19-0.52l0.18-0.99l0.21-0.19
  		l1.43,0.12l1.03-0.08l0.33,0.25l0.75,0.14l0.46-0.09l0.9-0.38l-0.09,0.26l-0.62,0.74l-0.89,0.58l-1.81,0.72l-1.43,2.03l-0.34,0.39
  		l-0.76,0.65l-1,0.44l-1.36,0.29l-0.56,0.22l-0.68,0.58l-0.3,0.46l-0.03,0.18l0.07,0.3l0.58,0.43l0.3,0.14l0.47,0.07l1.15-0.15
  		l1.02-0.35l0.43-0.03l0.66,0.19l0.72-0.07l0.51-0.38l0.97-1.11l0.34-0.61l0.07-0.75l1.97-2.25l0.46-0.15l0.48,0.03l0.79-0.14
  		l0.65-0.53l0.85-0.95l0.09,0.12l-0.67,1.19l-0.17,0.53l0.63-0.31l0.27-0.25l0.18-0.37l0.25-1l1.44-2.31l0.13-0.8l0.22-0.14
  		l1.22,0.58l0.49,0.53l0.42,1.19l0.33,1.45l0.19,0.49l0.24,0.25l-0.16,0.57l-0.85,1.33l-1.03,1.87l-0.53,1.26l-0.15,0.53l0,0.39
  		l0.34,0.44l0.26,0.14l0.22,0.05l0.56-0.21l1.26-1.06l0.72-0.77l0.67-0.86l0.5-0.48l0.33-0.09l0.16,0.14l-0.01,0.36l-0.27,0.95
  		l0.26,0.56l-0.12,0.73l-0.18,2.2l0.22,0.44l0.19,0.11l0.27-0.04l1.3-0.83l0.49-0.07l0.27,0.01l0.31,0.2l0.35,0.39l0.39,0.15
  		l0.44-0.1l0.38-0.27l0.32-0.44l0.59-1.02l0.61-1.64l0.06-0.39l-0.05-0.37l-0.2-0.61l1.93-3.44l0.13-0.46l0.12-0.85L1366.07,886.29z
  		 M1353.81,886.02l-0.34,0.42l-0.39,0.23l-0.1,0.21l-0.01,0.27l-0.26,0.45l-0.52,0.63l-0.05,0.32l0.42,0.02l0.41-0.37l0.67-1.41
  		L1353.81,886.02z M1139.94,838.82l0.8,0.09l0.35-0.08l0.28-0.18l0.19-0.27l0.1-0.36l0.39-0.53l-0.01-0.18l-0.54-0.13l-2.6,0.99
  		l0.39,0.36L1139.94,838.82z M1265.06,884.94l0.37-0.05l0.74-0.66l0.21-0.78l-0.47,0.22l-0.22-0.07l-0.61,0.1l0.02,0.74l-0.2,0.43
  		L1265.06,884.94z M1289.65,870.3l0.42-0.36l-0.33-0.7l-0.68-0.87l-1.9-1.16l-0.76-0.19l-4.22-2.37l-1.9-0.89l-1.94-0.74l-1.71-0.48
  		l-3.94-0.64l-3.13-0.73l-0.95-0.03l-1.09,0.2l-0.95,0.43l0.38,0.65l4.68,1.86l0.91,0.61l0.85,0.74l0.42,0.81l0.51,0.66l4.42,1.9
  		l2.04,0.59l2.08,0.32l2.81,0.67l3.52-0.16L1289.65,870.3z M1304.89,924.87l-1.53-0.14l0.03,0.15l0.64,0.33l0.97,0.03l1.13-0.37
  		l0.51-0.65l-1.17,0.57L1304.89,924.87z M1182.81,913.87l7.01-0.01l3.5,0l3.5-0.01l3.51-0.01l3.5,0l3.5-0.01l0.83-2.01l0.77-0.93
  		l1.06,0.31l0.56-0.03l0.63-0.48l0.51-0.29l0.33,0.04l0.29,0.44l0.24,0.29l0.28-0.09l0.24-0.42l-0.01-0.58l0.33-0.4l0.39-0.06
  		l0.36,0.01l0.18-0.2l-0.02-0.28l-0.13-0.45l0.04-0.55l0.89-0.97l1.09-0.66l0.38-0.33l0.11-0.67l0.63-0.69l0.31-0.4l0.08-0.35
  		l-0.16-0.43l0.02-0.81l0.22-0.97l0.26-1.06l0.58-0.96l0.95-1.05l0.24-1.38l0.26-1.45l1.15-1.42l1.3-1.61l0.74-0.92l1.33-1.67
  		l0.95-1.19l0.48-0.55l0.5-0.65l0.81,0.19l0.81,0.19l-0.12,0.94l0.13,0.69l0.38,0.4l0.56,0.27l0.42,0.09l0.49-0.01l1.35-0.53
  		l1.59-0.34l0.87-0.32l0.16-0.3l0.4-0.11l0.63,0.09l1.17,0.76l1.36,1.14l1.08,0.9l0.03,1.56l0.02,1.65l0.02,1.74l0.02,1.24l0.03,1.7
  		l0.02,1.35l0.03,1.76l0.02,0.94l0.15,0.27l-0.09,0.37l-0.04,0.18l0.01,0.15l0.06,0.18l-0.01,0.26l-0.14,0.23l-0.07,0.27l-0.01,0.15
  		l0.11-0.04l0.67,0.7l0.9,0.57l0.57-0.05l0.44-0.42l0.16,0.43l0.09,0.35l0.14,0.05l0.16,0.39l-0.09,0.37l-0.26,0.17l-0.28,0.14
  		l-0.05,0.28l0.14,0.29l0.2,0.26l0.22,0.45l-0.09,0.39l-0.2,0.33l-0.09,0.34l0.17,0.29l0.44,0.39l0.28,0.38l0.44,0.21l0.21-0.15
  		l0.17-0.2l0.18-0.15l0.31,0.09l0.36,0.11l0.39,0.13l0.34,0.27l0.91-0.14l0.49,0.12l0.09,0.5l0.3,0.32l1.39-0.17l1.65-0.62l0.6,0.49
  		l0.74-0.39l0.85-0.58l0.91-0.4l0.31-0.31l-0.63-0.82l0.78-0.42l0.21-0.26l0.32-0.17l-0.34,0.61l-0.36,0.45l1.3,0.98l0.6,0
  		l2.86-1.19l2.21-1.42l1.9-0.74l1.34-0.85l1.01-0.13l1.56-1.83l-0.41-1.04l0.08-0.35l0.81,0.84l0.46,0.63l0.66-0.22l0.75-0.09
  		l-0.31,0.55l-0.39,0.29l-1.37,1.36l-2.25,1.57l-0.72,1.05l0.33,0.21l0.35,0.05l0.72,0.27l0.55-0.61l0.68-0.28l2.22,0.21l2.1-0.22
  		l1.52,0.34l2.46-0.17l2.08,0.31l-0.78,0.46l-2.43,0.11l-2.9,0.98l-0.75,0.73l0.4,1.29l-0.84-0.95l-1-0.25l-0.1-0.51l0.06-0.53
  		l0.16-0.31l0.08-0.43l-0.99-0.29l0.35,0.33l-0.35,0.52l-2.55,0.79l-1.28,0.62l-6.35,3.74l-0.3,0.23l-0.3,0.43l1.19-0.33l0.72-0.33
  		l-0.15,0.29l-0.81,0.54l-0.56,0.31l-0.81,0.05l-1.17,0.32l-0.88,0.55l-0.58,0.59l-0.29,0.36l-0.18,0.35l1.72-1.44l0.62,0.07
  		l-1.95,2.08l-0.79,2.31l0,0.66l0.56,2.73l0.74,0.74l0.3-0.37l0.2-0.38l0.77,0.2l0.43,0.62l0.82,1.78l0.64,0.27l0.82-0.2l0.7,0.36
  		l0.26-0.06l0.18-0.38l0.36-0.04l0.35,0.16l0.12-1.21l0.8-0.6l0.53-0.05l0.72,0.04l1.89-1.44l0.31-0.63l1.13-0.95l0.95-1.24
  		l0.92-0.44l0.76-1.22l0.37,0.12l0.5-0.43l-0.31-0.83l-0.22-0.31l0.44-1.09l1-0.38l0.56,1.03l0.47-0.6l0-0.44l0.38-0.59l0.64,0.43
  		l0.27,0.59l0.6,0.37l0.5,0.25l1.27,0.07l0.35-0.36l0.19-0.3l-0.11-0.69l-0.39-0.75l0.5,0.29l0.74,0.16l0.64-0.12l0.63,0.1
  		l1.27-0.71l0.56,0.03l0.48-0.06l2.22-0.73l2.14-0.61l2.1-0.96l2.01-0.6l1.96-0.93l0.63-0.1l0.6-0.37l0.66-0.24l0.6-0.03l0.93-0.29
  		l0.87-0.53l1-0.21l0.54,0.24l0.28-0.2l0.3-0.41l-0.33-0.4l-0.3-0.19l-2.98-0.19l0.71-0.46l0.79-0.32l0.04-0.36l-0.62-1.02
  		l-0.65-0.78l-0.54-0.41l-1.39,0.47l-1.01-0.14l-0.85-0.61l-0.29-0.89l-0.1-0.55l-0.27-0.18l-2.21,1.44l-1.72,0.69l-0.21,0.25
  		l-0.3,0.2l-0.86-0.41l-1.39,0.13l0.27-0.39l0.15-0.57l-1.77-0.37l-1.66-0.06l-0.92,0.26l-0.64,0.06l-0.19-0.29l-0.35-0.33
  		l-1.27-0.66l-0.49-0.03l-1.14,0.21l-1.45-1.06l-1.53-0.65l1.89-0.9l-0.34-0.41l-0.36-0.21l-1.93-0.28l-0.56-0.29l-2.78-0.21
  		l-0.13-0.75l-0.77-0.47l0.05-0.73l-0.41-0.92l-0.31-1.68l-0.76-0.29l-0.55-1.31l-0.2-0.69l0.34-0.74l0.29-1.09l-0.94-0.27
  		l-0.84-0.03l-1.26,0.42l-0.57-0.21l-0.49-0.34l1.96-1.41l1.47-1.44l0.51-2.15l0.72-1.11l0.53-0.55l-1.44-0.77l-1.45,0.04l0.38-0.58
  		l-1.91,0.38l-0.98,0.46l-1.18,0.87l-1.04,0.18l-0.5-0.28l-0.75-1.75l-0.79-0.55l-3.04-0.83l-1.26-0.77l-0.58-0.07l-1.71,0.6
  		l-0.61-0.12l2.15-1.04l1.05,0.23l0.64-0.21l1.39,0.16l0.59-0.47l0.72-0.45l1.45,0.83l2.35,0.86l0.98,0.22l0.85-0.11l1.88-0.91
  		l0.64-0.58l1.16-0.39l0.48-0.34l0.49-0.89l0.61-0.54l2.39-0.68l0.8-1.37l0.06-1.52l-1.42-1.22l-0.84-0.41l1.2,0.02l1.36,0.35
  		l-0.06-0.73l-0.38-0.52l-2.58-1.99l-2.26-0.95l-4.71-0.77l-1.07-0.04l-3.03,0.44l-2.49,0.14l-3.54,0.94l-4.37,1.76l-3.73,1.17
  		l-2.76,1.35l-2.94,1.13l-1.63,0.91l-1.01,0.91l-1.63,0.87l-0.59,0.11l-1.44,0.98l-2.69,2.45l-1.39,0.86l-0.93,1.54l-1.86,2.14
  		l-1.81,1.62l-0.44,1l-1.25,0.94l-1.43,1.84l-1.11,0.9l-3.99,1.91l-1.34,0.35l-0.92,0.67l-1.5,0.38l-1.95,0.71l-1.93,0.23
  		l-1.75,0.85l-0.66,0.42l-0.45,0.73l-1.06,0.39l-1.1,0.55l-2,1.8l-2.16,0.82l-1.43,0.98l-0.79,1.54l-0.98,1.11l-0.81,1.31
  		l-0.16,0.48l-0.29,1.33l-0.33,0.35l-1.74,0.31l-2.4,1.61l-1.85,0.55l-2.5,1.53l-1.2,0.39l0.38,0L1182.81,913.87z M1284.81,900.54
  		l1.25-0.81l1.25-0.7l-0.14-0.25l-0.28-0.21l-0.75-0.23l-2.19,0.09l-2.18,0.2l-0.25,0.1l-2.12,0.24l-1.39,0.06l-1.32-0.4l-0.7-0.51
  		l-0.37-0.04l-0.37,0.09l-0.66-0.39l-1.24-0.22l-0.47,0.86l-0.4,0.27l-0.23-0.15l-0.19-0.27l-0.61-0.16l0.25-0.32l0.1-0.36
  		l-0.13-0.39l-0.2-0.32l-1.56-1.44l0.9-1.46l-0.13-0.73l0.03-0.85l-1.38,1.13l-0.56,0.57l-0.48,0.69l-0.63,0.7l-0.41,0.82l0.13,0.54
  		l1.28,0.1l0.84,0.34l0.25,0.4l-0.04,1.44l0.77,0.22l1.34-0.03l0.86,0.11l0.07,0.14l-0.11,0.15l-0.31,0.03l0.58,0.82l0.76,0.62
  		l0.61,0.22l2.46,0.59l0.53-0.07l0.51-0.3l-0.99-0.12l0.64-0.39l0.65-0.17l0.51-0.45l0.66-0.22l-0.14,0.26l-0.49,0.24l-0.03,0.48
  		l0.35,0.36l0.53-0.05l0.49,0.75l-1.07,0.6l0.23,0.09l0.77-0.1l0.21,0.7l0.62,0.3l0.52,0.07l1.78-0.11l0.45-0.24l-0.21-0.24
  		l-0.34-0.06l0.03-0.73l-0.11-0.71l0.22-0.39L1284.81,900.54z M1187.11,909.31l1.22-0.27l1.11,0.19l0.65-0.21l0.12-0.22l0.11-0.59
  		l0.53-1.66l-0.39,0.04l-1.39,1.46l-1.39,0.48l-0.91,0.78L1187.11,909.31z M1288.93,888.74l0.68-0.41l0.56-0.72l0.86-0.65l0.9-0.04
  		l0.67-0.79l-0.66-0.1l-0.62,0.16l-0.67,0.41l-1.68,1.33l-0.82,0.47l-0.7,2.03l0.48,0.16l0.99-0.04l0.52-0.4l-0.36-0.08l-0.53,0.02
  		l-0.3-0.2l0.23-0.64L1288.93,888.74z M1210.9,893.98l-0.18,0.37l0.77-0.09l0.95-0.49l0.74-0.8l-0.45-0.01l-0.77,0.37L1210.9,893.98
  		z M1312.31,850.82l3.52-3.25l0.19-0.73l0.18-0.22l0.7-0.42l0.57-0.12l1.45,0.12l1.52-0.18l0.56-0.12l1.42-0.86l0.71-0.3l2.59-0.48
  		l1.37-0.1l1.71,0.4l0.65-0.05l0.36-0.12l3.6-2.5l2.24-1.3l2.23-1.49l2.71-2.35l0.19-0.59l-0.15-0.58l-0.78-0.43l-0.42-0.34
  		l-1.44-0.68l-0.06-0.27l0.97,0.28l1.01,0.06l0.51-0.31l0.09-0.42l-0.34-0.52l-0.79-0.37l-1.79-0.34l-1.48,0.02l-0.81-0.1l0.27-0.33
  		l1.06-0.01l2.68-0.55l0.38-0.22l-0.13-0.39l-0.45-0.67l0.12-1l0.24-0.63l-0.53-1.39l0.71-1.53l0.09-0.88l-0.16-0.39l-0.32-0.46
  		l-0.07-0.3l0.03-0.36l-0.44-0.54l-0.46-0.92l-1.21-1.34l-1.35-0.14l-0.71-0.28l-0.76-1.08l-0.17-0.54l-0.5-0.02l-1.45,0.1
  		l-1.22,0.21l-1.44,0.77l-1.76,1.66l-0.93,0.68l-0.46-1.05l-0.29-0.26l-0.9-0.19l0.03-0.13l0.29-0.25l2.07-0.95l0.73-0.48l0.19-0.4
  		l-0.12-0.64l-0.42-0.88l-1.83-2.75l-1.67-0.33l-4.86-0.43l-0.99,0.31l-0.39,0.34l-0.04,0.2l0.36,0.46l1.18-0.19l0.95,0.05
  		l1.15,0.27l-0.06,0.14l-1.28,0.02l-2.01,0.44l-2.74,0.85l-2.25,1.18l-1.77,1.51l-1.62,1.15l-1.48,0.79l-1.04,0.44l-1.75,0.37
  		l-0.44,0.28l-0.89,1l-1.36,0.99l-1.53,0.46l-0.07-0.13l-0.07-0.13l0.61-0.32l0.18-0.3l-0.46-0.19l0.13-0.36l1.12-0.67l0.48-0.43
  		l-0.51-0.49l-1.76-0.89l-0.22-0.53l1.11,0.5l1,0.16l0.23-0.16l0.14-0.28l0.16-0.77l0.21-0.37l0.36-0.32l1.19-0.53l0.42-0.31
  		l0.62-0.09l0.82,0.13l1.31-0.03l2.49-1.09l1.37-0.4l0.22-0.15l0.29-0.39l0.49-0.26l0.67-0.13l1.02,0.06l0.73-0.12l0.63-0.62
  		l1.04-1.45l0.64-0.29l1.17-0.38l0.49-0.39l0.09-0.35l2.21-0.39l1.6-0.03l1.15-0.63l0.66-0.9l0.68-0.62l0-0.24l-0.66-0.58
  		l-1.21-0.12l-0.83-0.27l-0.86-0.52l-0.86-0.64l-0.28-1.2l-0.81-0.08l-1.15,0.19l-0.23,0.62l-1.48,0.45l-0.86-0.1l-2.36-0.65
  		l-0.89-1.33l-0.59-1.2l-0.35-1.11l-0.75-0.4l-1.46-0.2l-0.54,0.55l-0.59,0.85l-0.29,0.3l-2.71,1.97l-0.74,0.85l0.18-0.62l0.63-0.88
  		l2.16-2.2l0.4-0.54l-0.67-0.25l-0.74,0.28l-0.7-0.27l-0.05-0.85l-0.53-0.48l-0.87,0.17l-0.57,0.42l-2.38,0.27l-1.08,0.5l-1.82,1.48
  		l-0.51,0.09l0.81-0.81l0.59-0.83l0.14-0.5l0.61-1.45l1.15-0.92l0.27-0.43l-0.98-0.9l-0.37-0.65l-0.47-0.44l0.36-0.7l0.21-0.89
  		l-0.6-0.05l-0.53-0.2l-0.73,0.92l-0.26-1.04l-0.32-0.12l-0.89-0.73l-0.06-0.65l-0.75-0.19l-0.51,0.52l-0.87,0.61l-0.79-0.05
  		l-0.28-0.26l-0.09-0.49l-0.45-0.3l-1.38-0.22l-0.83-0.26l0-0.32l1.25-0.29l-0.2-0.35l-0.34-1.66l-0.48-0.07l-1.16,0.17l-1.3-0.28
  		l0.05-0.69l1.76-0.46l0.62-0.39l-0.75-0.35l-1.5-0.08l-2.09-0.32l-0.58-0.36l0.93-0.62l1.17-0.06l0.19-0.18l-0.64-0.53l-0.35-0.16
  		l-1.15-0.08l-1.05-0.91l-2.35-0.76l-0.86-0.86l0.31-0.2l0.74-0.21l0.64,0.04l-0.72,0.54l0.13,0.25l2.56,0.81l4.47,0.53l1.35-0.31
  		l-0.01-1.14l-0.15-0.93l0.38-0.82l0.1-1.07l-2.48-2.08l-0.74-0.16l-0.69,0.12l-0.53-0.14l-0.7-0.37l-0.28-0.24l0.32-0.32l0.45-0.89
  		l0.3-0.27l-0.01-0.13l-0.59-0.48l-1.41-0.39l-0.89-0.02l-0.92,0.17l-0.79-0.09l-0.49-0.17l-0.34-0.33l1,0.14l0.33-0.08l0.72-0.54
  		l0.73-0.09l0.7-0.31l0.98-0.61l0.31-0.69l-0.54-1.23l0.23-0.41l0.45-0.27l0.13-0.44l-0.5-0.62l-1.34-0.64l-0.71,0.12l-0.88-0.22
  		l-1.52-2.22l-0.86-0.05l-1.93,0.35l-1.38,0.44l-2.01,1.11l-0.35-0.15l0.93-0.85l0.75-0.52l2.11-0.9l1.16-1.46l0.68-1.9l-0.12-0.28
  		l-1.09,0.05l-0.84,0.16l-2.01,0.79l-0.48,0.05l0.11-0.38l-0.22-0.23l-0.54-0.08l-0.73,0.32l-1.49,1.36l-0.53,0.01l0.83-0.85
  		l0.4-0.66l1.44-0.82l0.98-0.32l1.92-1.56l-0.44-1.14l-0.69-1.12l-0.35-0.28l-1.14,0.25l-0.82-0.12l0.51-0.54l0.05-0.21l-0.53-0.93
  		l-0.26-0.29l0.05-0.1l0.68-0.28l-0.22-0.14l-0.65-0.16l-0.62,0.02l-1.42,0.4l-1.9,0.25l-1.24,0l-0.25-0.33l0.51-0.15l1.3,0.03
  		l0.93-0.19l1.17-0.45l0.77-0.98l-1.05-1.72l-0.82-0.11l-0.96,0.8l-0.2,0l0.15-0.52l-0.19-0.38l-1.39-0.39l-0.21-0.36l1.01-0.48
  		l0.84-0.81l-0.77-0.77l-0.74-0.88l-0.34-0.66l-0.08-0.7l-0.65-0.86l-0.8,0.36l-0.64,0.66l0.48-1.32l-0.12-1.59l-0.85-1.15
  		l-1.05-0.01l-1.28,0.27l-1.46,0.58l-0.3-0.18l0.46-0.32l1.56-0.72l0.91-0.97l-0.14-0.72l-0.53-0.51l-1.74-0.86l-0.94,0.06
  		l-0.23,0.29l-0.38,0.28l-0.35,0.44l-1.2,2.4l-0.27,0.87l-0.56,1.08l-0.08,0.52l0.19,0.46l0.39,0.36l0.72,0.39l-0.22,0.22
  		l-1.33-0.71l-0.64-0.11l-0.58,0.11l-0.41,0.17l-0.23,0.23l-0.4,1.08l-0.05,0.52l0.1,0.4l0.57,0.97l0.52,0.35l0.69,0.19l0.78,0.41
  		l0.86,0.63l0.26,0.29l-0.3,0.12l-1.68-1.08l-0.64-0.18l-1.06,0.11l-0.12,0.28l0.69,1.47l-0.04,0.19l-0.85-0.44l-0.27,0.38
  		l-0.28,0.93l-0.24,0.4l-0.45-0.21l-0.26,0l-0.07,0.2l0.29,0.75l0.22,0.31l0.28,0.21l0.93,0.24l0.95,0.39l-0.11,0.27l-1.1,0.03
  		l-0.15,0.3l-1.27-0.25l-0.22,0.1l-0.01,0.26l0.15,0.4l-0.77-0.12l-0.39,0.04l-0.67,0.77l0.02,0.24l0.69,0.43l-0.16,0.17l-0.94,0.09
  		l-0.64,0.23l-0.05,0.41l0.24,0.66l0.6,1.05l0.18,0.48l0.04,0.48l-0.07,0.45l-0.6,1.28l-0.12,0l-0.11-1.67l-0.13-0.48l-0.39-0.66
  		l-0.65-0.84l-0.58-0.56l-0.52-0.28l-0.54,0.04l-0.99,0.74l-0.3,0.41l-0.36,0.75l-0.42,1.08l-0.96,0.71l-1.5,0.35l-1,0.37
  		l-1.21,0.77l-1.85,0.86l-1.58,1.06l-0.23,0.34l-0.18,0.56l-0.13,0.79l-0.39,1.04l-0.16-0.22l0.14-1.2l-0.06-0.41l-0.01-1.27
  		l-0.4-1.74l-0.16-0.24l-0.42,0.48l-0.15,0.68l-0.27,0.46l-1.29,1.48l-0.46,0.16l1.28-1.57l0.19-0.35l0-0.41l-0.79-1.62l-0.34-0.3
  		l-0.76,0.15l-0.54,0.87l-0.96,2.76l-0.53,1.06l-0.52,0.48l-0.69,0.49l-2.41,0.44l-2.19,0.9l0.05-0.29l0.76-0.52l1.01-0.38
  		l1.93-0.45l1.08-0.48l0.94-1.06l0.35-0.78l0.68-2.12l0.04-1.05l-0.05-0.54l-0.15-0.35l-0.62-0.48l-0.46-1.83l-0.28-0.48l-0.51-0.5
  		l-0.74-0.53l-0.63-0.33l-0.51-0.14l-2.05,0.19l-1.03-0.12l-0.93,0.02l-0.82,0.16l-0.93,0.41l-2.26,1.51l-1.17,0.48l-0.75-0.09
  		l-1.3-0.6l-1.02-0.19l-0.05-0.36l1.52-0.33l0.94-0.49l0.22-0.31l0.11-0.3l0-0.29l0.13-0.21l0.25-0.13l0.26,0.2l0.64,1.34l0.24,0.13
  		l0.34-0.11l0.64-0.49l0.27-0.64l0.21-0.98l0.22-0.61l0.23-0.24l0.05-0.23l-0.38-0.81l0.08-0.34l0.84-1.21l0.05-0.32l-0.47-0.43
  		l-2.37-0.05l-0.24-0.63l0.16-1.2l0.3-0.96l0.45-0.72l0.19-0.66l-0.07-0.59l-0.36-1.25l-0.36-0.61l-0.51-0.59l-0.6-0.34l-4.39-0.33
  		l-1.18,0.01l-1.29-0.17l-0.3-0.53l1.23,0.14l4.6-0.03l1.41-0.15l1.03-0.58l0.37-0.58l0.06-0.29l-0.05-0.67l-0.04-0.28l-0.64-0.83
  		l-0.27-0.59l-0.13-0.71l-0.03-0.66l0.07-0.6l0.25-1.02l0.68-1.56l0.56-0.67l0.71-0.47l0.48-0.44l0.24-0.42l0.05-0.46l-0.13-0.51
  		l-0.48-1.13l-0.26-0.38l-0.45-0.25l-0.56,0.13l-0.23,0.45l-0.23,0.83l-0.26,0.45l-0.77,0.1l-0.92,0.6l-0.7,0.05l-0.87-0.3
  		l-0.43-0.53l0.01-0.76l-0.12-0.51l-0.25-0.25l-0.78-0.36l-0.88,0.06l-1.32,0.28l-1.54-0.16l-2.63-0.9l-1.18-0.27l-1.46-0.03
  		l-0.63-0.13l-1.08-0.7l-1.62-1.59l0.1-0.45l0.72-0.34l0.01-0.19l-1.25-0.1l-0.51-0.24l0.11-0.34l0.72-0.78l0.84-0.31l0.31-0.28
  		l0.12-0.25l-0.28-0.32l-1.92-0.92l-0.47,0.15l-0.35,0.53l-0.49,0.31l-1.62,0.32l-0.26-0.19l1.39-0.51l0.33-0.3l0.02-0.2l-0.35-0.62
  		l-0.38-0.32l-0.44-0.63l-0.4-0.39l-1.13-0.73l-1.22-0.45l-0.58,0.2l-0.73,0.57l-0.56,0.32l-0.37-0.02l0.89-1.49l0.29-0.94
  		l-0.01-0.33l-0.11-0.31l-0.21-0.49l-0.14-0.14l-0.4-0.09l-1.24,0.07l-0.93-0.72l-0.48-0.23l-1.23-1.06l-0.87-0.6l-1.09-0.57
  		l-1.7-1.12l-0.63-0.25l-0.5,0.06l-0.96,0.45l-1.42,0.84l-1.34,0.64l-1.88,0.65l-1.82,0.79l-0.37,0.36l0.65,0.76l-0.17,0.12
  		l-2.32-1.49l-0.97-0.45l-0.77-0.08l-1.91-0.54l-0.57,0.07l-0.67,0.27l-2.54,1.4l-0.16-0.18l1.13-0.73l-1.19-0.87l-6.73-1.96
  		l-2.22-0.78l-2.74-0.32l-1.41-0.3l-1.95,0.54l-2.49,1.37l-1.42,0.93l-0.34,0.49l-0.21,0.47l-0.11,0.96l0.08,1.32l0.5,2.39
  		l0.47,1.19l0.62,0.91l0.49,0.43l0.64,0.44l0.97,0.88l1.55,0.91l-1.13,1l-0.74,0.53l-0.11,0.57l0.19,2.09l-0.03,0.31l-0.29,0.63
  		l-0.55,0.94l-0.88,1.04l-1.9,1.92l-0.18,0.42l0.5,0.12l1.05-0.11l1.07,0.41l2.26-0.5l0.11,0.21l-0.6,0.24l-0.62,1.18l-0.23,0.23
  		l-0.25,0.5l0.64,0.79l0.64,0.14l1.04,0.05l0.1,0.26l-0.8,0.46l-0.7,1.01l0.28,0.82l1.65,2.74l-0.07,0.16l-0.93,0.41l-0.12,0.16
  		l0.33,0.34l1.98,0.24l0.19,0.26l-0.71,1.22l0.04,0.51l0.34,0.64l-0.03,0.46l-1.2,1.02l-0.09,0.39l0.63,0.94l0.52,0.38l-0.4,0.12
  		l-1.63-1.39l-1.14,0.06l-0.18,0.22l0.12,0.97l-0.13,0.29l-0.93,1.03l0.12,0.4l0.55,0.41l0.16,0.38l-0.69,0.94l-1.22,0.74
  		l-0.67,0.56l-0.61,0.73l-0.88,1.33l-1.57,1.65l-0.24,0.35l-0.2,0.55l-0.17,0.74l-0.11,1.07l0.08,0.41l0.36,0.58l0.94,0.27
  		l2.85,2.23l1.09,0.59l1.69,0.73l1.11,0.63l0.53,0.54l2.8,2.16l2.24,3.18l0.68,1.22l0.2,0.72l1.1,2.64l0.45,1.31l0.24,1.1l0.4,3.49
  		l0.05,2.22l-0.09,2.48l-0.14,1.69l-0.49,1.9l-0.39,1.1l-0.94,1.32l-1.48,1.53l-1.13,1.31l-0.78,1.1l-1.35,1.28l-3.18,2.49
  		l-0.62,0.62l-0.98,0.64l-2,1l-1.47,0.97l-1.44,0.67l-3.13,1.21l-0.53,0.31l-6.37,2.15l-0.39,0.3l-0.01,0.2l0.37,0.1l0.33,0.21
  		l0.28,0.32l0.65,1.28l0.38,1.12l0.38,0.67l0.63,0.85l0.51,0.54l0.67,0.36l0.58,0.19l0.07,0.14l-0.34,0.47l-0.53,0.21l1.47,0.54
  		l0.48,0.32l0.12,0.25l-0.65,0.59l-0.02,0.22l0.36,0.59l0.74,0.47l-0.01,0.1l-0.48-0.06l-0.31,0.21l-0.35,0.87l-0.27,0.29l0.11,0.7
  		l0.48,1.1l0.43,1.72l0.38,2.34l0.41,1.86l0.37,0.77l0.96,0.88l0.15,0.48l-0.27,0.5l-0.1,0.59l0.18,1.19l0.17,0.31l1.04,1.05
  		l0.37,0.49l0.24,1.05l0.03,1l0.11,0.22l0.55,0.34l-0.37,0.1l-0.39,0.44l-0.47,0.83l-0.92,1.21l-1.06,0.78l-0.36,0.56l-0.17,0.77
  		l-0.31,0.52l-0.45,0.27l0.03,0.46l1.7,1.88l0.34,0.44l0.04,0.33l-0.81,0.75l-0.26,0.51l-0.33,1.25l-0.05,0.8l-0.28-0.66l-0.4-1.42
  		l-0.17-0.44l-0.3-0.43l-0.42-0.42l-0.52-0.27l-0.62-0.12l-0.32-0.16l-0.28-0.79l-0.35-0.06l-1.34,0.65l-0.26,0.36l-0.16,0.49
  		l-0.32,0.46l-0.48,0.43l-0.39,0.75l-0.29,1.06l-0.12,0.73l0.05,0.4l0.14,0.37l0.66,0.76l1.55,1.46l0.61,0.92l0.28,0.8l-0.91-1.25
  		l-1.65-1.47l-1.56-1.84l-1.05-0.69l-1.21-0.53l-1.37-0.37l-0.86-0.15l-0.93,0.25l-1.68,1.3l-0.99,0.66l-0.47,0.08l1.5-1.56
  		l1.49-0.89l0.44-0.49l-0.04-0.49l-0.41-1.04l-0.78-1.59l-0.59-1.02l-0.4-0.45l-2.22-1.95l-1.34-0.82l-1.33-0.49l-0.95-0.6
  		l-0.57-0.7l-0.7-0.36l-0.83-0.03l-1.4,0.25l-0.11-0.08l0.43-0.33l0.97-0.45l0.42-0.34l0.34-0.49l-0.23-0.74l-1.2-1.48l-0.98-1
  		l-1.35-1.82l-0.74-0.75l-0.8-0.5l-0.49-0.45l-0.26-0.79l0-0.4l0.27-1.07l0.34-0.59l0.51-0.6l0.11-1.15l-0.29-1.7l-0.09-1.13
  		l0.34-1.48l0.07-0.9l-0.18-0.78l-0.65-1.84l-0.2-0.33l-1.1-1.24l-0.25-0.74l0.05-1.29l0.4-1.48l1.27-3.84l-0.06-0.5l-0.69-1.66
  		l-0.72-0.82l-1.55-0.95l-0.93-0.2l-0.95,0.11l-0.57-0.06l-0.67-0.72l-0.33-0.11l-1.92,0.2l-2.99-0.55l-0.83-0.03l-2.05-0.59
  		l-0.52,0.01l-0.43,0.19l-0.69,0.08l-0.96-0.03l-1.16,0.12l-1.36,0.28l-1.58,0l-1.8-0.28l-1.19-0.03l-0.57,0.23l-0.71,0.49
  		l-1.28,1.7l0.03-0.19l1.26-2.37l-0.05-0.61l-0.54-0.4l-1.05-0.56l-0.6-0.51l-0.68-0.78l-0.99-0.72l-1.3-0.66l-1.3-0.46l-1.3-0.26
  		l-2.01-0.66l-4.57-1.67l-3.1-0.71l-1.65-0.55l-0.66-0.42l-2.67-3.4l-1.66-1.5l-1.65-0.82l-1.48-0.87l-1.96-1.39l-1.23-1.07
  		l-1.03-0.45l-2.22-0.39l-1.1-0.38l-3.78-0.79l-2.4-0.85l-2.27-1.17l-2.08-0.91l-2.57-0.39l-1.8,0.19l-7.64,2.14l-1.95,0.66
  		l-0.9,0.46l-0.14-0.05l0.62-0.57l-0.04-0.28l-1.29,0.11l-0.45,0.17l-1.18,0.77l-0.63,0.3l-0.61,0.14l-0.04-0.08l0.54-0.29
  		l0.53-0.44l0.51-0.6l0.55-0.87l0.59-1.14l0.32-0.84l0.06-0.55l-0.14-0.78l-0.34-1.01l-1.79-3.76l-0.32-0.81l-0.86-2.83l-0.7-1.82
  		l-1.47-3.26l-0.22-0.92l-0.24-1.61l-0.2-0.38l-0.84-0.38l-0.82,0.19l-0.94-0.04l-2.48-0.35l-2.32,0.15l-0.57,0.29l-0.72,1.36
  		l-0.53,3.06l-0.51,0.99l0-0.51l0.44-3.94l-0.05-0.7l-1.11-0.36l-1.01-1.27l-0.33-0.25l-0.38-0.09l-0.42,0.07l-0.34-0.41l-0.26-0.89
  		l-0.27-0.56l-0.59-0.37l-0.93-0.23l0.73-0.24l0.43-0.79l0.26-1.45l0.1-2.62l-0.08-5.96l0.37-1.94l0.6-2.45l0.2-1.46l-0.2-0.47
  		l-0.29-0.31l-0.48-0.26l0.7-0.5l0.93-0.05l0.5-0.8l0.69-1.61l1-1.79l1.3-1.98l0.73-1.45l0.15-0.93l-0.04-0.71l-0.24-0.48l0.19-0.18
  		l1,0.13l0.45-0.46l0-0.26l-0.43-1.03l0.24-0.49l1.71-1.56l2.43-1.34l0.58-0.44l0.34-0.36l-0.02-0.16l-0.97-0.42l-0.54-0.45
  		l-0.27-0.32l-0.46-0.91l1.77,0.17l0.33-0.05l0.5-0.37l-0.2-0.27l-0.59-0.43l0.14-0.2l1.54-0.05l0.79-0.35l0.48-0.42l-0.1-0.21
  		l-0.36-0.25l0.04-0.29l1.35-0.85l-0.08-0.39l-0.62-0.54l-1.61-0.83l-0.22-0.2l0.43-0.02l2.43,0.79l1.1,0.61l0.73,0.68l0.58,0.4
  		l0.43,0.12l-0.14-0.44l-0.7-1l-0.62-0.64l-0.54-0.28l-0.02-0.39l0.51-0.5l0.95-0.68l0.28-0.43l0.09-0.58l0.46-0.24l0.82,0.1
  		l0.64-0.1l0.46-0.29l0.52,0.02l1.06,0.54l0.62,0.05l0.53-0.46l0.07-0.22l-0.17-0.69l-1.63-0.27l-0.79-0.24l-0.52-0.37l-0.34-0.29
  		l-0.27-0.51l-0.09-0.38l0.23-0.24l1.39-0.12l0.37-0.14l0.35-0.3l0.64-0.02l1.39,0.38l3.54,0.4l0.84-0.2l1.98-1.36l0.9-0.25
  		l1.15-0.07l0.79-0.34l0.42-0.61l0.24-0.61l0.07-0.62l-0.17-2.57l-0.3-0.63l-1.88-1.22l-1.12-0.44l-1.91-0.41l-1.34-0.74l-1.66-1.3
  		l-1.31-0.51l-0.97,0.29l-1.01,0.48l-1.79,1.03l-1.48,0.11l0.03-0.19l2.16-1.17l0.41-0.47l-0.33-0.22l-1.2-0.36l-1.6-0.35
  		l-5.36-1.89l-0.71-0.34l-1.08-0.29l-0.23,0.1l0.22,0.55l0.45,0.37l0.51,0.25l-0.03,0.17l-1.22,0.04l-1.21-0.37l-0.88-1.02
  		l0.07-0.42l0.43-0.43l0.07-0.49l-0.84-1.44l2.25,1.6l3.87,1.23l3.54,1.45l1.78,0.56l1.21,0.16l0.85-0.11l0.48-0.38l0.47-0.13
  		l0.45,0.13l0.08,0.16l-0.29,0.19l-0.02,0.19l0.25,0.2l2.12,0.2l1.14,0.22l3.63,1.44l1.37,0.4l1.13,0.09l0.89-0.21l0.6-0.36
  		l0.33-0.5l0.53-0.06l0.74,0.39l0.65,0.16l1.04-0.23l0.76-0.64l1.19-1.54l-0.04-0.34l-0.35-0.65l-0.91-1.36l0.22-0.04l1.85,0.52
  		l0.56-0.18l-0.04-0.29l-0.78-1.37l-0.27-0.26l-0.8-0.37l0.32-0.18l1.94-0.54l0.16,0.17l0.24,0.93l0.26,0.3l0.98,0.62l0.54,0.21
  		l0.42,0l0.3-0.2l0.52-0.14l1.59,0.75l0.7,0.21l0.2-0.17l-0.86-1.67l0.07-0.11l1.19,1.07l0.8,0.31l1.24,0.26l1.39-0.23l2.31-1.08
  		l2.3-1.27l0.91-0.82l0.29-0.79l0.66-1.33l5.09-5.79l0.82-1.38l1.3-1.87l0.22-0.62l-0.21-1.23l-0.68-0.37l-2.39-0.49l-4.53-0.27
  		l-2.26,0.01l-6.54-0.94l-1.28-0.65l-0.97-0.7l-3.02-2.79l-1.58-1.23l-1.15-0.6l-1.04-0.35l-0.94-0.1l-3.69-1l-3.26-0.48l-0.69,0.27
  		l-0.07,0.2l0.27,0.78l-2.06-0.9l-1.2-0.74l0.13-0.16l0.89-0.07l2.49-0.02l1.55,0.17l1.43,0.34l1.2,0.12l1.66-0.08l1.68,0.61l0.95,0
  		l0.96,0.2l0.35-0.05l-0.81-0.85l0.45-0.1l1.19,0.07l1.32,0.37l1.45,0.68l2.8,1.69l1.19,0.5l1.16,0.16l0.54,0.18l0.6,0.93l0.72,0.33
  		l1.62,0.99l2.31,1.68l1.27,0.63l2.46,0.19l1.9-0.05l1.36-0.22l0.82-0.39l0.95-0.8l1.08-1.21l2.12-1.97l5.55-4.9l0.26-0.36
  		l0.45-0.99l-0.05-0.5l-0.31-0.45l-0.95-0.54l-1.58-0.63l-2.39-0.73l-0.84-0.54l-0.52-0.8l0.07-0.22l0.32-0.14l0.1-0.21l-0.41-0.75
  		l0.24-0.17l0.63-0.12l4.8,0.15l2.29-0.18l1.58-0.5l1.36,0.44l1.14,1.37l0.97,0.99l0.8,0.62l1.58,0.76l2.36,0.89l1.26,0.4l0.16-0.1
  		l0.51-1.02l0.62-0.45l0.26-0.02l2.37,0.85l0.9,0.27l0.3-0.03l0.6-0.35l-0.24-0.72l-1.17-1.83l-1.09-1.49l-0.49-0.9l-0.26-0.81
  		l-0.34-0.49l-0.81-0.42l-2.28-2.05l-0.11-0.26l-0.3-0.39l-0.84-0.44l-1.01-0.1l-0.35,0.2l-0.67,0.64l-0.34,0.13l-0.78-0.27
  		l-0.02-0.23l0.62-0.7l1.64-1.03l1.29,0.17l1.3,0.62l0.07,0.16l0.54,0.48l1.31,0.92l0.32,0.33l-0.75,0.24l-0.04,0.16l0.35,0.42
  		l0.98,0.64l0.45,0.07l0.88-0.11l0.44,0.16l0.66,0.7l1.52,2.04l0.74,0.7l0.19,0.34l0.32,1.02l0.57,0.27l0.98-0.04l0.91-0.29
  		l0.84-0.55l2.1-1.68l2.59-0.52l0.74-0.48l1.51-1.24l0.96-0.42l0.52-0.36l0.72-0.86l0.91-1.35l0.67-0.77l0.43-0.19l1.28,0.03
  		l0.78-0.23l1.37-0.97l0.21-0.33l0.47-1.36l0.73-2.41l0.26-1.48l-0.2-0.54l-0.99-1.41l-0.68-0.6l-1.82-1.23l-1.35-1.16l-0.9-0.86
  		l-0.72-0.96l-0.32-0.89l-0.01-0.42l0.09-0.47l0.66-1.77l0.01-0.3l-0.18-0.03l-0.37,0.24l-0.62,0.58l-0.3,0.08l-0.3-0.16l-1.43-2.04
  		l-0.25-0.17l-0.07-0.15l0.32-0.46l-0.17-0.28l-0.43-0.37l-0.75-0.94l0.03-0.32l0.42-0.15l0.85,0.01l1.58-0.42l0.87,0.11l0.84,0.53
  		l0.77,0.06l0.7-0.41l1.62-0.55l0.95-0.46l2.07-1.49l0.19-0.52l0.06-0.75l-0.09-0.55l-0.57-0.7l-0.42-0.33l-0.8-0.23l-1.78-0.2
  		l-2.28-0.07l0.05-0.38l1.63-0.7l1.24-0.68l2.38-1.73l0.06-0.28l-0.47-0.69l-0.29-0.19l-2.69-0.89l-1.85-0.27l-1.67,0.4h-0.65
  		l-0.16-0.24l0.33-0.48l-0.2-0.53l-0.66-1.15l-2.8-0.72l-0.95-0.54l1.02-0.35l1.16-0.21l0.89-1.03l0.14-0.61l-2.06-0.74l-1.07-0.06
  		l-2.07,0.14l-4.72-0.27l-0.96,0.06l-2.12-0.69l-2.73-1.35l-0.65-0.13l-2.75-0.09l-1.59,0.22l-1.57,0.46l-1.32-0.01l-1.08-0.47
  		l-0.93-0.2l-0.78,0.06l-0.23,0.15l0.31,0.24l0.43,0.62l-0.02,0.45l-0.46,1.45l0.17,0.49l0.44,0.57l0.12,0.49l-0.2,0.42l0.02,0.47
  		l0.24,0.53l0.06,0.38l-0.12,0.24l-0.13,0.85l0.04,0.53l0.34,1.29l0.94,0.88l0.28,0.14l1.09-0.05l1.88,1.08l0.23,0.28l-0.26,0.78
  		l-0.02,0.48l-0.17,0.39l-0.78,0.19l-0.63,0.61l-0.18,0.55l-0.02,0.4l2.01,0.79l0,0.25l-3.43,0.47l-0.53-0.07l-0.73-0.41l-0.56,0
  		l-0.22,0.06l-0.38,0.6l-0.68,0.43l-0.39,0.42l-0.37,0.6l-0.09,0.76l0.19,0.92l-0.07,1.03l-0.49,1.71l-1.38,3.72l-0.27,0.39
  		l-3.24,3.19l-0.24,0.36l-0.65,1.26l-0.24,0.92l-0.28,1.92l-0.21,0.49l-0.41,0.45l-0.61,0.41l-0.57,0.23l-0.53,0.05l-0.94,0.66
  		l-1.34,1.27l-1.54,1.2l-0.45,0.16l-0.33-0.06l-0.27-0.2l-0.22-0.33l-0.45-1.58l-0.24-0.44l-4.19-3.88l-1.67-2.02l-0.99-2.67
  		l-0.1-0.56l0.05-2.57l-0.35-1.37l0.12-0.41l0.94-0.74l0.22,0.06l0.53,1l0.29,0.22l1.01,0.13l0.83-0.09l0.33-0.27l0.21-0.48
  		l0.12-0.67l0.03-0.85l-0.15-0.64l-0.32-0.43l-0.38-1.28l-0.44-2.12l-0.65-1.51l-1.53-1.52l-0.77-0.58l-2.71-1.54l-1.49-1.14
  		l-1.17-1.25l-0.87-0.68l-1.19-0.05l-0.68,0.21l-0.61,0.42l-1.69,2.11l-0.97,1.04l-0.45,1.23l-0.26,1.76l-0.27,1.13l-0.85,1.62
  		l-0.1,0.46l0.14,1.07l-0.15,0.45l-0.91,1.36l-0.94,0.87l-0.34,0.48l-0.15,0.51l-0.26,0.19l-0.36-0.14l-0.32-0.35l-0.27-0.56
  		l-0.36-0.24l-0.53-0.7l-0.89-0.55l-0.38-0.62l0.41-2l0.13-1.14l-0.27-1.43l0.03-0.5l0.59-0.65l-0.09-0.26l-0.91-0.97l-1.33-2.35
  		l-4.15-2.67l0.16-0.25l0.59-0.01l0.76-0.24l0.28,0.05l-0.21,0.33l0.37,0.24l0.95,0.15l0.59-0.35l0.23-0.84l0.39-0.68l0.54-0.53
  		l0.66-0.26l0.78,0l0.82-0.18l-0.29-0.28l-0.88-0.43l-0.94-0.17l-1,0.1l-1.39-0.1l-2.85-0.41l-1.28,0.26l0.11-0.18l1.03-0.65
  		l1.13-0.58l0.16-0.25l-0.43-0.11l-1.54-0.07l-1.25,0.52l-1.62,1.04l-1.58,0.21l-2.5-1.08l-0.41-0.46l0.23-0.29l-0.68-0.29
  		l-1.53-0.15l-1.26,0.36l-1.35,0.12l-0.44-0.05l-0.28-0.2l1.15-0.69l3.93-2.68l1.82-1.39l0.78-0.83l-0.68-0.5l-0.58-0.2l-2.68,0.02
  		l-0.56-0.31l0.48-0.71l0.77-0.76l0.36-0.52l0.25-0.07l0.69,0.64l0.74,0.42l1.06,0.4l1.15,0.16l2.04-0.23l0.37-0.21l0.06-0.25
  		l-0.76-0.83l-0.52-1.01l-0.38-0.41l-0.5-0.23l-0.46,0.16l-0.43,0.55l-0.48,0.14l-0.54-0.27l-0.21-0.23l0.3-0.74l-0.1-0.34
  		l-1.39-1.57l-0.85-1.75l-0.34-0.41l-0.28-0.25l-1.51-0.66l-0.63-0.39l-1.19-1.22l-1.5-0.61l-0.17-0.22l0.5-0.54l0.15-0.45
  		l0.18-2.36l-0.06-0.82l-0.49-2.16l-0.29-0.59l-0.41-0.55l-1.9-1.95l-1.27-0.93l-1.42-0.75l-1.57-1.08l-0.16-0.57l0.26-0.66
  		l0.04-0.41l-0.54-0.37l-2.32-0.08l-0.72,0.2l-1.15-0.1l-1.44-1.31l-0.1-1.05l-0.56-1l-0.46-0.12l-1.04,0.06l-1.28,0.31l-2.65,0.94
  		l-2.61,1.99l-0.88,1.43l-1.87,1.36l-0.29,0.39l0.35,0.73l0.48,0.18l0.84,0.16l1.93-0.02l0.33,0.21l-0.35,0.49l-0.98,1.92
  		l-0.58,0.28l-0.78-0.15l-1.06-0.51l-0.62-0.5l-0.59-0.27l-0.57-0.05l-0.65,0.27l-1.11,0.89l-1.13,1.02l-0.34,0.52l0.22,0.98
  		l-0.21,0.27l-0.5,0.25l-0.17,0.25l0.46,0.89l-0.18,1.04l-0.5,1.73l0.02,1.25l1.6,2l0.85,0.56l0.6,0.06l2.36-0.91l0.17,0.05
  		l-0.86,1.19l0.07,0.36l0.86,0.68l-1.43-0.28l-0.63,0.09l-0.87,0.3l-0.6,0.46l-0.33,0.63l-1.76,2.18l-0.12,1.28l0.07,0.5l0.5,1.29
  		l1.88,2.01l0.82,1.01l0.44,0.8l0.58,0.61l0.72,0.43l0.96,0.27l1.21,0.1l1.01,0.34l0.81,0.57l1.68,0.75l3.95,1.34l0.28-0.11
  		l0.64-0.96l0.31-0.11l0.36,0.11l1.01,0.99l0.8,0.99l0.68,0.64l0.57,0.29l0.91,0.14l1.25-0.01l0.84-0.16l1.02-0.6l1.22-0.32
  		l0.99,0.57l0.08,0.45l-0.17,0.66l0.05,0.36l0.89,0.11l-0.17,0.3l-0.98,0.87l-1.53,1.05l-0.61-0.39l0.16-0.42l1.59-1.82l-0.06-0.21
  		l-1.98,0.61l-2.54,0.52l-0.83,0.42l-0.19,0.57l-0.07,0.5l0.25,1.34l0.28,0.22l1.18,0.01l-0.02,0.19l-1.29,1.09l-2.01,1.36
  		l-0.73,0.69l-0.32,1.6l0.12,0.41l0.91,0.48l2.2-0.26l1.28-0.36l0.62-0.53l0.45-1.01l0.35-1.38l0.13-0.22l0.24-0.05l0.35,0.11
  		l0.38,0.34l0.42,0.57l0.29,0.65l0.16,0.72l0.02,0.8l-0.14,1.44l0.27,0.78l0.32,0.14l1.32,0.07l-0.29,0.29l-1.42,0.82l-2.32,1.01
  		l-1.44,1.09l-1.32,1.5l-1.09,1.02l-0.85,0.54l-2.18,1.73l-0.98,0.42l-0.79-0.13l-1.44-0.48l-0.92,0.34l-1.26,0.06l-0.36,0.15
  		l-0.29,0.35l-1.6,4.11l0.14,0.48l1.43,1.35l0.62,1.33l0.79,2.25l0.31,1.41l-0.52,1.57l-0.28,2.45l-0.38,0.44l-0.76,0.36l-1.13,0.11
  		l-2.93-0.51l-0.54,0.21l-0.07,0.2l0.25,0.75l2.06,2.05l0.24,0.5l-0.37,1.04l-0.08,0l-0.13-1.04l-0.61-0.72l-3.99-3.52l-0.51-0.67
  		l-0.02-0.22l0.15-0.16l0.45-0.1l1.14,1.03l1.01,0.06l0.64-0.36l0.55,0.11l0.78,0.47l0.78,0.16l1.33-0.13l0.43,0.04l0.48-0.1
  		l0.38-0.14l0.32-0.34l0.1-0.61l0.02-0.85l-0.1-0.57l-0.95-0.76l-0.24-0.09l-0.58,0.05l-1.27,0.38l-0.04-0.13l0.74-1.5l-0.21-0.26
  		l-1.34,0.26l-1.12,0.39l-1.09-0.01l-0.23-0.25l-0.13-1.24l-0.23-0.41l-1.32-1.51l-0.02-0.63l1.2-1.8l0.25-0.56l0.23-1.64l1.14-4.73
  		l0.55-1.33l-0.89,0.18l-3.06,1.25l-0.18,0.22l-0.27,0.74l-0.32,0.32l-0.51,0.21l-1.09,0.14l-0.03-0.33l2.06-2.64l0.38-0.69
  		l0.04-0.3l-1.66,0.88l-2.94-0.07l-0.19-0.14l-0.61-0.99l-0.54-0.66l-1.09-1.1l-0.59-0.38l-0.63-0.25l-0.6,0.02l-0.56,0.3l-0.77-0.1
  		l-1.59-0.74l-0.81,0.13l-0.12,0.19l0.23,0.89l0.98,0.91l-2.5,0.6l-1.08,0.42l-2.11-0.94l-0.45-0.02l-0.34,0.18l-0.74,0.11
  		l0.15,0.47l1.19,1.57l0.35,0.33l0.58,0.99l-0.04,0.25l-0.96-0.25l0,0.21l0.52,0.77l2.07,2.05l0.69,0.29l1.66-0.73l1.47-0.36
  		l1.62,0.25l1.77,0.86l1.09,0.67l0.41,0.48l0.15,0.37l-0.15,0.6l0.01,0.41l-0.33,0.43l-0.67,0.44l-1.52,0.71l-1.28-0.2l-2.72-1.15
  		l-0.39-0.4l-0.72-0.45l-2.97-3.16l-1.05-0.84l-0.77-0.38l-0.49,0.09l-0.25,0.35l-0.02,0.61l0.16,0.5l0.82,0.79l1.17,0.8l0.43,0.42
  		l0.04,0.28l-0.34,0.13l-2.06,0.26l-0.96,0.51l-0.92,0.27l-0.94,0.1l-0.97-0.07l-1.24-0.31l-1.51-0.56l-2.53-0.44l-3.71-0.34
  		l-2.05-0.01l-0.54,0.3l-0.81,0.15l-1.09-0.01l-0.93,0.15l-1.44,0.48l-0.59,0.05l-3.86,1l-1.13-0.22l-1.64-0.53l-1.46-0.12
  		l-1.28,0.3l-0.93-0.04l-0.58-0.38l-2.55-0.71l-1.26-0.59l-1.52-1.27l-2.54-1.79l-1.27-0.74l-1.54,0.67l-2.06,0.41l-2.46,0.14
  		l-1.32-0.15l-1.15-0.32l-1.19-0.85l-0.28-0.4l0.21-0.95l-0.14-0.24l-0.98-0.32l-0.92,0.09l-0.27-0.08l-0.21-0.77l-0.19-0.11
  		l-0.29,0.05l-0.42,0.28l-0.49-0.15l-0.79-0.47l-1.54-1.22l-0.43-0.65l-0.24-1.76l-0.7-2.06l-0.56-0.94l-0.67-0.67l-0.95-0.54
  		l-1.83-0.61l-1.25-0.2l-1.35,0.3l-3.28,1.18l-0.99,0.15l-5.1,1.78l-2.78,0.59l-4.61,0.56l-0.27,0.19l-2.49,3.21l-0.65,1.18
  		l0.27,0.29l2.68,1.45l0.38,0.34l0.52,0.06l1.32-0.28l0.65,0.09l2.47-0.16l0.48-0.42l-0.48-0.72l-0.06-0.49l1.03-0.66l1.01,0.11
  		l1.69,0.4l1.28-0.11l0.87-0.62l0.82-0.4l0.78-0.18l0.62-0.01l1.45,0.44l0.35-0.46l0.2-1.05l0.72-0.82l1.86-0.88l1.77-0.69l0.8-0.19
  		l1.33,0.37l0.2,0.28l-0.26,0.96l-0.64,0.75l-0.64,0.47l-0.9,0.52l-0.78,0.25l-1.17,0.1l-1.12,0.93l-0.21,0.45l0.04,1.28l-0.3,0.07
  		l-0.63-0.2l-1.12-0.1l-0.36,0.13l-0.67,0.9l-0.38,0.24l-0.73,0.21l-0.6,0.11l-1.1-0.03l-0.84,0.21l-1.07,0.47l-0.8,0.17l-0.53-0.14
  		l-2.12,0.39l-0.31-0.07l0.09,0.35l0.5,0.78l-0.29,0.75l-1.08,0.73l-0.57,0.55l-0.12,1.25l0.15,0.46l0.75,0.88l0.94,0.75l0.87,1.09
  		l0.1,0.53l-0.11,0.66l0.06,0.62l0.64,1.6l0.72,1.06l1.38,1.02l0.3,0.35l-0.54,0.69l0.21,0.46l0.59,0.66l0.82,1.34l-0.37-0.01
  		l-1.09-0.7l-0.61-0.23l-0.14,0.25l-0.27,0.06l-0.4-0.13l-1.07-0.95l-0.83,0.27l-0.18,0.32l0.05,2.1l0.08,0.63l0.17,0.41l1.23,1.72
  		l2.41,2.73l0.16,0.37l-0.27-0.05l-0.69-0.46l-0.9-0.8l-1.89-2.05l-0.47-0.66l-1.66-1.38l-0.37-0.52l-0.4-0.37l-0.44-0.23
  		l-0.47-0.46l-0.51-0.69l-2-1.73l-0.34-0.42l0.31,0.03l0.97,0.47l1.04,0.1l1.11-0.27l0.82-0.36l0.52-0.45l0.17-0.51l-0.18-0.57
  		l-0.49-0.77l-3.02-2.1l-1.22-1.15l-0.85-1.55l-0.17-0.1l-0.56-0.12l-0.29,0.34l-0.84,2.09l-0.31,0.22l-0.33-0.23l-0.5-0.8
  		l-0.15-0.55l-0.23-1.51l-0.37-0.78l-0.36-0.28l-1.2-0.27l-3.42-0.04l-0.47-0.27l-0.62-0.99l-0.6-0.66l-0.61-0.11l-0.27-0.21
  		l-0.89-1.29l-0.26-0.23l-0.24,0.01l-0.96,0.55l-1.31,0l-3.65,1.76l-1.56,0.6l-0.82,0.05l-0.57-0.15l-0.31-0.35l-0.83,0.11
  		l-1.35,0.56l-1.05,0.28l-1.14-0.01l-3.29,0.37l-1.14,0.01l-0.66,0.17l-1.02,0.5l-0.57,0.04l-3.17,0.03l-1.65-0.1l-1.18-0.22
  		l-3.93,0.03l-1.78-0.1l-1.33-0.29l-1.05-0.12l-0.77,0.06l-1.37-0.29l-1.97-0.64l-1.63-0.27l-1.3,0.1l-1.03-0.18l-1.31-0.76
  		l-1.23-0.44l0.06-0.31l1.9-1.09l0.29-0.29l-0.17-0.95l0.09-0.29l0.41-0.4l2.31-0.92l0.73-1.09l4.13,0.33l1.51-0.28l0.36-0.24
  		l0.27-0.34l0.47-1.35l-0.2-0.23l-0.53,0l-0.34-0.3l-0.16-0.61l-0.9-1.09l-1.65-1.58l-1.74-1.27l-3.15-1.53l-2.07-0.62l-1.71-0.72
  		l-1.59-0.47l-1.48-0.21l-0.65-0.01l-0.44,0.43l-1.94-0.25l-0.65,0.02l-0.07,0.24l1.57,1.51l0.05,0.27l-1.38-0.14l-0.94-0.4
  		l-0.76-0.1l-1.06,0.03l-4.01-0.55l-0.89,0.13l-0.8-0.09l-0.71-0.32l-4.37-0.96l-2.23-0.64l-1.78-0.74l-1.5-0.77l-2.18-1.33
  		l-1.04-0.34l-8.29-1.27l-2.42-0.57l-1.28-0.6l-4.4-2.93l-1.24-0.65l-3.15-1.22l-1.64-0.51l-1.78-0.33l-2.76-0.28l-2.67,0.12
  		l-2.67-0.13l-0.68,0.13l-1.44-0.16l-0.58,0.13l-0.43,0.41l-0.28,0.67l-0.29,1.59l-0.58,1.36l-0.3,0.32l-0.95,0.35l-0.83,1.14
  		l-0.57,0.46l-0.68,0.18l-3.71,0.07l-0.52,0.21l-1.91-0.09l-0.74-0.22l-0.39-0.31l-0.08-0.37l0.23-0.44l0.47-0.58l2.19-2.38
  		l0.12-0.55l-1.89-0.67l-0.48-0.49l-0.55-2.27l0.04-0.97l0.22-1.2l-0.03-0.74l-0.49-0.46l-0.44-0.15l-0.72,0.15l-0.56,0.37
  		l-0.33,0.56l-1.75,0.58l-0.32,0.23l0.23,0.21l0.36,0.11l0.48,0l0.8,0.23l-0.22,0.33l-0.8,0.49l-0.67,0.62l-0.53,0.75l-0.41,0.4
  		l-0.29,0.04l-0.73-0.21l-0.22,1.08l0.07,0.37l-1.06,1.05l-0.1,0.55l0.8,0.9l0.8,1.29l-0.04,0.77l-1.81,1.17l-1.16-0.03l-1.71-0.42
  		l-1.51-0.58l-1.32-0.72l-1.57-1.17l-3.05-2.77l-0.61-0.7l-0.62-1.15l-0.63-1.59l-0.79-1.55l-1.78-2.69l-0.74-0.86l-1.27-1.1
  		l-3.17-2.26l-0.92-0.49l-1.09-0.38l-0.42,0.11l-0.73,0.65l-0.34,0.67l-0.02,0.93l0.41,0.32l1.12,0.52l-0.46,0.52l0.08,0.2
  		l0.51,0.34l2.44,0.5l-0.68,0.58l-2.79,0.92l-1.54,0.81l-0.68,1.82l-0.23,0.41l-2.69,2.26l-1.24,0.89l-1,0.58l-0.7,0.17l-0.4-0.24
  		l-0.29-0.5l-0.18-0.75l0.16-0.49l1.68-0.64l0.18-0.3l0.28-1.03l-0.13-0.04l-0.72,0.49l-0.41,0.43l-0.64,0.35l-1.31,0.4l-2.59,0.43
  		l-4.59,1.6l-1.99,0.96l-1.37,1.29l-1.22,1.32l-1.81,2.41l-0.33,0.52l-0.47,1.13l-0.1-0.66l-0.04-1.63l-0.23-0.98l-0.42-0.33
  		l-0.42-0.13l-0.41,0.07l-0.4,0.34l-0.71,1.02l-0.26,0.04l0.18-0.8l-0.34-0.3l-1.85-0.39l-0.58,0.03l-1.32,0.4l-0.27,0.45l0.33,0.19
  		l-0.05,0.26l-0.39,0.53l-0.73,0.68l-1.81,0.83l-0.67,0.49l-1.21,0.52l-1.58,0.39l-1.46,0.91l-0.31,0.56l-0.12,0.44l0.05,0.59
  		l0.22,0.74l0.29,0.39l1.37,0.09l0.08,0.21l-0.38,0.41l-1.08,0.49l-5.04-0.48l-0.27,0.18l0.14,0.23l1.07,0.64l0.46,0.44l-0.5,0.1
  		l-1.28-0.44l-0.21-0.27l-0.38-0.82l0.33-0.63l1.27-1.19l1.17-0.91l1.02-1.08l1.27-1.55l1.1-0.8l1.73-0.2l1.27-0.52l1.13-0.85
  		l0.57-0.56l1.18-1.72l0.43-0.26l0.63-0.22l3.28-0.45l1.4-0.26l0.83-0.29l2.09-0.24l1.08-0.29l1.04-0.52l2.1-1.41l6.83-3.28
  		l0.93-1.15l0-0.47l-0.71-0.95l-0.44-0.38l-0.46,0.01l-1.41,1.3l-0.4,0.23l-0.83-0.06l-1.11,0.14l-0.84-0.18l-1.02-0.47l-0.86-0.21
  		l-1.41,0.24l-2.2,1.14l-0.54,0.51l-0.35,0.58l-0.88,1.1l-0.67,0.09l-0.87-0.35l-1.03,0.09l-1.19,0.54l-2.97,1.94l-1.93,0.73
  		l-0.58-0.05l-0.86-0.66l-1.3,0.21l-0.41,0.17l-0.28,0.3l0.02,0.13l0.75,0.08l0.56,0.28l0.07,0.23l-0.41,0.18l-0.63,0.05l-2.64-0.11
  		l-0.63,0.32l-0.95,1.82l-0.47,0.56l-0.66,0.55l-1.1,0.32l-1.54,0.1l-1.84,0.55l-2.14,1l-1.91,0.72l0.08-0.41l1.23-1.61l0.6-0.61
  		l0.57-1.17l-0.17-0.31l-1.5-0.44l-0.48-0.44l-0.47-0.77l-0.44-0.45l-1.41-0.19l-0.36,0.24l-0.19,0.49l-0.18,0.91l0.04,0.4
  		l0.29,1.01l-0.31,0.15l-3.02-0.27l-2.43,0.27l-0.49,0.27l-0.26,0.36l-0.21,1.51l-0.32,0.24l-1.74-0.44l-0.97,0.69l-0.64-0.3
  		l-1.84,2.97l0.51,0.45l0.9,0.47l0.77,0.27l0.64,0.06l-0.12,0.28l-0.89,0.11l-1.5-0.01l-0.44,0.12l-0.12,0.27l0.37,0.7l0.16,0.14
  		l2.01,0.37l1.71,0.74l0.23,0.19l1.48,1.98l-0.23,0.15l-0.87-0.18l-4.25-2.01l-2.15-0.73l-3.17-0.22l-1.84,0.12l-2.97-0.91l-1.6-0.2
  		l-5.13-1.91l-2.18-0.88l-1.37-1l-3.36-1.45l-4.14-2.96l-6.7-1.59l-3.61,0.29l-3.83-0.49l-1.2-0.23v128.47l2.02,0.52l2,0.52
  		l0.61-1.03l2.16,0.79l1.88,0.69l1.2-0.88l1.3-0.95l1.76-0.07l1.96-0.08l1.31-0.05v0.81l-0.49,1.35l-0.41,1.12l1.19,1.14l1.47,0.6
  		l1.38,0.56l0.62,1.55l1.5,1.19l1.15,0.91l1.1,0.87l1.57,1.24l1.1,0.87l1.46,1.15l0.87,0.68l0.42,1.33l0.5,1.59l-0.31,0.94
  		l0.69,0.15l1.36-1.05l1.27-0.65l1.57-0.81l1.06-0.55l1.98-0.03l0.94-1.59l0-2.23l1,0.04l0.59-0.3l0.26-0.66l-0.63-0.9l1.89-0.42
  		l1.37-0.3l1.95-0.83l1.91-0.81l0.91,0.63l0.9,0.61l1.77,1.46l0.12,0.35l-0.12,0.68l-0.17,0.68l1.07,1.91l0.31,0.21l0.88,0.26
  		l1.05,0.63l0.47,0.55l1.53,0.87l0.26,0.36l0.14,0.58l0.25,0.5l0.29,0.36l0.28,0.51l0.66,0.6l1.26,0.67l0.87,0.47l1.22,0.65
  		l1.24,1.33l1.07,1.15l1.22,1.14l-0.18,0.91l1.24,1.4l1.3,1.78l1,1.57l0.71,0.88l0.85,1.27l1.04,1.55l1.19,1.76l0.91,1.13l1.19,1.58
  		l0.59,0.94l-0.4,0.64l-0.49,0.79l1.52,0.38l1.06,0.26l-0.26,0.88l-0.35,1.16l1.19,0.46l0.81,0.31l-0.17,0.61l0.45,0.69l0.07,1.14
  		l1.46-0.07l0.64-0.03l0.87,0.5l1.15,0.66l1.15,0.62l0.98,0.53l1.29,0.31l1.59,0.45l0.78,0.92l1.45,0.39l0.54,1.29l1.67,0.47
  		l0.98-0.31l0.35,0.52l0.28,0.6l0.07,0.76l-0.09,0.74l0.04,0.09l-0.34,0.79l-0.21,0.73l-0.08,0.67l0.01,0.74l0.11,0.81l0.3,1.07
  		l0.5,1.33l-0.09,1.11l-0.53,0.81L714,802.6l0.36,0.59l0.53-0.63l0.91-1.63l0.33-0.8l0.19-1.16l0.03-1.36l0.14-0.32l0.25,0.72
  		l0.26,0.47l0.28,0.23l0.29,0.02l0.31-0.18l0.59-0.12l-1.85,2.14l-0.92,1.36l-0.49,0.98l-1.35,2.27l-0.27,0.75l-0.66,1.08
  		l-0.74,0.35l-0.36,0.52l-0.16,0.41l-0.16,0.94l-0.04,0.69l-0.31,0.68l0.28,0.81l0.9,0.94l1.74,1.03l1.56-0.52l0.87-0.11l1.42,0.07
  		l-1.39,0.75l-2.13,0.37l-0.17,0.32l-0.19,1.5l-1.24,1.25l-0.86,1.65l0.61,0.8l1.59,0.9l1.37,0.28l0.76,1.59l1.14,0.91l1.04,0.94
  		l0.85-1.09l1.5-0.53l0.22-0.28l0.15-0.7l0.07-1.12l0.2-0.75l1.28-1.57l0.36-0.23l0.46-0.51l0.13,0.02l0.14,0.2l0.32-0.08l1.56-1.02
  		l0.45,0l1,0.7l-0.24,0.15l-0.78,0.15l-0.52,0.25l-0.26,0.34l-0.15,0.38l-0.04,0.42l0.14,0.57l0.63,1.22l0.63,0.56l0.75,0.34
  		l0.35,0.06l0.85-0.22l1.36,0.08l0.78,0.43l1.39,1.33l0.19,0.63l-1.28-1.08l-0.45-0.55l-1.33-0.46l-0.56-0.02l-1.02,0.57l-0.54-0.12
  		l-2.45-1.48l-0.61-0.11l0.46-1.22l-0.03-0.45l-0.68-0.12l-0.72,0.27l-0.78,0.59l-0.48,1.23l0.35,1.05l0.41,0.86l1.79,0.45
  		l1.82,0.96l1.08,1.18l0.61,3.01l0.73,0.58l2.18-0.92l0.44-0.04l-0.47,0.6l-0.2,0.59l-0.54,2.07l-0.66,2.13l0.03,0.82l0.26-0.06
  		l0.48-0.45l1.23-1.4l0.8-0.22l0.03,0.06l-0.68,0.62l-0.26,0.42l-0.07,0.29l0.26,0.96l-0.07,0.28l-2.7,1.8l1.38,1.81l0.77,2.36
  		l0.9-1.82l0.78-2.26l0.57-1.13l0.36-0.43l0.66-0.33l1.29-0.28l0.35-0.18l3.47-3.1l0.34-0.23l0.26-0.33l0.18-0.43l0.06-0.39l-0.11-1
  		l0.09,0l0.35,0.66l0.13,0.38l0,0.34l-0.37,0.72l-1.62,1.36l-0.18,0.45l0.12,0.46l0.28,0.71l0.28,0.27l1.59,0.7l0.31,0.49l0.05,0.45
  		l1.2,1.28l0.33,0.58l-0.21-0.05l-0.74-0.68l-0.62-0.71l-0.5-0.75l-0.41-0.41l-1.41-0.27l-0.56-0.91l-1.65,0.44l-0.94,0.66
  		l-1.04,0.5l-1.03,0.7l-0.4,1.18l-0.13,1.08l1.1-0.03l-0.86,1.28l-0.28,0.65l-0.05,0.51l0.16,1.14l0.27,0.78l0.6,0.68l0.16,0.43
  		l0.12,0.17l0.38,0.14l0.2-0.01l0.3-0.4l0.28-0.55l1.12-1.3l0.88-0.32l2.57-0.1l2.89,0.15l-0.37,0.12l-1.96,0.25l-2.63,0.18
  		l-0.56,0.13l-0.62,0.38l-1.79,2.02l-0.48,0.94l-0.21,0.83l0.05,1.31l0.99,0.71l1.26,1.09l0.71,0.49l0.75,0.33l1.77,0.54l0.37,0.01
  		l0.45-0.3l2.77-0.24l0.58-0.56l0.38-1.02l0.28,1.07l-0.31,1.05l0.28,0.27l0.59-0.09l0.37,0.14l-0.19,0.34l-0.63,0.44l-0.38,0.47
  		l0.03,0.5l0.18,0.08l1.03,0.06l3.28-0.49l0.65,0.07l0.05,0.23l-1.43,0.22l-1.45,0.45l-1.49,0.19l-0.26,0.21l-0.02,0.42l0.38,0.22
  		l1.41,0.07l1.2,0.28l0.59,0.01l0.5,0.1l1.06-0.26l0.57,0.03l0.26,0.33l0.37,0.15l0.47-0.02l0.26-0.22l0.2-0.97l0.26-0.68l0.14-0.16
  		l0.11,1.27l0.16,0.3l0.26,0.11l2.24,0.23l1.27-0.41l0.62-0.86l0.36-0.82l0.1-0.58l-0.15-0.51l0.13-0.51l0.62-0.69l-0.13,0.52
  		l0.15,1.2l0.03,0.54l-0.07,0.35l-0.62,1.1l-1.01,1.31l0.11,0.61l0.49,0.09l0.44,0.64l-0.01,0.44l1.26,2.04l0.02,0.58l0.67,0.69
  		l1.85,1.64l0.6,0.33l1.11,0.07l1.18-0.23l0.7-0.67l0.62-0.24l0.11-0.18l0.01-0.85l0.12-0.26l0.77-0.87l0.02-0.34l-0.7-0.9l0.1-0.05
  		l0.45,0.16l0.46,0.32l0.32,0.41l0.03,0.21l-0.33,0.49l-0.52,0.29l-0.16,0.39l0,2.04l0.25,0.64l0.48,0.57l0.47,0.29l0.46,0.02
  		l1.06-0.27l-0.25,0.26l-1.06,0.7l-0.66,0.08l-0.25-0.55l-0.64-0.88l-0.31-0.2h-0.28l-0.26,0.27l-0.25,0.54l0.21,0.64l0.68,0.74
  		l0.48,0.44l0.28,0.13l2.77,0.93l0.19-0.06l0.6-0.53l0.32,0.1l0.53-0.19l0.11-0.63l-0.12-0.31l0.1-0.36l1.16-1.12l0.07,0.07
  		l-0.09,0.32l-0.27,0.59l-0.21,1.61l-0.14,0.57l-0.19,0.17l-0.04,0.17l0.12,0.17l0.86-0.05l1.34,0.28l0.43-0.08l0.72-0.76
  		l-0.29,0.83l-0.29,0.33l-1.01,0.02l-0.98,0.15l-0.38,0.19l0.32,0.45l-0.04,0.78l0.12,0.2l0.27,0.09l0.59-0.1l0.02,0.13l-0.27,0.37
  		l-0.07,0.31l0.26,0.69l0.08,0.14l0.12,0.03l0.12-0.17l0.19-0.49l0.21-0.24l0.33-0.15l0.32,0l0.82,0.5l0.32,0.38h26l3.59,0h82.6
  		l3.59,0h82.6l3.59,0h28.73l1.99,0.02l0.03-2.3l0.03-1.81l1.82,0.22l0.54,0.33l0.17,0.16l-0.05,0.5l0.15,1.52l0.33,1.26l0.76,1.51
  		l0,0l0.06,0.59l0.25,0.37l0.46,0.34l1.74,0.42l3.02,0.49l1.71,0.56l0.4,0.63l0.81,0.25l1.21-0.12l0.85-0.26l0.72-0.6l1.01-0.13
  		l0.87,0.04l0.87,0.06l0.47,0.09l1.34,0.48l0.88,0.39l1.26,0.72l0.7,0.32l0.33,0.75l0.39,0.96h0.56l0.42-0.56l1.07-0.1l1.4,0.39
  		l1.24,1.12l1.78,1l1.09,0.5l1.1,0l1.4-0.49l1.49-0.96l1.07-0.17l0.64,0.09l0.36,0.75l0.45,0.28l1.16-0.09l2.42,0.14l1.92-0.2
  		l0.44,0.43l0.39,0.67l0.78,0.21l1.06-0.21l1.67,0.15l0.25-0.12l0.82-0.85l0.09-0.07l0.78-0.24l0.26-0.41l0.16-0.49l0.37-0.81
  		l0.26-0.33l0.06-0.37l-0.13-0.4l0.04-0.38l0.2-0.36l0.96-0.51l2.63-0.92l0.09,0.12l-0.46,0.71l-0.49,1.35l-0.17,0.04l-0.16,0.17
  		l-0.14,0.29l0.37-0.02l0.89-0.33l0.41-0.17l0.1-0.33l0.14-0.66l0.19-0.37l0.24-0.07l0.16-0.32l0.08-0.57l0.2-0.34l0.31-0.11
  		l0.13-0.2l-0.02-0.28l0.08-0.75l0.06-0.35l0.27-0.51l0.38-0.16l0.52,0.03l0.36,0.26l0.2,0.49l0.05,0.4l-0.09,0.32l-0.51,0.58
  		l-1.45,1.38l-0.15,0.38l0.13,0.2l0.08,0.29l0.17,0.04l0.32-0.25l0.14-0.23l-0.04-0.2l0.11-0.26l0.27-0.32l0.21-0.1l0.16,0.13
  		l0.26-0.06l0.36-0.24l0.65-0.12l0.13-0.01l0.8-0.93l0.25-0.54l0-0.47l-0.32-0.48l-0.65-0.5l-0.19-0.54l-0.02-0.27l-0.13-0.34
  		l0.14-0.13l0.4,0.09l0.34-0.05l0.27-0.18l0.49-0.04l0.4,0.03l0.48,0.43l1.72,0.42l0.64,0.22l0.79,0.23l0.2,0.08l0.13,0.25
  		l0.29,0.11l0.45-0.04l0.48,0.08l0.81,0.38l0.74,0.26l1.17-0.03l0.64-0.11l1.02,0.18l0.57,0l0.58-0.3l0.5-0.08l0.42,0.14l0.18,0.19
  		l0.34,0.26l1.02-0.13l0.23,0.07l0.31,0.29l0.05,0.19l0.16,0.29l0.39,0.55l0.09,0.19l0.27,0.23l0.22,0.53l0.16,0.84l0.18,0.51
  		l0.2,0.19l0.09,0.22l0.19,0.88l0.46,1.01l0.69,0.98l0.92,0.96l1.15,0.64l1.37,0.32l1.22,0.07l1.07-0.17l1.42-0.09l1.77-0.01
  		l0.92,0.11l0.06,0.23l-0.17,0.34l-0.4,0.44l-0.04,0.29l-0.02,0.38l-0.32,0.5l-0.17,0.3l0.05,0.21l-0.09,0.28l-0.24,0.35l0.14,0.47
  		l0.52,0.59l0.64,0.47l0.75,0.36l0.47,0.37l0.19,0.39l0.21,0.21l0.23,0.04l0.21,0.18l0.19,0.32l-0.2,0.56l-0.59,0.81l-0.26,0.52
  		l0.06,0.24l-0.5,0.88l-0.03,0.35l0.21,0.24l0.8,0.34l0.26,0.28l0.21,0.04l0.15-0.2l0.32-0.09l0.48,0.02l0.45,0.14l0.42,0.27
  		l0.15,0.27l-0.12,0.28l-0.22,0.1l-0.65-0.04l-0.36,0.14l-0.22,0.3l-0.06,0.8l0.18,0.34l0.1,0l0.15-0.16l0.19-0.33l0.2-0.04
  		l0.22,0.25l-0.23,0.52l-0.69,0.78l-0.18,0.25l0,0.35l0.57,0.63l0.21-0.06l0.14-0.16l0.22-0.19l0.75-0.1l0.91-0.27l0.59-0.06
  		l0.4,0.24l0.26,0.34l0.03,0.36l-0.19,0.44l-0.02,0.39l0.14,0.34l0.49,0.18l0.84,0.02l0.44,0.1l0.16,0.28l0.17,0.04l1.53,0.13
  		l0.57,0.13l0.18,0.18l0.95,0.24l3.65,0.59l1.1-0.08l1.48-0.2l0.47,0.33l0.21,0.04l0.34-0.05l2.09-0.17l0.27,0.32l1.03,0.31
  		l0.68,0.02l0.88,0.03l1.64,0.15l0.4,0.11l0.03,0.35l0,0.14l0.13,0l0.19-0.16l0.32-0.06l0.23-0.15l0.13-0.24l0.26-0.04l0.6,0.3
  		l0.02,0.17l-0.83,0.36l0.09,0.15l0.05,0.09l0.22,0.05l0.02,0.16l-0.27,0.19l0.26,0.12l0.79-0.28l0.45-0.02l1.23,0.21h0.56
  		l0.34-0.18l0.05-0.16l0.15-0.1l0.1,0.04l0.04,0.21l-0.02,0.39l0.53,0.14l1.09-0.11l0.65,0.01h0.61l0.11,0.09l0.16,0.25l0.46,0.44
  		l0.15,0.2l0.14,0.45l0.34,0.27l0.11,0.24l0.02,0.21l0.1,0.21l0.3,0.47l0.4,0.33l0.22,0.29l0.08,0.34l0.21,0.13l0.35-0.08l0.15-0.14
  		l-0.02-0.29l0.13,0.06l0.21,0.48l-0.03,0.76l0.06,0.44l0.3,0.62l0.4,0.17l0.27,0.24l0.79,0.17l0.08-0.05l-0.15-0.4l0.08-0.1
  		l0.26-0.02l0.45,0.06l0.29,0.2l0.13,0.34l-0.07,0.43l-0.52,0.44l-0.08,0.37l0.11,0.23l0.13,0.04l0.15-0.15l0.22,0.07l0.29,0.29
  		l0.07,0.24l-0.14,0.18l-0.51,0.1l-0.02,0.18l0.54,0.39l0.42,0.52l0.22,0.05l0.12,0.17l0.02,0.28l0.22,0.19l0.62,0.21l0.24,0.53
  		l0.13,0.32l0.11,0.36l0.19,0.04l0.14-0.17l0-0.32l0.31-0.13l0.03,0.17l-0.18,0.39l0.04,0.31l0.26,0.23l-0.33,0.08l-0.92-0.07
  		l-0.42-0.32l-0.13-0.11l-0.28-0.04l-0.09-0.11l0.13-0.32l-0.08-0.09l-0.57,0.08l-0.57,0.23l-0.37,0.28l0,0.32l0.24,0.34l0.49,0.35
  		l0.2,0.59l-0.09,0.83l-0.25,0.56l-0.4,0.29L1134,919l-1.97-0.71l-1.12-0.63l-0.48-0.86l-0.17-0.06l-1.01,0.28l-0.54,0.35l-0.47,0.5
  		l-0.13-0.16l0.2-0.82l0.08-0.51l-0.04-0.2l-0.2-0.16l-0.37-0.13l-0.49,0.07l-0.74,0.38l-0.17-0.04l0.02-0.13l0.77-0.63l0.25-0.35
  		l0-0.32l0.11-0.28l0.22-0.25l-0.05-0.11l-0.33,0.02l-0.17,0.11l-0.35,0.45l-0.5-0.04l-0.06-0.1l0.14-0.26l-0.11-0.16l-0.35-0.07
  		l-0.11-0.29l-0.12-0.06l-0.44-0.2l-0.04-0.55l-0.15-0.44l-0.26-0.34l-0.03-0.34l0.26-0.55l-0.1-0.08l-0.87-0.07l-1.65-0.07
  		l-0.86,0.11l-0.07,0.29l0.19,0.21l0.46,0.14l0.32,0.24l0.11,0.21l0.3,0.21l0.35,0.45l0.41,0.69l0.3,0.29l0.32,0.03l0.18,0.07
  		l-0.03,0.69l0.15,0.43l0.35,0.39l0.18,0.44l-0.02,0.76l0.03,0.75l-0.17,0.49l-0.46,0.56l-0.29,0.47l-0.13,0.38l-0.25,0.25
  		l-0.36,0.12l-0.38,0.26l-0.39,0.41l-0.25,0.5l-0.12,0.6l-0.32,0.62l-0.52,0.64l-0.2,0.72l0.11,0.81l0.02,3.48l0.1,0.87l-0.06,0.85
  		l-0.22,0.84l-0.38,0.66l-0.54,0.48l-0.53,0.35l-0.53,0.23l-0.5,0.38l-0.46,0.54l-0.78,0.49l-1.1,0.43l-0.89,0.26l-0.6,2.83
  		l-0.48,1.17l-0.84,0.68l0.09,0.16l0.18,0.18l1.11,0.25l0.24,0.04l0.18-0.02l0.12,0.26l0.04,0.62l-0.12,0.45l-0.29,0.28l-0.45,0.18
  		l-0.62,0.07l-2.34-0.21l-0.87,0.09l-0.36,0.15l-0.28,0.15l-0.25,0.22l-0.24,0.61l-0.11,0.96l0.08,0.49l0.39,0.21l1.05,0.44
  		l0.87,0.09l1.21-0.39l0.38-0.01l0.34,0.12l0.31,0.26l0.47,0.68l0.13-0.12l0.05-0.61l0.24-0.54l0.42-0.46l0.85-0.56l1.27-0.65
  		l0.98-0.35l0.9-0.12l0.07-0.1l0.46-0.36l0.07,0.07l-0.18,0.35l0,0.17l0.17-0.01l0.22-0.38l0.27-0.74l0.67-0.79l1.98-1.47l0.76-0.39
  		l1.28-0.24l1.8-0.09l1.79,0.2l1.77,0.48l1.21,0.22l0.64-0.04l2.55,0.31l0.27-0.08l-0.36-0.18l-1.69-0.18l-0.53-0.19l-0.66,0.04
  		l-0.04-0.09l0.47-0.63l0.26-0.07l0.33-0.25l0.34-0.32l0.55-0.36l1.02-0.28l1.49-0.2l1.48-0.37l0.4-0.03l0.31,0.11l0.57-0.03
  		l1.75-0.27l1.03,0l0.74,0.08l0.46,0.15l0.55-0.05l1.01-0.46l0.12-0.17l-0.21-0.28l-0.53-0.4l-0.25-0.31l0.04-0.23l-0.07-0.2
  		l-0.26-0.3l0.06-0.13l0.06-1.75l-0.02-0.05l-1.28,0.41l-0.66,0.36l-0.39,0.1l-0.54-0.11l-1.06-0.01l-0.93-0.26l-0.75-0.18
  		l-0.38-0.49l-0.01-0.19l0.99-1.36l0.39-0.62l0.12-0.39l0.23-0.27l0.59-0.34l0.16-0.25l0.29-0.11l0.42,0.02l0.79-0.45l1.16-0.93
  		l0.88-0.57l0.61-0.21l1.65-0.21l1.04-0.27l0.74-0.1l0.45,0.07l0.69-0.11l0.93-0.3l1.25-0.22l1.57-0.14l1-0.15l0.44-0.17l0.86,0.01
  		l0.15-0.25l0.23-0.12l0.83,0.07l0.29,0.14l0.04,0.11l-0.2,0.07l0.11,0.18l0.43,0.29l0.69,0.09l0.81-0.18l0.55,0.08l-0.14,0.13
  		l-0.29,0l-0.04,0.06l0.21,0.24l0.19,0.01l0.65-0.27l0.04,0.1l-0.29,0.39l0.1,0.24l0.48,0.1l0.81-0.17l1.01-0.48l0.46-0.14
  		l0.07-0.23h-0.33l-0.87,0.09l-0.25,0.14l-0.01-0.09l0.17-0.36l0.79-0.53l0.35-0.3l0.21-0.33l-0.23,0.01l-0.67,0.34l-0.6,0.18
  		l-0.53,0.02l-0.12-0.34l0.28-0.71l-0.27-0.26l-1.32,0.35l-0.15,0.12l0,0.1l0.15,0.07l-0.02,0.07l-0.18,0.06l-0.3-0.06l0-0.15
  		l0.15-0.24l-0.16-0.11l-0.4,0.07l-0.55,0.16l-1.18,0.48l0.01-0.1l0.31-0.3l1.26-0.38l0.61-0.18l0.39,0.03l0.24-0.14l0.21,0
  		l0.19,0.14l0.47-0.06l0.75-0.25l0.32,0.08l-0.11,0.42l0.19,0.04l0.5-0.34l0.32-0.12l0.14,0.11l-1.05,0.56l-0.29,0.28l-0.06,0.16
  		l0.18,0.21l0.42-0.03l1.72-1.11l0.84-0.37l0.83-0.21l0.83-0.05l0.7-0.19l0.57-0.33l2.39-0.74l0.97-0.46l0.93-0.82l0.22-0.05
  		l0.06,0.04l0.05-0.07l3.29-2.85l1.87-1.32l1.54-0.73l1.17-0.35l0.79,0.05l0.45-0.05l2.95-2.11l2.19-1.23l1.05-0.22l-0.22-0.92
  		l-2.09-0.62l-0.57-0.4l2.34,0.3l1.18-0.65l0.84-0.95l0.72-0.59l1.98-0.28l1.63-1.69l0.87-1.31l0.29-0.43l1.04-0.57l0.34-0.94
  		l1.17-0.55l1.37-0.26l3.57-2.09l0.44-0.78l1.48-0.51l1.25-0.84l1.03,0.14l1.12-0.26l3.01-1.03l1.28-1.37l1.2-0.87l2.25-1.41
  		l2.17-3.02l1.25-0.85l2.57-2.53l0.75-0.99l0.55-1.29l0.55-1.56l-0.76-0.8l-0.89-0.36l-1.47-0.41l-2.99-1.19l-1.44,0.01l-1.41-0.15
  		l-0.71-0.59l-0.81-0.36l5.34,0.96l2.3,0.95l0.92,0.08l1.26,0.68l0.76,0.17l0.74-0.09l1.04-0.56l1.48-1.46l1.21-2.02l2.54-2.76
  		l2.19-1.2l0.35-0.73l0.71-0.53l1.09-0.47l1.01-0.16l0.62-0.38l-0.52-0.52l1.9-0.65l4.27-0.82l0.67-0.03l0.82-0.15l0.93-1.12
  		l0.23-1.64l2.47-4.29l1.69-0.79l1-0.99l0.6-0.06l0.46-0.56l0.71-0.14l0.36,0.19l1.07-0.15l0.98,0.21l1.43-1.03l1.62,0.38l4.16-0.67
  		l0.74,0.24l2.64,0.25l3.02-0.37l2.85,0.44l1.3-0.38l1.36-0.11l1.01,0.11l1.24,0.51l2.93,0.17l0.87-0.56l1.57,0.03l1.01-0.11l0.97,0
  		l1.47,0.19l1.51,0.08l1.65,0.42l2.07,0.07l0.71,0.39l0.93,1.02l3.66-1.08l0.92,0.11l1.89-0.15l1.26-0.49l1.68,0.32l1.43-0.2
  		l3.01-0.17l1.63-0.68l0.6-1.12l1.71-0.82l1.97-2.03l1.79-1.16L1312.31,850.82z M850.45,493.06l-4.45,0.35l-1.91,0.43l-1.58,1
  		l0.23,0.36l0.48,0.27l8.75,0.85l1.28-0.58l0.62-0.71l-0.24-0.74l-0.58-0.39l-1.03-0.45L850.45,493.06z M856.51,467.98l1.45-0.17
  		l3.72-0.89l3.59-0.45l3.13,1.53l2.04,0.15l0.69-0.23l1.13-0.91l0.6-0.73l0.5-0.18l1.2,0.34l1.57,0.83l0.72,0.21l2.84,0.29
  		l1.05-0.06l2.29-0.45l2.64,0.35l1.89-0.23l0.79-0.37l0.32-0.59l0.14-0.73l-0.05-0.88l-0.17-0.68l-0.89-1.38l-0.31-0.28l-0.65-0.2
  		l-0.51,0.02l-1.47-1.08l-1.05-0.52l-1.68-0.48l-2.25-0.97l-1.77-0.02l-2.19,0.42l-1.63,0.5l-2.77,1.2l-1.01,0.76l-1.59,0.53
  		l-4.26,0.5l-3.6,0.89l-1.81,0.61l-2.48,1.08l-1.11,1.03l-0.09,0.34l0.59,0.67L856.51,467.98z M881.55,474.92l3.6-0.96l0.78-0.76
  		l0.51-1.05l-0.03-0.33l-0.29-0.08l-6.75-0.44l-2.27,0.12l-1.23,0.29l-2.81-0.14l-9.25,1.49l-4.21,1.19l-1.83,0.41l-1.39,0.12
  		l-0.24,0.16l-0.46,0.79l-0.11,0.44l0,0.41l0.1,0.63l0.69,1.09l0.18,0.76l0.4,0.79l-0.14,0.27l-0.43,0.32l-0.17,0.35l0.1,0.39
  		l0.28,0.51l1,0.35l1.01,0.64l2.37,0.56l2.28,1.43l1.65,0.37l1.89-0.01l6.11-1.51l1.4-0.08l1.4,0.13l1.78-0.36l2.62-0.81l1.46-0.61
  		l0.5-0.86l0.19-0.95l-0.01-1.64l-0.11-0.49l-0.18-0.37l-0.31-0.26l-0.87-0.17l-3.6,0.09l-0.78-0.39l-0.38-0.31l-0.08-0.25
  		l0.96-0.42L881.55,474.92z M966.97,437.61l0.48,0.27l1.2,0.09l2.88-0.15l1.54,0.91l0.23,0.7l0.04,0.36l0.7,0.43l2.3,0.72l0.51-0.03
  		l0.38-0.22l0.43-0.93l0.18-1.26l-0.19-2.51l-0.27-1.09l-0.6-0.86l-1.03-0.57l-1.15-0.25l-2.29-0.04l-2.58-0.34l-0.6,0.08
  		l-2.11,0.91l-0.21,0.24l-0.35,0.98l-0.05,0.56l0.28,1.59L966.97,437.61z M920.83,454.07l1.91-0.01l3.43-0.34l1.3,0.45l0.1,0.68
  		l-1.33,1.89l-0.64,0.63l-0.01,0.51l0.63,0.39l0.66,0.02l0.69-0.35l2.87-2.44l2.05-0.63l0.33,0.08l0.89,0.71l1.01,0.54l0.1,0.3
  		l-2.35,1.66l-0.33,0.34l0.08,0.27l1.48,0.32l4.13-0.1l0.93,0.33l0.31,0.28l-1.61,0.81l-3.08,0.54l-0.78,0.53l3.65,0.22l0.89,0.54
  		l-0.74,1l-1.63,0.38l-3.79-0.37l-4.32-0.73l-0.78,0.12l-0.75,0.38l-0.72,0.63l0.09,0.93l0.89,1.23l0.98,0.91l2.11,1.05l1.59,0.46
  		l3.18,0.17l2.27-1.1l7.97-0.95l-0.45,0.75l0.1,0.43l0.42,0.58l0.46,0.36l0.51,0.13l2.72-0.48l1.92-0.08l1.92,0.28l4.48,1.19
  		l1.89,0.1l0.98,1.17l1.11,0.78l0.14,0.29l0.26,1.38l0.17,0.34l0.65,0.86l0.79,0.71l2.62,1.07l2.68,0.71l2.5-0.55l2.68-0.28
  		l1.47-0.31l0.32-0.37l0.57-1.61l0.52-0.57l-0.04-0.35l-0.41-1.03l-0.66-0.83l-3.63-2.97l-1.59-0.43l-0.23-0.41l0.08-0.73
  		l-0.05-0.52l-0.62-0.84l0.25-0.32l1.16-0.7l0.41-0.95l0.41-0.34l-0.23-0.37l-1.45-0.68l-1.96-2.03l-3.54-1.71l-4.06,0.7l-1.78-0.35
  		l-0.16-0.42l0.27-0.64l0.69-1.05l-0.03-0.46l-0.43-0.42l-0.47-0.21l-1.3-0.17l-3.41-1.82l-1.42-0.18l-0.85,0.17l-1.81,0.77
  		l-1.72,0.53l-0.12,1.05l-0.15,0.39l-0.59,0.61l-0.69,0.4l-0.14-0.4l0.1-0.55l-0.12-0.24l-1.14-0.49l0.4-0.41l0.29-0.68l0.09-0.52
  		l-0.01-0.43l-0.11-0.33l-2.21-2.56l-2.34-1.21l-1.97-0.38l-2.36-0.69l-2.17,0.07l-7.44,0.7l-4.55-0.24l-0.4,0.4l-0.66,1.13
  		l-0.48,1.48l-0.08,0.94l0.08,1L920.83,454.07z M918.2,481.1l0.85,0.69l0.44,1.15l1.17,1.95l1.49,1.76l1.39,1.29l1.68,0.31
  		l2.56,0.72l1.29-0.32l0.55-0.37l0.31-1.04l0.03-0.51l-0.4-1.06l-0.35-0.52l-1.92-1.36l-1.56-0.1l-0.33-0.56l-0.11-1.03l-0.56-0.75
  		l-1.82-1.69l-1.4-1.06l-1.11-0.64l-2.32-0.33l-1.45,0.26l-0.26,0.26l1.54,1.79L918.2,481.1z M1054.13,496.49l0.12-0.33l-0.01-0.38
  		l-0.25-0.39l-0.41-0.33l-1.47-0.96l-1.58-0.01l-2.3,0.47l-0.97,0.39l-0.32,0.59l0.05,0.35l0.97,1.27l1.24,1.47l1.09,0.98l0.93,0.5
  		l0.68,0.13l0.42-0.23l1.27,0.12l0.39-0.24l0-0.23l-0.39-0.23l-0.27-0.48l-0.14-0.72l0-0.5l0.29-0.51L1054.13,496.49z
  		 M844.41,477.38l2.7,0.87l1.53,0.01l2.3-0.62l0.9-0.38l0.9-0.68l0.01-0.34l-0.66-0.66l-0.21-0.38l-0.39-0.25l-1.09-0.22l-1.6-1.14
  		l-0.79-0.37l-0.83-0.11l-0.19-0.52l0.05-0.56l-0.28-0.26l-2.33,0.68l-1.01,0.45l-0.53,0.41l-0.85,0.29l-1.17,0.17l0.41,0.95
  		L844.41,477.38z M776.9,505.84l0.26,0.09l0.04,0.16l-0.18,0.22l0.01,0.33l0.51,0.42l0.02,0.29l-0.51,0.73l-0.32,0.13l0.05,0.38
  		l0.42,0.63l0.49,0.38l1.12,0.12l0.82-0.27l2.07-1.01l1.26-0.29l1.8,0.25l2.24,0.68l0.91,0.07l0.9-0.12l1.64-0.63l1-2.49l0.44-0.34
  		l0.29,0.06l0.35,0.23l0.36,0.56l0.77,1.74l0.62,0.44l0.42,0.87l0.47,1.52l0.42,0.77l0.37,0.02l1.73-0.47l2.09-0.12l1.5-0.98
  		l0.57-0.53l0.5-0.67l0.59,0.04l0.1-0.26l-0.01-0.58l-0.19-0.37l-1.48-0.83l-0.12-0.31l0.84-0.68l0.03-0.58l-0.13-0.94l0.02-0.63
  		l0.58-0.81l0.48-0.24l0.29,0.35l0.35,0.78l0.67,0.93l1,1.08l0.68,0.57l0.74,0.04l0.73-0.36l0.32-0.4l0.88-1.57l0.33-0.34l1.42-1
  		l0.16-0.55l-0.16-0.9l-1.31-0.81l-0.18-0.39l0.25-0.48l0.5-0.22l1.33,0.01l0.89-0.39l0.31-0.71l0.18-1.31l0.33-0.68l0.58-0.63
  		l0.82-0.42l1.06-0.21l1.65-0.58l0.53,0.02l0.32,0.35l-0.23,0.89l-0.77,1.43l-0.56,1.39l-0.34,1.36l-0.12,0.88l0.23,0.71l1.28,1.07
  		l0.92,0.49l2.02,0.77l1.23,0l0.95-0.16l0.67-0.29l0.39-0.42l0.53-0.89l0.15-0.53l0.11-1.15l-0.03-0.47l0.15-0.62l4.48-0.8l2.08-0.6
  		l1.21-0.75l0.77-0.59l0.32-0.43l0.01-0.44l-0.6-0.85l-1.83-1.59l-0.16-0.48l0.59-0.25l0.62-0.05l1.66,0.17l0.87-0.54l-0.39-0.53
  		l-1.82-1.08l-1.79-1.28l-0.37-0.63l0.97-0.73l4.97-1.57l0.99-0.47l0.31-0.3l-0.05-0.28l-0.64-0.7l-3.85-1.75l-1.69-1l-1.29-0.48
  		l-1.25-0.09l-2.73,0.34l-0.94,0.45l-0.78,0.7l-0.05,0.29l0.13,0.31l2.22,0.6l0.53,0.33l-0.77,0.6l-0.41,0.13l-1.83-0.16l-0.74-0.22
  		l-0.52,0.53l-0.58,0.32l-1.18-0.07l-4.94-1.14l-6.86,0.87l-2.28,0.5l-1.97,1.15l-1.44,1.14l-2.83,1.84l-3.11,2.53l-0.93,0.47
  		l-0.39,0.31l-0.67,1.25l-0.4,0.41l-4.31,1.8l-0.88,0.54l-0.86,0.68l-3.01,2.96l-0.44,0.21l-6.33,0.7l-0.49,0.2l-0.81,0.65
  		l-2.14,2.19l-0.88,1.1l-0.21,0.52l1.84-0.48L776.9,505.84z M850.18,504.76l-1.14-1.25l-0.36-0.53l-0.1-0.32l-0.03-0.81l-0.21-0.47
  		l-0.44-0.5l-0.88-0.41l-1.99-0.47l-1.95-0.07l-1.95,0.14l-4.9,1.05l-1.67,1.27l-0.39,0.61l-0.05,0.59l0.08,0.45l0.21,0.3l1.12,0.22
  		l5.39,0.49l1.22,0.29l0.86,0.39l-1.35,0.11l-6.98-0.32l-2.45-0.3l-1.27,0.13l-2.06,0.89l-1.15,0.82l-0.16,0.38l0.51,0.99
  		l-0.26,0.44l-0.71,0.59l0.09,0.49l1.77,0.67l0.9,0.17l6.2-0.24l5.14-0.03l-1.53,0.51l-2.55,0.44l-3.04,0.01l-4.65,0.56l-3.47,0.63
  		l-1.44,0.44l-0.56,0.47l-1.05,1.26l0.22,0.48l0.95,0.27l5.05,0.28l3.3-0.37l7.69-1.7l0.04,0.19l-0.21,0.28l-0.92,0.69l-0.71,0.35
  		l-6.25,2.14l-1.14,0.17l-5.71,0.04l-2.22,0.13l-0.87,0.23l-0.66,0.29L821,518.7l-1.06,1.1l-0.53,0.73l-0.17,0.37l-0.03,0.35
  		l0.29,0.65l0.53,0.5l4.19,0.81l1.38,0.08l3.07-0.34l2.81,2.21l3.49,1.24l0.38-0.1l0.62-0.6l0.29-0.78l0.31-0.42l0.5-0.4l0.55-0.18
  		l0.59,0.03l0.53,0.2l0.89,0.89l0.38,0.67l0.43,0.41l0.48,0.15l1.36-0.4l3.43-1.49l0.81-0.9l-0.01-0.51l-1.23-1.47l-0.08-0.3
  		l0.26-0.17l0.45,0.07l1.21,0.53l0.98,0.18l0.37-0.88l0.42-1.72l0.18-0.42l0.31-0.3l0.27,0.07l0.57,0.72l0.32,0.22l2.44-0.63
  		l1.03-0.07l-0.3,0.33l-2.15,1.28l-0.44,0.43l-0.36,0.63l-0.28,0.82l0.21,0.39l0.05,0.99l0.09,0.29l0.42,0.48l0.7,0.26l3.13-0.42
  		l3.27-0.25l0.81-0.22l0.47-0.3l0.81-0.83l0.43-0.3l0.47-0.12l1,0.2l1.87,1.13l0.35,0.01l1.8-0.16l1.85-0.4l1.35-0.03l1.24-0.4
  		l2.47-1.18l0.74,0.07l0.51,0.5l-0.38,0.54l-1.51,1.15l-2.07,1.22l-1.41,0.61l-0.95,0.23l-1.44,0.09l-2-0.16l-3.96,0.26l-1.46,0.32
  		l-4.11,1.69l-4.54,1.06l-2.27,0.78l-1.52,0.86l-0.54,0.74l0.92,1.11l0.79,0.51l2.85,1.42l1.39,0.6l1.33,0.38l4.19,0.47l4.19-0.25
  		l6.66-1.42l3.72-1.39l2.92-0.9l1.61-0.82l1.05-1.09l0.69-0.47l1.32-0.57l1.78-0.44l5.67-0.72l4.22-2.15l1.44-0.92l1.39,0.42
  		l0.28,0.29l-0.31,0.28l-0.68,0.25l1.32,0.55l1.01,0.21l1.01,0.08l1.08-0.16l1.71-0.58l1.71-0.23l3.02,0.81l2.6,0.42l0.82-0.02
  		l0.8-0.2l3.14-1.27l4.17-1.25l1.02-0.71l0.92-1.02l1.35-3.76l0.2-1.52l1.34-2.25l0.32-1.2l0-0.75l-0.69-2.33l-0.35-0.85l-0.24-0.27
  		l-0.66-0.37l-1.63-0.73l-4.14-0.89l-1.11,0.12l-1.25,0.51l-1.07,0.85l-0.35,0.4l-0.14,0.37l0.35,1l0.51,0.53l0.6,0.39l-0.04,0.16
  		l-1.67,0.47l-0.09,0.7l-0.01,0.9l-0.08,0.17l-0.48-1.61l-0.67-1.25l-0.25-0.31l-0.47-0.27l-0.68-0.22l-1.7-0.26l-1.03,0.09
  		l-1.36,0.41l-1.81,1.3l-0.28,0.1l-0.59-0.15l0.42-0.6l1.81-1.74l0.2-0.27l0.09-0.32l-0.02-0.37l-0.45-0.69l-0.64-0.39l-1.4-0.13
  		l-1.24,0.19l-1.06-0.05l-0.8-0.16l-0.16-0.32l0.2-0.53l0.64-0.67l1.08-0.8l0.46-0.58l-0.59-1.69l-1.28-1.08l-1.41-0.83l-0.09-0.14
  		l-0.13-0.98l-0.18-0.58l-0.57-0.89l-0.06-0.38l0.2-0.37l0.84-0.89l0.29-0.49l0.09-0.52l-0.21-0.29l-0.52-0.07l-2.34-1.12
  		l-2.25,0.16l-1.02,0.35l-1,0.57l-1.4,1.21l-3.03,2.98l-0.98,0.66l-2.43,1.19l-0.33,0.34l-0.04,0.49l0.25,0.64l0.32,0.46l0.39,0.29
  		l2.47,1.16l1.66,0.18l1.88,1.18l0.48,0.62l0.12,0.65l-0.07,0.51l-0.25,0.37l-3.5,1.6l0.63,1.14l7.17,2.83l0.22,0.42l0.16,0.87
  		l-0.11,0.64l-0.29,0.77l-0.49,0.46l-0.69,0.15l-11.56-0.84l-2.24-0.07l-1.38,0.22l-1.37-0.03l-0.93-0.37l-0.95-0.73l-0.82-1.1
  		l-0.69-1.47l-0.5-0.84l-0.3-0.2l-1.35-0.17l-1.42,0.11l-1.51-0.15l-0.2-0.23l0.28-0.33l1.51-0.77l0.02-0.49l-3.95-2.3l-3.06-2.26
  		l-2.37-0.75l-1.62-0.23l-1.61,0.16l-3.88,0.73L850.18,504.76z M819.95,507.02l-1.02-0.27l-1,0.05l-1.15,0.63l-2.08,1.41l-2.04,0.63
  		l-2.08,0.89l-3.18,2.36l-1.88,1.22l-0.79,0.62l-0.63,0.78l0.1,0.28l0.65,0.27l1.85,0.27l2.27,0.81l1.71,0.11l2.41-1.1l0.86-0.54
  		l2.83-3.34l1.47-2l0.75-0.78l1.07-1.93L819.95,507.02z M975.68,498.01l0.44,1.37l0.68,1.25l-2.35,0.21l-1.35-1.19l-2.86-1.81
  		l-1.22-0.14l-2.14-0.04l-2.69,0.37l-1.56,0.51l-2.16,1.07l-0.51,0.85l0.14,0.33l0.45,0.34l1.42,0.72l4.01,0.64l0.78,0.29l0.88,0.53
  		l-0.05,0.22l-0.5,0.15l-2.65,0.35l-0.48,0.25l0,0.25l1.95,0.79l1.56,0.02l1.52,0.49l2.33,0.37l-2.1,0.24l-1.78-0.11l-0.71,0.1
  		l-0.23,0.28l0.52,0.88l0.61,0.64l2.44,1.21l-0.1,0.32l-0.62,0.24l-0.76,0.05l-1.3-0.27l-0.72-0.36l-1.05-0.82l-5.64-3.46
  		l-1.31-0.67l-0.32-0.46l0.05-0.63l-0.43-0.65l-1.69-1.14l-0.64-0.25l-2.21-0.46l-0.93,0l-0.6,0.21l-0.89,0.7l-1.18,1.19l-0.28,0.81
  		l1.27,0.73l0.65,0.15l2.97-0.03l0.24,0.32l-2.04,1.17l-0.76,0.85l-0.09,0.32l0.41,0.72l0.9,0.58l2.4,0.28l-0.64,0.84l0.02,0.44
  		l0.3,0.62l0.47,0.62l1.07,0.98l0.25,0.09l2.1-0.32l0.31,0.07l-1.23,0.61l-1.2,0.08l-1.35-0.41l-1.5-0.87l-2.89-0.88l-1.7,0.15
  		l-1.06,1.07l0.16,0.6l-1.34,1.12l-2.67,1.27l-0.59,0.67l0.82,0.96l0.95,0.51l0.39,0l9.1-1.62l2.14,0.3l2.57-0.51l8.31-1.05
  		l6.07-0.29l-0.12,0.51l-3.22,0.23l-1.39,0.38l-0.12,0.36l-1.64,0.75l-2.65,1.84l-3.64,0.93l0.06,0.2l-0.23,0.82l0.98,0.43
  		l2.11,0.54l1.84,0.74l-0.05,0.18l-2.59,0.28l-0.2,0.52l1.07,2.06l0.54,0.66l0.49,0.34l2.42,0.08l2.69,0.32l1.74-1.01l0.8-0.1
  		l0.68,0.4l0.75,0.17l1.23-0.09l1.48,0.05l1.11,0.21l1.14-0.06l2.3-0.39l1.47-0.01l1.09-0.22l0.32-0.24l-0.31-0.86l-0.73-0.72
  		l-0.13-0.39l0.19-0.39l0.2-0.03l0.63,0.81l1.08,0.53l0.37,0.09l1.05-0.18l0.12-0.41l-0.38-0.67l-1.25-1.18l-0.21-2.66l1.89-1.57
  		l1.58,0.84l0.86,0.71l0.23-0.04l-0.62-2.26l0.02-2.07l-0.27-0.21l-3.56-0.92l-0.23-0.38l1.65-0.73l0.78-0.84l-0.01-0.49l-0.41-1.05
  		l0.02-0.67l0.31-1.27l0.69-0.99l0.06-0.51l-0.05-0.75l-0.36-0.74l-1.13-1.39l-0.27-0.55l0.01-0.49l0.3-0.42l0.1-0.6l-0.09-0.78
  		l-0.21-0.52l-0.69-0.4l-1.34-0.25l-2.26-0.75l-0.44-0.41l-2.01-1.21l-1.54-0.47l-1.94,0.89L975.68,498.01z M1144.25,506.21
  		l-1.42,0.93l-1.71,1.41l-1.43,0.91l-1.24,1.3l0.51,0.7l0.79,0.3l1.59-0.1l1.95-0.67l0.61,0.05l-0.1-1.02l0.05-1.03l0.93-0.7
  		l0.85-1.4l-0.68-0.49L1144.25,506.21z M731.25,844.19l0.53,0.19l0.34,0l0.14-0.19l0.07-0.36l0-0.89l-0.14-0.22l-0.46-0.76
  		l-0.1-0.34l-0.28-0.05l-0.77,0.47l-0.17,0.23l-0.05,0.22l0.48,1.29L731.25,844.19z M1054.04,483.29l-1.01-0.89l-2.8-1.86
  		l-2.11-0.61l-2.12-0.37l-1.41-0.11l-1.49,0.2l-0.76,0.33l-0.34,0.32l-0.28,0.92l-0.02,1.36l0.07,0.99l0.25,0.7l1.3,1.04l6.44,2.1
  		l1.12,0.04l2.2-1.02l0.73-0.45l0.29-0.32l0.15-0.51l0-0.7L1054.04,483.29z M1237.74,610.46l-0.68,0.05l-0.88,0.33l-1.08,0.6
  		l0.16,0.54l2.21,0.68l0.22-0.09l0.59-0.76l0.76-0.59l-0.63-0.65L1237.74,610.46z M1281.77,648.82l1.66-1.11l0.74-0.63l-0.17-0.14
  		l-0.45-0.02l-1.31,0.19l-0.33,0.41l-0.78,0.51l-0.57,0.58l-0.39,0.14l0.56,0.48L1281.77,648.82z M983.49,462.39l0.3,0.53
  		l-2.15,0.76l-0.07,0.36l0.05,0.39l0.35,0.72l0.17,0.16l1.18,0.48l0.38,0.31l0.17,1.11l1.74,1.17l0.2,0.58l4.18,0.5l2.49,0.84
  		l0.7,0.1l-0.12,0.24l-0.7,0.4l-1.56,0.25l-3.63,0.23l0.08,0.35l0.23,0.39l1.64,1.24l2.81,0.89l0.63,0.46l-0.28,0.49l-0.1,0.4
  		l0.2,0.58l0.43,0.39l1.31-0.11l1.95-0.68l1.06-0.42l3.92-0.28l2.87-0.67l1.85-0.71l0.68-0.14l1.44,0.05l0.94-0.45l1.29-1.52
  		l-0.01-0.56l-0.44-0.55l-0.97-0.77l-1.91-0.86l0.52-0.69l2.14-0.92l1.07-0.6l-0.01-0.28l-0.23-0.55l-0.98-0.73l-3.21-1.24
  		l-1.25-0.35l-3.43,0.21l-1.99-0.48l-0.44-0.37l0.12-0.41l-0.19-0.41l-1.77-1.3l-0.94-0.41l-2.94-0.62l-1.97-0.7l-1.79-0.47
  		l-1.8-0.24l-3.76-0.18l-1.43,0.01l-1.01,0.58l-0.07,0.42l0.43,1.1L983.49,462.39z M1012.55,434.71l-6.3-0.09l-3.27-0.25l-8.35-1.34
  		l1.13,2.16l0.27,0.9l0.15,1.18l1.06,1.33l3.9,2.74l1.2,0.59l0.99,0.26l1.57,0.13l2.15,0l2.73-0.46l3.3-0.93l1.51-0.2l-0.62,0.96
  		l-0.37,0.36l-6.59,1.89l-2.2,0.35l-0.84,0.43l-0.59,2.07l0.64,0.53l2.87,0.68l1.8,1.23l0.5-0.07l1.66-0.79l3.72-1.05l1.02-0.19
  		l1.46-0.02l1.26,0.11l0.17,0.19l-0.85,0.54l-0.58,0.79l0.13,0.23l0.45,0.14l0.9,0.08l3.22-1.2l1.43-0.27l2.97-1.16l1.74-0.39
  		l1.49-0.01l1.35,0.21l1.99,1.25l4.68,0.16l3.31-0.15l-0.37,0.22l-4.41,0.82l-5.72,0.66l-1.15,1.83l-1.33,0.55l-1.91,0.02l-1.9,0.3
  		l-5.53,1.92l-1.79,0.81l-0.06,0.4l0.14,0.41l0.33,0.41l1.79,1.06l4.77,1.2l1.49,0.6l-0.41,0.12l-2.97-0.15l-0.52,0.18l-0.09,0.31
  		l0.69,0.8l1.37,1.21l1.03,0.64l1.32,0.12l1.15-0.21l2.16,0.15l6.57,0.8l0.57,0.35l-3.62,0.41l-3.61,0.63l-1.03,0.49l0.34,0.56
  		l1.09,0.75l2.75,1.4l3.81,1.4l4.12,0.9l4.14,0.54l2.56,0.16l1.92-0.25l0.51-0.39l-0.26-0.62l-0.4-0.51l-0.54-0.4l-1.54-0.72
  		l0.26-0.26l1.37-0.17l1.36,0.05l1.36,0.28l0.93,0.4l0.51,0.53l0.78,0.46l1.87,0.82l0.6,0.48l0.45,0.13l0.3-0.23l-0.14-0.58
  		l-0.88-1.39l-1.23-1.68l-2.05-2.32l-0.63-0.99l0.33-1.07l0.35,0.11l0.58,0.51l2.28,2.48l1.56,1.27l3.15,2.96l1.06,0.46l1.24-0.03
  		l0.26-0.12l0.49-0.9l0.71-1.69l0.35-1.07l-0.14-1.29l-0.77-1.56l-0.23-0.7l0.27-0.22l1.08-0.11l2.49,1.95l1.15,0.36l0.9-0.32
  		l0.48-0.79l0.08-0.51l-0.07-0.56l-0.18-0.39l-0.29-0.21l-1.61-0.48l-0.22-0.35l0.54-0.45l0.19-0.91l-0.2-2.28l0.22-1.24l0.5-0.74
  		l0.54-0.42l1.36-0.8l0.41-0.13l0.11,0.17l-0.89,1.61l-0.26,0.67l-0.06,0.41l0.04,0.78l0.29,1.87l0.51,0.83l2.06,0.57l1.17-0.78
  		l1.96-1.78l1.4-0.98l0.54-0.61l0.5-1.43l0.37-0.15l1.62,0.14l0.77-0.3l1.51-0.89l3.02-1.15l6.75-2.04l0.91-0.48l1.17-0.96
  		l-0.18-0.83l-0.94-1.12l-2.74-2.72l-1.23-1.55l-0.26-0.07l-0.61,0.4l-0.45,0.41l-1.22,1.66l-0.49,0.12l-0.66-1.37l-0.8-1.03
  		l-0.44-0.32l-0.88-0.24l-2.63-0.22l-1.79,0.93l-0.54,0.13l-2.68,0.38l-0.44-0.17l0.63-0.95l0.64-0.62l0.8-2.73l0.61-1.71l-0.83-1.4
  		l-1.06-1.47l-1.07-0.06l-2.72-0.64l-1.76-0.16l-0.52-0.2l0.45-0.7l2.05-1.03l0.06-0.39l-0.1-1.83l-0.13-0.91l-0.25-0.46l-2.4-0.85
  		l-1.39-0.27l-2.52,0.03l-0.85,0.2l-1,0.61l-0.17,0.75l0.14,1.14l0.28,0.67l1.95,0.59l1.06,1.14l0.49,0.8l-0.03,0.27l-1.09-0.43
  		l-1.75,0.05l-2.69-0.68l-1.36-0.63l-1.51-1.26l-0.16-0.51l0.59-1.39l-0.06-0.35l-0.35-0.3l-0.07-0.25l0.59-0.65l-0.03-0.34
  		l-0.24-0.43l-0.58-0.61l-0.79-0.41l-1.65-0.14l-1.26,0.16l-1.04,0.58l-0.54,0.06l-3-0.98l-2.69-0.54l-0.89-0.35l0.09-0.94
  		l-0.04-0.27l-0.39-0.64l-3.12-1.77l-1.84-1.43l-4.06-3.95l-1.1-1.66l-0.95-1.05l-1.8-1.16l-1.69-0.69l-5.24-1.36l-2.51-0.36
  		l-2.29,0.28l-3.83,0.03l-1.01,0.2l-0.34,0.17l-0.11,0.31l0.01,0.51l0.11,0.5l0.2,0.48l0.71,0.32l1.82,0.23l4.11,0.08l1.02,0.6
  		l0.43,0.48l0,0.52l-0.43,0.57l-0.49,0.3l-0.83,0.04l-3.22-0.45l-3.29,0.97l-2.55,0.52l-3.88-0.37l-2.44,0.97l-2.02,2.73l-0.05,0.5
  		l2.69,0.59l3.43,1.13l1.62,2.18l3.32,0.61l1.46,0.46l0.85,0.53l-4.7,0.02l-2.1-0.28l-1.33,0.03l-1.12-1.48l-1.69-0.77l-2.35-0.09
  		l-1.75-0.69l-1.8,0.09l-1.73,0.58l-0.16,2.71l2.11,1.62l2.41,1.46l0.55,0.58l-1.67,0.03l-2.23-0.35l-0.84,0.05l-1.87,0.54
  		l-0.43,0.39l-0.07,0.35l0.22,0.43l0.51,0.51l0.78,0.41l1.6,0.47l1.02,0.15l2.17-0.33l2.03,1.86l1.79,0.01l5.07-1.3l2.76,0.13
  		l-0.35,0.26l-2.34,0.78l-0.21,0.31l-0.05,0.44l0.11,0.57l-0.09,0.35L1012.55,434.71z M1166.8,557.25l-1.07-0.35l-0.94-0.61
  		l-0.44-1.07l-0.3-0.47l-0.85-0.91l-2.08-0.75l-0.3-0.28l-0.66-1.26l-0.73-0.8l-1.48-0.61l-5.73-1.81l-1.88-0.3l-9.1,0.4l-1.44-0.21
  		l-2.96-0.77l-1.94-0.09l-2.46-0.96l-1.76-0.03l-1.19,0.16l-0.51,0.23l-0.22,0.36l-0.1,0.83l0.02,1.29l0.27,0.93l0.77,0.84
  		l-0.35,0.02l-0.18,0.17l-0.21,0.69l-0.01,0.79l0.4,0.76l0.42,0.47l0.92,0.56l2.74,0.41l0.92,0.34l0.31,1.03l0.27,1.34l0.53,1.63
  		l0.64,1.36l0.32,0.46l0.98,0.6l2.7,1.13l1.53-0.03l1.56-0.22l4.89-1.38l2.02-0.39l4.03-0.24l6.93,0.85l5.16,0.37l1.83-0.36
  		l0.79-0.61l-0.38-0.5l-1.01-0.75l-0.46-0.62l0.17-1.34L1166.8,557.25z M721.79,825.62l-0.08-1.61l-0.15-0.39l-0.36-0.42l-0.11,0.15
  		l-0.02,0.36l-0.52,0.84l-0.14,0.44l0.06,0.36l0.15,0.24l0.24,0.12l0.87,0L721.79,825.62z M726.29,825.68l-0.91-0.76l-0.99-0.57
  		l-0.95-0.52l-0.53-0.06l-0.23,0.45l-0.2,1.15l-0.23,0.56l-0.56,0.83l-0.06,0.31l0.07,0.29l0.68,0.83l0.09,0.79l0.52,0.76l1.04,0.93
  		l0.58-0.06l0.52-0.82l0.2-0.14l-0.17,1.87l0.14,0.48l0.12,1l0.04,1.26l0.44,0.76l0.46-0.57l0.4-1.27l0.56-0.76l0.03-1.16
  		l-0.39-3.63L726.29,825.68z M723.25,830.81l-1.62-1.47l-0.24-0.14l-0.26-0.08l-0.15,0.12l-0.04,0.32l0.15,0.57l0.84,1.33l0.41,0.35
  		l1.13,1.25l0.27-0.52l-0.03-1.03L723.25,830.81z M719.4,824.97l-0.17-0.09l-0.25-0.06l-0.11,0.1l0.12,0.58l0.19,0.36l0.57,0.84
  		l0.33,0.3l0.35,0.15l0.13-0.09l-0.25-0.69L719.4,824.97z M770.45,875.02l-0.22-0.3L770,874.8l-0.13,0.17l-0.13,0.78l-0.2,0.22
  		l-1.09-2.62l-1.06-1.38l-0.57-1.44l-0.29-0.39l-0.7-0.56l-0.49-0.58l-1.6-0.83l-2.61-0.87l-1.24-0.53l-1.58-1.11l-0.84-1.24
  		l-0.01-0.3l0.23-0.16l-0.25-0.51l-1.11-1.28l-1.4-1.8l-0.68-1.04l-0.9-1.63l-0.52-0.68l-0.44-0.28l-0.68-0.18l-1.89-0.25
  		l-3.07-0.81l-4.19-0.68l-4.18-1.38l-4.34-2l-1.73-0.44l-1.54,0.03l-1.18,0.33l-0.5,0.38l-0.38,0.55l-0.03,0.53l0.69,0.97l1.11,0.98
  		l0.65,0.24l0.8,0.06l0.83-0.07l1.12-0.41l-0.16-0.46l-0.01-0.33l1.9,0.12l0.51,0.15l-0.53,0.51l0.04,0.45l0.29,0.76l0.02,0.25
  		l-0.77-0.67l-0.53-0.16l-1.6,0.09l-0.36,0.08l-0.27,0.21l-0.48,1.09l0.14,0.22l0.81,0.14l0.1,0.23l0.09,0.9l-0.38,0.68l0.08,0.25
  		l0.4,0.11l0.39-0.04l0.81-0.46l1.75,0l0.31,0.36l0.27,0.5l0.41,0.36l0.5-0.21l0.16-0.27l0.03-0.37l0.16-0.1l0.29,0.18l0.2,0.24
  		l0.11,0.29l0,0.25l-0.24,0.64l0.1,0.47l0.26,0.43l0.43,0.34l0.55,0.09l0.6-0.12l0.25-0.22l0.18-0.35l0.19-0.1l0.45,0.23l0.88,0.2
  		l0.52,0.31l0.76,1.23l0.29,0.34l0.28,0.15l0.53-0.01l0.5,0.46l0.47,0.18l1.8-0.13l-0.2,0.24l-2.4,0.34l-0.83,0.31l-0.13,0.13
  		l-0.05,0.39l0.13,1.36l0.25,0.24l0.16-0.03l0.47-0.56l0.22,0.02l0.96,0.73l0.21-0.11l0.08-0.43l0.22-0.12l0.63,0.3l0.58-0.07
  		l0.21,0.14l0.22,0.32l0.24,0.12l0.72-0.36l-0.02,0.24l-0.39,1l0.27,0.43l0.28-0.02l0.7-0.3l0.33,0.18l0.58,0.66l0.29,0.1l0.33-0.03
  		l0.09,0.08l-0.49,0.51l-0.92,0.35l-0.14,0.17l1.42,0.68l0.99,0.83l0.45,0.21l0.25-0.08l0.82-0.62l1.64,0.08l2.03-0.25l0.19-0.18
  		l0.3-0.52l0.25-0.66l0.15-0.74l0.07-0.06l0.1,0.77l-0.14,0.63l-0.28,0.6l-0.72,0.78l-1.69,1.45l-0.04,0.21l0.16,0.45l0.87,0.53
  		l1.25,0.63l1.51,0.61l2.64,0.89l2.2,0.85l1.67,0.54l2.71,0.57l0.18,0.12l0.31-0.24l0.44-0.59l0.33-0.29l0.94,0.22l0.2-0.05
  		l0.23-0.48l-0.69-1.63L770.45,875.02z M747.5,867.48l-0.79-0.16l-0.19,0.44l0.02,0.47l0.36,0.19l1.03,0.16l-0.24-0.98L747.5,867.48
  		z M714.48,821.47l-1.34-0.79l-1.68-0.81l-0.48-0.12l-0.55,1l0.9,0.61l0.89,0.96l1.3,0.71l1.83,1.93l0.55,0.14l0.17-0.04l0.67-0.56
  		l0.12-0.31l-1.6-2.19L714.48,821.47z M728.9,833.11l0.22-1.01l0.42-2.19l0.01-0.49l-0.99,0.03l-0.63,0.97l0.03,1.54l0.08,0.65
  		l0.12,0.34l-0.06,0.36l-0.22,0.61l0.65-0.15l0.21-0.28L728.9,833.11z M742.99,863.32l-0.41-0.69l-0.34-0.39l-0.74-0.31l-0.84,0.38
  		l0.24,0.6l-0.07,0.22l-0.3,0.13l-0.09,0.16l0.11,0.19l1.05,0.84l0.6,0.31l0.53,0.13l0.33-0.05l0.13-0.23l-0.02-0.53L742.99,863.32z
  		 M637.92,612.59l0.7-0.56l1.38-0.19l-0.44-0.41l-1.19-0.46l-0.56-0.03l-1.28,0.78l0.29,0.29L637.92,612.59z M788.72,512.59
  		l-0.17,0.44l0.73,0.19l0.66-0.08l0.59-0.35l0.44-0.4l0.28-0.44l0.21-0.52l0.15-1.07l-0.08-0.37l-0.22-0.16l-0.67,0.17l-0.41,0.42
  		l0.2,0.33l0.02,0.28l-0.09,0.35L788.72,512.59z M705.15,835.01l-0.98-0.01l-0.51-0.13l-0.06-0.16l0.57-0.82l0-0.29l-0.12-0.11
  		l-0.92,0.11l-0.4-0.07l-0.12-0.35l-1.24-1.42l0.15-0.51l0.99-0.89l-1.31-0.49l-0.98-0.13l-0.7-0.71l-0.79-0.55l-0.57-0.15
  		l0.47-0.33l0.31-0.06l0.71,0.34l1.35-0.42l0.11-1.12l-0.25-0.95l-0.85-1.06l-0.84-0.39l-0.33-0.02l-0.81,0.34l-0.39,0.26
  		l-0.99,0.21l-1.61,0.28l-1.51-0.1l-0.19,0.08l0.35,0.58l0.3,0.17l2.74,0.83l-0.01,0.48l-0.71,0.11l-0.24,0.16l-0.02,0.31l0.18,0.46
  		l0.62,0.95l0.61,0.35l2.38,2.4l1.57,1.11l0.52,0.5l0.43,0.88l0.75,0.95l1.69,0.95l0.89-0.74L705.15,835.01z M713.06,804.48
  		l-0.09-0.92l-0.83,0.94l-1.52,1.31l-0.69,0.75l0.34,0.24l1.57-0.77L713.06,804.48z M750.43,575.43l3.96-0.19l0.69,0.29l0.75,0.49
  		l3.08,1.39l6.34,2.47l0.46,0.39l1.67,1.94l0.62,0.54l0.73,1.09l1.7,3.17l0.66,0.77l0.88,0.71l0.97,0.46l1.34,0.09l0.81-0.15
  		l1.01-0.47l1.43-1.01l3.31-1.12l3.43-2.78l0.41-0.1l0.66,0.06l0.63,0.64l0.63,0.28l2.63-0.4l1.93-0.49l2.62-0.93l0.84-0.81
  		l0.49-0.74l0.15-0.4l0.65-4.03l0.47-1.51l0.97-2.25l0.12-1.36l0.76-0.26l2.71-0.23l2.14-0.93l0.88-0.92l2.33-3.96l0.45-0.5
  		l0.98-0.7l8.24-3.5l4.37-2.18l3.36-1.12l4.91-2.36l2.05-0.67l2.08-0.47l3.94-1.52l0.65-0.36l0.3-0.42l0.16-0.61l-0.12-0.65
  		l-0.41-0.7l-0.46-0.56l-1.04-0.77l-2.72-1.35l-6.44-4.6l-1.92-1.23l-2.09-1.15l-2.66-1l-1.62-0.34l-2.18-0.23l-1.97-0.01l-2.9,0.37
  		l-0.68,0.2l-1,0.67l-2.37,2.44l-0.48,0.39l-0.3,0.09l-0.12-0.2l-0.06-1.65l-0.09-0.67l-0.18-0.3l-0.29-0.2l-0.92-0.14l-1.32,0.08
  		l-2.17,1.54l-0.07-0.07l0-0.29l0.18-0.39l1.29-1.31l-3.21-0.35l-5.15-1.65l-2.76-1.13l-2.08-1.16l-1.57-0.66l-1.59-0.25l-2.05,0.07
  		l-7.37,1.28l-7.12,0.47l-10.34,1.46l-0.11,0.35l0.54,0.38l0.13,0.57l0.45,0.36l2.65,4.88l0.58,0.84l0.87,0.74l1.81,0.48l0.63,0.69
  		l0,0.28l-1.96,2.03l-0.71,1.91l-2.61,1.77l-1.43,2.85l-0.45,0.63l-1.32,1.27l-0.27,0.8l0.16,0.28l0.68,0.58l0.78,0.06l0.46,0.22
  		l0.15,0.27l0.08,0.38l-0.03,0.34l-0.15,0.29l-2.93,1.01l-0.33,0.32l-0.26,0.48l-0.13,0.6l0.01,0.72l-0.13,1.39l0.1,0.44l0.29,0.2
  		l-0.02,0.26l-0.7,0.58l-0.83,0.46l-1.16,1.15l-0.65,0.43l-0.47,0.66l-0.63,1.18l-0.53,0.67l-0.44,0.17l-0.16,0.29l0.11,0.42
  		l0.18,0.28l0.24,0.15l-1.51,0.72l-0.05,0.13l0.07,0.73l-0.06,0.45l-0.18,0.46l-0.47,0.73l0.14,0.21L750.43,575.43z M695.01,824.81
  		l2.83-0.87l0.45-0.5l0.25-0.81l0.05-2.39l0.28-1.45l0.57-1.47l1-1.67l0.14-0.42l0.15-1.37l-0.24-0.02l-1.04,0.76l-1.02,0.41
  		l-1.63,0.09l-0.01,0.44l0.21,1.1l-0.03,0.46l-0.32,0.61l-0.16,1.15l-0.13,0.25l-2.34,0.36l-0.59,0.02l-0.27-0.14l-0.06-0.13
  		l0.08-0.14l2.95-1.46l0.33-0.37l0.17-0.33l-0.09-0.92l-0.42-0.85l-0.39-0.55l-0.35-0.26l-0.35-0.08l-1.85,0.43l-0.77-0.68l-2-0.15
  		l-0.83-0.2l-0.48-0.01l-0.13,0.17l-0.29,1.6l0,0.99l0.15,0.96l0.23,0.68l1.17,1.71l0.12,0.27l0.01,0.49l0.45,0.63l1.47,0.56
  		l2.01,1.24l0.05,0.15l-0.32-0.01l-0.71-0.24l-0.91-0.13l-0.32,0.03l-0.46,0.66l0.61,0.54l1.3,0.79L695.01,824.81z M711.35,817.16
  		l0.19,0.13l0.39-0.26l0.59-0.65l0.26-0.41l-0.52-0.89l-0.15-0.13l-0.69-0.45l-0.43-0.04l-0.39,0.17l-1.28,0.96l-0.41,0.46
  		l-0.1,0.34l0.03,0.34l0.17,0.33l0.17,0.18l0.17,0.03l0.05-0.18l-0.07-0.39l0.04-0.31l0.15-0.23l0.29,0.01l0.44,0.26l0.57,0.65
  		L711.35,817.16z M708.23,808.31l-1.09-0.15l-0.13,0.2l-0.27,0.85l-0.05,0.5l0.07,0.24l0.2-0.01l1.03-0.75l0.35-0.38l0.08-0.27
  		l-0.04-0.16L708.23,808.31z M705.45,836.76l-0.09,0.4l0.12,0.98l0.19,0.38l0.28,0.23l0.15-0.02l0.16-1.5l-0.74-0.62L705.45,836.76z
  		"/>
    </>,
    BLZ: <>
        <path className="st0" d="M1069.71,1165.07l-0.33-0.01l-0.46,1.33l-0.05,0.32l-0.33,0.5l0.4-0.16l0.86-1.86L1069.71,1165.07z
  		 M1066.44,1163.33l-0.41,0.09l-0.45-0.13l0.45-0.98l-0.65-0.09l-0.75,0.05l-0.52,0.27l-0.53,1.35l-1.33,1.89l-0.53,0.92l-0.43,0.32
  		l-0.34,0.12l-0.38-0.22l-0.91-0.52l-0.7,0.25l-0.24,0.6l0.01,0.75l-0.08,2.09l-0.09,2.42l-0.07,1.78l-0.09,2.37l-0.09,2.42
  		l-0.13,3.3l-0.08,2.13l0.04,0.05l1-0.1l1.49,0.09l0.36-0.01l-0.15-0.56l0.27-0.52l1.55-1.98l1.12-0.37l0.85-1.23l0.48-0.47
  		l0.77-1.24l0.43-2.84l-0.27-1.97l0.04-1.04l0.18-0.69l0.54-1.07l-0.58-0.8l0.42-1.22l0.12-0.82l0.93-2.38l0.1-0.9l-0.38-1.08
  		L1066.44,1163.33z M1069.98,1170.32l-0.05,0.39l-0.23,0.33l-0.37,0.31l-0.27,0.89l0.04,0.34l0.65-1.21l0.46-0.49l0.08-0.39
  		L1069.98,1170.32z"/>
    </>,
    BLR: <>
        <polygon className="st1" points="2085.17,822.17 2085,821.84 2083.18,820.71 2082.95,820.34 2082.8,819.9 2083.02,819.49
  		2083.01,819.03 2080.91,818.02 2079.16,817.84 2077.71,817.89 2077.15,817.68 2077.39,817.17 2077.75,816.24 2077.85,815.48
  		2077.76,815.14 2077.47,814.85 2076.09,814.21 2074.2,813.23 2073.32,812.12 2072.87,810.96 2072.35,810.25 2071.43,809.79
  		2071.49,809.5 2072.1,808.41 2072.08,808.24 2071.82,807.97 2070.67,807.42 2069.1,806.4 2069.04,806.13 2069.15,805.49
  		2069.37,804.86 2069.68,804.56 2070.61,803.27 2070.61,802.84 2070.45,802.25 2069.77,801.24 2069.24,800.6 2069.2,800.26
  		2069.29,799.99 2069.63,799.64 2069.96,799.2 2070.03,797.69 2070.01,797.17 2069.81,796.85 2069.59,796.72 2069.12,796.8
  		2068.45,796.55 2067.95,796.16 2067.64,796.03 2067.32,795.63 2066.38,794.82 2066.22,794.6 2064.34,793.91 2062.74,794.02
  		2061.85,793.91 2061.38,794.06 2060.89,794.51 2060.22,794.8 2059.72,794.81 2059.26,795.03 2058.01,795.81 2057.43,795.34
  		2056.93,794.63 2057.1,794.04 2057.31,793.49 2057.29,793.12 2057.11,792.8 2056.34,792.46 2054.69,791.83 2054.22,791.82
  		2053.51,792.05 2052.22,792.76 2051.77,792.61 2051.35,792.05 2050.9,791.34 2050.28,790.98 2048.96,791.02 2048.83,791.05
  		2048.2,791.45 2047.93,791.41 2046.78,790.38 2046.52,790.34 2045.8,790.49 2044.66,791.17 2042.96,792.77 2042.52,793.12
  		2042.07,794.34 2041.97,794.46 2040.98,794.41 2040.71,794.38 2039.71,794.4 2037.55,794.09 2036.71,794.29 2035.61,795.52
  		2035.19,795.7 2033.91,795.87 2033.69,796.01 2033.66,796.54 2033.46,797.44 2033.06,798.6 2032.64,799.5 2032.54,799.85
  		2032.86,800.13 2034.42,800.27 2035.09,800.42 2035.22,800.66 2034.87,800.97 2034.37,801.23 2034.15,801.47 2033.75,802.34
  		2031.14,802.23 2030.8,802.41 2030.63,802.81 2030.5,803.27 2030.16,803.83 2029.47,804.31 2028.39,804.48 2027.5,804.81
  		2026.84,805.81 2026.35,807.16 2026.36,808.12 2026.43,808.65 2026.37,808.95 2026.03,809.28 2025.49,810.16 2025.04,811.13
  		2024.87,811.65 2024.96,811.9 2025.46,811.9 2026.18,812.1 2026.56,812.49 2026.71,812.93 2026.71,813.41 2026.57,813.68
  		2026,813.87 2025.09,813.88 2024.56,813.65 2024.45,813.47 2024.7,813 2024.52,812.43 2024.15,812.1 2023.38,812.59
  		2022.65,812.59 2021.78,813.01 2021.2,813.7 2020.65,813.95 2019.16,813.81 2018.8,814.12 2018.49,815.51 2018.31,815.79
  		2017.07,815.73 2015.87,816.28 2014.52,816.73 2013.83,816.42 2013.45,816.07 2012.72,816.13 2011.91,816.28 2011.37,816.2
  		2010.77,816.24 2009.6,816.51 2008.13,816.42 2007.5,816.19 2008.46,820.11 2010.07,823.87 2010.65,825.69 2010.89,826.65
  		2011.08,828.05 2011.14,829.03 2011.13,829.58 2011.01,830.34 2010.53,830.79 2007.46,832.06 2006.88,832.46 2005.97,833.46
  		2005.14,834.48 2004.95,834.83 2004.89,835.06 2005.08,835.4 2006.17,835.94 2007.28,836.38 2007.64,836.71 2008.45,837.13
  		2008.75,837.51 2008.91,837.84 2008.9,838.6 2008.53,839.65 2008.69,840.44 2008.31,840.96 2008.01,841.55 2007.96,842.57
  		2008.52,843.7 2008.59,843.62 2008.54,842.67 2008.87,842.46 2009.37,842.32 2010.09,842.37 2010.7,842.52 2011.43,842.95
  		2011.66,842.88 2012.91,842.06 2014.2,840.83 2014.57,840.11 2014.89,839.78 2016.01,839.61 2016.99,839.54 2017.61,839.55
  		2019.14,839.43 2020.04,839.29 2020.83,839.08 2022.51,838.99 2025.15,839.14 2026.88,839.15 2028.06,839.27 2030.94,839.92
  		2032.01,840.04 2032.51,840.39 2033.46,840.52 2035.2,840.87 2036.71,841.06 2037.73,840.98 2038.3,841.08 2039.38,842.63
  		2039.6,842.81 2040.04,842.85 2040.92,842.72 2042.17,842.77 2042.92,843.09 2042.81,844.02 2043,844.15 2043.35,844.1
  		2043.75,843.58 2044.09,843.04 2044.34,842.87 2045.62,843.24 2046.21,843.18 2046.75,842.77 2047.08,842.7 2047.99,842.99
  		2049.11,843.19 2050.01,843.21 2050.58,843.43 2050.99,844.39 2051.34,844.59 2051.69,844.65 2052.21,843.79 2052.69,843.45
  		2053.34,843.21 2053.77,843.1 2054.06,842.8 2054.47,842.5 2054.81,842.48 2055.1,842.59 2055.42,843 2055.89,843.94
  		2056.47,844.88 2056.87,845.22 2057.91,844.93 2058.61,844.64 2059.9,844.58 2061.61,844.37 2062.91,844.1 2063.73,844.15
  		2064.22,844.45 2064.98,845.03 2065.18,845.85 2066.16,846.42 2066.96,846.53 2067.24,845.93 2067.7,845.52 2067.53,844.95
  		2067.45,844.23 2067.1,843.55 2066.87,842.83 2067.29,841.79 2067.76,840.88 2068,840.38 2068.74,839.47 2069.5,838.82
  		2070.64,837.77 2071.47,837.43 2072.22,837.59 2072.63,837.73 2073.71,837.11 2075.63,837.08 2077.23,837.16 2077.19,836.88
  		2076.62,835.81 2076.27,835.34 2075.86,835.08 2075.66,834.77 2075.65,833.49 2075.73,832.28 2075.99,832.13 2075.23,831.14
  		2075.17,830.39 2075.54,830.02 2075.56,829.71 2075.31,829.26 2074.53,828.54 2073.77,827.73 2073.28,827.08 2072.98,826.78
  		2073.35,826.27 2073.87,825.38 2074.07,824.86 2074.32,824.73 2075.54,824.66 2076.43,824.67 2077.09,824.87 2077.35,825.29
  		2077.96,825.76 2079.69,825.95 2080.42,825.93 2081.33,825.5 2082.81,824.56 2083.17,823.88 2084.09,823.4 2084.65,823.21
  		2085.15,823.12 2085.2,822.72 	"/>
    </>,
    BWA: <>
        <polygon className="st1" points="2056.61,1507.13 2055.95,1506.45 2054.85,1506.32 2054.56,1506.16 2054.31,1505.91 2054.15,1505.58
  		2054.09,1505.22 2054.27,1504.09 2054.17,1503.96 2053.88,1503.83 2053.27,1503.69 2051.83,1503.18 2050.01,1502.68
  		2047.06,1502.14 2045.92,1502 2045.65,1501.83 2045.32,1501.42 2044.75,1500.12 2044.22,1499.27 2042.95,1497.95 2042.75,1497.54
  		2042.81,1496.5 2042.9,1495.65 2043.04,1494.94 2042.98,1494.27 2042.96,1493.43 2043,1492.88 2042.83,1492.63 2042.37,1492.47
  		2041.06,1492.39 2039.47,1492.42 2039.42,1491.58 2039.27,1490.27 2038.97,1489.52 2038.61,1489.13 2037.88,1488.72
  		2036.41,1488.16 2034.4,1487.31 2032.68,1486.06 2030.72,1484.5 2030.1,1484.23 2029.38,1482.77 2028.27,1480.26 2028.34,1479.43
  		2028.18,1479.02 2027.1,1477.79 2026.86,1477.15 2026.67,1476.51 2024.97,1474.71 2024.38,1473.93 2023.94,1472.92 2023.5,1472.12
  		2023.13,1471.8 2022.64,1471.25 2022.3,1470.62 2022.15,1470.16 2022.28,1469.53 2022.44,1469.11 2022.08,1469.06 2019.5,1469.35
  		2018.51,1469.72 2016.31,1471.35 2015.84,1471.14 2015.31,1470.8 2014.86,1470.7 2013.9,1471.09 2012.93,1471.56 2011.86,1472.22
  		2010.98,1472.87 2010.7,1473.22 2009.32,1474.56 2008.87,1474.78 2008.47,1474.87 2008.31,1474.81 2008.14,1474.23
  		2007.29,1472.89 2005.93,1471.13 2005.54,1470.96 2005.27,1470.89 2004.26,1470.97 2001.34,1471.47 1998.87,1471.89
  		1995.09,1472.61 1991.03,1473.19 1988.53,1473.55 1986.36,1473.65 1986.36,1475.4 1986.36,1478.93 1986.37,1482.47
  		1986.37,1486.02 1986.38,1489.58 1986.38,1493.13 1986.39,1496.7 1986.39,1500.27 1986.4,1503.85 1986.4,1505.41 1986.33,1505.75
  		1985.08,1505.75 1982.26,1505.75 1979.88,1505.75 1977.96,1505.75 1977.96,1507.88 1977.96,1510.4 1977.97,1512.93
  		1977.97,1515.46 1977.97,1517.99 1977.98,1520.53 1977.98,1523.07 1977.98,1525.62 1977.98,1528.17 1977.99,1530.09
  		1977.99,1530.31 1978.39,1530.58 1981.06,1532.57 1981.78,1533.61 1982.14,1534.28 1983.28,1536.69 1984.14,1538.85
  		1984.83,1540.49 1984.88,1541.23 1984.98,1541.96 1985.08,1542.32 1985.02,1542.72 1984.53,1543.61 1984.03,1544.29
  		1983.43,1545.23 1983.37,1546.46 1983.55,1547.91 1983.92,1548.63 1984.38,1548.87 1985.49,1548.51 1986.19,1548.62
  		1987.17,1548.9 1990.41,1548.73 1990.8,1548.81 1992.02,1548.92 1992.42,1548.8 1992.79,1548.49 1993.21,1547.62 1993.59,1547.34
  		1994.28,1547.19 1995.09,1546.95 1995.76,1546.45 1996.83,1544.73 1998.96,1543.21 1999.62,1542.84 2000.03,1542.43
  		2000.39,1541.88 2001.14,1539.96 2001.7,1538.37 2001.89,1537.62 2002.4,1536.39 2003.01,1535.61 2003.6,1535.19 2003.9,1535.09
  		2004.67,1534.88 2005.66,1534.68 2006.7,1534.9 2007.81,1535.38 2009.07,1536.18 2010.35,1537.17 2010.95,1537.67 2011.58,1537.89
  		2012.72,1537.97 2013.47,1537.95 2014.62,1538.94 2015.21,1539 2016.52,1539.3 2018.14,1539.61 2019.16,1539.57 2020.25,1539.04
  		2021.04,1539.02 2022.06,1539.06 2023.18,1538.91 2024,1538.68 2024.63,1538.22 2025.18,1537.72 2025.82,1536.21 2026.18,1535
  		2026.75,1533.61 2027.44,1531.72 2027.69,1530.41 2027.95,1530.05 2028.95,1529.65 2029.79,1529.37 2032.03,1528.86
  		2032.49,1528.58 2032.91,1527.97 2033.89,1526.92 2035.1,1526.04 2035.72,1525.54 2036.86,1521.31 2037,1520.79 2037.83,1519.67
  		2038.34,1519.19 2038.67,1519.18 2039.14,1518.89 2039.75,1518.3 2040.47,1517.95 2041.31,1517.81 2041.85,1517.42 2042.1,1516.79
  		2042.53,1516.48 2043.15,1516.5 2043.5,1516.29 2043.58,1515.87 2043.95,1515.51 2044.61,1515.21 2044.95,1514.85 2044.98,1514.44
  		2045.77,1513.44 2047.3,1511.85 2048.75,1510.97 2050.1,1510.79 2051.39,1510.46 2052.6,1509.98 2053.5,1509.22 2054.07,1508.2
  		2055.05,1507.62 2057.03,1507.45 	"/>
    </>,
    BTN: <>
        <polygon className="st1" points="2584.83,1086.69 2584.56,1086.34 2584.51,1086.16 2584.59,1085.73 2584.83,1085.13 2585.27,1084.44
  		2584.95,1083.77 2584.49,1082.99 2584.16,1082.92 2583.32,1083.1 2582.41,1083.06 2581.69,1082.6 2581.16,1082.02 2581.03,1081.54
  		2581.18,1080.94 2581.42,1080.4 2581.47,1080.19 2581.45,1079.82 2581.55,1078.71 2581.25,1078.46 2580.3,1078.18 2579.24,1077.82
  		2578.73,1077.44 2578.45,1077.31 2578.05,1077.37 2577.41,1077.78 2576.8,1078.25 2576.32,1078.29 2575.83,1078.07
  		2575.36,1077.78 2573.75,1077.37 2573.03,1077.31 2571.75,1077.38 2570.7,1077.29 2570.53,1077.17 2570.54,1076.94
  		2570.69,1076.49 2570.78,1076.05 2570.66,1075.8 2569.58,1075.5 2568.61,1075.28 2567.57,1075.19 2566.86,1075.35 2566.18,1075.69
  		2565.62,1076.31 2564.8,1076.58 2563.82,1077.04 2563.35,1077.47 2562.64,1078.4 2561.6,1079.53 2560.65,1080.63 2560.16,1081.71
  		2559.52,1082.38 2558.86,1082.87 2558.39,1084.21 2558.31,1084.37 2557.28,1085.09 2557.1,1085.48 2557.33,1085.85
  		2557.73,1086.17 2557.91,1086.47 2558.1,1087.41 2558.62,1087.67 2559.65,1088.28 2560.55,1088.72 2562.1,1088.42 2562.54,1088.62
  		2563.3,1088.83 2563.89,1088.89 2564.24,1089.05 2564.43,1089.2 2564.41,1089.39 2564.44,1089.59 2565.29,1089.64 2565.74,1089.75
  		2567.25,1089.55 2568.76,1089.27 2569.46,1088.43 2569.77,1088.37 2570.64,1088.05 2571.5,1088.4 2572.44,1088.89 2572.95,1089.04
  		2573.95,1089.12 2574.93,1089.06 2577.28,1088.83 2578.56,1088.95 2579.74,1088.26 2579.99,1088.26 2580.51,1088.79
  		2581.8,1088.84 2582.5,1088.58 2583.24,1088.38 2583.72,1088.32 2584.1,1088.31 2584.56,1088.37 2584.99,1088.19 2585.19,1087.83
  		2585.14,1087.28 	"/>
    </>,
    BHS: <>
        <path className="st0" d="M1159.8,1090.69l-0.8-0.37l-0.1-0.22l0.03-0.23l-0.25-0.21l-0.61-0.32l-0.68-0.8l-0.71-0.6l-1.16-0.09
  		l-0.97-0.2l-0.63-0.04l-0.68,0.33l1.24,0.02l2.4,0.5l1.52,2.05l0.77,0.51l0.27,0.65l-0.2,0.58l0.08,0.57l-0.22,0.65l0.01,1.19
  		l-0.4,0.55l-0.92,0.63l0.59,0.26l0.73,0.9l0.18-0.08l0.29-0.46l0.2-2.56l1.09-0.84l-0.24-1.77L1159.8,1090.69z M1158.4,1104.25
  		l-1.03,0.02l-0.64,0.21l-0.29,0.25l0.17,0.15l0.68,0.18l0.95-0.19l0.66-0.27l-0.05-0.11L1158.4,1104.25z M1171.21,1117.94
  		l-1.19-0.93l-0.52-0.21l-0.22,0.61l0.68,0.09l1.47,1.08l0.63,0.23l0.35-0.05l-0.76-0.35L1171.21,1117.94z M1167.97,1103.29
  		l-1.28-1.26l-1.18-0.38l-1.39-1.01l-0.52-0.69l-0.14,0.12l-0.19,0.64l-0.27,0.48l0.74-0.14l0.61,0.1l1.02,0.81l1.1,0.26l0.72,0.81
  		l1.04,0.92l0.07,0.83l-0.44,0.8l-0.08,1.01l-0.89,0.04l0.17,0.19l0.5,0.37l0.3,0.64l0.3,0.29l-0.04-0.98l0.29-1.12l0.22-1.86
  		l-0.1-0.41L1167.97,1103.29z M1156.69,1114.42l-0.04-0.2l0.04-0.23l-0.25-1.32l-0.45-0.71l-0.57,0.87l-0.61-0.4l0.83-0.56
  		l-0.01-0.2l-0.37-0.34l-0.47,0.16l-0.78,0.11l-0.29,0.14l-0.56-0.1l-0.42,0.3l0.72,1.14l0.52,0.45l0.39,1.39l0.26,0.19l0.04,0.97
  		l1.66,0.12l0.46-1.15l-0.02-0.37L1156.69,1114.42z M1153.37,1090.09l0.03-0.25l-0.18-0.48l-1.22,0.27l-1.51-0.08l-0.33,0.18
  		l-0.55,0.04l-1.02-0.28l-0.88-0.62l-0.3,0.65l0.1,0.19l-0.1,0.41l-0.67,0.54l-0.72,0.15l-1.16-0.82l-0.42-0.14l1.57,1.45l0.47,0.25
  		l0.61-0.05l1.3-0.48l2.38-0.7L1153.37,1090.09z M1153.75,1110.61l0.23-0.3l0.91-0.54l0.09-0.29l-0.09-0.81l0.02-1.08l-0.81-0.77
  		l-0.66-1.32l-0.46-0.55l-0.02-0.71l-0.49-0.52l-1.09-0.53l-0.41,0.1l0.44,1.51l-0.21,0.94l-0.97,1.45l0.21,0.55l0.12,0.04l0.15,0.3
  		l-0.64,0.57l-0.17-0.46l-0.81,0.13l0.58,0.74l0.92,0.55l0.56,0.15l0.39-0.24l0.08,0.72l0.5,0.42l0.26,0.69l0.52-0.42
  		L1153.75,1110.61z M1194.33,1135.8l0.11,0.51l1.12-0.43l-0.66-0.48L1194.33,1135.8z M1193.7,1138.52l-0.83,0.44l-0.56-0.01
  		l-1.04-0.4l-0.83,0.1l-0.52,0.57l-0.8,0.2l0.09,0.18l0.02,0.18l-0.16,0.46l0.05,0.29l0.17,0.33l2.19-0.06l1.99-0.31l0.89-1.22
  		l0.27-0.64l0.13-0.94l-0.39-0.12L1193.7,1138.52z M1175.42,1112.11l-1.77-2.02l-0.07-0.2l-1.07-2.06l-0.47-0.15l-0.14,0.07
  		l-0.15,0.31l0.7,0.58l0.19,0.54l0.39,0.34l0.83,1.43l0.72,0.57l-0.03,0.4l-0.58,0.42l-0.19,0.31l0.3,0l0.83-0.18l0.56,0.09
  		L1175.42,1112.11z M1187.7,1125.11l-0.88,0.14l-0.18,0.29l-0.01,0.42l0.52,0.59l0.07,0.36l-0.25,0.44l-0.5,0.25l-0.13,0.19
  		l-0.36,0.24l-0.63,0.68l-1.13,0.77l-0.13,0.46l0.59-0.26l0.96-0.81l1-0.48l1.16-1.56l-0.34-1.25L1187.7,1125.11z M1196.86,1128.52
  		l-0.57-0.36l-0.96-0.26l-0.28,0.01l-0.52-0.13l-0.73-0.23l-0.29,0.66l0.44,0.12l1.09-0.01l0.77,0.08l0.89,0.61l0.31-0.32
  		L1196.86,1128.52z M1178.54,1121.96l-1.07-0.55l-0.38-1.61l-0.56-0.94l-0.35-0.96l-0.84-1.08l0.05,0.7l0.18,0.19l0.4,0.83l0.7,1.22
  		l0.23,0.61l-0.09,0.56l-0.53,0.1l-0.16,0.24l0.77,0.43l1.34,0.43l1.07,1.76l0.05-0.23l-0.04-0.93L1178.54,1121.96z
  		 M1182.46,1112.76l-0.46,0.19l-0.2,1.21l0.36,0.08l0.67-0.96l-0.18-0.51L1182.46,1112.76z M1184.4,1125.25l1.21,0.44l0.54-0.35
  		l-0.19-0.16l-0.99-0.32l-0.39-0.46l-0.72-0.25l-0.06,0.32l0.09,0.35l0.24,0.47L1184.4,1125.25z"/>
    </>,
    BRA: <>
        <path className="st0" d="M1386.28,1316.71l-0.61,0.81l0.05,0.76l0.45,0.12l1.45-0.04l0.93,0.52l0.72-0.05l0.66-0.25l1.19-1.75
  		l-0.35-0.44l-1.19-0.3l-2.1,0.65L1386.28,1316.71z M1387.29,1315.36l0.95-1.09l0.03-0.6l-0.19-0.36l-0.34,0.11l-0.12,0.17
  		l-1.17,0.16l-0.26,0.58l0,1.05l0.17,0.26L1387.29,1315.36z M1385.22,1302.81l0.84-0.38l0.06-0.34l-0.42-1.36l-0.17-0.11l-0.47-0.06
  		l-0.61,0.27l-0.15,0.83l0.45,1L1385.22,1302.81z M1384.99,1317.48l0.79-1l-0.09-1.03l-0.07-1.67l-0.18-0.08l-0.2,0.08l-0.23,0.19
  		l-0.01,1.12l-0.21,0.82l-0.63,0.67l-0.71,0.35l-0.11,1.26l1.52,0.52L1384.99,1317.48z M1401.51,1554.17l-0.34,0.54l-0.31,0.72
  		l-0.1,2.15l0.58-0.41l-0.09-0.54l0.73-1.27l0.27-1.04l-0.31-0.47L1401.51,1554.17z M1392.49,1320.6l-0.79-0.04l-5.22-0.94
  		l-1.8,0.34l-1.55,0.97l-0.41,0.77l-0.19,0.88l0.11,0.49l-0.14,0.46l-0.43,0.52l-0.08,0.37l-0.13,1.81l0.11,0.87l0.62,0.56
  		l0.98-0.04l0.13,0.25l-0.03,0.3l-0.13,0.07l-0.61-0.14l-0.52-0.03l-0.26,0.95l0.3,1.1l0.42,1.21l0.47,1.02l0.13,0.5l0.8,0.76
  		l0.54,0.11l0.88-0.37l1.93-0.07l0.37-0.37l0.47,0.04l0.83,0.46l0.89,0.23l0.47-0.29l0.83-0.14l0.53-0.22l0.52-0.69l0.16-0.99
  		l0.84,0.37l0.52,0.33l0.93,0.04l0.25-0.34l0.19-0.62l0.08-0.61l0.72,0.77l0.41,0.08l0.44-0.08l0.48-0.19l0.8-0.77l0.25-0.53
  		l-0.21-0.42l-0.09-0.42l0.42-0.45l0.52-0.35l0.2-0.21l0.68-1l0.45-0.79l0.18-0.38l0.08-0.39l-0.23-0.97l0.36,0.06l0.22-0.22
  		l0.28-1.09l0.3-0.78l0.41-0.74l-0.11-0.46l-0.44-0.21l-0.6-0.2l-0.61-0.14l-1.67-0.13l-2.78-0.43l-0.83-0.04l-0.84,0.08l-0.75,0.39
  		L1392.49,1320.6z M1431.13,1330.07l0.54-0.4l-0.04-0.34l-0.76-0.08l-0.14,0.67l-0.13-0.02l-0.08,0.23l0.45,0.15L1431.13,1330.07z
  		 M1379.99,1320.85l0.78,0.54l2.3-1.64l0.02-0.22l-0.14-0.4l-0.83-0.14l-0.65,0.08l-1.29,0.46l-0.26,0.56l0.03,0.13l-0.13,0.31
  		L1379.99,1320.85z M1390.24,1319.08l-0.23,0.35l1.02,0.42l2.23-0.26l0.53-0.48l0.07-0.47l-0.24-0.47l-0.87-0.22l-0.83,0.18
  		l-0.93,0.4L1390.24,1319.08z M1375.56,1324.08l-1.11,1.72l-0.01,1.93l-1.03,0.97l-1.15,2.09l0.89-0.16l1.64-0.77l1.45-1.09
  		l1.31-1.57l0.28-0.02l0.97-2.97l-0.79-1.05l-1.44,0.2L1375.56,1324.08z M1481.17,1431.56l-0.13,0.26l0.24,0.33l0.13,0.34l0.33,0.08
  		l0.29-0.5l-0.03-0.62l-0.61-0.02L1481.17,1431.56z M1516.46,1380.62l0.09-0.9l-0.24-2.22l-0.35-0.18l-0.03-0.8l-0.42-1.04
  		l-0.49-3.29l-0.9-1.75l-0.39-2.25l-0.79-2.94l-1.32-2.65l-0.75-0.71l-0.57-0.31l-3.63-0.63l-1.53,0.33l-1.89-0.08l-1.72,0.11
  		l-1.32-0.39l-0.96-0.71l-0.79-0.25l-1.85-0.2l-1.07-1.67l-2.74-1.01l-1.43-1.57l-2.13-1.57l-1.88-2.25l-0.76-0.6l-0.96-1.33
  		l-1.77-0.53l-1.77-1.27l-1-0.93l-2.85-1.62l-1.33-0.6l-0.83-0.58l-1.37-0.59l-1.62-1.04l-2.28-0.4l-2.01-0.15l-3.38,0.62
  		l-2.69,0.14l-1.04,0.42l-1.36-0.17l-1.35-0.32l-0.69-0.58l-1.3-0.52l-1.04,0.5l-2.1-0.12l-2.9-1.1l-0.69-0.6l-1.32-0.5l-0.88-0.54
  		l-2.47-0.66l-1.27-0.08l-0.46,0.31l-0.17,0.74l-2.3,0.14l-1.14,0.65l-0.58-0.1l-0.68,0.49l-1.51,1.4l-0.27-0.46l0.28-0.46
  		l0.67-0.85l0.09-0.32l-0.04-0.56l-0.62-0.19l-0.41,0l-0.67,0.53l-0.61,1.7l-0.47,1.72l-1.56,1.62l-0.85,0.56l0.01-0.52l0.69-3.18
  		l0.24-0.72l0.18-0.87l0.23-0.41l0.35-0.36l0-0.63l1.16-0.33l-0.08-0.8l-0.37-0.85l-0.72,0.19l-0.49,0.34l-0.7,1.2l-0.32-0.44
  		l-0.47-0.46l0.41-0.2l0.41-0.11l0.35-0.63l0.31-0.32l0.36-0.51l-0.08-0.89l-0.38-0.87l-0.5-0.8l-0.59,0.39l-0.48,0.05l0.48-0.55
  		l-0.58-0.07l-0.32-0.44l-0.77-0.69l-0.89-0.63l-0.43-0.39l-0.89,0.34l-0.48,1.02l-0.37,0.56l-0.4,0.17l-0.2-1.25l-0.89-1.77
  		l-0.83-0.21l-0.74,0.14l-1.13-0.81l-1.63-0.53l-0.61-0.71l-0.81,0.13l-0.63-0.16l-0.04-0.57l-0.86,0.07l-0.85-0.08l-0.8-0.28
  		l-0.85-0.22l-0.23-0.45l-1.06-0.67l-0.35-0.48l-0.69,0l-0.43-0.31l-0.68,0.06l-0.86-0.04l-0.62-0.54l-0.57-0.29l-1.09-0.16
  		l-0.35,0.18l-0.18,0.28l0.23,0.34l0.12,0.37l-0.44-0.15l-0.73-0.66l-0.79,0.41l-0.3,0.05l-0.37-0.12l-0.36-0.28l-0.29-0.11
  		l-0.64,0.25l-0.65,0.64l-0.6-0.54l-0.31,0.07l-0.39,0.2l-0.11,0.48l-0.62,0.27l-0.55,0.56l-0.43,0.55l0.09,0.66l-1.21,0.88
  		l0.35,0.7l-0.58,0.79l0.08,0.59l0.14,0.35l0.86,0.39l-0.81,0.32l-0.14,0.78l-0.56-0.39l-0.59-0.66l-0.93-0.01l-2.37,2.86
  		l-1.38,0.41l-0.47,0.32l-1.66,3.58l-0.42,1.34l-0.56,0.77l-0.44,0.29l-0.51,0.21l0.31-0.61l0.39-0.54l0.39-2.01l0.39-0.74
  		l0.52-1.84l0.72-2.01l-2.29,1.13l-1.13,0.5l-1.55-0.47l-0.81-0.32l-0.99,0.21l-0.47,0.32l-0.74,0.22l-1.2,0.77l-1.54-1.39
  		l-0.45-0.27l-0.43-0.46l0.12-0.56l-0.03-0.43l-0.9-1.29l-0.27-0.95l-0.06-0.54l-0.16-0.71l-0.44-0.52l-0.17-0.41l0.67-0.64
  		l-0.03-0.33l-0.44-0.52l-0.82,0.41l-0.31,0.38l-1.46,0.87l-0.8,0.73l-1.97,1.09l-0.97,0.34l-2.54,1.61l-2.1,0.45l-0.96-0.67
  		l-2.98-0.07l0.93-0.32l2.73-1.27l1.76,0.31l0.33-0.26l0.39-0.4l0.11-1.17l0.86-0.53l0.83-0.83l-0.01-1.36l0.16-0.78l1.24-1.78
  		l0.49-0.33l0.78-0.98l0.88-1.79l0.14-0.78l1.52-0.45l1.14-1.35l0.48-0.26l0.79-0.1l0.52-0.42l1.46-1.66l1-1.81l1.01-0.95l0.41-0.71
  		l1.88-1.5l0.2-0.31l0.92-0.58l0.33-0.35l-0.06-0.89l0.21-1.26l-0.64-2.01l-0.82-0.59l-1.12-0.46l-0.98-0.1l-1.3-0.27l-0.64-0.82
  		l-0.35-0.6l-0.28-0.88l-0.42-0.22l-0.47-0.03l0.32-0.38l-0.02-0.26l-0.49-1.39l-0.44-0.85l-0.23-0.8l-0.09-0.66l-1.41-3.56
  		l-0.49-1.71l-0.2-3.27l-1.21-3.54l-0.9-1.1l-1.13-0.75l-0.72,0.02l-0.09,0.65l-0.8,1.45l-0.26,0.18l-0.7,0.39l-0.32,0.53
  		l-0.19,0.51l-0.44,0.34l-0.42,0.43l-0.13,0.35l-0.39,0.28l-0.08,0.46l-0.98,1.63l-0.39,0.73l-0.56,0.78l-0.35,0.29l-0.48,0.46
  		l-0.24,0.54v0.55l-0.33,0.66l-0.19,0.57l-0.32,0.33l-0.83,1.81l-0.04,0.62l-0.2,0.37l-0.59,0.86l-0.4,0.52l-0.7,0.39l-0.73,0.42
  		l-0.28,0.46l-0.52,0.23l-0.38,0.02l-0.61-0.17l-0.82-0.08l-0.42,0.05l-0.19-0.23l-0.28-0.53l-0.41-0.37l-0.27,0.13l-0.55,0.37
  		l-0.65,0.22l-0.46-0.07l-1.01-0.26l-0.43-0.13l-0.13-0.22l-0.15-0.17l-0.22,0.07l-0.3,0.28l-0.4,0.29l-0.59,0.38l-1.21,0.69
  		l-0.34,0.25l-0.31-0.13l-0.51-0.14l-0.55-0.01l-1.18-0.45l-0.69-0.32l-0.3-0.4l-0.35-0.17l-0.2-0.11l-0.38-0.01l-0.3-0.27
  		l-0.05-0.32l-0.16-0.37l-0.38-0.11l-0.71,0.13l-0.21-0.09l-0.42-0.39l-0.35-0.43l-0.09-0.41l-0.23,0.04l-0.54,0.37l-0.37,0.08
  		l-0.29-0.1l-0.33,0.03l-0.83,0.4l-0.49,0.09l-0.35,0.4l-2.3,0.18l-0.6,0.11l-1.37-0.7l-0.36-0.23l-0.18-0.03l-0.15,0.04l-0.15,0.15
  		l-0.23,0.88l-0.21,0.24l-0.36,0.19l-0.35,0.35l-0.07,0.34l0.54,0.19l0.45,0.66l0.49,0.53l0.39,0.46l-0.05,0.53l-0.07,0.75
  		l-0.28,0.25l-0.48,0.12l-1.75-0.36l-1.34-0.32l-0.56-0.07l-0.25-0.08l-0.36,0.12l-0.32,0.17l-0.45-0.13l-0.62,0.07l-0.64,0.19
  		l-0.59,0.09l-1.12-0.29l-0.34-0.04l-0.23-0.13l-0.46-0.58l-0.22-0.07l-0.6,0.27l-0.72,0.19l-0.35-0.04l-0.42,0.2l-0.39,0.26
  		l-0.74,1.13l-0.38,0.4l-0.41,0.18l-0.82-0.01l-0.88,0.04l-0.66,0.27l-0.61,0.14l-0.31,0.02l-0.1,0.62l-0.14,0.29l-0.19,0.16
  		l-0.48,0.05l-0.43-0.02l-0.26-0.26l-0.48-0.13l-0.43-0.09l-0.28-0.15l-0.22,0.04l-0.19,0.26l-0.15,0.22l-0.13,0.41l-0.65,0.13
  		l-0.28,0.23l0.16,0.76l-0.07,0.3l-0.14,0.23l-0.79,0.05l-0.67-0.02l-0.39,0.28l-0.48,0.33l-0.29,0.06l-0.34-0.02l-0.46-0.38
  		l-0.44-0.47l-1.11-0.33l-1.1-0.27l-0.72-0.74l-0.17-0.37l-0.34-0.16l-0.86-0.88l-0.47-0.57l-0.51-0.15l-0.59-0.24l0.02-0.41
  		l-0.04-0.39l-0.25-0.16l-0.36-0.11l-0.13-0.22l0.04-0.52l0.07-1.33l-0.1-1.28l-0.79-0.44l-0.34-0.3l-0.6-1.89l-0.28-0.85
  		l-0.01-0.63l0.2-1.88l0.22-0.81l0.61-1.64l0.35-0.55l0.02-0.41l-0.04-0.53l-0.18-1.05l1.03-0.66l0.44-0.28l0.07-0.44l0.55-0.56
  		l0.24-0.53l0.2-0.42l-0.06-0.22l-0.24-0.13l-0.28-0.4l-0.6-1.15l-0.22-0.23l-0.18-0.32l0.09-0.51l0.23-0.55l-0.03-0.23l-0.36-0.3
  		l-0.74-0.5l-0.61-0.04l-0.47-0.18l-0.7-0.02l-0.56-0.06l-0.32-0.18l0.06-0.31l0.14-0.23l0.47-0.58l0.31-0.62l0.04-0.61l0.09-0.8
  		l0.14-0.69l0.07-0.78l-0.74-0.51l-0.23-0.42l-0.3-0.37h-0.33l-0.5-0.16l-0.79,0.49l-0.62-0.09l-0.43,0.19l-0.98-0.04l-0.63-0.24
  		l-0.76,0.16l0.25,0.09l0.34,0.23l0.31,0.69l0.26,0.73l0.01,0.38l-0.2,0.48l-0.43,0.55l-0.53,0.44l-0.77,0.38l-0.61,0.36l-0.51,0.94
  		l-0.31,0.33l-0.28,0.13l-0.56,0.12l-0.9-0.03l-0.6-0.07l-0.74,0.7l-0.94,0.26l-0.63,0.96l-2.25,0.76l-2.2,0.59l-0.6,0.23
  		l-2.17-0.49l-0.52,0.15l-0.6,0.45l-0.55,0.35l-0.47,0.02l-0.39,0.18l-0.23,0.65l-0.21,2.24l-0.78,0.67l-0.94,0l-0.65-0.78
  		l-0.77-0.59l-1.33-1.39l-0.37-0.18l-0.35,0.01l-1.24,0.41l-0.59-0.18l-0.47-0.22l-0.79,0.07l-1.41,0.02l-0.9,0.01l-0.44-0.38
  		l-0.41-0.78l-0.27-0.28l-0.32-0.22l-0.53-0.11l-2.27,0l-0.43,0l-0.31-0.15l-0.44-0.67l-0.48-0.31l-0.56-0.01l-0.25,0.37l0.97,1.2
  		l0.28,0.65l0.85,0.94l2.46,1.99l0.46,0.63l-0.05,0.81l-0.01,1.23l0.08,1.17l0.63,1.67l0.89,1.7l0.24,1.09l-0.17,0.8l-0.17,0.43
  		l0.02,0.19l0.18,0.17l0.85,0.25l1.78,0.15l1.08,0l1.65,0.18l0.12,0.6l-0.16,0.99l-0.33,0.56l-0.26,0.16l-0.9,0.13l-0.94,0.61
  		l-1.37,0.6l-0.78,0.08l-0.33,0.12l-0.28,0.18l-0.23,0.23l-0.27,1.12l-0.4,1.27l-0.76,0.75l-0.84,0.62l-0.85,0.07l-0.68-0.05
  		l-0.34,0.18l-0.49,0.51l-0.7,0.64l-0.54,0.34l-0.73-0.03l-0.78,0.31l-0.98,0.51l-0.65,0.42l-0.55,0.72l-0.79,0.76l-0.82,0.53
  		l-0.39,0.65l-0.56,0.83l-0.7,0.03l-0.06-0.5l0.33-0.8l-0.36-0.69l-0.66-0.37l-0.31-0.11l-0.31,0.05l-0.79,0.34l-0.96,0.62
  		l-0.59,0.45l-0.54,0.2l-1.1,0.18l-0.93,0.09l-0.38-0.13l-0.69-0.46l-1.6-1.43l-2.16-1.93l-1.59,0.37l-0.14-0.06l-0.1-0.21
  		l0.05-1.59l-0.02-1.8l-0.25-0.74l-0.73-1.18l-0.97-1.57l-0.26-0.45l-0.41-0.26l-0.48-0.04l-0.35,0.11l-0.48,0.29l-0.45,0.32
  		l-0.86,0.95l-0.87,1.11l-0.51,0.25l-0.51,0.1l-0.45-0.03l-0.36-0.3l-0.37-0.6l-0.45-0.8l-0.54-0.26l-0.21,0.25l-0.18,0.47
  		l-0.14,0.47l0.36,0.59l0.31,0.46l-0.53-0.02l-1.72,0l-1.98,0l-1.98,0l-1.78,0l-1.65,0l-0.63-0.04l-0.64-0.27l-0.61-0.13l-0.32,0.02
  		l-0.58,0.26l-0.75,0.04l-0.51,0.25l-0.41-0.03l-0.01,1.38l-0.01,1.97l-0.01,2.09l0.45-0.16l0.39,0.02l0.29,0.15l0.81-0.12
  		l0.45,0.06l0.42,0.05l0.39,0.01l0.24,0.17l0.33-0.03l0.35-0.18l0.42,0.11l0.45,0.29l0.29,0.44l0.26,0.54l0.26,0.29l-0.02,0.52
  		l-0.01,0.41l-0.09,0.34l0.11,0.22l0.08,0.23l-0.02,0.14l-0.15,0.06l-0.33,0.05l-0.35,0.04l-0.24-0.02l-0.19-0.21l-0.18-0.02
  		l-0.27,0.03l-0.28-0.13l-0.24-0.26l-0.43-0.26l-0.46,0.11l-0.32,0.14l-0.33,0.17l-0.3,0.17l-0.3-0.05l-0.38,0.13l-0.32,0.2
  		l-0.42,0.16l-0.46,0.08l-0.53,0.08l-0.51,0.03l-0.58,0.06l-0.03,1.1l-0.07,2.16l-0.04,1.43l0,1.32l0.22,0.48l1.02,1.01l0.8,0.53
  		l0.68,0.6l0.67,0.25l0.28,0.22l0.19,0.37l0.09,0.39l0.07,0.33l-0.07,0.35l-0.17,0.33l0.07,0.35l0.24,0.28l0.07,0.35l0.17,0.33
  		l0.09,0.33l0.2,0.24l0.26,0.17l0.33,0.28l0.04,0.26l-0.04,0.3l0,0.22l0.32,0.51l0.09,0.36l-0.15,0.42l-0.14,1.47l-0.37,1.68
  		l-0.23,1.28l-0.38,2.09l-0.44,2.43l-0.54,2.96l-0.54,2.92l-0.52,2.83l-0.47,2.56l-0.52,2.82l-0.31,1.71l-0.15,0.3l-0.05,0.55
  		l-0.27,0.22l-0.42,0.05l-0.64-0.39l-0.46,0.1l-0.46,0.03l-0.65-0.45l-0.23-0.45l-0.51-0.36l-1.06,0.15l-0.88,0.01l-0.73-0.08
  		l-0.66,0.12l-0.56,0.47l-0.42,0.55l-0.49,0.46l-1.44,0.31l-0.76,0.01l-0.69,0.3l-1.02,0.11l-0.7,0.27l-1.24,0.15l-1.49,0.14
  		l-0.83,0.41l-0.33,0.18l-0.84,0.57l-1.47,0.89l-0.81,0.31l-0.98,0.97l-1.17,0.91l-0.76,0.48l-1.12,0.22l-0.46,0.24l-0.17,0.29
  		l0.1,0.34l-0.19,0.87l-0.34,1.62l-0.09,0.79l-0.08,0.38l-0.74,1.3l-0.8,1.21l-0.39,0.8l-0.22,0.59l0.24,0.49l0.33,0.88l0.27,0.7
  		l0.08,0.47l-0.09,0.55l-0.34,0.5l-0.53,0.33l-0.72,0.09l-1.47,0.88l-1.64,1.3l-0.54,0.6l-0.15,0.57l-0.24,0.89l0.1,0.46l0.29,0.32
  		l0.29,0.76l0.02,0.39l-0.24,0.22l-0.46,0.05l-0.42,0.07l-0.32,0.2l-0.32-0.05l-0.29,0.1v0.32l0.1,0.37l-0.05,0.39l-0.2,0.24
  		l-0.17,0.17l0.17,0.24l0.29,0.22l0.44,0.37l0.61,0.71l0.47,0.12l0.39,0.24l0.05,0.39l-0.15,0.39l-0.34,0.17l-0.02,0.34l0.47,0.42
  		l0.32,0.29l0.32,0.44l0.29,0.61v0.39l0.32,0.49l0.2,0.42v0.39l0.51,0.39l0.44,0.29l0.32,0.27l0.32,0.17l0.07,0.29l-0.04,0.45
  		l0.46,0.73l0.84,0.55l0.68,0.8l0.44,0.58l0.81,0.93l0.03,1.07l-1.01,1.23l-1.01,1.23l1.65-0.03l1.68,0.02l1.76,0.35l1.18,0.34
  		l0.72,0.15l0.51,0.39l0.24,0.61l0.2,0.5l0.05,0.73l0.73,0.59l-0.05,0.56l-0.02,0.79l0.33,0.01l2.15,0l2.35,0l2.26-0.15l0.85-0.19
  		l1.03-0.96l0.62-0.28l0.6-0.45l0.73-0.82l0.57-0.37l0.49-0.45l0.72-0.45l0.3-0.34l0.24-0.12l0.56-0.22l-0.24,0.44l-0.19,0.45
  		l-0.06,0.65l0.27,0.71l-0.22,0.53l-0.36,0.48l-0.01,1.25l-0.01,1.77l-0.01,1.52l-0.01,1.9l-0.01,2.16l-0.01,1.43l0.39-0.28
  		l0.53-0.25l0.69,0.66l0.49,0.29l0.42,0.07l0.43-0.02l0.59-0.14l1.3-0.55l0.89-0.45l1.02,0.03l1.4,0.18l0.8-0.02l0.98-0.03
  		l1.97,0.06l1.91,0.33l1.29,0.14l0.54,0.28l0.12,0.45l0.36,0.21l0.41-0.08l0.47-0.03l1.05-0.46l0.85-0.31l0.73-0.37l0.37-0.36
  		l0.91-1.25l0.73-0.69l0.67-0.24l1.32-0.1l0.42,0.2l0.54-0.02l0.46-0.71l0.71-0.79l1.39-0.98l0.71-0.27l0.44-0.34l0.76-0.05
  		l0.66-0.36l3.21-2.48l1.3-0.64l0.81-0.12l0.67-0.15l1.14-0.36l2.85-0.34l1.84-0.14l0.59,0.34l0.66-0.1l0.56-0.56l0.46-0.18
  		l0.34,0.02l0.49,0.66l0.24,0.7l-0.16,0.53l0.03,0.77l0.22,1.01l-0.12,0.9l-0.69,1.18l-0.35,0.48l-0.09,0.49l0.06,0.67l0.32,1.09
  		l0.57,1.51l0.09,1.12l-0.4,0.72l-0.19,0.63l0.03,0.53l0.14,0.37l0.26,0.21l0.14,0.42l0.03,0.63l0.33,0.61l0.64,0.59l0.26,0.56
  		l-0.12,0.54l0.03,0.33l0.19,0.13l0.17-0.11l0.23-0.15l0.21,0.05l0.45,0.75l0.06,0.15l0.24,0.62l0.07,0.47l0.66,0.26l0.71,0.21
  		l0.39,0.25l0.79,0.74l0.66,0.49l0.83,0.4l0.27,0.64l0.51,0.97l1.39,0.37l1.63,0.19l1.04,0.21l1.27-0.51l0.84,0.07l0.87,0.35
  		l0.37,0.24l0.65,0.5l1,0.64l0.82,0.24l0.58-0.35l0.54-0.12l0.42,0.15l0.22,0.69l0.22,0.47l0.48,0.35l1.03,0.91l0.59,0.37l0.66-0.02
  		l1.36,0.59l1.45,0.58l0.75,0.1l0.74-0.08l0.49,0.22l0.2,0.7l1.26,1.4l0.59,0.55l0.71,0.47l1.8-0.01l0.54,0.14l0.8-0.12l2.42-0.24
  		l0.44-0.07l1.37,0.61l1.62,0.88l1.07,0.69l0.75,0.38l0.39,0.62l0.32,0.64l0.15,0.69l-0.19,0.69l-0.29,0.28l-0.1,0.45l0.12,0.67
  		l0.54,0.6l0.2,0.73l0.29,1.3l0.33,0.41l0.22,4.02l-1.08,0.03l-1.53,0.05l0.44,0.38l1.26,1.5l1.16,1.38l0.18,2.22l0.12,1.4
  		l0.16,1.98l0.1,1.18l2.9,0.11l3.34,0.12l4.02,0.15l3.53,0.13l0.35-0.01l0.61-0.16l0.41-0.21l0.25,0.01l0.04,0.48l-0.09,0.6l0,0.69
  		l-1.01,1.37l-0.06,0.43l0.15,1.81l0.36,1.46l0.18,1.32l0.41,0.41l1.2,0.7l1.81,1.29l0.72,0.17l0.61-0.17l0.37,0.53l0.07,0.85
  		l1,2.38l0.63,1.51l0.29,0.53l0.48,0.27l-0.09,0.2l-0.4,0.08l-0.18,0.28l-0.55,1.69l-0.72,2.24l-0.49,1.57l0.44,0.03l0.02,0.44
  		l0.1,0.67l-0.54,0.08l-0.16,0.24l-0.62,1.29l-0.82,1.69l-0.85,1.75l-0.5,1.04l0.86,0.77l1.42,1.28l-0.23,0.36l-0.61,0.18
  		l-0.51,0.13l-0.39,0.48l-0.22,0.35l-0.56,0.12l0.18,0.63l0.11,0.49l0.28,0.35l0.28,0.46l0.28,0.26l0.2,0.43l-0.06,0.49l0.11,0.64
  		l0.14,0.55l0.14,0.14l0.4,0.14l0.2,0.5l-0.14,0.25l0.06,0.29l0.14,0.28l-0.13,0.27l0.07,0.21l0.27,0.19l0.25,0.69l0.03,1.19
  		l-0.28,0.64l-0.22,0.52l-0.06,0.32l0.17,0.46l-0.28,0.55l-0.33,0.67l0.08,0.46l0.06,0.44l0.03,0.46l0.08,0.44l-0.11,0.46
  		l-0.11,0.41l-0.06,0.46l0.14,0.52l-0.25,0.49l-0.14,0.35l-0.06,0.35l0.25,0.55l0.64,0.23l0.5,0.06l0.47-0.29l0.36-0.09l0.67,0.26
  		l0.61,0.47l0.78,0.06l0.7,0.09l0.53,0.15l0.78-0.17l0.81,0.17l0.95,0.26l0.78,0.23l0.78-0.06l0.59-0.03l0.61-0.26l0.58,0.03
  		l0.45-0.47l0.25-0.41l0.22-0.29l0.64-0.23l0.45,0.15l0.36,0.76l0.64,0.44l0.25,0.32l0.47,0.15l1.03,0.03l0.64-0.03l0.72,0.23h0.47
  		l0.42,0.41l0.39,0.5l0.06,0.9l0.36,0.7l0.47,0.26l0.25,0.44l-0.08,0.61l-0.22,0.61l0.03,0.67l0.25,0.61v0.61l0.17,0.61l0.33,0.53
  		l0.11,0.85l-0.06,0.61l0.22,0.35l0.08,0.5l-0.14,0.41l-0.06,0.56l0.03,0.5l0.17,0.41l0.5,0.53l0.14,0.94v0.64l0.22,0.76l0.42,0.35
  		l0.67,0.12l0.78,0.12l0.95-0.18l0.84-0.21l0.47-0.21l0.92-0.56l0.81-0.32l0.42-0.21l0.39-0.15l0.81,0.35l0.75,0.44l0.58,0.62
  		l1.09,0.68l-0.21,0.16l-0.43,0.55l0.01,0.65l0.31,0.93l-0.27,1.97l-0.84,3.02l-0.35,1.76l0.15,0.5l-0.31,0.88l-1.16,1.9l-0.04,1.28
  		l0.66,0l0.31,0.28l0.48,0.15l0.51-0.33l0.43-0.15l0.69-0.01l0.37-0.37l0.43-0.06l0.3,0.2l0.29,0.24l0.61,0.05l0.48,0.62l0.54,0.19
  		l0.23,0.72l0.35,1.89l0.64,1.11l0.64,1.27l0.02,0.57l-0.36,0.57l-0.06,0.82l-0.22,2.01l-0.07,0.74l0.22,0.5l0.08,0.7l-0.35,0.86
  		l-0.67,1.29l-0.65,0.35l-0.17,0.01l-0.88,0.75l-0.62,0.28l-0.36-0.19l-0.41,0.32l-0.46,0.83l-0.56,0.37l-1.02,0.21l-0.3,0.1
  		l-0.59-0.03l-0.51,0.21l-0.43,0.45l-0.45,0.17l-0.48-0.11l-0.44,0.16l-0.39,0.44l-0.23,0.48l-0.06,0.51l-0.39,0.35l-0.71,0.19
  		l-0.24,0.26l0.04,0.36l-0.32,0.28l-1.2,0.29l-0.87,0.52l-0.53,0.74l-0.56,0.47l-0.89,0.29l-1.21,0.75l-0.17,0.47l0.46,0.43
  		l0.16,0.38l-0.13,0.33l-0.38,0.05l-0.62-0.24l-0.44-0.05l-0.27,0.14l-0.13,0.27l0.01,0.4l-0.22,0.27l-0.46,0.14l-0.29,0.33
  		l-0.12,0.51l-0.58,0.65l-1.03,0.78l-0.82,1.05l-0.6,1.32l-0.7,0.87l-0.8,0.42l-0.55,0.59l-0.3,0.77l-0.85,1.19l-1.4,1.62
  		l-1.27,1.11l-1.14,0.6l-0.64,0.68l-0.14,0.76l-0.74,0.87l-1.34,0.97l-0.38,0.44l0.48,0.68l1.42,0.18l1.43,0.02l0.23-0.17l0.56-1.11
  		l0.74-0.32l0.8-0.08l0.88,0.06l0.94,0.73l2.65,2.4l1.95,1.67l0.59,0.79l0.51,0.59l0.39,0.55l-0.16,1.43l0.02,0.63l0.09,0.18
  		l0.44,0.02l0.66-0.1l0.56-0.3l0.43-0.46l0.42-0.37l0.34-0.2l0.12-0.3l0.2-0.31l0.2-0.07l0.39,0.23l0.91,0.82l0.7,0.76l0.17,0.43
  		l0.27,0.45l0.29,0.39l0.2,0.38l0.68,0.5l0.69,0.32l0.46-0.32l1.18,1.04l2.6,0.87l0.48,0.53l0.45,0.75l0.91,1.13l1.26,1.02
  		l1.01,0.43l0.97,0.25l0.54,0.23l0.37,0.39l0.59,0.42l0.37,0.16l0.13,0.38l0.38,0.83l0.4,1.05l0.44,0.97l0.95,0.93l1.07,0.73
  		l1.11,0.41l0.53,0.43l0.25-0.21l0.4-0.27l0.52-0.2l0.32-0.6l0.27-0.61l0.69-0.34l0.56-0.39l0.05-0.4l0.1-0.34l0.11-0.44l0.29-0.43
  		l0.21-0.18l0.16,0.05l0.09,0.31v1.03l0.31,0.43l0.23,0.56l0.27,0.42l0.02,0.38l-0.06,0.42l-0.16,0.49l-0.36,0.56l-0.29,0.55
  		l-0.32,0.51l-0.35,0.6l-0.3,0.29l-0.33,0.1l-0.4-0.07l-0.37-0.46l-0.31-0.36l-0.33-0.22l-0.44,0.09l-0.53,0.35l-0.36,1.04
  		l-0.46,0.93l-0.54,0.24l-0.29,0.04l-0.22,0.09l-0.21,0.23l-0.27,0.37l-0.24,0.72l-0.14,1.08l0.13,1.15l-0.14,0.55l-0.31,0.14
  		l-0.15,0.1l-0.16-0.04l-0.05-0.05l-0.04,0.71l0.05,0.31l0.11,0.2l0.47,0.31l0.56,0.26l0.23,0.05l3.79-3.2l1.33-1.27l0.93-1.21
  		l1.21-2.46l1.41-4.07l0.56-1.08l0.71-0.96l0.53-0.49l-0.33-0.74l-0.21-1.12l-0.02-0.76l0.62-1.77l0.78-0.89l0.27-1.01l0.19-0.98
  		l0.38-0.42l1.77-0.88l1.77-1.29l0.36-0.48l-0.18-0.69l0.22-0.6l0.7-0.61l0.15-1.59l0.36,0.26l0.28,0.45l-0.04-1.48l0.35-1.14
  		l0.5-0.51l0.25-0.44l-1.05-1.1l-0.11-0.95l-0.03-0.97l0.41,0.23l0.14,0.56l0.46,0.82l1.17,0.46l0.13,0.99l0.78,0.05l2.41-1.26
  		l0.7,0.15l0.14,0.58l-0.3,1.12l-0.28,0.17l-0.59-0.4l-0.26,0.11l0.23,2.56l-0.68,1.01l-1.44,0.83l-0.21,0.94l0.09,0.43l-0.22,0.39
  		l-1.06-0.12l-0.47,0.35l0.03,1.37l-0.14,0.68l-0.82,1.27l-1.47,0.75l-1.97,2.02l-1.03,0.2l-0.32,0.33l-0.44,0.33l-0.86-0.49
  		l-0.57,0.14l0.03,0.77l0.14,0.6l-0.22,0.8l0.25,0.48l1-1.17l1.03-0.83l2.84-1.84l2.6-2.06l1.94-2.06l1.46-1.76l1.08-1.57l2.7-4.35
  		l2.24-5.74l2.42-4.01l2.07-2.63l1.93-1.86l2.09-1.57l1.89-1.12l0.02-1.21l0.88-1.2l0.38-0.93l0.23-1.19l0.13-2.27l-0.31-2.42
  		l0.59-1.67l-0.2-0.98l0.35-0.61l-0.12-0.65l-0.21-0.59l-0.19-1.62l-0.52-1.58l0.01-0.81l0.15-0.84l0.06-1.01l-0.41-0.52l-0.4-0.72
  		l0.29-0.37l0.29-0.01l0.5-0.42l0.36-2.19l-0.31-0.54l-0.56,0l0.11-0.28l1.02-0.25l1.21-1.96l-0.24-0.42l-0.65-0.26l-1.56-0.27
  		l-0.33-1.1l0.74,0.61l0.67,0.1l0.74-0.04l0.41-0.35l-0.26-0.83l0.47-0.35l1.09,0.31l0.74,0.03l-0.48,0.83l0.33,0.12l1.5-1.61
  		l0.8-0.61l0.18-0.89l-0.43-0.02l-0.25-0.26l0.63-0.32l0.32-0.02l0.38-0.4l2.01-1.53l3.83-2.56l2.27-2.28l1.99-1.12l5.55-2.79
  		l1.09-0.28l1.5,0.01l1.16,0.35l0.53-0.02l0.26-0.39l0.09-0.65l0.82-0.76l0.93-0.21l2.22-1.72l2.4-0.41l0.41-0.17l0.42-0.37
  		l-0.43-0.4l-0.44-0.19l-0.06-0.88l0.37-0.45l2.27-0.45l1.85,0.05l0.85-0.59l1.53-0.3l1.37,0.49l0.23,0.38l-0.97,0.32l-0.9-0.09
  		l-0.63,0.19l0.63,0.39l1.37-0.31l1.72-0.18l1.38-0.43l1.22-0.06l0.26-0.46l-0.13-0.53l-0.23-0.44l-0.04-0.3l0.11-0.42l0.63-0.2
  		l0.57-0.02l0.18,0.42l-0.3,0.7l0.16,0.46l0.55,0.35l0.49,0.22l1.09,0.06l2.09-0.29l3.86,0l0.67,0.06l0.46-0.9l0.39-0.5l-0.38-0.46
  		l-0.1-0.8l0.14-0.56l2.32-2.39l1.03-0.58l3.88-1.4l1.03-0.75l0.11-0.69l-0.28-2.72l-0.01-0.12l-0.2-0.8l0.78-2.35l1.06-1.81
  		l0.33-1.1l0.52-0.52l1.1-0.55l1.69-1.87l0.65-1.25l0.17-1.16l0.81-0.76l0.51-2.07l1.18-1.97l1.32-0.81l0.52-0.67l0.44-1.02
  		l0.27-1.53l-0.35-3.75l0.02-1.79l0.75-3.36l1.38-2.27l0.62-0.61l1.13-0.61l1.05-1.26l-0.14-0.53l-0.38-2.82l0.1-1.19l0.33-1.16
  		l0.33-2.41l0.52-2.23l0.86-2.73l0.68-2.77l-0.04-0.19l-0.49-2.38l-0.45-2.66l-0.14-2.72l-0.39-2.4l0.99-5.33l-0.2-0.23l-0.35,0.83
  		l-0.33-0.49l0.11-0.45l-0.05-1.99l0.34-0.8l0.11-0.42l-0.17-0.29l-0.22-0.19l-0.46,0.25l0.18-0.92l0.31-0.98l0.6-0.78l1.04-1.07
  		l0.02-0.97l0.58-1.07l-0.17-0.53l-0.57-0.46l0.54-0.06l0.37-0.29l0.45-1.06l0.31,0.18l1.09,1l0.22,1.65l0.43,0.09l0.38-0.01
  		l1.36-1.04l1.86-2.15l0.52-0.98l2.26-3.19l1.85-3.79l0.48-1.33l-0.22-0.88l0.67-1.2l0.32-0.32l0.01,0.44l-0.09,0.37l-0.2,0.34
  		l-0.01,0.45l0.34-0.24l1.13-1.6l-0.01-1.01l0.48,0.14l0.27-0.26l1.31-1.98l1.43-1.26l1.12-0.69l1.89-0.85l0.11-0.05l1.47-2.19
  		l1.42-1.26l1.43-1.93l0.32-0.64l-0.36-0.72l0.51,0.27l0.56-0.14l1.41-1.37l2.16-2.62l1.54-2.53l1.61-4.41l0.64-2.66l0.47-1.02
  		l-0.02-0.84l-0.35-1.05l0.05-0.47l0.15-0.49l-0.05-0.33l0.03-0.52L1516.46,1380.62z M1434.88,1341.46l-0.57,0.49l-0.11,0.51
  		l0.14,0.66l-0.27,0.95l0.83-0.82l0.14-1.07l-0.04-0.19l0.05-0.6L1434.88,1341.46z M1428.4,1521.21l-0.25-0.22l-1.26,1.49l0.33,0.35
  		l0.93-0.18l0.35,0.24l0.01-0.46l0.08-0.32l0.14-0.24l-0.13-0.38L1428.4,1521.21z M1400.88,1542.77l-1.06,1.07l0.53,1.11l0.16-0.11
  		l0.14-0.2l0.31-0.6l0.28-0.85L1400.88,1542.77z M1484.02,1427l-0.14,0.81l-0.86,0.68l0.03,0.54l0.33-0.18l1.2-0.89l0.01-0.17
  		l-0.12-0.41L1484.02,1427z M1437.5,1515.56l-0.24-0.27l-0.19-0.08l-0.26,0.37l-0.72,0.49l0.34,0.36l0.84-0.19l0.55-0.21l0.49,0.02
  		l-0.26-0.24L1437.5,1515.56z"/>
    </>,
    BQ: <>
        <polygon className="st0" points="1233.86,1215.2 1233.85,1215.58 1234.04,1215.83 1234.39,1216.01 1234.55,1216.31 1234.59,1217.07
  		1234.83,1217.49 1235.24,1216.54 1235.12,1215.8 	"/>
    </>,
    BOL: <>
        <path className="st0" d="M1324.97,1472.47l-0.29-0.53l-0.63-1.51l-1-2.38l-0.07-0.85l-0.37-0.53l-0.61,0.17l-0.72-0.17l-1.81-1.29
  		l-1.2-0.7l-0.41-0.41l-0.18-1.32l-0.36-1.46l-0.15-1.81l0.06-0.43l1.01-1.37l0-0.69l0.09-0.6l-0.04-0.48l-0.25-0.01l-0.41,0.21
  		l-0.61,0.16l-0.35,0.01l-3.53-0.13l-4.02-0.15l-3.34-0.12l-2.9-0.11l-0.1-1.18l-0.16-1.98l-0.12-1.4l-0.18-2.22l-1.16-1.38
  		l-1.26-1.5l-0.44-0.38l1.53-0.05l1.08-0.03l-0.21-4.02l-0.33-0.41l-0.29-1.3l-0.2-0.73l-0.54-0.6l-0.12-0.67l0.1-0.45l0.29-0.28
  		l0.19-0.69l-0.15-0.69l-0.32-0.64l-0.39-0.62l-0.75-0.38l-1.07-0.69l-1.62-0.88l-1.37-0.61l-0.44,0.08l-2.42,0.24l-0.8,0.12
  		l-0.54-0.14l-1.8,0.01l-0.71-0.47l-0.59-0.55l-1.26-1.4l-0.2-0.7l-0.49-0.22l-0.74,0.08l-0.75-0.1l-1.45-0.58l-1.36-0.59
  		l-0.66,0.02l-0.59-0.37l-1.03-0.91l-0.48-0.35l-0.22-0.47l-0.22-0.69l-0.42-0.15l-0.54,0.12l-0.58,0.35l-0.82-0.24l-1-0.64
  		l-0.65-0.5l-0.37-0.24l-0.87-0.35l-0.84-0.07l-1.27,0.51l-1.04-0.21l-1.63-0.19l-1.39-0.37l-0.51-0.97l-0.28-0.64l-0.83-0.4
  		l-0.66-0.49l-0.79-0.74l-0.39-0.25l-0.71-0.21l-0.66-0.26l-0.07-0.47l-0.24-0.62l-0.06-0.15l-0.45-0.75l-0.21-0.05l-0.23,0.15
  		l-0.17,0.11l-0.19-0.13l-0.03-0.33l0.12-0.54l-0.26-0.56l-0.64-0.59l-0.33-0.61l-0.03-0.63l-0.14-0.42l-0.26-0.21l-0.14-0.37
  		l-0.03-0.53l0.19-0.63l0.4-0.72l-0.09-1.12l-0.57-1.51l-0.32-1.09l-0.06-0.67l0.09-0.49l0.35-0.48l0.69-1.18l0.12-0.9l-0.22-1.01
  		l-0.03-0.77l0.16-0.53l-0.24-0.7l-0.49-0.66l-0.34-0.02l-0.47,0.18l-0.56,0.56l-0.66,0.1l-0.59-0.34l-1.84,0.14l-2.85,0.34
  		l-1.14,0.36l-0.67,0.15l-0.81,0.12l-1.3,0.64l-3.21,2.48l-0.66,0.36l-0.76,0.05l-0.44,0.34l-0.71,0.27l-1.39,0.98l-0.71,0.79
  		l-0.46,0.71l-0.54,0.02l-0.42-0.2l-1.32,0.1l-0.67,0.24l-0.73,0.69l-0.91,1.25l-0.38,0.36l-0.73,0.37l-0.85,0.31l-1.05,0.46
  		l-0.47,0.03l-0.41,0.08l-0.36-0.21l-0.12-0.45l-0.54-0.28l-1.29-0.14l-1.91-0.33l-1.97-0.06l-0.98,0.03l1.05,1.84l0.77,1.35
  		l0.88,1.53l0.71,1.24l1.07,1.88l0.93,1.62l0.99,1.73l1.12,1.97l-0.36,0.5l-0.29,0.4l0.03,0.68l-0.44,0.36l-0.47,0.22l-0.56,0.57
  		l-0.38,0.49l-0.02,0.7l0.07,3.57l-0.09,0.97l-0.29,0.84l-0.3,0.42l-0.18,0.33l0.43,0.83l0.41,1.67l0.31,0.33l0.39,0.68l0.18,0.64
  		l-0.08,0.25l-0.77,0.3l-0.28,0.26l-0.07,0.96l-0.33,0.34l-0.56,0.45l-0.36,0.52l-0.31,0.36l-0.3,0.21l-0.15,0.63l-0.2,0.64
  		l-0.7,0.42l-0.12,0.79l-0.01,0.65l0.37,0.65l1.21,1.37l0.12,0.32l-0.69,0.83l-0.4,0.57l-0.53,0.94l0.04,0.02l0.52,0.95l0.25,0.05
  		l0.39,0.28l0.29,0.16l0.16,0.16l0.2,0.24l0.37,0.35l0.54,0.26l0.2,0.45v0.53l0.17,0.13l0.76,0.15l0.61,0.04l0.3,0.21l-0.11,0.19
  		l-0.14,0.2l-0.23,0.27l-0.3,0.3l0.15,0.44l0.35,0.47l0.55,0.1l0.59,0.14l0.5,0.02l0.35,0.15l0,0.28l0.06,0.14l-0.02,0.12l-0.2,0.08
  		l-0.36,0.06l-0.33,0.07l-0.1,0.11l-0.06,0.17l-0.22,0.21l-0.41,0.21l-0.61,0.07l-0.39,0.08l-0.19,0.21l0.26,0.14l0.25,0.31
  		l0.09,0.21l0.01,0.2l-0.05,0.18l-0.07,0.18h-0.28l-0.32-0.06l-0.54-0.27l-0.26-0.16l-0.01,0.09l0.15,0.86l-0.29,0.28l-0.66,0.33
  		l-0.57,0.48l-0.57,0.8l-0.96,1.21l-0.33,0.33l-0.15,0.42l-0.55,0.14l-1.02,0.82l-0.18,0.42l0.17,0.4l0.52,0.33l0.35,0.48l0.09,0.62
  		l0,0.38l0,0.01l0.13,0.98l1.15,1.31l0.38,1.48l0.26,0.19l1.59,0.74l0.03,0.17l-0.23,0.28l-0.23,0.36l0.16,0.51l0.29,0.69l0.1,0.64
  		l0.18,0.66l0.17,1.01l0.11,0.92l0.4,1.36l0.08,0.84l0.01,0.51l0.31,0.5l0.62,0.59l0.83,0.6l0.66,0.7l0.51,0.47l0.61,0.39l0.47,0.35
  		l0.18,0.24l0.06,0.2l-0.2,0.19l-0.74,0.92l-1.04,1.4l0.02,0.17l0.99,1.01l0.16,0.4l-0.01,0.56l-0.33,0.68l-1.07,0.22l-0.24,0.18
  		l-0.03,0.22l0.25,0.29l-0.04,0.67l0.39,0.74l-0.2,0.25l-0.4,0.34l-0.01,0.33l0.13,0.37l0.42,0.3l1.65,1.04l0.13,0.14l-0.03,0.11
  		l-0.64,0.69l-0.07,0.43l0.02,0.71l0.09,0.46l0.21,0.19l0.83,0.22l1.02,1.59l0.98,1.49l-0.01,1.29l0.1,1.5l0.63,1.18l0.09,0.94
  		l0.21,1.07l0.74,0.65l0.29,1.29l0.08,0.69l-0.05,0.45l0.58,1.41l0.07,1.21l-0.14,0.87l0.01,0.48l0.08,0.34l0.5,0.31l0.22,0.19
  		l0.73,0.09l1.07,0.02l1.83-0.32l1.41-0.3l0.28-0.42l0.9-1.08l0.18-0.87l0.36-0.37l1.61-0.88l0.28-0.59l0.14-0.65l0.33-0.47
  		l0.61-0.1l1.11-0.41l1.19-0.39l0.36-0.53l0.34-0.93l0.29-1.03l0.23-0.25l0.38,0.03l0.64,0.26l0.34,0.39l1.67,1.23l0.75,0.7
  		l0.71,0.09l1.41-0.14l0.29,0.03l3.6,0.04l0.55,0.06l1.26,0.3l0.71,0.24l0.49,0.13l0.8,0.38l0.69,1.26l0.39,1l0.27,0.88l0.6,1.55
  		l0.41,0.59l0.15-0.29l0.35-1.69l0.48-0.99l0.65-1.11l1.31-2.58l0.46-0.39l0.51-0.19l0.36-0.02l0.36,0.19l0.49,0l0.35-0.2l3.44-0.03
  		l3.57-0.03l0.08,0.02l0.16,0.44l0.59,0.97l0.67,0.51l0.12,0.14l0.19-0.44l0.52-1.72l0.75-2.49l0.78-2.57l0.92-3.03l0-2.15l0-2.26
  		l1.3-1.86l0.92-1.32l0.8-1.25l0.81-2.14l0.54-1.43l2.06-0.34l3.5-0.74l1.75-0.37l3.68-0.78l3.74-0.79l3.93-0.05l3.79-0.05
  		l2.94,1.77l2.25,1.36l2.48,1.49l0.17,0.32l0.17,1.25l-0.17,1.45l0.56-0.12l0.22-0.35l0.39-0.48l0.51-0.13l0.61-0.18l0.23-0.36
  		l-1.43-1.28l-0.86-0.77l0.5-1.04l0.85-1.75l0.82-1.69l0.62-1.29l0.16-0.24l0.54-0.08l-0.1-0.67l-0.02-0.44l-0.44-0.03l0.49-1.57
  		l0.72-2.24l0.55-1.69l0.18-0.28l0.4-0.08l0.09-0.2L1324.97,1472.47z M1228.72,1455.72l0.29-0.14l0.34,0.16l0.17-0.01l-0.05-0.3
  		l-0.47-0.26l-0.57-0.56l-0.53-0.25l-0.21,0.05l-0.04,0.39l-0.11,0.39l-0.1,0.38l0.73-0.04L1228.72,1455.72z"/>
    </>,
    BRN: <>
        <path className="st0" d="M2778.69,1276.64l-0.44-0.05l-1.3,0.64l-0.84,0.55l-0.8,0.69l-0.85,0.62l-1.01,0.54l-1.05,0.45l-1.02,0.14
  		l-0.96-0.01l0.26,0.23l0.62,0.32l0.47,0.41l0.31,0.53l0.22,0.5l0.02,0.42l0.28,0.35l0.79,0.06l0.26,0.44l0.55,0.75l0.5,0.54
  		l0.31,0.21l0.39-0.11l0.6-0.49l0.43-0.61l0.29-0.82l-0.23-0.12l0.4-0.62l0.08-0.32l-0.19-0.3l-0.24-0.29l-0.09-0.75l-0.16-0.95
  		l-0.11-0.43l0.32-0.31l0.68-0.39l0.68-0.46l0.69-0.36l0.17-0.53L2778.69,1276.64z M2780.64,1281.34l-0.11-1.49l-0.33-0.98
  		l-0.5-0.97l-0.24-0.28l-0.95,0l0.02,0.66l-0.02,1.09l0.21,0.91l0.47,1.61l0.54,0.22l0.64,0.14l0.37-0.04l0.24-0.11l0.06-0.13
  		L2780.64,1281.34z"/>
    </>,
    BMU: <>
        <polygon className="st1" points="1264.81,1037.69 1264.17,1038.43 1263.39,1038.75 1263.54,1038.85 1263.75,1038.88 1264.51,1038.56
  		1265.03,1037.74 	"/>
    </>,
    BLM: <>
        <polygon className="st1" points="1280.22,1167.11 1280.14,1167.07 1280.13,1167.15 1280.18,1167.28 1280.27,1167.41 1280.37,1167.48
  		1280.5,1167.47 1280.62,1167.39 1280.71,1167.28 1280.77,1167.19 	"/>
    </>,
    BEN: <>
        <polygon className="st1" points="1841.96,1229.18 1841.34,1228.2 1841.24,1227.51 1841.15,1226.48 1841,1225.57 1840.82,1225.23
  		1840.5,1224.93 1840.35,1224.75 1839.08,1222.89 1839.1,1222.01 1839.63,1220.89 1839.98,1220.34 1839.45,1219.57 1838.76,1219.02
  		1838,1218.78 1837.49,1218.38 1837.22,1217.83 1836.23,1216.76 1833.94,1214.64 1833.71,1214.59 1833.33,1214.51 1832.68,1214.76
  		1832.28,1215.11 1832.01,1215.25 1831.59,1215.27 1830.5,1215.53 1829.63,1215.88 1829.61,1216.17 1830.02,1217.77
  		1829.82,1218.64 1829.61,1219.12 1828.97,1220.39 1828.49,1220.91 1826.38,1222.7 1825.35,1222.49 1823.18,1222.85
  		1822.85,1222.44 1822.35,1222.38 1821.72,1222.46 1821.49,1222.61 1821.42,1222.79 1821.2,1223.03 1820.8,1223.74 1820.49,1223.92
  		1820.1,1224.03 1819.63,1224.02 1819.35,1224.11 1819.35,1224.46 1819.27,1224.77 1818.95,1224.92 1818.81,1225.26 1818.84,1225.6
  		1818.65,1225.75 1818.24,1225.67 1818,1225.57 1817.77,1226.01 1817.49,1226.3 1817.29,1226.3 1817.07,1227.21 1816.62,1228.34
  		1816.33,1228.7 1816.13,1231.43 1816.27,1231.66 1816.37,1231.73 1817.77,1232.66 1819.61,1233.87 1820.9,1234.73 1821.01,1235.02
  		1821.03,1236.81 1821.05,1238.36 1821.32,1239.24 1821.37,1240.09 1821.7,1240.74 1822.89,1241.99 1823.18,1242.72
  		1823.21,1245.08 1823.23,1246.86 1823.38,1249.29 1823.38,1251.32 1823.38,1253.88 1823.39,1256.88 1823.39,1260.01
  		1822.6,1260.05 1823.03,1261.02 1823.1,1261.9 1823.2,1262.19 1822.99,1262.61 1823.16,1263.26 1823.51,1263.5 1824.38,1264.81
  		1824.68,1265.91 1823.27,1266.28 1823.37,1266.57 1825.01,1266.2 1828.96,1265.63 1832.5,1265.29 1832.51,1264.8 1832.74,1263.38
  		1832.89,1262.83 1833.07,1262.41 1832.89,1261.91 1832.71,1261.22 1832.62,1260.15 1832.84,1259.82 1832.92,1259.42
  		1832.87,1258.78 1832.87,1256.66 1833,1256.43 1833.15,1256.26 1833.16,1255.98 1832.87,1255.43 1832.6,1254.8 1832.61,1253.9
  		1832.51,1253.03 1832.32,1252.64 1832.46,1251.15 1832.54,1249.27 1832.47,1248.44 1832.64,1247.85 1832.73,1246.4
  		1832.72,1244.98 1833.07,1242.74 1834.11,1242.63 1835.35,1242.44 1835.9,1241.56 1836.22,1240.44 1836.12,1239.34
  		1836.36,1238.97 1836.85,1238.37 1837.74,1237.52 1837.71,1236.58 1837.95,1236.29 1838.38,1236.07 1838.98,1235.95
  		1839.66,1235.49 1840.04,1234.67 1840.41,1233.35 1839.82,1232.44 1839.84,1232.23 1840.06,1231.74 1840.41,1231.25
  		1840.7,1231.09 1841.36,1231.21 1841.47,1231.17 1841.57,1231.02 1842,1229.57 	"/>
    </>,
    BDI: <>
        <polygon className="st1" points="2069.21,1344.73 2069.06,1344.33 2069.09,1343.88 2068.95,1343.62 2068.35,1343.56 2067.47,1343.21
  		2066.71,1343.06 2066.21,1342.86 2066.03,1342.7 2065.95,1342.28 2066.09,1341.82 2066.17,1341.69 2066.36,1341.19 2066.03,1340.9
  		2065.95,1340.75 2066.1,1340.52 2066.87,1338.95 2067.04,1338.73 2066.83,1338.69 2066.44,1338.53 2065.82,1338 2064.66,1338.29
  		2064.34,1338.28 2063.92,1338.54 2063.57,1338.84 2063.36,1338.87 2063.15,1338.82 2062.15,1338.2 2061.79,1338.22
  		2061.64,1339.97 2061.47,1340.94 2061.27,1341.38 2060.55,1341.8 2059.83,1342.03 2059.44,1342.02 2057.86,1342.15
  		2057.24,1342.15 2056.9,1342.01 2056.46,1341.01 2055.62,1340.57 2054.81,1340.37 2054.49,1340.42 2054.19,1340.94
  		2054.07,1341.41 2054.07,1341.73 2054.09,1342.07 2054.5,1342.5 2055.24,1343.38 2055.84,1344.2 2055.86,1344.91 2055.74,1346.11
  		2055.73,1346.61 2056.47,1346.73 2056.75,1347.02 2056.74,1347.62 2056.77,1349.3 2056.87,1350.4 2057.02,1350.92 2057.61,1351.93
  		2057.6,1352.14 2058.36,1353.83 2058.83,1354.64 2059.25,1355.19 2059.45,1355.65 2059.4,1355.94 2060,1355.95 2060.43,1355.63
  		2061.93,1354.7 2063.62,1352.84 2063.95,1352.07 2064.64,1350.88 2065.31,1350.28 2065.57,1349.87 2065.74,1349.23
  		2065.96,1348.68 2066.84,1347.88 2067.7,1347.26 2067.64,1347.01 2067.52,1346.82 2067.65,1346.66 2068.12,1346.34
  		2069.03,1346.05 2069.21,1345.43 	"/>
    </>,
    BHR: <>
        <polygon className="st1" points="2235.96,1094.93 2235.52,1094.27 2235.76,1093.89 2235.58,1093.84 2234.78,1093.99 2234.64,1094.34
  		2234.95,1095.52 2234.75,1096.36 2235.41,1097.54 2235.67,1097.78 2235.94,1097.1 2236.03,1096.03 	"/>
    </>,
    BGR: <>
        <polygon className="st1" points="2049.1,926.92 2048.69,926.87 2047.4,926.58 2045.95,926.08 2045.1,925.09 2044.56,924.38
  		2043.33,924.7 2043.09,924.61 2042.76,924.27 2041.83,924.04 2040.69,924.04 2038.12,922.75 2037.84,922.53 2035.83,922.75
  		2032.81,923.39 2030.5,924.18 2028.13,925.58 2027.16,926.65 2026.04,927.21 2024.45,927.63 2021.61,927.47 2018.65,926.94
  		2015.47,926.36 2013.75,926.68 2011.43,926.44 2007.92,925.76 2005.31,925.55 2002.74,925.95 2002.3,925.64 2002.21,925.29
  		2002.31,924.79 2002.67,924.38 2003.3,924.08 2003.62,923.76 2003.66,923.45 2002.96,922.94 2001.52,922.24 2000.93,921.8
  		2000.62,921.98 2000.27,922.25 2000.08,922.72 2000.03,923.48 1998.95,924.07 1998.54,924.17 1998.36,924.56 1998.07,925.67
  		1998.11,926.5 1998.25,926.92 1998.32,927.26 1998.67,927.69 1998.99,928.33 1999.2,929.19 1999.67,929.85 2000.87,930.49
  		2001.46,930.87 2001.9,931.42 2002.24,931.91 2003.22,932.57 2003.15,933.04 2002.93,933.5 2002.7,933.71 2002.21,934.3
  		2001.73,934.63 2000.94,935.67 1999.7,935.72 1999.4,935.8 1998.93,936.09 1998.7,936.6 1998.92,937.02 1998.9,937.44
  		1998.67,938.25 1998.97,939.13 1999.41,939.52 1999.48,939.75 1999.41,940.16 1998.75,940.99 1998.55,941.3 1997.89,941.45
  		1999.19,942.95 1999.9,943.56 2000.74,944.02 2001.7,944.36 2002.04,944.68 2002.65,946.28 2002.95,946.89 2003.35,947.07
  		2003.45,947.24 2003.47,947.47 2003.01,948.59 2002.83,951.09 2002.71,951.29 2003.63,951.4 2004.73,951.44 2005.44,950.81
  		2006.55,950.76 2007.07,950.67 2007.93,950.79 2008.77,950.79 2009.84,950.52 2010.84,950.09 2011.62,950.13 2011.94,950.05
  		2012.12,949.96 2012.31,949.38 2013.78,949.34 2014.28,949.4 2015.1,949.41 2015.95,949.1 2016.21,949.12 2016.64,949.98
  		2016.86,950.23 2017.32,950.45 2018.36,951.09 2018.54,950.93 2018.97,950.71 2020.21,951.01 2021.39,951.5 2022.38,952.22
  		2023.48,952.01 2024.7,951.66 2025.5,951.56 2026.36,951.51 2026.87,951.35 2028.04,951.54 2029.25,951.15 2029.83,950.8
  		2029.99,950.3 2029.89,949.43 2029.62,948.56 2029.33,948.24 2029.24,947.91 2029.41,947.6 2029.59,947.38 2030.38,947.2
  		2031.39,947.47 2031.36,947.19 2031.44,946.91 2031.72,946.62 2032.99,946.37 2033.14,946.16 2033.32,945.66 2033.57,945.14
  		2033.87,944.97 2034.41,944.99 2035.43,944.87 2036.14,944.7 2036.85,944.35 2037.21,944.03 2038.74,943.84 2039.17,943.68
  		2039.59,943.82 2040.16,944.37 2041.11,945.16 2041.61,945.42 2041.99,945.3 2042.68,945.01 2043.33,945.01 2043.86,945.06
  		2044.12,944.81 2044.51,944.75 2045.65,944.93 2045.39,944.14 2044.03,942.52 2043.07,941.08 2042.5,940.57 2041.19,939.89
  		2043.46,937.47 2044,937.37 2044.59,937.03 2044.66,934.27 2044.93,932.59 2045.36,932.13 2045.83,931.75 2046.66,930.45
  		2048.22,930.13 2049.45,930.51 2050.26,929.37 2050.46,926.9 	"/>
    </>,
    BFA: <>
        <polygon className="st1" points="1829.44,1218.22 1828.18,1216.61 1827.32,1215.41 1827.16,1215.14 1827.04,1214.73 1827.12,1214.55
  		1827.47,1214.42 1828.26,1214.26 1828.41,1214.14 1828.45,1213.81 1828.33,1213.19 1827.89,1212.36 1827.43,1211.81
  		1827.17,1211.7 1826.69,1211.68 1826.18,1211.76 1825.21,1212.43 1824.78,1212.56 1823.78,1212.5 1822.88,1212.37 1822.34,1212.02
  		1820.72,1210.68 1818.94,1209.26 1818.19,1209.06 1818.02,1208.91 1817.9,1207.82 1817.93,1206.5 1818.03,1206.16 1818.77,1206.37
  		1819.56,1206.46 1819.82,1206.22 1819.18,1205.76 1818.27,1205.28 1817.94,1204.57 1817.67,1204.32 1817.26,1204.06
  		1816.8,1203.93 1816.32,1203.73 1816,1203.52 1815.47,1203.43 1814.91,1203.28 1814.1,1202.11 1813.32,1200.98 1812.85,1200.09
  		1812.69,1199.56 1812.92,1198.65 1812.69,1198.29 1811.81,1197.36 1811.08,1196.5 1811.26,1195.17 1811.41,1194.06
  		1811.42,1193.36 1811.53,1192.96 1809.76,1192.33 1807.71,1191.69 1806.29,1192.09 1806.06,1191.96 1805.87,1191.69
  		1805.18,1191.53 1804.09,1191.6 1803.3,1191.79 1802.05,1192.74 1801.12,1193.56 1800.86,1193.74 1799.55,1194.24 1797.12,1195.4
  		1795.74,1196.25 1795.42,1196.4 1794.81,1196.6 1793.87,1196.63 1793.08,1196.85 1792.38,1199.08 1791.9,1199.31 1789.01,1198.41
  		1788.42,1198.54 1787.91,1198.8 1786.3,1200.12 1785.5,1201.17 1785.06,1202.56 1785.12,1202.99 1785.13,1203.48 1784.85,1203.74
  		1784.46,1203.84 1784.11,1203.82 1782.76,1203.54 1782.34,1203.66 1782.16,1204.35 1782.19,1205.85 1781.89,1206.88
  		1781.09,1207.19 1780.48,1207.6 1779.99,1207.71 1779.58,1207.61 1777.25,1206.09 1776.45,1205.84 1775.58,1206.01
  		1774.74,1206.66 1774.36,1207.09 1773.83,1207.59 1773.24,1208.25 1773.4,1208.82 1773.82,1209.48 1774.11,1210.29 1774.1,1211.02
  		1771.96,1212.06 1772.14,1212.41 1772.46,1212.83 1772.46,1213.58 1772.4,1214.9 1771.97,1215.37 1771.41,1215.84 1771.07,1216.45
  		1770.73,1216.75 1770.12,1217.12 1769.29,1217.49 1767.85,1217.82 1766.7,1218.04 1766.26,1218.26 1765.65,1218.7 1765.16,1219.23
  		1765.05,1219.8 1765.14,1220.45 1765.31,1220.99 1765.53,1221.36 1765.66,1221.82 1765.48,1223.06 1765.06,1224.5 1764.66,1225.14
  		1764.02,1225.49 1763.46,1225.89 1763.64,1226.83 1763.74,1228.18 1763.58,1229.26 1763.55,1229.93 1763.32,1230.62
  		1763.18,1231.1 1763.7,1231.66 1764.37,1232.05 1765.38,1232 1766.11,1232.23 1766.75,1232.66 1767.17,1233.62 1767.64,1234.31
  		1767.84,1235.29 1768.58,1235.81 1769.15,1236.05 1769.93,1236.76 1770.74,1237.12 1771.58,1237.04 1771.97,1237.41
  		1772.59,1237.68 1773.21,1237.7 1773.76,1236.87 1774.49,1236.55 1776.32,1235.89 1777.04,1235.59 1777.77,1235.4 1779.54,1235.34
  		1781.18,1235.55 1781.99,1235.7 1782.55,1235.59 1783.08,1235.98 1783.63,1236.8 1784.07,1237.06 1784.53,1237.34 1784.87,1237.99
  		1785.27,1238.63 1785.49,1238.92 1785.98,1239.55 1786.4,1239.56 1786.82,1239.29 1787,1239.08 1786.91,1238.64 1786.4,1237.59
  		1786.28,1236.85 1786.54,1236.42 1786.53,1235.47 1786.26,1234 1786.22,1233.08 1786.4,1232.69 1786.31,1232.32 1785.95,1231.97
  		1785.92,1231.64 1786.23,1231.31 1786.19,1231.05 1785.8,1230.86 1785.46,1230.41 1785.15,1229.7 1785.21,1228.55 1785.79,1226.43
  		1785.87,1226.26 1786.52,1226.27 1786.53,1226.36 1788.57,1226.34 1790.9,1226.32 1793.69,1226.29 1796.23,1226.26
  		1796.34,1226.17 1796.76,1226.05 1799.32,1226.27 1800.92,1226.16 1801.6,1226.23 1802.1,1226.37 1803.2,1226.28 1803.79,1226.34
  		1804.24,1226.86 1804.42,1226.86 1804.67,1226.64 1805.11,1226.38 1805.56,1226.18 1805.88,1225.77 1806.08,1225.45
  		1806.37,1225.52 1806.79,1225.5 1807.07,1225.24 1807.18,1224.83 1809.12,1225.26 1811.04,1225.65 1813.78,1226.31
  		1813.83,1226.43 1813.85,1226.63 1814.33,1226.62 1815.12,1226.39 1817.29,1226.3 1817.49,1226.3 1817.77,1226.01 1818,1225.57
  		1818.24,1225.67 1818.65,1225.75 1818.84,1225.6 1818.81,1225.26 1818.95,1224.92 1819.27,1224.77 1819.35,1224.46
  		1819.35,1224.11 1819.63,1224.02 1820.1,1224.03 1820.49,1223.92 1820.8,1223.74 1821.2,1223.03 1821.42,1222.79 1821.49,1222.61
  		1821.72,1222.46 1822.35,1222.38 1822.85,1222.44 1823.18,1222.85 1825.35,1222.49 1826.38,1222.7 1828.49,1220.91
  		1828.97,1220.39 1829.61,1219.12 1829.82,1218.64 	"/>
    </>,
    BEL: <>
        <polygon className="st1" points="1863.13,856.12 1863.11,855.55 1862.72,855.18 1861.95,855.03 1861.74,854.77 1861.66,854.52
  		1862.22,853.95 1861.54,853.5 1861.24,853.04 1860.29,852.45 1860.19,852.25 1859.33,852.23 1858.81,851.6 1857.66,851.91
  		1857.66,851.98 1857.45,851.63 1857.2,851.22 1857.27,850.96 1858.02,850.23 1858.13,850.03 1858.05,849.92 1858.13,849.6
  		1858.71,848.52 1858.78,848.08 1858.52,847.78 1858.15,847.59 1856.94,847.27 1856.37,846.81 1856.1,846.42 1855.83,846.3
  		1855.43,846.44 1854.43,846.59 1853.62,846.37 1852.66,845.62 1852.43,844.95 1852.31,844.43 1852.07,844.25 1851.75,844.51
  		1851.34,844.93 1850.53,844.98 1850.3,844.89 1850.26,844.65 1850.22,844.43 1850,844.16 1849.76,844 1848.73,844.78
  		1848.35,844.78 1847.87,844.48 1847.63,844.19 1847.1,844.35 1846.63,844.71 1846.79,845.39 1846.54,845.51 1845.96,845.45
  		1845.3,845.17 1845.17,845.59 1844.84,846.06 1843.73,846.73 1842.57,847.17 1841.97,847.11 1841.55,846.88 1841.33,846.64
  		1840.71,846.41 1839.86,846.29 1839.32,846.54 1838.94,846.78 1838.61,846.74 1838.36,846.54 1838.17,846.24 1837.92,845.27
  		1836.87,845.56 1834.63,846.52 1830.97,848.4 1831.06,848.93 1831.39,849.61 1831.61,849.98 1831.43,850.46 1831.57,850.86
  		1832.18,851.57 1832.94,852.25 1833.62,852.68 1833.81,852.63 1834.31,852.51 1835.16,852.07 1835.87,851.93 1836.27,852.27
  		1836.5,852.46 1836.95,853.22 1837.07,854.01 1837.27,854.67 1837.63,854.94 1838.99,855.03 1839.98,855.27 1840.25,855.49
  		1840.59,856.95 1840.77,857.16 1841.02,856.99 1841.27,856.75 1841.61,856.71 1842.2,856.8 1842.97,856.83 1843.76,856.99
  		1844.86,857.82 1844.82,858.09 1844.61,858.57 1844.53,858.95 1844.72,859.1 1845.01,859.49 1844.94,859.95 1844.66,860.27
  		1844.54,860.53 1844.55,860.7 1844.65,860.84 1844.87,860.96 1846.5,861.13 1847.98,860.96 1848.92,860.5 1849.08,860.01
  		1849.34,859.46 1849.9,859 1850.29,858.84 1850.64,859.03 1850.05,860.97 1850.48,861.47 1850.54,862.21 1850.7,862.85
  		1851.23,862.84 1851.87,862.96 1852.33,863.2 1852.86,863.58 1853.62,863.93 1854.16,864.06 1854.36,864.35 1854.79,864.69
  		1855.47,865.41 1856.09,865.88 1856.38,865.88 1856.95,865.7 1857.8,865.58 1858.47,865.59 1858.68,865.42 1858.87,865.15
  		1859.03,864.77 1859.23,864.42 1858.58,863.47 1858.45,863.17 1857.92,862.63 1857.93,862.36 1858.05,862.1 1858.01,861.89
  		1858.08,861.41 1858.45,860.95 1858.7,860.39 1859.12,859.62 1860.04,858.69 1860.7,858.83 1860.99,858.83 1861.16,859.17
  		1861.22,859.2 1861.26,859 1861.71,857.97 1863.31,857.05 	"/>
    </>,
    BGD: <>
        <path className="st0" d="M2576.82,1126.97l-0.29,3.65l0.89-0.62l0.23-0.95l-0.17-0.73L2576.82,1126.97z M2580.55,1127.23l-0.33-0.76
  		l-0.24-0.35l-0.14,0.16l-0.23,1.08l0.47,0.86l0.37,0.23l0.39-0.26l-0.07-0.38L2580.55,1127.23z M2573.28,1123.44l-0.15-0.38
  		l-0.52-0.64l-0.37,0.18l0.22,0.35l0.35,0.26L2573.28,1123.44z M2573.61,1125.26l-0.14-0.63l-0.09-0.25l-0.64-0.44l-0.79,0.25
  		l0.16,0.77l0.32,0.66l0.04,0.48l0.71,0.68l0.22,0.84l0.05,1.03l-1.39,2.31l0.75,0.1l1.47-0.31l0.44-0.62l0.3-2.03l0.02-0.83
  		l-1.11-1.36L2573.61,1125.26z M2589.84,1137.24l-0.27-1.02l-0.08-1.24l-0.01-2.91l-0.07-0.33l-0.12-0.61l-0.25-3.19l-0.19-1.02
  		l-0.15-1.41l-0.23-0.43l-0.29-0.77l-0.31-0.66l-0.27-0.28l-0.17-1.24l-0.06-1.52l0-0.72l-0.38-1.49l-0.36-1.69l-0.5,0.07
  		l-0.29-0.41l-0.21,0.01l-0.7,0.38l-0.55-0.13l-0.41,0.05l0,0.78l0.07,0.83l-0.16,0.3l-1.09,0.97l-0.3,0.65l0.03,0.68l0.13,0.92
  		l-0.19,0.46l-0.47,0.43l-0.64,0.22l-0.56-0.1l-0.36-0.37l-0.34-0.95l-0.3-0.52l-0.31-0.12l-0.24,0.14l-0.03,0.6l0.02,0.49
  		l-0.08,0.05l-0.17-0.08l-0.2-0.24l-0.52-2.38l-0.74-1.84l-0.04-0.7l0.27-0.91l0.33-1.4l0.88-0.87l0.12-0.37l0.14-0.29l0.22-0.06
  		l1.13,0.08l0.38-0.14l0.33-0.41l0.49-0.33l0.49-0.13l0.39-0.05l0.62,0.31l0.26-0.18l0.19-0.58l0.27-0.58l0.17-0.28l0.42-0.13
  		l0.53-0.03l0.18-0.11l0.14-0.19l0.13-0.76l0.68-1.71l0.24-0.76l0.03-0.14l-0.02-0.85l0.19-0.12l1.13,0.41l0.49-0.01l0.27-0.17
  		l0.09-0.31l-0.14-0.36l-0.8-0.63l-1.42-0.85l-1.31-0.52l-2.41,0.08l-2.39,0.17l-0.7-0.08l-0.87-0.24l-2.15,0.03l-2.59,0.13
  		l-0.99-0.07l-0.49,0.01l-0.98,0.08l-1.59-0.24l-1.1-0.31l-0.98-0.34l-1.16-0.31l-0.28,0l-0.16-0.11l-0.11-0.28l-0.04-0.35
  		l0.24-1.65l-0.21-2.5l0.2-0.91l-0.95-2.06l-0.33-0.38l-0.44-0.02l-0.28,0.27l-0.11,0.48l0.16,0.54l-0.35,0.6l-0.7,0.2l-0.82-0.2
  		l-0.68-0.28l-0.87-0.61l-0.66-0.86l-0.05-0.95l-0.3-0.62l-0.41-0.3l-0.3-0.08l-0.26,0.07l-0.23,0.33l0.2,0.33l0.28,0.47l-0.09,0.32
  		l-0.25,0.05l-0.37-0.14l-0.58,0.07l-0.56-0.24l-0.33-0.02l-0.33-0.09l-0.02-0.55l-0.51-0.7l-0.86-0.78l-0.84-0.48l-0.41,0.07
  		l-0.2,0.53l0.05,0.2l0.29,0.1l0.43,0.31l0.09,0.32l-0.06,0.28l-0.53,0.52l-0.37,0.49l-0.83,0.71l-0.71,0.82l-0.18,0.62l-0.27,0.55
  		l-0.11,0.61l0.19,0.42l0.34,0.27l0.89,0.19l0.93,0.82l0.75,1.11l0.42,0.33l0.77,0.37l1.48,0.04l0.22,0.31l0.21,0.81l0.29,0.29
  		l0.75,0.38l0.06,0.28l-0.18,0.32l-0.33,0.26l-0.61,0.16l-0.59,0.06l-0.59-0.1l-0.87-0.07l-0.99,0l-0.7,2.02l-0.5,0.71l-0.29,0
  		l-0.76-0.34l-0.33,0.05l-0.88,1.8l-0.12,0.43l-0.06,0.33l0.47,0.69l0.56,0.57l0.67,0.22l0.52-0.17l0.42,0.23l0.5,0.57l0.86,0.38
  		l1.21,0.18l0.68,0.45l0.08,0.39l-0.06,0.4l-0.11,1.04l-0.12,0.6l-0.65,1.56l-0.47,1.35l0.24,0.64l0.17,0.26l0.16,0.2l0.52,0.5
  		l0.36,0.5l-0.31,1.27l0.17,0.33l0.7,0.22l0.75,0.17l0.26,0.21l-0.65,1.29l0.14,0.9l0.28,0.84l0.2,1.38l0.03,0.14l-0.05,0.35
  		l0.43,1.07l0.66,2.08l0.05,0.78l-0.04,0.82l0.25,0.69l0.1,1.25l0.62,0.78l0.57,0.54l0.37,0.14l0.63-0.12l0.83-0.88l0.41-0.82
  		l0.02-1.03l-0.28-1.59l0.12-0.55l0.54,2.57l0.16,1.08l0.02,0.82l0.5-0.41l0.33-0.56l0.75-0.36l0.46-0.57l0.34-0.94l0.11-0.72
  		l-0.11-1.02l0.24-0.87l0.87-0.69l-0.77,1.39l0,0.93l0.2,0.76l0.3,0.82l0.96-0.66l0.63-0.35l0.56-0.16l-1.03,1.22l-0.15,0.51
  		l0.01,0.64l0.5,0.35l0.24,0.27l0.6-0.11l0.49-0.61l0.57-1.31l1.17-1.15l0.49-0.35l0.31-0.35l0.22-0.91l-0.17-0.65l-0.54-0.91
  		l-0.37-0.44l0.09-0.41l-0.15-0.44l-0.38-0.59l0.02-0.67l0.21-0.47l0.76-0.2l-0.63-0.72l-0.1-0.59l0.52-0.27l0.57-0.43l0.03-0.62
  		l-0.07-0.55l-0.57-0.71l-1.11-0.18l-1.03-0.79l1.17,0.21l1.24,0.09l0.1-0.47l-0.05-0.56l0.1-0.36l0.26-0.12l0.1,0.53l0,0.79
  		l0.34,1.5l-0.19,1.58l0.19,0.61l1.44,2.68l1,1.1l1.73-0.15l0.55-0.25l0.82-0.82l0.81-0.55l0.59-0.77l0.02,0.77l0.4,0.79l1.38,1.79
  		l0.35,0.86l0.53,0.96l0.41-0.4l0.15-0.06l-0.05,0.29l-0.28,0.78l0.21,0.63l0.53,2.41l0.8,1.74l0.02,1.48l0.38,2.99l1.17,1.67
  		l0.45,0.88l0.5,0.81l0.14-0.01l-0.1-0.63l-0.22-0.59l-0.15-0.64l-0.03-0.5l-0.42-0.45l-0.19-0.78l-0.1-0.8l0.24-0.57l0.6-0.61
  		l0.43-0.11l0.35,0.27l0.84,0.4l0.57,0.38l0.25,0.49l0.26-0.06l0.27-0.32L2589.84,1137.24z M2583.58,1132.57l-0.18-0.38l-0.09,0
  		l-0.13,0.37l-0.13,0.66l0.15,0.52l0.31-0.72L2583.58,1132.57z M2584.01,1133.98l-0.22-0.01l-0.43,0.12l0.14,1.18l-0.12,0.36
  		l0.25,0.26l0.51-0.04l0.11-0.89l-0.11-0.64L2584.01,1133.98z"/>
    </>,
    BB: <>
        <polygon className="st1" points="1308.66,1207.59 1307.79,1206.56 1307.33,1206.69 1307.36,1207.99 1307.63,1208.4 1308.38,1208.74
  		1308.62,1208.57 1309.17,1207.97 	"/>
    </>,
    BIH: <>
        <polygon className="st1" points="1974.34,923.49 1973.35,922.66 1972.58,921.92 1971.68,921.36 1971.01,921.13 1970.81,920.85
  		1970.73,920.54 1970.8,919.98 1970.84,919.26 1971,918.81 1971.61,917.96 1972.19,917.06 1972.55,916.19 1972.73,915.38
  		1972.67,915.15 1972.36,914.98 1971.72,914.8 1970.84,914.96 1970.08,915.25 1969.79,915.27 1969.23,915.31 1968.35,915.12
  		1967.95,914.8 1967.88,914.46 1967.87,914.15 1967.59,913.64 1966.89,913.11 1965.42,913.02 1964.88,912.85 1964.32,912.66
  		1963.71,912.52 1963.14,912.53 1962.46,912.67 1961.27,912.44 1960.87,912.75 1960.25,913.11 1959.73,913.1 1958.7,912.27
  		1958.39,912.21 1957.48,912.64 1957.12,912.66 1956.83,912.53 1955.61,912.21 1955.06,912.14 1954.66,912.29 1953.94,912.13
  		1952.2,911.04 1951.12,911.87 1948.93,911.66 1948.28,912.23 1947.54,913.31 1946.93,913.82 1946.41,913.64 1945.79,913.16
  		1944.7,911.94 1944.15,911.72 1943.52,911.67 1942.97,911.8 1942.68,912.05 1942.45,913.83 1942.25,915.4 1942.24,916.34
  		1943.45,917.21 1944.87,918.71 1945.33,918.88 1945.56,919.37 1945.89,920.62 1946.27,922.03 1946.99,922.97 1947.64,923.64
  		1948.44,924.22 1949.43,925.14 1950.25,926.15 1950.47,926.52 1952.06,927.85 1953.6,929.21 1954.97,929.68 1955.19,929.93
  		1955.2,930.98 1955.35,931.37 1956.27,932.46 1958.15,934.05 1958.36,934.42 1958.42,934.69 1958.3,934.89 1957.81,935.11
  		1958.51,935.53 1959.12,935.34 1959.64,935.48 1959.97,936.06 1960.62,936.44 1961.68,937.12 1962.35,937.63 1963.87,938.56
  		1964.23,938.69 1964.98,938.96 1965.13,938.91 1965.35,938.76 1965.81,938.35 1965.9,938.13 1965.88,937.8 1965.23,936.75
  		1965.14,936.07 1965.04,934.81 1965.18,934.51 1965.42,934.36 1966.56,934.2 1966.54,933.21 1966.61,932.92 1966.84,932.51
  		1966.98,932.13 1967.62,931.59 1968.47,930.95 1968.85,930.93 1969.18,931.02 1969.55,931.57 1969.95,931.5 1970.04,930.84
  		1969.51,929.97 1969.23,929.41 1969.31,929.11 1969.51,928.95 1969.97,929.05 1970.4,929.2 1970.68,929.1 1971.11,929.02
  		1971.37,929.04 1971.87,928.52 1972.26,928.44 1972.76,928.42 1973.09,928.69 1973.53,928.74 1973.77,928.41 1973.9,927.92
  		1973.84,927.29 1972.79,925.85 1971.89,924.83 1971.79,924.61 1971.99,924.42 1972.3,924.32 1972.64,924.4 1973.51,924.48
  		1974.36,924.38 1974.64,924.14 1974.65,923.8 	"/>
    </>,
    AZE: <>
        <path className="st0" d="M2233.41,960.52l-0.5-0.49l-0.55-0.43l-0.53-0.3l-1.08-0.42l-1.17,0l-0.64-0.07l-0.49-0.24l-1.37-1.08
  		l-0.84-0.83l-1.94-2.26l-0.44-0.9l-0.26-1.02l-0.31-0.84l-0.47-0.72l-1.91-2.57l-1.34-1.58l-0.77-0.58l-0.46,0.66l-0.74,1.17
  		l-0.33,0.62l-0.79,0.57l-1.31,0.6l-0.73,0.26l-0.78,1.25l-0.86,1.21l-0.59,0.14l-1.68-0.19l-0.6-0.11l-1.71-0.53l-0.48-0.33
  		l-0.47-1.41l-0.53-0.61l-0.66-0.39l-0.45-0.33l-0.19-0.34l-0.48-0.49l-0.89-0.73l-0.64-0.7l-0.5-0.19l-0.63,0.25l-0.38,0.07
  		l-0.16-0.12l-0.12-0.59l-0.91-0.21l-0.21,0.36l-0.48,0.65l-0.38,0.33l-0.43,0.05l-0.42,0.15l-0.15,0.35l-0.02,0.45l0.07,0.32
  		l0.11,0.12l0.43,0.11l0.43,0.95l0.67,0.48l1.04,0.54l0.93,0.62l0.45,0.57l-0.09,0.41l-0.3,0.86l-0.77,0.71l-0.64,0.18l-0.23-0.07
  		l-0.42-0.22l-0.85-0.55l-0.92-0.43l-0.71,0.14l-0.47,0.17l-0.92-0.19l-1.09-0.38l-0.57-0.37l-0.25-0.27l0.17-0.49l-2.47-0.88
  		l-1.19-0.24l-0.54,0.26l-1.82,1.33l0.18,0.45l0.52,0.5l0.57,0.2l0.31,0.28l0.01,0.21l-1.01,0.26l-0.07,0.13l0.07,0.13l0.3,0.06
  		l1.41,0.63l0.8,0.01l0.42,0.19l0.21,0.38l0.67,0.51l0.53,0.5l0.03,0.17l-0.1,0.25l-1.5,0.97l-0.19,0.33l-0.02,0.35l0.66,1.05
  		l0.97,1.15l1.4,0.87l1.93,0.95l0.02,0.59l-0.31,0.7l-0.26,0.47l-0.12,0.32l-0.23,0.13l-1.92-0.03l-0.29,0.11l-0.13,0.14l-0.01,0.11
  		l0.69,0.21l1.08,0.74l0.62,0.72l0.65,0.31l0.72,0.57l0.58,0.53l0.9,0.69l1.01-0.23l1.35,0.61l0.06,0.42l-0.08,0.37l-0.85,0.41
  		l-0.11,0.17l0,0.14l0.11,0.2l0.5,0.33l0.58,0.49l0.66,0.74l-0.29,0.22l-0.61,0.03l-0.48-0.09l-0.17,0.15l0.01,0.24l0.62,0.56
  		l0.12,0.41l-0.03,0.71l0.03,0.89l0.54,0.02l1.92-1.8l0.58-0.6l1.15-0.31l0.64-0.72l1.04-0.87l1.26-0.82l1.16-0.73l0.89-0.44
  		l1.61-1.04l1.01-0.36l0.87,0.01l1.31,1.22l1.1,1.11l0.34,0.49l-0.55,0.43l-1.02,0.42l-0.2,0.31l-0.07,0.39l0.04,0.38l0.14,0.31
  		l1.25,0.71l0.14,0.39l0.01,0.4l-0.14,0.25l-0.28,0.14l-0.87,0.2l-0.74,0.23l-0.26,0.23l-0.22,0.27l0.03,0.3l0.23,0.34l1.53,0.93
  		l0.17,0.34l0.3,0.46l0.37,0.28l0.64,0.08l0.3,0.19l1.48,1.71l0.36,0.12l1.72-0.38l0.24,0.02l-0.15-3.72l0.03-0.23l0.6-1.21
  		l0.3-1.15l0.44-0.54l0.82,0.48l-0.02,0.55l0.1,0.25l0.37-0.26l0.29-0.42l0.59-2.09l0.44-0.43l0.35-0.2l0.04-0.48l-0.34-1.01
  		l-0.03-1.06l0.76-2.29l0.52-2.45l0.62-1.06l0.99-0.54l1.04-0.39l1.07-0.28l1.89-0.07l1.88,0.43L2233.41,960.52z M2193.37,958.49
  		l-0.32,0.16l-0.3-0.07v-0.41l0.22-0.16l0.25,0.01l0.24,0.15L2193.37,958.49z M2188.73,954.5l0.18-0.11l0.04-0.27l-0.06-0.24
  		l-0.23-0.08l-0.28,0.06l-0.02,0.27l0.09,0.25L2188.73,954.5z M2197.52,974.3l-0.79-1.58l0.22-0.65l-0.44-0.37l-1.06-0.67
  		l-0.27-0.28l0.15-0.38l0.1-0.7l-0.1-0.57l-0.29-0.17l-0.53-0.01l-0.65,0.14l-1.3,0.55l-0.9-0.35l-0.52-0.35l-0.3-0.29l-0.67,0.25
  		l-0.17-0.12l-0.04-0.73l-0.2-0.39l-0.41-0.46l-0.38-0.22l-1.39,0.45l-0.83,0.15l0.13,0.19l0.28,0.34l0.18,0.21l1.36,2.03l0.6,0.6
  		l0.35,0.51l0.24,0.56l0.42,0.38l0.55,0.21l0.67,0.55l0.45,0.43l0.76,0.88l0.8,0.33l2.92,0.64l1.62,0.3l-0.31-0.76L2197.52,974.3z"/>
    </>,
    ABW: <>
        <polygon className="st1" points="1220.98,1213.93 1220.87,1213.69 1220.35,1212.94 1219.83,1212.55 1219.7,1212.69 1219.57,1213.12
  		1220.09,1213.51 1220.61,1214.04 1221.01,1214.17 	"/>
    </>,
    AUS: <>
        <path className="st0" d="M3006.8,1408l-0.05,0.24l0.1,0.54l0.46,0.25l0.7-0.48l-0.65-0.95L3006.8,1408z M2984.29,1462.69l-0.43,0.36
  		l0.11,0.35l0.32-0.15l0.94-0.17l-0.09-0.45l-0.57-0.44L2984.29,1462.69z M2982.44,1459.84l0.37,0.01l0.6-0.2l0.31-0.41l0.33,0.11
  		l0.65-0.76l0.44-0.37l1.16-0.13l-0.79-0.96l-0.14-0.07l-1.09,0.37l-1.4,0.25l-0.54,0.52l-0.56,0.85l-0.13,0.76l0.1,0.24
  		L2982.44,1459.84z M3007.11,1403.89l0.21-0.58l-0.36-0.28l-0.43,0.59l0.37,0.5L3007.11,1403.89z M2963.46,1451.31l0.1,0.43
  		l0.46,0.42l0.36-0.4l-0.19-0.34l-0.06-0.65l-0.46-0.58l-0.2,0.49l-0.19,0.11l-0.17,0.39L2963.46,1451.31z M2960.2,1414.26l1.2-1.56
  		l0.33-1.54l-0.41,0.1l-0.04,0.68l-0.14,0.45l-0.19,0.16l-0.33,0.29l-0.75,1.24l-0.32,0.3l0.04,0.38L2960.2,1414.26z
  		 M2958.35,1415.99l0.77-0.63l0.07-0.37l-0.25,0.18l-1.53,0.76l-0.73,0.85l1.33-0.63L2958.35,1415.99z M2959.39,1450.09l0.1,0.38
  		l0.14,0.04l0.5-0.04l0.18-0.72l-0.22-0.09l-0.53,0.08L2959.39,1450.09z M2962.62,1438.23l-0.83-0.34l-0.31-0.36l-0.04-0.36
  		l0.36-1.08l0.23-0.33l0.24-0.09l0.53-0.59l-0.12-0.34l-0.17-0.2l-0.21-0.11l-0.34,0.78l-0.39,0.02l-0.36-0.35l-0.16-0.66l0.05-0.38
  		l-0.39-0.05l-0.61,0.39l-0.41,0.62l-0.92,0.61l-0.11,1.25l0.14,0.98l-0.3,0.42l-0.48,0.31l0.23,0.15l0.82,0.05l1.59,0.39l0.96-0.06
  		l1.1,0.17l0.31-0.4l0.16-0.53l-0.14-0.04L2962.62,1438.23z M2962.29,1450.46l0.13-0.06l0.23-0.27l-0.11-0.74l-0.26,0.36
  		L2962.29,1450.46z M3064.55,1490.88l0.27,0.01l0.2-0.1l0.01-0.12l-0.34-0.49l-0.2-0.59l-0.36,1.14L3064.55,1490.88z
  		 M3077.43,1508.58l0.27-0.14l-0.23-0.69l-0.28-0.15l-0.03,0.5l-0.19,0.35l0.22,0.15L3077.43,1508.58z M3098.78,1539.26l0.21-0.25
  		l0.05-0.61l0.49-1.52l1.76-4.02l0.08-0.76l-0.52-0.56l-0.13-1.58l-0.21-0.08l-0.28,0.09l-0.36,0.23l-0.31,0.45l0.36,0.16l0.47,0.8
  		l-0.12,0.74l-0.32,0.58l-1.27,1.09l0.19,0.97l-0.08,0.46l-0.44,0.84l-0.19,0.92l0.26,1.59L3098.78,1539.26z M3083.5,1521.42
  		l0.2-0.12l0.29-0.37l-0.18-0.46l-0.38-0.65l0.1-0.58l-0.24-0.15l-0.26,0.03l-0.29-0.22l-0.73-0.27l-0.22,0.62l1.27,1.86
  		L3083.5,1521.42z M3101.89,1553.22l0.18-0.14l-0.14-1.03l0.34-1.47l-0.29-0.07l-0.44,0.18l-0.13,0.81l0.1,0.87L3101.89,1553.22z
  		 M3008.05,1404.74l0.5-0.52l-0.34-0.44l-0.67,0.08l-0.2,0.42l0.18,0.31L3008.05,1404.74z M3101.72,1554.8l-0.04,1.44l0.26,0.37
  		l0.22,0.05l0.72-2.49l-0.14-0.13l-0.73-0.15L3101.72,1554.8z M3064.11,1489.55l0.17-0.13l0.1-0.79l-0.3,0.21l-0.21,0.29l-0.16,0.36
  		l0.22,0.09L3064.11,1489.55z M3072.47,1507.44l0-0.38l-0.13-0.88l-0.31,0.22l-0.05,0.67l0.2,0.64L3072.47,1507.44z
  		 M3040.36,1473.42l0.63,0.61l0.37,0.76l0.53,0.29l0.24-0.31l0.13-0.42l-0.36-0.64l-0.17-0.82l-0.25-0.05l-0.15,0.13l-0.37,0.12
  		l-0.74-0.03L3040.36,1473.42z M2925.63,1412.43l0.67,1.31l0.17-0.13l0.3-1.13l-0.27-0.53l-0.03-0.92l-0.12-0.24l-0.35,0.5
  		l-0.45,0.08l0.27,0.67L2925.63,1412.43z M2762.55,1540.81l0-0.62l-0.3-0.61l-0.96-2.64l-0.3,0.1l-0.33,0.34l0.47,1.91l1.62,2.79
  		l0.22-0.37l-0.29-0.71L2762.55,1540.81z M3058.22,1677.8l-0.16-0.1l-0.21-0.02l-0.64,0.18l0.08,0.22l0.51,0.48L3058.22,1677.8z
  		 M3035.06,1656.51l-0.51-0.4l-1.11,0.05l-0.08,0.22l-0.05,0.48l0.47,0.3L3035.06,1656.51z M3059.1,1693.79l-0.11-0.38l-0.22-0.34
  		l0-0.36l0.12-0.77l-0.24-1.13l0.08-1.71l-0.08-0.92l0.02-0.9l0.19-1.16l-0.05-1.17l-0.12-0.59l-0.05-0.59l0.06-1.68l-0.65-0.92
  		l-1.54-0.74l-0.54-0.01l-0.81,0.93l-0.47-0.01l-1.65-0.27l-0.36,0.31l-0.66,0.88l-0.39,0.37l-0.56-0.16l-0.57-0.29l-0.43,0.03
  		l-0.42,0.24l-0.95,0.11l-0.98-0.02l-0.59,0.25l-0.53,0.41l-0.17,0.51l0.1,0.59l-0.52-0.54l-0.53-0.36l-0.61,0.38l-0.64,0.26
  		l-2.16,0.21l-1.74-0.45l-2.44-0.93l-0.39-0.27l-0.35-0.35l-0.4-0.23l-0.92-0.35l-0.36-0.4l-0.41-0.12l-0.47,0.06l-0.67-0.32
  		l-0.56-0.56l-0.49-0.05l-0.55,0.25l-0.97-0.04l-1.89-0.65l-0.84-0.49l-0.07,1.1l-0.54,1.98l0.14,0.98l0.3,1.12l0.56,1.51l0.01,0.49
  		l0.1,0.29l1.16,2.26l1.18,1.84l1.54,1.94l0.18,0.89l-0.2,0.91l0.5-0.06l0.31-0.44l0.07,0.44l0.17,0.37l1.32,1.28l0.09,0.34
  		l-0.5,1.06l-0.28-0.87l-0.52-0.69l-1.47-1.09l0.32,2.27l0.26,0.9l1.85,3.88l0.25,0.25l0.42,0.17l0.36,0.31l0.6,0.79l1.02,1.71
  		l0.59,0.49l0.86-0.16l1.26,0.35l0.43-0.1l0.27,0.15l0.15,0.4l-0.99-0.01l-0.96,0.22l-0.11,0.33l0.26,0.37l0.25,1.05l1.21-0.35
  		l1.91,0.07l1.14-0.11l1.27,0.95l1.14,0.18l0.33-0.07l0.68-1.13l0.42-1.36l0.26-0.52l0.35-0.44l-0.76-0.58l-0.02-0.29l0.1-0.34
  		l1.48,1.02l0.61-0.41l0.12-0.91v-0.56l0.18-0.4l0.37-0.19l0.15-0.5l0.04-0.39l-0.39-0.88l-0.03-0.5l0.93,1.05l0.37,1.42l0.71-0.38
  		l0.1-0.22l-0.11-0.98l0.32-0.33l1.01,0.27l0.9,0.57l0.06,0.26l-0.06,0.27l-0.22,0.24l-0.73-0.24l-0.33,0.41l0.43,1.04l0.73,0.99
  		l0.44-0.25l0.9-0.14l0.3-0.25l-0.19-2l-0.36-1.47l-0.02-1.6l0.1-0.87l0.41-0.68l0.3-0.71l-0.04-0.92l0.15-0.87l0.37-0.9l0.51-0.67
  		l0.24-0.16l-0.13-0.19l0.22-0.58l0.39-0.43l0.24,0.52l-0.31,0.2l-0.18,0.23l0.61,0.38l0.18,0.34l0.06,0.37l-0.06,0.46l0.11,0.36
  		l0.34,0.07l0.1-0.47l-0.1-0.57L3059.1,1693.79z M3032.04,1658.2l1.2-0.08l0.71,0.36l-0.05-0.18l-0.29-0.46l-0.22-0.18l-0.59-0.13
  		L3032.04,1658.2z M2908.19,1414.82l0.75,1.46l1.12,1.06l2.58,1.56l2.87-1.83l0.76-1.09l0.64,0.05l0.08-0.66l0.6-0.62l-0.14-0.18
  		l-0.41-0.28l-0.31-0.59l-0.98-0.56l-0.44-0.48l-0.43,0.45l-0.64,0.17l-0.99,0.6l-0.3,0.05l-0.63-0.26l-1.35,0.64l-1.12-0.07
  		l-0.5-0.59l-0.34-0.22l-0.78-0.82l-0.2-0.02l-0.15,0.1l-0.14,0.19l0.31,0.76L2908.19,1414.82z M3058.73,1672.54l-0.23-0.19
  		l-0.78-0.28l-1.5-1.79l-0.57-0.31l-0.79,1.04l-0.61,0.38l0.38,0.4l0.54-0.05l0.25,0.65l-0.13,0.43l1.13,1.56l0.19,0.4l0.23,0.28
  		l0.27,0.21l0.88-0.28l0.75-0.61l0.12,0.01l0.08-0.29l-0.61-0.45l0.33-0.34L3058.73,1672.54z M3148.01,1827.66l-0.51,2.3l0.08,0.53
  		l0.28-0.46l0.56-1.57l0.11-1.19L3148.01,1827.66z M2956.5,1434.3l-0.21,0.14l-0.1,0.23l0,0.31l0.77,0.17l0.2-0.1l0.32-0.28
  		l-0.15-0.72l-0.35-0.36L2956.5,1434.3z M3103.19,1561.46l-1.02-1.74l-0.22-1.37l-0.36-1.17l-1.58-3.29l-0.68-1.9l0.38-1.91
  		l-0.66-6.11l0.68-3.05l-0.33-0.37l-0.82-0.47l-0.36-0.48l-0.54-1.14l-0.06-2.29l-1.05-1.41l-1.14-0.64l-0.77-1.16l-0.52-0.96
  		l-0.07-0.53l-0.31-0.9l-0.87-0.62l-0.6-0.3l-1.28-0.91l-0.63-0.92l-1.29-2.61l-0.6-0.69l-1.19-0.75l-0.97-0.04l-0.63-0.19
  		l-2.23-1.66l-0.69-0.36l-0.56-0.78l-0.83-0.84l-0.49-0.62l-0.74-0.65l-0.51-2.49l0-2.42l-0.16-2.88l-0.77-1.39l-0.42-0.45
  		l-0.46,0.15l-0.04,0.9l0.13,0.61l-0.32,0.03l-1.15-0.79l-1.43-0.85l-0.77-0.95l-0.56-0.89l-0.47,0.03l-0.33,0.14l-0.33,1.09
  		l0.54,1.88l-0.26,0.26l-0.46-0.43l-0.82-0.98l-0.43,0.32l-0.57,0.13l-0.49-0.99l-0.42-0.62l-0.6-2.06l-0.54-2.27l-0.05-1.64
  		l-1.05-0.9l-0.41-1.55l-0.33-0.43l-0.31-1.1l-1.22-1.43l-1.25-1.01l-1.04-0.96l-0.89-1.36l0.39-0.98l0.63,0.21l0.67-0.09l-0.54-1
  		l-0.52-0.67l-1.34-1.26l-0.62-0.32l-1.35-0.19l-1.49-1.15l-0.91-0.5l-0.65-0.08l-0.75-0.18l-0.53-0.65l-0.93-0.21l-1.32-1.28
  		l-0.64-1.29l-0.33-0.48l-0.44-0.36l-0.65,0.22l-0.53,0.1l-1.17-0.18l-0.39-0.53l-0.76-0.66l-1.46-0.18l-1.15-0.42l-0.88-0.42
  		l-0.89-0.53l-0.82-0.88l-0.73-1.18l0.12-1.51l0.18-0.98l-0.93-0.38l-1.61-2.05l-0.08-0.84l0.43-1.72l0.44-2.96l-0.64-2.19
  		l-1.25-2.68l0.09-1.36l-0.62-0.02l-0.7-0.27l-0.98-1.32l-0.74-0.87l-0.5-0.8l-0.54-1.08l0.09-0.87l0.13-0.58l0.05-1.55l-0.7-1.51
  		l-0.22-1.54l-0.66-1.93l0.18-1.28l-0.14-1.06l-0.21-0.91l0.21-0.58l0.09-0.74l-0.91-0.74l-0.97-0.56l-1.25-1l-2.25-1.55l-0.52-1.18
  		l-0.96-1.05l-1.27,0.41l-0.94,0.19l-0.88,0.79l-1.21,0.58l-1.17-0.53l-0.56-0.45l-0.41-1.57l-0.54-1.71l-0.46-0.86l-0.34-1.04
  		l0.32-2.53l-0.48-1.19l-0.15-1.78l-0.46-2.03l-0.51-1.02l0.03-0.82l-0.94-1.2l-0.3-0.86l-0.53-0.31l-0.68-0.49l-0.09-0.66
  		l0.05-0.48l0.41-0.8l0.22-1.03l-0.95-0.26l-0.66-0.04l-0.46-0.32l-0.52-0.5l-0.18-1.6l0.02-1.7l-0.14-1.06l-0.28-0.79l-0.2-0.84
  		l-0.48-0.89l-1.43-1.15l0.11-0.47l0.33-0.6l-0.51-0.35h-0.74l-0.42,0.8l-0.68,0.69l-1.33,0.53l-0.25,2.77l-0.83,3.04l-0.75,2.24
  		l0.14,0.68l-0.06,0.66l-0.41-0.3l-0.36-0.37l-0.55,0.89l-0.99,2.3l-0.09,1.19l0.58,0.32l0.4,0.32l0.49,0.1l0.22,0.29l0.12,0.58
  		l0.31,0.5l-0.08,0.54l-0.37-0.21l-0.79,0l-0.4,0.47l-1.02,0.94l0.27,2.69l-0.48,1.41l-0.46,1.1l-0.52,2.08l0.07,1.1l0.46,0.78
  		l0.5,1.15l-0.3,1.58l-0.3,1.13l0.68,3.27l0.19,1.74l-0.37,1.19l-1.09,3.51l-0.49,2.57l0.16,1.42l-0.47,1.3l-0.54,2.08l-0.61,1.57
  		l-2.13,3.17l-0.42,1.53l-0.72,1.91l-1.53,1.12l-1.16,0.7l-2.54,0.69l-1.46-0.02l-0.76-0.42l-0.43-0.36l-1.73-0.61l-2.1-1.38
  		l-1.62-0.45l-0.79-1.39l-0.08-0.57l-0.29-0.75l-0.85-0.99l-1.6-0.33l-1.64-0.71l-1.01,0.1l-2.2-0.61l-1.46-0.87l-1.34-1.21
  		l-1.76-2.09l-1.49-0.57l-1.91-0.87l-1.1-0.72l-0.67-0.35l-0.74-0.54l-0.67,0.12l-1.16,0.02l-0.73-0.51l-0.1-0.4l0.11-0.31
  		l0.04-0.57l-0.26-0.08l-0.26,0l-0.21,0.15l-0.3,0.11l-1.02-0.44l-1.44-0.73l-0.27-0.64l-0.45-0.79l-1.99-1.14l-1.15-0.94
  		l-2.54-1.37l-0.65-0.66l-0.21-0.58l-0.19-0.83l0.57-0.87l0.55-0.61l1.73-2.55l0.52-0.45l0.65-0.69l0.6-1.86l0.29-1.06l-0.51-1.61
  		l-0.02-2.7l2.01-1.05l0.56-0.14l0.52-0.23l0.59,0.33l0.4,0.51l0.41-0.09l1.12-1.88l-0.18-0.78l-0.47-0.67l0.16-0.41l3.46-3.69
  		l-0.42-0.9l-0.51-0.21l-0.99,0.06l-0.92-0.79l-0.59-1.49l-0.82-0.05l-1.46,1.53l-0.17,0.35l0.35,0.2l0.31,0.93l-0.57,1.09
  		l-0.57,0.01l-0.93-0.11l-0.42-0.78l-0.19-1.18l-0.6-0.33l-0.68,0.22l-0.56,0.42l-0.39,0.12l-0.33-0.27l-0.02-0.49l0.86-0.82
  		l0.72-0.53l0.05-0.2l-0.52-0.16l0.08-0.38l0.66-0.68l-0.31-0.03l-0.81,0.72l-0.87,0.42l-1.15,0.89l-1.65,0.58l-1.13,0.79
  		l-1.59-0.24l-1.47-0.77l-0.32-0.41l-0.72-0.6l-1.62,0.65l-1.02-0.07l-0.56-0.23l-0.96-0.15l-0.82-0.57l-1.98-0.92l-2.1-0.18
  		l-1.02,0.04l-0.76-0.47l-0.73-0.27l-1.44-0.19l-0.6-0.71l-0.75-1.43l-0.54-0.39l-0.88-0.14l-0.93,0.66l-0.54,0.31l-1.06-1.18
  		l-1.88-1.21l-0.6-0.17l-0.32,0.29l-0.23,0.56l-0.36,0.05l-0.42-0.25l-0.73-0.72l-0.48-0.13l-1.26,0.77l0.09,0.26l1.03,0.39
  		l1.08,1.07l0.51,0.22l1.22-0.28l1.66,0.2l1.68,1.34l-0.25,0.66l-0.12,0.92l0.05,1.01l-0.04,0.68l0.69,0.75l-0.31,0.06l-0.78-0.17
  		l-0.62,0.21l-0.58,0.35l-0.26,1.01l-0.33-0.48l-1-0.45l-0.6,0.35l-1,0.46l-0.9-0.18l-0.58-0.23l-1.36,0.39l-2.43-0.01l-0.81-0.57
  		l-0.24-0.97l-0.19-0.24l-0.22,0.43l-0.38,0.5l-1.47,0.1l-0.22,0.21l0.09,0.49l-0.06,0.61l-0.56,0.05l-0.7,0.16l0.08,0.75l0.13,0.58
  		l-0.26,0.29l-0.76-0.53l-0.34-0.57l-0.54-0.18l-0.42,0.2l-0.11,0.51l0.07,1.32l-0.38,0.15l-0.99-0.05l-0.46,0.25l-0.69,1.66
  		l-1.26,0.63l-0.19,0.87l-0.09,0.73l1.05,1.33l-0.51,0.68l-0.53,0.56l-0.53,0.24l-1.14,0.22l-0.83,0.61l-0.35,0.64l-0.07,0.61
  		l-0.23,0.78l-0.37,0.93l-0.07,0.5l-0.76,0.5l-1.35,1.49l-0.68,1.53l0.89,0.83l1.04,0.6l0.76-0.02l0.01,0.15l-0.79,0.61l0.49,0.63
  		l0.76,0.59l0.46,0.09l0.34,0.25l-0.72,0.14l-1.06,0.05l-0.21,0.64l0.13,0.74l0.19,0.64l-0.13,0.45l-0.4-0.31l-0.17-0.48l-0.91-0.98
  		l-0.66-0.3l-0.96-0.23l-0.29,0.3l0.04,1.49l-0.19,0.69l-0.34-0.39l-0.08-1.09l-0.9-0.88l-3.56-0.88l-0.5-0.06l-0.83,0.12
  		l-0.57,0.35l-0.06,0.34l-0.38,0.28l-0.62,0.32l-0.56,0.49l-0.37,0.41l-0.02,0.51l0.46,0.95l0.27,0.27l-0.04,0.45l-0.45-0.47
  		l-0.39-0.15l-0.37,0.74l-0.36,0.15l0.09-2.07l0.37-1.4l0.3-0.83l0.33-0.65l-0.16-0.34l-2.47-1.94l-1.05-1.59l-0.76-0.89l-1.19-0.86
  		l-0.62-0.54l-1.39-0.83l-1.63-0.57l-0.78-0.77l-0.87-0.28l-1.08,0.38l-0.09,0.72l0.14,0.7l-0.86,1.15l-0.92,0.61l-0.74-0.7
  		l-0.67-0.51l-0.67,0.37l-0.2,0.63l-0.34,0.23l-0.25-0.43l-0.37-0.95l-0.55-0.38l-0.55,0.17l0.16,0.75l0.32,0.41l-0.09,0.6
  		l-0.4,0.28l-0.07,0.57l-0.24,0.75l0.03,1.05l-0.63,0.22l-0.47,0.83l-0.34-0.18l-0.09-0.54l-0.17-0.55l-0.68-0.21l-0.25,0.52
  		l-0.15,0.18l-0.24,0.04l0.16-0.42l0-0.79l0.2-0.83l-0.65-0.3l-0.26,0.18l0.01,0.71l-0.16,1.04l-0.64,0.16l-0.57,0.47l-0.85,0.01
  		l-0.43,0.22l-0.15,0.55l-0.74,0.57l0.01,0.68l0.5,0.69l0.03,0.6l1.18,0.61l-0.07,0.61l-0.17,0.28l-0.45-0.11l-0.96-0.53l-0.97-0.11
  		l-0.29-0.24l-0.12,0.17l-0.01,0.41l-0.38,0.3l-0.54,0.03l-0.63,0.43l0.45,0.68l-0.08,0.27l0.22,0.33l0.97,0.06l0.41-0.09l0.04,0.58
  		l-0.13,0.58l-0.39,0.21l-0.37-0.53l-1.87-1.02l-0.49-0.1l-0.1,0.32l0.09,0.42l-0.39,0.51l-0.7,0.66l-0.47-0.18l-0.56,0.16
  		l-0.36,1.13l-0.13,1.13l0.62,0.79l0.41,1.05l0.86-1.29l0.35-0.15l0,0.56l-0.34,0.58l-0.2,0.71l-0.07,0.8l-0.56,0.02l-0.63-0.11
  		l-0.15,0.26l-0.24,0.6l0.14,0.82l0.42,0.31l0.98-0.03l0.83,0.06l0.74,0.3l0.13,0.25l-0.67-0.14l-1.37,0.08l-0.65-0.11l-1.28,0.05
  		l-0.96-0.47l-0.47-0.47l-0.72-0.12l-0.7,0.19l-0.39,0.66l-0.47,0.16l-1.1-1.63l-0.69-0.11l-0.34,0.38l0.33,1.02l-0.17,0.63
  		l-0.38,0.47l-0.47-0.03l-0.29,0.2l0.23,0.43l0.75,1.1l0.62,0.48l0.54,0.67l0.28,0.58l0.66-0.03l0.15,0.46l-0.38,0.67l0.01,1.07
  		l-0.27,0.05l-0.38-0.24l-0.76-0.66l-0.39-0.13l-0.2,0.19l-0.06,0.45l0.18,1.18l-0.39,1.69l0.08,0.49l-0.07,0.42l-0.32-0.3
  		l-0.39-0.65l-0.8-1.01l-0.99-2.21l-1.04-1.5l-0.57-1.27l-0.87-2.4l-0.45-0.04l-0.58,1.03l-0.64,1.36l-0.44,0.67l-1.03,0.66
  		l-0.63,0.67l-0.76,0.24l-0.84,0.77l-0.6,0.66l-0.85,1.53l-0.14,0.99l0.04,1.04l0.37,1.48l0.39,2.14l0.58,0.23l0.47,0.36l-0.13,0.65
  		l-0.7,0.41l-2.16,2.03l-1.45,0.72l-0.41,0.51l-0.53,1.07l-0.77,1.36l-0.35,0.85l-0.81,1.66l-1.31,1.85l-1.33,1.37l-1.53,1.1
  		l-1.01,0.53l-3.75,1.54l-2,0.59l-3.61,0.43l-1.53,0.7l-1.91-0.23l-2.14-0.15l-2.97,2.32l-2.47,0.56l-2.18,0.42l-0.94,0.38
  		l-2.15,1.34l-1.25,0.61l-2.34,0.69l-0.96-0.07l-1.29-0.63l-1.21,0.15l-1.34-0.09l-1.09,0.06l-0.85,0.52l-5.01,2.77l-0.99,0.76
  		l-1.03,1.1l-1.48,1.01l-1.18,1.17l-2.48,1.22l-2.55,0.92l-1.26,0.77l-0.9,1.04l-1.57,2.8l-0.33,0.71l-0.62,0.74l-0.83,0.27
  		l-0.54,0.24l0.19-1.41l-0.6-1.25l0.42-2.39l-0.16-0.71l-0.85,0.46l-0.54,0.51l-1.38,3.46l-0.95,2.69l0.72,1.54l0.23,0.9l0,0.96
  		l-0.25,1.39l0.02,0.9l-0.08,1.2l-1.72,2.79l-0.53,1.21l-0.58,2.33l-0.07,1.08l0.04,1.61l0.72,1.41l0.55,0.88l0.86,2.53l1.02,1.68
  		l1.69,3.39l1.86,2.75l0.12,1.05l-0.21,1.41l0.1,1.46l-0.33,0.43l-0.72,0.5l-0.83-0.65l-0.42-0.56l-0.53-2.07l-0.22-0.12l-0.35,0.91
  		l-0.39,0.39l-0.35-0.27l-0.27-0.34l-0.07-0.36l0.12-0.43l0.13-1.55l-0.77-0.89l-0.69-0.95l-0.74-0.23l-0.45,0.43l-0.02,0.59
  		l0.99,1.66l0.64,1.8l0.99,1.12l0.58,0.29l0.65,0.68l-0.14,1.51l-0.47,0.56l-0.39,0.29l-1.28-0.33l-0.3-1.09l-1-2.14l-0.32-0.83
  		l-0.28-0.23l-0.11,0.41l0.02,0.74l-0.19,0.32l-0.19-0.03l-0.4-0.39l-0.36-0.21l-0.22,0.07l0.39,0.53l0.86,1.58l3.17,3.87l2.68,4.51
  		l0.59,1.78l0.3,3.91l0.27,0.95l1.59,1.95l1.55,2.25l0.46,1.12l-0.01,0.96l0.32,0.91l1.92,2.47l0.86,2.66l0.1,0.97l-0.25,1.67
  		l0.23,2.94l0.22,1.6l0.7,3.17l0.83,2.28l0.99,1.42l1.35,3.15l2.05,3.63l0.33,1.8l-0.11,4.78l-0.15,1.56l-0.45,0.27l-0.3,0.66
  		l0.44,3.14l0.1,1.79l-0.66,1.68l-0.75,1.49l-1.32,1.02l-1.49,0.03l-0.7-0.59l-0.89-0.61l-0.15,2.72l-0.02,2.33l0.27,0.89l0.03,1.05
  		l1,0.81l0.56-0.32l0.7-0.04l2.42,1.15l1.36,0.95l2.19,2.55l1.94,0.67l2.53,1.16l2.93,0.37l2.35,0.07l3.69,0.61l0.79-0.22l1.58-0.19
  		l1.21-0.4l1.09-0.25l3.24-2.37l3.16-2.44l1.57-0.19l1.4-0.03l1.71-0.83l1.55-2.52l0.79-0.56l1.05-0.63l2.99-0.37l1.76,0.26
  		l0.94-0.41l2.39-0.46l4.97-0.42l2.73,0.34l1.83-0.05l0.97,0.17l0.76,1.11l5.28-0.95l1.5-0.07l0.94,0.16l1.18,0.83l1.33-0.78
  		l1.19,0.1l1.21-0.75l1.84-2.26l0.83-1.41l1.34-2.97l0.99-1.07l1.09-0.53l1.27-0.17l1.97-0.54l4.28-2.51l1.66-0.54l0.87-0.47
  		l2.95-1.95l1.85-0.37l5.41,0.5l2.57-0.13l1.99-0.31l3.02-1.05l3.28-0.79l4.03-1.66l3.37-1.72l2.03-0.4l3.21-0.3l4.72-0.45l5.5,0.23
  		l1.39-0.35l0.68-0.31l0.96-0.34l1.19,0.23l0.91,0.26l2.76,1.37l4.16,2.89l0.92,0.12l2.74-0.66l0.92,0.06l3.83,2.12l1.59,0.04
  		l1.27-0.05l0.96,0.23l1.02,0.57l1.21,1.33l2.56,1.28l0.13,1.03l-0.19,0.67l-0.58,0.03l-0.49,0.14l0.62,2.16l1.08,1.74l2.58,0.24
  		l0.94,0.61l0.61,0.69l0.47,1.09l0.35,1.71l1.29,1.42l1.21,1.22l0.28,0.5l0.57,1.72l0.22,0.5l0.47,1.7l0.69,1.95l-0.19,0.2
  		l-0.41-0.04l-0.73-0.49l-0.64-0.55l-0.34,0.09l-0.39,0.38l-0.05,0.47l0.57-0.12l0.34,0.07l0.79,0.6l0.74,0.69l0.58,0.4l1.4,1.72
  		l0.55-0.38l0.67-0.34l1.07,0.94l0.43,0.19l0.24-0.36l-0.4-1.68l-0.4-0.41l-0.1-0.6l0.5-0.43l0.25-0.51l1.19-1.26l2.61-3.77
  		l0.8-0.43l0.92-0.83l1.25-0.63l1.29-0.75l0.82-0.29l0.81-0.16l0.9-0.69l0.98-1.87l0.74-2.22l0.79-0.98l1.21-1.04l0.93-1.45
  		l-0.08-1.13l0.01-1.16l0.67,0.89l0.42,0.91l0.67,3.03l-0.57,0.66l-0.61,0.33l0.11,1.06l0.4,1.38l0.15,1.11l-1.27,1.17l-1.1,1.47
  		l-1.32,2.85l-0.09,0.86l-0.21,1.2l0.08,1.05l0.21,1.02l-0.33,1.58l-0.53,1.41l-0.7,0.04l-0.47-0.05l-1.04,0.13l-0.96-0.08
  		l-1.1,3.08l0.7,0.14l0.53-0.17l0.96,0l1.08-0.55l1.58-0.45l0.9,0.16l1.06-0.05l1.54-3.95l0.38-2.57l0.78-1.15l0.24-0.8l0.4-0.76
  		l0.82,1.3l0.66,1.26l1.45,2.05l0.45,1.02l0.18,2.48l-0.94,2.87l-0.56,0.82l-0.68,0.71l-0.57,1.2l1.73,0.31l1.12-0.02l1.75-0.87
  		l0.35-0.12l0.88-0.02l0.34-0.46l0.86-0.43l0.64-0.51l0.83-0.4l0.76,0.27l0.17,0.23l0.19,0.26l-0.28,0.57l-0.02,1.2l-0.5-0.13
  		l-0.44-0.71l-0.55,0.18l-0.38,0.54l-0.83-0.17l0.14,0.35l0.44,0.68l1.75,1.32l1.86,1.75l0.7,0.83l1.52,2.64l1.08,2.8l-0.09,0.83
  		l-0.53,1.49l-0.38,1.51l0.03,0.79l0.35,1.01l0.76,1.03l2.84,2.81l1.5,2.47l1.99,1.28l3.23,0.47l1.71,0.93l1.77,1.87l0.57,0.16
  		l0.86,0.08l1.1-1.14l1.68,0.12l2.21,1.13l1.32-0.27l0.94,0.14l1.32,0.64l1.92,1.26l2.04,0.64l1.21,0.95l0.94,0.14l1.69,0.62
  		l1.24-0.53l1.05-0.67l2.44-2.31l1.91-1.11l1-0.43l0.81-0.19l1.02-0.72l-0.64-0.51l-0.6,0.09l-1.03-0.29l0.59-0.33l0.62-0.25
  		l2.97-1.73l0.79,0.51l0.55,0.57l0.59,0.78l-0.45,1.11l-0.39,0.52l-0.92,0.84l-1.1,0.03l-0.53-0.07l1.09,0.94l0.95,0.63l1.95-1.15
  		l0.49-0.9l0.37-0.52l0.62-0.12l0.92,0.18l0.36,0.66l0.2,0.8l-0.67,0.22l-0.32,0.6l-0.23,0.57l1.16,0.73l0.6,0.46l0.72-0.01
  		l0.83,0.11l0.63,1.07l0.59,1.23l0.7-0.34l0.44-0.32l0.75,0.31l0.81,0.97l0.65,1.1l0.07,0.46l0.51,0.21l0.48-0.33l0.23-0.46
  		l-0.02-0.86l-0.13-1.35l-0.33-0.2l-0.76,0.73l-0.43-0.53l-0.58-0.56l0.01-0.54l0.63-0.27l0.54,0.12h0.67l3.55-0.47l4.54-4.34
  		l1.99-1.59l2.07-1.18l2.14-0.76l1.11-0.25l5.74-0.41l2.99,0.13l1.54-0.3l0.71-0.4l1.21-1.1l0.85-0.67l1.04-0.19l0.25-0.82l0-0.88
  		l0.2-0.92l-0.3-1.72l0.08-2.27l0.24-1.18l0.63-1.66l0.27-1.71l0.28-2.42l0.25-1.43l0.31-1.31l0.82-1.45l0.69-0.94l1.63-3.53
  		l0.56-0.35l0.47,0l0.2-0.21l0.06-0.2l-0.14-0.14l-0.21-0.41l0.14-0.33l0.49-0.33l0.16,0.13l0.25-0.07l0.04-0.18l-0.24-0.97
  		l0.34-1.36l0.42-2.37l0.47-1.06l0.28-0.85l1.09-1.27l1.19-1.25l-0.34-0.14l-0.56-0.09l0.36-0.3l0.28-0.09l0.36,0.2l0.3-0.55
  		l0.07-0.87l0.29-1.28l-0.26-1.11l0.55-0.35l0.63-0.21l0.26-1.17l0.17-0.47l0.39-0.44l0.65-0.93l0.51-0.97l1.22-1.85l1.19-0.76
  		l1.77-0.59l0.2-0.33l-0.45-0.2l0.02-0.2h0.67l0.27-0.65l0.71-0.48l1.17-1.11l0.39-1.02l0.24-0.81l0.12-1.84l1.91-2.41l1.33-3.26
  		l0.32-2.09l0.33-1.13l0.22-1.66l-0.2-1.73l0.06-1.44l1.33-3.68l0.3-1.52l0.41-0.97l0.63-3.63l0.01-1.89l0.96-2.19l1.2-1.78
  		l0.1-1.65l-0.4-1.27L3103.19,1561.46z M3058.98,1675.73l-1.08,0.51l-1.17-0.01l-0.41,0.24l0.08,0.23l0.69,0.47l0.94,0.06l0.89-0.23
  		l0.28,0.62l0.43-0.11l0.59-0.54l-0.45-0.65L3058.98,1675.73z M3029.15,1677.71l0.05-0.66l-0.06-0.05l-0.27,0.35l-0.35,0.15
  		l0.32,1.04L3029.15,1677.71z M2864.19,1441.49l-0.04-0.23l0.04-0.67l-0.32-0.16l-0.36,0.31l-0.22,0.85l0.37,0.43L2864.19,1441.49z
  		 M2858.5,1448.7l0.3,0.07l0.32-0.24l0.07-0.39l-0.34-0.39l-0.12-0.35l-0.27-0.02l-0.13,0.21l-0.18,0.41l0.34,0.36L2858.5,1448.7z
  		 M2781.56,1495.81l0.49-0.68l0.1-0.62l-0.19-0.42l-0.68,0.68l-0.38,0.57l0.08,0.35L2781.56,1495.81z M2905.73,1418.04l1.57-0.45
  		l1.56,0.54l0.87-0.16l-0.03-0.37l-0.19-0.31l-0.32-0.29l-0.7-0.2l-0.22-0.74l-0.4-0.7l-0.07-0.76l-0.32-0.7l-0.37,0l-0.37,0.2
  		l-0.83,0.99l0.29,0.54l0.09,0.99l-0.49,0.33l-0.56-0.14l-0.24,0.9l0.28,0.32L2905.73,1418.04z M3023.56,1670.56l-0.25-1.45
  		l-0.76-0.57l-0.44,0.03l-0.08,0.73l-0.5,0.41l-0.15,0.38l0.03,0.85l-0.22,0.79l0.41,0.79l-0.1,0.8l0.19,0.56l0.25-0.04l0.9-0.38
  		l0.65-0.56l0.25-0.68l-0.29-0.79L3023.56,1670.56z M3050.78,1703.62l-0.21,0.35l-0.07,0.31l-0.19,0.17l0.35,0.22l0.09,0.5
  		l0.19,0.09l0.53,0l-0.31-1.25L3050.78,1703.62z M3050.44,1705.66l-0.24-0.01l-0.42,0.53l-0.12,0.42l-0.18,0.08l-0.12,0.29
  		l-0.18,0.23l-0.49-0.18l0,0.19l0.41,0.71l0.65-0.18l0.65,0.18l0.4-1.06l-0.11-0.52L3050.44,1705.66z M3057.43,1698.9l-0.59-0.23
  		l-0.42,0.48l0.07,0.23l-0.01,0.52l0.16,0.04l0.47-0.09l-0.03-0.3l0.58-0.29L3057.43,1698.9z M2971.41,1631.12l-0.78,0.34
  		l-2.02-0.22l0.01-0.16l0.31-0.63l-0.43-0.35l-0.46-0.14l-1.65-0.12l-2.04,0.68l-3.82,0.81l-0.5,0.57l-0.32,0.78l0.41,0.43l1.4,0.94
  		l1.33,0.13l0.95-0.22l1.03,0.15l0.52-0.54l1.45,0.37l0.56,0.52l1.19-0.46l0.27-0.85l0.41-0.38l1.39-0.29l1.49,0.38l0.46-0.07
  		l0.48-0.46l-0.65-0.93L2971.41,1631.12z"/>
    </>,
    AUT: <>
        <polygon className="st1" points="1953.61,881.82 1953.46,881.35 1952.65,880.11 1951.75,878.08 1951.73,877.49 1952.08,876.82
  		1952.41,876.31 1952.45,875.9 1952.49,875.79 1952.28,875.55 1951.9,874.66 1951.48,874.54 1950.9,874.46 1950.46,874.33
  		1949.52,873.81 1949.04,873.66 1948.49,873.61 1947.95,873.92 1947.55,874.28 1946.31,874.27 1944.94,874.1 1942.99,872.92
  		1942.48,872.91 1941.94,872.96 1941.09,872.68 1939.43,871.91 1938.66,871.73 1938.17,871.84 1937.72,872.01 1937.4,872.03
  		1937.22,871.78 1936.6,871.47 1935.98,871.43 1935.8,871.62 1935.6,873.32 1935.39,873.93 1934.54,873.9 1934.24,874.18
  		1933.57,875 1933.44,875.79 1932.28,875.63 1931.73,875.5 1931.25,875.6 1930.71,876.04 1929.21,876.01 1928.03,875.75
  		1927.52,874.78 1926.98,874.39 1926.3,874.05 1926.06,873.97 1925.96,874.18 1925.91,874.84 1925.92,875.54 1925.81,875.91
  		1925.29,876.4 1925.02,876.5 1924.88,876.61 1923.29,875.97 1923.17,876.08 1923.07,876.16 1922.64,878 1922.35,878.35
  		1921.91,878.68 1921.01,878.99 1920.38,879.12 1919.89,879.28 1918.33,880.05 1917.63,880.51 1917.18,881.09 1917.17,881.42
  		1917.93,882.4 1918.8,883.41 1918.81,884.3 1918.42,884.96 1918.33,885.22 1918.59,885.32 1919.07,885.35 1919.48,885.47
  		1919.65,885.93 1919.6,886.75 1919.46,887.51 1919.31,887.82 1918.92,887.85 1918.17,887.52 1917.59,887.14 1917.36,886.91
  		1917.35,886.63 1917.48,886.45 1917.27,886.1 1916.55,885.78 1915.78,885.92 1915.21,886.14 1914.84,886.12 1914.44,885.82
  		1913.83,885.58 1913.03,885.42 1912.53,885.26 1912.43,885.36 1912.49,886.02 1912.33,886.31 1908.39,886.7 1907.18,887.06
  		1906.31,887.53 1905.66,887.73 1905.5,888.01 1904.86,888.39 1904.13,888.51 1903.96,888.39 1903.49,888.56 1902.7,888.73
  		1902.19,888.68 1901.95,888.37 1901.46,887.91 1901.26,887.58 1901.28,887.37 1900.17,887.33 1899.47,887.09 1897.99,887.14
  		1897.63,887.04 1897.55,887.15 1897.33,888.48 1897.04,889.02 1896.56,889.58 1895.95,889.89 1895.47,889.95 1895.49,889.54
  		1895.61,889.05 1895.26,888.93 1894.74,888.87 1894.48,888.73 1894.55,888.35 1894.43,888.13 1894.21,887.87 1893.69,887.53
  		1892.57,887.03 1891.81,886.78 1891.53,887.05 1891.36,887.13 1891.66,887.3 1891.56,887.43 1891.28,887.52 1890.17,887.47
  		1890.78,887.94 1890.63,888.74 1889.95,890.03 1890.02,890.21 1890.07,890.42 1890.15,890.66 1890.18,890.94 1890.32,891.23
  		1890.57,891.51 1890.65,891.77 1890.52,892.11 1890.39,892.3 1890.73,892.3 1891.78,892.52 1892.62,892.83 1892.79,893.17
  		1892.9,893.57 1893.9,894.13 1895.05,894.49 1895.44,894.37 1896.87,893.07 1897.42,893.29 1897.75,893.98 1897.74,894.34
  		1897.96,894.45 1898.81,894.46 1899.73,894.54 1900.33,895.1 1900.91,895.29 1901.74,895.36 1902.29,895.28 1902.56,895.06
  		1902.88,894.41 1903.48,893.59 1904.41,893.17 1906,893.09 1906.79,892.94 1907.62,892.95 1908.24,893.07 1908.88,893.06
  		1910.51,892.49 1912.2,892.04 1912.43,892.12 1912.47,892.27 1912.17,892.61 1911.87,893.07 1912.07,893.6 1913.03,894.65
  		1913.55,895.46 1914.04,896.06 1914.81,896.38 1915.81,896.58 1916.67,896.65 1917.56,896.87 1920.61,897.44 1922.15,897.59
  		1923.32,897.62 1925.09,897.99 1925.46,898.05 1926.2,898.09 1927.02,898.22 1927.78,898.39 1928.45,898.61 1929.87,898.83
  		1931.15,898.96 1931.54,899.09 1931.86,899.08 1932.25,899.26 1932.48,899.12 1932.64,898.88 1933.34,898.59 1933.99,898.21
  		1934.44,897.73 1934.7,897.35 1935.14,897.09 1935.61,897.01 1936.04,896.87 1937.87,896.69 1939.74,896.83 1940.63,896.57
  		1941.37,896.11 1942.44,895.98 1942.5,895.97 1944.1,896.33 1944.23,896.12 1944.3,896.03 1944.27,895.02 1944.77,894.56
  		1945.24,894.36 1946.59,893.21 1947.26,892.89 1948.03,892.94 1948.28,892.84 1948.35,892.67 1948.48,892.3 1948.54,891.94
  		1948.61,891.61 1948.53,891.42 1948.15,891.36 1947.97,890.54 1948.16,890.23 1948.36,890.01 1948.12,889 1948.19,888.66
  		1948.8,888.61 1949.3,888.39 1949.71,888.15 1949.82,887.84 1950.16,887.21 1949.85,886.43 1948.1,885.92 1948.01,885.73
  		1948.42,885.51 1948.85,885.19 1949.1,884.95 1949.44,884.91 1949.91,885.04 1950.76,885.6 1951.08,885.68 1951.39,885.52
  		1951.73,885.48 1952.66,885.5 1953.44,885.37 1953.27,884.77 1953.27,884.33 1953.14,883.99 1953.22,883.6 1953.54,883.3
  		1953.63,882.63 1954.12,882.18 	"/>
    </>,
    ASM: <>
        <polygon className="st1" points="3404.17,1438.74 3403.9,1438.9 3403.06,1439.21 3403.5,1439.62 3403.86,1439.54 3404.58,1438.95
  		3405.19,1438.82 	"/>
    </>,
    ARG: <>
        <path className="st0" d="M1259.13,1829.13l-3.18,0.25l-2.07-0.63l-2.05-0.76l-1.91-1.08l-1.75-1.48l-1.64-1.06l-1.72-0.86l-1.89-1.14
  		l-1.75-1.48l-1.48-1.55l-1.54-1.44l-0.67-0.5l-0.57-0.63l-1.14-2.81l-0.14-0.14l-1.95-0.13l-0.8-0.39l-0.27-0.45l0-0.51l0.35-0.73
  		l0.41-0.67l0.83-0.41l0.78,0.71l-0.32-1.12l-0.51-0.96l-1.96-2.33l-0.49-0.48l-0.01,3.38l-0.01,3.34l-0.01,3.13l-0.01,3.14
  		l-0.01,3.05l-0.01,3.14l-0.06,3.53l-0.05,2.63l0.29-0.23l1.08,0.03l1.34-0.23l0.94,0.01l1.79,0.36l1.8,0.24l5.61,0.41l1.66,0.25
  		l2.55,1.03l0.98,0.22l0.95-0.27l0.94-0.37l0.97-0.03l0.94-0.22l0.9-0.44l0.94-0.11l1,0.19l1.01,0.02l1.11-0.16l1.05-0.43l0.79-1.04
  		l0.62-1.29l-0.61-0.47L1259.13,1829.13z M1270.39,1830.42l-0.19-0.15l-0.42-0.1l-0.97,0l-1.84,0.2l-0.93-0.27l-0.27,0.43
  		l-0.37,0.24l-0.54,0.01l-0.57,0.61l0.22,0.43l0.79,0.46l1.08-0.73l0.47,0l1.1-0.51l2.48-0.05l0.48,0.21l1.17-0.5l0.14-0.5
  		l-0.56-0.02L1270.39,1830.42z M1288.29,1664.16l-0.5-0.23l-1.06-0.26l0.08,0.24l0.35,0.56l1.04,0.6l0.44,0.07l-0.08-0.62
  		L1288.29,1664.16z M1357.26,1545.22l0.06-0.82l0.36-0.57l-0.02-0.57l-0.64-1.27l-0.64-1.11l-0.35-1.89l-0.23-0.72l-0.54-0.19
  		l-0.48-0.62l-0.61-0.05l-0.29-0.24l-0.3-0.2l-0.43,0.06l-0.37,0.36l-0.69,0.01l-0.43,0.15l-0.51,0.33l-0.48-0.15l-0.31-0.28
  		l-0.66,0l-0.14,3.85l-0.39,2.72l-0.65,2.01l-0.59,1.07l-0.53,0.13l-0.38,0.32l-0.23,0.51l-0.43,0.43l-0.63,0.34l-0.34,0.37
  		l-0.05,0.41l-0.61,0.26l-1.16,0.12l-0.68,0.33l-0.2,0.53l-0.39,0.42l-0.57,0.31l-0.27,0.52l0.03,0.73l-0.33,0.62l-0.69,0.52
  		l-0.63,0.01l-0.58-0.49l-0.78-0.33l-0.98-0.16l-0.81,0.12l-0.65,0.41l-0.58,0.65l-0.51,0.89l-0.56,0.15l-0.62-0.6l-0.78-0.18
  		l-0.95,0.23l-0.75-0.08l-0.56-0.4l-0.86-0.04l-1.16,0.31l-2.35-0.36l-3.55-1.03l-3-0.39l-3.68,0.37l-0.31-1.07l0.19-0.58l0.6-0.43
  		l0.37-0.49l0.15-0.55l0.41-0.42l0.67-0.29l0.29-0.29l-0.1-0.3l0.14-0.26l0.39-0.23l0.22-0.36l0.05-0.49l0.15-0.24l0.26-0.18
  		l0.03-0.34l-0.15-1.04l0.02-0.85l0.18-0.66l0.23-0.4l0.16-0.1l0.15-0.24l0.06-0.4l0.24-0.37l1.17-0.77l0.44-0.42l0.04-0.38
  		l0.18-0.51l0.7-1.1l0.21-0.52l0.02-0.26l0.25-0.27l0.84-0.61l0.46-0.58l0.07-0.54l-0.2-0.61l-0.48-0.68l-1.5-1.71l-1.16-0.78
  		l-1.49-0.64l-0.98-0.21l-0.47,0.23l-0.48-0.18l-0.48-0.58l-0.82-0.46l-1.72-0.5l-3.9-2l-1.56-0.96l-0.53-0.59l-1.46-1.07
  		l-2.39-1.54l-1.83-0.75l-1.28,0.04l-2.05-0.45l-2.82-0.94l-1.63-0.91l-0.44-0.88l-1.04-0.88l-1.65-0.88l-0.86-0.58l-0.06-0.28
  		l-0.49-0.36l-0.91-0.45l-1-0.77l-1.09-1.08l-1.17-1.68l-1.24-2.27l-1.33-1.53l-1.42-0.79l-0.71-0.52l0-0.25l-0.21-0.24l-0.12-0.14
  		l-0.67-0.51l-0.59-0.97l-0.16-0.44l-0.08-0.02l-3.57,0.03l-3.44,0.03l-0.35,0.2l-0.49,0l-0.36-0.19l-0.36,0.02l-0.51,0.19
  		l-0.46,0.39l-1.31,2.58l-0.65,1.11l-0.48,0.99l-0.35,1.69l-0.15,0.29l-0.41-0.59l-0.6-1.55l-0.27-0.88l-0.39-1l-0.69-1.26
  		l-0.8-0.38l-0.49-0.13l-0.71-0.24l-1.26-0.3l-0.55-0.06l-3.6-0.04l-0.29-0.03l-1.41,0.14l-0.71-0.09l-0.75-0.7l-1.67-1.23
  		l-0.34-0.39l-0.64-0.26l-0.38-0.03l-0.23,0.25l-0.29,1.03l-0.34,0.93l-0.36,0.53l-1.19,0.39l-1.11,0.41l-0.61,0.1l-0.33,0.47
  		l-0.14,0.65l-0.28,0.59l-1.61,0.88l-0.36,0.37l-0.18,0.87l-0.9,1.08l-0.28,0.42l1.57,1.59l-0.68,2.15l-1.09,3.44l-0.84,2.66
  		l-0.14,0.36l-0.17,0.52l-1.82,0.76l-2.65,1.1l-1.36,0.58l-1.71,0.74l-0.41,0.61l-0.49,0.33l-0.54,0.43l-0.21,0.46l-0.51,0.29
  		l-0.46,1.05v0.8l0.29,0.55l0.51,0.23l0.16,0.66l0.16,0.46l0.37,0.36l-0.09,0.29l-0.3,0.22l-0.55,0.12l-0.37,0.66l-0.43,1.64
  		l-0.07,0.59l0.49,1.48l0.26,0.8l0.71,2.91l0.1,0.79l-0.97,1.11l-0.39,0.67l-0.14,0.59l0,0.47l0.09,0.43l0.81,1.37l0.94,1.23
  		l0.46,0.64v0.86l-0.23,0.49l-0.5,0.18l-1.11,0.34l-0.46,0.49l-0.51,0.07l-0.48-0.4l-0.51,0.1l-0.64,0.35l-0.24,0.84l-0.56,1.43
  		l-0.48,0.4l-0.36,1.09l-0.64,1.57l-0.31,0.95l-0.16,0.69l-0.65,0.44l-0.75,0.88l-0.58,0.86l-0.23,0.25l-0.44,0.07l-0.32,0.77
  		l-1.09,1.16l-0.26,1.35l-0.4,0.72l-0.07,1.3l-0.6,2.39l-0.11,0.53l-0.61,0.42l-0.8,0.92l-0.26,0.68l0.37,2.02l0.46,2.05l0.03,0.96
  		l-0.18,1.31l-0.12,0.57l0.31,0.24l0.51,0.15l0.16,0.5l-0.37,0.35l-0.16,0.63l-0.41,0.7l-1.23,0.28l-0.43-0.25l-0.14,0.23l0.07,0.5
  		l-0.27,0.59l-0.64,1.59l-0.42,1.45l-0.24,0.63l0.1,0.53l0.21,0.3l0.02,0.28l-0.35,0.35l-0.32,0.56l-0.35,0.08l-0.37-0.15
  		l-0.39,0.33l-0.08,0.69l-0.22,0.87l-0.1,1.02l-0.16,1.31l0.5,0.9l0.64,1.63l0.47,0.39l0.53-0.03l0.41,0.33l0.23,0.38l-0.31,0.68
  		l-0.54,0.11l-0.07,0.38l0.16,0.87l0.21,0.84l0.52,0.4l0.24,1.13l0.51,0.38l-0.06,1.44l0.51,1.7l0.54,0.49l0.25,0.23l-0.17,0.74
  		l-0.43,0.59l-0.09,0.95l0.16,0.69l0.55,0.65l0.43,0.07l0.61-0.27l0.64,0.31l0.09,0.56l0.09,0.51l-0.35,0.67l-0.37,1.23l-0.1,1.23
  		l0.11,1.87l0.17,1.45l0.04,0.91l0.04,0.41l-0.23,0.28l-0.56,0.15l-0.48,0.06l-0.41,0.23l-0.09,0.47l-0.32,0.78l-0.34,0.58
  		l-0.58,0.84l-0.37,0.87l-0.3,0.57l0.03,0.4l-0.21,0.76l-0.22,0.63l-0.46,2.14l-0.62,0.45l-0.49,0.22l-0.25,0.29l0.19,0.58
  		l0.52,0.17l0.18,0.47l-0.07,0.73l0.35,0.68l-0.03,0.82l0.33,1.55l-0.3,1.02l0.1,0.88l-0.01,0.87l-0.44,0.68l-0.9,0.13l-0.49,0.63
  		l-0.84,0.68l-0.09,0.55l-0.14,0.5l-0.35,0.18h-0.53l-0.44,0.08l-0.61,0.65l-0.65,0.35l-0.15,0.52l0.06,0.64l-0.35,0.39l-0.44,0.74
  		l-0.28,0.79l0.28,0.74l0.3,1.32l0.05,0.55l-0.38,1.09l-0.31,0.7l0.3,0.9l0.25,0.5l-0.24,1.11l-0.2,0.7l0.16,1.27l0.6,1.44
  		l0.57,1.28l0.08,1.49l0.15,1.18l0.27,1.28l0.58,0.51l0.44,0.43l-0.09,0.61l-0.32,0.75l-0.46,0.56l-1.14,0.19l-0.93,0.51l-0.75,0.35
  		l-0.57,0.43l-0.41,0.45l-0.2,0.5l0.14,2.16l-0.09,0.8l-0.38,1.13l-0.36,0.92l-0.15,0.28l-0.05,0.4l-0.07,0.38l-0.4,0.09l-0.57-0.17
  		l-0.32,0.11l-0.23,0.3l0.19,0.71l0.21,1.25l0.29,0.53l-0.08,0.42l-0.11,0.91l-0.38,0.73l-0.5,0l-0.32,0.3l-0.14,0.52l0.15,0.67
  		l0.66,0.55l0.23,0.35l-0.12,0.46l-0.51,0.19l-0.3,0.38l-0.29,0.85l-0.38,0.96l-0.41,0.71l-0.08,0.97l0.58,1.04l-0.06,1.02
  		l-0.04,2.98l-0.06,1.01l0.18,1.68l-0.22,0.46l-0.11,0.44l0.56,1.22l0.63,1.98l0.17,0.79l-0.09,0.55l-0.84,0.47l-0.7,0.19
  		l-0.41-0.33l-0.28,0.14l-0.32,0.58l-0.37,0.47l-0.14,0.47l0.39,0.61l0.21,1.16l-0.44,0.5l-0.32,0.55l0.12,0.72l0.14,1.3l-0.28,2.17
  		l0.37,0.77l0.4,0.37l1.31,0.44l0.99,0.22l0.26,0.72l-0.11,0.59l-0.47,0.29l-0.71,0.25v0.95l0.61,0.89l0.69,0.65l0.15,0.58
  		l-0.04,0.59l-0.48,0.5l0.66,1.08l0.3,0.73l-0.3,0.56l-0.43,0.85l-0.38,0.41l0,0.46l-0.16,0.93l-0.04,0.92l0.13,0.55l4.16,0.43
  		l0.95,0.17l0.52,0.55l-0.07,0.68l-0.52,0.73l-0.33,1.37l-0.82,0.23l-0.82-0.37l-0.89,0.13l-0.77,0.09l-1.1,0.04l-1.47,0.18
  		l-0.9-0.2l-0.07,0.5l0.26,0.87l1.93,0.27l1.82,0.5l0.55,0.92l0.74,1.04l0.76,0.65l0.04,1.06l-1.19,1.1l-0.15,0.78l-1.56,0.23
  		l-0.45,0.46l-0.22,1.52l0.19,1.2l0.59,0.42l0.41,0.79l-0.45,0.93l-1.05,0.64l-0.56,0.61l0.35,0.49l0.48,0.77l0.13,0.42l0.26,1.14
  		l0.3,1.59l-0.04,0.77l-0.28,0.58l-1.04,0.91l-0.71,0.42l-0.14,1.12l-0.05,0.84l0.07,0.76l0.45,0.6l-0.04,0.61l-0.62,0.13
  		l-0.53,0.29l-0.52,1.08l-1.51,1.1l-0.53,0.49l0.04,0.85l-0.6,1.21l-0.5,1.06l-0.38,0.99l0.07,1.04l0.85,0.46l0.67,1.01l0.3,1.28
  		l-0.52,1.47l-1.21,0.56l-0.71,0.63l-0.21,0.47l0.19,1.55l-0.05,0.73l-0.19,0.68l-0.31,0.53l-0.65,0.59l-1.15,0.52l-0.98,0.36
  		l-0.44,0.41l-0.51,0.9l-0.46,0.99l0.11,1.23l-2.75,0.14l-0.19,0.91l-0.59,0.72l-0.19,1.3l0.6,1.26l0.29,1.06l-0.49,1.28l0.18,1.31
  		l0.05,1.04l0.97,1.16l0.63,1.44l0.32,1.22l0.19,0.95l0.25,0.58l0.4,0.66l0.18,0.76l0.59,0.24l1.07-0.71l0.76-0.48l0.52-0.17
  		l1.54,0.11l0.93-0.44l0.42,0.05l0.57,0.25l0.44,0.53l0.33,1.19l0.2,1.34l-0.26,1.37l-0.44,0.3l-0.15,0.39l0.15,0.83l0.48,0.59
  		l-0.01,0.84l-0.53,1.91l-0.35,0.79l0.62,0.89l0.55,0.79l1.11,0.59l0.91,0.84l0.63,0.69l-0.15,0.94l0.44,0.29l1.7,0.02l2.54,0.03
  		l3.97,0.05l3.88,0.05l4.4,0.07l2.09,0.76l1.89,0.68h2.38l2.37,0.81l1.76,0.53l1.06,0.2l1.09,0.19l0.15,0.75l0.42-0.56l-0.84-1.24
  		l-1.66-2.08l-1.9-3.35l-0.41-0.42l-0.57-0.46l-1.24,0.29l-1.12-0.69l-0.81,0.1l-0.47-0.29l0.88-0.28l1.2,0.02l1.35-0.16l0.19-0.65
  		l0.05-0.47l-0.3-1.6l-0.66-2.3l-0.48-1.15l-0.56,0.14l-0.71,0.44l-0.06-0.2l1.04-0.86l0.68-0.96l0.11-1.24l0.43-1.88l0.38-0.93
  		l0.89-1.29l1.6-1.12l1.35-0.62l1.04-0.34l0.37-0.41l-0.44-0.73l-0.5-0.61l-0.55-0.29l-1.3-0.24l-1.91,0.17l0.56-0.38l2.12-0.36
  		l0.19-0.8l-0.29-0.76l0.04-0.45l0.83,1.25l0.69,1.22l0.7,0.71l1.24,0.68l0.94-0.14l1.95-1.17l0.74-0.71l0.36-0.67l1.02-5.65
  		l-0.27-0.42l0.07-0.62l1.84-3.2l1.71-1.49l1.11-1.37l0.82-0.65l2.11-1.13l1.57-1.12l1.71-0.83l3.17-2.78l0.62-0.7l0.26-0.46
  		l0.86-0.38l-0.45-0.94l-0.59-0.28l-1.37,0.28l-0.63,0.05l-0.44-0.33l1.55-0.47l1.3-0.87l0.61-0.68l0.33-0.75l0.31-2.38l-0.26-0.94
  		l-0.71-1.06l-1.22-0.67l-5.49-0.51l-1.06-0.42l-5.14-4.79l-1.01-1.17l-0.48-1.03l-0.19-0.8l-0.19-1.08l0.08-1.2l0.36-0.87
  		l1.38-2.04l1.14-2.08l2.66-3.34l0.5-0.31l2.5-0.47l0.43-0.26l0.34-0.42l1.23-0.87l1.33-0.72l3.64,0.44l1,0.01l0.28-0.65l0.06-0.72
  		l-0.84-0.82l0.43-1.4l2.41-1.9l0.6-1.2l0.2-0.84l-0.36-1.25l0.59-1.13l-0.56-2.68l0.18-1.62l0.26-0.6l0.53-0.51l1.72-2.34
  		l1.23-1.07l1.05-0.54l2.38-0.78l0.48-0.35l0.48-0.57l-0.52-0.2l-0.52,0.02l-1.58-0.42l-3.35-1.53l0.47-0.94l1.34-0.34l1.36-1.03
  		l1.37-0.18l1.38,0.6l0.64,0.75l0.24,1.11l0.75,1.08l0.81,0.2l2.88-0.77l0.4-0.61l0.23-0.51l0.19-1.42l-0.01-1.51l-0.29-1.25
  		l-0.46-0.95l-0.38-0.36l-0.56-0.39l-0.82,0.11l-1.6,0.59l-1.22,0.36l-0.21,0.33l1.61,0.16l-0.01,0.88l-0.33,0.42l-1.38,0.27
  		l-1.31,0.12l-1.27-0.18l-0.03-0.61l0.42-0.57l0.11-0.29l-0.22-0.16l-0.71,0.07l-0.65-0.41l-1.67-0.6l-0.74-0.6l-0.61-1.33
  		l0.44-2.26l-0.09-1.79l-0.92-3.29l-0.23-1.33l0.03-1.59l0.16-0.66l0.54-0.75l1.28-0.74l0.4,0.04l0.55,0.21l-0.13,0.37l-0.28,0.2
  		l1.95,0.41l2,0.68l2.19,0.85l2.95,1.42l1.27,0.1l3.44-0.07l2.14-0.43l1.36-0.62l3.39-1.56l0.78-0.76l0.47-1.4l-1.24-2.15
  		l-0.28-1.02l0.21-1.58l0.66-2.44l0.31-0.55l0.28-0.15l1.03-0.54l0.41-2.54l0.05-1.05l-0.45-0.3l-0.41-0.5l1.06-0.07l-0.61-0.63
  		l-0.7-0.47l-0.72-0.18l-0.36-0.91l0.29-1.6l-0.6-1.33l0.33-0.52l1.23,0.13l1.03,1.04l1.84,0.42l2.07,0.36l1.85-0.18l2.28,0.12
  		l1.75-0.19l9.06-1.33l1.28-0.41l5.64-1.21l6.97-2.32l4.49-2.6l0.83-0.82l0.33-1.72l0.94-1.6l2.59-2.89l3.04-4.73l0.46-1.03
  		l0.03-1.12l-0.25-2.97l-0.16-0.36l-0.27-0.41l-1.58,0.06l-1.17-0.54l-1.59-1.46l-0.59-1.13l-0.34-1.21l0.18-1.72l1.64-2.05
  		l-0.1-1.37l-1.12-1.66l-2.06-1.61l-1.82-1.18l-4.38-2l-1.14-1.44l-0.4-0.7l-0.5-1.53l0.42-0.31l0.33-0.1l0.36-0.56l-0.14-1.25
  		l-0.17-0.66l-0.23-0.87l-0.62-1.37l-0.14-0.84l0.78-3.55l0.26-1.64l0.41-0.37l0.56,0.1l0.49-0.04l0.42-0.6l0.25-0.52l-0.41-3.7
  		l0.16-0.86l0.66-1.4l0.03-0.68l-0.38-0.6l-0.1-0.61l0.17-0.63l-0.03-0.6l-0.24-0.58l0.18-0.48l0.6-0.38l0.45-0.58l0.3-0.78
  		l0.15-0.6l0.01-0.41l-0.18-0.39l-0.37-0.37l0.17-0.73l0.72-1.09l0.46-0.96l0.21-0.84l-0.01-0.68l-0.24-0.52l0.1-0.35l0.44-0.18
  		l0.2-0.54l-0.07-1.35l-0.45-1.12l0.35-0.88l1-1.02l0.52-0.82l0.04-0.63l0.31-0.36l0.38-0.44l1.34-0.97l0.74-0.87l0.14-0.76
  		l0.64-0.68l1.14-0.6l1.27-1.11l1.4-1.62l0.85-1.19l0.3-0.77l0.55-0.59l0.8-0.42l0.7-0.87l0.6-1.32l0.82-1.05l1.03-0.78l0.58-0.65
  		l0.12-0.51l0.29-0.33l0.46-0.14l0.22-0.27l-0.01-0.4l0.13-0.27l0.27-0.14l0.44,0.05l0.62,0.24l0.38-0.05l0.13-0.33l-0.16-0.38
  		l-0.46-0.43l0.17-0.47l1.21-0.75l0.89-0.29l0.56-0.47l0.53-0.74l0.87-0.52l1.2-0.29l0.32-0.28l-0.04-0.36l0.24-0.26l0.71-0.19
  		l0.38-0.35l0.06-0.51l0.23-0.48l0.39-0.44l0.44-0.16l0.48,0.11l0.45-0.17l0.42-0.45l0.51-0.21l0.59,0.03l0.3-0.1l1.02-0.21
  		l0.56-0.38l0.46-0.83l0.41-0.32l0.36,0.19l0.62-0.28l0.88-0.75l0.17-0.01l0.65-0.35l0.67-1.29l0.35-0.86l-0.08-0.7l-0.22-0.5
  		l0.07-0.74L1357.26,1545.22z"/>
    </>,
    ATA: <>
        <path className="st5" d="M1779.46,1213.98l0.25-0.02l0.06-0.09l-0.08-0.13l-0.15-0.04l-0.33,0.01l0.07,0.11L1779.46,1213.98z
  		 M1780.3,1213.69l0.4-0.13l-0.07-0.1l-0.15-0.01l-0.05-0.04l-0.13-0.03l-0.25,0.12l0.05,0.05L1780.3,1213.69z M1780.85,1213.29
  		l0.02,0.03l0.18,0.02l0.09,0.06l0.06-0.01l0.1-0.05l0.07-0.25l0.23-0.05l0.29,0.14l0.09-0.07l-0.07-0.08l0.04-0.15l0.33,0.04
  		l0.28-0.18l0.3,0.02l0.01-0.06l-0.07-0.16l-0.11-0.06l-0.2,0.05l-0.23-0.08l-0.52,0.12l-0.24-0.04l-0.51,0.19l-0.05,0.1l-0.44,0.33
  		l-0.09,0.13l0.33-0.11L1780.85,1213.29z M1776.68,1214.62l0.12,0l0.36-0.05l0.18-0.12l0.14,0.04l0.44-0.05l0.04,0.18l0.06,0.08
  		l0.18,0.11l0.67-0.37l-0.28,0.01l-0.25-0.2l-0.79,0.07l-0.09-0.04l-0.19-0.19l-0.12-0.04l-0.07,0.16l-0.25,0.16l-0.32-0.01l0,0.13
  		L1776.68,1214.62z M1777.69,1215.44l-0.24-0.2l-0.12,0.03l-0.06,0.12l0.09,0.13l0.13,0.06l0.1-0.03l-0.1-0.06l0-0.05l0.12,0.02
  		L1777.69,1215.44z M1777.59,1217.52l-0.28-0.08l-0.15,0.14l-0.02,0.34l-0.29,0.04l0.22,0.12l0.14,0.03l0.22-0.1l0-0.05l-0.06-0.12
  		l0.06-0.14L1777.59,1217.52z M1775.15,1218.55l0.04-0.08l0.16-0.17l-0.25,0.07l-0.15,0.11l-0.04,0.15l0.17-0.01L1775.15,1218.55z
  		 M1789,1276.71l0.07,0.07v0l0.07,0.03l7.93-0.36l0.04-0.04l0.09-0.09l0.11-0.15l0.02-0.02l0.17-0.08l0.23-0.11l0.4-0.1l3.41-0.63
  		l6.81-1.26l0.07-0.06l0.06-0.1v0l-0.01-0.13l-0.02-0.05l-0.03-0.07l-0.48-0.31l0.01-0.04l0.01-0.04l0.07-0.05l0.19-0.07l0.09-0.01
  		l0.09-0.01l0.14,0.01l0.27,0.03l0.36-0.36l0.14-0.37l0.08-0.91l-0.04-0.42l-0.28-0.52l-0.17-0.2l-0.1-0.55l-0.09-0.16l-0.08-0.15
  		l-0.16-0.38l-0.17-0.35l-0.09-0.13l-0.06-0.09l-0.13-0.08l-0.04-0.03l-0.29-0.02l-0.29-0.02l-0.95-0.01l-0.95,0.04l-0.12-0.01
  		l-0.12-0.01l-0.23-0.08l0.1-0.21l0.15-0.21l0.26-0.1l0.67-0.08l1.35-0.17l0.1-0.15l0.08-0.12l0.06-0.2l0.05-0.18l0-0.04l0.02-0.15
  		l-0.01-0.08l-0.01-0.11l-0.04-0.2v0l0,0l-0.07-0.09l-0.01-0.02h0l-0.02-0.02l-0.41-0.34l-0.33-0.22l-0.11-0.07l-0.15-0.08
  		l-0.31-0.15l-0.46-0.18l-1.22-0.42l-0.5-0.13l-0.33-0.09l-0.48-0.09l-0.35-0.03l-0.67-0.05l-0.37,0.02l-0.78,0.11l-0.23,0.05
  		l-0.19,0.04l-1.57,0.51l-0.94,0.3l-0.11,0.18l-0.38,0.51l-1.06,0.95l-0.18,0.19l-0.18,0.19l-0.14,0.19l-0.13,0.36l-0.06,0.14
  		l-0.09,0.2l0,0v0l0.08,0.15l0.08,0.03l0.09,0.03l-0.1,0.19l0,0.01l-0.15,0.16l-0.22,0.11l0,0.02l-0.02,0.1v0v0l0.02,0.17
  		l-0.03,0.33l-0.1,0.27l-0.1,0.18l-0.03,0.06l-0.15,0.2l0,0l0.49,0.12l0.02,0.01l0.06,0.02l0.07,0.07l0.09,0.18l0.07,0.2l-0.08,0.2
  		l-0.08,0.09l-0.04,0.04l-0.47,0.47l-0.94,0.79l-0.46,0.34l-0.5,0.37l-1.06,0.63l-0.07-0.06l-0.03-0.2l-0.14-0.03l-0.05-0.01
  		l-0.1,0.07l-0.1,0.07l-0.43,0.24l-0.44,0.08l-0.22-0.06l-0.15-0.1l-0.15-0.1l-0.09-0.02l-0.08,0.17l-0.08,0.17l-0.35,0.39
  		l-0.67,0.85l-0.15,0.12l-0.39,0.22l-0.04,0.23l0.04,0.58L1789,1276.71z M1789.23,1210.8l0.08-0.03l0.05-0.12l0.03-0.33l-0.05,0.02
  		l-0.13,0.16l-0.13,0.12l0.02,0.06L1789.23,1210.8z M1784.84,1215.81l0.35,0.2l0.17,0.01l0.36-0.27l0.02-0.07l-0.01-0.1l-0.01-0.02
  		l-0.14-0.02l-0.63-0.07l-0.23,0.18l0.02,0.05L1784.84,1215.81z M1787,1210.53l0.09,0.1l0.24-0.08l0.19-0.14l0.63-0.08l0.07-0.06
  		l-1.29-0.12l-0.1,0.09l0.13,0.11L1787,1210.53z M1784.98,1216.34l0.01,0.05l-0.03,0.05l-0.05,0.05l0.01,0.07l0.07,0.17l0.15,0.05
  		l0.53-0.16l0.13-0.12l0.32-0.12l0.15-0.01l0.28,0.11l0.79,0.05l0.15-0.08l-0.06-0.21l-0.09-0.13l-0.11-0.02l-0.45,0l-0.11-0.07
  		l0-0.05l-0.11-0.08l-0.82,0.08l-0.62,0.22L1784.98,1216.34z M1786.08,1216.69l-0.64,0.08l-0.05,0.09l0.1,0.13l0.4,0.19l0.15-0.13
  		l0.28-0.12l-0.08-0.2L1786.08,1216.69z M1753.23,1247.51l-0.06,0.1l-0.09,0.09l-0.02,0.14l0.2,0.17l0.11,0.04l0.17-0.07l0.28-0.21
  		l0.07-0.25l0.25-0.35l-0.24-0.08l-0.29,0.04L1753.23,1247.51z M1756.31,1235.77l0.26,0.11l0.39-0.13l-0.62-0.66l-0.24-0.13
  		l-0.47-0.12l-0.24,0.06l-0.14,0.11l-0.04,0.19l0.33,0.37L1756.31,1235.77z M1762.17,1229.14l0.21,0.24l0.15,0.2l0.15,0.03
  		l0.15-0.02l0.13-0.07l0.08,0l0.08,0.03l0.13-0.08l0.12-0.16l0.1-0.31l0.1-0.07l0.13,0.02l0.14,0.06l0.15-0.06l0.19-0.2l0.03-0.07
  		l-0.07-0.1l-0.25-0.11l-0.16-0.03l0.11-0.09l0.26-0.14l0.13-0.14l0.2-0.11l0.29-0.22l-0.13-0.07l-0.12-0.13l-0.09-0.06l-0.13-0.05
  		l-0.04-0.04l0-0.15l0.07-0.42l0.35-0.3l0.11-0.11l-0.05-0.23l-0.22-0.03l-0.19,0.1l-0.72,0.44l-0.15,0.16l-0.28,0.42l-0.15,0.24
  		l-0.14,0.27l-0.16,0.23l-0.47,0.52l-0.09,0.15l-0.01,0.19l0.03,0.19L1762.17,1229.14z M1764.98,1229.7l0.35-0.06l0.09-0.09
  		l0.04-0.08l0.24-0.19l-0.05-0.08l-0.13-0.08l-0.31-0.03l-0.25,0.04l-0.34,0.18l0.02,0.06l0.07,0.03l-0.01,0.23L1764.98,1229.7z
  		 M1754.01,1241.3l0.04,0.17l0.38,0.27l-0.08,0.1l-0.09,0.07l-0.12-0.02l-0.32-0.19l-0.38-0.26l-0.19-0.09l-0.26-0.09l-0.09,0.02
  		l-0.13,0.04l-0.12,0.05l-0.09,0.14l0.01,0.17l0.07,0.24l0.01,0.13l-0.03,0.07l-0.05,0.02l-0.12-0.01l-0.27-0.08l-0.41-0.25
  		l-0.43,0.04l-0.35,0.2l-0.08,0.1l-0.03,0.12l0.05,0.15l-0.01,0.09l-0.08,0.09l-0.02,0.16l0.05,0.17l0.17,0.12l0.23,0.17l0.19,0.08
  		l0.21,0.15l0.22,0.1l0.22,0.02l0.42-0.05l0.19,0.06l0.2,0.24l0.1,0.06l0.28,0.04l0.17-0.06l0.65-0.44l-0.06-0.14l-0.21-0.17
  		l-0.25-0.2l0.1-0.07l0.26-0.05l0.4,0.06l0.44,0.17l0.35,0.06l0.08-0.01l0.93-0.87l0.14-0.1l0.14,0.03l0.38,0.33l0.41,0.28l0.44,0.1
  		l0.2-0.04l0.2,0l0.96,0.24l-0.04,0.13l-0.08,0.14l-0.26,0.16l-0.13,0.04l-1.43,0.25l0.01,0.11l0.42,0.33l0.45,0.12l0.42-0.07
  		l0.42-0.12l0.55-0.2l0.2-0.02l0.2,0.01l0.2,0.08l0.2,0.12l-0.4,0.32l-0.44,0.12l-0.36,0.03l-1.32-0.03l-0.96-0.09l-0.44-0.12
  		l-0.44-0.07l-0.22,0.02l-0.21,0.08l-0.42,0.35l0.05,0.14l0.09,0.12l0.22,0.21l0.19,0.12l0.2,0.05l0.25-0.02l0.09,0.09l0.08,0.14
  		l0.12,0.04l0.94-0.1l1.24-0.04l0.43-0.05l0.35,0.03l0.34,0.14l0.87-0.13l1.54-0.32l0.11-0.37l0.92-0.74l0.18-0.18l0.15-0.24
  		l0.12-0.37l0.28-0.52l0.02-0.33l-0.04-1.38l-0.05-0.72l-0.07-0.61l-0.04-0.18l-0.21-0.13l-0.02-0.44l-0.17-0.33l-0.32-0.57
  		l-0.65-1.03l-0.26-0.59l-0.22-0.78l-0.12-0.27l-0.52-0.84l-0.27-0.14l-0.11-0.03l-0.3,0.14l0.05-0.39l0-0.16l-0.1-0.57l-0.09-0.12
  		l-0.28-0.29l-0.19-0.14l-1.76,0.27l-0.86,0.21l-0.22,0.09l-0.12,0.1l-0.09,0.19l-0.05,0.17l0,0.2l0.04,0.16l0.06,0.13l0.21,0.2
  		l0.23,0.12l0.16,0.18l0.04,0.32l-0.09,0.4l-0.15,0.51l-0.03,0.13l-0.02,0.2l0.05,0.2l0.08,0.12l0.15,0.16l0.06,0.05l1.04,0.42
  		l0.17,0.02l0.18-0.03l0.37-0.17l0.71,0.07l0.17,0.07l0.21,0.17l0.42,0.23l0.48,0.3l-0.07,0.05l-0.08,0.01l-0.49-0.18l-0.21-0.03
  		l-0.43,0.03l-0.42,0.14l-0.9,0.43l-0.23,0.4l0.03,0.18l0.22,0.16l0.24,0.08l0.46,0.11l0.65,0.06l0.18,0.05l0.18,0.11l0.18,0.05
  		l0.11-0.06l0.1-0.01l0.08,0.12l0.01,0.2l0,0.2l-0.02,0.19l-0.06,0.11l-0.08,0.03l-0.63-0.56l-0.1-0.04l-0.1-0.02l-0.65,0.15
  		l-0.82-0.03l-0.2,0.09l-0.36,0.33l-0.39,0.11l-1.15-0.23l-0.64-0.01l-0.63,0.18l0.12,0.2l0.16,0.12l0.86,0.17l0.39,0.2l-0.74,0.18
  		l-0.36-0.02l-0.36-0.05l-0.68-0.16l-0.14,0.01l-0.24,0.09L1754.01,1241.3z M1804,1208.91l0.28-0.09l0.13,0.16l0.56,0.01l0.41,0.23
  		l0-0.09l-0.02-0.07l-0.04-0.06l-0.03-0.02l-0.23-0.04l-0.05-0.11l-0.2-0.1l-0.39-0.07l-0.21,0.06l-0.18-0.05l-0.04,0.11l0,0.08
  		L1804,1208.91z M1758.12,1237.72l-0.02-0.04l-0.08-0.06l-0.05-0.04l-0.15,0.04l-0.24,0.06l-0.01-0.01l-0.07-0.09l-0.18,0.08
  		l0.02,0.1l0.18,0.4l0.24-0.01l0.18-0.14l0.03-0.02v0l0,0l0.03-0.04l0.15-0.2h0l0,0L1758.12,1237.72z M1765.02,1226.98l0.2,0.01
  		l0.08-0.02l0.19-0.16l-0.02-0.08l-0.11-0.19l-0.17-0.05l-0.15,0.06l0.02,0.14l-0.17,0.22L1765.02,1226.98z M1772.52,1220.69
  		l-0.17,0.01l-0.18,0.32l0.15,0l0.29-0.13l0.11-0.21l0.14-0.15l-0.08-0.03L1772.52,1220.69z M1771.2,1220.52l0.17,0.1l0.37,0.07
  		l0.06,0.05l0.05,0.08l0.17-0.09l0.16-0.2l0.18-0.02l0.19,0.02l0.14-0.05l0.13-0.11l0.32-0.2l0.34-0.11l-0.17-0.15l-0.19,0.05
  		l-0.18,0.11l-0.26,0l0.17-0.3l-0.16-0.26l0.07-0.17l-0.19-0.17l-0.27-0.02l-0.09,0.04l-0.13,0.12l-0.14,0.08l0.03,0.12l-0.01,0.11
  		l-0.44,0.1l0.03,0.09l0.05,0.07l-0.55,0.21l-0.1,0.16l-0.08,0.18l0.16,0.04L1771.2,1220.52z M1774.25,1215.45l0.12-0.15l0.05-0.12
  		l-0.38,0.14l-0.2,0.31l-0.03,0.12l0.07-0.01L1774.25,1215.45z M1773.94,1219.88l0.13-0.18l0.1,0.05l0.11-0.02l0.13-0.12l0.04-0.07
  		l0.21-0.09l0.02-0.21l0.15-0.18l0.06-0.28l-0.38-0.14l0-0.14l-0.11-0.08l-0.22,0l-0.17,0.1l-0.07,0.09l-0.05,0.12l0.04,0.07
  		l0.2,0.21l-0.04,0.12l-0.25,0.4l-0.14,0.15l-0.05,0.08l-0.06,0.02l0.1,0.05L1773.94,1219.88z M1767.65,1223.91l0.39-0.11l0.02-0.09
  		l-0.01-0.22l0.05-0.15l0.05-0.04l0.21-0.06l0-0.08l0.06-0.23l-0.36-0.06l-0.24,0.13l-0.06,0.18l-0.12,0.1l0.03,0.23l-0.19,0.09
  		l-0.05,0.16l0.01,0.12L1767.65,1223.91z M1766.29,1225.21l0.4-0.34l0-0.07l-0.05-0.13l0.09-0.15l-0.1-0.05l-0.27,0.13l-0.02,0.37
  		l-0.14,0.12l0,0.06l0.03,0.03L1766.29,1225.21z M1888.73,1237.44l0.21,0.26l0.09,0.24l0.1,0.01l0.16-0.41l0.29-0.39l-0.09-0.31
  		l-0.18-0.06l-0.52,0.09l-0.14,0.14l0.01,0.24L1888.73,1237.44z M2053.68,1223.69l0.25,0.04l0.24-0.04l0.12-0.1l-0.01-0.04
  		l-0.06-0.09l-0.25-0.08l-0.35,0.01l-0.1,0.1l0.03,0.06L2053.68,1223.69z M2065.09,1225.63l0.17,0.09l0.17-0.04l0.19-0.15
  		l-0.36-0.15l-0.27,0.04l0.02,0.05L2065.09,1225.63z M2061.98,1224.87l0.15-0.11l0.01-0.07l-0.02-0.13l-0.13-0.12l-0.37,0.01
  		l-0.21-0.07l-0.2,0.03l-0.18,0.1l-0.17,0.32l0.16,0.12L2061.98,1224.87z M2043.01,1226.66l0.21-0.07l0.2-0.15l-0.17-0.04
  		l-0.07-0.06l-0.25-0.04l-0.19,0.07l-0.08,0.11l0.19,0.17L2043.01,1226.66z M2040.68,1226.19l0.16,0.02l0.19-0.1l-0.24-0.17
  		l-0.05-0.1l-0.08-0.01l-0.09,0.2l0.02,0.06L2040.68,1226.19z M2041.08,1226.84l0.47,0.29l0.01,0.04l0.05,0.04l0.31-0.08l0.21-0.18
  		l-0.24-0.36l-0.46-0.14l-0.38-0.02l-0.05,0.16L2041.08,1226.84z M2016.46,1238.5l0.21,0.24l0.16,0.04l0.13,0l0.14-0.08l0.03-0.11
  		l-0.04-0.16l-0.03-0.08l-0.1-0.14l-0.22-0.17l-0.05-0.12l-0.02-0.18l-0.07-0.16l-0.17,0.07l-0.11,0.17l-0.02,0.35l0.04,0.18
  		L2016.46,1238.5z M2180.02,1225.47l0.38,0.36l0.08,0.02l0.1-0.14l-0.09-0.14l-0.46-0.55l-0.02,0.04l-0.01,0.12l0.05,0.13
  		L2180.02,1225.47z M2181.71,1226.89l0.09,0.04l0.03-0.18l-0.01-0.07l-0.05-0.09l-0.06-0.18l-0.15-0.06l-0.12,0.04l0.13,0.36
  		L2181.71,1226.89z M2067.76,1224.96l0.24-0.04l0.03-0.11l0.02-0.2l-0.21,0.06l-0.18,0.17l0.01,0.04L2067.76,1224.96z
  		 M2184.34,1229.01l0.1,0.03l0.16-0.09l0.05-0.11l0.1-0.17l-0.02-0.09l-0.1-0.17l-0.05-0.12l-0.25-0.2l-0.02,0.09l0.04,0.37
  		l-0.1,0.28L2184.34,1229.01z M2073.04,1222.14l0.11,0.08l0.02,0.08l0.03,0.29l0.07,0.06l0.29,0.04l0.11-0.07l-0.03-0.06l-0.21-0.15
  		l-0.14-0.14l-0.01-0.07l0.02-0.21l-0.1-0.14l-0.44-0.18l-0.17,0.02l-0.07,0.09l0.05,0.2L2073.04,1222.14z M1822.83,1270.37
  		l2.63,0.15l0.1-0.07l0.1-0.07v0l0,0l-0.02-0.05l-0.01-0.02l-0.09-0.11l-0.72-0.23l-2.06-0.54l-0.11,0.01l-0.35,0.23l-0.23,0.07
  		l-0.23,0.07l-0.17,0.03l-0.17,0.03l-0.28,0.03l-0.31,0.03l-0.06,0.3l0.22,0.05L1822.83,1270.37z M2068.15,1223.29l0.29,0.01
  		l0.06,0.08l0.78-0.07l0.46-0.34l0.04-0.11l-0.07-0.16l-0.26-0.21l-0.35-0.07l-0.5,0.05l-0.11,0.04l-0.25,0.17l-0.15,0.16l-0.1,0.25
  		l0.04,0.14L2068.15,1223.29z M1863.67,1243.79l0.3,0.17l0.24,0.03l0.14-0.08l-0.05-0.11l-0.18-0.23l-0.28-0.22l-0.16-0.02
  		l-0.07,0.05l-0.09,0.17l0.04,0.12L1863.67,1243.79z M1875.79,1238.62l0.04,0.09l0.47-0.19l-0.1-0.39l-0.04-0.04l-0.17-0.05
  		l-0.32,0.13l-0.35,0.02l0.31,0.32L1875.79,1238.62z M1880.83,1240.46l0.14,0.19l0.19,0.1l0.25,0.02l0.19-0.08l-0.43-0.39
  		l-0.04-0.06l-0.09-0.08l-0.25,0.03l0.01,0.06l-0.02,0.13L1880.83,1240.46z M1857.24,1245.55l0.24,0.17l0.26,0l0.13-0.08l-0.12-0.27
  		l-0.24-0.42l-0.1-0.07l-0.18,0.05l-0.11,0.2l0.01,0.18L1857.24,1245.55z M1848.72,1250.71l0.21,0.15l0.13,0.17l0.24,0.76l0.05,0.06
  		l0.15,0.09l0.45,0.04l0.13-0.18l0.01-0.12l-0.02-0.33l-0.32-0.43l-0.07-0.16l-0.02-0.16l0.08-0.77l0.16-0.31v-0.3l-0.11-0.32
  		l-0.2,0.02l-0.32,0.18l-0.2,0.4l-0.08,0.32l-0.18,0.21l-0.29,0.18l-1.16,0.24l-0.19,0.18l0.77-0.05L1848.72,1250.71z
  		 M1880.65,1239.11l0.87,0.38l0.05,0.09l0.01,0.02l0.02,0.19l0.16,0.07l0.13,0.06l-0.01,0.11l-0.03,0.14l-0.01,0.19l0.08,0.18
  		l0.32-0.09l0.05-0.04l0.38-0.28l0.14-0.15l0.15-0.32l0.17-0.15l0.05-0.11h0l0,0l-0.13-0.04l-0.16-0.04l-0.3,0.01l-0.11-0.06
  		l-0.08-0.05l-0.11-0.07l-0.28-0.18l-0.4-0.05l-0.13-0.02l-0.9,0.03l-0.07,0.07l0.04,0.03L1880.65,1239.11z M1974.17,1226.67
  		l0.13,0.03l0.3-0.07l0.41-0.02l0.02-0.03l-0.01-0.11l-0.06-0.03l-0.2-0.06l-0.51,0.01l-0.1,0.06l-0.01,0.08l0,0.07L1974.17,1226.67
  		z M1894.4,1238.07l0.09,0.11l0.34,0.17l0.29-0.08l0.12-0.15l0.05-0.21l-0.06-0.24l-0.16-0.14l-0.43-0.03l-0.26,0.08l-0.08,0.08
  		l0.01,0.11L1894.4,1238.07z M1915.14,1236.51l0.09,0.05l0.33,0.09l0.57,0.29l0.11-0.21l0.17-0.53l0.56-0.3l-0.09-0.09l-0.59-0.06
  		l-0.61,0.08l-0.38,0.14l-0.18,0.18l-0.06,0.11l0.02,0.07L1915.14,1236.51z M1880.73,1238.45l0.29-0.01l0.98-0.09l0.1-0.15l0-0.28
  		l-0.05-0.18l-0.17-0.1l-0.26-0.03l-0.4,0.09l-0.21,0.12l-0.38,0.47l0.01,0.06L1880.73,1238.45z M1933.91,1237.92l0.64,0.2l0.2,0.04
  		l0.25-0.12l0.33,0.02l0.12-0.12l0.03-0.17l-0.25-0.47l-0.09-0.23l-0.15-0.12l-0.32-0.06l-0.22,0.04l-0.58,0.41l-0.05,0.2l0.02,0.11
  		L1933.91,1237.92z M1752.42,1247.54l0.26-0.45l0.24-0.35l-0.09-0.16l-0.11-0.11l-0.25-0.09l-1.63-0.25l-0.64,0.2l0.05,0.11
  		l0.18,0.14l0.29,0.14l0.35,0.05l-0.06,0.14l-0.25,0l-0.09,0.17l-0.07,0.03l-0.49-0.19l-0.22,0.01l-0.22,0.1l-0.08,0.08l-0.06,0.14
  		l0.01,0.18l0.07,0.18l0.09,0.12l0.18,0.16l2.39,0.97l0.22-0.16l0.16-0.35l-0.18-0.13l-0.15-0.2l-0.04-0.14l-0.07-0.06l-0.09-0.13
  		l0.26-0.11L1752.42,1247.54z M1891.82,1238.74l0.61-0.09l0.11-0.07l0.13-0.08l0.09-0.11l0.07-0.23l0,0l0,0l-0.05-0.15l-0.04-0.02
  		l-0.03-0.01l-0.22-0.04l-0.8,0.39l-0.08,0.11l0.07,0.19L1891.82,1238.74z M1761.64,1270.21l0.26-0.28l1.11-0.8l0.87-0.57l1.22-0.75
  		l0.23-0.18l0.34-0.27l0.09-0.09l0.12-0.13l0.26-0.2l0.21-0.16l0.11-0.15l-0.2-0.13l-0.21-0.08l-0.15-0.06l-0.8,0.18l-3.47,1.28
  		l-0.63,0.33l-0.4,0.16l-1.03,0.3l-0.15,0.07l-1.14,0.64l-0.36,0.28l-0.39,0.47l-0.19,0.72l-0.02,0.09v0l0,0.01l0.01,0.22l0.04,0.08
  		l0.04,0.08l0.09,0.12l0.29,0.23l0.2,0.07l0.23,0.04l0.55,0.1l0.39,0.02l0.39,0.02l0.16-0.01l0.24-0.01l0.39-0.06l0.69-0.2
  		l0.18-0.41l0,0v0l-0.08-0.24l-0.02-0.06l0.18-0.22L1761.64,1270.21z M1779.14,1274.51l0.03-0.04l0.13-0.25l0.15-0.2l0.09-0.02
  		l0.09-0.02l0.19-0.01l-0.16-0.19l-0.16-0.15l-0.52-0.06l0-0.41l0,0l0.01-0.06l0.05-0.2l0.08-0.26l-0.3-0.4l-1.14-0.13l-0.13-0.02h0
  		l-0.12,0.04l-0.69,0.24l-0.16,0.22l-0.41,0.1l0,0.07l0,0.2v0l0.18,0.11l-0.1,0.07l-0.17,0.02l-0.52,0.07l-0.04,0.15l-0.01,0.05v0
  		l0.02,0.13l0.02,0.14l0.17,0.3l0.09,0.07l0.11,0.09l0.26,0.03l0.26,0.03l-0.21,0.21l-0.24,0.07l-0.33,0.09l-0.54,0.05l-0.54,0.05
  		l-0.51,0.02l-0.01,0l-0.02,0l-6.23,0.05l-0.24-0.1l-0.04-0.03l-0.05-0.04l-0.09-0.37v0l-0.14-0.1l-0.07-0.02l-0.08-0.03l-0.19,0.01
  		l-0.19,0.06l-0.17,0.09l-0.16,0.14l0.07,0.1l0.26,0.16l0.74,0.35l1.77,0.69l0.56,0.2l1.12,0.4l0.03-0.02l0.06-0.04l0.09-0.15
  		l0.08-0.13l0.05-0.03l0.06-0.04l0.27-0.06l0.36-0.08l1.03-0.09l0.14,0.14l0.15,0.45l0.08,0.13l0.29,0.17l0.2,0.12l1.17,0.23
  		l1.49,0.14l1.11,0.11l0.57-0.28l0.09-0.06l0.17-0.11l0.18-0.14l0.18-0.14l0.06-0.06l0.12-0.12l0.1-0.32v0l0-0.41l0.07-0.9
  		L1779.14,1274.51z M1763.42,1270.96l0.45,0.06l1.31,0.09l0.26-0.17l0.05-0.03v0l-0.08-0.24l0.06-0.14l0.06-0.13l0.12-0.07l0.11-0.1
  		l0.08-0.07h0l0,0l-0.37-0.09l-0.37-0.09l-0.43-0.04l-0.57,0.05l-0.36,0.23l-0.11,0.04l-0.05,0.02l-0.18,0.14l-0.08,0.15l-0.15,0.27
  		l0,0v0L1763.42,1270.96z M1783.57,1218.01l0.05,0.02l0.21-0.1l-0.1-0.07l-0.36,0.03l-0.03-0.05l-0.12-0.05l-0.44,0.06l0.12,0.12
  		l0.49,0.07L1783.57,1218.01z M1777.49,1239.72l-0.31-0.02l-0.19,0.07l-0.1,0.11l0,0l0.04,0.08l0.03,0.05l0.21,0.11l0.25,0.06
  		l0.09-0.01l0.09-0.01l0.01-0.02l0.01-0.02l0,0l0,0l0.04-0.14v0l-0.01-0.04l-0.02-0.08v0l0,0L1777.49,1239.72z M1782.97,1219.41
  		l0.35,0.08l0.17-0.03l-0.08-0.14l-0.14-0.06l0.16-0.17l0.19-0.05l-0.09-0.16l0.04-0.2l-0.48-0.25l0.07-0.14l-0.2,0.02l-0.21,0.14
  		l-0.06,0.09l-0.06,0.04l-0.06,0.02l-0.07-0.04l0.03-0.3l0.08-0.12l0-0.11l-0.09-0.19l-0.17,0.01l-0.08,0.08l-0.18,0.04l-0.14,0.09
  		l-0.23,0.11l-0.12,0.23l-0.15,0.21l-0.02,0.13l0.07,0.06l0.08-0.01l0.18-0.07l0.19-0.03l-0.03,0.18l0.04,0.13l0.21,0.1l-0.51,0.21
  		l0.16,0.16l0.08,0l0.27-0.14l0.09,0l0.09,0.03l-0.06,0.18l0.08,0.05l0.07-0.03l0.09-0.29l0.22-0.03l0.06,0.05l-0.02,0.13
  		L1782.97,1219.41z M1777.76,1238.73l0.03-0.06v0l0-0.1l0-0.1l-0.18-0.12l-0.6,0.03l-0.06,0.03l-0.04,0.02l-0.03,0.11l-0.03,0.11
  		l0,0l0.03,0.1l0.11,0.2l0.13,0.07h0.15l0.46-0.21L1777.76,1238.73z M1764.57,1271.47l0.03,0.03l0.07,0.09l0.9,0.45l0.26,0.13
  		l0.15,0.13l0.04,0.24l0.02,0.09l0.11,0.15l0.38,0.11l0.51,0.15l0.05,0.23l0.03,0.11l0.09,0.09l0.09,0.03l0.06-0.01l0.11-0.01
  		l0.33-0.1l0.16-0.06l0.36-0.18l0.36-0.18v0l-0.06-0.48l-0.05-0.18l-0.02-0.09l-0.19-0.05l-0.33-0.09l-0.05-0.08l-0.21-0.38
  		l-0.47-0.05l-0.92-0.02l-0.01,0l-0.17-0.11l-0.18-0.06l-0.83-0.03l-0.35-0.1l-0.1-0.03l0,0l-0.11,0.04l-0.11,0.04l0.06,0.15
  		L1764.57,1271.47z M1607.16,1273.02l0.67,0.13l0.07,0.01l0.33,0.11l0.01,0l0.41,0.04l0.33-0.01l-0.06-0.07l-0.11-0.12l-0.92-0.22
  		l-0.86-0.27l-0.27-0.08l-0.35-0.13l-0.57-0.22l-0.14,0.26l0.41,0.07l0.87,0.22L1607.16,1273.02z M1783.98,1219.41l-0.53,0.24
  		l-0.09,0.01l-0.14,0.06l0,0.08l0.03,0.15l0.19,0.09l0.16-0.01l0.45-0.29l0.08-0.12l-0.01-0.13l0.1-0.14L1783.98,1219.41z
  		 M1620.46,1259.97l-0.02-0.05l-0.11-0.15l-0.22-0.13l-0.23-0.07l-0.18,0.07l0.08,0.08l0.41,0.21L1620.46,1259.97z M2232.44,1294
  		L2232.44,1294L2232.44,1294L2232.44,1294z M1621.54,1261.28l0.68-0.17l0.8-0.05l-0.15-0.25l-1.12-0.17l-0.4,0l-0.05,0.11l0.03,0.08
  		l0.1,0.16l-0.06,0.24L1621.54,1261.28z M1621.6,1259.57l-0.34-0.09l-0.26,0.08l-0.14,0.25l0.24,0.2l0.57,0.16l0.21-0.31l-0.16-0.26
  		L1621.6,1259.57z M2189.4,1265.09l-0.14,0.06l-0.11,0.15l-0.41,0.33l-0.5,0.26l-0.19,0.01l-0.35-0.2l-0.82-0.23l-0.18,0.05l0,0.12
  		l0.07,0.32l0.13,0.24l0.29,0.13l0.62-0.09l0.56-0.24l0.8,0.1l0.25-0.13l0.23-0.29l-0.01-0.12l-0.08-0.35L2189.4,1265.09z
  		 M2221.62,1292.94l-0.58,0.01l-0.71,0.07l-0.89,0.15l-0.42-0.17l-0.33-0.27l-1.26-0.09l-1.34-0.02l-0.61-0.2l-0.58-0.07l-2.91,0.07
  		l-0.69-0.39l-0.39-0.29l-0.83-0.12l-0.81-0.22l-0.26-0.04l-0.26,0.02l-1.13-0.26l-3.02-0.48l-0.55-0.03l-0.51-0.13l-0.24-0.29
  		l-0.32-0.18l-2.15-0.13l-0.29-0.23l-0.48-0.01l-0.95-0.4l-0.77,0.01l-0.96-0.15l-0.69-0.29l-0.45-0.12l-0.12-0.37l-0.33-0.12
  		l-0.39-0.06l-0.88,0.2l-0.89-0.37l-3.12-0.17l-0.48-0.21l-0.33-0.2l0.03-0.19l0.27,0.05l0.27,0l0.48-0.06l0.14-0.09l0.16-0.25
  		l0.13-0.13l0.23-0.28l-0.6-0.35l-0.33-0.06l-0.48,0.25l-0.41,0.08l-0.36-0.22l-0.19-0.06l-0.12-0.15l0.07-0.33l-0.28-0.35
  		l-0.29-0.16l-0.39-0.03l-0.54-0.16l-0.84-0.41l-1.81-1.09l-0.42-0.14l-1.35,0.19l-1.32,0.3l-0.17,0.25l-0.29,0.07l-0.66-0.24
  		l-2.46,0.04l-0.21-0.37l2.28-0.41l2.26-0.56l-0.14-0.29l-1.08-0.67l-0.27-0.21l-0.06-0.24l-0.44-0.15l-0.27-0.29l-0.77-0.49
  		l-0.48-0.18l-0.27,0l-1.22-0.95l-0.79-0.21l0.02-0.3l0.11-0.12l0.32-0.18l0.02-0.37l-0.02-0.88l-0.2-0.03l-0.6-0.23l-0.03-0.25
  		l0.44-0.03l0.39-0.11l0.2-0.1l-0.01-0.24l-0.4-0.16l-0.14-0.22l0.15-0.32l0.06-0.24l-0.17-0.1l-2.67,0.07l-0.89-0.08l-0.02-0.31
  		l0.37-0.23l2.55-0.85l0.37-0.14l0.32-0.18l0-0.33l-0.31-0.21l-0.5,0.05l-0.39-0.14l-0.04-0.28l0.71-0.22l0.15-0.19l-0.04-0.23
  		l-0.43-0.01l-0.19-0.19l0.17-0.1l0.25-0.03l0.79-0.52l0.04-0.28l-0.19-0.23l-0.15-0.41l0.71-0.61l0.57-0.29l0.64,0.15l0.35,0.18
  		l0.23,0l0.16-0.12v-0.25l-0.25-0.24l-0.56-0.92l0.02-0.42l0.29-0.14l0.16,0.03l0.39,0.59l1.2,0.81l0.46-0.21l1.1-0.35l0.72-0.17
  		l0.72-0.35l0.6-0.11l2.68-0.13l0.31,0.23l0.43,0.27l0.59-0.06l0.36,0.02l0.15-0.32l-0.13-0.35l-0.46,0.01l-0.53-0.1l-0.54-0.18
  		l-0.99-0.58l-0.23-0.6l-0.02-0.35l-0.19-0.08l-0.26,0.34l-0.4,0.38l-0.76,0.35l-0.6-0.31l-0.58-0.05l0.24-0.3l0.58-0.41l0.11-0.35
  		l-0.13-0.28l-0.34-0.03l-0.35-0.09l0.02-0.31l-0.5-0.29l-0.27-0.46l-0.02-0.75l-0.27-0.46l-0.38-0.55l-0.29-0.36l-0.43-0.03
  		l-0.31-0.06l-0.41-0.19l0.07-0.33l0.22-0.15l0.28-0.31l-0.03-0.33l-0.13-0.33l0.27-0.4l-0.18-0.89l-0.41-0.07l-0.11-0.2l0.38-0.39
  		l0.18-0.36l0.13-0.39l-0.11-0.2l-0.32-0.13l-0.41-0.26l-0.2-0.24l-0.09-0.57l-0.52-0.23l-1.22-0.07l-0.35,0.04l-0.22-0.22
  		l1.39-0.43l0.42,0.06l0.32,0.1l0.25-0.1l0.33,0.01l0.22-0.26l0.13-1.25l0.27-0.31l0.38-0.29l0.37-0.19l0.18-0.63l0.24-0.16
  		l0.29,0.13l0.32,0.52l0.36,0.01l0.43-0.16l0.43,0.04l0.5,0.13l0.3,0.04l0.27-0.06l0.54,0.11l0.19-0.13l-0.02-0.28l-0.25-0.19
  		l-0.41-0.58l-0.47-0.83l0.24-0.09l0.13-0.27l-0.17-0.31l-0.25-0.98l0.11-0.57l0.35-0.08l0.23,0.03l0.21,0.66l0.01,0.74l0.18,0.34
  		l0.36-0.12l0.33,0.07l0.32-0.15l0.35-0.31l-0.24-0.37l-0.2-0.13l0.25-0.06l0.29-0.15l0.49-0.02l1.02,0.06l0.54-0.36l0.43,0.03
  		l0.32-0.19l-0.17-0.2l-0.7-0.21l-0.71-0.18l-0.66-0.79l-0.03-0.21l0.78,0.26l0.49,0.47l1.26-0.09l0.63,0.03l0.32-0.22l0.64,0.09
  		l0.54,0.38l0.92-0.52l0.3-0.6l0.24-0.28l-0.03-0.23l-1.01-0.37l-0.81-0.24l-0.36-0.27l-0.35-0.3l0.27-0.01l0.26,0.02l0.64,0.29
  		l0.66,0.06l0.6,0.16l0.49,0.23l0.29-0.12l0.14-0.3l-0.05-0.36l-0.24,0.09l-0.31,0.02l0.14-0.98l0.35-0.57h0.33l0.48,0.07l0.33-0.34
  		l-0.14-0.42l-0.32-0.47l-0.3-0.62l-0.29,0.08l-0.05,0.42l-0.16,0.21l-0.33-0.17l-0.57-0.23l-1.56-0.79l-0.75-0.26l-0.38-0.05
  		l-0.37-0.3l-0.16-0.26l-0.14-0.29l-0.29-0.23l-0.13-0.15l-0.61-0.13l-1.09-0.35l-0.89-0.1l-0.5,0.04l-1.16-0.24l-0.89-0.05
  		l-0.56-0.15l-0.73,0.41l-0.78,0.02l-0.39-0.07l-0.58-0.39l-0.64-0.64l-0.83,0.1l-0.35,0.35l0.03,0.61l0.45,1.13l-0.02,0.17
  		l-0.16,0.06l-0.49-0.44l-0.53,0.03l-0.36-0.29l-0.7-1.67l-0.38-0.44l-0.32-0.33l-0.8-0.34l-0.15-0.44v-0.34l-0.35-0.33l-0.27-0.35
  		l-0.72-0.17l-1.33-0.47l-0.39-0.07l-0.5-0.29l-0.41-0.09l-0.28,0.08l-0.53,0.33l-0.79-0.42l-1.01,0.02l-0.86-0.33l-0.89-0.17
  		l-0.64-0.41l-0.32-0.17l-0.22-0.21l-0.52-0.44l-0.68-0.68l-0.3-0.21l-0.22-0.08l-0.22,0.08l0.01,0.45l-0.05,0.46l-0.11,0.28
  		l-0.38,0.11l-0.28,0.17l-0.47,0.12l-0.48-0.28l-0.49-0.12l-0.51-0.01l-1.27-0.1l-0.21,0.22l-0.29,0.17l-0.27-0.17l-0.03-0.44
  		l-0.1-0.75l-0.24-0.08l-0.48,0.14l-0.6,0.1l-0.5-0.05l-0.63-0.01l-0.82,0.04l-0.69,0l-0.77,0.11l-1.6-0.29l-0.39,0.03l-0.47-0.05
  		l-0.53-0.3l0.15-0.26l0.03-0.22l-0.08-0.25l-0.04-0.24l-1-0.66l-0.54-0.39l-0.76-0.1l-0.77,0.11l-0.45,0.29l-0.86,0.23l-0.39,0.33
  		l-0.38-0.11l-0.06-0.22l0.38-0.46l0.19-0.51l0.46-0.6l0.19-0.43l-0.13-0.32l-0.37-0.05l-0.42,0.21l-0.37,0.01l-0.09-0.46
  		l-0.24-0.19h-0.51l-0.5,0.22l-0.51,0.16l-0.36,0.04l-0.65-0.2l-0.3-0.23l-0.34-0.2l-0.82-0.04l-0.42,0.11l-0.69-0.24l-1.81-0.11
  		l-0.52-0.23l-0.67-0.19l-1.56-0.1l-0.19,0.07l-0.24-0.06l-0.39-0.26l-0.31-0.15l-0.75-0.18l-0.81-0.02l-0.27,0.21l-0.34,0.09
  		l-0.65-0.44l-0.33-0.08l-0.82-0.26l-0.37-0.16l-0.69,0.61l-0.36,0.07l-0.67,0.38l-0.2-0.01l-0.1-0.39l-0.1-0.21l-0.4-0.22
  		l-0.21-0.15l-0.72-0.22l-0.53,0.04l-0.49,0.25l-1-0.04l-0.88,0.21l-1.08-0.06l-0.51-0.07l-0.67,0.05l-0.5,0.18l-0.33,0.07
  		l-0.26,0.16l-0.42,0.37l-0.44,0.86l-0.48,0.88l-0.46,0.17l-0.3-0.05l-0.34,0.08l-0.36,0.04l-0.84-0.28l-0.77,0.07l-0.32-0.19
  		l-0.89-0.7l-0.38-0.79l-0.44-0.11l-0.63-0.2l-0.38-0.09l-0.47,0.09l-0.37,0.09l-0.2,0.28l-0.35,0.38l-0.49,0.16l-0.4,0.04
  		l-0.41-0.17l-0.31-0.16l-0.41,0.02l-0.55,0.21l-0.8,0.21l-1.06,0.18l-0.81,0.17l-0.27,0.13l-0.76,0.47l-0.23,0.1l-0.92,0.14
  		l-1.04,0.31l-2.29,0.41l-0.38-0.16l0.65-0.37l1.22-0.38l0.76-0.28l-0.15-0.05l-0.18,0l-0.76,0.08l-0.81,0.24l-0.64,0.23l-0.45,0.08
  		l-0.35-0.03l-0.35-0.14l-0.34-0.1l-0.34,0.01l-0.37,0.13l-0.81-0.06l-0.3,0.02l-0.38-0.18l-0.38-0.03l-0.37,0.19l-0.53,0.11
  		l-0.59,0.18l-0.9,0.11l-0.38,0.21l-0.44,0.15l-0.48,0.1l-0.61,0.05l-0.53,0.11l-0.21-0.23l0.14-0.48l0.49-0.12l0.56-0.19l1.27-0.25
  		l0.3-0.21l0.35-0.57l-0.59-0.64l-0.41-0.2l-0.38-0.05l-0.45-0.03l-0.51-0.33l-0.69-0.9l-0.44-0.39l-0.38-0.13l-0.24-0.11
  		l-0.49-0.15l-1,0.14l-0.75,0.16l-1.22,0.18l-0.99,0.35l-0.58,0.71l0.06,0.64l-0.33,0.29l-1.11,0.64l-0.65,0.23l-1-0.14l-0.96-0.29
  		l-0.39-0.38l-0.3,0.1l-0.37-0.02l-0.21-0.25l-0.18-0.08l-0.71-0.25l-1.42-0.18l-2.5-0.74l-0.6-0.08l-0.68-0.29l-0.61-0.15l-0.34,0
  		l-0.23,0.03l-0.85-0.25l-0.89-0.15l-0.51,0.2l-0.39,0.06l-1.26-0.01l-0.17,0.07l-0.11,0.14l0.01,0.24l-0.79,0.77l-0.54,0.2
  		l-0.69,0.15l-0.7,0.23l-0.82,0.3l-0.92,0.07l-0.25-0.36l-0.21-0.06l-0.26-0.11l-0.37-0.09l-0.97,0.42l-0.6-0.09l-0.52-0.24
  		l-0.56,0.15l-0.66,0.15l-0.78,0.07l-0.81,0.03l-0.53-0.18l-0.3-0.13l-0.44-0.08l-0.46,0.13l-0.49,0.31l-0.41,0.13l-0.23,0
  		l-0.44-0.14l-0.66-0.17l-0.51-0.04l-0.62,0.16l-0.25-0.03l-0.19-0.03l-0.31-0.14l-0.43-0.15l-0.54,0.09l-0.42,0.1l-0.95,0.09
  		l-0.86,0.4l-0.46,0.11l-1.07,0.16l-0.63-0.01l-0.5-0.06l-0.52-0.02l-0.86,0.08l-0.6-0.09l-1.62,0.46l-0.25,0.14l-0.35,0.16
  		l-1.14,0.05l-0.74,0.32l-0.51,0l-0.56-0.11l-0.67-0.07l-0.48,0.04l-0.59,0.4l-0.46,0.15l-0.74,0.46l-0.34,0.5l-0.26,0.02
  		l-0.99,0.01l-0.6,0.24l-0.46-0.01l-1.5,0.44l-1.49,0.36l-1.94,0.54l-0.46,0.17l-0.56,0.32l-0.29,0.36l-0.13,0.73l-0.47,0.41
  		l-0.39,0.43l-0.36,0.56l-0.5,0.34l-0.63,0.1l-0.76,0.43l-0.74,0.48l-0.45-0.01l-0.4,0.28l-0.13,0.48l-0.33,0.4l-0.38,0.14
  		l-0.5-0.12l-1.04,0.08l-0.62-0.26l-0.51-0.18l-0.48,0.27l-0.63,0.07l-0.51,0.52l-0.4,0.28l-0.14,0.47l0.03,0.51l-0.25,0.25
  		l-0.37,0.41l-0.28,0.19l-0.65,0.16l-0.24,0.31l-0.25,0.49l-0.31,0.68l-0.16,0.51l-0.05,0.68l-0.13,0.37l-0.2,0.16l-0.16,0.22
  		l-0.63,0.29l-0.21,0.07l-0.08,0.3l-0.5,0.42l-0.6,0.26l-0.35,0.41l-0.39,0.41l-0.44,0.12l-0.28,0.03l-1.33,0.33l-0.57,0.46
  		l-0.24,0.34l0.08,0.58l-0.08,0.58l-0.4,0.29l-0.77,0.46l-0.58-0.22l-0.43-0.07l-0.35-0.03l-0.13-0.29l0.45-0.44l0.26-0.18l0.4-1.06
  		l0.18-0.61l0.12-0.58l0.27-0.98l0.47-0.9l0.32-0.53l0.3-0.63l0.49-0.35l0.25-0.12l0.32-0.23l0.26-0.3l0.19-0.18l0.57-1.09
  		l0.02-0.39l0.1-0.5l-0.16-0.32l-0.25-0.03l-0.48,0.15l-0.36,0.14l-1.12,0.03l-0.51-0.32l-0.71-0.17l0.27-0.31l0.29-0.29l0.62-0.44
  		l0.2-0.19l0.27-0.18l0.43,0.21l0.59,0.06l0.32-0.03l0.29-0.15l0.14-0.31l0.05-0.37l-0.1-0.25l-0.32-0.12l-0.14-0.23l0.05-0.31
  		l0.29-0.11l0.55-0.02l0.32-0.12l0.14-0.2l-0.03-0.25l-0.15-0.41l0.21-0.29l-0.18-0.24l-0.02-0.38l0.41-0.44l0.3-0.2l0.1-0.22
  		l-0.14-0.27l-0.21-0.31l-0.15-0.37l-0.18-0.37l0.1-0.55l-0.17-0.31l-0.26-0.06l-0.45,0.25l-0.48,0.12l-1.03,0.09l-0.41-0.11
  		l-1.08-0.14l-0.59-0.13l-1.24-0.01l-1.41-0.15l-2.05-0.3l-1.16-0.29l-0.42-0.05l-0.83,0.06l-0.4,0.11l-0.6,0.26l-0.93-0.22
  		l-1.56-0.11l-0.54-0.13l-0.96-0.35l-1.11,0.06l-0.39,0.17l-0.72,0.08l-0.93-0.78l-0.76-0.2l-0.52-0.18l-0.36-0.19l-0.36-0.08
  		l-0.48,0.12l-0.85,0.01l-0.24,0.05l-0.36,0.13l-0.35,0.3l-0.38,0.16l-0.64-0.2l1.06-0.69l0.16-0.35l-0.05-0.24l-0.29-0.18
  		l-0.26-0.29l0.27-0.07l0.39,0.17l0.57,0.16l0.29-0.03l0.36-0.28l-0.33-0.42l-0.25-0.16l-0.9-0.15l-0.7-0.49l-0.48-0.39l-0.37-0.23
  		l-0.39-0.14l-0.62-0.11l-2.3-0.17l-1.29,0.26l-1.04,0.07l-0.89,0.15l-0.36,0.16l-1.36,0.73l-0.63,0.12l-0.46,0.27l-0.16,0.48
  		l0.11,0.45l0.39,0.2l-0.02,0.36l0.18,0.65l-0.1,0.14l-0.47-0.07l-0.52,0.01l-0.93-0.44l-0.44-0.27l-0.75-0.01l-0.66,0.32l0.24,0.39
  		l0.21,0.14l0.38-0.05l0.54,0.08l-0.3,0.39l-0.76,0.52l-0.02,0.32l0.05,0.52l-0.18,0.41l-0.32,0.19l-0.1-0.22v-0.41l-0.2-0.27
  		l-0.45-0.12l-0.46,0.17l-0.39,0.04l-0.32-0.19l-0.36-0.28l0.21-0.32l0.31-0.18l-0.09-0.15l-0.36-0.01l-0.49-0.25l-0.59-0.02
  		l-0.19,0.11l-0.25,0.3l0,0.23l0.21,0.18l-0.07,0.26l-0.44,0.12l-0.48,0.01l-0.57,0.24l-0.67-0.02l-0.37,0.12l-0.52,0.42l-0.59,0.18
  		l-0.35,0.03l-1.13,0.23l-0.69,0.04l-0.38,0.11l-0.26,0.09l-0.74,0.71l-1.05,0.25l-0.85,0.26l-0.4,0.19l-0.57,0.47l-0.6,0.05
  		l-0.48,0.21l-0.31,0.2l-0.32,0.31l-0.18,0.66l-0.1,0.8l-0.39,0.58l-0.5,0.57l-0.35,0.45l-0.29,0.26l0.09,0.3l-0.05,0.24l-0.7-0.38
  		l-0.64-0.75l-0.65-0.32l-0.41-0.02l-0.33,0.09l-0.47,0.2l-0.47-0.27l-0.25-0.24l-0.24-0.05l-0.46,0l-0.57,0.07l-0.81-0.01
  		l-0.38,0.07l-0.24-0.14l-0.17-0.48l-0.69-1.02l-0.28-0.23l-0.97,0.05l-0.44-0.04l-0.08-0.25l0.2-0.13l0.34-0.3l0.26-0.3l-0.05-0.28
  		l-0.61-0.06l-0.7-0.04l-0.62,0.06l-0.62,0.3l-0.24,0.27l-0.13,0.65l0.31,0.57l0.3,0.4l0.13,0.44l0.02,0.34l-0.14,0.35l-0.18,0.57
  		l-0.34,0.29l-0.3,0.08l-0.54,0.24l-1.41,0.41l-0.57,0l-0.41,0.07l-1.5,0.17l-0.97,0.35l-1,0.58l-0.95,0.32l-1.24,0.3l-0.34,0.13
  		l-0.54,0.32l-0.52,0.14l-0.3,0.04l-0.46,0.17l-0.95,0.06l-0.58-0.15l-0.84-0.06l-0.78-0.26l-0.3-0.24l-0.37-0.38v-0.55l-0.27-0.29
  		l-0.38-0.12l-0.4-0.03l-0.25,0.56l-0.47,0.49l-0.46,0.24l-0.31,0.05l-0.96-0.23l-0.3-0.15l-0.08-0.17l0.29-0.27l-0.06-0.28
  		l-0.27-0.19l-0.46-0.38l-0.2-0.08l-0.26-0.06l-0.66,0.77l-0.27,0.61l-0.21,0.54l-1.7,0.24l-0.33-0.02l-0.53,0.03l-0.44-0.01
  		l-0.26-0.05l-0.2-0.27l-0.23-0.48l0.19-0.6l0.12-0.65l-0.34-0.26l-0.24-0.03l-0.45,0.22l-0.35,0.07l-0.15,0.41l-0.22,0.34
  		l-0.19,0.07l-1.73-0.29l-0.83-0.18l-0.22-0.63l-0.37-0.19l-0.64,0.16l-0.39,0.43l-0.44,0.02l-0.9-0.12l-1.03,0.02l-1.21,0.14
  		l-0.52-0.18l-0.42-0.19l-0.42-0.57l-0.79,0.04l-0.06,0.17l0.23,0.09l0.25,0.06l0.12,0.17l-0.45,0.31l-0.4,0.2l-0.28,0.24
  		l-0.43,0.57l-0.42,0.39l-0.24,0.1l-0.9-0.12l-0.42-0.13l-1.36-0.59l-0.6-0.34l-0.49-0.44l-0.85-0.28l-0.59,0.67l-0.53,0.27
  		l-0.39-0.04l-1.14-0.34l-0.5,0.45l-0.81,0.13l-0.8,0.17l-1.56,0.16l-0.96,0.06l-2.9,0.62l-1.62,0.18l-1.27,0.34l-0.64,0.25
  		l-1.3,0.4l-0.54,0.24l-0.69,0.41l-0.61,0.54l-0.26,0.28l-0.39,0.24l-0.39-0.28l-0.14-0.3l-0.1-0.64l-0.31-0.28l-0.27,0.06
  		l-0.25,0.34l-0.26,0.09l-0.93,0.07l-0.45-0.25l-0.63-0.12l-0.36,0l-0.77,0.13l-0.86,0.05l-0.51-0.12l-0.47,0l-0.36-0.04l-2.71,0.05
  		l-0.3-0.05l-0.02-0.2l0.08-0.37l0.32-0.34l0.35-0.28l0.22-0.37l-0.19-0.37l-0.44-0.11l-0.56,0.14l-0.55,0.2l-0.52,0.09l-0.35-0.03
  		l-0.64-0.16l-0.42,0.14l-0.24,0.05l-0.18,0.14l-0.03,0.18l0.21,0.25l0.25,0.35l0.05,0.34l-0.14,0.33l-0.08,0.74l-0.36,0.3
  		l-0.54,0.04l-0.51,0.09l-0.27-0.01l-0.58-1.04l-0.48-0.62l-0.31-0.19l-0.36-0.07l-0.52-0.22l-0.38-0.33l-0.31,0.03l-0.16,0.15
  		l0.05,0.14l0.38,0.12l0.16,0.23l-0.36,0.23l-0.32,0.16l-0.2,0.15l-0.25,0.49l-0.3,0.37l-0.26,0.02l-0.34-0.26l-0.3-0.14l-0.6-0.36
  		l-0.47-0.14l-0.27,0.03l-0.24,0.12l-0.26,0.19l0.12,0.35l0.25,0.4l0.81,0.35l0.66,0.22l0.28-0.03l0.31-0.06l0.09,0.22l0.01,0.27
  		l-0.29,0.44l-0.41,0.85l-0.27,0.45l-0.51,0.11l-0.57,0.18l-1.18,0.45l-0.83,0.54l-0.71,0.02l-0.61-0.12l-0.65-0.08l0,0.18l0.23,0.2
  		l0.3,0.54l-0.3,0.35l-0.28,0.07l-1.24-0.26l-1.06,0.17l-0.37,0.19l-0.63,0.63l-0.23,0.19l-0.28,0.13l-0.13,0.46l-0.02,0.31
  		l0.24,0.13l0.52,0.1l0.29,0.17l-0.12,0.21l-0.32,0.05l-0.18,0.13l0.11,0.17l0.85,0.11l0.88-0.2l1.17-0.13l0.07,0.3l-0.15,0.18
  		l-0.78,0.62l-0.36,0.42l-0.44,0.34l-0.26,0.11l-1.37-0.3l-0.54,0.01l-0.47-0.03l-0.56,0.05l-0.25,0.16l-0.88,1.15l-0.26,0.59
  		l-0.28,0.4l-0.53,0.28l-0.18,0.23l-0.24,0.46l0.24,0.37l0.18,0.17l0.18,0.02l0.2,0.13l-0.51,0.11l-0.48,0.03l-1.16,0.26l-1.8-0.18
  		l-0.53,0.37l-0.37,0.15l-0.8,0.18l-0.93,0.04l-0.93-0.12l-1.32,0.21l-1.94,0.18l-3.23,0.7l-0.9,0.36l-1.04,0.38l-0.94,0.26
  		l-0.77,0.12l-1.54,0.42l-1.73,0.86l-0.6,0.24l-0.48,0.39l-2.14,1.03l-0.7,0.48l-0.62-0.09l-0.38,0.02l-1.38,0.54l-0.39,0.11
  		l-0.88,0.44l-0.39,0.37l-0.47,0.8l-0.46,0.36l-0.5,0.06l-0.76,0.8l-1.21,1.68l-0.15,0.58l0.05,0.63l0.63,0.28l0.68,0.36l0.94,0.18
  		l1.44,0.53l2.17,0.48l0.82-0.03l2.04-0.31l0.77-0.07l0.61-0.02l0.59,0.16l0.25,0.57l-0.06,0.74l0.29,0.41l0.18,0.06l8.47,0.67
  		l1.06,0.05l0.25-0.07l0.42,0.05l0.38,0.18l1.23,0.18l-0.3,0.44l-0.8,0.18l-0.4,0.22l-9.19,0.46l-0.37,0.04l-0.48,0.17l-1.13,0.23
  		l-1.07,0.12l-0.54,0.59l-0.58-0.02l-1.12,0.07l-0.81,0.22l-0.63,0.07l-0.24-0.05l-0.25,0.09l-1.84,0.27l-1.76,0.2l-0.35,0.4
  		l-0.46,0.28l-0.34,0.33l-0.48,0.13l-0.48,0.17l-0.56,0.06l-0.72,0.38l-1.45-0.47l-1.37-0.31l-1.79,0.64l-1.23,0.57l-0.86,0.03
  		l-0.38,0.18l-0.56,0.36l-0.5,0.47l-0.6,0.82l-0.94,0.71l-1.11,1.12l-0.88,1.12l-0.71,0.27l-0.41-0.06l-0.29,0.21l-1.06,0.32
  		l-1.34,0.25l-0.7,0.07l-0.62-0.25l0.12-0.51l0.45-0.3l0.27-0.5l-0.13-0.52l-0.25-0.41l-0.56,0.14l-0.82,0.11l-0.61,0l-0.95-0.35
  		l-0.86-0.14l-3.01,0.33l-1.13,0.03l-1.01,0.17l-0.94,0.2l-1.24,0.32l-0.69,0.08l-0.98-0.04l-0.39,0.11l-0.33,0.04l-0.45,0.1
  		l-1.11,0.51l-1.25,0.66l-0.91,0.06l-0.5,0.41l-0.44,0.28l-1.87,0.93l-0.37,0.23l-0.43,0.31l-0.89,0.74l-2.21,1.55l-0.61-0.07
  		l-0.98-0.01l-1.06-0.06l-0.8-0.15l-0.3-0.25l0.11-0.28l0.17-0.21l0.24-0.22l0.19-0.39l-0.03-0.48l-0.17-0.24l-0.72,0.32l-0.38-0.15
  		l-0.38-0.7l-0.61-0.47l-0.3-0.44l-0.19-0.42l0.33-0.11l0.83-0.16l2.31-0.85l0.52-0.34l-0.29-0.05l-0.31-0.01l-1.88,0.37l-1.34-0.04
  		l-1.48,0.19l-0.55-0.01l-1.13,0.31l-0.94-0.02l-0.45-0.23l-0.46-0.17l-0.27,0.06l-0.26-0.07l-0.36-0.42l0.23-0.61l-0.1-0.23
  		l-0.2-0.1l-0.33-0.08l0.17-0.17l0.23-0.05l2.19-0.06l1.03-0.08l-0.1-0.33l-1.12,0.03l-0.82-0.07l-0.4-0.05l-0.24-0.2l1.08-0.14
  		l0.59-0.19l0.4,0.08l0.44-0.05l0.84,0.07l0.39-0.04l1.1,0.07l0.73-0.02l0.68-0.18l-0.34-0.26l-0.25-0.09l-1.78-0.02l-2.3-0.14
  		l-1.49-0.26l-4.32-1.34l-0.32-0.08l-0.25-0.24l-0.08-0.3l-0.48-0.15l-1.07,0.16l-0.82-0.17l-0.25-0.02l-0.43-0.19l-0.41-0.26
  		l-0.28-0.51l-0.3-0.38l-0.23-0.09l-0.6-0.03l-0.38,0.12l-0.27,0.15l-1.43,0.34l-0.69,0.38l-0.86,0.27l-0.64-0.1l-1-0.33l-1.04,0.09
  		l-0.53,0.21l-0.49-0.11l-0.29-0.24l-0.2-0.35l-0.27-0.43l-0.11-0.36l-0.28-0.62l-0.2-0.19l-0.29-0.18l-0.49-0.56l-0.27-0.27
  		l-0.63,0.15l-0.73,0.09l-1.83,0.06l-1.32-0.32l-1.36-0.38l1.75-0.01l1.79,0.06l0.87-0.06l0.57-0.16l0.62-0.21l0.39-0.34l0.56-0.8
  		l-0.13-0.66l-0.2-0.32l-0.51-0.25l-5.34-0.09l-1.26-0.15l-0.48,0.11l-0.13,0.11l0.02,0.43l-0.1,0.35l-0.31,0.02l-0.34-0.06
  		l-0.49-0.41l-0.11-0.42l-0.51-0.55l-0.29-0.25l-1.68-0.74l-0.69-0.4l-0.52-0.17l-0.61-0.65l-0.18-0.29l-0.12-0.3l0.1-0.23
  		l0.29-0.19l0.34,0.21l0.23-0.02l-0.03-0.18l-0.47-0.43l-0.32-0.39l-0.12-0.32l-0.05-0.32l0.66,0.51l0.59,0.52l0.86,0.65l1.23,0.58
  		l2.01,0.78l0.95,0.27l0.95-0.01l1.9-0.27l1.52-0.03l0.59-0.34l0.17-0.39l0.04-0.5l-0.42-0.13l-0.35-0.2l-2.98-0.78l-3.49-1.05
  		l-0.25-0.15l0.86-0.02l0.39-0.17l0.52-0.18l0.9,0.17l0.77,0.18l3.5,0.38l2.36,0.41l1.88,0.45l1.06,0.08l1.39-0.27l1.01-0.54
  		l0.42-0.3l0.68-0.78l0.04-0.4l-1.13-0.21l-1.99-0.22l-1.46-0.01l-0.65-0.29l-0.34-0.25l-0.56-0.23l-1.04-1.08l-0.62-0.61l-0.21-0.5
  		l0.18-0.27l1.71-0.17l0.51,0.03l0.24,0.06h0.31l0.39-0.08l0.32-0.02l2.51,0.44l0.74-0.08l1.35,0.05l1.67,0.24l1.63-0.05l0.62-0.08
  		l0.62-0.17l0.21-0.07l0.33-0.5l1.1-0.64l3.23-0.9l2.07-0.36l0.73-0.23l1.16-0.51l0.5-0.1l0.48-0.18l1.31-0.58l1.24-0.47l0.19-0.19
  		l-0.08-0.17l-0.31-0.06l-0.37-0.03l-0.53,0.01l-0.55-0.13l0.76-0.32l0.55-0.13l0.58-0.06l0.1-0.14l-0.3-0.29l-0.42-0.02l-0.64-0.09
  		l0.31-0.19l0.35-0.17l0.36-0.1l0.29,0.11l0.13-0.22l-0.08-0.31l-0.02-0.29l0.19-0.02l0.33,0.05l0.32,0.17l0.26,0.57l0.35,0.2
  		l0.42-0.09l0.38-0.23l0.13-0.31l-0.07-0.23l-0.43-0.59l-0.17-0.16l-0.02-0.23l0.26,0.04l0.17,0.08l0.64,0.14l0.49-0.01l0.65-0.12
  		l0.31-0.38l0.24-0.23l-0.14-0.24l-0.61,0.23l-0.38,0.08l-0.92-0.14l0.22-0.22l0.27-0.12l0.62,0.05l0.33-0.31l-0.21-0.23l-0.29-0.07
  		l-0.94-0.01l0.17-0.13l0.09-0.26l0.26-0.1h0.26l0.36,0.21l0.21-0.1l0.34-0.21l0.2-0.56l-0.16-0.35l-0.36-0.26l-0.59-0.25
  		l-0.42,0.12l-0.18-0.44l-0.09-0.42l-0.23-0.14l-0.17-0.24l0.51,0.05l0.54,0.11l0.34,0.2l0.29,0.27l0.33-0.03l0.38-0.17l0.23-0.21
  		l0.28,0.1l0.51,0.12l0.19-0.3l0.11-0.55l-0.1-0.32h-0.25l-0.19,0.27l-0.23-0.03l-0.27-0.6v-0.55l-0.35-0.09l-0.39,0.18l-0.63-0.34
  		l0.01-0.45l0.42,0.01l0.57-0.15l0.12-0.22l-0.05-0.32l-0.02-0.43l-0.03-0.24l-0.21-0.07l-0.21,0l-0.28,0.14l-0.37,0.07l-0.33-0.14
  		l-0.24-0.07l-0.48,0.06l-0.65-0.18l0.57-0.38l0.53-0.14l1.1-0.14l0.16-0.24l-0.08-0.29l-0.16-0.24l-0.24-0.08l-0.63,0.37
  		l-0.29-0.01l-0.42-0.05l0.09-0.09l0.22-0.05l0.49-0.46l0.26-0.09l0.24-0.17l0.16-0.2l0.26-0.08l0.07-0.25l-0.1-0.26l-0.42-0.55
  		l-0.11-0.44l-0.36-0.05l-0.34,0.02l-0.47,0.14l-0.14-0.33l0.08-0.24l0.33-0.07l0.2-0.11l0.16-0.19l0.21-0.15l-0.02-0.26l-0.9,0.02
  		l-0.42-0.24l-0.26-0.19l0.08-0.28l0.21-0.15l0.37,0.15l0.14-0.26l-0.05-0.26l-0.44-0.3l-0.37-0.65l-0.08-0.78l-0.25-0.34
  		l-0.46-0.34l-0.28-0.14l-0.18-0.24l-0.37-0.36l-0.28-0.31l-0.04-0.29l0.24-0.45l-0.18-0.15l-0.55-0.19l-0.05-0.23l0.12-0.12
  		l0.65-0.29l0.42-0.09l0.24,0.05l0.08-0.14l-0.22-0.07l-0.29-0.01l-1.05,0.16l-0.23,0.09l-0.44,0.27l0.02,0.33l0.14,0.27l-0.63-0.08
  		l-0.85-0.23l-0.47-0.17l-0.15-0.11l0.18-0.3l0.15-0.13l0.1-0.13l-0.17-0.12l-0.44-0.02l-0.22-0.09l0.16-0.15l0.85-0.37l0.12-0.13
  		l-0.04-0.14l-0.06-0.08l-0.13,0.04l-0.47,0.23l-0.31,0.03l-0.29-0.01l-0.17-0.05l0.16-0.26l0.15-0.12l-0.11-0.25l-0.13-0.17
  		l0.02-0.18l0.03-0.09l0.28-0.4l-0.05-0.15l-0.04-0.07l-0.06-0.18l-0.04-0.26l0.04-0.21l0.11-0.16l0.17-0.05l0.18,0.1l0.31-0.02
  		l0.47-0.09l-0.01-0.12l-0.06-0.08l-0.3-0.09l0.14-0.09l0.2-0.08l0.1-0.1l-0.12-0.06l-0.04-0.25l0.15-0.16l0.1-0.24l0.09-0.27
  		l0.14-0.02l0.1,0.16l0.28,0l0.65,0.22l0.37-0.05l0.15-0.12l-0.03-0.21l-0.07-0.13l-0.49-0.32l0.11-0.14l0.09-0.05l0.15-0.25
  		l0.23-0.29l0.18-0.08l-0.06-0.19l-0.12-0.13l0.3-0.11l0.25,0.01l0.34,0.06l0.14,0.27l0.33,0.3l0.53,0.69l0.14,0.08l0.17,0
  		l-0.01-0.26l-0.2-0.2l0.03-0.14l0.04-0.06l0-0.16l-0.06-0.22l-0.19-0.16l0.13-0.22l0.18-0.06l0.16,0.01l0.46-0.07l0.23,0.04
  		l0.44,0.26l0.06,0.32l0.16,0.08l0.11-0.26l0.04-0.28l0.27-0.07l0.17-0.24l0.25,0.06l0.26,0.14l0.03,0.24l0.19,0.14l0.16-0.22
  		l0.07-0.22l-0.19-0.24l0.1-0.12l0.18-0.02l0.2,0.12l0.22-0.22l0.11-0.16l-0.1-0.14l-0.35,0l-0.18-0.04l-0.14,0.06l-0.09,0.15
  		l-0.18-0.01l-0.11-0.04l-0.12,0.02l-0.17,0.24l-0.39,0.04l-0.09,0.07l-0.39,0.07l-0.3-0.02l-0.3-0.24l-0.22-0.34l-0.02-0.23
  		l0.15-0.2l0.13-0.23l0.29-0.33l0.43-0.06l-0.07-0.14l-0.19-0.03l-0.27-0.17l-0.17-0.37l0.11-0.17l0.11-0.12l0.14-0.12l0.17,0.13
  		l0.35,0.01l0.16-0.16l-0.29-0.45l0.06-0.14l0.18,0l0.18,0.04l0.11,0.05l0.19,0.02l0.49-0.13l0.26-0.22l0.46-0.52l0.19-0.15
  		l0.29-0.2l0.1-0.17l0.18-0.01l0.5-0.34l0.2,0.01l0.15,0.05l0.06,0.31l0.16,0.07l0.13-0.15l-0.07-0.26l0.12-0.24l0.16-0.04
  		l0.16,0.17l0.25,0.12l0.32,0.02l0.29,0.25l0.19-0.04l-0.04-0.23l-0.16-0.16l-0.02-0.11l0.16-0.04l0.04-0.13l-0.22-0.04l-0.1-0.04
  		l-0.13-0.09l0.08-0.11l0.3-0.02l0.21-0.32l0.34-0.47l0.49-0.44l0.73-0.31l0.22-0.12l0.28-0.2l0.22-0.09l0.32-0.07l0.24-0.03
  		l0.1,0.13l-0.1,0.14l0.06,0.19l0.26-0.04l0.25,0.02l0.1-0.17l-0.26-0.19l-0.17-0.38l-0.1-0.31l-0.17-0.08l-0.4-0.02l-0.86,0.26
  		l-0.63,0.38l-0.83,0.24l-0.36,0.05l-0.3,0.34l-0.33,0.12l-0.53,0.31l-0.87,0.26l-0.52,0.04l-1.06,0.43l-0.1,0.1l0.06,0.12
  		l-0.35,0.48l-0.17,0.14l-0.4,0.19l-0.13,0.14l-0.05,0.2l-0.24,0.17l-0.22,0.02l-0.23,0.05l-0.46,0.3l-0.19,0.06l-0.17-0.05
  		l-0.12-0.25l-0.18,0.04l-0.13,0.29l0.09,0.23l-0.25,0.07l-0.2-0.05l-0.62,0.3l0.06,0.25l0.1,0.15l-0.05,0.18l-0.21-0.04l-0.16-0.16
  		l-0.39,0.03l-0.5-0.15l-0.27,0.18l-0.23,0.25l-0.06,0.29l0.04,0.41l0.26,0.15l0.2,0.04l-0.04,0.15l-0.08,0.07l-0.37-0.01
  		l-0.12,0.05l-0.09,0.14l-0.06,0.05l-0.41,0.02l0.04,0.12l0.05,0.09l-0.08,0.18l-0.07,0.04l-0.31-0.1l-0.05,0.2l0.04,0.16l0.19,0.1
  		l0.06,0.18l-0.18,0.18l-0.2-0.08l-0.5-0.14l-0.19,0.03l-0.29,0.11l0.08,0.22l0.09,0.14l-0.26,0.07l-0.27-0.03l-0.27,0.02
  		l-0.18,0.36l-0.1,0.1l0,0.16l0.18,0.18l-0.07,0.51l-0.09,0.16l-0.15,0.08l-0.19-0.01l-0.41-0.16l-0.23,0l-0.12,0.05l-0.24,0.25
  		l-0.04,0.15l0.11,0.41l-0.07,0.2l-0.06,0.12l0.03,0.25l0.04,0.16l0.03,0.39l-0.05,0.14l-0.1-0.08l-0.11-0.17l-0.27,0.07l-0.14,0.15
  		l-0.12-0.08l0.03-0.23l-0.08-0.11l-0.05-0.48l-0.14-0.12l-0.23,0.02l-0.25,0.36l-0.26,0.06l-0.1,0.07l-0.1,0.48l-0.06,0.51
  		l0.04,0.21l0.04,0.1l0.1,0.04l0.65-0.19l0.36,0.02l0.39,0.11l0.05,0.1l-0.17,0.1l-0.26,0.31l-0.19,0.43l-0.15,0.31l-0.08,0.29
  		l0.31,0.38l0.33,0.29l-0.18,0.18l-0.27,0.49l-0.14,0.38l0.11,0.3l-0.14,0.31h-0.3l-0.17,0.29l0.37,0.36l0.3,0.17l0.08,0.42
  		l-0.24,0.28l-0.35,0.22l-0.12,0.3l-1.39-0.21l-0.58,0.12l-0.21,0.09l-0.23,0.06l0.13,0.3l0.3,0.38l0.12,0.53l0,0.68l0.5,0.75
  		l0.43,0.56l0.35,0.87l0.17,0.52l0.17,0.7l-0.05,0.75l0.13,0.81l0.48,0.64l0.2,0.31l0.24,0.29l0.22,0.65l-0.45,1.01l-0.41,0.76
  		l-0.65,0.77l-0.6,0.35l-1.48,0.59l-0.83,0.22l-1.24,0.2l-0.64,0.17l-1.25-0.04l-0.79,0.32l-0.44-0.01l-0.54,0.09l-0.7,0.21
  		l-0.55,0.05l-0.44-0.02l-1.93,0.88l-0.36-0.02l-0.27-0.04l-0.45,0.11l-0.47-0.2l-0.34-0.05l-0.45-0.02l-0.55,0.25l-0.58,0.09
  		l-0.68,0.24l-0.84-0.06l-0.24,0.11l-0.29,0.08l-0.16-0.09l0.18-0.35l0.48-0.53l-0.16-0.37l-0.51,0.12l-0.56-0.03l-0.73,0.09
  		l-0.54,0.11l-0.48,0.03l-0.68-0.17l-0.32-0.68l-1.01-0.77l-0.52-0.21l-0.62-0.1l-0.52-0.19l-0.26,0.11l-0.05,0.37l0.32,0.49
  		l0.11,0.29l0.08,0.37l-1.24-0.62l-0.27,0.05l-0.16,0.23l0.05,0.55l0.13,0.56l-0.26,0.37l-0.54,0.2l-1.04,0.22l-1.14-0.44
  		l-0.41-0.09l-0.94,0l-0.42-0.21l-0.75-0.25l-0.64-0.06l-0.74-0.19l-0.5-0.31l-0.58-0.54l-0.4-0.23l-0.32,0.06l-1.12,0.51
  		l-0.34,0.03l-0.45-0.03l-0.66-0.56l-0.37,0.01l-0.59,0.16l-0.49-0.07l-0.39,0.03l-0.26-0.38l0.41-0.64l0.25-0.26l0-0.24l-0.6-0.29
  		l-0.46-0.07l-0.63,0.03l-0.18,0.46l-0.2,0.22l-0.33-0.06l-0.53-0.03l-0.39,0.34l-0.21-0.05l-0.26,0.12l0.04,0.49l-0.28,0.43
  		l-0.88,0.26l-0.45-0.04l-1.94-0.45l-1.06-0.05l-1.58,0.18l-0.5,0.25l-0.47,0.09l-0.61-0.22l-0.8-0.04l-0.37-0.07l-0.53,0.07
  		l-0.63,0.18l-0.49,0.05l-0.44-0.03l-0.51-0.11l-0.51-0.22l-0.94-0.28l-0.32,0.06l-0.3-0.15l-0.35-0.24l-0.35-0.04l-2.89-0.08
  		l-0.81,0.14l-0.55-0.09l-0.46-0.12l-0.92,0.05l-0.63,0.12l-0.29-0.03l-0.34-0.08l-0.69-0.04l-0.13-0.13l0.22-0.14l0.16-0.26
  		l-0.16-0.26l-0.22-0.08l-0.67-0.07l-0.46,0.02l-0.19,0.17l-0.29,0.16l0.12,0.44l0.42,0.83l0.3,0.35l0.42,0.12l1.55-0.03l0.44,0.06
  		l0.69-0.04l1.36,0.12l0.75,0.17l0.89,0.32l0.59,0.27l0.55,0.14l-0.48,0.1l-0.33-0.02l-0.36,0.04l-0.21,0.17l-0.23,0.09l-1.69,0.13
  		l-0.48,0l-0.26-0.08l-0.33-0.14l-0.5-0.1l-0.44-0.04l-0.38-0.09l-0.68-0.05l-0.7,0.1l-0.11,0.48l0.17,0.35l0.59,0.15l0.61,0.11
  		l0.7,0.23l0.23,0.26l0.44,0.9l0.16,0.48l0.41,0.07l0.26,0.13l0.63-0.19l0.53-0.02l0.22,0.11l0.19,0.53l-0.46,0.58l-0.38,0.18
  		l0.29,0.15l0.27,0.09l0.57-0.06l0.36,0.1l0.8,0.47l0.87,0.23l0.31,0.18l-0.16,0.32l-0.19,0.15l-0.41,0.04l-1-0.07l-1,0.22
  		l-0.69-0.06l-0.44,0.16l-0.6,0.09l-0.48-0.21l-0.59-0.53l-0.14-0.34l-1.92-0.04l-0.63-0.08l-0.55,0.02l-0.86,0.19l-0.47-0.12
  		l-0.83,0.13l-0.51-0.15l-0.9,0.3l-2.2,0.53l-0.57-0.13l-0.6,0.09l-0.97-0.05l-0.81-0.25l-1.03-0.17l-0.81-0.08l-1.3,0.05
  		l-2.01-0.03l-0.46,0.11l-0.19-0.31l0.89-0.66l0.79-0.42l0.42-0.45l0.13-0.63l-0.14-0.61l-0.41-0.28l-0.43-0.07l-0.45-0.14
  		l-0.29-0.15l-0.52,0.05l-0.29-0.07l-0.32,0.31l0.15,0.42l0.05,0.42l-0.17,0.24l0.09,0.29l0.28,0.35l-0.2,0.15l-0.31,0.03
  		l-0.55,0.11l-1.66,0.21l-0.91,0.19l-0.29,0.03l-0.32,0.11h-0.32l0.02-0.26l0.2-0.24l0.15-0.72l0.22-0.09l0.34-0.21l0.48-0.37
  		l-0.22-0.21l-0.34,0.04l-0.2-0.14l0.07-0.5l0.25-0.07l0.15-0.18l-0.03-0.25l-1.51-0.58l-0.5-0.08l-0.3,0.3l-0.36,1.02l-0.21,0.64
  		l-0.21,0.12l-2.19-0.14l-1.15,0.09l-1.33-0.25l-0.97-0.08l-0.57,0.04l-0.27,0.11l-0.4,0.34l-0.72,0.37l-0.46,0.12l-3.37,0.34
  		l-4.24,0.08l-0.76-0.13l-1.88-0.08l-1.86,0.1l-1.15-0.16l-1.52,0.08l-1.95,0.36l-0.54,0.03l-1,0.23l-0.73,0l-1.2-0.23l-1.53-0.05
  		l-0.62-0.16l-0.55,0.09l-1.16,0.06l-0.87,0.16l-0.58,0.01l-0.58-0.09l-0.63-0.19l-0.68-0.3l-0.94-0.01l-1.21,0.27l-0.36,0.26
  		l-0.42,0.72l-0.16,0.38l-0.18,0.08l-0.8-0.03l-0.95-0.28l-2.76,0.31l-0.98,0.19l-1.09,0.71l-0.32,0.15l-0.43,0.18l-0.52,0.08
  		l-0.41,0.09l0.63,0.74l-0.24,0.02l-0.23-0.02l-0.67-0.2l-1.06-0.59l-0.43-0.14l-1.25,0.19l-1,0.07l-1.17,0.62l-0.9,0.37l-0.69,0.17
  		l-1.59,0.04l-0.61,0.49l-0.11,0.3l0.94,0.62l0.39,0.22l0.36,0.31l-0.44,0.07l-0.36-0.02l-1.29-0.32l-0.4-0.07l-1.88-0.7l-0.83-0.1
  		l-0.25,0.05l-0.31,0.19l-0.27,0.26l-0.21,0.13l-0.7,0.15l-0.67,0.2l0.57,0.2l0.53,0.15l0.8,0.13l2.28-0.21l1.02,0.26l1.1,0.57
  		l0.75,0.35l0.14,0.18l-0.02,0.33l0.1,0.26l-0.32,0.22l-0.23,0.06l-0.06,0.15l0.19,0.1l0.2,0.04l0.34,0.22l0.09,0.14l-0.21,0.08
  		l-0.31,0.2l0.02,0.22l0.15,0.03l0.12,0.23l0.09,0.22l-0.14,0.13l-0.72-0.01l-0.57-0.06l-0.97-0.82l-0.51,0.1l-0.42,0.13l-0.22,0.02
  		l-0.3-0.06l-0.84-0.38l-0.68-0.41l-0.37,0.08l-0.11,0.3l-0.01,0.31l0.18,0.23l0.33,0.07l0.54,0.2l0.19,0.19l-0.33,0.34l-1.42,0.35
  		l-0.63,0.28l-0.21,0.23l-0.23,0.09l-0.66-0.1l-0.4-0.15l-1.18-0.61l-1.38-0.57l-0.5-0.05l-1.95,0.12l-0.69-0.1l-0.2-0.2l-0.06-0.2
  		l-0.19-0.14l-0.36-0.18l-1.63-0.38l-0.52-0.03l-0.46,0.05l-1.01-0.13l-0.53,0.03l-0.28,0.11l-0.54,0.3l-0.85,0.11l-0.59-0.04
  		l-0.68-0.58l-0.29,0.05l-0.38,0.25l-0.06,0.76l0.03,0.55l-0.22,0.45l-0.27,0.63l0.17,0.43l0.22,0.25l0.79,0.48l1.05,0.49l1.26-0.05
  		l2.22,0.02l0.55,0.11l0.62-0.02l0.73,0.17l-0.44,0.39l-0.32,0.16l-2.17,0.44l-0.52,0.19l-0.47,0.3l0.64,0.44l1.63,0.88l1.25,0.33
  		l1.81,0.28l1.47,0.07l0.83-0.13l0.19,0.05l0.15,0.31l-0.07,0.2l0.34,0.16l0.48,0.15l0.48,0.31l0.58,0.27l1.01,0.35l2.6,0.46
  		l1.14,0.31l0.44,0.18l0.17,0.33l-0.08,0.21l-0.3,0l-0.25,0.09l0.01,0.17l0.16,0.13l0.04,0.28l-0.24,0.08l-0.57,0.07l-1.47-0.01
  		l-0.48,0.05l-0.68,0.13l-0.39,0.25l-0.25,0.6l0.11,0.23l0.42,0.3l0.27,0.13l1.27,0.32l0.3,0.03l0.09,0.05l0.01,0.28l0.11,0.2
  		l0.29,0.1l0.8,0.08l0.94,0.32l-0.18,0.28l-7.06,0.55l-7.02,0.45l-1.1,0.12l-0.52,0.29l-0.39,0.38l0.98,0.25l2.86,0.58l0.76,0.24
  		l0.46,0.25l0.5,0.33l-0.19,0.28l-0.23,0.2l-0.47,0.25l-0.48,0.32l1.51,1.04l1.58,1.21l-0.7,0.61l-0.76,0.37l-2.4,0.85l-0.56,0.55
  		l-1.04,0.21l-1.77,0.22l-0.91,0.14l-0.28,0.1l0.16,0.26l0.42,0.05l0.59,0.16l-0.72,0.51l-0.49,0.16l-3.15,0.75l-0.87-0.22
  		l-1.21-0.02l-2.89,0.14l-0.68-0.5l-0.61-0.29l-0.36-0.08l-1.12,0.2l-0.59,0.24l-0.7,0.2l-0.36-0.25l-0.49-0.56l-1.27-0.34
  		l-1.08-0.07l-2.72,0.08l-0.6,0.04l-0.25-0.06l0.26,5.85l0,1.25l0.05,0.03l1.22,27.92h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54
  		h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54
  		h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54
  		h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54
  		h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54
  		h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54
  		h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54
  		h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54
  		h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54
  		h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54
  		h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54h2.54l0.15-28.17
  		l-5.48-0.68L2221.62,1292.94z M2193.06,1248.7l0.34,0.22l0.24-0.07l0.03-0.13l0.18-0.19l-0.13-0.19l-0.19-0.47l-0.08-0.01
  		l-0.12,0.09l-0.05,0.12l0.03,0.14l-0.32,0.42L2193.06,1248.7z M2190.89,1263.88l0.35-0.01l0.43-0.11l0.65-0.36l0.43-0.14
  		l-0.14-0.27l-1.49-0.26l-1.79,0.03l-0.68-0.28l0-0.05l0.04-0.14l-0.22-0.32l-0.49-0.09l-0.38,0.11l-0.17,0.24l0.1,0.14l0.25,0.18
  		l0.03,0.16l-0.3,0.26l-0.14,0.19l-0.29,0.12l0.04,0.09l0.53,0.59l0.21,0.29l0.14,0.3l0.53-0.37l0.46-0.21l0.2-0.21l0.95-0.02
  		L2190.89,1263.88z M2182.62,1252.78l-0.01,0.08l0.05,0.25l0.15,0.11h0.24l0.15-0.29l0.07-0.07l0.2-0.45l-0.37,0.08L2182.62,1252.78
  		z M2232.89,1287.19l-0.34-0.12l-0.34-0.06l-0.41-0.17l-0.77-0.25l-2.87-0.69l-1.43-0.43l-0.55,0.07l-0.48,0.14l-0.36-0.04
  		l-0.47,0.15l-0.4,0l-2.1-0.55l0.12,0.24l0.19,0.24l4.56,1.38l0.63,0.24l3.65,0.89l0.69,0.37l0.52,0.38l0.27,0.12L2232.89,1287.19z
  		 M2181,1255.89l-0.22,0.11l-0.23,0.26l0.13,0.09l0.23,0.02l0.32-0.48l-0.09-0.04L2181,1255.89z M1777.12,1232.81l0.23,0.05
  		l0.07-0.09l-0.09-0.14l-0.2-0.11l-0.24-0.02l-0.12,0.09l0.22,0.15L1777.12,1232.81z M1828.23,1271.86l0.11-0.01l0.2-0.02l0.31-0.14
  		l0.31-0.14l0.05-0.01l0.03,0l0.03-0.09l0.01-0.05l0,0v0l-0.33-0.13l-0.07-0.03l-0.41,0.02l-0.15,0.16l-0.07,0.08l-0.15,0.27h0l0,0
  		L1828.23,1271.86z M2010.94,1243.68l-0.18,0.29l-0.24,0.24l-0.05,0.14l0.1,0l0.38-0.08l0.27-0.16l0.04-0.21l-0.2-0.26
  		L2010.94,1243.68z M1776.21,1236.25l-0.11,0.12l-0.03,0.13l0.05,0.06l0.13,0.09l0.27-0.01l0.09-0.07l-0.08-0.23L1776.21,1236.25z
  		 M1775.34,1235.15l0.04-0.23l-0.04-0.15l-0.02-0.06l-0.29-0.24l-0.12-0.12l-0.14-0.15l-0.22-0.12l-0.37-0.09h0l-0.13,0.03
  		l-0.09,0.08v0l0,0l0.06,0.17l0.06,0.17l0.51,0.66l0.08,0.45l0.16,0.3l0.12-0.02l0.04-0.01l0.05-0.1l0.11-0.33v0l-0.01-0.17
  		l0.07-0.02L1775.34,1235.15z M2012.87,1243.06l-0.08,0.16l0.09,0.26l0.09,0.01l0.23-0.44l-0.04-0.03l-0.18-0.05L2012.87,1243.06z
  		 M1831,1272.41l-0.24-0.08l-0.88-0.63l-0.14-0.04l-0.58,0.4l0,0l0.32,0.48l0.3,0.11l0.11,0.04l1.35,0.21l0.34,0.13l0.42,0.17
  		l0.71-0.31l0.14-0.01l0.28-0.03l0.14,0.02l0.13-0.06l0.06-0.03h0h0l-0.09-0.07l-0.37-0.28l-1.43-0.37l-0.22-0.35l-0.1,0.21
  		l-0.05,0.11v0l0.01,0.04l0.03,0.15l-0.15,0.11L1831,1272.41z M1619.12,1260.39l-0.11-0.1l-0.5-0.09l-0.48,0.04l-0.73-0.05
  		l-0.25,0.15l0.42,0.23l0.46,0.1l0.31-0.02l0.26-0.15l0.54-0.03L1619.12,1260.39z M1648.54,1251.98l0.13,0.09l0.2,0.1l0.44,0.04
  		l0.76,0.18l0.2-0.08l0.18-0.25l0.02-0.21l-0.04-0.15l-0.46,0l-0.59-0.17l-0.06-0.13l-0.3-0.02l-0.32,0.09l-0.41,0.27l0.2,0.14
  		L1648.54,1251.98z M1591.28,1285.5L1591.28,1285.5l0.06,0.1l0.13,0.1l0.06,0.11l0,0.11l0,0.07l0.09,0.09l0.37,0.15l0.19,0.17
  		l0.15,0.07l0.2,0.09l0.33,0.05l1.01,0.15l0.56-0.1l0.3-0.35l0.35-0.07h0l0,0l-1.27-0.47l-0.13-0.11l-0.52-0.11l-0.19-0.04
  		l-0.46-0.03l-1.34-0.07l0,0h0L1591.28,1285.5z M1697.25,1247.18l0.31,0.26l0.4,0.04l0.22-0.16l-0.78-0.78l-0.2,0.09l-0.09,0.09
  		l0.02,0.12L1697.25,1247.18z M1675.26,1250.93l0.89-0.1l0.3-0.14l0.1-0.11l-0.04-0.04l-0.13-0.05l0.04-0.05l0.24-0.13l0.54-0.38
  		l-0.09-0.05l-0.32-0.11l-1.8,0.77l-0.04,0.14l0.06,0.14L1675.26,1250.93z M1655.68,1251.94l0.13,0.04l0.15,0.19l0.18,0.11
  		l0.54,0.24l0.27-0.06l0.24-0.16l0.16-0.35l-0.15-0.2l-0.46-0.07l-0.66-0.26l-0.34-0.07l-0.16,0.05l0.07,0.5L1655.68,1251.94z
  		 M1657.02,1248.72l0.18,0.06l0,0.45l0.04,0.04l0.16,0.03l0.21-0.03l0.19-0.17l0.11-0.07l0.23-0.01l0.23,0.06l0.08,0.04l0.08,0.07
  		l0.06,0.16l-0.01,0.23l0.41,0.28l0.65,0.22l0.37,0.29l0.24,0.1l0.23,0.03l0.6,0.4l0.39,0.09l1.22,0.06l0.39,0.11l0.08,0l0.18-0.11
  		l0-0.2l0.05-0.18l0.02-0.15l-0.09-0.07l-0.15-0.17l-0.36-0.13l-0.04-0.09l0.09-0.13l0.11-0.09l-0.16-0.16l-0.74-0.33l-0.14-0.02
  		l-0.14,0.05l-0.54,0.28l-0.21-0.01l-0.21-0.09l-0.18-0.02l-0.41,0.09l-0.22,0.01l-0.22-0.07l-0.11-0.08l-0.01-0.11l0.06-0.11
  		l0.19-0.05l0.2,0.03l0.61-0.07l0.02-0.08l-0.11-0.17l-0.32-0.19l-0.1-0.09l-0.12-0.29l-0.2-0.17l-0.22-0.06l-0.22-0.11l-0.16-0.15
  		l-0.48-0.1l-0.48-0.03l-0.42,0.04l-0.27,0.06l-0.26-0.05l-0.26,0.03l-0.23,0.27l-0.06,0.23l0.03,0.24L1657.02,1248.72z
  		 M1670.62,1250.34l-0.2,0.19l0.03,0.06l0.12,0.08l0.11,0.01l0.38-0.04l0.18-0.12l0.7-0.28l0.12-0.11l0.2-0.31l-0.06-0.15
  		l-0.09-0.09l-0.46-0.11l-0.54-0.01l-0.28,0.12l-0.24,0.02l0.25,0.62l-0.06,0.06L1670.62,1250.34z M1723.28,1232.84l0.02,0.03
  		l0.01,0.01l0.01,0.01h0.01l0.01,0l0.03-0.01l0.03-0.01l0.03,0l0.04,0l0.02,0l0.01,0l0.01-0.01l0.01-0.04l0.01-0.04l-0.01-0.02
  		l0-0.03l0-0.02l0-0.02v-0.01l-0.02-0.03l-0.02-0.03l-0.02-0.01l-0.02,0l-0.03,0l-0.02,0l-0.03,0l-0.02,0.01l-0.01,0.01l0,0.02
  		l-0.01,0.03l-0.01,0.03v0.03l0,0.02l-0.02,0.02l-0.01,0.04l0.01,0.03L1723.28,1232.84z M1702.72,1243.95l0.21,0.02l0.21-0.04
  		l0.33-0.01l2.25,0.35l0.29-0.02l0.16,0.05l0.16,0.09l0.62,0.23l0.43,0.09l0.52,0.22l0.48,0l0.43,0.06l0.42,0.2l0.44,0.03l0.61,0
  		l0.42-0.03l0.42-0.09l0.21,0l0.4,0.18l0.2,0.02l0.2-0.07l0.2-0.04l1.16,0.1l0.04-0.08l0.03-0.1l-0.12-0.24l0.46-0.05l0.45-0.1
  		l0.08-0.55l-0.14-0.25l0-0.36l-0.14-0.04l-0.4,0.22l-1.04,0.29l-0.42,0.16l-0.15,0.01l-0.17-0.04l-0.16-0.09l0.48-0.31l0.75-0.29
  		l0.31-0.51l-0.47-0.2l-0.88,0.05l-0.4,0.31l-0.12,0.34l-0.07,0.15l-0.08,0.14l-0.19,0.19l-0.21,0l-0.12-0.13l-0.1-0.18l0.2-0.32
  		l-0.2-0.4l-0.42,0.12l-0.19,0.67l-0.44,0.02l-0.01-0.35l0.15-0.36l-0.18-0.31l-0.37-0.13l-0.4-0.06l-0.63,0.3l-0.21,0.26
  		l-0.31,0.13l-0.56-0.09l-0.31,0.3l-0.09,0.04l-0.09,0.01l-0.27-0.36l-0.18-0.35l-0.24-0.01l-0.33,0.11l-3.12,0.4l-0.2,0.08
  		l-0.09,0.08l-0.05,0.17l0.09,0.18L1702.72,1243.95z M1749.05,1240.37l0.17,0.05l0.17,0l2.47-0.4l0.54-0.13l0.54-0.16l0.92-0.43
  		l0.26-0.23l-0.28-0.29l-0.31-0.19l-0.14-0.06l-0.15-0.03l-0.13,0.08l0,0.26l-0.2-0.13l-0.32-0.13l-0.1,0.04l-0.02,0.46l-0.11,0.14
  		l-0.11,0.07l-0.36-0.53l-0.2-0.13l-0.1,0l-0.1,0.06l-0.05,0.14l-0.04,0.18l-0.12,0.15l-0.15,0.07l-1.49,0.21l-0.39,0.09l-0.23,0.1
  		l-0.22,0.16l-0.02,0.16l0.16,0.33L1749.05,1240.37z M1721.93,1246.29l0.05,0.14l-0.17,0.2l-0.11,0.79l0.3,0.04l0.33-0.09l0.29-0.16
  		l0.19-0.18l0.21-0.31l0.04-0.26l-0.05-0.22l-0.22-0.1l0.21-0.32l0.03-0.17l-0.08-0.24l-0.25-0.19l-0.64-0.03l-0.56,0.16l-0.1,0.1
  		l0.21,0.45L1721.93,1246.29z M1715.35,1245.59l1.09-0.18l-0.01-0.08l-0.14-0.33l-0.04-0.05l-0.05-0.03l-0.34,0.17l-0.84,0.28
  		l-0.1,0.16l0.1,0.08L1715.35,1245.59z M1717.27,1246.47l0.31,0l0.07-0.04l-0.08-0.09l-0.37-0.21l-0.08,0.01l-0.12,0.13l0.06,0.08
  		L1717.27,1246.47z M1664.78,1249.67l0.08,0.02l0.07,0.08l0.59,0.35l-0.15,0.1l-0.31,0.37l0.01,0.15l0.15,0.17l0.05,0.1l-0.23,0.03
  		l-0.12,0.05l0.03,0.22l0.14,0.14l1.03,0.22l2.21-0.23l0.11-0.04l0-0.12l-0.06-0.05l-0.03-0.07l0.06-0.31l0.05-0.06l1.29-0.59
  		l-0.07-0.24l-0.12-0.23l-0.32-0.35l-0.3-0.01l-1.4-0.07l-0.85-0.07l-0.85-0.11l-0.86-0.01l-0.18-0.02l-0.18,0.03l-0.25,0.2
  		l-0.08,0.23l-0.1,0.14l0.28,0.02L1664.78,1249.67z M1599.97,1280.89L1599.97,1280.89l0.28,0.25l0.15,0.12l0.26,0.21l0.21,0.14
  		l0.5,0.34l0.07,0.12l0.07,0.11l0.16-0.03l0.11-0.13l0.16-0.19l-0.77-0.37l-0.12-0.15l-0.04-0.05l-1.02-0.53l-0.04,0l-0.09,0l0,0h0
  		L1599.97,1280.89z M1615.23,1262.69l0.14-0.02l0.07-0.08l-0.26-0.05l-0.94-0.44l-0.35,0.02l-0.53,0.18l0.3,0.09L1615.23,1262.69z
  		 M1647.88,1251.81l0.05-0.11l-0.34-0.08l-0.5,0.12l-0.05,0.14l0.56,0.13L1647.88,1251.81z M1597.15,1279.8l-0.24-0.19l-0.58-0.25
  		l-1.12-0.29l-1.62-0.36l-1.34-0.14l-1.21,0.18l-0.07,0.08l-0.04,0.04l-0.01,0.05l-0.03,0.12l0.02,0.06v0l0.02,0.02l0.07,0.06
  		l0.16,0.06l0.07,0.03l0.19,0.03l0.74,0.13l4.84,0.5l0.19-0.04l0,0h0L1597.15,1279.8z M1590.4,1270.38l0.15,0.09l0.31,0.18
  		l0.47,0.22l0.6,0.21l0.11,0.04l2.62,0.81l0.86,0.2l1.06,0.25l0.26,0.04l0.65,0.1l2.26-0.15l0.08-0.11l0.02-0.19l-0.1-0.17
  		l-0.12-0.08l-0.07-0.09l-0.06-0.07l-0.09-0.13l-0.03-0.05v0l-0.01-0.01l-0.19-0.18l-0.05-0.08l-0.04-0.07l-0.26-0.23l-0.22-0.2
  		l-0.5-0.32l-0.52-0.21l-0.93-0.57l-0.46-0.25l-0.48-0.26l-0.65-0.43l-0.93-0.43l-0.41-0.13l-0.02,0l-0.4-0.07l-0.45-0.07
  		l-0.18-0.01l-0.27-0.01l-0.24,0.01l-0.16,0.23l-0.57,0.3l-0.28,0.29l-0.31,0.15l-0.25,0.12l-0.04,0.07l-0.09,0.16l-0.08,0.35
  		l-0.04,0.26l-0.03,0.17l0,0v0l0.07,0.2L1590.4,1270.38z M1750.14,1236.99l0.07,0.04l0.83,0.17l0.76,0.1l0.32-0.15l0.17-0.43
  		l0.21-0.09l0.04-0.07l-0.05-0.11l-0.15-0.18l-0.47-0.35l-0.32-0.08l-0.27,0.04l-0.08-0.02l-0.15,0.05l-0.09,0.22l-0.05,0.08
  		l-0.62,0.13l-0.14,0.11l-0.1,0.22l0.02,0.18L1750.14,1236.99z M1614.9,1260.45l0.76-0.05l0.27-0.16l-0.03-0.06l-0.13-0.1
  		l-1.19,0.09l-0.06,0.09l0.08,0.09L1614.9,1260.45z M1624.14,1256.35l0.35,0.09l0.2-0.02l-0.03-0.1l-0.11-0.15l-0.18-0.2l-0.62-0.27
  		l-0.24-0.03l-0.33,0.1l-0.14,0.15l0.2,0.14L1624.14,1256.35z M1620.88,1258.04l0.35,0.19l0.46,0.24l0.37-0.05l-0.47-0.55
  		l-0.74-0.13l-0.1,0.04l-0.01,0.06l0.02,0.05L1620.88,1258.04z M1618.84,1261.25l0.06-0.08l-0.12-0.09l-0.35-0.07l-0.96-0.13
  		l-0.12,0.06l0.1,0.29l0.42,0.1l0.76-0.05L1618.84,1261.25z M1617.44,1262.46l-0.23-0.13l-0.26-0.02l-0.26,0.1l0.4,0.27l0.92,0.06
  		l-0.52-0.19L1617.44,1262.46z M1615.23,1260.97l-0.37,0.09l-0.2,0.13l0.1,0.09l0.49,0.27l1.09,0.09l0.07,0.06l0.16,0.05l0.79,0.03
  		l0.11-0.06l-0.1-0.1l-0.23-0.05l-0.05-0.11l-0.12-0.18l-0.43-0.28l-0.09-0.14l-0.15-0.05l-0.94,0.09L1615.23,1260.97z"/>
    </>,
    AGO: <>
        <path className="st0" d="M1911.7,1362.16l0.56,1.07l0.25,1.2l-0.22,0.59l-0.21,0.79l0.37,0.83l0.12,0.22l0.35-0.1l1.1-0.16l0.83-0.08
  		l0.16-0.19l0.13-2.28l0.03-2.31l-0.29-0.3l-0.29-0.19l-0.01-0.16l0.43-0.29l0.6-0.34l0.19-0.15l0.66-0.61l1.3-1.42l0.99-0.35
  		l0.93-0.37l0.13-0.14l-0.21-0.13l-0.65-0.57l-0.76-0.89l-0.28-0.14l-0.42,0.01l-0.66,0.33l-0.65,0.51l-1.18,0.47l-0.98,0.27
  		l-0.09,0.32l-0.23,0.56l-0.33,0.35l-0.87,0.11l-0.31,0.5l-0.75,0.96l-0.5,0.44l0.18,0.26L1911.7,1362.16z M2012.19,1414.22
  		l-0.13-0.5l-0.13-1.11l-0.18-1.54l-0.18-1.11l-0.32,0.17l-0.18,0.44l-0.05,0.34l-0.57,0.26l-1.16-0.05l-1.15-0.25l-0.81-0.08
  		l-0.54,0.06l-2.05,0.83l-0.68,0.11l-2.2-0.06l-1.25-0.17l-0.89-0.03l-0.63,0.26l-0.78,0.62l-0.66,0.33l-0.3-0.04l-0.19-0.26
  		l-0.26-0.35l-0.08-0.93l-0.33-1.02l0.22-0.53l0.65-0.39l0.22-0.78l-0.2-1.18l-0.01-0.83l0.18-0.48l-0.24-1.16l-0.65-1.85l-0.91-1.5
  		l-1.18-1.16l-0.77-1.11l-0.37-1.06l0.14-2.53l0.36-2.23l0.28-1.77l-0.08-2.97l-0.8-1.93l-0.18-2.08l0.45-2.22l0.07-1.52l-0.3-0.78
  		l-0.21-0.12l-0.26-0.08l-2.02-0.07l-2.7-0.1l-2.35-0.03l-2.55-0.03l-0.42-0.28l-0.19-0.52l0.01-0.51l0.52-1.57l-0.07-0.13
  		l-0.91-0.03l-2.46,0.26l-1.62,0.25l-1.03,0.08l-1.81,0.43l-1.12,0.9l-0.37,1.14l0.03,0.94l-0.06,0.69l-0.51,0.72l-0.4,0.82
  		l-0.02,0.43l-0.24,2.19l-1.67,0.29h-1.67l-0.39-0.03l-2.06-0.52l-0.76,0l-0.66,0.27l-1.26,0.27l-1.21,0.2l-1.22,0.65l-0.32,0.06
  		l-0.81-0.34l-1.13,0.03l-1.14,0.16l-0.54,0.07l-0.37-0.2l-1.05-1.63l-1.4-2.18l-0.76-1.36l-0.28-0.36l-0.49-0.47l-0.67-0.89
  		l-0.28-0.84l0.12-0.8l-0.39-1.08l-0.9-1.36l-0.59-1.3l-0.28-1.23l-0.07-1.06l0.14-0.88l-0.17-0.65l-0.49-0.42l-0.27-0.53
  		l-0.19-0.22l-0.4-0.5l-0.89-0.55l-0.98-0.29l-2.15-0.01l-2.81-0.01l-2.54,0.04l-2.83,0.05l-2.86,0.05l-0.77,0.07l-2.18,0.03
  		l-1.75-0.14l-0.65-0.09l-1.14-0.07l-1.8-0.02l-0.97,0.05l-2.34,0l-0.21,0.01l-0.37,0.16l-1-0.21l-0.98,0.07l-0.49,0.36l-1.85,0.81
  		l-2,0.35l-1.46,0.32l-0.66,0.07l-0.16,0.27l0.43,0.53l0.57,1.4l1,1.99l2.55,3.06l0.33,2.33l1.92,4.61l2.42,4.96l-0.1,0.84
  		l0.01,0.72l0.09,0.59l-0.16,0.53l-2.57,2.7l-0.06-0.27l0.26-0.34l0.13-0.29l-0.39,0.19l-0.41,0.58v0.48l0.65,1.54l0.67,1.34
  		l0.35,1.36l0.11,1.29l0.66,1.04l0.38,1.45l1.37,2.18l0.37,1.38l0.79,0.77l0.74,1.03l0.15,1.05l0.8,1.46l0.12,1.06l-0.53,3.67
  		l0.01,2.75l-0.84,2.64l-0.74,1.38l-1.52,1.99l-2.14,1.12l-1.51,1.05l-0.72,2.14l-2.92,3.49l-0.39,2.71l-1.05,2.42l-0.83,5.11
  		l-1.75,5.23l-0.48,2.27l-0.41,1.03l-0.57,0.74l-0.42,0.42l-0.83,0.55l0.16,0.72l0.23,0.61l0.19,4.45l-0.01,1.72l-0.33,1.44
  		l-0.31,3.26l1.34-0.2l0.94-0.5l0.85-0.03l0.83,0.39l0.89,0.03l0.34-0.06l1.59,0.06l0.91-0.45l1.08-0.45l0.62-0.39l0.88-0.41
  		l1.16-0.41l0.66,0.03l0.81,0.15l1.08,0.16l0.61,0.28l0.72,0.87l1.12,0.8l0.82,0.47l0.94,0.62l0.28,0.24l0.42,0.13l0.25,0.04
  		l1.76-0.1l1.59-0.09l1.71,0l3.22,0.01l3.22,0l3.22,0l3.22,0.01l3.22,0l3.22,0.01l3.22,0.01l3.22,0.01l1.32,0l2.3,0.03l2.42,0.03
  		l0.27,0.05l0.27,0.17l0.22,0.16l0.86,1.1l1.09,1.15l0.91,0.54l1.09,0.32l1.02,0.12l0.95-0.08l1.58,0.15l2.21,0.37l2.29,0.11
  		l2.38-0.15l1.67,0.21l0.97,0.56l0.99,0.38l1.01,0.2l1.37-0.12l1.73-0.43l1.47,0.06l0.68,0.32l0.41,0.01l2.54-0.46l2.04-0.37
  		l3.06-0.58l2.53-0.48l3.74-0.72l2.63-0.5l-1.68-1.43l-1.9-1.63l-1.97-1.81l-1.48-1.42l-0.73-0.82l-1.3-1.08l-0.94-0.53l-0.36-0.27
  		l-0.93-2.87l-0.51-2.64v-1.99l0-2.75l0-2.75l0-2.75l0-2.74l0-2.74l0-2.74v-2.74l0-2.73l0-1.33h1.94h2.2h2.3h2.5h2.5h2.5h1.75
  		l0.46-0.02l0.55-0.08l0.04-0.27l-0.72-1.34l0.03-0.47l0.19-0.91l0.3-0.79l0.39-1.03l0.04-0.61l-0.32-1.98l0.03-1.1l0.09-1.15
  		l0.09-1.09l-0.11-0.76l0.13-0.41l0.23-0.59l0.12-0.67l0.15-0.29L2012.19,1414.22z"/>
    </>,
    ARM: <>
        <path className="st0" d="M2192.75,958.17v0.41l0.3,0.07l0.32-0.16l0.08-0.33l-0.24-0.15l-0.25-0.01L2192.75,958.17z M2201.15,972.52
  		l0.61-0.03l0.29-0.22l-0.66-0.74l-0.58-0.49l-0.5-0.33l-0.11-0.2l0-0.14l0.11-0.17l0.85-0.41l0.08-0.37l-0.06-0.42l-1.35-0.61
  		l-1.01,0.23l-0.9-0.69l-0.58-0.53l-0.72-0.57l-0.65-0.31l-0.62-0.72l-1.08-0.74l-0.69-0.21l0.01-0.11l0.13-0.14l0.29-0.11
  		l1.92,0.03l0.23-0.13l0.12-0.32l0.26-0.47l0.31-0.7l-0.02-0.59l-1.93-0.95l-1.4-0.87l-0.97-1.15l-0.66-1.05l0.02-0.35l0.19-0.33
  		l1.5-0.97l0.1-0.25l-0.03-0.17l-0.53-0.5l-0.67-0.51l-0.21-0.38l-0.42-0.19l-0.8-0.01l-1.41-0.63l-0.3-0.06l-0.07-0.13l0.07-0.13
  		l1.01-0.26l-0.01-0.21l-0.31-0.28l-0.57-0.2l-0.52-0.5l-0.18-0.45l-0.21,0.13l-1.39,0.18l0,0.11l0.32,0.28l-0.06,0.09l-2.33,0.03
  		l-0.77,0.17l-2.07-0.22l-0.68,0.1l-0.58,0.21l-1.42,0.24l-0.98,0.28l-1.25,0.14l-1.29,0.01l-0.44,0.08l0.13,0.42l0.52,0.6
  		l0.63,0.37l0.34,0.39l0.55,1.35l0.22,0.74l-0.08,0.72l-0.38,0.73l-0.83,0.91l0.21,0.38l0.19,0.5l-0.06,0.36l0.59,1.16l0.27,0.72
  		l-0.22,0.17l-0.14,0.23l1.06,0.56l1.27,0.47l0.53,0.09l1.45-0.21l0.94-0.05l0.93,0.44l1.35,1.07l1.46,1.39l0.29,0.42l0.83-0.15
  		l1.39-0.45l0.38,0.22l0.41,0.46l0.2,0.39l0.04,0.73l0.17,0.12l0.67-0.25l0.3,0.29l0.52,0.35l0.9,0.35l1.3-0.55l0.65-0.14l0.53,0.01
  		l0.29,0.17l0.1,0.57l-0.1,0.7l-0.15,0.38l0.27,0.28l1.06,0.67l0.44,0.37l-0.22,0.65l0.79,1.58l0.27,0.61l0.31,0.76l0.47,0.09
  		l1.24-0.43l1.46,0.06l-0.03-0.89l0.03-0.71l-0.12-0.41l-0.62-0.56l-0.01-0.24l0.17-0.15L2201.15,972.52z M2188.91,954.39
  		l-0.18,0.11l-0.28-0.11l-0.09-0.25l0.02-0.27l0.28-0.06l0.23,0.08l0.06,0.24L2188.91,954.39z"/>
    </>,
    ALB: <>
        <polygon className="st1" points="1986.47,957.48 1986.2,956.9 1986.27,956.16 1986.22,955.94 1986.01,955.63 1985.48,955.48
  		1984.39,955.61 1984.12,955.37 1983.68,954.04 1983.33,953.83 1982.93,953.38 1982.27,951.93 1982.25,951.3 1982.3,950.74
  		1981.93,949.44 1982.16,949.11 1982.5,948.9 1982.5,948.37 1982.41,947.58 1982.81,946.01 1982.92,945.89 1983.05,945.45
  		1983,944.49 1982.55,942.89 1982.24,942.36 1981.59,941.84 1981.08,941.5 1980.18,941.19 1979.72,940.32 1979.02,939.31
  		1978.69,939.08 1978.54,939.06 1977.64,939.5 1976.97,939.7 1976.37,939.81 1976.08,939.6 1975.94,939.31 1975.97,938.48
  		1975.86,938.2 1975.65,938.06 1975.24,938.26 1974.76,938.9 1974.31,939.65 1973.65,940.42 1973.09,941.17 1972.75,941.71
  		1972.79,941.69 1973.03,942.32 1973.53,943.04 1973.78,943.53 1973.66,943.82 1973.05,943.73 1972.6,943.52 1972.77,943.92
  		1972.69,944.38 1972.77,944.64 1972.64,945.44 1972.61,945.94 1973.67,946.07 1974.59,946.76 1974.58,948.24 1974.33,948.68
  		1973.92,949.02 1973.44,950.41 1973.55,951.45 1973.77,952.29 1973.57,953.6 1973.61,955.33 1972.96,956.75 1972.57,958.01
  		1972.63,958.43 1973.43,959.94 1973.6,960.59 1973.44,960.88 1973.05,960.7 1972.75,960.55 1972.44,960.57 1972.76,961.16
  		1973.08,961.78 1973.81,962.53 1976.9,964.18 1977.85,965.87 1978.11,966.58 1978.16,967.48 1978.34,967.47 1978.65,967.58
  		1979.25,967.95 1979.89,968.03 1980.24,967.79 1980.44,967.56 1980.57,967.19 1980.63,966.76 1980.73,966.62 1981.22,966.67
  		1981.37,966.56 1981.37,966.17 1981.05,965.69 1980.77,965.09 1980.77,964.81 1981,964.7 1981.38,964.44 1981.59,964.12
  		1981.99,963.96 1982.59,963.93 1983.26,963.79 1983.69,963.44 1983.75,963.1 1984.02,962.16 1984.2,961.71 1984.48,961.29
  		1984.64,960.72 1984.94,960.19 1985.58,959.96 1986.15,959.7 1986.59,959.01 1986.83,958.43 1986.84,958.07 	"/>
    </>,
    AIA: <>
        <polygon className="st1"
                 points="1278.87,1164.07 1277.79,1164.67 1277.74,1164.92 1279.07,1164.48 1279.26,1164.11 	"/>
    </>,
    ATG: <>
        <path className="st0" d="M1289.51,1170.3l0.13-0.23l-0.02-0.75l-0.23-0.25l-0.36-0.06l-0.27-0.15l-0.12,0.08l-0.02,0.16l0.21,0.77
  		L1289.51,1170.3z M1289.96,1174.12l-0.26-0.29l-0.66-0.26l-0.59,0.61l0.04,0.3l0.19,0.43l0.94,0.14l0.27-0.34l0.18-0.1l0.07-0.18
  		l0-0.25L1289.96,1174.12z"/>
    </>,
    AFG: <>
        <polygon className="st1" points="2440.34,991.6 2439.96,991.17 2439.12,990.48 2438.52,990.12 2437.38,990.24 2436.71,990.11
  		2435.91,989.89 2435.15,989.92 2434.68,990.34 2434.37,990.75 2433.62,990.88 2432.53,991.2 2430.86,991.7 2430.05,991.62
  		2429.83,991.41 2430.01,991.12 2430.58,990.75 2430.72,990.3 2430.61,989.89 2430.08,989.77 2429.87,989.71 2429.64,989.62
  		2428.6,989.37 2427.77,989.47 2426.32,989.99 2423.66,991.35 2422.5,992.27 2421.66,993.66 2419.14,994.1 2417.41,994.9
  		2415.63,996.19 2414.45,996.88 2413.88,996.98 2413.3,996.86 2412.73,996.51 2412.17,995.43 2411.67,993.79 2411.34,992.7
  		2411.53,991.31 2411.74,989.72 2411.95,988.1 2412.3,986.23 2412.59,985.56 2412.6,985.12 2412.35,984.89 2411.81,984.91
  		2410.98,985.15 2410.39,985.2 2410.04,985.04 2410.08,984.17 2410.5,982.58 2409.85,981.25 2408.14,980.17 2406.68,979.79
  		2405.47,980.12 2404.46,980.98 2403.64,982.37 2402.79,983.51 2401.91,984.39 2401.29,984.81 2401.08,984.98 2400.95,985.35
  		2401.42,986.52 2401.39,987.51 2400.86,988.3 2400.28,988.68 2399.65,988.64 2399.15,988.46 2398.78,988.13 2397.77,988.04
  		2396.12,988.19 2395,988.59 2394.39,989.23 2394.22,990.08 2394.47,991.13 2394.34,991.93 2393.83,992.49 2393.41,992.81
  		2393.08,992.89 2392.37,992.41 2391.27,991.36 2390.52,990.79 2390.11,990.71 2389.89,990.77 2389.63,990.87 2389.49,991.01
  		2389.37,991.32 2389.02,991.44 2388.52,991.35 2388.07,991.44 2387.8,991.77 2387.03,992.16 2385.69,992.61 2384.95,993.08
  		2384.83,993.59 2384.63,993.81 2384.22,993.73 2383,994.42 2382.08,994.21 2381.04,993.32 2380.46,992.58 2380.4,992.27
  		2380.35,992.01 2379.9,991.74 2379.12,991.79 2378.61,991.66 2378.36,991.36 2377.73,991.44 2376.7,991.91 2375.65,991.66
  		2374.59,990.7 2372.56,990.35 2369.98,990.57 2369.56,990.6 2368.54,990.38 2366.5,989.93 2364.75,989.02 2363.61,988.43
  		2363.43,988.51 2362.92,988.91 2362.56,989.41 2362.28,990.37 2361.84,991.51 2359.72,991.55 2357.92,991.64 2356.75,992.07
  		2355.62,992.66 2355.33,993.36 2355.08,994.28 2354.42,996.35 2353.82,998.23 2353.51,999.45 2353.04,1000.29 2351.76,1001.39
  		2350.29,1002.13 2349.52,1002.47 2349.17,1002.9 2349.1,1003.31 2348.82,1003.44 2348.22,1003.36 2347.58,1003.43 2346.18,1003.86
  		2344.67,1004.38 2342.86,1004.91 2341.82,1004.91 2341.41,1005.02 2341.23,1005.29 2341.41,1005.78 2341.59,1006.15
  		2341.75,1006.63 2341.32,1007.02 2341.03,1007.68 2340.79,1008.85 2340.15,1009.2 2339.12,1009.77 2337.98,1010.51
  		2337.69,1010.66 2337.04,1010.88 2336.39,1010.81 2335.79,1010.7 2335.15,1010.89 2334.49,1011.47 2334.18,1011.29
  		2334.02,1010.71 2333.69,1010.33 2332.65,1009.48 2331.76,1008.87 2331.37,1008.83 2330.56,1008.99 2329.53,1009.1 2328.7,1008.98
  		2328.04,1008.73 2327.03,1007.9 2326.65,1007.44 2326.38,1007.1 2325.68,1007.19 2325.85,1007.82 2325.82,1008.2 2325.54,1008.58
  		2325.37,1009.05 2325.15,1009.65 2325.07,1010.12 2324.65,1010.35 2324.32,1010.5 2324.37,1011.1 2324.54,1011.6 2324.73,1012.2
  		2324.51,1012.61 2324.37,1013.08 2324.06,1013.84 2324.15,1014.46 2323.81,1015 2323.4,1015.47 2323.12,1015.85 2323.06,1016.38
  		2322.75,1016.57 2322.17,1017.01 2321.81,1017.32 2321.28,1017.41 2321.17,1017.66 2321.25,1017.91 2321.47,1018.07
  		2321.82,1018.61 2322.54,1019.55 2320.46,1019.66 2319.85,1020.49 2319.14,1021.67 2319.49,1024.05 2319.14,1025.28
  		2319.35,1025.96 2319.88,1026.44 2320.56,1026.7 2321.84,1026.72 2322.69,1026.9 2322.77,1027.22 2322.29,1027.68 2321.51,1028.55
  		2321.1,1028.93 2319.77,1030.67 2319.78,1031.41 2319.91,1032.01 2320.48,1033.89 2321.03,1035.7 2322.04,1038.97 2322.02,1039.73
  		2321.7,1041.41 2321.68,1042.44 2321.82,1043.76 2321.72,1044.45 2321.96,1045.98 2322.24,1046.09 2324.41,1046.39
  		2326.39,1046.66 2329.03,1047.03 2329.83,1047.93 2330.33,1049.89 2330.3,1051.37 2330.08,1052.12 2328.18,1054.26
  		2326.27,1056.43 2324.35,1058.59 2322.15,1061.07 2325.36,1062.07 2327.86,1062.84 2331.9,1064.08 2335.04,1065.04
  		2335.91,1065.19 2345.1,1064.37 2348.5,1065 2349.57,1065.34 2349.73,1065.14 2350.19,1064.72 2350.98,1064.29 2352.06,1063.95
  		2353.13,1063.77 2354.67,1063.74 2355.71,1063.77 2356.48,1063.87 2357.97,1063.81 2358.68,1063.64 2361.13,1062.97
  		2362.77,1062.51 2365.26,1061.8 2367.08,1061.28 2367.53,1061.01 2368,1060.51 2368.22,1060.06 2367.67,1059.37 2367.59,1058.77
  		2367.96,1058 2368.16,1056.82 2368.12,1055.15 2368,1054.18 2368.51,1052.39 2368.93,1051.38 2369.77,1050.89 2370.36,1050.6
  		2370.6,1050.38 2370.84,1050.14 2371.75,1048.77 2372.57,1048.13 2373.37,1047.74 2374.24,1047.79 2374.98,1048.32
  		2376.43,1048.55 2377.82,1048.4 2379.03,1048 2379.58,1047.67 2380.22,1047.38 2380.19,1047.06 2379.46,1046.77 2379.04,1046.37
  		2378.88,1045.88 2379.29,1045.58 2380.24,1045.49 2382.57,1044.29 2383.53,1043.49 2383.79,1043.13 2384.23,1043.09
  		2385.12,1043.45 2386.16,1043.58 2386.82,1043.21 2387.46,1043.13 2388.1,1043.53 2388.44,1044.01 2389.02,1044.58
  		2389.75,1044.69 2390.63,1044.39 2391.55,1043.72 2392.43,1042.8 2393.21,1041.88 2393.01,1038.97 2392.89,1037.26
  		2393.29,1036.35 2393.88,1035.79 2394.27,1034.93 2394.26,1034.17 2394.67,1033.53 2395.08,1031.77 2395.64,1031.36
  		2396.78,1031.07 2398.6,1030.91 2400.04,1030.1 2401.47,1029.25 2401.67,1028.5 2401.13,1027.7 2400.41,1026.13 2399.75,1025.2
  		2398.16,1023.53 2398.35,1022.49 2399.23,1022.07 2401.41,1022.79 2402.02,1022.93 2402.78,1023.03 2404.78,1023.01
  		2406.42,1022.73 2408.13,1022.09 2408.47,1021.43 2408.45,1020.64 2408.48,1019.98 2408.5,1019.07 2407.89,1018.49
  		2407.52,1017.97 2407.41,1017.55 2407.84,1017.32 2408.25,1016.89 2408.65,1016.12 2409.6,1015.19 2410.18,1014.35
  		2410.71,1013.95 2411.53,1013.41 2412.05,1012.61 2412.29,1012.13 2412.77,1011.66 2412.92,1011.35 2412.8,1011.08
  		2412.56,1010.73 2412.29,1010.35 2412.29,1009.97 2412.52,1009.57 2412.76,1009.21 2412.65,1008.71 2412.52,1007.89
  		2412.07,1007.4 2411.77,1006.28 2411.3,1005.14 2411.05,1004.7 2410.59,1004.14 2409.55,1003.55 2409.26,1003.15 2409.66,1002.39
  		2410.33,1001.91 2411.6,1000.74 2412.3,999.93 2412.92,999.37 2413.73,999.46 2414.21,999.41 2414.62,998.89 2415.45,998.43
  		2416.93,997.47 2417.44,996.82 2418.22,996.5 2418.87,996.42 2419.75,996.2 2420.6,995.85 2421.37,995.58 2422.57,995.53
  		2424.49,995.37 2425.53,995.21 2428.01,995.08 2430.71,995.02 2431.02,995.01 2432.19,995.36 2432.98,995.64 2433.29,995.62
  		2434.61,994.93 2436.6,994.09 2437.52,993.72 2437.4,993.64 2437.16,993.38 2436.13,992.61 2436.1,992.41 2437.67,991.65
  		2438.6,991.36 2439.09,991.13 2439.19,991.18 2439.43,991.53 2440.04,991.76 2440.47,991.7 	"/>
    </>,
    ARE: <>
        <path className="st0" d="M2262.82,1112.68l0.23-0.16l0.86,0.09l0-0.3l-0.29-0.34l-0.51-0.39l-1.21,0.43l-0.46,0.36l0.69,0.22
  		L2262.82,1112.68z M2258.89,1111.59l0.42-0.2l0.22-0.24l0.31-0.56l-0.28-0.35l-0.35,0.41l-0.32,0.2l-1.19,0.45l0.57,0.34
  		L2258.89,1111.59z M2268.12,1110.11l0.24,0.02l0.07-0.17l-0.33-0.25l-0.24-0.31l-0.16,0.02l-0.37,0.3l0.19,0.25L2268.12,1110.11z
  		 M2284.5,1104.83l-0.08-4.92l-0.55-0.73l-0.16,0.21l-0.24,0.02l-0.56-0.17l-0.33-0.41l0.06-0.5l0.02-0.92l0.16-0.87l-0.04-0.92
  		l-0.43-0.19l-0.3,0.05l-0.05,0.09l-0.42,1.23l-0.71,1.09l-3.52,2.65l-0.75,0.93l-0.94,0.85l-0.15,0.56l-1.73,1.74l-2.96,2.06
  		l-0.74,0.85l-0.29,0.84l-0.37,0.51l-0.39,0.29l-0.3,0.61l-0.34,0.93l-0.52,0.71l-0.78,0.21l-1.32,0.74l-2.14,0.84l-0.77,0.07
  		l-3.98-0.26l-2.55-0.43l-3.18-0.06l-1.15,0.37l-2.19,1.04l-1.11,0.21l-1.79-0.13l-0.53-0.23l-0.43-0.57l-0.2-1.6l-0.28-0.07
  		l-0.59,0.11l-0.35-0.45l-0.15-0.33l-0.31,0.46v0.25l0.03,1.15l0.17,0.44l0.31,0.39l0.46,0.58l0.46,0.58l0.46,0.58l0.46,0.58
  		l0.46,0.58l0.46,0.58l0.46,0.58l0.46,0.58l0.46,0.58l0.46,0.58l0.46,0.58l0.46,0.58l0.46,0.58l0.46,0.58l0.46,0.58l0.46,0.58
  		l0.38,0.48l0.71,0.09l0.23,0.03l0.64,0.08l0.99,0.13l1.29,0.16l1.52,0.19l1.7,0.22l1.82,0.23l1.87,0.24l1.87,0.24l1.81,0.23
  		l1.7,0.22l1.52,0.19l1.29,0.16l0.99,0.13l0.64,0.08l0.23,0.03l0.67,0.09l0.13-0.02l0.56-0.71l0.07-1.29l-0.02-0.64l0.07-0.99
  		l0.59-1.37l0.7-1.75l0.51-1.16l0.44-1.01l0.35-0.81l0.2-0.84l-0.1-0.59l-0.23-0.21l-0.2-0.28l0.67-0.45l1.25-0.29l0.7,0.06
  		l0.97-0.22l0.77-0.19l0.06-0.26l-0.22-0.44l-0.32-0.64l-1.09-0.07l-0.33-0.18l0.38-0.95l-0.01-0.3l-0.14-0.36l-0.16-0.6l0.08-0.77
  		l0.22-0.52l0-0.42l-0.11-0.87l0.03-0.77l0.23-0.38l0.4-0.36l0.38-0.18l0.4,0.01l0.32,0.15l0.14,0.41l-0.09,0.28l-0.22,0.04
  		l-0.08,0.12l0.32,0.54l0.47,0.53l0.36-0.09l0.4-0.42l0.42-0.34l0.53-0.3l0.38-0.58l0.33-0.37l0.3-0.05L2284.5,1104.83z
  		 M2283.73,1102.89l-0.35,0.24l-0.25-0.04l0.05-0.48l0.15-0.33l0.36,0.03l0.09,0.2L2283.73,1102.89z M2252.87,1111.32l0.34-0.39
  		l-0.24-0.39l-0.39,0.22l-0.01,0.15l0.15,0.48L2252.87,1111.32z"/>
    </>,
    AND: <>
        <polygon className="st1" points="1824.35,938.79 1824.1,938.51 1822.91,938.2 1822.35,938.12 1821.99,938.33 1821.73,938.59
  		1821.62,939.07 1821.68,939.25 1821.75,939.59 1821.73,939.96 1821.9,940.2 1822.22,940.23 1822.62,940.15 1823.06,940.01
  		1823.84,939.6 1824.07,939.53 1824.14,939.3 1824.36,938.99 	"/>
        <rect className="st6" height="2160" width="3840"/>
    </>,
}
