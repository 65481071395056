import { PlanetsEnum } from "../stores/UIStore";
import addresses from '../../../contract/addresses.json';

export const TEST_NETWORK = false;
export const CHAIN_ID = TEST_NETWORK ? 97 : 56;  // 3 = ropsten, 1 = mainnet, 97 = bsc test, 56 = bsc main
export const REWARD_TOKEN_CONTRACT = addresses.rt;
export const NFT_TOKEN_CONTRACT = addresses.nft;

export const ADAPTIVE_BREAKPOINT = 799;

export const PLANET_ORDER = [
    PlanetsEnum.neptune,
    PlanetsEnum.earth,
    PlanetsEnum.moon,
    PlanetsEnum.jupiter,
    PlanetsEnum.mercury,
    PlanetsEnum.sun,
    PlanetsEnum.venus,
    PlanetsEnum.mars,
    PlanetsEnum.saturn,
    PlanetsEnum.uranus,
    PlanetsEnum.tesla,
];

export const PLANETS_DATA = {
    [PlanetsEnum.sun]: {
        name: 'Sun',
        orbitName: 'active-sun',
        iso: 'sun',
        actualNum: '01',
        type: 'Star',
        poster: require('../static/videos/nft-sun-pattern.png'),
        video: require('../static/videos/nft-sun.mp4'),
    },
    [PlanetsEnum.mercury]: {
        name: 'Mercury',
        orbitName: 'active-planet-4',
        iso: 'mercury',
        actualNum: '02',
        type: 'Planet',
        poster: require('../static/videos/nft-mercury-pattern.png'),
        video: require('../static/videos/nft-mercury.mp4'),
    },
    [PlanetsEnum.venus]: {
        name: 'Venus',
        orbitName: 'active-planet-5',
        iso: 'venus',
        actualNum: '03',
        type: 'Planet',
        poster: require('../static/videos/nft-venus-pattern.png'),
        video: require('../static/videos/nft-venus.mp4'),
    },
    [PlanetsEnum.earth]: {
        name: 'Earth',
        orbitName: 'active-planet-3',
        iso: 'earth',
        actualNum: '04',
        type: 'Planet',
        poster: require('../static/videos/nft-earth-pattern.png'),
        video: require('../static/videos/nft-earth.mp4'),
    },
    [PlanetsEnum.moon]: {
        name: 'Moon',
        orbitName: 'active-planet-9',
        iso: 'moon',
        actualNum: '05',
        type: 'Planet',
        poster: require('../static/videos/nft-moon-pattern.png'),
        video: require('../static/videos/nft-moon.mp4'),
    },
    [PlanetsEnum.mars]: {
        name: 'Mars',
        orbitName: 'active-planet-6',
        iso: 'mars',
        actualNum: '06',
        type: 'Planet',
        poster: require('../static/videos/nft-mars-pattern.png'),
        video: require('../static/videos/nft-mars.mp4'),
    },
    [PlanetsEnum.jupiter]: {
        name: 'Jupiter',
        orbitName: 'active-planet-2',
        iso: 'jupiter',
        actualNum: '07',
        type: 'Planet',
        poster: require('../static/videos/nft-jupiter-pattern.png'),
        video: require('../static/videos/nft-jupiter.mp4'),
    },
    [PlanetsEnum.saturn]: {
        name: 'Saturn',
        orbitName: 'active-planet-7',
        iso: 'saturn',
        actualNum: '08',
        type: 'Planet',
        poster: require('../static/videos/nft-saturn-pattern.png'),
        video: require('../static/videos/nft-saturn.mp4'),
    },
    [PlanetsEnum.uranus]: {
        name: 'Uranus',
        orbitName: 'active-planet-8',
        iso: 'uranus',
        actualNum: '09',
        type: 'Planet',
        poster: require('../static/videos/nft-uranus-pattern.png'),
        video: require('../static/videos/nft-uranus.mp4'),
    },
    [PlanetsEnum.neptune]: {
        name: 'Neptune',
        orbitName: 'active-planet-1',
        iso: 'neptune',
        actualNum: '10',
        type: 'Planet',
        poster: require('../static/videos/nft-neptune-pattern.png'),
        video: require('../static/videos/nft-neptune.mp4'),
    },
    [PlanetsEnum.tesla]: {
        name: 'Tesla',
        orbitName: 'active-planet-10',
        iso: 'tesla',
        actualNum: '11',
        type: 'Car',
        poster: require('../static/videos/nft-tesla-pattern.png'),
        video: require('../static/videos/nft-tesla.mp4'),
    },
}
