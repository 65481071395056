import { injectable } from "inversify";
import { RootStore } from "./RootStore";
import { action, makeObservable, observable, reaction, runInAction } from "mobx";
import Timeout from "await-timeout";
import { ADAPTIVE_BREAKPOINT } from "../utils/const";
import { Location } from "history";

export type ActiveTab = 'solarSystem' | 'aboutUs';
export enum PlanetsEnum {
    sun = 1,
    mercury,
    venus,
    earth,
    moon,
    mars,
    jupiter,
    saturn,
    uranus,
    neptune,
    tesla,
}

type Modals = 'moreInfo' | 'continent' | 'metamask' | 'transaction' | 'transactionLoading'

@injectable()
export class UIStore {
    @observable aboveBreakpoint: boolean = true;

    @observable activeTabTransitioning: boolean = false;
    @observable activeTab: ActiveTab = 'solarSystem';
    @observable activeTabDelayed: ActiveTab = 'solarSystem';

    @observable activePlanet?: PlanetsEnum;
    @observable activePlanetDelayed?: PlanetsEnum;
    @observable rootZoomIn: boolean = false;
    @observable rootZooming: boolean = false;
    @observable rootCarousel: boolean = false;
    @observable rootZoomOut: boolean = true;
    @observable rootPlanetsOpacity: boolean = false;
    @observable rootScrolling: boolean = false;

    @observable zoomOutTransitioningStep: number = 0;
    @observable spaceSwitching: boolean = false;

    @observable showPlanetContent?: PlanetsEnum;

    @observable mobileMenuVisible: boolean = false;
    @observable mobileMenuTransitioning: boolean = false;
    @observable mobileVisiblePlanet: PlanetsEnum = PlanetsEnum.earth;

    @observable openModal?: Modals;
    @observable openModalDelayed?: Modals;
    @observable activeAccount?: string;
    @observable activeCountry?: string;
    @observable activeCountryDelayed?: string;
    @observable transactionTokenId?: string;
    @observable transactionTitle?: string;
    @observable transactionCode?: string;

    @observable hoveredOrbit?: string;
    @observable animateMoon: boolean = true;
    @observable switchContent: boolean = false;

    constructor(private readonly rootStore: RootStore) {
        makeObservable(this);
        this.init();
    }

    @action init = () => {
        window.addEventListener('resize', this.checkBreakpoint);
        this.checkBreakpoint();
        reaction(() => this.rootStore.navigation.location, this.onRouteChange);

        const pathname = this.rootStore.navigation.location.pathname;
        if (pathname === '/aboutUs') {
            this.activeTab = this.activeTabDelayed = 'aboutUs';
        } else if (/^\/planets\/.+/.test(pathname)) {
            const name = pathname.match(/^\/planets\/([^\/]+)/)[1];
            const planet = PlanetsEnum[name];
            if (typeof planet === "undefined") {
                this.rootStore.history.replace('/');
            } else {
                this.rootZoomIn = true;
                this.rootZoomOut = false;
                this.rootCarousel = true;
                this.animateMoon = false;
                this.activePlanet = this.mobileVisiblePlanet = PlanetsEnum[name];
                if (name === 'earth' && !this.aboveBreakpoint) {
                    this.switchContent = true;
                }
                if (/^\/planets\/earth\/.+$/.test(pathname)) {
                    const code = pathname.match(/^\/planets\/earth\/([^\/]+)/)[1];
                    this.showCountry(code, true)
                    // this.rootStore.entityStore.readyPromise.promise.then(() => this.showCountry(this.rootStore.entityStore.countriesMap[number], true));
                }
            }
        } else {
            this.rootStore.history.replace('/');
        }
    }

    @action onRouteChange = (cur: Location) => {
        const pathname: string = cur.pathname;
        if (pathname === '/aboutUs') {
            this.toggleTab('aboutUs', true);
        } else {
            this.toggleTab('solarSystem', true);
        }

        if (pathname === '/') {
            this.zoomOutPlanets(true);
            this.hideModals();
            this.animateMoon = true;
        } else {
            const match = pathname.match(/^\/planets\/([^\/]+)/);
            if (match) {
                const name = match[1];
                const planet = PlanetsEnum[name];
                if (typeof planet === "undefined") {
                    this.rootStore.history.replace('/');
                } else {
                    this.animateMoon = false;
                    this.zoomInPlanet(PlanetsEnum[name], true);
                    if (name === 'earth' && !this.aboveBreakpoint) {
                        this.switchContent = true;
                    }
                    if (/^\/planets\/earth\/.+$/.test(pathname)) {
                        const code = pathname.match(/^\/planets\/earth\/([^\/]+)/)[1];
                        this.showCountry(code, true);
                        // this.rootStore.entityStore.readyPromise.promise.then(() => this.showCountry(this.rootStore.entityStore.countriesMap[number], true));
                    } else {
                        this.closeCountries(true);
                    }
                }
            }
        }
    }

    @action checkBreakpoint = () => {
        this.aboveBreakpoint = window.outerWidth > ADAPTIVE_BREAKPOINT;
    }

    toggleTab = async (tab: ActiveTab, history: boolean = false) => {
        // if (tab === this.activeTab && tab !== 'solarSystem')
        //     return;

        if (this.mobileMenuVisible)
            this.toggleMobileMenu();

        switch (tab) {
            case 'solarSystem':
                !history && this.rootStore.history.push('/');
                if (!history || history && this.rootStore.history.location.pathname === '/') {
                    this.zoomOutPlanets(history);
                    this.hideModals();
                }
                break;
            case 'aboutUs':
                !history && this.rootStore.history.push('/aboutUs');
                break;
        }

        runInAction(() => { this.activeTab = tab; this.activeTabTransitioning = true; });
        await Timeout.set(30);
        runInAction(() => this.activeTabDelayed = tab);
        await Timeout.set(300);
        runInAction(() => this.activeTabTransitioning = false);
    }

    zoomInPlanet = async (planet: PlanetsEnum, history: boolean = false) => {
        if (planet === this.activePlanet)
            return;

        !history && this.rootStore.history.push(`/planets/${PlanetsEnum[planet]}`);
        const prevActivePlanet = this.activePlanet;
        if (!prevActivePlanet) {
            runInAction(() => {
                this.animateMoon = false;
                this.activePlanet = planet;
                this.mobileVisiblePlanet = planet;
                this.rootZoomIn = true;
                this.rootZooming = true;
                this.rootZoomOut = false;
            });
            setTimeout(() => runInAction(() => {
                this.rootCarousel = true;
                this.rootZooming = false;
            }), 600);
        } else {
            if (this.rootScrolling)
                return;

            runInAction(() => {
                this.activePlanet = planet;
                this.mobileVisiblePlanet = planet;
                this.rootPlanetsOpacity = true;
                this.rootScrolling = true;
            });
            setTimeout(() => runInAction(() => {
                this.rootPlanetsOpacity = false;
            }), 700);
            setTimeout(() => runInAction(() => {
                this.rootScrolling = false;
            }), 1500);
        }
    }

    zoomOutPlanets = async (history: boolean = false) => {
        !history && this.rootStore.history.push('/');
        runInAction(() => {
            this.animateMoon = true;
            this.rootZoomOut = true;
            this.activePlanet = undefined;
            this.rootZoomIn = false;
            this.rootZooming = true;
            this.activeCountry = this.activeCountryDelayed = undefined;
            this.switchContent = false;
        });
        setTimeout(() => runInAction(() => {
            this.rootZooming = false;
            this.rootCarousel = false;
        }), 0);
    }

    @action togglePlanetContent = (planet: PlanetsEnum) => {
        if (this.showPlanetContent === planet)
            this.showPlanetContent = undefined;
        else
            this.showPlanetContent = planet;
    }

    toggleMobileMenu = async (newValue?: boolean) => {
        if (this.mobileMenuTransitioning)
            return;
        if (typeof newValue === 'undefined')
            newValue = !this.mobileMenuVisible;
        runInAction(() => this.mobileMenuTransitioning = true);
        await Timeout.set(0);
        runInAction(() => this.mobileMenuVisible = newValue);
        await Timeout.set(500);
        runInAction(() => this.mobileMenuTransitioning = false);
    }

    @action swipePlanetsMobile = (dir: number) => {
        if (typeof this.activePlanet !== "undefined" || this.showPlanetContent)
            return;
        if (dir > 0) {
            if (this.mobileVisiblePlanet === PlanetsEnum.tesla) {
                this.mobileVisiblePlanet = PlanetsEnum.sun;
                return;
            }
        } else {
            if (this.mobileVisiblePlanet === PlanetsEnum.sun) {
                this.mobileVisiblePlanet = PlanetsEnum.tesla;
                return;
            }
        }
        this.mobileVisiblePlanet += dir;
    }

    showModal = async (modal: Modals) => {
        if (this.openModal) {
            runInAction(() => this.openModalDelayed = undefined);
            await Timeout.set(150);
        }
        runInAction(() => this.openModal = modal);
        await Timeout.set(10);
        runInAction(() => this.openModalDelayed = modal);
    }

    hideModals = async (history: boolean = false) => {
        if (this.openModal === 'continent')
            !history && this.rootStore.history.push(`/planets/${PlanetsEnum[this.activePlanet]}`);

        runInAction(() => this.openModalDelayed = undefined);
        await Timeout.set(300);
        runInAction(() => this.openModal = undefined);
    }

    @action showCountry = (country: string, history: boolean = false) => {
        this.activeCountry = country;
        setTimeout(() => runInAction(() => this.activeCountryDelayed = country), 0);
        !history && this.rootStore.history.push(`/planets/${PlanetsEnum[this.activePlanet]}/${country}`);
    }

    @action closeCountries = (history: boolean = false) => {
        this.activeCountryDelayed = undefined;
        this.activeCountry = undefined;
        !history && this.rootStore.history.push(`/planets/${PlanetsEnum[this.activePlanet]}`);
    }

    @action showProfile = (address: string) => {
        this.activeAccount = address;
        this.showModal('moreInfo');
    }

    @action setHoveredOrbit = (name?: string) => {
        this.hoveredOrbit = name;
    }

    @action toggleSwitchContent = () => {
        this.switchContent = !this.switchContent;
    }

    @action setTransaction = (tokenId: string, code: string, title: string) => {
        this.transactionTokenId = tokenId;
        this.transactionCode = code;
        this.transactionTitle = title;
    }
}
