import React from 'react';

const RotatedScreen = () => {
    return (
        <div className="rotated-screen">
            <div className="message">Please, use nftMap in vertical orientation!</div>
        </div>
    )
}

export default RotatedScreen;