import React from 'react';
import { observer } from "mobx-react";
import { PLANET_ORDER } from "../../utils/const";
import { Planet } from "./Planet";
import { useInjection } from "inversify-react";
import { EntityStore } from "../../stores";

const SolarSystem = observer(() => {
    const entityStore = useInjection(EntityStore);

    return (
        <div className="planets-canvas">
            {PLANET_ORDER.map(pid => <Planet id={pid} key={pid} />)}
        </div>
    );
});

export default SolarSystem;
