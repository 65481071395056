import { GraphQLClient } from "graphql-request";
import { getSdk, Sdk } from "./graphql";

export class Api {
    client: GraphQLClient;
    sdk: Sdk;

    constructor() {
        this.client = new GraphQLClient(process.env.ENVIRONMENT === 'dev' ? `http://dev.bennnnsss.com:36519/graphql` : '/graphql');
        this.sdk = getSdk(this.client);
    }

    getEntities = async  () => {
        return await this.sdk.getEntities();
    }

    getMintArgs = async (buyer: string, tokenId: number) => {
        const r = await this.sdk.tokenMintArgs({ buyer, tokenId });
        return r.tokenMintArgs;
    }

    getEntitiesInfo = async (tokenIds: string[]) => {
        return this.sdk.entitiesInfo({ tokenIds });
    }
}
