import React from 'react';
import { observer } from "mobx-react";
import classNames from "classnames";
import { useInjection } from "inversify-react";
import { EntityStore, UIStore } from "../../stores";
import { ObservableHistory } from "mobx-observable-history";
import { NFT_TOKEN_CONTRACT, REWARD_TOKEN_CONTRACT } from "../../utils/const";

const AboutUs = observer(() => {
    const uiStore = useInjection(UIStore);
    const history = useInjection(ObservableHistory);
    const entityStore = useInjection(EntityStore);

    const { activeTabDelayed, activeTabTransitioning } = uiStore;

    return (
        <section className={classNames('about-us', { 'active-tab': activeTabDelayed === 'aboutUs' })} style={{ display: activeTabTransitioning ? 'block' : '' }}>
            <div className="provenance">
                <div className="provenance-wrapper">
                    <div className="provenance-title">NTFmap Provenance Record</div>
                    <div className="provenance-text">
                        This page presents the provenance record of each Hashmask that will ever exist. Each full
                        resolution Hashmask image is firstly hashed using SHA-256 algorithm. A combined string is
                        obtained by concatenating SHA-256 of each Hashmask image in the specific order as listed
                        below. The final proof is obtained by SHA-256 hashing this combined string. This is the
                        final provenance record stored on the smart contract
                    </div>
                    <div className="provenance-verify">
                        <div className="provenance-verify-title">Finalized Starting Index: 10141</div>
                        <div className="provenance-verify-text">Each Hashmask token ID is assigned to an artwork
                            image from the original sequence with this formula: (tokenId + startingIndex) % 16384 =&gt;
                            Image Index From the Original Sequence
                        </div>
                        <div className="provenance-verify-contracts">
                            <div className="provenance-verify-contracts-item">
                                <span>NFTM Contract Address:</span>
                                <a href={`https://bscscan.com/address/${REWARD_TOKEN_CONTRACT}`} target='_blank'>{REWARD_TOKEN_CONTRACT}</a>
                            </div>
                            <div className="provenance-verify-contracts-item">
                                <span>NFT Contract Address:</span>
                                <a href={`https://bscscan.com/address/${NFT_TOKEN_CONTRACT}`} target='_blank'>{NFT_TOKEN_CONTRACT}</a>
                            </div>
                            {/*<div className="provenance-verify-contracts-item">
                                <span>Final Proof Hash:</span>
                                <div
                                    className="proof-hash">df760c771ad006eace0d705383b74158967e78c6e980b35f670249b5822c42e1
                                </div>
                            </div>*/}
                        </div>
                    </div>

                    {/*<div className="provenance-subtitle">Concatenated Hash String</div>
                    <div className="provenance-text">The table below lists the original index, assigned Hashmask
                        Token ID, SHA256 Hash output and IPFS link of each Hashmask image
                    </div>*/}

                    <div className="provenance-list">
                        <div className="provenance-descr">
                            Initial Sequence Index - Name - Assigned Token ID{/* - SHA-256*/} - CDN Link
                        </div>
                        <h3>Planets</h3>
                        <div className="table-wrapper">
                            <table>
                                <tbody>
                                {entityStore.planets?.map(p => (
                                    <tr key={p.tokenId}>
                                        <td>{p.number}</td>
                                        <td>{p.name}</td>
                                        <td><a href={p.link} onClick={e => { e.preventDefault(); history.push(p.link) }}>{p.tokenId}</a></td>
                                        <td><a href={`https://ipfs.io/ipfs/${p.ipfsVideo}?filename=token.mp4`}>{p.ipfsVideo}</a></td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        <h3>Countries</h3>
                        <div className="table-wrapper">
                            <table>
                                <tbody>
                                {entityStore.countries?.map(c => (
                                    <tr key={c.tokenId}>
                                        <td>{c.number}</td>
                                        <td>{c.name}</td>
                                        <td><a href={c.link} onClick={e => { e.preventDefault(); history.push(c.link) }}>{c.tokenId.toString().padStart(7, '0')}</a></td>
                                        <td><a href={`https://ipfs.io/ipfs/${c.ipfsVideo}?filename=token.mp4`}>{c.ipfsVideo}</a></td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
});

export default AboutUs;
