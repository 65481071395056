import React, { useEffect, useRef, useState } from 'react';
import { observer } from "mobx-react";
import { useInjection } from "inversify-react";
import { EntityStore, UIStore, WalletStore } from "../../stores";
import Timeout from "await-timeout";
import classNames from "classnames";
import { CountryType } from "../../utils/graphql";
import useAsyncEffect from "use-async-effect";
import { numberWithCommas, toBNJS } from "../../utils/utilities";
import { Api } from "../../utils/api";
import { toast } from "react-toastify";

const CountryInfo = observer(({ c }: { c: CountryType }) => {
    const video = useRef<HTMLVideoElement>();

    const uiStore = useInjection(UIStore);
    const entityStore = useInjection(EntityStore);
    const walletStore = useInjection(WalletStore);
    const api = useInjection(Api);

    const [ videoState, setVideoState ] = useState('needLoading');
    const [ owner, setOwner ] = useState<string>();
    const [ bought, setBought ] = useState(false);
    const [ boughtTransition, setBoughtTransition ] = useState(false);
    const [ perDay, setPerDay ] = useState(false);

    useAsyncEffect(async () => {
        if (!entityStore.mintedCountries.includes(c.tokenId.toString()))
            return;
        setBought(true);
        const nft = walletStore.nftTokenContract;
        try {
            const owner = await nft.methods.ownerOf(c.tokenId.toString()).call();
            setOwner(owner);
        } catch (e) {}
    }, [entityStore.mintedCountries, entityStore.refreshNotify]);

    useEffect(() => {
        if (uiStore.activeCountry === c.code) {
            if (video.current?.readyState === 4 && video.current.paused) {  // HAVE_ENOUGH_DATA
                video.current.play();
                setVideoState('playing');
            } else {
                video.current?.load();
            }
        } else if (videoState === 'playing') {
            video.current?.pause();
            setVideoState('paused');
        }
    }, [uiStore.activeCountry]);

    const onBuy = async () => {
        if (!await walletStore.connect())
            return;
        uiStore.showModal('transactionLoading');

        try {
            const [ tokenId, price, dailyReward, uri, sig ] = await api.getMintArgs(walletStore.account, c.tokenId);
            await walletStore.nftTokenContract.methods.mint(tokenId, price, dailyReward, uri, sig).send({ from: walletStore.account, value: price as string });
            entityStore.refresh();

            uiStore.setTransaction(c.tokenId.toString(), c.code, c.name);
            uiStore.showModal('transaction');
            await Timeout.set(0)
            setBought(true);
            setBoughtTransition(true);
            await Timeout.set(400);
            setBoughtTransition(false);
        } catch (e) {
            console.log(e);
            toast.error('An error has occurred');
            uiStore.hideModals();
        }
    }

    return (
        <div className={classNames(`country-info`, { sold: bought })} style={{ animation: boughtTransition && 'sold .4s ease-out' }} id={`${c.code}Info`}>
            <div className="body">
                <div className="left-content">
                    <div className="toolbar-wrapper">
                        <div className="back-btn desktop-only" onClick={() => uiStore.closeCountries()}>
                            <button className="btn btn-box btn-chevron-left-blue" type="button" name="button" title="Back to solar system."/>
                            <p>Back to countries</p>
                        </div>
                        <div className="toolbar">
                            <div className="planet-name">
                                <div className="name">{c.name}</div>
                                <div>nft</div>
                            </div>
                        </div>
                    </div>
                    <div className="params params-2 desktop-only">
                        <div className="params-label">Name</div>
                        <div className="params-value">{c.name}</div>
                    </div>
                    <div className="params params-2">
                        <div className="params-label">Type</div>
                        <div className="params-value">Country</div>
                    </div>
                    <div className="params full-width">
                        <div className="params-row">
                            <div className="params-col">
                                <div className="params-label">tokens {perDay ? 'per day' : 'per year'}</div>
                                <div className="params-value params-value-green">+{numberWithCommas(toBNJS(c.emission).div(perDay ? 365 : 1).toFixed(0))} NFTm</div>
                            </div>
                            <div className="params-col desktop-only">
                                <div className="custom-switch">
                                    <input checked={perDay} onChange={() => setPerDay(!perDay)} type="checkbox" name="custom-switch"/>
                                    <div className="active-switch"/>
                                    <div className="switch-content">
                                        <p className="switch-left-content">Per Year</p>
                                        <p className="switch-right-content">Per day</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="params params-2">
                        <div className="params-label">Population</div>
                        <div className="params-value">{numberWithCommas(toBNJS(c.population))}</div>
                    </div>
                    <div className="params params-2">
                        <div className="params-label">Surface Area</div>
                        <div className="params-value">{numberWithCommas(toBNJS(c.area))} km&sup2;</div>
                    </div>
                    <div className="details details-buy">
                        <div className="wrapper">
                            <div className="details-icon">
                                <div className="btn btn-box btn-etherium"/>
                            </div>
                            <div className="details-values">
                                <strong>{numberWithCommas(toBNJS(c.price))} BNB</strong>
                                <div>~{numberWithCommas(entityStore.bnbPrice.times(c.price).toFixed(0))} USD</div>
                            </div>
                        </div>
                        <div className="details-arrow"><img src={require('../../static/icons/cards/line-long.svg')} alt=""/></div>
                        <div className="details-btn">
                            <button className="btn btn-green-2 btn-rectangle btn-buy" type="button" name="button" onClick={onBuy}>
                                Buy
                            </button>
                        </div>
                    </div>
                    <div className="details details-more">
                        <div className="details-icon">
                            <div className="btn btn-box btn-user"/>
                        </div>
                        {owner && (
                            <div className="details-values">
                                <strong>{owner}</strong>
                                <div>Owner Address</div>
                            </div>
                        )}
                        <div className="details-arrow"><img src={require('../../static/icons/cards/line-long.svg')} alt=""/></div>
                        <div className="details-btn">
                            <button className="btn btn-gray btn-rectangle btn-buy" type="button" name="button"
                                    onClick={() => uiStore.showProfile(owner)}>More
                            </button>
                        </div>
                    </div>
                </div>
                <div className="right-content">
                    <video
                        className="nft-skeleton"
                        playsInline
                        preload="none"
                        loop
                        poster={`${c.video}.jpg`}
                        muted
                        ref={video}
                        onCanPlayThrough={() => {
                            if (videoState !== 'needLoading')
                                return;
                            video.current.play();
                            setVideoState('playing');
                        }}
                    >
                        <source src={c.video} type="video/mp4"/>
                        {/*<source src={require('../../static/videos/nft-russia.webm')} type="video/webm"/>*/}
                    </video>
                </div>
            </div>
        </div>
    )
});

const CountriesInfo = observer(() => {
    const entityStore = useInjection(EntityStore);
    const uiStore = useInjection(UIStore);

    if (!entityStore.ready)
        return null;

    return (
        <div className="countries-info">
            {uiStore.activeCountry && <CountryInfo c={entityStore.countriesMap[uiStore.activeCountry]} />}
            {/*{entityStore.countries?.map(c => (
                <CountryInfo c={c} key={c.code} />
            ))}*/}
        </div>
    );
});

export default CountriesInfo;
