import { action, makeAutoObservable, observable } from "mobx";
import { RootStore } from "./RootStore";

export class OrientationStore {
    @observable isLandscape: boolean = false;

    constructor(protected rootStore: RootStore) {
        makeAutoObservable(this);
        window.addEventListener('orientationchange', this.onOrientationChange)
        this.onOrientationChange();
    }

    @action onOrientationChange = () => {
        this.isLandscape = Math.abs(parseInt(window.orientation as string)) === 90;
    }
}