import { observer } from "mobx-react";
import { PlanetsEnum, UIStore } from "../../stores/UIStore";
import React, { useEffect, useRef, useState } from "react";
import { useInjection } from "inversify-react";
import { PLANETS_DATA } from "../../utils/const";
import classNames from "classnames";
import { numberWithCommas, toBNJS } from "../../utils/utilities";
import { EntityStore } from "../../stores";

export const Planet = observer(({ id }: { id: PlanetsEnum }) => {
    const video = useRef<HTMLVideoElement>();

    const uiStore = useInjection(UIStore);
    const entityStore = useInjection(EntityStore);

    const p = entityStore.planetsMap[Number(id)];

    const pd = PLANETS_DATA[id];
    const { name, orbitName, iso } = pd;
    const [earth, moon, sun, tesla] = [PlanetsEnum.earth, PlanetsEnum.moon, PlanetsEnum.sun, PlanetsEnum.tesla].map(i => i === id);

    const [rotationDegree, setRotationDegree] = useState(0);
    const [moonTransform, setMoonTransform] = useState(false);
    const [moonTransition, setMoonTransition] = useState(false);
    const [videoState, setVideoState] = useState('needLoading');

    useEffect(() => {
        if (!moon) return;

        let start = performance.now();
        let currentValue = 0;
        const render = time => {
            const delta = time - start;
            if (delta >= 10 && uiStore.animateMoon && uiStore.aboveBreakpoint) {
                setMoonTransform(true);
                setMoonTransition(true);
                currentValue += .35;
                start = performance.now();
            }
            if (!uiStore.animateMoon) {
                if (uiStore.aboveBreakpoint) {
                    setTimeout(() => {
                        setMoonTransform(true);
                        setMoonTransition(false);
                    }, 600);
                } else {
                    setMoonTransform(false);
                    setMoonTransition(false);
                }
            }
            setRotationDegree(currentValue);
            requestAnimationFrame(render);
        };
        requestAnimationFrame(render);
    }, []);

    useEffect(() => {
        if (!sun || videoState !== 'needLoading' || !uiStore.aboveBreakpoint || !video.current) return;
        video.current.load();
    }, [uiStore.aboveBreakpoint, video]);

    return (
        <div
            className={classNames('planet-wrapper', iso)}
            style={{
                transition: moonTransition ? 'transform .15s linear' : undefined,
                transform: moonTransform ? `rotate(${rotationDegree}deg) translateX(${moonTransition ? 5 : 0}em) rotate(${-1 * rotationDegree}deg)` : undefined,
            }}
        >
            <div className="card-planet mobile-only">
                <div className="card-header">
                    <div className="row">
                        <div className="actual-planet-number">#{pd.actualNum}</div>
                        <img src={require('../../static/icons/cards/line-small.svg')}/>
                        <div className="planet-number">11</div>
                    </div>
                    <div className="planet-name">{name}</div>
                </div>
                {!earth && (
                    <div className="card-details">
                        <div className="details-icon">
                            <div className="btn btn-box btn-etherium"/>
                            <div className="eth-value">
                                <strong>{numberWithCommas(toBNJS(p?.price))}</strong>
                                <div>BNB</div>
                            </div>
                        </div>
                        <div className="details-values">
                            <div className="usd-value">~{numberWithCommas(entityStore.bnbPrice?.times(p?.price || 0).toFixed(0))} USD</div>
                        </div>
                    </div>
                )}
                <div className="btn btn-gray btn-more btn-buy btn-icon" onClick={() => { uiStore.zoomInPlanet(id); !uiStore.aboveBreakpoint && uiStore.toggleSwitchContent() }}>
                    More information
                    <button className="btn btn-box btn-chevron-right icon" type="button" name="button"/>
                </div>
            </div>
            <div
                className="planet"
                data-iso={iso}
                data-move=""
                onMouseEnter={() => uiStore.setHoveredOrbit(orbitName)}
                onMouseLeave={() => uiStore.setHoveredOrbit(undefined)}
                onClick={() => { uiStore.zoomInPlanet(id); !uiStore.aboveBreakpoint && uiStore.toggleSwitchContent() }}
            />
            <div className="planet-body">
                {!tesla && <div className="shadow-1"/>}
                {tesla && <div className="engine-shadow"/>}
                {!sun && <div className={`planet-${iso}`}/>}
            </div>
            {sun && (
                <video
                    playsInline
                    preload="none"
                    loop
                    poster={require('../../static/videos/sun-pattern.png')}
                    muted
                    ref={video}
                    onCanPlayThrough={() => {
                        if (videoState !== 'needLoading')
                            return;
                        video.current.play();
                        setVideoState('loaded');
                    }}
                >
                    <source src={require('../../static/videos/sun.mp4')} type="video/mp4"/>
                    {/*<source src={require('../../static/videos/sun.webm')} type="video/webm"/>*/}
                </video>
            )}
            {!earth && (
                <div className="tooltip desktop-only">
                    <div className="tooltip-row">
                        <p className="title">{name}</p>
                        <div className="union">
                            <p>{numberWithCommas(toBNJS(p?.price))}</p>
                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M24 4C7.53 4 4 7.53 4 24C4 40.47 7.53 44 24 44C40.47 44 44 40.47 44 24C44 7.53 40.47 4 24 4ZM19.8371 22.085L24.4979 17.4244L29.1611 22.0874L31.8731 19.3754L24.4979 12L17.1252 19.373L19.8371 22.085ZM12.498 23.9996L15.2101 21.2875L17.922 23.9995L15.21 26.7115L12.498 23.9996ZM19.8371 25.915L24.4979 30.5756L29.1609 25.9127L31.8744 28.6232L31.8731 28.6247L24.4979 36L17.1249 28.6272L17.1211 28.6234L19.8371 25.915ZM31.0741 24.0011L33.7862 21.289L36.4981 24.0009L33.786 26.713L31.0741 24.0011ZM27.2484 23.9985H27.2495L24.4976 21.2465L22.4638 23.2803H22.4637L22.2301 23.514L21.7481 23.9961L21.7443 23.9998L21.7481 24.0038L24.4976 26.7533L27.2495 24.0014L27.2509 23.9998L27.2484 23.9985Z"
                                      fill="white"/>
                            </svg>
                        </div>
                    </div>
                    <div className="subtitle">+{numberWithCommas(toBNJS(p?.emission).toFixed(0))} NFTm / YEAR</div>
                </div>
            )}
        </div>
    )
});
