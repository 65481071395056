import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { COUNTRIES, COUNTRIES_MAP, COUNTRIES_NAME, COUNTRIES_SOLD, COUNTRIES_SVG } from "../../utils/mapData";
import classNames from "classnames";
import { useInjection } from "inversify-react";
import { EntityStore, UIStore } from "../../stores";
import { CountryType } from "../../utils/graphql";
import { numberWithCommas } from "../../utils/utilities";
import { observer } from "mobx-react";

const Map = observer(({ root }: { root: HTMLDivElement }) => {
    const svg = useRef<SVGSVGElement>();

    const uiStore = useInjection(UIStore);
    const entityStore = useInjection(EntityStore);

    const [ tooltip, setTooltip ] = useState<CountryType>();
    const [ tooltipPosition, setTooltipPosition ] = useState<{ left: number, top: number }>();

    if (!uiStore.aboveBreakpoint) return null;

    return (
        <>
            <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 3840 2160"
                xmlSpace="preserve"
                onMouseMove={e => {
                    // const rect = svg.current.getBoundingClientRect();
                    setTooltipPosition({ left: e.clientX, top: e.clientY });
                }}
                ref={svg}
                id='mapSvg'
            >
                {entityStore.countries?.map(c => (
                    <g
                        className={classNames('country', { sold: entityStore.mintedCountries.includes(c.tokenId.toString()) })}
                        id={c.code}
                        key={c.code}
                        onMouseEnter={() => setTooltip(c)}
                        onMouseLeave={() => setTooltip(undefined)}
                        onClick={() => uiStore.showCountry(c.code)}
                    >
                        {COUNTRIES_SVG[c.code]}
                    </g>
                ))}
            </svg>
            <img src={require('../../static/images/continents/map-sprite.png')} alt="" id="mapSprite"/>
            {root && ReactDOM.createPortal((
                <>
                    {tooltip && (
                        <div className='tooltip country-tooltip' style={{ ...tooltipPosition }}>
                            <div className="tooltip-row">
                                <p className="title">{tooltip.name}</p>
                                <div className="union">
                                    <p>{numberWithCommas(tooltip.price)}</p>
                                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M24 4C7.53 4 4 7.53 4 24C4 40.47 7.53 44 24 44C40.47 44 44 40.47 44 24C44 7.53 40.47 4 24 4ZM19.8371 22.085L24.4979 17.4244L29.1611 22.0874L31.8731 19.3754L24.4979 12L17.1252 19.373L19.8371 22.085ZM12.498 23.9996L15.2101 21.2875L17.922 23.9995L15.21 26.7115L12.498 23.9996ZM19.8371 25.915L24.4979 30.5756L29.1609 25.9127L31.8744 28.6232L31.8731 28.6247L24.4979 36L17.1249 28.6272L17.1211 28.6234L19.8371 25.915ZM31.0741 24.0011L33.7862 21.289L36.4981 24.0009L33.786 26.713L31.0741 24.0011ZM27.2484 23.9985H27.2495L24.4976 21.2465L22.4638 23.2803H22.4637L22.2301 23.514L21.7481 23.9961L21.7443 23.9998L21.7481 24.0038L24.4976 26.7533L27.2495 24.0014L27.2509 23.9998L27.2484 23.9985Z"
                                              fill="white"/>
                                    </svg>
                                </div>
                            </div>
                            <div className="subtitle">+{numberWithCommas(tooltip.emission)} NFTm / YEAR</div>
                        </div>
                    )}
                </>
            ), root)}
        </>
    );
});

export default Map;
