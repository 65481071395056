import React from 'react';
import { observer } from "mobx-react";
import { useInjection } from "inversify-react";
import { UIStore } from "../stores";
import classNames from "classnames";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";

const TransactionModal = observer(() => {
    const uiStore = useInjection(UIStore);

    const shareText = `Happy NFTmap Owner!\n\nI’ve bought ${uiStore.transactionTitle} NFT token on https://nftmap.com check it out`;
    const shareLink = `http://dev.bennnnsss.com:26519/r/${uiStore.transactionTokenId}/`;

    return (
        <>
            <div
                id="transactionLoaderPopup"
                className={classNames('popup popup-transaction-loader', { show: uiStore.openModalDelayed === 'transactionLoading' })}
                style={{ display: uiStore.openModal === 'transactionLoading' ? 'block' : 'none' }}
            >
                <div className="popup-container">
                    <div className="transaction-loader">
                        <svg className="ellipse" version="1.1" xmlns="http://www.w3.org/2000/svg"
                             xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 47.4 47.7" xmlSpace="preserve">
                <path fill="none" stroke="#1B3E48"
                      d="M23.7,45.8c12.1,0,22-9.9,22-22c0-12.1-9.9-22-22-22c-12.1,0-22,9.9-22,22C1.7,35.9,11.6,45.8,23.7,45.8z"/>
                            <path className="progress"
                                  d="M23.7,45.8c12.1,0,22-9.9,22-22c0-12.1-9.9-22-22-22c-12.1,0-22,9.9-22,22C1.7,35.9,11.6,45.8,23.7,45.8z"/>
              </svg>
                        <svg className="logo-dark" width="40" height="40" viewBox="0 0 40 40" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M19.8321 0.167564C20.1405 0.0460972 20.4863 0.02741 20.8134 0.0180664C24.9442 0.158221 29.019 1.66254 32.2807 4.204C31.1125 3.43783 29.7854 2.93327 28.4489 2.54084C26.29 1.90548 24.0564 1.62517 21.8414 1.25142C21.2713 1.1393 20.6825 1.03652 20.1405 0.830961C19.9442 0.737524 19.7199 0.653432 19.6171 0.46656C19.561 0.317062 19.7106 0.214282 19.8321 0.167564Z"
                                fill="white"/>
                            <path
                                d="M11.0097 2.13956C12.1312 1.57894 13.2994 1.11176 14.505 0.775391C13.8508 1.02767 13.1592 1.36404 12.8041 1.9994C12.5891 2.37315 12.6639 2.8777 12.9723 3.18604C13.4957 3.71863 14.262 3.89616 14.9723 4.03631C16.8321 4.34465 18.7293 4.23253 20.6078 4.23253C23.9443 4.17646 27.3461 4.24187 30.5798 5.17623C31.5798 5.46588 32.5517 5.83963 33.4676 6.31615C34.5985 6.91414 35.6732 7.67098 36.477 8.68009C36.7573 9.05383 37.0003 9.4556 37.234 9.85738C38.0471 11.2402 38.7013 12.7072 39.1499 14.2489C38.7947 13.3145 38.2059 12.4736 37.4957 11.7635C36.0844 10.3526 34.2527 9.43692 32.3555 8.8763C30.4302 8.31568 28.4209 8.11947 26.4302 8.11947C23.8041 8.12881 21.2059 8.50256 18.6172 8.89499C16.6732 9.18464 14.7293 9.49298 12.7573 9.53035C11.6919 9.5397 10.6078 9.47429 9.57978 9.14727C8.89753 8.93236 8.23398 8.58665 7.748 8.04472C7.23398 7.4841 6.99099 6.68055 7.12183 5.93306C7.29006 4.92395 7.96295 4.06434 8.76669 3.45701C9.47697 2.9618 10.2433 2.53199 11.0097 2.13956Z"
                                fill="white"/>
                            <path
                                d="M1.54984 12.2959C2.22274 10.6981 3.08255 9.18448 4.14797 7.82031C3.83956 8.28749 3.57788 8.80139 3.40966 9.33398C3.05452 10.4739 3.12928 11.7633 3.68068 12.8378C4.20405 13.8937 5.15732 14.6972 6.21339 15.1831C7.58723 15.8185 9.12928 15.996 10.6246 15.9773C12.5125 15.9399 14.3816 15.5942 16.2134 15.1924C17.9984 14.8 19.7741 14.3328 21.5685 13.9404C23.7835 13.4452 26.0171 13.0434 28.2882 12.978C30.5779 12.9126 32.9237 13.1836 35.0358 14.1086C36.3536 14.6879 37.5872 15.5288 38.5031 16.65C39.3629 17.6872 39.933 18.9766 39.9891 20.3314C39.9704 22.387 39.6059 24.452 38.9424 26.3861C39.3255 25.0313 39.0919 23.5363 38.3536 22.3403C37.8769 21.5461 37.1947 20.8734 36.4283 20.3501C35.4003 19.6587 34.2227 19.2289 33.0171 18.9953C31.933 18.7897 30.8302 18.7243 29.7368 18.7617C27.4564 18.8364 25.2134 19.3223 22.9984 19.8736C20.6246 20.4809 18.2695 21.191 15.8769 21.7143C13.9984 22.1254 12.0826 22.4151 10.1573 22.4057C8.22274 22.387 6.24143 22.0133 4.5405 21.0509C3.12928 20.2567 1.94237 19.014 1.33489 17.5003C0.811525 16.2109 0.718067 14.7439 1.11994 13.4171C1.24143 13.0434 1.39096 12.6697 1.54984 12.2959Z"
                                fill="white"/>
                            <path
                                d="M-0.00034743 19.5649H0.00899836C0.02769 21.3963 0.831428 23.1435 2.04638 24.4797C3.47629 26.0681 5.4389 27.1052 7.49498 27.6472C9.94358 28.2919 12.5137 28.2638 15.0183 27.9555C17.3268 27.6659 19.5978 27.1239 21.8688 26.5726C23.6725 26.1428 25.4763 25.7037 27.3081 25.4234C28.4389 25.2552 29.5884 25.1524 30.738 25.1898C31.9529 25.2365 33.1959 25.4327 34.2987 25.9653C35.3081 26.4418 36.1959 27.2641 36.5884 28.3292C37.0277 29.4785 36.794 30.796 36.1305 31.8144C35.5978 32.5712 34.981 33.272 34.3361 33.9448C33.5417 34.7483 32.6819 35.5145 31.7567 36.1685C32.3081 35.7014 32.7847 35.0847 32.8875 34.3559C32.9716 33.8513 32.8221 33.3187 32.5043 32.917C32.1585 32.4685 31.6632 32.1695 31.1492 31.9733C30.1398 31.5808 29.0464 31.5154 27.981 31.4967C25.6632 31.5341 23.3828 31.9452 21.0931 32.2722C18.8782 32.5993 16.6539 32.8796 14.4109 32.8889C11.794 32.9076 9.13049 32.5526 6.70059 31.5154C5.35479 30.9455 4.10246 30.1606 3.04638 29.1515C2.13984 28.2825 1.38283 27.2267 0.934232 26.0494C0.261335 23.9658 -0.0283848 21.7607 -0.00034743 19.5649Z"
                                fill="white"/>
                            <path
                                d="M3.8418 31.7778C4.96329 33.142 6.47731 34.1324 8.08479 34.8425C9.62684 35.5246 11.2624 35.9544 12.9259 36.216C16.1782 36.7393 19.4773 36.5617 22.7483 36.5617C23.8231 36.5898 24.9166 36.6085 25.9633 36.8607C26.3838 36.9729 26.8231 37.113 27.1315 37.44C27.3278 37.6269 27.4025 37.9446 27.2717 38.1875C27.0567 38.6173 26.6082 38.8416 26.1876 39.0191C24.1876 39.6918 22.0661 40.0095 19.9539 40.0002C20.0941 39.9815 20.253 39.9721 20.3839 39.8974C20.4493 39.8413 20.3839 39.7386 20.3278 39.7199C20.0381 39.5517 19.7016 39.4863 19.3745 39.4115C18.5427 39.234 17.7016 39.1219 16.8605 38.9817C15.1128 38.7014 13.3652 38.3744 11.6829 37.8138C10.5427 37.4307 9.42124 36.9542 8.41189 36.3001C7.36516 35.5807 6.40254 34.7211 5.52404 33.8054C4.91656 33.1607 4.34647 32.4879 3.8418 31.7778Z"
                                fill="white"/>
                        </svg>
                    </div>
                    <div className="message">Finishing transaction...</div>
                </div>
            </div>
            <div
                id="transactionPopup"
                className={classNames("popup popup-transaction", { show: uiStore.openModalDelayed === 'transaction' })}
                style={{ display: uiStore.openModal === 'transaction' ? 'block' : 'none' }}
            >
                <button className="popup-close btn btn-box btn-close" data-name="" onClick={() => uiStore.hideModals()}/>
                <div className="popup-container">
                    <div className="left-content">
                        <div className="message">
                            <p className="congrats">Congratulation!</p>
                            <p className="subtitle">you successfully bought</p>
                        </div>
                        <div className="planet-name">{uiStore.transactionTitle}</div>
                        <div className="social">
                            <div className="title">tell your friends about it</div>
                            <div className="btns">
                                <a href={`https://twitter.com/share?text=${encodeURIComponent(shareText)}&url=${encodeURIComponent(shareLink)}`} target='_blank' className="btn btn-box btn-purple-twitter"/>
                                <a href={`https://t.me/share/url?url=${encodeURIComponent(shareLink)}&text=${encodeURIComponent(shareText)}`} target='_blank' className="btn btn-box btn-purple-telegram"/>
                                <a onClick={() => { copy(`${shareLink}\n\n${shareText}`); toast.success('Link was copied') }} className="btn btn-box btn-purple-link"/>
                            </div>
                        </div>
                    </div>
                    <div className="right-content">
                    </div>
                </div>
            </div>
        </>
    )
});

export default TransactionModal;
