import React from 'react';
import classNames from "classnames";
import { observer } from "mobx-react";
import { resolve } from "inversify-react";
import { UIStore, WalletStore } from "../stores";

interface INavModalProps {
}

interface INavModalState {
}

@observer
class NavModal extends React.Component<INavModalProps, INavModalState> {
    @resolve(UIStore)
    declare protected readonly uiStore: UIStore;
    @resolve(WalletStore)
    declare protected readonly walletStore: WalletStore;

    render() {
        const { mobileMenuVisible, mobileMenuTransitioning, activeTab } = this.uiStore;

        return (
            <div id="navBlock" className={classNames('nav-block', 'mobile-only', { active: mobileMenuVisible })} style={{ display: mobileMenuTransitioning ? 'flex' : undefined }}>
                <nav>
                    {this.walletStore.connected ? (
                        <div className="pocket" onClick={() => this.uiStore.showProfile(this.walletStore.account)}>
                            <div className="pocket-icon btn btn-box btn-creditcard-purple"/>
                            <div className="pocket-amount">
                                {this.walletStore.rtBalance?.toFixed(2) || (
                                    <div className="spinner-box small">
                                        <div className="circle-border">
                                            <div className="circle-core"/>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="pocket-symbol">NFTm</div>
                        </div>
                    ) : (
                        <button
                            type="button"
                            name="button"
                            className="btn btn-green btn-rectangle btn-avatar"
                            onClick={() => this.walletStore.connect()}
                        >
                            <img src={require('../static/images/avatars/metamask.png')} alt="metamask" />
                            <span className="text">Sign IN</span>
                        </button>
                    )}
                    <p
                        className={classNames({ active: activeTab === 'solarSystem', show: mobileMenuVisible })}
                        onClick={() => this.uiStore.toggleTab('solarSystem')}
                    >
                        Solar System
                    </p>
                    <p
                        className={classNames({ active: activeTab === 'aboutUs', show: mobileMenuVisible })}
                        onClick={() => this.uiStore.toggleTab('aboutUs')}
                    >
                        About
                    </p>
                </nav>
                <div className="social">
                    <p className="text">Share with your Friends</p>
                    <a href="#" className="btn btn-box btn-twitter"/>
                    <a href="#" className="btn btn-box btn-telegram"/>
                </div>
            </div>
        )
    }
}

export default NavModal;
