import React, { useEffect, useRef } from 'react';
import { observer } from "mobx-react";
import { resolve, useInjection } from "inversify-react";
import { UIStore, WalletStore } from "../../stores";
import { WithTranslation, withTranslation } from "react-i18next";
import classNames from "classnames";
import { ActiveTab } from "../../stores/UIStore";
import Timeout from "await-timeout";
import { reaction } from "mobx";

interface IHeaderProps extends WithTranslation {
}

const Header = withTranslation()(observer(({}: IHeaderProps) => {
    const tabLine = useRef<HTMLDivElement>();

    const walletStore = useInjection(WalletStore);
    const uiStore = useInjection(UIStore);

    const onSwitchTab = async (tab: ActiveTab) => {
        await uiStore.toggleTab(tab);
    }

    const onSignIn = async () => {
        if (walletStore.metamaskFound) {
            await walletStore.connect();
        } else {
            await uiStore.showModal('metamask');
        }
    }

    const onLogo = async () => {
        uiStore.toggleTab('solarSystem');
        uiStore.toggleMobileMenu(false);
        uiStore.zoomOutPlanets();
        uiStore.hideModals();
    }

    useEffect(() => {
        switch (uiStore.activeTab) {
            case 'solarSystem':
                tabLine.current.style.left = '0';
                break;
            case 'aboutUs':
                tabLine.current.style.left = '50%';
                break;
        }
        reaction(() => uiStore.activeTab, async (newVal, prevVal) => {
            if (newVal === prevVal) return;
            switch (newVal) {
                case "solarSystem":
                    tabLine.current.style.left = '0';
                    tabLine.current.style.width = '100%';
                    await Timeout.set(250);
                    tabLine.current.style.width = '';
                    break;
                case 'aboutUs':
                    tabLine.current.style.width = '100%';
                    await Timeout.set(250);
                    tabLine.current.style.left = '50%';
                    tabLine.current.style.width = '';
                    break;
            }
        })
    }, []);

    const { activeTab, mobileMenuVisible, openModalDelayed } = uiStore;

    return (
        <header data-country-iso="" data-planet-iso="" className={classNames({ 'background-blur': activeTab === 'aboutUs' })}>
            <button
                type="button"
                name="button"
                data-active="false"
                className="btn btn-box btn-chevron-left back-content-btn mobile-only" title="Back to solar system."
                data-move-out onClick={() => uiStore.zoomOutPlanets()}
            />
            <button
                type="button"
                name="button"
                data-active="false"
                className="btn btn-box btn-chevron-left back-content-btn mobile-only" title="Back to solar system."
                data-close-country onClick={() => uiStore.zoomOutPlanets()}
            />

            <img src={require('../../static/icons/logos/full-nftmap-logo.svg')}
                 alt="logo" className="logo desktop-only" onClick={onLogo} />
            <img src={require('../../static/icons/logos/nftmap-logo.svg')}
                 alt="logo" className="logo-mobile mobile-only" onClick={onLogo} />

            <div className="tab-content-wrapper desktop-only">
                <div className="tabs">
                    <p className="tab" data-tab=".solar-system" data-tab-name="solarSystem"
                       data-position="left" data-state={activeTab === 'solarSystem' && 'active'} data-line-target="tabLine" onClick={() => onSwitchTab('solarSystem')}>Solar System</p>
                    <p className="tab" data-tab=".about-us" data-tab-name="aboutUs" data-position="right"
                       data-state={activeTab === 'aboutUs' && 'active'} data-line-target="tabLine" onClick={() => onSwitchTab('aboutUs')}>About</p>
                </div>
                <div id="tabLine" className="active" ref={tabLine} />
            </div>
            {walletStore.connected ? (
                <div className="pocket desktop-only" onClick={() => uiStore.showProfile(walletStore.account)}>
                    <div className="pocket-icon btn btn-box btn-creditcard-purple"/>
                    <div className="pocket-amount">
                        {walletStore.rtBalance?.toFixed(2) || (
                            <div className="spinner-box small">
                                <div className="circle-border">
                                    <div className="circle-core"/>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="pocket-symbol">NFTm</div>
                </div>
            ) : (
                <button type="button" name="button" onClick={onSignIn}
                        className="btn btn-gray btn-rectangle btn-avatar desktop-only">
                    <img src={require('../../static/images/avatars/metamask.png')} alt="metamask" />
                    <span className="text">Sign IN</span>
                </button>
            )}
            <div className={classNames('nav-btn', 'mobile-only')} onClick={() => uiStore.toggleMobileMenu()}>
                <div className="bg"/>
                <div className="line-1"/>
                <div className="line-2"/>
                <div className="line-3"/>
            </div>
        </header>
    )
}));

export default Header;
