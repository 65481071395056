import React, { useState } from 'react';
import { observer } from "mobx-react";
import classNames from "classnames";
import ShareUrl from 'share-url'
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";

const MainPage = observer(() => {
    const [ email, setEmail ] = useState('');
    const [ validated, setValidated ] = useState(false);

    return (
        <>
            <div className="main-page-title">
                <h1 className="desktop-only">Buy yourself a Planet or a Country</h1>
                <p className="desktop-only">With NFT you Literally can do it!</p>
                {/*<div className="offer-card mobile-only">
                    <div className="offer">Subscribe to be among the <b>first buyers:</b></div>
                    <div className="card card-gray disabled">
                        <form className={classNames({ validated })} onSubmit={e => { e.preventDefault(); setValidated(true) }}>
                            <div className="field-wrapper">
                                <div className="custom-input">
                                    <input id="subscribeField2" type="text" name="subscribe"
                                           placeholder="Enter your e-mail here" required value={email} onChange={e => setEmail(e.target.value)} />
                                    <small className="invalid-text">Please, enter the correct mail address</small>
                                </div>
                            </div>
                            <button className="btn btn-green-2 btn-rectangle btn-buy" type="submit" name="button">Subscribe</button>
                        </form>
                    </div>
                </div>*/}
            </div>
            <div className="main-page-cards desktop-only">
                <div className="card card-gray card-purchased-item disabled" style={{ display: 'none' }}>
                    <div className="top-content">
                        <p className="main-number">0</p>
                        <img src={require('../../static/icons/cards/line.svg')} className="arrow" />
                        <p className="number">9</p>
                    </div>
                    <div className="bottom-content">
                        <p className="text">Planets left</p>
                        <button type="button" name="button" className="btn btn-box btn-chevron-right disabled"/>
                    </div>
                </div>
                <div data-trigger-move="earth" className="card card-gray card-purchased-item hover" style={{ display: 'none' }}>
                    <div className="top-content">
                        <p className="main-number">56</p>
                        <img src={require('../../static/icons/cards/line.svg')} className="arrow" />
                        <p className="number">197</p>
                    </div>
                    <div className="bottom-content">
                        <p className="text">Countries left</p>
                        <button type="button" name="button" className="btn btn-box btn-chevron-right"/>
                    </div>
                </div>
                {/*<div className="offer-card">
                    <div className="offer">Subscribe to be among the <b>first buyers:</b></div>
                    <div className="card card-gray disabled">
                        <form className={classNames({ validated })} onSubmit={e => { e.preventDefault(); setValidated(true) }}>
                            <div className="field-wrapper">
                                <label htmlFor="subscribeField">YOUR E-MAIL</label>
                                <div className="custom-input">
                                    <input id="subscribeField" type="text" name="subscribe" placeholder="Enter your e-mail here"
                                           required value={email} onChange={e => setEmail(e.target.value)} />
                                    <small className="invalid-text">Please, enter the correct mail address</small>
                                </div>
                            </div>
                            <button className="btn btn-green-2 btn-rectangle btn-buy" type="submit" name="button">Subscribe</button>
                        </form>
                    </div>
                </div>*/}
            </div>
            <div className="main-page-btns">
                <a href={`https://twitter.com/share?text=NFTmap.com&url=${encodeURIComponent(window.location.href)}`} target='_blank' className="btn btn-box btn-transparent-twitter"/>
                <a href={`https://t.me/share/url?url=${encodeURIComponent(window.location.href)}&text=NFTmap.com`} target='_blank' className="btn btn-box btn-transparent-telegram"/>
                <a onClick={() => { copy(window.location.href); toast.success('Link was copied') }} className="btn btn-box btn-transparent-link"/>
            </div>
            <div className="main-page-btns-2 desktop-only">
                <a href={`https://twitter.com/share?text=NFTmap.com&url=${encodeURIComponent(window.location.href)}`} target='_blank' className="btn btn-box btn-transparent-twitter"/>
                <a href={`https://t.me/share/url?url=${encodeURIComponent(window.location.href)}&text=NFTmap.com`} target='_blank' className="btn btn-box btn-transparent-telegram"/>
                <a onClick={() => { copy(window.location.href); toast.success('Link was copied') }} className="btn btn-box btn-transparent-link"/>
            </div>
        </>
    )
});

export default MainPage;
