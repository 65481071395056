import "reflect-metadata";
import "babel-polyfill";

import React from 'react';
import ReactDOM from 'react-dom';
import Application from './Application';
import './sass/main.scss';
import 'react-toastify/scss/main.scss';

import './i18n';
import { RootStore } from "./stores";
import { Provider } from "inversify-react";

export const rootStore = new RootStore();
const container = rootStore.container

ReactDOM.render(
    <Provider container={container}>
        <Application />
    </Provider>,
    document.getElementById('body')
);
