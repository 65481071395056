import React, { useMemo, useRef, useState } from 'react';
import { EntityStore, OrientationStore, UIStore } from "./stores";
import { ToastContainer } from "react-toastify";
import { useInjection } from "inversify-react";
import Header from "./components/new/Header";
import { Modals } from "./modals";
import Preloader from "./components/new/Preloader";
import { observer } from "mobx-react";
import classNames from "classnames";
import useAsyncEffect from "use-async-effect";
import Timeout from "await-timeout";
import MainPage from "./components/new/MainPage";
import SolarSystem from "./components/new/SolarSystem";
import PlanetInfoContainer from "./components/new/PlanetInfoContainer";
import CountriesInfo from "./components/new/CountriesInfo";
import Background from "./components/new/Background";
import AboutUs from "./components/new/AboutUs";
import RotatedScreen from "./components/new/RotatedScreen";
import { PLANETS_DATA } from "./utils/const";
import { Swipeable } from "react-swipeable";
import { PlanetsEnum } from "./stores/UIStore";

const Application = observer(() => {
    const root = useRef<HTMLDivElement>();

    const orientationStore = useInjection(OrientationStore);
    const uiStore = useInjection(UIStore);
    const entityStore = useInjection(EntityStore);

    const [ flex, setFlex ] = useState(false);
    const [ rotated, setRotated ] = useState(false);
    const [ loading, setLoading ] = useState(true);
    const [ preloading, setPreloading ] = useState(false);

    useAsyncEffect(async () => {
        if (orientationStore.isLandscape) {
            setFlex(true);
            await Timeout.set(20);
            setRotated(true);
        } else {
            setRotated(false);
            await Timeout.set(500);
            setFlex(false);
        }
    }, [orientationStore.isLandscape]);

    useAsyncEffect(async () => {
        await entityStore.readyPromise.promise;
        await Timeout.set(1500);
        setLoading(false);
        setPreloading(true);
        await Timeout.set(300);
        setPreloading(false);
    }, []);

    return (
        <div
            id="swipe"
            className={classNames(
                'root switch-content-visibility',
                typeof uiStore.activePlanet !== "undefined" && `planet-active-${PLANETS_DATA[uiStore.activePlanet].iso}`,
                uiStore.activeCountryDelayed && `country-active-${uiStore.activeCountryDelayed}`,
                uiStore.openModal === 'transaction' && `transaction-confirmed-${uiStore.transactionCode}`,
                `planet-mobile-active-${PLANETS_DATA[uiStore.mobileVisiblePlanet].iso}`,
                {
                    loading,
                    preloading,
                    rotated,
                    'active-menu': uiStore.mobileMenuVisible,
                    'zoom-in': uiStore.rootZoomIn,
                    zooming: uiStore.rootZooming,
                    'zoom-out': uiStore.rootZoomOut,
                    carousel: uiStore.rootCarousel,
                    'planets-opacity': uiStore.rootPlanetsOpacity,
                    scrolling: uiStore.rootScrolling,
                    'switch-checked': uiStore.switchContent,
                }
            )}
            style={{ overflow: 'hidden', display: flex ? 'flex' : undefined }}
        >
            <Swipeable
                onSwiped={() => console.log('swiped!')}
                onSwipedLeft={() => uiStore.swipePlanetsMobile(1)}
                onSwipedRight={() => uiStore.swipePlanetsMobile(-1)}
                trackMouse={uiStore.rootZoomOut}
                trackTouch={uiStore.rootZoomOut}
                style={{ width: '100%', height: '100%' }}
            >
                <div ref={root}/>

                <ToastContainer/>

                <Preloader loading={loading} preloading={preloading} />
                <Header />
                <div className="solar-system active-tab">
                    <MainPage />
                    <SolarSystem />
                    <PlanetInfoContainer root={root.current} />
                    <CountriesInfo />
                </div>
                <AboutUs />
                <RotatedScreen />
                <Background />

                <Modals />
            </Swipeable>
        </div>
    );
});

export default Application;
