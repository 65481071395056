import React, { useEffect, useState } from 'react';
import Background from "./Background";

const Preloader = ({ loading, preloading }: { loading: boolean, preloading: boolean }) => {
    const [ value, setValue ] = useState(0);

    useEffect(() => {
        const start = performance.now();
        let intervalTime = 1.5;
        let intervalCoef = 1.12;
        let value = 0;
        const interval = time => {
            if (preloading) {
                setValue(100);
                value = 100;
            }
            const delta = time - start;
            if (delta >= intervalTime) {
                setValue(++value);
                intervalTime *= intervalCoef;
            }
            if (value >= 100) return;
            requestAnimationFrame(interval);
        }
        requestAnimationFrame(interval);
    }, []);

    if (!loading && !preloading)
        return null;

    return (
        <div className="preloader-wrapper" style={{ opacity: loading ? 1 : 0 }}>
            <div className="preloader">
                <div className="spinner-box">
                    <div className="circle-border">
                        <div className="circle-core"/>
                    </div>
                </div>
                <div className="progress">
                    <div id="percent">{value}</div>%
                </div>
            </div>
            <Background />
        </div>
    )
};

export default Preloader;
