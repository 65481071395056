import React from 'react';
import { observer } from "mobx-react";
import { UIStore } from "../../stores";
import { useInjection } from "inversify-react";
import classNames from "classnames";

const Background = observer(() => {
    const uiStore = useInjection(UIStore);

    return (
        <div className="space-background">
            <div className="background-stars">
                <div className="star-layer-1">
                    <svg width="1828" height="1156" viewBox="0 0 1828 1156" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.27">
                            <path
    d="M477.83 773.984C477.768 774.061 477.684 774.117 477.589 774.146C477.495 774.175 477.394 774.175 477.299 774.147C477.204 774.118 477.12 774.062 477.057 773.986C476.995 773.91 476.956 773.816 476.946 773.718C476.936 773.619 476.956 773.52 477.002 773.433C477.048 773.346 477.12 773.274 477.207 773.227C477.294 773.181 477.393 773.161 477.492 773.17C477.59 773.18 477.683 773.218 477.76 773.281C477.811 773.323 477.853 773.374 477.884 773.432C477.915 773.489 477.934 773.553 477.941 773.618C477.947 773.683 477.941 773.749 477.922 773.812C477.903 773.875 477.872 773.934 477.83 773.984V773.984Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.39">
                            <path
    d="M642.99 931.024C642.802 931.254 642.55 931.423 642.266 931.509C641.983 931.596 641.68 931.597 641.396 931.511C641.111 931.426 640.859 931.258 640.67 931.029C640.482 930.799 640.365 930.52 640.336 930.224C640.306 929.929 640.365 929.631 640.504 929.37C640.644 929.108 640.857 928.893 641.119 928.753C641.38 928.612 641.678 928.553 641.973 928.581C642.268 928.61 642.548 928.726 642.778 928.913C643.086 929.165 643.281 929.529 643.321 929.925C643.36 930.321 643.241 930.716 642.99 931.024V931.024Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.15">
                            <path
    d="M657.218 783.27C657.155 783.346 657.071 783.403 656.977 783.432C656.882 783.461 656.781 783.461 656.686 783.432C656.591 783.404 656.507 783.348 656.444 783.272C656.381 783.195 656.343 783.102 656.333 783.003C656.323 782.905 656.342 782.806 656.389 782.718C656.435 782.631 656.506 782.559 656.594 782.513C656.681 782.466 656.78 782.446 656.878 782.455C656.977 782.465 657.07 782.503 657.147 782.566C657.249 782.65 657.314 782.771 657.328 782.903C657.341 783.035 657.301 783.167 657.218 783.27Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.25">
                            <path
    d="M603.739 973.823C603.551 974.052 603.299 974.221 603.016 974.307C602.732 974.394 602.429 974.394 602.145 974.308C601.861 974.223 601.609 974.055 601.42 973.826C601.232 973.597 601.115 973.317 601.086 973.022C601.056 972.726 601.115 972.429 601.254 972.167C601.393 971.905 601.607 971.69 601.868 971.55C602.13 971.409 602.427 971.35 602.722 971.378C603.017 971.406 603.297 971.522 603.527 971.709C603.835 971.961 604.031 972.326 604.071 972.722C604.11 973.118 603.991 973.514 603.739 973.823V973.823Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.49">
                            <path
    d="M829.976 822.192C829.839 822.334 829.662 822.432 829.469 822.474C829.276 822.516 829.075 822.499 828.891 822.427C828.707 822.354 828.549 822.229 828.436 822.067C828.324 821.904 828.262 821.712 828.258 821.514C828.254 821.317 828.309 821.122 828.416 820.956C828.523 820.79 828.677 820.659 828.858 820.58C829.039 820.5 829.239 820.477 829.434 820.512C829.628 820.547 829.808 820.638 829.95 820.776C830.045 820.867 830.121 820.976 830.174 821.096C830.227 821.217 830.255 821.347 830.258 821.478C830.26 821.61 830.236 821.741 830.188 821.863C830.14 821.986 830.068 822.097 829.976 822.192V822.192Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.54">
                            <path
    d="M771.579 891.412C771.374 891.626 771.109 891.774 770.819 891.837C770.53 891.901 770.227 891.877 769.951 891.768C769.675 891.66 769.437 891.471 769.268 891.228C769.099 890.984 769.006 890.695 769 890.399C768.995 890.102 769.078 889.81 769.238 889.56C769.398 889.311 769.629 889.114 769.901 888.996C770.173 888.877 770.474 888.842 770.766 888.895C771.058 888.948 771.328 889.086 771.541 889.292C771.683 889.429 771.796 889.592 771.875 889.773C771.954 889.953 771.996 890.147 772 890.344C772.003 890.541 771.968 890.737 771.896 890.92C771.824 891.103 771.716 891.271 771.579 891.412Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.42">
                            <path
    d="M805.115 1018.62C805.047 1018.69 804.958 1018.74 804.862 1018.76C804.765 1018.78 804.665 1018.78 804.573 1018.74C804.481 1018.7 804.401 1018.64 804.345 1018.56C804.289 1018.48 804.258 1018.38 804.256 1018.28C804.254 1018.18 804.282 1018.09 804.335 1018C804.388 1017.92 804.465 1017.85 804.556 1017.82C804.647 1017.78 804.747 1017.76 804.844 1017.78C804.941 1017.8 805.031 1017.84 805.103 1017.91C805.15 1017.96 805.188 1018.01 805.214 1018.07C805.24 1018.13 805.255 1018.2 805.256 1018.26C805.257 1018.33 805.245 1018.4 805.221 1018.46C805.197 1018.52 805.161 1018.57 805.115 1018.62V1018.62Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.24">
                            <path
    d="M805.749 799.714C805.543 799.928 805.279 800.076 804.989 800.14C804.7 800.204 804.398 800.18 804.121 800.072C803.845 799.964 803.607 799.777 803.437 799.533C803.268 799.29 803.174 799.001 803.168 798.705C803.162 798.408 803.245 798.116 803.405 797.867C803.565 797.617 803.795 797.42 804.067 797.301C804.339 797.182 804.64 797.147 804.932 797.199C805.224 797.252 805.494 797.389 805.707 797.595C805.849 797.732 805.963 797.895 806.042 798.075C806.121 798.255 806.164 798.449 806.168 798.646C806.172 798.843 806.137 799.039 806.065 799.222C805.993 799.405 805.886 799.572 805.749 799.714V799.714Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.29">
                            <path
    d="M825.579 754.075C825.442 754.218 825.266 754.317 825.072 754.36C824.879 754.403 824.677 754.387 824.493 754.315C824.309 754.242 824.15 754.117 824.037 753.954C823.924 753.792 823.861 753.599 823.858 753.401C823.854 753.203 823.909 753.009 824.016 752.842C824.123 752.675 824.278 752.544 824.459 752.466C824.641 752.387 824.842 752.364 825.037 752.399C825.231 752.435 825.411 752.527 825.554 752.665C825.743 752.849 825.852 753.101 825.857 753.365C825.862 753.629 825.762 753.885 825.579 754.075V754.075Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.25">
                            <path
    d="M991.431 748.721C991.293 748.863 991.116 748.961 990.923 749.003C990.73 749.045 990.528 749.028 990.344 748.956C990.16 748.883 990.002 748.757 989.889 748.595C989.777 748.432 989.715 748.24 989.711 748.042C989.708 747.844 989.763 747.65 989.87 747.483C989.977 747.317 990.13 747.186 990.312 747.107C990.493 747.028 990.694 747.004 990.888 747.039C991.083 747.074 991.263 747.166 991.406 747.303C991.596 747.488 991.706 747.741 991.711 748.007C991.715 748.272 991.615 748.529 991.431 748.721V748.721Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.24">
                            <path
    d="M877.809 723.054C877.604 723.269 877.339 723.417 877.049 723.481C876.759 723.545 876.456 723.521 876.18 723.413C875.903 723.305 875.665 723.117 875.495 722.873C875.326 722.629 875.232 722.34 875.227 722.043C875.221 721.746 875.304 721.454 875.465 721.204C875.626 720.955 875.857 720.758 876.129 720.64C876.402 720.521 876.703 720.487 876.995 720.54C877.288 720.593 877.557 720.732 877.771 720.939C878.055 721.215 878.219 721.592 878.226 721.989C878.234 722.385 878.084 722.768 877.809 723.054V723.054Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.34">
                            <path
    d="M487.137 873.555C487.075 873.632 486.991 873.688 486.896 873.717C486.801 873.746 486.7 873.746 486.606 873.717C486.511 873.689 486.427 873.633 486.364 873.556C486.301 873.48 486.262 873.387 486.253 873.288C486.243 873.19 486.262 873.091 486.309 873.003C486.355 872.916 486.426 872.845 486.513 872.798C486.601 872.751 486.7 872.731 486.798 872.741C486.896 872.75 486.99 872.789 487.066 872.851C487.117 872.893 487.159 872.944 487.191 873.002C487.222 873.06 487.241 873.123 487.247 873.188C487.254 873.254 487.248 873.32 487.229 873.383C487.21 873.446 487.179 873.504 487.137 873.555Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.35">
                            <path
    d="M596.529 632.474C596.341 632.704 596.089 632.872 595.806 632.959C595.522 633.045 595.219 633.046 594.935 632.96C594.651 632.874 594.399 632.706 594.211 632.477C594.022 632.248 593.906 631.968 593.877 631.672C593.847 631.377 593.906 631.08 594.045 630.818C594.185 630.556 594.399 630.341 594.66 630.201C594.922 630.061 595.219 630.002 595.514 630.031C595.809 630.059 596.089 630.175 596.319 630.363C596.471 630.488 596.598 630.641 596.691 630.815C596.784 630.989 596.842 631.179 596.862 631.375C596.881 631.571 596.862 631.769 596.805 631.958C596.748 632.146 596.654 632.322 596.529 632.474V632.474Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.36">
                            <g style={{ mixBlendMode: 'screen' }}>
                                <path
    d="M766.457 712.267C766.269 712.497 766.017 712.666 765.734 712.752C765.45 712.838 765.147 712.839 764.863 712.753C764.579 712.667 764.327 712.499 764.139 712.27C763.95 712.041 763.834 711.762 763.804 711.467C763.775 711.171 763.833 710.874 763.972 710.612C764.112 710.35 764.325 710.136 764.587 709.995C764.848 709.855 765.145 709.795 765.44 709.823C765.735 709.852 766.015 709.967 766.245 710.154C766.398 710.279 766.525 710.433 766.618 710.606C766.711 710.78 766.77 710.971 766.789 711.167C766.809 711.363 766.79 711.562 766.733 711.75C766.676 711.939 766.582 712.115 766.457 712.267V712.267Z"
    fill="white"/>
                            </g>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.61">
                            <path
    d="M774.324 871.596C774.261 871.673 774.178 871.73 774.083 871.759C773.989 871.788 773.888 871.789 773.793 871.761C773.698 871.732 773.614 871.677 773.551 871.601C773.488 871.525 773.449 871.432 773.438 871.333C773.428 871.235 773.447 871.136 773.493 871.048C773.539 870.961 773.61 870.889 773.697 870.842C773.784 870.795 773.883 870.774 773.981 870.783C774.08 870.792 774.173 870.831 774.25 870.893C774.301 870.934 774.344 870.985 774.375 871.043C774.406 871.1 774.426 871.164 774.433 871.229C774.44 871.294 774.434 871.361 774.415 871.423C774.396 871.486 774.365 871.545 774.324 871.596V871.596Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.17">
                            <path
    d="M456.227 343.118C456.102 343.271 455.934 343.384 455.744 343.441C455.555 343.499 455.353 343.499 455.164 343.442C454.975 343.385 454.806 343.273 454.681 343.12C454.555 342.967 454.478 342.781 454.458 342.584C454.438 342.387 454.478 342.189 454.571 342.014C454.664 341.839 454.806 341.696 454.981 341.603C455.155 341.51 455.353 341.47 455.55 341.489C455.747 341.508 455.934 341.585 456.087 341.711C456.292 341.879 456.422 342.122 456.448 342.386C456.474 342.649 456.395 342.913 456.227 343.118V343.118Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.24">
                            <path
    d="M807.074 820.423C806.95 820.577 806.782 820.691 806.593 820.749C806.403 820.808 806.201 820.809 806.011 820.752C805.821 820.696 805.652 820.584 805.526 820.431C805.4 820.278 805.322 820.091 805.302 819.894C805.282 819.697 805.321 819.498 805.414 819.323C805.507 819.148 805.65 819.005 805.825 818.911C806 818.818 806.198 818.778 806.395 818.798C806.593 818.817 806.78 818.895 806.933 819.021C807.136 819.189 807.265 819.43 807.292 819.693C807.318 819.956 807.24 820.218 807.074 820.423V820.423Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.18">
                            <path
    d="M248.46 781.247C248.335 781.4 248.167 781.512 247.978 781.57C247.789 781.628 247.587 781.629 247.397 781.572C247.208 781.515 247.04 781.403 246.914 781.25C246.788 781.097 246.71 780.911 246.691 780.714C246.671 780.517 246.71 780.319 246.803 780.144C246.896 779.969 247.038 779.826 247.212 779.732C247.387 779.639 247.585 779.599 247.782 779.618C247.979 779.637 248.165 779.714 248.319 779.839C248.524 780.007 248.654 780.25 248.681 780.514C248.707 780.777 248.628 781.041 248.46 781.247V781.247Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.48">
                            <path
    d="M368.031 853.086C367.844 853.316 367.592 853.485 367.308 853.572C367.025 853.659 366.721 853.659 366.437 853.574C366.153 853.488 365.901 853.32 365.712 853.091C365.523 852.862 365.406 852.583 365.377 852.287C365.347 851.992 365.406 851.694 365.545 851.432C365.684 851.17 365.898 850.955 366.16 850.815C366.421 850.675 366.719 850.615 367.014 850.644C367.309 850.672 367.59 850.788 367.819 850.976C368.127 851.228 368.322 851.591 368.362 851.987C368.401 852.382 368.283 852.778 368.031 853.086V853.086Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.36">
                            <path
    d="M99.5055 895.689C99.3176 895.919 99.0658 896.087 98.7821 896.174C98.4984 896.26 98.1954 896.261 97.9115 896.175C97.6276 896.089 97.3755 895.921 97.1871 895.692C96.9987 895.462 96.8825 895.182 96.8531 894.887C96.8237 894.592 96.8825 894.295 97.022 894.033C97.1616 893.771 97.3756 893.556 97.637 893.416C97.8985 893.276 98.1955 893.217 98.4907 893.245C98.7859 893.274 99.066 893.39 99.2954 893.578C99.448 893.703 99.5745 893.856 99.6677 894.03C99.7608 894.203 99.8188 894.394 99.8383 894.59C99.8579 894.786 99.8385 894.984 99.7814 895.173C99.7243 895.361 99.6305 895.537 99.5055 895.689V895.689Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.11">
                            <path
    d="M317.543 538.106C317.481 538.182 317.397 538.239 317.302 538.267C317.208 538.296 317.107 538.297 317.012 538.268C316.917 538.239 316.833 538.183 316.77 538.107C316.707 538.031 316.669 537.938 316.659 537.839C316.649 537.741 316.668 537.642 316.715 537.554C316.761 537.467 316.833 537.395 316.92 537.349C317.007 537.302 317.106 537.282 317.204 537.291C317.303 537.301 317.396 537.339 317.473 537.402C317.524 537.443 317.566 537.495 317.597 537.553C317.628 537.61 317.647 537.674 317.654 537.739C317.66 537.805 317.654 537.871 317.635 537.933C317.616 537.996 317.585 538.055 317.543 538.106V538.106Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.09">
                            <path
    d="M290.108 778.778C289.921 779.009 289.669 779.179 289.385 779.267C289.101 779.354 288.797 779.356 288.513 779.271C288.228 779.186 287.975 779.018 287.785 778.789C287.596 778.56 287.479 778.28 287.449 777.984C287.419 777.688 287.478 777.39 287.617 777.128C287.757 776.865 287.972 776.65 288.234 776.51C288.496 776.369 288.793 776.31 289.089 776.339C289.385 776.368 289.665 776.485 289.895 776.674C290.2 776.926 290.394 777.288 290.434 777.682C290.474 778.076 290.356 778.47 290.108 778.778V778.778Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.38">
                            <path
    d="M241.023 697.21C240.961 697.287 240.877 697.343 240.782 697.373C240.687 697.402 240.586 697.402 240.491 697.374C240.396 697.345 240.312 697.289 240.249 697.213C240.186 697.136 240.147 697.043 240.137 696.944C240.127 696.846 240.147 696.747 240.194 696.659C240.24 696.572 240.312 696.5 240.399 696.454C240.487 696.407 240.586 696.387 240.684 696.397C240.783 696.407 240.876 696.446 240.953 696.509C241.055 696.593 241.119 696.714 241.132 696.845C241.145 696.976 241.106 697.107 241.023 697.21V697.21Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.19">
                            <path
    d="M333.092 760.358C333.03 760.435 332.946 760.492 332.852 760.521C332.757 760.55 332.656 760.551 332.561 760.523C332.466 760.494 332.381 760.438 332.318 760.362C332.255 760.286 332.216 760.192 332.206 760.094C332.196 759.995 332.215 759.895 332.262 759.808C332.309 759.72 332.38 759.649 332.468 759.602C332.555 759.555 332.654 759.536 332.753 759.545C332.852 759.555 332.945 759.594 333.022 759.657C333.123 759.741 333.187 759.862 333.201 759.993C333.214 760.124 333.175 760.255 333.092 760.358V760.358Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.23">
                            <path
    d="M391.98 742.74C391.855 742.893 391.687 743.005 391.498 743.063C391.309 743.12 391.107 743.12 390.918 743.063C390.728 743.006 390.56 742.894 390.435 742.741C390.309 742.589 390.232 742.402 390.212 742.205C390.192 742.009 390.231 741.811 390.324 741.636C390.417 741.462 390.559 741.318 390.733 741.225C390.907 741.131 391.105 741.091 391.302 741.11C391.499 741.129 391.686 741.205 391.839 741.33C391.941 741.413 392.025 741.516 392.088 741.632C392.15 741.748 392.189 741.875 392.202 742.006C392.215 742.137 392.202 742.269 392.164 742.395C392.126 742.521 392.064 742.638 391.98 742.74V742.74Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.13">
                            <path
    d="M544.182 874.548C544.119 874.625 544.035 874.681 543.941 874.709C543.846 874.738 543.745 874.738 543.65 874.71C543.556 874.681 543.472 874.625 543.409 874.549C543.346 874.472 543.307 874.379 543.297 874.281C543.288 874.182 543.307 874.083 543.353 873.996C543.4 873.909 543.471 873.837 543.558 873.79C543.645 873.743 543.744 873.723 543.843 873.733C543.941 873.742 544.034 873.78 544.111 873.843C544.214 873.927 544.279 874.049 544.292 874.181C544.306 874.313 544.266 874.445 544.182 874.548V874.548Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.48">
                            <path
    d="M518.953 883.144C518.766 883.374 518.514 883.542 518.231 883.629C517.947 883.716 517.644 883.716 517.36 883.631C517.076 883.545 516.824 883.377 516.635 883.149C516.447 882.92 516.33 882.64 516.3 882.345C516.271 882.049 516.329 881.752 516.469 881.49C516.608 881.228 516.822 881.014 517.083 880.874C517.345 880.733 517.642 880.674 517.937 880.702C518.232 880.731 518.512 880.847 518.742 881.034C518.895 881.159 519.021 881.312 519.114 881.486C519.208 881.659 519.266 881.849 519.286 882.045C519.305 882.241 519.286 882.439 519.229 882.628C519.172 882.816 519.078 882.992 518.953 883.144V883.144Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.51">
                            <path
    d="M467.487 841.043C467.362 841.196 467.194 841.309 467.005 841.367C466.816 841.425 466.614 841.425 466.424 841.368C466.235 841.311 466.067 841.199 465.941 841.046C465.815 840.894 465.738 840.707 465.718 840.51C465.698 840.313 465.737 840.115 465.83 839.94C465.923 839.766 466.066 839.623 466.24 839.529C466.414 839.435 466.613 839.396 466.809 839.415C467.006 839.434 467.193 839.511 467.346 839.636C467.551 839.804 467.681 840.047 467.708 840.311C467.734 840.574 467.655 840.838 467.487 841.043V841.043Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.22">
                            <path
    d="M687.7 852.479C687.575 852.631 687.407 852.744 687.218 852.801C687.029 852.859 686.827 852.859 686.638 852.801C686.448 852.744 686.28 852.632 686.155 852.479C686.029 852.326 685.952 852.139 685.933 851.943C685.913 851.746 685.952 851.548 686.045 851.373C686.138 851.199 686.281 851.056 686.455 850.962C686.63 850.869 686.828 850.829 687.024 850.848C687.221 850.868 687.408 850.945 687.561 851.07C687.662 851.153 687.747 851.256 687.809 851.371C687.871 851.487 687.91 851.614 687.923 851.745C687.936 851.876 687.923 852.008 687.885 852.134C687.846 852.26 687.784 852.377 687.7 852.479V852.479Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.3">
                            <path
    d="M937.741 820.722C937.616 820.876 937.448 820.988 937.259 821.046C937.07 821.104 936.867 821.104 936.678 821.047C936.489 820.99 936.321 820.878 936.195 820.725C936.069 820.573 935.991 820.386 935.972 820.189C935.952 819.993 935.991 819.794 936.084 819.62C936.177 819.445 936.32 819.302 936.494 819.209C936.668 819.115 936.867 819.075 937.063 819.095C937.26 819.114 937.447 819.191 937.6 819.316C937.702 819.399 937.786 819.501 937.848 819.617C937.91 819.733 937.949 819.86 937.962 819.99C937.975 820.121 937.962 820.253 937.924 820.378C937.886 820.504 937.824 820.621 937.741 820.722V820.722Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.48">
                            <path
    d="M616.108 1030.87C616.046 1030.95 615.962 1031 615.867 1031.03C615.772 1031.06 615.671 1031.06 615.577 1031.03C615.482 1031.01 615.398 1030.95 615.335 1030.87C615.272 1030.8 615.233 1030.7 615.223 1030.6C615.213 1030.51 615.233 1030.41 615.279 1030.32C615.326 1030.23 615.397 1030.16 615.484 1030.11C615.572 1030.07 615.671 1030.05 615.769 1030.06C615.868 1030.07 615.961 1030.11 616.038 1030.17C616.14 1030.25 616.205 1030.37 616.218 1030.51C616.231 1030.64 616.192 1030.77 616.108 1030.87V1030.87Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.19">
                            <g style={{ mixBlendMode: 'screen' }}>
                                <path
    d="M279.887 743.609C279.699 743.839 279.448 744.008 279.164 744.095C278.88 744.182 278.577 744.183 278.293 744.097C278.009 744.012 277.756 743.844 277.568 743.615C277.379 743.387 277.262 743.107 277.232 742.812C277.202 742.516 277.261 742.219 277.4 741.957C277.539 741.695 277.753 741.48 278.014 741.339C278.275 741.199 278.572 741.139 278.868 741.167C279.163 741.195 279.443 741.311 279.673 741.498C279.981 741.75 280.177 742.114 280.217 742.509C280.257 742.905 280.138 743.301 279.887 743.609V743.609Z"
    fill="white"/>
                            </g>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.14">
                            <path
    d="M682.122 807.117C682.059 807.193 681.975 807.25 681.881 807.279C681.786 807.308 681.685 807.308 681.59 807.28C681.496 807.251 681.411 807.195 681.349 807.119C681.286 807.042 681.247 806.949 681.237 806.851C681.227 806.752 681.247 806.653 681.293 806.566C681.34 806.478 681.411 806.407 681.498 806.36C681.585 806.313 681.684 806.294 681.783 806.303C681.881 806.313 681.975 806.351 682.051 806.414C682.154 806.498 682.219 806.619 682.232 806.751C682.245 806.883 682.205 807.014 682.122 807.117V807.117Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.48">
                            <path
    d="M345.055 889.496C344.93 889.649 344.762 889.761 344.573 889.819C344.384 889.877 344.182 889.877 343.993 889.82C343.803 889.763 343.635 889.651 343.509 889.499C343.384 889.346 343.306 889.16 343.286 888.963C343.267 888.766 343.306 888.568 343.398 888.393C343.491 888.219 343.634 888.076 343.808 887.982C343.982 887.889 344.181 887.849 344.377 887.868C344.574 887.887 344.761 887.964 344.914 888.089C345.016 888.172 345.1 888.274 345.162 888.39C345.224 888.506 345.263 888.633 345.276 888.763C345.289 888.894 345.277 889.026 345.239 889.151C345.201 889.277 345.138 889.394 345.055 889.496V889.496Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.16">
                            <g style={{ mixBlendMode: 'screen' }}>
                                <path
    d="M531.561 915.387C531.373 915.617 531.122 915.786 530.838 915.873C530.555 915.959 530.252 915.96 529.968 915.874C529.684 915.789 529.432 915.621 529.243 915.392C529.054 915.163 528.938 914.883 528.908 914.588C528.878 914.293 528.937 913.996 529.076 913.734C529.215 913.472 529.429 913.257 529.69 913.116C529.951 912.976 530.248 912.916 530.543 912.944C530.839 912.973 531.119 913.088 531.349 913.276C531.501 913.4 531.628 913.554 531.721 913.727C531.815 913.901 531.873 914.091 531.893 914.287C531.913 914.483 531.893 914.682 531.837 914.87C531.78 915.059 531.686 915.235 531.561 915.387V915.387Z"
    fill="white"/>
                            </g>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.35">
                            <path
    d="M522.02 953.881C521.832 954.111 521.581 954.28 521.297 954.366C521.013 954.452 520.71 954.453 520.426 954.367C520.142 954.281 519.89 954.113 519.701 953.884C519.513 953.655 519.396 953.375 519.367 953.079C519.337 952.784 519.396 952.487 519.536 952.225C519.675 951.963 519.889 951.748 520.151 951.608C520.412 951.468 520.709 951.408 521.004 951.437C521.3 951.465 521.58 951.581 521.81 951.769C522.117 952.021 522.312 952.385 522.352 952.781C522.391 953.178 522.272 953.573 522.02 953.881V953.881Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.15">
                            <path
    d="M499.636 1018.38C499.574 1018.46 499.49 1018.51 499.395 1018.54C499.301 1018.57 499.199 1018.57 499.105 1018.54C499.01 1018.51 498.926 1018.46 498.864 1018.38C498.801 1018.3 498.762 1018.21 498.752 1018.11C498.743 1018.01 498.762 1017.91 498.809 1017.83C498.856 1017.74 498.927 1017.67 499.014 1017.62C499.101 1017.57 499.2 1017.56 499.299 1017.56C499.397 1017.57 499.491 1017.61 499.567 1017.68C499.618 1017.72 499.66 1017.77 499.691 1017.83C499.722 1017.88 499.741 1017.95 499.748 1018.01C499.754 1018.08 499.748 1018.14 499.728 1018.21C499.709 1018.27 499.678 1018.33 499.636 1018.38V1018.38Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.64">
                            <path
    d="M683.321 362.088C683.196 362.242 683.028 362.354 682.839 362.412C682.649 362.47 682.447 362.471 682.257 362.414C682.068 362.356 681.9 362.244 681.774 362.092C681.648 361.939 681.57 361.752 681.55 361.555C681.53 361.358 681.569 361.16 681.662 360.985C681.755 360.81 681.898 360.666 682.072 360.573C682.246 360.479 682.444 360.439 682.642 360.458C682.839 360.477 683.026 360.553 683.179 360.679C683.384 360.848 683.513 361.09 683.54 361.354C683.566 361.618 683.488 361.882 683.321 362.088Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.06">
                            <path
    d="M650.75 503.73C650.625 503.884 650.457 503.996 650.268 504.054C650.079 504.112 649.877 504.113 649.688 504.056C649.499 503.999 649.331 503.887 649.205 503.734C649.079 503.581 649.001 503.395 648.982 503.198C648.962 503.001 649.001 502.803 649.094 502.628C649.186 502.453 649.329 502.31 649.503 502.217C649.677 502.123 649.875 502.083 650.072 502.102C650.269 502.121 650.456 502.198 650.609 502.324C650.71 502.406 650.795 502.509 650.857 502.624C650.92 502.74 650.958 502.867 650.972 502.998C650.985 503.128 650.972 503.26 650.934 503.386C650.896 503.512 650.833 503.629 650.75 503.73V503.73Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.59">
                            <path
    d="M711.414 784.792C711.226 785.022 710.975 785.19 710.692 785.276C710.408 785.362 710.106 785.362 709.822 785.276C709.539 785.19 709.288 785.022 709.1 784.792C708.912 784.563 708.796 784.283 708.767 783.988C708.738 783.692 708.797 783.395 708.936 783.133C709.076 782.872 709.29 782.657 709.551 782.517C709.812 782.377 710.108 782.318 710.403 782.347C710.698 782.376 710.977 782.492 711.206 782.68C711.36 782.804 711.487 782.957 711.581 783.131C711.675 783.305 711.733 783.496 711.753 783.692C711.772 783.889 711.752 784.088 711.694 784.276C711.636 784.465 711.541 784.641 711.414 784.792V784.792Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.16">
                            <path
    d="M189.693 565.077C189.425 565.373 189.054 565.554 188.658 565.583C188.261 565.611 187.869 565.486 187.562 565.232C187.254 564.978 187.055 564.615 187.005 564.218C186.954 563.82 187.057 563.419 187.292 563.095C187.56 562.8 187.93 562.619 188.327 562.59C188.723 562.561 189.115 562.687 189.423 562.94C189.73 563.194 189.929 563.557 189.98 563.955C190.03 564.352 189.927 564.754 189.693 565.077Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.56">
                            <path
    d="M325.619 374.188C325.44 374.385 325.193 374.506 324.929 374.525C324.665 374.544 324.403 374.461 324.198 374.291C323.993 374.122 323.86 373.88 323.827 373.615C323.793 373.35 323.862 373.083 324.018 372.867C324.197 372.67 324.444 372.549 324.708 372.53C324.972 372.511 325.234 372.594 325.439 372.764C325.644 372.933 325.777 373.175 325.81 373.44C325.844 373.705 325.775 373.973 325.619 374.188V374.188Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.6">
                            <path
    d="M214.623 634.185C214.56 634.261 214.476 634.317 214.381 634.346C214.287 634.375 214.186 634.375 214.091 634.346C213.997 634.317 213.913 634.261 213.85 634.185C213.787 634.108 213.748 634.015 213.739 633.916C213.729 633.818 213.749 633.719 213.795 633.632C213.842 633.544 213.913 633.473 214 633.426C214.088 633.38 214.187 633.36 214.285 633.37C214.384 633.379 214.477 633.418 214.553 633.481C214.604 633.522 214.646 633.573 214.677 633.631C214.708 633.689 214.727 633.753 214.734 633.818C214.74 633.884 214.734 633.95 214.715 634.012C214.696 634.075 214.664 634.134 214.623 634.185V634.185Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.37">
                            <path
    d="M149.979 466.825C149.854 466.978 149.686 467.09 149.497 467.148C149.308 467.206 149.106 467.206 148.917 467.149C148.727 467.092 148.559 466.98 148.433 466.828C148.308 466.675 148.23 466.489 148.21 466.292C148.19 466.095 148.229 465.897 148.322 465.722C148.415 465.548 148.557 465.404 148.731 465.311C148.906 465.217 149.104 465.177 149.3 465.196C149.497 465.215 149.684 465.292 149.837 465.417C149.939 465.5 150.024 465.602 150.086 465.718C150.148 465.834 150.187 465.96 150.2 466.091C150.213 466.222 150.201 466.354 150.163 466.48C150.125 466.606 150.062 466.723 149.979 466.825Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.24">
                            <path
    d="M641.119 858.843C640.932 859.074 640.681 859.243 640.397 859.331C640.114 859.418 639.81 859.419 639.526 859.334C639.242 859.249 638.989 859.081 638.8 858.852C638.611 858.624 638.494 858.344 638.465 858.048C638.435 857.753 638.493 857.455 638.633 857.193C638.772 856.931 638.986 856.717 639.248 856.576C639.51 856.436 639.807 856.377 640.102 856.406C640.398 856.435 640.678 856.551 640.907 856.739C641.059 856.863 641.186 857.016 641.279 857.189C641.372 857.362 641.43 857.552 641.449 857.747C641.469 857.943 641.45 858.14 641.394 858.328C641.337 858.516 641.244 858.691 641.119 858.843V858.843Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.59">
                            <path
    d="M841.979 864.804C841.916 864.881 841.832 864.937 841.738 864.966C841.643 864.995 841.542 864.996 841.447 864.967C841.352 864.938 841.268 864.882 841.205 864.806C841.143 864.729 841.104 864.636 841.094 864.537C841.084 864.439 841.104 864.34 841.151 864.252C841.198 864.165 841.269 864.094 841.356 864.047C841.444 864 841.543 863.981 841.641 863.991C841.74 864.001 841.833 864.04 841.909 864.103C841.96 864.144 842.002 864.195 842.033 864.253C842.064 864.311 842.083 864.374 842.089 864.439C842.096 864.504 842.089 864.57 842.07 864.633C842.051 864.695 842.02 864.754 841.979 864.804Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.51">
                            <path
    d="M603.357 1020.42C603.232 1020.57 603.064 1020.69 602.875 1020.75C602.686 1020.8 602.483 1020.81 602.294 1020.75C602.104 1020.69 601.935 1020.58 601.809 1020.43C601.683 1020.27 601.605 1020.09 601.585 1019.89C601.565 1019.69 601.604 1019.49 601.697 1019.32C601.79 1019.14 601.933 1019 602.108 1018.91C602.283 1018.81 602.481 1018.77 602.678 1018.79C602.875 1018.81 603.062 1018.89 603.216 1019.02C603.419 1019.18 603.548 1019.43 603.575 1019.69C603.602 1019.95 603.523 1020.22 603.357 1020.42V1020.42Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.2">
                            <path
    d="M643.301 1021.39C643.238 1021.46 643.154 1021.52 643.059 1021.55C642.965 1021.58 642.864 1021.58 642.769 1021.55C642.674 1021.52 642.59 1021.46 642.528 1021.39C642.465 1021.31 642.426 1021.22 642.416 1021.12C642.407 1021.02 642.426 1020.92 642.473 1020.83C642.52 1020.75 642.591 1020.68 642.678 1020.63C642.765 1020.58 642.864 1020.56 642.963 1020.57C643.061 1020.58 643.155 1020.62 643.231 1020.68C643.282 1020.73 643.324 1020.78 643.355 1020.83C643.386 1020.89 643.405 1020.96 643.412 1021.02C643.418 1021.09 643.412 1021.15 643.393 1021.22C643.373 1021.28 643.342 1021.34 643.301 1021.39V1021.39Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.09">
                            <path
    d="M738.92 982.7C738.795 982.854 738.627 982.966 738.438 983.024C738.249 983.082 738.046 983.083 737.857 983.026C737.667 982.969 737.499 982.857 737.373 982.704C737.247 982.551 737.169 982.364 737.15 982.167C737.13 981.97 737.169 981.772 737.262 981.597C737.355 981.422 737.497 981.279 737.672 981.185C737.846 981.092 738.044 981.052 738.241 981.071C738.438 981.09 738.625 981.167 738.779 981.292C738.983 981.461 739.113 981.703 739.139 981.967C739.166 982.231 739.087 982.494 738.92 982.7Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.64">
                            <path
    d="M670.888 1065.9C670.763 1066.05 670.595 1066.17 670.406 1066.22C670.217 1066.28 670.015 1066.28 669.826 1066.22C669.637 1066.17 669.469 1066.05 669.344 1065.9C669.219 1065.75 669.141 1065.56 669.122 1065.36C669.103 1065.17 669.142 1064.97 669.235 1064.79C669.328 1064.62 669.471 1064.48 669.645 1064.38C669.819 1064.29 670.017 1064.25 670.213 1064.27C670.41 1064.29 670.596 1064.37 670.749 1064.49C670.851 1064.58 670.936 1064.68 670.998 1064.79C671.061 1064.91 671.099 1065.04 671.112 1065.17C671.125 1065.3 671.112 1065.43 671.074 1065.56C671.035 1065.68 670.972 1065.8 670.888 1065.9V1065.9Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.06">
                            <path
    d="M328.318 749.117C328.192 749.27 328.025 749.382 327.836 749.439C327.647 749.497 327.445 749.497 327.256 749.439C327.067 749.382 326.899 749.27 326.774 749.117C326.648 748.964 326.571 748.778 326.552 748.581C326.532 748.385 326.572 748.187 326.665 748.013C326.758 747.838 326.9 747.696 327.074 747.602C327.249 747.509 327.446 747.47 327.643 747.489C327.84 747.508 328.026 747.586 328.179 747.711C328.281 747.793 328.366 747.896 328.428 748.011C328.49 748.127 328.529 748.254 328.542 748.385C328.555 748.516 328.542 748.648 328.503 748.774C328.465 748.899 328.402 749.016 328.318 749.117Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.45">
                            <path
    d="M771.154 1033.43C770.967 1033.66 770.715 1033.83 770.432 1033.91C770.148 1034 769.845 1034 769.561 1033.92C769.277 1033.83 769.025 1033.66 768.837 1033.43C768.648 1033.2 768.531 1032.93 768.502 1032.63C768.472 1032.33 768.53 1032.04 768.67 1031.78C768.809 1031.51 769.023 1031.3 769.284 1031.16C769.545 1031.02 769.842 1030.96 770.137 1030.99C770.433 1031.02 770.713 1031.13 770.942 1031.32C771.095 1031.44 771.222 1031.6 771.315 1031.77C771.409 1031.94 771.467 1032.13 771.487 1032.33C771.506 1032.53 771.487 1032.72 771.43 1032.91C771.373 1033.1 771.279 1033.28 771.154 1033.43Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.52">
                            <path
    d="M186.409 874.215C186.346 874.291 186.262 874.348 186.168 874.377C186.073 874.406 185.972 874.406 185.877 874.377C185.783 874.349 185.698 874.293 185.636 874.217C185.573 874.14 185.534 874.047 185.524 873.948C185.514 873.85 185.534 873.751 185.58 873.663C185.627 873.576 185.698 873.505 185.785 873.458C185.872 873.411 185.972 873.391 186.07 873.401C186.169 873.41 186.262 873.449 186.338 873.512C186.441 873.596 186.506 873.717 186.519 873.849C186.532 873.981 186.493 874.112 186.409 874.215V874.215Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.1">
                            <path
    d="M190.399 1074.84C190.336 1074.92 190.253 1074.97 190.158 1075C190.063 1075.03 189.962 1075.03 189.868 1075C189.773 1074.97 189.689 1074.92 189.626 1074.84C189.563 1074.77 189.524 1074.67 189.514 1074.57C189.504 1074.47 189.524 1074.38 189.57 1074.29C189.617 1074.2 189.688 1074.13 189.776 1074.08C189.863 1074.04 189.962 1074.02 190.06 1074.03C190.159 1074.04 190.252 1074.07 190.329 1074.14C190.431 1074.22 190.496 1074.34 190.509 1074.47C190.522 1074.61 190.483 1074.74 190.399 1074.84V1074.84Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.11">
                            <path
    d="M224.869 732.697C224.745 732.851 224.577 732.964 224.388 733.023C224.198 733.081 223.996 733.082 223.806 733.026C223.616 732.969 223.447 732.857 223.321 732.704C223.195 732.552 223.117 732.365 223.097 732.168C223.077 731.97 223.116 731.772 223.209 731.597C223.302 731.422 223.445 731.278 223.62 731.185C223.795 731.091 223.993 731.052 224.19 731.071C224.388 731.091 224.574 731.168 224.728 731.294C224.931 731.462 225.06 731.704 225.087 731.966C225.113 732.229 225.035 732.491 224.869 732.697V732.697Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.6">
                            <path
    d="M192.11 645.866C191.97 646.006 191.792 646.101 191.598 646.14C191.404 646.179 191.203 646.159 191.02 646.084C190.837 646.008 190.681 645.88 190.571 645.716C190.461 645.551 190.402 645.358 190.402 645.16C190.402 644.962 190.461 644.769 190.571 644.604C190.681 644.44 190.837 644.312 191.02 644.236C191.203 644.16 191.404 644.141 191.598 644.18C191.792 644.218 191.97 644.314 192.11 644.454C192.202 644.547 192.276 644.657 192.326 644.778C192.376 644.899 192.402 645.029 192.402 645.16C192.402 645.291 192.376 645.421 192.326 645.542C192.276 645.663 192.202 645.773 192.11 645.866V645.866Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.57">
                            <path
    d="M281.892 629.217C281.682 629.428 281.414 629.571 281.123 629.629C280.832 629.688 280.53 629.658 280.256 629.545C279.981 629.431 279.746 629.239 279.581 628.992C279.416 628.745 279.328 628.454 279.328 628.157C279.328 627.86 279.416 627.57 279.581 627.323C279.746 627.076 279.981 626.884 280.256 626.77C280.53 626.657 280.832 626.627 281.123 626.685C281.414 626.744 281.682 626.887 281.892 627.097C282.171 627.379 282.328 627.76 282.328 628.157C282.328 628.554 282.171 628.935 281.892 629.217V629.217Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.18">
                            <path
    d="M405.385 591.386C405.175 591.596 404.907 591.738 404.617 591.796C404.326 591.854 404.024 591.824 403.75 591.711C403.476 591.597 403.242 591.405 403.077 591.158C402.912 590.911 402.824 590.621 402.824 590.325C402.824 590.028 402.912 589.738 403.077 589.491C403.242 589.244 403.476 589.052 403.75 588.939C404.024 588.825 404.326 588.795 404.617 588.853C404.907 588.911 405.175 589.054 405.385 589.263C405.524 589.403 405.635 589.568 405.71 589.75C405.785 589.932 405.824 590.127 405.824 590.325C405.824 590.522 405.785 590.717 405.71 590.899C405.635 591.081 405.524 591.246 405.385 591.386V591.386Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.38">
                            <path
    d="M75.7425 832.422C75.5553 832.653 75.3038 832.823 75.0199 832.911C74.7359 832.999 74.4323 833.001 74.1474 832.915C73.8626 832.83 73.6095 832.663 73.4201 832.434C73.2307 832.204 73.1137 831.924 73.0838 831.628C73.0539 831.333 73.1125 831.035 73.2522 830.772C73.3918 830.51 73.6063 830.295 73.8683 830.154C74.1303 830.014 74.4281 829.955 74.7239 829.984C75.0197 830.013 75.3002 830.129 75.5298 830.318C75.8351 830.57 76.0287 830.933 76.0685 831.327C76.1084 831.72 75.9912 832.114 75.7425 832.422V832.422Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.43">
                            <path
    d="M1271.7 753.032C1271.63 753.108 1271.55 753.164 1271.45 753.193C1271.36 753.222 1271.26 753.222 1271.16 753.194C1271.07 753.165 1270.99 753.109 1270.92 753.033C1270.86 752.956 1270.82 752.863 1270.81 752.765C1270.8 752.666 1270.82 752.567 1270.87 752.48C1270.91 752.393 1270.98 752.321 1271.07 752.274C1271.16 752.228 1271.26 752.208 1271.36 752.217C1271.45 752.227 1271.55 752.265 1271.62 752.328C1271.68 752.369 1271.72 752.42 1271.75 752.478C1271.78 752.536 1271.8 752.6 1271.81 752.665C1271.81 752.73 1271.81 752.796 1271.79 752.859C1271.77 752.922 1271.74 752.981 1271.7 753.032V753.032Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.34">
                            <path
    d="M1436.11 909.434C1436.05 909.511 1435.97 909.567 1435.87 909.596C1435.78 909.625 1435.68 909.625 1435.58 909.596C1435.49 909.567 1435.4 909.511 1435.34 909.435C1435.28 909.359 1435.24 909.265 1435.23 909.167C1435.22 909.068 1435.24 908.969 1435.29 908.882C1435.33 908.795 1435.4 908.723 1435.49 908.677C1435.58 908.63 1435.68 908.61 1435.77 908.62C1435.87 908.629 1435.97 908.668 1436.04 908.73C1436.09 908.772 1436.14 908.823 1436.17 908.881C1436.2 908.939 1436.22 909.002 1436.22 909.068C1436.23 909.133 1436.22 909.199 1436.21 909.262C1436.19 909.325 1436.15 909.384 1436.11 909.434Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.1">
                            <path
    d="M1635.71 892.469C1635.58 892.622 1635.41 892.735 1635.23 892.793C1635.04 892.851 1634.83 892.851 1634.65 892.794C1634.46 892.737 1634.29 892.625 1634.16 892.472C1634.04 892.319 1633.96 892.133 1633.94 891.936C1633.92 891.739 1633.96 891.541 1634.05 891.366C1634.14 891.191 1634.29 891.048 1634.46 890.955C1634.63 890.861 1634.83 890.822 1635.03 890.841C1635.23 890.86 1635.41 890.937 1635.57 891.062C1635.67 891.145 1635.75 891.247 1635.81 891.363C1635.88 891.479 1635.92 891.606 1635.93 891.736C1635.94 891.867 1635.93 891.999 1635.89 892.125C1635.85 892.25 1635.79 892.367 1635.71 892.469V892.469Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.06">
                            <path
    d="M1397.21 952.553C1397.09 952.706 1396.92 952.818 1396.73 952.876C1396.54 952.933 1396.34 952.933 1396.15 952.876C1395.96 952.819 1395.79 952.706 1395.67 952.554C1395.54 952.401 1395.47 952.214 1395.45 952.017C1395.43 951.821 1395.47 951.622 1395.56 951.448C1395.65 951.273 1395.79 951.13 1395.97 951.037C1396.14 950.943 1396.34 950.904 1396.54 950.923C1396.73 950.942 1396.92 951.019 1397.07 951.144C1397.28 951.312 1397.41 951.555 1397.44 951.819C1397.46 952.084 1397.38 952.347 1397.21 952.553Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.53">
                            <path
    d="M1268.41 359.39C1268.34 359.462 1268.25 359.511 1268.16 359.532C1268.06 359.553 1267.96 359.545 1267.87 359.508C1267.78 359.472 1267.7 359.41 1267.64 359.328C1267.58 359.247 1267.55 359.151 1267.55 359.052C1267.55 358.953 1267.58 358.856 1267.63 358.773C1267.68 358.69 1267.76 358.624 1267.85 358.585C1267.94 358.545 1268.04 358.533 1268.14 358.551C1268.24 358.568 1268.33 358.614 1268.4 358.683C1268.44 358.728 1268.48 358.783 1268.51 358.843C1268.54 358.903 1268.55 358.968 1268.55 359.034C1268.55 359.1 1268.54 359.165 1268.52 359.226C1268.49 359.287 1268.46 359.343 1268.41 359.39V359.39Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.37">
                            <path
    d="M1624.2 801.592C1623.99 801.805 1623.73 801.952 1623.44 802.014C1623.15 802.076 1622.84 802.051 1622.57 801.942C1622.29 801.833 1622.06 801.645 1621.89 801.401C1621.72 801.157 1621.63 800.869 1621.62 800.572C1621.62 800.276 1621.7 799.984 1621.86 799.735C1622.02 799.486 1622.25 799.289 1622.52 799.171C1622.8 799.053 1623.1 799.018 1623.39 799.071C1623.68 799.124 1623.95 799.262 1624.16 799.468C1624.3 799.605 1624.42 799.768 1624.5 799.949C1624.58 800.13 1624.62 800.325 1624.62 800.522C1624.62 800.72 1624.59 800.916 1624.52 801.099C1624.44 801.283 1624.34 801.45 1624.2 801.592Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.21">
                            <path
    d="M1565.08 870.093C1564.94 870.236 1564.77 870.334 1564.57 870.376C1564.38 870.418 1564.18 870.402 1564 870.329C1563.81 870.257 1563.65 870.131 1563.54 869.968C1563.43 869.806 1563.37 869.613 1563.36 869.415C1563.36 869.217 1563.42 869.023 1563.52 868.857C1563.63 868.69 1563.78 868.56 1563.97 868.481C1564.15 868.402 1564.35 868.379 1564.54 868.415C1564.74 868.45 1564.92 868.543 1565.06 868.68C1565.15 868.772 1565.23 868.88 1565.28 869.001C1565.33 869.121 1565.36 869.251 1565.36 869.382C1565.37 869.513 1565.34 869.644 1565.29 869.766C1565.25 869.888 1565.17 869.999 1565.08 870.093V870.093Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.11">
                            <path
    d="M1599.7 998.365C1599.49 998.578 1599.22 998.725 1598.94 998.787C1598.65 998.85 1598.34 998.825 1598.07 998.716C1597.79 998.607 1597.56 998.419 1597.39 998.176C1597.22 997.932 1597.12 997.644 1597.12 997.348C1597.11 997.051 1597.2 996.76 1597.36 996.511C1597.52 996.261 1597.75 996.065 1598.02 995.946C1598.29 995.827 1598.59 995.792 1598.88 995.844C1599.17 995.896 1599.44 996.033 1599.66 996.238C1599.8 996.375 1599.91 996.539 1599.99 996.72C1600.07 996.901 1600.12 997.096 1600.12 997.294C1600.12 997.491 1600.09 997.688 1600.01 997.872C1599.94 998.056 1599.83 998.223 1599.7 998.365Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.19">
                            <path
    d="M1578.67 627.914C1578.46 628.127 1578.2 628.275 1577.91 628.338C1577.62 628.4 1577.31 628.376 1577.04 628.267C1576.76 628.159 1576.52 627.971 1576.36 627.727C1576.19 627.483 1576.09 627.195 1576.09 626.898C1576.08 626.602 1576.17 626.311 1576.33 626.061C1576.49 625.811 1576.72 625.615 1576.99 625.496C1577.26 625.378 1577.56 625.342 1577.85 625.395C1578.14 625.447 1578.41 625.585 1578.63 625.79C1578.77 625.927 1578.88 626.091 1578.96 626.271C1579.04 626.452 1579.08 626.647 1579.09 626.844C1579.09 627.042 1579.06 627.238 1578.98 627.421C1578.91 627.605 1578.8 627.772 1578.67 627.914Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.49">
                            <path
    d="M1569.86 655.256C1569.79 655.328 1569.7 655.377 1569.6 655.399C1569.51 655.42 1569.41 655.412 1569.31 655.376C1569.22 655.34 1569.14 655.277 1569.09 655.196C1569.03 655.115 1569 655.018 1569 654.919C1568.99 654.82 1569.02 654.723 1569.08 654.64C1569.13 654.556 1569.21 654.491 1569.3 654.452C1569.39 654.412 1569.49 654.401 1569.59 654.418C1569.68 654.436 1569.77 654.482 1569.84 654.551C1569.94 654.643 1569.99 654.769 1570 654.901C1570 655.033 1569.95 655.161 1569.86 655.256V655.256Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.58">
                            <path
    d="M1590.12 655.144C1590.05 655.216 1589.96 655.265 1589.87 655.286C1589.77 655.307 1589.67 655.299 1589.58 655.263C1589.48 655.227 1589.4 655.165 1589.35 655.083C1589.29 655.002 1589.26 654.906 1589.26 654.807C1589.26 654.708 1589.28 654.611 1589.34 654.527C1589.39 654.444 1589.47 654.378 1589.56 654.339C1589.65 654.299 1589.75 654.287 1589.85 654.305C1589.94 654.322 1590.03 654.368 1590.11 654.437C1590.2 654.529 1590.26 654.655 1590.26 654.788C1590.26 654.92 1590.21 655.049 1590.12 655.144V655.144Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.09">
                            <path
    d="M1671.31 701.756C1671.17 701.898 1671 701.997 1670.8 702.04C1670.61 702.082 1670.41 702.066 1670.22 701.994C1670.04 701.922 1669.88 701.796 1669.77 701.634C1669.66 701.471 1669.59 701.279 1669.59 701.081C1669.59 700.883 1669.64 700.688 1669.75 700.522C1669.86 700.355 1670.01 700.224 1670.19 700.145C1670.37 700.066 1670.57 700.043 1670.77 700.079C1670.96 700.114 1671.14 700.206 1671.29 700.344C1671.48 700.528 1671.58 700.78 1671.59 701.044C1671.59 701.309 1671.49 701.565 1671.31 701.756Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.18">
                            <path
    d="M1281.78 853.244C1281.59 853.473 1281.34 853.642 1281.05 853.729C1280.77 853.815 1280.47 853.815 1280.18 853.73C1279.9 853.644 1279.65 853.476 1279.46 853.246C1279.27 853.017 1279.15 852.737 1279.12 852.442C1279.09 852.147 1279.15 851.849 1279.29 851.587C1279.43 851.326 1279.65 851.111 1279.91 850.971C1280.17 850.831 1280.47 850.771 1280.76 850.8C1281.06 850.829 1281.34 850.945 1281.57 851.133C1281.72 851.257 1281.84 851.411 1281.94 851.584C1282.03 851.758 1282.09 851.948 1282.11 852.145C1282.13 852.341 1282.11 852.539 1282.05 852.727C1281.99 852.916 1281.9 853.092 1281.78 853.244Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.45">
                            <path
    d="M1226.14 673.543C1226.08 673.62 1226 673.676 1225.9 673.705C1225.81 673.734 1225.71 673.734 1225.61 673.705C1225.52 673.677 1225.43 673.621 1225.37 673.545C1225.31 673.468 1225.27 673.375 1225.26 673.277C1225.25 673.178 1225.27 673.079 1225.31 672.992C1225.36 672.904 1225.43 672.833 1225.52 672.786C1225.61 672.739 1225.71 672.719 1225.8 672.729C1225.9 672.738 1226 672.777 1226.07 672.84C1226.12 672.881 1226.17 672.932 1226.2 672.99C1226.23 673.048 1226.25 673.111 1226.25 673.177C1226.26 673.242 1226.25 673.308 1226.23 673.371C1226.22 673.434 1226.18 673.492 1226.14 673.543V673.543Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.58">
                            <path
    d="M1412.38 565.435C1412.2 565.665 1411.95 565.834 1411.66 565.92C1411.38 566.007 1411.07 566.008 1410.79 565.922C1410.51 565.836 1410.25 565.668 1410.07 565.439C1409.88 565.21 1409.76 564.93 1409.73 564.635C1409.7 564.34 1409.76 564.042 1409.9 563.78C1410.04 563.518 1410.25 563.303 1410.51 563.163C1410.77 563.023 1411.07 562.963 1411.37 562.991C1411.66 563.02 1411.94 563.135 1412.17 563.323C1412.48 563.575 1412.68 563.939 1412.72 564.335C1412.75 564.731 1412.64 565.127 1412.38 565.435V565.435Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.15">
                            <g style={{ mixBlendMode: 'screen' }}>
                                <path
    d="M1559.93 691.001C1559.81 691.154 1559.64 691.267 1559.45 691.324C1559.26 691.382 1559.06 691.382 1558.87 691.325C1558.68 691.268 1558.51 691.156 1558.39 691.003C1558.26 690.85 1558.18 690.664 1558.16 690.467C1558.14 690.27 1558.18 690.072 1558.28 689.898C1558.37 689.723 1558.51 689.58 1558.68 689.486C1558.86 689.393 1559.06 689.353 1559.25 689.372C1559.45 689.391 1559.64 689.467 1559.79 689.593C1559.89 689.675 1559.98 689.778 1560.04 689.894C1560.1 690.009 1560.14 690.137 1560.15 690.267C1560.17 690.398 1560.15 690.531 1560.12 690.657C1560.08 690.782 1560.01 690.9 1559.93 691.001V691.001Z"
    fill="white"/>
                            </g>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.53">
                            <path
    d="M1568.58 850.947C1568.45 851.1 1568.28 851.212 1568.09 851.27C1567.9 851.327 1567.7 851.327 1567.51 851.27C1567.32 851.213 1567.16 851.101 1567.03 850.948C1566.9 850.795 1566.83 850.609 1566.81 850.412C1566.79 850.215 1566.83 850.017 1566.92 849.842C1567.01 849.668 1567.16 849.525 1567.33 849.431C1567.5 849.338 1567.7 849.298 1567.9 849.317C1568.1 849.336 1568.28 849.413 1568.44 849.538C1568.54 849.622 1568.62 849.724 1568.68 849.84C1568.75 849.956 1568.78 850.083 1568.8 850.214C1568.81 850.345 1568.8 850.477 1568.76 850.603C1568.72 850.728 1568.66 850.846 1568.58 850.947V850.947Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.08">
                            <path
    d="M1266.18 470.19C1266 470.42 1265.74 470.589 1265.46 470.675C1265.18 470.762 1264.87 470.762 1264.59 470.676C1264.3 470.59 1264.05 470.422 1263.86 470.193C1263.67 469.964 1263.56 469.683 1263.53 469.388C1263.5 469.093 1263.56 468.795 1263.7 468.533C1263.84 468.271 1264.05 468.056 1264.31 467.916C1264.58 467.776 1264.87 467.717 1265.17 467.745C1265.46 467.774 1265.74 467.89 1265.97 468.078C1266.28 468.331 1266.47 468.695 1266.51 469.091C1266.55 469.487 1266.43 469.882 1266.18 470.19V470.19Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.45">
                            <path
    d="M1600.94 799.47C1600.81 799.624 1600.64 799.737 1600.46 799.796C1600.27 799.854 1600.06 799.855 1599.87 799.798C1599.68 799.742 1599.52 799.63 1599.39 799.477C1599.26 799.325 1599.19 799.138 1599.17 798.941C1599.15 798.744 1599.18 798.545 1599.28 798.37C1599.37 798.195 1599.51 798.052 1599.69 797.958C1599.86 797.865 1600.06 797.825 1600.26 797.845C1600.46 797.864 1600.64 797.942 1600.8 798.068C1601 798.235 1601.13 798.477 1601.16 798.739C1601.18 799.002 1601.1 799.265 1600.94 799.47Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.54">
                            <path
    d="M1123.29 682.013C1123.1 682.245 1122.85 682.415 1122.57 682.503C1122.28 682.592 1121.98 682.593 1121.69 682.509C1121.41 682.424 1121.15 682.256 1120.97 682.027C1120.78 681.798 1120.66 681.517 1120.63 681.222C1120.6 680.926 1120.66 680.628 1120.8 680.365C1120.94 680.103 1121.15 679.888 1121.41 679.747C1121.68 679.607 1121.97 679.548 1122.27 679.578C1122.57 679.607 1122.85 679.724 1123.08 679.913C1123.38 680.165 1123.57 680.526 1123.61 680.92C1123.65 681.313 1123.54 681.706 1123.29 682.013V682.013Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.19">
                            <path
    d="M1447.75 584.464C1447.56 584.693 1447.31 584.862 1447.02 584.948C1446.74 585.034 1446.44 585.034 1446.15 584.948C1445.87 584.862 1445.62 584.694 1445.43 584.465C1445.24 584.236 1445.12 583.956 1445.1 583.661C1445.07 583.365 1445.12 583.068 1445.26 582.806C1445.4 582.545 1445.62 582.33 1445.88 582.19C1446.14 582.05 1446.44 581.99 1446.73 582.019C1447.03 582.047 1447.31 582.163 1447.54 582.35C1447.69 582.475 1447.82 582.629 1447.91 582.803C1448 582.977 1448.06 583.167 1448.08 583.364C1448.1 583.56 1448.08 583.758 1448.02 583.947C1447.97 584.136 1447.87 584.312 1447.75 584.464V584.464Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.05">
                            <path
    d="M1042.32 760.294C1042.2 760.447 1042.03 760.559 1041.84 760.617C1041.65 760.675 1041.45 760.675 1041.26 760.618C1041.07 760.561 1040.9 760.449 1040.78 760.296C1040.65 760.144 1040.58 759.957 1040.56 759.76C1040.54 759.564 1040.58 759.365 1040.67 759.191C1040.76 759.016 1040.9 758.873 1041.08 758.779C1041.25 758.686 1041.45 758.646 1041.65 758.665C1041.84 758.684 1042.03 758.761 1042.18 758.886C1042.29 758.969 1042.37 759.071 1042.43 759.187C1042.49 759.303 1042.53 759.43 1042.55 759.56C1042.56 759.691 1042.55 759.823 1042.51 759.949C1042.47 760.075 1042.41 760.192 1042.32 760.294Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.15">
                            <path
    d="M1154.17 873.715C1154.05 873.868 1153.88 873.981 1153.69 874.038C1153.5 874.096 1153.3 874.097 1153.11 874.039C1152.92 873.982 1152.75 873.87 1152.63 873.717C1152.5 873.565 1152.42 873.378 1152.41 873.181C1152.39 872.984 1152.42 872.786 1152.52 872.611C1152.61 872.437 1152.75 872.294 1152.93 872.2C1153.1 872.107 1153.3 872.067 1153.5 872.087C1153.69 872.106 1153.88 872.183 1154.03 872.309C1154.14 872.392 1154.22 872.494 1154.28 872.61C1154.34 872.726 1154.38 872.852 1154.4 872.983C1154.41 873.113 1154.4 873.245 1154.36 873.371C1154.32 873.496 1154.26 873.613 1154.17 873.715V873.715Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.07">
                            <path
    d="M893.365 874.744C893.177 874.973 892.925 875.142 892.641 875.229C892.358 875.315 892.055 875.315 891.771 875.229C891.487 875.144 891.235 874.975 891.046 874.746C890.858 874.517 890.742 874.237 890.712 873.942C890.683 873.647 890.742 873.349 890.881 873.087C891.021 872.826 891.235 872.611 891.496 872.471C891.758 872.331 892.055 872.271 892.35 872.3C892.645 872.329 892.925 872.445 893.155 872.633C893.307 872.757 893.434 872.911 893.527 873.084C893.62 873.258 893.678 873.448 893.698 873.645C893.717 873.841 893.698 874.039 893.641 874.227C893.584 874.416 893.49 874.592 893.365 874.744Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.35">
                            <path
    d="M1083.96 757.826C1083.78 758.057 1083.53 758.227 1083.24 758.314C1082.96 758.402 1082.65 758.403 1082.37 758.317C1082.08 758.232 1081.83 758.064 1081.64 757.835C1081.45 757.605 1081.34 757.325 1081.31 757.029C1081.28 756.733 1081.34 756.435 1081.48 756.173C1081.62 755.911 1081.83 755.696 1082.09 755.556C1082.35 755.416 1082.65 755.357 1082.95 755.386C1083.24 755.416 1083.52 755.533 1083.75 755.722C1084.06 755.974 1084.25 756.337 1084.29 756.731C1084.33 757.124 1084.21 757.518 1083.96 757.826V757.826Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.18">
                            <path
    d="M1126.95 739.408C1126.89 739.485 1126.81 739.542 1126.71 739.571C1126.62 739.601 1126.52 739.601 1126.42 739.573C1126.33 739.545 1126.24 739.489 1126.18 739.413C1126.12 739.336 1126.08 739.243 1126.07 739.144C1126.06 739.046 1126.08 738.946 1126.12 738.859C1126.17 738.771 1126.24 738.7 1126.33 738.653C1126.42 738.606 1126.52 738.586 1126.61 738.596C1126.71 738.606 1126.81 738.645 1126.88 738.708C1126.98 738.792 1127.05 738.912 1127.06 739.044C1127.08 739.175 1127.04 739.306 1126.95 739.408V739.408Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.04">
                            <path
    d="M1338.79 854.226C1338.6 854.455 1338.35 854.623 1338.07 854.709C1337.78 854.795 1337.48 854.794 1337.2 854.708C1336.91 854.622 1336.66 854.454 1336.47 854.225C1336.29 853.996 1336.17 853.716 1336.14 853.421C1336.11 853.126 1336.17 852.829 1336.31 852.567C1336.45 852.306 1336.66 852.091 1336.93 851.951C1337.19 851.811 1337.48 851.752 1337.78 851.78C1338.07 851.809 1338.35 851.924 1338.58 852.112C1338.74 852.237 1338.86 852.39 1338.96 852.564C1339.05 852.738 1339.11 852.929 1339.13 853.126C1339.15 853.322 1339.13 853.521 1339.07 853.709C1339.01 853.898 1338.92 854.074 1338.79 854.226V854.226Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.61">
                            <path
    d="M1312.04 861.566C1311.98 861.643 1311.9 861.7 1311.8 861.729C1311.71 861.758 1311.61 861.758 1311.51 861.73C1311.42 861.701 1311.33 861.645 1311.27 861.569C1311.21 861.493 1311.17 861.399 1311.16 861.301C1311.15 861.203 1311.17 861.103 1311.21 861.016C1311.26 860.928 1311.33 860.857 1311.42 860.81C1311.5 860.763 1311.6 860.743 1311.7 860.752C1311.8 860.762 1311.89 860.8 1311.97 860.863C1312.07 860.947 1312.14 861.068 1312.15 861.2C1312.17 861.332 1312.13 861.463 1312.04 861.566Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.08">
                            <path
    d="M1260.96 819.755C1260.9 819.831 1260.82 819.887 1260.72 819.916C1260.63 819.945 1260.53 819.945 1260.43 819.916C1260.34 819.888 1260.25 819.832 1260.19 819.755C1260.13 819.679 1260.09 819.586 1260.08 819.487C1260.07 819.389 1260.09 819.29 1260.13 819.203C1260.18 819.115 1260.25 819.044 1260.34 818.997C1260.43 818.95 1260.53 818.93 1260.62 818.94C1260.72 818.949 1260.82 818.988 1260.89 819.05C1260.94 819.092 1260.99 819.143 1261.02 819.201C1261.05 819.259 1261.07 819.322 1261.07 819.388C1261.08 819.453 1261.07 819.519 1261.05 819.582C1261.04 819.645 1261 819.704 1260.96 819.755V819.755Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.59">
                            <path
    d="M1481.16 831.229C1481.1 831.306 1481.01 831.363 1480.92 831.392C1480.82 831.421 1480.72 831.422 1480.63 831.393C1480.53 831.365 1480.45 831.309 1480.38 831.233C1480.32 831.156 1480.28 831.063 1480.27 830.964C1480.26 830.866 1480.28 830.766 1480.33 830.679C1480.37 830.591 1480.45 830.52 1480.53 830.473C1480.62 830.426 1480.72 830.407 1480.82 830.417C1480.92 830.426 1481.01 830.465 1481.09 830.528C1481.19 830.612 1481.25 830.733 1481.27 830.864C1481.28 830.995 1481.24 831.127 1481.16 831.229V831.229Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.19">
                            <path
    d="M1732 800.071C1731.81 800.301 1731.56 800.469 1731.27 800.554C1730.99 800.64 1730.69 800.639 1730.4 800.552C1730.12 800.465 1729.87 800.296 1729.68 800.065C1729.49 799.835 1729.38 799.554 1729.35 799.259C1729.32 798.963 1729.38 798.666 1729.52 798.404C1729.66 798.143 1729.88 797.93 1730.14 797.791C1730.4 797.652 1730.7 797.595 1731 797.625C1731.29 797.656 1731.57 797.774 1731.8 797.963C1731.95 798.089 1732.08 798.243 1732.17 798.416C1732.26 798.59 1732.32 798.78 1732.34 798.976C1732.35 799.172 1732.33 799.369 1732.28 799.557C1732.22 799.745 1732.12 799.92 1732 800.071V800.071Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.45">
                            <path
    d="M1410.75 1010.52C1410.56 1010.75 1410.31 1010.92 1410.03 1011C1409.74 1011.09 1409.44 1011.09 1409.15 1011C1408.87 1010.92 1408.62 1010.75 1408.43 1010.52C1408.24 1010.29 1408.12 1010.01 1408.1 1009.72C1408.07 1009.42 1408.12 1009.12 1408.26 1008.86C1408.4 1008.6 1408.62 1008.39 1408.88 1008.25C1409.14 1008.1 1409.44 1008.04 1409.73 1008.07C1410.03 1008.1 1410.31 1008.22 1410.54 1008.4C1410.69 1008.53 1410.82 1008.68 1410.91 1008.86C1411 1009.03 1411.06 1009.22 1411.08 1009.42C1411.1 1009.61 1411.08 1009.81 1411.02 1010C1410.97 1010.19 1410.87 1010.36 1410.75 1010.52V1010.52Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.31">
                            <g style={{ mixBlendMode: 'screen' }}>
                                <path
    d="M1073.74 722.657C1073.55 722.886 1073.3 723.055 1073.01 723.142C1072.73 723.229 1072.43 723.229 1072.14 723.144C1071.86 723.058 1071.61 722.89 1071.42 722.661C1071.23 722.432 1071.11 722.152 1071.08 721.857C1071.05 721.562 1071.11 721.264 1071.25 721.002C1071.39 720.74 1071.61 720.526 1071.87 720.385C1072.13 720.245 1072.43 720.185 1072.72 720.214C1073.02 720.243 1073.3 720.358 1073.53 720.546C1073.83 720.798 1074.03 721.162 1074.07 721.557C1074.11 721.953 1073.99 722.349 1073.74 722.657V722.657Z"
    fill="white"/>
                            </g>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.4">
                            <path
    d="M1476.76 786.814C1476.58 787.044 1476.32 787.212 1476.04 787.299C1475.76 787.386 1475.45 787.387 1475.17 787.301C1474.88 787.215 1474.63 787.047 1474.44 786.818C1474.26 786.589 1474.14 786.309 1474.11 786.014C1474.08 785.718 1474.14 785.421 1474.28 785.159C1474.42 784.897 1474.63 784.682 1474.89 784.542C1475.15 784.401 1475.45 784.342 1475.75 784.37C1476.04 784.399 1476.32 784.514 1476.55 784.702C1476.86 784.955 1477.05 785.318 1477.09 785.714C1477.13 786.11 1477.01 786.505 1476.76 786.814Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.4">
                            <path
    d="M1138.53 868.227C1138.47 868.303 1138.38 868.36 1138.29 868.389C1138.19 868.417 1138.09 868.418 1138 868.389C1137.9 868.361 1137.82 868.305 1137.75 868.228C1137.69 868.152 1137.65 868.059 1137.64 867.96C1137.63 867.862 1137.65 867.763 1137.7 867.675C1137.75 867.588 1137.82 867.516 1137.9 867.47C1137.99 867.423 1138.09 867.403 1138.19 867.412C1138.29 867.422 1138.38 867.461 1138.46 867.523C1138.56 867.607 1138.62 867.728 1138.64 867.86C1138.65 867.992 1138.61 868.124 1138.53 868.227V868.227Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.06">
                            <g style={{ mixBlendMode: 'screen' }}>
                                <path
    d="M1325.04 894.113C1324.91 894.267 1324.74 894.379 1324.55 894.437C1324.36 894.495 1324.16 894.496 1323.97 894.438C1323.78 894.381 1323.62 894.269 1323.49 894.116C1323.36 893.964 1323.29 893.777 1323.27 893.58C1323.25 893.383 1323.29 893.185 1323.38 893.01C1323.47 892.835 1323.62 892.692 1323.79 892.599C1323.96 892.506 1324.16 892.466 1324.36 892.485C1324.56 892.505 1324.74 892.582 1324.89 892.707C1325 892.79 1325.08 892.893 1325.14 893.008C1325.21 893.124 1325.24 893.251 1325.26 893.381C1325.27 893.512 1325.26 893.644 1325.22 893.769C1325.18 893.895 1325.12 894.012 1325.04 894.113V894.113Z"
    fill="white"/>
                            </g>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.2">
                            <path
    d="M1315.11 932.282C1315.05 932.358 1314.96 932.414 1314.87 932.443C1314.77 932.472 1314.67 932.472 1314.58 932.443C1314.48 932.415 1314.4 932.359 1314.34 932.282C1314.27 932.206 1314.23 932.113 1314.23 932.014C1314.22 931.916 1314.23 931.817 1314.28 931.73C1314.33 931.643 1314.4 931.571 1314.49 931.524C1314.57 931.477 1314.67 931.458 1314.77 931.467C1314.87 931.477 1314.96 931.515 1315.04 931.578C1315.09 931.619 1315.13 931.671 1315.16 931.728C1315.19 931.786 1315.21 931.85 1315.22 931.915C1315.23 931.981 1315.22 932.047 1315.2 932.11C1315.18 932.172 1315.15 932.231 1315.11 932.282Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.22">
                            <path
    d="M1293.88 997.748C1293.76 997.901 1293.59 998.013 1293.4 998.071C1293.21 998.128 1293.01 998.128 1292.82 998.071C1292.63 998.013 1292.46 997.901 1292.34 997.748C1292.21 997.595 1292.13 997.409 1292.11 997.212C1292.09 997.015 1292.13 996.817 1292.23 996.642C1292.32 996.468 1292.46 996.325 1292.64 996.232C1292.81 996.138 1293.01 996.099 1293.21 996.118C1293.4 996.137 1293.59 996.215 1293.74 996.34C1293.84 996.423 1293.93 996.526 1293.99 996.642C1294.05 996.758 1294.09 996.884 1294.1 997.015C1294.12 997.146 1294.1 997.278 1294.07 997.404C1294.03 997.53 1293.97 997.647 1293.88 997.748V997.748Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.42">
                            <path
    d="M1372.84 484.963C1372.65 485.193 1372.4 485.362 1372.12 485.449C1371.83 485.535 1371.53 485.536 1371.25 485.45C1370.96 485.365 1370.71 485.197 1370.52 484.968C1370.33 484.739 1370.22 484.459 1370.19 484.164C1370.16 483.868 1370.21 483.571 1370.35 483.309C1370.49 483.046 1370.71 482.831 1370.97 482.691C1371.23 482.55 1371.53 482.49 1371.82 482.519C1372.12 482.547 1372.4 482.662 1372.63 482.85C1372.94 483.102 1373.13 483.466 1373.17 483.863C1373.21 484.259 1373.09 484.654 1372.84 484.963Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.29">
                            <path
    d="M1444.23 482.459C1444.17 482.535 1444.08 482.591 1443.99 482.62C1443.89 482.649 1443.79 482.649 1443.7 482.62C1443.6 482.591 1443.52 482.535 1443.46 482.459C1443.39 482.382 1443.36 482.289 1443.35 482.19C1443.34 482.092 1443.36 481.993 1443.4 481.905C1443.45 481.818 1443.52 481.746 1443.61 481.7C1443.7 481.653 1443.79 481.633 1443.89 481.643C1443.99 481.653 1444.08 481.691 1444.16 481.754C1444.26 481.838 1444.33 481.96 1444.34 482.092C1444.35 482.224 1444.31 482.356 1444.23 482.459V482.459Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.26">
                            <path
    d="M1504.89 763.529C1504.77 763.682 1504.6 763.795 1504.41 763.852C1504.22 763.909 1504.02 763.909 1503.83 763.852C1503.64 763.795 1503.47 763.682 1503.35 763.529C1503.22 763.377 1503.15 763.19 1503.13 762.993C1503.11 762.796 1503.15 762.598 1503.24 762.424C1503.33 762.249 1503.47 762.106 1503.65 762.013C1503.82 761.92 1504.02 761.88 1504.22 761.899C1504.41 761.919 1504.6 761.996 1504.75 762.121C1504.85 762.204 1504.94 762.306 1505 762.422C1505.06 762.538 1505.1 762.665 1505.12 762.796C1505.13 762.927 1505.12 763.06 1505.08 763.186C1505.04 763.311 1504.98 763.428 1504.89 763.529V763.529Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.23">
                            <path
    d="M983.51 544.081C983.322 544.31 983.071 544.479 982.787 544.566C982.504 544.653 982.201 544.654 981.917 544.568C981.633 544.483 981.381 544.315 981.192 544.086C981.003 543.857 980.887 543.578 980.857 543.282C980.827 542.987 980.886 542.69 981.025 542.428C981.164 542.166 981.378 541.952 981.639 541.811C981.901 541.671 982.198 541.611 982.493 541.64C982.788 541.668 983.068 541.784 983.298 541.971C983.45 542.096 983.577 542.249 983.671 542.422C983.764 542.596 983.822 542.786 983.842 542.982C983.862 543.178 983.843 543.376 983.786 543.564C983.729 543.753 983.635 543.928 983.51 544.081V544.081Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.42">
                            <path
    d="M1119.07 352.888C1119.01 352.964 1118.92 353.02 1118.83 353.049C1118.74 353.077 1118.63 353.077 1118.54 353.049C1118.45 353.02 1118.36 352.964 1118.3 352.888C1118.24 352.811 1118.2 352.718 1118.19 352.619C1118.18 352.521 1118.2 352.422 1118.24 352.335C1118.29 352.248 1118.36 352.176 1118.45 352.129C1118.54 352.083 1118.64 352.063 1118.73 352.073C1118.83 352.082 1118.93 352.121 1119 352.184C1119.05 352.225 1119.09 352.276 1119.13 352.334C1119.16 352.392 1119.18 352.456 1119.18 352.521C1119.19 352.587 1119.18 352.653 1119.16 352.716C1119.14 352.778 1119.11 352.837 1119.07 352.888V352.888Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.08">
                            <path
    d="M943.453 445.532C943.39 445.608 943.307 445.664 943.212 445.693C943.118 445.722 943.017 445.722 942.922 445.694C942.827 445.665 942.743 445.609 942.681 445.533C942.618 445.457 942.579 445.364 942.569 445.265C942.559 445.167 942.578 445.068 942.625 444.98C942.671 444.893 942.742 444.821 942.829 444.775C942.916 444.728 943.015 444.708 943.114 444.717C943.212 444.727 943.305 444.765 943.382 444.827C943.433 444.869 943.475 444.92 943.507 444.978C943.538 445.036 943.557 445.099 943.564 445.165C943.57 445.23 943.564 445.296 943.545 445.359C943.526 445.422 943.495 445.481 943.453 445.532V445.532Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.25">
                            <path
    d="M1434.21 837.254C1434.15 837.331 1434.06 837.387 1433.97 837.416C1433.88 837.445 1433.77 837.445 1433.68 837.416C1433.59 837.388 1433.5 837.332 1433.44 837.256C1433.38 837.179 1433.34 837.086 1433.33 836.988C1433.32 836.889 1433.34 836.79 1433.38 836.703C1433.43 836.616 1433.5 836.544 1433.59 836.497C1433.67 836.45 1433.77 836.43 1433.87 836.44C1433.97 836.449 1434.06 836.488 1434.14 836.55C1434.19 836.592 1434.23 836.643 1434.26 836.701C1434.3 836.759 1434.32 836.822 1434.32 836.887C1434.33 836.953 1434.32 837.019 1434.3 837.082C1434.28 837.145 1434.25 837.203 1434.21 837.254V837.254Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.14">
                            <path
    d="M1636.62 844.485C1636.43 844.715 1636.18 844.884 1635.89 844.971C1635.61 845.057 1635.31 845.058 1635.02 844.972C1634.74 844.886 1634.49 844.718 1634.3 844.488C1634.11 844.259 1633.99 843.979 1633.96 843.683C1633.94 843.388 1633.99 843.09 1634.13 842.828C1634.27 842.566 1634.49 842.352 1634.75 842.212C1635.01 842.072 1635.31 842.013 1635.61 842.043C1635.9 842.072 1636.18 842.188 1636.41 842.377C1636.72 842.629 1636.91 842.993 1636.95 843.388C1636.99 843.783 1636.87 844.177 1636.62 844.485Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.32">
                            <path
    d="M1397.23 999.454C1397.1 999.607 1396.93 999.719 1396.74 999.777C1396.55 999.835 1396.35 999.835 1396.16 999.778C1395.97 999.721 1395.81 999.609 1395.68 999.457C1395.55 999.304 1395.48 999.118 1395.46 998.921C1395.44 998.724 1395.48 998.526 1395.57 998.351C1395.66 998.177 1395.8 998.033 1395.98 997.94C1396.15 997.846 1396.35 997.806 1396.55 997.825C1396.74 997.844 1396.93 997.92 1397.08 998.045C1397.19 998.129 1397.27 998.231 1397.33 998.347C1397.39 998.462 1397.43 998.589 1397.45 998.72C1397.46 998.851 1397.45 998.983 1397.41 999.109C1397.37 999.235 1397.31 999.352 1397.23 999.454V999.454Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.44">
                            <path
    d="M1437.55 1000.78C1437.42 1000.93 1437.26 1001.04 1437.07 1001.1C1436.88 1001.16 1436.68 1001.16 1436.49 1001.1C1436.3 1001.04 1436.13 1000.93 1436.01 1000.78C1435.88 1000.62 1435.8 1000.44 1435.78 1000.24C1435.76 1000.04 1435.8 999.844 1435.9 999.67C1435.99 999.495 1436.13 999.352 1436.31 999.259C1436.48 999.166 1436.68 999.126 1436.87 999.145C1437.07 999.165 1437.26 999.242 1437.41 999.367C1437.51 999.45 1437.6 999.552 1437.66 999.668C1437.72 999.784 1437.76 999.911 1437.77 1000.04C1437.79 1000.17 1437.77 1000.31 1437.74 1000.43C1437.7 1000.56 1437.63 1000.67 1437.55 1000.78V1000.78Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.27">
                            <path
    d="M1532.79 961.745C1532.66 961.898 1532.49 962.011 1532.3 962.069C1532.11 962.126 1531.91 962.127 1531.72 962.07C1531.53 962.013 1531.36 961.901 1531.24 961.748C1531.11 961.595 1531.03 961.408 1531.01 961.211C1530.99 961.014 1531.03 960.816 1531.13 960.641C1531.22 960.466 1531.36 960.323 1531.54 960.229C1531.71 960.135 1531.91 960.095 1532.11 960.114C1532.3 960.133 1532.49 960.21 1532.64 960.335C1532.85 960.504 1532.98 960.747 1533 961.011C1533.03 961.275 1532.95 961.538 1532.79 961.745Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.57">
                            <path
    d="M1464.75 1044.95C1464.62 1045.1 1464.46 1045.21 1464.27 1045.27C1464.08 1045.33 1463.87 1045.33 1463.69 1045.27C1463.5 1045.21 1463.33 1045.1 1463.2 1044.95C1463.08 1044.79 1463 1044.61 1462.98 1044.41C1462.96 1044.21 1463 1044.01 1463.1 1043.84C1463.19 1043.67 1463.33 1043.52 1463.51 1043.43C1463.68 1043.34 1463.88 1043.3 1464.08 1043.32C1464.27 1043.34 1464.46 1043.41 1464.61 1043.54C1464.71 1043.62 1464.8 1043.73 1464.86 1043.84C1464.92 1043.96 1464.96 1044.09 1464.97 1044.22C1464.99 1044.35 1464.97 1044.48 1464.93 1044.61C1464.9 1044.73 1464.83 1044.85 1464.75 1044.95Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.2">
                            <path
    d="M1121.79 727.852C1121.73 727.929 1121.65 727.985 1121.55 728.014C1121.46 728.042 1121.36 728.042 1121.26 728.014C1121.17 727.985 1121.08 727.929 1121.02 727.852C1120.96 727.776 1120.92 727.683 1120.91 727.584C1120.9 727.486 1120.92 727.387 1120.97 727.3C1121.01 727.212 1121.08 727.141 1121.17 727.094C1121.26 727.048 1121.36 727.028 1121.45 727.038C1121.55 727.047 1121.65 727.086 1121.72 727.148C1121.77 727.19 1121.82 727.241 1121.85 727.299C1121.88 727.357 1121.9 727.421 1121.9 727.486C1121.91 727.551 1121.9 727.617 1121.88 727.68C1121.87 727.743 1121.83 727.802 1121.79 727.852Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.09">
                            <path
    d="M1564.25 1011.85C1564.18 1011.93 1564.1 1011.98 1564 1012.01C1563.91 1012.04 1563.81 1012.04 1563.71 1012.01C1563.62 1011.98 1563.53 1011.93 1563.47 1011.85C1563.41 1011.77 1563.37 1011.68 1563.36 1011.58C1563.35 1011.48 1563.37 1011.38 1563.42 1011.3C1563.46 1011.21 1563.53 1011.14 1563.62 1011.09C1563.71 1011.04 1563.81 1011.02 1563.91 1011.03C1564 1011.04 1564.1 1011.08 1564.17 1011.14C1564.28 1011.23 1564.34 1011.35 1564.35 1011.48C1564.37 1011.61 1564.33 1011.75 1564.25 1011.85V1011.85Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.37">
                            <path
    d="M980.276 853.262C980.213 853.338 980.129 853.395 980.035 853.424C979.94 853.453 979.839 853.453 979.744 853.424C979.65 853.396 979.566 853.34 979.503 853.263C979.44 853.187 979.401 853.094 979.391 852.995C979.381 852.897 979.401 852.798 979.447 852.71C979.494 852.623 979.565 852.552 979.652 852.505C979.739 852.458 979.838 852.438 979.937 852.448C980.035 852.457 980.129 852.496 980.205 852.558C980.308 852.642 980.373 852.764 980.386 852.896C980.399 853.027 980.36 853.159 980.276 853.262V853.262Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.48">
                            <path
    d="M984.679 1054.2C984.554 1054.35 984.386 1054.46 984.197 1054.52C984.008 1054.58 983.806 1054.58 983.616 1054.52C983.427 1054.46 983.259 1054.35 983.134 1054.2C983.008 1054.04 982.931 1053.86 982.911 1053.66C982.892 1053.46 982.931 1053.27 983.024 1053.09C983.117 1052.92 983.26 1052.77 983.434 1052.68C983.609 1052.59 983.807 1052.55 984.003 1052.57C984.2 1052.59 984.387 1052.66 984.54 1052.79C984.641 1052.87 984.726 1052.97 984.788 1053.09C984.85 1053.21 984.888 1053.33 984.901 1053.46C984.914 1053.59 984.901 1053.73 984.863 1053.85C984.825 1053.98 984.762 1054.09 984.679 1054.2V1054.2Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.62">
                            <path
    d="M1084.7 449.804C1084.63 449.874 1084.54 449.921 1084.44 449.941C1084.35 449.96 1084.25 449.95 1084.15 449.912C1084.06 449.874 1083.98 449.81 1083.93 449.727C1083.87 449.645 1083.85 449.548 1083.85 449.45C1083.85 449.351 1083.87 449.254 1083.93 449.172C1083.98 449.09 1084.06 449.025 1084.15 448.988C1084.25 448.95 1084.35 448.94 1084.44 448.959C1084.54 448.978 1084.63 449.025 1084.7 449.095C1084.79 449.189 1084.85 449.317 1084.85 449.45C1084.85 449.583 1084.79 449.71 1084.7 449.804V449.804Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.21">
                            <path
    d="M1216 535.864C1215.79 536.073 1215.53 536.216 1215.23 536.274C1214.94 536.332 1214.64 536.302 1214.37 536.189C1214.09 536.075 1213.86 535.882 1213.69 535.636C1213.53 535.389 1213.44 535.099 1213.44 534.802C1213.44 534.505 1213.53 534.215 1213.69 533.968C1213.86 533.721 1214.09 533.529 1214.37 533.415C1214.64 533.301 1214.94 533.272 1215.23 533.33C1215.53 533.387 1215.79 533.53 1216 533.74C1216.28 534.022 1216.44 534.404 1216.44 534.802C1216.44 535.2 1216.28 535.582 1216 535.864V535.864Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.43">
                            <path
    d="M1351.62 543.412C1351.55 543.482 1351.46 543.53 1351.37 543.549C1351.27 543.568 1351.17 543.559 1351.08 543.521C1350.99 543.483 1350.91 543.419 1350.85 543.337C1350.8 543.254 1350.77 543.158 1350.77 543.059C1350.77 542.96 1350.8 542.863 1350.85 542.781C1350.91 542.698 1350.99 542.634 1351.08 542.597C1351.17 542.559 1351.27 542.549 1351.37 542.568C1351.46 542.588 1351.55 542.635 1351.62 542.706C1351.72 542.799 1351.77 542.926 1351.77 543.059C1351.77 543.191 1351.72 543.318 1351.62 543.412V543.412Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.36">
                            <path
    d="M869.215 811.158C869.09 811.312 868.923 811.425 868.733 811.484C868.544 811.542 868.342 811.543 868.152 811.487C867.962 811.43 867.793 811.318 867.667 811.165C867.541 811.013 867.463 810.826 867.443 810.629C867.423 810.431 867.462 810.233 867.555 810.058C867.648 809.883 867.791 809.739 867.966 809.646C868.14 809.552 868.339 809.513 868.536 809.532C868.733 809.552 868.92 809.629 869.073 809.755C869.277 809.923 869.406 810.165 869.432 810.427C869.459 810.69 869.381 810.952 869.215 811.158Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.1">
                            <path
    d="M1217.02 368.997C1217 368.701 1217.07 368.406 1217.22 368.15C1217.37 367.893 1217.59 367.687 1217.86 367.558C1218.13 367.428 1218.43 367.38 1218.72 367.42C1219.01 367.46 1219.29 367.587 1219.51 367.783C1219.73 367.98 1219.89 368.238 1219.97 368.525C1220.04 368.812 1220.03 369.115 1219.94 369.396C1219.84 369.676 1219.66 369.922 1219.43 370.101C1219.19 370.281 1218.9 370.386 1218.61 370.404C1218.21 370.427 1217.82 370.292 1217.52 370.028C1217.23 369.765 1217.05 369.394 1217.02 368.997V368.997Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.21">
                            <path
    d="M1129.19 466.624C1129.18 466.525 1129.2 466.427 1129.25 466.342C1129.3 466.256 1129.38 466.187 1129.47 466.144C1129.56 466.101 1129.66 466.085 1129.75 466.098C1129.85 466.112 1129.94 466.154 1130.02 466.219C1130.09 466.285 1130.14 466.371 1130.17 466.467C1130.19 466.562 1130.19 466.663 1130.16 466.757C1130.13 466.85 1130.07 466.932 1129.99 466.992C1129.91 467.052 1129.81 467.087 1129.72 467.093C1129.65 467.097 1129.58 467.088 1129.52 467.067C1129.46 467.045 1129.4 467.012 1129.35 466.968C1129.3 466.925 1129.26 466.872 1129.24 466.813C1129.21 466.754 1129.19 466.69 1129.19 466.624V466.624Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.5">
                            <path
    d="M1217.39 330.623C1217.37 330.425 1217.42 330.229 1217.52 330.058C1217.62 329.887 1217.77 329.75 1217.95 329.663C1218.13 329.577 1218.32 329.545 1218.52 329.572C1218.72 329.599 1218.9 329.683 1219.05 329.814C1219.2 329.946 1219.3 330.118 1219.35 330.309C1219.4 330.501 1219.39 330.703 1219.33 330.89C1219.27 331.077 1219.15 331.24 1218.99 331.36C1218.83 331.479 1218.64 331.549 1218.44 331.561C1218.18 331.577 1217.92 331.487 1217.72 331.311C1217.52 331.135 1217.4 330.887 1217.39 330.623V330.623Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.53">
                            <path
    d="M1545.74 560.41C1545.74 560.311 1545.78 560.215 1545.83 560.134C1545.89 560.053 1545.97 559.991 1546.06 559.955C1546.16 559.92 1546.26 559.912 1546.35 559.934C1546.45 559.956 1546.54 560.006 1546.61 560.078C1546.67 560.15 1546.72 560.241 1546.74 560.339C1546.75 560.436 1546.74 560.537 1546.7 560.627C1546.66 560.717 1546.59 560.794 1546.51 560.846C1546.42 560.899 1546.33 560.925 1546.23 560.922C1546.09 560.918 1545.97 560.862 1545.88 560.766C1545.79 560.67 1545.74 560.542 1545.74 560.41V560.41Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.2">
                            <path
    d="M1175.31 505.52C1175.32 505.322 1175.38 505.13 1175.5 504.968C1175.61 504.805 1175.77 504.681 1175.96 504.61C1176.14 504.539 1176.34 504.524 1176.54 504.568C1176.73 504.611 1176.9 504.712 1177.04 504.856C1177.18 505 1177.27 505.181 1177.3 505.376C1177.33 505.572 1177.31 505.772 1177.23 505.953C1177.15 506.134 1177.01 506.287 1176.84 506.392C1176.68 506.496 1176.48 506.549 1176.28 506.543C1176.02 506.534 1175.77 506.422 1175.59 506.231C1175.41 506.039 1175.31 505.784 1175.31 505.52V505.52Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.28">
                            <path
    d="M1173.45 445.81C1173.45 445.711 1173.48 445.615 1173.54 445.534C1173.59 445.452 1173.67 445.39 1173.77 445.354C1173.86 445.318 1173.96 445.311 1174.06 445.333C1174.15 445.354 1174.24 445.405 1174.31 445.477C1174.38 445.549 1174.42 445.639 1174.44 445.737C1174.45 445.835 1174.44 445.936 1174.4 446.026C1174.36 446.117 1174.29 446.193 1174.21 446.245C1174.12 446.298 1174.03 446.324 1173.93 446.32C1173.8 446.316 1173.67 446.26 1173.58 446.165C1173.49 446.069 1173.44 445.942 1173.45 445.81V445.81Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.21">
                            <path
    d="M1099.04 399.149C1099.04 398.951 1099.1 398.759 1099.22 398.596C1099.33 398.434 1099.49 398.31 1099.68 398.239C1099.86 398.167 1100.06 398.153 1100.26 398.197C1100.45 398.24 1100.63 398.341 1100.76 398.485C1100.9 398.629 1100.99 398.81 1101.02 399.005C1101.05 399.201 1101.03 399.401 1100.95 399.582C1100.87 399.763 1100.73 399.916 1100.56 400.021C1100.4 400.126 1100.2 400.178 1100 400.172C1099.74 400.164 1099.49 400.052 1099.31 399.861C1099.13 399.669 1099.03 399.413 1099.04 399.149V399.149Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.09">
                            <path
    d="M1275.2 569.3C1275.21 569.101 1275.27 568.908 1275.38 568.745C1275.5 568.582 1275.66 568.457 1275.84 568.385C1276.03 568.313 1276.23 568.297 1276.42 568.341C1276.62 568.384 1276.79 568.484 1276.93 568.628C1277.07 568.772 1277.16 568.954 1277.19 569.149C1277.22 569.345 1277.2 569.546 1277.11 569.728C1277.03 569.909 1276.9 570.061 1276.73 570.166C1276.56 570.271 1276.37 570.323 1276.17 570.316C1275.91 570.307 1275.66 570.195 1275.48 570.005C1275.3 569.815 1275.2 569.562 1275.2 569.3V569.3Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.09">
                            <path
    d="M1267.05 552.106C1267.06 552.007 1267.09 551.911 1267.14 551.83C1267.2 551.749 1267.28 551.687 1267.37 551.651C1267.46 551.615 1267.56 551.608 1267.66 551.629C1267.76 551.65 1267.85 551.7 1267.91 551.771C1267.98 551.843 1268.03 551.933 1268.05 552.03C1268.06 552.128 1268.05 552.228 1268.01 552.319C1267.97 552.409 1267.9 552.486 1267.82 552.539C1267.74 552.592 1267.64 552.619 1267.54 552.617C1267.48 552.616 1267.41 552.602 1267.35 552.575C1267.29 552.549 1267.24 552.511 1267.19 552.463C1267.15 552.416 1267.11 552.36 1267.09 552.299C1267.06 552.237 1267.05 552.172 1267.05 552.106V552.106Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.45">
                            <path
    d="M1257.28 561.247C1257.28 561.148 1257.31 561.052 1257.37 560.971C1257.43 560.89 1257.51 560.828 1257.6 560.792C1257.69 560.756 1257.79 560.748 1257.89 560.77C1257.99 560.791 1258.07 560.841 1258.14 560.912C1258.21 560.983 1258.26 561.074 1258.27 561.171C1258.29 561.268 1258.28 561.369 1258.24 561.459C1258.2 561.55 1258.13 561.626 1258.05 561.68C1257.97 561.733 1257.87 561.76 1257.77 561.758C1257.64 561.755 1257.51 561.699 1257.42 561.603C1257.33 561.508 1257.28 561.38 1257.28 561.247Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.41">
                            <path
    d="M1197.37 575.048C1197.37 574.949 1197.4 574.853 1197.46 574.772C1197.52 574.691 1197.6 574.628 1197.69 574.593C1197.78 574.557 1197.88 574.549 1197.98 574.57C1198.07 574.592 1198.16 574.641 1198.23 574.713C1198.3 574.784 1198.34 574.874 1198.36 574.972C1198.38 575.069 1198.37 575.169 1198.33 575.26C1198.29 575.351 1198.22 575.427 1198.14 575.48C1198.05 575.534 1197.96 575.561 1197.86 575.559C1197.79 575.557 1197.73 575.543 1197.67 575.517C1197.61 575.49 1197.55 575.452 1197.51 575.405C1197.46 575.357 1197.43 575.301 1197.4 575.24C1197.38 575.179 1197.37 575.113 1197.37 575.048V575.048Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.61">
                            <path
    d="M1318.66 326.965C1318.65 326.866 1318.68 326.767 1318.73 326.682C1318.78 326.596 1318.85 326.528 1318.94 326.484C1319.03 326.441 1319.13 326.425 1319.23 326.438C1319.32 326.452 1319.42 326.494 1319.49 326.56C1319.56 326.625 1319.62 326.711 1319.64 326.807C1319.67 326.903 1319.66 327.004 1319.63 327.097C1319.6 327.191 1319.54 327.273 1319.46 327.333C1319.38 327.392 1319.29 327.427 1319.19 327.433C1319.06 327.441 1318.93 327.396 1318.83 327.308C1318.73 327.22 1318.67 327.097 1318.66 326.965V326.965Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.41">
                            <path
    d="M1384.31 525.035C1384.3 524.936 1384.32 524.838 1384.37 524.752C1384.42 524.667 1384.5 524.598 1384.59 524.555C1384.67 524.511 1384.77 524.495 1384.87 524.508C1384.97 524.522 1385.06 524.564 1385.14 524.63C1385.21 524.695 1385.26 524.781 1385.29 524.877C1385.31 524.973 1385.31 525.074 1385.28 525.167C1385.25 525.261 1385.19 525.343 1385.11 525.402C1385.03 525.462 1384.93 525.497 1384.83 525.503C1384.7 525.511 1384.57 525.466 1384.47 525.378C1384.37 525.291 1384.31 525.167 1384.31 525.035V525.035Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.31">
                            <g style={{ mixBlendMode: 'screen' }}>
                                <path
    d="M1255.19 530.352C1255.17 530.056 1255.24 529.761 1255.39 529.505C1255.54 529.249 1255.76 529.043 1256.03 528.913C1256.29 528.783 1256.59 528.735 1256.89 528.776C1257.18 528.816 1257.46 528.942 1257.68 529.139C1257.9 529.335 1258.06 529.593 1258.13 529.88C1258.21 530.167 1258.2 530.47 1258.1 530.75C1258.01 531.031 1257.83 531.276 1257.59 531.456C1257.36 531.636 1257.07 531.741 1256.78 531.76C1256.58 531.772 1256.38 531.746 1256.2 531.682C1256.01 531.617 1255.84 531.517 1255.69 531.386C1255.54 531.256 1255.42 531.097 1255.33 530.919C1255.25 530.742 1255.2 530.549 1255.19 530.352Z"
    fill="white"/>
                            </g>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.06">
                            <path
    d="M1179.33 456.72C1179.31 456.424 1179.38 456.129 1179.53 455.873C1179.68 455.617 1179.9 455.41 1180.17 455.28C1180.44 455.15 1180.74 455.103 1181.03 455.143C1181.33 455.183 1181.6 455.31 1181.82 455.506C1182.05 455.703 1182.2 455.961 1182.28 456.249C1182.35 456.536 1182.34 456.839 1182.25 457.119C1182.15 457.4 1181.97 457.645 1181.73 457.825C1181.5 458.004 1181.21 458.109 1180.92 458.127C1180.52 458.15 1180.13 458.015 1179.83 457.751C1179.54 457.488 1179.36 457.117 1179.33 456.72V456.72Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.47">
                            <path
    d="M1496.85 505.735C1496.83 505.439 1496.9 505.144 1497.05 504.888C1497.2 504.631 1497.42 504.425 1497.69 504.296C1497.95 504.166 1498.25 504.118 1498.55 504.158C1498.84 504.199 1499.12 504.325 1499.34 504.522C1499.56 504.719 1499.72 504.978 1499.79 505.265C1499.87 505.552 1499.86 505.855 1499.76 506.135C1499.66 506.416 1499.49 506.661 1499.25 506.84C1499.01 507.02 1498.73 507.125 1498.43 507.143C1498.04 507.166 1497.65 507.032 1497.35 506.768C1497.05 506.503 1496.87 506.132 1496.85 505.735V505.735Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.46">
                            <path
    d="M1186.72 496.188C1186.7 495.892 1186.77 495.598 1186.92 495.343C1187.07 495.088 1187.3 494.883 1187.56 494.754C1187.83 494.626 1188.13 494.579 1188.42 494.62C1188.72 494.661 1188.99 494.788 1189.21 494.986C1189.43 495.183 1189.59 495.441 1189.67 495.728C1189.74 496.015 1189.73 496.317 1189.63 496.597C1189.54 496.877 1189.36 497.122 1189.12 497.301C1188.89 497.48 1188.6 497.585 1188.31 497.603C1188.11 497.615 1187.91 497.588 1187.72 497.523C1187.54 497.458 1187.36 497.357 1187.22 497.226C1187.07 497.094 1186.95 496.935 1186.86 496.757C1186.78 496.579 1186.73 496.385 1186.72 496.188V496.188Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.53">
                            <path
    d="M1457.62 398.676C1457.6 398.38 1457.67 398.086 1457.82 397.831C1457.97 397.576 1458.2 397.371 1458.46 397.242C1458.73 397.114 1459.03 397.067 1459.32 397.108C1459.62 397.149 1459.89 397.277 1460.11 397.474C1460.33 397.671 1460.49 397.929 1460.57 398.216C1460.64 398.503 1460.63 398.806 1460.53 399.086C1460.44 399.366 1460.26 399.611 1460.02 399.79C1459.79 399.969 1459.5 400.074 1459.21 400.092C1459.01 400.103 1458.81 400.076 1458.62 400.011C1458.44 399.946 1458.27 399.845 1458.12 399.713C1457.97 399.582 1457.85 399.423 1457.76 399.245C1457.68 399.067 1457.63 398.874 1457.62 398.676V398.676Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.22">
                            <path
    d="M1553.15 449.309C1553.14 449.112 1553.19 448.916 1553.29 448.745C1553.39 448.574 1553.54 448.437 1553.71 448.351C1553.89 448.264 1554.09 448.232 1554.29 448.259C1554.48 448.286 1554.67 448.37 1554.81 448.501C1554.96 448.632 1555.07 448.804 1555.12 448.995C1555.17 449.186 1555.16 449.388 1555.1 449.575C1555.03 449.762 1554.91 449.926 1554.76 450.046C1554.6 450.165 1554.41 450.236 1554.21 450.248C1554.08 450.257 1553.95 450.239 1553.83 450.196C1553.7 450.153 1553.59 450.087 1553.49 449.999C1553.39 449.912 1553.31 449.806 1553.25 449.688C1553.19 449.569 1553.16 449.441 1553.15 449.309Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.48">
                            <path
    d="M1476.06 264.726C1476.05 264.627 1476.08 264.529 1476.13 264.443C1476.18 264.358 1476.25 264.289 1476.34 264.246C1476.43 264.202 1476.53 264.187 1476.63 264.2C1476.72 264.213 1476.82 264.256 1476.89 264.321C1476.96 264.387 1477.02 264.473 1477.04 264.569C1477.07 264.664 1477.06 264.765 1477.03 264.859C1477 264.952 1476.94 265.034 1476.86 265.094C1476.78 265.154 1476.69 265.189 1476.59 265.195C1476.46 265.203 1476.33 265.158 1476.23 265.07C1476.13 264.982 1476.07 264.858 1476.06 264.726V264.726Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.51">
                            <path
    d="M1370.54 259.901C1370.52 259.704 1370.57 259.507 1370.67 259.336C1370.77 259.165 1370.92 259.028 1371.1 258.941C1371.27 258.854 1371.47 258.822 1371.67 258.849C1371.86 258.876 1372.05 258.96 1372.2 259.092C1372.34 259.223 1372.45 259.395 1372.5 259.587C1372.55 259.778 1372.54 259.98 1372.48 260.167C1372.41 260.354 1372.29 260.518 1372.14 260.637C1371.98 260.757 1371.79 260.827 1371.59 260.839C1371.33 260.854 1371.07 260.764 1370.87 260.588C1370.67 260.413 1370.55 260.166 1370.54 259.901V259.901Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.48">
                            <path
    d="M1496.82 142.487C1496.81 142.29 1496.85 142.093 1496.95 141.922C1497.05 141.751 1497.2 141.614 1497.38 141.527C1497.56 141.44 1497.76 141.408 1497.95 141.435C1498.15 141.462 1498.33 141.546 1498.48 141.677C1498.63 141.808 1498.73 141.98 1498.78 142.172C1498.83 142.363 1498.83 142.565 1498.76 142.752C1498.7 142.939 1498.58 143.103 1498.42 143.222C1498.26 143.342 1498.07 143.412 1497.88 143.424C1497.61 143.441 1497.35 143.351 1497.15 143.175C1496.96 142.999 1496.83 142.752 1496.82 142.487V142.487Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.3">
                            <path
    d="M1550.67 413.606C1550.66 413.409 1550.71 413.213 1550.8 413.042C1550.9 412.872 1551.05 412.735 1551.23 412.648C1551.41 412.562 1551.6 412.53 1551.8 412.556C1552 412.582 1552.18 412.665 1552.33 412.795C1552.48 412.925 1552.58 413.096 1552.63 413.286C1552.68 413.477 1552.68 413.678 1552.62 413.865C1552.55 414.052 1552.44 414.216 1552.28 414.337C1552.13 414.458 1551.94 414.53 1551.74 414.545C1551.61 414.554 1551.48 414.537 1551.35 414.494C1551.23 414.452 1551.11 414.385 1551.01 414.298C1550.91 414.211 1550.83 414.105 1550.77 413.986C1550.71 413.867 1550.68 413.738 1550.67 413.606V413.606Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.57">
                            <path
    d="M1457.31 284.582C1457.31 284.484 1457.33 284.386 1457.38 284.3C1457.43 284.215 1457.5 284.147 1457.59 284.104C1457.68 284.061 1457.78 284.046 1457.88 284.06C1457.98 284.073 1458.07 284.116 1458.14 284.181C1458.22 284.247 1458.27 284.333 1458.29 284.429C1458.32 284.524 1458.32 284.625 1458.28 284.719C1458.25 284.812 1458.19 284.894 1458.11 284.953C1458.03 285.013 1457.94 285.048 1457.84 285.054C1457.77 285.058 1457.71 285.049 1457.65 285.027C1457.58 285.006 1457.53 284.972 1457.48 284.928C1457.43 284.884 1457.39 284.831 1457.36 284.772C1457.33 284.712 1457.31 284.648 1457.31 284.582V284.582Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.28">
                            <path
    d="M1516.99 302.164C1516.98 302.066 1517.01 301.968 1517.06 301.882C1517.11 301.797 1517.18 301.729 1517.27 301.686C1517.36 301.643 1517.46 301.628 1517.56 301.642C1517.65 301.655 1517.75 301.698 1517.82 301.764C1517.89 301.83 1517.95 301.916 1517.97 302.012C1518 302.107 1517.99 302.208 1517.96 302.301C1517.93 302.395 1517.87 302.476 1517.79 302.536C1517.71 302.595 1517.61 302.63 1517.52 302.636C1517.45 302.64 1517.38 302.631 1517.32 302.609C1517.26 302.588 1517.2 302.554 1517.15 302.51C1517.1 302.466 1517.06 302.413 1517.04 302.354C1517.01 302.294 1516.99 302.23 1516.99 302.164V302.164Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.6">
                            <path
    d="M1444.06 312.828C1444.05 312.73 1444.08 312.632 1444.13 312.547C1444.18 312.461 1444.25 312.393 1444.34 312.35C1444.43 312.307 1444.53 312.292 1444.63 312.306C1444.73 312.319 1444.82 312.362 1444.89 312.427C1444.96 312.493 1445.02 312.579 1445.04 312.675C1445.07 312.77 1445.06 312.871 1445.03 312.965C1445 313.058 1444.94 313.14 1444.86 313.199C1444.78 313.259 1444.69 313.294 1444.59 313.3C1444.52 313.304 1444.46 313.295 1444.39 313.273C1444.33 313.252 1444.28 313.218 1444.23 313.174C1444.18 313.13 1444.14 313.077 1444.11 313.018C1444.08 312.959 1444.06 312.894 1444.06 312.828V312.828Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.16">
                            <path
    d="M1423.14 502.409C1423.14 502.311 1423.16 502.212 1423.21 502.127C1423.26 502.041 1423.33 501.973 1423.42 501.929C1423.51 501.886 1423.61 501.87 1423.71 501.884C1423.81 501.897 1423.9 501.939 1423.97 502.005C1424.05 502.07 1424.1 502.156 1424.13 502.252C1424.15 502.348 1424.15 502.449 1424.12 502.542C1424.08 502.636 1424.02 502.718 1423.94 502.777C1423.87 502.837 1423.77 502.872 1423.67 502.878C1423.61 502.882 1423.54 502.873 1423.48 502.852C1423.42 502.831 1423.36 502.797 1423.31 502.754C1423.26 502.71 1423.22 502.657 1423.19 502.598C1423.16 502.539 1423.15 502.475 1423.14 502.409V502.409Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.17">
                            <path
    d="M1423.25 347.758C1423.23 347.561 1423.28 347.365 1423.38 347.195C1423.48 347.025 1423.63 346.888 1423.81 346.802C1423.99 346.717 1424.19 346.685 1424.38 346.713C1424.58 346.74 1424.76 346.825 1424.91 346.956C1425.06 347.088 1425.16 347.26 1425.21 347.451C1425.26 347.642 1425.25 347.844 1425.19 348.03C1425.12 348.217 1425.01 348.38 1424.85 348.5C1424.69 348.619 1424.5 348.69 1424.31 348.702C1424.17 348.71 1424.04 348.692 1423.92 348.649C1423.79 348.605 1423.68 348.538 1423.58 348.45C1423.48 348.363 1423.4 348.256 1423.34 348.138C1423.29 348.019 1423.25 347.89 1423.25 347.758Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.63">
                            <path
    d="M1289.58 352.126C1289.56 351.83 1289.64 351.535 1289.79 351.28C1289.94 351.025 1290.16 350.82 1290.43 350.691C1290.69 350.563 1290.99 350.516 1291.29 350.558C1291.58 350.599 1291.86 350.727 1292.08 350.924C1292.3 351.122 1292.46 351.38 1292.53 351.667C1292.6 351.954 1292.59 352.257 1292.49 352.537C1292.4 352.817 1292.22 353.062 1291.98 353.24C1291.75 353.419 1291.46 353.524 1291.17 353.541C1290.77 353.563 1290.38 353.426 1290.08 353.161C1289.78 352.896 1289.6 352.523 1289.58 352.126V352.126Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.11">
                            <path
    d="M1299.61 336.555C1299.6 336.456 1299.62 336.358 1299.67 336.273C1299.73 336.188 1299.8 336.12 1299.89 336.077C1299.98 336.034 1300.08 336.018 1300.17 336.032C1300.27 336.046 1300.36 336.088 1300.44 336.154C1300.51 336.22 1300.56 336.306 1300.59 336.401C1300.61 336.497 1300.61 336.598 1300.58 336.691C1300.55 336.784 1300.49 336.866 1300.41 336.926C1300.33 336.986 1300.23 337.021 1300.14 337.027C1300.07 337.031 1300 337.021 1299.94 337C1299.88 336.978 1299.82 336.944 1299.77 336.901C1299.72 336.857 1299.68 336.804 1299.66 336.744C1299.63 336.685 1299.61 336.621 1299.61 336.555V336.555Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.45">
                            <path
    d="M1343.09 333.914C1343.09 333.816 1343.11 333.718 1343.16 333.633C1343.21 333.547 1343.29 333.479 1343.38 333.436C1343.47 333.393 1343.57 333.378 1343.66 333.392C1343.76 333.405 1343.85 333.448 1343.93 333.513C1344 333.579 1344.05 333.665 1344.08 333.761C1344.1 333.856 1344.1 333.957 1344.07 334.05C1344.03 334.144 1343.97 334.225 1343.9 334.285C1343.82 334.345 1343.72 334.38 1343.62 334.386C1343.56 334.39 1343.49 334.381 1343.43 334.359C1343.37 334.338 1343.31 334.304 1343.26 334.26C1343.21 334.217 1343.17 334.163 1343.14 334.104C1343.11 334.045 1343.1 333.98 1343.09 333.914Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.61">
                            <path
    d="M1230.24 427.12C1230.22 426.823 1230.29 426.527 1230.44 426.269C1230.59 426.012 1230.81 425.804 1231.08 425.673C1231.34 425.542 1231.64 425.493 1231.94 425.533C1232.23 425.573 1232.51 425.7 1232.73 425.897C1232.96 426.094 1233.11 426.353 1233.19 426.641C1233.26 426.929 1233.25 427.233 1233.15 427.514C1233.06 427.795 1232.88 428.04 1232.64 428.219C1232.4 428.398 1232.12 428.502 1231.82 428.518C1231.43 428.539 1231.04 428.405 1230.74 428.143C1230.45 427.881 1230.27 427.514 1230.24 427.12V427.12Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.11">
                            <path
    d="M1123.26 554.589C1123.24 554.293 1123.31 553.997 1123.46 553.739C1123.61 553.482 1123.83 553.275 1124.1 553.144C1124.37 553.014 1124.67 552.966 1124.96 553.006C1125.25 553.047 1125.53 553.174 1125.75 553.372C1125.98 553.569 1126.13 553.829 1126.21 554.117C1126.28 554.405 1126.27 554.708 1126.17 554.989C1126.07 555.27 1125.89 555.515 1125.65 555.693C1125.42 555.872 1125.13 555.975 1124.83 555.991C1124.64 556.003 1124.44 555.977 1124.26 555.913C1124.07 555.849 1123.9 555.749 1123.75 555.619C1123.61 555.489 1123.49 555.331 1123.4 555.154C1123.32 554.977 1123.27 554.785 1123.26 554.589V554.589Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.27">
                            <path
    d="M1185.68 308.542C1185.68 308.444 1185.7 308.345 1185.75 308.26C1185.8 308.174 1185.88 308.106 1185.96 308.062C1186.05 308.019 1186.15 308.003 1186.25 308.016C1186.35 308.03 1186.44 308.072 1186.51 308.137C1186.59 308.203 1186.64 308.289 1186.67 308.384C1186.69 308.48 1186.69 308.581 1186.66 308.675C1186.62 308.768 1186.57 308.85 1186.49 308.91C1186.41 308.97 1186.31 309.005 1186.21 309.011C1186.15 309.015 1186.08 309.006 1186.02 308.985C1185.96 308.964 1185.9 308.93 1185.85 308.887C1185.8 308.843 1185.76 308.79 1185.73 308.731C1185.71 308.672 1185.69 308.608 1185.68 308.542V308.542Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.12">
                            <g style={{ mixBlendMode: 'screen' }}>
                                <path
    d="M1477.02 297.086C1477 296.789 1477.07 296.493 1477.22 296.236C1477.37 295.979 1477.59 295.772 1477.86 295.641C1478.12 295.51 1478.42 295.462 1478.72 295.502C1479.01 295.542 1479.29 295.669 1479.51 295.866C1479.73 296.064 1479.89 296.323 1479.97 296.611C1480.04 296.899 1480.03 297.202 1479.93 297.483C1479.83 297.764 1479.65 298.009 1479.42 298.188C1479.18 298.367 1478.89 298.471 1478.6 298.487C1478.2 298.508 1477.82 298.373 1477.52 298.111C1477.22 297.849 1477.04 297.48 1477.02 297.086V297.086Z"
    fill="white"/>
                            </g>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.44">
                            <path
    d="M1253.42 446.608C1253.41 446.411 1253.45 446.214 1253.55 446.043C1253.65 445.872 1253.8 445.735 1253.98 445.648C1254.16 445.561 1254.36 445.529 1254.55 445.556C1254.75 445.583 1254.93 445.667 1255.08 445.799C1255.23 445.93 1255.33 446.102 1255.38 446.294C1255.43 446.485 1255.43 446.687 1255.36 446.874C1255.3 447.061 1255.18 447.225 1255.02 447.344C1254.86 447.464 1254.67 447.534 1254.48 447.546C1254.34 447.554 1254.21 447.536 1254.09 447.493C1253.96 447.451 1253.85 447.384 1253.75 447.297C1253.65 447.21 1253.57 447.104 1253.52 446.986C1253.46 446.868 1253.43 446.739 1253.42 446.608Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.62">
                            <path
    d="M1380.23 255.578C1380.22 255.381 1380.26 255.185 1380.36 255.015C1380.46 254.845 1380.61 254.708 1380.79 254.623C1380.97 254.537 1381.17 254.506 1381.36 254.533C1381.56 254.561 1381.74 254.645 1381.89 254.777C1382.04 254.908 1382.14 255.08 1382.19 255.271C1382.24 255.462 1382.23 255.664 1382.17 255.851C1382.11 256.037 1381.99 256.201 1381.83 256.32C1381.67 256.44 1381.48 256.51 1381.29 256.522C1381.15 256.53 1381.02 256.511 1380.9 256.468C1380.77 256.425 1380.66 256.357 1380.56 256.27C1380.46 256.182 1380.38 256.076 1380.32 255.957C1380.27 255.839 1380.23 255.71 1380.23 255.578V255.578Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.33">
                            <g style={{ mixBlendMode: 'screen' }}>
                                <path
    d="M1278.05 326.633C1278.03 326.337 1278.1 326.042 1278.25 325.786C1278.4 325.53 1278.63 325.324 1278.89 325.194C1279.16 325.064 1279.46 325.017 1279.75 325.057C1280.05 325.097 1280.32 325.223 1280.54 325.42C1280.77 325.617 1280.92 325.875 1281 326.162C1281.07 326.449 1281.06 326.751 1280.97 327.032C1280.87 327.312 1280.69 327.558 1280.46 327.737C1280.22 327.917 1279.94 328.023 1279.64 328.041C1279.44 328.053 1279.25 328.026 1279.06 327.962C1278.87 327.898 1278.7 327.797 1278.55 327.666C1278.41 327.536 1278.29 327.377 1278.2 327.2C1278.11 327.023 1278.06 326.83 1278.05 326.633V326.633Z"
    fill="white"/>
                            </g>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.47">
                            <path
    d="M1265.44 303.692C1265.42 303.396 1265.49 303.101 1265.64 302.845C1265.79 302.588 1266.02 302.382 1266.28 302.253C1266.55 302.123 1266.85 302.075 1267.14 302.116C1267.44 302.156 1267.71 302.283 1267.94 302.48C1268.16 302.677 1268.32 302.935 1268.39 303.222C1268.47 303.509 1268.45 303.812 1268.36 304.093C1268.26 304.373 1268.08 304.619 1267.85 304.798C1267.61 304.977 1267.32 305.082 1267.03 305.1C1266.63 305.123 1266.24 304.988 1265.94 304.724C1265.65 304.46 1265.47 304.089 1265.44 303.692V303.692Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.36">
                            <path
    d="M1246.72 262.431C1246.7 262.135 1246.78 261.841 1246.92 261.585C1247.07 261.33 1247.3 261.124 1247.56 260.995C1247.83 260.865 1248.13 260.817 1248.42 260.858C1248.72 260.898 1248.99 261.024 1249.21 261.22C1249.43 261.416 1249.59 261.674 1249.67 261.96C1249.74 262.246 1249.73 262.549 1249.64 262.829C1249.54 263.11 1249.37 263.355 1249.13 263.536C1248.9 263.716 1248.61 263.822 1248.32 263.842C1247.92 263.865 1247.53 263.729 1247.23 263.465C1246.93 263.201 1246.75 262.829 1246.72 262.431V262.431Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.46">
                            <path
    d="M1439.52 546.3C1439.51 546.201 1439.53 546.102 1439.58 546.015C1439.63 545.928 1439.7 545.858 1439.79 545.813C1439.88 545.767 1439.98 545.75 1440.08 545.761C1440.18 545.773 1440.27 545.814 1440.34 545.879C1440.42 545.944 1440.47 546.03 1440.5 546.126C1440.53 546.222 1440.52 546.323 1440.49 546.417C1440.46 546.511 1440.4 546.594 1440.32 546.655C1440.24 546.715 1440.15 546.751 1440.05 546.757C1439.92 546.765 1439.79 546.722 1439.69 546.637C1439.59 546.551 1439.53 546.431 1439.52 546.3V546.3Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.51">
                            <path
    d="M1249.89 470.52C1249.88 470.259 1249.96 470.003 1250.13 469.805C1250.3 469.608 1250.55 469.486 1250.81 469.465C1251 469.448 1251.2 469.49 1251.38 469.587C1251.55 469.684 1251.69 469.83 1251.78 470.007C1251.87 470.184 1251.91 470.385 1251.88 470.582C1251.86 470.78 1251.78 470.965 1251.65 471.116C1251.52 471.266 1251.34 471.374 1251.15 471.427C1250.96 471.479 1250.76 471.472 1250.57 471.408C1250.38 471.344 1250.22 471.225 1250.1 471.067C1249.97 470.909 1249.9 470.718 1249.89 470.52V470.52Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.3">
                            <path
    d="M1600.76 343.216C1600.75 342.92 1600.82 342.626 1600.97 342.37C1601.12 342.115 1601.34 341.909 1601.61 341.78C1601.87 341.65 1602.17 341.603 1602.46 341.643C1602.76 341.683 1603.03 341.809 1603.25 342.005C1603.48 342.201 1603.64 342.459 1603.71 342.745C1603.79 343.031 1603.78 343.334 1603.68 343.614C1603.59 343.894 1603.41 344.14 1603.17 344.32C1602.94 344.501 1602.66 344.607 1602.36 344.627C1601.96 344.651 1601.57 344.516 1601.27 344.252C1600.97 343.987 1600.79 343.614 1600.76 343.216Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.25">
                            <path
    d="M1621.05 496.653C1621.05 496.519 1621.1 496.389 1621.18 496.29C1621.27 496.19 1621.39 496.13 1621.52 496.122C1621.65 496.114 1621.78 496.158 1621.88 496.245C1621.98 496.333 1622.04 496.456 1622.05 496.589C1622.06 496.723 1622.01 496.854 1621.92 496.953C1621.84 497.052 1621.71 497.112 1621.58 497.12C1621.45 497.128 1621.33 497.084 1621.23 496.997C1621.13 496.91 1621.07 496.786 1621.05 496.653V496.653Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.11">
                            <path
    d="M1632.62 457.27C1632.6 456.973 1632.66 456.675 1632.81 456.416C1632.96 456.156 1633.18 455.945 1633.44 455.81C1633.71 455.676 1634.01 455.623 1634.3 455.66C1634.6 455.696 1634.88 455.82 1635.1 456.015C1635.33 456.209 1635.49 456.467 1635.57 456.754C1635.64 457.042 1635.64 457.346 1635.54 457.628C1635.45 457.911 1635.27 458.158 1635.03 458.339C1634.8 458.521 1634.51 458.627 1634.21 458.646C1633.82 458.671 1633.43 458.54 1633.14 458.283C1632.84 458.026 1632.66 457.662 1632.62 457.27V457.27Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.15">
                            <path
    d="M1557.02 320.925C1557 320.528 1557.13 320.137 1557.4 319.839C1557.66 319.541 1558.03 319.359 1558.43 319.335C1558.82 319.31 1559.21 319.444 1559.51 319.708C1559.81 319.971 1559.99 320.343 1560.02 320.74C1560.03 320.936 1560 321.134 1559.94 321.32C1559.87 321.506 1559.77 321.678 1559.64 321.826C1559.51 321.974 1559.36 322.094 1559.18 322.181C1559 322.267 1558.81 322.318 1558.61 322.33C1558.42 322.342 1558.22 322.316 1558.03 322.252C1557.85 322.188 1557.67 322.087 1557.53 321.957C1557.38 321.827 1557.26 321.668 1557.17 321.491C1557.09 321.314 1557.03 321.122 1557.02 320.925Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.22">
                            <path
    d="M1663.68 373.074C1663.66 372.778 1663.73 372.483 1663.88 372.226C1664.03 371.97 1664.25 371.763 1664.52 371.633C1664.79 371.502 1665.09 371.454 1665.38 371.494C1665.67 371.534 1665.95 371.66 1666.17 371.856C1666.4 372.053 1666.55 372.311 1666.63 372.598C1666.71 372.885 1666.69 373.188 1666.6 373.469C1666.5 373.749 1666.32 373.995 1666.09 374.175C1665.85 374.354 1665.57 374.46 1665.27 374.478C1664.88 374.501 1664.49 374.366 1664.19 374.103C1663.89 373.84 1663.71 373.47 1663.68 373.074Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.25">
                            <path
    d="M1250.28 403.151C1250.25 402.854 1250.32 402.556 1250.46 402.295C1250.61 402.034 1250.83 401.822 1251.09 401.687C1251.36 401.551 1251.66 401.498 1251.95 401.534C1252.25 401.571 1252.53 401.694 1252.76 401.889C1252.98 402.085 1253.14 402.343 1253.22 402.631C1253.3 402.919 1253.29 403.224 1253.19 403.506C1253.1 403.789 1252.92 404.036 1252.68 404.217C1252.44 404.398 1252.16 404.504 1251.86 404.521C1251.47 404.546 1251.09 404.416 1250.79 404.16C1250.49 403.904 1250.31 403.542 1250.28 403.151V403.151Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.35">
                            <path
    d="M1149.58 490.331C1149.55 490.034 1149.61 489.736 1149.76 489.475C1149.9 489.215 1150.12 489.003 1150.39 488.867C1150.65 488.732 1150.95 488.678 1151.25 488.714C1151.55 488.749 1151.82 488.872 1152.05 489.067C1152.28 489.262 1152.44 489.52 1152.52 489.807C1152.6 490.095 1152.59 490.399 1152.49 490.682C1152.4 490.965 1152.22 491.213 1151.98 491.394C1151.75 491.576 1151.46 491.683 1151.16 491.702C1150.77 491.723 1150.39 491.592 1150.09 491.336C1149.8 491.081 1149.61 490.721 1149.58 490.331Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.58">
                            <path
    d="M1196.37 307.886C1196.35 307.688 1196.39 307.489 1196.49 307.315C1196.59 307.142 1196.73 307.001 1196.91 306.91C1197.09 306.82 1197.29 306.784 1197.48 306.808C1197.68 306.832 1197.87 306.914 1198.02 307.043C1198.17 307.173 1198.28 307.345 1198.33 307.537C1198.38 307.728 1198.37 307.931 1198.31 308.12C1198.25 308.308 1198.13 308.473 1197.97 308.594C1197.81 308.715 1197.62 308.787 1197.43 308.799C1197.17 308.816 1196.91 308.729 1196.71 308.559C1196.51 308.388 1196.39 308.146 1196.37 307.886V307.886Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.12">
                            <path
    d="M1176.23 325.327C1176.22 325.13 1176.26 324.934 1176.36 324.764C1176.46 324.594 1176.61 324.458 1176.79 324.372C1176.97 324.287 1177.17 324.256 1177.36 324.283C1177.56 324.311 1177.74 324.396 1177.89 324.527C1178.04 324.659 1178.14 324.831 1178.19 325.022C1178.24 325.213 1178.23 325.415 1178.17 325.601C1178.1 325.788 1177.99 325.951 1177.83 326.07C1177.67 326.19 1177.48 326.26 1177.29 326.272C1177.15 326.28 1177.02 326.262 1176.9 326.219C1176.77 326.176 1176.66 326.108 1176.56 326.02C1176.46 325.932 1176.38 325.826 1176.32 325.707C1176.27 325.588 1176.23 325.459 1176.23 325.327V325.327Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.4">
                            <path
    d="M1147.54 386.934C1147.53 386.736 1147.58 386.539 1147.68 386.368C1147.78 386.197 1147.93 386.06 1148.1 385.973C1148.28 385.886 1148.48 385.854 1148.68 385.88C1148.87 385.907 1149.06 385.991 1149.21 386.122C1149.35 386.253 1149.46 386.425 1149.51 386.616C1149.56 386.808 1149.55 387.01 1149.49 387.197C1149.42 387.384 1149.31 387.548 1149.15 387.668C1148.99 387.787 1148.8 387.858 1148.6 387.87C1148.34 387.885 1148.08 387.795 1147.88 387.62C1147.68 387.444 1147.56 387.198 1147.54 386.934V386.934Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.3">
                            <path
    d="M1142.75 316.134C1142.73 315.838 1142.8 315.543 1142.95 315.287C1143.1 315.031 1143.33 314.826 1143.59 314.697C1143.86 314.567 1144.16 314.52 1144.45 314.561C1144.75 314.602 1145.02 314.73 1145.24 314.927C1145.47 315.125 1145.62 315.384 1145.7 315.671C1145.77 315.958 1145.76 316.261 1145.66 316.541C1145.56 316.822 1145.39 317.067 1145.15 317.245C1144.91 317.424 1144.63 317.528 1144.33 317.545C1144.13 317.557 1143.94 317.53 1143.75 317.466C1143.56 317.401 1143.39 317.301 1143.25 317.169C1143.1 317.038 1142.98 316.879 1142.89 316.702C1142.81 316.524 1142.76 316.331 1142.75 316.134V316.134Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.58">
                            <path
    d="M1451.24 316.116C1451.23 315.919 1451.28 315.723 1451.38 315.552C1451.48 315.382 1451.63 315.245 1451.8 315.159C1451.98 315.072 1452.18 315.041 1452.38 315.068C1452.57 315.095 1452.76 315.18 1452.9 315.311C1453.05 315.442 1453.16 315.614 1453.21 315.806C1453.26 315.997 1453.25 316.199 1453.19 316.385C1453.12 316.572 1453 316.736 1452.85 316.855C1452.69 316.975 1452.5 317.045 1452.3 317.057C1452.17 317.065 1452.04 317.047 1451.91 317.004C1451.79 316.961 1451.67 316.894 1451.58 316.807C1451.48 316.719 1451.4 316.613 1451.34 316.495C1451.28 316.376 1451.25 316.248 1451.24 316.116V316.116Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.62">
                            <path
    d="M1110.02 376.714C1110.01 376.615 1110.03 376.516 1110.08 376.429C1110.13 376.342 1110.2 376.272 1110.29 376.227C1110.38 376.181 1110.48 376.164 1110.58 376.175C1110.67 376.187 1110.77 376.228 1110.84 376.293C1110.92 376.358 1110.97 376.444 1111 376.54C1111.02 376.636 1111.02 376.737 1110.99 376.831C1110.96 376.925 1110.9 377.008 1110.82 377.069C1110.74 377.129 1110.65 377.165 1110.55 377.171C1110.42 377.179 1110.29 377.136 1110.19 377.051C1110.09 376.965 1110.03 376.845 1110.02 376.714V376.714Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.45">
                            <path
    d="M1463.16 191.923C1463.14 191.627 1463.21 191.332 1463.36 191.076C1463.51 190.821 1463.74 190.615 1464 190.486C1464.27 190.357 1464.57 190.309 1464.86 190.35C1465.16 190.391 1465.43 190.518 1465.65 190.716C1465.88 190.913 1466.03 191.172 1466.11 191.459C1466.18 191.746 1466.17 192.049 1466.07 192.329C1465.98 192.609 1465.8 192.855 1465.56 193.033C1465.32 193.212 1465.04 193.317 1464.74 193.334C1464.55 193.346 1464.35 193.319 1464.16 193.255C1463.98 193.19 1463.8 193.09 1463.66 192.959C1463.51 192.827 1463.39 192.668 1463.3 192.491C1463.22 192.313 1463.17 192.12 1463.16 191.923V191.923Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.48">
                            <path
    d="M1372.33 96.412C1372.32 96.3133 1372.34 96.2152 1372.39 96.1299C1372.44 96.0447 1372.52 95.9761 1372.61 95.933C1372.69 95.8898 1372.79 95.874 1372.89 95.8875C1372.99 95.901 1373.08 95.9431 1373.16 96.0087C1373.23 96.0743 1373.28 96.1603 1373.31 96.2559C1373.33 96.3515 1373.33 96.4524 1373.3 96.5458C1373.26 96.6393 1373.21 96.7211 1373.13 96.7809C1373.05 96.8408 1372.95 96.876 1372.86 96.8821C1372.79 96.8863 1372.72 96.8774 1372.66 96.856C1372.6 96.8346 1372.54 96.8011 1372.49 96.7574C1372.44 96.7137 1372.4 96.6607 1372.37 96.6014C1372.35 96.5421 1372.33 96.4778 1372.33 96.412V96.412Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.48">
                            <path
    d="M1509.26 277.692C1509.26 277.593 1509.28 277.495 1509.33 277.41C1509.38 277.325 1509.46 277.256 1509.54 277.214C1509.63 277.171 1509.73 277.155 1509.83 277.169C1509.93 277.183 1510.02 277.225 1510.09 277.291C1510.17 277.356 1510.22 277.443 1510.25 277.538C1510.27 277.634 1510.27 277.735 1510.23 277.828C1510.2 277.921 1510.14 278.003 1510.06 278.063C1509.99 278.122 1509.89 278.157 1509.79 278.163C1509.73 278.167 1509.66 278.158 1509.6 278.137C1509.54 278.115 1509.48 278.081 1509.43 278.038C1509.38 277.994 1509.34 277.941 1509.31 277.881C1509.28 277.822 1509.27 277.757 1509.26 277.692V277.692Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.4">
                            <path
    d="M1563.02 305.064C1563.03 304.767 1563.13 304.48 1563.3 304.238C1563.47 303.996 1563.71 303.811 1563.99 303.706C1564.27 303.601 1564.57 303.581 1564.86 303.649C1565.15 303.716 1565.41 303.868 1565.62 304.085C1565.82 304.302 1565.95 304.574 1566 304.867C1566.05 305.161 1566.01 305.462 1565.89 305.732C1565.76 306.002 1565.56 306.23 1565.31 306.386C1565.06 306.542 1564.76 306.62 1564.47 306.609C1564.07 306.595 1563.7 306.424 1563.43 306.135C1563.16 305.845 1563.01 305.461 1563.02 305.064V305.064Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.05">
                            <path
    d="M1527.3 353.389C1527.31 353.092 1527.4 352.805 1527.58 352.563C1527.75 352.321 1527.99 352.136 1528.27 352.031C1528.54 351.926 1528.85 351.905 1529.14 351.972C1529.42 352.039 1529.69 352.19 1529.89 352.407C1530.09 352.623 1530.23 352.895 1530.28 353.188C1530.33 353.481 1530.29 353.782 1530.16 354.052C1530.04 354.323 1529.84 354.551 1529.59 354.708C1529.34 354.865 1529.04 354.943 1528.75 354.933C1528.35 354.92 1527.98 354.75 1527.7 354.461C1527.43 354.171 1527.29 353.786 1527.3 353.389V353.389Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.3">
                            <path
    d="M1593.8 434.213C1593.8 434.015 1593.87 433.824 1593.98 433.663C1594.1 433.502 1594.26 433.379 1594.45 433.31C1594.63 433.24 1594.83 433.227 1595.02 433.272C1595.22 433.317 1595.39 433.419 1595.53 433.563C1595.66 433.707 1595.75 433.889 1595.78 434.084C1595.82 434.279 1595.79 434.479 1595.71 434.659C1595.63 434.84 1595.49 434.991 1595.32 435.096C1595.16 435.2 1594.96 435.253 1594.76 435.246C1594.5 435.236 1594.25 435.121 1594.07 434.928C1593.89 434.734 1593.79 434.477 1593.8 434.213Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.24">
                            <path
    d="M1485.38 427.12C1485.38 427.021 1485.42 426.926 1485.47 426.845C1485.53 426.765 1485.61 426.703 1485.7 426.669C1485.8 426.634 1485.9 426.627 1485.99 426.65C1486.09 426.672 1486.18 426.723 1486.24 426.795C1486.31 426.867 1486.36 426.958 1486.37 427.056C1486.39 427.153 1486.38 427.253 1486.33 427.343C1486.29 427.433 1486.23 427.509 1486.14 427.562C1486.06 427.614 1485.96 427.64 1485.86 427.637C1485.73 427.632 1485.6 427.575 1485.51 427.478C1485.42 427.381 1485.38 427.253 1485.38 427.12V427.12Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.54">
                            <path
    d="M1444.63 454.532C1444.64 454.433 1444.67 454.337 1444.73 454.256C1444.78 454.176 1444.86 454.114 1444.96 454.079C1445.05 454.044 1445.15 454.037 1445.25 454.06C1445.34 454.082 1445.43 454.133 1445.5 454.205C1445.57 454.278 1445.61 454.368 1445.63 454.466C1445.64 454.564 1445.63 454.664 1445.59 454.754C1445.55 454.845 1445.48 454.92 1445.4 454.972C1445.31 455.025 1445.21 455.05 1445.11 455.047C1444.98 455.042 1444.86 454.985 1444.77 454.889C1444.68 454.792 1444.63 454.664 1444.63 454.532V454.532Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.6">
                            <path
    d="M1418.21 487.223C1418.22 486.926 1418.32 486.639 1418.49 486.398C1418.66 486.157 1418.9 485.973 1419.18 485.869C1419.46 485.765 1419.76 485.745 1420.05 485.813C1420.34 485.881 1420.6 486.034 1420.8 486.251C1421.01 486.468 1421.14 486.74 1421.19 487.033C1421.24 487.326 1421.19 487.627 1421.07 487.897C1420.95 488.167 1420.75 488.395 1420.49 488.55C1420.24 488.706 1419.95 488.784 1419.65 488.773C1419.26 488.759 1418.88 488.587 1418.61 488.297C1418.34 488.006 1418.19 487.62 1418.21 487.223V487.223Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.37">
                            <path
    d="M1537.19 162.496C1537.18 162.398 1537.2 162.3 1537.25 162.215C1537.31 162.13 1537.38 162.061 1537.47 162.018C1537.56 161.976 1537.66 161.96 1537.75 161.974C1537.85 161.987 1537.94 162.029 1538.02 162.095C1538.09 162.161 1538.14 162.247 1538.17 162.342C1538.19 162.438 1538.19 162.539 1538.16 162.632C1538.13 162.725 1538.07 162.807 1537.99 162.867C1537.91 162.927 1537.82 162.962 1537.72 162.968C1537.65 162.972 1537.59 162.963 1537.52 162.941C1537.46 162.92 1537.4 162.886 1537.35 162.842C1537.3 162.799 1537.26 162.745 1537.24 162.686C1537.21 162.627 1537.19 162.562 1537.19 162.496Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.54">
                            <path
    d="M668.823 343.623C668.834 343.721 668.815 343.82 668.769 343.908C668.723 343.995 668.652 344.068 668.566 344.115C668.479 344.162 668.38 344.183 668.282 344.174C668.183 344.165 668.09 344.127 668.013 344.065C667.936 344.003 667.879 343.92 667.849 343.826C667.82 343.731 667.819 343.63 667.846 343.535C667.874 343.44 667.929 343.356 668.005 343.292C668.081 343.229 668.174 343.189 668.272 343.179C668.404 343.164 668.536 343.203 668.64 343.287C668.743 343.37 668.809 343.491 668.823 343.623V343.623Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.57">
                            <path
    d="M778.731 237.186C778.752 237.383 778.714 237.581 778.623 237.756C778.531 237.932 778.389 238.076 778.216 238.171C778.042 238.266 777.845 238.307 777.648 238.289C777.451 238.272 777.263 238.196 777.109 238.072C776.955 237.948 776.841 237.781 776.782 237.592C776.723 237.404 776.721 237.201 776.777 237.012C776.832 236.822 776.943 236.653 777.095 236.526C777.247 236.399 777.433 236.32 777.629 236.299C777.893 236.27 778.157 236.348 778.364 236.514C778.57 236.681 778.702 236.922 778.731 237.186V237.186Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.2">
                            <path
    d="M253.13 144.716C253.138 145.013 253.057 145.304 252.899 145.555C252.741 145.806 252.512 146.004 252.242 146.124C251.971 146.245 251.671 146.283 251.379 146.233C251.087 146.183 250.816 146.048 250.601 145.845C250.385 145.641 250.235 145.378 250.169 145.09C250.103 144.801 250.123 144.499 250.229 144.222C250.334 143.945 250.519 143.705 250.76 143.533C251.001 143.361 251.288 143.264 251.584 143.255C251.782 143.248 251.979 143.281 252.163 143.352C252.348 143.422 252.517 143.528 252.661 143.664C252.805 143.8 252.92 143.963 253.001 144.143C253.081 144.324 253.125 144.519 253.13 144.716V144.716Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.27">
                            <path
    d="M717.658 191.373C717.661 191.472 717.634 191.569 717.582 191.653C717.529 191.737 717.453 191.803 717.363 191.843C717.273 191.884 717.172 191.896 717.075 191.88C716.978 191.864 716.887 191.819 716.815 191.751C716.743 191.683 716.693 191.595 716.671 191.499C716.649 191.403 716.656 191.302 716.691 191.21C716.726 191.117 716.787 191.037 716.868 190.98C716.948 190.922 717.044 190.89 717.143 190.887C717.208 190.885 717.274 190.896 717.335 190.919C717.397 190.942 717.453 190.978 717.501 191.023C717.549 191.068 717.588 191.122 717.614 191.182C717.641 191.242 717.656 191.307 717.658 191.373V191.373Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.31">
                            <path
    d="M725.201 265.83C725.209 266.126 725.129 266.418 724.971 266.669C724.814 266.921 724.585 267.119 724.315 267.241C724.044 267.362 723.744 267.4 723.452 267.351C723.159 267.302 722.888 267.167 722.672 266.963C722.457 266.76 722.306 266.497 722.239 266.208C722.173 265.919 722.193 265.617 722.299 265.34C722.404 265.062 722.588 264.823 722.83 264.65C723.071 264.478 723.358 264.381 723.654 264.372C723.852 264.366 724.048 264.398 724.233 264.468C724.417 264.538 724.586 264.644 724.73 264.78C724.874 264.915 724.989 265.078 725.07 265.258C725.151 265.438 725.195 265.632 725.201 265.83V265.83Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.29">
                            <path
    d="M819.793 319.755C819.797 319.952 819.743 320.147 819.638 320.313C819.532 320.48 819.379 320.612 819.199 320.692C819.019 320.772 818.819 320.797 818.624 320.764C818.43 320.73 818.25 320.64 818.106 320.505C817.963 320.369 817.863 320.194 817.818 320.002C817.774 319.81 817.788 319.608 817.858 319.424C817.927 319.239 818.05 319.079 818.211 318.965C818.371 318.85 818.562 318.785 818.759 318.778C818.891 318.773 819.024 318.794 819.148 318.841C819.272 318.887 819.385 318.958 819.481 319.049C819.578 319.14 819.655 319.249 819.708 319.371C819.762 319.492 819.79 319.623 819.793 319.755V319.755Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.54">
                            <path
    d="M589.562 117.565C589.567 117.762 589.514 117.957 589.408 118.124C589.303 118.291 589.15 118.423 588.97 118.504C588.789 118.584 588.589 118.609 588.394 118.576C588.2 118.543 588.019 118.453 587.876 118.317C587.732 118.181 587.632 118.006 587.588 117.814C587.544 117.621 587.558 117.42 587.628 117.235C587.698 117.05 587.821 116.89 587.982 116.776C588.143 116.661 588.334 116.596 588.531 116.59C588.663 116.586 588.794 116.609 588.917 116.656C589.04 116.703 589.153 116.773 589.249 116.864C589.344 116.954 589.421 117.063 589.475 117.183C589.529 117.303 589.559 117.433 589.562 117.565V117.565Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.15">
                            <path
    d="M601.882 138.553C601.89 138.85 601.81 139.142 601.652 139.394C601.494 139.645 601.265 139.844 600.994 139.964C600.723 140.085 600.422 140.123 600.129 140.073C599.837 140.023 599.565 139.887 599.35 139.682C599.135 139.478 598.985 139.214 598.92 138.925C598.854 138.635 598.876 138.332 598.983 138.055C599.09 137.778 599.276 137.539 599.519 137.368C599.761 137.197 600.049 137.102 600.346 137.094C600.543 137.089 600.738 137.123 600.922 137.193C601.106 137.264 601.274 137.37 601.416 137.505C601.559 137.641 601.674 137.803 601.753 137.983C601.833 138.163 601.877 138.356 601.882 138.553V138.553Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.19">
                            <path
    d="M613.556 126.556C613.564 126.852 613.483 127.145 613.325 127.395C613.166 127.646 612.937 127.844 612.666 127.965C612.395 128.086 612.094 128.123 611.802 128.073C611.51 128.022 611.239 127.886 611.024 127.682C610.809 127.478 610.659 127.214 610.594 126.925C610.528 126.636 610.55 126.333 610.656 126.056C610.763 125.779 610.949 125.54 611.191 125.369C611.433 125.198 611.721 125.102 612.017 125.094C612.415 125.085 612.8 125.233 613.089 125.507C613.377 125.781 613.545 126.158 613.556 126.556Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.08">
                            <path
    d="M686.607 105.783C686.61 105.882 686.583 105.98 686.531 106.063C686.478 106.147 686.402 106.213 686.311 106.254C686.221 106.294 686.121 106.307 686.023 106.29C685.926 106.273 685.835 106.228 685.763 106.16C685.692 106.092 685.642 106.004 685.62 105.908C685.598 105.811 685.605 105.71 685.641 105.618C685.676 105.526 685.738 105.446 685.819 105.389C685.899 105.332 685.995 105.3 686.094 105.297C686.227 105.294 686.355 105.343 686.451 105.434C686.548 105.525 686.604 105.651 686.607 105.783V105.783Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.06">
                            <path
    d="M579.009 306.603C579.041 306.898 578.984 307.196 578.846 307.459C578.709 307.721 578.496 307.937 578.236 308.08C577.975 308.222 577.679 308.283 577.383 308.257C577.088 308.23 576.807 308.116 576.576 307.93C576.345 307.744 576.175 307.494 576.086 307.21C575.997 306.927 575.995 306.624 576.078 306.34C576.162 306.055 576.328 305.801 576.555 305.611C576.783 305.421 577.062 305.302 577.357 305.271C577.553 305.249 577.751 305.267 577.94 305.322C578.13 305.378 578.306 305.47 578.46 305.594C578.613 305.717 578.741 305.87 578.835 306.044C578.929 306.217 578.989 306.407 579.009 306.603Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.29">
                            <path
    d="M375.461 316.962C375.482 317.159 375.445 317.358 375.354 317.533C375.262 317.708 375.121 317.853 374.947 317.948C374.774 318.043 374.576 318.084 374.379 318.067C374.182 318.05 373.994 317.974 373.84 317.85C373.686 317.726 373.572 317.559 373.513 317.37C373.453 317.181 373.452 316.979 373.507 316.789C373.563 316.6 373.673 316.43 373.825 316.303C373.977 316.177 374.163 316.097 374.36 316.076C374.49 316.062 374.622 316.074 374.748 316.11C374.874 316.147 374.992 316.209 375.094 316.291C375.196 316.373 375.281 316.475 375.344 316.59C375.407 316.705 375.447 316.832 375.461 316.962V316.962Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.47">
                            <path
    d="M455.976 179.383C455.986 179.482 455.968 179.581 455.922 179.669C455.876 179.757 455.806 179.829 455.719 179.876C455.632 179.924 455.533 179.945 455.435 179.936C455.336 179.927 455.242 179.889 455.165 179.827C455.088 179.765 455.031 179.682 455.002 179.588C454.972 179.493 454.971 179.392 454.999 179.297C455.026 179.202 455.082 179.118 455.158 179.054C455.233 178.991 455.326 178.951 455.425 178.94C455.49 178.933 455.556 178.939 455.619 178.958C455.682 178.976 455.741 179.007 455.792 179.048C455.843 179.089 455.886 179.14 455.917 179.197C455.949 179.255 455.969 179.318 455.976 179.383V179.383Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.44">
                            <g style={{ mixBlendMode: 'screen' }}>
                                <path
    d="M615.763 165.17C615.773 165.268 615.754 165.367 615.709 165.455C615.663 165.542 615.592 165.614 615.505 165.662C615.418 165.709 615.32 165.73 615.221 165.721C615.123 165.712 615.029 165.674 614.952 165.612C614.875 165.551 614.818 165.467 614.789 165.373C614.759 165.279 614.758 165.178 614.786 165.083C614.813 164.988 614.868 164.903 614.944 164.84C615.02 164.776 615.112 164.737 615.211 164.726C615.276 164.718 615.342 164.724 615.405 164.743C615.469 164.761 615.528 164.792 615.579 164.833C615.63 164.874 615.673 164.925 615.704 164.983C615.736 165.041 615.756 165.104 615.763 165.17V165.17Z"
    fill="white"/>
                            </g>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.63">
                            <path
    d="M715.914 252.606C715.944 252.901 715.886 253.198 715.748 253.46C715.609 253.722 715.396 253.937 715.135 254.078C714.875 254.219 714.578 254.279 714.283 254.252C713.988 254.224 713.708 254.11 713.477 253.923C713.247 253.737 713.077 253.486 712.989 253.203C712.902 252.92 712.899 252.618 712.983 252.333C713.067 252.049 713.233 251.796 713.461 251.606C713.688 251.416 713.967 251.298 714.261 251.266C714.458 251.245 714.657 251.264 714.846 251.32C715.036 251.376 715.212 251.469 715.366 251.593C715.519 251.718 715.647 251.871 715.741 252.045C715.835 252.219 715.894 252.409 715.914 252.606V252.606Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.37">
                            <path
    d="M315.206 210.313C315.217 210.411 315.198 210.511 315.152 210.598C315.107 210.686 315.036 210.758 314.949 210.806C314.862 210.853 314.763 210.874 314.665 210.865C314.566 210.857 314.473 210.819 314.395 210.757C314.318 210.695 314.261 210.611 314.232 210.517C314.202 210.422 314.201 210.321 314.229 210.226C314.257 210.131 314.313 210.047 314.389 209.983C314.465 209.92 314.558 209.88 314.656 209.87C314.788 209.856 314.919 209.895 315.023 209.978C315.126 210.061 315.192 210.181 315.206 210.313V210.313Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.39">
                            <path
    d="M703.356 203.845C703.367 203.943 703.348 204.042 703.303 204.13C703.257 204.218 703.186 204.29 703.099 204.337C703.013 204.385 702.914 204.405 702.815 204.397C702.717 204.388 702.623 204.35 702.546 204.288C702.469 204.226 702.412 204.143 702.382 204.049C702.353 203.954 702.352 203.853 702.379 203.759C702.407 203.664 702.462 203.579 702.538 203.515C702.614 203.452 702.706 203.412 702.805 203.401C702.937 203.387 703.069 203.426 703.172 203.509C703.276 203.592 703.342 203.713 703.356 203.845Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.39">
                            <path
    d="M371.096 341.719C371.117 341.916 371.08 342.114 370.989 342.29C370.897 342.466 370.756 342.61 370.582 342.705C370.409 342.8 370.211 342.842 370.014 342.825C369.816 342.807 369.629 342.732 369.475 342.607C369.32 342.483 369.206 342.316 369.147 342.127C369.088 341.938 369.086 341.736 369.142 341.546C369.198 341.356 369.309 341.187 369.461 341.06C369.613 340.933 369.799 340.855 369.996 340.834C370.259 340.806 370.522 340.884 370.728 341.05C370.934 341.215 371.066 341.456 371.096 341.719Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.54">
                            <path
    d="M249.334 284.053C249.355 284.249 249.318 284.448 249.226 284.623C249.135 284.799 248.993 284.943 248.82 285.038C248.646 285.133 248.449 285.174 248.252 285.157C248.055 285.14 247.867 285.064 247.713 284.94C247.559 284.816 247.445 284.649 247.386 284.46C247.327 284.272 247.325 284.07 247.38 283.88C247.436 283.69 247.546 283.521 247.698 283.394C247.849 283.267 248.035 283.188 248.232 283.166C248.363 283.152 248.495 283.163 248.621 283.2C248.747 283.237 248.865 283.298 248.967 283.38C249.07 283.463 249.155 283.565 249.218 283.68C249.281 283.795 249.32 283.922 249.334 284.053V284.053Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.4">
                            <path
    d="M1077.79 303.69C1077.81 303.886 1077.77 304.085 1077.68 304.26C1077.59 304.436 1077.45 304.58 1077.28 304.675C1077.1 304.77 1076.91 304.811 1076.71 304.793C1076.51 304.776 1076.32 304.7 1076.17 304.576C1076.02 304.452 1075.9 304.285 1075.84 304.096C1075.78 303.907 1075.78 303.705 1075.84 303.515C1075.89 303.325 1076 303.156 1076.16 303.03C1076.31 302.903 1076.49 302.824 1076.69 302.803C1076.95 302.774 1077.22 302.852 1077.42 303.018C1077.63 303.185 1077.76 303.426 1077.79 303.69V303.69Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.06">
                            <path
    d="M496.669 506.808C496.68 506.906 496.661 507.006 496.616 507.094C496.57 507.182 496.499 507.254 496.413 507.302C496.326 507.349 496.227 507.37 496.128 507.362C496.03 507.353 495.936 507.315 495.859 507.253C495.781 507.191 495.724 507.107 495.695 507.013C495.665 506.918 495.664 506.817 495.692 506.722C495.72 506.627 495.776 506.543 495.852 506.479C495.928 506.416 496.021 506.376 496.119 506.366C496.251 506.352 496.382 506.391 496.485 506.474C496.588 506.557 496.654 506.677 496.669 506.808Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.22">
                            <path
    d="M338.421 664.063C338.453 664.358 338.397 664.657 338.26 664.92C338.123 665.183 337.911 665.4 337.65 665.543C337.39 665.686 337.093 665.748 336.797 665.721C336.501 665.695 336.22 665.582 335.989 665.395C335.757 665.209 335.587 664.958 335.498 664.675C335.409 664.391 335.407 664.088 335.491 663.803C335.575 663.518 335.741 663.264 335.97 663.074C336.198 662.884 336.477 662.766 336.772 662.735C337.167 662.693 337.562 662.81 337.871 663.059C338.18 663.308 338.377 663.669 338.421 664.063V664.063Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.11">
                            <path
    d="M254.054 328.574C254.065 328.672 254.046 328.772 254 328.859C253.955 328.947 253.884 329.02 253.797 329.067C253.711 329.115 253.612 329.136 253.513 329.127C253.414 329.119 253.32 329.081 253.243 329.019C253.166 328.957 253.109 328.873 253.08 328.779C253.05 328.684 253.049 328.583 253.077 328.488C253.105 328.393 253.16 328.308 253.236 328.245C253.312 328.181 253.406 328.142 253.504 328.132C253.636 328.118 253.767 328.156 253.87 328.239C253.973 328.322 254.039 328.442 254.054 328.574Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.54">
                            <path
    d="M1100.55 277.731C1100.58 278.026 1100.53 278.324 1100.39 278.588C1100.25 278.851 1100.04 279.068 1099.78 279.21C1099.52 279.353 1099.22 279.415 1098.93 279.389C1098.63 279.363 1098.35 279.25 1098.12 279.064C1097.89 278.878 1097.72 278.628 1097.63 278.345C1097.54 278.062 1097.54 277.759 1097.62 277.474C1097.7 277.189 1097.87 276.935 1098.1 276.745C1098.33 276.554 1098.6 276.435 1098.9 276.403C1099.29 276.36 1099.69 276.476 1100 276.725C1100.31 276.974 1100.51 277.336 1100.55 277.731V277.731Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.54">
                            <path
    d="M206.48 513.909C206.502 514.106 206.465 514.305 206.374 514.48C206.283 514.656 206.142 514.801 205.968 514.896C205.795 514.992 205.597 515.034 205.4 515.016C205.203 514.999 205.015 514.924 204.861 514.8C204.706 514.677 204.592 514.51 204.533 514.321C204.473 514.132 204.471 513.93 204.526 513.74C204.582 513.55 204.692 513.38 204.844 513.253C204.996 513.126 205.182 513.047 205.378 513.026C205.509 513.011 205.641 513.023 205.767 513.059C205.893 513.096 206.01 513.157 206.112 513.239C206.215 513.321 206.3 513.422 206.363 513.537C206.426 513.652 206.466 513.779 206.48 513.909V513.909Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.12">
                            <path
    d="M1114.43 241.793C1114.44 241.892 1114.42 241.991 1114.38 242.079C1114.33 242.166 1114.26 242.239 1114.17 242.286C1114.09 242.334 1113.99 242.355 1113.89 242.346C1113.79 242.337 1113.7 242.3 1113.62 242.238C1113.54 242.176 1113.48 242.092 1113.45 241.998C1113.43 241.903 1113.42 241.802 1113.45 241.707C1113.48 241.612 1113.53 241.528 1113.61 241.464C1113.69 241.401 1113.78 241.361 1113.88 241.351C1114.01 241.336 1114.14 241.375 1114.24 241.458C1114.35 241.541 1114.41 241.662 1114.43 241.793V241.793Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.48">
                            <path
    d="M408.776 209.957C408.787 210.056 408.768 210.155 408.723 210.243C408.677 210.331 408.606 210.403 408.52 210.45C408.433 210.498 408.334 210.519 408.235 210.51C408.137 210.501 408.043 210.464 407.966 210.402C407.889 210.34 407.832 210.256 407.802 210.162C407.773 210.067 407.772 209.966 407.799 209.871C407.827 209.776 407.883 209.692 407.959 209.628C408.035 209.565 408.128 209.525 408.226 209.515C408.358 209.5 408.489 209.539 408.593 209.622C408.696 209.705 408.762 209.826 408.776 209.957V209.957Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.47">
                            <path
    d="M1138.25 196.88C1138.27 197.077 1138.23 197.276 1138.14 197.452C1138.05 197.628 1137.91 197.773 1137.74 197.869C1137.56 197.964 1137.36 198.006 1137.17 197.989C1136.97 197.971 1136.78 197.896 1136.63 197.772C1136.47 197.648 1136.36 197.48 1136.3 197.291C1136.24 197.102 1136.24 196.9 1136.29 196.71C1136.35 196.52 1136.46 196.35 1136.61 196.224C1136.77 196.097 1136.95 196.018 1137.15 195.998C1137.41 195.97 1137.67 196.048 1137.88 196.213C1138.09 196.378 1138.22 196.618 1138.25 196.88V196.88Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.48">
                            <path
    d="M505.685 315.163C505.708 315.36 505.671 315.559 505.58 315.735C505.49 315.912 505.349 316.057 505.175 316.153C505.002 316.249 504.804 316.291 504.606 316.274C504.409 316.257 504.221 316.182 504.066 316.058C503.911 315.934 503.797 315.767 503.737 315.578C503.678 315.388 503.676 315.186 503.732 314.996C503.788 314.805 503.899 314.636 504.051 314.509C504.204 314.382 504.39 314.303 504.587 314.283C504.849 314.255 505.111 314.332 505.317 314.497C505.522 314.662 505.655 314.901 505.685 315.163V315.163Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.2">
                            <path
    d="M653.079 292.723C653.09 292.822 653.071 292.921 653.025 293.009C652.98 293.096 652.909 293.169 652.822 293.216C652.735 293.264 652.637 293.284 652.538 293.276C652.439 293.267 652.346 293.229 652.269 293.167C652.192 293.105 652.135 293.022 652.105 292.927C652.075 292.833 652.074 292.732 652.102 292.637C652.13 292.542 652.185 292.457 652.261 292.394C652.337 292.33 652.43 292.291 652.528 292.28C652.66 292.266 652.792 292.305 652.895 292.388C652.999 292.471 653.065 292.591 653.079 292.723V292.723Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.06">
                            <path
    d="M780.222 126.969C780.254 127.264 780.197 127.562 780.06 127.824C779.922 128.087 779.71 128.304 779.449 128.446C779.189 128.588 778.892 128.65 778.597 128.624C778.302 128.597 778.02 128.484 777.789 128.298C777.558 128.111 777.388 127.861 777.299 127.578C777.21 127.295 777.208 126.992 777.291 126.707C777.375 126.422 777.541 126.169 777.768 125.978C777.996 125.788 778.275 125.669 778.57 125.638C778.766 125.616 778.964 125.634 779.153 125.69C779.342 125.745 779.518 125.837 779.671 125.961C779.825 126.084 779.952 126.237 780.047 126.41C780.141 126.583 780.201 126.773 780.222 126.969V126.969Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.24">
                            <path
    d="M717.983 438.045C718.016 438.341 717.961 438.64 717.825 438.904C717.688 439.168 717.476 439.386 717.216 439.529C716.955 439.673 716.658 439.736 716.362 439.71C716.066 439.684 715.784 439.571 715.552 439.384C715.32 439.198 715.149 438.947 715.06 438.663C714.972 438.379 714.969 438.075 715.054 437.79C715.138 437.504 715.305 437.251 715.534 437.061C715.763 436.871 716.043 436.753 716.339 436.723C716.732 436.682 717.125 436.799 717.432 437.046C717.74 437.294 717.938 437.653 717.983 438.045V438.045Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.43">
                            <g style={{ mixBlendMode: 'screen' }}>
                                <path
    d="M1072.91 263.514C1072.92 263.612 1072.91 263.712 1072.86 263.8C1072.81 263.888 1072.74 263.961 1072.66 264.008C1072.57 264.056 1072.47 264.077 1072.37 264.069C1072.27 264.06 1072.18 264.022 1072.1 263.96C1072.03 263.898 1071.97 263.815 1071.94 263.72C1071.91 263.625 1071.91 263.524 1071.94 263.429C1071.96 263.334 1072.02 263.249 1072.1 263.186C1072.17 263.123 1072.27 263.083 1072.36 263.073C1072.5 263.059 1072.63 263.098 1072.73 263.181C1072.83 263.263 1072.9 263.383 1072.91 263.514V263.514Z"
    fill="white"/>
                            </g>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.48">
                            <path
    d="M624.037 269.646C624.059 269.843 624.022 270.042 623.931 270.218C623.84 270.394 623.698 270.539 623.524 270.635C623.351 270.73 623.153 270.772 622.955 270.754C622.758 270.737 622.57 270.661 622.416 270.537C622.261 270.413 622.147 270.245 622.088 270.056C622.029 269.867 622.028 269.664 622.084 269.474C622.14 269.284 622.252 269.115 622.405 268.988C622.557 268.862 622.744 268.783 622.941 268.763C623.203 268.736 623.465 268.814 623.67 268.979C623.876 269.145 624.007 269.384 624.037 269.646V269.646Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.58">
                            <path
    d="M476.9 515.886C476.922 516.083 476.886 516.282 476.795 516.458C476.705 516.634 476.563 516.78 476.39 516.876C476.216 516.972 476.018 517.014 475.821 516.997C475.623 516.98 475.435 516.905 475.281 516.781C475.126 516.657 475.012 516.489 474.952 516.3C474.893 516.111 474.891 515.908 474.947 515.718C475.003 515.528 475.114 515.359 475.266 515.232C475.418 515.105 475.605 515.026 475.802 515.005C476.064 514.978 476.326 515.055 476.532 515.22C476.737 515.385 476.869 515.624 476.9 515.886V515.886Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.13">
                            <path
    d="M642.717 499.458C642.738 499.655 642.701 499.854 642.609 500.029C642.517 500.205 642.376 500.349 642.202 500.444C642.029 500.539 641.831 500.58 641.633 500.563C641.436 500.545 641.249 500.469 641.095 500.345C640.941 500.221 640.827 500.053 640.768 499.864C640.709 499.675 640.708 499.473 640.764 499.283C640.82 499.093 640.931 498.924 641.083 498.798C641.235 498.671 641.421 498.592 641.618 498.572C641.881 498.545 642.144 498.623 642.35 498.789C642.555 498.955 642.687 499.196 642.717 499.458Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.2">
                            <path
    d="M386.201 156.002C386.223 156.199 386.186 156.398 386.095 156.574C386.003 156.75 385.862 156.895 385.688 156.991C385.514 157.086 385.316 157.128 385.119 157.11C384.921 157.093 384.733 157.017 384.579 156.892C384.425 156.768 384.311 156.6 384.252 156.411C384.193 156.222 384.192 156.019 384.248 155.829C384.305 155.639 384.417 155.47 384.569 155.344C384.722 155.217 384.909 155.139 385.106 155.119C385.368 155.091 385.631 155.169 385.836 155.334C386.041 155.5 386.172 155.74 386.201 156.002Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.64">
                            <path
    d="M426.372 112.478C426.383 112.576 426.364 112.676 426.318 112.763C426.272 112.851 426.201 112.923 426.114 112.971C426.027 113.018 425.928 113.039 425.83 113.03C425.731 113.021 425.637 112.983 425.56 112.92C425.483 112.858 425.427 112.774 425.397 112.68C425.368 112.585 425.368 112.484 425.396 112.389C425.424 112.294 425.48 112.209 425.556 112.146C425.633 112.083 425.726 112.044 425.825 112.034C425.89 112.027 425.956 112.033 426.018 112.051C426.081 112.07 426.14 112.101 426.191 112.142C426.242 112.183 426.284 112.234 426.315 112.292C426.346 112.349 426.365 112.413 426.372 112.478Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.46">
                            <path
    d="M626.567 239.595C626.578 239.693 626.559 239.793 626.513 239.88C626.468 239.968 626.397 240.04 626.31 240.088C626.223 240.135 626.124 240.156 626.025 240.147C625.926 240.138 625.833 240.1 625.756 240.037C625.679 239.975 625.622 239.891 625.593 239.797C625.563 239.702 625.563 239.601 625.591 239.506C625.62 239.411 625.675 239.326 625.752 239.263C625.828 239.2 625.921 239.161 626.02 239.151C626.151 239.137 626.283 239.176 626.386 239.26C626.488 239.343 626.554 239.463 626.567 239.595V239.595Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.43">
                            <path
    d="M195.508 419.549C195.53 419.684 195.525 419.821 195.492 419.953C195.459 420.085 195.4 420.208 195.317 420.317C195.235 420.425 195.132 420.515 195.013 420.582C194.895 420.649 194.764 420.691 194.629 420.706C194.494 420.72 194.358 420.707 194.228 420.667C194.098 420.627 193.977 420.561 193.874 420.473C193.77 420.385 193.686 420.277 193.625 420.155C193.565 420.033 193.53 419.901 193.523 419.765C193.509 419.509 193.594 419.258 193.76 419.063C193.925 418.868 194.159 418.744 194.414 418.717C194.668 418.689 194.923 418.76 195.127 418.915C195.33 419.07 195.467 419.297 195.508 419.549Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.27">
                            <path
    d="M162.295 228.858C162.317 229.055 162.28 229.254 162.188 229.43C162.097 229.606 161.956 229.751 161.782 229.846C161.608 229.942 161.41 229.983 161.212 229.966C161.015 229.948 160.827 229.872 160.673 229.748C160.519 229.623 160.405 229.456 160.346 229.266C160.287 229.077 160.286 228.875 160.342 228.685C160.399 228.494 160.51 228.325 160.663 228.199C160.816 228.073 161.002 227.994 161.2 227.974C161.462 227.947 161.724 228.024 161.93 228.19C162.135 228.355 162.266 228.595 162.295 228.858V228.858Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.28">
                            <path
    d="M149.424 278.817C149.457 279.112 149.402 279.411 149.265 279.675C149.128 279.939 148.916 280.156 148.655 280.299C148.395 280.443 148.097 280.505 147.801 280.479C147.505 280.452 147.223 280.339 146.992 280.152C146.76 279.965 146.59 279.714 146.501 279.43C146.413 279.146 146.411 278.842 146.496 278.557C146.58 278.272 146.748 278.018 146.977 277.829C147.206 277.639 147.486 277.522 147.782 277.492C148.175 277.453 148.567 277.57 148.874 277.818C149.181 278.066 149.379 278.425 149.424 278.817Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.41">
                            <path
    d="M114.618 385.989C114.629 386.088 114.61 386.187 114.564 386.275C114.518 386.363 114.447 386.435 114.36 386.482C114.273 386.53 114.174 386.55 114.076 386.541C113.977 386.532 113.883 386.494 113.806 386.432C113.729 386.369 113.673 386.286 113.643 386.191C113.614 386.096 113.614 385.995 113.642 385.9C113.67 385.805 113.726 385.721 113.802 385.658C113.879 385.595 113.972 385.555 114.071 385.546C114.202 385.532 114.333 385.571 114.436 385.654C114.538 385.738 114.604 385.858 114.618 385.989V385.989Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.47">
                            <path
    d="M630.431 323.772C630.463 324.068 630.406 324.366 630.269 324.63C630.131 324.893 629.918 325.11 629.657 325.252C629.397 325.394 629.099 325.456 628.803 325.429C628.507 325.402 628.226 325.287 627.995 325.1C627.764 324.913 627.594 324.662 627.506 324.378C627.419 324.094 627.417 323.79 627.502 323.506C627.587 323.221 627.755 322.968 627.984 322.778C628.213 322.589 628.493 322.472 628.788 322.442C629.183 322.4 629.577 322.517 629.885 322.767C630.193 323.016 630.389 323.378 630.431 323.772V323.772Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.05">
                            <path
    d="M750.605 208.904C750.627 209.101 750.589 209.3 750.498 209.476C750.406 209.651 750.264 209.796 750.09 209.89C749.916 209.985 749.718 210.026 749.521 210.008C749.323 209.99 749.136 209.914 748.982 209.79C748.828 209.665 748.715 209.497 748.656 209.308C748.597 209.119 748.596 208.916 748.653 208.726C748.71 208.536 748.821 208.368 748.974 208.241C749.127 208.115 749.313 208.037 749.511 208.017C749.773 207.99 750.036 208.068 750.242 208.234C750.447 208.4 750.578 208.641 750.605 208.904V208.904Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.04">
                            <path
    d="M704.102 439.537C704.141 439.931 704.023 440.324 703.774 440.631C703.525 440.939 703.165 441.136 702.772 441.18C702.476 441.212 702.178 441.156 701.915 441.018C701.651 440.88 701.435 440.667 701.292 440.406C701.15 440.145 701.089 439.848 701.116 439.552C701.143 439.256 701.257 438.975 701.444 438.744C701.631 438.513 701.882 438.342 702.166 438.255C702.45 438.167 702.754 438.165 703.039 438.25C703.323 438.335 703.576 438.503 703.766 438.732C703.955 438.961 704.072 439.241 704.102 439.537V439.537Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.15">
                            <path
    d="M727.709 416.611C727.73 416.808 727.692 417.006 727.6 417.181C727.508 417.356 727.366 417.5 727.192 417.594C727.018 417.689 726.82 417.73 726.623 417.711C726.426 417.693 726.239 417.617 726.085 417.493C725.932 417.368 725.818 417.201 725.76 417.012C725.701 416.823 725.7 416.621 725.756 416.431C725.812 416.242 725.923 416.073 726.076 415.946C726.228 415.82 726.414 415.741 726.61 415.721C726.741 415.706 726.873 415.718 726.999 415.755C727.126 415.793 727.243 415.854 727.345 415.937C727.447 416.02 727.532 416.122 727.595 416.238C727.657 416.353 727.696 416.48 727.709 416.611V416.611Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.15">
                            <path
    d="M759.401 337.983C759.412 338.082 759.394 338.181 759.348 338.269C759.302 338.357 759.232 338.43 759.145 338.478C759.058 338.525 758.959 338.546 758.86 338.537C758.761 338.529 758.668 338.491 758.59 338.428C758.513 338.366 758.456 338.282 758.427 338.188C758.397 338.093 758.397 337.992 758.425 337.897C758.453 337.802 758.509 337.717 758.585 337.654C758.662 337.591 758.755 337.551 758.854 337.541C758.985 337.529 759.115 337.568 759.218 337.65C759.32 337.733 759.386 337.852 759.401 337.983Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.4">
                            <path
    d="M769.966 426.129C769.997 426.424 769.94 426.722 769.802 426.984C769.664 427.247 769.451 427.463 769.19 427.604C768.93 427.746 768.633 427.807 768.337 427.779C768.042 427.752 767.761 427.638 767.53 427.451C767.3 427.265 767.13 427.014 767.042 426.73C766.954 426.447 766.952 426.144 767.036 425.859C767.121 425.575 767.288 425.322 767.516 425.132C767.744 424.942 768.023 424.824 768.318 424.793C768.714 424.752 769.11 424.869 769.419 425.12C769.728 425.37 769.925 425.733 769.966 426.129V426.129Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.39">
                            <path
    d="M1105.63 238.014C1105.67 238.309 1105.61 238.608 1105.47 238.871C1105.33 239.135 1105.12 239.351 1104.86 239.493C1104.6 239.636 1104.3 239.697 1104.01 239.67C1103.71 239.643 1103.43 239.529 1103.2 239.342C1102.97 239.155 1102.8 238.903 1102.71 238.62C1102.62 238.336 1102.62 238.032 1102.71 237.747C1102.79 237.462 1102.96 237.209 1103.19 237.02C1103.42 236.83 1103.7 236.713 1103.99 236.683C1104.39 236.644 1104.78 236.761 1105.09 237.01C1105.39 237.26 1105.59 237.62 1105.63 238.014V238.014Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.51">
                            <path
    d="M807.517 348.423C807.527 348.521 807.509 348.621 807.463 348.709C807.417 348.796 807.346 348.869 807.259 348.916C807.172 348.963 807.073 348.984 806.974 348.975C806.876 348.966 806.782 348.928 806.705 348.865C806.628 348.803 806.571 348.719 806.542 348.625C806.513 348.53 806.512 348.429 806.54 348.334C806.569 348.239 806.625 348.155 806.701 348.091C806.777 348.028 806.871 347.989 806.969 347.979C807.101 347.965 807.232 348.004 807.335 348.088C807.437 348.171 807.503 348.291 807.517 348.423V348.423Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.43">
                            <path
    d="M376.427 600.415C376.45 600.612 376.413 600.811 376.322 600.987C376.231 601.163 376.089 601.308 375.916 601.404C375.742 601.499 375.544 601.541 375.347 601.524C375.149 601.507 374.961 601.431 374.807 601.307C374.653 601.183 374.538 601.016 374.479 600.826C374.42 600.637 374.418 600.435 374.474 600.245C374.53 600.055 374.642 599.886 374.794 599.759C374.946 599.632 375.133 599.553 375.33 599.533C375.592 599.505 375.855 599.583 376.06 599.748C376.266 599.913 376.398 600.153 376.427 600.415Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.5">
                            <path
    d="M497.349 714.184C497.381 714.479 497.324 714.776 497.187 715.039C497.049 715.302 496.837 715.519 496.577 715.661C496.317 715.803 496.02 715.865 495.725 715.839C495.429 715.813 495.148 715.699 494.917 715.513C494.686 715.327 494.515 715.077 494.426 714.794C494.337 714.511 494.334 714.208 494.418 713.923C494.501 713.638 494.667 713.384 494.894 713.194C495.122 713.004 495.401 712.885 495.696 712.853C495.892 712.831 496.09 712.849 496.279 712.904C496.468 712.959 496.645 713.051 496.798 713.175C496.952 713.298 497.08 713.451 497.174 713.624C497.269 713.797 497.328 713.987 497.349 714.184Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.54">
                            <path
    d="M199.441 305.293C199.444 305.491 199.387 305.685 199.28 305.85C199.172 306.016 199.018 306.146 198.836 306.224C198.655 306.302 198.454 306.324 198.26 306.289C198.066 306.253 197.886 306.16 197.745 306.022C197.603 305.885 197.505 305.708 197.464 305.515C197.422 305.322 197.439 305.121 197.511 304.937C197.584 304.753 197.709 304.595 197.872 304.483C198.034 304.37 198.226 304.308 198.424 304.305C198.689 304.302 198.946 304.404 199.136 304.589C199.327 304.774 199.437 305.027 199.441 305.293Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.24">
                            <path
    d="M335.967 307.716C335.97 307.914 335.914 308.108 335.806 308.274C335.699 308.44 335.545 308.571 335.363 308.649C335.181 308.727 334.981 308.75 334.786 308.714C334.592 308.679 334.412 308.586 334.27 308.448C334.128 308.311 334.03 308.134 333.989 307.94C333.948 307.747 333.964 307.546 334.037 307.362C334.11 307.178 334.236 307.02 334.399 306.907C334.562 306.795 334.754 306.734 334.952 306.731C335.083 306.729 335.214 306.753 335.336 306.801C335.458 306.85 335.569 306.922 335.663 307.013C335.758 307.105 335.833 307.214 335.885 307.334C335.937 307.455 335.965 307.585 335.967 307.716V307.716Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.23">
                            <path
    d="M384.732 271.04C384.734 271.139 384.706 271.236 384.652 271.319C384.598 271.402 384.521 271.467 384.43 271.506C384.339 271.546 384.238 271.557 384.141 271.539C384.044 271.521 383.954 271.474 383.883 271.405C383.812 271.336 383.764 271.247 383.743 271.15C383.723 271.053 383.732 270.952 383.769 270.86C383.805 270.769 383.869 270.69 383.951 270.634C384.032 270.578 384.129 270.548 384.228 270.547C384.36 270.546 384.487 270.597 384.582 270.689C384.676 270.782 384.73 270.908 384.732 271.04V271.04Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.43">
                            <path
    d="M415.596 228.642C415.6 228.939 415.516 229.231 415.355 229.48C415.193 229.729 414.962 229.925 414.689 230.042C414.416 230.16 414.115 230.194 413.823 230.14C413.531 230.086 413.262 229.946 413.049 229.739C412.837 229.532 412.69 229.267 412.628 228.976C412.567 228.686 412.593 228.383 412.703 228.108C412.813 227.832 413.002 227.595 413.247 227.427C413.492 227.259 413.781 227.168 414.078 227.164C414.475 227.161 414.857 227.314 415.141 227.591C415.425 227.868 415.589 228.246 415.596 228.642V228.642Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.63">
                            <path
    d="M287.409 641.398C287.441 641.693 287.385 641.991 287.247 642.254C287.11 642.517 286.898 642.733 286.637 642.875C286.377 643.018 286.08 643.08 285.785 643.053C285.489 643.027 285.208 642.914 284.977 642.728C284.746 642.541 284.575 642.291 284.487 642.008C284.398 641.725 284.395 641.422 284.479 641.137C284.562 640.852 284.728 640.599 284.955 640.408C285.183 640.218 285.462 640.099 285.757 640.067C285.953 640.046 286.151 640.064 286.34 640.119C286.529 640.175 286.705 640.267 286.859 640.39C287.012 640.514 287.14 640.666 287.234 640.839C287.329 641.012 287.388 641.202 287.409 641.398V641.398Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.48">
                            <path
    d="M893.897 433.497C893.875 433.3 893.912 433.101 894.004 432.925C894.095 432.75 894.236 432.605 894.41 432.51C894.584 432.415 894.782 432.373 894.979 432.391C895.176 432.408 895.364 432.484 895.518 432.608C895.672 432.732 895.786 432.899 895.845 433.088C895.904 433.277 895.906 433.48 895.85 433.67C895.794 433.86 895.683 434.029 895.531 434.155C895.379 434.282 895.192 434.361 894.995 434.382C894.733 434.409 894.469 434.332 894.263 434.166C894.057 434 893.926 433.759 893.897 433.497V433.497Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.36">
                            <path
    d="M743.667 437.809C743.635 437.514 743.69 437.216 743.828 436.952C743.965 436.689 744.177 436.472 744.438 436.329C744.698 436.186 744.995 436.124 745.291 436.15C745.587 436.177 745.869 436.29 746.1 436.477C746.331 436.664 746.502 436.915 746.59 437.198C746.679 437.482 746.681 437.785 746.597 438.07C746.512 438.355 746.345 438.609 746.117 438.799C745.888 438.989 745.609 439.106 745.313 439.137C744.919 439.178 744.525 439.061 744.217 438.812C743.909 438.563 743.711 438.203 743.667 437.809V437.809Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.07">
                            <path
    d="M659.08 539.194C659.047 538.899 659.102 538.6 659.239 538.336C659.376 538.073 659.588 537.855 659.849 537.712C660.109 537.569 660.406 537.507 660.702 537.533C660.998 537.559 661.28 537.673 661.511 537.859C661.743 538.046 661.913 538.297 662.002 538.58C662.091 538.864 662.093 539.168 662.009 539.453C661.925 539.738 661.758 539.991 661.529 540.181C661.301 540.371 661.021 540.489 660.725 540.52C660.331 540.562 659.937 540.446 659.628 540.198C659.32 539.949 659.122 539.588 659.08 539.194Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.22">
                            <path
    d="M742.386 399.478C742.366 399.281 742.404 399.082 742.497 398.907C742.59 398.732 742.733 398.588 742.907 398.494C743.082 398.4 743.28 398.361 743.477 398.38C743.675 398.399 743.862 398.476 744.015 398.602C744.168 398.727 744.281 398.896 744.338 399.086C744.395 399.275 744.395 399.478 744.337 399.667C744.279 399.857 744.167 400.025 744.013 400.15C743.86 400.276 743.672 400.353 743.475 400.371C743.213 400.396 742.951 400.315 742.747 400.148C742.543 399.981 742.414 399.74 742.386 399.478Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.5">
                            <path
    d="M1079.25 615.052C1079.24 614.755 1079.32 614.462 1079.47 614.21C1079.63 613.957 1079.86 613.758 1080.13 613.636C1080.4 613.514 1080.7 613.475 1081 613.525C1081.29 613.575 1081.56 613.71 1081.78 613.915C1081.99 614.119 1082.14 614.383 1082.21 614.673C1082.27 614.963 1082.25 615.266 1082.15 615.543C1082.04 615.821 1081.85 616.06 1081.61 616.231C1081.37 616.402 1081.08 616.497 1080.78 616.504C1080.39 616.512 1080 616.364 1079.72 616.093C1079.43 615.821 1079.26 615.447 1079.25 615.052V615.052Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.56">
                            <path
    d="M708.317 575.985C708.313 575.886 708.34 575.788 708.392 575.704C708.445 575.62 708.521 575.553 708.611 575.513C708.702 575.472 708.802 575.459 708.9 575.476C708.998 575.492 709.088 575.538 709.16 575.606C709.232 575.674 709.282 575.762 709.304 575.858C709.326 575.955 709.319 576.056 709.283 576.148C709.247 576.241 709.185 576.321 709.104 576.378C709.023 576.435 708.927 576.467 708.828 576.469C708.696 576.471 708.569 576.422 708.473 576.332C708.378 576.241 708.321 576.117 708.317 575.985Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.39">
                            <path
    d="M702.897 516.435C702.891 516.236 702.943 516.041 703.048 515.873C703.153 515.704 703.306 515.571 703.487 515.49C703.668 515.409 703.869 515.383 704.065 515.417C704.26 515.45 704.441 515.541 704.585 515.677C704.729 515.814 704.829 515.99 704.872 516.184C704.916 516.377 704.901 516.579 704.829 516.764C704.757 516.949 704.632 517.109 704.47 517.222C704.307 517.336 704.115 517.399 703.916 517.403C703.653 517.408 703.399 517.309 703.208 517.128C703.017 516.947 702.905 516.698 702.897 516.435V516.435Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.19">
                            <path
    d="M809.831 635.482C809.821 635.185 809.9 634.892 810.058 634.639C810.215 634.387 810.444 634.187 810.715 634.066C810.986 633.944 811.287 633.905 811.58 633.955C811.874 634.004 812.145 634.14 812.361 634.344C812.577 634.549 812.727 634.812 812.793 635.102C812.859 635.392 812.837 635.695 812.73 635.973C812.623 636.25 812.436 636.49 812.193 636.661C811.95 636.832 811.662 636.927 811.365 636.934C810.969 636.943 810.586 636.796 810.299 636.524C810.012 636.252 809.843 635.877 809.831 635.482V635.482Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.06">
                            <path
    d="M732.818 644.53C732.816 644.431 732.844 644.334 732.897 644.251C732.95 644.167 733.027 644.102 733.117 644.062C733.208 644.022 733.308 644.01 733.405 644.027C733.503 644.044 733.593 644.09 733.664 644.158C733.736 644.227 733.785 644.315 733.807 644.411C733.828 644.508 733.82 644.609 733.784 644.701C733.749 644.793 733.686 644.873 733.605 644.929C733.524 644.986 733.428 645.017 733.329 645.02C733.264 645.021 733.198 645.01 733.137 644.986C733.076 644.962 733.02 644.927 732.972 644.881C732.925 644.836 732.887 644.781 732.86 644.721C732.834 644.661 732.82 644.596 732.818 644.53V644.53Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.58">
                            <path
    d="M843.421 391.505C843.41 391.407 843.43 391.307 843.476 391.22C843.522 391.132 843.593 391.061 843.68 391.013C843.767 390.966 843.866 390.946 843.964 390.955C844.063 390.964 844.156 391.003 844.233 391.065C844.31 391.127 844.366 391.211 844.396 391.305C844.425 391.4 844.425 391.501 844.397 391.596C844.369 391.691 844.313 391.775 844.237 391.838C844.161 391.901 844.068 391.94 843.97 391.951C843.904 391.958 843.838 391.952 843.775 391.933C843.712 391.914 843.654 391.883 843.603 391.842C843.552 391.8 843.509 391.749 843.478 391.692C843.447 391.634 843.427 391.57 843.421 391.505V391.505Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.45">
                            <path
    d="M981.727 476.976C981.696 476.681 981.754 476.383 981.892 476.121C982.03 475.858 982.244 475.643 982.504 475.501C982.765 475.36 983.062 475.299 983.358 475.327C983.653 475.354 983.934 475.469 984.164 475.656C984.395 475.843 984.564 476.094 984.652 476.378C984.74 476.662 984.741 476.965 984.656 477.249C984.572 477.534 984.404 477.786 984.176 477.976C983.947 478.165 983.668 478.283 983.373 478.313C983.177 478.334 982.979 478.315 982.79 478.259C982.601 478.203 982.425 478.11 982.273 477.986C982.12 477.862 981.993 477.709 981.899 477.535C981.806 477.362 981.747 477.172 981.727 476.976V476.976Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.39">
                            <path
    d="M710.745 526.981C710.735 526.882 710.754 526.783 710.8 526.696C710.847 526.608 710.918 526.537 711.005 526.49C711.092 526.443 711.191 526.423 711.289 526.432C711.388 526.441 711.481 526.48 711.558 526.542C711.635 526.605 711.691 526.688 711.72 526.783C711.749 526.878 711.75 526.979 711.721 527.073C711.693 527.168 711.637 527.252 711.561 527.315C711.484 527.378 711.391 527.417 711.293 527.427C711.161 527.441 711.029 527.401 710.926 527.318C710.824 527.234 710.758 527.113 710.745 526.981Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.31">
                            <path
    d="M1030.05 562.392C1030.04 562.294 1030.06 562.194 1030.11 562.106C1030.15 562.019 1030.22 561.947 1030.31 561.9C1030.4 561.853 1030.5 561.833 1030.6 561.842C1030.7 561.852 1030.79 561.89 1030.87 561.953C1030.94 562.016 1031 562.1 1031.03 562.195C1031.06 562.29 1031.06 562.391 1031.03 562.486C1031 562.58 1030.94 562.665 1030.87 562.727C1030.79 562.79 1030.69 562.828 1030.6 562.838C1030.46 562.85 1030.33 562.81 1030.23 562.727C1030.13 562.644 1030.07 562.523 1030.05 562.392V562.392Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.3">
                            <path
    d="M718.811 566.198C718.78 565.903 718.838 565.605 718.976 565.343C719.115 565.08 719.328 564.865 719.589 564.724C719.85 564.582 720.147 564.522 720.443 564.55C720.738 564.577 721.019 564.692 721.249 564.879C721.479 565.066 721.649 565.318 721.736 565.601C721.824 565.885 721.825 566.188 721.74 566.473C721.655 566.757 721.488 567.01 721.259 567.199C721.031 567.388 720.751 567.506 720.456 567.536C720.06 567.576 719.665 567.458 719.357 567.208C719.048 566.957 718.852 566.594 718.811 566.198V566.198Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.14">
                            <path
    d="M985.802 457.154C985.782 456.957 985.82 456.759 985.913 456.584C986.005 456.409 986.147 456.265 986.321 456.171C986.495 456.077 986.693 456.037 986.89 456.055C987.087 456.074 987.274 456.15 987.428 456.275C987.581 456.4 987.694 456.567 987.753 456.756C987.811 456.945 987.812 457.148 987.755 457.337C987.698 457.527 987.587 457.695 987.435 457.821C987.282 457.948 987.096 458.026 986.899 458.046C986.768 458.06 986.636 458.047 986.51 458.01C986.385 457.973 986.267 457.911 986.165 457.828C986.064 457.745 985.979 457.643 985.917 457.527C985.854 457.412 985.815 457.285 985.802 457.154V457.154Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.61">
                            <path
    d="M1083.4 503.675C1083.4 503.576 1083.41 503.477 1083.46 503.39C1083.51 503.303 1083.58 503.231 1083.67 503.185C1083.75 503.138 1083.85 503.118 1083.95 503.127C1084.05 503.137 1084.14 503.175 1084.22 503.237C1084.29 503.3 1084.35 503.384 1084.38 503.478C1084.41 503.572 1084.41 503.673 1084.38 503.768C1084.35 503.863 1084.3 503.947 1084.22 504.01C1084.15 504.073 1084.05 504.112 1083.95 504.123C1083.89 504.129 1083.82 504.123 1083.76 504.104C1083.7 504.086 1083.64 504.054 1083.59 504.013C1083.54 503.971 1083.49 503.92 1083.46 503.862C1083.43 503.804 1083.41 503.74 1083.4 503.675V503.675Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.38">
                            <path
    d="M997.509 322.646C997.489 322.449 997.527 322.251 997.62 322.076C997.712 321.901 997.854 321.758 998.028 321.663C998.202 321.569 998.4 321.529 998.597 321.547C998.794 321.566 998.981 321.642 999.135 321.767C999.288 321.892 999.401 322.059 999.459 322.248C999.518 322.437 999.519 322.639 999.462 322.829C999.406 323.018 999.294 323.187 999.142 323.313C998.99 323.44 998.803 323.518 998.607 323.538C998.343 323.565 998.079 323.486 997.874 323.319C997.668 323.152 997.537 322.91 997.509 322.646V322.646Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.61">
                            <path
    d="M892.862 322.231C892.852 322.133 892.871 322.034 892.917 321.946C892.964 321.859 893.035 321.787 893.122 321.74C893.209 321.693 893.308 321.673 893.406 321.682C893.505 321.691 893.598 321.729 893.675 321.792C893.752 321.854 893.808 321.938 893.837 322.033C893.866 322.127 893.867 322.228 893.838 322.323C893.81 322.418 893.754 322.502 893.678 322.565C893.602 322.628 893.509 322.667 893.41 322.677C893.278 322.691 893.147 322.651 893.044 322.568C892.941 322.484 892.876 322.363 892.862 322.231V322.231Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.53">
                            <path
    d="M1014.03 199.528C1014.02 199.43 1014.04 199.33 1014.08 199.243C1014.13 199.156 1014.2 199.084 1014.29 199.037C1014.37 198.99 1014.47 198.97 1014.57 198.979C1014.67 198.988 1014.76 199.026 1014.84 199.088C1014.92 199.151 1014.97 199.235 1015 199.329C1015.03 199.423 1015.03 199.525 1015 199.619C1014.98 199.714 1014.92 199.798 1014.84 199.862C1014.77 199.925 1014.68 199.964 1014.58 199.974C1014.51 199.981 1014.45 199.975 1014.38 199.956C1014.32 199.938 1014.26 199.907 1014.21 199.865C1014.16 199.824 1014.12 199.773 1014.09 199.715C1014.05 199.657 1014.03 199.593 1014.03 199.528V199.528Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.22">
                            <path
    d="M1078.91 468.148C1078.89 467.951 1078.93 467.753 1079.02 467.577C1079.12 467.402 1079.26 467.258 1079.43 467.164C1079.61 467.07 1079.8 467.029 1080 467.047C1080.2 467.066 1080.38 467.142 1080.54 467.267C1080.69 467.391 1080.81 467.559 1080.86 467.748C1080.92 467.937 1080.92 468.139 1080.87 468.329C1080.81 468.519 1080.7 468.687 1080.55 468.814C1080.39 468.94 1080.21 469.018 1080.01 469.038C1079.75 469.065 1079.48 468.987 1079.28 468.82C1079.07 468.653 1078.94 468.411 1078.91 468.148V468.148Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.19">
                            <path
    d="M979.102 343.327C979.071 343.032 979.129 342.734 979.267 342.472C979.406 342.21 979.619 341.994 979.88 341.853C980.14 341.712 980.437 341.651 980.732 341.678C981.028 341.706 981.308 341.82 981.539 342.007C981.769 342.194 981.939 342.445 982.027 342.728C982.114 343.012 982.116 343.315 982.032 343.599C981.947 343.883 981.781 344.136 981.553 344.326C981.324 344.516 981.045 344.633 980.75 344.664C980.355 344.705 979.959 344.587 979.65 344.336C979.341 344.086 979.144 343.723 979.102 343.327Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.61">
                            <path
    d="M1040.5 358.239C1040.49 358.141 1040.51 358.042 1040.55 357.954C1040.6 357.867 1040.67 357.795 1040.76 357.748C1040.84 357.701 1040.94 357.68 1041.04 357.69C1041.14 357.699 1041.23 357.737 1041.31 357.8C1041.39 357.862 1041.44 357.946 1041.47 358.04C1041.5 358.135 1041.5 358.236 1041.47 358.331C1041.44 358.426 1041.39 358.51 1041.31 358.573C1041.24 358.636 1041.14 358.675 1041.05 358.685C1040.98 358.692 1040.91 358.686 1040.85 358.667C1040.79 358.648 1040.73 358.617 1040.68 358.576C1040.63 358.535 1040.59 358.484 1040.55 358.426C1040.52 358.368 1040.5 358.305 1040.5 358.239V358.239Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.29">
                            <path
    d="M955.284 562.325C955.274 562.226 955.293 562.127 955.339 562.04C955.386 561.952 955.457 561.88 955.544 561.833C955.631 561.786 955.73 561.766 955.828 561.776C955.927 561.785 956.02 561.823 956.097 561.886C956.173 561.948 956.23 562.032 956.259 562.126C956.288 562.221 956.289 562.322 956.26 562.417C956.232 562.511 956.176 562.596 956.1 562.659C956.024 562.722 955.931 562.761 955.832 562.771C955.767 562.778 955.701 562.772 955.638 562.753C955.575 562.734 955.517 562.703 955.466 562.662C955.415 562.62 955.372 562.569 955.341 562.511C955.31 562.453 955.291 562.39 955.284 562.325V562.325Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.28">
                            <path
    d="M948.779 407.801C948.759 407.604 948.797 407.406 948.89 407.231C948.982 407.056 949.125 406.913 949.299 406.819C949.473 406.725 949.671 406.685 949.867 406.704C950.064 406.722 950.251 406.799 950.405 406.924C950.558 407.048 950.671 407.216 950.729 407.405C950.787 407.594 950.788 407.796 950.732 407.986C950.675 408.175 950.564 408.344 950.411 408.47C950.259 408.596 950.073 408.674 949.876 408.694C949.745 408.708 949.613 408.696 949.487 408.658C949.361 408.62 949.244 408.558 949.142 408.475C949.04 408.392 948.956 408.29 948.893 408.174C948.831 408.059 948.792 407.932 948.779 407.801V407.801Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.63">
                            <path
    d="M824.788 401.871C824.778 401.773 824.797 401.674 824.843 401.586C824.89 401.499 824.961 401.427 825.048 401.38C825.135 401.333 825.234 401.313 825.332 401.323C825.431 401.332 825.524 401.37 825.601 401.433C825.677 401.495 825.734 401.579 825.763 401.673C825.792 401.768 825.793 401.869 825.764 401.964C825.736 402.058 825.68 402.143 825.604 402.206C825.528 402.269 825.435 402.308 825.336 402.318C825.271 402.325 825.205 402.319 825.142 402.3C825.079 402.281 825.02 402.25 824.969 402.209C824.918 402.167 824.876 402.116 824.845 402.058C824.814 402 824.794 401.937 824.788 401.871V401.871Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.34">
                            <path
    d="M867.619 397.425C867.598 397.228 867.636 397.03 867.728 396.855C867.82 396.68 867.962 396.536 868.136 396.442C868.31 396.347 868.507 396.306 868.704 396.324C868.901 396.342 869.088 396.418 869.242 396.543C869.396 396.667 869.509 396.834 869.568 397.023C869.627 397.212 869.628 397.414 869.572 397.604C869.516 397.793 869.406 397.962 869.254 398.089C869.102 398.215 868.916 398.294 868.719 398.315C868.456 398.342 868.192 398.264 867.985 398.097C867.779 397.931 867.647 397.689 867.619 397.425V397.425Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.51">
                            <path
    d="M760.347 495.223C760.336 495.124 760.355 495.025 760.401 494.937C760.446 494.849 760.517 494.776 760.605 494.729C760.692 494.681 760.791 494.661 760.89 494.67C760.988 494.679 761.082 494.717 761.159 494.78C761.236 494.843 761.293 494.927 761.322 495.021C761.351 495.116 761.351 495.218 761.322 495.313C761.294 495.408 761.238 495.492 761.161 495.555C761.084 495.618 760.99 495.656 760.892 495.666C760.761 495.679 760.63 495.639 760.528 495.556C760.426 495.473 760.361 495.353 760.347 495.223V495.223Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.29">
                            <path
    d="M658.899 627.156C658.889 627.058 658.907 626.958 658.953 626.87C658.999 626.782 659.07 626.71 659.157 626.663C659.244 626.615 659.344 626.595 659.442 626.604C659.541 626.613 659.635 626.651 659.712 626.714C659.789 626.776 659.845 626.86 659.875 626.955C659.904 627.05 659.904 627.151 659.875 627.246C659.846 627.341 659.79 627.425 659.714 627.488C659.637 627.551 659.543 627.59 659.444 627.599C659.314 627.612 659.183 627.573 659.081 627.49C658.979 627.407 658.914 627.287 658.899 627.156V627.156Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.33">
                            <g style={{ mixBlendMode: 'screen' }}>
                                <path
    d="M1000.35 354.908C1000.32 354.613 1000.38 354.316 1000.52 354.054C1000.66 353.793 1000.88 353.579 1001.14 353.439C1001.4 353.299 1001.7 353.24 1001.99 353.269C1002.29 353.297 1002.57 353.413 1002.79 353.6C1003.02 353.788 1003.19 354.039 1003.28 354.322C1003.37 354.606 1003.37 354.908 1003.28 355.192C1003.2 355.476 1003.03 355.728 1002.8 355.917C1002.57 356.106 1002.3 356.224 1002 356.254C1001.8 356.275 1001.61 356.256 1001.42 356.199C1001.23 356.143 1001.05 356.049 1000.9 355.924C1000.74 355.799 1000.62 355.645 1000.52 355.47C1000.43 355.296 1000.37 355.104 1000.35 354.908V354.908Z"
    fill="white"/>
                            </g>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.51">
                            <path
    d="M783.83 513.787C783.821 513.689 783.841 513.59 783.887 513.503C783.934 513.415 784.005 513.344 784.092 513.297C784.179 513.251 784.278 513.231 784.377 513.241C784.475 513.25 784.568 513.289 784.645 513.351C784.721 513.414 784.777 513.498 784.806 513.592C784.835 513.687 784.835 513.788 784.807 513.882C784.779 513.977 784.723 514.061 784.647 514.124C784.57 514.187 784.477 514.226 784.379 514.236C784.313 514.243 784.247 514.236 784.184 514.217C784.121 514.198 784.063 514.167 784.012 514.125C783.961 514.084 783.919 514.032 783.887 513.974C783.856 513.916 783.837 513.853 783.83 513.787Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.55">
                            <path
    d="M902.372 317.492C902.362 317.394 902.381 317.295 902.427 317.207C902.474 317.12 902.545 317.048 902.631 317.002C902.718 316.955 902.817 316.934 902.916 316.944C903.014 316.953 903.107 316.991 903.184 317.053C903.261 317.115 903.317 317.199 903.347 317.293C903.376 317.388 903.377 317.489 903.349 317.583C903.321 317.678 903.265 317.763 903.189 317.826C903.113 317.889 903.02 317.928 902.922 317.939C902.857 317.946 902.79 317.94 902.727 317.921C902.664 317.902 902.605 317.871 902.554 317.83C902.503 317.788 902.461 317.737 902.429 317.679C902.398 317.621 902.378 317.558 902.372 317.492V317.492Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.61">
                            <g style={{ mixBlendMode: 'screen' }}>
                                <path
    d="M802.826 392.908C802.796 392.613 802.854 392.315 802.992 392.053C803.131 391.791 803.344 391.575 803.605 391.434C803.866 391.293 804.163 391.233 804.458 391.26C804.754 391.288 805.034 391.403 805.264 391.59C805.494 391.777 805.664 392.028 805.752 392.312C805.839 392.595 805.841 392.898 805.756 393.182C805.671 393.467 805.504 393.72 805.276 393.909C805.048 394.099 804.769 394.216 804.474 394.247C804.078 394.287 803.682 394.169 803.373 393.918C803.065 393.667 802.868 393.304 802.826 392.908V392.908Z"
    fill="white"/>
                            </g>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.39">
                            <path
    d="M768.789 330.11C768.758 329.815 768.816 329.517 768.954 329.254C769.092 328.992 769.306 328.776 769.567 328.634C769.828 328.493 770.125 328.432 770.42 328.46C770.716 328.487 770.997 328.602 771.227 328.789C771.458 328.976 771.627 329.228 771.715 329.511C771.802 329.795 771.803 330.098 771.718 330.383C771.633 330.667 771.466 330.92 771.237 331.11C771.008 331.299 770.729 331.416 770.433 331.446C770.038 331.486 769.643 331.368 769.335 331.118C769.027 330.867 768.831 330.505 768.789 330.11V330.11Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.49">
                            <path
    d="M780.322 537.902C780.306 537.52 780.437 537.146 780.687 536.857C780.938 536.568 781.289 536.386 781.669 536.347C782.05 536.309 782.431 536.417 782.734 536.65C783.037 536.883 783.24 537.223 783.301 537.6C783.334 537.802 783.325 538.009 783.275 538.207C783.225 538.406 783.135 538.592 783.01 538.754C782.886 538.916 782.729 539.051 782.55 539.151C782.372 539.25 782.175 539.312 781.971 539.333C781.768 539.354 781.562 539.332 781.367 539.271C781.172 539.209 780.992 539.108 780.838 538.974C780.683 538.84 780.558 538.675 780.469 538.491C780.38 538.307 780.33 538.106 780.322 537.902V537.902Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.63">
                            <path
    d="M1126.95 395.584C1126.94 395.485 1126.96 395.386 1127.01 395.298C1127.05 395.211 1127.13 395.139 1127.21 395.092C1127.3 395.044 1127.4 395.024 1127.5 395.033C1127.6 395.043 1127.69 395.081 1127.77 395.143C1127.84 395.206 1127.9 395.289 1127.93 395.384C1127.96 395.479 1127.96 395.58 1127.93 395.675C1127.9 395.769 1127.85 395.854 1127.77 395.917C1127.69 395.98 1127.6 396.019 1127.5 396.029C1127.44 396.036 1127.37 396.03 1127.31 396.011C1127.24 395.992 1127.19 395.962 1127.14 395.92C1127.08 395.879 1127.04 395.828 1127.01 395.77C1126.98 395.712 1126.96 395.649 1126.95 395.584V395.584Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.12">
                            <path
    d="M1152.79 548.107C1152.78 548.009 1152.8 547.909 1152.85 547.822C1152.89 547.734 1152.96 547.662 1153.05 547.615C1153.14 547.568 1153.24 547.548 1153.34 547.557C1153.43 547.566 1153.53 547.604 1153.6 547.667C1153.68 547.729 1153.74 547.813 1153.77 547.907C1153.8 548.002 1153.8 548.103 1153.77 548.198C1153.74 548.293 1153.68 548.377 1153.61 548.44C1153.53 548.503 1153.44 548.542 1153.34 548.552C1153.27 548.559 1153.21 548.553 1153.15 548.535C1153.08 548.516 1153.02 548.485 1152.97 548.444C1152.92 548.402 1152.88 548.351 1152.85 548.293C1152.82 548.236 1152.8 548.172 1152.79 548.107Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.64">
                            <path
    d="M1163.65 508.172C1163.64 508.073 1163.66 507.974 1163.7 507.886C1163.75 507.798 1163.82 507.726 1163.9 507.678C1163.99 507.631 1164.09 507.61 1164.19 507.619C1164.29 507.628 1164.38 507.666 1164.46 507.728C1164.54 507.791 1164.59 507.874 1164.62 507.969C1164.65 508.064 1164.65 508.165 1164.62 508.26C1164.6 508.355 1164.54 508.439 1164.46 508.502C1164.39 508.566 1164.29 508.605 1164.2 508.615C1164.13 508.622 1164.06 508.616 1164 508.597C1163.94 508.579 1163.88 508.548 1163.83 508.507C1163.78 508.466 1163.74 508.415 1163.71 508.357C1163.67 508.3 1163.65 508.237 1163.65 508.172V508.172Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.51">
                            <path
    d="M1081.3 375.286C1081.27 374.991 1081.33 374.693 1081.47 374.43C1081.61 374.167 1081.82 373.952 1082.08 373.81C1082.34 373.669 1082.64 373.608 1082.93 373.635C1083.23 373.663 1083.51 373.778 1083.74 373.965C1083.97 374.152 1084.14 374.403 1084.23 374.687C1084.32 374.971 1084.32 375.274 1084.23 375.559C1084.15 375.843 1083.98 376.096 1083.75 376.285C1083.52 376.474 1083.24 376.592 1082.95 376.622C1082.75 376.642 1082.55 376.624 1082.36 376.569C1082.18 376.513 1082 376.42 1081.85 376.296C1081.69 376.172 1081.57 376.018 1081.47 375.845C1081.38 375.672 1081.32 375.482 1081.3 375.286V375.286Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.49">
                            <path
    d="M778.843 470.505C778.831 470.249 778.917 469.999 779.084 469.805C779.25 469.611 779.485 469.489 779.739 469.462C779.993 469.436 780.248 469.508 780.451 469.664C780.653 469.819 780.789 470.047 780.829 470.299C780.851 470.433 780.845 470.571 780.811 470.703C780.778 470.835 780.718 470.958 780.635 471.066C780.552 471.174 780.448 471.264 780.33 471.33C780.211 471.397 780.08 471.438 779.945 471.452C779.809 471.466 779.673 471.452 779.543 471.412C779.413 471.371 779.293 471.304 779.19 471.216C779.087 471.127 779.003 471.018 778.943 470.896C778.883 470.774 778.849 470.641 778.843 470.505Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.57">
                            <path
    d="M681.455 561.954C681.424 561.659 681.482 561.361 681.62 561.098C681.758 560.835 681.972 560.62 682.233 560.478C682.494 560.337 682.791 560.276 683.086 560.303C683.382 560.331 683.663 560.446 683.893 560.633C684.124 560.82 684.293 561.071 684.381 561.355C684.468 561.639 684.469 561.942 684.384 562.227C684.299 562.511 684.132 562.764 683.903 562.953C683.674 563.143 683.395 563.26 683.099 563.29C682.904 563.311 682.706 563.292 682.517 563.237C682.328 563.181 682.152 563.088 682 562.964C681.847 562.84 681.72 562.686 681.627 562.513C681.533 562.34 681.475 562.15 681.455 561.954V561.954Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.48">
                            <path
    d="M982.511 250.359C982.491 250.162 982.529 249.963 982.621 249.788C982.713 249.613 982.855 249.469 983.029 249.375C983.203 249.281 983.401 249.24 983.598 249.258C983.795 249.276 983.982 249.353 984.136 249.477C984.29 249.602 984.403 249.77 984.461 249.959C984.52 250.148 984.521 250.35 984.464 250.54C984.408 250.729 984.296 250.898 984.144 251.024C983.992 251.15 983.805 251.229 983.608 251.249C983.345 251.276 983.081 251.198 982.876 251.031C982.67 250.864 982.539 250.622 982.511 250.359Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.27">
                            <path
    d="M887.192 158.864C887.182 158.766 887.201 158.667 887.247 158.579C887.294 158.492 887.365 158.42 887.452 158.373C887.539 158.326 887.638 158.305 887.736 158.315C887.835 158.324 887.928 158.362 888.005 158.425C888.082 158.487 888.138 158.571 888.167 158.665C888.196 158.76 888.197 158.861 888.169 158.956C888.14 159.051 888.084 159.135 888.008 159.198C887.932 159.261 887.839 159.3 887.74 159.31C887.609 159.323 887.477 159.284 887.374 159.2C887.272 159.117 887.206 158.996 887.192 158.864V158.864Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.45">
                            <path
    d="M1031.74 334.118C1031.73 334.02 1031.75 333.921 1031.79 333.833C1031.84 333.746 1031.91 333.674 1032 333.627C1032.09 333.58 1032.18 333.559 1032.28 333.569C1032.38 333.578 1032.47 333.616 1032.55 333.678C1032.63 333.741 1032.69 333.825 1032.71 333.919C1032.74 334.014 1032.74 334.115 1032.72 334.21C1032.69 334.304 1032.63 334.389 1032.56 334.452C1032.48 334.515 1032.39 334.554 1032.29 334.564C1032.16 334.578 1032.02 334.538 1031.92 334.455C1031.82 334.371 1031.75 334.25 1031.74 334.118V334.118Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.14">
                            <path
    d="M1087.14 359.195C1087.14 358.997 1087.19 358.803 1087.3 358.637C1087.41 358.471 1087.56 358.341 1087.75 358.264C1087.93 358.186 1088.13 358.164 1088.32 358.201C1088.52 358.238 1088.7 358.331 1088.84 358.47C1088.98 358.609 1089.08 358.786 1089.12 358.98C1089.16 359.174 1089.14 359.375 1089.07 359.559C1088.99 359.742 1088.86 359.9 1088.7 360.011C1088.54 360.122 1088.34 360.183 1088.15 360.184C1087.88 360.186 1087.63 360.083 1087.44 359.898C1087.25 359.712 1087.14 359.46 1087.14 359.195V359.195Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.42">
                            <path
    d="M1123.38 486.892C1123.38 486.793 1123.4 486.696 1123.46 486.613C1123.51 486.531 1123.59 486.466 1123.68 486.427C1123.77 486.388 1123.87 486.377 1123.97 486.395C1124.07 486.414 1124.16 486.461 1124.23 486.53C1124.3 486.599 1124.35 486.688 1124.37 486.785C1124.39 486.882 1124.38 486.983 1124.34 487.074C1124.3 487.166 1124.24 487.245 1124.16 487.3C1124.08 487.356 1123.98 487.386 1123.88 487.387C1123.75 487.388 1123.62 487.336 1123.53 487.244C1123.43 487.151 1123.38 487.025 1123.38 486.892V486.892Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.07">
                            <path
    d="M950.201 547.355C950.199 547.157 950.256 546.963 950.364 546.797C950.472 546.631 950.627 546.501 950.809 546.424C950.991 546.346 951.192 546.324 951.387 546.361C951.581 546.398 951.76 546.491 951.902 546.63C952.043 546.769 952.14 546.946 952.18 547.14C952.221 547.334 952.202 547.535 952.128 547.719C952.054 547.903 951.927 548.06 951.763 548.171C951.6 548.283 951.407 548.343 951.209 548.344C950.944 548.346 950.689 548.243 950.501 548.058C950.312 547.872 950.204 547.62 950.201 547.355V547.355Z"
    fill="white"/>
                        </g>
                        <g style={{ mixBlendMode: 'screen' }} opacity="0.29">
                            <path
    d="M1054.22 217.888C1054.2 217.692 1054.24 217.493 1054.33 217.318C1054.42 217.144 1054.57 217 1054.74 216.906C1054.91 216.812 1055.11 216.771 1055.31 216.789C1055.51 216.808 1055.69 216.884 1055.85 217.009C1056 217.133 1056.11 217.3 1056.17 217.489C1056.23 217.678 1056.23 217.88 1056.18 218.07C1056.12 218.259 1056.01 218.428 1055.86 218.555C1055.7 218.681 1055.52 218.759 1055.32 218.78C1055.19 218.794 1055.06 218.782 1054.93 218.744C1054.81 218.707 1054.69 218.645 1054.59 218.562C1054.48 218.479 1054.4 218.377 1054.34 218.262C1054.27 218.146 1054.24 218.019 1054.22 217.888V217.888Z"
    fill="white"/>
                        </g>
                    </svg>
                </div>
                <div className="star-layer-2">
                    <svg width="1828" height="1098" viewBox="0 0 1828 1098" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.09"
    d="M64.7109 61.7561C65.2632 61.7561 65.7109 61.3079 65.7109 60.755C65.7109 60.2021 65.2632 59.7539 64.7109 59.7539C64.1587 59.7539 63.7109 60.2021 63.7109 60.755C63.7109 61.3079 64.1587 61.7561 64.7109 61.7561Z"
    fill="white"/>
                        <path opacity="0.5"
    d="M5.84961 161.907C6.12575 161.907 6.34961 161.683 6.34961 161.407C6.34961 161.13 6.12575 160.906 5.84961 160.906C5.57347 160.906 5.34961 161.13 5.34961 161.407C5.34961 161.683 5.57347 161.907 5.84961 161.907Z"
    fill="white"/>
                        <path opacity="0.63"
    d="M85.1836 265.987C85.7359 265.987 86.1836 265.538 86.1836 264.985C86.1836 264.433 85.7359 263.984 85.1836 263.984C84.6313 263.984 84.1836 264.433 84.1836 264.985C84.1836 265.538 84.6313 265.987 85.1836 265.987Z"
    fill="white"/>
                        <path opacity="0.36"
    d="M105.293 352.224C105.569 352.224 105.793 352 105.793 351.723C105.793 351.447 105.569 351.223 105.293 351.223C105.017 351.223 104.793 351.447 104.793 351.723C104.793 352 105.017 352.224 105.293 352.224Z"
    fill="white"/>
                        <path opacity="0.53"
    d="M136.369 483.752C136.921 483.752 137.369 483.304 137.369 482.751C137.369 482.198 136.921 481.75 136.369 481.75C135.817 481.75 135.369 482.198 135.369 482.751C135.369 483.304 135.817 483.752 136.369 483.752Z"
    fill="white"/>
                        <path opacity="0.37"
    d="M77.1406 587.33C77.6929 587.33 78.1406 586.882 78.1406 586.329C78.1406 585.776 77.6929 585.328 77.1406 585.328C76.5883 585.328 76.1406 585.776 76.1406 586.329C76.1406 586.882 76.5883 587.33 77.1406 587.33Z"
    fill="white"/>
                        <path opacity="0.57"
    d="M57.7656 717.627C58.3179 717.627 58.7656 717.179 58.7656 716.626C58.7656 716.073 58.3179 715.625 57.7656 715.625C57.2133 715.625 56.7656 716.073 56.7656 716.626C56.7656 717.179 57.2133 717.627 57.7656 717.627Z"
    fill="white"/>
                        <path opacity="0.17"
    d="M28.8828 866.592C29.4351 866.592 29.8828 866.144 29.8828 865.591C29.8828 865.038 29.4351 864.59 28.8828 864.59C28.3305 864.59 27.8828 865.038 27.8828 865.591C27.8828 866.144 28.3305 866.592 28.8828 866.592Z"
    fill="white"/>
                        <path opacity="0.19"
    d="M63.9805 903.786C64.2566 903.786 64.4805 903.562 64.4805 903.286C64.4805 903.009 64.2566 902.785 63.9805 902.785C63.7043 902.785 63.4805 903.009 63.4805 903.286C63.4805 903.562 63.7043 903.786 63.9805 903.786Z"
    fill="white"/>
                        <path opacity="0.43"
    d="M82.625 1057.41C83.4534 1057.41 84.125 1056.74 84.125 1055.91C84.125 1055.08 83.4534 1054.41 82.625 1054.41C81.7966 1054.41 81.125 1055.08 81.125 1055.91C81.125 1056.74 81.7966 1057.41 82.625 1057.41Z"
    fill="white"/>
                        <path opacity="0.09"
    d="M296.502 105.907C296.778 105.907 297.002 105.683 297.002 105.407C297.002 105.13 296.778 104.906 296.502 104.906C296.226 104.906 296.002 105.13 296.002 105.407C296.002 105.683 296.226 105.907 296.502 105.907Z"
    fill="white"/>
                        <path opacity="0.47"
    d="M276.76 198.042C277.588 198.042 278.26 197.37 278.26 196.541C278.26 195.711 277.588 195.039 276.76 195.039C275.931 195.039 275.26 195.711 275.26 196.541C275.26 197.37 275.931 198.042 276.76 198.042Z"
    fill="white"/>
                        <path opacity="0.49"
    d="M320.266 224.627C320.818 224.627 321.266 224.179 321.266 223.626C321.266 223.073 320.818 222.625 320.266 222.625C319.713 222.625 319.266 223.073 319.266 223.626C319.266 224.179 319.713 224.627 320.266 224.627Z"
    fill="white"/>
                        <path opacity="0.23"
    d="M280.414 430.085C281.242 430.085 281.914 429.413 281.914 428.584C281.914 427.754 281.242 427.082 280.414 427.082C279.586 427.082 278.914 427.754 278.914 428.584C278.914 429.413 279.586 430.085 280.414 430.085Z"
    fill="white"/>
                        <path opacity="0.32"
    d="M243.49 517.929C244.319 517.929 244.99 517.257 244.99 516.427C244.99 515.598 244.319 514.926 243.49 514.926C242.662 514.926 241.99 515.598 241.99 516.427C241.99 517.257 242.662 517.929 243.49 517.929Z"
    fill="white"/>
                        <path opacity="0.63"
    d="M199.982 617.575C200.259 617.575 200.482 617.351 200.482 617.075C200.482 616.798 200.259 616.574 199.982 616.574C199.706 616.574 199.482 616.798 199.482 617.075C199.482 617.351 199.706 617.575 199.982 617.575Z"
    fill="white"/>
                        <path opacity="0.17"
    d="M232.156 768.869C232.709 768.869 233.156 768.421 233.156 767.868C233.156 767.315 232.709 766.867 232.156 766.867C231.604 766.867 231.156 767.315 231.156 767.868C231.156 768.421 231.604 768.869 232.156 768.869Z"
    fill="white"/>
                        <path opacity="0.19"
    d="M235.447 796.319C236 796.319 236.447 795.87 236.447 795.318C236.447 794.765 236 794.316 235.447 794.316C234.895 794.316 234.447 794.765 234.447 795.318C234.447 795.87 234.895 796.319 235.447 796.319Z"
    fill="white"/>
                        <path opacity="0.16"
    d="M209.488 947.842C210.041 947.842 210.488 947.394 210.488 946.841C210.488 946.288 210.041 945.84 209.488 945.84C208.936 945.84 208.488 946.288 208.488 946.841C208.488 947.394 208.936 947.842 209.488 947.842Z"
    fill="white"/>
                        <path opacity="0.63"
    d="M360.848 1044.1C361.4 1044.1 361.848 1043.65 361.848 1043.1C361.848 1042.55 361.4 1042.1 360.848 1042.1C360.295 1042.1 359.848 1042.55 359.848 1043.1C359.848 1043.65 360.295 1044.1 360.848 1044.1Z"
    fill="white"/>
                        <path opacity="0.13"
    d="M370.354 87.6105C370.63 87.6105 370.854 87.3864 370.854 87.1099C370.854 86.8335 370.63 86.6094 370.354 86.6094C370.077 86.6094 369.854 86.8335 369.854 87.1099C369.854 87.3864 370.077 87.6105 370.354 87.6105Z"
    fill="white"/>
                        <path opacity="0.46"
    d="M425.559 170.23C426.387 170.23 427.059 169.558 427.059 168.728C427.059 167.899 426.387 167.227 425.559 167.227C424.73 167.227 424.059 167.899 424.059 168.728C424.059 169.558 424.73 170.23 425.559 170.23Z"
    fill="white"/>
                        <path opacity="0.36"
    d="M371.449 273.903C371.725 273.903 371.949 273.679 371.949 273.403C371.949 273.126 371.725 272.902 371.449 272.902C371.173 272.902 370.949 273.126 370.949 273.403C370.949 273.679 371.173 273.903 371.449 273.903Z"
    fill="white"/>
                        <path opacity="0.46"
    d="M403.256 375.053C403.808 375.053 404.256 374.605 404.256 374.052C404.256 373.499 403.808 373.051 403.256 373.051C402.704 373.051 402.256 373.499 402.256 374.052C402.256 374.605 402.704 375.053 403.256 375.053Z"
    fill="white"/>
                        <path opacity="0.07"
    d="M469.43 457.768C469.982 457.768 470.43 457.32 470.43 456.767C470.43 456.214 469.982 455.766 469.43 455.766C468.877 455.766 468.43 456.214 468.43 456.767C468.43 457.32 468.877 457.768 469.43 457.768Z"
    fill="white"/>
                        <path opacity="0.48"
    d="M416.418 561.117C417.246 561.117 417.918 560.444 417.918 559.615C417.918 558.786 417.246 558.113 416.418 558.113C415.59 558.113 414.918 558.786 414.918 559.615C414.918 560.444 415.59 561.117 416.418 561.117Z"
    fill="white"/>
                        <path opacity="0.14"
    d="M392.289 761.415C392.565 761.415 392.789 761.191 392.789 760.915C392.789 760.638 392.565 760.414 392.289 760.414C392.013 760.414 391.789 760.638 391.789 760.915C391.789 761.191 392.013 761.415 392.289 761.415Z"
    fill="white"/>
                        <path opacity="0.23"
    d="M427.02 845.232C427.296 845.232 427.52 845.007 427.52 844.731C427.52 844.455 427.296 844.23 427.02 844.23C426.743 844.23 426.52 844.455 426.52 844.731C426.52 845.007 426.743 845.232 427.02 845.232Z"
    fill="white"/>
                        <path opacity="0.58"
    d="M480.764 928.677C481.04 928.677 481.264 928.453 481.264 928.176C481.264 927.9 481.04 927.676 480.764 927.676C480.488 927.676 480.264 927.9 480.264 928.176C480.264 928.453 480.488 928.677 480.764 928.677Z"
    fill="white"/>
                        <path opacity="0.52"
    d="M369.621 1029.33C369.897 1029.33 370.121 1029.1 370.121 1028.82C370.121 1028.55 369.897 1028.32 369.621 1028.32C369.345 1028.32 369.121 1028.55 369.121 1028.82C369.121 1029.1 369.345 1029.33 369.621 1029.33Z"
    fill="white"/>
                        <path opacity="0.31"
    d="M549.131 59.5608C549.683 59.5608 550.131 59.1126 550.131 58.5597C550.131 58.0068 549.683 57.5586 549.131 57.5586C548.579 57.5586 548.131 58.0068 548.131 58.5597C548.131 59.1126 548.579 59.5608 549.131 59.5608Z"
    fill="white"/>
                        <path opacity="0.29"
    d="M682.209 196.811C682.761 196.811 683.209 196.363 683.209 195.81C683.209 195.257 682.761 194.809 682.209 194.809C681.657 194.809 681.209 195.257 681.209 195.81C681.209 196.363 681.657 196.811 682.209 196.811Z"
    fill="white"/>
                        <path opacity="0.38"
    d="M599.949 300.389C600.502 300.389 600.949 299.941 600.949 299.388C600.949 298.835 600.502 298.387 599.949 298.387C599.397 298.387 598.949 298.835 598.949 299.388C598.949 299.941 599.397 300.389 599.949 300.389Z"
    fill="white"/>
                        <path opacity="0.33"
    d="M690.252 420.573C691.08 420.573 691.752 419.901 691.752 419.072C691.752 418.243 691.08 417.57 690.252 417.57C689.424 417.57 688.752 418.243 688.752 419.072C688.752 419.901 689.424 420.573 690.252 420.573Z"
    fill="white"/>
                        <path opacity="0.15"
    d="M641.627 510.708C641.903 510.708 642.127 510.484 642.127 510.208C642.127 509.931 641.903 509.707 641.627 509.707C641.351 509.707 641.127 509.931 641.127 510.208C641.127 510.484 641.351 510.708 641.627 510.708Z"
    fill="white"/>
                        <path opacity="0.58"
    d="M722.426 584.175C723.254 584.175 723.926 583.503 723.926 582.674C723.926 581.844 723.254 581.172 722.426 581.172C721.597 581.172 720.926 581.844 720.926 582.674C720.926 583.503 721.597 584.175 722.426 584.175Z"
    fill="white"/>
                        <path opacity="0.13"
    d="M600.682 743.114C600.958 743.114 601.182 742.89 601.182 742.614C601.182 742.337 600.958 742.113 600.682 742.113C600.405 742.113 600.182 742.337 600.182 742.614C600.182 742.89 600.405 743.114 600.682 743.114Z"
    fill="white"/>
                        <path opacity="0.17"
    d="M714.018 786.669C714.294 786.669 714.518 786.445 714.518 786.169C714.518 785.892 714.294 785.668 714.018 785.668C713.741 785.668 713.518 785.892 713.518 786.169C713.518 786.445 713.741 786.669 714.018 786.669Z"
    fill="white"/>
                        <path opacity="0.29"
    d="M696.467 940.755C696.743 940.755 696.967 940.531 696.967 940.254C696.967 939.978 696.743 939.754 696.467 939.754C696.191 939.754 695.967 939.978 695.967 940.254C695.967 940.531 696.191 940.755 696.467 940.755Z"
    fill="white"/>
                        <path opacity="0.41"
    d="M563.754 1008.73C564.582 1008.73 565.254 1008.06 565.254 1007.23C565.254 1006.4 564.582 1005.73 563.754 1005.73C562.925 1005.73 562.254 1006.4 562.254 1007.23C562.254 1008.06 562.925 1008.73 563.754 1008.73Z"
    fill="white"/>
                        <path opacity="0.12"
    d="M757.523 4.29518C758.076 4.29518 758.523 3.84697 758.523 3.29407C758.523 2.74118 758.076 2.29297 757.523 2.29297C756.971 2.29297 756.523 2.74118 756.523 3.29407C756.523 3.84697 756.971 4.29518 757.523 4.29518Z"
    fill="white"/>
                        <path opacity="0.26"
    d="M841.611 147.037C842.164 147.037 842.611 146.589 842.611 146.036C842.611 145.483 842.164 145.035 841.611 145.035C841.059 145.035 840.611 145.483 840.611 146.036C840.611 146.589 841.059 147.037 841.611 147.037Z"
    fill="white"/>
                        <path opacity="0.18"
    d="M910.344 313.928C910.896 313.928 911.344 313.48 911.344 312.927C911.344 312.374 910.896 311.926 910.344 311.926C909.791 311.926 909.344 312.374 909.344 312.927C909.344 313.48 909.791 313.928 910.344 313.928Z"
    fill="white"/>
                        <path opacity="0.13"
    d="M756.791 354.924C757.343 354.924 757.791 354.476 757.791 353.923C757.791 353.37 757.343 352.922 756.791 352.922C756.239 352.922 755.791 353.37 755.791 353.923C755.791 354.476 756.239 354.924 756.791 354.924Z"
    fill="white"/>
                        <path opacity="0.05"
    d="M864.279 455.209C864.832 455.209 865.279 454.761 865.279 454.208C865.279 453.655 864.832 453.207 864.279 453.207C863.727 453.207 863.279 453.655 863.279 454.208C863.279 454.761 863.727 455.209 864.279 455.209Z"
    fill="white"/>
                        <path opacity="0.55"
    d="M753.867 606.132C754.696 606.132 755.367 605.46 755.367 604.631C755.367 603.801 754.696 603.129 753.867 603.129C753.039 603.129 752.367 603.801 752.367 604.631C752.367 605.46 753.039 606.132 753.867 606.132Z"
    fill="white"/>
                        <path opacity="0.64"
    d="M767.76 681.531C768.588 681.531 769.26 680.858 769.26 680.029C769.26 679.2 768.588 678.527 767.76 678.527C766.931 678.527 766.26 679.2 766.26 680.029C766.26 680.858 766.931 681.531 767.76 681.531Z"
    fill="white"/>
                        <path opacity="0.28"
    d="M751.309 848.888C751.585 848.888 751.809 848.664 751.809 848.387C751.809 848.111 751.585 847.887 751.309 847.887C751.032 847.887 750.809 848.111 750.809 848.387C750.809 848.664 751.032 848.888 751.309 848.888Z"
    fill="white"/>
                        <path opacity="0.47"
    d="M907.418 987.87C908.246 987.87 908.918 987.198 908.918 986.369C908.918 985.539 908.246 984.867 907.418 984.867C906.59 984.867 905.918 985.539 905.918 986.369C905.918 987.198 906.59 987.87 907.418 987.87Z"
    fill="white"/>
                        <path opacity="0.04"
    d="M912.902 990.299C913.455 990.299 913.902 989.851 913.902 989.298C913.902 988.745 913.455 988.297 912.902 988.297C912.35 988.297 911.902 988.745 911.902 989.298C911.902 989.851 912.35 990.299 912.902 990.299Z"
    fill="white"/>
                        <path opacity="0.17"
    d="M1092.78 47.9837C1093.61 47.9837 1094.28 47.3114 1094.28 46.4821C1094.28 45.6528 1093.61 44.9805 1092.78 44.9805C1091.95 44.9805 1091.28 45.6528 1091.28 46.4821C1091.28 47.3114 1091.95 47.9837 1092.78 47.9837Z"
    fill="white"/>
                        <path opacity="0.11"
    d="M1096.43 117.255C1096.71 117.255 1096.93 117.031 1096.93 116.754C1096.93 116.478 1096.71 116.254 1096.43 116.254C1096.16 116.254 1095.93 116.478 1095.93 116.754C1095.93 117.031 1096.16 117.255 1096.43 117.255Z"
    fill="white"/>
                        <path opacity="0.28"
    d="M1074.5 327.976C1075.33 327.976 1076 327.304 1076 326.474C1076 325.645 1075.33 324.973 1074.5 324.973C1073.67 324.973 1073 325.645 1073 326.474C1073 327.304 1073.67 327.976 1074.5 327.976Z"
    fill="white"/>
                        <path opacity="0.62"
    d="M1018.56 401.772C1019.11 401.772 1019.56 401.324 1019.56 400.771C1019.56 400.218 1019.11 399.77 1018.56 399.77C1018.01 399.77 1017.56 400.218 1017.56 400.771C1017.56 401.324 1018.01 401.772 1018.56 401.772Z"
    fill="white"/>
                        <path opacity="0.57"
    d="M1065.72 522.916C1066.28 522.916 1066.72 522.468 1066.72 521.915C1066.72 521.362 1066.28 520.914 1065.72 520.914C1065.17 520.914 1064.72 521.362 1064.72 521.915C1064.72 522.468 1065.17 522.916 1065.72 522.916Z"
    fill="white"/>
                        <path opacity="0.23"
    d="M932.279 654.907C932.555 654.907 932.779 654.683 932.779 654.407C932.779 654.13 932.555 653.906 932.279 653.906C932.003 653.906 931.779 654.13 931.779 654.407C931.779 654.683 932.003 654.907 932.279 654.907Z"
    fill="white"/>
                        <path opacity="0.4"
    d="M951.291 758.023C952.119 758.023 952.791 757.351 952.791 756.521C952.791 755.692 952.119 755.02 951.291 755.02C950.463 755.02 949.791 755.692 949.791 756.521C949.791 757.351 950.463 758.023 951.291 758.023Z"
    fill="white"/>
                        <path opacity="0.19"
    d="M1034.65 854.648C1035.48 854.648 1036.15 853.976 1036.15 853.146C1036.15 852.317 1035.48 851.645 1034.65 851.645C1033.82 851.645 1033.15 852.317 1033.15 853.146C1033.15 853.976 1033.82 854.648 1034.65 854.648Z"
    fill="white"/>
                        <path opacity="0.18"
    d="M1073.77 961.156C1074.6 961.156 1075.27 960.483 1075.27 959.654C1075.27 958.825 1074.6 958.152 1073.77 958.152C1072.94 958.152 1072.27 958.825 1072.27 959.654C1072.27 960.483 1072.94 961.156 1073.77 961.156Z"
    fill="white"/>
                        <path opacity="0.37"
    d="M1071.94 1090.58C1072.49 1090.58 1072.94 1090.14 1072.94 1089.58C1072.94 1089.03 1072.49 1088.58 1071.94 1088.58C1071.39 1088.58 1070.94 1089.03 1070.94 1089.58C1070.94 1090.14 1071.39 1090.58 1071.94 1090.58Z"
    fill="white"/>
                        <path opacity="0.32"
    d="M1113.98 61.8941C1114.81 61.8941 1115.48 61.2218 1115.48 60.3924C1115.48 59.563 1114.81 58.8906 1113.98 58.8906C1113.16 58.8906 1112.48 59.563 1112.48 60.3924C1112.48 61.2218 1113.16 61.8941 1113.98 61.8941Z"
    fill="white"/>
                        <path opacity="0.34"
    d="M1148.35 137.021C1148.63 137.021 1148.85 136.797 1148.85 136.52C1148.85 136.244 1148.63 136.02 1148.35 136.02C1148.07 136.02 1147.85 136.244 1147.85 136.52C1147.85 136.797 1148.07 137.021 1148.35 137.021Z"
    fill="white"/>
                        <path opacity="0.07"
    d="M1213.79 315.897C1214.62 315.897 1215.29 315.225 1215.29 314.396C1215.29 313.567 1214.62 312.895 1213.79 312.895C1212.96 312.895 1212.29 313.567 1212.29 314.396C1212.29 315.225 1212.96 315.897 1213.79 315.897Z"
    fill="white"/>
                        <path opacity="0.45"
    d="M1142.13 350.533C1142.69 350.533 1143.13 350.085 1143.13 349.532C1143.13 348.979 1142.69 348.531 1142.13 348.531C1141.58 348.531 1141.13 348.979 1141.13 349.532C1141.13 350.085 1141.58 350.533 1142.13 350.533Z"
    fill="white"/>
                        <path opacity="0.18"
    d="M1179.06 526.944C1179.61 526.944 1180.06 526.495 1180.06 525.942C1180.06 525.39 1179.61 524.941 1179.06 524.941C1178.51 524.941 1178.06 525.39 1178.06 525.942C1178.06 526.495 1178.51 526.944 1179.06 526.944Z"
    fill="white"/>
                        <path opacity="0.44"
    d="M1191.49 617.48C1192.32 617.48 1192.99 616.808 1192.99 615.978C1192.99 615.149 1192.32 614.477 1191.49 614.477C1190.66 614.477 1189.99 615.149 1189.99 615.978C1189.99 616.808 1190.66 617.48 1191.49 617.48Z"
    fill="white"/>
                        <path opacity="0.07"
    d="M1266.07 730.206C1266.9 730.206 1267.57 729.534 1267.57 728.705C1267.57 727.875 1266.9 727.203 1266.07 727.203C1265.24 727.203 1264.57 727.875 1264.57 728.705C1264.57 729.534 1265.24 730.206 1266.07 730.206Z"
    fill="white"/>
                        <path opacity="0.06"
    d="M1202.46 819.511C1203.29 819.511 1203.96 818.839 1203.96 818.009C1203.96 817.18 1203.29 816.508 1202.46 816.508C1201.63 816.508 1200.96 817.18 1200.96 818.009C1200.96 818.839 1201.63 819.511 1202.46 819.511Z"
    fill="white"/>
                        <path opacity="0.54"
    d="M1210.13 882.33C1210.69 882.33 1211.13 881.882 1211.13 881.329C1211.13 880.776 1210.69 880.328 1210.13 880.328C1209.58 880.328 1209.13 880.776 1209.13 881.329C1209.13 881.882 1209.58 882.33 1210.13 882.33Z"
    fill="white"/>
                        <path opacity="0.48"
    d="M1107.77 1031.89C1108.04 1031.89 1108.27 1031.67 1108.27 1031.39C1108.27 1031.11 1108.04 1030.89 1107.77 1030.89C1107.49 1030.89 1107.27 1031.11 1107.27 1031.39C1107.27 1031.67 1107.49 1031.89 1107.77 1031.89Z"
    fill="white"/>
                        <path opacity="0.15"
    d="M1281.43 48.4464C1281.7 48.4464 1281.93 48.2223 1281.93 47.9458C1281.93 47.6694 1281.7 47.4453 1281.43 47.4453C1281.15 47.4453 1280.93 47.6694 1280.93 47.9458C1280.93 48.2223 1281.15 48.4464 1281.43 48.4464Z"
    fill="white"/>
                        <path opacity="0.2"
    d="M1378.68 165.2C1378.95 165.2 1379.18 164.976 1379.18 164.7C1379.18 164.423 1378.95 164.199 1378.68 164.199C1378.4 164.199 1378.18 164.423 1378.18 164.7C1378.18 164.976 1378.4 165.2 1378.68 165.2Z"
    fill="white"/>
                        <path opacity="0.46"
    d="M1351.99 259.898C1352.82 259.898 1353.49 259.225 1353.49 258.396C1353.49 257.567 1352.82 256.895 1351.99 256.895C1351.16 256.895 1350.49 257.567 1350.49 258.396C1350.49 259.225 1351.16 259.898 1351.99 259.898Z"
    fill="white"/>
                        <path opacity="0.09"
    d="M1456.18 353.826C1456.74 353.826 1457.18 353.378 1457.18 352.825C1457.18 352.272 1456.74 351.824 1456.18 351.824C1455.63 351.824 1455.18 352.272 1455.18 352.825C1455.18 353.378 1455.63 353.826 1456.18 353.826Z"
    fill="white"/>
                        <path opacity="0.15"
    d="M1413.04 484.624C1413.87 484.624 1414.54 483.952 1414.54 483.123C1414.54 482.293 1413.87 481.621 1413.04 481.621C1412.21 481.621 1411.54 482.293 1411.54 483.123C1411.54 483.952 1412.21 484.624 1413.04 484.624Z"
    fill="white"/>
                        <path opacity="0.35"
    d="M1409.39 575.757C1410.22 575.757 1410.89 575.085 1410.89 574.256C1410.89 573.426 1410.22 572.754 1409.39 572.754C1408.56 572.754 1407.89 573.426 1407.89 574.256C1407.89 575.085 1408.56 575.757 1409.39 575.757Z"
    fill="white"/>
                        <path opacity="0.15"
    d="M1306.29 711.638C1306.57 711.638 1306.79 711.414 1306.79 711.137C1306.79 710.861 1306.57 710.637 1306.29 710.637C1306.01 710.637 1305.79 710.861 1305.79 711.137C1305.79 711.414 1306.01 711.638 1306.29 711.638Z"
    fill="white"/>
                        <path opacity="0.53"
    d="M1413.78 831.952C1414.6 831.952 1415.28 831.28 1415.28 830.451C1415.28 829.622 1414.6 828.949 1413.78 828.949C1412.95 828.949 1412.28 829.622 1412.28 830.451C1412.28 831.28 1412.95 831.952 1413.78 831.952Z"
    fill="white"/>
                        <path opacity="0.28"
    d="M1360.76 969.669C1361.04 969.669 1361.26 969.445 1361.26 969.169C1361.26 968.892 1361.04 968.668 1360.76 968.668C1360.49 968.668 1360.26 968.892 1360.26 969.169C1360.26 969.445 1360.49 969.669 1360.76 969.669Z"
    fill="white"/>
                        <path opacity="0.47"
    d="M1384.53 1020.81C1385.36 1020.81 1386.03 1020.14 1386.03 1019.31C1386.03 1018.48 1385.36 1017.81 1384.53 1017.81C1383.7 1017.81 1383.03 1018.48 1383.03 1019.31C1383.03 1020.14 1383.7 1020.81 1384.53 1020.81Z"
    fill="white"/>
                        <path opacity="0.21"
    d="M1533.69 79.8274C1534.52 79.8274 1535.19 79.1551 1535.19 78.3258C1535.19 77.4965 1534.52 76.8242 1533.69 76.8242C1532.86 76.8242 1532.19 77.4965 1532.19 78.3258C1532.19 79.1551 1532.86 79.8274 1533.69 79.8274Z"
    fill="white"/>
                        <path opacity="0.14"
    d="M1503.35 183.268C1503.9 183.268 1504.35 182.82 1504.35 182.267C1504.35 181.714 1503.9 181.266 1503.35 181.266C1502.8 181.266 1502.35 181.714 1502.35 182.267C1502.35 182.82 1502.8 183.268 1503.35 183.268Z"
    fill="white"/>
                        <path opacity="0.5"
    d="M1549.78 295.994C1550.33 295.994 1550.78 295.546 1550.78 294.993C1550.78 294.44 1550.33 293.992 1549.78 293.992C1549.23 293.992 1548.78 294.44 1548.78 294.993C1548.78 295.546 1549.23 295.994 1549.78 295.994Z"
    fill="white"/>
                        <path opacity="0.15"
    d="M1615.95 386.63C1616.23 386.63 1616.45 386.406 1616.45 386.129C1616.45 385.853 1616.23 385.629 1615.95 385.629C1615.68 385.629 1615.45 385.853 1615.45 386.129C1615.45 386.406 1615.68 386.63 1615.95 386.63Z"
    fill="white"/>
                        <path opacity="0.56"
    d="M1463.86 451.545C1464.42 451.545 1464.86 451.097 1464.86 450.544C1464.86 449.991 1464.42 449.543 1463.86 449.543C1463.31 449.543 1462.86 449.991 1462.86 450.544C1462.86 451.097 1463.31 451.545 1463.86 451.545Z"
    fill="white"/>
                        <path opacity="0.48"
    d="M1501.52 623.431C1501.8 623.431 1502.02 623.207 1502.02 622.93C1502.02 622.654 1501.8 622.43 1501.52 622.43C1501.24 622.43 1501.02 622.654 1501.02 622.93C1501.02 623.207 1501.24 623.431 1501.52 623.431Z"
    fill="white"/>
                        <path opacity="0.19"
    d="M1559.65 668.952C1560.2 668.952 1560.65 668.503 1560.65 667.95C1560.65 667.397 1560.2 666.949 1559.65 666.949C1559.1 666.949 1558.65 667.397 1558.65 667.95C1558.65 668.503 1559.1 668.952 1559.65 668.952Z"
    fill="white"/>
                        <path opacity="0.14"
    d="M1516.51 868.286C1516.78 868.286 1517.01 868.062 1517.01 867.786C1517.01 867.509 1516.78 867.285 1516.51 867.285C1516.23 867.285 1516.01 867.509 1516.01 867.786C1516.01 868.062 1516.23 868.286 1516.51 868.286Z"
    fill="white"/>
                        <path opacity="0.52"
    d="M1613.03 888.687C1613.86 888.687 1614.53 888.014 1614.53 887.185C1614.53 886.356 1613.86 885.684 1613.03 885.684C1612.2 885.684 1611.53 886.356 1611.53 887.185C1611.53 888.014 1612.2 888.687 1613.03 888.687Z"
    fill="white"/>
                        <path opacity="0.53"
    d="M1619.97 1036.42C1620.52 1036.42 1620.97 1035.97 1620.97 1035.42C1620.97 1034.86 1620.52 1034.41 1619.97 1034.41C1619.42 1034.41 1618.97 1034.86 1618.97 1035.42C1618.97 1035.97 1619.42 1036.42 1619.97 1036.42Z"
    fill="white"/>
                        <path opacity="0.48"
    d="M1800.21 65.2824C1800.49 65.2824 1800.71 65.0583 1800.71 64.7818C1800.71 64.5054 1800.49 64.2812 1800.21 64.2812C1799.94 64.2812 1799.71 64.5054 1799.71 64.7818C1799.71 65.0583 1799.94 65.2824 1800.21 65.2824Z"
    fill="white"/>
                        <path opacity="0.46"
    d="M1662.75 160.71C1663.58 160.71 1664.25 160.038 1664.25 159.209C1664.25 158.379 1663.58 157.707 1662.75 157.707C1661.92 157.707 1661.25 158.379 1661.25 159.209C1661.25 160.038 1661.92 160.71 1662.75 160.71Z"
    fill="white"/>
                        <path opacity="0.56"
    d="M1704.43 304.784C1704.98 304.784 1705.43 304.335 1705.43 303.782C1705.43 303.229 1704.98 302.781 1704.43 302.781C1703.88 302.781 1703.43 303.229 1703.43 303.782C1703.43 304.335 1703.88 304.784 1704.43 304.784Z"
    fill="white"/>
                        <path opacity="0.41"
    d="M1777.91 406.663C1778.74 406.663 1779.41 405.991 1779.41 405.162C1779.41 404.332 1778.74 403.66 1777.91 403.66C1777.09 403.66 1776.41 404.332 1776.41 405.162C1776.41 405.991 1777.09 406.663 1777.91 406.663Z"
    fill="white"/>
                        <path opacity="0.61"
    d="M1784.13 443.862C1784.68 443.862 1785.13 443.413 1785.13 442.86C1785.13 442.308 1784.68 441.859 1784.13 441.859C1783.57 441.859 1783.13 442.308 1783.13 442.86C1783.13 443.413 1783.57 443.862 1784.13 443.862Z"
    fill="white"/>
                        <path opacity="0.33"
    d="M1747.93 583.074C1748.76 583.074 1749.43 582.401 1749.43 581.572C1749.43 580.743 1748.76 580.07 1747.93 580.07C1747.11 580.07 1746.43 580.743 1746.43 581.572C1746.43 582.401 1747.11 583.074 1747.93 583.074Z"
    fill="white"/>
                        <path opacity="0.34"
    d="M1729.65 660.667C1730.48 660.667 1731.15 659.995 1731.15 659.166C1731.15 658.336 1730.48 657.664 1729.65 657.664C1728.83 657.664 1728.15 658.336 1728.15 659.166C1728.15 659.995 1728.83 660.667 1729.65 660.667Z"
    fill="white"/>
                        <path opacity="0.09"
    d="M1687.97 840.837C1688.25 840.837 1688.47 840.613 1688.47 840.337C1688.47 840.06 1688.25 839.836 1687.97 839.836C1687.7 839.836 1687.47 840.06 1687.47 840.337C1687.47 840.613 1687.7 840.837 1687.97 840.837Z"
    fill="white"/>
                        <path opacity="0.22"
    d="M1791.8 883.428C1792.36 883.428 1792.8 882.98 1792.8 882.427C1792.8 881.874 1792.36 881.426 1791.8 881.426C1791.25 881.426 1790.8 881.874 1790.8 882.427C1790.8 882.98 1791.25 883.428 1791.8 883.428Z"
    fill="white"/>
                        <path opacity="0.55"
    d="M1700.41 1092.28C1700.68 1092.28 1700.91 1092.05 1700.91 1091.78C1700.91 1091.5 1700.68 1091.28 1700.41 1091.28C1700.13 1091.28 1699.91 1091.5 1699.91 1091.78C1699.91 1092.05 1700.13 1092.28 1700.41 1092.28Z"
    fill="white"/>
                        <path opacity="0.1"
    d="M28.5156 66.38C28.7918 66.38 29.0156 66.1559 29.0156 65.8795C29.0156 65.603 28.7918 65.3789 28.5156 65.3789C28.2395 65.3789 28.0156 65.603 28.0156 65.8795C28.0156 66.1559 28.2395 66.38 28.5156 66.38Z"
    fill="white"/>
                        <path opacity="0.24"
    d="M53.7422 204.728C54.0183 204.728 54.2422 204.504 54.2422 204.227C54.2422 203.951 54.0183 203.727 53.7422 203.727C53.466 203.727 53.2422 203.951 53.2422 204.227C53.2422 204.504 53.466 204.728 53.7422 204.728Z"
    fill="white"/>
                        <path opacity="0.27"
    d="M73.8516 234.877C74.4038 234.877 74.8516 234.429 74.8516 233.876C74.8516 233.323 74.4038 232.875 73.8516 232.875C73.2993 232.875 72.8516 233.323 72.8516 233.876C72.8516 234.429 73.2993 234.877 73.8516 234.877Z"
    fill="white"/>
                        <path opacity="0.45"
    d="M6.21484 386.263C6.49099 386.263 6.71484 386.039 6.71484 385.762C6.71484 385.486 6.49099 385.262 6.21484 385.262C5.9387 385.262 5.71484 385.486 5.71484 385.762C5.71484 386.039 5.9387 386.263 6.21484 386.263Z"
    fill="white"/>
                        <path opacity="0.08"
    d="M69.8301 510.838C70.3824 510.838 70.8301 510.39 70.8301 509.837C70.8301 509.284 70.3824 508.836 69.8301 508.836C69.2778 508.836 68.8301 509.284 68.8301 509.837C68.8301 510.39 69.2778 510.838 69.8301 510.838Z"
    fill="white"/>
                        <path opacity="0.21"
    d="M63.9805 650.785C64.8089 650.785 65.4805 650.112 65.4805 649.283C65.4805 648.454 64.8089 647.781 63.9805 647.781C63.152 647.781 62.4805 648.454 62.4805 649.283C62.4805 650.112 63.152 650.785 63.9805 650.785Z"
    fill="white"/>
                        <path opacity="0.52"
    d="M77.873 661.497C78.1492 661.497 78.373 661.273 78.373 660.997C78.373 660.72 78.1492 660.496 77.873 660.496C77.5969 660.496 77.373 660.72 77.373 660.997C77.373 661.273 77.5969 661.497 77.873 661.497Z"
    fill="white"/>
                        <path opacity="0.49"
    d="M10.9668 819.878C11.7952 819.878 12.4668 819.206 12.4668 818.377C12.4668 817.547 11.7952 816.875 10.9668 816.875C10.1384 816.875 9.4668 817.547 9.4668 818.377C9.4668 819.206 10.1384 819.878 10.9668 819.878Z"
    fill="white"/>
                        <path opacity="0.5"
    d="M59.959 915.402C60.7874 915.402 61.459 914.729 61.459 913.9C61.459 913.071 60.7874 912.398 59.959 912.398C59.1306 912.398 58.459 913.071 58.459 913.9C58.459 914.729 59.1306 915.402 59.959 915.402Z"
    fill="white"/>
                        <path opacity="0.38"
    d="M103.83 993.726C104.659 993.726 105.33 993.054 105.33 992.224C105.33 991.395 104.659 990.723 103.83 990.723C103.002 990.723 102.33 991.395 102.33 992.224C102.33 993.054 103.002 993.726 103.83 993.726Z"
    fill="white"/>
                        <path opacity="0.61"
    d="M217.531 91.0374C218.084 91.0374 218.531 90.5892 218.531 90.0363C218.531 89.4834 218.084 89.0352 217.531 89.0352C216.979 89.0352 216.531 89.4834 216.531 90.0363C216.531 90.5892 216.979 91.0374 217.531 91.0374Z"
    fill="white"/>
                        <path opacity="0.36"
    d="M318.438 218.175C319.266 218.175 319.938 217.503 319.938 216.673C319.938 215.844 319.266 215.172 318.438 215.172C317.609 215.172 316.938 215.844 316.938 216.673C316.938 217.503 317.609 218.175 318.438 218.175Z"
    fill="white"/>
                        <path opacity="0.32"
    d="M309.662 291.837C309.938 291.837 310.162 291.613 310.162 291.336C310.162 291.06 309.938 290.836 309.662 290.836C309.386 290.836 309.162 291.06 309.162 291.336C309.162 291.613 309.386 291.837 309.662 291.837Z"
    fill="white"/>
                        <path opacity="0.05"
    d="M361.578 393.95C361.854 393.95 362.078 393.726 362.078 393.45C362.078 393.173 361.854 392.949 361.578 392.949C361.302 392.949 361.078 393.173 361.078 393.45C361.078 393.726 361.302 393.95 361.578 393.95Z"
    fill="white"/>
                        <path opacity="0.05"
    d="M251.533 467.283C252.085 467.283 252.533 466.835 252.533 466.282C252.533 465.729 252.085 465.281 251.533 465.281C250.981 465.281 250.533 465.729 250.533 466.282C250.533 466.835 250.981 467.283 251.533 467.283Z"
    fill="white"/>
                        <path opacity="0.07"
    d="M347.686 639.67C348.238 639.67 348.686 639.222 348.686 638.669C348.686 638.116 348.238 637.668 347.686 637.668C347.133 637.668 346.686 638.116 346.686 638.669C346.686 639.222 347.133 639.67 347.686 639.67Z"
    fill="white"/>
                        <path opacity="0.42"
    d="M312.953 746.312C313.782 746.312 314.453 745.64 314.453 744.81C314.453 743.981 313.782 743.309 312.953 743.309C312.125 743.309 311.453 743.981 311.453 744.81C311.453 745.64 312.125 746.312 312.953 746.312Z"
    fill="white"/>
                        <path opacity="0.04"
    d="M248.975 843.667C249.803 843.667 250.475 842.995 250.475 842.166C250.475 841.336 249.803 840.664 248.975 840.664C248.146 840.664 247.475 841.336 247.475 842.166C247.475 842.995 248.146 843.667 248.975 843.667Z"
    fill="white"/>
                        <path opacity="0.05"
    d="M191.574 978.087C191.85 978.087 192.074 977.863 192.074 977.586C192.074 977.31 191.85 977.086 191.574 977.086C191.298 977.086 191.074 977.31 191.074 977.586C191.074 977.863 191.298 978.087 191.574 978.087Z"
    fill="white"/>
                        <path opacity="0.64"
    d="M265.061 1091.54C265.337 1091.54 265.561 1091.32 265.561 1091.04C265.561 1090.77 265.337 1090.54 265.061 1090.54C264.784 1090.54 264.561 1090.77 264.561 1091.04C264.561 1091.32 264.784 1091.54 265.061 1091.54Z"
    fill="white"/>
                        <path opacity="0.14"
    d="M394.117 85.048C394.393 85.048 394.617 84.8239 394.617 84.5474C394.617 84.271 394.393 84.0469 394.117 84.0469C393.841 84.0469 393.617 84.271 393.617 84.5474C393.617 84.8239 393.841 85.048 394.117 85.048Z"
    fill="white"/>
                        <path opacity="0.24"
    d="M526.463 163.001C526.739 163.001 526.963 162.777 526.963 162.501C526.963 162.224 526.739 162 526.463 162C526.187 162 525.963 162.224 525.963 162.501C525.963 162.777 526.187 163.001 526.463 163.001Z"
    fill="white"/>
                        <path opacity="0.48"
    d="M516.594 247.319C517.146 247.319 517.594 246.87 517.594 246.317C517.594 245.765 517.146 245.316 516.594 245.316C516.041 245.316 515.594 245.765 515.594 246.317C515.594 246.87 516.041 247.319 516.594 247.319Z"
    fill="white"/>
                        <path opacity="0.45"
    d="M520.248 417.143C520.8 417.143 521.248 416.695 521.248 416.142C521.248 415.589 520.8 415.141 520.248 415.141C519.696 415.141 519.248 415.589 519.248 416.142C519.248 416.695 519.696 417.143 520.248 417.143Z"
    fill="white"/>
                        <path opacity="0.49"
    d="M502.699 535.362C503.252 535.362 503.699 534.913 503.699 534.36C503.699 533.808 503.252 533.359 502.699 533.359C502.147 533.359 501.699 533.808 501.699 534.36C501.699 534.913 502.147 535.362 502.699 535.362Z"
    fill="white"/>
                        <path opacity="0.33"
    d="M429.215 575.486C429.491 575.486 429.715 575.261 429.715 574.985C429.715 574.708 429.491 574.484 429.215 574.484C428.939 574.484 428.715 574.708 428.715 574.985C428.715 575.261 428.939 575.486 429.215 575.486Z"
    fill="white"/>
                        <path opacity="0.55"
    d="M501.236 663.593C502.065 663.593 502.736 662.921 502.736 662.092C502.736 661.262 502.065 660.59 501.236 660.59C500.408 660.59 499.736 661.262 499.736 662.092C499.736 662.921 500.408 663.593 501.236 663.593Z"
    fill="white"/>
                        <path opacity="0.09"
    d="M538.895 825.367C539.723 825.367 540.395 824.694 540.395 823.865C540.395 823.036 539.723 822.363 538.895 822.363C538.066 822.363 537.395 823.036 537.395 823.865C537.395 824.694 538.066 825.367 538.895 825.367Z"
    fill="white"/>
                        <path opacity="0.38"
    d="M528.658 975.658C529.21 975.658 529.658 975.21 529.658 974.657C529.658 974.104 529.21 973.656 528.658 973.656C528.106 973.656 527.658 974.104 527.658 974.657C527.658 975.21 528.106 975.658 528.658 975.658Z"
    fill="white"/>
                        <path opacity="0.39"
    d="M375.471 1049.45C375.747 1049.45 375.971 1049.23 375.971 1048.95C375.971 1048.68 375.747 1048.45 375.471 1048.45C375.195 1048.45 374.971 1048.68 374.971 1048.95C374.971 1049.23 375.195 1049.45 375.471 1049.45Z"
    fill="white"/>
                        <path opacity="0.45"
    d="M589.348 40.5295C589.9 40.5295 590.348 40.0813 590.348 39.5284C590.348 38.9755 589.9 38.5273 589.348 38.5273C588.795 38.5273 588.348 38.9755 588.348 39.5284C588.348 40.0813 588.795 40.5295 589.348 40.5295Z"
    fill="white"/>
                        <path opacity="0.44"
    d="M714.383 154.954C714.659 154.954 714.883 154.73 714.883 154.454C714.883 154.177 714.659 153.953 714.383 153.953C714.107 153.953 713.883 154.177 713.883 154.454C713.883 154.73 714.107 154.954 714.383 154.954Z"
    fill="white"/>
                        <path opacity="0.19"
    d="M724.254 310.639C724.806 310.639 725.254 310.191 725.254 309.638C725.254 309.085 724.806 308.637 724.254 308.637C723.702 308.637 723.254 309.085 723.254 309.638C723.254 310.191 723.702 310.639 724.254 310.639Z"
    fill="white"/>
                        <path opacity="0.37"
    d="M719.5 384.339C720.328 384.339 721 383.667 721 382.838C721 382.008 720.328 381.336 719.5 381.336C718.672 381.336 718 382.008 718 382.838C718 383.667 718.672 384.339 719.5 384.339Z"
    fill="white"/>
                        <path opacity="0.23"
    d="M628.467 509.874C629.295 509.874 629.967 509.202 629.967 508.373C629.967 507.543 629.295 506.871 628.467 506.871C627.638 506.871 626.967 507.543 626.967 508.373C626.967 509.202 627.638 509.874 628.467 509.874Z"
    fill="white"/>
                        <path opacity="0.46"
    d="M646.746 564.276C647.298 564.276 647.746 563.827 647.746 563.274C647.746 562.722 647.298 562.273 646.746 562.273C646.194 562.273 645.746 562.722 645.746 563.274C645.746 563.827 646.194 564.276 646.746 564.276Z"
    fill="white"/>
                        <path opacity="0.27"
    d="M698.295 677.87C699.123 677.87 699.795 677.198 699.795 676.369C699.795 675.539 699.123 674.867 698.295 674.867C697.466 674.867 696.795 675.539 696.795 676.369C696.795 677.198 697.466 677.87 698.295 677.87Z"
    fill="white"/>
                        <path opacity="0.21"
    d="M699.758 787.17C700.31 787.17 700.758 786.722 700.758 786.169C700.758 785.616 700.31 785.168 699.758 785.168C699.206 785.168 698.758 785.616 698.758 786.169C698.758 786.722 699.206 787.17 699.758 787.17Z"
    fill="white"/>
                        <path opacity="0.08"
    d="M601.047 938.46C601.875 938.46 602.547 937.788 602.547 936.959C602.547 936.129 601.875 935.457 601.047 935.457C600.218 935.457 599.547 936.129 599.547 936.959C599.547 937.788 600.218 938.46 601.047 938.46Z"
    fill="white"/>
                        <path opacity="0.04"
    d="M617.133 1068.99C617.685 1068.99 618.133 1068.54 618.133 1067.99C618.133 1067.43 617.685 1066.98 617.133 1066.98C616.581 1066.98 616.133 1067.43 616.133 1067.99C616.133 1068.54 616.581 1068.99 617.133 1068.99Z"
    fill="white"/>
                        <path opacity="0.55"
    d="M867.934 30.5128C868.21 30.5128 868.434 30.2887 868.434 30.0123C868.434 29.7358 868.21 29.5117 867.934 29.5117C867.657 29.5117 867.434 29.7358 867.434 30.0123C867.434 30.2887 867.657 30.5128 867.934 30.5128Z"
    fill="white"/>
                        <path opacity="0.09"
    d="M800.664 201.067C800.94 201.067 801.164 200.843 801.164 200.567C801.164 200.291 800.94 200.066 800.664 200.066C800.388 200.066 800.164 200.291 800.164 200.567C800.164 200.843 800.388 201.067 800.664 201.067Z"
    fill="white"/>
                        <path opacity="0.5"
    d="M788.6 230.253C789.428 230.253 790.1 229.581 790.1 228.752C790.1 227.922 789.428 227.25 788.6 227.25C787.771 227.25 787.1 227.922 787.1 228.752C787.1 229.581 787.771 230.253 788.6 230.253Z"
    fill="white"/>
                        <path opacity="0.36"
    d="M864.645 394.819C865.197 394.819 865.645 394.37 865.645 393.818C865.645 393.265 865.197 392.816 864.645 392.816C864.092 392.816 863.645 393.265 863.645 393.818C863.645 394.37 864.092 394.819 864.645 394.819Z"
    fill="white"/>
                        <path opacity="0.54"
    d="M888.773 519.027C889.602 519.027 890.273 518.354 890.273 517.525C890.273 516.696 889.602 516.023 888.773 516.023C887.945 516.023 887.273 516.696 887.273 517.525C887.273 518.354 887.945 519.027 888.773 519.027Z"
    fill="white"/>
                        <path opacity="0.46"
    d="M776.168 576.851C776.996 576.851 777.668 576.179 777.668 575.349C777.668 574.52 776.996 573.848 776.168 573.848C775.34 573.848 774.668 574.52 774.668 575.349C774.668 576.179 775.34 576.851 776.168 576.851Z"
    fill="white"/>
                        <path opacity="0.09"
    d="M783.846 687.019C784.674 687.019 785.346 686.347 785.346 685.517C785.346 684.688 784.674 684.016 783.846 684.016C783.017 684.016 782.346 684.688 782.346 685.517C782.346 686.347 783.017 687.019 783.846 687.019Z"
    fill="white"/>
                        <path opacity="0.32"
    d="M907.053 801.44C907.605 801.44 908.053 800.991 908.053 800.439C908.053 799.886 907.605 799.438 907.053 799.438C906.5 799.438 906.053 799.886 906.053 800.439C906.053 800.991 906.5 801.44 907.053 801.44Z"
    fill="white"/>
                        <path opacity="0.2"
    d="M767.76 942.488C768.588 942.488 769.26 941.815 769.26 940.986C769.26 940.157 768.588 939.484 767.76 939.484C766.931 939.484 766.26 940.157 766.26 940.986C766.26 941.815 766.931 942.488 767.76 942.488Z"
    fill="white"/>
                        <path opacity="0.16"
    d="M760.082 1038.75C760.91 1038.75 761.582 1038.07 761.582 1037.24C761.582 1036.41 760.91 1035.74 760.082 1035.74C759.254 1035.74 758.582 1036.41 758.582 1037.24C758.582 1038.07 759.254 1038.75 760.082 1038.75Z"
    fill="white"/>
                        <path opacity="0.59"
    d="M931.184 33.9397C931.736 33.9397 932.184 33.4915 932.184 32.9386C932.184 32.3857 931.736 31.9375 931.184 31.9375C930.631 31.9375 930.184 32.3857 930.184 32.9386C930.184 33.4915 930.631 33.9397 931.184 33.9397Z"
    fill="white"/>
                        <path opacity="0.36"
    d="M935.936 123.975C936.488 123.975 936.936 123.527 936.936 122.974C936.936 122.421 936.488 121.973 935.936 121.973C935.383 121.973 934.936 122.421 934.936 122.974C934.936 123.527 935.383 123.975 935.936 123.975Z"
    fill="white"/>
                        <path opacity="0.18"
    d="M1068.65 241.597C1069.48 241.597 1070.15 240.925 1070.15 240.095C1070.15 239.266 1069.48 238.594 1068.65 238.594C1067.82 238.594 1067.15 239.266 1067.15 240.095C1067.15 240.925 1067.82 241.597 1068.65 241.597Z"
    fill="white"/>
                        <path opacity="0.18"
    d="M1045.25 345.041C1045.8 345.041 1046.25 344.593 1046.25 344.04C1046.25 343.487 1045.8 343.039 1045.25 343.039C1044.7 343.039 1044.25 343.487 1044.25 344.04C1044.25 344.593 1044.7 345.041 1045.25 345.041Z"
    fill="white"/>
                        <path opacity="0.44"
    d="M1060.97 481.423C1061.25 481.423 1061.47 481.199 1061.47 480.922C1061.47 480.646 1061.25 480.422 1060.97 480.422C1060.69 480.422 1060.47 480.646 1060.47 480.922C1060.47 481.199 1060.69 481.423 1060.97 481.423Z"
    fill="white"/>
                        <path opacity="0.14"
    d="M1082.91 601.107C1083.18 601.107 1083.41 600.882 1083.41 600.606C1083.41 600.33 1083.18 600.105 1082.91 600.105C1082.63 600.105 1082.41 600.33 1082.41 600.606C1082.41 600.882 1082.63 601.107 1082.91 601.107Z"
    fill="white"/>
                        <path opacity="0.57"
    d="M1026.97 728.378C1027.8 728.378 1028.47 727.706 1028.47 726.877C1028.47 726.047 1027.8 725.375 1026.97 725.375C1026.14 725.375 1025.47 726.047 1025.47 726.877C1025.47 727.706 1026.14 728.378 1026.97 728.378Z"
    fill="white"/>
                        <path opacity="0.33"
    d="M990.41 873.679C991.239 873.679 991.91 873.007 991.91 872.177C991.91 871.348 991.239 870.676 990.41 870.676C989.582 870.676 988.91 871.348 988.91 872.177C988.91 873.007 989.582 873.679 990.41 873.679Z"
    fill="white"/>
                        <path opacity="0.35"
    d="M1096.44 946.377C1096.99 946.377 1097.44 945.929 1097.44 945.376C1097.44 944.823 1096.99 944.375 1096.44 944.375C1095.88 944.375 1095.44 944.823 1095.44 945.376C1095.44 945.929 1095.88 946.377 1096.44 946.377Z"
    fill="white"/>
                        <path opacity="0.52"
    d="M964.818 1011.53C965.371 1011.53 965.818 1011.08 965.818 1010.52C965.818 1009.97 965.371 1009.52 964.818 1009.52C964.266 1009.52 963.818 1009.97 963.818 1010.52C963.818 1011.08 964.266 1011.53 964.818 1011.53Z"
    fill="white"/>
                        <path opacity="0.36"
    d="M1214.89 100.786C1215.16 100.786 1215.39 100.562 1215.39 100.286C1215.39 100.009 1215.16 99.7852 1214.89 99.7852C1214.61 99.7852 1214.39 100.009 1214.39 100.286C1214.39 100.562 1214.61 100.786 1214.89 100.786Z"
    fill="white"/>
                        <path opacity="0.42"
    d="M1153.47 124.113C1154.3 124.113 1154.97 123.44 1154.97 122.611C1154.97 121.782 1154.3 121.109 1153.47 121.109C1152.64 121.109 1151.97 121.782 1151.97 122.611C1151.97 123.44 1152.64 124.113 1153.47 124.113Z"
    fill="white"/>
                        <path opacity="0.33"
    d="M1157.12 319.42C1157.68 319.42 1158.12 318.972 1158.12 318.419C1158.12 317.866 1157.68 317.418 1157.12 317.418C1156.57 317.418 1156.12 317.866 1156.12 318.419C1156.12 318.972 1156.57 319.42 1157.12 319.42Z"
    fill="white"/>
                        <path opacity="0.14"
    d="M1234.63 383.605C1235.46 383.605 1236.13 382.933 1236.13 382.103C1236.13 381.274 1235.46 380.602 1234.63 380.602C1233.8 380.602 1233.13 381.274 1233.13 382.103C1233.13 382.933 1233.8 383.605 1234.63 383.605Z"
    fill="white"/>
                        <path opacity="0.18"
    d="M1135.92 447.522C1136.47 447.522 1136.92 447.073 1136.92 446.521C1136.92 445.968 1136.47 445.52 1135.92 445.52C1135.37 445.52 1134.92 445.968 1134.92 446.521C1134.92 447.073 1135.37 447.522 1135.92 447.522Z"
    fill="white"/>
                        <path opacity="0.12"
    d="M1135.19 613.089C1136.02 613.089 1136.69 612.417 1136.69 611.588C1136.69 610.758 1136.02 610.086 1135.19 610.086C1134.36 610.086 1133.69 610.758 1133.69 611.588C1133.69 612.417 1134.36 613.089 1135.19 613.089Z"
    fill="white"/>
                        <path opacity="0.46"
    d="M1183.81 768.273C1184.64 768.273 1185.31 767.601 1185.31 766.771C1185.31 765.942 1184.64 765.27 1183.81 765.27C1182.98 765.27 1182.31 765.942 1182.31 766.771C1182.31 767.601 1182.98 768.273 1183.81 768.273Z"
    fill="white"/>
                        <path opacity="0.52"
    d="M1271.92 867.322C1272.47 867.322 1272.92 866.874 1272.92 866.321C1272.92 865.768 1272.47 865.32 1271.92 865.32C1271.37 865.32 1270.92 865.768 1270.92 866.321C1270.92 866.874 1271.37 867.322 1271.92 867.322Z"
    fill="white"/>
                        <path opacity="0.35"
    d="M1214.16 900.627C1214.71 900.627 1215.16 900.179 1215.16 899.626C1215.16 899.073 1214.71 898.625 1214.16 898.625C1213.61 898.625 1213.16 899.073 1213.16 899.626C1213.16 900.179 1213.61 900.627 1214.16 900.627Z"
    fill="white"/>
                        <path opacity="0.46"
    d="M1268.63 1011.16C1269.19 1011.16 1269.63 1010.71 1269.63 1010.16C1269.63 1009.61 1269.19 1009.16 1268.63 1009.16C1268.08 1009.16 1267.63 1009.61 1267.63 1010.16C1267.63 1010.71 1268.08 1011.16 1268.63 1011.16Z"
    fill="white"/>
                        <path opacity="0.42"
    d="M1410.85 89.8057C1411.13 89.8057 1411.35 89.5817 1411.35 89.3052C1411.35 89.0288 1411.13 88.8047 1410.85 88.8047C1410.57 88.8047 1410.35 89.0288 1410.35 89.3052C1410.35 89.5817 1410.57 89.8057 1410.85 89.8057Z"
    fill="white"/>
                        <path opacity="0.11"
    d="M1312.87 120.319C1313.42 120.319 1313.87 119.87 1313.87 119.317C1313.87 118.765 1313.42 118.316 1312.87 118.316C1312.32 118.316 1311.87 118.765 1311.87 119.317C1311.87 119.87 1312.32 120.319 1312.87 120.319Z"
    fill="white"/>
                        <path opacity="0.23"
    d="M1334.8 277.831C1335.63 277.831 1336.3 277.159 1336.3 276.33C1336.3 275.5 1335.63 274.828 1334.8 274.828C1333.98 274.828 1333.3 275.5 1333.3 276.33C1333.3 277.159 1333.98 277.831 1334.8 277.831Z"
    fill="white"/>
                        <path opacity="0.41"
    d="M1443.75 342.613C1444.58 342.613 1445.25 341.94 1445.25 341.111C1445.25 340.282 1444.58 339.609 1443.75 339.609C1442.93 339.609 1442.25 340.282 1442.25 341.111C1442.25 341.94 1442.93 342.613 1443.75 342.613Z"
    fill="white"/>
                        <path opacity="0.17"
    d="M1300.44 442.898C1301.27 442.898 1301.94 442.226 1301.94 441.396C1301.94 440.567 1301.27 439.895 1300.44 439.895C1299.61 439.895 1298.94 440.567 1298.94 441.396C1298.94 442.226 1299.61 442.898 1300.44 442.898Z"
    fill="white"/>
                        <path opacity="0.12"
    d="M1285.08 637.842C1285.64 637.842 1286.08 637.394 1286.08 636.841C1286.08 636.288 1285.64 635.84 1285.08 635.84C1284.53 635.84 1284.08 636.288 1284.08 636.841C1284.08 637.394 1284.53 637.842 1285.08 637.842Z"
    fill="white"/>
                        <path opacity="0.26"
    d="M1333.71 746.178C1334.26 746.178 1334.71 745.73 1334.71 745.177C1334.71 744.624 1334.26 744.176 1333.71 744.176C1333.16 744.176 1332.71 744.624 1332.71 745.177C1332.71 745.73 1333.16 746.178 1333.71 746.178Z"
    fill="white"/>
                        <path opacity="0.31"
    d="M1414.14 840.374C1414.97 840.374 1415.64 839.702 1415.64 838.873C1415.64 838.043 1414.97 837.371 1414.14 837.371C1413.31 837.371 1412.64 838.043 1412.64 838.873C1412.64 839.702 1413.31 840.374 1414.14 840.374Z"
    fill="white"/>
                        <path opacity="0.31"
    d="M1455.82 935.764C1456.37 935.764 1456.82 935.316 1456.82 934.763C1456.82 934.21 1456.37 933.762 1455.82 933.762C1455.27 933.762 1454.82 934.21 1454.82 934.763C1454.82 935.316 1455.27 935.764 1455.82 935.764Z"
    fill="white"/>
                        <path opacity="0.16"
    d="M1316.53 1040.67C1316.8 1040.67 1317.03 1040.45 1317.03 1040.17C1317.03 1039.9 1316.8 1039.67 1316.53 1039.67C1316.25 1039.67 1316.03 1039.9 1316.03 1040.17C1316.03 1040.45 1316.25 1040.67 1316.53 1040.67Z"
    fill="white"/>
                        <path opacity="0.58"
    d="M1514.32 43.9565C1515.14 43.9565 1515.82 43.2842 1515.82 42.4548C1515.82 41.6255 1515.14 40.9531 1514.32 40.9531C1513.49 40.9531 1512.82 41.6255 1512.82 42.4548C1512.82 43.2842 1513.49 43.9565 1514.32 43.9565Z"
    fill="white"/>
                        <path opacity="0.47"
    d="M1592.19 210.855C1593.02 210.855 1593.69 210.183 1593.69 209.353C1593.69 208.524 1593.02 207.852 1592.19 207.852C1591.36 207.852 1590.69 208.524 1590.69 209.353C1590.69 210.183 1591.36 210.855 1592.19 210.855Z"
    fill="white"/>
                        <path opacity="0.3"
    d="M1640.45 272.071C1640.72 272.071 1640.95 271.847 1640.95 271.571C1640.95 271.294 1640.72 271.07 1640.45 271.07C1640.17 271.07 1639.95 271.294 1639.95 271.571C1639.95 271.847 1640.17 272.071 1640.45 272.071Z"
    fill="white"/>
                        <path opacity="0.32"
    d="M1581.59 383.337C1581.86 383.337 1582.09 383.113 1582.09 382.836C1582.09 382.56 1581.86 382.336 1581.59 382.336C1581.31 382.336 1581.09 382.56 1581.09 382.836C1581.09 383.113 1581.31 383.337 1581.59 383.337Z"
    fill="white"/>
                        <path opacity="0.18"
    d="M1481.78 455.442C1482.05 455.442 1482.28 455.218 1482.28 454.942C1482.28 454.666 1482.05 454.441 1481.78 454.441C1481.5 454.441 1481.28 454.666 1481.28 454.942C1481.28 455.218 1481.5 455.442 1481.78 455.442Z"
    fill="white"/>
                        <path opacity="0.58"
    d="M1476.66 616.749C1477.49 616.749 1478.16 616.077 1478.16 615.248C1478.16 614.418 1477.49 613.746 1476.66 613.746C1475.83 613.746 1475.16 614.418 1475.16 615.248C1475.16 616.077 1475.83 616.749 1476.66 616.749Z"
    fill="white"/>
                        <path opacity="0.25"
    d="M1602.43 691.046C1603.25 691.046 1603.93 690.374 1603.93 689.545C1603.93 688.715 1603.25 688.043 1602.43 688.043C1601.6 688.043 1600.93 688.715 1600.93 689.545C1600.93 690.374 1601.6 691.046 1602.43 691.046Z"
    fill="white"/>
                        <path opacity="0.63"
    d="M1560.02 837.445C1560.84 837.445 1561.52 836.772 1561.52 835.943C1561.52 835.114 1560.84 834.441 1560.02 834.441C1559.19 834.441 1558.52 835.114 1558.52 835.943C1558.52 836.772 1559.19 837.445 1560.02 837.445Z"
    fill="white"/>
                        <path opacity="0.64"
    d="M1633.5 892.442C1633.78 892.442 1634 892.218 1634 891.942C1634 891.666 1633.78 891.441 1633.5 891.441C1633.22 891.441 1633 891.666 1633 891.942C1633 892.218 1633.22 892.442 1633.5 892.442Z"
    fill="white"/>
                        <path opacity="0.61"
    d="M1465.32 1083.99C1465.88 1083.99 1466.32 1083.55 1466.32 1082.99C1466.32 1082.44 1465.88 1081.99 1465.32 1081.99C1464.77 1081.99 1464.32 1082.44 1464.32 1082.99C1464.32 1083.55 1464.77 1083.99 1465.32 1083.99Z"
    fill="white"/>
                        <path opacity="0.36"
    d="M1703.33 76.3968C1703.88 76.3968 1704.33 75.9486 1704.33 75.3957C1704.33 74.8428 1703.88 74.3945 1703.33 74.3945C1702.78 74.3945 1702.33 74.8428 1702.33 75.3957C1702.33 75.9486 1702.78 76.3968 1703.33 76.3968Z"
    fill="white"/>
                        <path opacity="0.04"
    d="M1821.79 183.864C1822.06 183.864 1822.29 183.64 1822.29 183.364C1822.29 183.087 1822.06 182.863 1821.79 182.863C1821.51 182.863 1821.29 183.087 1821.29 183.364C1821.29 183.64 1821.51 183.864 1821.79 183.864Z"
    fill="white"/>
                        <path opacity="0.28"
    d="M1645.57 267.218C1646.39 267.218 1647.07 266.546 1647.07 265.716C1647.07 264.887 1646.39 264.215 1645.57 264.215C1644.74 264.215 1644.07 264.887 1644.07 265.716C1644.07 266.546 1644.74 267.218 1645.57 267.218Z"
    fill="white"/>
                        <path opacity="0.41"
    d="M1750.13 334.424C1750.68 334.424 1751.13 333.976 1751.13 333.423C1751.13 332.87 1750.68 332.422 1750.13 332.422C1749.57 332.422 1749.13 332.87 1749.13 333.423C1749.13 333.976 1749.57 334.424 1750.13 334.424Z"
    fill="white"/>
                        <path opacity="0.23"
    d="M1701.5 519.623C1702.05 519.623 1702.5 519.175 1702.5 518.622C1702.5 518.069 1702.05 517.621 1701.5 517.621C1700.95 517.621 1700.5 518.069 1700.5 518.622C1700.5 519.175 1700.95 519.623 1701.5 519.623Z"
    fill="white"/>
                        <path opacity="0.26"
    d="M1688.34 597.446C1688.62 597.446 1688.84 597.222 1688.84 596.946C1688.84 596.669 1688.62 596.445 1688.34 596.445C1688.06 596.445 1687.84 596.669 1687.84 596.946C1687.84 597.222 1688.06 597.446 1688.34 597.446Z"
    fill="white"/>
                        <path opacity="0.18"
    d="M1800.21 726.779C1800.77 726.779 1801.21 726.331 1801.21 725.778C1801.21 725.226 1800.77 724.777 1800.21 724.777C1799.66 724.777 1799.21 725.226 1799.21 725.778C1799.21 726.331 1799.66 726.779 1800.21 726.779Z"
    fill="white"/>
                        <path opacity="0.36"
    d="M1668.96 837.907C1669.24 837.907 1669.46 837.683 1669.46 837.407C1669.46 837.13 1669.24 836.906 1668.96 836.906C1668.69 836.906 1668.46 837.13 1668.46 837.407C1668.46 837.683 1668.69 837.907 1668.96 837.907Z"
    fill="white"/>
                        <path opacity="0.64"
    d="M1653.61 921.357C1653.88 921.357 1654.11 921.132 1654.11 920.856C1654.11 920.58 1653.88 920.355 1653.61 920.355C1653.33 920.355 1653.11 920.58 1653.11 920.856C1653.11 921.132 1653.33 921.357 1653.61 921.357Z"
    fill="white"/>
                        <path opacity="0.5"
    d="M1680.66 1060.57C1681.21 1060.57 1681.66 1060.12 1681.66 1059.57C1681.66 1059.02 1681.21 1058.57 1680.66 1058.57C1680.11 1058.57 1679.66 1059.02 1679.66 1059.57C1679.66 1060.12 1680.11 1060.57 1680.66 1060.57Z"
    fill="white"/>
                        <path opacity="0.47"
    d="M152.09 107.873C152.642 107.873 153.09 107.425 153.09 106.872C153.09 106.319 152.642 105.871 152.09 105.871C151.538 105.871 151.09 106.319 151.09 106.872C151.09 107.425 151.538 107.873 152.09 107.873Z"
    fill="white"/>
                        <path opacity="0.62"
    d="M169.273 220.099C169.55 220.099 169.773 219.875 169.773 219.598C169.773 219.322 169.55 219.098 169.273 219.098C168.997 219.098 168.773 219.322 168.773 219.598C168.773 219.875 168.997 220.099 169.273 220.099Z"
    fill="white"/>
                        <path opacity="0.37"
    d="M2.19336 325.643C2.74564 325.643 3.19336 325.195 3.19336 324.642C3.19336 324.089 2.74564 323.641 2.19336 323.641C1.64107 323.641 1.19336 324.089 1.19336 324.642C1.19336 325.195 1.64107 325.643 2.19336 325.643Z"
    fill="white"/>
                        <path opacity="0.36"
    d="M75.6797 337.721C76.232 337.721 76.6797 337.273 76.6797 336.72C76.6797 336.167 76.232 335.719 75.6797 335.719C75.1274 335.719 74.6797 336.167 74.6797 336.72C74.6797 337.273 75.1274 337.721 75.6797 337.721Z"
    fill="white"/>
                        <path opacity="0.25"
    d="M73.4863 536.459C74.0386 536.459 74.4863 536.011 74.4863 535.458C74.4863 534.905 74.0386 534.457 73.4863 534.457C72.934 534.457 72.4863 534.905 72.4863 535.458C72.4863 536.011 72.934 536.459 73.4863 536.459Z"
    fill="white"/>
                        <path opacity="0.5"
    d="M63.6133 584.768C64.1656 584.768 64.6133 584.32 64.6133 583.767C64.6133 583.214 64.1656 582.766 63.6133 582.766C63.061 582.766 62.6133 583.214 62.6133 583.767C62.6133 584.32 63.061 584.768 63.6133 584.768Z"
    fill="white"/>
                        <path opacity="0.34"
    d="M83.7227 749.242C84.5511 749.242 85.2227 748.569 85.2227 747.74C85.2227 746.911 84.5511 746.238 83.7227 746.238C82.8942 746.238 82.2227 746.911 82.2227 747.74C82.2227 748.569 82.8942 749.242 83.7227 749.242Z"
    fill="white"/>
                        <path opacity="0.45"
    d="M138.562 844.998C139.115 844.998 139.562 844.55 139.562 843.997C139.562 843.444 139.115 842.996 138.562 842.996C138.01 842.996 137.562 843.444 137.562 843.997C137.562 844.55 138.01 844.998 138.562 844.998Z"
    fill="white"/>
                        <path opacity="0.15"
    d="M32.5391 891.345C32.8152 891.345 33.0391 891.121 33.0391 890.844C33.0391 890.568 32.8152 890.344 32.5391 890.344C32.2629 890.344 32.0391 890.568 32.0391 890.844C32.0391 891.121 32.2629 891.345 32.5391 891.345Z"
    fill="white"/>
                        <path opacity="0.13"
    d="M56.3027 992.265C57.1312 992.265 57.8027 991.593 57.8027 990.763C57.8027 989.934 57.1312 989.262 56.3027 989.262C55.4743 989.262 54.8027 989.934 54.8027 990.763C54.8027 991.593 55.4743 992.265 56.3027 992.265Z"
    fill="white"/>
                        <path opacity="0.21"
    d="M284.438 92.2689C285.266 92.2689 285.938 91.5966 285.938 90.7673C285.938 89.9379 285.266 89.2656 284.438 89.2656C283.609 89.2656 282.938 89.9379 282.938 90.7673C282.938 91.5966 283.609 92.2689 284.438 92.2689Z"
    fill="white"/>
                        <path opacity="0.18"
    d="M236.908 118.353C237.184 118.353 237.408 118.129 237.408 117.852C237.408 117.576 237.184 117.352 236.908 117.352C236.632 117.352 236.408 117.576 236.408 117.852C236.408 118.129 236.632 118.353 236.908 118.353Z"
    fill="white"/>
                        <path opacity="0.49"
    d="M200.35 305.648C201.178 305.648 201.85 304.976 201.85 304.146C201.85 303.317 201.178 302.645 200.35 302.645C199.521 302.645 198.85 303.317 198.85 304.146C198.85 304.976 199.521 305.648 200.35 305.648Z"
    fill="white"/>
                        <path opacity="0.15"
    d="M363.041 341.748C363.593 341.748 364.041 341.3 364.041 340.747C364.041 340.194 363.593 339.746 363.041 339.746C362.489 339.746 362.041 340.194 362.041 340.747C362.041 341.3 362.489 341.748 363.041 341.748Z"
    fill="white"/>
                        <path opacity="0.15"
    d="M240.199 535.591C240.475 535.591 240.699 535.367 240.699 535.09C240.699 534.814 240.475 534.59 240.199 534.59C239.923 534.59 239.699 534.814 239.699 535.09C239.699 535.367 239.923 535.591 240.199 535.591Z"
    fill="white"/>
                        <path opacity="0.53"
    d="M295.404 611.491C295.957 611.491 296.404 611.042 296.404 610.489C296.404 609.936 295.957 609.488 295.404 609.488C294.852 609.488 294.404 609.936 294.404 610.489C294.404 611.042 294.852 611.491 295.404 611.491Z"
    fill="white"/>
                        <path opacity="0.51"
    d="M268.715 702.991C269.267 702.991 269.715 702.542 269.715 701.989C269.715 701.437 269.267 700.988 268.715 700.988C268.163 700.988 267.715 701.437 267.715 701.989C267.715 702.542 268.163 702.991 268.715 702.991Z"
    fill="white"/>
                        <path opacity="0.34"
    d="M341.836 794.123C342.388 794.123 342.836 793.675 342.836 793.122C342.836 792.569 342.388 792.121 341.836 792.121C341.284 792.121 340.836 792.569 340.836 793.122C340.836 793.675 341.284 794.123 341.836 794.123Z"
    fill="white"/>
                        <path opacity="0.58"
    d="M244.221 931.874C245.049 931.874 245.721 931.202 245.721 930.373C245.721 929.543 245.049 928.871 244.221 928.871C243.392 928.871 242.721 929.543 242.721 930.373C242.721 931.202 243.392 931.874 244.221 931.874Z"
    fill="white"/>
                        <path opacity="0.59"
    d="M184.262 1029.69C184.538 1029.69 184.762 1029.47 184.762 1029.19C184.762 1028.92 184.538 1028.69 184.262 1028.69C183.986 1028.69 183.762 1028.92 183.762 1029.19C183.762 1029.47 183.986 1029.69 184.262 1029.69Z"
    fill="white"/>
                        <path opacity="0.12"
    d="M386.805 13.6769C387.081 13.6769 387.305 13.4528 387.305 13.1763C387.305 12.8999 387.081 12.6758 386.805 12.6758C386.529 12.6758 386.305 12.8999 386.305 13.1763C386.305 13.4528 386.529 13.6769 386.805 13.6769Z"
    fill="white"/>
                        <path opacity="0.18"
    d="M535.604 142.046C536.432 142.046 537.104 141.374 537.104 140.545C537.104 139.715 536.432 139.043 535.604 139.043C534.775 139.043 534.104 139.715 534.104 140.545C534.104 141.374 534.775 142.046 535.604 142.046Z"
    fill="white"/>
                        <path opacity="0.1"
    d="M425.193 252.306C425.47 252.306 425.693 252.082 425.693 251.805C425.693 251.529 425.47 251.305 425.193 251.305C424.917 251.305 424.693 251.529 424.693 251.805C424.693 252.082 424.917 252.306 425.193 252.306Z"
    fill="white"/>
                        <path opacity="0.22"
    d="M413.859 418.107C414.136 418.107 414.359 417.882 414.359 417.606C414.359 417.33 414.136 417.105 413.859 417.105C413.583 417.105 413.359 417.33 413.359 417.606C413.359 417.882 413.583 418.107 413.859 418.107Z"
    fill="white"/>
                        <path opacity="0.11"
    d="M501.604 533.897C502.156 533.897 502.604 533.449 502.604 532.896C502.604 532.343 502.156 531.895 501.604 531.895C501.051 531.895 500.604 532.343 500.604 532.896C500.604 533.449 501.051 533.897 501.604 533.897Z"
    fill="white"/>
                        <path opacity="0.44"
    d="M480.033 561.713C480.585 561.713 481.033 561.265 481.033 560.712C481.033 560.159 480.585 559.711 480.033 559.711C479.481 559.711 479.033 560.159 479.033 560.712C479.033 561.265 479.481 561.713 480.033 561.713Z"
    fill="white"/>
                        <path opacity="0.04"
    d="M390.461 714.931C390.737 714.931 390.961 714.707 390.961 714.43C390.961 714.154 390.737 713.93 390.461 713.93C390.185 713.93 389.961 714.154 389.961 714.43C389.961 714.707 390.185 714.931 390.461 714.931Z"
    fill="white"/>
                        <path opacity="0.16"
    d="M376.934 798.285C377.762 798.285 378.434 797.612 378.434 796.783C378.434 795.954 377.762 795.281 376.934 795.281C376.105 795.281 375.434 795.954 375.434 796.783C375.434 797.612 376.105 798.285 376.934 798.285Z"
    fill="white"/>
                        <path opacity="0.48"
    d="M482.592 988.834C483.144 988.834 483.592 988.386 483.592 987.833C483.592 987.28 483.144 986.832 482.592 986.832C482.04 986.832 481.592 987.28 481.592 987.833C481.592 988.386 482.04 988.834 482.592 988.834Z"
    fill="white"/>
                        <path opacity="0.5"
    d="M379.492 1057.51C379.768 1057.51 379.992 1057.28 379.992 1057.01C379.992 1056.73 379.768 1056.51 379.492 1056.51C379.216 1056.51 378.992 1056.73 378.992 1057.01C378.992 1057.28 379.216 1057.51 379.492 1057.51Z"
    fill="white"/>
                        <path opacity="0.63"
    d="M561.561 104.576C562.113 104.576 562.561 104.128 562.561 103.575C562.561 103.022 562.113 102.574 561.561 102.574C561.008 102.574 560.561 103.022 560.561 103.575C560.561 104.128 561.008 104.576 561.561 104.576Z"
    fill="white"/>
                        <path opacity="0.36"
    d="M561.562 207.562C562.391 207.562 563.062 206.89 563.062 206.06C563.062 205.231 562.391 204.559 561.562 204.559C560.734 204.559 560.062 205.231 560.062 206.06C560.062 206.89 560.734 207.562 561.562 207.562Z"
    fill="white"/>
                        <path opacity="0.37"
    d="M659.543 227.324C660.371 227.324 661.043 226.651 661.043 225.822C661.043 224.993 660.371 224.32 659.543 224.32C658.715 224.32 658.043 224.993 658.043 225.822C658.043 226.651 658.715 227.324 659.543 227.324Z"
    fill="white"/>
                        <path opacity="0.49"
    d="M597.756 424.096C598.308 424.096 598.756 423.648 598.756 423.095C598.756 422.542 598.308 422.094 597.756 422.094C597.204 422.094 596.756 422.542 596.756 423.095C596.756 423.648 597.204 424.096 597.756 424.096Z"
    fill="white"/>
                        <path opacity="0.48"
    d="M563.389 470.71C564.217 470.71 564.889 470.038 564.889 469.209C564.889 468.379 564.217 467.707 563.389 467.707C562.56 467.707 561.889 468.379 561.889 469.209C561.889 470.038 562.56 470.71 563.389 470.71Z"
    fill="white"/>
                        <path opacity="0.1"
    d="M602.143 623.932C602.695 623.932 603.143 623.484 603.143 622.931C603.143 622.378 602.695 621.93 602.143 621.93C601.59 621.93 601.143 622.378 601.143 622.931C601.143 623.484 601.59 623.932 602.143 623.932Z"
    fill="white"/>
                        <path opacity="0.48"
    d="M618.961 758.619C619.513 758.619 619.961 758.171 619.961 757.618C619.961 757.065 619.513 756.617 618.961 756.617C618.409 756.617 617.961 757.065 617.961 757.618C617.961 758.171 618.409 758.619 618.961 758.619Z"
    fill="white"/>
                        <path opacity="0.46"
    d="M661.004 869.518C661.556 869.518 662.004 869.07 662.004 868.517C662.004 867.964 661.556 867.516 661.004 867.516C660.452 867.516 660.004 867.964 660.004 868.517C660.004 869.07 660.452 869.518 661.004 869.518Z"
    fill="white"/>
                        <path opacity="0.3"
    d="M712.555 964.082C713.383 964.082 714.055 963.409 714.055 962.58C714.055 961.75 713.383 961.078 712.555 961.078C711.726 961.078 711.055 961.75 711.055 962.58C711.055 963.409 711.726 964.082 712.555 964.082Z"
    fill="white"/>
                        <path opacity="0.35"
    d="M722.426 1094.24C722.978 1094.24 723.426 1093.8 723.426 1093.24C723.426 1092.69 722.978 1092.24 722.426 1092.24C721.873 1092.24 721.426 1092.69 721.426 1093.24C721.426 1093.8 721.873 1094.24 722.426 1094.24Z"
    fill="white"/>
                        <path opacity="0.43"
    d="M904.129 109.701C904.681 109.701 905.129 109.253 905.129 108.7C905.129 108.147 904.681 107.699 904.129 107.699C903.577 107.699 903.129 108.147 903.129 108.7C903.129 109.253 903.577 109.701 904.129 109.701Z"
    fill="white"/>
                        <path opacity="0.37"
    d="M785.309 157.513C785.585 157.513 785.809 157.289 785.809 157.012C785.809 156.736 785.585 156.512 785.309 156.512C785.032 156.512 784.809 156.736 784.809 157.012C784.809 157.289 785.032 157.513 785.309 157.513Z"
    fill="white"/>
                        <path opacity="0.5"
    d="M839.053 238.671C839.881 238.671 840.553 237.999 840.553 237.17C840.553 236.34 839.881 235.668 839.053 235.668C838.224 235.668 837.553 236.34 837.553 237.17C837.553 237.999 838.224 238.671 839.053 238.671Z"
    fill="white"/>
                        <path opacity="0.21"
    d="M732.662 353.826C733.214 353.826 733.662 353.378 733.662 352.825C733.662 352.272 733.214 351.824 732.662 351.824C732.11 351.824 731.662 352.272 731.662 352.825C731.662 353.378 732.11 353.826 732.662 353.826Z"
    fill="white"/>
                        <path opacity="0.32"
    d="M776.168 539.385C776.72 539.385 777.168 538.937 777.168 538.384C777.168 537.831 776.72 537.383 776.168 537.383C775.616 537.383 775.168 537.831 775.168 538.384C775.168 538.937 775.616 539.385 776.168 539.385Z"
    fill="white"/>
                        <path opacity="0.09"
    d="M828.449 655.542C829.278 655.542 829.949 654.87 829.949 654.041C829.949 653.211 829.278 652.539 828.449 652.539C827.621 652.539 826.949 653.211 826.949 654.041C826.949 654.87 827.621 655.542 828.449 655.542Z"
    fill="white"/>
                        <path opacity="0.59"
    d="M891.699 741.282C891.975 741.282 892.199 741.058 892.199 740.782C892.199 740.505 891.975 740.281 891.699 740.281C891.423 740.281 891.199 740.505 891.199 740.782C891.199 741.058 891.423 741.282 891.699 741.282Z"
    fill="white"/>
                        <path opacity="0.63"
    d="M844.535 773.994C845.087 773.994 845.535 773.546 845.535 772.993C845.535 772.44 845.087 771.992 844.535 771.992C843.983 771.992 843.535 772.44 843.535 772.993C843.535 773.546 843.983 773.994 844.535 773.994Z"
    fill="white"/>
                        <path opacity="0.12"
    d="M829.547 965.278C829.823 965.278 830.047 965.054 830.047 964.778C830.047 964.501 829.823 964.277 829.547 964.277C829.271 964.277 829.047 964.501 829.047 964.778C829.047 965.054 829.271 965.278 829.547 965.278Z"
    fill="white"/>
                        <path opacity="0.48"
    d="M838.32 1074.48C838.873 1074.48 839.32 1074.03 839.32 1073.48C839.32 1072.92 838.873 1072.48 838.32 1072.48C837.768 1072.48 837.32 1072.92 837.32 1073.48C837.32 1074.03 837.768 1074.48 838.32 1074.48Z"
    fill="white"/>
                        <path opacity="0.54"
    d="M993.701 34.9034C993.977 34.9034 994.201 34.6793 994.201 34.4029C994.201 34.1264 993.977 33.9023 993.701 33.9023C993.425 33.9023 993.201 34.1264 993.201 34.4029C993.201 34.6793 993.425 34.9034 993.701 34.9034Z"
    fill="white"/>
                        <path opacity="0.18"
    d="M1071.94 158.882C1072.77 158.882 1073.44 158.21 1073.44 157.381C1073.44 156.551 1072.77 155.879 1071.94 155.879C1071.11 155.879 1070.44 156.551 1070.44 157.381C1070.44 158.21 1071.11 158.882 1071.94 158.882Z"
    fill="white"/>
                        <path opacity="0.15"
    d="M1090.95 280.493C1091.23 280.493 1091.45 280.269 1091.45 279.993C1091.45 279.716 1091.23 279.492 1090.95 279.492C1090.67 279.492 1090.45 279.716 1090.45 279.993C1090.45 280.269 1090.67 280.493 1090.95 280.493Z"
    fill="white"/>
                        <path opacity="0.23"
    d="M1043.06 361.877C1043.61 361.877 1044.06 361.429 1044.06 360.876C1044.06 360.323 1043.61 359.875 1043.06 359.875C1042.5 359.875 1042.06 360.323 1042.06 360.876C1042.06 361.429 1042.5 361.877 1043.06 361.877Z"
    fill="white"/>
                        <path opacity="0.55"
    d="M1025.87 459.462C1026.15 459.462 1026.37 459.238 1026.37 458.961C1026.37 458.685 1026.15 458.461 1025.87 458.461C1025.6 458.461 1025.37 458.685 1025.37 458.961C1025.37 459.238 1025.6 459.462 1025.87 459.462Z"
    fill="white"/>
                        <path opacity="0.13"
    d="M933.742 581.112C934.294 581.112 934.742 580.663 934.742 580.111C934.742 579.558 934.294 579.109 933.742 579.109C933.19 579.109 932.742 579.558 932.742 580.111C932.742 580.663 933.19 581.112 933.742 581.112Z"
    fill="white"/>
                        <path opacity="0.04"
    d="M920.582 694.706C921.41 694.706 922.082 694.034 922.082 693.205C922.082 692.375 921.41 691.703 920.582 691.703C919.754 691.703 919.082 692.375 919.082 693.205C919.082 694.034 919.754 694.706 920.582 694.706Z"
    fill="white"/>
                        <path opacity="0.64"
    d="M918.021 809.728C918.298 809.728 918.521 809.504 918.521 809.227C918.521 808.951 918.298 808.727 918.021 808.727C917.745 808.727 917.521 808.951 917.521 809.227C917.521 809.504 917.745 809.728 918.021 809.728Z"
    fill="white"/>
                        <path opacity="0.09"
    d="M1078.89 982.478C1079.16 982.478 1079.39 982.254 1079.39 981.977C1079.39 981.701 1079.16 981.477 1078.89 981.477C1078.61 981.477 1078.39 981.701 1078.39 981.977C1078.39 982.254 1078.61 982.478 1078.89 982.478Z"
    fill="white"/>
                        <path opacity="0.42"
    d="M1038.3 1078.37C1038.58 1078.37 1038.8 1078.14 1038.8 1077.87C1038.8 1077.59 1038.58 1077.37 1038.3 1077.37C1038.03 1077.37 1037.8 1077.59 1037.8 1077.87C1037.8 1078.14 1038.03 1078.37 1038.3 1078.37Z"
    fill="white"/>
                        <path opacity="0.05"
    d="M1199.17 4.89177C1199.44 4.89177 1199.67 4.66765 1199.67 4.3912C1199.67 4.11474 1199.44 3.89062 1199.17 3.89062C1198.89 3.89062 1198.67 4.11474 1198.67 4.3912C1198.67 4.66765 1198.89 4.89177 1199.17 4.89177Z"
    fill="white"/>
                        <path opacity="0.16"
    d="M1120.93 144.972C1121.76 144.972 1122.43 144.3 1122.43 143.47C1122.43 142.641 1121.76 141.969 1120.93 141.969C1120.1 141.969 1119.43 142.641 1119.43 143.47C1119.43 144.3 1120.1 144.972 1120.93 144.972Z"
    fill="white"/>
                        <path opacity="0.15"
    d="M1100.09 321.849C1100.37 321.849 1100.59 321.625 1100.59 321.348C1100.59 321.072 1100.37 320.848 1100.09 320.848C1099.81 320.848 1099.59 321.072 1099.59 321.348C1099.59 321.625 1099.81 321.849 1100.09 321.849Z"
    fill="white"/>
                        <path opacity="0.14"
    d="M1210.14 366.501C1210.41 366.501 1210.64 366.277 1210.64 366C1210.64 365.724 1210.41 365.5 1210.14 365.5C1209.86 365.5 1209.64 365.724 1209.64 366C1209.64 366.277 1209.86 366.501 1210.14 366.501Z"
    fill="white"/>
                        <path opacity="0.56"
    d="M1146.52 477.166C1147.07 477.166 1147.52 476.718 1147.52 476.165C1147.52 475.612 1147.07 475.164 1146.52 475.164C1145.97 475.164 1145.52 475.612 1145.52 476.165C1145.52 476.718 1145.97 477.166 1146.52 477.166Z"
    fill="white"/>
                        <path opacity="0.38"
    d="M1268.27 648.957C1269.09 648.957 1269.77 648.284 1269.77 647.455C1269.77 646.625 1269.09 645.953 1268.27 645.953C1267.44 645.953 1266.77 646.625 1266.77 647.455C1266.77 648.284 1267.44 648.957 1268.27 648.957Z"
    fill="white"/>
                        <path opacity="0.32"
    d="M1201 764.341C1201.27 764.341 1201.5 764.117 1201.5 763.84C1201.5 763.564 1201.27 763.34 1201 763.34C1200.72 763.34 1200.5 763.564 1200.5 763.84C1200.5 764.117 1200.72 764.341 1201 764.341Z"
    fill="white"/>
                        <path opacity="0.3"
    d="M1139.57 855.975C1140.13 855.975 1140.57 855.527 1140.57 854.974C1140.57 854.421 1140.13 853.973 1139.57 853.973C1139.02 853.973 1138.57 854.421 1138.57 854.974C1138.57 855.527 1139.02 855.975 1139.57 855.975Z"
    fill="white"/>
                        <path opacity="0.08"
    d="M1188.2 968.204C1188.48 968.204 1188.7 967.98 1188.7 967.704C1188.7 967.427 1188.48 967.203 1188.2 967.203C1187.92 967.203 1187.7 967.427 1187.7 967.704C1187.7 967.98 1187.92 968.204 1188.2 968.204Z"
    fill="white"/>
                        <path opacity="0.18"
    d="M1206.85 1067.89C1207.4 1067.89 1207.85 1067.44 1207.85 1066.89C1207.85 1066.34 1207.4 1065.89 1206.85 1065.89C1206.29 1065.89 1205.85 1066.34 1205.85 1066.89C1205.85 1067.44 1206.29 1067.89 1206.85 1067.89Z"
    fill="white"/>
                        <path opacity="0.46"
    d="M1365.88 25.889C1366.43 25.889 1366.88 25.4407 1366.88 24.8878C1366.88 24.3349 1366.43 23.8867 1365.88 23.8867C1365.33 23.8867 1364.88 24.3349 1364.88 24.8878C1364.88 25.4407 1365.33 25.889 1365.88 25.889Z"
    fill="white"/>
                        <path opacity="0.45"
    d="M1406.83 138.753C1407.66 138.753 1408.33 138.081 1408.33 137.252C1408.33 136.422 1407.66 135.75 1406.83 135.75C1406 135.75 1405.33 136.422 1405.33 137.252C1405.33 138.081 1406 138.753 1406.83 138.753Z"
    fill="white"/>
                        <path opacity="0.24"
    d="M1376.85 233.546C1377.68 233.546 1378.35 232.874 1378.35 232.045C1378.35 231.215 1377.68 230.543 1376.85 230.543C1376.02 230.543 1375.35 231.215 1375.35 232.045C1375.35 232.874 1376.02 233.546 1376.85 233.546Z"
    fill="white"/>
                        <path opacity="0.32"
    d="M1288.01 415.177C1288.28 415.177 1288.51 414.953 1288.51 414.676C1288.51 414.4 1288.28 414.176 1288.01 414.176C1287.73 414.176 1287.51 414.4 1287.51 414.676C1287.51 414.953 1287.73 415.177 1288.01 415.177Z"
    fill="white"/>
                        <path opacity="0.09"
    d="M1382.33 487.416C1382.89 487.416 1383.33 486.968 1383.33 486.415C1383.33 485.862 1382.89 485.414 1382.33 485.414C1381.78 485.414 1381.33 485.862 1381.33 486.415C1381.33 486.968 1381.78 487.416 1382.33 487.416Z"
    fill="white"/>
                        <path opacity="0.6"
    d="M1300.8 568.437C1301.63 568.437 1302.3 567.764 1302.3 566.935C1302.3 566.106 1301.63 565.434 1300.8 565.434C1299.98 565.434 1299.3 566.106 1299.3 566.935C1299.3 567.764 1299.98 568.437 1300.8 568.437Z"
    fill="white"/>
                        <path opacity="0.49"
    d="M1326.76 664.561C1327.31 664.561 1327.76 664.113 1327.76 663.56C1327.76 663.007 1327.31 662.559 1326.76 662.559C1326.21 662.559 1325.76 663.007 1325.76 663.56C1325.76 664.113 1326.21 664.561 1326.76 664.561Z"
    fill="white"/>
                        <path opacity="0.61"
    d="M1379.04 833.785C1379.87 833.785 1380.54 833.112 1380.54 832.283C1380.54 831.454 1379.87 830.781 1379.04 830.781C1378.21 830.781 1377.54 831.454 1377.54 832.283C1377.54 833.112 1378.21 833.785 1379.04 833.785Z"
    fill="white"/>
                        <path opacity="0.39"
    d="M1384.89 948.71C1385.72 948.71 1386.39 948.038 1386.39 947.209C1386.39 946.379 1385.72 945.707 1384.89 945.707C1384.06 945.707 1383.39 946.379 1383.39 947.209C1383.39 948.038 1384.06 948.71 1384.89 948.71Z"
    fill="white"/>
                        <path opacity="0.31"
    d="M1345.04 1039.48C1345.87 1039.48 1346.54 1038.8 1346.54 1037.97C1346.54 1037.15 1345.87 1036.47 1345.04 1036.47C1344.21 1036.47 1343.54 1037.15 1343.54 1037.97C1343.54 1038.8 1344.21 1039.48 1345.04 1039.48Z"
    fill="white"/>
                        <path opacity="0.19"
    d="M1473.73 65.5501C1474.56 65.5501 1475.23 64.8778 1475.23 64.0485C1475.23 63.2192 1474.56 62.5469 1473.73 62.5469C1472.91 62.5469 1472.23 63.2192 1472.23 64.0485C1472.23 64.8778 1472.91 65.5501 1473.73 65.5501Z"
    fill="white"/>
                        <path opacity="0.14"
    d="M1473.37 201.569C1473.92 201.569 1474.37 201.12 1474.37 200.567C1474.37 200.015 1473.92 199.566 1473.37 199.566C1472.81 199.566 1472.37 200.015 1472.37 200.567C1472.37 201.12 1472.81 201.569 1473.37 201.569Z"
    fill="white"/>
                        <path opacity="0.56"
    d="M1546.12 329.437C1546.95 329.437 1547.62 328.765 1547.62 327.935C1547.62 327.106 1546.95 326.434 1546.12 326.434C1545.29 326.434 1544.62 327.106 1544.62 327.935C1544.62 328.765 1545.29 329.437 1546.12 329.437Z"
    fill="white"/>
                        <path opacity="0.47"
    d="M1534.06 373.355C1534.89 373.355 1535.56 372.683 1535.56 371.853C1535.56 371.024 1534.89 370.352 1534.06 370.352C1533.23 370.352 1532.56 371.024 1532.56 371.853C1532.56 372.683 1533.23 373.355 1534.06 373.355Z"
    fill="white"/>
                        <path opacity="0.47"
    d="M1596.21 466.782C1596.49 466.782 1596.71 466.558 1596.71 466.282C1596.71 466.005 1596.49 465.781 1596.21 465.781C1595.93 465.781 1595.71 466.005 1595.71 466.282C1595.71 466.558 1595.93 466.782 1596.21 466.782Z"
    fill="white"/>
                        <path opacity="0.31"
    d="M1510.29 624.662C1510.85 624.662 1511.29 624.214 1511.29 623.661C1511.29 623.108 1510.85 622.66 1510.29 622.66C1509.74 622.66 1509.29 623.108 1509.29 623.661C1509.29 624.214 1509.74 624.662 1510.29 624.662Z"
    fill="white"/>
                        <path opacity="0.05"
    d="M1547.95 743.749C1548.78 743.749 1549.45 743.077 1549.45 742.248C1549.45 741.418 1548.78 740.746 1547.95 740.746C1547.12 740.746 1546.45 741.418 1546.45 742.248C1546.45 743.077 1547.12 743.749 1547.95 743.749Z"
    fill="white"/>
                        <path opacity="0.3"
    d="M1585.61 878.67C1586.16 878.67 1586.61 878.222 1586.61 877.669C1586.61 877.116 1586.16 876.668 1585.61 876.668C1585.06 876.668 1584.61 877.116 1584.61 877.669C1584.61 878.222 1585.06 878.67 1585.61 878.67Z"
    fill="white"/>
                        <path opacity="0.11"
    d="M1470.08 940.889C1470.63 940.889 1471.08 940.441 1471.08 939.888C1471.08 939.335 1470.63 938.887 1470.08 938.887C1469.53 938.887 1469.08 939.335 1469.08 939.888C1469.08 940.441 1469.53 940.889 1470.08 940.889Z"
    fill="white"/>
                        <path opacity="0.55"
    d="M1618.14 1078.74C1618.42 1078.74 1618.64 1078.51 1618.64 1078.23C1618.64 1077.96 1618.42 1077.73 1618.14 1077.73C1617.87 1077.73 1617.64 1077.96 1617.64 1078.23C1617.64 1078.51 1617.87 1078.74 1618.14 1078.74Z"
    fill="white"/>
                        <path opacity="0.59"
    d="M1776.08 58.4632C1776.64 58.4632 1777.08 58.015 1777.08 57.4621C1777.08 56.9092 1776.64 56.4609 1776.08 56.4609C1775.53 56.4609 1775.08 56.9092 1775.08 57.4621C1775.08 58.015 1775.53 58.4632 1776.08 58.4632Z"
    fill="white"/>
                        <path opacity="0.52"
    d="M1802.41 204.131C1802.96 204.131 1803.41 203.683 1803.41 203.13C1803.41 202.577 1802.96 202.129 1802.41 202.129C1801.86 202.129 1801.41 202.577 1801.41 203.13C1801.41 203.683 1801.86 204.131 1802.41 204.131Z"
    fill="white"/>
                        <path opacity="0.41"
    d="M1724.17 329.532C1724.45 329.532 1724.67 329.308 1724.67 329.032C1724.67 328.755 1724.45 328.531 1724.17 328.531C1723.89 328.531 1723.67 328.755 1723.67 329.032C1723.67 329.308 1723.89 329.532 1724.17 329.532Z"
    fill="white"/>
                        <path opacity="0.05"
    d="M1808.99 395.182C1809.54 395.182 1809.99 394.734 1809.99 394.181C1809.99 393.628 1809.54 393.18 1808.99 393.18C1808.44 393.18 1807.99 393.628 1807.99 394.181C1807.99 394.734 1808.44 395.182 1808.99 395.182Z"
    fill="white"/>
                        <path opacity="0.08"
    d="M1671.52 477.763C1671.8 477.763 1672.02 477.539 1672.02 477.262C1672.02 476.986 1671.8 476.762 1671.52 476.762C1671.25 476.762 1671.02 476.986 1671.02 477.262C1671.02 477.539 1671.25 477.763 1671.52 477.763Z"
    fill="white"/>
                        <path opacity="0.49"
    d="M1668.96 582.806C1669.24 582.806 1669.46 582.582 1669.46 582.305C1669.46 582.029 1669.24 581.805 1668.96 581.805C1668.69 581.805 1668.46 582.029 1668.46 582.305C1668.46 582.582 1668.69 582.806 1668.96 582.806Z"
    fill="white"/>
                        <path opacity="0.56"
    d="M1661.65 684.46C1662.48 684.46 1663.15 683.788 1663.15 682.959C1663.15 682.129 1662.48 681.457 1661.65 681.457C1660.82 681.457 1660.15 682.129 1660.15 682.959C1660.15 683.788 1660.82 684.46 1661.65 684.46Z"
    fill="white"/>
                        <path opacity="0.27"
    d="M1800.95 848.792C1801.77 848.792 1802.45 848.12 1802.45 847.291C1802.45 846.461 1801.77 845.789 1800.95 845.789C1800.12 845.789 1799.45 846.461 1799.45 847.291C1799.45 848.12 1800.12 848.792 1800.95 848.792Z"
    fill="white"/>
                        <path opacity="0.17"
    d="M1770.23 894.275C1770.51 894.275 1770.73 894.05 1770.73 893.774C1770.73 893.498 1770.51 893.273 1770.23 893.273C1769.96 893.273 1769.73 893.498 1769.73 893.774C1769.73 894.05 1769.96 894.275 1770.23 894.275Z"
    fill="white"/>
                        <path opacity="0.18"
    d="M1750.13 996.021C1750.4 996.021 1750.63 995.797 1750.63 995.52C1750.63 995.244 1750.4 995.02 1750.13 995.02C1749.85 995.02 1749.63 995.244 1749.63 995.52C1749.63 995.797 1749.85 996.021 1750.13 996.021Z"
    fill="white"/>
                    </svg>
                </div>
            </div>
            <div className="background-blend-purple"/>
            <div className="background-blend-red desktop-only"/>
            <div className="background-blend-red-2 desktop-only"/>
            <div className={classNames('orbits-canvas desktop-only', uiStore.hoveredOrbit)}>
                <div className="orbit orbit-1"/>
                <div className="orbit orbit-2"/>
                <div className="orbit orbit-3"/>
                <div className="orbit orbit-4"/>
                <div className="orbit orbit-5"/>
                <div className="orbit orbit-6"/>
                <div className="orbit orbit-7"/>
                <div className="orbit orbit-8"/>
                <div className="earth-orbit"/>
                <img className="tesla-orbit" src={require('../../static/icons/orbits/tesla-orbit.svg')}/>
            </div>
            <img className="comet comet-1" src={require('../../static/images/comets/comet-green.svg')}/>
            <img className="comet comet-2" src={require('../../static/images/comets/comet-purple.svg')}/>
            <img className="comet comet-3" src={require('../../static/images/comets/comet-pink.svg')}/>
        </div>
    )
});

export default Background;
