import React from 'react';
import { observer } from "mobx-react";
import { PlanetsEnum } from "../../stores/UIStore";
import { PlanetInfo } from "./PlanetInfo";
import { PlanetInfoEarth } from "./PlanetInfoEarth";
import { useInjection } from "inversify-react";
import { EntityStore } from "../../stores";

const PlanetInfoContainer = observer(({ root }: { root: HTMLDivElement }) => {
    const entityStore = useInjection(EntityStore);

    return (
        <div className="planets-info">
            <PlanetInfoEarth root={root} />
            {entityStore.planets?.map(p => <PlanetInfo id={PlanetsEnum[p.enum]} key={p.enum} />)}
        </div>
    )
});

export default PlanetInfoContainer;
