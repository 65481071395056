import { observer } from "mobx-react";
import { PlanetsEnum, UIStore } from "../../stores/UIStore";
import React, { useEffect, useRef, useState } from "react";
import { useInjection } from "inversify-react";
import { PLANETS_DATA } from "../../utils/const";
import { EntityStore, WalletStore } from "../../stores";
import { Api } from "../../utils/api";
import Timeout from "await-timeout";
import { COUNTRIES_NAME } from "../../utils/mapData";
import classNames from "classnames";
import { numberWithCommas, toBNJS } from "../../utils/utilities";
import useAsyncEffect from "use-async-effect";
import { toast } from "react-toastify";

export const PlanetInfo = observer(({ id }: { id: PlanetsEnum }) => {
    const video = useRef<HTMLVideoElement>();

    const uiStore = useInjection(UIStore);
    const walletStore = useInjection(WalletStore);
    const entityStore = useInjection(EntityStore);
    const api = useInjection(Api);
    const pd = PLANETS_DATA[id];
    const tesla = id === PlanetsEnum.tesla;

    const p = entityStore.planetsMap[Number(id)];

    const [ videoState, setVideoState ] = useState('needLoading');
    const [ perDay, setPerDay ] = useState(false);
    const [ bought, setBought ] = useState(false);
    const [ boughtTransition, setBoughtTransition ] = useState(false);
    const [ owner, setOwner ] = useState<string>();

    useAsyncEffect(async () => {
        if (!entityStore.mintedPlanets.includes(p.tokenId.toString()))
            return;
        setBought(true);
        const nft = walletStore.nftTokenContract;
        try {
            const owner = await nft.methods.ownerOf(p.tokenId.toString()).call();
            setOwner(owner);
        } catch (e) {}
    }, [entityStore.mintedCountries, entityStore.refreshNotify]);

    useEffect(() => {
        if (uiStore.activePlanet === id) {
            if (video.current?.readyState === 4 && video.current.paused) {  // HAVE_ENOUGH_DATA
                video.current.play();
                setVideoState('playing');
            } else {
                video.current?.load();
            }
        } else if (videoState === 'playing') {
            video.current?.pause();
            setVideoState('paused');
        }
    }, [uiStore.activePlanet]);

    const onBuy = async () => {
        if (!await walletStore.connect())
            return;
        uiStore.showModal('transactionLoading');

        try {
            const [ tokenId, price, dailyReward, uri, sig ] = await api.getMintArgs(walletStore.account, p.tokenId);
            await walletStore.nftTokenContract.methods.mint(tokenId, price, dailyReward, uri, sig).send({ from: walletStore.account, value: price as string });
            entityStore.refresh();

            uiStore.setTransaction(p.tokenId.toString(), pd.iso, pd.name);
            uiStore.showModal('transaction');
            await Timeout.set(0)
            setBought(true);
            setBoughtTransition(true);
            await Timeout.set(400);
            setBoughtTransition(false);
        } catch (e) {
            console.log(e);
            toast.error('An error has occurred');
            uiStore.hideModals();
        }
    }

    let weight = toBNJS(p.weight).div('1e21').toString()
    let weightExp = 21;
    while (weight.endsWith('000')) {
        weightExp += 3;
        weight = weight.slice(0, weight.length - 3);
    }

    return (
        <div className={classNames(`planet-info`, { sold: bought })} style={{ animation: boughtTransition && 'sold .4s ease-out' }} id={`${pd.iso}Info`}>
            <div className="body">
                <div className="left-content">
                    <div className="toolbar-wrapper">
                        <div className="back-btn desktop-only" onClick={() => uiStore.zoomOutPlanets()}>
                            <button className="btn btn-box btn-chevron-left-blue" type="button" name="button"
                                    title="Back to solar system." onClick={() => uiStore.zoomOutPlanets()}/>
                            <p>Back to space</p>
                        </div>
                        <div className="toolbar">
                            <div className="planet-name">
                                <div className="name">{p.name}</div>
                                <div>nft</div>
                            </div>
                        </div>
                    </div>
                    <div className="params params-2 desktop-only">
                        <div className="params-label">Name</div>
                        <div className="params-value">{p.name}</div>
                    </div>
                    <div className="params params-2">
                        <div className="params-label">Type</div>
                        <div className="params-value">{pd.type}</div>
                    </div>
                    <div className="params full-width">
                        <div className="params-row">
                            <div className="params-col">
                                <div className="params-label">tokens {perDay ? 'per day' : 'per year'}</div>
                                <div className="params-value params-value-green">+{numberWithCommas(toBNJS(p.emission).div(perDay ? 365 : 1).toFixed(0))} NFTm</div>
                            </div>
                            <div className="params-col desktop-only">
                                <div className="custom-switch">
                                    <input checked={perDay} onChange={() => setPerDay(!perDay)} type="checkbox"
                                           name="custom-switch"/>
                                    <div className="active-switch"/>
                                    <div className="switch-content">
                                        <p className="switch-left-content">Per Year</p>
                                        <p className="switch-right-content">Per day</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {tesla ? (
                        <>
                            <div className="params params-2">
                                <div className="params-label">Maximum speed</div>
                                <div className="params-value">&gt; {numberWithCommas(toBNJS(p.sunDistance))} km/h</div>
                            </div>
                            <div className="params params-2">
                                <div className="params-label">Acceleration to 100</div>
                                <div className="params-value">{numberWithCommas(toBNJS(p.weight))} seconds</div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="params params-2">
                                <div className="params-label">Distance from the sun</div>
                                <div className="params-value">{numberWithCommas(toBNJS(p.sunDistance).div('1e6').toString())} million km</div>
                            </div>
                            <div className="params params-2">
                                <div className="params-label">Weight</div>
                                <div className="params-value">{numberWithCommas(weight)}×10<sup>{weightExp}</sup> kg</div>
                            </div>
                        </>
                    )}
                    <div className="details details-buy">
                        <div className="wrapper">
                            <div className="details-icon">
                                <div className="btn btn-box btn-etherium"/>
                            </div>
                            <div className="details-values">
                                <strong>{numberWithCommas(toBNJS(p.price))} BNB</strong>
                                <div>~{numberWithCommas(entityStore.bnbPrice?.times(p.price).toFixed(0))} USD</div>
                            </div>
                        </div>
                        <div className="details-arrow">
                            <img src={require('../../static/icons/cards/line-long.svg')} alt=""/>
                        </div>
                        <div className="details-btn">
                            <button className="btn btn-green-2 btn-rectangle btn-buy" type="button" name="button" onClick={onBuy}>Buy</button>
                        </div>
                    </div>
                    <div className="details details-more">
                        <div className="details-icon desktop-only">
                            <div className="btn btn-box btn-user"/>
                        </div>
                        {owner && (
                            <div className="details-values">
                                <strong>{owner}</strong>
                                <div>Owner Address</div>
                            </div>
                        )}
                        <div className="details-arrow">
                            <img src={require('../../static/icons/cards/line-long.svg')} alt=""/>
                        </div>
                        <div className="details-btn">
                            <button className="btn btn-gray btn-rectangle btn-buy" type="button" name="button" onClick={() => uiStore.showProfile(owner)}>More</button>
                        </div>
                    </div>
                </div>
                <div className="right-content">
                    <video
                        className="nft-skeleton"
                        playsInline
                        preload="none"
                        loop
                        poster={pd.poster}
                        muted
                        ref={video}
                        onCanPlayThrough={() => {
                            if (videoState !== 'needLoading')
                                return;
                            video.current.play();
                            setVideoState('playing');
                        }}
                    >
                        <source src={p.video} type="video/mp4"/>
                        {/*<source src={require('../../static/videos/nft-sun.webm')} type="video/webm"/>*/}
                    </video>
                </div>
            </div>
        </div>
    );
});
